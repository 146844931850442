import React from 'react'
import Helmet from "react-helmet";
import {Link} from "react-router-dom";

const BonusTextPage = () => <>
  <Helmet>
    <title>Тексты и статьи</title>
  </Helmet>
  <div className='page--sidebarRight'>
    <main>
      <h1>Тексты и статьи</h1>
      <p>
        Если вы уже достаточно насладились текстом самой «Чумы» и вам почему-то вдруг захотелось узнать чуть больше о мире действия, можете рискнуть. Сюда мы намереваемся выкладывать, и выкладывать, и выкладывать, и выкладывать наши рабочие материалы — по мере приведения оных в пригодный для употребления вид, разумеется. Правда, мы всё равно не можем гарантировать сохранность вашей психики в случае ознакомления с ними, поскольку они представляют собой достаточно точное отражение наших пристрастий, чаяний и надежд. Вас предупреждали.
      </p>
      <p>
        В теории от самых въедливых принимаются заявки на освещение конкретных вопросов. Звоните по телефону <img src='/images/phone.gif' alt='' /><a href='mailto:bedrograd@gmail.com'>bedrograd@gmail.com</a>, спрашивайте Бюро Патентов, прикидывайте, на каком корабле вам валить из страны.
      </p>
      <p>Приятного ознакомления!</p>
      <ol>
        <li>
          <Link to='/bonus-taurese-phonology'>Таврская фонология и фонетика: краткий очерк</Link>. // Языки малых народов Всероссийского Соседства: лингвистический миф и актуальная действительность. — Б.: 1862.
        </li>
        <li>
          <Link to='/bonus-levels'>Уровни доступа к информации</Link>, очень субъективная шпаргалка.
        </li>
        <li>
          «<Link to='/node/163'>Вы к нам надолго</Link>», Г. Онегин, Бедроград, декабрь 1869 («Литература Нового Бедрограда». — 1870. — №2 (162). — С. 32—89.)
        </li>
        <li>
          К. К. Ройш. <Link to='/node/170'>Разрозненные замечания о нашем сегодняшнем понимании Йыхи Йихина, хозяина оскописткого салона и основателя Петербержской Исторической Академии</Link>. // Бедроград: День Города: выпуск 27. — Б.: 1882.
        </li>
        <li>
          <Link to='/404'>...</Link>
        </li>
      </ol>
    </main>
    <aside>
      <section className='index_sidebar_contacts' style={{
        background: 'transparent'
      }}>
        <h2>Сколько можно читать!</h2>
        <p>Может, лучше смотреть?</p>
        <p>И глаза отдохнут, и душа успокоится. Ну, то есть таков был план.</p>
        <Link to='/art' style={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}>
          <img src='/images/bonus-art2.jpg' alt='Красивые картиночки' style={{
            maxWidth: '100%'
          }} />
        </Link>
      </section>
    </aside>
    <footer>
      <img src='/images/site_footer.jpg' alt='' style={{
        maxWidth: '100vw'
      }} />
    </footer>
  </div>
</>

export default BonusTextPage