import React from 'react'
import {Helmet} from 'react-helmet'
import './disclamer.sass'

interface DisclamerProps {
  acceptDisclamer: () => void
}

export default ({ acceptDisclamer }: DisclamerProps) => <div className='disclamer'>
  <Helmet>
    <title>Дисклеймер</title>
  </Helmet>
  <main className='disclamer_container'>
    <p>Здравствуйте.</p>
    <p>
      Во избежание досадных недоразумений мы заранее предупреждаем вас,
      что на сайте, который вы вознамерились посетить,
      содержатся материалы. Точка.
    </p>
    <p>
      Согласно духу и букве актуальных веяний законотворчества материалы подобного характера должны быть
      ограждены от лиц, не достигших 18 лет.
    </p>
    <p>
      Мы очень переживаем за наши материалы и обретение оными своей собственной,
      морально устойчивой и во всех отношениях зрелой аудитории — и потому <b>призываем лиц,
      не достигших 18 лет, а также всех, кто хоть сколько-нибудь сомневается в своей моральной устойчивости,
      НЕМЕДЛЕННО ПОКИНУТЬ САЙТ.</b>
    </p>
    <p>
      (Даже длительное созерцание этого предупреждения наносит вашей морали значительный ущерб
      своими чудовищными формулировками.)
    </p>
    <p>
      Вам есть 18 лет, и вам пришло на ум что-то неприличное,
      имеющее право существовать разве что за задёрнутыми шторами?
    </p>
    <p>
      Поздравляем, вы нас поняли. Прямо сейчас вы видите такую штору.<br />
      Отдёргивать её вас никто не призывает, не агитирует и — леший упаси — не принуждает.
    </p>
    <div className='disclamer_links'>
      <a href='https://souzmult.ru'>
        Понял, ухожу,<br />
        счастливо оставаться
      </a>
      <button className='linkButton ta-right' onClick={acceptDisclamer}>
        С условиями ознакомлен,<br />
        готов рискнуть и достиг 18 лет
      </button>
    </div>
    <p className='ta-center'>
      Спасибо за понимание.
    </p>
  </main>
</div>