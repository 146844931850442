import React from 'react'
import {Link} from 'react-router-dom'
// @ts-ignore
import VK, {Group} from 'react-vk'

import news from '../../../Data/news'
import NewsArticle from '../../NewsArticle/NewsArticle'
import quotes from "../../../Data/quotes";
import BlockQuote from "../../BlockQuote/BlockQuote";
import './IndexPage.sass'
import ShareWidgets from "../../ShareWidgets/ShareWidgets";

// @ts-ignore
export default () => <div className='page--sidebarRight'>
  <main>
    <section>
      {
        news.slice(0, 3).map(article => <NewsArticle article={article} />)
      }
      <p className='ta-right' style={{
        margin: '0 10px 10px'
      }}>
        <Link
          to='/archive'
          style={{
            fontSize: '13px',
            fontWeight: 'bold'
          }}
          tabIndex={0}
        >
          Архив новостей <img src='/images/down.png' alt='' />
        </Link>
      </p>
    </section>
    <section className='index_feedback'>
      <h2>Мнения первых пострадавших</h2>
      {
        quotes.map(quote => <BlockQuote quote={quote} />)
      }
    </section>
  </main>
  <aside>
    <section className='index_sidebar'>
      <h2>Где я? Кто здесь?</h2>
      <div>
        <p>«Чума в Бедрограде» — это немалых объёмов роман про некую болезнь, поразившую некий город. Компетентные лица, впрочем, утверждают, что дело вовсе не в городе и даже не в болезни.</p>
        <p>«В чём же? В чём же?» — кричат малые дети, бегая за нами. Мы лишь молча усмехаемся. Ответ на этот вопрос — в «Чуме», а «Чуму» можно</p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Link to='/book/prolog' className='index_sidebar_btn index_sidebar_btn--read' tabIndex={0}>читат'</Link>
        или
        <Link to='/download' className='index_sidebar_btn index_sidebar_btn--download' tabIndex={0}>скачат'</Link>
        или даже
        <Link to='/faq' className='index_sidebar_btn index_sidebar_btn--comprehend' tabIndex={0}>понят'</Link>
      </div>
    </section>
    <section className='index_sidebar_contacts'>
      <h3>Звоните нам</h3>
      <small>по телефону</small>
      <a href='mailto:bedrograd@gmail.com' tabIndex={0}><img src='/images/phone.gif' alt='' /> bedrograd@gmail.com</a>
      <h3>Ищите нас</h3>
      <small>по адресу</small>
      <VK>
        <Group
          groupId={33910355}
          options={{
            mode: 1
          }}
        />
      </VK>
      <h3>Расскажите</h3>
      <small>кому следует</small>
      <ShareWidgets />
      <h3>Любим и чтим</h3>
      <small>наших трудовых спонсоров</small>
      <a href='https://megus.org' tabIndex={0}>megus.org</a>
      <a href='https://mithril.msk.su' tabIndex={0}>mithril.msk.su</a>
      <br/>
      <span>и просто товарищей</span>
      <a href='https://shadem.ru' tabIndex={0}>shadem.ru</a>
      <a href='https://vk.com/freedomcast' tabIndex={0}>FreedomCAST</a>
      <a href='http://ethnophony.ru' tabIndex={0}>Этнофония</a>
      <a href='http://pineapplepeak.blogspot.com' tabIndex={0}>Апогей Ананасности</a>
    </section>
  </aside>
  <footer>
    <img src='/images/gryphons_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</div>
