import React from 'react'
import {Link} from 'react-router-dom'

type IChapter = [ number, string, (boolean|undefined)? ]

export type IDay = [ string, Array<IChapter> ]

export const days: Array<IDay> = [
  [
    'День первый. Суббота',
    [
      [1, 'Не лучший день в жизни Габриэля Евгеньевича']
    ]
  ],
  [
    'День второй. Воскресенье',
    [
      [2, 'Яблоко'],
      [3, 'Опять думать']
    ]
  ],
  [
    'День третий. Понедельник',
    [
      [4, 'Варианты решения типовых задач']
    ]
  ],
  [
    'День четвёртый. Вторник',
    [
      [5, 'Припев (первый раз)'],
      [6, 'Идеология за лето'],
      [7, 'Припев (второй раз)'],
      [8, 'Приближение источников света'],
      [9, 'Припев (третий раз)']
    ]
  ],
  [
    'День пятый. Среда',
    [
      [10, 'Трое на одного'],
      [11, 'Разделяй и херачь'],
      [12, 'Женщина на корабле (здесь был Хикеракли)']
    ]
  ],
  [
    'День шестой. Четверг',
    [
      [13, 'Брать след, отлеплять от земли'],
      [14, 'Двоеточие и скобочка'],
      [15, 'Опорный конспект'],
      [16, 'О пользе чтения'],
      [17, 'Не заслуживает жалости']
    ]
  ],
  [
    'День седьмой. Пятница',
    [
      [18, 'Маленькие такие гвоздики'],
      [19, 'Если достаточно сильно зажмуриться'],
      [20, 'Помоеньки, родненькие'],
      [21, 'Профиль'],
      [22, 'Два заветных слова'],
      [23, 'Слишком густые леса'],
      [24, 'Он, Гошка'],
      [25, '853']
    ]
  ],
  [
    'День восьмой. Суббота',
    [
      [26, 'О где, о где они теперь'],
      [27, 'Черёмуха'],
      [28, 'О кто, о кто мог угадать'],
      [29, 'Четверо безымянных'],
      [30, 'Да или нет']
    ]
  ],
  [
    'День девятый. Воскресенье',
    [
      [31, 'Максим, купи скопца'],
      [32, 'Присмотреться'],
      [33, 'Что-то, что не должно литься (Хрусть!)'],
      [34, 'Слишком длинная, неправдоподобная и сопливая байка'],
      [35, 'Бы'],
      [36, 'Снились трещины']
    ]
  ],
  [
    'Не день десятый',
    [
      [37, 'Чем всё закончилось с одной стороны', true],
      [38, 'Чем всё закончилось с другой стороны', true]
    ]
  ]
]

export const chapterNumber = (number: number) => {
  return number < 10 ? `0${number}` : `${number}`
}

const chapters = days.map(day => <>
  <h2>{day[0]}</h2>
  {day[1].map(chapter => <p className='chapter'>
    {chapter[2] ? '' : `Глава ${chapter[0]}. `}<Link to={`/book/chapter${chapterNumber(chapter[0])}`}>{chapter[1]}</Link>
  </p>)}
</>)

export default () => <div className='page--sidebarRight'>
  <main>
    <h1>Содержание</h1>
    <h2><Link to='/book/prolog'>Пролог</Link></h2>
    {chapters}
    <h2><Link to='/book/epilogue'>Эпилог</Link></h2>
  </main>
  <aside>
    <section className="index_sidebar_contacts" style={{
      background: 'transparent'
    }}>
      <h2>Скачать «Чуму» ШОК ВИДЕО crack keygen БЕЗ РЕГИСТРАЦИИ</h2>
      <p>
        Если вам лениво читать «Чуму» из тревожного места под названием «интернет», если ваша печатная машинка предпочитает fb2, epub или pdf, если ваш родитель вас никогда не любил, вы можете
        <Link to='/download' className='index_sidebar_btn index_sidebar_btn--download' tabIndex={0}>скачат'</Link>
        уже опубликованные главы «Чумы в Бедрограде» и наслаждаться ими втихомолку.
      </p>
      <p>
        Кроме того, если вдруг случится война и наш сервер обрушится, у вас сохранится личная индивидуальная копия
(не сервера, увы, а только романа).
      </p>
      <p>Пришлёте нам потом.</p>
    </section>
  </aside>
  <footer>
    <img src='/images/site_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</div>
