import React from 'react'
import { IBookPage } from './index'

const chapter26: IBookPage = {
  header: 'header_d8.jpg',
  title: 'Глава 26. О где, о где они теперь',
  prevLink: 'chapter25',
  nextLink: 'chapter27',
  content: <>
    <h2>Бедроградская гэбня. Бахта Рука</h2>

    <img alt='' src="/images/26_bahta.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>А ещё говорят «назвался лошадью —
      полезай под плуг». Росы и дураки говорят.</p>

    <p>Бахта Рука с недоумением
      наблюдал, как Андрей открывает капот, дёргает шланги, хватается за масляный
      щуп. Вот зачем, спрашивается: Гошка и Соций, может, и купятся на манёвры, они в
      ремонтные дела не лезут, но Бахта Рука-то прекрасно знает, что всё с такси в
      порядке сегодня, а Андрею от волнения просто руки занят’ нечем.</p>

    <p>Главное — было бы чего волноват’ся:
      Университет хорошо прижат, рыпат’ся бесполезно, они теперь не только Дмитрия
      Борстена, они что угодно на тарелочке подадут и не переломятся. А Андрей психует.
    </p>

    <p>Не умеет же человек побеждат’,
      всё время ему подстава какая мерещится. Гошка его
      припадки беспокойства «белочкой» кличет обычно, смеётся, подтрунивает — вот
      Андрей и наловчился в запчастях истерику прятат’, чтоб не раздражат’ лишний
      раз.</p>

    <p>Бахта Рука подошёл поближе,
      тронул незаметно за плечо: мол, ты чего опят’?</p>

    <p>— Непонятно, — отозвался Андрей.
      — Непонятно, как фаланги поступят с нами в связи с блокадой Порта. Если
      докопаются, кто довёл ситуацию до подобных мер, — нам не выпутаться.</p>

    <p>Говорят, «назвался лошадью —
      полезай под плуг» — это метафора. Только ну их, эти метафоры — лошад’ не для
      плуга, что росы в лошадях понимают!</p>

    <p>А Бедроградская гэбня — не для
      беготни на цыпочках перед фалангами или кем ещё.</p>

    <p>— А кто довёл ситуацию до подобных
      мер? — улыбнулся Андрею Бахта Рука. — Порт функционирует самостоятельно, у
      городских властей советов не спрашивает, кому оказывать поддержку, выбирает
      сам. И блокады свои объявляет — тоже сам.</p>

    <p>Андрей отцепился
      наконец от масляного щупа, вытер руки, хлопнул капотом:</p>

    <p>— После того, как меня взяли в
      Медкорпусе, я уже ни на что не надеюсь. Кто мог предположить, что у фаланг есть
      наводка? Откуда она взялась?</p>

    <p>— Разобрались же, — мотнул
      головой Бахта Рука, — некто Дмитрий Ройш, он же, возможно, нынешний университетский
      Борстен, он же, возможно, давнишний Смирнов-Задунайский, с которым ты когда-то
      нехорошо поступил, — Бахта Рука кивнул на Андреевы часы. — Сколько там? В любом
      случае, совсем скоро его увидим.</p>

    <p>В ненастный
      ден’ по солнцу не сориентируешься, но Бедроградская гэбня уже достаточно тут
      торчит, чтоб назначенные восем’ утра таки наступили.</p>

    <p>— Дмитрий
      Ройш-Борстен-Смирнов-Задунайский — это не ответ, — Андрей нахмурился,
      взгромоздился на капот сверху, взял сигарету не с того конца, раздражённо
      сплюнул попавший в рот табак.</p>

    <p>Бахта Рука кинул ему коробок
      спичек.</p>

    <p>Забавно видет’ очаровательного,
      спокойного, благожелательного на людях Андрея таким —
      не прячущим нервы и злобу, не натягивающим маску дружелюбия. Это и ест’ главный
      показател’ синхронизации, о которой тут так много думали и говорили, когда
      Андрей пропал, — не закрыват’ся от своих. А если и
      закрыват’ся на секунду — упялившис’ ли под капот, устроив ли какой другой
      спектакл’, — то бросат’ это дело, как только тебя спросят, что стряслос’.</p>

    <p>— Что тебя не устраивает в
      Дмитрии Борстене? — подошёл Соций, тоже закурил, поглядывая на дорогу.</p>

    <p>— Что это не ответ, — буркнул ещё
      раз Андрей. — Даже если он — это он, что мы с того имеем? Мы всё равно не
      знаем, например, как он приманил в Медкорпус фаланг.</p>

    <p>— Так и спросим, — кровожадно
      хмыкнул Соций. — Спрошу, вернее.</p>

    <p>Бахта Рука к Социевой
      кровожадности относился положительно, хотя совест’ напоминала: возможно,
      Дмитрий Ройш-Борстен, если он Дмитрий Смирнов-Задунайский, — кассах, а с
      кассахами у Соция разговор всегда короткий, после Северной Шотландии-то.</p>

    <p>За свою
      жизн’ Бахта Рука непростого отношения к малым народам тоже наелся, недаром он
      тавр без косы — любители таврских кос постаралис’. Так что он бы и рад
      посочувствоват’ гипотетическому кассаху, что тому именно с Социем встречат’ся,
      но, с другой-то стороны, пойди напасис’ сочувствия на
      университетских. Они ж на шею сядут и поедут, хлыстом погоняя.</p>

    <p>— Ну про
      фаланг он, может, для разнообразия и расскажет, — гнул свою линию Андрей. — Но
      это всё равно не самое важное!</p>

    <p>— Тебе
      какую проблему ни реши, ты тут же новую найдёшь, — не выдержал, засмеялся Бахта
      Рука. — Что у нас <i>теперь</i> самое
      важное?</p>

    <p>— <i>Откуда,</i> — мрачно бросил Андрей. Помолчал для нагнетания атмосферы,
      потаращился в пустоту и продолжил через какое-то время: — Откуда было известно,
      что надо давать фалангам наводку. Проще говоря, как они в принципе вышли на
      наши планы.</p>

    <p>— И вот этого херов Дмитрий Ройш,
      в смысле Борстен, точно не сдаст, — не менее мрачно выплюнул Гошка, с самого
      прибытия куривший сигарету за сигаретой в отдалении.</p>

    <p>Он вообще был сегодня сам не свой
      — устал, наверное, в таком режиме бегат’. Вроде и полномочия Университетской
      гэбни временно отобрали, и оружие табельное, и Дмитрия Борстена-Ройша вытащили поговорит’, а Гошка как будто не рад.</p>

    <p>Бахта Рука чувствовал себя
      неуютно, глядя на такого Гошку. Перед выездом и Бахта Рука, и Андрей, и Соций
      попробовали к Гошке сунут’ся, но тот только попросил
      стимуляторов каких-нибуд’. Сказал, с ног валится, голова не варит. Шутил, что
      стареет, не выдерживает рабочего напряжения.</p>

    <p>Какое ему старет’ — это Бахте
      Руке сорок пят’, но он и не думал как-то о старости, Социю вон тоже за сорок
      перевалило, а Гошка-то их обоих помладше будет.</p>

    <p>— Едет, — щёлкнул языком Соций,
      собрался вес’, сразу стал жестче, внимательней. Не человек, а согнутая пружина.</p>

    <p>Андрей легко спрыгнул с капота,
      расправил плечи, натянул на лицо безмятежное радушие — будто не он полминуты
      назад видел сплошные трудности без единого просвета.</p>

    <p>Бахта Рука буквально физически
      ощутил, как сам приходит в боевую готовност’, заражается
      агрессивным настроем Соция и Андрея. Его-то самого скорее веселила перспектива
      беседы с неизвестным университетским медиком без прописки, которого в числе
      главных фигурантов назвал известный университетский медик, отец Ройшевой девки.</p>

    <p>Но собственные эмоции становятся
      неважны, когда головы твоей гэбни так отчётливо жаждут военных действий.</p>

    <p>— Одно такси всего, —
      констатировал и без того очевидный факт Бахта Рука. Махнул младшим служащим,
      которые уже ждали сигнала, чтоб выехат’ проверит’ хвосты.</p>

    <p>Кое-кто сидел по кустам и
      ближайшим постройкам, но им никакой специальный сигнал пока не нужен, сами
      должны следит’ за происходящим. Будет повод открыват’ огон’ — тогда и сигналы будут.
    </p>

    <p>Оставшиеся младшие служащие
      мгновенно выстроилис’ у ворот склада, готовые обыскат’ Дмитрия Борстена на
      предмет оружия и иных объектов, которые можно использоват’ для нападения.</p>

    <p>На Соция, конечно, попробуй напади, но Андрей настаивал на перестраховке, да и
      Бахта Рука на сей раз не видел в предосторожностях ничего дурного.</p>

    <p>— Раньше он, если это он, особой
      физической подготовкой не отличался, — шепнул Социю Андрей, хотя вчера они и
      так вес’ вечер проговорили о характеристиках и особых приметах гипотетического
      Дмитрия Смирнова-Задунайского.</p>

    <p>Соций только самодовольно
      улыбнулся, даже дал Андрею шутливого пинка — ох, не терпится
же ему поговорит’ по душам с университетским человеком.</p>

    <p>Всем не терпится, но поговорит
      только Соций.</p>

    <p>По-хорошему всем остальным и быт’-то здес’ не полагалос’ —&nbsp;ясно же, что
      университетские
      очен’ хотели бы скрыт’ Дмитрия Борстена ото всех, от кого получится. Но о
      прямом запрете речи не заходило, равно как и о составе
      людей, обеспечивающих безопасност’ мероприятия за дверьми склада. Могут же трое
      остальных голов Бедроградской гэбни пожелат’ проследит’ за ней самолично?</p>

    <p>Такси резво припарковалос’ у
      самого начала асфальтированной дорожки к складу через пустыр’. Склад-то в черте
      города, но всё же на отшибе — чтоб никто ничего, если что. Поэтому вокруг —
      пустыр’, живая изгород’ чут’ подальше, горстка гаражей, завод на той стороне
      шоссе. От шоссе до склада — метров сто этой самой асфальтированной дорожки.
      Стреляй — не хочу, в общем-то.</p>

    <p>Бахта Рука помнил: договорилис’
      не стрелят’ без необходимости. Гошка после встречи гэбен всю шапку прозудел:
      сначала выяснит’ хот’ что-то и только потом переходит’ к решительным действиям.
      Если вообще переходит’. Но не оцениват’, как простреливается дорожка, было
      невозможно.</p>

    <p>Такси с Дмитрием Борстеном
      простояло с минуту безо всяких признаков жизни, а потом грянуло на всю округу
      какой-то очен’ знакомой симфонической музыкой, и взмахнуло дверьми прям как
      крыльями, до тошноты синхронно.</p>

    <p>Наружу выскочили Охрович и
      Краснокаменный, с совершенно клоунским видом
      отсалютовали издалека Бедроградской гэбне и вдруг с места рванули к складу.</p>

    <p>Если не знат’, что они всегда
      такие, впору бы за пистолет схватит’ся. Но
      Бедроградская гэбня за столько лет привыкла: психи —
      они и ест’ психи. И хот’ пойди разберис’ ещё, были ли на самом деле какие-то
      там тайные гормональные опыты в начале пятидесятых, через которые вроде как прошла
      вся Университетская гэбня, или Андрей эти опыты десят’ лет назад сам придумал,
      чтоб давнее дело на Колошме закрыт’, но Охрович и Краснокаменный-то однозначно психи. Молевич и Базал’д ещё куда
      ни шло, на них и не подумаешь, что жертвы экспериментов, а эти ну точно как из
      дурки сбежали.</p>

    <p>«Эти» тем временем уже вломилис’ на
      склад.</p>

    <p>Всё по-честному: в безопасности
      помещения имеют право быт’ уверены обе стороны. На
      складе за Охровичем и Краснокаменным присмотрят младшие служащие, а
      Бедроградская гэбня постоит ещё, покурит и посверлит глазами такси, где осталис’,
      по всей видимости, только водител’ и Дмитрий Борстен.</p>

    <p>Андрей улыбался, поворачиваяс’ то
      к Социю, то к Бахте Руке, просто-таки сиял и лучился, но с близкого расстояния
      можно было заметит’, как подрагивают у него от возбуждения пальцы.</p>

    <p>Соций же, не скрываяс’, пялился на такси.</p>

    <p>Бахта Рука поискал Гошку.</p>

    <p>Тот среагировал на взгляд,
      швырнул окурок на проезжую част’ и подошёл, наконец, к
      трём другим головам. Вид у него и правда был совсем уж
      измятый, замученный, какой-то потёртый, и Бахте Руке сразу захотелос’, чтобы
      всё прошло тихо-мирно. Когда кто-то из гэбни не в порядке, не тихо и не мирно
      действоват’ можно, но сложно.</p>

    <p>— Выспись, пожалуйста, — Бахта
      Рука говорил тихо, но настойчиво. — У меня самого от тебя башка
      деревенеет.</p>

    <p>— И так всю чуму проспал, —
      процедил сквоз’ зубы Гошка.</p>

    <p>Процедил, но тут же
      отрефлексировал свой тон, усмехнулся, тряхнул головой, отгоняя усталост’, — и
      сразу стал больше похож на нормального Гошку.
      Заметил-таки, как психует Андрей, инстинктивно
      придвинулся. Кажется, даже дёрнулся по голове потрепат’, но сообразил, что неподалёку
      имеются зрители, и сдержался.</p>

    <p>Вот так — гораздо лучше.</p>

    <p>Бахта Рука как раз пытался припомнит’, что же всё-таки за музыка послужила
      фанфарами Охровичу и Краснокаменному в момент их выпрыгивания из такси, когда
      те снова возникли в поле зрения. Проверили, значит, склад. Проверят’ там
      вообще-то нечего — только и готовили, что записывающие (и воспроизводящие)
      устройства, а они-то неопасны.</p>

    <p>Сейчас Бахте Руке удалос’
      разглядет’, что Охрович и Краснокаменный явилис’ при гэбенных мундирах, но без
      наплечников. Ёмко и выразительно, особенно с учётом того, что их текущую профессиональную принадлежност’, видимо, призваны
      были продемонстрироват’ бордельные ошейники.</p>

    <p>Что там Гошка вещал когда-то про
      университетских блядей?</p>

    <p>Всё про всех университетских
      блядей разом всплыло в голове у Бахты Руки, когда Охрович галантно взялся за
      двер’ такси, а Краснокаменный протянул руку, помог пассажиру выбрат’ся.</p>

    <p>Пассажиру.</p>

    <p>Дмитрию вроде как Борстену.</p>

    <p>Из такси выплыло нечто, меньше
      всего напоминающее загруженного работой университетского медика на вос’мой ден’
      чумы в Бедрограде.</p>

    <p>И больше всего напоминающее
      оживший дореволюционный фотоснимок.</p>

    <p>Своеобразный
      такой, буквально-таки «Петерберг глазами европейских газет». Потому что ну всем
      же известно, чем особенно брал за душу иностранцев единственный свободный для
      въезда город Росской Конфедерации.</p>

    <p>Этим самым.</p>

    <p>Вот примерно так, кстати, и
      выглядел этот их завкаф в свои лучшие годы.</p>

    <p>Не портретно, нет.</p>

    <p>Гм, стилистически.</p>

    <p>Бахта Рука даже несколько
      оторопел.</p>

    <p>Дмитрий Борстен, стратегично
      зарывшис’ лицом в какой-то букет (неужто действительно
      ветка черёмухи — в сентябре-то?), двигался по асфальтированной дорожке через
      пустыр’ в сопровождении Охровича и Краснокаменного, и стрелят’ в него не
      хотелос’ вовсе.</p>

    <p>Если чего-то и хотелос’, то точно
      не стрелят’.</p>

    <p>Невосполнимой потерей для городской
      экономики был несчастный указ о запрещении борделей! Бедрограду — как месту,
      где зародилас’ оскопистская традиция — можно было и оставит’ его особое положение.
      Для привлечения туристов.</p>

    <p>Такая перспективная ниша, а
      тепер’ в ней только Университет и беснуется по мелочи.</p>

    <p>Стилистическое сходство с
      петербержскими дореволюционными достопримечательностями, по всей видимости, — чрезвычайно
      популярное веяние в университетской среде, если уж оно не обошло даже
      загруженного работой медика на вос’мой ден’ чумы.</p>

    <p>Завкаф же завкафом: непременная
      белоснежная струящаяся рубашка с кружевной оторочкой и воротом-стойкой,
      подчёркивающим шею; узкие расшитые штаны, подчёркивающие всё на свете. Лёгкие,
      летящие волосы, убранные в кокетливо перевязанную шёлковой лентой косу. Тонкие
      кольца на пальцах.</p>

    <p>Но сут’ не в том, совсем не в
      том.</p>

    <p>Сут’ — в пластике, невозможной и
      вызывающей.</p>

    <p>Дмитрию Борстену оставалас’ ещё
      пара шагов до младших служащих, коим надлежало обыскат’ его перед заходом на
      склад, а Бахта Рука уже сто раз успел пожалет’, что Бедроградская гэбня сегодня
      не сама проводит обыск. В восем’ рук бы, прямо как в лучшие годы этого их
      завкафа, — хот’ это и не сам завкаф, а аллюзия на.</p>

    <p>Аллюзия, попав в руки младших
      служащих, вынуждена была расстат’ся со своим букетом. Ветер
      наконец донёс цветочный запах, и сомнений не осталос’: черёмуха.</p>

    <p>Где — и, леший, зачем — они берут
      черёмуху в сентябре? И аллюзии на завкафа?</p>

    <p>Бахта Рука ещё долго стоял бы,
      безмолвно пяляс’ на благоухающую аллюзию, если бы не услышал выдох, почти стон,
      трёх других голов Бедрограской гэбни.</p>

    <p>— Это он, — сказал Соций с
      некоторым недоумением, недоверием даже, и лицо у него было при этом как у
      человека, увидевшего забытого отрядского приятеля.</p>

    <p>— Это он, — сказал Андрей одними
      губами, и лицо у него при этом было белее цветов черёмухи —&nbsp;как у
      человека, увидевшего мертвеца.</p>

    <p>— Это он, —&nbsp;сказал Гошка
      как-то немного потерянно и зло, сквоз’ зубы, и лицо у него при этом было таким,
      будто он сейчас собирался кого-нибуд’ убит’.</p>

    <p>Сказали — в один голос, но будто
      бы и не слышали друг друга.</p>

    <p><i>Это он.</i></p>

    <p>Бахта Рука не понял, что имели в
      виду Соций, Андрей и Гошка, зато сам готов был поручит’ся:
      это <i>не</i> он. Совершенно точно.</p>

    <p>В смысле, не тот, кого они
      ожидали увидет’ Дмитрием Борстеном. Не Дмитрий Смирнов-Задунайский, погибший во
      время вспышки степной чумы на Колошме аж в сем’десят
      шестом году.</p>

    <p>Столько материалов стародавнего
      дела за вечер все вместе перерыли: не он это, не он.</p>

    <p>Конечно, Бедроградская гэбня всё знает о способах сменит’ внешност’ — те же вечные
      младшие
      служащие, в которых Гошка переодевается, чего стоят! Но Дмитрий
      Смирнов-Задунайский как минимум был кассах — а это не
      только чёрные волосы, которые можно и убедительно перекрасит’. Да, у кассахов
      гораздо менее характерные национальные черты, чем у тех же тавров, но если
      держат’ в уме кассашество, точно не ошибёшься. Разрез глаз, губы, телосложение
      тоже скорее узнаваемое.</p>

    <p>Вообще-то из погибшего во время
      вспышки степной чумы Дмитрия Смирнова-Задунайского аллюзия на завкафа вышла бы
      ещё попрямолинейней. Оба вед’ кассахи. А этот нынешний
      — вот ни разу не кассах.</p>

    <p>И какое тогда, к лешему, «это
      он»?</p>

    <p>Спросит’ у всех
троих Бахта Рука не смог: пока он глазел в сторону обыска, Соций уже почти дошёл
до Охровича и Краснокаменного — чтобы те в свою очеред’ проверили его на
наличие оружия.</p>

    <p>Аллюзия же на завкафа и
      оскописткие салоны, скучая в ожидании возможности пройти на склад, картинно
      обернулся через плечо на Соция. Выражение лица с такого расстояния
      верно воспринят’ было непросто, но Бахте Руке почудилос’ там очен’ не
      завкафское нахальство.</p>

    <p>Будто бы прямая издёвка даже.</p>

    <p>В попытке смахнут’ наваждение
      Бахта Рука думал поинтересоват’ся-таки у Андрея и Гошки, кого они в аллюзии
      опознали.</p>

    <p>Думал, собирался, но так и не
      произнёс ни звука: Гошка, не сводя страшных глаз со спины в шелках, быстро и
      однозначно потянулся к кобуре, а Андрей, стоявший совсем близко, его руку тут
      же перехватил. Сжал пальцы вокруг запяст’я Гошки так, что побелели костяшки,
      сам явно до смерти перепугался, но решительно прошипел что-то вроде: «Кто
      говорил не открывать огонь, пока не разберёмся?»</p>

    <p>Рука Гошки сначала дёрнулас’, но
      быстро обмякла, повисла безвольно.</p>

    <p>Он обернулся
      было на Андрея, открыл рот — но тут же снова сжал зубы, проглотил несказанное.</p>

    <p>Бахта Рука обеспокоенно потянулся
      за сигаретами.</p>

    <p>Что это было — леший знает, но уж
      всяко не что-то простое, понятное и хорошее.</p>

    <p>В лучшем случае — простое и понятное, но никак не хорошее: Бахта Рука готов был поспорит’ на ещё одно отрезание косы, если б она у него
      была, что минуту назад Гошка, наплевав на собственные указания и планы,
      порывался ходячую аллюзию застрелит’.</p>

    <p>Потому что это не Дмитрий
      Борстен, это <i>он</i>.</p>

    <p>И для Андрея — тоже <i>он</i>.</p>

    <p>И для Соция.</p>

    <p>Все трое <i>его</i> как-то идентифицировали, и все трое наверняка по-своему, и
      только для Бахты Руки человек в кружевной рубашке тем и остался —
      университетским незнакомцем, пришедшим на переговоры с одним из голов
      Бедроградской гэбни. Хот’ на волне общего безумия и показалос’ на миг, что ест’
      в его чертах что-то смутно, отдалённо совсем знакомое
      — точно встречалис’ раньше мельком. Показалос’, что и Бахта Рука опознал бы,
      буд’ у него побольше времени.</p>

    <p>Показалос’. Нормальные фантомные
      ощущения от синхронизации.</p>

    <p>Заскрипели ворота — это аллюзия
      на завкафа и Соций скрылис’ внутри.</p>

    <p>Гошка, кажется, выругался
      бессильным полушёпотом, Андрей, кажется, выдохнул и вес’ затрясся мелкой дрож’ю,
      на секунду забыв, что вокруг ест’ ещё зрители, помимо голов Бедроградской
      гэбни.</p>

    <p>И вопрос о том, стоит ли их
      спрашиват’ о причинах подобной реакции, ответа не потребовал.</p>

    <p>Аллюзия на завкафа и Соций
      скрылис’ внутри. Скрылис’, всё.</p>

    <p>Теперь только ждат’.</p>

    <p>Только пуст’ Гошка и Андрей
      сначала отойдут немного.</p>

    <p>На пустыре было ветрено и
      промозгло, днём наверняка опят’ зарядит дожд’. Если к понедельнику погода не
      разойдётся, несчастный юбилей Первого Большого
      Переворота можно заранее считат’ неудавшимся. По такой сырости надо было сразу бассейны
      вместо трибун устанавливат’.</p>

    <p>Мысли о запланированном,
      расписанном по пунктам и, несмотря на масштаб, рядовом вообще-то мероприятии
      кое-как успокаивали. Весёлая это работа — быт’ городской власт’ю. Сначала
      перетравишь полгорода, а потом устраиваешь торжества для народа. Университету
      хорошо, у них зона ответственности меньше — воюй в своё удовольствие.</p>

    <p>Когда сочиняли вес’ план с
      контролируемым заражением, мечталос’: Университета не станет на шестом уровне
      доступа, а то и не станет вовсе. Фаланги разгонят их и по ушам надают, потому
      что нечего брат’ся за дело, ежели не тянешь.</p>

    <p>На вос’мой
      ден’ чумы Бахта Рука вдруг задумался: а так уж ли не тянут?</p>

    <p>Вон сколько продержалис’. До
      пятого дня вообще выигрывали всухую, водили за нос. Ройшева девка
      — малолетняя вед’ дурочка, а как Гошку развела. Пропажа Андрея в Медкорпусе —
      сложнейшая операция, должно быт’, раз так лихо сработала. И лекарство гнат’
      нашли неведомый способ при своих-то жалких ресурсах, и с Портом договорилис’ о
      помощи, и вообще молодцом. А когда они вчера Ройша на место Молевича усадили,
      Бахта Рука уж было решил, что Бедроградская гэбня совсем сливает.</p>

    <p>Сложно, сложно не зауважат’
      Университет после всего этого.</p>

    <p>На самом деле размышления Бахты
      Руки — от погоды через несчастный юбилей к уважению Университета — уместилис’ в
      половине минуты, а то и меньше. Вспыхнули маячками: «тучи и ветер», «сегодня
      суббота — юбилей в понедельник», «Университету о понедельнике парит’ся не надо»,
      «Университет — молодцы».</p>

    <p>Вспыхнули и погасли, потому что
      молодцы Университета стремительно приближалис’ к трём головам Бедроградской
      гэбни с ветками черёмухи наперевес.</p>

    <p>Охрович и Краснокаменный решили
      скоротат’ время одних переговоров за другими переговорами?</p>

    <p>— Дорогие друзья!</p>

    <p>— Уважаемые радиослушатели!</p>

    <p>— Только сегодня и только у нас!</p>

    <p>— Радикальное и остросюжетное
      предложение!</p>

    <p>— Слишком много денег?</p>

    <p>— Некуда девать многочисленные
      ресурсы?</p>

    <p>— Годами гнёте спины, а радости
      всё нет?</p>

    <p>— Мы знаем ответ на все ваши
      вопросы!</p>

    <p>— Только сегодня и только у нас —</p>

    <p>— КУПИТЕ СКОПЦА!</p>

    <p>Бахта Рука не выдержал, хмыкнул.</p>

    <p>Гошка устало скривился, как
      кривятся, услышав жужжание мошкары, собравшис’ спат’. У него был пугающе пустой
      взгляд, остановившийся чут’ левее ворот склада, и всё ещё пульсирующая жилка на
      виске.</p>

    <p>Андрей сделал незаметный шажок к Гошке, огляделся раздражённо и будто бы потеряно.
      Его
      прервали на полуслове, он вед’ явно хотел высказат’ся — не то объяснит’, не то,
      наоборот, спросит’.</p>

    <p>Бахта Рука с радост’ю послушал бы
      и объяснения, и вопросы, но…</p>

    <p>— Купите скопца, не пожалеете.</p>

    <p>— А то и двух.</p>

    <p>— Четырёх! Гэбню скопцов!</p>

    <p>— Стоп. Гэбня скопцов — это гэбня <i>из</i> скопцов?</p>

    <p>— Или гэбня <i>над</i> скопцами?</p>

    <p>— Стыдно спрашивать, что такое
      «скопцов».</p>

    <p>— Скопцов — это псевдоним
      уважаемого члена Революционного Комитета.</p>

    <p>— Уважаемого члена по фамилии
      Скворцов.</p>

    <p>— Которая, в
свою очередь, послужила псевдонимом Гошке Петюньевичу.</p>

    <p>— Когда он катался в Столицу к
      тавру-вирусологу.</p>

    <p>— Гошка Петюньевич не очень изобретателен в псевдонимах.</p>

    <p>— Ничего, он пока что только
      младший служащий.</p>

    <p>— Ещё наберётся опыта, куда он
      денется.</p>

    <p>— Может, даже до какой-нибудь
      гэбни дорастёт.</p>

    <p>— Не примите это за угрозу или,
      леший упаси, эротическое предложение но…</p>

    <p>— Гошка Петюньевич, а вы не
      хотели бы в гэбню скопцов?</p>

    <p>Бахта Рука только сейчас осознал,
      что гэбенные мундиры на них застёгнуты, гм, «под Гошку». Гошка мундир носит распахнутым, и даже на самые официальные мероприятия — вроде
      вчерашней встречи гэбен — хот’ и застёгивается, но всё равно не на все
      пуговицы. Получается немного небрежно и крайне располагающе.</p>

    <p>И вед’ это Охрович и
      Краснокаменный явно нарочно — они же целиком состоят из таких мелочей. Долбаные внимательные психи. Чего сегодня к Гошке прицепилис’?</p>

    <p>Сам Гошка сначала вроде и не
      слушал их, но как только услышал, Бахта Рука испугался, что гроза таки
      состоится. А это не нужно, нельзя из своего табельного
      стрелят’ по головам гэбни, пуст’ и временно отстранённым. Бедроградская гэбня и
      по действительным, помнится,
      стреляла, когда со Столицей выясняли, кто правее, — но то другое дело было, а
      сейчас фаланги точно не спустят, загрызут к лешему.</p>

    <p>Наверное, Охровича и
      Краснокаменного спасло то, что их двое, и выбрат’, который
      большее хамло и, соответственно, должен умерет’ раньше, просто невозможно.</p>

    <p>Гошка бесился, закипал на глазах,
      того и гляди выхватит пистолет, Андрей почти уже рванулся
      сигналит’ младшим служащим, чтоб те успели выстрелит’ раньше — лучше уж их
      табельное засветит’, но Охрович и Краснокаменный Андрея от младших служащих
      ловко загородили, пользуяс’ разницей в росте и размахе рук, заголосили
      наперебой:</p>

    <p>— Да что вы такие обидчивые!</p>

    <p>— Нельзя быть обидчивыми.</p>

    <p>— От этого бывает дурной запах
      изо рта.</p>

    <p>— И ройшемордие.</p>

    <p>— Нормальный человек не
      согласится на ройшемордие.</p>

    <p>— Даже с доплатой из скопцов.</p>

    <p>— О нет. Мы только что впервые
      позволили себе контрреволюционное высказывание.</p>

    <p>— Теперь мы умрём в муках.
      Покойный хэр Ройш не прощает.</p>

    <p>— Доставайте, доставайте уже свои
      табельные пистолеты.</p>

    <p>— Лучше уж так, чем страшиться
      мести покойного хэра Ройша.</p>

    <p>— Умрём молодыми и счастливыми.</p>

    <p>— И вас за собой утащим сообразно
      служебным инструкциям.</p>

    <p>— Мы отстранены только до начала
      следующей недели.</p>

    <p>— Поэтому по факту — не отстранены.</p>

    <p>— Если вы нас убьете, наш личный
      Ройш обо всём позаботится.</p>

    <p>— О том, чтобы вам влепили как за убийство действительных голов гэбни.</p>

    <p>— Одного с вами уровня доступа, а
      то и выше.</p>

    <p>— Он же может нас посмертно
      повысить, наш личный Ройш.</p>

    <p>Охрович и Краснокаменный
      заткнулис’, уважительно покосилис’ на кобуру Гошки и вдруг принялис’ за Андрея:</p>

    <p>— Наш личный Ройш.</p>

    <p>— <i>Дмитрий Ройш.</i></p>

    <p>Андрей аж
      вытянулся.</p>

    <p>— Не спешите стрелять. Вас же
      беспокоит, ну беспокоит же Дмитрий Ройш.</p>

    <p>— Готовы
      поспорить, просто-таки по ночам терзает.</p>

    <p>— Приходит в тревожных снах и
      рассказывает страшные байки про совесть и неизбежную месть.</p>

    <p>— Или — того хуже — про то, как
      он ценит то, что вы для него сделали.</p>

    <p>— Вы немало трудились на благо
      спокойствия отечества, не так ли, Андрей Эдмундович?</p>

    <p>— И сохранили крепкий и здоровый
      сон, Андрей Эдмундович.</p>

    <p>— Это очень хорошо, Андрей
      Эдмундович.</p>

    <p>— Здоровье — это важно, это
      превыше всего.</p>

    <p>— Как и крепость.</p>

    <p>— Шестьдесят градусов твиревой
      настойки защищают от всего на свете.</p>

    <p>— Вот только давайте без
      предвзятости к алкоголизму!</p>

    <p>— В нашей гэбне, знаете ли, алкоголизм с недавних
      пор
      практикуют.</p>

    <p>— Ох
      ядрён молочный самогон на пинежской стороне!</p>

    <p>— Не доводилось пробовать?</p>

    <p>— В качестве закуски лучше всего
      идёт художественная литература.</p>

    <p>— Всего один листик, а каков
      эффект!</p>

    <p>— Мы до глубины пищеварительного
      тракта восхищены вашим рецептом.</p>

    <p>— Нет, правда. Ваши новшества
      внесли в наше скучное меню немного разнообразия.</p>

    <p>— И открыли нам глаза НА ВСЁ!</p>

    <p>— На то, что Габриэль Евгеньевич &shy;—
      молочный самогон.</p>

    <p>— Потому что он вдруг взял и
      забродил.</p>

    <p>— Совсем испортился, пришлось
      вылить в унитаз.</p>

    <p>— Но мы набодяжили себе нового, вы же видели только что.</p>

    <p>— Но не молочного,
      мы разочаровались в молочном.</p>

    <p>— Скорее кактусового.</p>

    <p>— С иголочками.</p>

    <p>— Для действительно суровых
      людей, прошедших огонь и воду.</p>

    <p>— Чью глотку, казалось бы, уже
      ничто не возьмёт.</p>

    <p>— Глотка сурового человека Соция
      Всеволодьевича останется довольна.</p>

    <p>— Подожди, не стоит так в лоб
      говорить, что он отсосёт.</p>

    <p>— Наши благодарные слушатели
      могут разнервничаться?</p>

    <p>Слушатели — все трое — потихоньку
      переставали испытыват’ бурную агрессию в адрес Охровича и Краснокаменного.</p>

    <p>Бахта Рука глянул на Гошку и на
      Андрея, отметил у обоих расслабленные плечи, порадовался: в конце концов,
      отчего бы не послушат’ болтовню университетских психов, пока Соций делом занят?
      Ест’ в этой болтовне что-то притягательное.</p>

    <p>Сочетание осмысленности с
      бестолковост’ю, наверно.</p>

    <p>Охрович и Краснокаменный
      выступали очен’ старательно, заглядывали в глаза и что только не скакали вокруг
      трёх голов Бедроградской гэбни хороводом, много двигалис’, отчего размывалис’ в
      одно большое серое пятно. В этом было что-то почти чарующее, отбивающее желание
      двигат’ся и думат’ самим.</p>

    <p>Поговорит’ о
важном — о том, кого они все там увидели в человеке с букетом черёмухи, — можно
и потом.</p>

    <p>— Он, конечно, не Габриэль
      Евгеньевич.</p>

    <p>— С Габриэлем Евгеньевичем никто
      не сравнится!</p>

    <p>— Но новые времена диктуют новые
      потребности.</p>

    <p>— Нам приходится следить за
      модой.</p>

    <p>— Бордельное дело — это не шутки.</p>

    <p>— Бордельная промышленность, мы
      бы даже сказали.</p>

    <p>— Не угодишь вкусу толпы — и оп-па, экономическая блокада.</p>

    <p>— Бедный Порт, прогорели на
      ерунде.</p>

    <p>— Не учли такую мелочь.</p>

    <p>— Разброс революционных
      предпочтений клиентуры в нынешнем сезоне.</p>

    <p>— Состригли с Габриэля
      Евгеньевича его локоны под Веню.</p>

    <p>— И народ не понял и не принял,
      денежки утекли.</p>

    <p>— Бесцветной жидкостью с едва
      заметным лимонным ароматом по трубам.</p>

    <p>— Не путать с мочой.</p>

    <p>— Или понятнее сказать «не путат’»?</p>

    <p>— Таврская экспансия!</p>

    <p>— Пихтские всадники проскачут по
      улицам города!</p>

    <p>— Они разносят чуму. Как крысы и
      чайки. Скоро приедут огнемётчики. Они найдут только трупы. Трупы! Трупы!
      Трупы!! Какие трупы вам больше нравятся? О, мы видим движения ваших рук, вы
      предпочитаете здоровых. Непоеденных чумой. Пихтами и огнемётчиками. Вы
      предпочитаете самообслуживание. Сделай труп сам! Набор: пистолет и гроб в
      комплекте. Для детей младшего отрядского возраста. Для старшего у нас другие
      развлечения. Вы знаете, чем занимаются в старших отрядах? Двигают
      экспериментальную науку! Наука —&nbsp;это такая специфическая форма высшей
      нервной деятельности, характеризующаяся тем, что она гаже даже бордельного
      дела. Думаете, мы в Университете дурака валяем —&nbsp;да мы
бы и рады валять, но они всё время встают, суки, ты их ногами, а они всё равно
встают, попробовали твирь, безрезультатно, не знаем, что делать, дайте совет,
вы же опытные, вы же столько лет, вы же всё сами, вы же знаете лучше нас,
помогите, помогите, помогите, мы устали, избавьте нас от мучений,
      покончим с этим, оставим это, забудем это, столько лет прошло, было и прошло
      кому теперь нужна эта жалкая пустая месть оставьте свои склоки ссоры оставьте
      слышите мирись мирись мирись МИРИСЬ ТЫ СЛЫШИШЬ МЕНЯ
      БЛЯДИНА ДУМАЕШЬ Я НЕ НАЙДУ ТЕБЯ ПАДЛА Я НАЙДУ ТЕБЯ ВСЕ ВСЕХ НАХОДЯТ
      ВОССОЕДИНЯЮТСЯ В ЖАРКИХ ПЫЛКИХ ЧЕРЕЗ ДЕСЯТЬ ЛЕТ И ЧТО ТЕПЕРЬ С ЭТИМ ДЕЛАТЬ
      десять лет назад мальчик александр распускал слухи про бедроградскую гэбню
      через глупых университетских девочек и где он теперь он снова мальчик а глупых
      университетских девочек не сочтёшься <i>о
        где о где они теперь</i> все исчезают
      все умирают все встречаются в посмертии чтобы снова поговорить ПОГОВОРИТЕ С
      НАМИ МЫ СОСКУЧИЛИСЬ НАЛЕЙТЕ КОНЬЯКУ И ПОТАСКАЙТЕ ЗА
      КОСУ но это уже откуда-то не оттуда это какие-то таврские девочки вот сколько
      лет гранты проедаем а до сих пор не знаем бывают ли косы у таврских девочек и
      бывают ли таврские девочки в принципе кассахских вот не бывает даже у габриэля
      евгеньевича мать британка а вы его ВЫ ЧЕМ ДУМАЛИ ЭТО ЖЕ ГРОЗИТ МЕЖДУНАРОДНЫМ
      СКАНДАЛОМ у него мать есть СЛЫШИТЕ пожалели бы убогого
      неказистого естественнорождённого не знающего покоя алхимической печи ЗАЧЕМ
      МЕЛОЧИТЬСЯ надо было сразу взращивать чуму в алхимических печах добавлять в
      генетический материал добропорядочных граждан лет через эдак чума на своих
      ногах гуляет по улицам города не всё же по канализации по говну рыскать пора
      выходить из подземелий ЕДЕТ ВЕРХОМ НА КРЫСЕ за ней ползут
      перекатываются тела живые недоживые завтра умрут у чумы глаза зелёные нет
      зеленоватые серые рыжие были бы голубые нет больше голубых в руках коса КОСА
      КАССАХА страшно не победить нет пистолетов нечем стрелять идёт по улицам города
      идёт идёт а все вокруг лежат всем больно всем страшно
      зачем вы распахнули печь с чумой затворите закройте заприте заебитесь
      опомнитесь вернитесь отзвонитесь нам ЗАПИШИТЕ ТЕЛЕФОННЫЙ НОМЕР ПРОСТОЙ ТЕЛЕФОННЫЙ
      НОМЕР не упустите свой шанс испытайте катарсис купите скопца двух четырёх целую гэбню ЗВОНИТЕ НАМ ПО НОМЕРУ 66563</p>

    <p>&nbsp;</p>

    <p>…Бахта Рука, Андрей и Гошка
      молчали. Ветер разносил по пустырю тяжёлый, приторный, дурманящий запах
      черёмухи, и, конечно, это именно от него в голове было пусто, липко. И,
      конечно, это именно от него хотелос’ просто усест’ся на капот, откинут’ся и
      задремат’.</p>

    <p>Вед’ поговорит’
о важном — о чём бы они там ни собиралис’ поговорит’ — можно и потом.</p>
  </>
}

export default chapter26
