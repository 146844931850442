import React from 'react'
import news from '../../../Data/news'
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import Helmet from "react-helmet";

const ArchiveIndexPage: React.FunctionComponent = () => {
  const groupped = {} as {[key: string]: {
    count: number,
    link: string
  }}

  for (const article of news) {
    const date = dayjs.unix(article.timestamp)
    const value = date.locale('ru').format('MMMM YYYY')
    const link = date.format('YYYYMM')

    if (groupped[value]) {
      groupped[value].count += 1
    } else {
      groupped[value] = {
        link,
        count: 1
      }
    }
  }

  console.log(groupped)

  const archive = Object.keys(groupped)
    .map(key => <li key={key}>
      <Link to={`/archive/${groupped[key].link}`}>{key}</Link> ({groupped[key].count})
    </li>)

  return <>
    <Helmet>
      <title>Архив новостей</title>
    </Helmet>
    <main>
      <article className='ArchiveIndexPage'>
        <h1>Архив новостей</h1>
        <ul>
          {archive}
        </ul>
      </article>
    </main>
    <footer>
      <img src='/images/site_footer.jpg' alt='' style={{
        maxWidth: '100vw'
      }} />
    </footer>
  </>
}

export default ArchiveIndexPage