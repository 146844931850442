import React from 'react'
import { IBookPage } from './index'

const chapter25: IBookPage = {
  header: 'header_d7.jpg',
  title: 'Глава 25. 853',
  prevLink: 'chapter24',
  nextLink: 'chapter26',
  content: <>
    <h2>Медицинская гэбня. Виктор Дарьевич</h2>

    <img alt='' src="/images/25_victor_darievich.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p><i>— Не стрелять, — произнёс Виктор Дарьевич негромко, но его люди
      услышали и подчинились. — Опустить оружие. Пока что это неуместно.</i></p>

    <p><i>Дула послушно уставились в пол.</i></p>

    <p><i>Виктор Дарьевич почувствовал себя глупо и очень хорошо одновременно.
      Гулкие своды заброшенного здания грозным эхом повторяли его размеренные шаги.</i></p>

    <p>&nbsp;</p>

    <p>Виктор Дарьевич открыл глаза, для
      разминки перебрал в уме новейшую редакцию полного перечня психопатий и только
      после этого приподнялся на локте. В купе было уже темно, а за окном — ещё
      темнее.</p>

    <p>Новейшая редакция была хороша как
      минимум тем, что, помимо действительно существенных изменений, Виктор Дарьевич
      внёс туда один категорически абсурдный пункт, появившийся из опечатки одного
      излишне серьёзного ассистента. Виктор Дарьевич дал волю фантазии, доработал
      опечатку, за два вечера сочинил внятное клиническое описание выдуманного
      расстройства и заставил одну маленькую и доверенную лабораторию подвести под
      это дело правдоподобную статистику.</p>

    <p>Вот и пусть теперь европейская
      разведка, постоянно пытающаяся умыкнуть передовые разработки Медицинской гэбни,
      ломает голову над неизвестным недугом. До смерти надоели,
умственно неполноценные. Дёргают, дёргают — а потом Бюро Патентов опять
      вызывает на ковёр, качает головами и просит не сдавать позиций в международной
      гонке за прогрессом. Очень отвлекает.</p>

    <p>Виктор Дарьевич
вздохнул и воровато закурил (у путешествия без своей гэбни определённо есть
преимущества — никто не зудит: «Не кури на голодный
желудок, хоть стакан воды выпей, прос��увшись»).</p>

    <p>До Революции росской медицины почти что не существовало. То есть практикующие
      врачи
      практикующими врачами (правда, тоже те ещё шарлатаны были), но о медицине как
      науке речи не шло. В штейгелевском институте в
      Петерберге наклёвывались какие-то разработки, но там они и оставались. С
      закрытым городом, переполненным иностранцами, дореволюционное правительство не
      слишком считалось.</p>

    <p>Европы такое положение дел более
      чем устраивало: удобно было держать на коротком поводке Росскую Конфедерацию за
      счёт чудовищного разрыва в научно-технической сфере. Революционный Комитет,
      быстро и нагло взявшийся за сокращение этого разрыва, нанёс Европам прямо-таки
      оскорбление. Вот и развели тут разведку.</p>

    <p>Виктор Дарьевич самодовольно
      затянулся поглубже.</p>

    <p>В описание фальшивой психопатии
      он запихнул что-то про манию контроля третьего уровня, хотя терминологически
      вернее было бы написать «третьей степени» (степени мании контроля, бред какой!).
      В Европах шутку не поймут — зато, когда тиснут в какое-нибудь международное
      издание статейку с ворованными материалами о свежем открытии в психиатрии,
      взятом известно откуда, во Всероссийском Соседстве
      порадуются лица третьего уровня, пристально следящие за любой утечкой
      информации из Медкорпуса. Хотя оные утечки — ой не их забота. Но мания
      контроля, мания контроля!</p>

    <p>Докурив, Виктор Дарьевич вслепую
      ткнул останком сигареты в столик и ругнулся. Ему было вполне уютно в простеньком купе за какие-то смешные
деньги, но в тех вагонах, к которым он успел привыкнуть за долгие годы службы в
Медицинской гэбне, пепельницы прикручивались прямо к столикам и проводники
меняли их по первому требованию. В дешёвых купе не курят, вспомнил
      Виктор Дарьевич. Пришлось бросить окурок в стакан и открыть окно, вентиляция-то
      тоже не та.</p>

    <p>Чья-то мания контроля принуждает
      иногда брать билеты в купе без пепельниц.</p>

    <p>Виктор Дарьевич выглянул наружу:
      в проходе, чуть в стороне от дверей его купе, смиренно пялился
      на сменяющие друг друга пейзажи, скажем так, <i>сопровождающий</i>. У Медицинской
      гэбни есть свой, хоть и маленький, штат людей при оружии. Не то чтобы
      надобность в таких людях возникала каждый день, но чем леший не развлекается.</p>

    <p>Прочие сопровождающие, которые не
      несли вахту прямо сейчас, должны были сидеть по своим купе — справа и слева от
      Виктора Дарьевича. Он взял с собой десять человек, хотя его изо всех сил
      убеждали, что нужно больше, но на целую армию Виктор Дарьевич согласиться не
      мог, призрак детских игр в шпионов и без армии
      несколько смущал его.</p>

    <p>Дежурному было велено как можно
      скорее обеспечить крепкий чай, пепельницу и ещё крепкий чай. Судя по
      проносящимся мимо живописным болотам с осторожными вкраплениями какой-то промышленности,
      скоро Бедроград.</p>

    <p>Виктор Дарьевич машинально
      проверил нагрудный карман — бумага была на месте.</p>

    <p>Формально (и с точки зрения
      здравого смысла, кстати) у Медицинской гэбни нет полномочий
задерживать и ограничивать в перемещении и коммуникации кого бы то ни
      было. Неформальная необходимость то и дело вырисовывается: один прикарманит
      ценные образцы, другой вдруг вспомнит, что некоторые опыты на людях бывают
      неприятными, и не захочет иметь к ним отношения, третий решит махнуть в Европы,
      чтоб побыть там носимым на руках ценным
      специалистом из самогó великого и ужасного
      Медкорпуса. Ну и ещё бывают вариации на тему.</p>

    <p>Виктор Дарьевич без оговорок признавал
      только одну разновидность профилактики дезертирства: всех любить, всё давать и
      платить побольше. Он честно старался перед всякой
      встречей со всяким подчинённым пролистать досье, вспомнить, как того звать и
      ради чего тот пашет, — ну и обеспечить необходимую дозу внимания тоже старался
      по мере сил. Не то чтобы Виктор Дарьевич шибко разбирался в людях
      (небесполезный, но какой-то уж больно скучный навык), но зато твёрдо верил, что
      каждому человеку можно сделать хорошо, интересно и удобно — и не будет никаких
      проблем. Нормальные люди к тому же должны сами знать, как сделать им хорошо,
      интересно и удобно (Виктор Дарьевич вот прекрасно знал, что сейчас ему
      требуется крепкий чай, пепельница и ещё крепкий чай). Незнание таких простых
      вещей о себе говорит либо о незрелости и, как следствие, недостаточной
      осознанности действий (это исправимо), либо о том, что кто-то слишком сложная
      натура и потому идёт из Медкорпуса (или из жизни Виктора Дарьевича, что,
      впрочем, давным-давно одно и то же) прямой дорогой к лешему.</p>

    <p>Сложным натурам не место в науке:
      в науке наибольшую ценность имеет свежая голова, неограниченность в средствах и
      методах, исправная техника, сообразительные ассистенты, удобное кресло, крепкий
      чай, пепельница и ещё крепкий чай (почему их несут так долго, кстати?). Тонкие
      душевные переживания имеют в науке нулевую ценность, а в ряде случаев — и вовсе
      отрицательную.</p>

    <p>Ведь именно тонкие душевные
      переживания — наиболее частотная причина утечки кадров из Медкорпуса (или
      других видов вредоносного поведения кадров). Семь из десяти, Медицинская
      гэбня ведёт статистику. Даже листик с перечнем отговорок дефективных
кадров завели (и пометили печатью пятого уровня доступа к информации).</p>

    <p>Вкратце получается что-то вроде:</p>

    <p>— не хочу убивать;</p>

    <p>— не хочу калечить;</p>

    <p>— не хочу держать в тайне (от
      народных масс) открытия;</p>

    <p>— не хочу делиться (с коллегами)
      открытиями;</p>

    <p>— не хочу риска.</p>

    <p>Бывают и более адекватные (те, с
      которыми можно смириться) причины: деньги, самовлюблённость, какая-то неудача —
      либо громкая и позорная, либо тихая и с надеждой сокрыть. Сам Виктор Дарьевич
      всё равно понимал только тех, кто уходит из Медкорпуса, потому что стало
      неинтересно и неудобно. Такое возможно, почему бы и нет — профилактика
      профилактикой, но всем-то не угодишь.</p>

    <p>Вот как раз на случай, когда профилактика
      не помогает, а кадры убегают каким-нибудь вредоносным для Медкорпуса способом,
      и была придумана бумага, согревающая сейчас нагрудный карман Виктора Дарьевича.</p>

    <p>Простейший ход (и в большей
      степени медицинский, нежели бюрократический) — заподозрить сбежавшего и
      укрывшегося от гэбни сотрудника Медкорпуса в потенциальной опасности для
      населения. Заверить печатью пятого уровня доступа директиву об изоляции до
      получения результатов полного клинического обследования и выслать на
      поиски свой небольшой штат людей
      при оружии. Конкретика зависит от специфики работы: от возможной инфекции до
      возможного перенасыщения организма психоактивными
      веществами. Придумывали иногда и совсем весёлые вещи —
      мутировавшие взрослые формы детских заболеваний, зависимость (и тяжёлую
      абстиненцию) от конкретных лабораторных запахов, едва ли не психическую
      дисфункцию в результате длительных рабочих контактов с источником мощных
      радиоволн. Развлекались, короче говоря.</p>

    <p>Сегодняшняя бумага, к большому
      сожалению Виктора Дарьевича, была без особых развлечений: покинувший по
      собственному желанию Медицинский Корпус сотрудник признан латентным носителем
      смертельно опасного вирусного заболевания.</p>

    <p>Переносчиком чумы, если
      по-народному (по-народному всё, что можно подхватить от человека и от непосредственного
      действия чего можно умереть, — чума; потрясающая терминологическая путаница!).</p>

    <p>«Чуму» выбрали, чтобы уж
      наверняка — дело-то серьёзное. Если что, можно и к местной власти обратиться за
      помощью в поимке. Бедроградской гэбне ведь не нужна чума в городе? Но лучше б,
      конечно, своими усилиями добраться до беглеца.</p>

    <p>В общем, «чума», печать, число,
      подписи: Рыжов (Валентин Ананьевич, Инфекционная
      Часть), Камерный (Леонид Леонидович, Хирургическая Часть), Курлаев
      (Бенедикт Ростиславович, Диагностически-прогностическая
      Часть и Общая Терапия) и Подпокровов (Виктор
      Дарьевич, Когнитивная Часть).</p>

    <p>Рыжов, Камерный, Курлаев и Подпокровов пятым
      уровнем доступа повелевают Дмитрию Ройшу вернуться в Медкорпус и полечиться от
      «чумы».</p>

    <p>Потому что скотина он, этот Дмитрий
      Ройш.</p>

    <p>Рыжов сказал: «Бездельник, подармоедствовал и ускакал».</p>

    <p>Камерный сказал: «Нехорошо
      вышло».</p>

    <p>Курлаев
      сказал: «Мы из-за его выкрутасов теперь не отмоемся».</p>

    <p>Виктор же Дарьевич сказал:
      «Скотина», — но подумал, что тут есть, над чем думать.
      С некоторой вероятностью.</p>

    <p>В мае этого года Дмитрий Ройш
      явился в Медкорпус налегке и с предложением честной сделки: документы и
      прикрытие в обмен на работу и ещё раз работу. Виктор Дарьевич
ему работу дал, хотя Рыжов вздыхал из-за очередного самоучки в Инфекционной части
(«к себе в Когнитивную бери хоть тех, кто читать не умеет, но прекрати уже
совать мне людей без минимального медицинского образования»), Камерный качал
головой из-за беспечности Виктора Дарьевича («любишь же ты всяких
перекати-поле»), Курлаев кривился из-за бюрократии
(«опять фальшивые досье, нас и так прижимают изо всех сил»).</p>

    <p>Виктор же Дарьевич счёл бы
      Медицинскую гэбню полными дураками, если б они не
      взяли к себе человека, который (без образования и без всяких там лабораторных
      условий) сварганил когда-то сыворотку, вроде как обеспечивающую иммунитет к
      степной чуме.</p>

    <p>Потому что этот Дмитрий ни разу
      не Ройш, а вовсе даже Смирнов-Задунайский, заключённый с Колошмы, в условиях
      эпидемии назначенный санитаром и погибший в сожжённом изоляторе аж в 76-м году.</p>

    <p>О чём этот Дмитрий сам в лоб и
      заявил, когда пришёл на собеседование к Медицинской
      гэбне.</p>

    <p>На собеседование его протолкнул
      один виктор-дарьевический ревизор, в прошлом — голова
      гэбни Колошмы. Личность просителя тоже он подтвердил. И он же поселил того в собственной
      квартире, пока Медицинская гэбня не разберётся с
      документами, пропиской и, соответственно, жильём.</p>

    <p>Рыжов отмахнулся: «Проблемой
      меньше», — Камерный удивился: «Тёплые же отношения у
      гэбни Колошмы с заключёнными», — Курлаев запараноил: «Вокруг твоего ревизора столько лет фаланги
      ходили, ты рехнулся ему позволять контакты с
      мертвецом?»</p>

    <p>Виктор же Дарьевич подумал: «Они
      издеваются», — когда в июне вызвал Дмитрия Ройша по поводу прописки, а тот
      замахал руками и стал путано объяснять, что, мол, там
      между ним и этим самым ревизором что-то происходит и, мол, хотелось бы, чтоб и
      дальше происходило. Пожалуйста, мол, придержите прописку, вы
же, мол, <i>понимаете</i>, пусть, мол,
ревизор пока думает, что Дмитрию Ройшу всё ещё негде жить.</p>

    <p>Виктор Дарьевич <i>понимать</i> всякое «что-то происходит»
      очень не хотел, поэтому схватился за голову и прогнал Дмитрия Ройша с глаз
      долой и с напутствием: пусть тогда сам приходит, если захочет жильё, —
      документы в порядке, возможность есть.</p>

    <p>За лето Дмитрий Ройш так и не
      пришёл. Зато, когда в сентябре он пропал из Медкорпуса, притащив за собой
      фаланг, Рыжов спросил: «Где его искать-то?», — Камерный вспомнил: «Через
      ревизора», — а Курлаев изошёл на желчь: «Через
      ревизора, через ревизора — отлично придумано: прописки нет, жилья нет, соседей
      нет, ничего нет; пойди докажи теперь, что он сам
      есть!»</p>

    <p>Виктор же Дарьевич промолчал, в
      который раз убедившись, что всякое «между ними что-то происходит» — не к добру.</p>

    <p>В купе осторожно постучались.</p>

    <p>Один из сопровождающих (не тот,
      что дежурил в проходе) принёс чай и пепельницу. Виктор Дарьевич недоумённо
      нахмурился: он просил чай, пепельницу и <i>ещё
        чай</i> — что тут непонятного? Сопровождающий смутился и с достойной похвалы
      расторопностью понёсся за вторым чаем, но из-за всё той же расторопности не оставил Виктору Дарьевичу ни
      первого чая, ни пепельницы — так и утащил на подносе.</p>

    <p>Виктор Дарьевич вздохнул и решил
      в следующий раз поинтересоваться фамилией дефективного.</p>

    <p>&nbsp;</p>

    <p>Виктор Дарьевич признавал право
      на жизнь (по крайней мере, право на немалую зарплату сотрудника Медкорпуса)
      только за двумя типами людей.</p>

    <p>К первому типу относились все те,
      кто способен приносить пользу мозгами: умные, погружённые в своё дело, с
      надписью «мне интересно» на лбу. Ко второму — те, кто способен приносить пользу
      руками: сообразительные, расторопные, умеющие читать надписи на чужих лбах и,
      соответственно, без лишних просьб убегающие делать отчёты, новые анализы и
      новый же чай.</p>

    <p>С прочими вариациями на тему
      якобы разумных существ Виктор Дарьевич предпочитал дел не иметь.</p>

    <p>Вот
разнесчастный Дмитрий Ройш как раз произвёл на Виктора Дарьевича правильное
впечатление: всё рассказывал о том, сколько всего мы ещё не знаем о травах и
традиционной медицине степняков, сколько всего можно было бы сделать, если
учесть и использовать тот опыт, которым степняки не делятся с кем попало, — и
так далее, и так далее, и так далее. Рассказывал, как не воспользовался
      суматохой, чтобы сбежать с Колошмы, когда степная чума только-только вспыхнула,
      — потому что его озарило, в каком направлении стоит работать над лекарством.
      Рассказывал, как, чудом спасшись из изолятора, дошёл до степного поселения и
      убедил местного травника взять его учеником. Рассказывал, как семь лет собирал
      траву, варил настои по местным рецептам и разбирался в тонкостях местных же
      представлений о фармакологии. Рассказывал, что покинул степь по
      одной-единственной причине: производство понаехало, строительство развернулось,
      стали шастать силовики и едва ли не фаланги — а так
      бы, конечно, там и остался.</p>

    <p>Виктору Дарьевичу чрезвычайно
      импонировали все эти россказни, но убедили его не они.</p>

    <p>Убедила его мазь, замедляющая
      рост волос, рецепт которой Дмитрий Ройш вынашивал все свои семь лет в степи, но
      так и не воплотил в отсутствие технических средств — одной травой там не
      обойтись.</p>

    <p>Мазь, замедляющая рост волос.</p>

    <p>Виктор Дарьевич не любил ни
      бриться, ни ходить с бородой. Виктор Дарьевич забывал бриться по две недели и с
      изумлением каждый раз обнаруживал в зеркале почти что
      бородатого Виктора Дарьевича. Эта досадная мелочь отравляла жизнь Виктора
      Дарьевича почище европейской разведки. До неудобств,
      порождаемых манией контроля фаланг, правда, она не дотягивала, но всё равно
      была недалека. Виктор Дарьевич не без оснований полагал, что несчастье, которое
      он испытывал при виде своего как чрезмерно заросшего, так и лысого
      подбородка, каждый раз укорачивало его
      жизнь на пару месяцев. И ведь он даже подумывал озадачить решением этой
      проблемы какую-нибудь гормональную лабораторию, но всё время благополучно
      забывал до следующей операции по сбриванию волосяных покровов.</p>

    <p>А тут — мазь, замедляющая рост
      волос.</p>

    <p>Все новейшие препараты, лечащие
      то, другое и ещё вот то, которые так и норовят украсть у Медкорпуса Европы, —
      чушь по сравнению с этой простой и необходимой разработкой. И до неё (при всей
      её простоте и необходимости) почему-то до сих пор не доходили руки ни у кого,
      кроме этого самого Дмитрия Ройша.</p>

    <p>Виктор Дарьевич рефлекторно потёр
      подбородок: щетина как щетина. <i>Два месяца</i> уже щетина как щетина.</p>

    <p>И пусть этот самый Дмитрий Ройш
      тысячу раз скотина, но Виктор Дарьевич всё равно не жалеет, что взял его в
      Медкорпус.</p>

    <p>Дефективный сопровождающий
      наконец вернулся с чаем, пепельницей и <i>ещё
        чаем</i>. И даже догадался как-то (спросил у кого-то из тех, кто часто катается
      с Медицинской гэбней?) предложить Виктору Дарьевичу
      массаж плечевого пояса. Ну что ж, фамилией пока можно и не интересоваться,
      пусть живёт.</p>

    <p>Из леса на горизонте вынырнуло
      хорошо освещённое шоссе, запетляло поодаль. По шоссе ползла колонна грузовиков
      — если приглядеться, можно даже различить государственную символику и
      схематичные деревья с корнями, симметричными ветвям.</p>

    <p>Скоро, теперь уже совсем скоро
      Бедроград: шоссе — Коннодорожное, самый старый путь в
      Столицу; грузовики — Службы Переворотов, наверняка везут какие-то материалы для
      подготовки к юбилею Первого Большого.</p>

    <p>Виктор Дарьевич залпом выпил один
      чай, удовлетворённо закурил и взялся за второй.</p>

    <p>Когда выяснилось, что Дмитрия
      Ройша следует искать в Бедрограде, вопрос, кому именно за ним ехать, решился
      мгновенно. Виктор Дарьевич родился в Бедрограде, отряд закончил в Бедрограде,
      два года в медицинском училище и ещё сколько-то там на
      медфаке учился тоже в Бедрограде. За бедроградское отделение Медкорпуса со
      всеми сопутствующими учреждениями (вроде экспериментального отряда номер
      одиннадцать) отвечает тоже Виктор Дарьевич. Виктор Дарьевич в Бедрограде лучше
      всех ориентируется, имеет больше всех знакомых, полезнее всех проведёт время,
      если поиски затянутся.</p>

    <p>И тем не
      менее, остальные головы Медицинской гэбни были не слишком счастливы, что за
      Дмитрием Ройшем отправился Виктор Дарьевич.</p>

    <p>Потому что Виктор Дарьевич,
      видите ли, будет слишком мягок с Дмитрием Ройшем.</p>

    <p>Отлучиться всем составом они не
      могли — помимо того, что это в принципе нерационально, <i>прямо сейчас</i> были и другие осложнения. Даже не осложнения, а
      обострения — обострение внимания фаланг к Медкорпусу, например.</p>

    <p>&nbsp;</p>

    <p>Поздним вечером <i>воскресенья</i> к Медицинской
      гэбне заявился фаланга.</p>

    <p>Разумеется, с полным набором
      разрешений на проведение официального расследования на их территории (всё-таки
      согревало то, что ко всем прочим частям госаппарата фаланги заявляются безо
      всяких разрешений, а Медицинская гэбня давно добилась
      отдельной процедуры для вмешательства в свои дела). Так вот заявился,
      значит, фаланга. Рассказать Медицинской гэбне, что у них в
Медкорпусе — в Инфекционной Части — завёлся сотрудник, чьи отчёты противоречат
реальному положению дел.</p>

    <p>«Вот это новость», — хором
      промолчала Медицинская гэбня.</p>

    <p>«Серьёзно противоречат», — сольно
      застращал фаланга.</p>

    <p>Начал
показывать документы: некто Дмитрий Ройш, чуть больше трёх месяцев числящийся стажёром-лаборантом,
чуть меньше трёх месяцев выписывает из хранилищ леший знает что (ну мало ли,
запасливый), встаёт в очереди на пользование леший знает какой аппаратурой (ну
мало ли, любопытный), пытается пробить себе через канцелярию эксперименты на людях
(ну мало ли, садист).</p>

    <p>Медицинская
      гэбня на все эти мелочи жизни только выдохнула в лицо лица третьего уровня
      доступа четыре струйки табачного дыма и спросила, а какой, собственно, лицам
      третьего уровня доступа тут интерес.</p>

    <p>Лицо третьего уровня доступа засияло
      так, будто у него в папочке не материалы расследования, а тонко раскатанный
      лист свежего дерьма.</p>

    <p>Дерьмо и
      было.</p>

    <p><i>В пятницу</i> днём директору некоего столичного детского отряда пришла
      бумага из Инфекционной Части — с указанием закрыться на карантин. Этак с выходных
      этак на десять дней. Директору эти десять
      дней — та ещё катастрофа: двадцатого числа юбилей Первого
      Большого. Он, конечно, под Бедроградом проводится, но Детские Отряды Всей Страны
      Непременно Должны Принять Посильное Участие В
      Праздновании (и далее по скучному тексту диктора радио). А
карантин — это ж никакого юбилея, это ж пустое место с номером отряда на
торжественном общем сборе, это ж Столичная гэбня за несвоевременность
карантинов потом без соли и перца директора сожрёт.</p>

    <p>Директор бросился писать
      слезливые телеграммы, вопрошая, за что ему такое счастье — никто ж из детей
      вроде не болеет ничем.</p>

    <p>Ему ответили: приходите завтра в
      Инфекционную Часть, поговорим.</p>

    <p>Директор оказался подкованный.</p>

    <p>Сообразил, что зовут его не к
      гэбне и даже не в канцелярию Инфекционной Части, а в какую-то комнатку при
      какой-то лаборатории, да ещё и подписываются частным лицом, Дмитрием Ройшем,
      без титулов и бюрократических званий. Ну и побежал жаловаться
      кому следует.</p>

    <p>А кому следует жаловаться, этот
      директор знал потому, что в прошлом году уже имел конфликт с Медкорпусом.</p>

    <p>Не карантин и вообще не
      Инфекционной Части дело, Дмитрию Ройшу о таком услышать было неоткуда: ребёнку
      из этого отряда собирались делать сложную экспериментальную операцию в
      Хирургической Части (прочие методы себя не оправдали), а отец воспротивился в
      последний момент. Решил, что лучше ребёнок гарантированно помрёт через пару
      месяцев, чем прямо сейчас подвергнется процедуре с неизвестным исходом. Бедный
      директор отряда тогда сам себе болячек нажил, занимаясь дипломатией. Медкорпус
      — это ж не врачи районной больницы: если пациент попал в Медкорпус, он сам уже ничего
      решать не может, в Медкорпусе лучше знают, что пациенту нужно, а что не нужно.
      Но отец был крикливый, грозился связями в Европах, где
      и так-то косо смотрят на всероссийскую систему воспитания детей. Ни
      алхимические печи им не по нраву, ни одни отцы в отсутствии матерей, ни отряды,
      где дети якобы оторванными от семьи живут. Европы бы
      эту историю проглотили с большим удовольствием, гвалт бы подняли — вот и
      пришлось директору спешно разбираться, кто может Медкорпусу требования
      предъявлять. Столичная гэбня не может, шестой уровень
      доступа против пятого.</p>

    <p>Кто выше Столичной
      гэбни? Конечно, фаланги.</p>

    <p>Конечно, фаланги с той историей
      разобрались.</p>

    <p>Медицинская гэбня даже не в
      обиде: все эти крики про эксперименты на детях страсть как надоели. Нету, нету
      никаких экспериментов на детях! Есть одиннадцатый
      отряд города Бедрограда, подальше от Медкорпуса, где никто ни о чём не кричит.
      Маленькая, конечно, площадка, на такой всего, что хочется, не сделаешь, но зато
      тихая, мирная — и договорённость с Бедроградской гэбней на сей
счёт была-таки заключена. Родной отряд Виктора Дарьевича, между прочим:
      сироты, отпрыски трудолюбивых специалистов всяких шибко выездных профессий и
      прочие неприкаянные. И все счастливы, и не надо никаких экспериментов
      на детях больше.</p>

    <p>Директор же того отряда, которому
      назначили внезапный карантин, один раз наловчившись решать свои проблемы через
      фаланг, и в этот раз кинулся к ним — если не помогут, так хоть будут
      высокопоставленные свидетели, что отряд не по своей воле косит юбилейные
      мероприятия, а из-за какого-то Дмитрия Ройша из Инфекционной Части Медицинского
      Корпуса.</p>

    <p>Какого-то Дмитрия Ройша.</p>

    <p>Стажёра-лаборанта.</p>

    <p>Не показывавшегося на рабочем месте
      неведомо сколько времени, но назначившего директору
      столичного отряда личную встречу в неофициальной обстановке (в тёмных
      закоулках, где одна сплошная шумная аппаратура и присесть перекурить негде).</p>

    <p>И не явившегося
      на встречу.</p>

    <p>Фаланга это точно знает, он сам
      вместо директора повидаться с Дмитрием Ройшем хотел.</p>

    <p>Исполнительный
      и отзывчивый фаланга, не оставил простого гражданина в беде! А о том, что,
      никакая помощь простому гражданину бы не светила, если б не особая
      привязанность фаланг к Медкорпусу, давайте помолчим.</p>

    <p>Фаланга, изложивший, наконец,
      свой повод для расследования на территории Медицинской гэбни, выглядел
      действительно счастливым человеком.</p>

    <p>Медицинская
гэбня, выслушав счастливого человека фалангу, напомнила ему, что расследование
не расследование, а допрос головам гэбни пятого уровня доступа фаланга
устраивать не может, ибо пятый уровень доступа к вертикали исполнительной
власти не принадлежит, третьему уровню доступа не подчиняется и отвечает за
свои действия перед Бюро Патентов и только перед Бюро Патентов.</p>

    <p>Поэтому шёл бы третий уровень
      доступа расследовать дело Дмитрия Ройша, может, чего полезного нарасследует.</p>

    <p>А Медицинская
      гэбня благодарит его за предоставленные сведения и желает всяческих удач.</p>

    <p>Фаланга скуксился,
      но убрался.</p>

    <p>Ночью <i>с воскресенья на понедельник</i> Медицинская
      гэбня не спала и не работала.</p>

    <p>Ночью с воскресенья на
      понедельник Медицинская гэбня ругалась друг с другом.</p>

    <p>Рыжов говорил: «В Инфекционной
      Части не место всяким проходимцам, это серьёзнейшие вещи, а не игрушки, поймаем
      недоумка — пойдёт на донорские органы». И Рыжов был в
      своём праве. Инфекционная Часть — его, он и так еле согласился пристроить
      Дмитрия Ройша по просьбе Виктора Дарьевича, а получилась такая дрянь.</p>

    <p>Камерный поддакивал: «Он нас
      обманул, использовал в своих каких-то целях, а мы ведь ему помогли — так дела
      не делаются, он должен ответить за свои поступки». И Камерный тоже был в своём
      праве. Если тут кто и будет разбирать Дмитрия Ройша на органы, то именно он,
      кому ещё-то скальпелем махать.</p>

    <p>Курлаев
      истерил: «Он нарочно нас подставил,
этот карантин — бессмыслица, он чего-то хотел добиться от директора отряда, это
какое-то грязное дело, а нам после него фаланг отваживать». И Курлаев — как ни
      прискорбно — был в своём праве. Вся
      бюрократия обычно на нём, ему больше всех впахивать
      придётся, чтоб фаланги отцепились.</p>

    <p>Виктор же Дарьевич, засунув
      чувство вины перед гэбней поглубже и высунув здравый
      смысл повыше, осторожно возражал. Кулаками и скальпелями трясти без толку, бить
      и резать пока некого. А если б и было кого, всё же стоило бы сначала
      разобраться, грязное дело или не грязное.</p>

    <p>Просто так подобные номера не
      выкидывают, тут должно быть объяснение.</p>

    <p>Сначала объяснение, потом
      обвинение.</p>

    <p>Потом, возможно, скальпель.
      Возможно, нет.</p>

    <p>И <i>с утра понедельника</i> Медицинская гэбня сама начала разматывать
      клубок деяний Дмитрия Ройша.</p>

    <p>Во-первых, он пропал.</p>

    <p>Что уже нехорошо: человек с
      одними фальшивыми документами с некоторой вероятностью найдёт способ сделать
      другие фальшивые документы, — и ищи его на здоровье по всей стране.</p>

    <p>Во-вторых, все эти запросы на
      материалы, аппаратуру и эксперименты были вовсе не от него, а от самых разных
      сотрудников Инфекционной Части, коим он по доброте душевной разрешил
      пользоваться своим именем для всяких подотчётных действий.</p>

    <p>Так часто делают: кто свою очередь
      на какую-то редкую аппаратуру (например) израсходовал, тот клянчит у коллег их
      очередь, если им вдруг не горит. Но чтоб так много человек одним и тем же
      именем для прикрытия пользовались — это что-то новенькое.</p>

    <p>В-третьих, он действительно
      пытался пропихнуть карантин тому несчастному отряду.</p>

    <p>Сотрудники Инфекционной Части
      имеют право принимать бюрократические решения в обход своей канцелярии, если вопрос экстренный. Им для этого нужна
      всего-навсего печать любого медицинского ревизора.</p>

    <p>Потому что ревизоры — это быстрый
      способ опосредованной связи с Медицинской гэбней.
      Ревизор получает запрос на карантин, доносит его в обход медленных канцелярий
      прямиком до гэбни, в случае согласия гэбни ставит именную печать и, в общем-то,
      всё. Иногда — разбираются без гэбни даже, когда всё просто, понятно и не грозит
      дополнительными проблемами впоследствии.</p>

    <p>Ревизоры умеют отличать, что
      грозит, а что не грозит.</p>

    <p>Медицинская
      гэбня чрезвычайно гордилась институтом ревизоров в Медкорпусе: сокращение
      бюрократической волокиты посредством специально обученных реактивных
      бюрократов, способных быстро разобраться со спорными моментами и не досаждать
      гэбне глупостями, — это единственный способ и формальности соблюсти, и сил
      впустую не тратить.</p>

    <p>Бюро Патентов вон тоже имеет
      специально обученных реактивных бюрократов. Фаланги называются. Это
      обстоятельство радовало Медицинскую гэбню
      дополнительно, хоть и несколько фрайдистски: как же, свои собственные маленькие
      и безобидные фаланги, которые делают то, что им скажешь!</p>

    <p>Ну, чаще всего делают.</p>

    <p>Ни один ревизор к Медицинской гэбне с карантином Дмитрия Ройша совершенно
      точно не обращался.</p>

    <p>Значит, именная печать поставлена
      по собственному разумению ревизора.</p>

    <p>Оставалось, в общем-то, только
      взглянуть, которого из ревизоров конкретно. Но Виктор Дарьевич не очень-то
      хотел глядеть. Дурные, скажем так, предчувствия одолевали на этот счёт Виктора
      Дарьевича.</p>

    <p>Одолевали и одолели.</p>

    <p>Карантин Дмитрия Ройша подтвердил
      ревизор не Инфекционной Части, а Когнитивной. Так иногда случается, до кого
      первого добежали — тот и разбирается, ничего криминального. Совсем ничего.</p>

    <p>Кроме того, леший, что карантин
      Дмитрия Ройша подтвердил ревизор Когнитивной Части, служивший когда-то давно в
      гэбне Колошмы.</p>

    <p>И поселивший
      когда-то недавно Дмитрия Ройша в собственной квартире.</p>

    <p>&nbsp;</p>

    <p>Виктор Дарьевич допил свой второй
      чай, когда поезд уже замедлился и пополз по ночному Бедрограду.</p>

    <p>Фонарный свет в лужах, редкие
      такси, знакомые и привычные силуэты домов, чернильными тенями проступающие в
      темноте.</p>

    <p>Столица, где
Виктор Дарьевич жил уже много лет подряд, гораздо больше, просторней,
величественней и эффектней: почти все здания — высоченные, некоторые даже выше
десяти этажей, улицы — широкие и прямые, фонари — яркие, освещающие по целому
кварталу; каждая деталь так и кричит о новом государстве, прогрессе, торжестве
технического развития. Но тем, кто вырос в кривом и скособоченном
      Бедрограде, старый осыпающийся кирпич заводских стен как-то ближе столичных
      роскошеств.</p>

    <p>В купе заглянул один из
      сопровождающих, молча и со значением кивнул, увидев, что Виктор Дарьевич и сам
      уже натягивает плащ.</p>

    <p>От этого «молча и со значением»
      Виктора Дарьевича подташнивало: весь день прошёл молча и со значением, Виктор
      Дарьевич же отправился ловить нарушителя спокойствия Медкорпуса!</p>

    <p>Вместе с десятью сопровождающими
      при оружии, которых не учат (где их там вообще учат, в Институте госслужбы?)
      понимать с первого раза, что такое «чай, пепельница и ещё чай», зато учат делать
      всё молча и со значением.</p>

    <p><i>Вообще всё</i>.</p>

    <p>Виктор Дарьевич с ужасом подумал,
      что не желает представлять, как эти люди спят или, например, посещают душ и сортир.
      Или, например, сношаются.
      Едят. Слушают радио. Ищут, как бы прикурить, когда барахлит
      зажигалка. Зашнуровывают ботинки. Выбирают носки в магазине.</p>

    <p>От такого градуса молчаливости и
      значительности должно сводить мышцы лица.</p>

    <p>Тяжело быть человеком при оружии!</p>

    <p>Виктор Дарьевич одарил тоскливым
      взглядом портфель, куда был убран табельный пистолет (всё равно не умел
      пользоваться) и молчаливо (и со значением, куда деваться) приладил на голове
      конспиративную шляпу.</p>

    <p>Про шляпу и думать стыдно, а
      пистолет Виктору Дарьевичу точно не пригодится. Всё равно же у него полные
      карманы подходящих экспериментальных медикаментов: паралитиков, галлюциногенов,
      универсальных аллергенов.</p>

    <p>Сделаны (но пока недостаточно
      протестированы) по заказу оперативного отдела при
      Столичной гэбне: максимально просты в использовании, вводятся с одного укола, с
      одной царапины даже. Или есть вот, например, совсем новый препарат, у которого
      только и надо что капсулу раздавить на расстоянии не менее метра от лица — а
      дальше подопытный сам вдохнёт, и будут перед ним зелёные лешие по потолку
      скакать. Правда, у препарата два неизбежных минуса: слишком резкий цветочный
      запах (латиноамериканские лианы все как одна пахучи, зато смутно напоминают
      кое-что из наших деревьев) и необходимость хотя бы ватно-марлевую повязку
      надевать, иначе не только у подопытного зелёные лешие
      поскачут. Но всё равно хорошая штука.</p>

    <p>А сопровождающие навязали Виктору
      Дарьевичу пистолет. Вроде как — положено.</p>

    <p>Но уколы-то Виктор Дарьевич всяко лучше делает, чем стреляет!</p>

    <p>Глупость ситуации продолжала
      смущать Виктора Дарьевича.</p>

    <p>Интересно, как те гэбни, в прямые
      обязанности которых входят все эти поиски, расследования, поимки, дознания,
      опознания, допросы, не умирают прямо на службе от стыда за глупость ситуаций?</p>

    <p>Дмитрий Ройш, положим, страшный
      человек, ведущий свою непонятную игру, — но шляпу, шляпу-то зачем?</p>

    <p>Ещё перед выездом из Столицы
      группа сопровождения приволокла Виктору Дарьевичу невзрачный плащ и эту
      несчастную шляпу, посоветовала побриться (ещё чего, даром, что ли, брал Дмитрия
      Ройша в Медкорпус?) и купить билеты в вагон попроще.</p>

    <p>С вагоном попроще
      проблем у Виктора Дарьевича было меньше всего: дорогие купе — это, конечно,
      удобно, там пепельницы есть, но совершенно ведь необязательно. Как и большие
      деньги в принципе.</p>

    <p>Деньги нужны на Медкорпус (на
      аппаратуру, материалы, зарплаты), а всё остальное — мелочи. Приятные и
      бестолковые.</p>

    <p>&nbsp;</p>

    <p>Виктор Дарьевич видел людей,
      которые работают за деньги и ради денег, и очень им удивлялся.</p>

    <p>Он,&nbsp;конечно,
любил хорошие сигареты и хороший чай (и то, и другое — индокитайский сбор,
обязательно июньский, из молодых верхних листьев, импорт, редкий товар), но
прекрасно помнил, как обходиться без излишеств.</p>

    <p>Сразу после отряда Виктор
      Дарьевич пошёл в училище (в любой институт или университет всё равно можно
      только через два года, с семнадцати). Какие-то средства от государства получают
      все, даже те, кто не работает и не учится (а до Революции, говорят, часто шли
      учиться за стипендию и жильё). И средств этих вполне достаточно для жизни, но
      Виктора Дарьевича уже тогда интересовала не жизнь, а наука.</p>

    <p>Например, интересно добыть
      дорогостоящих препаратов (часто наркотических, а то и просто наркоты), накачать
      кого-нибудь из приятелей и следить за изменениями как физиологического, так и
      психического характера.</p>

    <p>После училища Виктор Дарьевич
      пошёл санитаром в больницу и студентом не-первого курса на медфак (выбил себе
      как-то экзамены), и запросы его более чем возросли.</p>

    <p>Он столько
всего заказывал из-за границы через знающих людей, что питался раз в два дня и
у сокурсников, одевался с чужого плеча, разрешал себе выкурить сигарету
целиком, не экономя, только после того, как закончит опыт или статью, — и
вообще сидел по уши в фантастических каких-то долгах, с которыми вовсе не догадывался,
как потом развязаться.</p>

    <p>До тех пор, пока за полгода до
      получения медфаковского диплома не стал вдруг головой Медицинской гэбни.</p>

    <p>Его
студенческие работы подкинул тогдашней Медицинской гэбне (без Рыжова, Камерного
и Курлаева — даже вспоминать странно) один из преподавателей,
когда-то работавший в Когнитивной Части.</p>

    <p>Просто так, потому что счёл
      уместным.</p>

    <p>А тогдашняя Медицинская гэбня
      (без Рыжова, Камерного и Курлаева, которые
      бы упёрлись рогами и никогда бы не пошли на такое; Рыжов и Камерный когда-то <i>уже</i> не пошли) сочла уместным через
      некоторое время предложить мальчику без диплома пятый уровень доступа.</p>

    <p>У них был аврал, четвёртый голова
      вздумал в преклонном возрасте протестировать новые методы электросудорожной
      терапии на себе, и результат оказался неудовлетворительным (не методы и были),
      но всё же шаг со стороны гэбни получился неожиданный. Никто такого и вообразить
      не мог — особенно мальчик без диплома, который вообще-то ничего не воображал, а
      просто хотел увидеть-таки своими глазами Медкорпус, куда его вдруг пригласили
      скататься на выходные.</p>

    <p>Мальчик без диплома приехал в
      Столицу самым дешёвым поездом, спал сидя, настрелял полпачки сигарет на
      вокзале, а до Медкорпуса шёл пешком, чтоб потом были деньги пообедать в чужом
      городе, где не к кому завернуть в г��сти. И это было скорее забавно, нежели
      неудобно.</p>

    <p>Обедал и ужинал он, правда, тогда
      с Медицинской гэбней, а вот в Бедроград возвращался с
      приключениями: на обратный билет не хватало, пришлось просить мелочь у людей на
      вокзале.</p>

    <p>А какой-то рьяный младший
      служащий, к которому он случайно сунулся, достал свой жетон двенадцатого уровня
      и захотел поговорить с попрошайкой в следственном отделении при
      Столичной гэбне. Когда при досмотре обнаружился новенький и блестящий жетон
      пятого уровня доступа, следственного отделения было уже не избежать: кто б
      поверил мальчику без диплома и денег на билет, что жетон настоящий?</p>

    <p>Разобравшись, что таки да, настоящий, Столичная гэбня явилась в следственное
      отделение
      сама, всем составом.</p>

    <p>Приносить
извинения за доставленные неудобства лицу более высокого уровня доступа (у них
тоже не хватило воображения представить, что Медицинская гэбня имеет право
выдвигать свои кандидатуры при смене состава, что Медицинская гэбня захочет на
место древнего именитого профессора мальчика без диплома и что Медицинская
гэбня за два дня умудрится оформить бумаги, пока мальчик без диплома гуляет с
экскурсией по Медкорпусу).</p>

    <p>Мальчик без диплома вполуха выслушал ненужные ему извинения и попросил у
      Столичной гэбни добавить нужной ему мелочи на билет в Бедроград.</p>

    <p>После истории с мелочью
      финансовые трудности в жизни Виктора Дарьевича закончились и до сих пор не
      собирались начинаться, но страстной любовью к деньгам он так и не проникся.</p>

    <p>Выяснил, правда, что человеку с
      мозгами сподручнее работать, не ограничивая себя в
      средствах, а человеку без мозгов никакие средства мозгов не заменят. Поэтому
      надо следить, чтоб при мозгах всегда были средства, иначе на кой средства
      вообще сдались?</p>

    <p>&nbsp;</p>

    <p>Поезд остановился, в приоткрытое
      окно ворвался скромный по вокзальным меркам шум — ночь всё-таки, хоть и с
      пятницы на субботу.</p>

    <p>Подхватив портфель, в котором
      покоился табельный пистолет, и проверив карманы с
      новейшими медикаментозными решениями любых проблем, Виктор Дарьевич вышел из
      купе, нахмурился на дежурного сопровождающего, чтобы тот собрал уже всех
      остальных и можно было спешить к Дмитрию Ройшу. А то ждут непонятно чего, не
      хотят выбираться вместе с остальными пассажирами. Наверное, это тоже
      конспирация. Но времени-то на неё нет, состав и так задержался на подъезде к
      городу. «Юбилей Первого Большого Переворота создаёт
      определённые трудности», — пожимал плечами проводник.</p>

    <p>Определённые трудности были
      некстати.</p>

    <p>Шанс прямо этой ночью увидеться с
      Дмитрием Ройшем не давал покоя Виктору Дарьевичу. Упустить его из-за накладок
      со временем было бы обидно.</p>

    <p>Дежурный сопровождающий явно
      собирался возразить поспешности, но Виктор Дарьевич уже смекнул,
      как доносить до сведения людей при оружии своё мнение.</p>

    <p>Молча и со значением, конечно.</p>

    <p>Ну или
      просто со значением (Виктор Дарьевич надвинул конспиративную шляпу и выдал
      что-то вроде: «Быстро. Бегом, я сказал!»).</p>

    <p>Что самое забавное, люди при
      оружии действительно стартовали бегом.</p>

    <p>Виктор Дарьевич хмыкнул и
      поправился: «Быстрым шагом».</p>

    <p>Люди при оружии подчинились.</p>

    <p>Глупо, но как-то почти приятно.
      Через минуту посреди сырой, пахнущей обычной бедроградской погодой платформы
      Виктор Дарьевич тихо скомандовал: «Смирно», — и с восторгом закурил, стоя в
      окружении остолбеневших людей при оружии. Наверное, это было не слишком
      конспиративно, но Виктор Дарьевич не удержался. Если у тебя есть десять
      странных типов, которые так беспрекословно подчиняются любой ерунде, которую ты
      ляпнешь, не воспользоваться ситуацией невозможно.</p>

    <p>Это Виктор Дарьевич ещё не стал
      отдавать приказ «догнать женщину в голубом и
      наговорить ей комплиментов, а потом пасть ниц и завыть» или там «немедленно
      изыскать способ покормить вот тут толстую чайку», а мог бы.</p>

    <p>Стоп, чайка.</p>

    <p>Чайка.</p>

    <p>Виктор Дарьевич прищурился:
      чайка, чайка. Как так?</p>

    <p>Сразу после Революции было
      принято решение по возможности избавить крупные города от дополнительных
      источников потенциальных эпидемий. Антисанитария, доставшаяся новому
      государству в наследство от Росской Конфедерации, поддавалась с трудом, но
      поддавалась. Бездомные кошки и собаки исчезли с улиц вместе с мусором, который
      начали собирать и обрабатывать принципиально иными методами. Когда методы были
      усовершенствованы, из городов пропали грызуны и птицы (специалисты из Европ до
      сих пор не верят, что такое вообще возможно, но действительно же пропали). Так
      можно легко отличить, где кончается цивилизация и начинается область, ызды и
      всякие там селенья, если дорожных знаков и
      лесов-полей почему-то недостаточно: за городом поют птицы, в городе нет. В
      парковых зонах ещё встречается какая-то живность, но чтоб вот прямо так, чайка
      на вокзале?</p>

    <p>Виктор Дарьевич едва удержался,
      чтоб не протереть глаза.</p>

    <p>Впрочем, он о чём-то подобном от
      кого-то слышал.</p>

    <p>Давно, когда был студентом и
      экономил сигареты, докуривая до половины и растягивая на два раза, кроме
      исключительных случаев. Но слышал, слышал. От кого-то из преподавателей или
      соседей слышал.</p>

    <p>В пятидесятых
      был крупный пожар в Порту, крысы и чайки ринулись в близлежащие районы, но
      вернулись сразу, как огонь был сбит. В Порту до сих пор живут себе преспокойно
      и крысы, и чайки (возможно, и кто-то ещё), в Порту совсем другие условия,
      городские санитарные меры на Порт не распространяются, там всё совершенно
      по-другому с отходами — и крысам, и чайкам есть чем питаться.</p>

    <p>Но на вокзале-то нечем.</p>

    <p>Как туда занесло чайку, Виктор
      Дарьевич не понимал, но всю дорогу до места встречи с Дмитрием Ройшем
      инстинктивно оборачивался в сторону Порта. То, что над ним не стоял дым, ещё
      ничего не значило.</p>

    <p>В общем-то, пожар — не
      единственная возможная причина для бегства чаек и крыс.</p>

    <p>&nbsp;</p>

    <p>От вокзала идти было недалеко,
      тем более что Виктор Дарьевич напряг память и сообразил, что за адрес отправил
      телеграммой Дмитрий Ройш.</p>

    <p>Заброшенных и пустующих зданий в
      Бедрограде совсем мало, это в некотором роде нонсенс, исключение из правил
      ведения городского хозяйства — не эксплуатировать недвижимость. В тесном
      проулке за вокзалом прятался один такой нонсенс: дореволюционный особнячок,
      который так и не был переоборудован подо что-нибудь подходящее. Заградительными
      лентами его обвесили ещё до того, как Виктор Дарьевич начал опаздывать к отбою
      в отряд, блуждая по Старому городу. Почему по сей день ничего
не изменилось — загадка.</p>

    <p>Виктор-дарьевическим
      людям при оружии (<i>его людям</i>,
      проникаться значительностью — так проникаться!) тесный проулок и пустующий
      особнячок не понравились: слишком тихое место, ночью и вовсе безлюдно, а ну как
      Дмитрий Ройш выкинет что-нибудь этакое.</p>

    <p>Виктор Дарьевич без лишних
      сомнений пригнулся, скользнул за заградительную ленту, подавая пример своим
      людям: нечего тут думать, пришли — надо действовать.</p>

    <p>Зря, что ли, из Столицы явились.</p>

    <p>Ступеньки крыльца давно
      облупились, рассыпáлись
      каменной пылью прямо под ногами. Трое из его людей остались снаружи, начали
      круговой обход территории, остальные же последовали за Виктором Дарьевичем в
      сырой мрак, открывшийся за тяжёлой, заедающей дверью. Достали фонари
      (интересно, у них на любой случай при себе обмундирование?), зашарили яркими
      лучами по полу. На полу обнаружились следы жизнедеятельности любителей
      отдохнуть в благости и покое неиспользуемых развалин. Вот чего сейчас не
      хватало — так это в процессе переговоров застрелить какого-нибудь нетрезвого
      неудачника старшеотрядского возраста. Разбирайся
      потом с Бедроградской гэбней.</p>

    <p>Виктор Дарьевич пресёк все
      попытки не пускать его в тёмные закутки одного — потому что безопасность
      безопасностью, но кто тут из присутствующих лицо наиболее высокого уровня
      доступа? И кто тут, по правде сказать, реже всех шныряет по тёмным закуткам в
      силу специфики работы.</p>

    <p>Надо же воспользоваться
      представившейся возможностью в полной мере!</p>

    <p>Дмитрий Ройш мог прятаться где
      угодно. Особняк невелик, но при желании есть где
      укрыться. Виктор Дарьевич поразмыслил и пришёл к выводу, что молча рыскать с
      пистолетами и фонариками — лучший способ спугнуть добычу. Уйдёт ещё через
      какое-нибудь незаколоченное окно — и что потом
      делать?</p>

    <p>Виктор Дарьевич остановился посреди помещения, которое
      когда-то, вероятно, служило холлом, и, наплевав на безопасность и инструкции
      своих людей, спросил у пустоты:</p>

    <p>— Дмитрий? Дмитрий Ройш?</p>

    <p>Пустота ответила не сразу,
      подумала немного, но потом в дальнем углу что-то заскрипело (люди Виктора
      Дарьевича напряглись, мгновенно перестроились), и от стены отделилась тёмная
      фигура:</p>

    <p>—&nbsp;Либо мои органы восприятия
      окончательно свихнулись, либо вы всё-таки не Сепгей Борисович.</p>

    <p>Сепгей Борисович, мда.</p>

    <p>Ревизор Когнитивной Части,
      давнишний голова гэбни Колошмы.</p>

    <p>Мда, мда.</p>

    <p>Виктор Дарьевич предпочёл чуток
      потянуть время:</p>

    <p>— А вы всё-таки не Дмитрий Ройш.
      Так что мы в некотором роде в расчёте.</p>

    <p>По фигуре нагло прошлись
      фонарики, хотя Виктор Дарьевич не отдавал такого приказа.</p>

    <p>Вот дефективные, а.</p>

    <p>Виктор Дарьевич своим «не Дмитрий Ройш» совсем не то имел в виду.</p>

    <p>— Сочтём комическим
      недоразумением и разойдёмся друзьями? — ехидно предложил Дмитрий Ройш,
      являющийся Ройшем только по документам, сделанным для него Медицинской гэбней.
      Нахмурился, пометался взглядом по фонарикам (считает?), но с места всё же не
      сдвинулся.</p>

    <p>— Я бы не торопился расходиться,
      — миролюбиво возразил Виктор Дарьевич. — Нам есть о чём побеседовать, не
      находите?</p>

    <p>— Угу, — Дмитрий Ройш ещё раз с
      подозрением оглядел Виктора Дарьевича, — скажем, о том, куда затерялся в пути
      Сепгей Борисович.</p>

    <p>Ещё раз мда.</p>

    <p>Это скользкий момент, с него не
      стоило бы начинать дипломатические переговоры.</p>

    <p>— Скажем, о том, <i>почему</i> он вынужден был затеряться, —
      Виктор Дарьевич поморщился, очень не желая останавливаться на скользких
      моментах. — Негоже сотрудникам Медкорпуса исчезать в неизвестном направлении по
      неизвестным причинам. Один уже пропал, не отпускать же было второго.</p>

    <p>— Так он всё-таки порывался
      исчезнуть? А то стороннему человеку может показаться, — Дмитрий Ройш
      демонстративно присмотрелся к людям при фонариках и оружии, — что вся эта
      история с нервным срывом была мистификацией, необходимой для достижения неких
      не очень честных и благородных целей.</p>

    <p>— Поймите, — вздохнул Виктор
      Дарьевич, — меньше всего мне бы хотелось, чтобы эта встреча была омрачена
      неуместным давлением. Неуместным <i>силовым
        давлением,</i> — подумав, прибавил он. — Но поймите также, что в моих интересах
      в первую очередь задать вам свои вопросы, а потом уже отвечать на ваши. И давайте не будем о честности и
благородстве моих целей, это как-то совсем нелепо звучит после всего, что я
выслушал от других голов Медицинской гэбни <i>о
ваших</i> честности и благородстве. Это в принципе не те термины, в
      которых стоит вести деловую беседу. Лучше просто побыстрее
      разобраться, зачем вы устроили бардак в Медкорпусе.</p>

    <p>И как же Виктор Дарьевич не любил
      (и не умел) вести деловые беседы в таком тоне, а.</p>

    <p>— А то что? Попросите своих
      верных друзей расстрелять меня на месте? — хмыкнул Дмитрий Ройш. — Совет на
      предмет деловых терминов: никогда не давайте собеседнику уверовать в то, что
      вам от него надо больше, чем ему от вас.</p>

    <p>Виктор Дарьевич не любил и не
      умел вести что бы то ни было в таком тоне, но его уже
      понесло:</p>

    <p>— Зачем же расстрелять? Это трата
      ресурсов. Непонятливым собеседникам всегда найдётся какое-нибудь другое
      применение в Медкорпусе.</p>

    <p>— Вы восьмимильными шагами
      повышаете свои шансы что-нибудь от меня услышать.</p>

    <p>Дмитрий Ройш говорил спокойно и с
      издёвкой, выглядел расслабленно — даже полез в карман
      за сигаретами.</p>

    <p>То есть, гм, это Виктор Дарьевич
      подумал, что за сигаретами, а его люди, видимо, подумали
леший знает что, потому что стоило Дмитрию Ройшу запустить руку под
      небрежно наброшенный на плечи бордовый плащ, как семь рук направили на него
      семь пистолетов.</p>

    <p>Виктор Дарьевич был очень, очень
      недоволен своими людьми.</p>

    <p>Дмитрий Ройш на мгновение замер,
      оценил диспозицию и медленно, но уверенно продолжил движение. Движение, само
      собой, продолжилось извлечением сигарет и закуриванием.</p>

    <p>Виктор Дарьевич продолжил быть
      недовольным своими людьми со всеми на то основаниями.</p>

    <p>— Знаете, — Дмитрий Ройш выпустил
      струю дыма, хорошо, даже театрально как-то заметную в свете лучей фонариков, — в
      сомнительных латиноамериканских радиопостановках обычно гадкий и нехороший
      представитель органов правопорядка, взяв доброго сердцем главного героя под
      арест, говорит ему, мол, вперёд, попробуй сопротивляться, спровоцируй меня, дай
      мне только повод. С учётом темпа жизни и содержания последних дней я могу сказать
      вам следующее: вперёд, дайте мне повод дать вам повод.</p>

    <p>Один из людей Виктора Дарьевича
      вопросительно повернул на него голову — видимо, ждал указаний. Чтобы наставлять
      пистолеты, им указания, значит, не нужны, а чтобы разобраться с последствиями,
      значит, нужны. Всё-таки дефективные какие-то —</p>

    <p>Последнюю мысль Виктор Дарьевич
      додумать не успел: ему в лицо ударил луч света, а когда рябь ушла из глаз,
      рядом с Дмитрием Ройшем уже нарисовались два человека в тельняшках.</p>

    <p>Верхней одежды у них не имелось,
      а, следовательно, разглядеть тельняшки и осознать
невербальное сообщение было совсем просто даже в темноте, даже после
      ослепления фонариком.</p>

    <p>Портовые головорезы.</p>

    <p>Один, видимо, тавр (коса на плече
      болтается), а второй встал прямо перед Дмитрием Ройшем, закрыл его от
      пистолетов. Личная охрана из Порта?</p>

    <p>— Слышь,
      мужик, — обратился, видимо, к Виктору Дарьевичу второй, который не тавр. — У
      тебя стволов сколько? Тут семь, на улице ещё кто-то колобродит.
      С десяток наберётся? А то чужие стволы лучше сразу помногу сбывать.</p>

    <p>Видимо, это была угроза.</p>

    <p>Люди Виктора Дарьевича начали
      потихоньку озираться. Видимо, прикидывали, где могут прятаться ещё какие-нибудь
      головорезы.</p>

    <p>Виктору Дарьевичу было достаточно
      и этих двух — он вдруг сообразил, что заигрался. Не затем ехал в Бедроград,
      чтобы бойню устраивать.</p>

    <p>— Не стрелять, — произнёс Виктор
      Дарьевич негромко, но его люди услышали и подчинились. — Опустить оружие. Пока
      что это неуместно.</p>

    <p>Дула послушно уставились в пол.</p>

    <p>Виктор Дарьевич почувствовал себя
      глупо и очень хорошо одновременно. Гулкие своды заброшенного здания грозным
      эхом повторяли его размеренные шаги.</p>

    <p>Он неспешно сократил расстояние
      до головореза, укрывшего Дмитрий Ройша, остановился в
      метре от него под неодобрительное покашливание кого-то из своих людей и ответил
      на заданный вопрос:</p>

    <p>— Стволов у меня немного, и я бы
      предпочёл не демонстрировать их вовсе. Я хочу просто поговорить с Дмитрием
      Ройшем. <i>Поговорить, </i>а не повязать,
      покарать или призвать к ответственности. Мне интересно прояснить некоторые
      детали его пребывания в Медкорпусе, вот и всё, — Виктор Дарьевич протянул головорезу, укрывшему Дмитрия Ройша, руку. — Подпокровов Виктор Дарьевич, Медицинская
      гэбня.</p>

    <p>Головорез
      усмехнулся, но руку пожал.</p>

    <p>Он был загорелый и с серьгой в
      ухе — и если бы всё это происходило не на самом деле, а в книжке или
      радиопостановке (сомнительной латиноамериканской), Виктор Дарьевич счёл бы загар
      и серьгу в ухе слишком уж стереотипными деталями для создания образа портового головореза.</p>

    <p>— Виктор как-тебя-там, —
      обратился к нему головорез, — хуйня получается. Не
      тебя с твоими стволами тут ждали, сечёшь? И пока объяснений нет, а стволы есть,
      никого не ебёт, чё тебе там интересно. Соображай
      давай, как положение поправить.</p>

    <p>— Подсказываю ещё раз: Сепгей Борисович, —
      вышел из-за спины головореза Дмитрий Ройш.</p>

    <p>— Ну что ж, — покладисто кивнул
      Виктор Дарьевич, который ехал в Бедроград не затем, чтобы устраивать бойню, и
      очень старался держать это в голове, — Сепгей Борисович естественным образом
      оказался первым, к кому мы, Медицинская гэбня,
      вынуждены были обратиться по вопросу исчезновения Дмитрия Ройша. Сепгей
      Борисович очень хотел донести до нас мысль, что с Дмитрием Ройшем его связывали
      сугубо личные отношения, но мы не поверили. Использование именной ревизорской
      печати Сепгея Борисовича и ещё некоторые детали чисто бюрократического
      характера, которые едва ли смог бы учесть человек, никогда не служивший
      ревизором в Медкорпусе, навели нас на мысль, что Сепгей Борисович должен был
      оказывать какое-то содействие Дмитрию Ройшу в его таинственных делах. И мы, в общем-то,
      наудачу отправили от имени Сепгея Борисовича телеграмму в Бедроград. С учётом
      того, что по следам Дмитрия Ройша в Медкорпус прибежали фаланги, с которыми у
      Сепгея Борисовича связаны не лучшие воспоминания, правдоподобное содержание
      телеграммы сочинить было нетрудно.</p>

    <p>Виктор Дарьевич тоже закурил.</p>

    <p>Что-то там про нервный срыв было
      в этой телеграмме.</p>

    <p>«Правдоподобное содержание».</p>

    <p>Особенно правдоподобное с учётом
      того, что нервный срыв они Сепгею Борисовичу таки организовали — как побочный
      эффект медикаментозных методов дознания. Надо же было разобраться, что творил
      Дмитрий Ройш. Рыжов, Камерный и Курлаев рвали и
      метали, вот Виктору Дарьевичу и пришлось наскрести по хранилищам Когнитивной
      Части реальные аналоги сыворотки правды.</p>

    <p>Только это в научно-фантастических
      выдумках эффективность подобных препаратов высока, а на деле всего и удаётся
      выяснить, что да, что-то там знает Сепгей Борисович про Дмитрия Ройша, немало
      даже знает, но говорить не хочет.</p>

    <p>Единственное, что он им выдал (и
      ох как неприятен был процесс выдачи), — это Бедроград. Точнее, Бедроград и то,
      что никаких «сугубо личных отношений» не было, а вроде как хотелось. Но последнее
      было полезно только тем, что наглядно продемонстрировало: никогда нельзя
      слушать муру про сугубо личные отношения, надо копать
      глубже.</p>

    <p>Глубже не вышло — Сепгей
      Борисович пришёл во временную негодность, слишком уж не хотел выдавать тайны
      Дмитрия Ройша.</p>

    <p>Но вот об этом лучше пока
      помолчать, наверно.</p>

    <p>— Телеграмму, значит, написали
      вы. А Сепгей Борисович, значит, живёт себе, припеваючи новые печальные песни, и
      ходит на очередные допросы к фалангам. Безо всяких нервных срывов и желания
      навестить старых приятелей. Так, ага?</p>

    <p>— Не в наших интересах часто
      отпускать его на очередные допросы к фалангам, — увильнул Виктор Дарьевич и даже
      не соврал.</p>

    <p>Действительно, совершенно не в их
      интересах.</p>

    <p>— Когда вы меня о чём-нибудь
      спросите, я вам так же содержательно отвечать буду, — съязвил Дмитрий Ройш,
      раздражённо запустил окурок в темноту и уставился куда-то в стену. — Как же всё
      заебало, сил просто нет! Опять какие-то разговоры о важном — жив ли там кто-то,
      здоров ли, чего хочет, чего может —&nbsp;и опять нет никакого способа понять,
      правду вы мне говорите, неправду, не купил ли вас кто, нет ли у вас ещё
      какого-нибудь тайного интереса… тьфу. Где вы, старые добрые времена, когда
      правильный ответ неизбежно находился в чьей-нибудь шпаргалке, —&nbsp;с каждым
      словом он выглядел всё более взвинченным, и Виктору Дарьевичу это не нравилось.
      — Хотите со мной нормально поговорить — так говорите со мной <i>нормально</i>,
      леший вас дери!</p>

    <p>Наверное, это было неправильно,
      неумно и вообще так не ведут деловые беседы, но Виктор Дарьевич, как уже сто
      раз сам себе признался, деловых бесед вести не любил и не умел, а потому таки
      сказал:</p>

    <p>— Мы его пытали, — хотел на том и закончить, но, посмотрев на Дмитрия Ройша,
      продолжил:
      — Ну не совсем пытали, конечно, но ресурсы Когнитивной Части позволяют
      принудить человека к большей откровенности, чем ему самому хотелось бы. Ничего
      смертельного, но пока что ему лучше в Когнитивной Части и оставаться. И как бы
      вам сказать, — Виктор Дарьевич призадумался, — я не буду говорить, что мы
      ничего такого не хотели, что это случайно вышло и так далее. Но в определённом
      смысле мы <i>такого</i> и
      правда не хотели. Мы хотели получить от него хоть какие-то сведения о том, что
      произошло в Медкорпусе. Знаете, нас это волнует: фаланги, расследования,
      разбирательства — все шишки нам, а мы ещё и не в курсе, за что. А Сепгей
      Борисович вместо того, чтоб дать нам любую, минимальную самую зацепку, упёрся и
      молчал до тех пор, пока нервная система не затрещала. Увлеклись, — пожал
      плечами Виктор Дарьевич. — Но выбора-то не было, вы его уж больно надёжно
      завербовали.</p>

    <p>Дмитрий Ройш молчал.</p>

    <p>Головорез
      с серьгой, всё ещё недвусмысленно изображавший личную охрану Дмитрия Ройша,
      потянулся к пачке Виктора Дарьевича, самопроизвольно угостился импортной
      сигаретой — индокитайский сбор, июньский, молодые верхние листья. Виктор
      Дарьевич как можно дружелюбнее протянул ему следом и зажигалку тоже: дипломатия
      — это несложно, когда дефективные при оружии не лезут
      вперёд тебя со своими пистолетами.</p>

    <p>Этих самых при оружии медленно
      обходил второй портовый головорез, который тавр.</p>

    <p>Останавливался рядом с каждым,
      разглядывал, точно музейный экспонат какой. Демонстрация превосходства? Виктор
      Дарьевич ничего против не имел: он не воюет, ему армия
      не нужна. Он поговорить приехал.</p>

    <p>— А я уж было подумал, что всё
      недоброе, что могло с Сепгеем Борисовичем произойти, уже произошло и стремится
      закончиться. Но он же <i>хороший человек</i> со всеми вытекающими. Всё правильно сделал. Идиот
      проклятый, — мрачно ругнулся Дмитрий Ройш. — Ну и чего вы теперь от меня
      хотите?</p>

    <p>Ох.</p>

    <p>Неуютно было Виктору Дарьевичу от
      реакции Дмитрия Ройша.</p>

    <p>Вот как в начале лета он не желал
      вникать, чего вдруг Дмитрию Ройшу приспичило
      оставаться без прописки на квартире у этого самого Сепгея Борисовича, так и
      сейчас не желал вникать, почему вдруг Дмитрий Ройш так погрустнел и стал выглядеть
      лет на десять старше.</p>

    <p>— Да всё того же, — вздохнул
      Виктор Дарьевич, — понять, <i>что это было.</i> По Медкорпусу шныряют фаланги, копаются в подвигах некоего стажёра-лаборанта.
      Из-за требования закрыть отряд на карантин, заверенного печатью Сепгея
      Борисовича, и приглашения директору отряда встретиться с этим
      стажёром-лаборантом. Собственно, в прошлую субботу на встречу вместо директора
      пришёл фаланга, поскольку директор по случайности знал, куда бежать ябедничать
      на Медкорпус. Пришёл — и с вами не увиделся, вы пропали. Начал разбираться —
      обнаружил много неприятных мелочей. В воскресенье вечером явился к нам, а мы и
      не знали, что ему ответить, — Виктор Дарьевич покачал головой. — Дмитрий,
      давайте честно. Моя гэбня хочет вас разобрать на донорские органы, поскольку вы
      устроили с этим карантином леший знает что и притащили
      к нам фаланг с официальным расследованием. Я хочу понять, как нам из этого
      расследования выпутаться без особых жертв, а потому не спешил бы вас ни на что
      разбирать. Расскажите, что, почему и зачем. Не для того, чтобы я мог определить
      вам меру наказания, а чтобы я мог понять, что мне дальше делать там, в Столице.</p>

    <p>И это не считая того, промолчал
      Виктор Дарьевич, что вообще-то жуть как любопытно, из-за чего весь шум-то.</p>

    <p>— Тестировать на одном несчастном
      Сепгее Борисовиче многочисленные ресурсы Когнитивной Части точно было не лучшим
      началом, — усмехнулся Дмитрий Ройш. — А что, собственно, вы можете мне
      предложить в обмен на увлекательный рассказ о том, как заманить фалангу? Ну,
      кроме угроз смертоубийством, которое вам же навредит больше, чем мне, и
      воззваний к совести, которая у меня, кажется, только что окончательно атрофировалась?</p>

    <p>Хуже, чем вести деловые беседы,
      Виктор Дарьевич умел разве что торговаться.</p>

    <p>С учётом того, что финансовые и
      иные средства всего Медкорпуса попали к нему в руки даже раньше диплома о
      высшем медицинском образовании, он как-то до сих пор не научился препираться,
      отказывать, сбивать цену.</p>

    <p>Зачем, если можно просто
      заплатить (деньгами или не деньгами — не суть) побольше?</p>

    <p>— А чего, собственно, вы хотите в
      обмен на информацию? — в тон Дмитрию Ройшу переспросил Виктор Дарьевич. — Я
      полагаю, ваше нынешнее положение не безоблачно. Вас, как минимум, ищут фаланги.
      Хотя от них вы, вероятно, укрылись в Порту, — Виктор Дарьевич уставился на
      тельняшку головореза с серьгой и вспомнил про чайку на
      вокзале. — Кстати, насчёт Порта: я бы вам не советовал там сейчас оставаться.</p>

    <p>Головорез
      с серьгой посмотрел на Виктора Дарьевича с нескрываемым интересом.</p>

    <p>Пришлось пояснить:</p>

    <p>— Чайки в городе. Это может быть
      признаком много чего, но большинство вариаций на тему этого много чего —
      небезопасные вещи. Экология. Токсичный груз, какое-то серьёзное загрязнение
      морской воды, а то и…</p>

    <p>— Виктор как-тебя-там, — оборвал
      ход его мысли головорез с серьгой. — Давай так: ты
      своих стволов сейчас отправишь погулять вот вместе с ним, — ткнул пальцем в головореза-тавра. — Пущай в магазин сгоняют: бухнуть там,
      пожрать сообразят. Тут на втором этаже газовая горелка завалялась, на ней
      детишки твиревую кашу бодяжат. А мы втроём пока
      перетрём чего надо, ага? При шестёрках разговоры не разговаривают, смекаешь? А мы
      тебя не обидим, не ссы. У тебя ж табельный
      пистолетик есть? Есть. Ну вот и оставь, коли ссышь. По
      рукам?</p>

    <p>Виктор Дарьевич опять протянул
      руку (молча) и посмотрел на своих людей (со значением). Наверное, они не очень
      довольны. Наверное, они иначе представляют себе операции по задержанию беглецов
      из Медкорпуса. Наверное, они полагают, что Виктор Дарьевич — заигравшийся самодур.
    </p>

    <p>Зато совершенно точно, что
      Виктору Дарьевичу плевать, что они думают.</p>

    <p>Как только его люди с тавром-головорезом скрылись за дверью, головорез с серьгой
      неожиданно светским тоном заявил:</p>

    <p>— У вас отличные сигареты, Виктор
      Дарьевич. Традиционный рецепт южных индокитайских провинций? Верхние листья отшельникова куста, покупаете явно по сумасшедшей цене.
      Контрабандой через Порт дешевле, — подмигнул он Виктор Дарьевичу. — Угостите
      ещё одной, будьте любезны.</p>

    <p>Тот решил пока не удивляться,
      достал из кармана пачку и развернулся к Дмитрию Ройшу.</p>

    <p>— Вы на вопрос не ответили, —
      напомнил (в первую очередь — себе) Виктор Дарьевич. — Чего вы хотите в обмен на
      информацию?</p>

    <p>Дмитрий Ройш внимательно осмотрел
      пачку самокруток, которую всё ещё сжимал в руке:</p>

    <p>— Начнём с того, что нам нужно
      очень много денег, — и красноречиво перевёл взгляд на эксклюзивные
      индокитайские сигареты.</p>

    <p>&nbsp;</p>

    <p>— Я бы соврал, если сказал бы,
      что не собирался вас подставлять, — Дмитрий Ройш забрался с ногами на
      подоконник заколоченного окна. — Собирался. Просто предположил, что вы же
      Медицинская гэбня, что вам стоит расплеваться с одним фалангой. Опять же,
      чего-нибудь да стоит, просто меньше, чем другим. Ну, мне так кажется. И ещё я
      бы соврал, если сказал бы, что с самого начала пришёл просить вас об одолжении
      с циничным планом в загашнике. Когда мы с вами, Виктор Дарьевич, беседовали о
      моём трудоустройстве, я крайне искренне полагал, что останусь в Медкорпусе
      надолго. Возможно, на всю жизнь. Но потом обстоятельства изменились.</p>

    <p>Виктор Дарьевич не понаслышке
      знал: обстоятельства иногда меняются, а вместе с ними — цели и задачи. Для чего
      бы ни путешествовал в Бедроград Виктор Дарьевич, прямо сейчас его волновало
      только одно: как скоро будет готово ароматное нечто, над которым колдовал головорез с серьгой.</p>

    <p>Головорез-тавр
      некоторое время назад занёс какие-то продукты и специи и смиренно удалился
      следить, как люди Виктора Дарьевича наматывают круги вокруг заброшенного
      здания. С тех пор головорез с серьгой не отходил от
      газовой горелки, а Виктор Дарьевич не отходил от головореза с серьгой.</p>

    <p>Со своей гэбней, помешанной на
      правильной пище, нечасто видишь такое количество соли и перца.</p>

    <p>— Я не знаю, в каких вы
      отношениях с Бедроградской гэбней — мне уже насрать,
      честно говоря. Наверное, в хороших. Неважно. Так сложилось, — Дмитрий Ройш
      покосился на головореза с серьгой, —&nbsp;что Университет — в плохих.
      Достаточно плохих, чтобы
      Бедроградская гэбня захотела его подставить. А я, соответственно, с Университетом
      — в достаточно хороших, чтобы мне не было жалко подставить вас — при всей
      благодарности — для того, чтобы Бедроградская гэбня не сумела подставить
      Университет. В общем, не пытайтесь это понять, но знайте, что всё — моё
      исчезновение, рандеву с директором отряда и закономерное явление фаланги — было
      выверено по часам в соответствии с моментом явления — не шибко легального, к
      слову — одного из голов Бедроградской гэбни в Медкорпус.</p>

    <p>Возможно, это всё ароматы перца,
      но Виктор Дарьевич пока что не слишком хотел злиться на превращение Медкорпуса
      в поле чьих-то чужих битв. Ну мало ли, было выверено по
      часам. Ну мало ли, западня. Ну
      мало ли, на голову Бедроградской гэбни. Ну мало ли, с
      заманиванием фаланг (они <i>всерьёз</i> рассчитывали, что смогут не только заманить, но и дальше манипулировать
      фалангами в своих целях? Вот дефективные!). Ну мало
      ли, подставить одних и других, чтоб тебя не
      подставили третьи. Ну мало ли.</p>

    <p>По сравнению с желанием поужинать
      с дальней дороги это всё ерунда.</p>

    <p>Дмитрий Ройш методично докуривал эксклюзивные
      индокитайские сигареты:</p>

    <p>— Хочется сказать что-нибудь
      ёмкое вроде «но, конечно, именно в Медкорпусе это произошло неслучайно», только
      я уже не знаю, случайно или нет. И с чего всё началось, не знаю. Скажем так:
      вся интрига так или иначе приводит к чуме. Которую Бедроградская гэбня хотела тихонько сотворить в
      Медкорпусе и выдать за университетскую, которую в итоге сделал для них я — ну,
      не только я, но ваше дружелюбие к соучастникам не располагает сообщать имена.
      Как было сказано выше, не пытайтесь это понять.</p>

    <p>Опять «чуму», да что ж такое!</p>

    <p>Дмитрий Ройш, конечно, по
      образованию не медик, но в Медкорпусе-то он хоть сколько-то проработал. И всё
      равно продолжает говорить на языке народных масс.</p>

    <p>«Чума» — это уж больно
      расплывчато.</p>

    <p>Любое инфекционное заболевание,
      от которого мрут, у народных масс «чума».</p>

    <p>Это пошло с тёмных времён, когда
      на росской территории начала то и дело вспыхивать зараза, ныне именуемая
      степной чумой. Случилась неувязка: коренные жители степи хранили название
      болезни в тайне (как и все прочие знания о ней, как и свои несовершенные методы
      борьбы), а заинтересованные в экономических связях с росами европейцы издалека
      обозвали явление, как уж сумели. Не то и впрямь решили, что до росов докатился
      бич Европ (почему-то с другой стороны континента), не то всё дело было в
      деньгах: согласно какому-то средневековому европейскому соглашению, страна, в
      которой зафиксирована эпидемия чумы, была что-то там в
      перспективе должна всем остальным, с кем вела торговлю, — за риски.</p>

    <p>На деле же болезнь, пришедшая из
      степи, имеет вирусную природу — в отличие от вызываемой бактериальным
      возбудителем европейской чумы. Но у росов это случайное название прижилось и в
      результате перекинулось едва ли не на всю карантинную группу.</p>

    <p>Тоже в силу недоразумения: неискушённым
      в медицине росам о степной чуме было понятно лишь то, что она чрезвычайно легко
      передаётся и приводит к летальному исходу. Симптоматика же начальных стадий
      настолько вариативна, что без современного лабораторного анализа спутать можно
      вообще с чем угодно.</p>

    <p>Современный лабораторный анализ
      может немало, а всероссийская медицина на голову переросла шпионящие теперь за
      Медкорпусом Европы, да только перед загадкой степной чумы и нынешние
      инфекционисты — всё те же неискушённые в медицине росы.</p>

    <p>Потому что за все четыре вспышки,
      случившиеся после Революции, ни один живой заболевший так и не попал в
      Медкорпус.</p>

    <p>Степная чума — жуткая штука, хоть
      и не чума она вовсе.</p>

    <p>Всем прочим «чумам» до неё тем
      более далеко. А народные массы всё равно боятся, хоть им и внушают с отрядских
      лет, что эпидемии — прерогатива дореволюционного прошлого.</p>

    <p>Кстати.</p>

    <p>— Эпидемии — это всё-таки к
      Рыжову, а не ко мне, — вновь засмотрелся на процесс приготовления пищи Виктор
      Дарьевич. — Но вам повезло, что по вашу душу приехал не он. Он бы душой не
      ограничился — в конце концов, ваши художества творились преимущественно у него в
      Инфекционной Части. Но в двух словах: что конкретно вы синтезировали? Чайки из
      Порта из-за этого ведь улетают?</p>

    <p>— Как я и сказал, чуму. Водяную
      чуму, двоюродную племянницу степной.</p>

    <p>Виктор Дарьевич медленно, <i>очень медленно</i> повернулся к Дмитрию
      Ройшу. Тот неубедительно скорчил торжественно-издевательскую рожу, но глаза его
      за стёклами очков оставались усталыми и немного злыми.</p>

    <p>Бедроград рехнулся.</p>

    <p>Шансы Виктора Дарьевича рехнуться вместе с Бедроградом стремительно росли. И
      нельзя
      сказать, что он был сильно против. Совсем не против.</p>

    <p>Даже за.</p>

    <p>— Я правильно понимаю, что когда
      вы говорите «племянницу», вы имеете в виду… — начал
      было Виктор Дарьевич, но тут перед самым его носом оказалась ароматная кастрюля
      с пышущей жаром смесью мяса, макарон и перца.</p>

    <p>Как же давно Виктор Дарьевич не
      ел перца, а.</p>

    <p>Головорез
      с серьгой театрально раскланялся и гаркнул:</p>

    <p>— Паста по-портовому! Приятного
      аппетита!</p>

    <p>&nbsp;</p>

    <p>(Если бы
кто-нибудь попросил Виктора Дарьевича внятно объяснить, что было дальше, то
Виктор Дарьевич пришёл бы в некоторое замешательство.</p>

    <p>В науке и в криминалистике
      пользуются (несколько по-разному, но всё же) термином «аффект». Так вот к той
      беседе гораздо больше подходит термин «угар». То есть это, конечно, не термин,
      но Виктор Дарьевич начал всерьёз задумываться, что науке (его науке) данного
      термина ой как не хватает.</p>

    <p>Потому что угар
есть, а термина нет.)</p>

    <p>&nbsp;</p>

    <p>— Едят мох и оскопляются
      сообразно псевдоимперским ритуалам? — ошалело переспрашивал Виктор Дарьевич.</p>

    <p>— Ну да, — кивал Дмитрий Ройш.</p>

    <p>— Леший, мох. Это же какой
      коктейль может быть в крови в результате, — рассуждал Виктор Дарьевич. — А
      недостаток солнечного света…</p>

    <p>— Делает их уродами,
      — заявлял со всей категоричностью Дмитрий Ройш.</p>

    <p>— И не только! Должно быть, все
      гормональные процессы… к лешему гормональные процессы! — перескакивал с одной
      мысли на другую Виктор Дарьевич. — Специфическая социализация тут даже
      интересней, потому что полностью изолированная группа в таких условиях…</p>

    <p>— Окончательно свихнётся, — забегал вперёд Дмитрий Ройш.</p>

    <p>— Да, но это же замечательно! —
      уточнял Виктор Дарьевич. — Я знаю, специализация не ваша, но только представьте… Хотя
      чего вам представлять, вы-то видели. Леший, как я вам
      завидую!</p>

    <p>— Нашли чему. Они нас там чуть
      самих не оскопили, — возражал Дмитрий Ройш.</p>

    <p>— Да, но это тем более замечательно! — возражал на возражения Виктор
      Дарьевич. — Групповая агрессия, коллективный экстаз. Возможно, даже
      коллективные галлюцинации?</p>

    <p>— Возможно, — бурчал Дмитрий
      Ройш.</p>

    <p>— Замечательно, леший, как же
      замечательно, — восторгался Виктор Дарьевич. — Понимаете, создать такую среду
      экспериментально невозможно, а ведь это идеальная площадка для проверки гипотез
      относительно продуктивности деструктивных девиаций.</p>

    <p>— Да мы просто везунчики, — ехидничал
      ничего не смыслящий в научном прогрессе Дмитрий Ройш.</p>

    <p>— Подождите, — всё-таки приходил
      в себя Виктор Дарьевич, — но при чём здесь степная чума?</p>

    <p>— Виктор Дарьевич, только
      подумайте: едят мох и оскопляются по псевдоимперским
      ритуалам! — бессовестно вклинивался головорез с
      серьгой, и степная чума вновь отступала для Виктора Дарьевича на задний план.</p>

    <p>&nbsp;</p>

    <p>— Существование подобной
      социальной структуры на протяжении столь длительного временного периода, —
      воспарял к небесам Виктор Дарьевич, — подтверждает… вернее, конечно,
      опровергает… опровергает господствующие представления
      о тотальности этого нелепого принципа поиска оптимальных решений. Для социума
      опровергает. Но от социума мы можем продвинуться к индивидуальному сознанию! И
      вот тогда существование подобной социальной структуры на протяжении столь
      длительного временного периода подтверждает, хоть и косвенно, представления о
      возможности выхода мышления на путь познания, отличный от пути поэтапных мелких
      улучшений уже имеющегося в опыте. Это гносеологическая революция!</p>

    <p>— Гносеологический Расстрельный
      Комитет, — тряс головой Дмитрий Ройш. Не его специализация, что тут поделать.</p>

    <p>— Вы торопитесь, — неожиданно
      вторгался в фантазии Виктора Дарьевича головорез с
      серьгой. — Не смешивайте коллективное сознание с индивидуальным.
      Эта дорога в конечном итоге вязнет в болоте дурного
      фрайдизма, сколько раз уже было за последние полвека в истории науки.</p>

    <p>— Но обусловленность коллективного сознания индивидуальным…
      — бросался на защиту фантазий Виктор Дарьевич, игнорируя некоторую внезапность
      демонстрации компетентности головореза с серьгой в обсуждаемом вопросе.</p>

    <p>— Работает также и в обратную
      сторону, — упрямился головорез с серьгой. — Поэтому мы
      не можем с уверенностью утверждать, что первично.</p>

    <p>— А кто утверждает? — продолжал Виктор
      Дарьевич. — Я разделяю представления о взаимовлиянии коллективного и
      индивидуального и не стремлюсь доказать, что эволюция мышления предшествует
      социальной эволюции. Если это запросы социальной эволюции дают толчок эволюции
      мышления, а не наоборот, в вопросе <i>самой
        возможности</i> эволюции мышления
      ничего не меняется.</p>

    <p>— Ну, кроме того, — усмехался головорез с серьгой, — что в данном случае для <i>практического преодоления</i> принципа
      поиска оптимальных решений на уровне индивидуального сознания всем носителям оного
      придётся переселиться в Вилонский Хуй и перенять образ жизни скопцов.</p>

    <p>— Это несущественно, —
      отмахивался Виктор Дарьевич.</p>

    <p>&nbsp;</p>

    <p style={{ textIndent: '18.0pt' }}>— Почти
      идеальный социум! — подводил итог Виктор Дарьевич.</p>

    <p>— Тогда уж не почти, а собственно <i>идеальный</i> социум, — бессовестно ржал головорез с серьгой, — это тот, который не воспроизводится
      вовсе!</p>

    <p>— Не размножается и живёт вечно,
      — заражался весельем Виктор Дарьевич. — Таким образом
      мы ещё через несколько шагов упраздняем эволюцию.</p>

    <p>— И Революцию, что уже нехорошо,
      — подхватывал головорез с серьгой. — Ибо скопцы в
      вопросе поддержания численности населения противоприродным
      — то есть ведущим к упразднению эволюции — способом давно перегнали
      Всероссийское Соседство с его какими-то там отсталыми алхимическими печами.</p>

    <p>— Таким образом, мы можем
      заключить, что упразднение эволюции ведёт к Колошме! — двигался дальше Виктор
      Дарьевич. — Ведь нельзя же не сажать за такие разговоры.</p>

    <p>— Как будто в этом мире есть хоть
      что-то, что не ведёт к Колошме, — подавал голос
      притихший Дмитрий Ройш.</p>

    <p>&nbsp;</p>

    <p>— Нет, — упорствовал головорез с серьгой.</p>

    <p>— Ну не на лабиринтах с
      переменной структурой же их тестировать, — возмущался Виктор Дарьевич. — У
      каждого из них могут быть уникальные интеллектуальные возможности, посерьёзнее моих
      или ваших.</p>

    <p>— Которые блокируются в отрыве от
      сформировавшей их среды! — рушил надежды головорез с
      серьгой. — Ну вы-то должны понимать, как непросто
      поставить действительно результативный эксперимент, когда речь идёт о высшей
      нервной деятельности и её производных.</p>

    <p>— Безусловно, — не собирался спорить
      Виктор Дарьевич. — У меня до сих пор статистика по лучшим экспериментам забита
      сплошными отрядскими детьми. Как раз потому, что в
      моём распоряжении уже сколько лет есть целый отряд в
      Бедрограде, где была смоделирована необходимая среда. В пределах данного
      конкретного отряда — совершенно естественная, поэтому валидность всегда пристойная.</p>

    <p>— И чего вы тогда ждёте от
      покинувших привычный ареал скопцов? — допытывался головорез
      с серьгой.</p>

    <p>— Чего угодно, — предвкушал
      Виктор Дарьевич. — Знаете, в самой известной монографии на росском
      языке по генезису научного мышления…</p>

    <p>— Вот давайте без самой известной монографии на росском
      языке! — дёргался вдруг головорез с серьгой.</p>

    <p>— Помилуйте, но от чего тогда
      отталкиваться? — недоумевал Виктор Дарьевич.</p>

    <p>&nbsp;</p>

    <p>— Восемьсот пятьдесят три, —
      хвастался Виктор Дарьевич.</p>

    <p>— А я не помню уже, — увиливал головорез с серьгой. — Больше шести сотен, но за
      точное
      число не поручусь.</p>

    <p>— У меня больше, — непристойно
      радовался Виктор Дарьевич. — Хотя я думал, у такого публичного человека, как вы,
      известного в академических кругах на всю страну…</p>

    <p>— При жизни я уклонялся от своего
      академического долга, — мотал головой головорез с
      серьгой. — Публичность, публичность — моих заслуг тут нет, просто нужен был
      кто-то, чтоб прикрыть брешь в идеологии. Вот кто под руку подвернулся, тому и
      шесть сотен слушателей в аудитории за раз.</p>

    <p>— Ну, по правде сказать, —
      признавался Виктор Дарьевич, — мои восемьсот пятьдесят три человека в аудитории
      за раз были в Европах. Это не очень честно, потому что понабежали
      фотографы, газетчики, шпионы: выступление от лица Медкорпуса в Европах — это
      скорее политическая сенсация, нежели научная.</p>

    <p>— Для сравнения, у кого больше,
      это не имеет значения, — не оспаривал первенство головорез
      с серьгой (интересно, был ли он головорезом с серьгой, когда им прикрывали
      брешь в идеологии?).</p>

    <p>— Не рой себе яму, — подключался
      Дмитрий Ройш. — Если степень академичности не имеет значения, то ты тем более
      выигрываешь. Скопцов в Вилонском Хуе всяко больше
      тысячи, и что с того, что главным посылом той твоей речи было «пожалуйста, не
      убивайте и не оскопляйте нас»?</p>

    <p>&nbsp;</p>

    <p>— И они поверили? — изумлялся
      Виктор Дарьевич.</p>

    <p>— Вы сами говорили о коллективных
      галлюцинациях, — пожимал плечами головорез с серьгой.
      — Скопцы к этому делу привычные. Подумаешь, явление народу божественных
      сущностей. Главное — отвести их подальше от архетипической схемы принесения
      бога в жертву, но тут есть несколько достаточно изученных закономерностей.
      Отведёшь — и наслаждайся поклонением сколько влезет.
      Хотя, конечно, та ещё комедия.</p>

    <p>— Потрясающе, — радовался Виктор
      Дарьевич. — В Европах бы удавились!</p>

    <p>— В смысле? — переспрашивал головорез с серьгой.</p>

    <p>— Ой, вы же, наверное, не знаете,
      — соображал Виктор Дарьевич. — Они там за последний десяток лет взяли моду
      верить только выкладкам естественных наук, потому что, мол, у прочих слишком
      низки шансы быть верифицированными. Это всё попытки выключить Всероссийское
      Соседство из мировых академических процессов хоть частично. И ведь представьте:
      воруют у меня бесконечно — психиатрию, психофизиологию, но базовые постулаты
      когнитивной науки якобы не признают.</p>

    <p>— Да ты ебанись, — присвистывал головорез с серьгой. — Вот так покинешь
      цивилизацию
      ненадолго, и всё, дом родной потом не узнаешь. Что у нас ещё произошло: отказ
      Европ от религии, реформа языка, изобретение летательных аппаратов?</p>

    <p>— Нет, — смеялся Виктор Дарьевич.
      — Пока всего лишь изобретение устройств, записывающих не только звук, но и
      изображение. В семьдесят шестом, кажется, первые появились.</p>

    <p>— О да, я слышал, — неожиданно
      ёрничал головорез с серьгой.</p>

    <p>— Это же невероятно удобно, —
      пояснял Виктор Дарьевич. — Мы фиксируем операции, мы можем наблюдать за
      течением любого эксперимента своими глазами, не вмешиваясь…</p>

    <p>— Вы знаете, — ухмылялся головорез с серьгой, — мне как раз в семьдесят шестом
      довелось наблюдать, не вмешиваясь. Благодаря техническому прогрессу.</p>

    <p>— Тогда вы тем более должны понимать, как это
      здорово! — воодушевлялся Виктор Дарьевич.</p>

    <p>— Обдрочиться,
      — соглашался головорез с серьгой.</p>

    <p>— А, я понял, — хлопал себя по
      лбу Виктор Дарьевич. — Их же не для экспериментов делали в первую очередь, а
      для допросов и прочих…</p>

    <p>— Воздействий, — огрызался
      почему-то Дмитрий Ройш.</p>

    <p>— Да, да, — вспоминал Виктор
      Дарьевич, — Медкорпусу-то эти устройства достались в
      подарок от Бедроградской гэбни, это их инженеры ваяли. Вы знаете, там в гэбне есть
      один умный мальчик…</p>

    <p>— Знаем, — мрачнел на глазах
      Дмитрий Ройш.</p>

    <p>&nbsp;</p>

    <p>— И всё-таки — умный мальчик, —
      качал головой Виктор Дарьевич.</p>

    <p>— Будущее госслужбы, — закуривал головорез с серьгой. — Я его вообще-то очень
      уважаю после
      Колошмы. Спас паршивую ситуацию, сшив мне то
      обвинение. А так стольким бы людям не поздоровилось, вплоть до фаланг.</p>

    <p>— Ещё бы не спас, — самодовольно
      брал портовую самокрутку Виктор Дарьевич. — Вы
      думаете, я его просто так в семнадцать лет в ревизоры бедроградского отделения
      Медкорпуса брал? Он же из моего экспериментального отряда. Там всякие вещи
      делаются, но по одному человеку за выпуск растим на особых условиях —
      изолированно от других детей, по усиленной учебной программе, с ответственными
      поручениями и так далее. Против всех правил педагогики на самом-то деле, но
      кадры из них потом получаются высочайшей компетенции.</p>

    <p>— Как вам в принципе удалось
      выбить целый отряд, где можно развлекаться и плевать на педагогику? —
      недоумевал головорез с серьгой.</p>

    <p>— Как-то, — разводил руками
      Виктор Дарьевич. — Он и до нас служил кому-то экспериментальной площадкой, но к
      тому моменту, как я попробовал его заполучить, видимо, уже не был нужен прежним
      владельцам. Так что никаких проблем — если деньги есть, твори что хочешь в
      одиннадцатом отряде.</p>

    <p>— Как говаривали в былые времена
      на Колошме, отличная вывеска для туристов, — совершенно обалдевал
      Дмитрий Ройш. — Но так-то это безумие, конечно. И умный мальчик — ваш, и
      одиннадцатый отряд — ваш, и микрофлора пяток скопцов вам любезна. К чему вы
      вообще руки приложить не успели?</p>

    <p>&nbsp;</p>

    <p>— Ну, эротические эксперименты на
      детях, да. Ну, с подконтрольностью исследовательской группы вышла накладка, да.
      Но, — не собирался сдаваться Виктор Дарьевич, — четырнадцать-пятнадцать — это
      разве ещё дети?</p>

    <p>— Нет, конечно, — вступался за
      Виктора Дарьевича головорез с серьгой.</p>

    <p>— Какая разница, дети или нет? —
      упрямился Дмитрий Ройш. — Как будто если лишить взрослого человека возможности
      самому выбирать некоторые аспекты своей биографии — от места проживания до
      того, имеет ли он страстные чувства в адрес своих воспитателей, — сильно лучше
      получается. Вы так говорите про детей и недетей, как
      будто это разные биологические виды, и одних можно, а других нельзя.</p>

    <p>— Давайте оставим вопросы морали,
      — брыкался Виктор Дарьевич. — Они к результативности исследований отношения не
      имеют. Тем более что лично вы живы и вроде как в порядке — о чём тогда
      разговор?</p>

    <p>— При чём тут мораль, —
      возмущался Дмитрий Ройш. — Я-то, может, и в порядке, но, во-первых, есть не
      только удачливый я, а во-вторых, степень моего порядка вы как, на глазок
      оцениваете? Подобные неверифицированные выводы
      ненаучны, в конце концов!</p>

    <p>— Вы не понимаете значимости того
      проекта! — вздыхал Виктор Дарьевич. — Если б не сорвалось, мы бы прояснили
      важнейший вопрос…</p>

    <p>— Блокируется или не блокируется
      инстинкт самосохранения менее значимыми инстинктами, если их искусственно
      подкорректировать? — уточнял головорез с серьгой.</p>

    <p>— Я бы не назвал это
      «подкорректировать», тут мы имеем дело с… — начинал,
      но притормаживал Виктор Дарьевич. — Подождите, как вы догадались?</p>

    <p>— Путём длительных контактов с
      бывшим экспериментальным материалом, вестимо, — усмехался головорез
      с серьгой.</p>

    <p>— Серьёзно? — буквально
      подпрыгивал Виктор Дарьевич. — Тогда вы, с вашей-то академической подготовкой,
      можете прямо сейчас заполнить некоторые пробелы. Это совершенно неожиданная
      удача! Я давно и думать забыл, а тут…</p>

    <p>— Нет, вы оба совсем охуели, —
      стонал Дмитрий Ройш — Надо было из отряда не в Столицу, а в Индокитай сбегать.</p>

    <p>— Не ной, — обрывал его головорез с серьгой. — Наука нуждается в деталях твоей
      эротической биографии. Так вот, Виктор Дарьевич, я к вашим услугам.</p>

    <p>&nbsp;</p>

    <p>— Такой был бы отчёт, а, —
      сокрушался Виктор Дарьевич.</p>

    <p>— Неподотчётное мероприятие, —
      хмыкал головорез с серьгой. — Слушайте, а по всему
      выходит, что этот ваш умный мальчик из Бедроградской гэбни вовсю
      тогда пользовался экспериментальными данными из отряда. Мог?</p>

    <p>— Разумеется, — не сомневался
      Виктор Дарьевич. — Я от него картотеку специально не закрывал, мы до сих пор в
      хороших отношениях. А уж что касается одиннадцатого отряда — это с не очень
      давних пор и вовсе общая территория Медкорпуса и Бедроградской гэбни. С учётом
      того, что он оттуда и выпускался, пойди скрой
      какую-нибудь информацию. Так что да, пользовался наверняка — и тогда, и не
      тогда.</p>

    <p>— Ну я как
      жопой чуял, — фырчал Дмитрий Ройш.</p>

    <p>— И мне с одной стороны не жалко,
      — размышлял Виктор Дарьевич, — а с другой… Веселье на Колошме — это ладно, но
      откуда мне знать, что ещё он за счёт Медкорпуса проворачивал и никак не
      отчитывался? И этот заказ вирусов прямо у нас под носом… вот же скотина
      неблагодарная!</p>

    <p>— Все преследуют собственные
      цели, это естественно. К сожалению, — вторил ему головорез
      с серьгой.</p>

    <p>&nbsp;</p>

    <p>— Легально? Прямо из студентов? —
      таращился Виктор Дарьевич. — Феерическая наглость! В смысле, здорово же.</p>

    <p>— Как будто у кого-то был выбор,
      — пояснял головорез с серьгой.</p>

    <p>— Всем бы подобную прыть в
      использовании человеческих ресурсов! — смеялся Виктор Дарьевич. — Уж не знаю как,
      но с медицинской точки зрения вы выбрали самый
      результативный метод. И самый безопасный. А то искусственный синтез крови может
      с такими флуктуациями протекать, мало не покажется.</p>

    <p>&nbsp;</p>

    <p>— Молодец Попельдопель! —
      восхищался Виктор Дарьевич. — Верно прикинул риски,
      верно оценил перспективы — и всё равно не побоялся провернуть такое нарушение.
      Знает толк! Мы же с ним вообще-то на медфаке на одном курсе учились, хоть и по
      разным кафедрам…</p>

    <p>— Так это из вас он деньги сосёт?
      — интересовался головорез с серьгой.</p>

    <p>&nbsp;</p>

    <p>— Нет, это уже фантастика. Неужто скопцы в Бедрограде? — не верил своему счастью
      Виктор
      Дарьевич.</p>

    <p>— Ну не все, спасибо лешему, —
      подтверждал головорез с серьгой.</p>

    <p>&nbsp;</p>

    <p>— Допустим. Но у людей, рождённых
      не из печи, с иммунитетом серьёзнейшие проблемы могут быть, — озадачивался
      Виктор Дарьевич.</p>

    <p>— Попельдопель то же самое
      говорит, — кивал головорез с серьгой.</p>

    <p>— Это очень интересный случай, —
      прикидывал Виктор Дарьевич. — Не в моей компетенции, но Инфекционная Часть
      любит подобные дополнительные факторы.</p>

    <p>&nbsp;</p>

    <p>— Пятьдесят первый год? Из той скандальной
      лаборатории, которая сокрыла данные по контролю уровня агрессии? —
      переспрашивал Виктор Дарьевич. — Не сокрыла б, я б их всех собрал хоть в том же
      одиннадцатом отряде! Наверное, даже вместе с сотрудниками лаборатории. И
      многообещающее исследование бы не пропало, и социализацию бы мы гарантировали,
      и вообще не было бы впоследствии никаких глупых разбирательств…</p>

    <p>— Красота и всеобщее
      благоденствие, — иронизировал головорез с серьгой.</p>

    <p>— Дайте помечтать! — смеялся
      Виктор Дарьевич. — А ещё чего-нибудь, потерянного Медкорпусом, у вас случайно
      не найдётся в загашнике?</p>

    <p>&nbsp;</p>

    <p>— Так, — загибал пальцы Виктор
      Дарьевич. — Парочку скопцов утром. Лучше не парочку, лучше больше. Парочку как
      раз себе оставьте — куда вам дюжина. А мне отдайте всех остальных. Этого вашего
      больного с нестандартными показателями, образец вируса, образец лекарства,
      сыворотку для аллергии, в идеале кого-то из испытуемых, все данные по эпидемии
      — но это я у Попельдопеля спрошу. Насчёт пятьдесят первого года подумайте.</p>

    <p>— Подумаю, — со
      вздохом соглашался головорез с серьгой.</p>

    <p>— Подумайте-подумайте, — давил
      Виктор Дарьевич. — И я бы с вами поподробнее обсудил ещё кое-что…</p>

    <p>— А вот это — увы, — мотал
      головой головорез с серьгой. — У меня, к несчастью, и
      так за ночь дел накопилось. Опять вместо того, чтоб поспать, придётся мозгами
      шевелить, как с минимальными потерями выпутаться из ближайших сложностей.</p>

    <p>— Да не убегайте вы, — не хотел
      прекращать беседу так сразу Виктор Дарьевич. — Может, я вам ещё чем-то смогу
      посодействовать?</p>

    <p>&nbsp;</p>

    <p>— Я не шучу, — многозначительно
      покашливал Виктор Дарьевич. — Чтоб <i>действительно</i> с минимальными потерями, вам всего-то и нужно — найти за оставшееся время ветку
      черёмухи!</p>

    <p>&nbsp;</p>

    <p>Если бы
кто-нибудь попросил Виктор Дарьевича внятно объяснить, что всё это было,
воплотил ли он намерения Медицинской гэбни относительно Дмитрия Ройша и как он
будет объяснять оной гэбне (то есть трём другим её головам), почему он ехал в
Бедроград за беглецом и с людьми при оружии, а возвращается за деньгами и с
тысячей обещаний скорейшей помощи тому же самому беглецу (и ещё куче
      других заинтересованных лиц), — то Виктор Дарьевич пришёл бы в некоторое
      замешательство.</p>

    <p>Потому бы пришёл, что нечего тут
      объяснять.</p>

    <p>И так всё понятно.</p>
  </>
}

export default chapter25
