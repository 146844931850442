import React from 'react'
import Header from "./Header/Header";
import './Layout.sass'
import {RouteComponentProps, withRouter} from "react-router";

const getRandomPhrase = () => {
  const randomPhrases = [
    'И здесь был Хикеракли',
    'Здесь не могло быть вашей рекламы',
    'О где, о где они теперь',
    'О кто, о кто мог угадать',
    'КУПИЛ СКОПЦА?',
    'А вы не видели усики?',
    'Не заслуживает жалости!',
    'Хрусть!',
    'Оставим эти танцы',
    'Думать вредно',
    'Всех на Колошму',
    'Фаланги тоже люди',
    'Открыть бордель и издать ПСС',
    'Да какого лешего!',
    'Тянет на ПН4',
    'Чучело не переодето!',
    'Опять Александр?',
    'Прививались от обратной ветрянки?',
    'Шшш, ты не дёргайся',
    'Не ваш уровень доступа'
  ]

  return randomPhrases[Math.floor(Math.random() * randomPhrases.length)]
}

const Layout: React.FunctionComponent = ({ children }) => <div className='page'>
  <div className='page_wrapper'>
    <Header />
    {children}
    <footer className='page_footer'>
      {getRandomPhrase()}
    </footer>
  </div>
</div>

export default Layout