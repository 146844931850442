import React from 'react'
// @ts-ignore
import VK, {Group} from 'react-vk'
import ShareWidgets from '../ShareWidgets/ShareWidgets'

export default () => <div className='page--sidebarLeft'>
  <aside>
    <section className='index_sidebar_contacts'>
      <h3>Звоните письма</h3>
      <small>по телефону</small>
      <a href='mailto:bedrograd@gmail.com' tabIndex={0}><img src='/images/phone.gif' alt='' /> bedrograd@gmail.com</a>
      <h3>Ищите нас</h3>
      <small>по адресу</small>
      <VK>
        <Group
          groupId={33910355}
          options={{
            mode: 1
          }}
        />
      </VK>
      <h3>Расскажите</h3>
      <small>кому следует</small>
      <ShareWidgets />
      <h3>Любим и чтим</h3>
      <small>наших трудовых спонсоров</small>
      <a href='https://megus.org' tabIndex={0}>megus.org</a>
      <a href='https://mithril.msk.su' tabIndex={0}>mithril.msk.su</a>
      <br/>
      <span>и просто товарищей</span>
      <a href='https://shadem.ru' tabIndex={0}>shadem.ru</a>
      <a href='https://vk.com/freedomcast' tabIndex={0}>FreedomCAST</a>
      <a href='http://ethnophony.ru' tabIndex={0}>Этнофония</a>
      <a href='http://pineapplepeak.blogspot.com' tabIndex={0}>Апогей Ананасности</a>
    </section>
  </aside>
  <main className='page--withText'>
    <section>
      <h1>Об авторах</h1>
      <img src='/images/authors.jpg' alt='' style={{ float: 'left', margin: '0 10px 10px 0' }} />
      <p>Нас две штуки, Корнел и Альфина. Корнел умный, а Альфина красивая.</p>
      <p>
        Мы являемся счастливыми обладателями глубоко гуманитарного образования (философского и лингвистического соответственно). Глубоко гуманитарное образование дало нам главное: знание о том, что буквы можно складывать в слова, слова — в предложения, а где-то там дальше можно сделать вид, что ты производишь нечто осмысленное и даже, возможно, интересное. К сожалению, при этом образование не дало нам представления ни о чём другом. Мы старательно делаем вид, что ни о чём другом приличному человеку и знать особо не надо.
      </p>
      <p>
        Что не мешает нам об этом писать. Тонкости медицинских процессов, особенности работы человеческой психики, расположение печени в организме — вот примеры фактов, которые описываются в «Чуме» так, что любой человек, имеющий об этом хоть сколько-то профессиональное представление, мигом заподозрит неладное и невесело покачает головой. В лучшем случае. В худшем — пожелает уебать нас печатной машинкой по голове.
      </p>
      <p>
        И кто мы, право слово, такие, чтобы ему мешать.
      </p>
      <p>
        А, собственно, кто мы такие? Мы юны, хороши собой и полны яростного запала. Рассказать что-то о нашей биографии сложно, потому что в последний год она свелась к написанию «Чумы». Тем не менее, помимо этого Альфина замечена в рисовании <a href='http://fullstop.alphyna.org' target='_blank'>комиксов</a>, ведении <a href='https://alphyna.livejournal.com/' target='_blank'>жежешечки</a>, распиздяйском образе жизни и психологическом возрасте, не превышающем восемнадцать лет (физический — 21, если вы вдруг подумали о том, что она старая и толстая). Корнел замечен в том, что не любит быть хоть в чём-то замечен, поскольку у него всегда найдутся дела поважнее.
      </p>
      <p>
        Осенью 10-го года мы заперлись на кухне и так до сих пор оттуда не вышли, потому что нечаянно, эмм, придумали мир. Безусловно, со всеми случается, но нас столь категорично не отпускало, что пришлось брать себя в руки и что-то как-то материализовывать. Всё равно не отпустило, зато теперь у нас есть «Чума». Весь придуманный мир в неё, разумеется, не влез, но это в наши планы и не входило — будем считать, что мы дали священный обет что-то как-то материализовывать ближайшие эн лет. Возможно, что-то ещё даже как-то вынырнет на поверхность и когда-то предстанет перед общественностью. Заранее готовьте пути отступления.
      </p>
      <p>
        Да, мы пишем вдвоём (и не пишем тоже). Да, рисует вроде как одна Альфина, но Корнел-то лучше знает, как всё это должно выглядеть в итоге, где фейл и которую точечку надо перекрасить ещё сорок раз для достижения истинной гармонии. Да, ругаемся мы немало. Да, нам кажется, что это круто, продуктивно и что на свете вообще есть немало вещей, которые лучше одной головы.
      </p>
      <p>
        (Здесь самое место выразить благодарности тем, кто помогал нам, чем мог, на нелёгком пути ваяния «Чумы».)
      </p>
      <p>
        Спасибо <a href='https://twitter.com/translatordude' target='_blank'>Блейду</a>, который не только приютил нас на своей кухне и подносил нам печеньки и сигареты, но и одарил «Чуму» немалым количеством тех самых тупых имён и названий. Которые мы, конечно, всё равно перепилили, переврали и извратили, но подвиг генератора имён забыт не будет!
      </p>
      <p>
        Спасибо <a href='https://megus.org/'>Мегусу</a>, который, в своё время, не только поделился доменом, но и помог сделать первую версию этого сайта — просто так, по доброте душевной.
      </p>
      <p>Спасибо Мерку за предоставленный оной первой версии хостинг.</p>
      <p>
        (Не)спасибо <a href='https://twitter.com/dotterian'>Доттериану</a>, который оставил всех без «Чумы» на 16 месяцев, пока занимался переносом сайта на новые рельсы.
      </p>
      <p>
        Спасибо всем тем, кто стойко терпел нашу производственную десоциализацию, продолжал нас любить и иногда даже понимать. Вы герои, серьёзно.
      </p>
      <p>
        И, конечно, отдельное спасибо всем нашим первым экспериментальным читателям, избранные цитаты из которых разместились аж на главной странице.
      </p>
      <p>Киноакадемии тоже спасибо, хоть она тут и ни при чём.</p>
    </section>
  </main>
  <footer>
    <img src='/images/site_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</div>
