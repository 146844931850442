import React from 'react'
import {DiscussionEmbed} from 'disqus-react'
import Helmet from 'react-helmet'

export default () => <>
  <Helmet>
    <title>А поговорить?</title>
  </Helmet>
  <main>
    <h1>Здравствуйте, уважаемые радиослушатели!</h1>
    <p>Эта страница существует исключительно для того, чтобы желающие могли писать комментарии обо всём сразу, не размениваясь на отдельные главы. Потому что, знаете, это ведь именно то, чего не хватает интернету — ещё одной страницы с комментариями без комментариев. Эдакая вебдванольная гостевая книга. Привет, спамеры, мы ждали вас.</p>
    <DiscussionEmbed
        shortname='bedrograd'
        config={{
          url: `https://bedrograd.megus.org/guestbook`,
          identifier: `guestbook`,
          title: 'А поговорить?'
        }}
      />
  </main>
  <footer>
    <img src='/images/site_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</>
