import React from 'react'
import Helmet from 'react-helmet'
import glasses from '../../Data/glasses'
import MarkdownPage from '../../MarkdownPage'

const GlassesPage = () => <>
  <Helmet>
    <title>Декоративно-прикладной фанарт</title>
  </Helmet>
  <MarkdownPage
    sidebarPosition='right'
    markdown={glasses}
    sidebar=''
    full
  />
</>

export default GlassesPage