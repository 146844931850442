import React from 'react'
import {NavMenuArray} from "./Header/Header";
import MenuLink from "./MenuLink";

interface PropTypes {
  main?: boolean,
  items: NavMenuArray,
  label: string,
  className?: string,
  focusParent?: () => void
}

interface StateProps {
  items: PropTypes['items']
}

export default class MenuBar extends React.Component<PropTypes, StateProps> {
  state = {
    items: this.props.items
  }

  outOfBounds = (index: number) => {
    const { items } = this.props
    const result = index < 0 || index >= items.length

    if (result) {
      console.log(`Index out of bounds: ${index}`)
    }

    return result
  }

  setIsFocused = (index: number, value: boolean) => new Promise(resolve => {
    const { items } = this.state

    if (this.outOfBounds(index)) {
      return
    }

    items[index].isFocused = value

    this.setState({ items }, resolve)
  })

  focusNext = (index: number) => {
    console.log('focusNext', index)
    this.setIsFocused(index, false)
      .then(() => {
        let newIndex = index + 1
        if (this.outOfBounds(newIndex)) {
          newIndex = 0
        }
        console.log('focusNext2', newIndex)
        // noinspection JSIgnoredPromiseFromCall
        this.setIsFocused(newIndex, true)
      })
  }

  focusPrev = (index: number) => {
    const { items } = this.props
    this.setIsFocused(index, false)
      .then(() => {
        let newIndex = index - 1
        if (this.outOfBounds(newIndex)) {
          newIndex = items.length - 1
        }
        // noinspection JSIgnoredPromiseFromCall
        this.setIsFocused(newIndex, true)
      })
  }

  focusSubmenu = (index: number) => {
    const { items } = this.props
    this.setIsFocused(index, false)
      .then(() => {
        const item = items[index]
        if (!!item.subMenu) {
          item.subMenu[0].isFocused = true
          this.setState({ items })
        }
      })
  }

  render() {
    const { main, items, label } = this.props

    const ContainerTag = main ? 'nav' : 'section'

    return <ContainerTag role='menubar' aria-label={label} className={this.props.className}>
      {
        items.map((linkProps, index) => <MenuLink
          key={`${linkProps.title}`}
          item={linkProps}
          focusSubmenu={() => this.focusSubmenu(index)}
          focusNext={() => this.focusNext(index)}
          focusPrev={() => this.focusPrev(index)}
          focusItem={() => this.setIsFocused(index, true)}
          focusParent={this.props.focusParent}
        />)
      }
    </ContainerTag>
  }
}