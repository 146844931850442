import React, {useState} from 'react'
import Disclamer from "./Components/Disclamer/Disclamer";
import Helmet from "react-helmet";
import Layout from "./Components/Layout/Layout";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import IndexPage from "./Components/Pages/IndexPage/IndexPage";
import NewsArticlePage from "./Components/Pages/NewsArticlePage/NewsArticlePage";
import ArchiveIndexPage from "./Components/Pages/ArchiveIndexPage/ArchiveIndexPage";
import ArchivePage from "./Components/Pages/ArchivePage";
import TaxonomyPage from "./Components/Pages/TaxonomyPage";
import ScrollToTop from "./Components/ScrollToTop";
import FaqPage from "./Components/Pages/FaqPage";
import CharactersPage from "./Components/Pages/CharactersPage/CharactersPage";
import BonusTextPage from "./Components/Pages/BonusTextPage";
import BonusTauresePhonologyPage from "./Components/Pages/BonusTauresePhonologyPage";
import BonusLevelsPage from "./Components/Pages/BonusLevels/BonusLevels";
import NadolgoPage from "./Components/Pages/NadolgoPage";
import BonusYiiha from "./Components/Pages/BonusYiiha";
import PageNotFound from "./Components/Pages/PageNotFound";
import BonusArtPage from "./Components/Pages/BonusArtPage/BonusArtPage";
import BonusArtImagePage from "./Components/Pages/BonusArtImagePage";
import FeedbackPage from "./Components/Pages/FeedbackPage";
import SynesthesiaPage from "./Components/Pages/SynesthesiaPage";
import RecommendationsPage from "./Components/Pages/RecommendationsPage";
import PoetryPage from "./Components/Pages/PoetryPage";
import GlassesPage from "./Components/Pages/GlassesPage";
import CosplayPage from "./Components/Pages/CosplayPage";
import FanartPage from "./Components/Pages/FanartPage";
import FanartImagePage from "./Components/Pages/FanartImagePage";
import AuthorPage from "./Components/Pages/AuthorPage";
import GuestBookPage from "./Components/Pages/GuestBookPage";
import ContentsPage from "./Components/Pages/ContentsPage";
import BookPage from "./Components/Pages/BookPage";
import DownloadPage from "./Components/Pages/DownloadPage";

const App = () => {
  const disclamerKey = 'забирайменяскорей'
  const [showDisclamer, setDisclamer] = useState(!window.localStorage.getItem(disclamerKey))

  const acceptDisclamer = () => {
    window.localStorage.setItem(disclamerKey, 'увозизастоморей')
    setDisclamer(false)
  }

  return <>
    <Helmet
      titleTemplate='%s | Чума в Бедрограде'
      defaultTitle='Чума в Бедрограде | здесь был хикеракли'
    >
      <title>Чума в Бедрограде</title>
    </Helmet>
    {
      showDisclamer
        ? <Disclamer
          acceptDisclamer={acceptDisclamer}
        />
        : <BrowserRouter>
          <ScrollToTop />
          <Layout>
            <Switch>
              <Route exact path='/' component={IndexPage} />
              <Route path='/node/163' component={NadolgoPage} />
              <Route path='/node/170' component={BonusYiiha} />
              <Route path='/node/126' component={SynesthesiaPage} />
              <Route path='/node/124' component={RecommendationsPage} />
              <Route path='/node/144' component={PoetryPage} />
              <Route
                path='/node/:id(62|63|64|65|66|69|70|75|82|97|98|99|104|107|118|132|155|157|159|161|165|168|172)'
                component={BonusArtImagePage}
              />
              <Route
                path='/node/:id(87|88|89|90|91|92|93|110|111|112|113|114|116|117|120|121|122|125|128|129|130|131|133|134|135|136|137|138|139|140|141|142|143|145|146|147|148|149|150|151|152|153|154|174|175|176|177|178|179|180)'
                component={FanartImagePage}
              />
              <Route path='/node/181' component={GlassesPage} />
              <Route path='/node/182' component={CosplayPage} />
              <Route path='/node/:id' component={NewsArticlePage} />
              <Route path='/archive' exact component={ArchiveIndexPage} />
              <Route path='/archive/:date' exact component={ArchivePage} />
              <Route path='/taxonomy/term/:term' exact component={TaxonomyPage} />
              <Route path='/faq' component={FaqPage} />
              <Route path='/characters' component={CharactersPage} />
              <Route path='/bonus-text' component={BonusTextPage} />
              <Route path='/bonus-taurese-phonology' component={BonusTauresePhonologyPage} />
              <Route path='/bonus-levels' component={BonusLevelsPage} />
              <Route path='/art' component={BonusArtPage} />
              <Route path='/feedback' component={FeedbackPage} />
              <Route path='/fanart' component={FanartPage} />
              <Route path='/authors' component={AuthorPage} />
              <Route path='/guestbook' component={GuestBookPage} />
              <Route path='/book' exact component={ContentsPage} />
              <Route path='/book/:chapter' component={BookPage} />
              <Route path='/download' component={DownloadPage} />
              <Route path='/NO_MERCY.pdf'
                // @ts-ignore
                render={() => {
                  window.location.reload()
                }}
              />
              <Route path='*' component={PageNotFound} />
            </Switch>
          </Layout>
        </BrowserRouter>
    }
  </>
}

export default App;
