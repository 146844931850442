import React from 'react'
import { IBookPage } from './index'

const chapter17: IBookPage = {
  header: 'header_d6.jpg',
  title: 'Глава 17. Не заслуживает жалости',
  prevLink: 'chapter16',
  nextLink: 'chapter18',
  content: <>
    <h2>Университет. Охрович и Краснокаменный</h2>

    <img alt='' src="/images/17_okhrovich_i_krasnokamennyi.jpg" style={{float: 'left', margin: '0 10px 10px 0'}} />
    <p>На часах 07:46</p>

    <p>&nbsp;</p>

    <p>— Решка, грифон, грифон, грифон.</p>

    <p>— Метелин? Не-не-не. Два Метелина
      за одну неделю — дурная примета, кто-нибудь точно пулю словит, а мы тут делом
      заняты, исторические события переживаем.</p>

    <p>— Нда, и Гуанако под боком,
      Метелин кассах, Гуанако и кассахи, видишь связь?</p>

    <p>— Полезет прям под потолок
      сношаться с чучелом?</p>

    <p>— В <i>лучшем</i> случае. Ладно, перекидывай.</p>

    <p>— Грифон, грифон, решка, решка.
      Золотце. Что скажешь про фактор Гуанако?</p>

    <p>— Фактор Гуанако к кому угодно
      может на потолок полезть сношаться, его вообще не стоило брать в расчёт. Давай
      жилет.</p>

    <p>— Золотце —&nbsp;это казённый
      дом, власть, деньги и достойное внимания умение стрелять. Он же как раз
      Метелина учил.</p>

    <p>— Самое то для исторических событий.
      У нас кого-то надо научить стрелять?</p>

    <p>— Зачем плодить себе соперников,
      когда можно придержать всё оружие для себя и править, устрашая?</p>

    <p>— Можно поучить убегать от
      выстрелов. На практике. Револьвер Золотцу в этот раз заряжать будем?</p>

    <p>— В прошлый попался всего лишь
      жалкий студентишка, а вот сегодня есть шансы на крупную добычу. По-моему,
      стоит.</p>

    <p>— Где французские усики? Золотцу
      без усиков нельзя, Бюро Патентов покарает.</p>

    <p>— Небось Ройш стырил. У него <i>должны быть</i> тайные страсти.</p>

    <p>— Играет по ночам во французишку-детектива!</p>

    <p>— Больше-то ему по ночам делать
      нечего. Придётся рисовать, гнев Бюро Патентов в нынешней ситуации особо опасен.</p>

    <p>— Не на самом же чучеле,
      придурок, мы не хотим потом весь Революционный Комитет с усиками! Бумажку
      возьми.</p>

    <p>— Заряди револьвер помягче, мне
      жаждется крови.</p>

    <p>— Будет тебе кровь, вечером
      анализы у студентов. И никто не посмеет запретить нам взять столько, сколько мы
      захотим.</p>

    <p>— Ага. Скажи, а тебе никогда не
      хотелось трахнуть скопца? Эээ, нет, не то сказал, отмотали назад. Тебе никогда
      не хотелось, чтобы тебя трахнул скопец?</p>

    <p>— Чтобы ответить на подобный
      вопрос, надо сперва уточнить терминологический аппарат. Я у вас — как
      преподаватель истфака БГУ имени Набедренных у преподавателя истфака БГУ имени
      Набедренных — хочу спросить: скопец, оскопист или скопник?</p>

    <p>&nbsp;</p>

    <p>На часах было без четверти
      восемь, на кафедре истории науки и техники — тихо и безлюдно, Охрович и
      Краснокаменный. Как раз правильная обстановка для установки ловушек и зарядки
      револьверов. Чтобы потом обстановка разрядилась максимально эффектно.</p>

    <p>Право слово, тот стресс, который
      все переживают, вреден для печени и отвратительно сказывается на селезёнке.
      Вызывает раздражение слизистой и раздражительность на окружающих. Всем этим
      людям пора одуматься.</p>

    <p>Особенно Ройшу. Его чёрное
      сморщенное сердце, конечно, бьётся настолько через раз, что тишина отдаётся по
      всем коридорам истфака, но пусть расскажет это гэбне, когда они будут
      допрашивать его на предмет усиков!</p>

    <p>Клеящий невинность Ройш восседал
      в кафедральном конференц-зале (он пытался прорваться в преподавательскую
      вотчину, но кто бы его пустил) в ожидании Максима и/или Лария. Ройш был
      хороший, умный человек. Сунувшись в преподавательскую вотчину минут пятнадцать
      назад, он сказал, что ему нужно поговорить с кем-нибудь из гэбни, а Охрович и
      Краснокаменный сказали, что у них революционное чучело, а Ройш сказал, что ага,
      вот он и спрашивает, нет ли уже на кафедре кого-нибудь из гэбни, с кем можно
      поговорить. </p>

    <p>Хороший, умный человек.</p>

    <p>Но допросу про усики быть!</p>

    <p>Охровичу и Краснокаменному
      нравилась чума. На фоне студенческих страданий можно делать всё что
      заблагорассудится. Можно не колоть страждущим обезболивающее. Можно ездить
      вместе с медфаковцами по городу и вламываться в квартиры к заражённым. Можно
      при каждой встрече с Максимом напоминать ему, что всего один акт
      душеспасительной ебли с Габриэлем Евгеньевичем снял бы любую усталость. Можно
      пить сколько угодно твиревой настойки, у Лария всё равно её бесконечность.
      Можно подкрасться к Гуанако со спины и попытаться вернуть ему истинную красоту,
      отрезав морской хвост (пока не удалось, но работа в этом направлении ведётся).
      Все так замотаны в свою Большую Политику, что едва обращают внимание. Никому
      нет дела до заботливо надетых ряс настоящих скопцов (из мешковины, простого
      кроя, длиной до пупка, Охрович и Краснокаменный провели опросы). Никто не
      попадается в расставленные по факультету ловушки.</p>

    <p>Охровичу и Краснокаменному не
      нравилась чума.</p>

    <p>Чуме пора бы закончиться.</p>

    <p>ЧУМЕ ПОРА БЫ <b style={{ color: '#C00000'}}>ЗАКОНЧИТЬСЯ</b> СЛЫШИТЕ ДА</p>

    <p>Чёрное сморщенное сердце Ройша
      стучало так редко, потому что он был рептилией. Хладной рептилией, решившейся
      наконец подарить свои честь и совесть смертной теплокровной женщине и
      обнаружившей нехватку оной.</p>

    <p>Ройшу нужно вернуть его женщину,
      но достоин ли он этого?</p>

    <p>Он же неблагодарная медуза,
      которая не подарит в ответ ни тепла, ни любви.</p>

    <p>И не сделает никого счастливым.</p>

    <p>Пожалуй, да, имеет смысл устроить
      возвращение женщины.</p>

    <p>На кафедру вошёл Ларий — добрый
      Ларий, услужливый Ларий, готовый помочь всем и каждому Ларий.</p>

    <p>3</p>

    <p>2</p>

    <p>1</p>

    <p>Так и есть — направился к
      чайнику. Моторная фиксация. Возможно, интересный предмет исследования.
      Возможно, нет.</p>

    <p>Скорее нет.</p>

    <p>— Вижу, вы заняты важным делом, —
      беззаботно улыбнулся Ларий, кивая на чучело. — Долго ещё?</p>

    <p>— Своим вопросом вы, Ларий
      Валерьевич, оскорбляете Революцию, — ответили Охрович и Краснокаменный. — В нём
      имплицирована необходимость поспешности.</p>

    <p>— Где бы мы сейчас жили, если бы
      Золотце торопился в Четвёртом Патриархате?</p>

    <p>— Он напутал бы в ингредиентах,
      правительственным желудкам не понравилась бы его еда, и они непременно
      раскусили бы, что шпион спрятался среди поваров.</p>

    <p>— И оскопили его.</p>

    <p>— И всех его друзей.</p>

    <p>— И врагов.</p>

    <p>— С учётом того, что его
      заклятыми врагами были они сами, эта тактика впоследствии была бы названа
      историками «что за херня».</p>

    <p>— Вы бы сделали на этом достойную
      карьеру, — хмыкнул Ларий и ненавязчиво сменил тему, — а сегодня, между прочим,
      много дел. К вечеру будет готова первая часть лекарства, а значит, с
      освободившимися студентами нужно что-то делать. Гуанако предложил определить их
      в бордель, а борделем заведуете вы, поэтому с сегодняшнего вечера торжественно
      передаю студентов в ваши заботливые руки.</p>

    <p>Щедрый дар. Хвост он не спасёт.</p>

    <p>— По-нашему, это слишком, —
      покачали головами Охрович и Краснокаменный. — Думаешь, они уже смогут?</p>

    <p>— В слабых, едва стоящих на ногах
      от потери крови работниках определённо есть некая фишка, но сколько они
      продержатся?</p>

    <p>— Мы не собираемся делать
      поблажек.</p>

    <p>— Всё это шарлатанство с
      лекарством и так дало им несанкционированный отгул.</p>

    <p>— Думаем, ты понимаешь, что мы не
      могли не пошутить про работу в борделе.</p>

    <p>— Ты же любишь нас именно за наше
      остроумие.</p>

    <p>— И принципиальность.</p>

    <p>— Твёрдость убеждений.</p>

    <p>— Каждый раз, когда звучит слово
      «бордель», мы обязаны шутить про шлюх.</p>

    <p>— Таково наше послушание.</p>

    <p>— Священный обет.</p>

    <p>Ларий улыбался, но на самом деле
      его этот поток речи раздражал. Это повод ещё немного поговорить и пошутить.</p>

    <p>Охрович и Краснокаменный ещё
      немного поговорили и пошутили.</p>

    <p>— Это было первое, —&nbsp;не
      выдержал Ларий. —&nbsp;Второе —&nbsp;у Ройша, он как раз делится соображениями
      с Максимом. Ночью ездил в Хащину, вроде как что-то узнал. Не хотите
      присоединиться и послушать?</p>

    <p>На такое и отвечать нечего. Вот
      Охрович и Краснокаменный, вот недоделанный Золотце, какие могут быть вопросы?</p>

    <p>Разве они не <b style={{ color: '#C00000' }}>объяснили</b> уже, что торопить
      украшение революционного чучела —&nbsp;кощунственно?</p>

    <p>КОНТРРЕВОЛЮЦИЯ КАРАЕТСЯ</p>

    <p>— Как знаете, —&nbsp;самодовольно
      пожал плечами Ларий, — но они там <i>ругаются</i>.</p>

    <p>Сволочь. Ловушка. ЛоВуШкА!</p>

    <p>И они попались. Теперь
      краеугольная жажда послушать ругань Максима и Ройша зовёт туда. Но
      профессиональная гордость —&nbsp;велит остаться тут и продолжить. Но если долго
      давить жажду, будет икота и дурной запах изо рта. Но если вскочить и побежать,
      кто-нибудь стырит ещё и револьвер в дополнение к усикам.</p>

    <p>Ларий, захлопнув ловушку, вышел.</p>

    <p>Из конференц-зала бессловесно
      слышалось гудение Максима и не слышалось Ройша. Потому что Ройш говорит тихо.
      По длине пауз ничего не вычислишь.</p>

    <p>Усики пришлось-таки нарисовать на
      бумажке и прилепить к морде чучела слюнями. На кого спланируют — тому будет
      счастье в новом году.</p>

    <p>Студентов в бордель?</p>

    <p>Не по домам, потому что нельзя
      ходить?</p>

    <p>&nbsp;&nbsp;&nbsp;&nbsp;Не по домам, потому что тайна?</p>

    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Не по домам, потому что может
      потребоваться ещё кровь?</p>

    <p>Страшные люди — Университет. Как
      их не любить.</p>

    <p>Охрович и Краснокаменный
      приставили стремянку к стене. Залезли, продели руки чучела в предназначенные
      именно и только для них петли. Направили револьвер в сторону входа. Подумали,
      позволили часам Золотца вольготно свисать из кармана.</p>

    <p>Пусть будет нотка лёгкой
      небрежности.</p>

    <p>Подумали, стащили один сапог и
      водрузили на стол Максима.</p>

    <p>Умеренной громкости нотка.</p>

    <p>Подумали, взяли со стола Лария
      кипу каких-то бумаг и аккуратно, по одной просунули их под дверь запертого
      завкафского кабинета.</p>

    <p>Ларий слишком самодовольно
      хихикал себе под нос, когда выходил. Гордыня порочна.</p>

    <p>Все дела сделаны?</p>

    <p>Все дела сделаны, можно и Максима
      с Ройшем удостоить внимания. Или удостоить вниманием? Нет, кажется, всё-таки
      внимания.</p>

    <p>Охрович и Краснокаменный вышли из
      преподавательской вотчины и симметрично подперли косяки двери кафедрального
      конференц-зала.</p>

    <p>— Может быть, я всё-таки лучше
      знаю, занимаются фаланги решением вопроса или нет?</p>

    <p>Это Максим. Он похож на
      автопоезд, выжавший тормоз, но всё равно катящийся. Потому что оказался на
      уклоне в 30 градусов. Или даже 45. И теперь он катящийся. Хотя лучше <i>катющийся</i>, катющийся смешнее. Катющийся
      с зубовным скрежетом и искрами из-под колёс. Очень старается, но всё равно
      только стирает тормоза окончательно.</p>

    <p>А всего-то и надо — отдаться.</p>

    <p>Максим не посещал бордельный
      инструктаж (<b style={{ color: '#C00000' }}>шутить
про шлюх</b> —&nbsp;священный обет, надо поддерживать репутацию).</p>

    <p>Максим <i>не заслуживает</i> жалости.</p>

    <p>— Максим, вы не слушаете, так что
      я вынужден повторить ещё раз. Я уже понял, что фаланги не выдавали вам
      эксплицитной информации относительно своего решения нашего вопроса. Тем не
      менее, наличие Силового Комитета в Хащине ясно говорит о том, что высокие
      уровни доступа так или иначе действуют. Мой вопрос, соответственно, звучит так:
      как вы <i>оцениваете</i> шансы того, что они
      действуют хоть в какой-то степени в наших интересах?</p>

    <p>А это Ройш. Много слов, мало слов
      на «ия-ий-ие» или «изм». Предложения короче минуты. Да он страдает!</p>

    <p>Надо ему об этом рассказать.
      Пусть ЗНАЕТ, что он СТРАДАЕТ.</p>

    <p>Ройш неспособен придти к
      соглашению со своим внутренним миром.</p>

    <p>Ройш НЕ ЗАСЛУЖИВАЕТ жалости.</p>

    <p>— Всё это звучит подозрительно и
      невнятно. Студентка Шухер попала в хащинскую больницу в тяжёлом состоянии, её
      забрал Силовой Комитет, да? Без фаланг, без извещения кого бы то ни было, узнав
      о ней раньше нас? И все ваши доказательства —&nbsp;слова какого-то врача!
      Помилуйте, вы понимаете, как легко купить одного врача?</p>

    <p>Это снова Максим, не Ларий. Ларий
      сел на край стола, сложил руки на груди и участлив. Зачем иметь своё мнение,
      когда есть столько других. Хороших и разных.</p>

    <p>Ларий знает, к чьему мнению
      примкнуть.</p>

    <p>Ларий не заслуживает (? —&nbsp;а
      есть поводы?) жалости.</p>

    <p>— Я не опираюсь исключительно на
      слова; врач передал мне некий предмет, доподлинно принадлежавший Брови и
      находившийся среди её вещей всё последнее время. Фотографию Габриэля
      Евгеньевича, подписанную её рукой и предназначавшуюся её хащинской подруге,
      если быть точным. Я полагаю это достаточным доказательством пребывания Брови в
      Хащине.</p>

    <p>Максим зафыркал.</p>

    <p>Обидно говорить с рептилией, да,
      да, да? У него на всё есть ответ. ВСЕГДА есть ответ. Он Ройш, он создан таким.</p>

    <p>— Я не замечал никаких изменений
      в поведении фаланг — им как было, так и остаётся плевать на нас.</p>

    <p>В кафедральных дверях
      нарисовалась новая фигура. Это Дима, человек-который-не-спит-третьи-сутки.
      Человек-который-не-определился-виноват-он-в-исчезновении-Брови-и-других-радостях-или-нет.
      Дима никогда не в курсе, что и зачем он делает. Привык полагаться на других.
      Привык доверять всем подряд, потому что не хватает мозгов запомнить, кто
      хороший, а кто плохой.</p>

    <p>Дима <b style={{ color: '#C00000' }}>не заслуживает</b> жалости.</p>

    <p>— Неважно, — это всё ещё Ройш,
      хладнокровный и кривомордый. — Вполне допуская возможность того, что выясненная
      мной информация о Силовом Комитете является ложной, я, тем не менее, полагаю,
      что мы лишены возможности рисковать. Эпидемия грозит окончательно выйти из-под
      контроля, она более не может оставаться тайной. К вечеру сегодняшнего дня мы
      получим лекарство — это тот самый момент, когда следует официально объявить о
      чуме. Поскольку фаланги отказываются обнаруживать своё знание происходящих
      событий, наиболее разумным решением представляется официально уведомить о чуме
      Бедроградскую гэбню.</p>

    <p>Сумасшедший Ройш, с гиканьем
      бросающийся в атаку?</p>

    <p>ДА ПОЖАЛУЙСТА ЕЩЁ КУСОЧЕК</p>

    <p>— Радикальное предложение, — это
      Дима, человек-которого-никто-не-спрашивал, — и нет, я вообще-то зашёл только
      для того, чтобы сообщить, что лекарство будет часам эдак к семи-восьми вечера.
      Но, кажется, тут всем не до того, так что вы продолжайте, продолжайте.</p>

    <p>Ройш скосил на него глаза. Ройшу
      не нравится спорить, Ройшу нравится, когда всё делают по-ройшевски.</p>

    <p>Охрович и Краснокаменный,
      поразмыслив, сложили пальцы домиками в знак поддержки.</p>

    <p>Им тоже нравится, когда всё
      делают по-ихнему.</p>

    <p>—&nbsp;Ещё один советник, — от
      Максима почти повалил пар. — Раз уж явился, порадуй нас — сегодняшнего
      лекарства хватит? Это то, что можно предъявить фалангам в качестве решения
      проблемы? Это то, что будет выглядеть убедительно?</p>

    <p>— Возможно, ты хотел спросить
      «это то, что может спасти население Бедрограда?». Ответ: не знаю. Первой порции
      на всех точно не хватит. Тетрадку с арифметикой дома забыл, завтра принесу.
      Будет ли оно вообще работать — одному лешему известно. Должно. Иначе нашу жизнь
      пора признавать довольно безрадостной.</p>

    <p>— Ты <i>не знаешь</i>.</p>

    <p>— Не то чтобы запихивание твири в
      студенческие жопы было моим жизненным интересом. По крайней мере, со стороны
      запихивающего.</p>

    <p>Сейчас Максим встанет на дыбы и
      измордует его до смерти, РАДОСТЬ КАКАЯ</p>

    <p>— Ты не знаешь, и, тем не менее,
      взялся за всё это.</p>

    <p>Ну или сдержится.</p>

    <p>КрЕтИн (лучше: ДеБиЛ, должно
      заканчиваться тоже большой буквой, иначе как-то нехудожественно).</p>

    <p>Сдерживать порывы порочно. Ведёт
      к несварению и коликам.</p>

    <p>— Предложи лучший вариант, гений
      стратегической мысли.</p>

    <p>А Дима красивый, когда злится.
      (Фу, <i>какая пошлость!</i>) Ну не красивый,
      но сразу видно, что достаточно только разозлить, чтобы ебать всем раёном.</p>

    <p>Самозабвенно ругается.</p>

    <p>Охрович и Краснокаменный ценили
      такое в людях.</p>

    <p>Хотя нужно смотреть на мир
      оптимистично. Постоянное желание не давать воли эмоциям — это тоже неплохо.</p>

    <p>отрицание</p>

    <p style={{ fontSize: '16.0pt' }}>напряжение</p>

    <p style={{ fontSize: '18.0pt' }}>внутренний конфликт!!</p>

    <p>Красота.</p>

    <p>— Откуда мне знать? Это твой
      вирус.</p>

    <p>— По последним сводкам — всё же
      Бедроградской гэбни.</p>

    <p>— Ты <i>издеваешься</i>? — Максим таки вскочил, забегал по конференц-залу
      (вместо того, чтобы дать виновнику по лицу — или это эксклюзивная мера для
      Габриэля Евгеньевича?). — Никто не просил ни тебя, ни Гуанако влезать в
      университетские дела. Вы никто, ходячие мертвецы. Вам достаточно сесть на
      корабль, свалить снова в степь, истаять в тумане — и всё, взятки гладки.
      Расхлёбывать — нам, Университетской гэбне.</p>

    <p>— Ага, все наши — особенно
      рвущего жопу на тельняшки Гуанако — действия так и кричат о том, что мы мечтаем
      развести здесь говн побольше и сгинуть безнаказанными. Валил бы ты с такой
      фекальной фиксацией в Бедроградскую гэбню!</p>

    <p>Ну про фекальную фиксацию — это
      Дима зря. Сам рассказывал, как его пытали бесконечным сортиром (! — отличная
      история, заперли на много дней в летнем клозете, каждое утро — новые сигареты и
      ничего больше, все условия для безумия!) и какой отпечаток это оставило.</p>

    <p>Неизгладимый отпеч��ток.</p>

    <p>Он, наверное, и в чуму вписался
      только ради канализационного дерьма.</p>

    <p>Это <b style={{ color: '#C00000' }}>Любовь</b>.</p>

    <p>Максим ходил ходуном. Сочленения
      расхлябывались на глазах.</p>

    <p>— Ты, Дима, не знаешь, будет ли
      лекарство и сработает ли оно. Ты, Ройш, не уверен в том, что Силовой Комитет
      был в Хащине, но имеешь много мнения о том, чем нам всем заниматься. Гуанако
      вообще даже не пытается сделать вид, что помнит, что его пребывание на
      университетской территории — <i>одолжение</i> с нашей стороны, мы не обязаны были пускать к себе скрывающегося человека.
      Может, пора бы уже спросить, что думает обо всём этом Университетская гэбня?</p>

    <p>Может, пора бы уже напомнить
      Максиму, что т. н. «Университетская гэбня» — не он один, а четыре человека?</p>

    <p>Нет, рано.</p>

    <p>— Хорошо, —&nbsp;преклонил голову
      Ройш. — И как же, по мнению Университетской гэбни, разумно поступить?</p>

    <p><i>Ой, это же он про нас!</i></p>

    <p>Это же Охровича и Краснокаменного
      спрашивают, что они думают!</p>

    <p>Повод выразительно молчать.</p>

    <p>ДО ПОРЫ ДО ВРЕМЕНИ.</p>

    <p>Максим шумно выдохнул.</p>

    <p>— Съездить в Хащину. Проверить
      все возможные и невозможные уголки. Точно выяснить, был ли там Силовой Комитет.
      По крайней мере, в качестве первого шага.</p>

    <p>— Бессмысленная трата времени, —
      кисло выдавил Ройш. — Лучшее, что мы получим, — ещё одно непроверяемое
      подозрение. Их и так уже слишком много.</p>

    <p>— Я согласен, — это Ларий
      изогнулся, кивает. — Силовой Комитет — профессионалы, записку на двери не
      оставят. К тому моменту, как любой из нас доберётся до Хащины, ни следов, ни
      свидетелей уже точно не будет. Встрече гэбен пора быть безотносительно ситуации
      с Бровью — мы уже почти сделали лекарство, чем скорее мы официально объявим, что
      нашли эпидемию и готовы с ней бороться, тем лучше. Мы и так зашиваемся со
      студентами. По-моему, лучше не разбрасываться.</p>

    <p>Дима закусил губу. У него всё ещё
      нездорово блестели глаза (воспоминания о дерьме?). Но в целом — успокоился.
      Очень старается быть взрослым.</p>

    <p>Видели Охрович и Краснокаменный,
      как он от полбутылки с лестницы летает.</p>

    <p>И не вырастет никогда, потенциал
      отсутствует.</p>

    <p>РАДОСТЬ.</p>

    <p>— А по-моему, Максим прав,
      —&nbsp;с сожалением выдал Дима. — До лекарства ещё часов двенадцать — целый
      день. Информация с пылу с жару, Ройш только вернулся. По-моему, как раз очень
      разумно попытаться съездить в Хащину. Вас, больших политиков, мои сопли не
      слишком интересуют, а я бы вот, к примеру, не отказался найти Бровь. Или хотя
      бы её бренные останки.</p>

    <p><i>Отличная</i> тактика. Показной гуманизм заставляет всех почувствовать
      вину.</p>

    <p>И не думать о том, виноват ли
      гуманист.</p>

    <p>Одобрение.</p>

    <p>И да, лучше всего согласиться с
      Максимом. Он слишком упивается своей позицией униженного. Встал в позу «делайте
      по-моему просто потому, что в прошлый раз не делали по-моему».</p>

    <p>— Мы поедем, — постановили
      Охрович и Краснокаменный.</p>

    <p>— Здесь кошмарно душно, нам нужен
      свежий воздух.</p>

    <p>— К тому же мы сами любим бренные
      останки.</p>

    <p>— Они вселяют вдохновение и
      наполняют радостью бытия.</p>

    <p>— И мы ведь можем пострелять
      Силовой Комитет и их приспешников, если те окажут сопротивление.</p>

    <p>— Это был не вопрос, если что.</p>

    <p>Ларий беззлобно покачал головой. <b style={{ color: '#C00000' }}>Ещё бы</b> он воспротивился!</p>

    <p>Ройш сделал вид, что не услышал.</p>

    <p>УВЕЛИЧЕНИЕ ДЕЦИБЕЛЛОВ ВПЕРЁЁЁЁЁД</p>

    <p>— Вам следует вернуться до семи
      вечера, — сломался (<i>все</i> ломаются!!)
      Ройш. — Я настаиваю на том, что встреча гэбен необходима. Нам до сих пор
      неизвестно, вернулся ли в Бедроградскую гэбню Андрей Зябликов, на нейтрализацию
      которого было потрачено столько сил наших столичных помощников.</p>

    <p>НеЙтРаЛиЗаЦиЮ.</p>

    <p>Размечтался!</p>

    <p>Наши столичные помощники (отважный
      вирусолог, ревизор печального образа и загадочный дармоед с подозрительно
      знакомой фамилией) могут сколько душе угодно нейтрализовывать Андрея Зябликова,
      да только Хуй им Вилонский, а не.</p>

    <p><i>Нейтрализация</i> бывает исключительно четвёртого, и осуществлять её —
      удивительно, правда? — имеют право исключительно трое других. Наших столичных
      помощников, положим, тоже трое (было), но не любые же трое же подходят в
      комплект к четвёртому же!</p>

    <p>Впрочем, затея <i>ничего такая</i>: подставить самого
      сомнительного четвёртого в Бедроградской гэбне под свет софитов и тем
      деморализовать троих. Осветителями поработали те самые помощники, а дальше уж или
      грифон, или решка, извините-подвиньтесь, строгая дизъюнкция.</p>

    <p>Если Бедроградская гэбня откажется
      от встречи 4х4, потому что её до сих пор 3, Бедроградская гэбня не то что
      жалости, она вообще ничего-никогда-атата не заслужит.</p>

    <p>ПОТОМУ ЧТО ВТРОЁМ-ТО НЕ СЛУЖАТ
      ВОТ И ПОПАЛИСЬ РОДИМЫЕ</p>

    <p>Ну, это идеальный вариант:</p>

    <p>1. Андрей у фаланг или изгнан из
      гэбни с позором =&gt; гэбня без Андрея неполноценна;</p>

    <p>2. Неполноценная гэбня официально
      отказывается от официальной встречи =&gt; можно вприпрыжку бежать к фалангам;</p>

    <p>3. Фаланги льют слёзы (*они сами
      сделали неполноценной гэбню), но <i>уже
        поздно</i>.</p>

    <p>А у нас и болезнь выявлена, и
      лекарство набодяжено, и книга отзывов и предложений на нужной странице открыта.</p>

    <p>Красота.</p>

    <p>Если пункт (1)
      не выполняется, уже не красота, но тоже неплохо. Возьмёмся за руки 4х4 и
      оставшимися на свободе конечностями пойдём лечить город вместе. <i>И пусть попробуют
        отказаться. </i>На ПРОТОКОЛЬНЫХ мероприятиях не отказываются!</p>

    <p>А Ройш всё гундит себе и гундит —
      это он Охровича и Краснокаменного убеждает, да? В том, что и без него ясно, да?
      Слепой, да?</p>

    <p>— …неизвестно, применены к ним ли
      какие-то санкции, планируют ли они продолжать. Простой способ выяснить —
      потребовать официальной встречи. А для этого, как всем нам известно,
      Университетская гэбня <i>тоже</i> должна присутствовать
      в полном составе. Повторяю: вам следует вернуться до семи вечера.</p>

    <p>Максим отвернулся. Обидно говорить
      с рептилией, да, да, да?</p>

    <p>Обидно, когда разумные решения
      принимаешь не ты один, да, да, да?</p>

    <p>Или даже совсем не ты.</p>

    <p>Гэбня не гэбня.</p>

    <p>— Теперь, когда все со всем
      разобрались и меня готовы послушать, —&nbsp;поведал повисшей тишине Дима, —
      самое время сообщить вам, что лекарство будет готово к семи-восьми вечера. А то
      я ведь пришёл, чтобы это сказать, но раньше никто не слушал. Всем было не до
      того.</p>

    <p>Охрович и Краснокаменный мысленно
      (вслух — не дождётся) одобрили Димину драматическую наблюдательность.
      Наблюдательность — да, драматическую — нет, драматизма должно быть БОЛЬШЕ.</p>

    <p>Например, так: утро шестого дня
      чумы в Бедрограде началось с того, что всем было не до чумы в Бедрограде.</p>

    <p>Потом следовало бы сказать:
      дальнейшие варианты развития событий пугали.</p>

    <p>Или не пугали.</p>

    <p>Скорее не пугали.</p>
  </>
}

export default chapter17
