import React from 'react'
import { IBookPage } from './index'

const chapter34: IBookPage = {
  header: 'header_d9.jpg',
  title: 'Глава 34. Слишком длинная, неправдоподобная и сопливая байка',
  prevLink: 'chapter33',
  nextLink: 'chapter35',
  content: <>
    <h2>Университет. Гуанако</h2>

    <img alt='' src="/images/34_guanaco.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>Леший, насколько же хотелось
      отключиться!</p>

    <p>Гуанако ворочался на блядской
      койке Святотатыча и старательно не волновался за Диму.</p>

    <p>То, что на блядской койке
      Святотатыча Дима обнаружен не был, — ещё ничего не значит.</p>

    <p>То, что Святотатыч отводил глаза
      и изо всех сил придумывал себе занятия поинтересней
      расспросов, с Охровичем и Краснокаменным ли уехал Дима, — тем более ничего не
      значит.</p>

    <p>То, что Святотатыч пошёл кому-то там
      звонить в другую каморку, хотя в этой тоже есть телефон, — ничего, ничегошеньки
      не значит.</p>

    <p>Может, день такой.</p>

    <p>Воскресенье, 19 сентября 1883
      года, девятый день чумы в Бедрограде.</p>

    <p>Все ёбнулись.</p>

    <p>Гуанако ещё раз повернулся на
      блядской койке Святотатыча и сообразил: браслет!</p>

    <p>Блядский браслетик-батарейка,
      стимулятор из натуральных степных продуктов. Вгоняет натуральные продукты
      шипами в кровь, не даёт валиться с ног.</p>

    <p>Ноги недовольно зачесались под
      сапогами — Гуанако впервые за всю чуму нацепил браслет ночью с шестого на седьмой
      день, после всяких там жидкостей из кранов в доме Габриэля Евгеньевича. На ногу
      и нацепил, чтобы Дима не заметил, а то он и так злоупотребляет — нечего
      подкреплять его зависимость дурным примером старших. Непедагогично.</p>

    <p>Дима, впрочем, заметил почти сразу,
      но Гуанако всё равно признал лодыжки более удачным местом для браслетов, чем
      запястья. Руки сейчас не для того нужны, чтобы в степных аксессуарах
      красоваться. Зацепится браслет за что-нибудь — неудобно же может выйти.</p>

    <p>Ноги недовольно чесались, но снимать
      браслет таки не следовало. Вырубит ведь нахуй на порядочное количество часов.</p>

    <p>Если бы Дима был тут, можно было
      бы даже позволить себе такой увлекательный отдых — всего-то полдень,
      Бедроградская гэбня ждёт «реальную университетскую власть» только к ночи. А
      важные дела переделаны: репутация Максима и Университета в Порту поправлена,
      положение Порта — ну, гм — поправлено.</p>

    <p>Кое-как.</p>

    <p>На средства Виктора Дарьевича из Медицинской гэбни и состоятельных студентов из
      контрреволюционного выпуска.</p>

    <p>Это пиздец. Виктор Дарьевич из
      Медицинской гэбни так порадовался скопцам и наличию собеседника, компетентного
      в теории и методологии науки, что отвалил бабла.
      Состоятельные студенты из контрреволюционного выпуска так обрадовались
      предоставленной Охровичем и Краснокаменным возможности сделать что-то в память
      о давным-давно сгинувшем в экспедиции профессоре, что отвалили бабла.</p>

    <p>Порт вот тоже не так давно отвалил
      бабла. Примерно с той же мотивацией.</p>

    <p>И чем это для Порта закончилось?</p>

    <p>Чумой, блокадой, обезлюдевшими
      пристанями.</p>

    <p>Гуанако рывком поднялся с
      блядской койки Святотатыча.</p>

    <p>Поспать не выйдет — чума, блокада,
      обезлюдевшие пристани. Ещё разок искупаться, что ли?</p>

    <p>Нет.</p>

    <p>Разумнее и дальше торчать рядом с
      телефоном и ждать вестей от Димы или от того, с кем он там отсюда уехал. И не
      трезвонить самому, хотя хочется.</p>

    <p>Что трезвонить, что ещё разок
      искупаться.</p>

    <p>Гуанако любил море.</p>

    <p>Гуанако любил море, Порт,
      Бедроград, Бедроградскую область, Всероссийское Соседство (всё то Всероссийское
      Соседство, где успел побывать), Европы (все те Европы, где успел побывать),
      Ирландию, Северную Шотландию, даже Колошму.</p>

    <p>Хотя почему «даже»?</p>

    <p>Колошма в степи, степь охуенная,
      на самом первом допросе Гуанако попросил Савьюра дать ему подойти к окну попялиться на степь (в камере-то окон нет), Савьюр разрешил
      — и ка-ак покатилось!</p>

    <p>Потому что вечно так
      складывается, что Гуанако любит <i>всё</i>,
      что видит. Возможно, это какой-то дефект организма, но Гуанако-то только за.</p>

    <p>Так жить веселее.</p>

    <p>Гуанако посмотрел на телефон,
      полюбил его немного про себя и улёгся обратно любить потолок.</p>

    <p>Отличный, между прочим, потолок —
      изрисованный гипнотическими узорами из трудно опознаваемых хуёв, задниц, торсов,
      рук, губ и каких-то других частей тела. Чего
      ещё можно желать?</p>

    <p>Искупаться, леший.</p>

    <p>Погода-то издевается — вдарила по бесконечным дождям теплынью под конец чумы.</p>

    <p>Гуанако любил и дожди, но вот
      такая теплынь, не летняя, а ветреная и неожиданная — это ж как есть
      бедроградский апрель.</p>

    <p>А апрель — это ж <i>до мая</i>.</p>

    <p>Гуанако тряхнул головой и
      предпочёл ещё попредаваться фантазиям о том, как
      хорошо было бы лишний раз искупаться.</p>

    <p>В Пассажирском Порту, да.</p>

    <p>&nbsp;</p>

    <p>Он делал это раньше, но давным-давно
      и всего единожды. Опасно: большие корабли, маленькие катера, оживлённое
      движение, а главное — блядские таможенники, которые бдят.</p>

    <p>В грузовых бухтах всё гораздо лучше.
      Движение ещё оживлённей, краны вертятся постоянно, что-нибудь непременно
      срывается, падает в воду, но там-то как раз таможенники давно на всё забили.
      Там нет не-портовых, приличия соблюдать не надо.</p>

    <p>Поэтому все, кого припёрло, а больше всех — дети, мечтающие податься в юнги,
      плавают себе в грузовых бухтах. Это весёлая игра — лавировать в круговерти
      движущегося металла, избегать встречи с винтами и тросами, бороться с волной.</p>

    <p>В детстве Гуанако из-за этой игры
      мог сутками не возвращаться в отряд. Отрядские педагоги капали батюшке, батюшка
      устраивал выволочку, но на выволочку было плевать,
      потому что напротив батюшкиной квартиры жил сосед-моряк — старый, дряхлый,
      татуированный и без ноги. Не избежал когда-то встречи с винтом.</p>

    <p>Иногда сосед-моряк даже ковылял
      до Порта, хотя переселиться туда снова почему-то не хотел. В каком-то совсем
      смешном возрасте, в котором ещё можно без труда прятаться от батюшки аж под его
      рабочим креслом, сосед-моряк согласился впервые
      взять с собой в Порт Гуанако.</p>

    <p>И всё, дороги обратно не было.</p>

    <p>Порт — это на всю жизнь, ёба.</p>

    <p>Батюшка чуть не прибил Гуанако
      тем самым рабочим креслом.</p>

    <p>Какой Порт? Вот инженером
      корабельным — это да!</p>

    <p>Батюшка был плохим корабельным
      инженером, но хорошим заведующим целым бюро корабельных инженеров. Других вариантов
      для своего сына он не видел и видеть не желал.</p>

    <p>Только с сыном ему повезло
      меньше, чем тому же давешнему Шухеру с дочкой.</p>

    <p>Гуанако с
совершенно подкресельного возраста был в курсе, что
вся эта Революция и детские отряды не для того делались, чтоб батюшка мог им
командовать, как это обычно бывает в отсталых Европах (пропаганда и идеология
спасут мир!).</p>

    <p>С пятнадцати лет любой человек во
      Всероссийском Соседстве — совсем самостоятельный гражданин и сам решает, в Порт
      ему или не в Порт. До пятнадцати пришлось изворачиваться, потому что кое-какие
      права у батюшки всё же имелись. Но у отрядских педагогов они тоже имелись, а
      надувать батюшку при помощи педагогов, а педагогов при помощи батюшки — это ж
      как нехуй.</p>

    <p>Мёртвый идеолог Сергей Корнеевич
      Гуанако ещё на заре своей короткой жизни знал, что его государство (несмотря на
      все свои промахи) идёт правильной дорогой!</p>

    <p>И сам шёл в Порт мечтать податься
      в юнги.</p>

    <p>С подачей в юнги не вышло. К
      пятнадцати гуанаковским годам, когда это стало
      возможным, батюшка подсуетился и — здравствуй, старый приятель Шухер! — сделал
      ему через знакомых фальшивый отвод по медицинским показаниям.</p>

    <p>Это никуда не годилось.</p>

    <p>Сам Гуанако в пятнадцать лет
      медицинских или бюрократических знакомых не имел и потому остался в дураках. Портовые плевали на
      бумажный закон, но врачей уважают всерьёз. Нельзя — значит, нельзя. А юнг
      проверяют по бумажкам ого-го
      как — кому охота, чтоб у него дитё на корабле померло?</p>

    <p>Гуанако усмехнулся не то
      самодовольно, не то печально: ну прям как с новобранцами в Северной Шотландии!</p>

    <p>К тому же помер сосед-моряк (не
      на корабле, в своей квартире). И батюшка подсуетился ещё немного, нашел
      знакомых в Распределительной службе, и та распределила Гуанако не какую-нибудь
      случайную жилплощадь, как это происходит со всеми гражданами Всероссийского
      Соседства в пятнадцать лет, а очень, очень неслучайную. Квартиру напротив
      батюшки, в которой помер сосед-моряк, она ему распределила.</p>

    <p>Поближе, чтоб сторожить.</p>

    <p>Пятнадцатилетний
Гуанако такого блядства спустить не мог, плюнул на жилплощадь в Бедрограде и
всё равно свалил в Порт, хоть и не в юнги.</p>

    <p>В досье у него написано, что два
      года до Университета он работал в Порту грузчиком. Грузчиком он тоже работал,
      было дело, но сначала поступил куда более пятнадцатилетне
      — устроился в бордель (а Охрович и Краснокаменный ещё смеют возмущаться, что
      Гуанако соответствующий инструктаж не проходил, ха-ха).</p>

    <p>Про бордели в досье не пишут,
      потому что во Всероссийском Соседстве их нет. Да и пробыл там Гуанако всего
      какую-то жалкую неделю, которую только на инструктаж и хватит.</p>

    <p>А через неделю его оттуда
      буквально за шкирку забрал Святотатыч. Фактически первый полноценный клиент
      (леший, стыд-то какой!).</p>

    <p>О Портовой гэбне Гуанако тогда
      ещё ничего не знал, но всё равно просёк, что это у Святотатыча метод такой —
      чтоб свои люди были действительно своими, надо их самому и растить. Чем младше,
      тем лучше (хотя никто сильно младше старшеотрядского
      возраста к нему не попадал — при контролируемом деторождении дети на дороге не
      валяются, даже на портовой дороге).</p>

    <p>Святотатыч и растил, блядский
      недоделанный педагог.</p>

    <p>Его инструктажи были пожёстче бордельных. Именно Святотатыч заставил как-то
      Гуанако искупаться в Пассажирском Порту. Не в блокаду, когда всем похуй, а в
      нормальный рабочий день. Поместил на нижнюю палубу отбывающего через пару часов
      парохода какую-то ювелирную цацку, сказал: принесёшь —
      пойдёшь в контрабанду. Не принесёшь — на таксиста выучу.</p>

    <p>Это было весело. На пассажирское
      судно (пассажирское судно для законопослушных граждан с выездными документами!)
      портовым вход заказан. Есть персонал, но в
      Пассажирском Флоте сплошь приличные люди, которых таможня знает в лицо до
      последнего кока. И в багаж не сунешься — таможенники каждый шов на чемоданах
      проверяют, о содержимом и говорить нечего.</p>

    <p>Короче, Гуанако поплыл. Под
      водой, чтоб не заметили. Под водой мимо блядских прогулочных катеров с
      блядскими винтами, на которых иностранцы любуются видами Бедрограда с воды (идиоты,
      Бедрограда с воды не видно, только Радиовышку; всё,
      что видно с воды, — это Порт).</p>

    <p>Как он не утонул, непонятно. Но,
      наверное, с тех самых пор страха смерти и нет. Просто нет, хотя случались
      поводы побояться. Какой к лешему страх смерти, когда есть пароход, на пароходе цацка, и, если она там и останется, Святотатыч не возьмёт
      пятнадцатилетнего ребёнка в контрабанду?</p>

    <p>И каждый раз, когда случается
      повод побояться, обязательно находится своя метафорическая цацка,
      метафорическое желание раздобыть которую начисто отбивает все прочие
      трепыхания.</p>

    <p>Святотатыч — прекрасный
      недоделанный педагог.</p>

    <p>Но — недоделанный, поэтому не
      бояться <i>чужой</i> смерти он Гуанако так и
      не научил.</p>

    <p>Многочисленные приползания Святотатыча в эту самую блядскую каморку с
      ножевыми, пулевыми, леший-знает-какими ранениями стабильно вызывали у Гуанако
      одну-единственную реакцию: <i>только не
        сегодня, пожалуйста!</i></p>

    <p><i>Слышишь меня, блядина, не сегодня! Сегодня я тебя не отпущу, не отпущу
      и всё, и прекрати хрипеть, меня это не впечатляет. Не хрипи, а улыбайся,
      блядина, скоро будет врач. Врач,
      обезболивающее, алкоголь и трава, и мы будем пить, пить и трахаться, не мотай
      головой, улыбайся. Улыбайся и говори, блядина, помолчишь ты потом. Не сегодня.</i></p>

    <p>Это, в общем, относится к любым
      чужим смертям (кроме тех, которые инициировал сам Гуанако). Обещание пить и
      трахаться — не к любым, но можно и к любым, если оно вдруг чем-то поможет (чего
      не сделаешь ради «не сегодня»).</p>

    <p>Убивать-то легко, это тоже
      метафорическая цацка на метафорическом пароходе, а вот смотреть, как пытаются
      умереть у тебя перед носом, — трудно.</p>

    <p>Что-то совсем дурацкое подаёт в
      такие моменты голос из глубин: неужто совсем ничего нельзя сделать?
      Ничего-ничего, вы уверены? А если так? А если ещё как-нибудь? А если встать на голову,
      снять штаны, достать из-под земли толпу скопцов и сам Вилонский Хуй? А если
      постараться, поднапрячься ещё немного?</p>

    <p>Ну вот видите, а вы говорили.</p>

    <p>Наверное, это тоже педагогическая
      заслуга Святотатыча — всегда помнить, что сдаваться нельзя. Что всё можно, если
      очень нужно. Что всё получится, если не сидеть, сложив лапки.</p>

    <p>Через пару лет в Порту со
      Святотатычем Гуанако перестал складывать лапки над своей липовой справкой о
      негодности к плаваниям и уплыл (матросом, не юнгой даже) на Курёхине, громадном
      дореволюционном грузовом чудище.</p>

    <p>Перепил молодого матроса,
      которого ещё толком не видела команда, потоптался в задумчивости над его
      бессознательным телом, спёр-таки документы — и привет.</p>

    <p>Это тоже было весело, очень
      весело, только закончилось грустно.</p>

    <p>Курёхин вернулся в Порт через
      полгода. Счастливый, довольный и повзрослевший Гуанако вернулся к Святотатычу.
      Показать татуировку с именем первого корабля на лопатке, ну и вообще.</p>

    <p>Вообще не вышло, да и про
      татуировку Святотатыч сказал, что она дерьмо. Его личные рабы-мастера с первого
      этажа кирпичного домика сделали бы лучше.</p>

    <p>А если Гуанако такой умный, чего
      б ему в Университет не податься?</p>

    <p>Как раз семнадцать лет
      исполнилось в плаванье, уже можно высшее образование получать.</p>

    <p>Гуанако охуел, но вышел вон.</p>

    <p>Послали — так послали, ну и леший
      с этим. С этим, и с вот тем, и ещё с контрабандой, и с цацкой на пароходе, и с пить-и-трахаться, и с чем угодно вообще.</p>

    <p>На лестнице по дороге вон Гуанако
      нашёл серебряный перстень с чёрным треугольным камнем (тот, который давно у
      Димы). Думал вернуться, отдать находку, но семнадцать лет немногим лучше
      пятнадцати, поэтому — перстень на палец и бегом в Университет.</p>

    <p>Потому что Святотатыч-то пошутил,
      но Гуанако-то может! Пусть утрётся.</p>

    <p>Становиться профессором с мировым
      именем, чтобы Святотатыч утёрся, в планы Гуанако не входило. Но где мы и где
      планы, какие планы в этой весёлой жизни, когда сегодня ты пишешь вступительное
      эссе про какую-то хуйню, завтра — курсовик про аскезные,
      послезавтра — про имперских скопцов, а там и до диплома про эффект Четвёртого
      Патриарха недалеко. А потом благодаря твоему диплому к хуям разваливается твоя
      же кафедра истории религии, и ты с горя напиваешься, а приходишь в себя уже в
      засиженных кассахскими снайперами северо-шотландских
      горах, и рядом с тобой твой раненый командир, и, кажется, у тебя после всего
      этого вовсе не будет зрения, но это же не повод складывать лапки. И можно было
      остаться в армии, но зрение падает и падает, ты лежишь себе на лазаретской койке,
      размышляешь о судьбах малых народов и
      невзначай порождаешь свою кандидатскую и докторскую, которые пойдут потом на
      растопку идеологической печи, потому что Хикеракли уже стар и далёк от теории и
      методологии науки, а государству, делающему такие крупные ставки на научный
      прогресс, страсть как нужен кто-то, кто объединил бы в идеологических книжонках
      политику и науку.</p>

    <p>А Святотатыч-то пошутил про
      Университет.</p>

    <p>Только потом, примерно в святотатычевском тогдашнем возрасте, Гуанако вдруг
      врубился, почему.</p>

    <p>Потому что когда с тобой рядом
      есть кто-то, кто ощутимо младше тебя, это только поначалу ты учишь его всяким
      глупостям и твёрдо знаешь, что нельзя отпускать — пропадёт ведь без тебя. Зато
      стоит этому кому-то научиться всему необходимому, приходит поганое чувство:
      всё, экзамены сданы, дальше он как-нибудь сам. Приходит поганое чувство, что он
      <i>хочет</i> дальше как-нибудь сам. Нельзя
      же всё время сидеть на месте, надо что-то делать, зачем-то бежать, надо куда-то
      плыть на Курёхине — а ты не можешь, ты старый-и-скучный.</p>

    <p>То есть Святотатыч не может. У
      него тот самый диагноз, который был проставлен в липовой справке от батюшки
      Гуанако. Доплавался он уже до диагноза и дотонулся.
    </p>

    <p>Гуанако узнал перед самой
      Колошмой, когда пришёл поплакаться, что его собственный мальчик, кажется, хочет
      дальше как-нибудь сам. Узнал, охуел, рефлекторно спрятал под стол руку с
      перстнем с треугольным камнем.</p>

    <p>Ну и поржали же они тогда.</p>

    <p>А через пару дней — Столичная
      гэбня: вербовка или Колошма. И Гуанако не то чтобы прямо настолько не хотел
      вербоваться, но вербоваться — это точно на ближайшие годы застрять безвылазно в
      Университете, а Диме доучиваться как раз эти самые ближайшие годы, а Диме так
      хочется побыть самостоятельным, а не мальчиком-при-Гуанако, что лучше уж
      Колошма, чем вербовка.</p>

    <p>И всё было, конечно, <i>не совсем так</i> и уж точно <i>не только так</i> (контрреволюционные
      студенты, сваливаемый на них подрыв Первого Большого Переворота), это Гуанако
      сейчас драматизирует для целостности картины.</p>

    <p>Но очень <i>не без этого</i> всё было.</p>

    <p>И ведь живут же где-то люди,
      которые не верят в дурной фрайдизм (да сам Гуанако первый не верит, когда
      нормальный, но не когда Димы почему-то нет на блядской койке Святотатыча, а
      телефон всё не звонит!).</p>

    <p>Колошма — это отдельный пиздец.</p>

    <p>Степняки говорят «дурное место»,
      а оно не дурное, это фрайдизм — дурной, а место — заколдованное. Пять-шесть
      допросов, двое суток в камере вместе с Начальником Колошмы, сорвавшаяся рука у
      охранника, который эти двое суток охранял начальников кабинет, — и пожалуйста,
      скандал на весь госаппарат.</p>

    <p>Начальника Колошмы <i>(Савьюра — самую мягкую, лёгкую и лечебную
      из наркотических трав степи)</i> Гуанако из головы вряд ли когда-нибудь
      выкинет.</p>

    <p>Потому что.</p>

    <p>Потому что, если человек с
      двадцати пяти до сорока пяти лет ни разу нормально не улыбался, а тебе удалось
      так пошутить (встать на голову, снять штаны, попросить пистолет застрелиться),
      чтоб его всё-таки прорвало, — пойди попробуй ещё выкинуть там что-нибудь из
      головы.</p>

    <p>И закрыли эту тему.</p>

    <p>О таком можно было разве что с Хикеракли
      разговаривать, который заехал как-то на Колошму, к Гуанако в гости. Сам
      Хикеракли тоже хорош: налил стародавних революционных соплей про волосы цвета
      дорогой твиревой настойки и предложил в этой луже заняться политикой.
      Пристроить студентов из контрреволюционного выпуска, ага.</p>

    <p>Потрепались за жизнь, а
      закончилось всё гэбней прямо в Университете.</p>

    <p>А ещё через пару месяцев из-за
      этой гэбни прямо в Университете, которая не приглянулась гэбне прямо в
      Бедрограде, на Колошме оказался Дима.</p>

    <p>Не самостоятельный и не взрослый,
      а испуганный и несчастный, потому что страшно, потому что не верится, потому
      что ему устроили психологические воздействия, которые только в дурном месте
      Колошме и возможны. А ещё потому что в блядских расшифровках блядских двух
      суток в камере с Савьюром — сплошная радиопьеса, где
      всем сначала очень плохо, потом очень хорошо, а потом кто-то умирает, а кого-то
      водят целовать трупы.</p>

    <p>И пойди попробуй ещё объясни, как
      так.</p>

    <p>И пойди попробуй ещё проснись
      среди ночи и сообрази без очков, чья башка у тебя на плече. Не свихнись от
      обилия соплей в жизни, пойди попробуй. Пойди-пойди.</p>

    <p>За год ходить пробовать
      становится легче, но приезжает миленький-славненький Андрей, поднявшийся с
      гэбни Колошмы до Бедроградской гэбни, и опять чего-то хочет. Гуанако ему
      когда-то уже последнюю тюремную робу отдал — подписал признание в подготовке
      преднамеренного убийства Савьюра, — а ему всё мало, миленькому-славненькому.</p>

    <p>Университет ему подавай.</p>

    <p>За Университет Гуанако сам
      порвётся на тюремную робу, но Андрей был настойчив, и у него был Дима. И рычаги
      давления: аллергия на твирь, неравнодушные к кассахским
      шлюхам младшие служащие, безымянные заключённые, которых можно вдоволь
      стрелять.</p>

    <p>И аппаратура у него была эта
      самая, записывающая и воспроизводящая изображение.</p>

    <p>На безымянных заключенных Гуанако
      сломался (ну не на младших же служащих ему ломаться — превосходная порнография,
      глаз радуется!).</p>

    <p>Дима не умеет убивать, и учить
      его бесполезно.</p>

    <p>Не надо его учить.</p>

    <p>Гуанако сломался и наплёл Андрею
      какой-то полуправды про то, что с Университетом можно сделать. Был бы Андрей
      поумнее, он бы додавил, задал бы нужных вопросов, разглядел бы несостыковочки. А
      так — остался с полуправдой, из которой
      выгоды извлечь не удалось.</p>

    <p>Может, и разглядел бы, и задал
      бы.</p>

    <p>Но тут — опа,
      степная чума.</p>

    <p>Дальнейшее Гуанако довольно
      подробно расписывал давеча Социю, только в одном приврал: заточка в Димину
      спину воткнулась хуёвенько — все выжили, кроме втыкателей. Ну, рука отнялась, так это бывает.</p>

    <p>А стрёмно было как раз так, как
      расписывал Социю.</p>

    <p>Очень стрёмно.</p>

    <p>Потому что степная чума, а у тебя
      в камере даже завалялись недюжинные запасы алкоголя и сигарет (стараниями уже
      почившего Хикеракли), и охранники к тебе привыкли, они тебя помнят по истории с
      Начальником, они тебя в обмен на твои богатства выпустят в блядскую степь, но
      Дима-то не пойдёт. Дима что-то придумал в плане лекарства, Диме кажется, что он
      вот-вот поймёт, как лечить неизлечимую степную чуму, Дима подался в
      добровольные санитары, Дима носится со своими изоляторами, с больными и со
      здоровыми, Дима поубивал из-под палки безымянных заключённых и теперь Диме
      хочется спасать других безымянных заключённых.</p>

    <p>Это бред и пиздец, надо валить, с
      каждым днём чумы шансов свалить всё меньше, но Дима говорит: «Пожалуйста, ещё
      чуть-чуть» (совсем как когда просыпал в твоей кровати твои же лекции) — и ты
      (совсем как когда уходил на лекции один) соглашаешься. Только не уходишь, ведь «вернуться
      вечером» ты уже точно не сможешь.</p>

    <p>Соглашаешься на блядское
      «пожалуйста, ещё чуть-чуть».</p>

    <p>Соглашаешься, потому что и твой
      батюшка в прошлом, и Шухер в будущем очень неправы, а прав — Святотатыч.
      Соглашаешься, потому что забота, помимо всего прочего, означает не отнимать
      возможность делать то, чего хочется. Даже если это «чего» — бред и пиздец, и
      надо валить, и с каждым днём чумы шансов свалить всё меньше.</p>

    <p>Когда дело доходит до
      огнемётчиков, ты не жалеешь. Ты чистишь табельный пистолет тошнотворно
      благородного Сепгея Борисовича (он тоже неравнодушен к кассахским
      шлюхам?) и вслух пошагово разжёвываешь план действий, которого скорее нет, чем
      есть, но с простейшей-то техникой безопасности в экстремальных ситуациях Диму
      давно пора было ознакомить.</p>

    <p>И вдруг понимаешь: даже если всё
      кончится дерьмом, ты всё равно не пожалеешь. Ты проверил на прочность свою веру
      в то, что каждый сам решает, что ему делать со своей жизнью. Ты не стал ни
      твоим батюшкой, ни Шухером.</p>

    <p>Наверное, потому, что ты очень
      влюблён.</p>

    <p>И неважно, что тот же блядский
      Габриэль Евгеньевич в сто раз красивей, а тот же блядский Святотатыч — в сто
      раз умней (а про Савьюра лучше просто помолчать).</p>

    <p>Неважно.</p>

    <p>Просто твой собственный мальчик
      вырос и выучился (хоть и чему-то совершенно не тому), и ты смотришь на него как
      идиот, забывая заканчивать фразы (жизненно важные, про технику безопасности).</p>

    <p>Наутро ты маскируешься тряпками
      под больного, проверяешь в последний раз обоймы, плетёшься бок о бок с
      настоящими больными на бойню, дышишь под тряпками твиревыми
      парами, ждёшь обрушения стены изолятора, палишь по огнемётчикам
      и прорываешь кордоны <i>таким идиотски
        счастливым</i>, что умереть там и тогда у вас бы просто не получилось.</p>

    <p>Потом таким же идиотски
      счастливым идёшь по степи, вынимаешь Диме заточки из спины, думаешь, куда
      податься дальше.</p>

    <p>Пока ты думаешь, идиотски
      счаст��иво проходят семь лет, но это отдельная книга этнографических зарисовок
      про степь, её не уложишь в пару коротких мыслей (признайся, ты всё ещё любишь
      писать книжки с этнографическими зарисовками, религиоведческими построениями и
      историей мира от начала времён!).</p>

    <p>Начало времён стучится к вам
      само, оно в земле прямо под Колошмой, куда вы попадаете по случайности, сбегая
      с насиженного места от врывающейся цивилизации.</p>

    <p>В начале времён, в —
      существующем! настоящем! — Вилонском Хуе добрая тысяча злых скопцов, и это уже
      не книжка с этнографическими зарисовками, это блядский приключенческий роман
      для детей старшеотрядского возраста. Скопцам надо
      что-то сказать, чтобы уйти от них живыми и, гм, целыми. И ты изо всех сил
      вспоминаешь сгинувшую кафедру истории религии, скармливаешь им сказки про
      явление божества и пророчества, заодно отправляя делегацию паломников в
      Бедроград (в буйстве безумия и десоциализации нашёлся
      потрясающе адекватный дед, который ещё помнил дореволюционный Петерберг и его
      местонахождение относительно Хуя).</p>

    <p>Ты просто хотел порадовать истфак
      скопцами — ты же не думал, что они доберутся только через несколько месяцев,
      что они доберутся так вовремя и что ты и будешь перепродавать их то студентам,
      то медикам, чтобы лечить в Бедрограде и в Порту Димой придуманную водяную чуму.</p>

    <p>Выбравшись от скопцов, вы
      понимаете, что тоже хотите в Бедроград.</p>

    <p>Ненадолго, надолго не стоит — вы
      же мёртвые, но глянуть одним глазком-то хочется!</p>

    <p>А в Бедрограде всё ломается.</p>

    <p>Не из-за пару раз тоскливо
      вздохнувшего Габриэля Евгеньевича — какая хуйня.</p>

    <p>Из-за того, что в Бедрограде ты
      теряешься, не находишь себе места, чувствуешь себя ходячей байкой, которая
      только и способна, что сама себя пересказывать. И видишь, что Диме это не
      нравится.</p>

    <p>Дима слышал и видел все эти
      байки, Дима хочет начать уже делать что-то новое.</p>

    <p>А ты — наконец-то нарисуй себе
      звёздочку на борту! — стал для него старым-и-скучным.</p>

    <p>И ты намекаешь ему, предлагаешь
      ему по-человечески, но он отпирается, Святотатычево
      «послать ребёнка в Университет» тут не срабатывает — и приходится действовать
      радикально.</p>

    <p>Ты мешаешь какую-то коктейльную
      бодягу на кухне у гостеприимных Габриэля Евгеньевича и Максима, Габриэль
      Евгеньевич заходит за чайником, в благоговейном ужасе смотрит на то, как ты
      пускаешь в бодягу венчики тобою же принесённых зелёных роз (брал у Жудия, Жудий
      любит цветочки, у него в теплицах помимо кактусов с наркотой и розы — зелёные,
      и черёмуха цветёт хоть в сентябре). Посозерцав твой
      циничный вандализм, Габриэль Евгеньевич впадает в своё вечное жизнь-всегда-хуже-чем-могла-бы-быть.</p>

    <p>А с тобой бы она была лучше.</p>

    <p>Как бы не так, блядь.</p>

    <p>Но ты жалеешь его, жалеешь
      Максима, который столько лет был вынужден мириться с воспоминаниями о тебе,
      жалеешь Диму, которому с тобой скучно, но привычно, и потому он не может
      решиться уйти, и делаешь <i>это.</i></p>

    <p>Вспоминаешь, как хорош собой
      Габриэль Евгеньевич, как вообще-то неплохо (кто бы мог подумать!) вам с ним
      было когда-то давно, как круто, когда благодаря тебе он не ноет хотя бы пару
      часов после ебли, — и предлагаешь ящик самогона и всех обратно.</p>

    <p>Ты ведь знаешь, что это временно,
      что Габриэлю Евгеньевичу больше нравится ныть, чем не ныть, и поэтому он
      вернётся к Максиму обновлённым, но не ноющим хотя бы о тебе. А Дима за это
      время как раз куда-нибудь приткнётся, и поначалу ему будет очень грустно, но
      потом он <i>займётся</i> <i>уже чем-то
        новым</i>, чем-то, чего ты не
      можешь, — и всем будет хорошо.</p>

    <p>И да, тебе много раз говорили,
      что ты постоянно лезешь решать за других. Но ты лезешь и лезешь, лезешь и
      лезешь, и нет никакого спасения — хоть со скопцами в Хуе оставайся и тренируй смирение.</p>

    <p>Но к скопцам тебе стрёмно, а
      потому, когда Дима приткнулся в Столице у Сепгея Борисовича и Медицинской гэбни
      (Ройш донёс, понимающий человек), а Габриэль Евгеньевич, пресытившись
      ирландскими холмами, выдал ожидаемое «всё, наигрались», ты в последний раз
      рассказал кому-то свои старые и скучные байки и пошёл вешаться.</p>

    <p>ПОТОМУ ЧТО ХВАТИТ УЖЕ, ЭТО И ТАК
      СЛИШКОМ ДЛИННАЯ, НЕПРАВДОПОДОБНАЯ И СОПЛИВАЯ БАЙКА!</p>

    <p>Святотатыч сказал, что
      разочарован в тебе, в себе и — больше всего — в давнишней команде с Курёхина.</p>

    <p>Кто ж, мол, так узлы вяжет, чему,
      мол, ты вообще учился. Отправить бы тебя, мол, после этой оборвавшейся верёвки
      ещё раз на Курёхина — узлы вязать. Хочешь, мол, через пару дней как раз будет
      оказия.</p>

    <p>Вязать узлы.</p>

    <p>Ты согласился, придавленный
      грузом драматического охуения, но вспомнил, что после неудавшихся самоубийств
      имеешь привычку делать странные вещи с головами каких-нибудь гэбен. Выученный
      вдоль и поперёк Святотатыч тебе решительно не подходил, и ты сообразил, что
      Ройш недавно как раз рассказывал про абсолютно неизвестного тебе Гошку из
      Бедроградской гэбни, который под личиной младшего служащего интересовался у
      какой-то девочки Ройшевым уровнем доступа.</p>

    <p>И это, конечно, не твоё дело, но почему
      бы не попробовать помочь, раз уж ты не умеешь вязать узлы?</p>

    <p>Святотатыч сказал, что у этого
      Гошки в Порту есть женщина, которая очень расстроится, если через столько лет
      знакомства узнает, что водила его с представителем городской власти. И что
      женщина давно уже хочет открыть свой собственный бордель, а этот Гошка — жмот,
      который не даёт ей денег из городского бюджета, хотя мог бы. И что есть
      возможность поведать ей об этом хоть сейчас.</p>

    <p>Ты поржал.</p>

    <p>Тебе было пусто, неловко из-за
      вязания узлов и решительно некуда приткнуть себя на пару дней до Курёхина,
      поэтому ты с бухты-барахты придумал хорошую шутку и сам же над ней поржал.</p>

    <p>Так хорошо поржал, что сегодня
      вечером тебе придётся смотреть в глаза и этому Гошке, и Андрею с Колошмы, и
      Социю из Северной Шотландии, и их тавру без косы, который с другим составом
      Бедроградской гэбни допрашивал тебя в студенческой юности про наркоту.</p>

    <p>Смотреть в глаза и вместе решать,
      что теперь со всем этим — с Бедроградом, Портом, Университетом — делать.</p>

    <p>По возможности — не вспоминая,
      как петляло и извивалось течение загробного допроса, заворачивало на совсем уж
      неожиданные территории, чтобы казаться непринуждённым и естественным. Как у
      тебя вдруг спрашивали (просто так, разговор зашёл): «…Хорошо, а, например, этот
      херов Андреев 66563, когда помер, в чём Загробной гэбне каялся, о чём
      рассказывал?»</p>

    <p>«О чём-о чём, — отмахивалась Загробная
      гэбня в твоём же лице, — о своих мужиках, конечно».</p>

    <p>О чём ещё, блядь, на загробном-то
      допросе!</p>

    <p>И ведь сложись у тебя с мужиками
      всё чуть иначе — не бывать ни допросу этому, ни плану контратаки, порождённому
      по его следам, ни прочим подвигам. Стрёмно.</p>

    <p>А плаванье на Курёхине и
      возвращение на берег прямиком к началу чумы (Святотатыч числа подогнал,
      блядина?) ты сейчас и вовсе малодушно пытаешься выкинуть из своей слишком
      длинной, неправдоподобной и сопливой байки.</p>

    <p>Потому что опять — на Курёхине
      было весело, очень весело, а потом раз — и Святотатычева
      каморка, перстень с треугольным камнем, только тебе не семнадцать, а сорок
      один, и ты уже не можешь при встрече с очень важным человеком начать взахлёб
      вещать, как именно тебе было весело где-то там без него.</p>

    <p>А объяснять, как ты на самом деле
      скучал, как ты был во всём сразу неправ и какой ты после этого мудак, ты вообще
      никогда не умел.</p>

    <p>Мудак.</p>

    <p>Но если очень напиться, можно
      поиграть в карты сначала на раздевание тела Габриэля Евгеньевича, а потом и на
      перстень с треугольным камнем — который твой очень важный человек так жаждал
      тебе вернуть, раз уж встретились.</p>

    <p>И ты пропускаешь за этим занятием
      всё на свете: объяснения, что там за план с чумой и как он должен сработать,
      явление Максима, явление девочки-без-диктофона, которой теперь нет, а ты её
      даже не разглядел. Тебе плевать — ты всё поддаёшься и поддаёшься в карты, чтобы
      Дима выиграл и перстень остался у него, но Дима играет хуёво, особенно
      нетрезвый, а карта тебе идёт крупная, ты уже и не знаешь, как ещё извернуться,
      но, выиграв-таки блядский перстень, просто надеваешь его обратно на Диму.</p>

    <p>Просто надеваешь и вырубаешься к
      лешему.</p>

    <p>&nbsp;</p>

    <p>Гуанако открыл глаза, потому что
      на лестнице стало как-то ну очень уж шумно.</p>

    <p>Заснуть с браслетом-стимулятором
      нельзя, а вот потонуть во всяких дурацких мыслях до потери ориентации во
      времени и пространстве — сколько угодно.</p>

    <p>Лучше б он в Пассажирском Порту
      поплавал, как хотел, а не в собственном прошлом!</p>

    <p>Скучная же байка, к тому же надоела
      до смерти.</p>

    <p>Шум на лестнице усиливался,
      готовясь ворваться в дверь Святотатычевой каморки.</p>

    <p>Гуанако на всякий случай протёр
      глаза и поднялся на ноги.</p>

    <p>— Все покупают, а он не купил.</p>

    <p>— Все готовы на благие дела ради
      ближнего своего.</p>

    <p>— Аж тошно от благодати и
      всеобщей любви!</p>

    <p>— Ну вот он и разрядил обстановочку.</p>

    <p>— Не купил.</p>

    <p>— Не купил, — говорила закрытая
      дверь всё приближающимися и приближающимися голосами Охровича и
      Краснокаменного.</p>

    <p>Если б не короткие реплики,
      выстраивающиеся скорее в монолог, нежели в диалог, Гуанако бы эти голоса не
      узнал. Слишком уж бесцветно они звучали.</p>

    <p>— Вы чего? — не утерпел Гуанако,
      сам распахнул дверь, выскочил на лестницу.</p>

    <p>Рассмеялся.</p>

    <p>Пошатнулся.</p>

    <p>Полез за самокруткой.</p>

    <p>Смял самокрутку в пальцах.</p>

    <p>Почему-то первым, что он заметил,
      был собственный похожий на студенческий мундир плащ. Так радовался ещё, когда
      увидел этот плащ в какой-то лавке: отличный крой, да и цвет отличный — тёмный,
      бордовый. Веселился: если что, крови будет не видно.</p>

    <p>Оказывается, видно.</p>

    <p>Столько крови не может быть не
      видно.</p>

    <p>— Сергей Корнеевич, вы удивлены?
      — устало спросил Охрович.</p>

    <p>— Мы тоже умеем лажать, — слишком быстро, почти перекрыв Охровича, выдохнул
      Краснокаменный.</p>

    <p><i>— Только не сегодня, блядь,</i> — одними губами прошелестел Гуанако,
      осёкся (сколько раз он повторял это в блядской Святотатычевой
      каморке?), посторонился, дав Охровичу и Краснокаменному внести Диму.</p>

    <p>Или уже не Диму.</p>

    <p>— Блядь, — гораздо твёрже
      высказался Гуанако.</p>

    <p>— Не блядь, а наглядная
      демонстрация того, чем хороши наши законы и плохо нелегальное оружие, ими
      запрещаемое, — поправил Охрович, пристраивая Димину голову на подушку.</p>

    <p>— От него бывают дырки в печени.
      И в ногах, да, — осмотрел Краснокаменный свою руку, только что расставшуюся с
      Диминой штаниной.</p>

    <p>Гуанако уже набирал номер
      мастерской на первом этаже, хотя проще было бы спуститься, но спускаться было
      стрёмно, выходить из каморки хоть на секунду было стрёмно, всё что угодно было
      стрёмно.</p>

    <p>— Да? — отозвался Святотатыч на
      леший-знает-какой раз.</p>

    <p>— Врача, срочно. Тяжёлый
      огнестрел, — Гуанако обернулся на Охровича и Краснокаменного, те немного
      недоумённо закивали.</p>

    <p>Огнестрел, огнестрел — водяная
      чума и прочие напасти выглядят по-другому.</p>

    <p>— Максим таки того твоего
      мальчика? — хмуро поинтересовался Святотатыч.</p>

    <p>Через телефонную трубку было
      слышно, как он придвигает к себе второй аппарат, нажимает кнопки и пережидает
      гудки.</p>

    <p>Максим?</p>

    <p>— Блядь, весь Университет — мои
      мальчики, — огрызнулся Гуанако.</p>

    <p>Максим, очень весело. Просто
      отлично.</p>

    <p>— Всё будет, жди.</p>

    <p>Гуанако посмотрел на замолчавший
      телефон. Конечно же, всё будет — у Святотатыча всегда всё схвачено, можно не
      париться, можно просто ждать.</p>

    <p>Охуенно, блядь.</p>

    <p>Охрович и Краснокаменный всё так
      же бесцветно помялись.</p>

    <p>— Двое ваших мальчиков сообщают
      вам, что ещё один ваш мальчик тоже неподалёку.</p>

    <p>— В машине. Снова связан, ему
      понравилось.</p>

    <p>— Желаете применить к нему
      воспитательные меры?</p>

    <p>— Или сначала к нам?</p>

    <p>Гуанако посмотрел на них с
      удивлением. Какие меры, леший.</p>

    <p>— Это, гм, спасибо. Вы его
      спасли.</p>

    <p>— Вы потрясающе оптимистичны, Сергей
      Корнеевич, — покосился на Святотатычеву койку
      Охрович.</p>

    <p>— Но даже если вы и правы, это
      всё равно не наша заслуга, — закурил Краснокаменный.</p>

    <p>Один закурил.</p>

    <p>Они совсем ёбнулись?</p>

    <p>— Это заслуга Хикеракли. И нашей
      сознательности в вопросе переодевания чучела.</p>

    <p>— Выкинутый монеткой Хикеракли
      позволяет проспать всего полтора часа.</p>

    <p>Они хотели сказать что-то ещё, но
      Охрович наконец заметил сигарету у Краснокаменного, как-то беспомощно дёрнулся
      и проглотил следующую реплику.</p>

    <p>— Да с вами-то что? —
      окончательно прихуел Гуанако. — Сдайте Максима
      Святотатычу и валите отсюда, блядь. Выспитесь, прогуляйтесь, отожмитесь по
      сорок восемь раз на каждой руке, переоденьте уже своё чучело, убейте
      кого-нибудь, только станьте нормальными обратно! Пожалуйста, блядь.</p>

    <p>Тело на Святотатычевой
      койке, связанный Максим в машине — это всё куда ни шло, рабочая ситуация, можно
      разрулить (вероятно). Но ёбнувшиеся Охрович и Краснокаменный — это, простите,
      как есть удар под дых.</p>

    <p>— Не драматизируйте, —
      воссоединился с сигаретой Охрович.</p>

    <p>— Мы крепче, чем кажемся, —
      подставил ему зажигалку Краснокаменный.</p>

    <p>— Необходимость разделиться во
      времени и пространстве не может нас уничтожить.</p>

    <p>— Это было бы слишком просто. И
      слишком выгодно нашим врагам.</p>

    <p>— Вы ещё не забыли, что у нас — у
      нас у всех — есть враги?</p>

    <p>— Не отвлекайтесь слишком на
      выходки друзей.</p>

    <p>— Это порочно.</p>

    <p>— Это мелочи.</p>

    <p>— Следует помнить о главном.</p>

    <p>— Главное случится ночью.</p>

    <p>— К ночи надо готовиться.</p>

    <p>— Нам — точно надо.</p>

    <p>— У нас ещё не найден розовый
      плюш.</p>

    <p>— У вас в Порту ведь найдётся
      розовый плюш?</p>

    <p>— Тогда мы пошли.</p>

    <p>— Не будем отвлекать вас от
      рыданий над телом.</p>

    <p>— Или нет, нам говорили, что обычно
      вы делаете с мёртвыми телами что-то другое.</p>

    <p>— Тогда тем более не будем
      отвлекать.</p>

    <p>— Мы сейчас неспособны полноценно
      присоединиться, так что нам тут делать нечего.</p>

    <p>Гуанако заулыбался как идиот:
      издеваются — значит, живы. Не очень изящно издеваются, но хотя бы метко.</p>

    <p>Живы, точно.</p>

    <p>— Сергей Корнеевич, последнее
      уточнение.</p>

    <p>— Нам вас ночью ждать, если
      мёртвое тело станет действительно мёртвым телом?</p>

    <p>Ха-ха.</p>

    <p>— Ждать, — отмахнулся Гуанако. —
      Потому что мёртвое тело никуда ночью не пойдёт в любом случае, а следовательно,
      для демонстрации реальной университетской власти во всей красе нам придётся
      задействовать запасной план. Я не могу не увидеть Ройша в женском платье!</p>

    <p>— В женском свитере с дурацкой
      брошкой, — затянулся три раза подряд Охрович.</p>

    <p>— Этими свитерами Максим и
      заманил мёртвое тело в своё логово, — никак не мог выбросить окурок
      Краснокаменный.</p>

    <p>— У Ройша — свитера, у нас —
      розовый плюш.</p>

    <p>— Вы подумали о себе?</p>

    <p>— Вы должны подумать о себе, или мы
      вас с собой не берём.</p>

    <p>— Иначе Бедроградская гэбня не
      сможет с чистой совестью расстрелять нас за нашу страсть к костюмированным
      вечеринкам.</p>

    <p>— И дешёвым приёмчикам.</p>

    <p>— Мы смирились с вашим подходом,
      на эту ночь нам потребуются дешёвые приёмчики.</p>

    <p>— Подумайте и об этом тоже, вы же
      большой специалист.</p>

    <p>Гуанако рассеянно кивнул.</p>

    <p>Ночь, Бедроградская гэбня,
      переговоры.</p>

    <p>Закрыть вопрос чумы в Бедрограде.</p>

    <p>Это нужно, это важно, это то, что
      давно пора было сделать.</p>

    <p>В Святотатычеву
      каморку вошли какие-то незнакомые люди, но Гуанако понял это только тогда,
      когда с лестницы его позвал потерянный Муля Педаль.</p>

    <p>Незнакомые люди склонились над
      койкой, захлопали надеваемыми резиновыми перчатками, зазвенели каким-то
      металлом, запахли спиртом.</p>

    <p>Муля Педаль позвал ещё раз.</p>

    <p>Охрович и Краснокаменный от души
      толкнули заторможенного Гуанако в сторону двери.</p>

    <p>Гуанако опять рассеянно кивнул и
      почти самостоятельно вышел из Святотатычевой каморки.</p>

    <p>За всё это время он так и не
      посмотрел на Диму.</p>

    <p>На плащ, на кровь, на странную
      обмякшую позу попялился достаточно.</p>

    <p>На Диму — нет.</p>

    <p>&nbsp;</p>

    <p>— Ты б поорал, а, — покачал
      головой Святотатыч, придвинул какую-то воняющую кофе бодягу прямо под нос
      Гуанако.</p>

    <p>— Это Дима мешал, — вклинился
      Муля Педаль. — Типа дефицитной тепер’ твиревой настойке
      — как это говорят? — ал’тернатива.</p>

    <p>— Ну ты ещё из меня слезу подави!
      — почти заржал Гуанако, но бодяги пригубил.</p>

    <p>Они сидели в комнатушке,
      заставленной до потолка бочками с — дефицитной теперь! — твиревой настойкой и
      пили бодягу, которую мешал Дима.</p>

    <p>Муля Педаль увязался пооправдываться: он-де не знал, он-де не догадался, он-де
      видел, как Дима оставлял записку, и думал, что всё в порядке.</p>

    <p>Он думал, что всё в порядке,
      потому что Гуанако-ж-сам-велел-отвезти-Максима-куда-тот-пожелает!</p>

    <p>Велел, да.</p>

    <p>И повелел бы ещё раз, если б
      можно было переиграть.</p>

    <p>— Схожу потолкую с пленником, —
      приготовился вставать Святотатыч.</p>

    <p>— Я сам, — Гуанако мотнул головой
      и залпом допил бодягу.</p>

    <p>Святотатыч посмотрел на него с
      сомнением.</p>

    <p>— Он меня сорок восемь раз
      переспросил, хочу ли я ему ствол давать, — усмехнулся Гуанако. — Сам явно не
      понял, чё сделал.</p>

    <p>— Ты его оправдыват’
      будешь? — ошалел Муля Педаль.</p>

    <p>— Будет, — как-то чересчур устало
      вздохнул Святотатыч, и все его пятьдесят с лишним лет нарисовались у него на
      лбу. — Гуанако, умнее будь. Если твой ёбаный Максим чего-то сам не понял,
      надави на него и употреби с пользой. Не всё тебе ему стелить помягче, пусть
      отрабатывает благодеяния.</p>

    <p>— Я не умею умнее, — забрал и Святотатычев стакан Гуанако. — Умел бы, меня б
      здесь не
      было.</p>

    <p>— Здесь бы ты и был, — возразил
      Святотатыч. — В Порту, в смысле, а не где ты там обычно шатаешься. Оставайся,
      а? Когда дерьмо закончится.</p>

    <p>Гуанако побродил взглядом по твиревым бочкам.</p>

    <p>— Куда я денусь, мне долги
      отдавать. Озьма вроде приумолк пока после даров от медиков, но я ему ещё до
      того обещал вписаться в одно крупное дельце.</p>

    <p>— Озьма тебя изъездит вдоль и
      поперёк за жалкую неделю финансовой поддержки. Не дури, не столько назанимал,
      чтоб теперь до края света грести, — Святотатыч тоже смотрел куда-то мимо. — Да
      и не о том я. Сам оставайся, ты же хочешь.</p>

    <p>Гуанако хотел.</p>

    <p>— Может, они меня ещё на британо-кассахский флаг порвут. Ночью-то.</p>

    <p>— И не такие не рвали.</p>

    <p>Гуанако встретился-таки глазами
      со Святотатычем, помычал, собираясь с мыслями, и всё же объяснил честно:</p>

    <p>— Да не знаю я, блядь, где мне
      оставаться, а где нет! Если ты мне дерьмовых врачей прислал — останусь. Если
      нормальных — не один же буду решать, что дальше делать.</p>

    <p>— А, — хмыкнул Святотатыч и
      плеснул в похищенный у него стакан ещё бодяги.</p>

    <p>— Она с наркотой, между прочим, —
      тоном крайне осведомлённого человека напомнил Муля Педаль. — Щас вскрыват’ начнёт.</p>

    <p>— Тогда я пошёл разговоры
      разговаривать, пока меня не размазало совсем, — обрадовавшись поводу, вскочил
      Гуанако.</p>

    <p>В том, что его сейчас на самом
      деле может что-то там размазать, он сомневался.</p>

    <p>Но повод же!</p>

    <p>Святотатыч и Муля Педаль за
      спиной обсуждали растущую популярность нового пойла среди озлобленного блокадой
      портового населения.</p>

    <p>Максим сидел в комнатушке
      напротив.</p>

    <p>Гуанако очень хотел навсегда
      остаться в Порту или, например, прямо сейчас сбежать в пассажирскую бухту и искупаться-таки,
      но вместо этого он решительно пнул дверь, ведущую к Максиму.</p>

    <p>— И чё это было? — заявил он с
      порога и, сползши по двери, уселся на корточки (бодяга с наркотой оказалась
      круче, чем можно было предположить).</p>

    <p>Максим молчал.</p>

    <p>Так как бодяга с наркотой
      оказалась круче, чем можно было предположить, молчание Максима пришлось к
      месту.</p>

    <p>Про таких хороших парней как
      Максим пишут скучные, плоские книжки без неожиданных подстав через каждые
      двадцать страниц. Фантастику с гражданским пафосом или что-то вроде.</p>

    <p>Потому что такие хорошие парни
      как Максим предсказуемы, а фантастическим сюжетам, разворачивающимся в
      запутанных реалиях выдуманных миров, для равновесия нужны предсказуемые герои.</p>

    <p>— Более предсказуемо было бы,
      если б ты палил по мне, — высказался в потолок Гуанако. — Ты меня удивил.
      Уважаю.</p>

    <p>Максим крякнул где-то там в
      недрах комнатушки.</p>

    <p>Один раз удивил — и хватит пока.
      Зато как!</p>

    <p>Те же Охрович и Краснокаменный,
      впрочем, не удивились.</p>

    <p>Охрович и Краснокаменный перед
      выездом на ритуал священного переодевания чучела позвонили Святотатычу, а тот
      сказал, что Димы нет, а сам он так забегался с аппаратурой, спизженной у
      Бедроградской гэбни (государственная символика очень уж почесала глубоко
      анархический менталитет Серьёзных Людей из Порта), что думал, Охрович и
      Краснокаменный Диму и увезли.</p>

    <p>Охрович и Краснокаменный подождали,
      пока Святотатыч выяснит, с кем последним видели Диму, и, услышав ответ,
      мгновенно рванули по квартирам Максима. По отдельности, ибо квартир две.</p>

    <p>У Охровича и Краснокаменного есть
      чутьё и нет <i>идиотской веры в лучшее</i>.</p>

    <p>— Ты б всё же дал какой-нибудь комментарий,
      — попросил Гуанако, сфокусировавшись-таки на Максиме.</p>

    <p>Максим как Максим, только мрачный
      и какой-то пустой. Привязанный к батарее целым букетом любимых узлов
      Святотатыча. Без морской подготовки такие даже со свободными руками не
      распутаешь.</p>

    <p>У Максима на правой руке совсем
      побелели пальцы, и Гуанако всё же принял кое-как вертикальное положение, потому
      что его вдруг очень припёрло ослабить Максиму петлю.</p>

    <p>Максим не понял, брыкнулся,
      выкрикнул:</p>

    <p>— Вы издеваетесь?!</p>

    <p>То есть не выкрикнул — вышептал скорее уж. Но в любом случае — «вы».</p>

    <p>Странно, непривычно, давно-это-было.
      Опять сбивается. Зачем?</p>

    <p>— Нет, — честно ответил Гуанако.</p>

    <p>Издеваться &shy;— это ходить в
      шелковой рубахе к бывшему командиру, например. Да и то.</p>

    <p>— Тогда
      пристрелите меня к лешему, — выплюнул («вы»!) Максим.</p>

    <p>— Приплыли, ёба.</p>

    <p>— Я не хочу
      разговаривать, объяснять, оправдываться. Не хочу.</p>

    <p>Гуанако вмазал
      ему с ноги куда-то в грудь. Несерьёзно, безо всякого желания.</p>

    <p>— Дай угадаю:
      так ты станешь общительней, потому что тебя задевает, когда по-хорошему.
      По-плохому проще?</p>

    <p>Максим мотнул
      тяжёлой головой, но вышло уже менее убедительно, чем раньше.</p>

    <p>— Прости, не
      сообразил сразу, — Гуанако картинно вздохнул, пытаясь вспомнить шелково-рубаховые ощущения. — Наверное, чтобы ты сам
      захотел мне что-то рассказать, я должен тебя демонстративно не слушать. Ты не
      умеешь по-человечески, ты же пресмыкаться любишь.</p>

    <p>Как желваки-то
      заходили, а.</p>

    <p>Нести всю эту
      чушь было неловко. Особенно неловко — потому что эффект, увы, наличествовал.</p>

    <p>— Только
      предупреждаю сразу: совсем уж эталонным Габриэлем Евгеньевичем морду воротить я
      не смогу, ага?</p>

    <p>— Перестаньте
      паясничать! — рявкнул Максим, и это вышло у него отлично.</p>

    <p>Этак преподавательски. Если не гэбенно.</p>

    <p>Гуанако даже
      задумался на секунду, от кого он чаще слышал в своей жизни «перестаньте
      паясничать» — от преподавателей или от разнообразных голов гэбен?</p>

    <p>— Это ты
      перестань, — ляпнул он. — Нечего уже, Дима-то откинулся.</p>

    <p>Максим глянул
      непонимающе, пошевелил губами.</p>

    <p>— Сделал, сделал
      ты, чего хотел. Теперь поведай, нахуя.</p>

    <p>— Он вас… тебя
      обманул, — явно начал считать половицы Максим. — Наверное. Он всё отрицал, но
      кто, если не…</p>

    <p>— Невнятно, —
      нога Гуанако опять проехалась по рёбрам.</p>

    <p>Ну почему,
      блядь, нельзя ответить, когда тебя спрашивают нормально? Зачем, блядь, обязательно
      все эти танцы устраивать?</p>

    <p>«Все эти танцы»
      — это Савьюр так говорил.</p>

    <p>«Оставим все эти
      танцы», потому что кому оно нужно, потому что и так уже всё ясно, потому что
      тратить время и силы на обманы и недоговорённости — глупо.</p>

    <p>Вспомнилось:
      когда Савьюр откинулся, хотелось проехаться ногой по собственным рёбрам. А
      лучше сразу по голове.</p>

    <p>— Вчера вечером
      я имел разговор с Бедроградской гэбней, — заявил Максим, и мигом стало
      неприлично весело. — Они приходили к Охровичу и Краснокаменному спросить меня,
      я ли заразил наш… дом на Поплеевской.</p>

    <p>Вот упорные.
      Гуанако же честно сказал: нет, не Максим.</p>

    <p>— Взгляд с
      другой стороны вправляет мозги, — Максим посмотрел на Гуанако (с другой
      стороны?) с вызовом. — Ведь это было бы так умно, <i>так практично</i> для Университета — заразить кого-то из своих. Если бы
      фаланги не наплевали на нас, если бы наш запрос к фалангам был сформулирован
      иначе, это стало бы первейшим аргументом для применения санкций к Бедроградской
      гэбне.</p>

    <p>— Ты так
      ухватился за эту мысль, потому раньше она не приходила тебе в голову и теперь открыла
      новые горизонты большой политики? — со всем ядом, на который он был способен,
      переспросил Гуанако.</p>

    <p>Гуанако ведь
      говорил им всем — Озьме, когда прошёл слушок, что Габриэля Евгеньевича в Порт
      транспортировал кто-то напоминающий Максима, Социю, когда он наседал, — кишка
      узка и размах крыльев не тот.</p>

    <p>Не умеют в
      Университете такие сделки с лешим проворачивать.</p>

    <p>— Я так
      ухватился за эту мысль, — строго, преподавательски-гэбенно
      отчеканил Максим, — потому что я всегда считал, что враг — вот он, с другой стороны.
      <i>Не рядом со мной.</i></p>

    <p>— Они в полном
      составе тебя навещали? — переключился Гуанако, и Максим почему-то опешил.</p>

    <p>Он что, правда
      думал, что они тут будут до глубокой ночи спорить о его эпических деяниях?</p>

    <p>— В полном, да.
      И не слишком трезвые — запах джина за ними так до утра и не выветрился.</p>

    <p>Разобрались-примирились?
      Ну и отлично, камень с души.</p>

    <p>— Жрать хочешь?
      — ещё ослабил верёвку Гуанако.</p>

    <p>Максим поднял на
      него непонимающие глаза.</p>

    <p>— Жрать.
      Принимать пищу. Говорят, это бывает уместно для поддержания физических сил.
      Попельдопель утверждает, что и моральных тоже.</p>

    <p>Предложить ему
      актинии слабой соли, из-за стоимости которых столько дерьма в четверг вылилось?</p>

    <p>— Я и
      представить не мог, <i>насколько же</i> вам
      плевать, — едва ли не обиженно выдал наконец Максим. — Я думал, у вас к Диме
      было хоть что-нибудь. Меньше, чем к Габриэлю, — и он это знал, и поэтому он
      заразил Габриэля… но чтобы <i>вот так?</i></p>

    <p>Гуанако порвало.
      Просто порвало.</p>

    <p>То ли это Димино
      наркотическое пойло, то ли это у Максима было слишком смешное лицо
      разочарованного отличника на первой сессии, который только что узнал, что можно
      не делать каждый день домашнюю работу и всё равно как-то жить, — но Гуанако
      буквально согнуло пополам от хохота.</p>

    <p>— Максим, ты
      всерьёз полагаешь, что когда «что-то есть», следует драть глотки зубами всем,
      кто косо посмотрел?</p>

    <p>— Я не косо
      посмотрел, я убил его!</p>

    <p>Все так легко
      ведутся на россказни о Диминой смерти, что, если ему приспичит умереть-таки в
      самом деле, никто ведь не поверит.</p>

    <p>Гуанако первый
      не поверит.</p>

    <p><i>Только не сегодня, пожалуйста, блядь.</i></p>

    <p>— Забей ты, пока
      вроде нет. Я спиздел.</p>

    <p>Максим сверкнул
      глазами, набычился:</p>

    <p>— Я никогда не
      смогу вас понять. Вас, Диму, если он действительно не заражал наш дом…</p>

    <p>— А хоть
      кто-нибудь хоть кого-нибудь понимает? — невесело усмехнулся Гуанако. — Я вот —
      точно никого. Но знаешь, мне достаточно просто знать, что бывают <i>другие
        люди</i>. Непохожие на меня, думающие
      и действующие иначе. Если держать это в голове, жить немного проще.</p>

    <p>— Мне незачем, —
      Максим обвис в верёвках. — Меня отовсюду вышвырнули, а Габриэль… о нём обещала
      позаботиться Бедроградская гэбня. И не обманула: Охрович и Краснокаменный
      сказали, что он уже в Медкорпусе.</p>

    <p>— Димиными
      стараниями. Бедроградская гэбня о Медкорпусе ничего пока не знает, — ляпнул
      Гуанако и пожалел: Максим совсем сдулся.</p>

    <p>— Так Дима
      всё-таки не хотел, чтобы… — вопрос застрял у него в глотке.</p>

    <p>— Откуда мне знать,
      — Гуанако пожал плечами и дошёл до двери, не сбившись с курса, что было уже
      достижением. — Всё, что мы вообще можем, — это верить. В лучшее. Иногда.</p>

    <p>— Но когда
      кругом столько врут…</p>

    <p>— Приходится
      бить себя по щекам и верить особенно яростно!</p>

    <p>&nbsp;</p>

    <p>Верить, да.</p>

    <p>Максим
      заканчивал кафедру истории науки и техники, а не истории религии. Кафедра
      истории религии развалилась, ещё когда её заканчивал Гуанако.</p>

    <p>Максиму,
      наверно, просто неоткуда было узнать, что такое «верить» и как это работает.</p>

    <p>«Верьте мне», —
      твердил то ли Гуанако Савьюру, то ли Савьюр Гуанако, когда они заперлись в
      гуанаковской камере до приезда Комиссии по Делам Гэбен, которая должна была
      разобраться с ситуацией на Колошме.</p>

    <p>Верьте мне, всё
      будет круто, всё будет навсегда. Это неважно, что Савьюра через несколько часов
      нечаянно пристрелит собственный охранник — пока здесь-и-сейчас веришь, что всё
      будет круто и навсегда, оно ведь и правда в некотором смысле так.</p>

    <p>Верьте мне, всё
      так и было, всё правда. Это неважно, слушают ли тебя студенты на лекции, гэбня
      ли на допросе, скопцы ли в Вилонском Хуе — пока здесь-и-сейчас веришь, что всё
      так и было, твою лажу сожрёт любой. Историография — скорее искусство, нежели
      наука. Искусство верить, что всё, что ты сейчас наплёл, — чистейшая правда.</p>

    <p>Верьте мне,
      шансы есть, может получиться. Это неважно, сказал тебе это хороший врач или
      плохой, — пока веришь, что шансы есть, они есть, и всё тут.</p>

    <p>Гуанако верил в
      шансы до тех самых пор, пока не подслушал, как у Святотатыча интересуются:
      «Очнулся. Положить под снотворное или, наоборот, привести в кондицию для
      беседы?»</p>

    <p>Хороши или плохи
      портовые врачи, но они-то знают, что пациента часто не из гуманизма с того
      света вытаскивают, а для того, чтобы спросить «где деньги, падла?».</p>

    <p>Ну или там: ты
      заражал дом Габриэля Евгеньевича?</p>

    <p>И Гуанако готов
      сделать вид, что его очень интересует ответ на этот простой и очевидный вопрос,
      чтобы не признаваться всем подряд, что ему просто очень нужно как можно скорее
      посмотреть, потрогать. Попускать сопли.</p>

    <p>А Святотатыч и
      прочие все подряд (какое счастье, что Охрович и Краснокаменный уехали!) готовы
      сделать вид, что они ничегошеньки не понимают. И даже дать Гуанако одному
      потоптаться перед дверью святотатычевской каморки.</p>

    <p>За дверью было
      тихо.</p>

    <p>За приоткрытой
      медленно-медленно, чтоб не скрипела, дверью было тихо и Дима.</p>

    <p>Дима был бледнющий, растрёпанный и какой-то смешной. Пытался не
      сползать, усидеть-таки на подушках и даже читать какую-то Святотатычеву
      книжку. Не умеет он ни убивать, ни умирать — умирать ему скучно, обязательно же
      надо делать что-нибудь, не валяться же просто так!</p>

    <p>Гуанако, всё ещё
      косой от Диминой наркотической бодяги, не выдержал и заржал.</p>

    <p>Это какой-то
      пиздец — столько ржать всё время. Чума в Бедрограде, люди умирают и выживают, а
      Гуанако всё ржёт и ржёт. Но ведь правда же — весело.</p>

    <p>Весело, когда бледнющий, растрёпанный, какой-то совершенно по-первокурсничьи маленький-тощенький Дима делает
      клинически упорное лицо и вцепляется слабыми пальцами в книжку.</p>

    <p>Весело.</p>

    <p><i>Не сегодня,</i> всё-таки не сегодня — это и
      без врачей понятно.</p>

    <p>Дима обернулся.</p>
  </>
}

export default chapter34
