import React from 'react'
import Helmet from 'react-helmet'
import MarkdownPage from '../../MarkdownPage'
import nadolgo from "../../Data/nadolgo"

const NadolgoPage = () => <>
  <Helmet>
    <title>Вы к нам надолго</title>
  </Helmet>
  <MarkdownPage
    sidebarPosition='none'
    markdown={nadolgo}
  />
</>

export default NadolgoPage