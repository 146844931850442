import React from 'react'
import Helmet from 'react-helmet'
import tauresePhonology from '../../Data/taurese-phonology'
import MarkdownPage from '../../MarkdownPage'

const BonusTauresePhonologyPage = () => <>
  <Helmet>
    <title>Таврская фонология и фонетика: краткий очерк</title>
  </Helmet>
  <MarkdownPage
    sidebarPosition='right'
    markdown={tauresePhonology}
    sidebar=''
  />
</>

export default BonusTauresePhonologyPage