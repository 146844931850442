import React from 'react'
import { IBookPage } from './index'

const chapter02: IBookPage = {
  header: 'header_d2.jpg',
  title: 'Глава 2. Яблоко',
  prevLink: 'chapter01',
  nextLink: 'chapter03',
  content: <>
    <h2>Медицинский Корпус. Сепгей Борисович</h2>
    <img src="/images/02_sepgei_borisovich.jpg" alt='' style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>— Да, ошиблись буквой при
      заполнении документов. Страшно извинялись, обещали как можно быстрее
      переделать. А у моего отца было своеобразное чувство юмора — и он решил
      оставить, как уж получилось.</p>
    <p>Сепгей Борисович улыбнулся
      пожилой даме, отчаянно пытавшейся задвинуть какие-то бумажки вглубь стола с
      конторкой как можно незаметней. Пожилая дама даже рассмеялась.</p>
    <p>Папино своеобразное чувство юмора
      пригодилось-таки Сепгею Борисовичу, хоть и с опозданием в тридцать с лишним
      лет: никто не любит медицинских ревизоров. Кроме Медицинской гэбни, которая и
      посылает их на проверки. В каждой, даже самой
      маленькой и самой скромной во всем Медкорпусе лаборатории обязательно есть
      что-нибудь, что ревизору видеть не нужно. По этому поводу все застигнутые
      проверкой сотрудники будут смотреть на ревизора как на живого лешего безлунной
      ночью. И очень удобно, если живому лешему при предъявлении удостоверения сразу
      найдётся, что сказать своим несчастным жертвам просто так, не по делу, чтоб
      хоть немного выдохнули, — потому что эти самые жертвы вытаращили глаза на
      нелепое имя. Всегда таращат. Как выяснилось за последние четыре года, это
      всё-таки очень удобно.</p>

    <p>— Я утреннюю кровь вот ещё в
      бланке не расписала, надо доделать до обеда, — с
      надеждой в голосе защебетала пожилая дама после стандартной процедуры снятия
      напряжения смехом.</p>

    <p>И уселась за свой несчастный
      стол, завертелась на месте, схватилась за первый попавшийся бланк, всё так же
      старательно отвлекая внимание Сепгея Борисовича от таинственных глубин
      конторки.</p>

    <p>Сепгей Борисович, проведший
      большую часть своей службы государству на разнообразных допросах в
      разнообразных следственных и исправительных учреждениях, улыбнулся ещё раз.
      Неумелое враньё медицинских работников всё никак не
      могло перестать казаться ему трогательным.</p>

    <p>— Вам премия в размере трёх
      окладов от Виктора Дарьевича лично, — сообщил он даме, твёрдой рукой выгребая
      сокрытые тенью бумажки, — вот за эти диаграммы.</p>

    <p>Пожилая дама ойкнула.</p>

    <p>Сепгей Борисович положил в
      опустевшую тень конторки квитанцию.</p>

    <p>— Но как же…</p>

    <p>— Он не может ждать, его озарило,
      — как можно дружелюбнее (ещё инфарктов тут не хватало, сам-то он не врач) пояснил
      Сепгей Борисович. — Вот и послал внеочередную проверку забрать результаты
      эксперимента прямо сейчас, до сравнительного анализа. Говорит, там всё и так
      ясно.</p>

    <p>Виктор Дарьевич вообще много
      говорит, стоит только проявить минимальную заинтересованность в предмете работы
      очередной лаборатории, над которой нависает проверка. А Бенедикт Ростиславович
      на это делает кислую мину — он хороший бюрократ и до сих пор помнит, что не
      каждому человеку с удостоверением работника Медицинского Корпуса следует быть в курсе
      всех (пусть даже сорок раз любопытных) подробностей секретных разработок.
      Валентин Ананиевич мину не делает, но всё время косится то на часы, то на
      дверь. Совершенно не умеет отдыхать. И только Леонид Леонидович с неподражаемо
      благостным спокойствием внимает россказням Виктора Дарьевича и, кажется,
      действительно получает удовольствие.</p>

    <p>А Сепгей Борисович — кажется —
      таки проникся любовью к Медицинской гэбне.</p>

    <p>Несмотря на.</p>

    <p>— Ну вот, — окончательно
      отпустило пожилую даму, — а я вас так перепугалась… мнэээ,
      Сепгей Борисович! Я-то как раз с диаграммами и возилась, новых-то цифр не
      пришло пока, но уже в этих прям на глаз видно такую красивую закономерность,
      просто праздник какой-то!</p>

    <p>Праздник. Лаборатория ГСЭС №37.</p>

    <p>ГСЭС — это гормональная
      составляющая экстремальных ситуаций. Никаких лишних бюрократических
      обозначений, в аббревиатуре всё очень предметно: создают экстремальную
      ситуацию, помещают в неё испытуемых и берут кровь на гормоны. До, после и (если
      условия эксперимента позволяют) во время. А потом
      обсчитывают, сравнивают и создают новую экстремальную ситуацию с учётом
      полученных данных.</p>

    <p>№37 — это всё то же самое, только
      в качестве испытуемых — дети старшего отрядского возраста.</p>

    <p>Диаграммы же сегодняшние, с
      красивой закономерностью — это что-то такое, от чего у Бенедикта Ростиславовича
      случился нервный тик половины лица, когда Виктор Дарьевич открыл
      было рот о них рассказать. И закрыл обратно, спустившись под действием нервного
      тика Бенедикта Ростиславовича на грешную землю, полную уровней доступа к
      информации и других ненужных Виктору Дарьевичу вещей.</p>

    <p>Поэтому Сепгей Борисович очень
      искренне предложил впавшей в раж пожилой даме:</p>

    <p>— Давайте обойдёмся без деталей.
      Гэбня не велит.</p>

    <p>— Жаль, я думала, сейчас
      быстренько объясню, что там нарисовалось, а вы Виктору Дарьевичу и перескажете!</p>

    <p>Сепгей Борисович
      в который раз подумал, что Медицинский Корпус попал на грешную землю, полную
      уровней доступа к информации, по какой-то нелепой ошибке. Добрая треть его
      сотрудников ночует на работе, подложив под голову документацию, не прошедшую ещё
      через Бюро Патентов, но активно использующуюся в дальнейших, и дальнейших, и
      дальнейших разработках. Добрая половина выполняет личные заказы голов
      Медицинской гэбни, а в отчётах пишет о всякой плановой ерунде. И абсолютно все
      сотрудники Медкорпуса — все, поголовно и без исключения — занимают
      государственную аппаратуру под личные проекты. Которые,
      может быть, как-нибудь, когда-нибудь (но точно не прямо сейчас, сейчас не до
      того!) и получат ну хоть какое-нибудь официальное разрешение на запуск.
      Примерно тогда, когда плодами этих проектов будут пользоваться все коллеги
      проектировщика и пара-тройка соседних лабораторий.</p>

    <p>Сепгей Борисович созерцал пожилую
      даму, наспех строчащую на полях таинственных диаграмм послание Виктору
      Дарьевичу про красивые гормональные закономерности детей старшего отрядского
      возраста в не дай леший
      узнать какой экстремальной ситуации, и с облегчением думал, что бюрократические
      кошмары перестали ему сниться ещё до Медицинского Корпуса. Наснились
      уже, хватит.</p>

    <p>Пожилая дама, исписав всё свободное
      и местами не очень свободное пространство одного из листов, с ощутимой болью
      оторвала его от сердца (и заодно от того самого стола с конторкой). Сепгей
      Борисович незамедлительно сокрыл лист в ревизорском портфеле.</p>

    <p>— Подождите, ещё парочка
      осталась. — Дама полезла в нижний ящик, из которого по прошествии
      минуты, не меньше, ей удалось извлечь последние диаграммы и яблоко. — У
      вас вид невыспавшийся, — пояснила она.</p>

    <p>Это
      настолько не лезло уже ни в какие ворота, что Сепгей
      Борисович не смог, просто не сообразил отказаться.</p>

    <p>Яблоко.</p>

    <p>Вид невыспавшийся.</p>

    <p>Невыспавшийся Сепгей Борисович и
      яблоко, картина маслом.</p>

    <p>Надо заесть яблоком от пожилой
      дамы с секретными диаграммами несчастную любовь. Самое то
      в рамках общего безумия трёх последних месяцев.</p>

    <p>Почему-то сразу вспомнилось, как
      в июне, сразу по приезде, Дима поставил банку с этой своей грязью в холодильник
      на нижнюю полку. Как раз рядом с яблоками. Поэтому яблоко — это как-то
      неожиданно сентиментально. Даже обидно, что Диминой грязи не место в легенде о
      несчастной любви.</p>

    <p>Сепгей Борисович так и выбирался
      из тихого лабиринта ГСЭС №37 — с ревизорским портфелем в одной руке и яблоком в
      другой. Должно быть, он действительно не выспался, раз мягкий и сорок раз
      выверенный медиками свет общего коридора всё равно
      резанул ему по глазам. Осталось убедить себя в том, что от несчастной любви не
      высыпаются так же, как от большой политики. В общем, и правда примерно то же
      самое: так и так маяться сутки, не получая вестей от Димы. А потом ещё сутки, и
      ещё сутки, и ещё. Как пойдёт.</p>

    <p>Связь пока что держать не стоит,
      да и незачем — роль у Сепгея Борисовича второстепенная, если не меньше.
      Затуманить мозги фалангам, спрятать хоть какие-то концы в воду. Выгородить
      самого себя, потому что он тут случайный человек, который случайно оказался в
      нужном месте в нужное время. «Обязательно выгородить!» — упрямо повторял Дима,
      закидывая вещи в сумку.</p>

    <p>За несчастную любовь не карают, в
      отличие от. А ещё несчастной любви совершенно
      необязательно знать, откуда её предмет вообще взялся, когда и зачем устроился в
      Медкорпус, чем там занимался и куда потом пропал. По
      крайней мере, считается, что необязательно.</p>

    <p>Из распахнутого настежь окна —
      сентябрь, почти лето! — донёсся взрыв хохота.</p>

    <p>Все строения Медицинского Корпуса
      похожи: огромные, раздвигающие пространство окна общих коридоров, кафешек,
      регистратур, изредка встречающихся приёмных местных бюрократов — в общем,
      окна всех тех мест, где даже буйно
      помешанные на своей работе медики отрывают взгляд от пробирок и бумажек,
      непременно выходят во внутренний двор. Поэтому если нужно, чтобы в сентябре
      малознакомые люди могли подтвердить дотошным фалангам факт наличия несчастной
      любви, весь июль придётся валяться на траве внутреннего двора. Чтобы всем-всем
      было видно. В рабочее время, с очередными нелегальными диаграммами для Виктора
      Дарьевича в ревизорском портфеле и Диминой лохматой башкой
      на коленях. Пока десятки людей ломают головы над политикой и один человек — над
      страшной заразой. Трёхнедельные гастроли Настоящей Бедроградской Конспирации
      перед невзыскательной столичной аудиторией, спешите увидеть.</p>

    <p>А в сентябре на траве уже
      холодно. Должно быть.</p>

    <p>Сепгей Борисович машинально
      пробежал глазами по двору. Действительно, на траве никого, зато все скамейки
      заняты. Но трава по-прежнему просматривается лучше.</p>

    <p>— Сепгей Борисович, — негромко
      окликнули его со спины.</p>

    <p>И яблоко по-прежнему в руке, что
      как-то глупо. Или сентиментально.</p>

    <p>— Да? — обернуться, улыбнуться и побольше думать о яблоке.</p>

    <p>В полутора шагах от Сепгея
      Борисовича стоял ничем не примечательный человек. Невыразительное лицо,
      невнятная осанка. Не первой свежести белый халат, пуговицы расстёгнуты, под
      халатом простая рубашка цвета ничего. То есть, конечно, серого, но совершенно
      пустого, такого, на котором невозможно задержаться взглядом.</p>

    <p>С лета семьдесят шестого Сепгея
      Борисовича подташнивало что от таких вот рубашек, что
      от белых халатов. Только недавно отпустило.</p>

    <p>— К вам вчера Ильянов, Андрей Витальевич, не
      заглядывал?</p>

    <p>В ответ Сепгей Борисович
      растерянно нахмурился.</p>

    <p>Яблоко, яблоко, яблоко!</p>

    <p>— А не вчера?</p>

    <p>Ничем не примечательный человечек
      имел крайне примечательные глаза. Совершенно ничего не выражающие.
      Кроме разве что негромкого равнодушного любопытства.</p>

    <p>До лета семьдесят шестого Сепгей
      Борисович не знал, что такие глаза вообще бывают.</p>

    <p>— Ильянов? Не припоминаю такой
      фамилии, — с очень искренним дружелюбием посмотрел на непримечательного
      человечка Сепгей Борисович.</p>

    <p>Микрофон, вероятно, между воротом
      халата и воротом рубашки.</p>

    <p>— А лица не припоминаете? —
      непримечательный человечек извлёк из нагрудного кармана пропуск в Медкорпус на
      имя Ильянова Андрея Витальевича с фотографическим изображением оного.</p>

    <p>По воскресеньям в любом общем
      коридоре любого строения Медкорпуса крайне безлюдно. А принуждать опознавать
      лица лиц высоких уровней доступа в месте, классифицируемом как публичное, —
      мерзкий приём.</p>

    <p>Как и все остальные приёмы
      фаланг.</p>

    <p>— Знакомое лицо. Но могу
      ошибаться — недостаточно крупный портрет, — Сепгей Борисович ещё раз растерянно нахмурился. Это единственно верная
      стратегия.</p>

    <p>А если ещё и не забывать о том,
      что фаланга допрашивает человека с ревизорским портфелем в одной руке и яблоком
      в другой, получается даже не очень раздражаться.</p>

    <p>— А к Дмитрию Ройшу давеча
      знакомое лицо не заглядывало? — в лоб спросил фаланга.</p>

    <p>И Сепгей Борисович в лоб ответил:</p>

    <p>— Если у Дмитрия Ройша какие-то
      проблемы, я бы предпочел обсуждать их в более подходящей обстановке.</p>

    <p>Невыразительные глаза фаланги
      символически выразили удовлетворение.</p>

    <p>— У Дмитрия Ройша проблемы?</p>

    <p>— Не издевайтесь, — отрезал
      Сепгей Борисович, — я давно выучил, что если вы кого-нибудь упоминаете, значит,
      у него проблемы.</p>

    <p>Несчастная любовь позволяет на
      нервах нарушать субординацию. И переходить прямо к делу. Очень удобная штука.</p>

    <p>Примерно как нелепое имя при
      службе медицинским ревизором.</p>

    <p>Фаланга для приличия коротко
      сверкнул жетоном третьего уровня доступа к информации, развернулся и пошёл по
      коридору в направлении лестницы. Сепгей Борисович устремился за ним. Сердце
      крайне правдиво ёкнуло: кажется, план хоть в какой-то мере, но сработал.</p>

    <p>Дмитрия Ройша они породили
      вместе: Дима — Настоящее Бедроградское Конспиративное Имя, в вымышленность
      которого не поверит ни один нормальный человек, потому что так вымышлять
      конспиративные имена не стал бы даже ребёнок старшего отрядского возраста в
      экстремальной ситуации, смоделированной Виктором Дарьевичем. Сепгей Борисович —
      концепцию мёртвой души всея Инфекционной Части Медкорпуса.</p>

    <p>Суть примерно такова: Дмитрий Ройш очень занят возлежанием на траве с ревизором из
Когнитивной Части, Дмитрию Ройшу некогда работать, поэтому распишитесь,
пожалуйста, за Дмитрия Ройша в этом никому не нужном журнале, сдайте,
пожалуйста, его заполненные на скорую руку бланки и вытряхните, пожалуйста, за
него пепельницу в день, когда он отвечает за место для курения. А взамен
      можете брать на его имя подотчётные реактивы из хранилища, например. Ему сейчас
      всё равно не надо, а вам не выдадут на своё имя в двойном объёме. И ещё много
      мелких, но неприятных бюрократических моментов можно обойти, если иметь в
      кармане не только своё удостоверение, но и удостоверение Дмитрия Ройша со своей
      фотографией. Или чьё угодно ещё удостоверение со своей фотографией, но кто ж,
      кроме Дмитрия Ройша, на такое согласится.</p>

    <p>В общем, как-то так и вышло, что
      к сентябрю Дмитрий Ройш поселился не в каждом, конечно, но очень во многих
      сотрудниках Инфекционной Части. Даже в тех, которые его самого в глаза никогда
      не видели.</p>

    <p>Поэтому Инфекционная Часть будет
      отвечать на любые расспросы про Дмитрия Ройша как на
      расспросы про покойника: либо хорошо, либо никак.</p>

    <p>Потом подключился самый настоящий
      Ройш, внук хэра Ройша. Не выходя из своей бедроградской квартиры, он каким-то
      чудом создал Дмитрию Ройшу в столичной Инфекционной Части некоторое количество
      настолько бюрократически возмутительных инцидентов, что по душу Дмитрия Ройша,
      рядового сотрудника Медкорпуса, пришёл фаланга.</p>

    <p>Привлечь внимание фаланг тогда,
      когда тебе захочется, а не тогда, когда этого хочется им, — бюрократическое
      достижение немыслимых высот. Кажется, Настоящая Бедроградская Конспирация
      всё-таки взяла вчера эти высоты. Потому что сегодня фаланга пришёл уже по душу
      Сепгея Борисовича.</p>

    <p>А Дима сел на поезд в Бедроград
      ещё позавчера вечером.</p>

    <p>Почему-то жаль, что даже
      Настоящая Бедроградская Конспирация в курсе, что в такой ситуации лучше не
      устраивать прощаний на вокзалах.</p>

    <p>Фаланга, протащивший Сепгея Борисовича
      на незримом поводке вниз по лестнице, прямо по коридору, направо по коридору,
      вверх по лестнице, налево по коридору, наконец-то скрылся за какой-то будто бы
      случайной дверью.</p>

    <p>Сепгей Борисович в
      профилактических целях сжал яблоко покрепче и переступил порог.</p>

    <p>Помещение за будто бы случайной
      дверью было плоть от плоти Медкорпуса — полное света и воздуха, с отделкой из
      какой-то пахучей древесины, со стульями, от которых никогда не заболит спина, и
      столом, к которому хочется прикасаться. Невзрачный фаланга за этим столом
      выглядел как оскорбление всему Медкорпусу.</p>

    <p>Оскорблением всему Медкорпусу он,
      в общем-то, и являлся.</p>

    <p>— Сепгей Борисович, вам нравится
      ваша работа?</p>

    <p>На этот вопрос нет правильного
      ответа. Ни того, который бы хотел услышать фаланга, ни того, который хотел бы
      произнести Сепгей Борисович.</p>

    <p>— Это немного не то, к чему я привык.
      И не то, к чему стремился, — Сепгей Борисович сел строго напротив неприметного
      человечка. Поставил портфель рядом. Выпустить яблоко из рук не получилось. — Но
      в моей нынешней работе есть свои плюсы. Мне нравится Медкорпус.</p>

    <p>Фаланга сверлил яблоко
      стеклянными глазками:</p>

    <p>— А вы ему скоро разонравитесь.
      Как только вскроется, что вы покрывали Дмитрия Ройша.</p>

    <p>— Если бы я мог его покрывать, я
      бы, должно быть, и сделал это. Но увы.</p>

    <p>Стеклянные глазки оживились.
      Оторвались от яблока, вперились в лицо Сепгея Борисовича с чем-то похожим на
      действительно сильное любопытство.</p>

    <p>Неужели Настоящая Бедроградская
      Конспирация сделала верную ставку?</p>

    <p>— Поясните, — в бесцветном голосе
      фаланги таки прозвучал охотничий азарт.</p>

    <p>— Это вы поясните, что происходит
      с Дмитрием Ройшем. Или хотя бы поясните, как я должен сейчас себя вести, чтобы
      ему помочь.</p>

    <p>Недоумение, охотничий азарт,
      лихорадочная дедуктивная работа. Впрочем, выдавали фалангу только очеловечившиеся
      глаза и задумчиво зашевелившиеся пальцы. Три невесомых постукивания по столу.
      Хороший знак.</p>

    <p>— Ваше имя?</p>

    <p>— Дата и место рождения?</p>

    <p>— Номер отряда?</p>

    <p>— Адрес полученного по
      распределению жилья?</p>

    <p>— Место дальнейшей учебы?</p>

    <p>— Работы?</p>

    <p>И ещё много стандартных пунктов
      досье, прекрасно известных фаланге.</p>

    <p>Сепгей Борисович удивился: он
      полагал, что достаточно хорошо знает методы работы фаланг. Три с половиной года
      под следствием — достаточный срок для того, чтобы иметь подобную уверенность.</p>

    <p>Фаланги не любят стандартных пунктов,
      это лишняя информация.</p>

    <p>Возможно, сейчас это способ взять
      передышку. Перед нестандартными.</p>

    <p>Стандартные пункты досье Сепгея
      Борисовича становились нестандартными то ли в ноябре семьдесят третьего, то ли
      в мае семьдесят шестого.</p>

    <p>Служба на Колошме.</p>

    <p>Седьмой уровень доступа взамен
      десятого. Десятый — это всё мелкие сошки, управленцы вне крупных городов,
      тюремщики, начальники под более высоким начальством, рядовые сотрудники
      наиболее значимых служб. Медицинские ревизоры, например.</p>

    <p>А седьмой — это уже самая
      настоящая большая политика. Колония для политических заключённых, самая
      знаменитая тюрьма Всероссийского Соседства.</p>

    <p>В ноябре семьдесят третьего гэбня
      Колошмы сменила три из четырёх своих голов разом. Негласное распоряжение: «вы
      там поспокойней». Сепгей Борисович всегда был из тех, кто поспокойней.
      Тогдашний главный политический скандал (чего уж там, главный политический
      скандал всех последних лет) он воспринял с недоумением: голова гэбни Колошмы
      спутался с заключённым, переругался с другими головами, наделал глупостей,
      умер. Звучало немыслимо. Шептались все, у кого за плечами был хоть какой-нибудь
      институт госслужбы. В институтах тоже шептались. Старый институтский тренер по
      стрельбе, приятель Сепгея Борисовича, прямо перед назначением того на Колошму
      сказал, что, мол, скандал поостынет, случай введут в программу подготовки
      госслужащих. Показательный, мол, случай. Интересно, ввели?</p>

    <p>После «показательного случая»
      сидеть в гэбне Колошмы было как-то неловко. Вся текущая рабочая документация,
      приходившая из внешнего мира, так и кричала между строк о показательном случае.
      Никто ничего не знал (расшифровки бесед того самого головы гэбни с тем самым
      заключённым появились хоть в каком-то доступе только через несколько месяцев),
      но все имели мнение. А Сепгей Борисович со своим седьмым уровнем слушал те
      самые пока не преданные публичной огласке внутри госаппарата записи и много
      думал.</p>

    <p>А в мае семьдесят шестого на
      Колошме вспыхнула степная чума. Это было неожиданно — все уже успели забыть,
      что Колошма знаменита не только показательным случаем, но и показательными
      эпидемиями. Забыли, вовремя не среагировали, не выслали в срок лекарство (как
      будто от степной чумы есть лекарство, но
      хоть иммунных препаратов бы тогда!). Пришлось справляться самим.</p>

    <p>Медкорпусу и не снилось, что
      такое настоящий плевок в лицо бюрократии. Врачей не хватало, охрана выполняла
      приказы с переменным успехом, кое-кого из заключённых назначили санитарами. То
      есть не назначили, само собой вышло, что кто-то готов работать, а документы
      поправили на коленке — ждали помощи от Медицинской гэбни. Вместо Медицинской
      гэбни помощь оказали фаланги и подчиняющийся им Силовой Комитет.</p>

    <p>Двойной кордон вокруг изолятора,
      куда согнали тех, кого ела заживо степная чума, и тех, кто один раз чихнул.</p>

    <p>Внутреннее кольцо — огнемётчики,
      внешнее — броневики с автоматчиками.</p>

    <p>Гэбня Колошмы узнала ночью
      накануне.</p>

    <p>У фаланг третий уровень доступа,
      у Силового Комитета — четвёртый. Они не обязаны отчитываться перед гэбней
      Колошмы. Перед Медицинской гэбней — тоже, у нее всего-то пятый. Самый высокий
      уровень доступа для гэбен, не считая Бюро Патентов.</p>

    <p>Медики во Всероссийском Соседстве
      — особые люди. Могут ночевать на работе и использовать государственную
      аппаратуру для личных проектов. Могут не отвечать ни перед кем, кроме Бюро
      Патентов, и устраивать экстремальные ситуации детям старшего отрядского
      возраста.</p>

    <p>Могут потратить две недели на
      доведение до ума средства, которое позволило бы щетине не расти. Просто потому
      что это интересно.</p>

    <p>У фаланг третий уровень доступа,
      и они едва терпят что особых людей, что стоящую над ними гэбню. Чтобы сделать
      этой гэбне гадость, готовы ухватиться за что угодно.</p>

    <p>И прислать огнемётчиков.</p>

    <p>Чуму на Колошме сожгли вместе с
      изолятором. По степи она ещё побродила, но кого волнует хоть что-то в степи,
      кроме Колошмы? Тем более что степняки умеют как-то охраняться от чумы своими
      наркотическим травами — теми самыми травами, которые вне экстремальной ситуации
      должны собирать на благо фармацевтики заключённые Колошмы.</p>

    <p>Теми самыми травами, из которых
      один из заключённых, переквалифицировавшийся в санитара, смешал иммунный
      препарат. Работало кое-как, но работало. Даже тормозило развитие болезни у тех,
      кто уже успел заразиться.</p>

    <p>Сепгей Борисович против всех
      правил безопасности послал-таки препарат в Медкорпус. Поверил, что Медицинская
      гэбня остановит эпидемию.</p>

    <p>С тех пор Медицинская гэбня
      кровно заинтересована в Сепгее Борисовиче как в живом очевидце степной чумы и
      создания иммунного препарата.</p>

    <p>С тех же самых пор в Сепгее
      Борисовиче заинтересованы фаланги. Как в голове гэбни, утерявшем во время
      вспышки эпидемии табельное оружие, при помощи которого кто-то прорвал кордон
      вокруг горящего изолятора.</p>

    <p>Много народу не сбежало,
      выбралось в степь всего полдюжины человек — и те полегли в ближайшем поселении,
      уже охваченном чумой. Там же обнаружили пистолет Сепгея Борисовича.</p>

    <p>Если бы доказали передачу
      табельного оружия кому-то из заключённых в экстремальных обстоятельствах с
      целью противостояния подразделениям Силового Комитета — Сепгею Борисовичу
      светил бы расстрел. Если бы не доказали, но подозревали — пожизненное лишение
      права на ношение оружия и какую-либо службу, кроме двенадцатого уровня под
      присмотром Силового Комитета.</p>

    <p>Если бы не вмешалась кровно
      заинтересовавшаяся в Сепгее Борисовиче Медицинская гэбня, его жизнь была бы
      гораздо проще.</p>

    <p>Или смерть.</p>

    <p>А так расследование утери
      табельного оружия продолжалось три с половиной года. Медицинская гэбня хотела
      для Сепгея Борисовича службы под своим присмотром: Валентин Ананиевич любит
      вирусы, а Виктор Дарьевич — моделировать экстремальные ситуации, они помогают
      выявить возможности организма и связь физиологии с работой мозга.</p>

    <p>Зато фаланги не любят Медицинскую
      гэбню.</p>

    <p>И ничего (никого!) отдавать ей
      просто так тоже не любят.</p>

    <p>Тайная многолетняя вражда на
      недоступно высоких уровнях доступа. Сепгей Борисович и не догадывался о ней,
      пока три с половиной года его жизни не были принесены в жертву этой вражде.
      Сама по себе его судьба ни для фаланг, ни для медиков большого значения не
      имела: есть Сепгей Борисович — отлично, нет Сепгея Борисовича — тоже неплохо,
      не такая и важная птица была. Но как только медики узнали, что он нужен
      фалангам, а фаланги — что он пригодился бы медикам, пути назад уже не было.</p>

    <p>Аттракцион «кто выиграет Сепгея
      Борисовича».</p>

    <p>Три с половиной года
      перетягивания бюрократического каната для фаланг и медиков. Три с половиной
      года допросов, периодических предварительных заключений и постоянной
      невозможности хоть где-то работать и хоть куда-то уехать.</p>

    <p>— Год назначения на службу
      медицинским ревизором?</p>

    <p>— Тысяча восемьсот семьдесят
      девятый.</p>

    <p>У ревизорского портфеля,
      примостившегося рядом со стулом Сепгея Борисовича, истёрлась серебряная бляшка
      Медкорпуса. Назначение (читай: бюрократическая победа медиков над фалангами)
      было давно. Достаточно давно, чтобы Сепгею Борисовичу больше не хотелось
      расстрелять в упор Медицинскую гэбню из неположенного ему теперь табельного оружия. Но недостаточно давно, чтобы
      не хотелось расстрелять любого фалангу, который зачем-нибудь негромко окликнет
      его со спины.</p>

    <p>У яблока в руках был очень
      отчётливый запах лета.</p>

    <p>— Год знакомства с Дмитрием
      Ройшем? — тем же протокольным тоном для заполнения стандартных пунктов досье
      вдруг спросил фаланга.</p>

    <p>Сепгей Борисович всё-таки
      дёрнулся. Должно быть, его собственные навыки ведения допросов давно не на том
      уровне, чтобы предсказывать следующий ход фаланги. Так ведь просто, а пробрало.</p>

    <p>— Нынешний, тысяча восемьсот
      восемьдесят третий. Июнь-месяц.</p>

    <p>И ответить на это тоже так
      просто. Конкретные факты на конкретный вопрос.</p>

    <p>С <i>Дмитрием Ройшем</i> Сепгей Борисович познакомился только в этом году.</p>

    <p>Фаланга выжидательно молчал,
      последовательно изучая стеллажи вдоль стен.</p>

    <p>Сепгей Борисович решился:</p>

    <p>— Где он?</p>

    <p>— А вы не знаете?</p>

    <p>— Не знаю, — отстранённо
      подумалось, что летом стоило составить служебную инструкцию Настоящей
      Бедроградской Конспирации. Если бы таковая имелась, сейчас было бы гораздо
      проще. — Позавчера он не вернулся. До этого пропадал полторы недели. Если
      вы спрашиваете о нём, значит, он уже
      никогда не вернётся?</p>

    <p>Стол между Сепгеем Борисовичем и
      фалангой был усыпан чьим-то чужими отчётами. Свежими, без печатей канцелярии
      Когнитивной Части. Кажется, профилактика нервных срывов — плановые работы,
      которые Виктор Дарьевич считает скучными.</p>

    <p>А пользоваться по своему
      разумению помещениями Медкорпуса, потому что с жетоном третьего уровня можно
      всё, — мерзкая манера.</p>

    <p>Как и все манеры фаланг.</p>

    <p>— И какая у вас с Дмитрием Ройшем
      была договорённость насчёт времени его возвращения в вашу квартиру?</p>

    <p>— Это не называется
      договорённостью, — Сепгей Борисович усмехнулся, и вот это уже было
      действительно сентиментально, — это называется свободой выбора.</p>

    <p>— Что вы имеете в виду?</p>

    <p>— Что мне придётся вас разочаровать,
      если вы пришли ко мне как к сообщнику Дмитрия Ройша в каком бы то ни было деле.
      Общих дел у нас с ним, увы, не было.</p>

    <p>— Увы? — всё-таки проглотил
      наживку фаланга.</p>

    <p>— Увы. Наличие общих дел дало бы
      мне возможность знать, где он сейчас находится. А это имеет для меня значение.</p>

    <p>— Что вы знаете о деятельности
      Дмитрия Ройша в Медицинском Корпусе?</p>

    <p>Сепгей Борисович собрался с
      мыслями:</p>

    <p>— Почти ничего. Могу добавить
      «увы» ещё раз. Знаю, к каким числам ему надо было сдавать отчётности, знаю, к
      какому часу он вставал по утрам в разные дни недели. Знаю, кто в его
      лаборатории всё время теряет в бардаке свои экспериментальные образцы. Одни
      бытовые подробности, словом.</p>

    <p>— Он ничего существеннее не
      рассказывал?</p>

    <p>— Ничего существеннее я не мог бы
      понять, я ведь не медик. И вам это отлично известно. Вряд ли я могу поддержать
      разговор о вирусологии за ужином — это даже не когнитивная наука лабораторий,
      которые я ревизирую.</p>

    <p>— И поэтому Дмитрий Ройш не
      считал нужным посвящать вас в свои дела? — с сомнением уточнил фаланга.</p>

    <p>— Разговор профессионала с
      непрофессионалом беспредметен. — Сепгей Борисович покачал головой. — Как будто
      вы станете общаться о расследованиях и допросах с тем, кто не состоит на
      государственной службе.</p>

    <p>С учётом наличия во Всероссийском
      Соседстве уровней доступа к информации последний вопрос прозвучал предельно
      нелепо. То есть влюблённо. Фаланга оценил.</p>

    <p>— И вы не попытались разобраться,
      что происходит, когда Дмитрий Ройш не возвращался в вашу квартиру полторы
      недели?</p>

    <p>— Нет, не попытался. Как бывший
      тюремщик я знаю, как выясняют, кто где был и чем занимался на самом деле. —
      Сепгей Борисович посмотрел куда-то в пол. — Не всем это нравится.</p>

    <p>Фаланга молчал.</p>

    <p>— Вы надеялись обнаружить
      очередное должностное преступление, которое позволило бы всё-таки выдернуть
      меня из Корпуса назло Медицинской гэбне? — очень грустно и очень прямо спросил
      Сепгей Борисович. — Увы. Когда в августе я заметил, что Дмитрий Ройш приходит
      вечером на нервах, и попытался чем-то помочь, он попросил меня не влезать. И я
      не влезал — не хотел быть дома ни тюремщиком, ни ревизором. Как медицинский
      ревизор я знаю, что ничего сильно страшного медики в рамках своих
      профессиональных занятий не делают. То есть волноваться не о чем. — Взгляд
      фаланги опять начал бродить по стеллажам. — Я ошибся?</p>

    <p>Из нагрудного кармана рубашки
      цвета ничего снова было извлечено удостоверение сотрудника Медкорпуса.</p>

    <p>— Так вот, Ильянов Андрей
      Витальевич.</p>

    <p>— Я уже ответил: лицо знакомое,
      но в голову ничего не приходит, — развёл руками Сепгей Борисович. Яблоко очень
      захотело выскользнуть.</p>

    <p>— Напрягите память, — настойчиво
      порекомендовал фаланга, — и не ограничивайте её напряжение Медицинским
      Корпусом. Это не единственное значимое учреждение в вашей биографии.</p>

    <p>Сепгей Борисович всмотрелся в
      мелкую чёрно-белую фотографию: цвет глаз неразличим, волосы светлые,
      слегка вьющиеся, собранные в
      хвост, аккуратные усы и аккуратная бородка, черты мягкие. Возраст между двадцатью и тридцатью, точнее сказать нельзя
      — взгляд на удивление ученический, выражающий уважение и готовность внимать.
      Человеку с таким взглядом непроизвольно хочется дать подробные объяснения,
      помочь разобраться в происходящем и в случае чего непременно за него
      заступиться.</p>

    <p>Очень удобный взгляд.</p>

    <p>Тем не менее, по фотографии на
      документах и в самом деле можно было бы не опознать.</p>

    <p>— Андрей, — проговорил наконец
      Сепгей Борисович, продолжая изучать удостоверение, — но не Витальевич и не
      Ильянов. Андрей Эдмундович Зябликов, бывший голова гэбни Колошмы, переведённый
      в Бедроград и пониженный до десятого уровня после скандального инц��дента в семьдесят
      третьем году.</p>

    <p>Фаланга тут же отодвинул
      фальшивое удостоверение от Сепгея Борисовича:</p>

    <p>— Нынешний голова Бедроградской
      гэбни.</p>

    <p>— Был повышен в семьдесят
      четвёртом за успешно закрытое дело 66563, — согласился тот. — Место его службы
      на текущий момент было мне неизвестно. Для меня Андрей Эдмундович — в первую
      очередь мой предшественник на Колошме.</p>

    <p>— Почему Дмитрий Ройш
      предпочитает компанию лиц, имевших отношение к Колошме?</p>

    <p>Вот это был первый на сегодня
      действительно плохой вопрос.</p>

    <p>— Ничего об этом не знаю, — для
      демонстрации досады не понадобилось особых усилий. А рука на яблоке сама собой
      разжалась. — Дмитрий Ройш прожил несколько лет в степном поселении —
      традиционная медицина малых народов часто вызывает интерес у специалистов. Хотя
      то поселение находится на значительном расстоянии от Колошмы, — но дать яблоку
      укатиться Сепгей Борисович всё равно не смог. — По крайней мере, он так
      говорил.</p>

    <p>— Он интересовался деталями вашей
      прежней службы?</p>

    <p>— Чумой, как и любой другой
      медик.</p>

    <p>— А если подумать получше?</p>

    <p>Грустная улыбка прилипнет теперь
      к Сепгею Борисовичу на ближайшие несколько дней.</p>

    <p>— Профессиональной деформацией, привившей
      стремление к контролю. Повторюсь: не всем это нравится.</p>

    <p>— И не для всех безопасно, —
      фаланга меланхолично смотрел мимо Сепгея Борисовича. Три с половиной года под
      следствием подсказывали, что это обыкновенно означает: все точки уже
      расставлены. Ещё пара каких-нибудь мелочей для подтверждения выводов, и
      процедура завершится.</p>

    <p>— Скажите, — прервал паузу Сепгей
      Борисович, — есть основания полагать, что Дмитрий Ройш намеренно искал
      контактов с теми, кто был на Колошме?</p>

    <p>Фаланга вдруг выдал
      покровительственную усмешку:</p>

    <p>— Нет таких оснований, просто в
      глаза бросается, хотя людей оттуда в его деле всего двое. Колошма всегда
      бросается в глаза, — спокойный и
      человечный тон фаланги наводил на мысли о дружелюбии сытого удава. Добыча,
      правда, оказалась не кроликом, а какой-то безвкусной растительностью (яблоком,
      вестимо), но сытости это не отменяло. — Характер его контактов с Андреем
      Эдмундовичем Зябликовым пока что остаётся невыясненным, если вы <i>это</i> хотели узнать. Кстати, а с точки
      зрения медицинского ревизора, зачем Андрею Эдмундовичу фальшивое удостоверение
      сотрудника?</p>

    <p>— Чтобы избежать лишнего внимания
      и лишней возни, должно быть, — пожал плечами Сепгей Борисович. — Для
      посторонних посещение Медкорпуса — даже по вполне законному поводу — сопряжено
      с бумажной волокитой.</p>

    <p>— Вы всегда так хорошо думаете о
      людях?</p>

    <p>— Я не думаю о людях плохо, не
      имея на то хоть каких-то оснований.</p>

    <p>А в ревизорском портфеле до сих
      пор какие-то жуткие диаграммы, не проходившие ни через одну канцелярию. Попроси
      фаланга раскрыть портфель, у него были бы все основания выдернуть-таки Сепгея
      Борисовича из объятий Медицинской гэбни, заодно подловив её на незаконных
      экспериментах.</p>

    <p>И встань беседа на опасный для
      Дмитрия Ройша и другой большой политики путь, Сепгей Борисович нашёл бы способ
      привлечь внимание фаланги к портфелю, сколько бы раз Дима ни просил не
      подставляться.</p>

    <p>— А как так вышло, что он стал
      жить у вас?</p>

    <p>Позвонил с вокзала через леший
      знает сколько лет и спросил, можно ли переночевать.</p>

    <p>Только фаланга интересуется <i>Дмитрием Ройшем</i>.</p>

    <p>— Его привёл ко мне старый
      приятель из Корпуса, Тахá Шапка.</p>

    <p>— Шапка — сотрудник Инфекционной
      Части, а вы утверждали, что далеки от её работы, — перебил фаланга. Раз он уже
      ознакомился со стандартными пунктами досье очередного рядового медика Тахи
      Шапки, должно быть, всё на самом деле идёт по плану.</p>

    <p>— Мы были знакомы ещё до моего
      назначения в Корпус. И на Колошму тоже, — Сепгею Борисовичу было как-то неожиданно приятно рассказать для
      разнообразия хоть немного правды. — Таху Шапку я допрашивал в качестве главы
      следственного отделения при Столичной гэбне. Стандартная процедура: пропажу без
      вести госслужащего двенадцатого уровня хотели повесить на тавра. Допрашивали
      всех тавров, с которыми обвиняемый когда-либо пересекался в Столице, — как это
      всегда и делается, если поднимают таврский вопрос. Я дело раскрыл — там была
      попытка бегства с госслужбы, сам беглец улики тавру и подкинул. А Таха Шапка
      после снятия обвинений с его друга вдруг пришёл меня отблагодарить. Шестьдесят
      девятый год, кажется.</p>

    <p>— Вернёмся к июню восемьдесят
      третьего, — удовлетворённо кивнул фаланга.</p>

    <p>— Таха Шапка иногда ночует у
      меня. Производственный раж вынуждает — я живу в получасе от Инфекционной Части,
      а он в двух часах езды. В июне он как-то пришёл с Дмитрием Ройшем, который был
      три дня как с вокзала, сразу занялся вместе с Тахой
      Шапкой каким-то проектом и все три дня спал на чемодане в Корпусе, не дойдя даже
      до службы обеспечения жильём. На четвёртый они решили передохнуть в более
      подходящих для жизни условиях, но поближе к Корпусу. В результате мы всю ночь
      потратили на разговоры о жизни в степи и о последней чуме, а потом само собой
      вышло, что Дмитрий Ройш зашёл без Тахи Шапки. И остался.</p>

    <p>Фаланга встал, с едва уловимым
      раздражением одёрнул непривычный для него белый халат, оглядел помещение и
      направился к двери.</p>

    <p>— Дмитрий Ройш с самого начала
      пользовался вашим гостеприимством и вашим служебным положением, — сухо сказал
      он перед тем, как оставить Сепгея Борисовича в одиночестве. — Вашей
      именной печатью заверен ряд фальшивых
      документов, вы знали?</p>

    <p>Сепгей Борисович тряхнул головой
      и тоже поднялся со стула.</p>

    <p>Яблоко сиротливо зеленело на
      чьих-то чужих отчётах по профилактике нервных срывов.</p>

    <p>Надо отнести Виктору Дарьевичу
      его диаграммы, от которых на других голов Медицинской гэбни нападает нервный
      тик, и заодно спросить, что пьют от недосыпа. Второстепенная роль сыграна,
      фаланга убедился в неуловимости (а заодно и цинизме) Дмитрия Ройша, больше ничего
      не будет — только ожидание новостей из Бедрограда и возвращение бюрократических
      кошмаров про рубашки цвета ничего. После того, как поживёшь под следствием три
      с половиной года, даже самая безобидная встреча с фалангой даром пройти не
      может.</p>

    <p>Только никому в Бедрограде об
      этом знать не стоит.</p>

    <p>Когда дверь за фалангой
      захлопнулась, Сепгей Борисович положил яблоко в портфель и подумал, что нелепое
      имя — это всё-таки очень удобно.</p>

    <p>Позволяет отыскать человека в
      телефонном справочнике с первой попытки.</p>
  </>
}

export default chapter02
