import React from 'react'
import { IBookPage } from './index'

const chapter37: IBookPage = {
  header: 'header_d10.jpg',
  title: 'Чем всё закончилось с одной стороны',
  prevLink: 'chapter36',
  nextLink: 'chapter38',
  content: <>
    <h2>Максим</h2>

    <img alt='' src="/images/coin_one.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>— Максим! Максим Аркадьевич! —
      донеслось со спины. — Слушай, как хорошо, что ты на факультете!</p>

    <p>Максим притормозил, обернулся и
      машинально кивнул.</p>

    <p>— Слушай, подпиши мне быстренько
      пару бумажек? Умоляю, а то я точно рехнусь. Ты представляешь, я приезжаю — то
      есть нет, как раз не приезжаю, никак не могу приехать. Потому что с Портом,
      говорят, трудности, корабли не пускают. У них там какая-то ерунда вроде
      обратной ветрянки — она что, в самом деле существует? Это вообще-то очень
      весело, если существует, но в бумажках я её как причину несвоевременного выхода
      на работу написать, оказывается, не могу! У меня срок стажировки ещё когда
      закончился, а добираться сначала морем, а потом поездом через пол-Европ на
      чухонский вокзал — это же та-ак долго! И вот я, наконец, приезжаю — а в
      завкафском кресле долбаный Ройш! Я так и села. Максим, да он же просто идиот
      какой-то — он что, про обратную ветрянку в Порту не слышал? Он вообще в отрыве
      от действительности живёт? Не хочет подпись ставить — я, видите ли,
      задержалась. Тьфу.</p>

    <p>Максим сочувственно покивал
      обрушившемуся на него потоку бессвязного возмущения.</p>

    <p>Она всегда такая, Лидия
      Петроновна. Впрочем, хоть сам Максим и не одобрял панибратства в теории, в
      данном конкретном случае всё-таки не Лидия Петроновна, а Лидочка — даже
      студенты регулярно позволяли себе называть её так в лицо. Ей нет тридцати, она
      с ног до головы обвешана яркими украшениями и очень много говорит.</p>

    <p>Она год проработала по обмену на
      отделении росской истории в ведущем университете дружественного Ирландского
      Соседства, она обычный молодой преподаватель, она никогда не была занята
      политикой.</p>

    <p>— Так ты подпишешь?</p>

    <p>Именно она была когда-то давно той
      первой студенткой истфака, благодаря которой совсем ещё неопытные Максим,
      Ларий, Охрович и Краснокаменный узнали, что головы Бедроградской гэбни не
      гнушаются прикидываться младшими служащими, дабы поближе подобраться к
      Университету.</p>

    <p>Символизм, подумал Максим, до
      тошноты много символизма в жизни.</p>

    <p>— Извини, не могу.</p>

    <p>Мимо по коридору то и дело
      торопливо пробегали опоздавшие к началу пары студенты. Многие из них приветливо
      улыбались Лидочке, те, кто пораспущенней, — махали ей как старой приятельнице,
      кто-то даже выкрикнул приглашение на свидание.</p>

    <p>На Максима сегодня студенты
      смотрели с недоумением.</p>

    <p>— Как это не можешь? — оторопела
      Лидочка. — Ты же заместитель завкафа, во всей этой административной мути твой
      голос значит ничуть не меньше.</p>

    <p>Леший, подумал Максим.</p>

    <p>— <i>Значил,</i> — он инстинктивно сделал полшага назад. — Я больше не
      работаю в БГУ имени Набедренных.</p>

    <p>— Ой, — по-отрядски смутилась
      Лидочка, тряхнула головой. Удивлённо звякнули её замороченные серьги, звук
      отразился от сводов и вернулся к Максиму эхом с нотками осуждения.</p>

    <p>— Обстоятельства таковы, что
      заявление об уходе стало необходимым, — хладнокровно пояснил он, стараясь не
      думать, что даже его подпись под этим заявлением рисовала умелая рука другого
      человека.</p>

    <p>«Стало необходимым» означает
      «стало необходимым». Не Максиму — обстоятельствам.</p>

    <p>— Ну, наверное, это правильно.
      Правильное решение, я хотела сказать, — пробормотала Лидочка, явно всё ещё не
      определившись с собственным отношением к новости. — Удачи тебе.</p>

    <p>Удачи.</p>

    <p>Удача и правильные решения нужны
      были раньше.</p>

    <p>Разворачиваться и уходить —
      невежливо. Много думать об изматывающей вежливости в адрес сотрудников
      учреждения, где более не числишься, — нерационально.</p>

    <p>— Но как же… как же мы тут без
      тебя не развалимся к лешему? — встрепенулась Лидочка, продолжая переваривать
      услышанное.</p>

    <p>— Не развалитесь.</p>

    <p>— Максим, только не пойми меня
      как-то не так, но ты же очень много делал! Во что превратятся методика, учебные
      планы, сетка расписания? Кто их будет контролировать-то? Да мы загнёмся!</p>

    <p>Максиму стало неловко. Сколько
      раз в день (хотя бы в неделю) он задумывался о методике, учебных планах и сетке
      расписания? Раньше — часто, не реже, чем о гэбенных делах. Начиная с мая —
      только тогда, когда получал пинок от Лария.</p>

    <p>То, что Бедроградская гэбня
      хотела запустить смертельный вирус в канализацию дома Ройша, несомненно, было
      важнее сетки расписания.</p>

    <p>Да только важнее ли?</p>

    <p>Не говоря уже о том, что и сам
      этот смертельный вирус с мая-месяца был далеко не пе��вым в списке приоритетов
      Максима.</p>

    <p>— Спасибо за беспокойство, но я
      не думаю, что оно оправдано, — отвёл взгляд Максим. — На данный момент я совсем
      не тот человек, который способен день за днём выигрывать неравную борьбу с расписанием.</p>

    <p>Вот, например, Лидочка вернулась
      со своей стажировки — её снова надо вписывать в какие-то часы, что-то где-то
      сдвигать, менять местами.</p>

    <p>Расписание, составленное ещё
      летом, Лидочку учитывало, но на третий день чумы вылез вдруг из-под земли
      Вилонский Хуй и грядущая в него экспедиция. Да, она была задумана в первую
      очередь как прикрытие для процедур, необходимых в связи с возникновением
      потребности в лекарстве, — но это не означает, что она не состоится. Состоится.
      Кто из сотрудников истфака будет ответственным за экспедицию (с учётом всех
      произошедших в кадровом составе изменений) — пока неясно, но есть ещё шансы
      разобраться с этим к началу следующего семестра. А в нынешнем семестре учебный
      план так или иначе должен быть подчинён экспедиционной подготовке.</p>

    <p>Его перекраивали на коленке между
      третьим и четвёртым днём чумы, когда проблем было столько, что возвращение на
      факультет той же Лидочки просто упустили из виду, забыли, не раскидали её пары
      по новому расписанию.</p>

    <p>Очевидно, именно поэтому Ройш,
      временно исполняющий теперь обязанности заведующего кафедрой, с каменным лицом
      отказывается ставить подпись. Хочет показным самодурством укрыть допущенный
      прокол, не множить зря слухи о небрежной работе кафедры в последнее время,
      привести всё в порядок и только тогда позволить Лидочке приступить к занятиям.</p>

    <p>Сколько ещё таких Лидочек Максим
      забыл упихнуть в учебный план между третьим и четвёртым днём чумы? Сколько ещё
      проколов поменьше разгребать всему истфаку?</p>

    <p>Страшно представить.</p>

    <p>А Ройшу — нестрашно. Кафедра
      одним махом лишилась завкафа и замзавкафа, и Ройш, поморщившись для острастки,
      сам предложил свою помощь в сложившейся ситуации. С одним-единственным условием
      — все административные обязанности будут без лишних разговоров сняты с него, как
      только это станет возможным.</p>

    <p>А до тех пор Ройш, так уж и быть,
      поработает и за Максима, и за Габриэля — и что-то подсказывало Максиму, что тот
      в одиночку справится лучше, чем они вдвоём.</p>

    <p>По крайней мере, лучше, чем они
      вдвоём справлялись с мая.</p>

    <p>— Не злись так на Ройша, — выдавил
      из себя совет Максим. — Ему сложно тянуть на себе всё сразу, но он освоится и
      станет адекватнее. Попробуй заглянуть завтра — вдруг передумает и всё сам
      подпишет?</p>

    <p>Странный разговор — совсем
      бытовой, обыденный, тривиальный. Если оставить за скобками всё, что во время
      этого разговора происходит у Максима в голове, получится скучная сценка из
      жизни преподавателей — уволившегося и вернувшегося к работе.</p>

    <p>Чтобы разглядеть за ней
      удаляющуюся поступь чумы, надо быть параноиком.</p>

    <p>Так — правильно. Так и
      планировалось, так и договаривались между собой все участники со стороны
      Университета в день юбилея Первого Большого Переворота: ни единого слова об
      эпидемии тем, кто непосредственно не был в ней замешан.</p>

    <p>Все следы — добросовестно
      замести. Всё, что замести нельзя, — затуманить полуправдивыми или вовсе ложными
      объяснениями. Ведь — как верно заметил в одной из своих статей покойный
      профессор Гуанако — «историография (в известной степени) является скорее
      искусством, нежели наукой».</p>

    <p>Пришло время заняться искусством
      умолчаний и переиначиваний. Очевидно, так всегда и бывает, когда карты
      разыграны, соглашения подписаны и даже дрова для отстройки сожжённых деревень
      уже начинают мало-помалу подвозить.</p>

    <p><i>Так всегда и бывает,</i> так и пишется история. Людям, связавшим всю
      свою жизнь с истфаком, удивляться тут нечему.</p>

    <p>Тем более что покойный профессор
      Гуанако всё про всех верно заметил в своих статьях. Давным-давно.</p>

    <p>— Максим, давай выпьем, а? —
      неожиданно предложила Лидочка. — Я тут сегодня как раз для друга бутылку ликёра
      захватила — из ирландского виски с вереском и клевером. Друг пока перебьётся, а
      нам с тобой явно не помешает. Пойдём?</p>

    <p>Так и пишется история — кем-то
      другим; а твоё место — пить ликер из виски и клевера, в меру собственных
      возможностей поддерживая официальную легенду среди тех, кто лично у тебя
      спросит о последних событиях.</p>

    <p>Максим покорно поплёлся за
      Лидочкой.</p>

    <p>Та сначала метнулась в сторону
      кафедры, но потом, очевидно, вспомнила о Ройше в завкафском кресле и решительно
      зашагала по лестнице на третий этаж.</p>

    <p>— Ликёр отличный, — щебетала она.
      — У них весь алкоголь отличный, они так много и так весело пьют — я даже
      удивилась! С драками, танцами на столах, но главное — каждый вечер. Потрясающие
      люди, такие лёгкие, такие непохожие на прочих европейцев — совершенно очевидно,
      почему они сразу, как смогли, вырвались из-под гнёта Британии и переняли нашу
      идеологию и строй. Только у них ещё лучше, хоть и нехорошо подобное говорить. Вдруг
      кто из представителей власти услышит! — беззаботно хихикнула Лидочка. — Но
      правда ведь лучше: проще, безумней как-то, их прёт и прёт! Впрочем, мой дед
      говорил, у нас первые лет двадцать после Революции тоже было не как теперь. Но
      и в Ирландии уже давно не двадцать лет с образования Соседства, а они всё равно
      молодцы — это вам не бессмысленный юбилей Первого Большого! Я слышала, скучно
      вышло, только какой-то медицинской программой вроде донорской крови или чем-то
      типа того мероприятие и спасли. Донорской крови, ты подумай! Или там были анализы
      какие-то для будущих исследований…</p>

    <p>— Анализы, — машинально ответил
      Максим. — Белковый синтез нового поколения, был сбор крови для банка образцов.</p>

    <p>То есть для того, чтобы чума не
      пошла дальше.</p>

    <p>Бедроградская гэбня —
      профессионалы. Прекрасно сориентировались в предлагаемых обстоятельствах.</p>

    <p>— Не суть, — Лидочка не стала
      вникать в легенду. — В любом случае, у ирландцев такого нет. Они до сих пор на чистом
      адреналине живут, хоть и поменяли форму государственности уже полвека как! Это,
      наверное, потому что Британия близко — вроде все тихо давно, но образ врага всё
      равно маячит, не даёт расслабиться на диване.</p>

    <p>Вроде тихо, подумал Максим. Так
      тихо, что можно год прожить в Ирландском Соседстве и не догадаться, что где-то
      совсем рядом, в паре часов пути по морю, идёт война.</p>

    <p>Аккуратная маленькая война
      Объединённой Британии-Кассахии с Ирландским Соседством при поддержке Соседства
      Всероссийского, имеющего там и свой частный интерес.</p>

    <p>Противоборствующие стороны
      поступили здраво: в отсутствие возможности исчерпать конфликт они сделали его
      игрушечным, немного ненастоящим, протекающим по определённым правилам в
      отведённом специально для этого конфликта месте.</p>

    <p>Чтобы ни непричастные граждане,
      ни бдительные Европы с Пактом о Неагрессии не совались под обстрел.</p>

    <p>Сам Максим не имел к аккуратной
      маленькой войне никакого отношения, но не так давно и ему пришлось по воле
      обстоятельств выслушать от компетентных лиц познавательную лекцию. Лекцию с
      однозначным выводом: если у противоборствующих сторон нет своей собственной
      безлюдной Северной Шотландии, где можно как угодно развлекаться и что угодно
      друг другу доказывать без последствий для остального мира, пусть сидят смирно и
      не высовываются.</p>

    <p>Пусть.</p>

    <p>Только самому Максиму больше нет
      — и не может быть — до этого дела.</p>

    <p>— Знаешь, я бы там осталась на
      подольше. Там всё время пьют, там куда ни плюнь — сплошные холмы.
      Зелёные-зелёные, прямо как на открытках! Я и не думала, что бывает такая трава,
      на которой спать мягче, чем в постели. А там все это делают — останавливаешься
      где-нибудь посреди шоссе и просто валишься в траву, — Лидочка засмеялась. —
      Столько одежды за год позеленело — пришлось всё к лешему выкинуть, когда
      чемодан собирала!</p>

    <p>Там все это делают, подумал
      Максим, останавливаются посреди шоссе и валятся в траву.</p>

    <p>В доме-башне на углу проспекта
      Объединённых Заводоводств и улицы Поплеевской до сих пор висит на стуле так и
      не выброшенная рубашка с травяными разводами и парой оторванных пуговиц.</p>

    <p>Сначала казалось, выбросить
      самому — слишком жест; лучше просто не замечать. Не замечать рубашку на стуле
      несколько месяцев подряд.</p>

    <p>А теперь… теперь даже не
      вспомнилось бы, если б не Лидочка.</p>

    <p>— Я в результате так много
      прогуливала собственных пар, так много каталась — в тот город, в этот
      город, посмотреть все подряд развалины,
      завернуть в какую-нибудь деревеньку, где варят самый-самый аутентичный
      картофельный самогон… В общем, так много каталась, что вдруг подумала: а почему
      бы мне самой таксистские права не получить? По новым правилам они и там, и у
      нас действительны безо всяких переэкзаменовок. Так что после Ирландии я
      таксист! Вот не подпишет мне Ройш бумажку — из вредности ведь заявление об
      уходе настрочу, мне теперь есть чем ещё в жизни заниматься! — Лидочка осеклась,
      сконфуженно замолчала, но хотя бы не начала лепетать извинения.</p>

    <p>И без извинений непросто.</p>

    <p>В коридорах третьего этажа было
      совсем пустынно, но со стен всё равно косились портреты, скалились линиями
      государственных границ карты, кололись остриём стрелок схемы. Они будут висеть
      тут ещё как минимум пару десятков лет — третий этаж ленив и консервативен, это
      на втором, где кафедра истории науки и техники, каждую неделю что-нибудь
      меняется…</p>

    <p>Менялось.</p>

    <p>Охрович и Краснокаменный, всегда
      занимавшиеся всем этим, сейчас… А что, собственно, сейчас с Охровичем и
      Краснокаменным? Максим не знал. Ему и не полагалось знать, он больше не голова
      Университетской гэбни. Да только Охрович и Краснокаменный — тоже.</p>

    <p>В это невозможно поверить, но это
      — так.</p>

    <p>Максим непроизвольно сжал в
      кармане монетку, которую сегодня случайно подобрал у Лария под столом.
      Дореволюционную, с вычурной и тонкой чеканкой — совсем такую же, какую
      использовали Охрович и Краснокаменный для определения лика революционного
      чучела.</p>

    <p>А может даже — <i>ту самую</i> монетку.</p>

    <p>Всю жизнь казалось, что
      «Университетская гэбня» и означает «Максим, Ларий, Охрович и Краснокаменный».
      Первый состав, продержавшийся девять полных лет. Но девять полных лет, как
      выясняется, — ещё далеко не вся жизнь, далеко не вечность, а всего-навсего
      маленькая ступенька на лестнице вечности.</p>

    <p>И с этой ступеньки был сделан
      шаг.</p>

    <p>Вопрос лишь в том, вверх или
      вниз.</p>

    <p>&nbsp;</p>

    <p>Вечером юбилейного дня Максима только-только
      выпустили из Порта.</p>

    <p>Без объяснений, без разговоров:
      Святотатыч был задёрган и всё никак не мог найти Максиму таксиста — вечный Муля
      Педаль сослался на какие-то дела, отвёл глаза и только буркнул что-то
      по-таврски, прибавив горстку вполне понятных росских ругательств.</p>

    <p>Незнакомый и совершенно
      неразговорчивый таксист доставил Максима на истфак. Максим растерялся:
      подниматься на кафедру? Но зачем, что он скажет, разве кто-то может его там
      ждать?</p>

    <p>Ждал его, разумеется, Гуанако.
      Кивнул, как ни в чём не бывало улыбнулся и продолжил тыкать табельным гэбенным
      пистолетом (чьим?) в карту Британии. Перед картой сидел какой-то человек,
      слушал с непроницаемо-дружелюбным видом одновременно скабрёзные и
      нравоучительные байки про маленькую аккуратную войну. «Врат Карлович Поппер», —
      протянул он руку Максиму, когда Гуанако отвлёкся на ввалившихся с грохотом
      Охровича и Краснокаменного.</p>

    <p>«Новый голова Университетской
      гэбни!»</p>

    <p>«Специально обученный латентно
      вражеским юрфаком Попельдопель-младший!» — не сдержались они от комментариев.</p>

    <p>Максим вспомнил: у Поппера
      действительно был младший брат в аспирантуре юрфака. Почему именно ему выпало
      стать заменой Максима, Максима уже не касалось. Только резануло что-то внутри —
      человек не с этого факультета, не с этой кафедры. Странно.</p>

    <p>Но ещё странней было осознать,
      что Охрович и Краснокаменный ввалились с заявлениями о собственном уходе.</p>

    <p>Обоснование сочинили в своей
      обычной манере: преподавательская-де деятельность отнимает у них слишком много
      времени, которое могло бы быть потрачено на личностный рост. То есть на
      посещение юбилейных мероприятий. Их собственное (добровольное!) рабочее рвение
      лишило их сегодня порции идеологических вливаний, положенных им как любым гражданам
      Всероссийского Соседства.</p>

    <p>И они-де осознали вдруг
      гражданскую опасность трудоголизма и приняли взвешенное решение завершить свою
      преподавательскую карьеру, которая ставит под угрозу личностный рост.</p>

    <p>«Заметьте, даже нигде толком не
      соврали!»</p>

    <p>«Пусть попробуют отклонить наш
      сознательный и насквозь идеологичный крик о помощи!» — хвастались они Гуанако.</p>

    <p>Эту лазейку нашёл Ройш, когда
      Максим безответственно исчез в неподходящий момент: ни из одной гэбни по
      собственному желанию не уходят. Из Университета же — легко,&nbsp;как с любого
      другого рабочего места. Головы Университетской гэбни согласно служебной
      инструкции <i>должны быть</i> сотрудниками БГУ
      имени Набедренных. Вывод очевиден.</p>

    <p>«Зачем?» — вырвалось у Максима.</p>

    <p>Охрович и Краснокаменный
      заговорили между собой визгливой тарабарщиной, которую они много лет подряд
      выдавали за афстралийский язык, и полезли прочувствованно обниматься с Вратом
      Поппером. Вернее, полез неожиданным образом один Охрович, а Краснокаменный
      довольно убедительно обнялся с кем-то воображаемым.</p>

    <p>Гуанако посмотрел на Максима
      своими до смерти надоевшими виноватыми глазами: «Они будто бы говорят нам — как
      умеют, так и говорят — что волноваться нечего, ибо достойные сидеть в гэбне
      люди уже найдены. Собственно, Попельдопель-старший и Писарь. В смысле, Стас
      Никитич, сотрудник лингвистического факультета. Ларий остаётся».</p>

    <p>Ларий остаётся.</p>

    <p>Максим испытал укол… зависти?</p>

    <p>Нет.</p>

    <p>Жалости?</p>

    <p>Тоже нет.</p>

    <p>Наверное, уважения.</p>

    <p>«Укол уважения» — такая
      неказистая, но такая точная в данном случае формулировка.</p>

    <p>Одно дело, если б заменили только
      Максима. Ларий, Охрович и Краснокаменный остались бы вместе, адаптировались бы,
      держась друг за друга, к новым условиям.</p>

    <p>Если остаётся <i>только</i> Ларий, ему придётся нелегко.</p>

    <p>Но он готов, как Ройш готов сесть
      пока что в завкафское кресло, как Охрович и Краснокаменный готовы уволиться.
      Очевидно, это зачем-то нужно. По своей воле они бы не пошли на такое, они ведь
      в самом деле любят — не только шестой уровень доступа, нет! — кафедру, смущать
      студентов на лекциях, даже руководить курсовыми. Леший, они выглядят
      сумасшедшими, но они умеют работать, они нужны здесь!</p>

    <p>«Условие Бедроградской гэбни», —
      шепнул Гуанако.</p>

    <p>«И мы… вы подчинились?» — не
      поверил Максим.</p>

    <p>«Подчинились и отдались, —
      Гуанако, ни с кем не прощаясь, вывел Максима с кафедры. — Политика в этом
      смысле ничуть не хуже нежно любимого Охровичем и Краснокаменным бордельного
      дела. Отдались и на этом хорошо наварились».</p>

    <p>«Кто наварился, если останется
      только Ларий?»</p>

    <p>«Университет, Максим».</p>

    <p>Они вдвоём с Гуанако спустились
      по главной лестнице, вышли через главный вход, на крыльце притормозили закурить
      между парами стройных, безвкусно-бордельных колонн.</p>

    <p>Истфак — бывшая Академия Йихина,
      бывший оскопистский бордель. Через два квартала налево стоит себе здание безо
      всяких колонн, которое занимает теперь действительный университетский бордель —
      причуда и каприз первого состава Университетской гэбни, плевок в лицо гэбне
      Бедроградской.</p>

    <p>Максим никогда не одобрял проклятый
      бордель, считал его слишком неадекватным способом демонстрации собственного
      бюрократического превосходства. Поначалу сам убивался над сметами, но потом
      Охрович и Краснокаменный взяли в свои руки даже бордельную бухгалтерию, так что
      Максим давно перестал вникать, что там делается и кому это нужно.</p>

    <p>Но сейчас почему-то стало обидно
      даже за бордель. Новый состав Университетской гэбни закроет его или оставит всё
      как есть?</p>

    <p>Поппер (старший) оставил бы
      запросто, он всегда веселился по этому поводу. Он вообще немало веселится, хоть
      ему и не тягаться с Охровичем и Краснокаменным. Но Поппер — занятой человек,
      завкаф вирусологии, учёный с мировым именем. Ему вечно не хватает времени на
      всё сразу. Он может просто-напросто не захотеть разбрасываться на ерунду.</p>

    <p>Наверное, хорошо, что в гэбне
      будет старший Поппер. Он прекрасно проявлял себя все эти годы в качестве
      полуслужащего, он по первому требованию включился в чуму и продемонстрировал,
      что может больше.</p>

    <p>Стасу Никитичу с лингвистического
      факультета на бордель плевать. Он был полуслужащим совсем другого толка: был,
      потому что мог и предложили, а не потому что хотел.</p>

    <p>Он сидел когда-то в гэбне
      Колошмы, после её развала находился под наблюдением Бюро Патентов. Когда у него
      закончился положенный отпуск, наркотики и деньги, он сунулся было снова подделывать
      почерк заключённых в тюрьму пониже рангом, но в какой-то момент начал опасаться
      давления со стороны Андрея Зябликова и, быстро разобравшись в тонкостях начинавшейся
      борьбы, попросил защиты у Университета. Так он оказался на лингвистическом
      факультете (пригодились таланты высококвалифицированного расшифровщика и
      переписчика). В обмен на оказанную поддержку его попросили стать
      университетским полуслужащим — человек, виртуозно ставящий чужие подписи,
      всегда пригодится.</p>

    <p>Пригодился не раз, вот даже подписал
      заявление об уходе Максима.</p>

    <p>Наверное, хорошо, что в гэбне
      будет Стас Никитич. Он не гонится за властью, он устал от всех этих дрязг ещё
      на Колошме, он не будет делать глупостей.</p>

    <p>Про младшего Поппера Максим
      практически ничего не знал. Но, наверное, хорошо, что он будет в гэбне. Сыщутся
      поводы, чтобы было хорошо.</p>

    <p><i>Наверное, всё хорошо.</i></p>

    <p>Максим искренне ненавидел
      «наверное».</p>

    <p>Должно быть — «наверняка».</p>

    <p>В питейном заведении неподалёку
      от истфака, куда Гуанако притащил Максима, было совсем немного народу. Приличное
      место, в которое ходят приличные люди (Гуанако поморщился на скудный
      ассортимент напитков, хотя высказываться не стал). Приличные люди ещё гудели на
      народных гуляниях в честь юбилея, в питейных заведениях Старого города тем
      вечером их ничто не держало.</p>

    <p>После второй стопки водки Максим
      всё же решился высказать вслух, что он, разумеется, теперь не имеет ни
      юридического, ни морального права оценивать чьи бы то ни было действия, но
      мысль о посторонних людях в Университетской гэбне его страшит.</p>

    <p>Как могут справиться с такой
      ответственностью посторонние люди, третьестепенные, простите, фигуры?</p>

    <p>«А зачем делить фигуры на первостепенные
      и все остальные? — неприятным тоном осведомился Гуанако, сжав горлышко графина
      с водкой. — С таким подходом вам, Максим Аркадьевич, всё-таки стоило сесть в
      Бедроградскую гэбню, которая всегда всё делает сама и только сама!»</p>

    <p>Максим не понял, к чему это
      замечание, Гуанако отстал от графина, рассмеялся и вскоре вовсе перестал
      злиться, потому что быстро увлёкся (так же, как и всегда) рассказыванием байки.</p>

    <p>Байки, которую Максим не знал.</p>

    <p>Байки, которую целиком не знал уже
      никто, кроме Гуанако.</p>

    <p>Ведь Хикеракли (член
      Революционного Комитета, обладатель второго уровня доступа, основатель
      Университетской гэбни) на самом деле давно покойник, в отличие от.</p>

    <p>Когда Гуанако вменили убийство
      легендарного Начальника Колошмы, Бюро Патентов позаботилось о том, чтобы
      большинство госслужащих считало, что заключённый, спровоцировавший всю ту кошмарную
      ситуацию, был расстрелян.</p>

    <p>Хикеракли в «большинство
      госслужащих» никогда не входил.</p>

    <p>Он был старый алкоголик с
      огромными возможностями. Он почему-то решил, что хочет навестить Гуанако на
      Колошме. Может, потому что это он когда-то ставил подпись под заключением об
      идеологической пригодности книг Гуанако к массовому тиражированию. Может,
      потому что его терзало любопытство относительно реальных обстоятельств
      скандального развала Колошмы. Может, ещё по какой-то причине.</p>

    <p>На Колошме Гуанако заговорил Хикеракли
      зубы. Или даже наоборот.</p>

    <p>Когда речь зашла о давно зачахшем
      контрреволюционном движении истфака БГУ имени Набедренных, Гуанако продавил на
      сей счёт свою позицию. Не столько из любви к продавливанию, сколько потому, что
      материалы по контрреволюционному движению снова вытащили из пыльного ящика —
      как раз в связи с первоначальным ложным обвинением Гуанако, предъявленным ему
      Столичной гэбней.</p>

    <p>Многим людям это грозило всё той
      же Колошмой.</p>

    <p>В теории выход был прост:
      Университет — и всё, что с ним связано, — нуждается в защите.</p>

    <p>Раньше такой защитой был Воротий
      Саныч — прежний завкаф науки и техники, полуслужащий Бюро Патентов.</p>

    <p>Воротий Саныч в силу возраста и
      изменившейся политической обстановки перестал справляться. Например, он
      пропустил те самые поползновения Столичной гэбни в адрес Гуанако, что привело к
      печальным последствиям. Не только для Гуанако лично — для всех. Даже гэбне
      Колошмы досталось, не говоря уже о ком поближе к Бедрограду и Столице.</p>

    <p>Столичная гэбня преследовала
      собственные интересы — вербовала идеолога из Бедрограда, дабы насолить гэбне Бедроградской
      (очередной пример гэбенных разборок, осколки которых разлетелись
      непозволительно далеко). Бедроградская гэбня не имела реальных шансов пресечь
      их действия, поскольку сама не контролировала отданный под личную
      ответственность Воротия Саныча Университет.</p>

    <p>Гуанако, побывавший на допросе у
      гэбни города Столицы, доподлинно знал, что один из трёх её голов — профессор
      Столичного Университета; человек, с которым он ранее имел контакты академического
      толка.</p>

    <p>Человек, радеющий в Столичной
      гэбне за престиж и безопасность Столичного Университета, породивший идею
      переманить бедроградского Гуанако.</p>

    <p>Непереманившийся Гуанако и
      могущественный Хикеракли, распивая твиревую настойку и савьюровый самогон на
      Колошме, додумались до того, что Бедрограду неплохо было бы перенять столичную
      схему.</p>

    <p>Протолкнуть кого-то из
      Университета прямо в Бедроградскую гэбню и таким образом обеспечить
      Университету всё то, чего ему так остро не хватает: контроль и защиту одновременно.</p>

    <p>Учитывая вновь всплывший вопрос давнишних
      антигосударственных настроений на истфаке, с этими двумя проблемами Гуанако и
      Хикеракли хотели разобраться в один ход — хикераклиевским авторитетом посадить
      в Бедроградскую гэбню кого-то из почившего контрреволюционного движения.</p>

    <p><i>Посадить в Бедроградскую гэбню Максима.</i></p>

    <p>Сейчас, почти десять лет спустя,
      Максим, услышав об этом, выпил водки прямо из графина.</p>

    <p>Почти десять лет назад Максим был
      аспирантом кафедры истории науки и техники. Одним из немногих аспирантов БГУ
      имени Набедренных из того самого контрреволюционного выпуска. Гуанако клялся
      Хикеракли, что Максим справится с государственной службой, что как глава того
      самого движения он обладает всеми необходимыми характеристиками, что это будет
      правильно.</p>

    <p>И смешно.</p>

    <p>Глава антигосударственного
      движения на государственной службе.</p>

    <p>Наверное, потому, что это было
      смешно, Хикеракли не просто согласился и пообещал, а даже развёл активную
      деятельность. Потряс собственным мнением второго уровня доступа перед Бюро Патентов
      и настоял на изменениях в составе Бедроградской гэбни.</p>

    <p>Согласился Хикеракли, согласилось
      Бюро Патентов, но не согласилась сама Бедроградская гэбня.</p>

    <p>Как им это удалось в отсутствие
      официальных рычагов воздействия — ни Хикеракли, ни Гуанако не узнали. Узнало,
      вероятно, Бюро Патентов, поскольку совсем скоро предложило Хикеракли
      альтернативу: переживаете за Университет? Формируйте там собственную гэбню.</p>

    <p>Кто будет в ней сидеть, придумывал
      ошеломлённый таким развитием событий Гуанако. Предлагал Хикеракли отозвать
      запрос, подумать ещё, что из всего этого может последовать, — но и сам
      Хикеракли уже ничего не решал: Бюро Патентов успело загореться фантазией о
      гэбне в Университете.</p>

    <p>Университет, а вообще-то,
      собственно истфак, — это ведь бывшая Академия Йихина, её студентами и
      вольнослушателями были все члены Революционного Комитета. Бюро Патентов не
      может бросить на произвол судьбы гнездо Революции.</p>

    <p>«Недоразумение, — усмехнулся
      графину с водкой Гуанако. — Университетская гэбня на шестом уровне доступа —
      недоразумение, следствие череды самых разнообразных желаний самых разнообразных
      людей».</p>

    <p>У Максима же просто не осталось
      слов.</p>

    <p>Впрочем, если бы какие-то слова и
      остались, Максиму всё равно пришлось бы немного помолчать — горстка жителей
      города Бедрограда, в котором затихала эпидемия водяной чумы, шумной волной
      прокатилась мимо окон питейного заведения.</p>

    <p>Жители города Бедрограда (и всего
      Всероссийского Соседства заодно) отмечали десятилетний юбилей Первого Большого
      Переворота.</p>

    <p>&nbsp;</p>

    <p>— Учащиеся БГУ имени Набедренных,
      шли бы вы отсюда! — шутливо прикрикнула Лидочка на каких-то студентов,
      расположившихся с книжками и сигаретами промеж дальних стеллажей библиотечного
      хранилища.</p>

    <p>Библиотечное хранилище — такое
      специальное место на третьем этаже, где можно укрыться от лишних ушей с любыми
      своими делами. Студенты часто используют его как ещё одну курилку, только
      потише и поспокойней. Максим сам когда-то использовал — прогуливая гуанаковский
      модуль по истории общественного прогресса, вёл тут беседы об идеологической
      фальши со всеми желающими.</p>

    <p>Трое студентов ретировались
      немедленно, едва завидев Максима. Должно быть, не осознали, не поверили ещё до
      конца, что он более не заместитель заведующего кафедрой и даже не
      преподаватель. Четвёртый студент медлил, складывал в сумку конспекты с крайне
      рассеянным видом. Когда он наконец двинулся к выходу, Лидочка уже устроилась с
      ногами на освободившемся столе.</p>

    <p>— Вы, кажется, что-то забыли! —
      позвала она студента обратно. — Книжку, с закладками!</p>

    <p>Тот проснулся, сбросил своё
      оцепенение, поблагодарил и заулыбался, протянул руку…</p>

    <p>Только тогда Максим заметил, <i>что за книгу</i> нашла Лидочка.</p>

    <p>Из-за множества исписанных закладок
      сперва не обратил внимания. Думал, это какая-то серьёзная монография, но нет —
      роман, хоть и исторический, хоть и в скромной чёрной обложке.</p>

    <p>Исторический роман про Падение
      Вилонского Хуя — про что же ещё в контексте грядущей экспедиции?</p>

    <p>— Максим Аркадьевич, — решительно
      начал студент, и Максим по привычке сразу вспомнил: какая-то неросская, но
      простая фамилия, второй курс, кафедра европейской истории, в конце того
      учебного года пытался переводиться на науку и технику, но не потянул
      академическую разницу у Охровича и Краснокаменного.</p>

    <p>Они строго спрашивают с тех, кто
      идёт на науку и технику не за наукой и техникой.</p>

    <p><i>Спрашивали.</i></p>

    <p>— Максим Аркадьевич, Габриэль
      Евгеньевич вернётся на факультет? Или вы не в курсе?</p>

    <p>Максим столько раз отвечал в
      проклятом мае на этот же самый вопрос, что теперь, больше не будучи
      преподавателем, обязанным соблюдать Устав, очень хотел просто съездить кулаком
      по вопрошающему.</p>

    <p>Лидочка, заметив его реакцию, тут
      же встряла сама:</p>

    <p>— Ну вы б ещё поинтересовались,
      вернётся ли на факультет без вести пропавший профессор Гуанако! Из своей
      экспедиции десятилетней давности, — Лидочка фыркнула, Максим молча вздрогнул. —
      Великих людей ждать бессмысленно. Они появляются и исчезают по неведомым
      причинам, а мы можем только сидеть в библиотечном хранилище и наблюдать за
      этим, как — ну не знаю, как за звездопадом! Молодой человек, я достаточно
      поэтично выразилась, чтоб вы отстали от преподавателей и дали им спокойно
      покурить?</p>

    <p>Студент постеснялся Лидочки
      (второй ведь курс — никогда раньше её не видел, не знает, что на деле она
      гораздо более расположена к панибратству, чем прикидывается) и, забрав у неё из
      рук старый роман Онегина Г. Е., как можно скорее исчез.</p>

    <p>Лидочка извлекла из своей яркой
      безразмерной сумки обещанный ирландский ликёр.</p>

    <p>Максим не ко времени вспомнил,
      что пару дней назад, столкнувшись на медфаке с Димой, пообещал себе не пить (хотя
      бы пока что).</p>

    <p>Максим ходил к Попперу за
      уточнениями, как именно лучше связываться с Медицинской гэбней неофициальным
      путём. Зачем к Попперу ходил Дима, Максим не знает и знать не может — никто
      более не обязан перед ним отчитываться.</p>

    <p>Максим ходил к Попперу за
      уточнениями не слишком трезвый: он обдумал, наконец, предложение Гуанако по
      поводу своей дальнейшей судьбы, озвученное за графином водки в день юбилея.</p>

    <p>Гуанако, разумеется, отпирался,
      всё повторял, что это не предложение, что он не то имеет в виду, что это не тот
      путь, по которому следует тотчас стремглав побежать. Что надо подумать тысячу
      раз — что тут <i>есть</i> над чем думать.</p>

    <p>Повторял, что сболтнул в запале,
      потому что такой вариант действительно имеется, но он ведь не единственный
      (Максим ещё тогда понял: не единственный, но правильный — хоть что-то <i>правильное</i> во всей этой истории).</p>

    <p>Когда не слишком трезвый Максим
      столкнулся с Димой, он его не узнал. Совершенно по-бытовому: не заметил в
      дверях, чуть задел плечом, извинился сначала, а потом оторопел. Максим не мог
      бы словами объяснить, что именно было не так с Димой, но — было.</p>

    <p>Вроде всё то же самое, только в
      этого человека Максим точно не стрелял.</p>

    <p>Максим стрелял в другого
      человека.</p>

    <p>В затуманенном алкоголем мозгу
      возникла глупая, дикая совсем мысль: значит, всё-таки хорошо стрелял.</p>

    <p><i>Всё-таки убил.</i></p>

    <p>Зря Максим дал согласие
      попробовать Лидочкин ликёр из Ирландии. Ещё не притронулся — а такая чушь лезет
      в голову.</p>

    <p>— Максим, я уже догадалась, что
      это не лучшая тема для обсуждения. Но мне ведь всё равно кто-нибудь сплетен на
      хвосте принесёт — факультет всё-таки! Так что расскажи уж сам, что там стряслось
      с твоим Онегиным?</p>

    <p>Максим вздохнул и взялся за
      сигареты.</p>

    <p>Даже если бы он хотел
      выговориться, он бы не стал: Лидочка не полуслужащий, Лидочка не видела чуму в
      Бедрограде, Лидочке попросту небезопасно быть в курсе некоторых вещей.</p>

    <p>Леший, леший.</p>

    <p>Лидочка, не выносившая пауз в
      беседе, вновь защебетала:</p>

    <p>— Мне, кстати, летом когда-то
      ребята с росской кафедры — в смысле, тамошней — говорили, будто бы видели
      Онегина прямо в Ирландии! Мельком. Хотели подойти, но он был не один, чересчур
      не один, и коллег не заметил. Ну, я спросила, с кем он там был не один. Подумала,
      что раз не с тобой, то ошиблись, видимо, — Лидочка опрокинула в себя щедрый
      глоток ликёра. — Ой, я опять что-то не то говорю, да?</p>

    <p>У обещания пока что не пить, подумал
      Максим, истёк срок годности.</p>

    <p>— Ты говоришь то, что есть, —
      ликёр был слишком медовым, но вкус виски всё равно пробивался из-под сладких
      добавок. — Да, коллеги с кафедры росской истории, скорее всего, не обознались.
      Да, сам я в Ирландию не катался.</p>

    <p>— Извини.</p>

    <p>— Забудь, уже не имеет значения.</p>

    <p>Мешать виски с клевером —
      всё-таки странная затея. Хотя в отсутствие альтернатив сойдёт.</p>

    <p>Надо было сегодня забирать с
      кафедры не бессмысленные теперь остатки своих вещей, а несколько бутылок
      твиревой настойки.</p>

    <p>Она привычнее.</p>

    <p>— Слушай, да я только потому и
      спросила, что мне из-за долбаного Ройша в завкафском кресле этой ночью сплошные
      кошмары будут сниться! — Лидочка аж раскраснелась от злости. — Вот урод
      бесчувственный, подписи ему жалко.</p>

    <p>Люди — разные, подумал Максим, и
      это всегда стоит держать в голове.</p>

    <p>Как и то, что у разных людей
      разные способы быть бесчувственными. Ройш хотя бы не пьёт, Ройш всего-навсего
      валит на себя непомерный объём работы.</p>

    <p>У всех <i>свои способы и свои поводы</i>.</p>

    <p>И если всё-таки не поговорить об
      этом хоть с кем-нибудь (пусть умолчаниями, пусть в самых общих чертах), можно не
      изображать перед самим собой испытание силы воли, а сразу упиться в хлам.</p>

    <p>— Есть вероятность, что Габриэль
      вернётся к работе через некоторое время, — Максим впервые с момента окончания чумы
      произнёс «Габриэль» и сам дёрнулся. — Он нездоров, лечится в Медицинском
      Корпусе.</p>

    <p>Вспомнилось, как, услышав о
      Медицинском Корпусе от Охровича и Краснокаменного на девятый день, Максим
      почему-то решил, что это работа Бедроградской гэбни. Они ведь обещали
      обеспечить Габриэлю безопасность, когда накануне приходили к Максиму спрашивать
      о заражении дома на Поплеевской!</p>

    <p>Но безопасность Габриэлю
      обеспечил Дима, которого Максим убил ни за что.</p>

    <p>Кто заразил их с Габриэлем дом —
      централизованно, через канализации — до сих пор неизвестно. Ответ найти не
      удалось. Все плюнули, у всех есть дела поважнее.</p>

    <p>Теперь Максим понимал: да, есть
      дела поважнее, на девятый день у самого Максима могли бы быть таковые. Он мог
      бы помочь любой мелочью Университету или Порту, но он предпочёл убить Диму.</p>

    <p>Уже плевать, всем плевать, но
      немного стыдно.</p>

    <p>— Что-то серьёзное? — искренне
      встрепенулась Лидочка, хотя Максим прекрасно знал, что Габриэля она всегда
      недолюбливала.</p>

    <p>— В Медицинский Корпус с
      несерьёзным не берут. Но всё будет в порядке.</p>

    <p>По крайней мере, подумал Максим, очень
      хотелось бы в это верить.</p>

    <p>Да только верить — это отдельный
      талант.</p>

    <p>— А… — начала было Лидочка, но
      передумала, просто сделала ещё глоток ликёра.</p>

    <p>— Спрашивай.</p>

    <p>— А ты?</p>

    <p>— Еду в Столицу, — честно ответил
      Максим. — Сегодня вечером.</p>

    <p>— В Медкорпус?</p>

    <p>— Именно, — и это снова было
      честно.</p>

    <p>Лидочка ведь не уточняла, едет ли
      он держать Габриэля за руку или вносить собственную посильную лепту в оплату
      помощи от Медицинской гэбни.</p>

    <p>Медицинская гэбня была бы не
      прочь разобраться на живом материале, что же не так с теми самыми людьми 51-го
      года рождения, которые отметились и в контрреволюционном движении, и в других
      нашумевших в своё время экстремистских акциях.</p>

    <p>Сведения о частной лаборатории,
      проводившей несанкционированные эксперименты по изменению уровня агрессии у
      целой партии младенцев, крайне противоречивы. Гормональные показатели тех, кто
      вырос из этих младенцев, колеблются на границе, но всё-таки в пределах нормы —
      только для Виктора Дарьевича, главы Когнитивной Части Медицинского Корпуса, это
      ровным счётом ничего не значит. У главы Когнитивной Части есть теория, которую
      ему было бы интересно проверить.</p>

    <p>«Это не единственный вариант», —
      повторял Гуанако.</p>

    <p>Гуанако вечно видит варианты там,
      где для Максима их быть не может.</p>

    <p>— Ты держись, — крайне уместно
      вздохнула Лидочка.</p>

    <p>— Спасибо, — сдержанно кивнул
      Максим.</p>

    <p>Максим не думал, что Медицинская
      гэбня так жестока и бесчеловечна, как о ней рассказывают. Не было ни страха, ни
      сомнений: если он может сделать что-то полезное сам, он должен сделать.
      Добровольно согласиться на эксперименты в Когнитивной Части — это ещё не
      самоубийство.</p>

    <p>Лидочкино «держись» уместно
      совсем по другому поводу: ведь есть же ещё вопрос, <i>что делать дальше</i>, если уж Когнитивная Часть — не самоубийство.</p>

    <p>Наверное, можно перебраться в
      проклятое Ирландское Соседство, устроиться там на росскую кафедру.</p>

    <p>Наверное, можно даже попытаться
      поговорить с Габриэлем, когда тот будет в порядке.</p>

    <p>Максим предпочёл бы любому
      многообещающему «наверное» простое и честное «наверняка». Поэтому —</p>

    <p><i>Наверняка можно просто не слишком спешить выбираться из Когнитивной
      Части.</i></p>

    <p>Для начала.</p>

    <p>&nbsp;</p>

    <p>…Когда Максим пришёл на вокзал,
      ощущение «для начала» всё-таки уступило место ощущению конца.</p>

    <p>Любой вокзал — странное место,
      каждый шаг по перрону делает прошлое всё дальше, а неизвестность, заброшенность
      и грязноватый туман — всё ближе. Поезда уходят в какое-то непонятное «завтра»,
      но перед тем, как уйти насовсем, на медленном ходу пробираются меж кирпичных
      коробок пустых депо и разобранных, омертвевших, никуда не отбывающих больше
      составов.</p>

    <p>Да, Максим снова был не слишком
      трезв, поскольку в яркой безразмерной сумке Лидочки оказалась не только одна
      бутылка и не только ликёра.</p>

    <p>В ушах еле слышно звенело, и
      почему-то очень хотелось зависнуть сейчас на краю перрона, до рези в глазах
      всматриваться в прямую вечную лесенку рельсов и думать, что будет, если упасть
      прямо перед гулко тормозящим поездом, везущим в Бедроград гостей из Крайнего
      Северного региона.</p>

    <p><i>«Дык ничего не будет, мертвяк! Теперь только лясы точить»,</i> —
      каркнул в голове у Максима хриплый насмешливый голос, в котором нелегко было
      признать обычные интонации Гуанако.</p>

    <p>В мае слетевший с катушек Гуанако
      вдруг придумал, как допросить Гошку о планах Бедроградской гэбни. Придумал,
      чтобы успокоиться, чтобы чем-то себя занять.</p>

    <p>Максим слушал запись этого
      псевдо-допроса не единожды — нужно же было вычленить из сюрреалистических
      разговоров о жизни и смерти все возможные точные сведения.</p>

    <p>И каждый раз при прослушивании у
      Максима невольно бежал по спине холодок в том месте, где загробный
      шаман-Гуанако объяснял: <i>«Поначалу-то лясы
        о том точат, что первое на уме да последнее перед крышкой гроба, правила тут
        такие. А у тебя знаешь что последнее? Знаешшшь-знаешшшь, только не
        признаёшшшь!»</i></p>

    <p>Максим отшатнулся от края
      перрона.</p>

    <p>Не хотелось бы рассказывать
      головам Загробной гэбни, что тем самым «последним перед крышкой гроба» была у
      Максима фляжка какой-то ирландской дряни и невнятные жалобы Лидочке.</p>

    <p>Не на жизнь, нет.</p>

    <p>Не на теперешние факультетские
      изменения.</p>

    <p>Не на то, как не склеилось обратно
      у Максима с Габриэлем после проклятого мая.</p>

    <p>Всего лишь жалобы на — леший, и почему
      только пришло на ум? — безвременный конец контрреволюционного движения.</p>

    <p>Если бы не он, всё вышло бы
      совсем иначе.</p>
  </>
}

export default chapter37
