import React from 'react'
import Helmet from 'react-helmet'
import MarkdownPage from '../../MarkdownPage'
import yiiha from "../../Data/yiiha";

const BonusYiiha = () => <>
  <Helmet>
    <title>Разрозненные замечания о нашем сегодняшнем понимании Йыхи Йихина, хозяина оскописткого салона и основателя Петербержской Исторической Академии</title>
  </Helmet>
  <MarkdownPage
    sidebarPosition='none'
    markdown={yiiha}
    noIndent
  />
</>

export default BonusYiiha