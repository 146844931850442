import React from 'react'
import {RouteComponentProps} from "react-router";
import PageNotFound from "./PageNotFound";
import {DiscussionEmbed} from "disqus-react";
import Helmet from "react-helmet";
import {Link} from "react-router-dom";

interface Article {
  title: string,
  image: string,
  text: React.ReactChild
}

const articles: {
  [key: number]: Article
} = {
  62: {
    title: 'Грифоны держат небо',
    image: 'header-big_0.jpg',
    text: <>
      Полная версия картинки, кусочки которой вы уже видели в шапке и на главной станице.<br/>
      Это грифоны. Они опасны. По неким не совсем прозрачным соображениям они и есть наша обложка.
    </>
  },
  63: {
    title: 'Габриэль Евгеньевич печалится',
    image: 'go copy_1.jpg',
    text: 'Старый (хоть и вечно молодой) Габриэль Евгеньевич о чём-то тихо грустит. Мы даже догадываемся, о чём, но пусть это останется его тайной. Тем более что в те далёкие времена, когда рождался сей парадный портрет, даже до первой — устной и умозрительной — редакции событий собственно «Чумы» оставалась добрая неделя.'
  },
  64: {
    title: 'Ещё грифон',
    image: 'griffin.jpg',
    text: 'Юный и дурацкий, но по-прежнему весьма опасный! И по-прежнему непонятно, при чём здесь вообще грифоны. О извилистые пути образных рядов.'
  },
  65: {
    title: 'Птицы',
    image: 'night_and_day.jpg',
    text: 'Очередная древняя картинка. Мы любим её такой, какая она есть, несмотря на топорность символизма. И несмотря на две левых руки. В сущности, две левых руки — это даже в каком-то смысле правильно. В сущности, во всех.'
  },
  66: {
    title: 'Ночь или ночь',
    image: 'smth.jpg',
    text: <>
      Да, вы совершенно правы, по частотности изображения конкретных персонажей на старых рандомных картинках прекрасно вычисляется некоторая предвзятость. Ничего не поделаешь. Разумеется, <em>на самом-то деле</em> среди персонажей «Чумы» не найдётся ни одного, к которому мы не были бы как-нибудь предвзяты. Но одно дело «как-нибудь», и совсем другое — эстетически. Ну вы поняли. Также любопытно, что образ попугаев так и не просочился в «Чуму», хотя был столь важен и меток. Ну что ж, они остались на картиночках.
    </>
  },
  69: {
    title: 'Полотно о духовном зрении',
    image: 'gayporn_1875_0.jpg',
    text: <>
      Это не то, о чём вы подумали, а визуальное отображение глубоко драматического мировосприятия известной персоны. Вторая персона, кстати, тоже известна, только мундир без наплечника совсем не выглядит мундиром, а так вполне можно догадаться. Очаровательные же и не в меру любопытные элементы мировосприятия навеяны фольклором одного из малых народов Всероссийского Соседства, но об оных элементах, увы, в «Чуме» нет ни слова. Может быть, когда-нибудь потом.<br/>
      <br/>
      А то, о чём вы подумали, имело место аж в 75-м году и, следовательно, к событиям «Чумы» совершенно не относится. Точно-точно.
    </>
  },
  70: {
    title: 'Ты не дёргайся',
    image: 'start.jpg',
    text: <>
      Художественное переосмысление пролога (совершённое ещё до того, как это стало модно). Эстетически вроде бы и не такое, как там написано, и уж тем паче не такое, как написано дальше. Потому что художественное.<br/>
      <br/>
      Зато торсы.
    </>
  },
  75: {
    title: 'Г.Е. и порнография',
    image: 'ge3.jpg',
    text: 'Созданы друг для друга.'
  },
  82: {
    title: 'Светотень',
    image: 'mismatch.jpg',
    text: <>Между Бровью и Ройшем — вся культура повального гомосексуализма города Бедрограда!<br/>
      <br/>
      (И это, кстати, ещё один способ вполне правдоподобно пересказать «Чуму». Потому что кругом ну такие пидоры, такие пидоры, а достаётся-то всем без разбору.)<br/>
      <br/>
      А если серьёзно, то мы ведь предупреждали, заведением какого толка был на заре своего нелёгкого пути этот самый истфак БГУ. С витражами, да. Тематическими. В главе, например, четвёртой об этом волнующем аспекте истории Университета сказано достаточно.
    </>
  },
  97: {
    title: 'Колошма',
    image: 'window_steppe.jpg',
    text: ''
  },
  98: {
    title: 'Камера в степи',
    image: 'cell.jpg',
    text: <>
      <em>«Постепенно он разобрал, что видит: сколоченные из грубых досок нары, столик — откуда-то знакомая обстановка
        одиночной камеры, только прямо посреди травы, на ветру, без стен. В камере сидели двое и разговаривали: человек
        в голубой рубашке и ещё кто-то, кто располагался к Диме спиной. Дым их сигарет втягивался в дыру на краю
        горизонта.<br/>
        <br/>
        Солнце».</em><br/>
      <br/>
      Солнце.<br/>
      <br/>
      Как и прочие рисунки про Колошму, этот — достаточно старый, ему как раз год (нарисован в январе 2011-го). Колошма
      головного мозга с нами уже давно. Проблемы с перспективой тоже, но их можно счесть особенностями восприятия
      зрителя.<br/>
      <br/>
      И ещё очки.
    </>,
  },
  99: {
    title: 'Журавлики',
    image: 'cranes.jpg',
    text: 'Об этом не написано в «Чуме», но Савьюр складывал журавликов из подручных протокольных материалов. Наверное, недостаточно думал о протоколах и слишком — о чём-то другом.'
  },
  104: {
    title: 'За спиной',
    image: 'bewithme.jpg',
    text: <p style={{textAlign: "center"}}>Тут, кажется, не нужно ничего объяснять.</p>
  },
  107: {
    title: 'Труд',
    image: 'hard.jpg',
    text: <>
      Картина называется «в первом раунде битвы со знаниями знания победили, но мы не сдаёмся». Университет — это не только то, что вы подумали, но и суровая юрфаковская муштра. Без всяких там хиханек и оскопистов.<br/>
      <br/>
      А совсем малолетний Гошка ничем не хуже взрослого Гошки. Возможно, даже лучше.
    </>
  },
  118: {
    title: 'Черёмуха',
    image: 'che.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>«И они сделали. Они <span style={{color: '#C00000', fontWeight: 'bold'}}>создали</span>».</p>
      <p style={{textAlign: 'center'}}>(По многочисленным просьбам неравнодушных радиослушателей)</p>
    </>
  },
  132: {
    title: 'Открытка к несуществующему празднику',
    image: 'valentine.jpg',
    text: <p style={{textAlign: 'center'}}>Как известно, у пролога имеются разнообразные интерпретации.</p>
  },
  155: {
    title: 'Ночью темно и в тёмных дворах опасно',
    image: 'ge_gentle.jpg',
    text: <>
      «Воздух — мутный, клубящийся, тошный — солгал, не пролился дождём; дома в липких водорослях сгрудились, сжались, придавили голову, пригвоздили к земле — как тут двигаться с места».<br/>
      <br/>
      Да и зачем; что стой, что беги — всё ведь одно.
    </>
  },
  157: {
    title: 'Куртуазная беседа как высшая ценность',
    image: 'talk.jpg',
    text: 'Перестаньте называть наши социальные конвенции «вредными привычками» только потому, что они вредные и мы к ним привыкли!'
  },
  159: {
    title: 'Быть может, это обложка какой-то другой книги',
    image: 'badass.jpg',
    text: 'Полотно о любви к Отечеству, в некотором роде играющее роль завесы тайны над вопросом «…но что же было потом?». Благодарность за перевод с британского выражается непревзойдённому языковому чутью Охровича и Краснокаменного.'
  },
  161: {
    title: 'О судьбах отечественного образования',
    image: 'teach.jpg',
    text: 'Мы не можем предоставить вам полную подборку журналов «Вопросы педагогики» за 1883 год, но давайте хоть так, одним глазком.'
  },
  165: {
    title: 'Не шутим про банан, не шутим!',
    image: 'table.jpg',
    text: 'Как уже неоднократно было подмечено нашими уважаемыми радиослушателями, один из ключевых мотивов всея «Чумы» — несоразмерность действий и противодействий, причин и следствий, стимулов и реакций, куриц (петухов?!) и яиц. Так что перед вами очень, очень глубокий комикс, обнажающий самую суть!'
  },
  168: {
    title: 'Ногу сверло',
    image: 'life_story.jpg',
    text: <p style={{textAlign: 'center'}}>«Это частная мечта, на которую каждый имеет право, и очередная совсем другая история».</p>
  },
  172: {
    title: 'Девочка и вирусы',
    image: 'vragi.jpg',
    text: <>
      Наверное, таким мог бы быть наш официальный плакат — радостных цветов, с мечтательной Главной Героиней и почти совсем без треша. Только метку «18+» надо поставить! (Вот вам по этому поводу <Link to='/archive/201209'>ссылка</Link> на Недели «Чумы» 2012, вдруг вы всё пропустили.)
    </>
  }
}



type PropTypes = RouteComponentProps<{
  id?: string
}>

export default ({ match }: PropTypes) => {
  if (!match.params.id) {
    return <PageNotFound />
  }

  const article = articles[+match.params.id]

  return <>
    <Helmet>
      <title>{article.title}</title>
    </Helmet>
    <main>
      <h1>{article.title}</h1>
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <img src={`/images/${article.image}`} alt={article.title} />
      </div>
      <p>{article.text}</p>
      <Link
        to='/art'
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold'
        }}
        tabIndex={0}
      >
        <img src='/images/up.png' alt='' style={{ margin: '0 5px' }} />
        Обратно в галерею
      </Link>
      <DiscussionEmbed
        shortname='bedrograd'
        config={{
          url: `https://bedrograd.megus.org/node/${match.params.id}`,
          identifier: `node/${match.params.id}`,
          title: article.title
        }}
      />
    </main>
    <footer>
      <img src='/images/site_footer.jpg' alt='' style={{
        maxWidth: '100vw'
      }} />
    </footer>
  </>
}