import React from 'react'
import {Link} from "react-router-dom";
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {CommentCount} from 'disqus-react'

import tagNames from '../../Data/tagNames'
import './NewsArticle.sass'
import Markdown from "../Markdown";

interface NewsArticleProps {
  article: {
    id: number,
    title: string,
    timestamp: number,
    author: string,
    tags: number[],
    html: string
  },
  full?: boolean
}

const NewsArticle: React.FunctionComponent<NewsArticleProps> = ({ article, full }) => {

  const date = dayjs.unix(article.timestamp).locale('ru').format('D MMMM YYYY в H:mm')

  const cat = /\[cat\|([^\]]+)]/.exec(article.html)
  let text = article.html

  if (cat && !full) {
    text = article.html
      .slice(0, cat['index']) + `<a href='/node/${article.id}'>${cat[1]}</a>`
  } else if (cat && full) {
    text = article.html.slice(0, cat['index']) + article.html.slice(cat['index'] + cat[1].length + 6, -1)
  }

  // будь проклят тот день, когда я сел за баранку этого тайпскрипта!
  const newTagNames = tagNames as {[key: string]: string}

  return <article className={`newsArticle ${full ? 'newsArticle-full' : ''}`} key={article.id}>
    {!full
      ? <Link to={`/node/${article.id}`} tabIndex={0}><h2>{article.title}</h2></Link>
      : <h1>{article.title}</h1>
    }
    <span className='newsArticle_info'>
      Опубликовано {date} пользователем {article.author}
    </span>
    <Markdown text={text} full={full} />
    <footer>
      <span>
        {
          article.tags.length > 0 &&
          <>
            <b>Тэги:</b><br/>
            {
              article.tags.map(tag => <React.Fragment key={tag}>
                <Link key={tag}
                  to={`/taxonomy/term/${tag}`}
                >
                  {newTagNames[`${tag}`]}
                </Link>
                <br key={`${tag}_br`} />
              </React.Fragment>)
            }
          </>
        }
      </span>
      {!full && <Link to={`/node/${article.id}#disqus_thread`}>
        <CommentCount
          shortname='bedrograd'
          config={{
            url: `https://bedrograd.megus.org/node/${article.id}`,
            identifier: `node/${article.id}`,
            title: article.title
          }}
        >
          Коментарии
        </CommentCount>
      </Link>}
    </footer>
  </article>
}

export default NewsArticle