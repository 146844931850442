import React from 'react'
import { IBookPage } from './index'

const chapter33: IBookPage = {
  header: 'header_d9.jpg',
  title: 'Глава 33. Что-то, что не должно литься (Хрусть!)',
  prevLink: 'chapter32',
  nextLink: 'chapter34',
  content: <>
    <h2>Университет. Дима</h2>

    <img alt='' src="/images/33_dima.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>— Могу я с тобой поговорить? —
      спросил Максим, делая приветственный шаг вперёд. Из-за его спины вынырнул
      тавр-таксист Муля Педаль.</p>

    <p>Уже около получаса эти двое
      отирались где-то рядом, бегая вверх-вниз по этажам резиденции Святотатыча. Они
      привезли краденную у Бедроградской гэбни аппаратуру (трогательное «выкуси» в
      адрес городских властей) вдвоём, без всяких гуанак, и пристраивали её в
      надёжные руки.</p>

    <p>Видать, наконец-то пристроили.</p>

    <p>— Не то чтобы разговоры со мной
      требовали какого-то особого разрешения, — отложил Дима книжку, которую лениво
      листал. — Если только ты не голова Бедроградской гэбни. Или Медицинской. В
      общем, если ты голова любой гэбни, то твой вопрос имеет смысл. Но поскольку ты
      не голова — и смотри, как здорово я не давлю на больные места!</p>

    <p>Книжку пришлось листать, потому
      что Диме было скучно, но спать он не мог (дурацкий
      браслет, повинующийся дурацкому мировому закону «во всём хорошем есть что-то
      плохое и наоборот, но кого волнуют наобороты»). Авантюрно-приключенческий роман
      был не то чтобы в его вкусе, но зато заботливо напечатан на разрозненных
      взлохмаченных листах, кое-как прошитых харизматичной бечёвкой, выглядящей так,
      будто её выпустили прямо из каната.</p>

    <p>Не книга, а сущая летопись былых
      портовых времён.</p>

    <p>Всё равно не в Димином вкусе,
      слишком много поисков сокровищ и мало сложных переживаний.</p>

    <p>(Впрочем, а
какие книги в Димином вкусе? И когда он в последний раз читал что-нибудь
      художественное для собственного удовольствия? Вот то-то и
оно.)</p>

    <p>Максим улыбнулся с совершенно
      измученным видом, как будто его вовсе не задело упоминание о собственном
      карьерном падении. Вернее, задело, но он не против,
      потому что надо свыкаться и смиряться.</p>

    <p>— Только я не знаю, как начать
      этот разговор, — неловко и потому как-то беззащитно сказал он. — Не умею.
      Довольно смешно учиться говорить, уже вылетев из гэбни.</p>

    <p>— Лучше поздно, — хмыкнул Дима,
      садясь (до этого он возлежал на койке Святотатыча, вольготно протянув ноги в
      ботинках прямо поверх пледа). — Я наблюдаю в своей жизни острую нехватку бесед,
      которые закончились бы не кромешным пиздецом, увольнением по собственному
      желанию или обнаружением чумных габриэлей евгеньевичей, так что твои благие
      намерения крайне приветствуются в среде меня.</p>

    <p>Максим вздохнул, потоптался,
      тряхнул головой и проиграл какое-то внутреннее сражение:</p>

    <p>— Поехали со мной.</p>

    <p>— Куда?</p>

    <p>— В мою квартиру на Бывшей Конной
      Дороге. Там остались вещи студентки… Брови. И, кроме тебя, их теперь некому
      отдать.</p>

    <p>Голова Димы была будто замотана
      тряпками, как при побеге из горящего изолятора: простые и понятные слова
      доходили до неё с опозданием, вязли и ломались по дороге, делаясь сложными и
      непонятными.</p>

    <p>(Это называется «гондон на
      голове».)</p>

    <p>Леший, у него <i>проблемы</i>.</p>

    <p>— Не то чтобы я знал, что с ними
      делать. Не то чтобы они были мне нужны. Не то чтобы там было много вещей — там
      же немного вещей?</p>

    <p>— Немного. Дело же не в
      количестве, — кивнул Максим и покосился на Мулю Педаль — смущённо и слегка
      раздражённо. — Леший, поехали, я по дороге попробую объяснить.</p>

    <p>— Отдал бы Ройшу, — невпопад и
      невсерьёз предложил Дима. — Ройшу они тоже не нужны, но в этом хотя бы есть
      логика.</p>

    <p>Человек умирает, и от него
      остаются какие-то вещественные ошмётки, разбросанные
      по всему миру. Пока есть ошмётки, есть вроде как и сам
      человек.</p>

    <p>Пока есть ошмётки,
      Максиму не побыть одному в собственной квартире.</p>

    <p>Не к Габриэлю же Евгеньевичу в
      жилище ему возвращаться.</p>

    <p>— Я не настаиваю, — Максим ещё
      раз покосился на Мулю Педаль, сжал зубы и договорил, — я прошу. Это ведь мы с
      тобой её убили.</p>

    <p>(Хрусть!)</p>

    <p>— Нет, — брякнул Дима быстрее,
      чем успел понять, что именно отрицает. — Или да. Но Охрович и Краснокаменный
      меня в наипрямейшем смысле загрызут, если я истаю из-под их бдительного ока.</p>

    <p>Максим ничего не ответил,
      полуотвернулся и стал совсем монолитным.</p>

    <p>Насколько отчаянно и нелепо Дима
      хотел побыть <i>хоть с кем-нибудь</i>,
      настолько отчаянно и нелепо Максим хотел побыть один.</p>

    <p>(Если бы книжка была
      увлекательной и в Димином вкусе, всё сложилось бы совершенно иначе.)</p>

    <p>— Не бойся, у меня есть пистолет,
      — постарался улыбнуться Максим. — И, поверь, я не побоюсь его использовать —
      чего мне уже бояться. Всё будет хорошо.</p>

    <p>— У Охровича и Краснокаменного
      легенда лучше, — проинформировал Дима. — Ты не в курсе, их артефакты — обрез и
      револьвер, в частности — правда принадлежали членам
      Революционного Комитета? Потому что мне вот как-то кажется, что нет. По
      бумагам, однако же, не подкопаешься. А я не хочу, чтобы ты из-за меня попал в
      какую-нибудь, гм, переделку.</p>

    <p>(Переделка —
это то, что переделывает. Кого-нибудь.)</p>

    <p>— Охрович и Краснокаменный
      сказали, что я слишком много думал о себе, — усмехнулся Максим. — Я не знаю,
      правы они или нет. Выясняется эмпирически.</p>

    <p>Кем бы был Дима, если бы не
      понимал и не разделял желания попасть в какую-нибудь-гм-переделку просто
      потому, что непеределанным больше ходить невозможно!</p>

    <p>(Хотя оптимизму
и жажде катарсиса явно следует заткнуться. В сущности,
неясно, что, кроме перестраховки и особого отношения Гуанако к Диме, стоит за
всеобщим представлением о том, что на него кто-нибудь нападёт.)</p>

    <p>— Надо сказать Святотатычу, что я
      поехал с тобой, — постановил Дима.</p>

    <p>— Ты тута не горячис’, —
      мгновенно среагировал Муля Педаль. — Святотатыч — человек занятой, сейчас —
      особенно. Уши разуй, послушай: там вокруг аппаратуры у серьёзных ребят
      серьёзные тёрки, не чета нам лезт’.</p>

    <p>Этажом ниже и
      правда довольно пламенно делили трофеи.</p>

    <p>Муля Педаль, конечно, к Портовой гэбне приближен, но в весьма и весьма разумных
      пределах. Максим — вообще никто.</p>

    <p>Не чета им лезт’, они своё дело
      сделали.</p>

    <p>Дима сунулся в родную и нежно
      любимую сумку, накопал умеренно мятую тетрадь и умеренно пишущую ручку,
      накорябал на страничке относительно содержательное послание и водрузил сие
      высокохудожественное произведение на столик.</p>

    <p>— Тогда хотя бы записку разумно
      оставить, — пояснил он. — Только надо чем-нибудь прижать, чтобы случайным и
      каверзным порывом ветра не унесло.</p>

    <p>Что-нибудь решило оказаться
      святотатычевской же книгой с измочаленными страницами.</p>

    <p>— Пойдём, — призвал Муля Педаль,
      — да не одевайся ты, теплын’ сегодня.</p>

    <p>Ну уж
      нет. Диме слишком нравился застрявший в его владении гуанаковский плащ, похожий
      на студенческий мундир, чтобы упустить возможность его хотя бы накинуть.</p>

    <p>И потом, история плаща явно
      демонстрировала его счастливые свойства. Дима надел (ну то есть на Диму надели,
      причём довольно насильственно, но это не имеет
      значения для науки истории) — Виктор Дарьевич склонился на сторону добра и
      щедрости. Дима снял — случилась вся эта нелепая муть с Шухером.</p>

    <p>Лучше он его дальше снимать не
      будет.</p>

    <p>Вообще никогда.</p>

    <p>(Как просто вершить судьбы мира.)</p>

    <p>Под подозрительно понимающим
      взором Мули Педали Дима вышел на лестничную клетку. Максим пропустил его вперёд,
      задержавшись было за спиной, будто испугался и
      передумал куда бы то ни было ехать, но уже двумя пролётами ниже нагнал обоих
      спускавшихся. Выглядел он при этом совершенно нормально и почти приветливо,
      только чуть крепче обычного сжимал левый кулак.</p>

    <p>&nbsp;</p>

    <p>— Это тяжело, — сказал Максим
      окну. — Я думал, проще.</p>

    <p>Несмотря на замечание Мули Педали
      о том, что с точки зрения распределения веса в такси ему было бы лучше сидеть
      на переднем сиденье, рядом с водителем, Максим предпочёл заднее.</p>

    <p>Рядом с Димой.</p>

    <p>— Думай о том, что ты с некоторой
      вероятностью спасаешь мою душу, — хмыкнул тот. — А то я уже почти свихнулся от
      отсутствия нормального человеческого общения. Нормальным человеческим общением,
      представь себе, у меня теперь считается даже передача из рук в руки вещей
      невинно убиенных.</p>

    <p>Максим косо усмехнулся. Он думал
      о чём-то другом.</p>

    <p>За окном нёсся Бедроград —
      Бедроград в лучах волшебного солнца, которое умудрилось испарить все лужи за
      пару часов своего висения в небе. Бедроград обновлённый и переделанный.</p>

    <p>Бедроград, в сквозном свете стеклянный и ненастоящий.</p>

    <p>Всё вокруг давно было
      ненастоящим.</p>

    <p>— Если ты заберёшь вещи Брови,
      получится, что мы оба признали свою вину, — каким-то заученным тоном проговорил
      Максим. — А вина должна находить виноватых. Только так
      и можно жить.</p>

    <p>— И умирать, и умирать, — фыркнул
      Дима. — Сколько у тебя образного мышления-то прорезалось.</p>

    <p>Максим поморщился, и по его лицу
      пробежала тень злости.</p>

    <p>(Эй, всем и всегда нелегко!)</p>

    <p>— Прости, — Дима пожал плечами
      под похожим на студенческий мундир плащом. — Ты будто забыл, что это у меня
      такое дружелюбие. А я будто забыл, что ты не всегда его перевариваешь. Так что
      прости и за это, и за все остальные нападки. Я никогда ничего против тебя особо
      не имел.</p>

    <p>Солнце светило Максиму в профиль,
      и от этого на жёлтом, как шампанское (вот тоже блистательный образ, не правда
      ли), фоне его лицо было совсем чёрным.</p>

    <p>— Я знаю, — сказал Максим очень
      прямо. — Я знаю, что против меня ты никогда ничего особо не имел.</p>

    <p>&nbsp;</p>

    <p>(Хрусть!)</p>

    <p>&nbsp;</p>

    <p>У Максимова подъезда доцветали
      цветочки и жила прочая жизнь.</p>

    <p>Не конец сентября, а сплошной
      апрель.</p>

    <p>(Почти даже май, но всё-таки
      апрель, чему все только рады.)</p>

    <p>Завтра юбилей Первого
      Большого Переворота, желающим поприсутствовать крайне повезло с погодой.</p>

    <p>Дима вылез из такси, распрямил
      спину и огляделся. Дети в отрядских курточках (сбежали с подготовки к юбилею,
      молодцы какие) сосредоточенно уравновешивали некие объекты на качелях, некая
      предприимчивая бабуська с крайне довольным видом кормила портовых чаек хлебом
      (чайки негодовали и требовали кровавого мяса). Вышедшая из соседнего подъезда
      девушка повела носом воздух и состроила Диме глазки.</p>

    <p>Если не думать о чайках, выходит,
      что <i>ничего не изменилось</i>.</p>

    <p>Нормальная всероссийская идиллия,
      которой не нужны переделки.</p>

    <p>— Идём, — Максим, задержавшийся,
      чтобы дать какие-то указания Муле Педали, наконец
      подошёл к Диме. Тот покорно проследовал.</p>

    <p>Неясно, чего он от всего этого
      ожидает.</p>

    <p>Наверное, того, что Максим сможет-таки
      разжать свой кулак.</p>

    <p>&nbsp;</p>

    <p>Дима бывал в квартире Максима —
      когда-то совсем давно, до Колошмы и степей — и всё
      равно не мог не поразиться этим катакомбам. Он сам, безусловно, успел
      налюбоваться на внутренности одиночной камеры, но добровольно-то зачем с собой такое делать!</p>

    <p>Это была не квартира, а серая
      бетонная мышеловка, и кто попал в неё — живым уже не выйдет.</p>

    <p>Тем нелепее выглядели валяющиеся
      на диване яркие свитера, чуть подёрнувшиеся пылью и явно нетронутые.</p>

    <p>Три свитера, джинсы, одна
      подозрительно знакомая рубашка. Брошка, приколотая к самому левому свитеру,
      расстегнулась и опасно свисала на иголке.</p>

    <p>— Лучше бы всё-таки Ройшу, —
      неуверенно шагнул Дима к рассыпанным по столу тетрадкам и книжкам, — ему давно
      бы пора сменить стиль.</p>

    <p>— Я не сунусь к Ройшу, не могу, —
      сказал Максим сам себе, — передай ему сам, если сочтёшь нужным.</p>

    <p>Потом он хмыкнул, как будто в
      этой фразе было что-то смешное.</p>

    <p>Томик Толстоевского. В давней
      беззаботной юности Дима порывался осилить росскую классику, столкнулся с её
      неприступностью и глубоко разочаровался. Может, сейчас он поумнел, возмужал и
      наконец-то поймёт глубины росской души?</p>

    <p>(Поиск соответствующей вкусу
      литературы — это воистину именно то, чем ему следует сейчас заниматься.)</p>

    <p>Ух ты,
      конспекты лекций Ройша. Неужто с сердечками на полях?</p>

    <p>Ан нет,
      не с сердечками, а с сонно съезжающими строчками.</p>

    <p>Никакие возвышенные чувства не
      победят его способность склонять студентов к самым простым и порочным желаниям.</p>

    <p>— У меня к тебе, кстати, ещё один
      вопрос, — спросил откуда-то издалека Максим.</p>

    <p>— Ммм? — пошуршал Дима страницами
      в надежде всё-таки отыскать хотя бы одно сердечко.</p>

    <p>— Где ты был в прошлое
      воскресенье?</p>

    <p>— Прошлое воскресенье — это
      когда?</p>

    <p>— Это второй день чумы.</p>

    <p>А.</p>

    <p>Ха-ха.</p>

    <p>Дима даже не сразу сообразил, что
      тогда было, а когда сообразил, решил, что на этот вопрос он отвечать не
      собирается (и не вполне понимает, зачем Максим заинтересовался нюансами его
      биографии).</p>

    <p>Потому что утром второго дня он
      проснулся на койке Святотатыча и обнаружил два важных факта: во-первых, что у
      него истерическое похмелье, а во-вторых, что схоронился
      он носом прямо под мышкой у Гуанако.</p>

    <p>Гуанако, которого больше в жизни
      Димы нет.</p>

    <p>(Ну то
      есть теперь всё-таки есть, но утром прошлого воскресенья так радикально не
      казалось.)</p>

    <p>В совокупности с
      твирево-алкогольной интоксикацией это возымело на Диму, скажем так,
      своеобразный эффект.</p>

    <p>И, право же, Максим — последний
      человек, которому следует об этом знать.</p>

    <p>— Я чувствую себя скотиной,
      разрушающей возникшую между нами и столь ещё хрупкую атмосферу взаимопонимания,
      но, с твоего позволения, на этот вопрос отвечать не стану.</p>

    <p>Сзади послышался сдержанный
      вздох.</p>

    <p>— Станешь, — коротко сообщил
      Максим.</p>

    <p>Раздался какой-то странный звук,
      который Дима не сумел мгновенно опознать. Из-за этого звука его правая нога
      решительно отказалась выполнять предписанные ей функции, ввиду чего Дима,
      попытавшись обернуться, изобразил довольно эффектный пируэт.</p>

    <p>Тройной тулуп. Похожий
на студенческий мундир.</p>

    <p>(Шутка так
себе, но Диму всегда поражало это слово применительно к акробатике. Тулуп — это одежда.)</p>

    <p>Ноге было больно.</p>

    <p>Если вдуматься, ноге было ИЗРЯДНО
      БОЛЬНО.</p>

    <p>Объяснение нашлось довольно
      быстро, и только тогда Дима вдруг сообразил, что, кажется, имеет дело с самым
      комическим и нелепым взаимонепониманием в своей жизни.</p>

    <p>В правой руке Максима был
      пистолет.</p>

    <p>В левой
      не было ничего, потому что кулак таки разжался.</p>

    <p>Выпавшая из него на пол бумажка смялась
      в крошечный неопознаваемый комочек, но Дима почему-то сразу понял, что это
      записка, которую он оставил Святотатычу.</p>

    <p>— Поправь меня, если моя логика
      несовершенна, — сквозь зубы (ИЗРЯДНО БОЛЬНО, БЛЯДЬ) прошипел он, — но пистолет
      в твоей руке и боль в моей ноге могли бы заставить неискушённого читателя
      предположить, что ты только что в меня стрелял.</p>

    <p>Это, а также звук выстрела,
      который Дима довольно отчётливо расслышал (хоть и не опознал, потому что,
      леший, он что, должен был быть морально готов к стрельбе?).</p>

    <p>— Только затем, чтобы ты никуда
      не побежал, — Максим приблизился, навис над Димой, направил на него пистолет и
      чуть прищурился. — Потому что сейчас мы с тобой будем говорить.</p>

    <p>— У тебя очень интересные
      представления о том, как склонять людей к диалогу, — Дима сел в
      человекоподобную позу, подтянул к себе ногу и принялся бережно закатывать
      штанину. — Леший, больно! У меня для тебя плохие новости, пальба в излюбленные
      конечности печально сказывается на коммуникативных навыках собеседника.</p>

    <p>В сумке, само собой, по-прежнему
      имелся набор юного медика, включающий в себя шприц с обезболивающим (ну то есть
      шприц и ампулу с обезболивающим, но их несложно
      объединить).</p>

    <p>Всегда под рукой, всегда к месту!</p>

    <p>Ммм, сладостное ощущение иголки в
      ноге.</p>

    <p>— Где ты был в прошлое
      воскресенье? — повторил Максим, сумрачно созерцая процесс обезболивания, но не
      вмешиваясь.</p>

    <p>Как приятно и радостно знать, что
      он вовсе не хотел никому сделать неприятно, это просто
      чтобы никто никуда не побежал. Посидел Максим денёк связанным
      у Охровича и Краснокаменного, подумал о судьбинушке своей, проникся и решил
      нести благо ограниченных перемещений в массы.</p>

    <p>— Чего ты прицепился к
      воскресенью? — досадливо буркнул Дима, закатывая вторую штанину для
      сравнительного изучения.</p>

    <p>Итак, теперь у него симметрично
      дырявые ноги.</p>

    <p>Слева — привет, меня зовут Андрей
      Зябликов, справа — приятно познакомиться, Андрей, я Максим Молевич.</p>

    <p>(Нельзя не проговорить отдельно:
      вот прямо тут, рядом, Максим Молевич направил на Диму пистолет, а Дима сидит на
      полу и разговаривает со своими ногами.)</p>

    <p>— Сам не догадываешься?</p>

    <p>Кровь лениво сползала на пол.</p>

    <p>Дима наконец-то посмотрел на
      Максима. Его лицо совершенно почернело.</p>

    <p>(Каждый раз, когда Дима
      сталкивался с этим выражением в книгах, он представлял нечто умеренно
      макабрическое вроде вылитого в рожу ведра кипящего масла и ожогов четвёртой
      степени, а вот поди ж ты: иногда и правда чернеет безо
      всякого масла. И игрищ освещения.)</p>

    <p>Тряпки (гондон!) на голове
      продолжали работать с успехом.</p>

    <p>— Нет, — честно сказал Дима,
      немного подумав, — не догадываюсь. Мне должно быть
      очевидно, почему ты решил попрактиковать на мне свою меткость?</p>

    <p>— Никто не знает, что ты делал в
      прошлое воскресенье. В день, когда заразили Габриэля.</p>

    <p>— <i>Предположительно</i>, — машинально поправил Дима, ещё раз трагически
      сравнил ноги, пришёл к неутешительным выводам, убрал пустую ампулу и шприц в
      сумку — а потом колёсики завертелись и картинка
      неожиданно сошлась. — Погоди, ты считаешь, что <i>я</i> его заразил?</p>

    <p>Максим усмехнулся совсем
      по-габриэль-евгеньевичевски — словно Димины трепыхания
      вызывали у него скучное презрение и ничего больше.</p>

    <p>Он это серьёзно, дошло вдруг до
      Димы.</p>

    <p>Сползающая на пол кровь
      неожиданно обожгла ногу, потому что нога (вместе с прочими частями тела) не менее
      неожиданно похолодела.</p>

    <p>— Где ты был в прошлое
      воскресенье? — механически спросил Максим.</p>

    <p>С третьего-то раза у него
      наверняка получится!</p>

    <p>— Да в Порту я был, в Порту, —
      возопил Дима, — выяснял отношения с Гуанако весь день. Я, знаешь ли, не то
      чтобы намеревался с ним в Бедрограде встречаться, и уж тем более не ожидал, что
      он начнёт на меня перстни напяливать. — Он помахал
      перед носом растопыренными пальцами в подтверждение. — Можно мы воздержимся от
      смачных подробностей и выяснения того, сколько именно литров до ужаса не
      метафорических слёз я там пролил?</p>

    <p>— Весь день, значит, — невесело
      усмехнулся Максим.</p>

    <p>— Ну, в сущности, да. Потом
      позвонил Шапка, и выяснения отношений плавно перетекли в лихорадочное
      выдумывание того, как можно быстро-быстро сделать много-много лекарства.
      По-моему, ты и сам всё это знаешь.</p>

    <p>— Весь день, значит, провёл у
      Святотатыча. И никто, кроме Гуанако, который и на том свете станет тебя
      покрывать, этого не видел и не может поручиться.</p>

    <p>— А тебе чьё поручительство
      нужно, хэра Ройша? — хмуро огрызнулся Дима.</p>

    <p>— Ничьё, —
Максим посмотрел на него почти снисходительно. — Не в воскресенье — так
      в другие дни у тебя была возможность.</p>

    <p>— Ага, как и у Гуанако, Брови,
      Ройша, всей Университетской гэбни и тебя самого. Что за тупой аргумент?</p>

    <p>Дима вдруг вспомнил, во что
      превратился Габриэль Евгеньевич после заражения, и его как-то внутренне
      скособочило.</p>

    <p>(Хрусть!)</p>

    <p>Он виноват в этом, безусловно, но
      не <i>так</i>. Он испытывает к Габриэлю
      Евгеньевичу сложные чувства, безусловно, но не <i>такие</i>.</p>

    <p>Как подобное могло прийти Максиму
      в голову?</p>

    <p>Как подобное могло прийти Максиму
      в голову настолько серьёзно?</p>

    <p>— Ты сам только что всё
      рассказал, — чугунно продолжал тот. — Гуанако вернулся и поманил тебя. Только
      пока Габриэль жив, они не перестанут друг к другу тянуться. Было бы куда лучше,
      если бы Габриэль умер, правда?</p>

    <p>Забавно, Дима и
      правда никогда не думал об этом в подобном ключе.</p>

    <p>Он бы предпочёл, чтобы в мае
      Гуанако не говорил ему извиняющимся голосом, что всё закончилось. Он бы
      предпочёл, чтобы в мае Габриэль Евгеньевич махнул рукой на прошлое и не пожелал
      возрождать былое с мертвецом.</p>

    <p>Он бы определённо предпочёл жить
      без тряпок (гондона!!) на голове и не делать глупостей от одиночества.</p>

    <p>Но всё, что было между Гуанако и
      Габриэлем Евгеньевичем, было именно <i>между</i> — ни в одном из них, и ни
      один из них этого особо не хотел, и ни один не мог толком бороться. Бывают
      интенции, а бывают обстоятельства.</p>

    <p>А бывают обстоятельства, которые
      сильнее интенций.</p>

    <p>И сильнее людей.</p>

    <p>— Тебя не смущает, что это <i>я</i> нашёл заражение в доме Габриэля
      Евгеньевича
      и что я же его вылечил?</p>

    <p>— Смущает. И сейчас ты мне
      подробно объяснишь, зачем всё это сделал.</p>

    <p>— Потому что предположить, что
      заразил его <i>не я</i>, слишком сложно,
      понимаю.</p>

    <p>— А кто?</p>

    <p>— Откуда мне знать? Попробую,
      впрочем, безумную догадку: Бедроградская гэбня, как и весь остальной блядский
      город?</p>

    <p>Максим опустился рядом с Димой на
      корточки, и вышло почти интимно.</p>

    <p>— Нет, не Бедроградская гэбня.
      Бедроградская гэбня в полном составе приходила сегодня ночью к Охровичу и
      Краснокаменному домой — поговорить со мной. Они ничего не скрывали, они ничего
      не просили. Спрашивали, не знаю ли я, кто заразил этот треклятый дом. Им
      незачем было врать.</p>

    <p>Дима натурально не поверил своим
      ушам.</p>

    <p>— Погоди-погоди, я произнесу это
      вслух: <i>я не заражал Габриэля Евгеньевича</i>.
      И Бедроградская гэбня сказала тебе то же самое. Но им ты веришь, а мне нет,
      так?</p>

    <p>— Им незачем врать. Тебе — есть
      зачем.</p>

    <p>— Зачем?</p>

    <p>Максим ещё раз невесело
      усмехнулся, как-то лениво протянул руку, схватил Диму за галстучный узел и
      притянул к себе. Его серые глаза были вовсе не серыми, а чёрными, пустыми — не
      глаза, а шестерёнки давно запустившегося механизма, который движется, движется,
      движется, и его не остановить.</p>

    <p>Механизма, который запустила
      Бедроградская гэбня.</p>

    <p>Механизма, который запустил Дима.</p>

    <p>Механизма, который запустился сам
      собой.</p>

    <p>Бедроградская гэбня, значит,
      пришла и сказала, что дом Габриэля Евгеньевича заразили не они.</p>

    <p>Ну вот
      просто пришла и сказала.</p>

    <p>Кто же, кто? — подумал Максим
      (известно же, что Бедроградская гэбня всегда откровенна
      и не врёт о своих намерениях!). И ничего умнее не придумал, чем предположить,
      что Дима решил обезопасить свою священную связь с Гуанако.</p>

    <p>Он
      правда совсем идиот?</p>

    <p>Он
      правда никогда не слышал, что насильно мил не будешь?</p>

    <p>Что если Гуанако кто-то милее
      Димы, устранение этого кого-то ничего бы не изменило?</p>

    <p>Что взаимоотношения —&nbsp;не бег
      в мешках, где за неимением первого призёра на верхнюю ступень пьедестала встаёт
      второй?</p>

    <p>Всё лицо Максима было чёрным и
      пустым, за ним уже даже не ходили поршни, он продолжал двигаться по инерции,
      только по инерции, и его не остановить.</p>

    <p>Дима понял, что сейчас не
      выдержит и заржёт.</p>

    <p>Это было комично как театральные
      сюжеты про героев, падающих на банановой кожуре. А потом на следующей. И на
      третьей. И четвёртую они уже видят, но всё равно идут
      вперёд, наступают, падают, видят пятую, наступают, падают, шестую, седьмую, и
      это движение уже не остановить, над ним властны какие-то другие законы, законы
      абсурда, законы поставленной цели, законы принятого решения, законы найденного
      ответа.</p>

    <p>Эти законы вытолкнули из
      хитросплетения поршней тусклый и холодный ствол пистолета, который упёрся Диме
      в живот. Он хотел попросить — <i>не надо</i> — но всё лицо Максима было только продолжением пистолета, тусклым и холодным.</p>

    <p>Дима попытался
      было его оттолкнуть, но ни пальцам, ни словам не справиться с металлом.</p>

    <p>— Ты врёшь, — тускло и холодно
      сказал Максим, — потому что понимаешь, что я тебя убью. Не понимаешь ты того,
      что я убью тебя <i>в любом случае</i>.</p>

    <p>Ствол нагревался, отбирая тепло у
      тела, и Дима его уже почти не чувствовал.</p>

    <p>— Ты? Меня? Убьёшь? — фыркнул он,
      и это было уже слишком нелепо, слишком комично, смешки начали сыпаться между
      зубов, между слов, их было не удержать. — Я тебя умоляю, икона
      контрреволюционного движения, ты вообще хоть когда-нибудь кого-нибудь убивал?
      Леший, <i>даже я убивал</i>, а тебя не
      посадили как раз потому, что вся твоя бурная деятельность сводилась к лёгким
      ножевым. И не больше.</p>

    <p>— Раньше — не убивал, — ровно
      ответил Максим.</p>

    <p>И нажал на спусковой крючок.</p>

    <p>(Помните, дети,
что «курок» — это молоточек в задней части огнестрельного оружия, а крючочек в
колечке, на который давят пальцем, называется «спусковым».</p>

    <p>Его нажимают —
и дальше только спуск, вниз, вниз, вниз.)</p>

    <p>Дима даже толком не закричал — в
      горле всё перемешалось, во всём теле перемешалось, и он совершенно потерял
      представление о том, где у него что и есть ли он
      вообще. Было только странное ощущение грязи на коже и того, что льётся что-то,
      что не должно литься, — как во сне, когда спешишь куда-то, опаздываешь, а вещи
      никак не хотят укладываться в чемодан, и всё время что-то вываливается. Вываливается, вываливается, удержать руками, хоть как-нибудь
удержать, пожалуйста, потому что страшно, беспорядочно страшно, пожалуйста!</p>

    <p>(«А ты всегда
интересовался тем, почему герои драматических произведений хватаются за раны.
      Возмущался — мол, на самом деле так не бывает, потому что ты, мол, когда
      коленки ссаживал, за них не хватался. Так то коленки, а то —
простреленная печень».)</p>

    <p>Диму можно официально поздравить,
      у него пограничное раздвоение личности.</p>

    <p>(«Или нет».)</p>

    <p>Или нет.</p>

    <p>— Заткнись, здесь всё равно
      звукоизоляция, — тряхнул его Максим, немного возвращаясь в фокус, и Дима понял,
      что всё-таки издаёт какие-то звуки — кричит?</p>

    <p>Или, что гораздо менее приятно
      представлять, эдак булькает.</p>

    <p>Рубашка вся была красной, и
      Димины руки были красными, и ноги, и руки Максима, и пол вокруг.</p>

    <p>И плащ, похожий на студенческий
      мундир.</p>

    <p>Какая досада.</p>

    <p>Он нравился Гуанако.</p>

    <p>(Хрусть!)</p>

    <p>Максим сорвал с Диминого плеча
      сумку и швырнул её в дальний конец комнаты, после чего тряхнул его ещё раз,
      легонько съездил рукоятью пистолета по лицу и заглянул в глаза.</p>

    <p>— Слушай меня внимательно. Твоя
      записка — вон она, на полу. Муля Педаль давно уехал — я сказал ему, чтобы не
      ждал. Святотатычу не до того. Охрович и Краснокаменный спят. Никто тебя не хватится и не будет искать. Никто не услышит, сколько ни кричи. Ты никому не нужен.</p>

    <p>Какая прочувствованная речь.</p>

    <p>— Дай&nbsp;— ты
— мне — спокойно — поползать — в луже — собственной — кровищи, — кое-как
прокряхтел Дима.</p>

    <p>— Нет, — рявкнул
      Максим. — Признайся, что это ты заразил Габриэля!</p>

    <p>— Только — это — не — я.</p>

    <p>— Я же всё равно заставлю тебя
      признаться! За ту пару часов, что тебе осталось, можно многое успеть. Имей хоть
      немного гордости, умри достойно.</p>

    <p>(«Дело, как все
и ожидали, закончилось выстрелом, даже двумя. И если
первый только заставил споткнуться, то второй, кажется, окончательно уничтожил
хорошего человека».)</p>

    <p>Не такое уж и просроченное
      оказалось предсказание, а?</p>

    <p>Дима не хотел смеяться, но
      засмеялся (не смотреть, не смотреть, не смотреть вниз), а не стоило, диафрагма
      — в груди, совсем рядом с животом, совсем рядом с —</p>

    <p>Не стоило.</p>

    <p>— Ты меня не убьёшь, — выплюнул
      он сквозь смешки. — Не сможешь.</p>

    <p>— Почему? — довольно искренне
      опешил Максим.</p>

    <p>— Потому что ты хороший человек,
      — а смех всё не прекращался, как будто дырка в животе прорвала мешок с ним, и
      из мешка теперь лилось, и лезло, и разбегалось по комнате, и всё было очень
      смешно, потому что <i>Максим — хороший
        человек</i>, — ты же хороший человек! А значит — я не знаю, в чём дело, у тебя
      закончились патроны, или будет осечка, или ещё чего, но выстрела может быть
      только два. Только два, понимаешь?</p>

    <p>И Дима совсем засмеялся от
      безопасности — и ещё немного от обиды, что Максим больше не может стрелять, но
      он, Дима, всё равно умрёт, потому что записка — вон она, Муля Педаль давно
      уехал, звукоизоляция, и никому он, Дима, не нужен. Это было смешно, смешно
      умереть, когда ты уже в безопасности.</p>

    <p>Смешно и очень не хочется.</p>

    <p>Очень не хочется умирать.</p>

    <p>(После тех
четырёх безымянных он долго гадал: если бы ему было очень больно — вот как
сейчас — и очень безнадёжно — вот как сейчас — хотел бы он, чтоб его добили?
      Потому что, может быть —&nbsp;может же такое быть!
      —&nbsp;если человек сам хочет, то это немного другое, это как-то иначе, это не
      так —</p>

    <p>Четверо безымянных ему уже не
      ответят, но если попытаться измерить по себе, может
      быть, может же такое быть —</p>

    <p>Но нет, не
хотел бы, не хотел бы, не хотел, чтобы Максим его добил, хотел наслаждаться
каждой минутой, пожалуйста, хотя бы ещё чуть-чуть.)</p>

    <p>Откуда-то, откуда не должно
      литься, полилось — и, кажется, уже не в животе.</p>

    <p>— Ты свихнулся, — с отвращением и
      некоторым страхом уронил Максим, отбрасывая Диму назад, в желанную лужу
      собственной крови.</p>

    <p>— Не-е-ет, — сквозь давящий грудь
      хохот проговорил тот, — просто я же тебя знаю, я знаю, что ты хороший, я знаю —</p>

    <p>(Хрусть! —&nbsp;уже почти.)</p>

    <p>Дима знал Максима, знал лучше
      всех на свете, лучше Гуанако и лучше Габриэля Евгеньевича. Потому что этим маем они
      с Максимом стояли вдвоём в коридоре, пока двое
      других сидели на кухне, пока двое других говорили на кухне. И слышать то, что
      они говорили, было очень стыдно, от этого почему-то начинала звенеть ложка в
      чайной чашке, которую Дима держал в руках.</p>

    <p>На кухне говорили о том, как
      когда-то любили друг друга — как Габриэль Евгеньевич любил и любит и как
      Гуанако не может, просто не в силах на это наплевать, как он готов наплевать на
      весь остальной мир, лишь бы Габриэль Евгеньевич был счастлив.</p>

    <p>Слышать это было стыдно, потому
      что остальной мир включал в себя и Диму, но гораздо более стыдно было слышать
      габриэль-евгеньевичевское «никогда-никого-кроме-тебя».</p>

    <p>Потому что совсем
рядом стоял Максим, и он тоже слышал, и у него в руках не звенела чашка,
      но Дима всё равно оказывался невольным свидетелем того, чему свидетельствовать
      нельзя.</p>

    <p>Нельзя подслушивать разговоры на
      кухне, но ещё более нельзя — подсматривать, как этот разговор подслушивает
      кто-то другой.</p>

    <p>Разговор, в котором его бросают.</p>

    <p>В котором
      говорят, что он не нужен.</p>

    <p>В
      котором вообще о нём не говорят.</p>

    <p>Когда звон у Димы в ушах стал
      громче звона чашки в руках, когда у Максима окончательно заиндевели все
      мускулы, они так же молча вышли вместе в тёмный майский воздух, и тогда Диме
      показалось, что холодный ночной ветер не очень-то холоден.</p>

    <p>Они вышли вместе, подсмотрев друг
      у друга то, что не следует подсматривать.</p>

    <p>И с тех пор знали друг друга
      лучше всех на свете.</p>

    <p>По крайней мере, Дима так думал.</p>

    <p>— Я думал, что ненавижу Гуанако,
      — выдернул его Максим из тёплых тёмных воспоминаний. — Но не могу его
      ненавидеть. Он честный, он искренний, он умный — он дурак.
      Поверил тебе всей душой. Смешно, как всем нравится маска безопасного идиота! Дима
      глупый, что он может натворить? Тебе доверяет
      Ройш, тебе доверяла студентка Шухер, тебе доверяет…
      доверял Габриэль. Всех окрутил, ко всем втёрся в доверие, все тебя любят — и
      ради чего?</p>

    <p>— Скажи, если бы в мае разговор
      Гуанако с Габриэлем Евгеньевичем слышал только ты, без меня, ты бы чувствовал
      себя менее униженно? — ухмыльнулся (наверное) Дима.</p>

    <p>Максим метнулся к нему,
      замахнулся, но Дима так и не понял, ударил или нет, — это было неважно, совсем
      уже неважно, всё рассыпалось и раскатывалось по комнате —</p>

    <p>— Ради чего, скотина? Ради чего?!
      — закричал Максим, хватая Диму за грудки. — Это ведь ты устроил чуму в
      Бедрограде, ты с самого начала придумал подсунуть им вирус! Зачем? Зачем? С кем
      ты хотел поквитаться — со мной, с Габриэлем, с Гуанако, с Университетом, со
      всем миром?</p>

    <p>Наверное, всё-таки ударил — нельзя ведь так кричать и не
      ударить, это было бы глупо.</p>

    <p>— С Андреем, разумеется, —
      фыркнул Дима, и от следующего тычка почувствовал, что
      ускользает от себя, что надо себя поймать, что любой младшеотрядник скажет
      тебе: нельзя проваливаться, нельзя позволять себе, надо держаться, надо иметь
      силу воли —</p>

    <p>Это Дима придумал чуму в
      Бедрограде.</p>

    <p>Дима.</p>

    <p>Окрутил, втёрся в доверие.</p>

    <p>Все его любят.</p>

    <p>В июне Гуанако позвонил ему в
      Медкорпус. Говорил странным голосом — «это по делу, ничего личного» — и Дима
      слушал странным слухом.</p>

    <p>Гуанако допросил Гошку, узнал о
      планах Бедроградской гэбни, удивился и дарит выясненные планы Диме — пусть
      делает с ними что хочет, а сам Гуанако сейчас сядет на Курёхина и уплывёт
      куда-нибудь подальше.</p>

    <p>Максим сказал: нужно раскрыть,
      спугнуть, публично обнаружить переустройство канализаций, отвадить.</p>

    <p>А Дима сказал: нет, нужно быть
      решительней, нужно быть хитрей, мы подсунем им свой вирус, вирус, от которого
      будет лекарство, я сделаю вирус, я всё продумал.</p>

    <p>И Максим сказал, что это
      безрассудство и глупость, но Охрович и Краснокаменный сказали, что это весело,
      и Ларий сказал, что это может быть полезно, и Ройш сказал, что он согласен, и
      Святотатыч сказал, что готов помочь.</p>

    <p>Поэтому они запустили чуму в
      Бедрограде.</p>

    <p>Которую
      придумал Дима.</p>

    <p>Придумал, потому что впервые за
      семь лет оказался один, без Гуанако.</p>

    <p>Потому что уверовал, что он не
      хуже и тоже может выстроить наглый план.</p>

    <p>Очень захотел быть не хуже.</p>

    <p>Убедить себя в том, что и один он
      чего-то стоит.</p>

    <p>Потому что его бросили.</p>

    <p>И ещё — потому что в
      Бедроградской гэбне до сих пор сидел Андрей, а Дима ненавидел Андрея,
      ненавидел, ненавидел — и так сейчас хотел ненавидеть сильнее, только ненависть
      затухала, и всё затухало —</p>

    <p>— Это всё — <i>это всё</i> — месть Андрею? — ужаснулся Максим. — Личная месть, которой
      плевать на потери?</p>

    <p>(Хрусть!)</p>

    <p>Все помнят, что Андрей когда-то
      посадил Диму на Колошму.</p>

    <p>Никто не помнит, что Дима
      отправился туда сам, чтобы избавить Максима от принятия тяжёлого решения.</p>

    <p>Сплошная
радиопостановка: запрещёнными бумагами они были повязаны с Габриэлем
Евгеньевичем, и Диму поймали, но он ничего не сказал, потом отпустили, потом
стали дознаваться, но всё было не так просто, а потом Андрей просто пришёл к
Максиму под дверь (гуанаковской городской квартиры — Диму тогда прятали в
гуанаковской городской квартире) и сказал: либо Габриэля Евгеньевича на пять
лет, либо Диму под расстрел.</p>

    <p>А Дима стоял
прямо там, за тоненькой дверью, и подслушивал этот разговор (сплошная
радиопостановка: Ларий дал ему стетоскоп для удобства), и вдруг понял,
      что Максим не будет выбирать между «плохо» и «плохо», не должен выбирать.</p>

    <p>И выбрал сам, открыл дверь.</p>

    <p>Привет, Андрей Зябликов, меня
      зовут Дима.</p>

    <p>И Максим
спросил, хорошо ли он подумал, а Дима ответил, что если бы он каждый раз думал
о том, к чему могут привести его действия, он бы ничего, ничего и никогда не
сделал.</p>

    <p>(Хрусть!)</p>

    <p>Потом они ехали в такси, и Андрей
      был неприятный, похожий на испуганную крысу, показывающую зубы из угла, в
      который её загнали.</p>

    <p>«Вы что, серьёзно меня расстреляете
      за бумажки?» — спросил Дима.</p>

    <p>(Ему тогда было
плевать, очень плевать: в бумажках был Начальник Колошмы, Савьюр — в бумажках
был разговор, в котором Диму бросали, в котором говорили, что Дима не нужен, в
котором вообще о нём не говорили.)</p>

    <p>«Вы бы предпочли другую смерть?»
      — рассеянно спросил его Андрей.</p>

    <p>«Как это ни ново, я предпочёл бы
      умереть от старости. В каком-нибудь хорошем месте — в сортире,
      например. Только не совсем неожиданно, перед смертью всё-таки полагается
      покурить».</p>

    <p>«Хорошо», — так же рассеянно
      ответил Андрей и вколол ему что-то в ногу.</p>

    <p>А проснулся Дима уже в сортире — и все ближайшие несколько дней приходил в себя в
      сортире. Простая деревянная коробка с дыркой, разве что с бетонными стенами за
      досками.</p>

    <p>О, он их отрывал.</p>

    <p>И каждый раз, когда Дима,
      обессилев отрывать доски, падал и снова приходил в себя, его ожидали три блока
      сигарет.</p>

    <p>Потом он сходил с ума от скуки,
      разрывал брюки на ремешки (смысл этого действия сейчас довольно сложно
      восстановить), рисовал дерьмом узоры на стенах, разбирал
      пол, копал подкопы, курил, но всегда выбивался из сил раньше, чем достигал
      какого-нибудь внятного результата.</p>

    <p>И когда он просыпался, его ждал
      абсолютно целый сортир, три блока сигарет и
      подозрение, что во сне ему вкололи чего-то, поддерживающего жизнь и силы.</p>

    <p>Из чего-то же надо выбиваться.</p>

    <p>Дима не помнил, сколько это
      продолжалось. Он находил грибки микрофонов и вырывал их, говорил в них, просил
      их. Грибки микрофонов ответили только один раз — спросили, откуда у Димы его
      запрещённые бумаги.</p>

    <p>Он предложил им прошествовать в
      известные места.</p>

    <p>Ему почему-то казалось
      непристойным говорить об этом вслух, потому что это его (их с Габриэлем
      Евгеньевичем) запрещённые бумаги, а в них — Савьюр, Гуанако и Савьюр, вдвоём,
      без Димы (без Димы и почти без Габриэля Евгеньевича), и имеет же он право хотя бы
      после смерти обоих —</p>

    <p>Потом он снова выбился из сил и
      заснул, а потом волшебным образом восстанавливающийся сортир
      продолжился ещё пару дней, пока на третий его дверь не оказалась открытой.</p>

    <p>Это методы психологического воздействия.</p>

    <p>Это бессмысленная жестокость.</p>

    <p>Когда Дима вышел, перед ним был
      только один коридор, ведущий в маленький бетонный дворик.</p>

    <p>В маленьком бетонном дворике была
      только одна дверь, ведущая в коридор, из которого вышел Дима.</p>

    <p>И — за Димой — группа людей с ружьями.</p>

    <p>Они связали ему
руки, они предложили завязать ему глаза, они поставили его спиной к стенке и
спросили, что он хочет сказать на прощание.</p>

    <p>Дима начал с
простого и просто пошёл долой с той точки, на которую его установили.</p>

    <p>Диму вернули.</p>

    <p>Он снова пошёл.</p>

    <p>Диму вернули и легонько
      пристукнули.</p>

    <p>Он вознамерился снова пойти, но
      тут ему напомнили, что другого шанса что-нибудь сказать не будет, и передёрнули
      затворы.</p>

    <p>«Жопа»,
      — ответил Дима.</p>

    <p>Ничего умнее он придумать не
      смог.</p>

    <p>Ничего глупее он придумать не
      смог.</p>

    <p>Группа людей хмыкнула и
      выстрелила.</p>

    <p>Десять сантиметров над головой,
      пять сантиметров перед большими пальцами ног.</p>

    <p>Когда группа людей направилась
      обратно в единственную дверь, он не побежал за ними вслед и даже ничего не
      крикнул, не смог.</p>

    <p>Ноги отнялись, и ещё что-то —
      тоже отнялось.</p>

    <p>Отнялось и больше не появлялось —
      ни когда группа людей вернулась на следующий день, ни когда она вернулась через
      день, ни когда на третий Дима проснулся в одиночной камере и увидел там
      Гуанако.</p>

    <p>Только ещё
через несколько дней, когда он поверил, что жив, что Гуанако жив, что они в
реальном мире, что расстрелы были фальшивыми, бессмысленной жестокостью,
своеобразным чувством юмора, что камера есть, что Гуанако есть, что Дима есть,
что это всё есть <i>на самом деле</i> —
только ещё через несколько дней он понял, что не простит Андрея, просто не
сможет.</p>

    <p>Не простит за бессмысленную
      жестокость.</p>

    <p>За желание отрабатывать хоть на
      ком-то методы психологического воздействия.</p>

    <p>За то, как Диме тогда было
      страшно, позорно страшно, и как он остался жив, и как он до сих пор не может
      забыть свой страх, не может простить себе свой страх, не может простить Андрею
      свой страх.</p>

    <p>Он думал, что в некотором смысле
      умер тогда — настолько болезненно оборвалось, настолько убедительно отнялось —
      и только сейчас, бессмысленно зажимая руками то, что уже давно не было смысла
      зажимать, понял: нет.</p>

    <p>Умирать — это что-то совсем
      другое.</p>

    <p>Не радиопостановка, не паника, не
      медленное погружение в тягучий сон.</p>

    <p>Умирать — это стоящий над тобой
      Максим с пистолетом и чугунной башкой, в которую он
      вбил какой-то идиотизм, и обида, невыносимая обида на то, что не хватает сил
      объяснить ему, не хватает слов переубедить его, не хватает времени найти того,
      кто сможет объяснить и переубедить, время утекает, бессмысленно зажимать
      руками. Обида и клаустрофобное желание дёрнуться, сделать хоть что-нибудь — и
      невозможность.</p>

    <p>(«Не устаю восхищаться тем, сколь
      обширную площадь может занять человеческая кровь в крайне сжатые сроки».)</p>

    <p>Почему у Димы два голоса в
      голове? Он всегда любил говорить сам с собой, он периодически спорит сам с
      собой, но почему <i>настолько</i> два
      голоса?</p>

    <p>(«Хрусть».)</p>

    <p>Остроумно.</p>

    <p>(«Кто на
      какое просветление заработал, тот такое и получает. Хрусть».)</p>

    <p>Это типа катарсис ломится
      изнутри?</p>

    <p>«Катарсис и желание жить».</p>

    <p>Я хочу жить. Я сейчас острее всего
      (какая актуальная метафора, блядь) хочу жить.</p>

    <p>«Жить — это не только дышать,
      знаешь ли».</p>

    <p>Это ещё и отсутствие тряпок на
      голове, знаю ли.</p>

    <p>«Гондона».</p>

    <p>Дима, кажется, снова засмеялся.</p>

    <p>Я просветлюсь, если назову свою стрёмную эмоциональную тупость гондоном? Что,
      правда?</p>

    <p>«Ты уже просветлился, кретин. Посмотрел бы на себя, лежишь в полутораметровой луже
      собственной кровищи, споришь сам с собой о катарсисе и гондонах и хихикаешь как
      на задней парте».</p>

    <p>Где твоя серебряная нить, говнюк, я хочу сам посмотреть.</p>

    <p>«Идиот,
      если ты покинешь своё тело, оно перестанет говорить и хихикать».</p>

    <p>Дима, я не хочу умирать.</p>

    <p>«Пиздец, ты
      правда думаешь, что меня зовут Дима?»</p>

    <p>Я не хотел, чтобы всё так вышло.
      Не хотел. Веришь?</p>

    <p>«Я — это ты, придурок,
      как я могу тебе не верить? Забей на это и подумай лучше о том, что мы только
      что употребили в семи репликах обращения «кретин», «говнюк»,
      «идиот» и «придурок». Вот и вопрос самоопределения решился».</p>

    <p>И «Дима» ещё.</p>

    <p>«Ну мне
      такие обращения вслух стыдно повторять».</p>

    <p>Дима заржал уже в голос, отчего
      ему стало совсем больно и растворившаяся было
      окружающая действительность начала твердеть и обрастать углами.</p>

    <p>Теперь всё будет хорошо?</p>

    <p>«Ага, не считая того, что Максим
      совсем не шутил и на полном серьёзе убеждён, что ты зачумил Габриэля
      Евгеньевича из ревности. И на полном серьёзе собирается тебя за это убить».</p>

    <p>Это не очень хорошо.</p>

    <p>«Плюс у тебя очень нехилая —
      полтора метра! — кровопотеря, так что ты просто физически не сможешь ему никак
      помешать».</p>

    <p>Да ладно, так не бывает.</p>

    <p>«Мне-то что, не мне пуля в рожу
      прилетит».</p>

    <p>Не прилетит, выстрела должно быть
      только два.</p>

    <p>«У тебя крайне надёжные источники
      информации, скажу я на это».</p>

    <p>Заткнись уже и дай собраться с
      мыслями!</p>

    <p>«Ладно-ладно, только одно слово
      мудрости напоследок».</p>

    <p>Ну?</p>

    <p>«Хрусть».</p>

    <p>&nbsp;</p>

    <p>Максим стоял на расстоянии пары шагов
      и держал Диму на мушке, но не как жертву, а как хищника, от которого пятятся боком,
      спиной по стеночке, аккуратно к выходу. Серебряных нитей не завезли, но было
      достаточно отрефлексировать выражение своего лица, чтобы представить себе
      приблизительную картину.</p>

    <p>Дима лежал навзничь, вся рожа (и всё остальное) у него было мокрым, но всё
      остальное,
      в отличие от рожи, не ухмылялось совершенно идиотским образом.</p>

    <p>— Я смеялся? Леший, я же
      наверняка смеялся, — поведал он в сторону Максима, отчаянно порываясь приподняться
      на локте.</p>

    <p>Тот продолжил слегка пятиться,
      сглотнул и чуть заметно дёрнул пальцем на спусковом крючке.</p>

    <p>— Слушай, не убивай меня прямо
      сейчас, пожалуйста, — попросил Дима, так и не преуспевший в своих попытках, —
      пожалуйста. Дай мне пять минут, а лучше — дай мне обезболивающего,
      я попробую всё объяснить. Клянусь тебе, ты всё неправильно понял. В смысле, да,
      чума была в определённой степени моей личной местью Андрею, и я этим более чем
      не горжусь, и заслуживаю какой-то кары, наверное, но ты же стреляешь в меня не
      из-за этого. А того, за что ты в меня стреляешь, я не делал. Правда. Я найду
      тебе свидетелей убедительней Гуанако, я объясню, пожалуйста, просто дай мне
      возможность…</p>

    <p>— Всё, что ты мог сказать и
      сделать, ты уже сказал и сделал, — несвоим голосом ответил Максим.</p>

    <p>После этого раздался звук,
      который Дима не сумел мгновенно опознать.</p>

    <p>«Сдох
      через пару секунд после того, как его отпустило» звучит до омерзения обидно.</p>

    <p>Но с другой стороны, можно
      сказать «его отпустило за пару секунд до смерти» — а это уже как-то приятнее.</p>

    <p>&nbsp;</p>

    <p>Было бы.</p>

    <p>Если бы Дима сдох.</p>

    <p>Вместо этого он увидел, как
      Максим величаво и медлительно рушится за пол.</p>

    <p>За спиной у него обнаружился
      Краснокаменный с занесённой повыше рукоятью золотцевского револьвера.</p>

    <p>Воспоследовала немая сцена
      определённой протяжённости.</p>

    <p>— Я же говорил, что выстрела
      может быть только два, а никто мне не верил, — глубокомысленно изрёк Дима.</p>

    <p>— А мы совсем не то имели в виду,
      когда предлагали Максиму купить скопца, — ответил ему Краснокаменный, сделал
      неловкую паузу, помялся и добавил, — совсем не то.</p>

    <p>И тут Дима заметил, что
      Краснокаменный стоит перед ним один, без Охровича.</p>

    <p>— Разделились, чтобы найти тебя,
      — пояснил он, расшифровав изумлённый взгляд. — Охрович поехал на Поплеевскую. …
      Символизм места может быть разным. … Других вариантов вообще всегда немало —
      кому, как не тебе, знать. … Муля Педаль никуда не уехал. … Выслужиться
      попытался. … Побыть лучше, чем от него хотят. … Так что, с одной стороны,
      искать тебя было немного сложнее. … Сразу поняли, что тут страшные вещи
      творятся, вошли — вошёл уже подготовленным. … Максим —
      осёл, мог же входную дверь на щеколду закрыть, так бы нам всем жизнь усложнил.</p>

    <p>Краснокаменный всё время нервно
      оглядывался по сторонам и говорил тише обычного, явно пытаясь сократить паузы
      между репликами и явно будучи не в силах.</p>

    <p>— Твоё явление в единственном
      числе кажется мне чем-то вроде знака свыше.</p>

    <p>— Наше явление в единственном
      числе — спасение твоей репутации. … Не считая жизни. … Храбрость твоего
      монолога просто потрясала душу.</p>

    <p>— Я вижу определённое свинство со стороны вселенной в том, что вы — ты упустил
      весь искромётный юмор и явился только на поскучневшего посткатартического меня.
      Но, знаешь, что-то мне подсказывает, что с этим свинством
      можно жить.</p>

    <p>Краснокаменный не очень
      прислушивался, всё так же нервно оглядываясь по сторонам.</p>

    <p>Печальная мысль поразила Диму.</p>

    <p>— Ты умеешь оказывать хоть
      какую-нибудь медицинскую помощь?</p>

    <p>— Мы — я всё умею, — огрызнулся
      Краснокаменный.</p>

    <p>— В сумке есть несколько ампул со
      снотворным. Надеюсь, ты сумеешь отличить их от шариковой ручки и вколоть всем
      присутствующим. Исключая себя самого, если можно.</p>

    <p>— Если я и окажу тебе медицинскую
      помощь, то только затем, чтобы мы потом могли отдельно убить тебя за
      неповиновение, — буркнул Краснокаменный, добывая сумку из угла, а ампулу и
      шприцы —&nbsp;из сумки.</p>

    <p>Первым он удостоил внимания
      Максима, а потом подошёл к Диме, умудрившись не поскользнуться.</p>

    <p>— Ты просто не представляешь,
      друг, насколько мы на тебя злы, — гавкнул он, злорадно (и очень умело, не
      стоило сомневаться) всаживая в него шприц, — и чем это тебе грозит.</p>

    <p>Его прекрасное, хоть и усатое,
      лицо мгновенно начало плыть куда-то в сторону, становясь пушистым (и
      по-прежнему отвратительно усатым) облачком.</p>

    <p>— Ты просто не представляешь,
      друг, <i>насколько с этим можно жить</i>, —
      сонно пробормотал Дима в ответ.</p>

    <p>И отключился.</p>
  </>
}

export default chapter33
