import React, {KeyboardEventHandler} from 'react'
import {NavLink, NavLinkProps} from "react-router-dom";
import {NavMenuItem} from "./Header/Header";
import MenuBar from "./MenuBar";

const keyCodes = {
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  PAGEUP: 33,
  PAGEDOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40
}

type PropTypes = {
  item: NavMenuItem,
  focusParent?: () => void,
  focusSubmenu: () => void,
  focusNext: () => void,
  focusPrev: () => void
  focusItem: () => void
}

interface StateTypes {
  isSubmenuOpen: boolean
}

export default class MenuLink extends React.Component<PropTypes, StateTypes> {
  node = React.createRef<NavLink>()

  state: StateTypes = {
    isSubmenuOpen: false
  }

  showPopup = () => new Promise(resolve => {
    this.setState({
      isSubmenuOpen: true
    }, resolve)
  })

  hidePopup = () => new Promise(resolve => {
    this.setState({
      isSubmenuOpen: false
    }, resolve)
  })

  keyDown: KeyboardEventHandler<HTMLAnchorElement> = (event) => {
    let stop = false

    switch (event.keyCode) {
      case keyCodes.DOWN:
        if (!!this.props.item.subMenu) {
          this.showPopup()
            .then(this.props.focusSubmenu)
          stop = true
        }
        break
      case keyCodes.SPACE:
      case keyCodes.RETURN:
        if (!!this.props.item.subMenu) {
          this.showPopup()
            .then(this.props.focusSubmenu)
          stop = true
        } else {
          // @ts-ignore
          this.node.current && this.node.current.click()
          if (this.props.focusParent) {
            this.props.focusParent()
          }
          stop = true
        }
        break
      case keyCodes.LEFT:
        this.props.focusPrev()
        stop = true
        break
      case keyCodes.RIGHT:
        this.props.focusNext()
        stop = true
        break
      case keyCodes.UP:
      case keyCodes.ESC:
      case keyCodes.TAB:
        if (this.props.focusParent) {
          this.props.focusParent()
          stop = true
        }
        break
    }

    if (stop) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  render() {
    const {subMenu, isFocused, ...linkProps} = this.props.item
    const { isSubmenuOpen } = this.state

    const ariaProps: Partial<NavLinkProps> = {}

    if (!!subMenu) {
      ariaProps['aria-haspopup'] = !!subMenu
      ariaProps['aria-expanded'] = isSubmenuOpen
    }

    // @ts-ignore
    isFocused && this.node.current && this.node.current.focus()

    return <NavLink
      {...linkProps}
      {...ariaProps}
      className={!!subMenu ? 'header_link-withSubmenu' : ''}
      role='menuitem'
      onMouseOver={this.showPopup}
      onMouseOut={this.hidePopup}
      onKeyDown={this.keyDown}
      tabIndex={isFocused ? 0 : -1}
      ref={this.node}
      aria-live='polite'
    >
      {linkProps.title}
      {!!subMenu && <MenuBar
        className={isSubmenuOpen ? 'header_link_subMenu-shown' : ''}
        items={subMenu}
        label={`Подменю ${linkProps.title}`}
        focusParent={() => {
          this.hidePopup()
            .then(this.props.focusItem)
        }}
      />}
      {/*{!!subMenu && <section className={isSubmenuOpen ? 'header_link_subMenu-shown' : ''}>
        {subMenu.map((menuItem, index) => <MenuLink
          item={menuItem}
          index={index}
          menu={subMenu}
        />)}
      </section>}*/}
    </NavLink>
  }
}