import React from 'react'
import { IBookPage } from './index'

const chapter13: IBookPage = {
  header: 'header_d6.jpg',
  title: 'Глава 13. Брать след, отлеплять от земли',
  prevLink: 'chapter12',
  nextLink: 'chapter14',
  content: <>
    <h2>Университет. Охрович и Краснокаменный</h2>

    <img alt='' src="/images/13_okhrovich_i_krasnokamennyi.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>На часах 19:23</p>

    <p>&nbsp;</p>

    <p>— Я говорил, что нужно было ехать
      на такси. Поехать порасспрашивать на вокзале <i>можно</i> и на такси. У нас есть эти волшебные штуки под названием
      ноги.</p>

    <p>— А Хащина тем временем спит
      спокойно, и ни одна суровая силовая пята не тревожила её сна. В Бедрограде та
      же благодать?</p>

    <p>— Тишь да гладь.</p>

    <p>— Которая
      способ вышивки.</p>

    <p>—&nbsp;Максима не нашли, Габриэля
      Евгеньевича не нашли, Бровь не нарисовалась даже на бумажках, запрос на встречу
      гэбен не отозвать, лекарство готово, план действий — нет.</p>

    <p>— Можно посадить вместо Максима
      революционное чучело. Эмоциональная глубина соответствует.</p>

    <p>— Нельзя посадить вместо Максима
      революционное чучело. Усики так и не обнаружены.</p>

    <p>— Ройш не сознался в грехах.</p>

    <p>— Беспринципное пресмыкающееся,
      готовое на всё ради услаждения своих низменных страстей.</p>

    <p>— Напомни, зачем мы очищали его
      дом от чумы?</p>

    <p>— Чтобы было чему перекинуться на
      дом Габриэля Евгеньевича. Ах да, это новость: на самом деле они с Максимом
      умерли от чумы. Дима с Гуанако нашли следы, улики и трупы.</p>

    <p>— Тогда можно принести на встречу
      гэбен труп Максима. В служебных инструкциях где-нибудь есть указания
      относительно того, что головы встречающихся гэбен должны быть живыми?</p>

    <p>— Тогда уж труп Габриэля
      Евгеньевича.</p>

    <p>— Сказать, что смерть преобразила
      Максима до неузнаваемости.</p>

    <p>— Габриэлю Евгеньевичу пошли бы
      усики.</p>

    <p>— Во-первых, нет, а во-вторых,
      зачем ты бередишь мою старую боевую рану.</p>

    <p>— Если все уже умерли,
      получается, наша поездка совершенно бессмысленна.</p>

    <p>— Ну почему же. Мы обнаружили ещё
      некоторое количество информации, которой не понимаем и в которой не можем быть
      уверенными.</p>

    <p>— Идём на мировой рекорд!</p>

    <p>&nbsp;</p>

    <p>На часах была почти половина
      восьмого вечера, на хащинском перроне — закономерно промозгло. Про-МОЗГ-ло.
      Мозги заполоняло тяжёлым туманом.</p>

    <p>И тут ударились Охрович и
      Краснокаменный оземь и обернулись Габриэлем Евгеньевичем. Единым
      в двух лицах.</p>

    <p>ДОЖДЕВЫЕ МЕТАФОРЫ</p>

    <p>От массового падежа
      университетских лиц случается опухоль мозга головы и плоскостопие.</p>

    <p>Ройш со своим
      «давайте созовём встречу гэбен, давайте-давайте, я Ройш, слушайте меня»
      заслужил исполнения своей мечты. Только так он смог бы осознать её мелкость.</p>

    <p>Порочность.</p>

    <p style={{fontSize: '14.0pt'}}>Бессмысленность.</p>

    <p style={{fontSize: '18.0pt'}}>Пошлость.</p>

    <p>И разочароваться.</p>

    <p>Охрович и Краснокаменный честно
      были готовы исполнить мечту Ройша, но Максим пропал. В неизвестном направлении.
      Ушёл с кафедры, хлопнув дверью, и —&nbsp;всё.</p>

    <p>Привет.</p>

    <p>Каюк.</p>

    <p>Специальное боевое
      разведывательное подразделение, направленное по его пылающим следам, обнаружило
      примерно ничего. Ни Максима, ни Габриэля Евгеньевича. Только раздрай в
      квартире.</p>

    <p>И кое-что ещё.</p>

    <p>Потому что нечего посылать на
      поиски <i>неудачников</i>. Тем более покойных.
    </p>

    <p>ПОКОЙ-ных, ных-ных-ных, ничего не
      вынюхали. (Лучше, смешнее: не <i>выныхали</i>,
      ничего не знают, не умеют.)</p>

    <p>Если бы на поиски Максима поехали
      Охрович и Краснокаменный, они бы непременно его нашли. Раз Габриэль Евгеньевич
      тоже тово — значит, Максим убежал за ним, как тот осёл из басни про морковку.
      Ну, где морковку привязали к нему самому, а он за ней бегал.</p>

    <p>Максим —&nbsp;<b style={{ color: '#C00000' }}>осёл</b>.</p>

    <p>Но без осла квартет из
      британского детского стишка (тот, в котором осёл, козёл, а дальше Охрович и
      Краснокаменный ни разу не прочитали, это вообще политическое какое-то
      произведение, ПРО ЧЕТВЕРЫХ ВМЕСТЕ ПОНИМАЕТЕ ДА) неполон.</p>

    <p>Ройш со своим «Бровь забрал Силовой Комитет, а я
      мечтаю
      облагодетельствовать её своей девственностью, найдите её, я Ройш, слушайте
      меня» заслужил исполнения мечты.</p>

    <p>Только никакого Силового Комитета
      в Хащине прошлой ночью не было.</p>

    <p>&nbsp;</p>

    <p>Никто не работает идеально
      чистенько. НИ-КТО. Всегда остаются
следы, грязь, бегающие глазки, надувшиеся губки, кто-то, кто видел, кто-то, кто
знает. Охрович и Краснокаменный умели брать след, отлеплять от земли,
      нести до места назначения и там разбираться.</p>

    <p>Но как взять то, чего <b style={{ color: '#C00000' }}>нет</b>.</p>

    <p>Врач, с которым разговаривал
      Ройш, действительно работал в хащинской районной больнице — но сегодня с утра
      на работу не вышел. У него после дежурства выходной, уехал отдыхать. В его
      квартире обнаружилась недостача трусов. В его подъезде видели чемоданы. В его
      телефонной книге нашлась дочь, тридцать лет, водитель такси, повязка на рукаве,
      воинственность на лице. Дочь, разумеется, особо не общалась с отцом, точно не
      общалась, месяца два уже не общалась, и накануне они не созванивались, точно не
      созванивались, но да, ей известно, что в обозримом будущем его ждать не
      следует.</p>

    <p>Ах
      водители такси, всё на свете готовы сдать.</p>

    <p>Кто ж устоит перед Охровичем и
      Краснокаменным.</p>

    <p>Они, конечно, и не надеялись
      найти врача. Был Силовой Комитет в Хащине, не был, всё одно —&nbsp;валить ему,
      и подальше. Если он умный.</p>

    <p>Он умный.</p>

    <p>Но никто из ночной смены не видел
      ни девочки, ни четвёртого уровня доступа. В жизни, может, и видел (задёргались-таки
      при виде плащей Охровича и Краснокаменного, таких же, как у Силового Комитета,
      только беееееее-лых — ТРИ НОЧИ когда-то строчили, ТРИ УПАКОВКИ игл затупили). В
      жизни видели, а вот прошлой ночью —&nbsp;нет. Точно нет. Точно-точно нет,
      можете отпустить моё запястье.</p>

    <p>НО ЗАЧЕМ ОТПУСКАТЬ ТВОЁ ЗАПЯСТЬЕ?</p>

    <p>Конечно, им всем приказали
      молчать. Но кто ж устоит перед Охровичем и Краснокаменным. Зачем перед ними
      устаивать. Они хорошие. Они зашли куртуазно спросить. Они знают, когда им врут.</p>

    <p>Им не врали.</p>

    <p>Они нашли утреннего уборщика и
      спросили у него, было ли в больнице натоптано. У Силового Комитета есть эти
      волшебные штуки под названием ноги.</p>

    <p>Ноги — топчут (топают? топочут? в общем — оставляют следы).</p>

    <p>Нет следов — нет ног.</p>

    <p>Или Силовой Комитет сработал
      идеально, не забыв выдать инструкции <b style={{ color: '#C00000' }}>всем</b> в больнице.</p>

    <p>Или, что более вероятно, Силового
      Комитета в Хащине не было.</p>

    <p>&nbsp;</p>

    <p>А в середине
дня, когда Охрович и Краснокаменный позвонили Ларию, дабы покаяться, что со
встречей гэбен всё же не стоило так спешить, поскольку выводы Ройша построились
на пустом месте, Ларий сказал, что со встречей гэбен <i>хотелось бы не спешить</i>, ибо Максим залихватски хряснул (хрястнул?
      Неграмотно, зато выразительно!) дверью, сгинул с кафедры и не
берёт трубку (ни одну из возможных).</p>

    <p>Только экстренный запрос на
      встречу гэбен (ВсТрЕчУ гЭбЕн По ПрИчИнЕ чРеЗвЫчАйНыХ
      оБсТоЯтЕлЬсТв!!) отозвать нельзя.</p>

    <p>Он на то и экстренный, что, как
      только мы — послали, а они — подтвердили (они подтвердили
      или чё?!), остаются сутки на всё про всё. Суууутки! И это максимум, дозволенный
      по протоколу. Не встретились в течение суток — ваяй объяснительные фалангам,
      они за такими мероприятиями <b>бдят</b>.</p>

    <p>Подёргать поводок и уйти в отказ
      — лажа УЛЬТРА-ГАБАРИТОВ.</p>

    <p>Но встреча обязана быть 4х4.</p>

    <p>Возможно, Максима удастся найти,
      говорил Ларий, сам не веря своим словам.</p>

    <p>Охрович и Краснокаменный знали
      много слов, которыми можно назвать подобную ситуацию. «Тупик». «Жопа». «Анальная
      пробка» (как&nbsp;синтез двух предыдущих).</p>

    <p>Это всё <i>Габриэль Евгеньевич</i> виноват — довёл-таки возлюбленного до ножки
      своими выкрутасами. Ах, мало ли, ударили его по лицу. Ах, мало ли, пять раз
      (спасибо Брови за разглашение этой информации, и земля ей пухом). Ах, его
      недостаточно любят. Ах, он такой тонкий, щас переломится.</p>

    <p>Нет, нет, это всё <i>Гуанако</i> виноват —&nbsp;его же
      присутствие на истфаке сотворило анальную пробку Максиму. <b style={{ color: '#C00000' }}>Кто-то покусился на законную власть.</b> Расстрелять. Порвать в клочья и вывесить на воротах. Забить в глотку все благие
      намерения.</p>

    <p>Нет, нет, это всё <i>Ройш</i> виноват —
      только Максиму дозволено иметь личные связи и личные слабости, не остальным.
      Остальные должны быть машинками для принятия математически безупречных решений.
      Решения можно подписать или отвергнуть.</p>

    <p>Нет, нет, это всё <i>Дима</i> виноват — ему всё нипочём (не,
      почём?). Он в университетских делах так, сбоку припёка, припёку терпят из
      милости. Припёка должна захлопнуться и не отсвечивать, а он любит отсвечивать.</p>

    <p>Нет, нет, это всё <i>Университетская гэбня</i> виновата —&nbsp;не
      отследили, не выслушали, не подставили доверительные плечи. Нет, нет, это
      вообще <i>Бедроградская гэбня</i> виновата — они
      первые начали, давно ещё развязали войну с
      Университетом, они наезжают, они <b style={{ color: '#C00000' }}>бяки</b>.</p>

    <p>Нет, нет, это фаланги виноваты.
      Хэр Ройш. Хикеракли.</p>

    <p>Набедренных.</p>

    <p>Дореволюцонное правительство.</p>

    <p>Империя до Падения Хуя.</p>

    <p>Скопцы!</p>

    <p>(Конечно,
скопцы. СКОПЦЫ — ПРОСТОЕ И ЭЛЕГАНТНОЕ РЕШЕНИЕ ВСЕХ ВАШИХ ПРОБЛЕМ. Скопцы оптом и в розницу, но оптом скидка.)
    </p>

    <p>Максим, купи скопца.</p>

    <p>Максима можно пожалеть, ему можно
      посочувствовать. Максим взвалил на себя всё, даже то, чего не просили (<b
        style={{ color: '#C00000' }}>особенно</b> то, чего не просили), и не может справиться. Максим боится потерять Габриэля
      Евгеньевича (ооо, были прецеденты) и своих миньонов (ооо, были прецеденты).</p>

    <p>Максим боится перестать быть
      хорошим человеком.</p>

    <p>И очень старается не перестать.</p>

    <p>Максима можно пожалеть, ему можно
      посочувствовать.</p>

    <p>НО ЗАЧЕМ.</p>

    <p>&nbsp;</p>

    <p>Силового Комитета (*возможно) не
      было в Хащине =&gt; вопрос судьбы Брови остаётся открытым.</p>

    <p>Охрович и Краснокаменный
      наведались к Галке, бровьской подруге. Габаритная женщина, в смеси молока и
      крови крови уже ЧУТЬ, светлые кудри, железные серьги.
      Учится в Хащинском Институте Логистики (сокращённо ХИЛ,
      гы-гы-гы), мечтает прокладывать дороги (? — своими руками?). Революционные
      предпочтения: над кроватью портрет За’Бэя, но втайне любит Метелина за чёрные
      волосы и то, что его расстреляли. При встрече неловко флиртует. Полное имя —
      Регалия Шепелогель, подружилась с Брованной Шухер наверняка на почве идиотских имён
      (*только Галке её — подходит <b style={{ color: '#C00000' }}>вполне</b>).
    </p>

    <p>Ещё раз: Регалия Шепелогель. РЕ-ГАЛИЯ.</p>

    <p>Такие женщины поддаются ОТЛИЧНОЙ
      последовательной дрессировке.</p>

    <p>Галка встретила их одна, вся в соплях. Не предложила чаю, но очень честно
      рассказала, что
      говорила с Бровью на днях, в воскресенье утром, поскольку в воскресенье днём
      они намеревались встретиться, и Бровь позвонила встречу отменить по загадочным
      причинам. Обещала потом всё рассказать. Стенала сквозь зубы, что сейчас не
      может никак. Не может встретиться, не может объяснить, почему не может
      встретиться, не может объяснить, почему не может объяснить, но это очень-очень
      важно. Галка была милостива — она сама сидела дома с каким-то ОРЗ, свидятся
      через недельку, и как там поживает ихний завкаф?</p>

    <p>У Галки ОРЗ.</p>

    <p>Галка умирает от чумы (?).</p>

    <p>Чума вышла за пределы Бедрограда
      (?).</p>

    <p>Галка не умирает от чумы (!), у
      неё эти сопли уже третью неделю без ухудшений и улучшений, но чума в самом деле
      вышла за пределы Бедрограда.</p>

    <p>В-город-из-города ездят
      электрички, поезда, такси.</p>

    <p>В них сидят люди.</p>

    <p>Некоторые из них больны.</p>

    <p>Некоторые из них будут лапать общественные перила, целоваться, пить и иметь
      половые
      контакты.</p>

    <p>Всероссийское Соседство <b style={{ color: '#C00000' }}>выгорит от чумы</b>.</p>

    <p>А Бровь ЭкСпЛиЦиТнО сказала
      Галке, что не приедет к ней в гости.</p>

    <p>Брови нет в Хащине, Брови нет в
      Бедрограде.</p>

    <p>Бровь была главным (ключевым,
      критическим, <u>единственным</u> — н. пч.) свидетелем против Бедроградской
      гэбни.</p>

    <p>&nbsp;</p>

    <p>Они сломаются. Максим — уже;
      Ройша и Диму ёбнет, когда они поймут, что Бровь — привет; Гуанако ёбнет, когда
      ёбнет Ройша и Диму. Выплывут Ларий и Попельдопель. Вот кого надо было брать в
      гэбню <i>(алё, Ларий уже в гэбне!)</i>.</p>

    <p>Шестой день идёт чума. Топ-топ.
      Самое время откинуться на простыни, закурить и подумать о содержательной
      стороне производимых телодвижений. Подумать о СМЫСЛЕ.</p>

    <p>Пять дней всё шло как по писаному. Шлёп-шлёп. Самое время всему скомкаться,
      смешаться
      и перестать быть (ск)ладненьким. Силовой Комитет,
      фаланги, Медицинская гэбня, кто кого подставит, кто кого заборет, остро
      наточенный сюжет, завязка двойным булинем, развитие, финал, мура
      козлиная.</p>

    <p>Так не бывает.</p>

    <p>Бывает-не-так.</p>

    <p>Грядёт Переломный Момент.</p>

    <p>Они (завязка,
развитие, финал, Максим, Ройш, Дима и т.д.) сломаются.</p>

    <p>А Охрович и Краснокаменный?</p>

    <p>А Охрович и Краснокаменный
      разбросают по обочинам тропы войны трупы войны.</p>

    <p>Когда хоть кого-нибудь найдут.</p>
  </>
}

export default chapter13
