import React from 'react'
import { days, IDay, chapterNumber } from './Pages/ContentsPage'
import { Link, NavLink } from 'react-router-dom'
import {RouteComponentProps, withRouter} from "react-router";

type DayProps = RouteComponentProps<{
  chapter: string
}> & {
  day: IDay
}

interface DayState {
  open: boolean
}

class Day extends React.Component<DayProps, DayState> {

  state: DayState = {
    open: false
  }

  componentDidMount() {
    this.recalcOpen()
  }

  componentDidUpdate(prevProps: DayProps) {
    if (prevProps.match.params.chapter !== this.props.match.params.chapter) {
      this.recalcOpen()
    }
  }

  recalcOpen = () => {
    const { day, match } = this.props
    const initialOpen = !!day[1].find(chapter => match.params.chapter === `chapter${chapterNumber(chapter[0])}`)
    this.setState({
      open: initialOpen
    })
  }

  toggle = () => {
    this.setState({
      open: !this.state.open
    })
  }


  render() {
    const { day } = this.props
    const { open } = this.state

    return <li className={open ? 'open' : 'closed'}>
      <span className='underlined' onClick={this.toggle}>{day[0]}</span>
      {open && <ul>
        {day[1].map(chapter => <li className='no-children'>
          {chapter[2] ? '' : `Глава ${chapter[0]}. `}<NavLink to={`/book/chapter${chapterNumber(chapter[0])}`}>{chapter[1]}</NavLink>
        </li>)}
      </ul>}
    </li>
  }
}

const DayWithRouter = withRouter(Day)

export default () => <section className='book-nav'>
  <h2><Link to='/book'>Содержание</Link></h2>
  <ul>
    <li className="no-children">
      <NavLink to='/book/prolog'>Пролог</NavLink>
    </li>
    {days.map(day => <DayWithRouter day={day} />)}
    <li className="no-children">
      <NavLink to='/book/epilogue'>Эпилог</NavLink>
    </li>
  </ul>
</section>
