import React from 'react'
import { IBookPage } from './index'

const chapter08: IBookPage = {
  header: 'header_d4.jpg',
  title: 'Глава 8. Приближение источников света',
  prevLink: 'chapter07',
  nextLink: 'chapter09',
  content: <>
    <h2>Университет. Бровь</h2>

    <img alt='' src="/images/08_brov.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }}/>
    <p>Люди в Университете хорошие,
    почти даже и все.</p>

    <p>Вот, например, Габриэль Евгеньевич.
      Ну да, является на свои занятия когда вздумается. Ну
      да, готов идти босиком по грязи, лишь бы все помнили, насколько он презирает
      окружающую действительность. Ну да, несколько сумасшедший. Но зато какой красивый! И не в том смысле
      красивый, что глупый. Вовсе и не глупый. Романы вон писал и даже, кажется,
      пишет. Бровь сама не читала, но принципиально уважала людей, имеющих
      профессиональное отношение к печатным машинкам.</p>

    <p>Или вот, скажем, Охрович и
      Краснокаменный. Ну кого может волновать тот факт, что
      они способны подарить любому желающему четвёртую группу инвалидности и уйти от
      всякой юридической ответственности (гэбня, уровень доступа и вообще их там не
      было), когда у людей такое прекрасное чувство юмора!</p>

    <p>Про инвалидность, между прочим,
      не преувеличение, был прецедент.</p>

    <p>Один, но Бровь
      Помнила.</p>

    <p>Тот же Ройш — отдельная песня.
      Все так на него реагируют, как будто и в самом деле не догадываются, что
      постоянное ройшевское недовольство — это тоже своеобразное чувство юмора. Конечно,
      он гордый и требует особого обращения, но как будто это так сложно. Между
      прочим, если его правильно попросить, ещё раз попросить, потом ещё раз очень-очень
      попросить и не забыть принести конспект какой-нибудь объёмистой монографии, он
      всегда готов пойти навстречу.</p>

    <p>Тут главное не ошибиться и не
      забыть, что монография должна быть не свежее шестидесятых.</p>

    <p>Максим (Аркадьевич), Ларий
      Валерьевич, Попельдопель —&nbsp;все замечательные люди, не лишённые
      недостатков, но зато друг за друга крутой и обрывистой горой, прям как родные.</p>

    <p>Как тут не встать в патетическую
      позу и не спросить у небес: что ж Брови-то в настоящие родные достался её папа?</p>

    <p>Наверное, дело в том, что родство
      в принципе включено в значение слова «папа». Если бы Брови достался чей-то
      чужой папа, вышло бы немного странно. Хотя во Всероссийском Соседстве детей
      делают в печах, наверняка ведь случалось и такое? Приходит кто-нибудь забирать
      ребёнка, весь такой довольный, уже тортик прикупил и соседей на праздник
      созвал, а ему — ка-а-ак подсунут, например, индокитайца!</p>

    <p>Или тавра!</p>

    <p>Таксиста!</p>

    <p>Но на самом деле, конечно, папа
      Брови достался хороший…</p>

    <p>— Нет, я же ск-казал.
      Ты не п-проходишь по медицинским п-п-показаниям.</p>

    <p>…просто чересчур заботливый.</p>

    <p>Очень легко не пройти по
      медицинским показаниям, когда осматривает тебя твой родной отец, только и
      видящий в кошмарах, как с тобой приключилось что-нибудь страшное.</p>

    <p>Например, не очень
      продолжительная (всего-то дня два) и не очень болезненная (в это тоже можно
      верить) медицинская процедура. В которой, между
      прочим, участвует поголовно весь истфак. И которая,
      между прочим, является образцом героизма, изобретательности и гражданской
      сознательности.</p>

    <p>— Т-ты
      даже не з-з-знаешь, во что ввязываешься.</p>

    <p>И чересчур упрямый.</p>

    <p>Бровь, в общем-то, понимала, что
      всё это — от чрезмерной к ней, Брови, любви, и даже вполне ценила, но, леший,
      ну не в тот же момент, когда решаются судьбы Бедрограда и так заманчиво маячит
      на горизонте Вилонский Хуй!</p>

    <p>То есть, конечно, он находится
      под землёй и, соответственно, не может маячить.</p>

    <p>Это была метафора.</p>

    <p>— Всё я знаю. Экспедиция —
      прикрытие, хотя все клянутся, что она на самом деле будет. Но сейчас студенты
      нужны для того, чтобы делать из их крови лекарство от чумы. Видишь? Я
      сознательная. И тоже хочу помочь. С твоей стороны довольно-таки свинство мне это запрещать.</p>

    <p>Ругались они, между прочим, на
      ушах у всего истфака с медфаком в придачу. Поучаствовать в процедуре,
      позволяющей потом поехать на полсеместра в степь, хотели все. Конечно, дело
      было не только в академическом рвении. Есть ещё, скажем, стадный инстинкт,
      близящийся юбилей Первого Большого Переворота (к которому все хотели бы уже
      освободиться), жаркая и пламенная любовь к парам Ройша (который, разумеется,
      возьмётся замещать всех уехавших) и её, Брови, идеологическая работа.</p>

    <p>На нытьё о том, что Бровь же
      такая важная и значимая, она же видела самого Гошку и даже пила с ним коньяк,
      она же не дура и прекрасно понимает, что происходит
      что-то хитрое и коварное, Дима на бегу вывалил ей всё как есть и предложил
      помочь. Походить, послушать, поубеждать сомневающихся в том, что Вилонский Хуй — это круто.</p>

    <p>Потому что Бровь, разумеется,
      куда эффектнее скопцов.</p>

    <p>Которые, между прочим, всё ещё толклись где-то на факультете (историческом, не медицинском)
      и охотно демонстрировали всем желающим признаки своей профессиональной
      принадлежности.</p>

    <p>Бровь, разумеется, посмотрела.
      Это заставило её глубоко задуматься о смене эстетической парадигмы за век. В
      Петерберге, как известно, были крайне популярны оскопистские салоны, а там, гм, вся соль была как раз в, ну, э,
      наличии признаков
      профессиональной принадлежности.</p>

    <p>То есть отсутствии.</p>

    <p>То есть, в общем, сто лет назад
      люди от этого тащились, а Брови вот оскопление вовсе не показалось воодушевляющим.</p>

    <p>Надо написать об этом курсовик! У
      Габриэля Евгеньевича, хе-хе. Не зря же он носит стрижку под Веню.</p>

    <p>В следующем году. В этом Ройш её
      действительно не отпустит и тему не сменит.</p>

    <p>Так вот! Поскольку весь истфак
      массово ломанулся за процедурами, инфраструктура медфака треснула. Актовый зал
      медфака (куда менее впечатляющий, чем истфаковский, ха-ха) быстренько снабдили
      простой аппаратурой (не так много нужно для простенького медицинского осмотра
      на предмет порока сердца, гонореи и прочих несовместимых с твирью заболеваний)
      и наводнили студентами. Актовый зал — потому что в другое помещение (например,
      кабинет в лазарете) столько народу не влезло бы, а всем истерически хотелось
      подкрасться поближе, посмотреть, поорать и что-нибудь спросить. Так что, когда
      дверь кабинета в лазарете чуть не выломали, было принято важное решение
      переместиться.</p>

    <p>Объёмы помещения не спасали,
      поскольку осмотр вели, по сути, папа, Попельдопель и Дима втроём. Конечно, к
      ним прилагался ещё пяток каких-то аспирантов-медиков, но их основная функция
      состояла в том, чтобы мычать и переминаться с ноги на ногу. Кроме того, Дима
      постоянно делал рукой «ща, погодите» и бегал к Попельдопелю за консультацией —
      и правда, зачем великим изобретателям из столичных
      медкорпусов знать, как проводится простой осмотр? Кроме того, большинство
      посетителей вместо осмотра предлагали Диме изысканную беседу о скопцах, и он,
      пленник своей вежливости (то есть неверных представлений о скорости течения
      времени), не мог отказаться.</p>

    <p>Свои признания в том, что всё она
      знает про истинную суть происходящего, Бровь поведала папе заговорщическим
      уголком рта, потому что на другой стороне его стола навязчиво топтался некий
      долговязый студенистый тип с пачкой справок в руках.</p>

    <p>Папа со стуком отложил ручку и
      снял очки. Все-то медики их носят, Дима вон по собственному признанию нацепил
      именно тогда, когда осознал зов сердца и уехал в Столицу.</p>

    <p>— Д-давай-ка от-тойдём на минуту. Ждите.</p>

    <p>Давно пора.</p>

    <p>Шпионский роман стремительно
      обращался шпионской эпической драмой, так что конспирация от всяких студенистых
      была весьма кстати.</p>

    <p>Папа что только не за ручку
      препроводил Бровь на кафедру вирусологии и педантично закрыл дверь. Кафедра,
      как обычно, щерилась бесконечными попельдопелевскими
      дипломами, склянками неизвестного назначения и гордо водружённым над столом
      плакатом с вирусом гриппа в разрезе и подписью «БОЙСЯ БОЛЕЗНЕЙ, ОНИ ВРАГИ».
      Конечно, не такой бардак, как на кафедре истории науки и техники, скорее
      нормальный рабочий беспорядок. Но если бы, например, Бедроградской гэбне
      удалось дать ход обвинению Брови в том, что она тайно умыкнула смертельный
      вирус прямо отсюда, фалангам не составило бы большого труда в это поверить.</p>

    <p>Кстати, очень любопытно, как
      происходит общение с ними. Бровь третий день честно жила в квартире Максима
      (Аркадьевича) и честно каталась на проводимые почти исключительно Ройшем пары в
      служебном такси (нынче без тавров, что вызывало ностальгическую печаль), но её
      так никуда и не позвали и вообще как будто забыли. При этом отловить и
      расспросить хоть кого-нибудь не получалось: Максим (Аркадьевич), если и
      появлялся, нёс на своём лице такую неподъёмную печать сосредоточенности и
      Важного Дела, что лезть к нему было страшно; Ларий Валерьевич отделывался
      неприлично формальными фразами про «в
      процессе, ждите, если что, вы узнаете об этом первыми»; Охрович и
      Краснокаменный — это, право слово, Охрович и Краснокаменный; Ройш…</p>

    <p>Ройш откровенно не хотел
      разговаривать.</p>

    <p>Если осознанно выбирать, то,
      конечно, Бровь предпочла бы шпионскую эпическую драму Ройшу, но, леший возьми,
      весьма и весьма хотелось бы всё и сразу.</p>

    <p>Да, да, она страшно всё испортила
      с этим дурацким диктофоном, ну так поругал бы,
      позлился, сказал бы, что она дура и не заслуживает, сказал бы хоть что-нибудь!
      Нет, всё в рамках протокола, спрашивает её на семинаре столько же, сколько и
      остальных, не избегает встречаться глазами, но только пара закончилась
      —&nbsp;всё, нет его.</p>

    <p>Истаял.</p>

    <p>И это было, в общем-то, обидно и
      даже как-то. Ну.</p>

    <p>Ну, в общем, Брови таки нравился
      Ройш.</p>

    <p>Пока во внутреннем монологе Брови
      звучали сии глубокомысленные рассуждения и трепетные признания, папа успел
      налить себе стакан воды, сесть за стол (вечно садится, любит он сидеть,
      ипохондрик несчастный) и аккуратно сложить руки.</p>

    <p>— Откуда т-т-тебе изв-вестно про чуму?</p>

    <p>А.</p>

    <p>Это щекотливый момент.</p>

    <p>— Само собой вышло. Бедроградская
      гэбня узнала — не спрашивай как, —&nbsp;что у меня с Ройшем сложные отношения,
      и заинтересовалась. Их волновал его уровень доступа — Ройш же
      как наследник Революции имеет право на второй. А об их волнениях узнал уже
      Университет, ну и это, раскрыл передо мной все карты.</p>

    <p>Папа замер, и лицо его отразило
      много градаций ужаса.</p>

    <p>— Т-то есть про Ройша — это
      п-п-правда?</p>

    <p>Разумеется, сложные отношения с
      Ройшем ударили в папино слабое сердце сильнее, чем сложные отношения между
      гэбнями. Кто бы сомневался.</p>

    <p>Это особенно трагично в свете
      того, что отношения с Ройшем, кажется, закончились.</p>

    <p>Теперь наука история может
      задуматься о том, а были ли они.</p>

    <p>— Да так, — старательно созерцая
      вирус гриппа, Бровь помахала рукой, —&nbsp;тоже не совсем. Ну, на втором курсе
      я у него курсовик писала, вроде понравилась. Он мне тоже, он же клёвый. Вот мы иногда и встречались поговорить о
      жизни.
      Ничего порочного и даже близко напоминающего эротическое, честное слово. А
      потом Бедроградская гэбня, а потом Университетская, а потом я стала у Ройша
      жить. Для конспирации. Чтобы все думали, что мы, ну. Того. Встречаемся.</p>

    <p>Конспирация была непростой,
      потому что, сколь бы ни был папа не от мира сего, всё-таки не очень просто
      сделать так, чтобы о каком-то конкретном факте из биографии его дочери знали все,
      кроме Андрония Леонидовича Шухера. Особенно с учётом
      его скромного хобби заскакивать иногда к ней домой без предупреждения.</p>

    <p>Подарить ему, что ли, футболку с
      надписью «У МЕНЯ СЛАБОЕ СЕРДЦЕ»?</p>

    <p>Но сколько бы папа ни ныл, он, в
      сущности, и правда так заботился. Некоторым в этом
      смысле везёт меньше. С другой стороны, переизбыток как будто лучше недостачи!
      Что папа, что Ройш…</p>

    <p>Впрочем, Ройша больше нет
      —&nbsp;и переизбытка, соответственно, нет. Ура. Всеобщее ликование. К тому же,
      судя по выражению лица, папу информация о конспиративном характере
      несуществующих отношений только порадовала, хоть и ненадолго.</p>

    <p>— Значит, Университетская
      г-г-гэбня т-тебя использовала?</p>

    <p>Это ещё кто кого! Университетская
      гэбня получила, что хотела (пробирку с продуктами разложения и своевременный
      запуск плана Бедроградской), а Бровь зато узнала
      тысячу страшных тайн и теперь имеет полное право затребовать себе форменный
      наплечник младшей служащей, полагающийся по званию. Вот знает ли папа,
      например, что Дима — это вовсе даже и Дмитрий Смирнов-Задунайский, создатель
      иммунной сыворотки от степной чумы и трагический труп, чей портрет висит в
      холле медфака в почётной чёрной рамке всего в паре десятков метров от портрета
      Шарля Дарвена?</p>

    <p>Хотя знает, наверное. Он же
      очкарик, а не слепой.</p>

    <p>Всё-таки потрясающе Дима
      самоуверен.</p>

    <p>— Ну что за глупости. Меня же
      никто не заставлял и ничего не скрывал. Наоборот, рассказали всё как есть,
      спросили, хочу ли помочь. Право выбора, все дела. Выбора, который я сделала.</p>

    <p>И тут такой косой луч света ей на
      лицо, как в театре, и драматические фанфары.</p>

    <p>Потому что Бровь звучит
      Решительно и Твёрдо.</p>

    <p>— И зачем?</p>

    <p>— Что значит «зачем»? Ты бы
      предпочёл, чтобы Бедроградская гэбня преуспела, заразила канализацию дома Ройша
      и подставила Университет?</p>

    <p>И мы не вспоминаем, кто при этом
      оказался бы козлом отпущения.</p>

    <p>Папа не нашёлся, что ответить.
      Ясно же, что это он так, из упрямства спорит, а сам вон занят осмотром
      студентов. Ну, в те моменты, когда не занят
      препирательствами с Бровью.</p>

    <p>— Я п-п-послал свою
      д-дочь учиться в БГУ не затем, чтобы она ввязывалась в г-г-государственные
      интриги.</p>

    <p>— Ты, папа, при всём уважении
      меня никуда не посылал. Я сама сюда пришла, о чём ничуть не жалею. И
      намереваюсь не жалеть и дальше. И вообще, я теперь младший служащий, оцени
      карьерный рост! — Бровь вздохнула и присела на край папиного стола. — Ну что
      ты, правда? Соврать тебе, что мне это не нравится? Нравится. Нравится быть в
      нужное время в нужном месте. Нам повезло, что над вирусом работал Дима. Он ведь
      случайно оказался в Медкорпусе и втёрся в доверие к кому надо.</p>

    <p>— Д-да,
      и сделал ст-т-тепную чуму.</p>

    <p>Что бы он ни хотел этим сказать,
      оно наверняка неверно и не относится к делу.</p>

    <p>— Не степную, а водяную, где твой
      профессионализм? Ну и вот. Ты ведь сам прекрасно понимаешь — ты вечно всё
      понимаешь и продолжаешь спорить! — что Бедроградская гэбня могла и ещё выждать,
      а потом подбросить-таки вирус. Какой-нибудь другой, о котором
      мы бы ничего не знали. Неизвестно когда. А так мы их спровоцировали и можем
      защищаться, потому что следим за тем, что происходит, и есть доказательства,
      есть свидетели…</p>

    <p>И нет одной, в сущности, мелочи. Какой же, какой — она такая мелкая, так легко забыть? А,
      вот: записи разговора с Гошкой, без которой всё это — плугом по болоту.</p>

    <p>Леший.</p>

    <p>— «Мы», — горько и очень искренне
      сказал папа, — т-ты правда д-д-думаешь, что живёшь в
      авантюрном романе. Т-ты всегда была увлекающейся
      д-д-девочкой. Они д-дали тебе какое-то т-там звание, этот, какой бишь он…
      уровень д-доступа, и т-т-ты правда думаешь, что
      влияешь на судьбы мира. Но это ведь ерунда. Т-т-ты же просто ст-тудентка, одна из многих. Не т-ты
      — т-так кто-нибудь другой.</p>

    <p>Рассказать бы ему, как она
      распивала коньяк с Гошкой, вот утёрся бы! Он сам к ним так ни разу и не
      пробился, дальше приёмной не попал, и ещё страшно хвастался тогда, что,
      кажется, <i>возможно</i>, видел мимоходом
      одного из голов Бедроградской гэбни. Событие в жизни.</p>

    <p>Рассказать бы ему, но он ведь расстроится,
      что маленькая беззащитная Ванечка уже (ах!) пьёт
      коньяк.</p>

    <p>Только это же не повод молчать,
      когда университетская идеология, за которую Бровь теперь отвечает, так
      откровенно хромает!</p>

    <p>— Это не вопрос моей
      исключительности, — как можно более рассудительно ответила она, — скорее уж
      моих интересов. Я, например, не хочу, чтобы Университет закрыли. Ладно, это
      драматизация, но не хочу и чтобы, скажем, Максима Аркадьевича или Охровича с
      Краснокаменным выгнали —&nbsp;а если бы всё сложилось по воле Бедроградской
      гэбни, наверняка вышла бы какая-нибудь такая гадость. И вообще, я
      университетская не потому, что мне дали уровень доступа — двенадцатый, кстати.
      Я университетская, потому что учусь в Университете. И хочу ему всех благ. Разве
      это плохо?</p>

    <p>— И чт-то
      бы для т-т-тебя изменилось, если бы Университетскую г-г-гэбню распустили? Я сам
      узнал о ней вчера. Ты —&nbsp;к-когда, полгода назад? Месяц? Год?</p>

    <p>— Не знаю, что изменилось бы,
      —&nbsp;честно ответила Бровь, — но ничего хорошего бы точно не было. И потом,
      ты так нервничаешь, как будто я с ножом в руках за Бедроградской гэбней по
      подворотням гонялась! А я почти ничего и не делала. Нужно было их
      спровоцировать на решительные действия —&nbsp;вот и помогла. И всё. Никто не
      умер, а у Университета теперь есть шансы не оказаться в дерьме.
      По-моему, всё прекрасно.</p>

    <p>Папа посмотрел на неё с таким
      искренним, болезненным каким-то недоверием, что Бровь даже смутилась. Прям как будто в этих её словах было что-то
      революционное.</p>

    <p>Недаром, недаром кафедральное
      чучело нынче нарядилось лицом Революции, самим Набедренных.
      Глубокий, глубокий символизм.</p>

    <p>Просто даже Знак.</p>

    <p>— В-ваня,
      — нетвёрдым и просящим голосом пробормотал папа, —&nbsp;т-т-ты звучишь, как
      п-пропагандистская листовка. «Нужно сп-провоцировать»?
      К-кому нужно? Т-т-тебе? Зачем?</p>

    <p>— Университету, — упрямо буркнула
      Бровь. Если хочет упереться рогами и не слушать, это же и получит.</p>

    <p>Наука генетика передаёт потомкам
      не только проблемы с сердцем, в конце концов.</p>

    <p>Папа взмахнул руками, как
      переполошённая курица.</p>

    <p>— Ванечка, милая, что т-т-такое
      «Университет»? Университет — это несколько зд-даний с
      к-к-колоннами и слова на шт-тампе из секретариата. Но
      п-п-провоцировать кого бы т-то ни было не нужно ни
      к-колоннам, ни штампу. Это нужно людям. К-к-каким-то людям.</p>

    <p>— Хорошим людям, — вставила
      Бровь, просто-таки каменеющая от переизбытка Решительности и Твёрдости. Папа,
      разумеется, будто и не услышал. На самом деле, он часто продолжает начатую
      фразу, не отвечая на вопросы, потому что (о чём почему-то всё время забывают
      его нелюбители) заикам довольно сложно говорить и не
      стоит тратить хорошо взятое дыхание. С этим (как с темпом жизни Ройша) можно
      только смириться.</p>

    <p>Впрочем, даже исполненная
      возвышенного смирения, Бровь всё равно решительно и твёрдо сложила руки на
      груди, а папа продолжил:</p>

    <p>— И кто эти люди? К-к-кто
      к-конкретно решил, что «нужно п-провоцировать»? Даже если это, как т-ты
      выражаешься, хороший человек, т-ты уверена, что он
      достаточно умный? Знает все нужные детали? Не совершил ошибки? Университет —
      это люди, В-ваня. Разные люди.</p>

    <p>Что значит — «кто конкретно
      решил»? Университет.</p>

    <p>Университетская гэбня.</p>

    <p>Максим (Аркадьевич).</p>

    <p>Кто-нибудь ещё.</p>

    <p>Короче, Университет.</p>

    <p>Гм.</p>

    <p>Да, это и правда звучит как
      пропагандистская листовка.</p>

    <p>Так ну и что? Это что, означает,
      что папа прав, нужно забить на чуму, не делать лекарства и сидеть в уголке,
      периодически возмущаясь, что люди такие разные?</p>

    <p>Покачивать эдак чинно головой
      —&nbsp;мол, куда катится мир, куда катится Всероссийское Соседство, тьфу.</p>

    <p>— Не знаю, кто конкретно,
      —&nbsp;всё ещё очень честно (и очень запальчиво, да, да, она в курсе) ответила
      Бровь. — Но — ну и что? Наверное, Университетская гэбня. Наверное, они не идиоты и догадываются, что всё могло
      —&nbsp;и ещё может
      —&nbsp;провалиться. Подозреваю, что они поумней нас с тобой будут —&nbsp;по
      крайней мере, поопытней в таких вопросах.</p>

    <p>Папа смотрел на Бровь с
      разочарованием. Когда он это делал, у него всегда немного оттопыривалась нижняя
      губа.</p>

    <p>Губа ведала, что сейчас будет. Они ещё немного поругаются, останутся при своих мнениях, но потом
папа согласится больше не поднимать этот вопрос и притащит в дар очередной
свитер не по размеру (свитера по размеру Брови продавались разве что в единственном
на весь город зоомагазине, в разделе товаров для ручных крыс — микроскопическая
комплекция тоже была щедрым генетическим даром, спасибочки).
      Папа почему-то уверен, что Бровь любит огромные свитера.</p>

    <p>Впрочем, за долгие годы ношения,
      наверное, и правда полюбила. Надо честно признаться хотя бы самой себе, что это
      сперва было типа в благодарность за трогательный знак
      внимания, а теперь она и сама такие покупает.</p>

    <p>Привычка — страшная вещь.</p>

    <p>— Не переживай ты так, тебе же
      вредно волноваться, — примирительно сказала Бровь. — Я даже согласна с тем, что
      план был рискованный и с переизбытком переменных. Но всё ведь сложилось и уже
      почти закончилось, а ты ведёшь себя так, будто произошло что-то страшное.</p>

    <p>Не-а, ноль звёздочек на борту,
      попытка дружелюбия не засчитывается. Папина губа совсем вылезла вперёд и затараторила (вот чего ей точно не нужно
      пытаться делать
      —&nbsp;так это тараторить, с заиканием-то), трепеща от негодования:</p>

    <p>— А к-к-как б-будто не
      п-п-произошло! Эпидемия ч-чумы в Б-б-бедрограде — это
      к-как б-будто мелочь!</p>

    <p>И опять! Ведь папа вроде как даже
      и прав, но говорит это с такой непоколебимой уверенностью и
      настолько не допуская возможности существования других мнений, что не
      продемонстрировать ему обратное — ну просто преступно же!</p>

    <p>Бровь надулась, изо всех сил
      сдерживая желание вопить с подвываниями:</p>

    <p>—&nbsp;Нет, не мелочь. Я даже не
      буду пытаться делать вид, что так и было задумано. Да, случилась ошибка
      —&nbsp;я не знаю, почему, я же не влияю на судьбы мира, мне даже никто не
      рассказывал, как Университет вообще обо всех этих таинственных бедроградско-гэбенных планах узнал. Но знаешь, в
      чём
      отличие Университетской гэбни от тебя —&nbsp;и, тонко намекну, отличие в лучшую
      сторону? Они что-то где-то сделали не так —&nbsp;и теперь пытаются исправить.
      Причём, по-моему, довольно остроумным способом. А ты сидишь и ноешь, что они
      глубоко неправы. Неправы —&nbsp;исправят. Нужно же не ковыряться в том, кто прав
      и виноват, а делать что-то.</p>

    <p>— П-п-проводить над своими ст-тудентами
      экспериментальные медицинские п-п-процедуры под руководством неизвестно
      к-к-кого, д-да? От-тличное решение.</p>

    <p>Бровь с папой метнули друг в
      друга гневные взоры и отвернулись в разные стороны.</p>

    <p>Неизвестно кто, между прочим,
      явно предпочёл бы бухать в Порту со Святотатычем-младшим — то есть это,
      светилом-мировой-науки-идеологом-всероссийского-соседства-доктором-наук Гуанако,
      —&nbsp;а не прыгать между бесконечными студентами с фонендоскопом в зубах. Сам
      Гуанако, кстати, тоже ведь просто с корабля неудачно сошёл, явно не собирался
      светить мировой науке по всему Университету, а пришлось — мелькает то тут, то
      там на грузовиках с халатами и прочей фанаберией из Порта. И Максим
      (Аркадьевич) вряд ли собирался не спать сутками (видела Бровь его рожу), бегая
      по фалангам. Попельдопель так даже и не скрывал своего отношения к экстренным
      ситуациям.</p>

    <p>Но ничего, все работали и не
      жаловались. Ну то есть жаловались, но так, гм.</p>

    <p>Не отрываясь при этом от работы.</p>

    <p>Оба Шухера обиженно сопели,
      заставляя лирически колыхаться попельдопелевские
      дипломы.</p>

    <p>— В-ванечка, я п-п-просто п-прошу
      тебя д-думать своей головой и не лезть т-т-туда, куда тебе пока рано.</p>

    <p>— Подделать мне справку и
      запретить участвовать в процедуре — отличный способ научить думать своей
      головой, ага.</p>

    <p>— Я ничего не п-п-подделывал! — возмущённо
      воскликнул папа —&nbsp;и, кажется, он не врал, или как минимум очень верил, что
      не врал. — У т-тебя слабое сердце, может не
      в-выдержать. Не хват-тало ещё, чтобы т-т-ты… чт-тобы
      с т-т-тобой… В к-конце концов, т-ты не единственная
      студентка, не п-п-прошедшая по медицинским п-показателям!</p>

    <p>Ну что за чушь,
      а? Разумеется, Бровь не может умереть от какой-то там твири, тем более в
      разгаре действия. Вот в самом конце, когда злодейскую Бедроградскую
      гэбню уже накажут, её головы, да, могут вырваться, наброситься где-нибудь в
      тёмном переулке —&nbsp;или нет, скорее прямо среди бела дня, ибо в отчаянии!</p>

    <p>И тогда Бровь эдак
      лихо сиганёт за какой-нибудь ящик (ну или куда-нибудь там), укрываясь от пуль,
      а Максим (Аркадьевич) прикроет её —&nbsp;у гэбни же есть табельное оружие. И
      она подкрадётся со спины, и её даже успеют заметить, но не придадут значения,
      потому что кто может ожидать, что обычная студентка, одна из многих, догадается
      огреть по затылку обломком трубы (ну или чем-нибудь
      там).</p>

    <p>И потом ей до конца учёбы будут
      ставить все экзамены автоматом.</p>

    <p>В благодарность.</p>

    <p>А папа будет удивляться, почему
      же так выросла её успеваемость, но она только улыбнётся, закурит портовую самокрутку (потому что ей подарят целый
      ящик — тоже в
      благодарность) и не ответит.</p>

    <p>Вот это — да, это реальная
      угроза. А что не выдержит хвалёно слабое шухеровское сердце — бредятина.</p>

    <p>— Папа, я <i>хочу помогать</i>! —&nbsp;всё-таки повысила голос Бровь. — И, как уже
      было сказано где-то в начале обсуждения, запрещать мне это —&nbsp;свинство!</p>

    <p>— Т-ты
      можешь умереть! —&nbsp;нездорово высоко взвизгнул папа.</p>

    <p>— А ещё могу спасти пару десятков
      людей! Честное слово, ну что за эгоизм? Как будто всё в этой жизни нужно делать
      только ради себя. «Кому это ну-у-ужно?»
      —&nbsp;Университетской гэбне! Ройшу! Габриэлю, простите, Евгеньевичу! <i>Другим</i> людям, которые мне нравятся, а
      поэтому это всё —&nbsp;и провокации, и твирь, и, может, что-нибудь ещё — нужно
      и мне. И я прекрасно знаю, что всё у меня со здоровьем в пределах нормы, от
      физкультуры вот не освобождают. Не хочешь по-честному —&nbsp;попрошу
      кого-нибудь ещё раз меня осмотреть. Подтвердят — приду извиняться. Нет
      —&nbsp;выставишь себя свиньёй в моих глазах.</p>

    <p>Папа сидел без движения,
      налившись неуютно-красной краской и мелко постукивая пальцами по столешнице.</p>

    <p>Отлично сработано, Бровь. Просто
      отлично. Самое время довести родного отца и одного из всего трёх
      функционирующих медиков до удара.</p>

    <p>— Т-т-там много народу, на
      п-п-повторные осмотры нет…</p>

    <p>— А у меня связи, — брякнула
      Бровь, соскочила со стола и вышла.</p>

    <p>Не очень твёрдо и не очень
      решительно.</p>

    <p>Ну почему ей всегда становится
      стыдно минуты эдак на пол раньше, чем следовало бы?</p>

    <p>&nbsp;</p>

    <p>Разумеется, в коридоре она
      врезалась прямиком в Диму, который занимался тем, что дёргал все двери подряд,
      извинялся, захлопывал их обратно и носился взад-вперёд.</p>

    <p>Дима? Один из двух оставшихся
      человек, которые могли бы её осмотреть? Вот тут, в пустом коридоре? Какое
      удивительное совпадение, что бы оно могло значить?</p>

    <p>Судьба определённо издевалась над
      Бровью.</p>

    <p>Её саму всегда раздражали люди,
      не способные определиться, чего им надо, —&nbsp;и вот, конечно, здравствуй,
      Сигизмунд Фрайд.</p>

    <p>Если папа в результатах осмотра
      соврал — это свинство. Но пойти
сейчас к Диме —&nbsp;вот так прям, папе в лоб, шоб
знал — свинство не меньшее. Плюс достаточно очевидно, что истина где-то
      посередине, Бровь полупригодна или типа того, а
      медицинское заключение зависит исключительно от степени осторожности заключателя.</p>

    <p>— Решил всё-таки отработать свой
      диплом и послушать лекции? — дружелюбно окликнула
      Бровь Диму. Тот, как обычно, обернулся (почему всегда, когда к нему обращаются,
      он оказывается спиной к говорящему?) и помахал рукой.</p>

    <p>Давненько не виделись, аж полчаса!</p>

    <p>— Курилку ищу, —&nbsp;ответил он,
      широкими шагами подбегая к Брови, —&nbsp;будь моей путеводной звездой.</p>

    <p>— Потому что спросить у
      кого-нибудь из, не знаю, <i>студентов
        медфака</i> ты не догадался.</p>

    <p>— Мне сказали, что на третьем
      этаже, а подробности поглотил рёв ликующей толпы.</p>

    <p>— Как откажешь кумиру народных
      масс?</p>

    <p>И как приятно знать, что у трёх
      действующих на данный момент медиков такой напряжённый график работы.</p>

    <p>Медфак выглядел непривычно. На
      истфаке сегодня просто воцарилась псевдоканикулярная
      тишина, сопровождаемая не менее псевдоканикулярной
      пустотой — но не благостно-летняя, а тяжёлая, зловещая прям.
      Двери аудиторий не заперты, но за ними никто не учится, не списывает и не ржёт
      во весь коридор.</p>

    <p>Будто по истфаку уже прошлась
      чума.</p>

    <p>Медфак пострадал меньше.
      Пятикурсников разослали по районным поликлиникам — сидеть в регистратуре и
      вылавливать людей с симптомами тяжёлого ОРЗ. Под предлогом практики.</p>

    <p>Тяжкая, тяжкая жизнь
      студента-медика.</p>

    <p>Актовый зал оккупировали истфаковцы, и всем было понятно, что никакого учебного
      процесса в здании не происходит, слишком лихорадочное волнение этот самый
      актовый зал источал. Тем не менее, пары продолжались, и все, кроме несчастных
      (счастливых?) пятикурсников и нескольких аспирантов, на них сидели.</p>

    <p>Вот это было куда более каникулярное ощущение. Вроде бы все учатся, но на
      самом деле нет, потому что как только пара закончится —&nbsp;побегут толпой на
      первый этаж, выспрашивать.</p>

    <p>Ультра-секретный
      совместный проект медицинского и исторического факультета, спешите видеть.</p>

    <p>Это всё (особенно про практику
      пятикурсников и то, как Брови повезло не быть с медфака) ей Дима же и
      рассказал. Неясно только, зачем. Она, конечно, сама спросила, но так, не особо
      ожидая реакции. Дело Брови уже сделано, ей сейчас осталось только крутиться
      где-нибудь неподалёку, не пропадая из видимости, и быть одной из студентов,
      готовых, того не зная, поучаствовать в создании лекарства. Но нет, Дима после
      своего вчерашнего выступления её аж специально нашёл
      (что было несложно, ибо Бровь как приклеилась к Ларию Валерьевичу, так и не
      собиралась отцепляться), увёл в сторонку и долго объяснял, что происходит.</p>

    <p>Нравится она ему, что ли?</p>

    <p>Он ей, конечно, тоже вполне так.
      Галка вот не оценила бы, ей подавай сплошных габриэлей евгеньевичей, ваяющих
      тонкими музыкальными пальцами будущую классику всероссийской литературы, она аж
      его фотку клянчила когда-то. И фотка-то есть, но всё никак не доехать до
      Хащины, с натуральной шпионской эпической драмой на руках.</p>

    <p>— Слушай, а ты не дальтоник?</p>

    <p>— Давай, пошути про мой вкус в
      одежде, — обиженно протянул Дима.</p>

    <p>— Нет, я полна терпимости и
      ничего не имею в виду, но ты в курсе, что на тебе розовая рубашка и салатовый
      галстук, а на штанах вышиты чьи-то бдительные глаза, да?</p>

    <p>— Эти штаны у меня с третьего
      курса.</p>

    <p>— И красные носки.</p>

    <p>— Красный — отличный цвет, чем он
      тебе не угодил?</p>

    <p>— И всё ещё розовая рубашка.</p>

    <p>— Это эстетическое решение.</p>

    <p>Ага, как и перевязать волосы
      простой хозяйственной резинкой.</p>

    <p>— Скажи мне честно, ты всегда
      таким был?</p>

    <p>Дима посмотрел на Бровь с болью и
      страданием.</p>

    <p>— Нет, я был лучше. Но потом к
      Ройшу, который так трогательно берёг половину моих вещей после моей же смерти,
      въехала какая-то незнакомая девушка и половину этой половины прикарманила.
      Не знаешь, случайно, почему я не нашёл своих любимых красных подтяжек и куда
      они задевались? А вторую квартиру, где хранился совсем неприкосновенный запас,
      так и вообще разграбили Охрович и Краснокаменный. Чья, по-твоему, расстрельная
      рубашка была вчера на чучеле Метелина? И зачем тебе вообще подтяжки, ты же их
      не носишь!</p>

    <p>— Понятия не имею, о чём ты
      говоришь, —&nbsp;крайне невинно (даже самый подозрительный тип не догадался бы!)
      ответила Бровь.</p>

    <p>— Мне не жалко, но носить одежду
      покойников — перверсия.</p>

    <p>— Вот поэтому, как ты сам
      заметил, я её и не ношу.</p>

    <p>Дима хмыкнул.</p>

    <p>Уела, а!</p>

    <p>Они наконец-то добрели до курилки
      — маленькой комнатки в конце коридора, сделанной, по папиным рассказам, в
      стародавние времена (то есть годы его юности) из сломавшегося сортира, который не хватило денег чинить. Поэтому
      унитазы
      выбили, дырки залили бетоном, сверху на них взгромоздили кое-как покрашенные
      лавки и поставили на подоконник пятилитровую пепельницу (которой медикам всё равно
      не хватало —&nbsp;нервная работа). Ах да, ещё прорубили в стене окно.</p>

    <p>Это ведь куда проще, чем починить
      канализацию!</p>

    <p>По крайней мере, так было в
      стародавние времена, когда Университет ещё не владел оной.</p>

    <p>Правда, косая деревянная рама,
      кажется, не умела закрываться до конца. Вентиляция в курилке — это хорошо в
      сентябре, а зимой тут что, прямо в пальто курят? Предварительно забрав его из
      гардероба и доставив на третий этаж?</p>

    <p>Дима вытащил из кармана мятую
      картонную пачку без опознавательных признаков и добыл оттуда самокрутку.
      Предложил вторую Брови. Она взяла. Вообще-то Бровь пыталась бросить курить — не
      хватало ещё добавить к слабому сердцу тухлые лёгкие (да, да, несложно
      догадаться, травма детства, папа-доктор-наук), но настоящая портовая самокрутка — это не то, от чего можно
      отказаться.</p>

    <p>Запрыгнув на подоконник, Дима
      закурил, галантно забыв предложить Брови зажигалку. Она немного побрюзжала без
      слов и стала копаться в поисках своей — оставшейся в сумке, оставшейся аж на кафедре истории науки и техники.</p>

    <p>Хороша собой
      и обладает отменной памятью.</p>

    <p>Заметив страдания Брови, Дима
      наконец-то протянул ей желанный источник огня. На этом инцидент с закуриванием можно было считать исчерпанным.</p>

    <p>Очень важное и примечательное
      событие в жизни обоих.</p>

    <p>— А чего на улицу не пошёл? Ближе
      же.</p>

    <p>— Там толпятся, уходить пока
      нельзя —&nbsp;скоро первую порцию студентов класть в лазарет. Уже положили бы,
      но последнюю партию коек только привезли из Порта, расставляют. Поэтому
      несчастные, не ведающие своей будущей судьбы, торчат как раз на крыльце
      факультета и треплются. И я не прочь потрепаться, но
      одни и те же вопросы уже вот тут, — он показал ребром ладони уровень шеи,
      поразмыслил, и поднял его до носа, — сидят. И потом, я же выпускник медфака.
      Вот спросит меня кто-нибудь — уважаемый Дмитрий, если вы тут пять лет
      проучились, скажите нам, где на знаменитом медицинском факультете БГУ имени
      Набедренных курилка? А я в ответ смогу только мычать Попельдопелем.</p>

    <p>— Кто спросит?</p>

    <p>— Кто-нибудь, —&nbsp;Дима, как
      обычно, говорил обо всём на свете, даже о партии коек, с ухмылкой от уха до
      уха, но сейчас его голос подёрнулся лёгкой мечтательностью. —&nbsp;Знаешь, о
      чём у меня интересовались на первом в жизни допросе?</p>

    <p>— О чём? —&nbsp;вежливо подыграла
      Бровь.</p>

    <p>Её личный шпионский роман до
      этапа допросов ещё не дошёл. И, вообще говоря, не собирался. Ну
      то есть она очень надеялась, что не собирался. И понятия не имела, что у Димы в
      анамнезе оный имеется.</p>

    <p>— О составе Временного
      Расстрельного Комитета.</p>

    <p>Тёмно-сизый дым извивался
      зигзагами и, издеваясь над аэродинамикой, утекал не в оконную щель, а под
      дверь, прямо в коридор.</p>

    <p>— Типа истязали тебя
      бессмысленными вопросами, ответов на которые нельзя не знать?</p>

    <p>—&nbsp;Вероятно, таков был план,
      — Дима ухмыльнулся совсем уж мечтательно. —&nbsp;Каково же было их удивление,
      когда я даже под пытками не ответил.</p>

    <p>Бровь выкатила глаза.</p>

    <p>— Тебя истязали бессмысленными
      вопросами, ответов на которые нельзя не знать, <i>в возрасте десяти лет</i>?</p>

    <p>— Нет, в возрасте десяти лет меня
      истязали другим, — голосом диктора, читающего радиопостановку-триллер, завыл
      Дима. — Я же кассах, представитель малого, но очень
      гордого народа. То есть папенька мой кассах. Был. А к
      кассахам в нашей стране, как ты не знаешь и не шибко должна знать по уровню
      доступа, особое отношение. Вот меня и определили в специальный отряд, где не
      учат революцию, а занимаются более интересными вещами.</p>

    <p>— Какими?</p>

    <p>— Кошмарными! Например,
      экспериментально выясняют, можно ли выработать у ребёнка весьма конкретные
      условные рефлексы на весьма конкретных людей, объекты или модели поведения.
      Хотя кто знает, может, это просто у руководства отряда было такое чувство
      юмора.</p>

    <p>Он ещё и жертва бесчеловечных
      экспериментов, оказывается.</p>

    <p>Не только у Брови было тяжёлое
      детство.</p>

    <p>— То есть ты теперь маниакальный псих, готовый по щелчку броситься на любого человека и
      отгрызть ему ногу?</p>

    <p>Дима выдал улыбку повышенной
      загадочности.</p>

    <p>— Веселее. Чья, по-твоему,
      расстрельная рубашка обычно висит на чучеле Вени.</p>

    <p>Веня —&nbsp;бордель — оскописты с
      их эротоманией —</p>

    <p>Ой.</p>

    <p>Ой-ой-ой.</p>

    <p>Сделаем вид, что Бровь не способна
      на логическое мышление, потому что вот этого она точно знать не хотела.</p>

    <p>И вообще, род занятий Вени он,
      значит, из Революции помнит, а состав Временного Расстрельного Комитета он,
      значит, не помнит!</p>

    <p>У каждого свои интересы.</p>

    <p>— У меня есть выгодное отличие от
      Вени: без признаков профессиональной принадлежности и, соответственно, с
      наличием признаков принадлежности половой я всё-таки имею какие-никакие шансы
      затеряться в толпе, — доверительно продолжил Дима. — У вас в отряде ходили
      страшилки про то, что вы останетесь там на всю жизнь?</p>

    <p>— Ну
      ходили, — осторожно признала Бровь.</p>

    <p>— А у нас в один прекрасный день
      могли защёлкнуть на шее ошейник — это не метафора — и сказать, что <i>больше ты
        оттуда никуда и никогда не денешься</i>.
      Если денешься — тебе же хуже, в естественной среде такие,
      мол, не адаптируются. Необходимые манипуляции произведены, изменения налицо,
      никакого оскопления не надо, ты, родимый, теперь не то чтобы совсем человек,
      сиди и рыпайся только в соответствии с заданным ритмом. Потому и сбежал на
      следующий день. У меня свои собственные эксперименты на детях.</p>

    <p>Бровь почувствовала, как у неё в
      голове с противоестественной скоростью переосмысляются бордельные начинания и
      отрядская система одновременно.</p>

    <p>Дима, крайне довольный созданным
      эффектом, полез за второй самокруткой.</p>

    <p>Дурной он.</p>

    <p>В естественной среде такие <i>определённо</i> не адаптируются.</p>

    <p>На мизинце правой руки у него
      красовался тот самый перстень с чёрным треугольным камнем. Видимо,
      Святотатыч-младший — да тьфу же, Гуанако же!
      —&nbsp;его всё-таки проиграл. Эх, судьбинушка
      портовая.</p>

    <p>— Знаешь, я тебе завидую, —
      туманным голосом выдал Дима и резко стал лет на десять старше. Если не на
      двадцать. Потому что как пить дать сейчас пойдёт беседа о том, какая Бровь
      юная, незамутнённая и с огоньком в глазах. Увлекающаяся девочка.</p>

    <p>Толку-то жить, если не
      увлекаться.</p>

    <p>Бровь состроила лицом вежливый
      вопрос.</p>

    <p>— Участвовать во всяких крупных
      политических событиях — это же так круто! Тем более если осознанно, тем более
      не в одиночку, а с друзьями.</p>

    <p>Это кто ей друзья —&nbsp;Университетская гэбня, что ли?</p>

    <p>Ей пойти предложить Максиму (Аркадьевичу)
      по пивасику, что ли?</p>

    <p>Дима совсем сдурел, что ли?</p>

    <p>— Ничего, — Бровь ободряюще
      похлопала его по плечу, — зато у тебя есть шрамы. Шрамы круче политики.</p>

    <p>— Безусловно, — Дима, зная толк в крутости, не мог врать даже из вежливости, — но не
      процесс их получения.</p>

    <p>Если бы Бровь жила в настоящем
      шпионском романе, там бы сейчас было что-нибудь про то, как в глазах его
      промелькнула затаённая боль. Только ничего в них, конечно, не промелькнуло,
      кроме вполне оправданного самодовольства.</p>

    <p>Бровь, зная толк в самодовольстве,
      была готова признать его оправданность.</p>

    <p>Над головами Людей, Знающих Толк,
      трепеща самокруточными крылышками, пронёсся дух взаимопонимания.</p>

    <p>— По здравом рассуждении,
      да, мне можно позавидовать, — важно произнесла Бровь и неожиданно ляпнула, — я
      чувствую себя настоящей героиней шпионского романа.</p>

    <p>Дух взаимопонимания крякнул под
      грузом такого откровения, но не шлёпнулся на пол и даже удержался н�� лету.</p>

    <p>Разговор складывался так приятно
      и перспективно, что Дима, конечно, не смог промолчать и не испортить тепла и
      уюта следующей репликой:</p>

    <p>— К тому же, ты всё делаешь
      правильно. Я вот, сколько себя помню, всё время творю леший знает
      что просто так — ради шутки, по тупости, от злости. Забил на Универ — очутился в степи. Забил на степь
      —&nbsp;очутился в
      Бедрограде. Забил на Бедроград — очутился в Столице. Рожа
      в шрамах, а мозгов не прибавилось, — он изучил свои довольно некрасивые руки
      так, как будто мозг содержался в них. — А у тебя всё то же веселье, только
      потом не надо за голову хвататься.</p>

    <p>«Диктофон!» — хотела отчаянно
      подумать Бровь, но вместо этого простенала вслух:</p>

    <p>— Диктофон!</p>

    <p>Дима посмотрел на неё круглыми
      глазами и фыркнул:</p>

    <p>— Диктофон, тоже мне. И без него
      всё обойдётся. И вообще, искупишь свою халатность кровью в самом прямом смысле.</p>

    <p>Показавший свой тёмный лик дух
      взаимопонимания злобненько захихикал. Конечно, как бы
      без него Дима умудрился попасть ровнёхонько во вторую тему, о которой Бровь Очень Не Хотела Говорить.</p>

    <p>Вот просить его или не просить
      провести повторный осмотр? Или даже чтобы просто подписал ей справку?</p>

    <p>Кстати, если у него и правда
      какие-то сложности в сфере эротического, хочет ли Бровь, чтобы Дима её <i>на
        самом деле</i> осматривал?</p>

    <p>Куда ни кинь — всюду говнище.</p>

    <p>— Ага, хм,
      кровью. Нда, — Бровь ещё немного пожевала эту реплику
      и сдалась. —&nbsp;Кажется, я не могу.</p>

    <p>Теперь настал Димин черёд
      изображать вежливое удивление.</p>

    <p>До чего же бессмысленны моменты в
      беседах, когда кто-нибудь скажет фразу, и понятно, что за ней должны следовать
      разъяснения, но сказавший всё равно молчит как индокитайская рыба зимбуль и ждёт наводящих вопросов. Это совсем не
      то же
      самое, что эффектные драматические паузы, это просто какой-то нелепый закон
      коммуникации.</p>

    <p>И даже над Бровью он властен.</p>

    <p>Она глубоко вздохнула — не забыв,
      впрочем, вынуть изо рта всё ещё тлеющую самокрутку,
      такую крутую, но такую крепкую.</p>

    <p>— Папа. У меня, как и у него,
      слабое сердце. И он, ну, боится. Написал мне справку о негодности физического
      состояния для подобных процедур.</p>

    <p>У Димы на лице проступила
      внимательность.</p>

    <p>— Правдивую?</p>

    <p>Ну что же он так давит!</p>

    <p>—&nbsp;Не знаю.</p>

    <p>— Ясно.</p>

    <p>А с другой стороны, без наводящих
      вопросов как-то, право слово, неловко.</p>

    <p>— Я думала о том, чтобы попросить
      тебя или Попельдопеля осмотреть меня ещё раз. Мне же правда страшно хочется, хм, искупать кровью. Но, понимаешь…
      папа делает это не из вредности, просто по-другому не умеет. И мне даже не
      столько страшно, что у меня в самом деле проблемы с
      сердцем или что он обидится, если я всё-таки пойду на процедуру, сколько… ох,
      как это объяснить? — Бровь машинально похлопала себя по карманам на предмет сигарет
      (которые намеревалась бросать уже употреблять!) и столкнулась с горьким
      разочарованием. —&nbsp;Может, он прав и мне не стоит. Может, ошибся. Может,
      вообще осознанно соврал. Просто я не хочу это проверять, понимаешь?</p>

    <p>— Не хочешь обнаружить, что
      соврал?</p>

    <p>— Не хочу в принципе задаваться
      этим вопросом. Хочу просто поверить ему и всё. Типа в знак благодарности за
      светлые чувства.</p>

    <p>Бровь никогда не умела говорить о
      серьёзных вещах (а что может быть серьёзней её переживаний по поводу родителя!),
      постоянно скатывалась в хиханьки, и сейчас ей почти
      впервые это показалось каким-то, ну, нечестным, что ли.</p>

    <p>Дима поковырял неровную (потому и
      неровную, что ковыряют тут всякие) краску на оконной раме.</p>

    <p>— И снова —&nbsp;ты крутая,
      Бровь. Я бы вот так не смог. Леший с ней, с процедурой, любопытство заело бы, —
      он изучил отковырянный кусочек и метнул его в пепельницу. — Ну, значит,
      искупишь метафорической кровью, дело найдётся. Ты ж теперь ответственная за
      боевой дух и прочую идеологию.</p>

    <p>Ответственная
      за боевой дух и идеологию знала толк в интонациях. Таким ровным голосом о
      весёлых перспективах будущего не говорят, сейчас точно прозвучит какая-нибудь…</p>

    <p>— И вообще, ты не знаешь, как
      тебе повезло.</p>

    <p>…гадость.</p>

    <p>Гадость?</p>

    <p>— Повезло?</p>

    <p>— Ага, —&nbsp;Дима, кажется, тоже
      чувствовал себя неуютно без наводящих вопросов, но героически продолжил.
      —&nbsp;Большая политика —&nbsp;штука неприятная. Особенно когда не столько
      болит, сколько чешется.</p>

    <p>Афористично и бессмысленно —
      значит, сейчас начнётся какое-то интересное повествование, и уши Брови — вот
      они, наготове.</p>

    <p>— Вся эта затея с созданием
      аллергии на твирь и, соответственно, на чуму мне ведь не сегодня и не вчера в
      голову упала. Просто когда-то давно я оказался на Колошме — это колония для
      политических в степи, если ты вдруг не в курсе. Даже не совсем и по глупости оказался,
      просто мне повезло иметь наиболее порочные связи с тогда-уже-дважды-покойным
      идеологом нашей страны — с Гуанако, короче. Только он, как ты могла заметить,
      на самом деле не умер, а всего лишь валялся на той самой Колошме в тяжёлом
      депрессивном состоянии. Вот заинтересованные личности и решили его подбодрить,
      подарить подарочек в виде меня. В знак доброй воли, чтоб не свихнулся
      окончательно, а ещё — чтобы можно было в случае чего отобрать. Психологически повоздействовать. — В этом месте
      Диме было бы очень уместно
      слегка усмехнуться, но он же всё всегда говорил с ухмылкой от уха до уха, и это
      тоже, никакого у человека чувства меры, ну отклей уже ухмылку-то, а! — И
      знаешь, что я тебе скажу? Быть методом психологического воздействия
      —&nbsp;паршивая работа. Тебе когда-нибудь засовывали в жопу дикобраза?</p>

    <p>Бровь остолбенело
      покачала головой. Она довольно спокойно относилась к животным, но подобного
      обращения никто не заслуживал.</p>

    <p>— Вот и мне не засовывали. Зато
      меня накачали сывороткой, вызывающей аллергию на кровавую твирь, и выпустили в
      поле на работы —&nbsp;политзаключённые в основном собирают траву, трудотерапия
      и подмога фармацевтике типа. Вышло неприятно. Когда
      дополз до изолятора — утешили, утёрли пот и прочие вытекающие из меня
      биологические жидкости, ласково обняли за плечи, отвели в тёмную-тёмную комнату
      и предложили укольчик для облегчения страданий. Когда укольчик подействовал, у
      меня наступил полный паралич без потери чувствительности. В принципе, этим
      могли и ограничиться, лежать неизвестно сколько в
      темноте и без движения тоже то ещё развлечение. Но нельзя же впустую
      расходовать ценную сыворотку! И зачем, когда твири под рукой — целая степь. Налетай,
      рви, режь себе меленько и запихивай, куда захочется. Даже особо глубоких
      надрезов делать не надо. Переносицу вон, считай, просто поцарапали, шрам всё
      равно на всю жизнь.</p>

    <p>Какая затаённая боль, шпионские
      романы — мура! Страшно — это когда её нет, вот правда
      нет, и Дима с улыбкой стаскивает очки —&nbsp;похвастаться ещё раз, и всё так же
      улыбается, и хочется просто дать ему в морду, чтобы
      перестал.</p>

    <p>Впрочем, желание дать в морду может быть обусловлено сходством с Габриэлем
      Евгеньевичем.</p>

    <p>— Лежал я себе в темноте, лежал и
      думал: всё, что нормальные люди знают о пытках, — страшная хрень. Любые
      болезненные ощущения со временем притупляются, в башке
      просто мутится и всё. Но вот если чешется! Леший, я бы тогда за возможность
      пошевелить хоть немного правой рукой позволил эту руку
      потом без анестезии отрезать. А я, между прочим, правша. Чуть не поехал крышей,
      в общем.</p>

    <p>Они давно уже не курили, просто
      сидели на подоконнике и рассматривали всё не желающие исчезать комки сизого
      самокруточного дыма.</p>

    <p>— И что?</p>

    <p>— И ничего. Когда
      заинтересованные личности в очередной раз зашли меня навестить, рассказать о
      том, как я им помогаю, и кокетливо погладить стебельком твири, я спросил,
      какого же лешего было резать мою прекрасную кожу, когда можно просто напихать
      травы в жопу. Заинтересованным личностям очень понравился мой план, а я теперь
      с подозрением отношусь к дикобразам, хоть они тут и ни при чём. И тайно называю
      процедуру, которой ты так удачно избежала, операцией «твирь-в-жопу».</p>

    <p>Бровь посмотрела на Димины
      некрасивые руки. Нет, никакого дрожания или сжатия кулаков, только слегка
      постукивает мизинцем в перстне.</p>

    <p>Вот и
      поди разбери, о чём сейчас спросить — то ли о морально-этических аспектах
      подвергания студентов таким радостям, то ли о том, почему они с Гуанако этот
      самый перстень давеча в карты разыгрывали.</p>

    <p>— И что самое крутое, —&nbsp;как
      ни в чём не бывало продолжил Дима, — если бы хоть
      что-то во всей этой истории сложилось иначе, сдох бы я благополучно от вспышки
      степной чумы 76-го. А так у меня — не иммунитет, конечно, но что-то вроде
      повышенной устойчивости.</p>

    <p>Бровь не ответила.</p>

    <p>—&nbsp;Поэтому я и делал водяную
      чуму из степной. Лекарство простое и экспериментально
      проверенное. На мне.</p>

    <p>На правой руке у Димы болтался
      неказистый браслетик, сплетённый, кажется, из каких-то степных трав. Если он
      так представляет себе украшения, то, право, этот человек безнадёжен.</p>

    <p>— А это что —&nbsp;реликвия? —
      тупо спросила Бровь, тыкая пальцем в браслетик.</p>

    <p>Нельзя рассказывать людям о том,
      как тебя пытали. По крайней мере, девятнадцатилетним увлекающимся девочкам. Надо
      запретить законом. Это преступное действие, не являющееся актом коммуникации,
      потому что ну что тут ответишь-то?</p>

    <p>Дима засмеялся совершенно
      естественным смехом, лишённым какой бы то ни было хриплости или там
      сдавленности. Медик, жил в степи, лечил коров, наткнулся на Вилонский Хуй.
      Более ничем не примечателен.</p>

    <p>— Нет, очередное гениальное
      изобретение. Простое, как коленка. Твирь — не только
      источник радости, направленный в жопу прогрессивного человечества, но ещё и
      сильный стимулятор, особенно бурая. Белая плеть —
      степная трава с острыми шипами. Сплети одно с другим —&nbsp;получишь браслет,
      который может медленно поставлять в организм новые и новые силы. Если,
      например, тебе нужно очень долго не спать, потому что ты бежишь из колонии для
      политзаключённых и хочешь убежать как можно дальше, не падая, — он улыбнулся. —
      Впервые за кучу лет нацепил.</p>

    <p>Дима собирается очень долго не
      спать, потому что студентов на истфаке не меньше полутысячи, а осматривать их,
      раскладывать, лелеять и набивать твирью их страждущие жопы — им втроём с
      Попельдопелем и папой.</p>

    <p>На деле наверняка сыщутся ещё какие-нибудь люди, но так звучит более
      шпионски-эпически-драматически.</p>

    <p>—&nbsp;А это? — на сей раз Бровь
      ткнула пальцем в перстень.</p>

    <p>Пора уже найти ответ на загадку треугольного
      камня!</p>

    <p>—&nbsp;А вот это как раз
      реликвия. Символический знак и знаковый символ наших с Гуанако долгих и
      нелёгких отношений.</p>

    <p>При этом в Порту, в субботу, оба
      выглядели так, как будто впервые в жизни встретились. И разыгрывали перстень в
      карты, Бровь всё помнит!</p>

    <p>Видимо, действительно долгих и
      нелёгких.</p>

    <p>Интересно, если бы Бровь из-за
      кого-то попала аж на Колошму, она бы на этого кого-то
      обиделась? Кажется, что нет, —&nbsp;кто-то же не виноват! — а на самом деле
      есть подозрение, что могло бы быть непросто этому кому-то в глаза заглянуть.
      Плохое же, ну, хочется забыть, а как забудешь, если живое напоминание маячит.</p>

    <p>Что-то из этого, видимо,
      прозвучало-таки вслух, потому что Дима снова рассмеялся.</p>

    <p>— Ну вот
      ты обижаешься на Ройша за то, что попала во всю эту историю?</p>

    <p>Дурацкий
      вопрос какой.</p>

    <p>— Нет, конечно, со мной же
      ничего… такого… ну, как с тобой —&nbsp;не случилось, — Бровь тут же исполнилась
      подозрений. — А что, должно случиться? Мне грозит страшная опасность?</p>

    <p>— Да нет, конечно, — махнул Дима
      некрасивой рукой, —&nbsp;весь Университет во главе с гэбней напряжённо следит
      за твоей судьбой и не жаждет прекращения этого удовольствия. Просто, знаешь,
      одно дело — ввязаться в нечто героически-политическое, потому что ты самый
      умный, или самый смелый, или ещё какой-нибудь исключительный. И совсем другое —
      просто постольку-поскольку. Носишь гуанаковский
      перстень. Распиваешь чаи с Ройшем. Выходит,
      ты сам —&nbsp;не ценность, а так, инструмент.</p>

    <p>—&nbsp;Психологического
      воздействия, —&nbsp;печально закончила Бровь.</p>

    <p>Дух взаимопонимания зашёлся в
      экстатическом припадке от такого родства судеб.</p>

    <p>Дима, наверное, всё-таки немного псих. Он, кажется, и сам не понимает, насколько печальные
      вещи говорит. Может, у него не потому такая жизнерадостная рожа,
      что он пытается отрицать свои травмы, а потому, что он просто идиот?</p>

    <p>Ага, только от интеллектуального
      уровня говорящего суровая правда жизни не меняется. Ну
      какая Бровь главная героиня шпионского романа? Были бы у Ройша чуть иные
      предпочтения — не сидело бы сейчас в медфаковской
      курилке никакой Брови. Сидел бы кто-то другой, и с кем-то другим точно так же
      делился бы Дима своей нелёгкой биографией. И кто-то другой, небось,
      нашёл бы, что на это сказать (а не спрашивал бы про произвольные аксессуары,
      леший, это ж просто младший отряд какой-то), не кашлял бы от портовых
      самокруток и нажал бы на гадском диктофоне правильную кнопку.</p>

    <p>А с другой стороны — ну вот такая
      и есть главная героиня. Попавшая в историю случайно,
напутавшая чего-то в самом начале, неловкая и маленькая — но к концу-то все
понимают, что свои благодарности она заслужила, потому что умная, красивая,
крутая, ошибки исправила и ещё своего напредлагала, а
прямо перед словом «КОНЕЦ» притащила Ройшу бутылку водки, и он не скукожился в
порицании, а покачал головой и пустил-таки её обратно.</p>

    <p>Ибо в такие истории случайные
      люди не попадают, если только они не психи вроде Димы.
      Случайные психи — это тоже архетип, существующий в
      рамках жанра. А настоящих, дурацких, жизненных
      случайностей в шпионских романах не бывает.</p>

    <p>Не бывает и точка.</p>

    <p>Какая жалость, что Бровь ещё так
      далека от последней главы, ввиду чего ни просветления нету,
      ни Ройша нету, ни с диктофонной записью и её важностью ничего не понятно.</p>

    <p>И дался же ей Ройш, а. Ведь до
      всех этих конспиративных историй и не был особо нужен вроде. Так оно и выходит
      —&nbsp;поживёшь с кем-нибудь вместе пару месяцев, а потом уже не вытрясешь из
      головы, сколько ни пытайся.</p>

    <p>Жертва обстоятельств!</p>

    <p>— Ройш не хочет со мной
      разговаривать, —&nbsp;пожаловалась Бровь в рамках обсуждения печальных
      биографий, — так что я теперь в большой политике сама по себе.</p>

    <p>— Да ну? —&nbsp;с готовностью
      отозвался Дима. — Ты же в курсе, что если он с кем-то не разговаривает, это ещё
      не значит, что не хочет?</p>

    <p>— Я в курсе, что когда на мои
      призывные взоры не реагируют, это, наверное, что-то значит.</p>

    <p>— Как будто законы человеческого
      общения распространяются на Ройша, — хмыкнул Дима и окинул Бровь
      врачебно-внимательным взглядом. — Ты его любишь?</p>

    <p>Дух взаимопонимания выпучил
      глаза, пробормотал что-то на предмет того, что на подобное он не подписывался,
      и всосался под дверь.</p>

    <p>— Н-н-нет, — медленно промямлила
      Бровь, — так я бы это не назвала.</p>

    <p>А как бы назвала, дура?</p>

    <p>Как-нибудь бы назвала, если бы
      это теперь имело хоть какое-то значение.</p>

    <p>— Вот и хорошо.</p>

    <p>У Димы были сросшиеся брови (нет <i>ровным счётом</i> никакого фрайдизма в
      том,
      что Бровь всё время обращает внимание именно на них), что вообще-то беее, но
      ему придавало постоянный вид какой-то отчаянной и дурацкой
      решительности. Особенно в сочетании с вечной ухмылкой.</p>

    <p>Как есть из дурки сбежал.</p>

    <p>И почему у Брови возникло ощущение,
      что то, что воспоследует, Дима будет искренне считать оптимистичным?</p>

    <p>— Ройш — странный человек, ага? —
      он лирически запрокинул голову. — Не умеет чувствовать, вообще. Это, наверное,
      даже круче, чем лежать в тёмной-тёмной комнате: смотреть на других и не знать,
      радуются они сейчас, печалятся, злятся или ещё чего. Поэтому — и он сам это
      почти понимает — ему нужна помощь. Надстройка. Человек, который чувствовал бы
      за него.</p>

    <p>Глубоко мыслит, а!</p>

    <p>Сарказм был бы уместнее, если бы
      Бровь не подозревала, что всё так и есть, просто ей не хватило речевых талантов
      это сформулировать.</p>

    <p>— Не полюбит он никогда и никого
      —&nbsp;не умеет, и бессмысленно от него этого ждать. Но если бы нашёлся
      человек, способный всё равно быть рядом и переводить на ройшевский
      язык то, что делают другие люди, связь с этим человеком он ценил бы больше… больше… ну, не больше своих бумажек,
      но, короче, ценил бы.</p>

    <p>— Поэтому он и хранил твои шмотки столько лет?</p>

    <p>Дима улыбнулся.</p>

    <p>— Знаешь, а сходи к нему, раз уж
      ты всё равно без твири-в-жопе.</p>

    <p>Инновационное предложение.</p>

    <p>— Поскольку сегодня никто не
      учится и завтра не собирается, Ройш засел у себя дома, аки в бастионе. У него
      там нужные канцелярские принадлежности и любимые носки под боком, оттуда и
      работает. Или, что даже более вероятно, сидит со скорбной рожей
      и оплакивает судьбы Университета. Сходи, сходи, поднимешь боевой дух.</p>

    <p>— Так он меня и пустил,
      —&nbsp;недоверчиво буркнула Бровь.</p>

    <p>Если с тобой откровенно не
      разговаривают, вламываться прямо на порог — это как-то, ну, невежливо.</p>

    <p>— Пустит. Если хочешь, можем на
      что-нибудь поспорить.</p>

    <p>Бровь смерила Диму изучающим
      взором. Ну что с него можно взять?</p>

    <p>— На галстук, — наконец решила
      она.</p>

    <p>Дима ответил ей взглядом, которым
      рассматривают достойного противника.</p>

    <p>— Ты ни перед чем не
      остановишься, да?</p>

    <p>&nbsp;</p>

    <p>Попрощавшись с Димой и
      старательно избегнув повторной встречи с папой, Бровь улизнула
      с медфака.</p>

    <p>Нет-нет, сама бы она ни за что не
      пошла к Ройшу домой, но одно дело —&nbsp;её личное мнение, а другое —
      ответственное задание по поднятию боевого духа в рядах. Тут, ясно, надо
      оставить в стороне страхи и двигать телом. Правда, предварительно имело смысл
      заглянуть на кафедру за сумкой: вдруг Ройш разгневается и исключит её из
      Университета? А в сумке не только сигареты с зажигалкой, но и любимый томик
      Толстоевского, и листовка с экспозиции личных артефактов древнего американского
      царя Итохлепа (на ней записан один важный телефон), и
      брелочек в виде хищной морды афстралийского
      козла на молнии, и (давно уже измятая) фотка Габриэля Евгеньевича для Галки.</p>

    <p>Кафедра встретила Бровь радостной
      пустотой и незапертостью (как разумно было оставить
      здесь свои ценные вещи!). Ларий Валерьевич, положим, на медфаке, а вот где,
      например, те же Охрович и Краснокаменный? И дверь в завкафский кабинет,
      разумеется, заперта.</p>

    <p>Хорошо хоть не нужно бегать и
      искать кого-нибудь, чтобы отчитаться о своём лишённом
      твири статусе, ибо Дима взялся сам сообщить Максиму (Аркадьевичу), «и вообще
      списки уложенных студентов есть».</p>

    <p>Если пустой факультет выглядел
      болезненно, то пустая кафедра — скорее штабом сверхсекретной операции, откуда
      все разбежались по сверхсекретным заданиям. Собственно, им она и являлась.</p>

    <p>И Бровь сейчас разбежится, у неё
      тоже задание.</p>

    <p>И это <i>так круто</i>.</p>

    <p>Чучело Набедренных
      свисало с потолка и всем своим видом говорило, что нужно не твирь в жопу
      совать, а деревья переворачивать. Государственная идеология, все дела. Корни и
      ветви, верхи и низы должны стать одинаковыми.</p>

    <p>Наверное, при жизни у этого
      человека были <i>очень большие проблемы</i>,
      раз ему такое вообще в голову пришло.</p>

    <p>Нет, ну серьёзно, ну вот как?</p>

    <p>В поисках ответа Бровь задумчиво
      стащила с полки первый том ПСС Гуанако, упихнутый туда, между прочим, так
      плотно, что сразу ясно — для красоты поставили и ни разу в руки не брали.
      Может, в самом деле почитать? Вон
      какая обложка радостная, раскрашенная в цвета локальной катастрофы на заводе
      лакокрасочных изделий, наверняка Охрович и Краснокаменный оформляли. И фотка
      самого Гуанако действительно имеется. Экий он тут
      бледненький, в больших круглых очках, свитерке
      каком-то, смотрит в сторону. От нынешнего — только
      улыбка, простая такая и очень приветливая.</p>

    <p>Он вообще приятный тип. Бровь с
      ним столкнулась на выходе с медфака, поздоровалась — ответил, хотя на роже было написано, что не опознал. Ну, в
      общем, оно и
      понятно, он же типа портовый, вот уже почти сутки как ездит взад-вперёд на
      бесконечных грузовиках — подвозит всякое там оборудование, лекарства
      (болеутоляющие, например, ага), ту же твирь. И при этом бодр
      и весел без всяких браслетиков-батареек. И с серьгой в ухе.</p>

    <p>Вот бы с кем по пивасику.</p>

    <p>Бровь ещё немного повертела книгу
      в руках, пытаясь понять, имеет она право на мелкое кафедральное хищение или
      нет.</p>

    <p>Составитель —&nbsp;Онегин Г. Е.</p>

    <p>Наверное, хлопнулся в обморок,
      когда увидел цвета обложек.</p>

    <p>Наверное, у него с Гуанако тоже
      какие-нибудь сложные отношения —&nbsp;всякого встречного-поперечного
      просто так в Порту не раздевают. То есть раздевают, конечно, но это не круто. Круто
      — представить, как Габриэль Евгеньевич рыдал в рукопись, когда Гуанако пропал
      (умер, сел на Колошму, чего там с ним, в общем, было).</p>

    <p>— Положите немедленно!</p>

    <p>Бровь эффектно подскочила на
      полтора метра и выронила книгу. Со спины к ней подкрался Максим (Аркадьевич) —
      строгий, страшный и гневный Максим (Аркадьевич).</p>

    <p>Кажется, теперь она понимает,
      почему Дима всегда оказывается спиной к человеку, который к нему обращается.
      Чем меньше смотришь на источник бури, тем лучше.</p>

    <p>— П-простите, — пропищала Бровь,
      поднимая книгу.</p>

    <p>Грохот, впрочем, продолжился.</p>

    <p>— У вас в руках кафедральная
      собственность, не предназначенная для студентов. Вы спрашивали разрешения её
      брать? Вы сами будете возмещать, если попортите? Или
      предлагаете кафедре обойтись неполным собранием сочинений?</p>

    <p>— Нет, — упрямый Гуанако,
      разумеется, вовсе не намеревался запихиваться на место без боя, — я только
      посмотреть…</p>

    <p>— Заведите себе собственное и смотрите, — отрезал Максим (Аркадьевич).</p>

    <p>Он был в своём вечном коричневом
      пиджаке и с такой же коричневой рожей. В серых глазах
      обитали гнев и усталость — совсем не такие, как бывают
      от долгого недосыпа. Возможно, такие, как бывают от долгого заполнения
      формуляров и хождения по фалангам.</p>

    <p>Особенно от хождения по фалангам
      без важного доказательства — диктофонной записи, например.</p>

    <p>Здравствуй, комплекс вины,
      кажется, ты окончательно сформировался.</p>

    <p>Как ответственная за боевой дух,
      Бровь, наверное, должна была сказать что-нибудь утешительное. С другой стороны,
      сегодня она отвечает за боевой дух Ройша (и ещё не факт, что ответит), а у
      Максима (Аркадьевича) есть Габриэль Евгеньевич. Вот где он шляется
      в такой важный момент, когда жизнь невинной студентки истфака находится под
      угрозой?</p>

    <p>Максим (Аркадьевич) выглядел
      смущённо и немного растерянно — примерно как когда съездил своему любимому
      завкафу по лицу. Кажется, ему тоже нередко становится стыдно минуты на пол
      раньше, чем следовало бы.</p>

    <p>Упрямый Гуанако наконец-то со
      скрипом занял подобающее место.</p>

    <p>Повод всё-таки попытаться
      провести идеологическую работу.</p>

    <p>— Простите, —&nbsp;нетвёрдым
      голосом повторила Бровь, — но я подумала… ну, сейчас же никто не учится, а я
      быстро читаю. Решила повысить свой интеллектуальный уровень.</p>

    <p>— Вы подумали, — рокотнул Максим (Аркадьевич), —&nbsp;вы подумали! Вы
      подумали, что лучше администрации кафедры знаете, как распоряжаться её
      ресурсами?</p>

    <p>— Нет, конечно, — нетвёрдый голос
      скатился обратно в писк.</p>

    <p>— Вот именно. Чрезвычайные
      обстоятельства — не повод решать за других
      и лезть не в своё дело.</p>

    <p>Брови только кажется, или это
      высказывание относилось не к ней?</p>

    <p>Жаль, что сейчас Максим
      (Аркадьевич) явно не станет распространяться. Мог бы случиться весьма
      выразительный монолог. Но нет, этот тип и так не слишком разговорчив, а нынче и
      вообще похож на шаровую молнию. Очень медленную и очень тёмную шаровую молнию.</p>

    <p>А ведь ей к нему в квартиру
      возвращаться (если, конечно, Ройш не предложит вечную любовь и прочие мирские
      блага, причём прямо на пороге своего жилища).</p>

    <p>Когда Бровь впервые ехала к
      Максиму (Аркадьевичу), она не ожидала, конечно, пачек трусов на подоконнике и
      портрета Габриэля Евгеньевича в рамочке —&nbsp;скорее эдакого
      жилого запустения, прикрытых тряпочками стульев и пяти тарелок на сушке. Но на
      деле квартира Максима (Аркадьевича) ещё сильнее кафедры напоминала тайный штаб:
      звукоизоляция (толстый поролон тошнотного бежевого
      цвета) местами приколочена прямо поверх обоев, телефон воткнут в стоящую тут
      же, на столе, коробку какого-то устройства (типа от прослушивания), шторы —
      просто куски плотной тёмно-серой ткани. Натурально бункер, а не жилое
      помещение.</p>

    <p>Голова гэбни должен уметь всё,
      да?</p>

    <p>Стрёмно это. И очень захотелось
      ещё раз извиниться — не за книгу, а за что-то другое. За то, что Максим
      (Аркадьевич) был когда-то вынужден жить в квартире с пуленепробиваемыми
      стёклами. За то, что Габриэль Евгеньевич постоянно устраивает ему сцены (это
      страшная тайна, но все же знают). За то, что Бровь намеревается ещё на какое-то
      время оккупировать ту самую пуленепробиваемую квартиру, так что в случае совсем
      драматичной сцены Максиму (Аркадьевичу) будет некуда податься. За то, что он
      так устало выглядит и не может найти слов извинения
      или хотя бы признания в том, что просто забегался и сорвался. За проклятый
      диктофон, в конце концов.</p>

    <p>Максим (Аркадьевич) взял с
      секретарского стола бутылку с какой-то явной гадостью и щедро отпил.</p>

    <p>Вместо извинений или
      дружественных слов Бровь подхватила сумку и безмолвно выскользнула за дверь.</p>

    <p>&nbsp;</p>

    <p>Перед домом Ройша она долго
      топталась. Обошла пару раз по кругу (выискал же как-то серую коробку даже в
      жёлто-кремовых новых районах Бедрограда), почитала уведомления на двери (что
      было не так просто, ибо уже темнело), обломила с ближайшего куста веточку.
      Только поймав на себе взгляд сидящего во дворе на лавочке и явно восхищённого её
      хождениями парня, Бровь решилась.</p>

    <p>Она бы вот на месте этого парня
      подошла и поинтересовалась, что незадачливая посетительница ищет. Так, к слову.</p>

    <p>Ройш жил на третьем этаже. Перед
      звонком следовало пригладить волосы, поправить широкий ворот свитера, пригладить
      волосы, убедиться в свежести своего дыхания (мало ли!), пригладить волосы
      —&nbsp;так, всё, она ответственный за боевой дух или кто?</p>

    <p>Дверь открылась не сразу. Ройш, в
      чёрном домашнем пиджаке (отличающемся от парадного, в общем-то, ничем) и
      трогательных клетчатых тапочках хмуро изучил Бровь и сделал приглашающий жест.</p>

    <p>Ну вот, не видать Брови галстука.</p>

    <p>С другой стороны, разве он был ей
      нужен?</p>

    <p>С третьей — зато никто больше не
      носил бы <i>это</i> с розовой рубашкой.</p>

    <p>С четвёртой — надо уже признаться
      себе в том, что она мнётся на пороге, краснеет и смущается. И Ройш ей не
      поможет. Не из зловредности, а потому, что, как верно (хоть и чересчур
      патетически) заметил Дима, не догадывается, что надо.</p>

    <p>Бровь вздохнула, решительно
      шагнула в коридор, решительно скинула куртку, решительно расшнуровала ботинки,
      решительно влезла ногами в гостевые тапки и решительно не знала, что дальше
      делать.</p>

    <p>—&nbsp;Пожалуйста, вымойте руки и
      проходите в гостиную, —&nbsp;церемонно предложил Ройш и удалился за чаем.</p>

    <p>Если бы Бровь никогда раньше не
      бывала в этом доме, она решила бы, что здесь царил идеальный порядок. Но нет:
      зубная щётка, конечно, в стаканчике, а вот тюбик с пастой просто на краю
      раковины; нижний край перекинутого через вешалку полотенца не параллелен полу;
      в гостиной на столе ровной стопкой лежат бумаги, которым место в кабинете, и —
      о святотатство! —&nbsp;печатная машинка там же.</p>

    <p>Ройш тоже не спит ночами.</p>

    <p>— Печенье, к сожалению,
      закончилось, есть только это, — Ройш бережно поставил прямо рядом с печатной
      машинкой конфетницу с чем-то мармеладным и занялся
      разлитием чая. — Или вы какой-нибудь более серьёзной еды хотите?</p>

    <p>От этого вопроса внутренности
      Брови почему-то с визгом бросились друг к другу в объятия. Ройш вовсе не был
      гостеприимным человеком, и вообще: одно дело — ритуальный чай любому зашедшему,
      другое — еда.</p>

    <p>Еда — это, ну.</p>

    <p>Как-то интимно.</p>

    <p>Вообще-то Бровь целый день ничего
      во рту не держала, но вот так с порога соглашаться на ужин было бы слишком.
      Пришлось помотать головой.</p>

    <p>Ройш кивнул и, не прикасаясь к
      своей чашке, уселся напротив.</p>

    <p>— Вас сегодня не было в
      Университете, —&nbsp;проницательно заметила Бровь. Ну а что, надо же с чего-то
      начать беседу.</p>

    <p>— Не вижу смысла туда ходить в
      нынешней ситуации.</p>

    <p>— Ага, истфак как вымер… даже на кафедре никого нет. Все студенты хотят в
      экспедицию. Это же так здорово, правда? Ну, в смысле —
      получится много лекарства…</p>

    <p>Ройш откинулся и сложил руки
      фирменным домиком (шалашом для карликовой пихтской лошади — как минимум одну
      приличную шутку Гошка-Александр породил), палец к пальцу.</p>

    <p>— Ума не приложу, кто мог вам
      выболтать про лекарство, — насупился Ройш.</p>

    <p>Когда ему что-то не нравилось (то
      есть довольно часто), он всегда выглядел немного обиженным, словно не
      обстоятельства так сложились, а кто-то гадит лично
      ему.</p>

    <p>— Намекаете на то, что подобные
      вещи вслух не обсуждают?</p>

    <p>Ройш только покачал своей
      идеально прилизанной головой. Гладко выбрит, застёгнут на все пуговицы
      —&nbsp;он же весь день проторчал дома, леший возьми! Как так можно?</p>

    <p>От живого человека — только
      тапочки.</p>

    <p>— Вы ведь на истфак не только
      лекции читать ходите, —&nbsp;предприняла Бровь ещё одну попытку мирного
      диалога. — Ну то есть я так думаю. Думала. Максим
      Аркадьевич вроде бы ведёт переговоры с фалангами. Вы ему нужны. Наверное.</p>

    <p>— Односторонние высказывания
      обычно не называются «переговорами», — бесстрастно проговорил Ройш. — Как было
      сказано выше, я не вижу необходимости своего личного участия в нынешней
      ситуации.</p>

    <p>Он настолько напоминал папу (по
      содержанию, не по форме, конечно), что Бровь чуть было
      не замахала руками, дабы отогнать призрак Фрайда.</p>

    <p>— Да, но… Максим Аркадьевич выглядит
      довольно устало. Я ничего не знаю — может, переговоры… общение с фалангами идёт
      хуже, чем планировалось. А вы многое умеете. Если бы вы как-то помогли…</p>

    <p>— Бровь, — прервал её Ройш, еле
      заметно вжимая голову в плечи, — подобные рассуждения от человека, незнакомого
      с принципами работы государственного аппарата, звучат наивно и по-дилетантски.
      Фаланги не всегда реагируют сразу и не всегда уведомляют о своих действиях, но
      всегда мгновенно изучают переданную им информацию. Информация была передана ещё
      вечером в субботу; если до сих пор реакции не воспоследовало, значит, таково их
      решение.</p>

    <p>Он прав: наивно и по-дилетантски,
      и вообще Бровь это значение слова «фаланги» два дня назад впервые узнала. Он
      прав, как и папа, который говорит, что Университет может ошибаться.</p>

    <p>Только разве из этого следует,
      что нужно сесть, ничего не делать, сложить пальцы домиком и принять их решение?</p>

    <p>— Их решение — бросить нас на
      произвол судьбы? С эпидемией чумы на руках?</p>

    <p>Ройш позволил себе досадливый
      вздох.</p>

    <p>—&nbsp;Как и многие другие, вы
      забываете, что мы имеем дело с <i>двумя
        разными</i> информационными прецедентами. Конфликт гэбен и попытка
      Бедроградской гэбни очернить репутацию Университета — только первый из них, и
      только о нём осведомлены фаланги. Этот прецедент не является
      ни исключительным, ни опасным для населения — следовательно, не требует
      обязательного применения каких бы то ни было санкций; необходимость оных
      санкций в данном случае оказывается сугубо решением фаланги, работающего по
      данному вопросу. Что же касается возможной эпидемии чумы — это другой
      прецедент, о котором фалангам неизвестно. Соответственно, они не учитывают этот
      фактор.</p>

    <p>Тогда, наверное, стоит им
      рассказать?</p>

    <p>Хотя нет, не стоит. Бровь
      действительно не очень понимала в политике и вообще, но, кажется, это как раз
      то, чего Университет пытался избежать и ради чего Дима приехал из Столицы. Если
      об эпидемии станет известно, получится, что Университет всё-таки некомпетентен,
      не уследил за своими канализациями, а доказать, что вирус подбросили как раз
      для этого, невозможно, и Бедроградская гэбня победила.</p>

    <p>По-дурацки получается. Об
      эпидемии рассказывать нельзя, но без рассказа об эпидемии (и, соответственно,
      серьёзности намерений Бедроградской гэбни) фаланги до решения не снизойдут. Ну
      и какие же это два разных информационных прецедента, если они настолько друг на
      друге завязаны?</p>

    <p>Нет, так быть не может. А даже
      если и не эпидемия, а один дом —&nbsp;ну и что? Всё равно кто-нибудь мог бы
      умереть. Всё равно это явные гадство и подстава, и
      есть же свидетели (вот она, Бровь, возле печатной машинки сидит), есть
      доказательства, не могут же фаланги это просто проигнорировать!</p>

    <p>Так же не бывает.</p>

    <p>— Вы сами сказали, что фаланги не
      всегда уведомляют о своих действиях. Может, они прямо сейчас везут
      Бедроградскую гэбню на Колошму, а нам не говорят, чтобы мы ещё немного
      помучились.</p>

    <p>Ройш снисходительно приподнял
      брови (опять они!).</p>

    <p>—&nbsp;В таком случае моё или чьё
      бы то ни было ещё дальнейшее участие в этом вопросе тем более излишне, не
      находите?</p>

    <p>Дома у него всегда было идеально
      чисто, но почему-то смутно пахло пылью. Наверное, от самого Ройша —&nbsp;в свои
      неполные тридцать он был тем самым сухим и высоким человеком-без-возраста,
      которому в конце полагается выйти на сцену с коронным
      «я же говорил».</p>

    <p>И всё-таки градус его пессимизма
      слишком высок для простых смертных.</p>

    <p>Знает ли он, о чём говорит? Да,
      знает.</p>

    <p>Делает ли он взвешенные и
      непредвзятые выводы относительно перспектив ситуации? Да, щас.</p>

    <p>— По всей видимости, вас
      интересует моё частное мнение относительно происходящего. Оно таково:
      существует некоторая вероятность того, что фаланги рассмотрели запрос
      Университетской гэбни и приняли меры. Вероятность эта ничтожно мала. В случае
      отсутствия прямой угрозы населению, о которой, напоминаю, фаланги не знают, их
      интерес к тем или иным запросам основывается, как правило, на… эффектности
      оных, — Ройш всё-таки снизошёл до чая, порушив домик из пальцев. —&nbsp;Как,
      полагаю, известно любому госслужащему, институт фаланг основал мой дед, хэр
      Ройш. Его рука прослеживается не только в существующей документации и служебных
      инструкциях, но и в системе ценностей, которую фаланги исповедуют. Безопасность
      граждан или соблюдение справедливости в данной системе находится на менее
      высокой позиции, чем поощрение изобретательности.</p>

    <p>Рука хэра Ройша.</p>

    <p>Фаланги.</p>

    <p>Фирменный жест Ройшей.</p>

    <p>Фаланги.</p>

    <p>Пальцы хэра Ройша.</p>

    <p>Фаланги!</p>

    <p>— Ловкий вор нравится им больше,
      чем тот, кого он обокрал?</p>

    <p>— Именно. И именно ловкий вор,
      если он достаточно эффектен, получит государственную поддержку или как минимум
      не понесёт наказания. Университет достаточно эффектен не был.</p>

    <p>— Но мы же… заранее узнали, что
      планирует Бедроградская гэбня, подсунули им свой вирус, спровоцировали их
      действовать в правильный момент, завладели пробиркой с продуктами разложения
      чумы прямо из их рук, даже записали изображение младших служащих в вашем подвале!
      Неужели этого мало?</p>

    <p>— Немало, но, по всей видимости, для
      должного эффекта чего-то не хватило.</p>

    <p>Проклятый, гнусный,
      гадский, ненавистный диктофон!!</p>

    <p>Если бы у Брови была возможность
      отмотать время назад, она бы позволила отрезать Димину правую руку без
      анестезии, лишь бы не перепутать проклятые, гнусные,
      гадские, ненавистные кнопки!</p>

    <p>А он ведь правша.</p>

    <p>— Записи нашего разговора с
      Гошкой?</p>

    <p>Сердце работало медленно и тяжко,
      как молотобоец с кувалдой.</p>

    <p>— Да.</p>

    <p>— То есть это я виновата в том,
      что фаланги не реагируют?</p>

    <p>Если он сейчас ответит в духе
      того, что нет, она же маленькая (юная и незамутнённая, с горящими глазами), на
      неё особо не надеялись и никто особо не винит, Бровь
      умрёт вот просто на месте.</p>

    <p>Допустить мелкий промах куда
      гаже, чем совершить кошмарную ошибку.</p>

    <p>— Вы виноваты, но не больше
      других. Вся задумка спровоцировать Бедроградскую гэбню была изначально
      рискованной, и не все её детали вам известны.</p>

    <p>Ага, например — зачем это вообще
      было надо? Если узнали заранее (кстати, как узнали-то?) — могли же, наверное,
      просто опечатать подвалы, или вскрыть переустройство канализаций, или ещё
      что-нибудь эдакое. И почему Университет был так уверен
      в том, что заразят только один дом, почему сразу не запасся лекарством?</p>

    <p>И не ввязалась ли Бровь <i>на самом деле</i> во что-то, чего не
      понимает и что могло быть, ну, ошибкой?</p>

    <p>— Вы сами, например, говорите:
      «спровоцировали действовать в правильный момент» —&nbsp;а чем он правильный?</p>

    <p>Шевелитесь, мозги, уходи,
      ассоциация с экзаменом.</p>

    <p>— Ну, у нас был вирус от Димы и
      сам Дима с хоть каким-то количеством лекарства, техника в подвале… и потом,
      надо же было что-то сделать до того, как Бедроградская гэбня отыскала бы
      другой, неизвестный нам вирус.</p>

    <p>— Всё это верно, но не описывает
      ситуацию полностью. Момент был важен ещё и тем, что в любой другой провокации
      бы не получилось в принципе. В субботу голова Бедроградской гэбни,
      ответственный за медицинские вопросы, отсутствовал. Если бы он был в городе,
      Гошка, несомненно, предварительно бы с ним проконсультировался, и у них была бы
      возможность задуматься о том, какие ещё мотивы могут быть у Брованны Шухер. В
      конце концов, одним из пунктов плана Бедроградской гэбни являлось обвинение вас
      в чрезмерной близости к шестому уровню доступа, а Университетской гэбни,
      соответственно, —&nbsp;в попустительстве гражданским. Не нужно обладать
      исключительными интеллектуальными способностями, чтобы предположить, что это может быть так <i>в
действительности</i> и что вы можете осознанно действовать в интересах
      Университета. Вся провокация должна была провалиться и сработала только за счёт
      своей скорости, неожиданности и неполноты состава Бедроградской гэбни. На фоне
      такой удачи отсутствие диктофонной записи — невеликая ложка дёгтя.</p>

    <p>Но всё равно известно, что, как,
      сколько раз и с какой бочкой она делает.</p>

    <p>Зато, зато, зато плакат «ЭПИЧЕСКАЯ
      ИСТОРИЯ О ТОМ, КАК ОДНА ОТДЕЛЬНО ВЗЯТАЯ МАЛЕНЬКАЯ БРОВЬ УДЕЛАЛА
      ГОЛОВУ БЕДРОГРАДСКОЙ ГЭБНИ ТОЛЬКО ЗА СЧЁТ ЛИЧНОЙ ХАРИЗМЫ, А ОН ПРОТУПИЛ И
      НАКОСЯЧИЛ, НЕ ПОДУМАВ» так и стоит перед глазами!</p>

    <p>— А что, по-вашему, следовало
      сделать, когда Университет узнал о планах Бедроградской гэбни?</p>

    <p>Ройш снова насупился.</p>

    <p>— Я не люблю умозрительных бесед
      и не имею ни права, ни желания об этом рассуждать. Решения о действиях Университетской гэбни принимает
      Университетская гэбня, в
      которую я не вхожу и не намереваюсь входить.</p>

    <p>Ну точно
      как папа.</p>

    <p>Политика самоустранения — это
      очень весело и круто, пока она наперекор и для дополнительного пересечения
      местности при беге с препятствиями, но не тогда же, когда это мешает делам.</p>

    <p>Ройш не как папа в другом — ему это в лоб не
      скажешь. Сложил себе пальцы — и всё как с козла вода.</p>

    <p>Бровь яростно вгрызлась в
      мармеладину.</p>

    <p>— По-моему, это вопрос желания.
      Был бы у вас второй уровень доступа — было бы право и рассуждать, и решать.</p>

    <p>— Да, но у меня нет второго
      уровня доступа и нет возможности его получить.</p>

    <p>Ну уж
      нет. Брови было слишком трудно поверить в то, что второй уровень вообще может
      существовать не на словах, чтобы теперь отступаться.</p>

    <p>— Константин Константьевич, я
      знаю ваши принципы и вполне их уважаю, но вы — наследник Революции. Ваш дед основал
      институт фаланг, сами сказали. Второй уровень — это очень высоко и, наверное,
      очень сложно, но если бы вы поступились немного…</p>

    <p>—&nbsp;Вы снова делаете выводы
      исходя из неполного знания ситуации.</p>

    <p>Ага, и ещё прямо наезжает. Прямо
      наезжает <i>на Ройша</i>.</p>

    <p>Даже главной героине шпионского
      романа такое непозволительно.</p>

    <p>— Запрос на получение второго
      уровня доступа можно подать всего единожды.</p>

    <p>Про некоторых людей говорят, что
      они как аршин проглотили. Ройшу, с учётом его роста, явно пришлось глотать
      корабельную мачту. Из-за этого он всегда держал спину неестественно прямо.</p>

    <p>Из-за этого ему было довольно
      неудобно спать на диване все те месяцы, что Бровь (для конспирации, только для
      конспирации!) жила в этой квартире.</p>

    <p>Но спать с ней в одной комнате (какое там в одной постели) он сухо отказался. Это было
      обидно, но обидно не на Ройша, а на то, что ему никак не втолковать, что он бы
      Бровь ничуть не стеснил, что она только рада была бы; что недаром же у него в
      спальне так и стоят две кровати, это же явно знак!</p>

    <p>Но нет, корчился на диване, и
      даже тактика «исчезать на ночные попойки» не помогла.</p>

    <p>— То есть вы подали запрос и вам
      отказали?</p>

    <p>— Нет. Я подал запрос, и его
      удовлетворили.</p>

    <p>Бровь недоумённо уставилась на
      самодовольно зашевелившиеся ройшевские пальцы. Если
      он так не любит своего деда, почему всё равно складывает пальцы домиком? Или
      эта страсть столь пагубна, что от неё не спасают никакие принципы?</p>

    <p>Но вопрос, конечно, не в этом.</p>

    <p><i>Так у вас всё-таки есть второй уровень доступа?!</i></p>

    <p>— Университетская гэбня была
      основана Хикеракли чуть меньше девяти лет назад, осенью 1874-го. Всего через
      полгода, весной 1875-го, начались конфликты с Бедроградской гэбней, вылившиеся
      в заключение одного из студентов, похищение представителя преподавательского
      состава и другие атаки на Университет. Тогда же стало известно, что Сергей
      Корнеевич Гуанако, преподаватель с кафедры истории науки и техники, расстрелян
      на Колошме за убийство одного из голов тамошней гэбни.</p>

    <p>Страсти-то какие. Интересно,
      Гуанако ведёт реестр своих смертей?</p>

    <p>— Через год, весной 1876-го
      некоторым лицам —&nbsp;в том числе мне — случайным образом стало известно, что
      обвинение в убийстве было ложным, а расстрел — фальсификацией, призванной
      окончательно закрыть вопрос так называемого «развала Колошмы». Поскольку
      изначальное обвинение, под предлогом которого Сергея Корнеевича посадили в
      колонию, также было сомнительным, возникло предположение, что его возможно
      реабилитировать. Примерно в то же время произошло очередное столкновение
      Бедроградской и Университетской гэбни; возникло подозрение, что один из голов
      первой неофициально добивается от считающегося расстрелянным Сергея Корнеевича
      содействия в тогдашнем противостоянии гэбен. Это стало окончательным и
      несомненным аргументом в пользу необходимости попытки реабилитации.</p>

    <p><i>«Вот заинтересованные личности и решили его подбодрить, подарить
      подарочек в виде меня. В знак доброй воли, чтоб не свихнулся окончательно, а
      ещё — чтобы можно было в случае чего отобрать. Психологически повоздействовать».</i></p>

    <p>Отобрать, сперва
      напихав твири в известные места. Ведь верно же, да?</p>

    <p>Один, но свой, рядом, в уютном якобы
      загробном мире Колошмы — или все, но где-то там, в Бедрограде, в далёких
      политических конфликтах. Старо, и скучно, и всё равно страшно — и как же они
      продолжают жить и улыбаться-то? Ведь и в байку же не превратишь, наверное, это
      же всё кошмарные тайны леший знает каких уровней доступа (впрочем, не то чтобы
      Дима следовал этому мудрому завету).</p>

    <p>Ройш же бесстрастно продолжал:</p>

    <p>— Но реабилитация Сергея
      Корнеевича силами Университетской гэбни была невозможна: лица шестого уровня
      доступа попросту не имели законного права знать, что он жив. Мы не могли бы
      подать прошение, потому что не сумели бы объяснить, каким образом завладели
      лежащей в его основе информацией. Как вы понимаете, разрешить этот парадокс
      было достаточно просто, и я подал запрос на получение второго уровня.</p>

    <p>Речь Ройша была длинной, ровной и
      аккуратной, как его пальцы или его же методички по истории древнего мира.</p>

    <p>Даже интересно —&nbsp;все эти
      события для него действительно такие же мёртвые, как трагедии и переживания
      людей, живших до Падения Хуя?</p>

    <p>— Процесс получения уровня
      доступа, тем более такого высокого, длителен и непрост. Мне пришлось собирать,
      искать и подделывать изрядное количество бумаг, поскольку некоторых документов,
      критически важных для подтверждения моей личности и прав, попросту не
      существует в природе.</p>

    <p>Бумаг, необходимых для
      подтверждения личности Ройша?</p>

    <p>Бровь, конечно, всё понимает,
      глаза лгут, сердце обманывает, но Ройш-то — как две капли собственный дед,
      красующийся на страницах любого отрядского учебника по Революции, десятилетний
      ребёнок опознает. Бюрократия — великая сила, если она властна даже над Ройшем,
      если даже ему приходится бегать в поисках очевидным образом ненужных бумажек и
      если даже ему может быть непросто их раздобыть.</p>

    <p>— Есть подозрение, что хэр Ройш
      их съел, — Ройш чуть заметно постучал указательными пальцами. — Когда бумаги
      были собраны, а личность и права подтверждены, я получил второй уровень доступа
      к информации и немедленно им воспользовался, направив соответствующие прошения
      в Бюро Патентов.</p>

    <p>Бровь тихо взяла ещё одну
      мармеладину.</p>

    <p>Ройш сейчас удивительно напоминал
      замаринованного лабораторного таракана, усаженного на стул как можно вертикальнее и шевелящего лапками.</p>

    <p>—&nbsp;К сожалению, бумажная
      волокита сыграла против меня. В мае 1876-го на Колошме произошла вспышка
      степной чумы. Среди заключённых выживших не оказалось. Несколько человек сумели
      сбежать, но их трупы были обнаружены в ближайшем поселении, также выжженном
      болезнью. Сергей Корнеевич Гуанако значился в списках
      погибших —&nbsp;в кои-то веки на всех уровнях доступа, включая первый.</p>

    <p>Переступить нелюбовь к деду,
      взять-таки второй уровень доступа — только затем, чтобы обнаружить, что тот,
      ради кого ты это делаешь, уже умер.</p>

    <p>На самом деле умер.</p>

    <p>Вот так — бац.</p>

    <p>Может, так и глотают корабельные
      мачты.</p>

    <p>— А Дима? — брякнула Бровь.</p>

    <p>— Дима?</p>

    <p>— Ну, он ведь был тем студентом,
      которого тогда посадили, когда потом в итоге получились канализации? Он же
      именно тогда, наверное, делал эту самую вакцину, из которой лекарство, которое
      из студентов.</p>

    <p>А ещё он был вашим единственным
      другом.</p>

    <p>Возможно, за всю биографию.</p>

    <p>— Как было сказано выше, среди
      заключённых выживших не оказалось.</p>

    <p>Среди личностей, призванный поддерживать
      боевой дух, — тоже.</p>

    <p>Даже распоследний
      таракан (а Ройш не распоследний, он явно в первых
      рядах тараканьей братии) заслуживает хотя бы одного друга, а что в розовой рубашке
      — ну это уж какой достался.</p>

    <p>— Поскольку необходимость иметь
      второй уровень доступа исчезла, я от него отказался. Запрос на его получение
      можно подать всего единожды, так что теперь я вряд ли могу оказать
      кому бы то ни было вескую бюрократическую поддержку.</p>

    <p>Бровь с интересом обнаружила, что
      руки у неё кривые, неудобные, и совершенно непонятно, как их нормально положить
      на стол, чтобы не мешались.</p>

    <p>— И поэтому вам теперь так важно
      помогать Университетской гэбне неофициально?</p>

    <p>— Странный вопрос. Я помогаю Университетской гэбне, потому что могу.</p>

    <p>И не выкидывал столько лет Димины
      клоунские рубашки, потому что не возникало необходимости, ага.</p>

    <p>У Брови закопошилось в горле.
      Один идиот, не понимающий, насколько печальна его участь, и второй, не
      замечающий собственного благородства.</p>

    <p>На почве идиотизма и сдружились,
      видать.</p>

    <p>Люди в Университете хорошие, но
      такие же тупые, сил нет.</p>

    <p>Чашка Брови тем временем
      опустела. Вероятно, это тоже Знак. Бровь несколько раз всухую сглотнула (надо
      же что-то сотворить с голосовыми связками, чтоб заработали) и поднялась.</p>

    <p>— Спасибо за чай. Я, честно
      говоря, без какой-то особой цели зашла… надеялась хоть
      немного поднять ваш боевой дух. Вместо этого, кажется, уронила свой.</p>

    <p>Ройш тоже встал, проводил её в
      коридор и помог надеть куртку.</p>

    <p>—&nbsp;Спасибо за добрые
      намерения.</p>

    <p>Так и стояли в коридоре —
      увлекающаяся главная героиня шпионского романа и таракан с мачтой в спине.</p>

    <p>Отличная парочка.</p>

    <p>— И, Бровь…</p>

    <p>— Да?</p>

    <p>— К понедельнику жду от вас
      черновика как минимум половины первой главы вашей курсовой работы. С учётом
      грядущей экспедиции в Имперскую Башню учебный план перестраивается, так что нам
      с вами придётся поторопиться.</p>

    <p>Бровь чуть не рассмеялась в
      голос.</p>

    <p>Этот человек невыносим.</p>

    <p>А для того, чтобы ей неожиданно
      очень-очень захотелось писать курсовик, оказывается, достаточно всего лишь
      устроить эпидемию чумы в Бедрограде.</p>

    <p>&nbsp;</p>

    <p>На улице было уже совсем темно, а
      до квартиры Максима (Аркадьевича) —&nbsp;совсем близко, так что Бровь решила
      пройтись пешком. Весь день приближался дождь, но так и не приблизился, и в
      воздухе висела какая-то липкая дырявая сырь.</p>

    <p>А Ройш, кстати, даже не подумал,
      что она теоретически могла участвовать в твири-в-жопу. Это два-три дня, но уже
      вторник, до следующего понедельника —&nbsp;всего ничего, какие полглавы, на
      одну главку времени не хватит!</p>

    <p>Хотя с чего бы это должно было
      остановить Ройша.</p>

    <p>Университетские люди Тверды и
      Решительны, и Бровь — одна из них. Да, это рекламный слоган.</p>

    <p>Всё-таки из неё получается весьма
      приличная главная героиня шпионского романа — вон
      сколько ей уже трагических предысторий понарассказывали!
      Этого пытали, тот сидел, третий критически опоздал и не спас того и этого, и
      теперь они все такие многоопытные и повидавшие виды.</p>

    <p>Круто, круто, круто.</p>

    <p>Бровь свернула в симпатичный
      переулок, наиболее подходящий градусу нынешних переживаний по степени затемнённости (чем темнее темнота, тем менее
      очевиден
      по-дурацки жизнерадостный цвет домов в новых районах Бедрограда).</p>

    <p>Вот бы Брови тоже обзавестись
      трагическим прошлым. Только как-нибудь эдак, без
      трагического настоящего.</p>

    <p>Университет и Бедроградская
      гэбня, оказывается, так давно и яростно воюют, что почти страшно становится. И
      так местами грязно. Сажать Диму под предлогом каких-то там бумажек — явно
      настолько глупых, что ни он сам, ни Ройш об этом не вспомнили, только дотошный Святотатыч
      — чтобы добиться от Гуанако помощи! Похищать людей! И другие атаки! И на что
      вообще ещё способна Бедроградская гэбня?</p>

    <p>За спиной у Брови взвыл двигатель
      такси — неожиданно, из-за угла. Прямо в спину ударил свет фар.</p>

    <p>Бровь посмотрела на свою тень,
      стремительно разваливающуюся на две (ввиду приближения источников света, она в
      отряде зачем-то учила физику).</p>

    <p>По законам жанра шпионского
      романа сейчас есть два варианта развития событий. Можно просто идти дальше, и
      тогда такси затормозит, проедет мимо, повернёт куда-нибудь — в общем, окажется
      просто случайным ночным такси.</p>

    <p>А можно обернуться и узнать, кто
      там сидит.</p>

    <p>Бровь учила физику, потому что
      мечтала заниматься радиотехникой. Но в БГУ нет ни радиотехнического, ни
      физического факультета, это всё —&nbsp;в Институте Связи, а папа очень хотел,
      чтобы в БГУ. Пришлось идти на истфак, и вовсе даже не из вредности, и вовсе
      даже ей всегда нравилось.</p>

    <p>Люди хорошие.</p>

    <p>Твёрдые и Решительные.</p>

    <p>Бровь обернулась.</p>

    <p>Даже сквозь свет фар — такой,
      оказывается, близкий — и даже за одну секунду —&nbsp;или сколько там успело
      пройти — она успела рассмотреть, что такси ведёт тавр, только не тот, портовый,
      а какой-то другой.</p>

    <p>Тавр-таксист?</p>

    <p>Что, серьёзно?</p>

    <p>Честное слово, это уже ни в какие
      ворота.</p>
  </>
}

export default chapter08
