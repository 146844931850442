import React from 'react'
import { IBookPage } from './index'

const chapter09: IBookPage = {
  header: 'header_d4.jpg',
  title: 'Глава 9. Припев (третий раз)',
  prevLink: 'chapter08',
  nextLink: 'chapter10',
  content: <>
    <h2>Университет. Габриэль Евгеньевич</h2>
    <img alt='' src="/images/09_gabriel_evgenievich.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }}/>
    <p>С Поплеевской вывернуть на Малый
    Скопнический — дело простое, плёвое, поднырнуть только под арку и прокрасться
    дворами; но ночью нельзя, ночью темно и в тёмных дворах опасно, так сказал бы
    Максим, так говорил Максим много раз. И что нельзя ходить после заката, и что
    нельзя ходить вообще —&nbsp;сиди в башне, я о тебе позабочусь.</p>

    <p>Только сколько можно сидеть и
      ждать, сколько можно быть бессмысленным украшением, сапфиром в фамильной
      короне?</p>

    <p>Габриэль Евгеньевич прикрыл
      глаза, чтоб не резали их так жгуче злые жёлтые фонари.</p>

    <p>&nbsp;</p>

    <p><i>Ю. Б.: Сколько раз я уже сознался в том, что у меня проблемы? Но жив,
      как видите. По крайней мере, на ближайшую пару суток.</i></p>

    <p><i>66563: Вы бы определились, у вас всё под контролем или всё-таки
      проблемы.</i></p>

    <p><i>Ю. Б.: У меня проблемы, и они под контролем. Ну, почти.</i></p>

    <p>&nbsp;</p>

    <p>От бумаг в кармане — бесконечных
      расшифровок бесед из далекой степной камеры — глаза не прикроешь, так и так
      прожигают подкладку плаща, брюки, кожу — до самой серёдки, до нутра. Только и
      остаётся, что сжимать их пальцами —&nbsp;крепче, до боли — чтобы хоть — не
      впустую ожог.</p>

    <p>Если не дворами, то по
      Шолоховскому переезду.</p>

    <p>В жёлтых фонарных кляксах
      Бедроград мокрый, словно только что вынырнул из пучины
      морской, словно ещё убегают под поребрики последние солёные потоки, и бурые
      гнилые водоросли путаются в ногах. Из этого морока нет спасения — город-порт,
      город-Порт, силок для случайных, костёр для невинных.</p>

    <p>Жжёт-горит
невыносимо, нет больше сил сидеть на месте, в башне; надо идти — покаяться,
отдать всё, что есть, самое дорогое, себя отдать на растерзание, только бы
прекратилось, и — вернулось, только бы не было ничего.</p>

    <p>С расшифровок всё началось
      —&nbsp;когда-то давно, когда не было ещё ни Максима, ни завкафского кресла,
      когда ещё не болела так голова. Глупо; Габриэлю Евгеньевичу просто хотелось
      знать, зачем Гуанако убил Начальника Колошмы, зачем он сам умер. Или Диме
      хотелось — он уже и не помнил, помнил только, что тот один раз ворвался домой —
      запыхавшийся (высокие ступени башни), лихорадочно-весёлый. Сказал —&nbsp;Стас
      Никитич-то, добрая душа, романтик, с Колошмы очутился в Порту, у него нашлись
      копии расшифровок, давай читать. Сказал — прочитаем и сожжём, но один-то раз
      можно, это же не политика, это просто чтобы знать.</p>

    <p>Сказал —&nbsp;ничего в этом нет
      опасного.</p>

    <p>&nbsp;</p>

    <p><i>66563: Знаете, чем категория веры лучше категории морали? Верить можно
      в то, во что хочется верить. (Усмешка.)</i></p>

    <p>&nbsp;</p>

    <p>Малых Скопнических в Бедрограде
      два: один настоящий —&nbsp;в Старом Районе, и второй — поддельный, ложный,
      названный в честь —&nbsp;в новых. Настоящего Габриэль
      Евгеньевич не заслужил, как не заслужил того, чтобы Диму потом посадили, как не
      заслужил знать, что чумной изолятор сгорел, как не заслужил их с Гуанако
      возвращения в Бедроград.</p>

    <p>Он же не хотел никому делать
      больно, не хотел поездок в Ирландию — зелёную и пьяную; не хотел, вернувшись,
      увидеть Максима таким чужим и спокойным. Всё, всё разрушилось и склеилось
      кое-как, криво, каракульно и внахлёст; и Максим запер его в башне, чтобы больше
      не убегал, и Дима остался —&nbsp;один и маленький, и у Гуанако больше нет за
      зрачками зелёных ирландских холмов, только тихая, тихая радость: спасибо, что
      всё закончилось.</p>

    <p>&nbsp;</p>

    <p><i>Ю. Б.: Не будьте Андреем, слушайте ушами. Тот факт, что я мало смеюсь,
      не означает, что мне не весело. Видите — вполне улыбаюсь. Можете рисовать
      звёздочку на борту.</i></p>

    <p><i>66563: Было бы чем — нарисовал бы.</i></p>

    <p><i>Ю. Б.: Охранники со стопками бумаги и упаковками ручек всё ещё к нашим
      услугам.</i></p>

    <p><i>66563: А это может быть уместно, как и ещё несколько пачек сигарет.
      (Усмешка.) Сложу из бумаги кораблик и нарисую звёздочку. В корабликах меньше
      мелодраматизма, чем в журавликах. (Усмешка.)</i></p>

    <p><i>(Пауза, шаги, скрип двери.)</i></p>

    <p><i>Ю. Б.: (Неразб., пауза, скрип двери.) Скоро будут.</i></p>

    <p><i>66563: Спасибо. И ложитесь спать.</i></p>

    <p>&nbsp;</p>

    <p>Габриэль Евгеньевич не знал, где
      находится здание Бедроградской гэбни — тайное, настоящее, не то, куда ходят в
      поисках чемоданов — но знал: если раскаяться, признаться, вернуть расшифровки —
      всё изменится, вернётся, и никому не нужно будет умирать, и никому не нужно
      будет воскресать, и перестанет так ныть и вертеться голова. Воздух
— мутный, клубящийся, тошный — солгал, не пролился дождём; дома в липких
водорослях сгрудились, сжались, придавили голову, пригвоздили к земле
—&nbsp;как тут двигаться с места. И всё же двигался, шатаясь, наперекор
      улицам, латунными дворами мимо чьих-то не спящих ещё окон и редких ночных
      такси.</p>

    <p>За одним из домов мелькнула
      бархатная тень, осторожно прильнула к парапету: грифон. Грифоны знают, где
      сердце Бедроградской гэбни, грифоны чуют кровь и по крови идут-ведут. Габриэль
      Евгеньевич замер было (страшные когти в мягких лапах),
      сердце трепетнуло в грудной клетке птицей, но потом усмирилось. Самому ему не
      дойти, не найти, сам он не ведает путей города; только грифоны с охровыми
      звериными глазами, с красными страшными языками укажут дорогу. В мохнатом
      фонарном свете померещилось даже: увидел силуэт, крылатый, бесшумный, похожий
      на ночного мотылька. Поспешил следом —&nbsp;и ноги почти оторвались от земли,
      почти взлетелось.</p>

    <p>Так и двигались по городу в
      поисках здания Бедроградской гэбни: два мягких, бархатных хищника и один человек,
      перепархивающий больной птицей. По улицам, проспектам, мимо закрывшихся на ночь
      магазинов и колыбелей квартир — завернув в один из переулков, грифоны
      клёкотнули вдруг несвоими голосами и взвились на крыши соседних домов. Привели,
      значит, по кровавому следу через весь замутившийся тиной город; милые. Габриэль
      Евгеньевич оглянулся — но нет, просто дома, глухие, почти без окон; зачем же
      —&nbsp;сюда?</p>

    <p>А вот зачем: через переулок,
      разбрызгивая свет фар и не визжа тормозами, пронеслось такси, под его колёсами
      —&nbsp;мелькнуло маленькое чьё-то тельце, без крика и удивления — просто так.
      Такси остановилось всё-таки, и всё остановилось, остекленело; Габриэль
      Евгеньевич понял — он подглядывает тайну, и приветливый автор не спешит
      перевернуть страницу, чтобы можно было вчитаться, запомнить каждую строчку,
      каждую чёрточку, чтобы потом рассказать всё как было.</p>

    <p>Габриэль Евгеньевич рванулся
      вперёд —&nbsp;скорее, скорее, были бы прокляты эти неверные мягкие ноги,
      вязнущие в асфальте, проваливающиеся в него по лодыжку. Переулок растягивался
      резиной, издевательски хохотал, но Габриэль Евгеньевич добрался-таки. На
      асфальте, аккуратно между луж, не намокнув ни в одной, лежала какая-то
      незнакомая девочка — с длинными волосами, с нелепо вывернувшейся головой, так
      вывернувшейся, что глаза заливает красным, и нет сил
шевельнуться, и можно только кричать, но крик давит мягкими грифоньими
      лапами ночь. Он знает её, должен узнать, должен запомнить —</p>

    <p>Если бы время не разморозилось и
      не побежало опять —</p>

    <p>«Это просто дурновкусие»,
      —&nbsp;сказала бы она.</p>

    <p>«Как тебя зовут? Что произошло?»</p>

    <p>«Я же с самого начала говорила, я
      говорила! Хотя, конечно, неплохая подводка получилась. Если бы можно было
      прожить второй раз… — и повернула бы свою выломанную голову, и прибавила:
      —&nbsp;А вы знаете, что все в курсе, что вы носите очки с простыми стёклами?»</p>

    <p>— если бы время не разморозилось
      и не побежало опять.</p>

    <p>Но оно разморозилось и побежало,
      и Габриэль Евгеньевич обернулся на затормозившее в нескольких метрах такси. Из
      такси коротко и по-деловому выскочили два человека (и их он тоже знает, должен
      знать, должен узнать!), побежали к девочке военной трусцой. К девочке и к
      Габриэлю Евгеньевичу, и ему вдруг стало страшно, очень страшно, потому что
      понял: сейчас загорится всё вокруг жёлтым фонарным светом, и не останется ни
      тел, ни следов, никто не вспомнит и не сумеет доказать. И в последней нелепой
      попытке он дёрнулся, выломал свою смешную голову, рванулся —&nbsp;лишь бы
      разомкнуть, разверзнуть эту душную липкую ночную грифонью влажную лихорадочную
      темноту —</p>

    <p>&nbsp;</p>

    <p>— Габриэль!</p>

    <p>Максим —&nbsp;чёрный против опять
      зачем-то включённого света, сожравшего его лицо и оставившего только неровные
      тени; не поймёшь — беспокоится? Злится?</p>

    <p>И всё же —&nbsp;спас, выдернул из
      неверного сонного морока.</p>

    <p>Сонного.</p>

    <p>Под головой у Габриэля
      Евгеньевича раскинул спину любимый мягкий ковёр, над головой — стеклянный
      четырёхугольник окна с отражением люстры. Значит, упал прямо здесь, как стоял,
      и весь день провалялся; и всё, что было, — только сны, отражения памяти.</p>

    <p>Нёс во сне пресловутые
      расшифровки отдавать Бедроградской гэбне, чушь какая.</p>

    <p>Нет давно никаких расшифровок,
      сгорели — пусть и поздно, позже, чем следовало — красным огнём.</p>

    <p>— Ты весь день проспал на полу у
      открытого окна, отлично. Теперь в дополнение к сотрясению мозга ещё и
      простынешь. Давай, поднимайся.</p>

    <p>Габриэль Евгеньевич попытался
      встать — ноги не слушались, конечно, налились сырой сонной водой. Максим не
      протянул руки. Попытался ещё раз — преуспел, хоть и ухнуло всё вокруг
      водоворотом.</p>

    <p>Надо выпить кофе. От савьюра в
      голове ватно и марлево, а от кофе — наоборот, яснеет, утекает прочь вся хмарь.
      Надо сказать Максиму что-нибудь — извиниться за своё молчание, спросить хотя бы
      просто, как прошёл его день; рассказать, что в одном из переулков Бедрограда
      было такси, и в такси были люди, и они убили —</p>

    <p>Нет — это всё сон, чушь. Сейчас
      не время, сейчас надо просто улыбнуться не чующими себя губами и попытаться
      хотя бы слушать, попытаться хоть как-то дать понять, что всё хорошо, что
      Габриэль Евгеньевич ждал Максима, что Максим его спас.</p>

    <p>— Если тебе интересно, день мой
      прошёл не очень хорошо. Ты хоть заглянул в бумаги, которые я для тебя оставил?</p>

    <p>Габриэль Евгеньевич покачал
      головой —&nbsp;с улыбкой, не в силах её отодрать. Максим проследовал за ним на
      кухню, сел за стол, спрятал глаза куда-то, уткнулся в себя. Габриэль
      Евгеньевич, пошатываясь, поставил турку на конфорку.</p>

    <p>Когда жёг расшифровки бесед из
      далёкой степной камеры — было стыдно и жарко до слёз, потому что уже нет
      смысла, потому что ему почти прямо сказали — оставьте себе, виновные наказаны,
      к вам цепляться не станут. Оставьте-оставьте, вы же так их хотели. И всё равно
      жёг — одолжил у соседа напротив ведро, чистое,
      хозяйственное, но словно насквозь прогорклое ядом, гадкое — не прикоснуться.
      Всю квартиру заволокло вонючим дымом, и было снова
      стыдно, как будто он сделал что-то непристойное.</p>

    <p>Сосед напротив тогда позвонил в
      дверь уточнить, не случилось ли чего. Пришлось, краснея, отдать ему ведро прямо
      так, с тлеющим ещё пеплом, бормотать какие-то извинения. Тот даже головой не
      покачал, только посоветовал не открывать окон —&nbsp;вызовет ещё кто-нибудь
      пожарную службу! —&nbsp;а включить лучше вытяжку.</p>

    <p>Сосед напротив привык к Габриэлю
      Евгеньевичу с его странностями, сколько лет рядом жили. Гуанако в Ирландии
      говорил, что у того в столе даже есть специальный ящик для вещей, выброшенных
      нервным Габриэлем Евгеньевичем из окна — и улицу не замусоривать, и вернуть при
      случае.</p>

    <p>И это тоже было болезненно,
      обжигающе стыдно.</p>

    <p>— Ты злишься? —&nbsp;выговорил
      кое-как Габриэль Евгеньевич, не оборачиваясь.</p>

    <p>— Я устал. События, которые разворачиваются в Университете… я за ними не успеваю,
      —
      Максим пошевелился тяжко, как древний великан. — Сперва
      предполагалось направлять запросы к фалангам — тебе плевать, какие, но хоть
      поверь, что важные. Но теперь это, кажется, никого не интересует. Мне одному полагается сидеть в приёмных, вести с фалангами
многочасовые беседы ни о чём, объяснять, что и откуда нам известно, а потом
прибегать на кафедру и обнаруживать, что я всех задерживаю, что накопилась уже
пачка бумаг, которые я&nbsp;— а вообще-то ты — должен подписать, что учебный
план переписан моим именем без моего ведома и что я же во всём виноват.
      Я лезу вон из кожи, чтобы везде успеть, и всё равно не успеваю.</p>

    <p>— Тебе же это нравится, — тихо
      ответил Габриэль Евгеньевич, — ты сам хотел ответственности.</p>

    <p>Надо утешить, ободрить, сказать
      что-то —&nbsp;хоть что-то.</p>

    <p>— Нет, <i>это</i> мне не нравится. Я не <i>этого</i> хотел. Я готов отвечать за Университет и за решения, которые принимает гэбня, я
      готов выслушивать всех, кто действует в интересах Университета. Я готов
      поступиться своим мнением —&nbsp;леший, знал бы ты, до какой степени готов! Но
      я не хочу приходить на кафедру и обнаруживать, что Ройш дома, Поппер утащил и
      Лария, и Охровича и Краснокаменного делать из студентов лекарство, хотя эпидемия
      — это только смутное предположение, и что всё это вертится без меня!</p>

    <p>— Но ведь вертится.</p>

    <p>— А должно ли вертеться?
      —&nbsp;Максим вскочил со стула, снова поймал себя —&nbsp;в полёте, в прыжке,
      сделал вид, что невзначай тянется в холодильник. —&nbsp;Мы разбрасываемся. Я очень
      стараюсь, но мне всё-таки нужно когда-то спать. Ответ от фаланг всё ещё может
      прийти —&nbsp;в любую минуту, хоть этой ночью. Отреагировать на него надо
      мгновенно, и сделать это должна Университетская гэбня.
      Но кто, если Ларий и Охрович и Краснокаменный так заняты
      лазаретом?</p>

    <p>Лазарет, лекарство из студентов,
      фаланги — безумие, карусель, цветные индокитайские фонарики. Габриэль
      Евгеньевич прикрыл глаза и вдохнул запах кофе —&nbsp;простой и чуть шершавый,
      согревающий всё тело.</p>

    <p>Хотел бы он оказаться в этой
      круговерти?</p>

    <p>— Я понятия не имею, о чём ты
      говоришь.</p>

    <p>— Знаю, — Максим вздохнул и полез
      на полку за солью — посолить огурец, который собирался
      есть просто так, не помыв даже. —&nbsp;Прости. Это всего лишь усталость. Я сам
      виноват в том, что не успеваю за событиями. Просто мне хотелось бы, чтобы моё
      мнение принимали в расчёт.</p>

    <p>Он подошёл к Габриэлю Евгеньевичу
      сзади —&nbsp;такой большой и сильный —&nbsp;аккуратно, чтобы ничего не сломать,
      приобнял за плечи. Уткнулся в волосы где-то за ухом и несколько секунд просто
      дышал — тихо-тихо и приятно, как шершавый запах кофе.</p>

    <p>— Это ведь нечестно,
      —&nbsp;сказал он наконец, и руки его заиндевели, стали
      каменными. — Я не вызывался в Университетскую гэбню,
      мне предложили. Предложили и сказали, что теперь я —&nbsp;мы, гэбня — принимаем
      решения за весь Университет. Если бы Ларий и Охрович и Краснокаменный решили
      что-то за меня, я бы согласился —&nbsp;я и так соглашаюсь. Но когда делами
      Университета почему-то занимаются Поппер и <i>эти
        двое</i>…</p>

    <p>— Не трогай этих двоих!</p>

    <p>Кофе, зашипев по-кошачьи, полился
      на плиту — улучил момент, коварный, тот один момент, когда Габриэль Евгеньевич
      отвернётся, чтобы сбросить с себя оковы Максимовых рук.</p>

    <p>Максим качнулся, отступил,
      посмотрел с недоверием.</p>

    <p>Эти двое случайно оказались — в
      Бедрограде, в Университете; они жили в степи, просто заглянули — в гости, на
      чай, просто застряли на часок, на денёк, на недельку; просто Серёжа сказал — <i>давай снова вместе, если ты не можешь меня
        отпустить</i>. Если бы не попытка <i>снова
        вместе</i>, их бы не было сейчас в Бедрограде.</p>

    <p>Не трогай их.</p>

    <p>Не трогай ни Поппера, ни Ройша;
      ты же сам сделал так, что каждый имеет право на своё мнение
      и каждый умеет действовать сам. Ты же гордишься этим — тем, что не превращаешь
      Университет в машину, тем, что мы все остаёмся людьми.</p>

    <p>Тем, что ты можешь положиться на
      кого-то, когда станет совсем невмоготу, и знаешь: никто не станет ждать
      отмашки.</p>

    <p>Вы же гэбня, у вас синхронизация.</p>

    <p>Они не могут сделать ничего тебе
      во зло.</p>

    <p>Как и <i>эти двое</i> — их вообще не должно было
      здесь быть.</p>

    <p>— Вот, значит, как, — тихо, с
      трудом выговорил Максим, — не трогать этих двоих. Почему же, скажи на милость?
      Потому что вам было хорошо ебаться?</p>

    <p>Без запаха кофе воздух снова стал
      тяжёлым, надавил невыносимо на плечи.</p>

    <p>Максим ревнует — он имеет право
      ревновать, он не заслужил того, что было в мае. Но нелепо же, нелепо видеть
      опасность там, где её нет, нет больше, вытравилась вся под корень, выжглась
      пеплом в вонючем ведре! И не только Максиму было в мае
      плохо, всем было, всем четверым — так плохо, что это и в самом деле комично, но
      только так и можно было понять, что не осталось опасности больше, что мёртвый
      Гуанако, герой ненаписанных романов, Габриэлю Евгеньевичу дороже живого,
      пропахшего степными травами и морем.</p>

    <p>Леший с ним, с Гуанако, леший с
      самим Габриэлем Евгеньевичем; если хочет Максим искать виноватых —&nbsp;вот
      они, здесь. Но Диму зачем под тот же удар? Он же тоже
      —&nbsp;жертва, он имел все права остаться в Столице, и если вернулся — то
      только потому, что любит Университет, и Университетскую
      гэбню, и, значит, лично Максима.</p>

    <p>— Что за старший отряд, —
      Габриэль Евгеньевич качнулся опять, схватился за стол. — Наши отношения тут ни
      при чём.</p>

    <p>— Ваши отношения, — Максим
      продолжил пятиться, словно пытался сбежать, и не мог, и не мог понять, надо ли
      бежать; наткнулся на подоконник, где стоял налитый ещё утром для Габриэля
      Евгеньевича стакан воды, посмотрел на него недоумённо, взял в руки, — нет у вас
      никаких отношений. <i>Этих двоих</i> вообще
      не должно быть в Бедрограде. Не должно быть в живых. Я рад, что мои друзья
      живы, но друзья и работа — это разные вещи. Друзья и обязанности — разные вещи.
      Друзья и Университет — разные вещи. Университет — я лично! — готов их принять и
      защитить. Если они хотят помочь, если у них есть разумные идеи —&nbsp;это
      прекрасно. <i>Но они не вправе решать за
        меня.</i></p>

    <p>В официальном обвинении
      значилось, что Гуанако попал на Колошму за идеологическое участие в
      деятельности некой террористической контрреволюционной группировки, состоящей
      притом из психически нестабильных людей. Несколько десятков человек, все
      ровесники, все в младенчестве участвовали в опытах по контролю уровня агрессии. Все
      социализированы, но могут быть потенциально опасны для общества. Гуанако
      вменили чуть ли не эксперименты на людях.</p>

    <p>Четверо из этих людей потом
      составили Университетскую гэбню.</p>

    <p>Гуанако мог не садитьс�� в
      колонию, участия-то его на самом деле не было. Но если бы не он — сел бы лидер
      этой самой группировки, сели бы её участники, сели бы те, кому просто не
      повезло родиться в тот же год.</p>

    <p>Пусть уж лучше одного Гуанако, не
      так ли?</p>

    <p>Лучше его, чем его студентов.</p>

    <p>Лидер этой самой группировки
      потом возглавил Университетскую гэбню.</p>

    <p>— Ты неблагодарная скотина,
      —&nbsp;губы Габриэля Евгеньевича еле двигались, словно только с мороза,
      —&nbsp;Гуанако дал тебе всё, что у тебя есть.</p>

    <p>Максим стоял — нелепый, со
      стаканом в одной руке и немытым огурцом в другой, в слишком тесном ему, не сдерживающем
      злости пиджаке. Стоял —&nbsp;и не мог пошевелиться, окаменел в своём гневе,
      окаменел от ужаса, будто сыпались кафельные плитки пола у него из-под ног,
      будто под ногами были хищные воды — отойди, поглотят!</p>

    <p>— Он дал мне многое, и я
      благодарен, —&nbsp;медленно, по слогам. —&nbsp;Потом он забрал у меня тебя.
      Теперь он вернулся в Университет, и Университет делает то, что он говорит, пока
      я бегаю по фалангам. Насколько же далеко должна простираться моя благодарность?</p>

    <p>Гуанако был человеком, который придумал
      Университетскую гэбню.</p>

    <p>Очаровал как-то Хикеракли — прямо
      так, с Колошмы, из посмертия —&nbsp;и вторым хикераклиевским уровнем доступа
      водворил гэбню на её нынешнее место.</p>

    <p>Он не вернулся в Университет, он
      не хочет больше видеть Университет, слишком много людей, слишком много
      воспоминаний, слишком много сложного —&nbsp;лучше
      прочь, в степи, на корабле.</p>

    <p>Не один Максим умеет уставать.</p>

    <p>Максим же поднёс стакан к губам,
      пытаясь остудить хоть немного то, что рвалось из него через все щели, через все
      нетвёрдые швы, но — не закончил движения, поставил обратно на подоконник. Без
      стука, тихо, честно — честно пытаясь сдержать себя, но —&nbsp;рвалось:</p>

    <p>— Когда Гуанако увёз тебя в мае,
      он обещал мне, что ты вернёшься, а он нет. Вышло ровно наоборот.</p>

    <p><i>Я же здесь, вот он я</i> — надо было сказать, но как? Как, если Максим
      слеп и не видит того, что —&nbsp;кто — прямо перед ним? Как, если ревность и
      рабочая усталость ему дороже того, что есть в руках, перед глазами, совсем
      рядом —&nbsp;вот?</p>

    <p>Никому нет дела до того, что
      внутри, — даже кофе сбежал, чтобы не видеть уродливой сцены. И без кофе, без
      его мягкого грифоньего тепла, не вышло разомкнуть губы даже затем, чтобы
      сказать —&nbsp;<i>убирайся вон</i>.</p>

    <p>— Да скажи же хоть что-нибудь!</p>

    <p>Но Габриэль Евгеньевич не мог —
      смёрзлись, смёрзлись губы, захлестнуло с головой ужасом. Ведь сейчас уйдёт
      Максим, а ему нельзя уходить, ему надо выспаться, он устал; надо переждать эту
      страшную ночь, и с утра будет солнце, и с солнцем всё изменится, разойдутся
      тучи в голове, разлетится туман, и каменный Максим оживёт, улыбнётся.</p>

    <p>Просто спрятаться, просто
      переждать.</p>

    <p>Габриэль Евгеньевич отвернулся.</p>

    <p>Максим кинулся вперёд так, что
      стало страшно —&nbsp;ударит; но нет, пронёсся мимо, на ходу хватая куртку,
      какие-то ещё вещи. В коридоре замешкался — вернётся?</p>

    <p>Иногда возвращается, ловит себя в
      полупрыжке.</p>

    <p>— Габриэль, я же пытался.
      Пытаюсь. Ты не говоришь <i>со мной</i>,
      только про них, <i>про него</i>. Что мне
      остаётся думать?</p>

    <p>Оправдываться в прихожей, в
      ботинках уже —&nbsp;нелепо и жалко; невозможно смотреть, стыд за другого жжёт
      только сильнее, охватывает всё тело огнём.</p>

    <p>— Уходишь —&nbsp;уходи. Твои
      монологи смешны.</p>

    <p>Максим дёрнулся, и глаза его
      побелели —&nbsp;не то от злости, не то от боли, не то просто шутит шутки
      тусклая коридорная лампочка.</p>

    <p>— И самое страшное —&nbsp;тебе это правда нравится. Это издевательство, — помедлил
      ещё
      один, последний раз. —&nbsp;Иногда я думаю, что всем было бы лучше, если тебя в
      моей жизни просто не было.</p>

    <p>И ушёл —&nbsp;аккуратно, не
      хлопая дверью; и (Габриэль Евгеньевич, как ни кружилась голова, видел через
      окно) свернул с Поплеевской на Малый Скопнический
      дворами.</p>
  </>
}

export default chapter09
