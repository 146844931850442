import {JSX} from "preact";
import prolog from './prologue'
import chapter01 from './chapter01'
import chapter02 from './chapter02'
import chapter03 from './chapter03'
import chapter04 from './chapter04'
import chapter05 from './chapter05'
import chapter06 from './chapter06'
import chapter07 from './chapter07'
import chapter08 from './chapter08'
import chapter09 from './chapter09'
import chapter10 from './chapter10'
import chapter11 from './chapter11'
import chapter12 from './chapter12'
import chapter13 from './chapter13'
import chapter14 from './chapter14'
import chapter15 from './chapter15'
import chapter16 from './chapter16'
import chapter17 from './chapter17'
import chapter18 from './chapter18'
import chapter19 from './chapter19'
import chapter20 from './chapter20'
import chapter21 from './chapter21'
import chapter22 from './chapter22'
import chapter23 from './chapter23'
import chapter24 from './chapter24'
import chapter25 from './chapter25'
import chapter26 from './chapter26'
import chapter27 from './chapter27'
import chapter28 from './chapter28'
import chapter29 from './chapter29'
import chapter30 from './chapter30'
import chapter31 from './chapter31'
import chapter32 from './chapter32'
import chapter33 from './chapter33'
import chapter34 from './chapter34'
import chapter35 from './chapter35'
import chapter36 from './chapter36'
import chapter37 from './chapter37'
import chapter38 from './chapter38'
import epilogue from './epilogue'

export interface IBookPage {
  header: string|false,
  prevLink: string|false,
  nextLink: string|false,
  title: string,
  content: JSX.Element
}

const pages: {
  [key: string]: IBookPage
} = {
  prolog, chapter01, chapter02, chapter03, chapter04, chapter05, chapter06, chapter07, chapter08, chapter09, chapter10,
  chapter11, chapter12, chapter13, chapter14, chapter15, chapter16, chapter17, chapter18, chapter19, chapter20, chapter21,
  chapter22, chapter23, chapter24, chapter25, chapter26, chapter27, chapter28, chapter29, chapter30, chapter31, chapter32,
  chapter33, chapter34, chapter35, chapter36, chapter37, chapter38, epilogue
}

export default pages
