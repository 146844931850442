import React from 'react'
import { IBookPage } from './index'

const chapter30: IBookPage = {
  header: 'header_d8.jpg',
  title: 'Глава 30. Да или нет',
  prevLink: 'chapter29',
  nextLink: 'chapter31',
  content: <>
    <h2>Бедроградская гэбня. Соций</h2>

    <img alt='' src="/images/30_sociy.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }}/>
    <p>Звук на записи утренней встречи
      был так себе. Основные микрофоны вмонтировали в стол, а до стола Соций с
      Гуанако так и не дошли.</p>

    <p>Так себе был звук на записи, но
      если сидеть тихо, всё можно услышать.</p>

    <p>Сейчас Бедроградская гэбня сидела
      очень тихо, хотя последние полминуты на записи никто ничего не говорил (запись
      изображения будет только к ночи, там технология сложнее, поэтому пока слушали
      звук).</p>

    <p>Звук: удар кулаком в ворота,
      скрип, снова скрип, шаги.</p>

    <p>Бедроградская гэбня сидела очень
      тихо.</p>

    <p>Соций не выдержал, чиркнул
      спичкой.</p>

    <p>Среагировав на движение, Бахта
      оглянулся в поисках пачки.</p>

    <p>Андрей поспешно придвинул к нему
      свою.</p>

    <p>Только Гошка не закурил.</p>

    <p>&nbsp;</p>

    <p><i>«— Командир, ты это… В общем, чего я тут буду, ты лучше, наверное, сам
      с Гошкой поговори, чё да как вышло. Короче, не виноват он, командир. Серьёзно.
      Он не мог не проболтаться, но нихуя он не виноват. Врубаешься?</i></p>

    <p><i>— Неважно.</i></p>

    <p><i>— К делу давай. И закругляемся, если неважно».</i></p>

    <p>&nbsp;</p>

    <p>Тут бы нажать на паузу (много раз
      уже нажимали, сразу обсуждали спорные моменты), но сейчас никто не решился.</p>

    <p>Про назначение новых переговоров
      дослушали молча.</p>

    <p>&nbsp;</p>

    <p><i>«— По рукам, наглая рожа».</i></p>

    <p>&nbsp;</p>

    <p>Хлопок рукопожатия, пара смешков,
      шаги, ворота скрипнули.</p>

    <p>Тишина. Что на записи тишина, что
      в подвале здания Института госслужбы. Том самом, с огромной картой в следах от
      пуль и с запасом стеклотары.</p>

    <p>Нехорошая такая тишина.</p>

    <p>Соций развернулся всем корпусом к
      Гошке. Уловил боковым зрением растерянный взгляд Андрея и возмущённый — Бахты.</p>

    <p>— Врёт он, — сплюнул Бахта.</p>

    <p>Привычно подал голос первым.</p>

    <p>Бахта всегда говорит и делает
      быстрее, чем нужно, чтобы обдумать. Выплеснул своё возмущение и смотрит теперь
      с сомнением. Не решил ещё, то ли хотел сказать, что сказал.</p>

    <p>Гошка сидел хмурый и равнодушный,
      хотя он тоже обычно начинает орать раньше, чем мозгами шевелить.</p>

    <p>Соций почти разозлился.</p>

    <p>— Конечно, врёт, — мягко, но
      уверенно вступился Андрей. — И про Смирнова-Задунайского своего,
      Борстена-Ройша, тоже врёт, я теперь в этом совсем убедился.</p>

    <p>Мягко-мягко, нежно-нежно,
      попробуй поспорь.</p>

    <p>А Социю есть, о чём спорить. Не
      поможет тут Андреева вечная дипломатия.</p>

    <p>Соций просто уставился мимо
      Андрея на Гошку.</p>

    <p>Гошка не отвёл глаз, смотрел
      прямо. Так, как будто врежет сейчас.</p>

    <p>Да лучше б врезал.</p>

    <p>— Ну? — не дождался Соций.</p>

    <p>— Не нукай, не запрягал. — Гошка
      прищурился. — И что, веришь?</p>

    <p>Падла, а.</p>

    <p>Нет бы сразу ответить — и дело с
      концом.</p>

    <p>— Ты, Соций, давай определись,
      кому веришь. Если ему — то зачем меня спрашивать? Если своей гэбне, то тем
      более — зачем меня спрашивать? — едко, презрительно так продолжил Гошка.</p>

    <p>И было чего презирать. Скажи кто
      вчера Социю, что он на голов своей гэбни всякую погань думать будет, он бы
      первым сказителю сказительный аппарат из глотки
      выдрал.</p>

    <p>Но тут такое дело, когда
      презрение, сомнение, доверие — да всё на свете! — надо запихать себе поглубже.
      Тут такое дело, когда надо мозгами шевелить, а не ядом брызгать и сантименты
      разводить.</p>

    <p>Соций выдохнул и призвал к
      шевелению мозгами:</p>

    <p>— Так ты пока ничего и не сказал,
      чему можно было бы верить или не верить.</p>

    <p>Гошка зыркнул
      ещё злее.</p>

    <p>— Чего ты хочешь от меня
      услышать?</p>

    <p>— Сдавал ты наши планы или не
      сдавал?</p>

    <p>Соций и Гошка одновременно
      отвернулись друг от друга, одинаково тяжело дыша.</p>

    <p>Бахта и Андрей молчали, не
      дёргались. Ждали.</p>

    <p>Гошка наконец взялся за сигареты.
      Соций понял это по запаху, смотреть на Гошку не мог — потряхивало.</p>

    <p><i>Это Гошка.</i></p>

    <p>Чего ему стоит не выёбываться
      сейчас, сказать прямо и в лоб?</p>

    <p>Бедроградская гэбня — отличные
      ребята, профессионалы из профессионалов, только всё равно любым отличным
      ребятам и профессионалам из профессионалов не помешал бы хотя бы годик в
      Северной Шотландии.</p>

    <p>Вот где быстро понимаешь, что
      отвечать на вопросы надо сразу. Иначе труба.</p>

    <p>Кто выёбывается — тот и мёртвый.</p>

    <p>Ёбаный Гуанако (сержант Гуанако,
      наглая рожа) — живой. И после Северной Шотландии, и после своих огнемётчиков в
      степную чуму. Знает потому что, когда надо прекращать выёбываться и мозгами
      начинать шевелить.</p>

    <p>Должно быть, поэтому Соций и
      верил ему. Не хотел, но верил.</p>

    <p>Уже почти.</p>

    <p>— Знаешь, ты молодец, что
      назначил-таки решающую встречу, — после долгого молчания заявил Гошка. — <i>Решающую,</i> когда можно будет перестрелять
      их всех нахер и забыть. А то всё разговоры, разговоры —&nbsp;скажи мне это,
      скажи мне то. Заебало языком чесать. Давно пора решить всё нормально, на
      кулаках, не мараясь о всякие там засады из младших служащих.</p>

    <p>Засады из младших служащих?</p>

    <p>— Так ты их всё-таки устроил? —
      первым опять переспросил Бахта.</p>

    <p>Обсуждали сразу после отстранения
      Университетской гэбни, стоит или не стоит их валить напрямую, пользуясь
      временным запретом на ношение оружия.</p>

    <p>Обсуждали, но вроде думали до
      Дмитрия Борстена обождать.</p>

    <p>Выходит, Гошка сам решил с
      университетскими разобраться?</p>

    <p>— А чего им спуску давать? —
      пожал плечами Гошка. — Но это я так, дуркую. Своими
      руками всяко приятнее.</p>

    <p>Андрей своим обычным жестом
      обхватил правой ладонью левое запястье и от этого стал будто даже ещё меньше и
      младше, чем на самом деле. Посмотрел на Гошку, дёрнул губами — вот-вот спросит
      что-то.</p>

    <p>Гошка резко обернулся к нему,
      задрал свою ломаную бровь.</p>

    <p>Андрей тряхнул головой: забей,
      мол, неважно.</p>

    <p>Соций не собирался затыкать
      Андрею рот, но раз тот сам не хочет говорить, пусть не говорит. Сейчас и без
      него есть о чём.</p>

    <p>— Это ты всё к чему? — мрачно
      поинтересовался у Гошки Соций. — Про преимущества решающей встречи-то.</p>

    <p>Гошка усмехнулся. Жёстко так,
      азартно. Совсем уж привычно.</p>

    <p>— К тому, что похеру,
      кто и что сказал или скажет. Имеют значения только действия.</p>

    <p>— Болтовня, — не купился Соций. —
      Сдавал или не сдавал?</p>

    <p>Это важно.</p>

    <p><i>Только</i> это важно.</p>

    <p>Сдавал или не сдавал. Да или нет.</p>

    <p>Хватит выёбываться.</p>

    <p>Бахта и Андрей всё ещё не
      встревали, но Соций чуял, что они тоже хотят услышать уже да или нет и
      перестать ебать себе мозги. Нельзя ничего делать дальше, пока ебёшь себе мозги.</p>

    <p>Гошка выпрямился под взглядами
      трёх голов Бедроградской гэбни:</p>

    <p>— Я думал, у нас тут синхронизация
      и доверие, а ты мне допрос готов устроить из-за спектакля неведомо кого.</p>

    <p>Андрей прям побелел.</p>

    <p>А, у него ж это любимая заноза в
      жопе.</p>

    <p>Дерьмище в гэбне из-за влияния
      посторонних.</p>

    <p>Развал Колошмы, будь он неладен.</p>

    <p>Соций даже пожалел сейчас, что не
      спросил утром у наглой рожи, чё он сам-то про свой развал Колошмы думает. Чё
      наделал и чё с Начальником Колошмы на самом деле устроил, что все в той гэбне
      так обоссались со страху, а искры до сих пор летят.</p>

    <p>Вон у Бедроградской гэбни сейчас
      прямо над головой Институт госслужбы. Там, блядь, этот развал Колошмы теперь к
      экзаменам учат. Соций как-то смотрел программу, ржал как лось. Андрею даже
      подсовывал: похоже, мол, на правду в методичках пишут или как?</p>

    <p>Андрей отмахивался: для
      формирования, мол, первичных представлений о синхронизации и рассинхронизации
      правда не нужна.</p>

    <p>А эта самая правда в своих шелках
      и кружевах в такси у склада села — и привет.</p>

    <p>Точно же всё не так было. Не так,
      как в методичках, и не так, как Андрей рассказывает.&nbsp;Не потому что Андрей
      врёт, а потому что сам не понимает, чё было-то.</p>

    <p>— Слушай меня внимательно, —
      медленно, с нажимом заговорил Гошка. — Я не знаю, по какому ветру улетели годы
      синхронизации и знания друг друга, но я — кто угодно, только не предатель, —
      Гошка подался вперёд. — Если бы я вдруг решил, что в Университете все сплошь лапулечки, которым пора даровать свободу от поганой
      Бедроградской гэбни, я бы в этой гэбне не сидел. Понял меня? На две стороны не
      играю.</p>

    <p>Да понятно, понятно.</p>

    <p>И всегда было понятно: Гошка
      решил — Гошка сделал. Предавал бы, так с музыкой. Вот с той самой
      симфонической, с которой сегодня университетское такси приезжало-уезжало.</p>

    <p>Соций вздохнул.</p>

    <p>В том и дело, что если не
      предавал, но сдал, хуже выходит.</p>

    <p>Не так крут, как все всю жизнь
      думали.</p>

    <p>— Он сказал, — кивнул Соций в сторону
      затихшего магнитофона, — что ты не виноват. Подумай хорошенько, может, где чего
      ляпнул случайно, ошибся…</p>

    <p>— Подумал уже, и не один раз, —
      тут же взбеленился Гошка. —&nbsp;И искренне верил, что каждый из нас подумал,
      вспомнил каждый свой херов день с начала времён, перепроверил все свои действия
      на ошибки. Что, нет? Я один такой сознательный? Оно и видно, — Гошка с грохотом
      встал, его стул пошатнулся и ёбнулся. — За всё, что я делаю, я отвечаю — не
      только как голова гэбни, но и один, сам по себе. И ты, Соций, это прекрасно
      знаешь. Полегчало? — бросил Гошка уже через плечо.</p>

    <p>Уходить собрался.</p>

    <p>— То есть он действительно врёт?
      —&nbsp;ещё раз уточнил Соций.</p>

    <p>И ещё уточнит, и ещё — хоть в
      сотый, хоть в тысячный раз. До тех пор будет уточнять, пока не услышит прямого
      ответа.</p>

    <p>Да или нет.</p>

    <p>Гошка обернулся у самой двери,
      долгим и непонятным каким-то взглядом окинул Соция. Проигнорировал Бахту,
      который вскочил его останавливать, проигнорировал даже Андрея, который усиленно
      захлопал на него своими вечными ресницами.</p>

    <p>Да или нет?</p>

    <p>— Вот я и говорю, — Гошка перевёл
      взгляд с Соция на утыканную гвоздями карту Бедрограда, — зачем спрашивать меня,
      если ты уже определился, кому тебе больше нравится верить?</p>

    <p>Да или нет, блядь!</p>

    <p>— Просто подтверди, и я поверю<i> тебе</i>, — прорычал Соций. — Чтобы ты
      перестал ломать комедию, я, блядь, должен начать тебе пальцы ломать по очереди?
      Отвечай: да или нет?</p>

    <p>Гошка улыбнулся. Опять — едко,
      презрительно.</p>

    <p>— Всегда додавить до конца, такой
      хороший голова гэбни, — мерзейшим тоном проворковал он. — Где ж твои навыки
      были, когда они в самом деле пригодились бы, — Гошка махнул рукой в сторону
      магнитофона. — Я хотел совсем не так, но разве ж мне оставят выбор?</p>

    <p>Да или нет — или уже пару пуль в
      размахавшуюся руку!</p>

    <p>Потом залечат, если что.</p>

    <p>Гошка толкнул дверь, постоял перед
      ней молча ещё пару секунд.</p>

    <p>— Да, Соций, он врёт. Я никому
      ничего не сдавал. Утри уже сопли ужаса и займись каким-нибудь полезным делом.</p>

    <p>И вышел вон.</p>

    <p>«Шелка и кружева не забудь
      нацепить», — едва не заорал ему в спину Соций.</p>

    <p>Потому что, блядь, вот это и есть
      форменные шелка и кружева — по полчаса на простые вопросы не отвечать, а
      ответив, дверьми хлопать!</p>

    <p>Зла не хватает.</p>

    <p>Ладно бы Гошка сказал «нет, не
      врёт». Тогда бы пусть сколько угодно хлопал, когда стыдно — можно. Но если «да,
      врёт», если не за что стыдиться — то какого лешего морду воротить?</p>

    <p>Оскорбили, блядь, подозрениями?</p>

    <p>С каких, блядь, пор мы такие
      хрустальные?</p>

    <p>Бахта, прихуев, протормозил чуть-чуть, а потом таки рванулся за Гошкой.</p>

    <p>— Не стоит, — сказал Андрей.</p>

    <p>Не сказал даже, а буквально
      попросил.</p>

    <p>— Это ещё почему? — не понял
      Бахта, остановившись на лестнице.</p>

    <p>Андрей закрыл лицо рукой,
      растерянно потёр глаза.</p>

    <p>— Потому что не стоит, правда, —
      звучал он подавленно и как-то жалко. — Пусть отойдёт. Это же не очень просто,
      когда тебя свои подозревают.</p>

    <p>Вот, пожалуйста, ещё один.</p>

    <p>Подозревают, не подозревают —
      рот, блядь, им на что? У Университета день за днём отсасывать?</p>

    <p>Одно слово <i>вовремя</i> — и никаких проблем.</p>

    <p>— Неспокойно как-то, что он один
      неведомо куда упрётся, — неуверенно возразил Бахта.</p>

    <p>«Чё ему сделается?» — едва не
      поржал Соций, но понял: не над чем ржать.</p>

    <p>Бахта не о том. Или — о том и не
      о том сразу, сам не знает.</p>

    <p>Потому что о том, что Гошке
      что-то сделается, беспокоиться глупо.</p>

    <p>Беспокоиться можно о том, что
      Гошка что-то <i>сделает</i>.</p>

    <p>Потому что может. Потому что если
      Гуанако сказал, что Гошка их сдал, а Гошка ломался и не захотел сразу ясность
      навести, один леший знает, что на самом деле было.</p>

    <p>И это поганая мысль.</p>

    <p>Поганая мысль, нарисовавшаяся
      сейчас на лбу у всех трёх оставшихся в подвале голов Бедроградской гэбни.</p>

    <p>Только обсуждать её никто не
      будет.</p>

    <p>Если начать, уже всё — можно
      сразу доставать ёбаный бланк для запросов на ПН4. Кому это надо, блядь?
      Вернётся Гошка — все вместе обсудят поганую мысль. Попробуют ещё раз. Или не
      обсудят, ладно.</p>

    <p>Можно ведь и обождать до
      завтрашней ночи.</p>

    <p>Соций не сомневался: Гуанако
      приведёт реальную университетскую власть к Бедроградской гэбне. И с большой
      вероятностью придёт сам. Вот тогда и стоит смотреть, что скажет Гуанако, что
      скажет Гошка и кто тут врёт.</p>

    <p>Да, Соций хотел бы разобраться <i>до</i> финальных переговоров. Но как
      разберёшься, если в ответ на простой вопрос тебе перед носом дверьми хлопают,
      блядь?</p>

    <p>В подвале зазвонил телефон,
      Андрей немедленно схватился за трубку.</p>

    <p>Тут имеет смысл немедленно
      хвататься — это ж не номер приёмной Бедроградской гэбни и даже не номера
      кабинетов в официальном здании. Этого номера никто, кроме специальных
      диспетчеров, не знает, а те соединяют, только если предварительные указания
      были.</p>

    <p>Сегодня — были.</p>

    <p>— Спасибо, — лучезарно улыбнулся
      трубке Андрей, но помрачнел, как только положил её обратно на рычаг.</p>

    <p>— От Гошки? — с дурацкой надёждой
      спросил Бахта.</p>

    <p>— Из лаборатории, — недовольно
      отмахнулся Андрей. — Анализы ничего не дали.</p>

    <p>Как только Соций ещё до
      прослушивания записи сознался, что Гуанако вёл себя поначалу сомнительно и на
      него, Соция, это произвело сомнительный же эффект, Андрей схватился за шприц.</p>

    <p>«Не колол он меня ничем, не
      кормил и не поил!» — возмущался Соций.</p>

    <p>«Как будто это все возможные
      способы, — дёргался Андрей. — Я не знаю точно, но Медкорпус же каких только
      разработок не ведёт!»</p>

    <p>«Университет не Медкорпус», —
      продолжал сомневаться Соций.</p>

    <p>«Университет не Медкорпус, а
      потом в Инфекционную Часть приходят фаланги, тыкают меня носом в какого-то
      Дмитрия Ройша, а девка настоящего Ройша как раз в это время разводит Гошку
      запустить эпидемию», — фыркал Андрей, и Соций покорно закатывал рукав для
      забора крови.</p>

    <p>Всё равно сомневался, но пусть уж
      лучше Андрей тратит время лабораторий на бесполезные анализы, чем свои мозги на
      догадки и предположения.</p>

    <p>Ну вот: лаборатории ничего не
      нашли, нечего предполагать.</p>

    <p>— Это ещё ничего не значит, — сам
      с собой говорил Андрей, уставившись на телефон. — Времени прошло достаточно…
      существуют препараты, которые разлагаются и с такой скоростью… но чтоб без
      укола... немыслимо, просто немыслимо.</p>

    <p>— Кончай, — раздражённо прервал
      его размышления Соций. — Даже если ты прав и меня леший знает как наркотой
      угостили, что с того? Нам это хоть что-то даёт? Встреча состоялась, всё. Под
      наркотой, не под наркотой — я сказал то и услышал это. И ничего уже не
      изменится, даже если в анализах что-то найдут.</p>

    <p>Андрей посмотрел на Соция
      удивлённо.</p>

    <p>— Не изменится, — машинально
      повторил он, но тут же очнулся: — Нет, изменится! Мы будем знать, к чему
      готовиться, чего ждать, какими средствами они обладают…</p>

    <p>— Снаряд в одно и то же место
      дважды не падает, — отмахнулся Соций. — Они не дураки уже засвеченный манёвр
      повторять.</p>

    <p>Бахта, который всё это время
      задумчиво разглядывал карту, вдруг обернулся с улыбкой от уха до уха:</p>

    <p>— Андрей, если тебя это так ебёт,
      пусть твои лаборатории не в анализах копаются, а справочники листают. На
      предмет черёмухи, — Бахта так обрадовался своей догадке, что чуть не прыгал. —
      Не зря ж он так вонял!</p>

    <p>— Леший, — хлопнул себя по лбу
      Андрей. — Только я ни о каких наркотических свойствах черёмухи ни разу не
      слышал, но мало ли, в состав чего она может входить, — он снова поднял трубку,
      зажурчал в неё нежным голоском.</p>

    <p>Ухватился за черёмуху, как будто
      она может решить какие-то проблемы.</p>

    <p>Соций курил, ожидая, пока Андрей
      закончит телефонный инструктаж. Не со всеми вопросами ещё разобрались.</p>

    <p>Бахта, глядя на Соция, потеребил
      один из гвоздей, вбитых в карту.</p>

    <p>— Завкафский дом, —
      безапелляционно заявил Соций, когда Андрей освободился.</p>

    <p>— Смирнов-Задунайский,&nbsp;— не
      менее весомо возразил Андрей.</p>

    <p>Вот далась ему эта кассахская
      шлюха. К тому же мёртвая.</p>

    <p>— Вроде ж закрыли тему, — вслух
      поддержал Соция Бахта. — На нервах они тебе играли, а ты и повёлся.</p>

    <p>Андрей недовольно покосился на
      Бахту. Ругнулся одними губами, но этого, видать, оказалось мало, потому что в
      следующую же секунду он дёрнулся всем корпусом, полсекунды подумал, выхватил
      вдруг из кобуры пистолет и истерично всадил три патрона подряд в и так уже
      обстрелянный многоугольник истфака. Карта в этом месте окончательно
      превратилась в лохмотья.</p>

    <p>Соций хмыкнул: а с виду такой
      спокойный, улыбчивый и дружелюбный мальчик.</p>

    <p>— Вы не понимаете, — Андрей
      прикрыл глаза, унял дрожь и скромно отложил пистолет, выпрямился этак по-отрядски,
      — это же 66563. Он врёт как дышит, его нельзя
      слушать.</p>

    <p>Соций негромко хуякнул по столу. Скорее для порядка, чем от раздражения.</p>

    <p>— Кто тут ещё чего не понимает, —
      к Социю опять вернулся тон, которым приказы отдают. — Это тебе он 66563, а мне
      — сержант Гуанако, зелень необстрелянная, которой я глаза бинтом завязывал,
      когда мы вдвоём между британскими постами застряли. Я чую, когда он пиздит, а
      когда нет.</p>

    <p>— Да не будь ты таким дураком! —
      Андрей одновременно скривился и взмахнул ресницами, как только он и умеет. —
      Чуешь? Тогда скажи, про Гошку он пиздит или нет?</p>

    <p>Не пиздит.</p>

    <p>Андрей осёкся, сам всё прочитал
      на лице Соция.</p>

    <p>— И потом, — продолжил Андрей,
      чтобы только не сидеть сейчас в красноречивом молчании, — почему, ты думаешь,
      он к тебе на встречу сунулся? Они ведь могли любого своего не слишком
      публичного человека за Дмитрия Борстена выдать, а пошёл всё равно 66563. Это им
      невыгодно — такой козырь раскрывать. Ещё раз: почему всё же раскрыли? Потому
      что он знал, что идёт к тебе, а ты ему поверишь.</p>

    <p>— Рот захлопни, — набычился
      Соций. — Может, где-то он меня и наебал. Но что касается твоего ёбаного
      Смирнова-Задунайского, на котором ты, видать, свихнулся, — тут всё чисто. Мы о
      таких вещах говорили, которые мирному населению не понять.</p>

    <p>Андрей от «мирного населения» аж
      поперхнулся. Он сам любит людей на сорта делить: у него кто в госаппарате не
      служил, тот «гражданский», жизни не видевший.</p>

    <p>А у Соция свои сорта, ничего не попишешь.</p>

    <p>— Не вставайте вы на дыбы, ещё не
      хватало, — прикрикнул Бахта.</p>

    <p>Вернулся к столу, отодвинул
      магнитофон, водрузил на его место бутылку пахучего джина. Верный расчёт: джин
      Бедроградская гэбня пьёт, когда можно расслабиться, джин пахнет хвоей, Андрей
      пахнет хвоей — это у него духи такие. Команда «можно расслабиться»
      непроизвольно пробежала по всему телу.</p>

    <p>Соций ещё раз посмотрел на
      Андрея: нечего на него срываться. Ну не врубается и не врубается, велика беда.</p>

    <p>— Соций, я не понимаю, но я
      стараюсь, — примиряющим жестом протянул бутылку Андрей. — Но и ты постарайся
      понять: я этого человека видел в совсем иных обстоятельствах.</p>

    <p>— Всё мы знаем о твоих
      обстоятельствах, — беззлобно хмыкнул Соций. — Твой Начальник Колошмы был
      ненормальный. То, что он ёбнулся, — это не гуанаковская
      заслуга, вот хоть режь меня.</p>

    <p>Андрей торопливо закивал: как
      угодно, мол, согласен. Но когда сам сделал глоток джина, осторожно продолжил:</p>

    <p>— Начальник Колошмы… Савьюр
      служил на Колошме на тот момент на полгода дольше, чем я на свете жил. Он был
      плохой голова гэбни, но тюремщик он был классный. Да, он фактически проводил
      допросы в одиночку, никто больше ни слова не говорил. И это бред, а не работа
      гэбни, — Андрея передёрнуло. — Но он в одиночку допрашивал так, как многие
      вчетвером не смогут. Насквозь видел заключённых, говорил им про них самих такие
      вещи, что они только глаза таращили.</p>

    <p>— И? — поторопил его нетерпеливый
      Бахта.</p>

    <p>— 66563 умудрился обмануть даже
      Савьюра, — сказал Андрей.</p>

    <p>Сказал, и все подумали: то есть
      мог обмануть и Гошку.</p>

    <p>Потому что не о том, обманул ли
      он утром Соция, они на самом деле говорят, ох не о том.</p>

    <p>— Да я уже вообще запутался, чего
      там было, — неприкрыто соврал Бахта, чтобы Андрей смог уже наконец излить душу.</p>

    <p>Соций готов был заржать: Андрей
      почти десять лет в Бедроградской гэбне — самое время Бедроградской гэбне
      узнать, чего у него на Колошме произошло. Неужто и впрямь что-то новое
      расскажет?</p>

    <p>— Ну вы же читали расшифровки, —
      промямлил Андрей, как будто вдруг передумал изливать, захотел свернуть
      разговор.</p>

    <p>— Да ладно тебе пятиться, —
      подтолкнул Соций.</p>

    <p>Андрей пошарил руками по столу,
      начал без толку перебирать предметы. А вот и его вечная белочка, давненько не
      видели!</p>

    <p>Хорошее слово — «белочка». И про
      беличьи нервные лапки, и про суматошность белой горячки. Очень точно состояние
      Андрея описывает.</p>

    <p>Гошка давно ещё придумал его так
      описывать.</p>

    <p>Гошка.</p>

    <p>— В общем, мы… я, — только
      заговорил, а уже запутался Андрей. — Мы действительно собирались Савьюра
      отравить. Нет, не отравить — положить под препаратом… и нет, не собирались, но
      подготовились. Там была сложная ситуация: Савьюр послал неофициальный запрос по
      своим каналам по поводу одной из версий предположительного обвинения 66563.
      Сразу, после самого первого допроса, на котором они договорились, что раз у
      66563 такое нелепое обвинение, которое надо допиливать, то допиливать будут
      совместно. В смысле Савьюр и 66563. Потому что 66563 не хотел, чтоб за него
      кому-то ещё досталось — этим его бывшим студентам, например. А шансы были,
      дело-то серьёзное. 66563 сам предложил, в какую сторону копать, и Савьюр послал
      запрос, — Андрей говорил медленно, сбивался и повторялся. — Ответ пришёл через
      месяц, когда всё уже было леший знает как. Савьюр от допросов 66563 ходил сам
      на себя не похожий. Все, кто говорят, что он по жизни был не в себе, просто не
      видели его тогда. Так получилось, что этот ответ с гэбенной почты забирал я. Я
      не думал его скрывать сначала, но потом опять допрос, опять 66563, и стало понятно:
      Савьюр его оправдает. Вывернет факты так, чтобы были основания выпустить. А это
      никуда, никуда не годилось! Мне на моём тогдашнем седьмом уровне доступа
      казалась, что указания с шестого — от Столичной-то гэбни! — это очень серьёзно.
      Казалось, что они гэбню Колошмы просто сожрут, если 66563 из тюрьмы выйдет.</p>

    <p>В этом месте Соций и Бахта хором
      загоготали.</p>

    <p>Как раз когда разваливалась
      Колошма, прежний состав Бедроградской гэбни ездил популярно объяснять гэбне
      Столичной, что она неправа.</p>

    <p>Попытаться завербовать человека
      из БГУ им. Набедренных, а получив отказ, засадить его пожизненно на Колошму —
      это ж надо было додуматься! Не видать им завербованных людей на территории
      Бедроградской гэбни (тогда-то и Университет был их территорией). Вот как
      Гуанако сел, отказавшись, а история выплыла, Бедроградская гэбня и начала
      пристально следить, к кому и зачем столичные подкатывают.</p>

    <p>Но за тот раз столичные тоже
      получили. Пули в две из четырёх голов. И ничего, фаланги не переломились
      закрыть глаза. Хотя стрёмно было, думали, просто так с рук не сойдёт. Но Гошка
      сказал: «Похер, достало, едем убивать».</p>

    <p>Гошка. Опять Гошка, да что ж
      такое.</p>

    <p>— Я уговорил остальных голов
      гэбни придержать для Савьюра препарат… ну, в общем, фактически это был почти
      что яд, — продолжил в пустоту Андрей. — Такой, медленного действия. Чтоб
      остановить его и выключить из ситуации, если что, но чтоб можно было и
      откатить, выдать за неожиданный приступ болезни естественного происхождения.
      Никто не собирался убивать Савьюра! Просто хотелось иметь тормоз — на случай,
      если Савьюр выкинет что-нибудь. Например, начнёт убеждать 66563, что тому стоит
      валить с Колошмы — официально или неофициально. Или ещё что-нибудь, он же был
      совершенно с катушек съехавший, — Андрей задумался. — А в результате вообще
      пиздец получился. 66563 на последнем допросе развлекался, как мог. Там же всё
      было: от секса с охранником на гэбенном столе до исповеди с просьбой
      застрелиться. Исповедь проникновенная вышла. Мол, не могу больше, мол, выбрал
      Колошму вместо вербовки у столичных не потому что идейный, а потому что в
      бедроградской жизни сплошное дерьмо. Мол, сбежал от этой жизни аж на Колошму, а
      вы, мол, Юр Саввович, только и делаете, что издеваетесь. Напоминаете, мол,
      Бедроград, соблазняете, мол, вернуться. А я, мол, не хочу, устал, тошно мне,
      даже на Колошме нет успокоения. Повесился бы, мол, так вы ж верёвки не дадите,
      — Андрей хватил ртом воздуха, как будто собирался нырять. — А Юр Саввович в
      ответ на это дал ему свой табельный пистолет.</p>

    <p>Соций нахмурился: то, что Андрей
      на «Юра Саввовича» сбивается, означает, что его совсем накрыло.</p>

    <p>А про табельный пистолет, который
      по протоколу и проносить-то нельзя в помещение для допросов, не то что
      заключённому в руки давать, все и так знают. Весь Институт госслужбы, под
      которым Бедроградская гэбня сейчас сидит, эту байку на экзаменах пересказывает.
      Без имён и лиц — им по уровню доступа пока не положено.</p>

    <p>— Погоди, а чего он стрелялся? —
      спросил Бахта. — В смысле, как убедил пистолет-то ему дать?</p>

    <p>Андрей улыбнулся с нездоровым
      видом, сверкнул глазами на Соция:</p>

    <p>— Кассахские шлюхи. Его же — для
      поддержания официальной легенды о пропаже в экспедиции — всё время заставляли
      письма в Бедроград писать. Значимым людям. Он увиливал, писал кому мог — вплоть
      до Хикеракли. Значимых людей не хотел подставлять, что, в общем-то, понятно.
      Делал вид, что таковых не имеется. А Савьюр давил, пробовал версии, врал про
      дела в Бедрограде, чтоб нащупать, на что 66563 среагирует. И это было бы
      нормально, если б Савьюр хоть во что-то ставил столичные указания. Но он <i>для
        себя</i> давление устраивал, а мы все
      слишком поздно это разглядели, — закусил губу Андрей с таким видом, будто б
      жалел о тогдашних промахах до сих пор. — Тут немного дезинформации, там немного
      задушевных бесед и неприятных вопросов психологического толка. 66563 нервничал,
      а потом стало уж совсем смешно: он же наглый, посмел попросить, чтоб ему
      охранника прислали в его одиночку. Как Гошка бы сказал, «для психической
      разгрузки», — ляпнул Андрей и сам не порадовался. — Ну, в общем, у охранника
      обнаружились некоторые сходства с искомыми значимыми людьми. Несущественные, но
      достаточные, чтоб решить, что Савьюр издевается. И 66563 этим воспользовался:
      на том самом последнем допросе напустил туману про свои бедроградские драмы,
      попрекнул тем, что ему на раны чего только не сыплют на этой поганой Колошме. И
      Савьюр ёбнулся. От мук совести, ещё от чего-то — не знаю. Дал пистолет.</p>

    <p>Соций улыбнулся Андреевой
      избирательной памяти.</p>

    <p>То, что Начальник Колошмы
      пистолет в помещение для допросов вообще принёс, потому что своей гэбне на тот
      момент уже не доверял, Андрей упоминать не стал.</p>

    <p>Как и то, что пистолет не был заряжен
      предварительными стараниями Андрея, который в свою очередь не доверял
      Начальнику Колошмы. Тот ведь не в своём уме был, к табельному оружию до самого
      развала Колошмы не прикасался. У него крыша ещё когда поехала как раз на почве
      убийств — да так поехала, что он не только без оружия ходил и физических
      пыток к заключённым не применял, он даже голос, говорят, не повышал. Псих.</p>

    <p>— Дальше все всё знают, — опять
      поторопил Андрея Бахта. — В обойме патронов нет, 66563 от пустого щелчка у
      виска хуеет, Начальник Колошмы сам идёт его провожать до камеры, остаётся там
      поговорить и в итоге зависает на два дня. С вызовом Комиссии по Делам Гэбен,
      состоящей сплошь из его приятелей, которой он ставит бредовые условия: перенабрать гэбню Колошмы с нуля, а его, Начальника, под
      любым официальным предлогом оставить в камере 66563. Пожизненно, — Бахта поржал
      себе под нос. — Слышь, Соций, ты не переживай так, что под эротическим
      давлением сдал ему нас с потрохами. Некоторые вон в пожизненное заключение
      сдавались, чтоб и дальше эротически давил! Пиздец какой, познакомиться, что ль,
      поближе с этим выдающимся человеком?</p>

    <p>Андрей фыркнул, демонстрируя своё
      отношение к дурацким шуточкам.</p>

    <p>— Ты зря вперёд забежал, —
      раздражённо заткнул он Бахту. — Я не для того всё это рассказываю, чтоб мы тут
      дружно порыдали над трагической несвоевременностью гибели Савьюра.</p>

    <p>— А для чего, собственно? — Соций
      уже устал от бесконечной Колошмы, не имеющей отношения к текущим событиям.</p>

    <p>— Короче говоря, — едва не
      прикурил сигарету не тем концом Андрей, — впоследствии выяснилось, что
      стрелялся 66563 не потому, что ему бедроградские драмы даже на Колошме забыть
      не дают. Стрелялся он, потому что увидел, как мы переговариваемся ногами про
      препарат для Савьюра и про то, что 66563 нам нужен живым, чтоб с обвинением всё
      срослось. А Савьюр хочет не обвинения, а непонятно чего, поэтому и препараты
      пора подключать. То, что 66563 так не вовремя оказался на полу и ноги смог
      увидеть, и то, что он вообще способен кое-как гэбенный
      тактильный код считывать, — это ещё ерунда, — Андрей несколько раз подряд
      глубоко и со вкусом затянулся. — Настоящий кошмар в том, что он своей смертью
      хотел спасти Савьюра от наших действий. Не понял, что никого мы не убиваем, что мы так — предохранить от
      глупостей. Вот 66563 и решил, что если он сам
      сдохнет, проблема хоть как-то снимется. Вроде как Савьюру делить с остальными
      головами будет нечего. И даже правильно решил: сдохни он тогда, всё бы
      по-другому закончилось.</p>

    <p>Вот теперь Социю стало как-то
      даже слишком ясно, почему Андрей так не любит вспоминать, кто пистолет
      Начальника-то разрядил.</p>

    <p>— Но он же не мог прямо сказать,
      чего вдруг помирать вздумал, — зло и очень непохоже на самого себя усмехнулся
      Андрей. — Он своими россказнями про бедроградские драмы развёл Савьюра как
      последнего двоечника из института, — Андрей кивнул в направлении потолка. —
      Савьюр купился. <i>Савьюр,</i> не
      кто-нибудь. Савьюр, который с двадцати пяти до сорока пяти просидел на
      допросах, который спектаклей от заключённых каких только не видел. Который
      хотел 66563 на свободу выпустить с полной реабилитацией и мешком гостинцев.
      Который слишком болезненно к смертям относился, чтоб вот так просто кому-то
      верёвку намыливать, то есть пистолет давать. Который — осознайте вы оба
      наконец! — любую лажу за километр чуял, как будто у него вместо мозга детектор
      лжи, а вместо дыхания — газообразная сыворотка правды!</p>

    <p>— Обороты сбавь, больно пафосно
      выходит, — осадил его Бахта, сунул прямо в руку бутылку джина.</p>

    <p>— Поймите вы, — сделав приличный
      глоток, гораздо спокойнее закончил Андрей, — после того, как 66563 у меня на
      глазах умудрился обмануть <i>даже Савьюра,</i> я больше ни единому его слову верить не готов. Без сотни перепроверок уж точно.</p>

    <p>Донышко бутылки веско стукнулось
      об стол.</p>

    <p>— Перепроверок так перепроверок,
      — покладисто ответил Соций. — Как предлагаешь перепроверять, например, свои
      подозрения насчёт Смирнова-Задунайского?</p>

    <p>Пока Андрей собирался с мыслями,
      Бахта успел сообразить за него:</p>

    <p>— До личности Дмитрия Ройша мы
      так сразу не доберёмся, а с Дмитрием Борстеном всё просто, — Бахта схватил со
      стола джин, весело опрокинул в себя с четверть бутылки. — Университетского
      медика должны знать в лицо университетские. Не гэбня, не их служащие, не
      «реальная власть», а простые университетские. Завязанные в деле, но морально
      нестойкие. Да хоть этот, как его? Стрём?</p>

    <p>— Шухер, — поправил Соций.</p>

    <p>— Шухер, да, — подхватил Бахта. —
      Мы же от него и узнали, что есть в Университете некто Дмитрий Борстен. Он
      должен его опознать, если найти подход и правильно спросить!</p>

    <p>— Я займусь, — благодарно кивнул
      Андрей, мигом стал собранным и деловитым.</p>

    <p>Соций был уверен: Дмитрий Борстен
      — не Дмитрий Смирнов-Задунайский. Гуанако не стал бы <i>так</i> врать, а значит, кассахская шлюха давно того. Но осуждать Андрея
      за поиски мёртвой кассахской шлюхи Соций не мог. Он
      врубился, наконец, что всё это значит для Андрея: если доказать, что Гуанако
      врёт хоть в чём-то, легче будет поверить, что Гуанако врёт про Гошку.</p>

    <p>Так что пусть Андрей ищет кого
      хочет. Тут уж у каждого свои методы бороться со страхом подставы.</p>

    <p>— И всё-таки, завкафский дом, —
      вернулся Соций к вопросу, который беспокоил его с самого начала больше всего.
      Гошка не считается.</p>

    <p>Андрей сделал кислую мину:</p>

    <p>— Опять то же самое. Если 66563
      сказал, что в Университете про канализационное заражение не знают, ничего это не
      значит. Вообще ничего.</p>

    <p>— Ну ты ещё скажи, что он и
      заражал, — ухмыльнулся Бахта, которого явно подзаебали
      бесконечные сомнения во всех подряд сведениях. — А лучше — что твой Борстен-Задунайский-Смирнов-Ройш!</p>

    <p>Соций чуток погипнотизировал
      многократно простреленную карту Бедрограда на стене, вытряхнул последние мысли о
      Гошке, собрал мозги в кучу и сказал:</p>

    <p>— Забейте на
      Борстена-Задунайского, есть версии пореалистичней.</p>

    <p>Бахта и Андрей повернулись к
      нему.</p>

    <p>— Сразу ведь было очевидно, что
      надо делать, но мы почему-то рванули не в ту степь, — начал Соций и понял, что
      избавляться от мыслей о Гошке таки не стоило.</p>

    <p>Всё равно за ним сейчас бежать
      придётся, куда бы он там ни свалил, — ну не пойдёт же Бедроградская гэбня на такое
      дело втроём!</p>

    <p>Да и джин втроём пить как-то
      невесело.</p>
  </>
}

export default chapter30
