import React from 'react'
import { IBookPage } from './index'

const chapter28: IBookPage = {
  header: 'header_d8.jpg',
  title: 'Глава 28. О кто, о кто мог угадать',
  prevLink: 'chapter27',
  nextLink: 'chapter29',
  content: <>
    <h2>Университет. Охрович и Краснокаменный</h2>

    <img alt='' src="/images/28_okhrovich_i_krasnokamennyi.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>ТРУПЫ ТРУПЫ ТРУПЫ РУКИ НОГИ
      ОТОРВАННЫЕ ГОЛОВЫ ДРУГИЕ ЧАСТИ ТЕЛА</p>

    <p>ДА</p>

    <p>ДА</p>

    <p>ДААА</p>

    <p>Охрович и Краснокаменный вскочили
      в такси сразу, как только дотуда доплёлся Гуанако
      (слабый, ничтожный, с вымученной улыбкой — <i>фу</i>).
      Они там с Социем мило поворковали.</p>

    <p>Голубки.</p>

    <p>Пташки.</p>

    <p>У Гуанако всю жизнь ДЕПРИВАЦИЯ
      НАСИЛИЯ, ему только дай тряхнуть старым добрым армейским.</p>

    <p>Быть профессором — стыыыыыыыдно.</p>

    <p>Охрович и
Краснокаменный никогда не будут профессором (-ами?
      КАКАЯ РАЗНИЦА ВСЁ РАВНО НЕ БУДУТ).</p>

    <p><b style={{ color: '#C00000' }}>Прозябать в доцентах</b> — удел
      лучших людей Бедрограда. <i>(*это
        эротическое высказывание?)</i></p>

    <p>Гуанако плюхнулся на переднее
      сиденье. Охрович и Краснокаменный (—?) на заднее.
      Отвесили сиденью Мули Педали пинков, тот сразу газанул,
      умненький. Врубил в открытые окна свою вечную Кармину
      Бурану.</p>

    <p>Мимо бледной
      Бедро-гадской гэбни с песнями и танцами!</p>

    <p style={{ fontSize: '16.0pt' }}>залихватски</p>

    <p>— Каждая секунда в этой рубашке
      влетает вам в грифончика, — сообщили Охрович и
      Краснокаменный Гуанако.</p>

    <p>— Мы создали ваш образ с нуля.</p>

    <p>— Вы <i>должны</i> нам.</p>

    <p>— Оплата натурой приветствуется.</p>

    <p>— Только не в образе, а то
      никогда не расплатитесь.</p>

    <p>— Впрочем, разве кто-то может
      желать иного будущего?</p>

    <p>Стыренная у Мули Педали самокрутка с гуанаковской рукой на буксире упрямо
      взметнулась (из любви упрямо метаться):</p>

    <p>— Не округляйте, не с нуля!</p>

    <p>ПоПрАл!
      (сущ.)</p>

    <p>Этот попрал циклится
      на мелочах. Неважных деталях. Охрович и Краснокаменный
      и сами бы предложили что-нибудь в таком духе.</p>

    <p>А всё потому, что он теперь водит
      дружбу с <b>большими шишками</b> (пятый уровень
      доступа — это вам не шестой, прозябать вас в доцентах!). Голова
Медицинской гэбни приехал в Бедроград мучить и истязать Диму, а тот на пару с
Гуанако задурил ему голову. Капитально развёл на бабки. В основном
      Гуанако разводил, конечно. Разводила (сущ.). Он же умный.</p>

    <p>&nbsp;</p>

    <p>КАК ЭТО БЫЛО</p>

    <p><i>(радиопьеса)</i></p>

    <p>&nbsp;</p>

    <p>А потом Гуанако такой говорит:
      итак, уважаемый, я вас развёл, я же умный. Больше у меня нет времени тут лясы
      точить, вон он <i>(указывает на Диму)</i> должен
      сейчас кое с кем встречаться. Так что я пошёл.</p>

    <p>Голова Медицинской
      гэбни такой: Куда пошёл? Может, ещё немного меня поразводите?
      У меня богатый репертуар.</p>

    <p>Гуанако такой: Встречаться и
      беседовать. Кое с кем.</p>

    <p>Голова Медицинской
      гэбни такой: По-моему, вы что-то путаете.</p>

    <p>Гуанако такой: Да похуй, всё
      равно меня убьют, это мой бывший армейский командир, я пред ним робею.</p>

    <p>Голова Медицинской
      гэбни такой: Дак давайте я
      вам дам!</p>

    <p>Дима такой: Я ревнив и протестую.</p>

    <p>Гуанако такой: Охрович и
      Краснокаменный в другом месте.</p>

    <p>Охрович и Краснокаменный (в
      другом месте) такие: Мы в другом месте!</p>

    <p>Голова Медицинской
      гэбни такой: Я ограничен и не понимаю интеллектуального юмора, но имел в виду
      экспериментальные психотропные пилюли, вызывающие временное помутнение рассудка
      и рассеивание внимания.</p>

    <p>Охрович и Краснокаменный (в
      другом месте) такие: <i>Пихто</i>тропные.</p>

    <p>Голова Медицинской
      гэбни такой: Давите капсулу у реципиента перед лицом, и всё, полчаса он сам не
      свой. Если вас страшит беседа — самое оно. Один недостаток, воняют
      латиноамериканскими лианами, потому их в массовое производство пока и не пустили.
      Собственно, потому они мне и самому не сдались. Берите, я вам даже приплачу, и
      так весь карман провонял.</p>

    <p>Гуанако такой: На что эти ваши
      латиноамериканские лианы похожи хоть?</p>

    <p>Голова Медицинской
      гэбни такой: На толстых довольных удавов с цветочками.</p>

    <p>Гуанако такой: Я имел в виду по вони.</p>

    <p>Голова Медицинской
      гэбни такой: А. Ну. Из росского? Пожалуй, на
      черёмуху.</p>

    <p>Гуанако такой: ЧЕРЁМУХУ ГОВОРИТЕ</p>

    <p>&nbsp;</p>

    <p>Хрупкую и нежную, как Димина
      самооценка, веточку черёмухи мигом притащил из Порта Муля Педаль. Веточку
      цветущей (и пахнущей) черёмухи. В сентябре!</p>

    <p>Глубоко же он смотрит Гуанако в
      рот, по самые гланды.</p>

    <p>Как и все. (Кто
это сказал? Изгнать коллаборациониста! Охрович и Краснокаменный —
      самостоятельные и независимые личности, они никому никуда не смотрят! А если
      смотрят — то только профессиональным глазом. Он у них один,
по чётным дням месяца принадлежит Охровичу, по нечётным — Краснокаменному.)</p>

    <p>Вонючую пихтотропную капсулу в волосах (тонкой
      полоской пластыря к
      уху) замаскировали хрупкой цветочной веточкой. А то если б посреди разговора от
      Гуанако черёмухой заблагоухало, даже тупая армейская башка
      заподозрил бы его в худшем. Надо сразу маскировать хрупкую цветочную веточку.
      Чтобы в худшем не заподозрили заранее. То есть чтобы
      заподозрили и потом уже не дёргались.</p>

    <p>И за этим Гуанако молитвенно
      приполз на коленях к Охровичу и Краснокаменному, и такой говорит: нет, ребята,
      это недостаточный материал для отдельной пьесы. Давайте кратенько.</p>

    <p>Натуральный попрал (вид тучного
      горного козла), только хилый.</p>

    <p>В общем, Гуанако попросил сделать
      с ним что-нибудь, чтоб никто не удивился веточке черёмухи в руках.</p>

    <p>И они сделали.</p>

    <p>Они <b style={{ color: '#C00000' }}>создали</b>.</p>

    <p>Он сперва
      ныл, что <i>вот так</i> как раз не умеет.
      Даже косу отказывался заплетать (пришлось повелеть Муле
      Педали лично попросить его об этом). А теперь вон явно втянулся, не спешит из
      рубашки-то выскакивать.</p>

    <p>Даже усталую сонность всем телом
      изобразил, чтоб подольше из неё не вылезать.</p>

    <p>Депривация
      агрессии принимает странные формы, да, да, да, постыдный профессор?</p>

    <p>— И как вам единение братьев по
      оружию? — вопросили Охрович и Краснокаменный.</p>

    <p>— Мы же видим, что вы откинулись
      и закурили.</p>

    <p>— Встреча прошла
      удовлетворительно?</p>

    <p>— Расскажите нам все грязные
      подробности.</p>

    <p>— Более чем удовлетворительно, —
      Гуанако выдохнул дым в крышу машины, самодовольный. — Брат по оружию желает
      повторить, но уже не со мной, а с <i>реальной
        университетской властью</i>. Без бюрократии, зато с пушками. Завтра ночью, в
      том же месте.</p>

    <p>— Это сложно, — нахмурились
      Охрович и Краснокаменный.</p>

    <p>— Мы не уверены в своей
      готовности соорудить в том же месте горы за сутки.</p>

    <p>— Да и запас кассахов у нас
      ограничен.</p>

    <p>— Теперь — совсем ограничен, братскими же стараниями.</p>

    <p>— Одну только форму ИВА шить —
      полночи уйдёт.</p>

    <p>— Он хорошо подумал?</p>

    <p>Гуанако потеребил косу <i>(сознался бы уже в своих страстях!!)</i>.</p>

    <p>— Забейте на форму, он же не один
      пойдёт. Всю Бедроградскую гэбню кассахами не купишь.</p>

    <p>Ах, так он не о войне в Северной
      Шотландии? А то Охрович и Краснокаменный на секунду подумали, что это он о
      войне в Северной Шотландии!</p>

    <p>Разборки с <i>реальной властью</i> — это хорошо. Одна формулировочка
      заставляет предвкушать.</p>

    <p>Охрович и Краснокаменный <b style={{ color: '#C00000' }}>знают</b>,
      кто есть реальная власть Университета.</p>

    <p>Охрович и Краснокаменный <b style={{ color: '#C00000' }}>утомились</b> отбиваться от сыплющихся на голову младших служащих.</p>

    <p>Одни шлют бездарей, а другие уже
      не знают, куда тела девать.</p>

    <p>Но это сейчас неважно. ПРИОРИТЕТЫ
      — вот чем должна уметь оперировать реальная власть.</p>

    <p>— Вы ничего не понимаете в
      грязных подробностях, — указали Охрович и Краснокаменный.</p>

    <p>— Расскажите нам <i>на самом деле</i> грязное.
    </p>

    <p>— Расскажите нам, как <i>на самом деле</i> прошла встреча.</p>

    <p>— Чем вы занимались.</p>

    <p>— Почему вы такой довольный.</p>

    <p>— Первое «вы» — это вы двое, а
      второе «вы» — это вы один.</p>

    <p>— Мы обращаемся к вам во
      множественном числе только из уважения к вашим множественным смертям, чтоб вы
      знали.</p>

    <p>— Что вспомнит профессор Гуанако
      об этой встрече в своей следующей загробной жизни?</p>

    <p>— Чем она примечательна?</p>

    <p>— Расскажите нам правду!</p>

    <p>— Правда
      предсказуема, — вздохнул Гуанако.</p>

    <p>ОПАСАЙТЕСЬ ВЖИВАНИЯ В ОБРАЗ,
      постыдный профессор.</p>

    <p>Вы уже закатываете глаза. Ещё
      день —&nbsp;и вы будете закатывать истерики.</p>

    <p>В вашем организме происходят
      кошмарные и необратимые изменения.</p>

    <p>— Вы загнали Социю пару скопцов?
      — из вежливости предположили Охрович и Краснокаменный.</p>

    <p>— Развели его на бабки?</p>

    <p>— Совратили?</p>

    <p>— Убедили бросить гэбню и
      запереться с вами вдвоём на складе на неопределённый срок?</p>

    <p>— Если последнее, то, вынуждены вас огорчить, план провалился.</p>

    <p>— Вы в такси с нами, а не на
      складе с Социем.</p>

    <p>Это должны были быть скопцы.
      Наверняка скопцы. Скопцов висит сегодня революционным чучелом на кафедре.
      Скопцов — это неожиданная власть, мастерство слова, личина
      благих намерений и смешение цинизма с высокими идеалами. И ещё скопцы (Виктору
      Дарьевичу — живые, Бедроградской гэбне только что — мастерски словесные)!!</p>

    <p>Это должны были быть скопцы.</p>

    <p>СкОпЦы!!</p>

    <p>— Ничего из ряда вон выходящего у
      нас там не было, — нормальным (одумался, молодцом) голосом ответил Гуанако. —
      Просто воплотили вдруг все мои дурацкие фантазии.
      Посмотрели — <i>посмотрели</i>, оцените
      техническое оснащение! — стародавнюю запись, где тысячи безликих людей в форме трахают Диму. Взглянуть ещё разок на эту запись в спокойной
      обстановке — это была у меня такая
      давняя фантазия, я даже верил, что она реалистична и как-нибудь когда-нибудь исполнится.
      Ну и вот. Потом исполнились совсем уж малореалистичные:
      я подрочил своему бывшему командиру во время допроса,
      а под занавес он от души засветил мне под рёбра. Всего лишь.</p>

    <p>Охрович и Краснокаменный даже
      слегка оробели от такого счастья. Аки художественный
      Гуанако пред художественным командиром в художественной пьесе.</p>

    <p>У этого человека <i>действительно</i> нет морали <i>( — есть, но стремится он к
      правильным идеалам)</i>.</p>

    <p>О да, они перескажут это Диме.</p>

    <p>В лицах (и числах — числа, числа,
      пусть Гуанако назовёт числа!).</p>

    <p>Перескажут не содержание плёнки
      (он наверняка в курсе, что там было), а содержание пересказа Гуанако
      перескажут. И это СЧАСТЬЕ.</p>

    <p>— За что же под рёбра? —
      сочувственно осведомились Охрович и Краснокаменный.</p>

    <p>— Вы ему, выходит, плохо подрочили?</p>

    <p>— Всё потому что манкируете и увиливаете.</p>

    <p>— Увиливаете и манкируете.</p>

    <p>— Бордельный
      инструктаж ещё никому не оказывался лишним.</p>

    <p>— Осознали?</p>

    <p>— Скорее за то, что не подрочил Гошке, — увильнул и манкировал
      Гуанако. — Когда в мае тому допросы устраивал.</p>

    <p>СлИл-таки.
      Всё рассказал, честный-честный Гуанако.</p>

    <p>Так а чобля, кто завтра на встречу-то придёт бля,
      если сегодня бля
      Бедроградская гэбня (гэ-бля) вся друг друга
      перегрызёт? Бля.</p>

    <p>НУ КТО
      ТАК ДЕЛАЕТ</p>

    <p>Охрович и Краснокаменный
      разочарованно замолчали.</p>

    <p>— Судя по тому, как вдохновенно
      Соций хуячил случайные объекты, — потёр под
      случайными рёбрами случайный Гуанако, — ничего они в Бедроградской гэбне об
      источнике утечки не знали. Неужто Гошка не вспомнил?</p>

    <p>Ха-ха, Гошка не вспомнил.</p>

    <p>Тот Гошка, который при виде
      Гуанако позеленел рожей почище собственных глаз и чуть
      этого же Гуанако не пристрелил на месте.</p>

    <p>Не заключённого же №66563 он
      стрелять пытался, его стрелял бы Андрей!</p>

    <p>А Андрей, наоборот, не дал Гошке <i>(на самом деле дал, шутить про шлюх, священный обет, тут слишком просто, поехали дальше)</i>.</p>

    <p>Да когда Гуанако букет от лица
      отвёл, у Гошки по всему телу твирь с того портового склада, где допрос был,
      проросла.</p>

    <p>Ещё как вспомнил. Вспомнил, узнал
      и возненавидел. До того явно жаждал убивать, что Охрович и Краснокаменный мигом
      сорвались с насеста и атаковали.</p>

    <p>— Вы же, помнится, по-фрайдистски
      любите Андрея? — высказались Охрович и Краснокаменный.</p>

    <p>— Так вот любите его ещё больше,
      он вам сегодня жизнь спас.</p>

    <p>— Схватил Гошку за руку, когда
      тот вас уже почти пристрелил.</p>

    <p>— Прямо там, на подходах к
      складу.</p>

    <p>— Гошка не только вспомнил, он
      также узнал и явно поимел мнение относительно вашей
      предпочтительной дальнейшей судьбы.</p>

    <p>— Опять жизнь спас? — искренне
      брякнул Гуанако. — Да что ж такое!</p>

    <p>Помолчал. Заржал.</p>

    <p>У Гуанако —
критическая рассинхронизация головы с телом.</p>

    <p>Таких не берут в гэбни.</p>

    <p>— У вас будет ещё возможность
      выразить ему свою благодарность, — утешили Охрович и Краснокаменный.</p>

    <p>— Завтра ночью, в том же месте.</p>

    <p>— Вы ведь, разумеется, понимаете,
      кто является <i>реальной университетской
        властью</i>?</p>

    <p>— Вы ведь, разумеется, не станете
      манкировать и увиливать?</p>

    <p>Гуанако так выкатил глаза, что
      Охрович и Краснокаменный даже со спины увидели.</p>

    <p>МИМИЧЕСКИЙ ДАР.</p>

    <p>— Сдурели? — завопил он.</p>

    <p>— Мы без вас дороги не найдём,
      Сергей Корнеевич, — завопили в ответ Охрович и Краснокаменный.</p>

    <p>— Ларий хочет посоветоваться с
      вами, Сергей Корнеевич.</p>

    <p>— Ройш хочет показать вам первому
      свой план, Сергей Корнеевич.</p>

    <p>— Попельдопель подписался на чуму
      благодаря вам, Сергей Корнеевич.</p>

    <p>— Максим сбежал из чумы, потому
      что в ней было слишком много вас, Сергей Корнеевич.</p>

    <p>— Габриэль Евгеньевич и Дима,
      Сергей Корнеевич!</p>

    <p>— Не раздражайте нас, вынуждая
      указывать на очевидное, Сергей Корнеевич!</p>

    <p>— Мы за такое на пересдачу
      отправляем.</p>

    <p>— Слушайте, ну что за хуйня, я же
      не собирался… — заблеял горным попралом
      Сергей Корнеевич.</p>

    <p>Охрович и Краснокаменный решили
      побыть милосердными.</p>

    <p>— Подумайте об этом с приятной
      для вас стороны.</p>

    <p>— Кто ещё, кроме нас и вас, может
      прийти на такое мероприятие с пистолетом и не опозориться?</p>

    <p>— Это вы сейчас отпираетесь, а
      если вас не взять, будете мучиться.</p>

    <p>— Вдруг с вашими детишками что-то
      случится.</p>

    <p>— Не выкобенивайтесь,
      Сергей Корнеевич.</p>

    <p>— Слушайте своё сердце.</p>

    <p>— Ухом, а не чем вы обычно
      слушаете.</p>

    <p>— Давайте потом решим, —
      невоздержанно увильнул Гуанако ещё один раз (последний). — По делу: в
      заражённые канализационным путём районы я пальцем ткну, как доедем куда-нибудь.
      Как выясняется, мы уже и нашли все, кроме одного. Больше они не осилили, у них
      во вторник утром фаланги весь вирус увели. И лекарство увели, они новое спешно бодяжат. Могут себе позволить — оборудованием-то запаслись.
      Фалангам Андрей заливал то же, что и Шапке: ах, контролируемое заражение зданий
      Бедроградской гэбни, учебная тревога. Фаланги посмеялись, пришли раскалывать
      осиротевшую Бедроградскую гэбню, а она им хрясь на
      стол бумажку с запросом на применение права нейтрализации четвёртого! Фаланги
      ещё немного посмеялись, закатили им очную ставку с Андреем. И вернули того в
      гэбню, отклонив под вполне официальным предлогом ПН4.
      Вероятно, из вредности.</p>

    <p><i>О кто, о кто мог угадать</i>, что всё пойдёт
      прахом из-за вредности фаланг!</p>

    <p>Игра, правила которой меняются
      произвольным образом, — сложная игра. Охрович и Краснокаменный пока что не
      выиграли, но когда-нибудь выиграют. Не ройшевскими
      методами. Своими.</p>

    <p>Интересно, заслуживают ли фаланги
      жалости.</p>

    <p>Вероятно, нет.</p>

    <p>— Так, чего я не сказал? — внаглую воспользовался паузой Гуанако. — Они
      действительно хуеют с централизованного заражения дома Габриэля
      Евгеньевича. Не заражали, не знают и очень хотят знать, а кто, собственно,
      знает. Переживают, волнуются. Порт, разумеется, на их совести, но блокады они
      не ждали. И не придумали пока, как следует реагировать. Ещё что-то важное было…
      — помялся (в прямом смысле тоже, да). — Вот, вы точно оцените: Габриэля
      Евгеньевича из его башни до такси в ковре тащили! В том самом, беленьком-пушистеньком.</p>

    <p>6/10.</p>

    <p>Это <i>высокая</i> оценка.</p>

    <p>— Нет, что-то вылетело из головы,
      определенно, — Гуанако поскрипел мозгами достаточно громко, чтобы Муля Педаль
      опасливо покосился на приборную панель. — А, да. Девочка-с-диктофоном, которая без
      диктофона, ещё во вторник вечером закончилась
      как ненужный свидетель.</p>

    <p>Обидно.</p>

    <p>Если бы девочка продолжилась,
      картина вышла бы интереснее.</p>

    <p>А теперь будет сплошной
      похоронный звон в ушах у причастных. И особенно — у
      деепричастных. Тех, которые причастны действием. Благо их, собственно говоря,
      все.</p>

    <p>Включила бы диктофон, дура, никому бы не понадобилось её убивать.</p>

    <p>Мёртвые не заслуживают жалости.</p>

    <p>— А что мы их наёбываем,
      они врубились только в среду, а потому искренне сожалеют, что просто задавили
      нахуй девочку, даже допроса не устроив. То есть вся эта Хащина с Силовым Комитетом
      — поздняя импровизация, чтоб у Ройша в сортире побултыхаться. Смесь-то, всученная девочке-с-диктофоном,
      была клейкая. Если к сортиру не прилипла до той самой
      среды — значит, никто её туда не выливал, значит, Бедроградскую гэбню гарантированно
      развели, сомнения снимаются. Проверочку они
      устраивали, вот и всё.</p>

    <p>ДА КОГО ЭТО ТРЕВОЖИТ, ПРОФЕССОР</p>

    <p>Бедроградская гэбня строит
      какие-то непрактичные планы, основная задача которых — быть зрелищными. Охрович
      и Краснокаменный сразу сказали, что никого в Хащине не было, правильно?</p>

    <p>ТАК ЗАЧЕМ ПЕРЕПРОВЕРЯТЬ?</p>

    <p>— С чумой решили покончить уже,
      чтоб от города хоть что-то осталось к тому моменту, как все между собой
      разберутся, — продолжал, и продолжал, и продолжал Гуанако, когда уже заглохнет.
      — Но они лечат сами, и мы тоже лечим сами, не координируясь. Это снижает
      эффективность, зато не придётся помощь от Медицинской
      гэбни обнародовать.</p>

    <p>ОБИДА</p>

    <p>Вся эта игрушечка по перетягиванию лиц высокого уровня доступа вышла бы совсем весёленькой, если бы Бедроградская гэбенька в
      итоге узнала, что Университетику
      это удалось. А что Гуанако и Дима формально Университету никто — так это разве
      кого волнует?</p>

    <p>Университет будет лечить город на
      деньги Медицинской гэбни. Бедроградская гэбня — на свои. И в конце кто-то
      поиздержится, а у кого-то будут средства на обновление гардероба революционного
      чучела.</p>

    <p>Побеждает умнейший, красивейший и
      просто лучший.</p>

    <p>То есть Охрович, Краснокаменный и
      революционное чучело.</p>

    <p>ВсЕгДа (лучше:
      ВеЧнО, а то
выходит сплошной не день больших последних букв).</p>

    <p>С Портом всё не так просто.
      Каждый прыщавый истфаковский абитуриент читал в своём задрипанном
      учебнике: Порт — это АНАРХИЯ!! Ментальность, культура, традиция, так
      исторически сложилось и обусловилось — эбитесь.
    </p>

    <p>Порт — это анархия, а анархия —
      это такой Габриэль Евгеньевич с кувалдой.</p>

    <p>Мы знаем, что вы предлагаете нам
      благое, но мы откажемся, потому что мы откажемся, и особенно рьяно мы
      откажемся, если вы власть (ужас, кошмар, <i>человек
        при жетоне</i>, гибель свободных душ и вольных умов).</p>

    <p>Не-е-ет,
      Порт <i>любит кочевряжиться</i>.
      Но уже сегодня к Охровичу и Краснокаменному приедут <b style={{ color: '#C00000' }}>грузовики</b>. Безвозмездные
      грузовики от старых друзей (сокурсников). В грузовиках — предметы, и Охрович и
      Краснокаменный не забудут снять налог.</p>

    <p>Порт всё равно будет кочевряжиться. Денег и простых вещей ему мало. Ему надо
      эксклюзивных даров, редких штучек и артефактов.</p>

    <p>Безвозмездные грузовики <i>всё равно</i> въедут в Порт
с триумфом.</p>

    <p>Порт <b style={{ color: '#C00000' }}>будет</b> кочевряжиться,
      но дальше уже есть Гуанако. Гуанако ещё кого-нибудь разведёт на бабки.</p>

    <p>Или совратит.</p>

    <p>Или запрётся с ним на складе. Или
      ещё где-нибудь запрётся.</p>

    <p>У Гуанако ещё две черёмуховые
      капсулы остались.</p>

    <p>БЕСЧЕСТНОСТЬ</p>

    <p>— Вам не стыдно просить о помощи Медицинскую гэбню, Сергей Корнеевич? — сладенько
      пропели
      Охрович и Краснокаменный.</p>

    <p>— Вы бы справились и без её
      головы.</p>

    <p>— С головой вообще делать дела
      неспортивно.</p>

    <p>— Ваш командир вас безо всякой
      черёмухи любит, он вам подрочить дал.</p>

    <p>— И денег вы бы сумели найти.</p>

    <p>— Можно было, например, ограбить
      саму Бедроградскую гэбню.</p>

    <p>— Взять в заложники Первое Большое Перевёрнутое и потребовать выкуп.</p>

    <p>— Устрашить мирное население
      бездарно розданными скопцами.</p>

    <p>— Зачем вы прибегаете к дешёвым приёмчикам?</p>

    <p>Гуанако сделал лицо очень бедного
      и очень дальнего родственника.</p>

    <p>Охрович и Краснокаменный
      по-прежнему видели все его лица со спины.</p>

    <p>— С командиром — кто ж знал, —
      промычал Гуанако. — Я и не думал, что он ко мне до сих пор так по-человечески
      относится, что разговор сам собой сложится. Может, кстати, и не сложился бы без
      дополнительных химических воздействий, только мы этого уже никогда не узнаем.
      Наркотики — это дёшево, зато надёжно. А всякое там человеческое отношение —
      никогда не знаешь, есть оно или нет. Не принимаются претензии! Как умеем, так и
      работаем.</p>

    <p>— Кто вам сказал, что вы имеете
      право не принимать наши претензии? — возмутились Охрович и Краснокаменный.</p>

    <p>— Человеческое отношение всегда
      есть, если есть один человек и второй человек.</p>

    <p>— Человеческим отношением
      называется отношение первого человека ко второму.</p>

    <p>— И наоборот.</p>

    <p>— Вот какое оно — другой вопрос.</p>

    <p>— Мы-то думали,
вы храбрый, а вам нужна наркота, чтобы не робеть пред командиром!</p>

    <p>— Всё равно гнильё ваши предъявы, — заупрямился горный попрал
      Гуанако. — Можно просто не робеть, а можно действовать эффективно. Если это
      означает перестраховываться, приходится перестраховываться. Мы тут все и так
      достаточно без страховки наворотили, чтоб теперь
      разгребать ближайшие десять лет. Слушайте, — вдруг прервал он нравоучительную
      тираду соображением о жизни насущной, — все понимают, конечно, что Максим —
      ваша добыча, и вам решать, что с ним теперь делать. Но встаёт вопрос: вы
      решили?</p>

    <p>— Мы что, не можем его убить? —
      хором (иногда они позволяли себе) вскричали Охрович и Краснокаменный.</p>

    <p>— А вы хотите? —&nbsp;усомнился
      Гуанако. — Он же так будет меньше мучиться.</p>

    <p>— С чего вы взяли, что
      преумножение людских страданий в наших интересах?</p>

    <p>— Вам кто-то что-то про нас
      наговорил?</p>

    <p>— Не верьте. У нас много злопыхателей, и они врут.</p>

    <p>— Назовите имена, и к вечеру они самолично признаются вам в том, что врали.</p>

    <p>— И не спрашивайте, что у них с
      лицом: упали с лестницы.</p>

    <p>Гуанако позорно сбежал в свою
      привычную прострацию:</p>

    <p>— Я не настаиваю, но, возможно,
      стоит подумать о Максиме в практическом ключе.</p>

    <p>— Вы пытаетесь купить нас нашим
      священным обетом, — укорили его Охрович и Краснокаменный.</p>

    <p>— Студенты многое бы дали за
      возможность применить заместителя заведующего кафедрой
      в практическом ключе.</p>

    <p>— Меньше, чем за заведующего
      кафедрой, но у того временно истёк срок годности.</p>

    <p>— Только в последнее время
      Университет наконец-то искренне плюнул на интересы студентов.</p>

    <p>— Что мы, разумеется, всецело
      одобряем.</p>

    <p>— <i>О где, о где</i> нам искать клиентуру, Сергей
      Корнеевич?</p>

    <p>— Если же вы не пытаетесь нас
      купить, Сергей Корнеевич, то будьте прямее и твёрже.</p>

    <p>— Осознайте, что вы <i>реальная власть</i>.</p>

    <p>— Дайте нам приказ.</p>

    <p>— Вам понравится.</p>

    <p>— Вам вообще такое нравится.</p>

    <p>— Прямее, Сергей Корнеевич, и
      твёрже!</p>

    <p>— Не просите
невозможного от человека в шёлковой рубашке, — воспротивился шёлковый Гуанако,
скуксился и закурил.</p>

    <p>У Максима теперь один
      практический ключ — тот, под которым сидеть. И ни путеводного фонаря, ни
      семафора. Выпустить его сейчас в бессердечный мир — слишком жестоко даже для
      Охровича и Краснокаменного.</p>

    <p>Была бы у него чума — были бы
      НАСТОЯЩИЕ СТРАДАНИЯ. Драма. Единение с возлюбленным в загробном мире. <i>(? — можно ли заразить
        загробный мир?)</i></p>

    <p>Но у Максима нет чумы.</p>

    <p>Анализ проведён.</p>

    <p>Хотя Гуанако, конечно, извернётся
      ужом, ударится оземь белкой (НЕМАЛО белок расплющил былинный слог), а придумает
      Максиму занятие. Найдёт способ заставить того почувствовать себя нужным.</p>

    <p>Максим будет давиться гордостью,
      но не проблюётся.</p>

    <p>Максим не умеет блевать без масла.</p>

    <p>А масла ему никто не даст.</p>

    <p>НИ-КТО.</p>

    <p>У Гуанако на масло не хватит ни
      сообразительности, ни такта, а больше Максим никого (НИ-КО-ГО) не волнует.</p>

    <p>— Насчёт приказов: я бы хотел
      обратиться к вам как к группе быстрого реагирования, — разродился
      наконец Гуанако, забыл про Максима и перешёл прямо к избранному. — Диму я
      только что торжественно похоронил посреди степи. Кажется, успешно — командир сожрал сказочку, поскольку она апеллировала ко всем
      армейским архетипам разом. Но прочие головы Бедроградской гэбни не в дискурсе, а
      потому могут усомниться. Дима с его
      псевдонимами сам так хорошо поапеллировал ко всякому,
      что они теперь от него никак отцепиться не могут. В общем, хорошо бы он сейчас
      поменьше бегал сам по себе, а то мало ли.</p>

    <p>— Вы назначаете нас в опекуны или
      в телохранители? — немедленно уточнили Охрович и Краснокаменный.</p>

    <p>— Следует ли нам принуждать Диму
      к противоестественной активности?</p>

    <p>— Есть ли запрет на инвалидность?</p>

    <p>— Есть ли карт-бланш на открытые
      переломы?</p>

    <p>— Следует ли бить его по лицу за
      попытки побыть самостоятельным?</p>

    <p>— Следует ли бросаться грудью под
      пули?</p>

    <p>— Следует сделать так, чтобы
      поводов для пуль не было, — самодовольно повелел Гуанако.</p>

    <p>— Значит, карт-бланш на открытые
      переломы есть, — сделали вывод Охрович и Краснокаменный.</p>

    <p>— Дима любит и умеет <i>ввязываться</i>.</p>

    <p>— Ввяжется —&nbsp;мы не станем
      заниматься художественным макраме.</p>

    <p>— Подойдём к вопросу, так
      сказать, радикально.</p>

    <p>— Мы вас поняли?</p>

    <p>— <i>Вы нас поняли?</i></p>

    <p>— Просто пусть он будет живой,
      функциональный и у нас, а не где-нибудь ещё, — нежненько посмотрел в окно
      Гуанако. — Мне сейчас некогда бдеть, у меня Порт, а вам я доверяю.</p>

    <p>БЛАГОДЕТЕЛЬ ВЫ НАШ</p>

    <p>Дима Дима Дима
      Димадимадимадимадиииииимааааааааа</p>

    <p>Один сплошной Дима у Гуанако в
      голове. Он всю черёмуху ради него устроил. Жаждал под пристойным предлогом
      выклянчить у Охровича и Краснокаменного кружева, чтобы потом напялить
      на Диму. Жаждал, чтобы тот был живой, функциональный и у него.</p>

    <p>Что бы ты делал, позорный
      профессор, если бы Дима <i>правда</i> хотел пойти
      на эту встречу?</p>

    <p>Все думали, что это у Гуанако
      степная сублимация преподавательской деятельности: для подпитки самодовольства
      нужен тот, кто смотрел бы снизу вверх и ловил бы каждый жест. За неимением
      реальных студентов пойдёт и бывший.</p>

    <p>Так оно когда-то и обстояло, но
      все глупцы, а Охрович и Краснокаменный ведают:</p>

    <p>уже кучу времени назад всё стало
      совсем наоборот.</p>
  </>
}

export default chapter28
