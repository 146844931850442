import React from 'react'
import {RouteComponentProps} from 'react-router'
import pages from '../../Data/book'
import store from '../../store'
import BookNav from "../BookNav";
import { Link } from 'react-router-dom';
import Helmet from "react-helmet";

class BookPage extends React.Component<RouteComponentProps<{
  chapter: string
}>> {
  componentWillUnmount() {
    store.clearHeader()
  }

  render() {
    const { match } = this.props
    const page = pages[match.params.chapter]
    if (page.header) {
      store.setHeader(page.header)
    }
    return <div className='page--sidebarLeft'>
      <Helmet>
        <title>{page.title}</title>
      </Helmet>
      <main className='page--withText'>
        <div className="bedrograd-nav">
          {page.prevLink ? <Link to={`/book/${page.prevLink}`}>
            <img alt='' src="/images/ArrowLeft.png" /> Предыдущая глава
          </Link> : <span />}
          {page.nextLink && <Link to={`/book/${page.nextLink}`}>
            Следующая глава <img src="/images/ArrowRight.png" alt='' />
          </Link>}
        </div>
        <h1 className='title'>{page.title}</h1>
        {page.content}
        <div className="bedrograd-nav">
          {page.prevLink ? <Link to={`/book/${page.prevLink}`}>
            <img alt='' src="/images/ArrowLeft.png" /> Предыдущая глава
          </Link> : <span />}
          {page.nextLink && <Link to={`/book/${page.nextLink}`}>
            Следующая глава <img alt='' src="/images/ArrowRight.png" />
          </Link>}
        </div>
      </main>
      <aside>
        <BookNav />
      </aside>
      <footer>
        <img src='/images/site_footer.jpg' alt='' style={{
          maxWidth: '100vw'
        }} />
      </footer>
    </div>
  }
}

export default BookPage
