import { store } from 'react-easy-state'

const state = store({
  headerImage: '',
  setHeader: (image: string) => {
    state.headerImage = image
  },
  clearHeader: () => {
    state.headerImage = ''
  }
})

export default state
