import React from 'react'
import { NavLinkProps } from "react-router-dom"
import './Header.sass'
import MenuBar from '../MenuBar'
import store from '../../../store'
import { view } from 'react-easy-state'

export type NavMenuItem = NavLinkProps & {
  subMenu?: NavMenuArray,
  isFocused?: boolean
}
export type NavMenuArray = Array<NavMenuItem>

const navLinks: NavMenuArray = [
  {
    title: 'Главная',
    to: '/',
    exact: true,
    isFocused: true
  },
  {
    title: 'Сама «Чума»',
    to: '#',
    isActive: (m, location) => location.pathname.startsWith('/book') || ['/plague', '/download'].includes(location.pathname),
    subMenu: [
      {
        title: 'Содержание',
        to: '/book'
      },
      {
        title: 'Читать',
        to: '/book/prolog'
      },
      {
        title: 'Скачать',
        to: '/download'
      }
    ],
  },
  {
    title: 'FAQ',
    to: '/faq'
  },
  {
    title: 'О персонажах',
    to: '/characters'
  },
  {
    title: 'Бонусы',
    to: '#',
    subMenu: [
      {
        title: 'Красивые картинки',
        to: '/art'
      },
      {
        title: 'Тексты и статьи',
        to: '/bonus-text'
      }
    ],
    isActive: (m, location) => ['/bonus', '/art', '/bonus-text'].includes(location.pathname),
  },
  {
    children: 'Новости',
    title: 'Новости',
    to: '/archive'
  },
  {
    children: 'Отзывы',
    title: 'Отзывы',
    to: '/feedback'
  },
  {
    children: 'Фанарт',
    title: 'Фанарт',
    to: '/fanart'
  },
  {
    children: 'Об авторах',
    title: 'Об авторах',
    to: '/authors'
  },
  {
    children: 'А поговорить?',
    title: 'А поговорить?',
    to: '/guestbook'
  }
]

class Header extends React.Component {
  render() {
    const style: React.CSSProperties = {}

    if (store.headerImage !== '') {
      style.backgroundImage = `url('/images/${store.headerImage}')`
    }

    return <header className='header'>
      <div className='header_image' style={style}/>
      <MenuBar
        main
        items={navLinks}
        label='Меню «Чумы»'
      />
    </header>
  }
}

export default view(Header)
