import React from 'react'
import { IBookPage } from './index'

const chapter16: IBookPage = {
  header: 'header_d6.jpg',
  title: 'Глава 16. О пользе чтения',
  prevLink: 'chapter15',
  nextLink: 'chapter17',
  content: <>
    <h2>Бедроградская гэбня. Гошка</h2>

    <img alt='' src="/images/16_goshka.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>На часах 10:32</p>

    <p>&nbsp;</p>
    <p>— Это меня и поражает. Ты вообще
      способен признать свою вину?</p>

    <p>— Вину?</p>

    <p>—&nbsp;Ладно, не вину — не лучшее
      решение?</p>

    <p>— Смотрите-ка, кто запел.</p>

    <p>— Ночью что только не с
      микроскопом облазили…</p>

    <p>— Знаю. А эта твоя финальная
      экспертиза?</p>

    <p>— Да не было в ней смысла. Гошка,
      у Ройша чисто. Зря выманивали в Хащину.</p>

    <p>— Девка
      защитила его сортир, как трогательно! Значит, и поделом девке.</p>

    <p>— Как ты вообще мог повестись на
      её спектакль?</p>

    <p>— А как ты мог попасться фалангам?
      Заткнись и радуйся, что вовремя очухались.</p>

    <p>— Раньше у нас хотя бы был план!
      Опасный, но стройный.</p>

    <p>— Ну и? Планы меняются.</p>

    <p>&nbsp;</p>

    <p>На часах была половина
      одиннадцатого утра, самое время добропорядочным гражданам собрать манатки, покинуть
      уютные жилища и отправиться пахать в поте
      лица. Тем из них, кто не чувствует усталости, головокружения и симптомов
      тяжёлого ОРЗ, конечно. Но их и не должно шибко интересовать, что происходит на
      лестничной клетке, верно? У них другие заботы сыщутся.</p>

    <p>В подвале похожего на башню дома
      номер 33/2 (33 —&nbsp;по проспекту Объединённых
      Заводоводств, 2 —&nbsp;по Поплеевской) было чистенько, уютненько и крайне
      двойственно. Подвалы — нейтральная территория, там университетский выход в
      канализацию и бедроградское всё остальное.</p>

    <p>Оформлением конкретно этого
      подвала занимался Университет — и, судя по ровным слоям краски на стенах, его
      обитатели намеревались в оный подвал переселяться. Только кушетки с камином и
      не хватало.</p>

    <p>— И дезинфекция уже пущена?
      —&nbsp;поёжился Андрей, повертел головой в поисках люка, ведущего в
      канализацию.</p>

    <p>На проспект Объединённых
      Заводоводств ехали вдвоём: вчетвером — много, заметно, да и других дел для
      голов гэбни сейчас по горло. Именно с Андреем — потому что у него в одной руке
      шприц, а в другой руль, очень удобно.</p>

    <p>Хотя кому Гошка это рассказывает?</p>

    <p>Потому что хотелось. Ну,
      посмотреть на Андрея в действии после всей этой херни.</p>

    <p>Самой сложной частью сегодняшней
      операции оказался выбор для него нормальной штатской одежды, поскольку
      выяснилось, что первые любимые брюки не подходят к рубашке, во
вторых любимых он мотался по Столице и видеть их больше не желает, а
      третьих любимых, в общем-то, и нет — во всех потенциальных третьих что-нибудь
      непременно не так. Пришлось насильно отдирать от шкафа прямо в форменных гэбенных,
      что неконспиративно, но времени-то было
      не шибко.</p>

    <p>Андрей потом никак не мог
      заткнуть фонтан на тему того, как хорошо некоторым быть вечным младшим служащим
      с набором повседневных туалетов, которых хватило бы на костюмерную театра
      средней руки.</p>

    <p>— Весьма, — кивнул Гошка, отвинчивая
      последний шуруп из крышки щитка. У него, ясное дело, был ключ, но бригадирша
      электриков настояла на том, чтобы они с Андреем свинчивали крышку сразу — мол,
      ей всё равно потом придётся, нечего преумножать труды. Она вообще оказалась
      склочной бабой, чем и понравилась Гошке — знает своё дело, знает, кто здесь
      главный и вовсе не собирается поступаться своими интересами.</p>

    <p>Хамить
      главному нехорошо, и бригадиршу ожидал бы перевод на какую-нибудь гораздо менее
      престижную должность, если бы не секретность операции.</p>

    <p>Теперь ей грозит несчастный
      случай, бедняжке.</p>

    <p>Хамить
      главному —&nbsp;нехорошо.</p>

    <p>— Когда мы говорим
      «контролируемая эпидемия», —&nbsp;поддавшись нравоучительному порыву, продолжил
      Гошка, —&nbsp;мы имеем в виду «контролируемая <i>нами</i> эпидемия». Если Университет тоже взялся травить людей, наш
      гуманистический долг и священная обязанность — их вылечить, не так ли.</p>

    <p>— Ну-ну. Всех, кроме одного?
      Почему бы этого одного тоже не вылечить? Можем же опять спалиться.</p>

    <p>— Потому, Андрей, — Гошка снял
      крышку щитка, воззрился на месиво проводов, — что травить своих — грешно и
      порочно. А за грехи и пороки приходит расплата. Какой, по-твоему, надо
      херачить?</p>

    <p>Андрей дёрнул плечом и
      отвернулся. Миленький вознегодовал?</p>

    <p>Миленький
прекрасно умел подстраиваться под ситуацию и ориентироваться на пересечённой
местности, но при этом всегда корчил такую рожу, будто ему в организм вводят
живых угрей. Внутривенно.</p>

    <p>— Вот и я думаю, что все, —
      хмыкнул Гошка, сцапал рукой самый центр чёрно-красного гнезда и изо всех сил
      дёрнул. Никаких искр, само собой, не брызнуло, только с писком мигнула и
      погасла лампочка.</p>

    <p>Простите, о невинные жертвы
      политических акций.</p>

    <p>— Ты так радуешься происходящему,
      как будто мы выигрываем, — проворчал Андрей из свалившейся на голову темноты.
      —&nbsp;А ситуация ведь глупая. Мы травим — они лечат. Они травят — мы лечим.
      Лекарства у нас после рейда фаланг — пихт наплакал. А синтез новых партий идёт
      слишком быстрыми темпами, ночью аппаратура вообще начала сбоить от таких
      перегрузок! Нужны решительные действия.</p>

    <p>А они тут
      чем занимаются?</p>

    <p>— Я так радуюсь происходящему,
      миленький, потому что теперь всё гораздо, гораздо интереснее.</p>

    <p>Ведь именно в этом соль политики
      — в нюансах, в маленьких шажках, которыми приходят большие перемены.
      Университет держится за свою мораль и атмосферу любви и ласки, а значит
      —&nbsp;достаточно эту атмосферу отобрать. И, откровенно говоря, это гораздо
      веселее, чем перестраивать канализации и бегать потом по фалангам, тыкая
      пальцами в то, кто тут некомпетентен. Ходить по старшим скучно и унизительно,
      то ли дело партизанская война на равных.</p>

    <p>Гошка любил пихтский эпос. Среди
      прочих там была невыносимо длинная и нудная история про то, как некий пихт
      продал свою бессмертную душу лешему за мирские блага, хоть глупые росы его и
      отговаривали. Продал, поимел лёгкую и счастливую
      жизнь, а в конце так и не понял, что такое душа, была ли она у него раньше,
      зачем отговаривали и в чём наёбка.</p>

    <p>Душу придумали древние росы и
      прочие интеллектуально обогащённые — на свою же голову.</p>

    <p>С теми, кому так важно не
      запачкаться, весело бороться, потому что достаточно разок кинуть в них говном
      —&nbsp;и они уже будут считать себя проигравшими. А добить лежачего
      — это так, дополнительное удовольствие.</p>

    <p>В поисках двери на лестницу
      Андрей, ругнувшись, схватил Гошку за плечо, и тому слегка ёкнуло.</p>

    <p>Что ж не радоваться-то, миленький, если мы снова вместе?</p>

    <p>— Куда ты лезешь, дай электрикам
      зайти, — осадил он его вслух. — Им дорога прямо на нужный этаж, займут
      народонаселение, пока мы делаем дело. Чтоб народонаселение особо не пялилось по
      сторонам.</p>

    <p>— Знаю я, — буркнул Андрей, но
      руку с плеча не убрал.</p>

    <p>Он мог сколько угодно клеить
      серьёзность, но Гошка-то знал, что в нём бьётся точно такой же азарт, только у
      Андрея от него синяки больше, потому что сложно одновременно бросаться под
      обстрел и опасаться где-нибудь напортачить. Наверное, миленького с его осторожностью можно даже пожалеть.</p>

    <p>Поразмыслив, Гошка притянул
      Андрея поближе — просто так, чтобы был.</p>

    <p>— Перестань делать лицо и
      посмотри на это так: зачем вообще идти в гэбню, если тебе не нравятся
      противостояния?</p>

    <p>Андрей покачал головой — и
      смотреть не надо, чтобы почувствовать, как надулся.</p>

    <p>— Ответственность? Синхронизация?
      Благо города? Ты сам всю шапку про это прозудел.</p>

    <p>— Да, а ещё хождение по колено в дерьме, пулевые ранения и рейды фаланг, — фыркнул
      Гошка. —
      Это тоже прописано в вакансии. Вот я и спрашиваю —&nbsp;зачем идти в гэбню,
      если тебе всё это, <i>всё вместе</i> не
      нравится? Чтобы носиться с тем, какой ты ебически
      благородный? С этим в Университет, миленький.</p>

    <p>Андрей что-то пробурчал, но
      мышцами расслабился.</p>

    <p>— Я всё ещё помню избиение стола.</p>

    <p>— И, поверь мне, я получил от
      него всё удовольствие, которое можно в принципе извлечь из избиения неживого
      объекта.</p>

    <p>А ещё Университет — хороший
      противник.</p>

    <p>Приятно, когда скандальную
      бригадиршу бьёт случайным ударом тока, но ещё приятнее — когда коротит тех, у кого
      к зубам прилагается табельное оружие.</p>

    <p>На лестнице послышались голоса —
      вот и скандальная бригадирша пожаловала, самое время. Гошка потрепал Андрея по
      голове —&nbsp;просто так, на дорожку —&nbsp;и махнул рукой. В темноте тот жеста
      не увидит, но всё равно почувствует, что пора.</p>

    <p>Пора.</p>

    <p>До нужной квартиры Гошка с
      Андреем добрались бодрой рысцой — электрики будут развлекать всех желающих тунеядцев не меньше часа, но это не повод. На лестничной
      клетке стояла бригадирша — невысокая и с во всех
      смыслах выдающейся челюстью.</p>

    <p>— Ключи, — коротко
      проинформировала она, протягивая уже одолженную у соседей
      искомую связку. Электрикам ведь нужно проверить все квартиры, даже те, хозяева
      которых отсутствуют или не могут открыть дверь по причине проблем со здоровьем.
      Электрикам дадут ключи без вопросов.</p>

    <p>Гошка кивнул ей на темнеющий
      лестничный пролет — нечего тут пялиться. Бригадирша
      покачала челюстью и удалилась.</p>

    <p>И откуда только такие страхолюдины берутся. Казалось бы, делаешь людей в печи — ну
      улучши хоть немного средние показатели презентабельности.</p>

    <p>— А если там всё-таки ещё кто-то?
      — Андрей обеспокоено прижал ухо к двери.</p>

    <p>— Кому там быть? За Молевичем
      проследили, он на службе. А больше никого наш пациент не ебёт. Во всех смыслах.</p>

    <p>Гошка отряхнул перчатки, достал
      пистолет —&nbsp;так, скорее для виду, левой рукой
      засунул ключ в скважину.</p>

    <p>Ну
      здравствуйте, доктор исторических наук Онегин Габриэль Евгеньевич.</p>

    <p>В завкафской квартире было душно
      и смутно пахло савьюром — как он тут живёт вообще? В коридоре стройными рядами
      висели тонкие плащи и куртки, под ними — многочисленные сапоги: натуральная
      кожа чуть ли не индокитайских крокодилов, пряжки из серебра. Достойный расход
      университетских финансов.</p>

    <p>Естественный свет пожрали плотные
      шторы, но его всё равно хватило, чтобы разглядеть: прямо напротив входной двери — спальня: гостеприимно распахнута,
      встречает с порога огромной кроватью под красным балдахином, в центре — бледный
      завкаф, приподнявшийся на одном локте.</p>

    <p>Ох
      леший.</p>

    <p>— Если это не приглашение, то я
      вообще не знаю, что называют приглашением, — Гошка стремительно преодолел
      расстояние от входной двери до кровати.</p>

    <p>За спиной шикнул Андрей. Мягко
      переступая, начал проверять одно помещение за другим на наличие нежданных
      сюрпризов. Такой хороший, вымуштрованный служащий — он что, слепой, что ли?
      Главная угроза всей операции тут, под носом — разметалась на простынях.</p>

    <p>Завкаф даже попытался дёрнуться,
      что было непередаваемо трогательно. В сердце Гошки немедленно расцвёл
      широчайший спектр светлых чувств. Можно ли не
      возлюбить человека, который всем своим видом <i>приглашает</i>?</p>

    <p>Невыносимая дилемма. Андрей
      отчасти прав, алгоритм прост: осмотреть помещения, устроить дезориентирующий
      бардак, по-быстрому свалить с добычей, да-да, но —</p>

    <p>Эта херова добыча сама дезориентирует кого хочешь!</p>

    <p>Сразу зажимать рот не хотелось
      —&nbsp;хотелось, чтобы завкаф пискнул чего-нибудь, но тот только распахнул пошире свои голубые-голубые глаза. Он не ломал комедию, не
      строил из себя героя, не торговался, а просто откровенно и неприкрыто боялся.</p>

    <p>Честное слово, вся затея с
      Университетом стоила как минимум этого момента. И ещё беззащитно выпирающих
      ключиц.</p>

    <p>Гошка с сожалением отдёрнул
      балдахин и как можно спокойнее сам опустился на
      простыни. Навис, почти даже нежно прикрыл так и не издавший ни звука рот рукой
      в мягкой перчатке. Завкаф не сопротивлялся и не отводил взгляда —&nbsp;и вот
      это уж точно было приглашением.</p>

    <p>Леший, работа работой, но как тут
      удержишься?</p>

    <p>Подошедший Андрей хладнокровно
      хрустнул ампулой (ну точно слепой!), но Гошка жестом придержал его —&nbsp;тоже
      не отводя взгляда от завкафа.</p>

    <p>Так. От работы надо получать
      удовольствие.</p>

    <p>— По-моему, сперва стоит его трахнуть, — постановил Гошка, аккуратно распахивая
      завкафский халат. Чёрный и шёлковый на красных простынях —&nbsp;просто как
      провода в щитке, глубокий символизм цвета. Завкаф прётся
      от знаков и предзнаменований — ну так вот, эта встреча была предначертана,
      пусть не жалуется.</p>

    <p>Какое
      тут жаловаться, праздник сплошной, день госслужащего прям. Если не считать
      того, что в голове досадливо вертелась тошнотворная эротическая мура про напряжение
      и электрический ток.</p>

    <p>Вредно, вредно думать. Откуда
      мыслей-то столько вдруг?</p>

    <p>Андрей недовольно фыркнул:</p>

    <p>— Опять?</p>

    <p>— Что «опять», тот раз когда был!</p>

    <p>Когда-когда, восемь лет назад,
      прямо после мальчика 66563 с расшифровками. Когда выяснилось, что у
      Университета хромает интуиция и они не догадываются,
      что своих людей нужно защищать. Завкафа тогда прикарманили
      так, всё для той же острастки. И удовольствия.</p>

    <p>Он ведь специально это делает
      —&nbsp;культивирует свою хрупкость и ранимость, стрижётся под оскописта и носит
      серебряные кольца. Гошка его не обвинял даже, наоборот — догадывался, как
      весело и приятно, когда у всех близлежащих студентов тестостерон из ушей
      хлещет. В Университете вообще сильна добрая традиция провокаций.</p>

    <p>Так что пусть радуется,
      провокация сработала.</p>

    <p>Андрей посмотрел на Гошку
      осуждающе — херов отрядский отличник! — и сдёрнул завкафу халат с плеча.</p>

    <p>Было бы наивно надеяться, что миленький вдруг решил взять уроки получения
      удовольствия от
      работы. Так и есть, ткнул шприцом. Ужас в голубых завкафских глазах булькнул и
      растворился.</p>

    <p>Какая потеря.</p>

    <p>— В бессознательном состоянии не
      так весело, но я готов пойти на эту уступку ради тебя, — ухмыльнулся Гошка и
      погладил завкафа по белому плечу.</p>

    <p>Андрей посмотрел на него с
      недоверием, механически проверил завкафу пульс, склонился изучить состояние
      глазных яблок:</p>

    <p>— Ты осознаёшь, что он заражён?</p>

    <p>— Ты осознаёшь, что именно
      поэтому мы с ним можем больше никогда не встретиться? Я не готов упустить этот
      шанс.</p>

    <p>— <i>Как тебе прекрасно известно</i>, водяная чума передаётся
      в том числе и половым путём, — Андрей сделал полдвижения, как будто хотел
      оттянуть Гошку в сторону за руку.</p>

    <p>— Ну так
      и что, у нас есть лекарство.</p>

    <p>— Когда именно пустили
      дезинфицирующий состав? Впрочем, неважно: сам он вряд ли пил из-под крана, куда
      ему в таком состоянии до кухни ходить, да ему бы уже и не помогло
      —&nbsp;посмотри на зрачки, на состояние мышц, тяжёлая стадия вот-вот начнётся,
      —&nbsp;зачастил Андрей, ещё раз зачем-то стал считать завкафский пульс.
      —&nbsp;Порция лекарства у меня, конечно, при себе, но разве задумка была не в том,
      чтобы оставить его зачумлённым? Наказание за грехи и пороки, все дела? Твои
      развлечения не стоят того, чтобы его лечить и заражать заново, я не знаю, что
      будет с иммунитетом, это ненадёжно без предварительных тестов…</p>

    <p>— Кто говорит о том, чтобы лечить
      <i>его</i>? —&nbsp;широко улыбнулся Гошка,
      но завкафа отпустил —&nbsp;с огромным сожалением.</p>

    <p>От некоторых приглашений не
      только невежливо, но ещё и очень, очень печально отказываться.</p>

    <p>— Не трать время на ерунду, —
      тряхнул головой Андрей. — Электрики не вечны, нам ещё кучу всего сделать надо.
      И вообще я так и знал, что вся эта затея с завкафом — только для твоего личного
      удовольствия.</p>

    <p>— Не включай белочку, если так
      торопишься, — рявкнул Гошка.</p>

    <p>Ну в
      конце концов, почему нет?</p>

    <p>Когда жители города загибаются от
      водяной чумы, не присоединиться к своему народу со стороны городских властей
      даже как-то бесчестно! На час-полчаса — всё равно дольше у параноика Андрея не
      хватит нервов не всаживать в Гошку целительный шприц.</p>

    <p>Даже интересно, в конце концов.</p>

    <p>— Да какая белочка? — Андрей отлип наконец от завкафского пульса, задумчиво
      уставился на
      перчатки на своих руках, которые были отнюдь не санитарной мерой, а
      конспиративной, нечего здесь отпечатки оставлять. — Послушай меня, пожалуйста.
      Шапка проверял вирус и лекарство на себе, нелегальных подопытных ему в
      Медкорпусе не достать. Мы проверяли на своих людях, но мало, мало проверяли! Я
      как услышал о возможном родстве вируса со степной чумой, всё время думаю: вдруг
      начнётся мутация? Вдруг мы не знаем каких-то свойств? Вдруг они нам подсунули формулу
      лекарства с сюрпризом?</p>

    <p>Бессознательное завкафское тело
      никого не боялось и ни к чему больше не приглашало, поэтому прочие страждущие были наконец услышаны.</p>

    <p>— Не ссы, наши
подопытные живы-здоровы, — Гошка опустился на корточки у прикроватной тумбочки,
полез шарить по ящикам. — Я ещё раз уточнял. Ну, после того, как дал девке пробирку.</p>

    <p>Валокордин, письма какие-то,
      весёленькие сигаретки из цветной бумаги — всё на пол. Письма, возможно, даже на
      кровать, это уже смотря чьи и о чём. Сейчас поглядим.</p>

    <p>&nbsp;</p>

    <p><i>«Я знаю, как жалко и нелепо писать мёртвым — но ведь и живому тебе
      письмо не дошло бы, верно? Заверну в конверт, выкину куда-нибудь, а потом
      поверю — смогу поверить —&nbsp;что его просто не пропустили по уровню доступа,
      или что затерялось в дороге, или даже что ты всё-таки прочитал, но не можешь
      ответить».</i></p>

    <p>&nbsp;</p>

    <p>— Люди — разные. В медицинском отношении-то точно, — заметив, что Гошка
      занялся делом, Андрей устыдился, осмотрелся и направился к завкафскому шкафу. —
      Долбаный Зиновий Леонидович с Колошмы вроде как без намордника шлялся по
      изоляторам, взбунтовавшуюся охрану стрелял. И
      пережил <i>две вспышки</i> степной чумы! А
      некоторые от простуды дохнут, такое случается.</p>

    <p>Из-за створки полетели шейные
      платки, тонкие рубашки и прочие неопознанные кружева. У этой ходячей провокации
      вообще, что ли, практичных шмоток нет, только
      бордельные?</p>

    <p>— Выбери там что-нибудь
      относительно пристойное и траурное, — подсказал зависший
      над письмами Гошка, игнорируя врачебные излияния, — если Молевич вдруг знает
      все кружева наперечёт, пусть считает, что завкаф сбежал в таком виде, в котором
      разве что стреляться.</p>

    <p>&nbsp;</p>

    <p><i>«Мне всегда казалось, что жизнь и смерть — одно и то же, только в
      смерти чуть спокойнее и чуть более всё равно. Звучит страшно подростково, да?
      Но мне нравилось быть глупым и эдак драматизировать.
      Это жалко и смешно, на моём месте нелепо осуждать тех, кто смеётся —&nbsp;я сам
      смеюсь. Смеюсь, но, смеясь, всё равно ползаю на брюхе, умоляю — снова быть
      глупым. Верить, что всё это предначертано, знаки, высшие силы, следы божества.</i></p>

    <p><i>Ненавижу тебя за то, что ты отобрал у меня эти простые иллюзии
      —&nbsp;как будто мало было отобрать у меня себя.</i></p>

    <p><i>Ненавижу себя за то, что не могу это всё просто отрезать.</i></p>

    <p><i>Тебя бы стошнило от патетичности моего слога, я знаю. Как хорошо, что
      ты не будешь этого читать — письмо затеряется в дороге.</i></p>

    <p><i>Не пройдёт по уровню доступа».</i></p>

    <p>&nbsp;</p>

    <p>— …у тебя могут начаться
      осложнения, какая-нибудь непредвиденная реакция организма, выпадешь на несколько дней, ну кому это
      нужно? — всё зудел и зудел Андрей.</p>

    <p>А, он до сих пор об этом?</p>

    <p>— Миленький, да ты никак ревнуешь
      меня к завкафскому телу!</p>

    <p>— Очень смешно, — огрызнулся
      миленький и убийственно серьёзно добавил: — Боюсь я, мы же без тебя не
      справимся, если…</p>

    <p>&nbsp;</p>

    <p><i>«Не стоило идти на истфак: слишком хорошо знаю представления о посмертии в
      разных культурах, чтобы верить хоть в одно».</i></p>

    <p>&nbsp;</p>

    <p>Так, эпистолярным произведениям
      однозначно место на кровати.</p>

    <p>— Всё, завалил ебало. Нет
      никакого «если», — последний раз запущенная во внутренности тумбочки рука
      наткнулась на связку ключей. — Не трахать так не трахать: умирать от чумы и правда
      некогда, Бедроград за спиной. Но ты мне теперь
      должен, — Гошка требовательно звякнул ключами. — Как думаешь, они от чего?</p>

    <p>В любом нормальном доме есть
      чужие ключи. Всероссийское Соседство, леший! Все всем соседи, в каждом
      мебельном магазине найдётся специальная вешалка на десять крючков, у завкафа в
      коридоре вон тоже такая прибита.</p>

    <p>Но эти-то ключи не на вешалке, а
      прям в спальне — тут явно есть, на чём сыграть, если б знать наверняка.</p>

    <p>— От чего бы ни были, брось их позаметнее, — отвлёкся на звон Андрей. — Должно
      быть сразу
      ясно, что завкаф не по гостям пошёл.</p>

    <p>Ключи шлёпнулись на письма,
      думать некогда.</p>

    <p>Гошка рывком поднялся, бросил
      тоскливый взгляд куда-то в район оставшихся неизученными завкафских ключиц и
      придирчиво воззрился на беспорядок, в считанные минуты устроенный Андреем.
      Правдоподобно — миленький знает толк в гардеробных истериках, сам сегодня
      штанов по нраву найти не мог, серьёзный человек и эпический герой.</p>

    <p>— Строже не вышло, одни шелка и
      вышивки, — протянул он Гошке какие-то тряпки, — там ещё в коридоре чёрный плащ
      болтается с застёжками под шинель, самое оно.</p>

    <p>Ну нихера себе.</p>

    <p>Гошка, конечно, сам говорил, что
      реквизит нужен, чтоб прям как будто стреляться, но
      увидеть рубашку военного образца времён Росской Конфедерации всё-таки не
      ожидал. Рубаха белая, парадная, она же расстрельная — на праздничный марш и под
      трибунал. В такой дед Гошки до старости фотографировался — он свой титул, умело
      конвертированный в деньги, как раз в Резервной Армии заработал.</p>

    <p>Они там в своём Университете
      совсем охерели? Срать присесть только на антикварный горшок пристало?</p>

    <p>Горшок, впрочем, был вполне
      обыкновенный, разве что пару дней подряд по воле неизвестного доброжелателя в
      нём плескалась водяная чума, а так всё путем; зато в ванной обнаружилась
      недешёвая штука — стиральный аппарат.</p>

    <p>Андрей уставился на него как на
      херов подарок ко дню рождения:</p>

    <p>— Конфискуем. Ну, якобы в ремонт,
      я имею в виду. Конфискуем и говорим, что проводку из-за него и того, а хозяина
      на месте не было, поэтому пришлось аж записку с
      объяснениями на дверь повесить. И сразу ставим слежку — при таком раскладе
      кто-нибудь из бдительных соседей сам Молевичу позвонит.</p>

    <p>— Резво соображаешь, —
      одобрительно кивнул Гошка. — Может, не придётся до ночи ждать, пока он
      соизволит домой пожаловать. Выцепим прямо из гущи событий!</p>

    <p>И непременно надо будет поставить
      на след кого-нибудь этакого, во вкусе Молевича: с беззащитными
      ключицами и приглашающим видом, чтоб ему тоже пусто было. Молевич всё же не
      совсем дурак, беспрерывно его вести не выходит, умеет
      чуять слежку — проверено. Так вот пусть и смотрит себе на слежку во все глаза.
      Сегодня ни одного его движения упустить нельзя.</p>

    <p>— Ведро, вода, порошок, — раскомандовался миленький, с решимостью подбираясь к
      железному нутру аппарата. Видать, опыт валяния под такси помогает имитировать
      поломки любых устройств. Что ж он в щиток-то сам лезть не захотел?</p>

    <p>— Устроим Потопление Первозданной
      Земли?</p>

    <p>— В слезах, — невнятно отозвался
      Андрей, зажимавший зубами какую-то маленькую железку, извлечённую из кармана.
      Вечно они с Бахтой как лавки запчастей на выезде! — У меня перегоревший
      предохранитель завалялся. Даже подходит, но он похуже, чем в аппарате. Сейчас
      заменю, и будет не подкопаться.</p>

    <p>Гошка покорно включил воду —
      четыре головы лучше одной, быстрее и эффективнее. Отвлекать соседей электриками
      — быстро и эффективно, но не очень чисто: электрики тоже люди, всегда могут
      заподозрить, задуматься, сболтнуть лишнего. Особенно если кто-нибудь хорошенько
      порасспрашивает, а такое возможно — вдруг у
      Университета есть нюх. Создать и для электриков убедительную картину разрушений
      не помешает.</p>

    <p>Херовы убедительные картины!</p>

    <p>Вода из-под крана в доме-башне 33/2
      была убедительнее некуда — то, что она совершает оборот сортир-кран,
      действительно на совести Бедроградской гэбни.</p>

    <p>Отчасти.</p>

    <p>Лет пять, что ли, назад инженеры намалевали на бумаге финальную версию
      очистительных систем,
      позволяющих пускать часть использованной воды обратно в краны через совсем
      небольшой промежуток времени. Обкатывать на своих же служебных зданиях было
      можно, но осторожно — великое изобретение-то хотели приписать Университету, а
      хер бы Университет незаметно окопался прямо под Бедроградской гэбней! Да и
      расход воды, нагрузка на оборудование — и чего там ещё бывает? — в жилых домах
      другие.</p>

    <p>Короче, надо было выбрать дом для
      пробного запуска. Сначала спорили, пытались решить по уму, потом Бахте надоело,
      и он выстрелил в карту, не целясь. Попал в Шолоховский переезд, все заржали —
      там же рядом перекрёсток Объединённых Заводоводств и Поплеевской, дом завкафа,
      откуда по данным приставленных наблюдателей всё время то убегал, то возвращался
      Молевич.</p>

    <p>Гошка тогда настоял, чтобы
      выстрел Бахты засчитали — почему бы не заставить Молевича пить воду из
      завкафского сортира? Отличная шутка, в Университете такие любят!</p>

    <p>Завкафскую канализацию
      перестроили, потестировали пару недель — никаких жалоб, никто ничего не заметил
      — и вернули в прежний режим работы. Ну а что — всё прилично, переключатель-то
      предусмотрен.</p>

    <p>Протестировали и забыли к херам
      лешим!</p>

    <p>То есть не забыли, само собой, но
      в расчёт не принимали: дёргать переключатель под завкафом и запускать заражение
      всего дома — глупо. Молевич заметил бы раньше, чем нужно для создания
      по-настоящему опасной ситуации в городе. Да и для фаланг при худшем развитии
      событий чума из-под кранов <i>именно этого
        дома</i> означала бы покушение на голову гэбни, если б всё-таки удалось
      доказать, что тайной перестройкой канализаций занимался не Университет. До
      вчерашнего дня думали, что нихера они докажут, что всё схвачено, но теперь
      надеяться на прежний план не следовало — он же утёк как-то.</p>

    <p>Мочой по трубам, блядь.</p>

    <p>Устраивая на полу ванной и
      коридора мыльное болото, Гошка в который раз пожалел, что Бедроградская гэбня
      когда-то не удержалась от сортирного юмора в адрес Молевича. Не будь в
      завкафской канализации тестовых фильтров, хер бы Университет сейчас оказался в
      таком выгодном положении. Покушение на голову гэбни со стороны другой гэбни
      (которое другая гэбня не совершала, но фильтры-то с переключателем есть!) —
      слишком, слишком отягчающее обстоятельство, а чума в Бедрограде и без того не
      очень-то легка в качестве превышения служебных полномочий.</p>

    <p>— Даже если предположить, что у
      них там всё решает кто-то один и это не Молевич, — подал голос Андрей, только
      что закончивший возиться со стиральным аппаратом, — всё равно Молевич должен
      быть уже в курсе, что дом заражён. У завкафа не самая типичная симптоматика,
      но, зная о чуме в принципе, не догадаться нельзя.</p>

    <p>— И что?</p>

    <p>Возможно, Андрей пытался сказать
      что-то осмысленное, но и эта попытка Гошку малость
      взбесила. Его бесило сейчас вообще всё — предполагай не предполагай, точно-то
      им не разобраться, кто что знает и кто кого травит. И вообще всё через задницу, и
      задницы завкафа Гошке не досталось — времени нет,
      последствия непредсказуемы, хер знает что ещё.</p>

    <p>Дерьмо.</p>

    <p>— И то, что завкафа он не
      вылечил, — Андрей педантично оглядел ванную, добавил пару косметических штрихов
      картине разрушений и двинулся в коридор.</p>

    <p>— Или сам заразил, — напомнил
      Гошка.</p>

    <p>— И <i>до сих пор</i> не вылечил! — ох, миленький даже повысил голос с шёпота
      до полушёпота. Волнуется?</p>

    <p>— Может, хочет предъявить
      фалангам тело в наиболее плачевном состоянии и выбить из них скупую
      бюрократическую слезу?</p>

    <p>— А может, ему наплевать на
      завкафа, и мы тут с тобой зря время тратим, — объяснил
      наконец миленький причину своего негодования. Вот дурак!</p>

    <p>— Слушай сюда, мой осторожный
      друг, — скривился Гошка. — Кто там говорил, что все люди разные? Так вот, они
      разные. И не все из них столь же трусливы — то есть, прости, предусмотрительны
      и разумны, как ты. Молевич, скорее всего, не считает, что поваляться пару дней
      с водяной чумой — очень уж большая опасность для его драгоценного завкафа. Может, это даже такая
      воспитательная мера и прочие высокие отношения. Что ещё не означает, что он не
      схватится за жопу, когда насквозь больной завкаф
      исчезнет в неизвестном направлении. Совершенно не означает.</p>

    <p>Андрей нарисовал на лице «да-да,
      конечно, я очень ценю твоё мнение, но шёл бы ты лучше лесом», но Гошку это не
      задело вовсе. Главное — хоть не врёт, что со всем согласен.</p>

    <p>— Проехали, — Андрей кивнул Гошке
      на дверь во вторую комнату, а сам пошёл громить кухню. — Просто если он не
      клюнет, будет тупо.</p>

    <p>— Во-первых, у нашей кампании по
      расхищению завкафов двойная цель, забыл? — вселять оптимизм в удаляющуюся спину
      было не особо приятно, но уж во что есть. — И второй цели <i>мы достигнем</i>, тут без вариантов. А с Молевичем надо поработать. Да,
      истерического беспорядка и сопливых писем на кровати
      может оказаться маловато. Значит, сейчас что-нибудь придумаем, чтоб он точно
      клюнул.</p>

    <p>И Гошка шагнул-таки во вторую
      комнату — придумывать.</p>

    <p>Херов Молевич, что бы он там себе
      ни воображал, должен расплатиться за грехи. Вся Университетская гэбня должна,
      но Молевич — в первую очередь. Расплачивается всегда тот, у кого слабые места
      на весь Бедроград светят.</p>

    <p>И ведь не то чтобы иметь слабые
      места порочно само по себе, кто ж их не имеет. Просто слабые места одной головы
      гэбни — это слабые места всей гэбни, если гэбня нормальная.
      <i>Если</i>. А у Университета всё наперекосяк:
      Молевич завёл себе такую слабость, не ткнуть
      носом в которую — просто преступление. Дошёл бы уже
      наконец своим могучим университетским интеллектом до того, что что-то здесь
      неправильно!</p>

    <p>И очень, очень вредно думать, что
      вот Молевич-то не знает, а при другом раскладе служить бы ему на благо
      государства совсем в другой должности. Бедроградская гэбня, правда,
      своевременно и убедительно воспротивилась, но осадочек
      остался.</p>

    <p>Остался-остался.</p>

    <p>Вторая комната была примечательна
      ковром. Херовым белым ковром, ворс по щиколотку — попробуй
      пройди, не оставив следов! Как они вообще умудряются держать его чистым?
      Порхают, бляди?</p>

    <p>Гошка посмотрел на мягкий ворс в
      больших сомнениях — он был преградой на пути к письменному столу, а на столе-то
      две печатные машинки, бумаги, книги с закладками. Наверняка что-то полезное
      найдётся, чтоб запудрить мозги Молевичу.</p>

    <p>В конце концов, ковёр можно
      конфисковать вместе со стиральным аппаратом. Ванная совсем близко, натекло, потом
      электрики грязными ботинками потоптались. И как сознательные работники сферы
      коммунальных услуг они просто обязаны были забрать ковёр в реанимацию за свой
      счёт! Так: не забыть добавить сей факт в послание хозяевам от электриков и в
      самом деле вернуть имущество завтра с бригадиршей, хорошенько почистив.</p>

    <p>А пока остаётся завернуть в него
      бессознательное завкафское тело — надо же как-то спускать добычу с долбаного седьмого этажа! Первоначальный план подразумевал,
      что они что-нибудь сообразят на месте, вытрясут из бригадирши стремянку или
      тележку, набросят сверху тряпку, но ковёр даже лучше.</p>

    <p>Вынос завкафа в ковре, акт первый
      и единственный, современная драматургия в экстазе.</p>

    <p>Принятие стратегического решения
      позволило спокойно и кощунственно прошествовать по белоснежному ворсу в пыльной
      обуви. Здрасьте, фантомные ощущения из чужой жизни:
      ходить по этому ковру босиком и трахать завкафа.</p>

    <p>И стучать фалангам на
      Бедроградскую гэбню!</p>

    <p>Потому что первым, что Гошка
      разглядел на столе, были копии запросов. Подписаны подателями
      в субботу вечером, оперативненько. Ничего нового, но
      посмотреть своими глазами было нелишне: бла-бла-бла, пресечена попытка
      умышленного заражения канализаций и водопровода жилого дома. Предположительно
      смертельно опасным вирусом (предварительное медицинское заключение
      прилагается). Предположительно Бедроградской гэбней
(показания студентки исторического факультета БГУ им. Набедренных
Брованны Шухер и список нелегально проникших в канализацию младших служащих
Бедроградской гэбни прилагаются). Предположительно в
целях вменить Университетской гэбне служебную некомпетентность (ничего не
прилагается, жирный карандашный знак вопроса и меленьким почерком — секретарь
Ларий? — рядом: «Не стоит, это несерьёзно и не очень
честно»).</p>

    <p>К этой макулатуре прикасаться не
      нужно, пусть себе лежит, как лежала. И всем своим видом прозрачно намекает, что
      так тоже не делают — хоть бы папочкой какой прикрыл разбросанную по дому
      производственную документацию, многоуважаемый голова гэбни. Тут, между прочим,
      электрики прогуливаются, у соседей ключи есть, гости на чай забежать могут — да
      мало ли что ещё бывает на частной квартире. Честное слово, стыдно и больно за
      судьбы всероссийской политики.</p>

    <p>Зато теперь совершенно точно
      известно, что вирус они ждали. В запросе, правда, речь всего об одном жилом доме,
      Ройшевом, а не об эпидемии.</p>

    <p>Зассали, хотели обойтись малой
      кровью? За угрозу эпидемии, пусть и выявленную, их бы никто по голове не
      погладил. Допустили возможность — всё равно лохи. Но не очень понятно, что они
      собирались делать дальше. Зачем сначала катать запросы про пресечение заражения
      одного дома, скрывать масштабы катастрофы, а потом травить своих?
      Планы изменились? Фаланги послали их с такой ерундой, и пришлось действовать
      решительней? Кто-то был не согласен с первоначальным планом и развёл
      самодеятельность?</p>

    <p>И что это за приписка про «несерьёзно и не очень честно»?</p>

    <p>У них было что-то похожее на
      доказательства намерений Бедроградской гэбни? Что, блядь? И откуда? Сами
      сфабриковали и вдруг усовестились, когда до дела дошло?</p>

    <p>Гошка недовольно тряхнул головой
      — нашёл время думать! И так дурная привычка, а сейчас вообще без толку, сейчас
      первоочередная задача — нагнать вокруг завкафского исчезновения драматизма погуще да пожирнее. Молевичу пора бы переключиться с чумы в
      Бедрограде на что-нибудь более подходящее его пламенной натуре. Поиски
      пропавшего возлюбленного — прекрасный сюжет, благодарные радиослушатели заранее
      утонули в соплях.</p>

    <p>Так как радиоприёмника поблизости
      не наблюдалось, взгляд Гошки сам непроизвольно нашёл ему замену и упёрся в
      бесконечные книжные полки, оцепившие комнату по периметру. Антикварный горшок
      возвращается! Все издания у завкафа были либо ветхими и раритетными, либо,
      наоборот, подарочными и расфуфыренными — тяжёлые переплёты, кожа с тиснением,
      деньги некуда девать.</p>

    <p>Ближе к окну, в паре сантиметров
      от зарослей белоснежного ворса, во всё это буйство затесался целый ряд паршивых
      овец в мягких обложках — в смутно знакомых мягких обложках. Гошка пригляделся:
      они самые, выпуски «Литературы Нового Бедрограда».
      Было в пятидесятых-шестидесятых такое всё из себя
      элитарное студенческое чтиво, его ещё потом закрыли к лешему за какой-то
      плагиат с порнографической повести Хикеракли. Когда Гошка учился на юрфаке,
      хорошим тоном считалось непременно быть в курсе содержания свежего номера за
      пару дней до выхода.</p>

    <p>С хорошим тоном и вообще всякой
      там литературой у Гошки были сложные отношения. Натянутые и драматичные. Книжки
      читают те, кому заняться больше нечем, но иногда ведь и правда бывает нечем — когда
      сидишь в сортире, например.</p>

    <p>Короче, как ни тяжко было это
      признавать, херову «Литературу Нового Бедрограда»
      Гошка раньше знал едва ли не наизусть. По крайней мере
      всю ту, которая вышла за пять лет его учёбы. Сейчас, стоя с пистолетом
      наперевес посреди уютного гнёздышка головы враждебной гэбни, Гошка в который
      раз осознал, что в жизни ничего не бывает зря: завкаф-то на младших курсах в
      этом самом журнале печатался! Он точно помнит, помнит-помнит, там ещё был один
      рассказ, такой, полный мистический прозрений, про —</p>

    <p>То что
      нужно, короче.</p>

    <p>Подборка «Литературы Нового Бедрограда» полетела на пол. Какой же это год?
      Шестьдесят четвёртый? Пятый? Осенью читал, в коллоквиум с тем несчастным
      зачётом по имперскому священному праву, никому не нужная историческая
      дребедень, преподавателю петарда в карман досталась — но курс-то какой был?</p>

    <p>А, вот он, нужный выпуск.
      Завкафское имя даже на обложке есть, с ума сойти как всё серьёзно.</p>

    <p>Гошка ткнулся
      в содержание, нашёл рассказ — «Белое дерево», леший еби! — и открыл сразу
      последнюю страницу. Кого вообще волнует, чем всё началось, главное — чем
      кончится.</p>

    <p>&nbsp;</p>

    <p><i>«В лесу было сыро и глухо, ноги мои всё охотнее бросались навстречу
      ветвям низких деревьев —&nbsp;продолжай идти, пока не упадёшь, только так всё
      закончится. В прогалине впереди сверкнула трава — неожиданно высокая, зелёная,
      та, которой можно напиться. В ней будет мягко, и когда я уйду — никто не
      услышит звука моих шагов.</i></p>

    <p><i>Ветви разжались, и я выпал на поляну — утреннюю, безмолвную. Отсыревшее
      небо, всхлипнув, распахнулось — продолжай идти.</i></p>

    <p><i>Здравствуй, я почти уже здесь, я уже не слышу себя; сейчас дыхание моё
      падёт росой, прозрачной, беззвучной, и они не найдут меня, и даже тело, даже
      то, что останется, не ухватят их жадные руки. Они вспомнят, и спросят, и ты
      ответишь — он улетел.</i></p>

    <p><i>На том конце поляны, по щиколотку в траве, стояло белое дерево,
      источавшее тихий свет, пропахший грозовым озоном и ещё чем-то — поднебесным,
      невыразимым.</i></p>

    <p><i>Я не зря шёл.</i></p>

    <p><i>Здравствуй.</i></p>

    <p><i>На ветви дерева, почти касаясь ногами травы, висел я — уродливый,
      кривой, с нерасплёсканным хохотом на лице. Я должен
      был заглянуть в свои глаза, должен, но —</i></p>

    <p><i>Слишком страшно?</i></p>

    <p><i>Смешной смертный страх, последнее сомнение — и небо с ворчанием
      сомкнулось обратно, свет впитался в древесную кору, блестящая как ножи трава
      зашелестела. Я достал из кармана бумагу и ручку, написал записку —&nbsp;простите-прощайте, я пришёл, дальше некуда, инициалы. Я
      умер, повесился на суку, как последняя шваль — иначе
      было нельзя. Я просто хотел летать, но вы слишком липкие, слишком цепкие, мне
      пришлось идти. И я пришёл.</i></p>

    <p><i>Я пришёл.</i></p>

    <p><i>Прощай.</i></p>

    <p><i>Я сунул записку в карман к себе и вскинул голову — теперь сделаны все
      дела, можно смотреть. В моих мёртвых глазах было только стекло — простое,
      небесное — и от этого стекла я вдруг впервые почувствовал, как назойливо, до
      мозолей жмут мне ноги. Столько лет носил, привык, не замечал, смирился,
      озлобился, опошлился, пал пора конец сколько можно скинуть скорее скинуть моё
      тело скроено не по размеру но его можно скинуть скорее скорее скорее скорее скорее —</i></p>

    <p><i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;—
      скорее —</i></p>

    <p><i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;— лететь.</i></p>

    <p>&nbsp;</p>

    <p><i>Я честно листал потом сводки, даже ездил ещё раз в родную деревню —
      ведь кто-то же должен был быть мной, чьё-то тело я нашёл тогда на суку, в
      чей-то карман засовывал размокшую записку! Впустую; жизнь в деревне простая и
      жестокая, исчезают люди чаще, чем можно подумать. Этот напился и помёрз, тот
      ухнул в реку — всех не учтёшь. Так что нет ничего удивительного в том, что ни
      упоминаний о самоубийце, ни семьи его я не сумел отыскать — чего уж говорить о
      самом теле или белом дереве на краю поляны. Ходи не ходи — всё впустую.</i></p>

    <p><i>Слишком густые вокруг моей родной деревни леса».</i></p>

    <p>&nbsp;</p>

    <p>О глубокий внутренний мир завкафа,
      полный смертей и воскрешений!</p>

    <p>Гошка неаккуратно рванул
      прочитанную страницу. «Литература Нового Бедрограда»
      нехотя, буквально-таки с хрипом расставалась с фальшивыми покойниками —</p>

    <p>&nbsp;</p>

    <p><i>«Думаешь, это всё не по-настоящему? Думаешь, ещё живой?»</i></p>

    <p><i>Зачем мне думать, я и так знаю.</i></p>

    <p>&nbsp;</p>

    <p>Так стоп, это уже не то. Ни в
      одном глазу не то, вообще не оттуда.</p>

    <p>Память совсем оборзела от
      культурных вливаний такого градуса, сама теперь подбрасывает какие-то
      посторонние цитаты.</p>

    <p>Или не цитаты —</p>

    <p>&nbsp;</p>

    <p><i>«Не умрёт уже, некому больше. Померли все и так».</i></p>

    <p><i>И шаман хихикал, хихикал и шуршал своей юбкой, чавкал, ковыряясь в
      потрохах.</i></p>

    <p><i>«Не веришь? Не хочешь вспоминать? Не нравится?»</i></p>

    <p>&nbsp;</p>

    <p>Что за нахер
      в голову лезет?</p>

    <p>— Ты чего? — застыл на пороге
      Андрей, явно тоже задумавшись о грязи с ботинок и порхающих блядях. Белоснежный
      ворс поверх паркета никого не оставит равнодушным.</p>

    <p>Чего-чего. Окультурился по самое
      ничего!</p>

    <p>— Проходи, не стесняйся, — позвал
      его Гошка. — Затолкаем завкафа в ковёр, вытащим из подъезда, а завтра вернём
      ковёр без завкафа, но чистеньким и пушистеньким. От
      лица электриков, я имею в виду.</p>

    <p>Андрей кивнул, пулей очутился у
      стола, нацелился на те самые копии запросов фалангам, но мгновенно забил, как
      только Гошка пренебрежительно махнул рукой. Всё-таки приятно, когда тебя
      понимают без слов, для того и нужна вся эта синхронизация, чтобы —</p>

    <p>&nbsp;</p>

    <p><i>Нет, не я; мы.</i></p>

    <p><i>Мы придумали, мы сделали, нашим кишкам и по ветру болтаться. И не жалею
      я, нисколько не жалею себя, не жалею тех, кто платил; жалею лишь, что дело не
      сделалось, что всё впустую вышло. Пусть бы даже они победили,
так бывает; но если одни только смерть и страх, и ничего больше, то, выходит, —
зря, зря, всё зря, все —&nbsp;зрячие, слепые —&nbsp;в одну кучу, а должно быть
не так, должно быть ясно, кто, где и зачем.</i></p>

    <p><i>«Ты ш-ш-что ж думаеш-ш-шь, тут и впрямь
      —&nbsp;победить можно?»</i></p>

    <p>&nbsp;</p>

    <p>Рехнуться,
      а!</p>

    <p>Накатило, блядь, от завкафской писанины. Это не цитаты, не литература, это ж в самом деле было. В смысле, привиделось под наркотой —
      Врата, безотказная, вдоль и поперёк знакомая женщина «для психической
      разгрузки», когда-то весной угощала. Говорила, приходы ого-го, картинки цветные, почти как настоящие. Ей
      самой тогда ещё давний любовник являлся, вёл душеспасительные
      беседы о том, что, почему и как у них вышло. А Гошке…</p>

    <p>— Ты точно ничего у завкафа не
      успел подцепить? — обеспокоено спросил Андрей. — Выглядишь странно.</p>

    <p>Опять та же песня, да сколько
      можно. В порядке Гошка, в порядке.</p>

    <p>Если и подцепил чего, то только мистические прозрения, леший их. Ему ведь
      под наркотой от Враты до всякой чумы чума являлась. Вирус нужный подобрать не
      успели, а Гошка уже смотрел галлюцинации про шамана с наплечником, Загробную гэбню
      и то, как заражение вышло из-под контроля.</p>

    <p>Какой он, оказывается,
      слабонервный местами — прям как завкаф. Всё проебали, все умерли, ах! Не ах, а
      снова фрайдизм. Это потому что тогда как раз в канализациях недалеко от бывших
      метелинских мануфактур что-то обвалилось, Бедроградская гэбня стремалась, что
      Университет полезет чинить, заметит
      перестроенную систему очистки. В общем, на том участке фильтры пришлось под
      покровом ночи в восемь рук демонтировать, безо всяких инженеров. Хорошо
      искупались.</p>

    <p>У Андрея аж
      на лбу читалось, что он сейчас не выдержит, кровь на анализ добывать начнёт.
      Параноик и не лечится.</p>

    <p>— Отцепись и помоги мне сочинить
      предсмертную записку, — потряс выдранной страницей Гошка. — План созрел.
      Действительно коварный и на литературной почве. У
      завкафа в далёкой юности был рассказ про то, как чувак
      страдал-страдал, исстрадался в конец и отправился в поисках ответов на вечные
      вопросы в родное село. А там в соседнем лесу нашёл
      себя повешенным. Не себя, конечно, просто умеренно похожего мужика, но в карман
      ему подложил послание всему прогнившему свету со своей подписью. И типа
      просветлился. Понимаешь, к чему клоню?</p>

    <p>Андрей слушал внимательно, как
      всегда немного по-отрядски, и это грело. Как и то,
      что, пока Гошка вставлял в печатную машинку свежий лист и задумчиво разминал
      пальцы, Андрей быстро, ловко и без возражений выдёргивал херов ковёр из-под
      всего, что на нём стояло.</p>

    <p>Не трахнуть
      завкафа, так хоть в ковёр закатать!</p>

    <p>— Он же не бедроградский, да? С
      Пинеги? — вспомнил наконец Андрей, посмотрел на Гошку
      восхищённо и миленько-миленько, сил нет. — Если Молевич помчится искать его аж
      на Пинегу… Это ж четыре часа в один конец, как только
      сядет на поезд — можно подтверждать им запрос на встречу. Ну
      ты даёшь!</p>

    <p>Даёт. Догоняет и ещё раз даёт.</p>

    <p>И
      пожалуйста, миленький, не спрашивай, с чего Гошке вдруг вздумалось в завкафской
      прозе копаться. И тем более — как это подходящий образец так быстро откопался.</p>

    <p>Миленькому
      до тридцати целый год жить, он почти на десяток лет младше Гошки, «Литературу
      Нового Бедрограда» уже не застал — ну и хорошо, ну и не надо.</p>

    <p>В некоторых вещах стыдно признаваться даже при их хвалёной синхронизации.</p>
  </>
}

export default chapter16
