import React from 'react'
import Helmet from 'react-helmet'
import feedback from '../../Data/feedback'
import MarkdownPage from '../../MarkdownPage'

const FeedbackPage = () => <>
  <Helmet>
    <title>Нам пишут</title>
  </Helmet>
  <MarkdownPage
    sidebarPosition='right'
    markdown={feedback}
    sidebar=''
  />
</>

export default FeedbackPage