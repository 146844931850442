import React from 'react'
import { IBookPage } from './index'

const chapter38: IBookPage = {
  header: 'header_d10.jpg',
  title: 'Чем всё закончилось с другой стороны',
  prevLink: 'chapter37',
  nextLink: 'epilogue',
  content: <>
    <h2>Гошка</h2>

    <img alt='' src="/images/coin_two.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p><i>Ответственный за коммуникативный процесс: Говори быстрее, вспоминай, а то застрянешь
      на всю вечность —&nbsp;а мне охота разве с тобой маяться?</i></p>

    <p><i>Гошка Туралеев: А что вспоминать? Мы не сделали ничего. (Пауза.) Собираемся… не
      успели пока. Надо было навести порядок, ну ты
      понимаешь, да? Не со стволом же за ними бегать. (неразборчиво)
      …другие методы.</i></p>

    <p><i>ОКП: Какие?</i></p>

    <p><i>Г. Т.: А тебе всё расскажи.</i></p>

    <p><i>ОКП: Мне-то что, я уже всё знаю, это тебе нужно. (Усмешка.) Чтобы
      совесть не терзала.</i></p>

    <p><i>Г. Т.: Ну так, значит, сам знаешь. Вирус.</i></p>

    <p><i>(Короткая пауза.)</i></p>

    <p><i>ОКП: Серьёзный масштаб. Страшно не было?</i></p>

    <p><i>Г. Т.: Это не так и страшно, если лекарство заранее сделать. Кто
      заразится, тому придётся, конечно, немного помучиться, но мы успеем всех
      вылечить. Нам только и надо — припугнуть немного.</i></p>

    <p><i>ОКП: Немного?</i></p>

    <p><i>Г. Т.: (смешок) Один дом. (Пауза.) Ты сам-то подумай: а если болезнь
      сама собой случится, без нас и без лекарства? Бывают же эксцессы, и чем дольше
      их не было, тем больше шансов, что они на носу. Что тогда от города останется?
      Вот и я думаю, нечего ждать. Это просто наглядная демонстрация.</i></p>

    <p>&nbsp;</p>

    <p>А это — просто ле-по-та.</p>

    <p>Расшифровка допроса («допроса»,
      ебать их коленом!) лежала на столе гораздо более пухлой стопкой, чем ожидал
      здравый смысл и трезвая память. Вот нужна она Гошке или не нужна?</p>

    <p>С одной стороны, это вроде как
      официальный документ, Базальд прислал от лица реформированной Университетской
      гэбни в порядке сатисфакции. Вместе с гошковским же пистолетом. Типа мы как на
      духу, типа теперь между гэбнями всё чисто, мир и херова любовь до крышки гроба.
      Закрываем вопрос наиболее цивилизованным из всех возможных образом. Гошка
      Петюньевич может любоваться на эту лепоту до крышки всё того же гроба.</p>

    <p>С другой же стороны, ну кого это
      ебёт?</p>

    <p>Вопрос академического толка с
      экзамена в Институте госслужбы: какую юридическую силу имеет эта макулатура в
      свете того, что голову гэбни шестого уровня доступа не имеет права допрашивать
      никто, кроме фаланг и Бюро Патентов лично, и уж тем более — не мертвец без
      всего?</p>

    <p>Садитесь, вы меня не удовлетворили.
      Правильный ответ: в этом государстве —&nbsp;какую
      угодно.</p>

    <p>Но как же,
лепечете вы, ведь аудиозапись можно проанализировать, непринадлежность голоса
никому из хотя бы голов Университетской гэбни доказуема, тут есть, чем
защищаться?</p>

    <p>Ваши шансы на дополнительной
      сессии, скажем прямо, я тоже оцениваю как невысокие.</p>

    <p>Мы живём в государстве великих
      возможностей, где каждый отхватывает ровно столько, на
      сколько у него хватает наглости.</p>

    <p>Поразмыслив, Гошка метнул пухлую
      стопку в общую кучу.</p>

    <p>Пусть будет напоминаньицем.</p>

    <p>На экране крупным планом возник
      растроганный профиль какого-то старика, что только без скупой слезы. Снимали со
      спин и из-за кустов, поэтому крупных планов, особенно не затылков, получилось
      мало. Этот определённо следует оставить, пусть Бюро Патентов прорыдается от
      патриотизма.</p>

    <p>Гошка сделал соответствующие
      пометки на листе.</p>

    <p>Дальше потянулся бесконечно
      скучный общий план как раз-таки затылков с невнятным Первым
      Большим Перевёрнутым где-то вдалеке. Звука не было, его писали отдельно, но
      Гошка смутно припоминал, что речь толкали экологи. Оставить, значит, полминуты
      нарезкой под эту самую речь, тонкости никому ни в хер не упали.</p>

    <p>Мы тут не образовательные
      материалы клепаем, а духоподъёмные патриотические картинки. Первые,
      экспериментальные, не только ж для кассахских шлюх
      аппаратура существует. Сегодня в здании Бедроградской гэбни, завтра у Бюро
      Патентов, послезавтра (как только производство техники наладят для масс) — в
      доме каждого добропорядочного гражданина.</p>

    <p>Скучный план продолжал тянуться.</p>

    <p>Гошка не удержался, встал со
      стула и продолжил наводить порядок в вещах. Вот списку бедроградских младших
      служащих, выполняющих свои обязанности в Столице, нечего прямо посреди полочки
      делать.</p>

    <p>У нас же теперь есть целый новый
      голова гэбни.</p>

    <p>Изображение на экране неожиданно
      уехало куда-то в сторону, сквозь кусты, и остановилось на крупном же плане
      человека, хмуро беседовавшего с кем-то в стороне от общего празднования.
      Собеседника закрывала листва — почти целиком, виднелась только часть корпуса и
      руки от локтя.</p>

    <p>До чего яркий и целостный образ.</p>

    <p>Съёмкой изображения руководил,
      разумеется, Бахта, так что, когда он увидел, что Гошку незаметно увлёк в
      сторону фаланга, одно из подглядывающих устройств направилось следом. Бахта
      волновался. Не мог подслушать — решил хотя бы подсмотреть.</p>

    <p>Гошка никогда прежде не видел
      себя со стороны в движении.</p>

    <p>Со стороны в движении он выглядел
      очень даже ничего.</p>

    <p>&nbsp;</p>

    <p>В государстве великих
возможностей власть непублична. Простым людям не полагается о ней
      задумываться в принципе. Ввиду этого никакого официального присутствия
      Бедроградской гэбни на десятилетнем юбилее Первого
      Большого Переворота быть не могло.</p>

    <p>Но как же воздать почести и
      выразить уважение умищу покойного Набедренных!</p>

    <p>Так что Бедроградской гэбне
      полагался свой собственный юбилей, маленький и тайный, на задворках у
      основного. Под короткую и вдохновенную речь фаланги, при форме и с болтающимися
      поверх наплечников языками.</p>

    <p>Но разве кого-то волнует, сколько
      они вокруг организации феерии и торжества бегали.</p>

    <p>Если бы не это, если бы можно
      было сразу явиться в штатском, фаланги могли бы и не заметить (и уж точно не
      доказать), что у Бедроградской гэбни осталось только три головы. Но отсутствие
      четвёртого мундира не проебёшь, поэтому фаланг даже предуведомили.</p>

    <p>Мы будем втроём: Андрей
      Эдмундович Зябликов временно недееспособен по причине нервного расстройства.</p>

    <p>Воздание почестей прошло мирно и
      гладко, только фаланга между делом попросил всех троих продемонстрировать
      табельное оружие и отметил, что у Гошки пистолет Андрея. Гошка не хотел думать
      о том, как фаланга догадался, почуял, заподозрил —&nbsp;и тем более о том, как
      он удержал в голове номера из двенадцати символов. Отговорился синхронизацией —
      перепутал, мол, — а потом все лихо разбежались. Бахта в фургончик с аппаратурой
      для записи изображения, Соций переодеваться в охранника, Гошка — просто
      разбежался.</p>

    <p>С разбегу оно проще.</p>

    <p>И ведь даже не подумал тогда, что
      пронесло, — ничего не подумал.</p>

    <p>План был гениален и прост. Бьют
      до сантиметра выверенные (пожара ещё не хватало) фейерверки,
      грохочет музыка, приглашённый из Столичного Университета гость читает
      вступительную речь, и все эти суки искренне и убедительно проникаются духом
      праздника, внимают мудрости Набедренных и гордятся всем на свете. Отрядские
      дети хором запевают, какую-то особо чувствительную дамочку уже откачивают, и
      Гошка, бегающий между простых граждан, понимает, что
      это всё чего-то стоит и зачем-то нужно.</p>

    <p>Что все политические дрязги в конечном счёте служат благу для простых людей.</p>

    <p>Порт перекрыт, чтобы вот этому
      мужику из Средней Полосы было что рассказать потомкам.</p>

    <p>Андрей в психушке,
      чтобы отрядские дети с младых ногтей по-настоящему любили свою страну.</p>

    <p>Гошка бегает промеж простых
      людей, неизбежно подхватывает их праздничное настроение, вспоминает ясноглазые
      юрфаковские идеалы, и ему перестаёт казаться —</p>

    <p>В общем, так и выглядел последний
      шанс Гошки.</p>

    <p>План был гениален, прост и почти
      сработал, а потом Гошку нежно ухватили за локоть и повлекли куда-то в сторону.
      Он сперва даже недоумённо обернулся (и чего,
      интересно, собирался увидеть?), и даже покорно пошёл следом.</p>

    <p>— Вам не следовало снимать
      мундир, — равнодушно заметил фаланга, когда они отошли на достаточное
      расстояние от толпы, — без него бинты слишком заметны.</p>

    <p>Это был не тот фаланга, который
      читал вдохновенную речь на частном превозношении херова дуба.</p>

    <p>— Очень хорошо, что вы
      озаботились моим благосостоянием, — ответил Гошка, —&nbsp;у меня как раз
      вопрос. Бедроградская гэбня воздавала почести Большому
      Перевёрнутому в частном порядке, под руководством фаланги. Не вас. А где он —
      тот, который был с нами? Сколько здесь вообще присутствует фаланг? Сегодня же,
      знаете ли, большой праздник, всему госаппарату полагается отметиться. Наверняка
      фаланги должны были съехаться в огромном количестве. У вас было своё
      празднество, ещё более тайное, чем наше?
      Смилостивьтесь, покажите мне грузовик с фалангами, который стоит ещё дальше в
      кустах, я хочу на это посмотреть.</p>

    <p>Фаланга смерил Гошку слегка
      любопытствующим и очень удовлетворённым взглядом.</p>

    <p>— Ваш запрос не соответствует
      вашему уровню доступа.</p>

    <p>— А ваше беспокойство о моём гардеробе
      —&nbsp;вашей юрисдикции, так что я пошёл.</p>

    <p>Ведь никто здесь не хочет, чтобы
      дело дошло до рукоприкладства, верно?</p>

    <p>— Вы очень талантливый человек,
      Гошка Петюньевич, — промямлил фаланга.</p>

    <p>Мимо, водоросль, твои приёмчики.</p>

    <p>Гошка кивнул и развернулся
      уходить.</p>

    <p>— Я хотел поговорить с вами об
      Андрее Эдмундовиче, — разродился фаланга, почти
      повысив голос.</p>

    <p>Что тоже победа — ну и что, что
      после этой фразы Гошка не смог уйти.</p>

    <p>Пришлось медленно развернуться
      обратно.</p>

    <p>— Что именно вас интересует? Все
      бумаги были направлены по соответствующему адресу. Если это официальное
      разбирательство, говорить вам полагается с <i>тремя</i> головами Бедроградской гэбни.</p>

    <p>— Временно
недееспособной ввиду неполноты состава, насколько я понимаю.</p>

    <p>Временно недееспособной,
      но поскольку никто, кроме них, не справится с проведением юбилея, на это
      прикроют глаза, да?</p>

    <p>Хер тебе, водоросль.</p>

    <p>— Нас не уведомляли о прекращении
      дееспособности гэбни, речь шла только о временном отсутствии в ней Андрея
      Эдмундовича по состоянию здоровья. Если это официальное уведомление, то я буду
      только рад отправиться прямо в Бедроград прямо сию же минуту.</p>

    <p>Фаланга поморщился.</p>

    <p>— Ситуация действительно сложная,
      а вы, Гошка Петюньевич, действительно талантливый человек. Судите сами:
      Бедроградская гэбня удовлетворительно организовала проведение столь важного и
      ответственного мероприятия, количество накладок в рамках допустимого. Но при
      этом один её голова отсутствует. Конечно, на облике мероприятия это не
      скажется, но халатность, согласитесь, откровенная.</p>

    <p>— Андрей Эдмундович заболел, — сквозь
      зубы проговорил Гошка.</p>

    <p>Сломался в неподходящий момент.
      Это бывает. Не всегда бывает так капитально.</p>

    <p>Миленький
      не стоял на ногах, не мог удержать дрожь в руках и сперва всё твердил и твердил
      что-то нелепое про трещины, тетрадки, своё отрядское детство и Савьюра.
      Последнее Гошка ещё более-менее понял по контексту (не сразу, но таки допёр, под
      кого косил Смирнов-Задунайский), а остальное,
      кажется, вынырнуло из каких-то не освоенных человечеством недр. Здравый смысл —
      мол, нехер вестись на очередную атаку университетских,
      это же всё то же самое, миленький, мозги-то прочисти — на Андрея не
      подействовал. Тот только бормотал, что ситуацию нужно прекратить, что ему это
      поможет, что нужно перекрыть каналы восприятия&nbsp;—</p>

    <p>Бормотал, бормотал — и прямо на
      складе стал задыхаться.</p>

    <p>Тем и закончилась встреча, насилу
      успели добежать до ближайшего телефона. На складе его нет, среди захваченных
      младших служащих сильно квалифицированных медиков тоже не нашлось, а у Андрея в
      такси все нужные ампулы имеются — да только хером Гошка ебал, что в таких
      случаях надо колоть, это ж не пулевое-ножевое, а
      какие-то психические процессы. И пришлось звонить собственным врачам и
      спокойным голосом спрашивать, что делают в таких ситуациях.</p>

    <p>А потом выслушивать краткую
      энциклопедическую выдержку о панических атаках, вызывающей их тревожности,
      необходимости как минимум психотерапии и всём таком.</p>

    <p>Послать нахер, бросить трубку и
      обойтись даже без уколов — простыми дыхательными упражнениями.</p>

    <p>Миленький
      не свихнулся, миленький довольно быстро пришёл в себя, бледный, но живой. Миленький
      окончательно очухался бы как миленький через пару дней
      (врачи это сказали, он сам это сказал), но — херов юбилей, куда нельзя не
      прийти и куда нельзя приходить в таком состоянии. Куда миленький не смог бы
      толком дойти.</p>

    <p>Пришлось создавать иллюзию
      состояния здоровья, благо была в черте города Бедрограда <i>очень специальная</i> психиатрическая лечебница в смежной юрисдикции
      Бедроградской и Медицинской гэбен. Туда фаланги не сунутся, а с Виктором
      Дарьевичем уладили постфактум. В лечебнице сказали, что, раз такое дело, можно
      лечить тревожность Андрея постепенно и поэтапно, без спешки.</p>

    <p>Ночные разговоры на складе почти
      успели стать утренними.</p>

    <p>На то, чтобы разобраться с миленьким, у
      Бедроградской гэбни
      оставались жалкие три-четыре часа.</p>

    <p>И они согласились на постепенно, поэтапно и без спешки.</p>

    <p>Андрей, разумеется, был против. Говорил, что это ненадёжно, что лучше ему
      что-нибудь
      сломать или хотя бы написать, что сломали, а то лечебницу под Медицинской гэбней,
      куда фалангам не сунуться, они не
      простят.</p>

    <p>Андрей Эдмундович заболел.</p>

    <p>— Андрей Эдмундович не посетил
      юбилей Первого Большого Переворота, — еле заметно
      развёл руками фаланга, — а это прецедент. Как вы сами думаете, что нам с ним
      делать?</p>

    <p>— Я сам думаю, что вас совершенно
      не интересует, что я думаю, потому что вы всё давно решили, — отрезал Гошка.</p>

    <p>— Ваша несговорчивость — часть
      вашего таланта, — растянул губы в улыбке фаланга.</p>

    <p>Это была бессмысленная фраза,
      нужная только для того, чтобы помурыжить. Фаланги не тянут время просто так, им
      всегда нужно либо додержать до кондиции, либо дать подумать, либо навести на
      какую-то мысль.</p>

    <p>Гошка отчаянно сопротивлялся, но
      мысль пришла сама собой.</p>

    <p>Гэбня не считается недееспособной
      по причине физического отсутствия (в том числе из-за болезни) одного из голов.
      Каждый может уехать, каждый может в самом деле сломать
      ногу.</p>

    <p>Гэбня считается недееспособной — <i>временно недееспособной по причине
      неполноты
      состава</i> — только в том случае, если в ней официально менее четырёх голов.</p>

    <p>Если одного из них решили
      отстранить.</p>

    <p>Насовсем.</p>

    <p>Фаланга снова посмотрел на Гошку
      с любопытством и удовлетворением. Тот факт, что ваш мыслительный процесс столь
      явно пропечатывается у вас на лбу, Гошка Петюньевич, — это тоже часть вашего
      таланта, так приятно видеть, что он идёт в нужную сторону.</p>

    <p>Интересно, что будет, если
      вломить ему прямо здесь и сейчас? Ведь с некоторой вероятностью — ничего.</p>

    <p>А потом ещё выяснится, что и оно пошло
      на пользу государству.</p>

    <p>Всё ведь всегда оказывается
      строго противоположным твоим ожиданиям.</p>

    <p>— Это официальное уведомление? —
      мрачно спросил Гошка.</p>

    <p>Нынешний
фаланга был немного нетипичным —&nbsp;свежим, наверное. Не успевшим
      выцвести. Со слишком здоровой кожей и слишком заметной радостью.</p>

    <p>— Нового голову Бедроградской
      гэбни назначат в течение трёх дней, — проворковал он.</p>

    <p>Не ответив на вопрос.</p>

    <p>— Это официальное уведомление? —
      с нажимом повторил Гошка и шагнул вперёд — достаточно грозно, чтобы водоросль
      шевельнулась.</p>

    <p>— В вопросе донесения
      которого до двух других ныне актуальных голов Бедроградской гэбни я полагаюсь
      на фактическую синхронизацию, — так и не сумел выдавить прямого ответа фаланга,
      невольно чуть отклоняясь назад.</p>

    <p>Ну точно
      свежий.</p>

    <p>Им так не полагается.</p>

    <p>То есть Гошка думает, что не
      полагается, служебных инструкций фаланг он ни разу в жизни не видел. Кто знает,
      что из всего этого им и правда предписано.</p>

    <p>Надо сдержаться. Всё должно
      пройти спокойно. Никаких эксцессов.</p>

    <p>Гошке очень нужно, чтобы всё было
      спокойно.</p>

    <p>— Я перебираю в уме ваши реплики
      и пытаюсь понять, не додумал ли чего-нибудь, чего вы не говорили. Складывается
      впечатление, что Андрея Эдмундовича отстранили от службы. Поправьте же меня,
      если вы не это имели в виду.</p>

    <p>Фаланга снова растянул губы и
      качнулся.</p>

    <p>— Желаю ему скорейшего
      выздоровления, если таковое возможно, — самодовольно изрёк он и пошёл прочь.</p>

    <p>Вот так просто?</p>

    <p>За непосещение юбилея Первого Большого Переворота?</p>

    <p>Да что за херня!</p>

    <p>— По-моему, это идиотизм, — не
      удержался Гошка фаланге в спину.</p>

    <p>Тот аж
      зажмурился от удовольствия.</p>

    <p>— Если это официальная позиция
      Бедроградской гэбни, то она, во-первых, сформулирована не в соответствии со
      служебными инструкциями, эксплицитно предписывающими головам гэбни
      высказываться во множественном числе, а во-вторых, не требуется в данной
      ситуации до соответствующего запроса. Если же это ваше личное мнение, Гошка
      Петюньевич, то оно звучит… довольно грубо.</p>

    <p>Смакует, блядь. Получает
      удовольствие от работы.</p>

    <p>И ничего — ничего! — нельзя
      сделать. Нельзя опротестовать, нельзя возмутиться, нельзя привлечь внимание
      общественности. Просто фаланги решили, что Андрею хватит.</p>

    <p>И ничего нельзя сделать.</p>

    <p>— Первый Большой Переворот —
      важнейший символ нашего государства, — сочувственно заметил фаланга. — В нём
      воплощается всё, ради чего мы живём, чем хотим быть, к чему стремимся. Если
      госслужащему это не нужно, сложно не задаться вопросом о том, нужен ли такой
      госслужащий.</p>

    <p>Гошка ничего не мог на это
      ответить.</p>

    <p>Потому что фаланга был прав.
      Госслужба — это херово призвание, и когда собственный зад становится дороже
      херова дуба, с неё пора уходить.</p>

    <p>Ох, миленький.</p>

    <p>Миленький.</p>

    <p>Не попасть под ПН4 после подвигов с Колошмой, остаться в гэбне и удачно
      миновать ещё одно ПН4, когда у тебя отловили херовы <i>склады смертельного вируса,</i> — и распрощаться с уровнем доступа
      из-за того, что не смог дойти послушать исполнение государственного гимна
      детьми.</p>

    <p>Думали, что пронесло, но фаланги
      методичны, и сколько раз человеку может повезти перед одним невезением?</p>

    <p>Сам Гошка не думал, что пронесло.
      Фаланги методичны, фаланги доводят до ума.</p>

    <p>А последний шанс на то и
      последний, что тут либо грифон, либо решка.</p>

    <p>Ребром не падает.</p>

    <p>&nbsp;</p>

    <p>Гошка критически изучил свой
      крупный план в движении. Ну а что, отлично держит лицо.</p>

    <p>Он хмыкнул и сделал пометки на
      листе. Изображениям с юбилея отчаянно не хватает крупных планов — ну так пусть
      будет ещё один. Миниатюра «фаланги в нашей жизни», очень выразительно.</p>

    <p>Плёнка вернулась к демонстрации
      массовки.</p>

    <p>На место Андрея в Бедроградскую
      гэбню пришёл новый голова, Олег Жеверецкий — высокий, энергичный, деятельный и
      до тошноты хорошо осведомлённый о событиях последнего времени. Прежде сидел со
      своим восьмым уровнем доступа в гэбне Международных Отношений. Сразу сказал,
      что предпочёл бы пока что нарушить служебные инструкции и не выстраивать
      фактической синхронизации. В переводе на человеческий язык — сидите со своей
      чумой на жопе ровно, постепенно пообвыкнемся.
      Первостепенная задача с его точки зрения — восстановить функциональность Порта
      и добрые с ним отношения.</p>

    <p>Профессиональный, опытный и
      разумный человек.</p>

    <p>Душа компании.</p>

    <p>Хороший голова гэбни.</p>

    <p>Хороший
      глава гэбни.</p>

    <p>Бахта и Соций сперва
      чувствовали себя немного преступниками, не поднимая вопрос чумы, но уже
      втянулись. Этот Олег не раздавал указаний, разумеется, но правильно направлял,
      так что всё само собой вертелось. Бегал по встречам, громко восхищался
      деятельным подходом Бедроградской гэбни.</p>

    <p>Добился снятия блокады Порта.</p>

    <p>Гэбня Международных Отношений
      умеет решать такие вопросы.</p>

    <p>А миленький — ну что миленький.
      Гошка хотел сходить к нему лично — тогда же, в день юбилея, — но постепенное
      поэтапное восстановление его дееспособности оказалось более серьёзным процессом,
      чем Гошка предполагал. То ли и правда взялись за искоренение всех его неврозов,
      то ли Медицинской гэбне не хватает экспериментальных
      материалов, то ли у Виктора Дарьевича всё ещё имеются свои планы.</p>

    <p>Это ведь <i>очень специальная</i> лечебница.</p>

    <p>Не пустили.</p>

    <p>На столе в кобуре по-прежнему
      лежал пистолет Андрея —&nbsp;перераспределили, когда Гошка послал уведомление
      об утере собственного табельного. Олег пришёл со своим, а Андрею больше не
      полагалось, так что почему бы и нет.</p>

    <p>Почему бы и нет.</p>

    <p>Если бы не Андрей, не сидел бы
      Гошка сейчас в здании Бедроградской гэбни. Он проверился в лаборатории и
      убедился —&nbsp;конечно, в группе риска. В той самой четверти населения,
      которую их лекарство от чумы не лечит. У людей, вышедших из алхимических печей,
      кровь стабилизирована, но различающиеся белки присутствуют и передаются по
      наследству. Так что, в сущности, даже термин «группа крови» вполне применим,
      хотя им и не пользуются.</p>

    <p>У Гошки была третья.</p>

    <p>Большинство заражённых
      с третьей группой крови не выживали.</p>

    <p>Кроме тех, кем занимался
      Университет. Их лекарство работало на всех. Их лекарство делали из настоящей, а
      не искусственно синтезированной крови.</p>

    <p>Если бы не Андрей, кто бы помешал
      Гошке трахнуть чумного завкафа в надежде потом
      вылечиться —&nbsp;и не вылечиться.</p>

    <p>Если бы не Андрей, не сидел бы
      Гошка сейчас в здании Бедроградской гэбни, не сидел. Летом 74-го, когда Университетской гэбни ещё не было, все высшие уровни доступа
      хором постановили, что Университетом всё-таки нужно управлять. Решили поступить
      по образцу Столицы: посадить в Бедроградскую гэбню кого-нибудь
      университетского.</p>

    <p>Только чтобы кого-нибудь
      посадить, нужно кого-нибудь снять.</p>

    <p>И этим кем-то должен был стать
      Гошка.</p>

    <p>В августе, когда пришло
      уведомление, Андрей только-только пообвыкся — сам он очутился в Бедроградской
      гэбне всего на полгода раньше. И тут — бумажка. Гошке Петюньевичу Туралееву
      надлежит покинуть должность головы Бедроградской гэбни. В качестве рекомендации
      (но никак не конкретного указания) ему предлагается престижная и подходящая по
      всем параметрам позиция заместителя ректора Института госслужбы.</p>

    <p>Гошка тогда взбеленился, потому
      что слишком уж очевидно было, что дело не в нём, а в необходимости усадить на
      его место правильного человека.</p>

    <p>Максима Молевича, тогда ещё
      аспиранта, пешком из-под стола. Максима Молевича с контрреволюционным движением
      за плечами.</p>

    <p>Максима Молевича, при упоминании
      которого Андрей резко нахмурился.</p>

    <p>Ещё бы — мало того, что они
      меньше года назад этого Молевича вдоль и поперёк на Колошме пережёвывали (кого
      66563 покрывал-то), так Андрей к тому же знал всю его медицинскую подноготную.
      Изменённый уровень агрессии, проще говоря — псих и
      лечить нет смысла.</p>

    <p>Бахта пожал плечами — мол, жалко,
      но такова государственная служба, мы знали, что она собой представляет.</p>

    <p>Привыкший выполнять приказы Соций
      промолчал — с приказами <i>бюрократическими</i> у него куда более сложные отношения, наверняка не одного хорошего человека штаб
      по тупости приказал на смерть отправить.</p>

    <p>Гошка сказал, что никуда не уйдёт
      и херова точка.</p>

    <p>И Андрей его поддержал.</p>

    <p>Одно, мол, дело, когда голову
      гэбни заменяют в связи с повышением или нарушением. Другое
      — когда убирают просто так, наплевав на прекрасный послужной список.</p>

    <p>Все понимали, что Андрей просто
      боится сидеть с Молевичем в одной гэбне (синхронизироваться с психом, из-за
      которого опосредованно развалилась Колошма!),
      только это никого не ебало.</p>

    <p>Бумажку они попросту сожгли,
      звонков две недели успешно избегали. Тогда сильно повезло, был один человек в
      Силовом Комитете, согласившийся помочь — проинформировать о перемещениях фаланг.
      Всего он знать не мог, но ещё пару недель протянули, не оказываясь на рабочем
      месте, когда уведомления пытались передать лично.</p>

    <p>Потом один из фаланг просто
      пропал на пути к зданию Бедроградской гэбни.</p>

    <p>Они ведь без охраны ходят,
      внаглую.</p>

    <p>Разве ж кто-то виноват, что в
      городе неспокойная обстановка? Давно пора было озаботиться приструниванием
      Порта.</p>

    <p>Фаланги намёк
поняли и больше уведомлениями не сыпали, а в сентябре возникла Университетская
гэбня с этим самым Молевичем среди голов и шестым уровнем доступа.</p>

    <p>Ебись лопатой, Гошка Петюньевич.</p>

    <p>Фаланги методичны и доводят дело
      до конца.</p>

    <p>И их много.</p>

    <p>Гошка промотал плёнку вперёд — на
      этой катушке больше ничего, кроме общего плана, не имелось. Видимо, Бахте
      самому было немного не до съёмок в процессе.</p>

    <p>Вынув катушку из аппарата, Гошка
      положил её на видное место, как раз рядом с пистолетом Андрея, прикрыл листом с
      расписанием того, что следует вырезать для финального монтажа. Создать материал
      с юбилея — серьёзная идеологическая работа, его технической разметкой не может
      заниматься никто, кроме головы гэбни.</p>

    <p>Вот теперь всё на своих местах.</p>

    <p>Этот Олег прекрасно сработался с
      Бедроградской гэбней, Бахта и Соций повеселели, жизнь пришла в нормальный
      режим, разве что распивание джина перестало сопровождаться стрельбой по карте.
      Синхронизация пока недостаточная, а главное — как разумно (профессионально и
      опытно) заметил этот Олег, перед тем, как кого-то истреблять, нужно добиться
      безукоризненной работы того, что есть. И вообще, нынешняя ситуация явно
      располагает к созданию своеобразной сети гэбен разного уровня доступа, которые
      могли бы в первую очередь сотрудничать, а уж потом — выяснять отношения. В
      Университетской гэбне теперь сидят Юр Поппер, который явно имеет свои связи с
      гэбней Медицинской, и Стас Шмелёв, бывший голова гэбни Колошмы. Ну а оставшийся на
      своём месте Ларий Базальд очень радеет за
      дружбу всех бедроградских гэбен шестого уровня доступа. Он сам, Олег, уж точно
      проследит за тем, чтобы и с гэбней Международных Отношений (и опосредованно с
      Портом) всё шло гладенько. Разве предыдущий опыт, который мы пока что не
      обсуждаем, не научил нас тому, что выигрывают те, кто может работать вместе?</p>

    <p>Короче, долой изоляционизм, даёшь
      дружбу и всероссийскую синхронизацию.</p>

    <p>Как будто это кому-то поможет,
      если рыба была гнилой с самого начала.</p>

    <p>Гошка закинул рюкзак на плечо и
      осмотрел стол с катушкой и кобурой (у него есть свой пистолет, уже не
      табельный, шаман же грозил кислотной слюной —&nbsp;вот и прислали обратно со
      сбитым номером, Университет доигрывает спектакли до конца и не забывает о том,
      что во Всероссийском Соседстве трудно достать оружие). Осмотрел потемневший
      экран, серый потолок, простой паркетный пол, диван на четверых во вроде как
      личном кабинете, поредевшие полки.</p>

    <p>Всё на своих местах, никаких
      сантиментов в адрес квадратных метров, пусть даже они успели стать куда более
      родными, чем имеющаяся где-то в Бедрограде квартира.</p>

    <p>В Столицу уезжают с вокзала в
      Старом городе, а до вокзала добираются на автопоезде — такси он не водит,
      третьему лицу не доверит, а Бахта — голова гэбни.</p>

    <p>Правильно, хорошо работающей гэбни, которая быстро оправилась от всех
      потрясений и уже почти привела город в порядок.</p>

    <p>Гэбни, в работоспособности
      которой Гошка убедился лишних сорок восемь раз перед тем, как выйти из
      кабинета.</p>

    <p>Отчасти дело, конечно, в том, что
      некоторые ошибки не прощают. Гошку переиграл Университет, из-за чего всё
      покатилось под откос, и сколько бы оно ни выравнивалось — некоторые ошибки не
      прощают. Гошка предпочёл бы, чтобы его гэбня об истории с шаманом вовсе не
      знала, Гошка отмалчивался до последнего (может, это и оказалось последним
      маленьким гвоздиком в крышку гроба психического здоровья миленького?).</p>

    <p>Отмалчивался, чтобы не начали
      отговаривать.</p>

    <p>Потому что на самом деле соль,
      разумеется, не в этом. Просто госслужба — это херово призвание, и нельзя
      просиживать форменные брюки или даже полировать табельное оружие в гэбне, если
      ты не любишь само государство. Нельзя, потому что слишком просто задурить
      собственную голову и искренне уверовать в то, что ты печёшься о вверенном тебе
      городе.</p>

    <p>Если правда печёшься, если правда
      хочешь, чтобы людям было лучше жить, стоит задуматься о том, с какого хера ты
      ставишь знак равенства между «лучше» и «как
      придумало Бюро Патентов».</p>

    <p>Это государство было создано
      странными людьми со странными целями, и награждают здесь не умных
      и смелых, а хитрых и пронырливых. В этом государстве между двумя уровнями
      доступа — пропасть, зияющая дыра, которую не заполнить никакими молитвами и
      прочей мистической поебенью. В этом государстве тот, кто выше, получает от
      работы вагон удовольствия, а тот, кто ниже, просто не думает о том, кто выше.</p>

    <p>Ведь думать вредно.</p>

    <p>Всем, от мала
      до велика.</p>

    <p>Мы живём в прекрасном мире и в
      прекрасной стране, на которой выросли паразитические надстройки, перебирающие
      длинными пальцами, стравливающие хороших, в общем-то, людей ради
      собственной потехи, ставящие свои
      извращённые эстетические предпочтения выше любого здравого смысла. Способные простить склад смертельного вируса и лишить уровня
      доступа за несвоевременную болезнь.</p>

    <p>Держащих
      каждого херова нормального человека даже не в постоянном страхе —&nbsp;в
      постоянной невесомости.</p>

    <p>Как можно двигаться вверх, если
      под ногами нет почвы?</p>

    <p>Юный Молевич с его
      контрреволюционным движением был на удивление прав. Ему не хватило мозгов,
      таланта и удачи, поскольку личные проблемы всю жизнь застилали глаза, но по сути он
      был на удивление прав.</p>

    <p>Университет с его вечным
      балаганом нашёл свой способ жить не по-фаланговски.</p>

    <p>Гошка так не умеет.</p>

    <p>У Гошки броская внешность,
      медальон на шее, который так легко опознать, а теперь ещё и две дырки в правой
      руке — та ещё особая примета. Гошка по-прежнему любит Бахту и Соция, Гошка
      по-прежнему любит институт гэбен. Не любит он всё то, что мешает этому
      институту работать по-человечески.</p>

    <p>Гошка хочет жить в стране, где
      можно не только думать, но и знать, что думают <i>о тебе</i>.</p>

    <p>Ходят слухи, что много хороших людей нынче подались в Столицу, а значит, со
      Столицы и начинать. Столичную гэбню не жалко (вот уж
      кто точно сам виноват), да и с Бахтой и Социем так получится подольше не
      пересекаться. Не говоря уж о том, что некоторые из подавшихся в Столицу хороших
      людей могут разделять кое-какие дооформившиеся наконец
      мысли.</p>

    <p>Бедроградская гэбня работает
      хорошо. Бедроградская гэбня работает хорошо <i>и
        без него</i>, <i>Гошки,</i> он убедился.</p>

    <p>В Бедрограде всё будет спокойно —
      какое-то время.</p>

    <p>В конце концов, динамит — это
      очень просто, а за десять часов дороги до Столицы можно придумать и более
      изящные решения. Найти сочувствующих, задействовать
      имеющиеся ресурсы. Предложить интересную сделку томящимся
      в Медкорпусе.</p>

    <p>В Бедрограде всё будет спокойно,
      потому что Всероссийское Соседство не ограничивается Столицей и Бедроградом, а
      у института фаланг всё-таки есть одно достоинство.</p>

    <p>Все их резиденции во всех городах
      Всероссийского Соседства располагаются по адресу Революционный проспект, дом 3.</p>
  </>
}

export default chapter38
