import React from 'react'
import { IBookPage } from './index'

const chapter27: IBookPage = {
  header: 'header_d8.jpg',
  title: 'Глава 27. Черёмуха',
  prevLink: 'chapter26',
  nextLink: 'chapter28',
  content: <>
    <h2>Бедроградская гэбня. Соций</h2>

    <img alt='' src="/images/27_sociy.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>— Сейчас, сука, — очень спокойно
      возразил Соций.</p>

    <p>Злиться рано. Поговорить так поговорить. Хотя ой как хотелось расквасить
      наглую рожу.</p>

    <p>— <i>Сейчас,</i> сука, — ещё спокойнее повторил Соций.</p>

    <p>В ответ — томный выдох, полузакрытые
      глаза, запрокинутая голова.</p>

    <p>Но рожа
      всё равно наглая, Соция не обманешь.</p>

    <p>— У тебя, сука, язык отнялся? —
      вознегодовал Соций. — Всё в бордельные ужимки ушло?</p>

    <p>Склад был почти пустой, всё
      повынесли. Взамен поставили стол, два стула, записывающие устройства по углам
      (хотя ведущие микрофоны всё равно вмонтированы в стол), да проигрывающую
      аппаратуру в стороне — могла пригодиться.</p>

    <p>До стола Соций и его собеседник
      ещё не дошли. Как только закрылись ворота, Соций развернул того к себе за плечо
      и рявкнул: «Неужто это ты был
      что Дмитрием Борстеном, что Дмитрием Ройшем? Морду не вороти, отвечай давай!»</p>

    <p>Собеседник вывернулся из-под
      руки, зябко вздрогнул, одними губами произнёс: «Позже».</p>

    <p>Да какое позже. Сейчас, сука.</p>

    <p>Ну
      приехали: пошатнулся, потерял равновесие, рухнул прямо Социю на грудь,
      вцепился. Мда. Посмотрел этак (<i>этак!</i>) снизу вверх и пролепетал:</p>

    <p>— Извини.</p>

    <p>Вот падла,
      в обморок вздумал хлопаться.</p>

    <p>Видел бы его Соций впервые,
      может, и поверил бы. Шелка, кружева, обмороки — ага, карман держи пошире и не
      забывай заодно пихтские макаронины сам у себя на
      ушах поудобней развешивать.</p>

    <p>— Отставить хуйню, сержант
      Гуанако, — на автомате выдал Соций.</p>

    <p>— Командир, никакой хуйни. Чума в
      городе, силы на исходе.</p>

    <p>И за шею обхватил.</p>

    <p>Наглая, наглая рожа.</p>

    <p>А обращение «командир», пропетое таким
      вот тоном, — это уже совсем.</p>

    <p>— Отставить, я сказал, — Соций
      нахмурился. — Или тебе надо сначала личико попортить,
      чтоб ты вспомнил, что ты делаешь, когда с тобой командир говорит?</p>

    <p>Исполняешь или подыхаешь. В
      ёбаной Северной Шотландии только так.</p>

    <p>Гуанако (Гуанако это, Гуанако —
      дерьмовым бы Соций был командиром, если б своего сержанта не признал)
      выпрямился, обхватывание за шею прекратил и попробовал удержаться на своих
      ногах. Выглядел всё равно обморочным (прямо как этот их завкаф): потёр виски,
      запустил пальцы в волосы, снова пошатнулся.</p>

    <p>На этот раз Соций поймал его сам.
      Прежде, чем успел подумать.</p>

    <p>Это всё шелка и кружева.</p>

    <p>— Ты, блядь, насквозь, что ли,
      пропитался своей ёбаной черёмухой? — искренне озадачился Соций, ясно уловив
      характерный запах.</p>

    <p>Отняли же букет на входе. В букете
      чего хочешь спрятать можно.</p>

    <p>Отняли, а Гуанако всё равно
      воняет так, будто он этой черёмухой срёт.</p>

    <p>— Спасибо, командир, — слабо
      улыбнулся Гуанако.</p>

    <p>Оторвал дрожащие пальцы от виска
      и, блядь, провёл ими по щеке Соция.</p>

    <p>— Совсем вздурел? — когда
      воняющие черёмухой гуанаковские пальцы добрались по щеке до губ, Соций
      отшвырнул того к лешему.</p>

    <p>Отшвырнул к лешему, инстинктивно
      облизнул губы, тряхнул головой.</p>

    <p>Гуанако в шелках и кружевах на
      полу — это ещё хуже, чем Гуанако в шелках и кружевах на шее.</p>

    <p>Соций несильно, но с душой пнул его ботинком:</p>

    <p>— Ну
      чего ты развёл оскопистский бордель? Нормальный вроде мужик был когда-то, а
      тут…</p>

    <p>«Мы привыкли, что университетские
      при виде любой опасности откладывают здоровую такую кучу в штаны, а они
      действительно бляди, и недешёвые», — сказал у Соция в голове Гошка.</p>

    <p>Так отчётливо сказал, что Соций аж обернулся на ворота.</p>

    <p>Заперто.</p>

    <p>Никого нет, только он и Гуанако в
      шелках и кружевах на полу.</p>

    <p>«Бляди, — повторил в голове у
      Соция Гошка. — Боевые, перешедшие в нападение».</p>

    <p>Соций невольно в который раз
      окинул взглядом складское помещение. Слишком простая геометрия, негде укрыться
      в случае атаки боевых блядей. Винтовки или автоматы?
      Нет, что-то другое. Здравый смысл подсказывает: у боевых
      блядей снаряжение должно быть облегчённое, как у разведгруппы. Гранаты
      наверняка будут.</p>

    <p>Граната в ограниченном
      пространстве — это же дерьмо. Склад не бункер,
      места-то много, можно откатиться, но стены хлипкие.</p>

    <p>И окон нет. Вот засада.</p>

    <p>Гуанако приподнялся на локте,
      посмотрел на Соция мутными глазами.</p>

    <p>Соций поймал себя на том, что
      хватается за отсутствующую кобуру.</p>

    <p>Какие нахуй боевые бляди?</p>

    <p>Боевые
      бляди — это ж Гошка говорил про Университет, когда в среду дошло, кто Андрею
      веселье с фалангами устроил. Кто завкафский дом через канализацию заразил.</p>

    <p>Кто из Бедроградской гэбни
      распоследних кретинов сделал.</p>

    <p>— Командир, ты чего? — окликнул
      Соция Гуанако.</p>

    <p>Стал собой на пару секунд, но
      стоило сфокусировать на нём взгляд, тут же оборделился обратно. Дрогнул
      плечами, закусил губу, уставился из-под чёлки.</p>

    <p>Университетские боевые бляди как
      они есть.</p>

    <p>— Да что ж ты университетский, а?
      — искренне и невпопад посетовал Соций.</p>

    <p>— Да что ж ты
      бедроградско-гэбенный? — еле слышным голосом прошелестел в ответ Гуанако.</p>

    <p>— Да что ж ты живой, — буркнул
      себе под нос Соций.</p>

    <p>Гуанако нехорошо усмехнулся и
      подался вперёд:</p>

    <p>— Я мёртвый. А черёмуха — чтоб
      гнильё перебить. Не гнилым же на допрос приходить.</p>

    <p>— Это не допрос, это частная
      встреча, — отбрил Соций.</p>

    <p>От черёмухи тянуло блевать.</p>

    <p>Если под черёмухой гниль, тогда
      понятно, чего так мерзко.</p>

    <p>— Не надейся, — вскинулся
      Гуанако, — мёртвых не так легко допрашивать. Уметь надо. Мёртвые — упрямые, пойди разговори.</p>

    <p>— Зачем припёрся,
      если так? — Соций непроизвольно прикрыл нос.</p>

    <p>Гниль от трупов ядовита, лучше не
      вдыхать.</p>

    <p>— Чума прислала, — зашипел
      Гуанако.</p>

    <p>— Ты не заговаривайся. Не
      присылала тебя чума, мы тебя сами позвали. Не тебя, но мы.</p>

    <p>Гуанако расхохотался. Захлебнулся
      хохотом, снова откинулся на пол. Соций подошёл, встал прямо над ним, вперился в
      зелёные (нет, зеленоватые, — это у Гошки зелёные,
      когда он не в линзах) глаза. Выколоть, может, нахуй?</p>

    <p>Зеленоватые потому что. Болото,
      плесень, гниль.</p>

    <p>— Вы сами, вы сами позвали, —
      выдавил из себя Гуанако сквозь хохот. — Чума не присылала, потому что чума — я.
      И вы меня позвали.</p>

    <p>— У нас лекарство есть, —
      отмахнулся Соций.</p>

    <p>— Есть, да не от той.</p>

    <p>— Опять про степную пиздишь? Ты
      про степную, Шапка про степную. При чём здесь она?</p>

    <p>— Степная чума всегда при чём, —
      хохот оборвался. — Степная чума — это все болезни разом. Прячется в насморке, в
      желудочных коликах, в мигрени. Кругом, кругом одна степная
чума, никогда не знаешь, где вылезет, разыграется и сожрёт с потрохами, а вы
позвали на свои головы, слишком близко подошли, слишком обнаглели, страх
забыли, переступили черту, вам теперь век от неё — от меня! — не избавиться,
всюду найду, догоню, поймаю, опутаю, сгнию по кусочку, зубы выпадут, глаза
ослепнут, кожа лохмотьями сойдёт, руки отнимутся, ноги потяжелеют, лихорадка
измучает, а когда изнутри гниль горлом пойдёт, как привяжется запах черёмухи
      — не отмыть, не отделаться, так в могилу с черёмухой и сойдёте.</p>

    <p>— Какую могилу? — вдруг оборвал
      его Соций. — Тех, кто от степной чумы умер — жгут. Тех, кто не умер, тоже.</p>

    <p>— А вас никто жечь не будет. И
      закапывать тоже. Кому охота руки марать? Вы чуму позвали, вы нелюди,
      вы уже живая гниль. Ты надрежь кожу, у тебя там гной и черви вместо крови и
      мяса.</p>

    <p>Соций только хмыкнул:</p>

    <p>— А что если тебе надрезать?</p>

    <p>Хохот вернулся, заполнил
      складское помещение до потолка.</p>

    <p>— А ты попробуй. У тебя ножа нет,
      бритвы нет, никакого стекла даже нет. Всё на входе отняли. Чего делать будешь —
      кусать?</p>

    <p>Гуанако — Гуанако ли? Мертвец?
      Чума? Черёмуха! — снова приподнялся и подставил Социю свою голую загорелую шею.</p>

    <p>Очень знакомым — после
      давешних-то операций — жестом.</p>

    <p>— Ты плохой завкаф, черёмуха, —
      брякнул Соций. — Завкаф-то бледный.</p>

    <p>— Был бледный, — заговорщицки
      подмигнул черёмуха. — Бледный, хилый, изнеженный — вот и не выдержал чумы. Она
      сначала в нём по городу ходила, а потом он закончился, и чума нашла себе кого
      покрепче.</p>

    <p>Соций заржал, поразившись
      возникшей догадке:</p>

    <p>— Так выходит, это не ты, а чума
      шелка и кружева любит?</p>

    <p>— И черёмуху, чтоб не вонять! —
      подтвердил черёмуха.</p>

    <p>Соций опустился на корточки рядом
      с черёмухой — рассмотреть получше.</p>

    <p>Кожу рассмотреть, червей под ней,
      соединение волос с черепом — не могут же они быть настоящими, если черёмуха —
      мёртвый.</p>

    <p>И тут сообразил: хуйня какая.</p>

    <p>Оживающие мертвецы, степная чума.
      Где-то это всё уже было.</p>

    <p>Говорили же сами. Как только
      поняли, что Университет тоже в игре, говорили и про мертвецов, и про чуму, и
      про лешего в ступе. Обычная психическая атака, к тому же рассчитанная непонятно
      на кого.</p>

    <p>— Слушай, ты серьёзно? — быстрее,
      чем успел обдумать, спросил Соций. — В смысле, само собой, несерьёзно, я о другом.
      Ты серьёзно считаешь, что на мне твои сказки хоть
      как-то сработают?</p>

    <p>— Не считаю, — оскалился
      черёмуха, — давно со счёта сбился: трупы, трупы, трупы вокруг. Никто не
      считает, я не считаю, вы не считаете, они не считают, а обернёшься — считать-то
      и нечего, за спиной пустота, дыра в пустоту, крысами объеденная. Закрысь, как в
      тюрьме говорят, на Колошме говорят, всё оттуда, с Колошмы, и тянется, вся погань оттуда прёт: степная чума, водяная чума…</p>

    <p>— Ебальник-то завали, — вполне
      миролюбиво предложил Соций. — Не на того напал. Я в курсе, что у нас полстраны
      на Колошме ёбнутые. Но я-то нет, мне похуям.</p>

    <p>— …вся погань оттуда прёт, —
      черёмуха вещал, будто и не слыша Соция, — степная
      чума, водяная чума, Андрей ваш оттуда.</p>

    <p>— Опять, — сплюнул Соций. —
      Сколько можно в одну и ту же дыру ебать? Не прокатил ваш план, выяснили уже,
      что Андрей нас не кидал и не собирался.</p>

    <p>— Не собирался?</p>

    <p>Черёмуха, наконец, сел. Лежа не
      так удобно глазами сверкать и страшные лица корчить.</p>

    <p>Соция заебало.</p>

    <p>С одной стороны, все эти
      завывания и причитания производили какой-то эффект. Вроде и не верилось, а жуть
      всё равно порядочная: явился на встречу человек, который семь лет как числится подохшим от степной чумы. Да ещё и не тот, которого ожидали.</p>

    <p>Явился и про степную чуму сказки
      рассказывает. Может, тронулся башкой от неё. Хотя
      скорее уж выёбывается просто, внимание рассеивает. Грамотно рассеивает вполне.</p>

    <p>А с другой стороны, ну заебало
      же.</p>

    <p>— Отставить наезды на Андрея. Не
      твоего ума это дело, черёмуха, что у нас в гэбне происходит.</p>

    <p>Потому что даже если вдруг
      всё-таки происходит — лучше самим из табельного расстрелять в уголке, чем
      кого-то постороннего допускать до.</p>

    <p>До.</p>

    <p>До чего-то, о чём не говорят и не
      думают, пока оно не случится.</p>

    <p>Случилось — расстрелять самим и
      забыть к лешему.</p>

    <p>С предателями всё по-быстрому.</p>

    <p>— Страшно, командир? — прошептал
      черёмуха. — Ты не боишься всего этого: чумы — любой чумы, смертей — будто ты
      смертей не видел. Гнили, червей, трупов — это глупости для всяких завкафов в
      кружевах. Фаланг не боишься, санкций — до Колошмы же может дойти, если
      разберутся, на что вы замахнулись. Но это всё ерунда, командир, так? А вот если
      кто-то свой кинул — это страшно.</p>

    <p>— Отставить.</p>

    <p>— …потому что никому нельзя
      доверять. Кроме тех, кто прикрывает тебе спину. А если и там подстава, значит, никто
      никогда ничего и не прикрывал.</p>

    <p>— Ещё одно поганое слово…</p>

    <p>— И что, командир? — черёмуха
      подался вперёд. — Ты совсем двинулся — мертвецу угрожать? Хочешь на червей у
      меня под кожей полюбоваться? Давай-давай, только говорить ты с кем будешь? У
      тебя вопросы какие-то были, помнишь?</p>

    <p>Соций выдохнул.</p>

    <p>Черёмуха знает, что делает.
      Знает, куда волну гнать, чтоб башка не варила.</p>

    <p>Черёмуха — университетская боевая
      блядь.</p>

    <p>Нельзя расслабляться.</p>

    <p>— Дался вам всем Андрей, — мотнул
      головой Соций. — И чего дался-то? Самый скользкий с виду потому что? Так все ж
      понимают, что это он специально так держится. Никто из нас в вашу лажу не
      поверит — на что рассчитываете?</p>

    <p>Не о том спрашивать собирался, но
      что теперь-то.</p>

    <p>— Ты будто не врубаешься, —
      черёмуха уставился в потолок. — Он голову своей первой гэбни…</p>

    <p>— Да не он, бляди, — Соций аж
      хуякнул кулаком об пол.</p>

    <p>Все, все обсосали уже эту ёбаную
      Колошму. Чем она намазана, что не перестать сосать до сих пор?</p>

    <p>— Не он, и не он стрелял, и не он
      приказ отдавал — оно как-то само получилось, — подтвердил черёмуха. — У него всегда
      так и бывает: тут два слова, там два слова, похлопал глазками, а потом оно
      как-то само получилось, а Андрей вроде и не делал ничего.</p>

    <p>Не поспоришь.</p>

    <p>Но что с того?</p>

    <p>Черёмуха хмыкнул:</p>

    <p>— Он ведь тому голове гэбни в рот
      заглядывал, каждое слово ловил, буквально в тетрадочке записывал. Серенькой
      такой, как сейчас помню. Бац — и перестал записывать. Тетрадочка, наверно,
      закончилась.</p>

    <p>— Что ты мне доказать пытаешься?</p>

    <p>— Не доказать. Показать, —
      черёмуха явно видел в переплетении металлических балок на потолке что-то более
      занимательное, чем скептичное лицо Соция. — Вам ведь кажется, что вы тут
      вчетвером против всего мира, маленькая храбрая Бедроградская гэбня. А это ж
      нихуя не так. У вас служащих, полуслужащих и всяких прочих — жопой ешь. Какую
      работу вы им даёте — другой вопрос, но они есть. Все ыздные гэбни Бедроградской
      области и их служащие — тоже под вами, правильно? Да и Порт, Университет — они
      ж вам не враги на самом деле, вам просто хочется, чтоб они были врагами. И
      простые люди имеются, которые вообще ни при чём. То есть вы за них отвечаете,
      это, наверное, никак из головы не выкинешь, но парочка-то приятелей для
      забухать и расслабиться точно найдётся, — черёмуха помолчал немного, продолжая
      крайне осмысленным взглядом сверлить балки над головой. — А на Колошме-то не
      так. Степь кругом, все люди в радиусе нескольких десятков километров — это те,
      кто на Колошме. Заключённые и охранники. С заключёнными понятно, но
      охранники-то тоже чай не из институтов госслужбы. Местные же в основном, из
      самых цивилизованных степных поселений — себе на уме, работают за зарплату. И
      для гэбни Колошмы, выходит, есть только гэбня Колошмы. Это ж правда как на
      вражескую территорию вчетвером пробраться. А тут такое дерьмище в гэбне.</p>

    <p>Продолжать черёмуха не стал.</p>

    <p>А Соций не стал отвечать.</p>

    <p>Ощущение было, будто стены
      раздвигаются и истаивают. Будто за ними эта ёбаная степь. Может даже — ёбаные
      горы: засиженные снайперами перевалы и заминированные ущелья, а вы идёте
      вчетвером — четыре ножа и одна граната, можно ещё прикладом приложить, если что,
      но патроны всё, и найти б хоть один труп при патронах, неважно, в чьей форме и
      с каким флагом, ничего не важно, только б вы четверо дошли хоть куда-нибудь.
      Вчетвером, не меньше, только б не меньше.</p>

    <p>Можно идти и не вчетвером, это
      случайно так подумалось. В армии-то гэбен нет. Сколько выжило в очередной
      операции — столько и идёт. Но <i>каждый</i>,
      кто с тобой на войне, должен куда-нибудь дойти. Что бы ни творил и кем бы ни
      был — должен. Это не сантименты, это такое правило. Не веришь в него — не
      суйся.</p>

    <p>А стены всё раздвигались и
      раздвигались.</p>

    <p>Переутомление, в глазах рябит.
      Весь вечер и почти всю ночь ко встрече с Дмитрием Борстеном готовились, а
      теперь стены уплывают.</p>

    <p>— Эй, черёмуха, — позвал
      откуда-то из застенных далей Соций. — Чё такое Дмитрий Борстен?</p>

    <p>— Откуда вы его вообще взяли? —
      голос черёмухи прозвучал точно из-под воды.</p>

    <p>— Есть способы.</p>

    <p>— А Дмитрия Борстена — нет, —
      черёмуха, кажется, нахально поржал. — Его выдумали, чтоб ваши способы вас и
      запутали. Чтоб вы себе мозги ебали, пока дела делаются.</p>

    <p>Соций сфокусировал взгляд:</p>

    <p>— И чё ты мне тогда это сдаёшь?</p>

    <p>— А чё б не сдать? Мозги
      проёбаны, дела сделаны — Дмитрий Борстен больше не нужен. Вы думали, вы себе
      хороший кусок отхватили, это свидание назначив, и поуспокоились на ночь — а
      Университет себе времени под другое выиграл.</p>

    <p>— Подо что же? — с сомнением
      поинтересовался Соций.</p>

    <p>Горы вместо стен его малость
      отвлекали.</p>

    <p>— Да так, по мелочи. Теперь
      крышка вам, если в отсутствии табельного оружия и гэбенных полномочий кто-то из
      Университетской гэбни ноги протянет. Протягивать — так вместе, — неуместно
      подмигнул черёмуха.</p>

    <p>Подмигнул и водрузил свои ёбаные
      ноги в узких штанах Социю поперёк коленей.</p>

    <p>— Опять бордель, ёба? —
      прикрикнул на черёмуху Соций, но тот только прогнулся поживописней. — Отвечай,
      ёба, Дмитрия Ройша тоже нет?</p>

    <p>— Нет, — черёмуха картинно
      скользнул рукой по бедру, вытащил сигареты.</p>

    <p>Сигареты тоже стоило бы забирать
      на входе.</p>

    <p>— А тавру в Медкорпусе кто мозги
      пудрил? — настаивал Соций, игнорируя ноги, бедра и всё остальное. — Или тавр
      сам нам пудрил? Но на Андрея с расспросами про Дмитрия Ройша наседали фаланги.
      А раз так, не может быть, чтоб его вовсе не было. Какое-то подставное лицо не
      могло не отсвечивать.</p>

    <p>Соций говорил сам с собой.</p>

    <p>Ясно же: черёмуха соврёт, если
      захочет, а стены больше не раздвигаются, они падают, и хочется упасть вместе с
      ними, провалиться на самое дно — самое дно чего? — это как в реку после взрыва,
      кажется, выжить нельзя, но вода почему-то выносит некоторых потом живыми, а
      некоторые тонут и бьются о пороги, и вроде бы все знакомы с инструктажем по
      горным течениям, вроде бы всё делают правильно, но кого вынесет живым, а кого
      мёртвым, никогда не знаешь, инструктаж, опыт — не спасут, спасает всегда что-то
      другое, просто с реками это заметнее всего, как будто реки и выбирают, а на
      самом деле — всегда так, всегда выбираешь не ты, а тебя, если повезёт, а если
      не повезёт, то и сплывай нахуй по течению, сплывай-сплывай!</p>

    <p>У черёмухи в усмехающихся губах
      вспыхнул кончик цветной сигареты.</p>

    <p>Бирюзовая бумага, фильтр из
      золочёной — явно же дрянь ароматизированная, которую курить невозможно.</p>

    <p>Соций вспомнил: давным-давно,
      когда брали ещё одного Дмитрия — Смирнова-Задунайского, того как раз, которого
      ждали сегодня Борстеном и Ройшем, — у него в сумке обнаружилось несколько таких
      сигарет. Бирюзовых, лиловых, голубых. С золочёными фильтрами. Там ещё
      обнаружились запрёщенные лекарственные препараты и расшифровки по делу
      Начальника Колошмы, Бед��оградская гэбня обрадовалась: хотели просто
      университетских студентов подёргать, чтоб их новоиспечённая гэбня не зарывалась,
      а тут такое нарушение информационной безопасности!</p>

    <p>Смирнов-Задунайский мигом сам на
      Колошме очутился, Университетская гэбня зассала и разозлилась, стали уже
      всерьёз палки друг другу в колёса пихать.</p>

    <p>И на тебе: Соций помнит цветные
      сигареты столетней давности. И зачем столько мусора в голове? Никому не нужные
      детали, никому (кроме запараноившего Андрея) не нужный Смирнов-Задунайский — а
      в результате ёбаный золочёный фильтр у черёмухи делает бессмысленно жутко,
      будто всё возвращается, всё записано где-то во всех подробностях, ошибёшься — и
      всё тебе припомнится. Так, как сам в жизни не припомнишь.</p>

    <p>Ёбаные золочёные фильтры, ёбаные
      психические атаки!</p>

    <p>— Почему в медкорпусовском досье
      у Дмитрия Ройша фотография Смирнова-Задунайского?</p>

    <p>Соций спрашивал, сам не понимая
      зачем. Фотографию видел Андрей, Андрей мог ошибаться, сходство могло быть
      случайным, а черёмуха вообще может сто лет не помнить, кто такой
      Смирнов-Задунайский.</p>

    <p>— Хорошо они вас задели, эти
      фальшивые Дмитрии, — выпустил струю дыма Социю в лицо черёмуха. — А никто и не
      надеялся почти, что вы такие трепетные и сентиментальные.</p>

    <p>Одна нога черёмухи переместилась
      Социю на плечо, закрыв форменный наплечник.</p>

    <p>Соций вдруг задал себе вопрос: а
      какого лешего он всё это терпит?</p>

    <p>— Ты не комментируй, ты отвечай,
      ёба!</p>

    <p>— Это ты отвечай: кассаха шёл
      ногами бить, поэтому так задело? — черёмуха лениво приподнялся, закусил губу.</p>

    <p>— Да кассахов надо не ногами, — в
      тон ответил Соций.</p>

    <p>Кассахов надо в младенчестве
      сапогом давить, чтоб не вырастали в хитрых продажных блядей, которые по
      шотландским горам на британских боеприпасах сидят.</p>

    <p>— Вот и я говорю, все вы дураки в
      вашей Бедроградской гэбне, — расстегнул на своих шелках и кружевах пару пуговиц
      черёмуха. — Мозгов нет, потому и подменяете политику личными предпочтениями.</p>

    <p>— Ты у меня поговори, — рыкнул
      Соций.</p>

    <p>— У тебя кассахи, у Андрея —
      призраки бурной юности, у Гошки — мировая справедливость и кому за какие
      заслуги уровни доступа перепадают. Горазд же человек чужие заслуги
      подсчитывать, просто поразительно. Один тавр у вас нормальный, — шелка и
      кружева таки упали с плеча черёмухи. — Но ему можно, он тавр, тавры — не люди,
      их вон тоже некоторые любят ногами и не ногами, кого ебёт тавр, косы им все
      пообкорнать, и дело с концом, а конец у всех один — и тут уж тавр не тавр,
      кассах не кассах, так чего выёбываться, нет бы дела делать, деревья
      переворачивать, вы которой гэбенной головой думали эпидемию перед юбилеем
      Первого Большого Переворота затевать, иногородних же понаедет, по всей стране
      разнесут заразу, это уже не вопрос, кто в Бедрограде хозяин и по какому праву,
      это Всероссийское Соседство вымрет нахуй, а лет через десять его сплошные
      кассахи заселят с британской помощью, виноградом засеют и козлов разведут,
      будет вся земля от чухонцев до индокитайцев в козлином дерьме и в кассахах, а
      про Бедроградскую гэбню с её кретинской чумой в британских учебниках умных слов
      понапишут…</p>

    <p>Черёмуха ещё что-то нёс о том,
      как закончится Всероссийское Соседство, а Соций смотрел на британо-кассахский
      флаг. На складе его, само собой, не было, но Социю не нужен был
      британо-кассахский флаг, чтобы на него смотреть.</p>

    <p>Британцы — люди как люди,
      Британия вообще во многом похожа на Всероссийское Соседство, да побольше, чем
      другие европейские страны. Оттого и идёт уже сколько лет тихая бессмысленная
      война на почти незаселённой территории Северной Шотландии. Делай друг с другом
      что хочешь — главное, чтоб Европы ничего не видели, приебутся же со своим
      Пактом о Неагрессии.</p>

    <p>А чтоб было что ответить, если
      таки приебутся, Британия и завела себе кассахов. Присоединила их ёбаное плато,
      торчащее посреди Среднеросской Полосы, когда Ирландское Соседство образовалось
      с подачи Всероссийского. Чтоб не оказаться в дураках.</p>

    <p>Британцев понять можно, а
      кассахов нет.</p>

    <p>Им пообещали ни за что лысую и
      пустую Северную Шотландию, а они давай грызться за неё с
      Ирландско-Всероссийской Армией, которая вообще-то с Британией воевать пришла, а
      не с народным кассахским ополчением.</p>

    <p>Хитрые продажные бляди, и
      попробуй их передави — скачут по горам так же резво, как их козлы.</p>

    <p>— …гора козлиного дерьма на
      месте Бедрограда, высоченная такая, выше Бедроградской Радиовышки. И всё — ваша
      заслуга, — голое плечо черёмухи так и предлагало хуйнуть ему под рёбра.</p>

    <p>— Сами хороши, у вас завкаф —
      кассахская шлюха, — заметил Соций.</p>

    <p>— Мне казалось, как раз за это
      его ценим не только мы, но и вы, — нагло залез руками под шелка и кружева
      черёмуха. — Ты хочешь поговорить о кассахских шлюхах, командир?</p>

    <p>«Командир» отрезвлял немного,
      напоминал, что черёмуха — это не только черёмуха, хоть и не верилось, хоть и
      мешали верить шелка и кружева, ленивые потягивания и томные закусывания губ,
      хоть и хотелось прямо сейчас, а лучше полчаса назад ещё забить на попытки
      сделать встречу осмысленной, притянуть черёмуху к себе за волосы и порвать к
      лешему шелка и кружева…</p>

    <p>— Командир, за лицом следи, когда
      о кассахских шлюхах думаешь, — хмыкнул черёмуха. — Извиняй, я-то не завкаф. И
      не Дмитрий Борстен, которого нет, и не Дмитрий Ройш, которого не было, и не
      какой-то другой Дмитрий, которого Бедроградская гэбня сегодня так хотела
      увидеть. Кругом кассахские шлюхи, и ни одной на этом складе, какая тоска.</p>

    <p>Соций схватил черёмуху за голое
      плечо и рывком поднял на ноги:</p>

    <p>— Ни одной? Как сказать. Давай-ка
      мы с тобой опознание проведём, — Соций подтащил черёмуху к аппаратуре,
      воспроизводящей изображение, и нажал пару кнопок. — Я тебе сейчас кое-что
      покажу, а ты мне скажешь, не узнаёшь ли ты, дрянь, Дмитрия Борстена или Дмитрий
      Ройша.</p>

    <p>Было понятно, что ничего это не
      даст, что черёмуха как не раскалывался, так и не расколется, кто у них там на
      самом деле по Университету и Медкорпусу с фальшивыми документами бегает, и вообще
      эту конкретную запись сюда из архивов для психологических воздействий на
      другого человека притаранили…</p>

    <p>Но слишком много кассахских шлюх
      разом начисто отбивают представления о целесообразности.</p>

    <p>&nbsp;</p>

    <p>Длина плёнки — три часа сорок две
      минуты двадцать секунд.</p>

    <p>С учётом перемотки,
      британо-кассахского флага перед глазами и уверенной руки черёмухи на ширинке
      форменных гэбенных брюк, реальный объём потраченного на просмотр времени можно
      будет узнать только из записи уже этой встречи.</p>

    <p>Встреча записывается.</p>

    <p>Бедроградская гэбня будет
      рассматривать её под лупой.</p>

    <p>Социю не будет неудобно перед
      Бедроградской гэбней за допущенные вольности (на плёнке вон вольности похлеще).</p>

    <p>Социю будет хуже чем неудобно, за
      допущенные разговоры.</p>

    <p>Пока на плёнке два десятка
      младших служащих наглядно демонстрировали всем желающим, для чего нужны
      кассахские шлюхи, Соций с черёмухой, к сожалению, ещё и говорили.</p>

    <p>Говорили, блядь.</p>

    <p>Плёнка старая — май семьдесят
      шестого, записана как раз перед последней вспышкой степной чумы на Колошме.
      Рябит, притормаживает, случайно меняет контрастность. Сейчас плёнки гораздо
      лучшего качества, но и эту уже можно смотреть.</p>

    <p>Из-за кассахской шлюхи.</p>

    <p>Когда-то Андрей имел
      неосторожность взяться решать проблемы Бедроградской гэбни своими методами, не
      посоветовавшись собственно с Бедроградской гэбней.</p>

    <p>Поехал на Колошму трясти
      заключённого 66563 (он же Гуанако С. К.) на предмет слабых мест гэбни
      Университетской. Даже если оставить в стороне специфичность пируэта его
      тактической мысли, это всё равно была дурная затея. На Колошме началась степная
      чума, гэбня Колошмы вынуждена была отчитаться наверх, кто находился на
      территории колонии на момент объявления чрезвычайной ситуации, — и слухи о
      самодеятельности Андрея дошли даже до Бюро Патентов.</p>

    <p>Мог, кстати, и вовсе не вернуться
      оттуда — степной чуме поебать на уровни доступа. Но вовремя сообразил,
      припугнул помнивших его по прежней службе охранников, улизнул на ночном
      товарняке. Два десятка младших служащих, которых он с собой прихватил для
      самодеятельности, не улизнули. Не выжил вроде бы ни один.</p>

    <p>Но хоть кассахскую шлюху отымели
      перед смертью.</p>

    <p>Для того чтобы 66563 (он же
      Гуанако С. К., он же вообще-то черёмуха, что ещё дерьмовее) думал побыстрее, как
      помочь Андрею завалить Университетскую гэбню.</p>

    <p>В общем, вчера вечером, пока все
      ещё считали, что на встречу в качестве Дмитрия Борстена явится Дмитрий
      Смирнов-Задунайский (он же кассахская шлюха), Андрей убеждал Соция, что эта
      плёнка может быть хорошим инструментом для выбивания собеседника из колеи.</p>

    <p>С личностью собеседника
      Бедроградская гэбня облажалась, поэтому плёнка побыла хорошим инструментом для
      выбивания из колеи самого Соция.</p>

    <p>Нельзя смотреть, как два десятка
      младших служащих имеют кассахскую шлюху, и оставаться в своём уме.</p>

    <p>Если ты, конечно, не черёмуха,
      который, дрянь такая, способен одновременно смотреть, расстёгивать форменные
      гэбенные брюки и задавать правильные вопросы.</p>

    <p>И Соций ведь ответил на них.</p>

    <p>Коротко, но достаточно для
      составления общей картины.</p>

    <p>Ответил, как и почему фаланги
      выпустили и вернули в гэбню Андрея.</p>

    <p>Ответил, когда Бедроградская
      гэбня догадалась, что Университет контролирует ситуацию с чумой.</p>

    <p>Ответил, что никто так и не
      понял, откуда Университет узнал о планах Бедроградской гэбни.</p>

    <p>Ответил, сколько районов
      подверглось централизованному заражению через водопровод и канализации.</p>

    <p>Ответил, что завкафский дом через
      водопровод и канализации Бедроградская гэбня не заражала.</p>

    <p>Ответил, что после манёвров
      фаланг запасов вируса не осталось (хоть это и не проблема), а запасы лекарства
      пришлось спешно восполнять (что не самая большая проблема, но проблема).</p>

    <p>Ответил, что Ройшеву девку
      собственноручно прибили ещё во вторник, до того, как вернулся Андрей и до того,
      как догадались, что Университет в игре.</p>

    <p>Ответил, что боялись кидалова от
      Андрея и расследования от фаланг, а потому даже не допросили девку (а стоило),
      просто убрали свидетеля побыстрей.</p>

    <p>Ответил, что Ройша в Хащину
      вызывали через своего врача, чтобы слазить к нему в сортир, воспользовавшись
      тем, что девка мертва, а в Университете об этом не знают.</p>

    <p>На все вопросы ответил.</p>

    <p>Даже что завкафа из квартиры
      Гошка с Андреем выносили в его собственном ковре.</p>

    <p>Нельзя смотреть, как два десятка
      младших служащих имеют кассахскую шлюху, если рядом с тобой сидит черёмуха (он
      же 66563, он же Гуанако С. К., он же, блядь, сержант Гуанако из твоего
      собственного давнишнего спецподразделения Ирландско-Всероссийской Армии).</p>

    <p>Нельзя.</p>

    <p>Когда в голове поганым образом
      прояснилось и стало чуть менее важно пристально следить, как стонет и извивается
      на рябой плёнке кассахская шлюха, Соций через силу обернулся к черёмухе. И
      увидел, что больше там и не пахло никакой черёмухой. То есть как раз
      по-прежнему пахло, но это всё, что осталось от ёбаного спектакля.</p>

    <p>Гуанако (сержант Гуанако)
      выключил все бордельные замашки, по-человечески потянулся, по-человечески сел —
      и потому в шелках и кружевах выглядел теперь совершенно бредово.</p>

    <p>Соций бы даже поржал, если б
      только что не сдал ему Бедроградскую гэбню с потрохами.</p>

    <p>— Слышь, командир, — с тоской
      уставился на свои цветные с золочёным фильтром сигареты Гуанако, — угости
      нормальным куревом, а? Нет больше сил этой блядской хуйнёй давиться.</p>

    <p>Соций сумрачно протянул ему
      пачку.</p>

    <p>— Командир, — Гуанако покачал
      головой, — когда этот наш Университет был борделем в прямом смысле слова —
      слышал, наверное: Йыха Йихин, Академия, оскописты? Так вот, когда этот
      наш Университет был борделем в
      прямом смысле слова, там наличествовала традиция, которую я до сих пор понимаю
      скорее умом, нежели, гм, сердцем. Ряд помещений были оборудованы под так
      называемые аскезные. Специальные комнаты для тех, на кого после эротических
      действий наваливается тяжесть бытия и осознание собственной греховности, —
      Гуанако хмыкнул. — Командир, отставить аскезную.</p>

    <p>Соций ещё более сумрачно закурил
      сам:</p>

    <p>— Какая нахуй аскезная, это ж трибунал.
      Был бы трибунал, если б мы были в армии. А ты, блядина, ещё издеваешься.</p>

    <p>— Ты про то, что у тебя язык
      развязался? — устало потёр глаза Гуанако. — Ну мы и не в армии, к слову. В
      армии, командир, трибунал — это не то, что ты мне сейчас растрепал, а то, что
      твоя гэбня в городе устроила. Сам знаешь, за умышленный вред мирному населению
      не пиздюлей получают…</p>

    <p>— …а гранату в рот, — закончил
      за него Соций. — В армии проще.</p>

    <p>— И тут всё просто, — пожал
      плечами Гуанако. — Спрашивай, я тебе тоже что-нибудь растреплю. Можно даже без
      ответных эротических действий, прямо сейчас мне и твоих сигарет со вкусом
      сигарет достаточно. Валяй.</p>

    <p>Ёбаная наглая рожа.</p>

    <p>Соций на эту ёбаную наглую рожу
      смотрел с какой-то прям неприличной теплотой с самого начала. Как только на
      улице признал.</p>

    <p>Потому что, блядь, чума,
      Университет, все эти разборки — это одно, а сто лет назад в Северной Шотландии
      — другое.</p>

    <p>Хорошо так, крепко впечатывается.</p>

    <p>— Ты врубаешься, что по-хорошему
      я теперь не должен выпускать тебя отсюда живым? — Соций взялся за следующую
      сигарету.</p>

    <p>— Нет, не врубаюсь, — Гуанако
      отмахнулся. — Что, блядь, такого стратегически важного ты мне выдал? Что девку
      никакой Силовой Комитет не забирал? Так это тебе Охрович и Краснокаменный ещё
      позавчера бы сказали, они всю вашу хащинскую больничку перетрясли. Что вы девку
      того? Так и это скорее понятно. Вы ж не думали в самом деле, что Университет за
      неё на какие-то уступки пойдёт, соври вы, что она живая?</p>

    <p>— Да хуй с ней, с девкой, —
      согласился Соций.</p>

    <p>— А что ещё-то? Андрей и фаланги?
      Спасибо, занимательная байка. Только из неё ничего не следует в практическом
      отношении.</p>

    <p>— Лекарство, заражённые районы, —
      уставился в стену Соций.</p>

    <p>Стены, кстати, больше никуда
      уплывать не пытались.</p>

    <p>— Командир, давай начистоту: вы
      готовы всё это продолжать? Восьмой день хуйнёй маемся — может, хватит уже? Я
      юбилеем Первого Большого всерьёз тебя стращал, между прочим. К понедельнику
      надо бы закругляться с эпидемиями, а то хуже будет. Эпидемия ж не для эпидемии
      затевалась, а для Университета, так?</p>

    <p>Соций отвернулся, но невольные
      воспоминания о том, как и что затевалось (семь ёбаных лет затевалось) и как на
      деле всё наперекосяк пошло, всё равно полезли в голову.</p>

    <p>Думали-то о том, чтоб
      Университетскую гэбню спихнуть, а заниматься приходится всяким дерьмом. Тех же
      больных выискивать и госпитализировать, да так, чтоб слухи не поползли.
      Городские поликлиники подозревать ничего не должны, простые люди, которые не
      врачи, — тем более. А Андрей говорит, больные мрут. Вот прям больные,
      получившие дозу лекарства у медиков Бедроградской гэбни. Не то чтобы пачками,
      но прецеденты есть, и Андрея это, как всегда, ебёт.</p>

    <p>Потому что <i>нет</i> во Всероссийском Соседстве смертности от эпидемий, не должно
      быть, не может быть, и вообще это подрыв государственных устоев. Обсосали как
      только могли уже государственные устои, знали, блядь, на что шли, — чего
      теперь-то паниковать?</p>

    <p>Короче, Соций и без Гуанако знал,
      что эпидемия — сплошная морока. Первые дни-то бодро идёт, когда только заражать
      надо, а когда уже заразили — сплошь лишняя трата сил на весь этот контроль и
      секретность.</p>

    <p>Но с другой стороны, делать-то
      что?</p>

    <p>Гасить чуму собирались, когда
      Университетская гэбня за неё по башке получит, а та не получила. И может не
      получить вообще.</p>

    <p>— Вот что, — сообразил Соций. —
      Раз мы тут типа начистоту беседуем и ты сам предложил мне что-нибудь
      растрепать, скажи уж, что ты про заражение завкафского дома думаешь? Мы с него
      хорошо так прихуели. А на вчерашней встрече гэбен у ваших были такие рожи, как
      будто они прихуели и сами. Чё за лажа?</p>

    <p>Гуанако машинально взялся за свою пачку с цветными
      сигаретами, рассеянно кивнул Социю, когда тот сунул ему нормальную, и так три
      затяжки и промолчал.</p>

    <p>— Не ебу, чё за лажа, — выдал он
      наконец. — Я ж сам видел, как это заражение всплыло. Свечку держал. Гарантирую:
      всплыло случайно, никто не думал завкафские трубы на вирус проверять. Что у
      него самого чума — стоило догадаться, но мог ведь и без труб подхватить где-то.
      А чтоб весь дом, никто и не думал. Вы ж не совсем крышей поехали — покушение на
      голову гэбни так в лоб обставлять, — Гуанако затормозил на секунду. — Или
      поехали, раз у завкафского дома канализация с трубопроводом объединена? Только
      не пизди, что это не ваша работа. Я столько подвалов за эти дни оббегал, что
      ваши фирменные вентили на фильтрах во сне на ощупь опознаю.</p>

    <p>— Да наша, наша, — не стал
      отнекиваться Соций, — только давнишняя. Это тестовые фильтры были, самые
      первые. Обкатывали конструкцию на случайном жилом доме. Что на завкафском — так
      вышло. Пошутили типа. Забыли уже сорок лет как, что там вообще этот переключатель
      на безотходный оборот есть. Он у нас на нынешних картах канализаций даже
      обозначен не был — в расчёт не брали, ясен хуй. А тут — опаньки, кто-то
      воспользовался оборудованием.</p>

    <p>— Ну, найти-то его там несложно,
      если знать, что искать, — пепел с гуанаковской сигареты упал прямо на шелка и
      кружева, но тот не заметил. — Но в Университете только в четверг, когда завкаф
      пропал, про заражение его дома узнали. Точно говорю.</p>

    <p>— Может, Молевич краны крутил? —
      озвучил Соций основную версию Бедроградской гэбни.</p>

    <p>То, что он понёсся как миленький
      на Пинегу, когда завкафа умыкнули, — ещё не значит, что не сам травил.</p>

    <p>— Максим? — переспросил Гуанако.
      — Командир, да нихуя подобного. Вон, художества с портовыми борделями тоже на
      него валить пытаются, а он там ни при чём, он по коровьим пастбищам в это время
      скакал. Это ж явно вы Максима в Порту изобразили, чтоб дипломатию попортить?</p>

    <p>Соций аж почувствовал на секунду,
      как жарко было в тот день голове под париком. С учётом того, что про завкафа в
      ковре он уже слил, отпираться нелепо. Да и вреда никакого: удочку закидывали на
      слухи в Порту и сомнения у Портовой гэбни, а им-то как раз ничем не помешает
      признание, которого даже на записи у Университета не будет.</p>

    <p>— Положим, в Порту мы
      порезвились.</p>

    <p>— Суки, ну нахуя? — Гуанако даже
      как-то осел. — Не маскарад под Максима нахуя, а вообще — нахуя? Это ж полнейший
      пиздец, чума в Порту. Даже если Порт на Максима поведётся и Университет под
      корень вырежет, вы-то всё равно в выигрыше не останетесь. У вас, блядь, экономическая
      блокада в городе. Чё делать думаете? У Бюро Патентов деньги клянчить?</p>

    <p>— Сами разберёмся, — излишне быстро буркнул Соций.</p>

    <p>Это было неприятно — знать, что
      твоя гэбня неправа.</p>

    <p>С Портом ведь действительно
      лоханулись. Не верили, что Портовая гэбня может закатить ёбаную блокаду. Но, по
      правде говоря, сам-то Соций ещё до всякой блокады был против чумы в Порту. От
      такого рукой подать до международной огласки, а о международной огласке Соций
      привык думать с семнадцати лет — как в ирландско-всероссийских действующих
      войсках (которые на бумаге резервно-тренировочные) оказался, так и привык.</p>

    <p>И Бахта тоже скептически отнёсся
      к прямым нападкам на Порт — он дольше их всех в Бедроградской гэбне служит, у
      него свои резоны. Навидался всякого в прежних составах.</p>

    <p>Но Гошка и Андрей хотели заразить
      Порт — и все пошли заражать Порт.</p>

    <p>Потому что думать головы гэбни
      могут каждый своё, а делать — одно общее. Тут уж без вариантов. Только паршиво
      получается вообще-то, что Гошка и Андрей в войну заигрались и дальше могильной
      плиты над телами Университетской гэбни ничего видеть не хотят.</p>

    <p>Так ведь действительно до
      эпидемии по всей стране дойдёт. Вряд ли в прямом смысле, но когда на здравый расчёт
      становится плевать, много чего нахуячить можно.</p>

    <p>Все эти размышления Соция
      совершенно не радовали, поэтому он предпочёл перевести стрелки обратно:</p>

    <p>— Ты сразу версию с Молевичем и
      завкафским домом не отбрасывай. Мало ли, самым умным себя возомнил.</p>

    <p>— Командир, как тебе ещё
      объяснить, чтоб до тебя дошло? — встал на дыбы Гуанако. — Я твоего Молевича
      зелёным первокурсником помню. Знаю как облупленного. Он много чего может
      возомнить, но завкафом рисковать бы не стал. Размаха крыльев не хватает,
      сечёшь? Пороху не нюхал, не догадывается, что так тоже делают, когда выбора
      особого нет. Ты, блядь, веришь мне или как?</p>

    <p>— Да не вопрос, но кто тогда? —
      гнул свою линию Соций. — У вас же там толпа народу помимо гэбни в делах
      замешана. Ты вот объявился, хотя ты мертвец. Извиняй, но после твоего явления в
      нормальную университетскую власть кто поверит-то? Мало ли, кто ещё у вас из-под
      земли вылезает и свои интересы продавливает.</p>

    <p>— Навскидку не скажу, — Гуанако
      выглядел озадаченным. — Правда, ну вот вообще не приходит в голову, кому было бы
      выгодно завкафский дом тайно заразить. Спроси чего попроще.</p>

    <p>Соций чуял: Гуанако не пиздит.
      Действительно настроился начистоту разговаривать, наглая рожа. И зачем только
      комедию ломал с шелками и кружевами, с черёмухой и чумной гнилью? Сразу могли
      бы сесть и нормально, по-деловому всё перетереть.</p>

    <p>Северная Шотландия не важнее Бедроградской
      гэбни, но Северная Шотландия не даёт держать в уме, что перед тобой вроде как
      враг.</p>

    <p>Ну какой враг, если в одном окопе
      сидели?</p>

    <p>В ИВА, Ирландско-Всероссийскую
      Армию, попадают не все. Уровни доступа к информации — действующих войск нет,
      войны с Британией-Кассахией нет, ничего нет. Поэтому те, кто в ИВА таки
      оказался, уже повязаны общим знанием, которое не светит мирному населению.</p>

    <p>Первый контракт заключается на
      год. После года остаются немногие, да и их, если выживут, пинают обратно во
      Всероссийское Соседство ещё через шесть лет. Какие-то доктора якобы доказали,
      что больше семи лет воевать — значит остаться совсем без крыши. Ёбаная
      неагрессия, даже сюда пролезла. Европы на ней помешаны с каких-то стародавних
      времён, Всероссийское Соседство вроде и умнее, а всё равно туда же. Больше семи
      лет стрелять-убивать — ни-ни.</p>

    <p>Соций стрелял-убивал семь лет.
      Был командиром крохотного спецподразделения, которое в самые жопы без масла
      ввинчивалось.</p>

    <p>Гуанако — всего год по первому
      контракту. Вроде собирался остаться, ему офицерское звание предлагали, но
      словил контузию, зрение так упало, что мог и вовсе ослепнуть. А без глаз какое
      нахуй звание.</p>

    <p>Обычно новобранцы чуть больше
      месяца торчат в Ирландии в учебке, а потом — на корабль и с корабля сразу на
      фарш, в общую мясорубку. Кто через год будет целым, тому и дают возможность
      задержаться, но уже в каком-нибудь нормальном спецподразделении, которое делами
      занимается, а не просто так спину под пули подставляет. Но некоторых — тех, кто
      в учебке отличился, — закидывают к кому-нибудь в нагрузку на первом же году. По
      человеку на каждое подразделение, кроме контрразведки. Типа пушечным мясом.
      Чтоб, когда на сложном участке пушечное мясо по неопытности первым под обстрел
      попадёт, вменяемые солдаты собрались и среагировали на атаку. Это штабные падлы
      такой ёбаный принцип сочинили, потому что не им же с этими новобранцами
      возиться.</p>

    <p>Вот Гуанако (сержант Гуанако) и
      был таким пушечным мясом под началом у Соция. И ни разу по-крупному не попал:
      быстро схватывал, приказы слушал, всё время доёбывался с просьбами что-то
      объяснить-показать — учиться хотел, наглая рожа.</p>

    <p>Ну и выучился: за весь год его
      цепляло только по мелочи, под ногами не мешался, даже наоборот. Соций сам ходил
      штабным падлам говорить, что вот этого парня лучше по истечении контракта не
      отпускать, потому что явно толк мог выйти.</p>

    <p>А потом — шли через ущелье и
      подорвались.</p>

    <p>Не просто так, конечно, там
      отдельная песня про кассахских шлюх и про то, что бывает с лохами, которые им
      верят. Но закончилось тем, что после того взрыва из всего подразделения только
      два человека хоть как-то ползали, а остальные — всё, привет. У Соция такие
      потери всего раз за все семь лет службы и были. И крутые же ребята полегли —
      те, которых ему потом набрали, рядом не валялись.</p>

    <p>Самому повезло: множественные
      осколочные, пара ребёр — но так паршиво за ребят было, что хоть там и оставайся
      подыхать.</p>

    <p>А тут вылезает из какой-то
      трещины пушечное мясо, наглая рожа, ёбаный новобранец (год — это ж разве срок!)
      и давай Соция пинать. Вставай, мол, командир, всё равно в часть не вернёмся уже
      — далеко забрались, так давай, мол, по этим горам вдвоём пробежимся, устроим,
      мол, напоследок праздник тому, кто первый попадётся.</p>

    <p>Собрали что осталось из оружия и
      пробежались.</p>

    <p>Хорошо бегали: у Гуанако рука
      малость треснула, да и контузия эта, в глазах двоилось — мимо гор промахивался,
      падал. На третий раз попросил: завяжи, мол, командир, мне нахуй глаза бинтом, и
      то легче будет.</p>

    <p>Соций завязал. Думал, без толку,
      подохнёт сейчас и наглая рожа, глупо подохнет, а тот ничего, приспособился
      как-то. Его когда случайные кассахские шлюхи с бинтом на глазах завидели, так в
      штаны и наложили.</p>

    <p>Псих потому что.</p>

    <p>Два психа: оба от злости и от
      боли были как ширнувшиеся, даже хуже. Так развлеклись, что уже поверить трудно.
      Тихими и аккуратными быть не пытались, потому что всё равно замочат — на
      чужой-то территории. А нихуя: в результате по трупам дошли до своей разведки, а
      та их уже переправила куда следует. Прихуев совершенно.</p>

    <p>На том сержант Гуанако и
      закончился. Провалялся в лазарете пару недель, медики сказали, зрению кранты, в
      лучшем случае — в очках в сортире не утонет. Поэтому не увидел его Соций в
      новом составе своего подразделения, а жаль.</p>

    <p>Жаль, что Гуанако, чуть
      оправившись, в аспирантуру на этот свой истфак попёрся (хотя куда ещё очкарикам
      деваться?). Если б не очки — не сидели б они сейчас с Социем на этом складе,
      решая, чья сторона в нынешних разборках больше налажала.</p>

    <p>— Чего попроще спросить? —
      хмыкнул Соций. — Очки-то твои где, наглая рожа?</p>

    <p>Гуанако заржал.</p>

    <p>— Да пиздец вообще, —
      оторжавшись, объяснил он. — Вроде как контузило обратно, ты представь? Внутричерепное
      давление нормализовалось типа. Ну и степными травками долечился, тамошние
      знахари всякое умеют. Хотя зрение и так потихоньку всё время выравнивалось, а
      потом вот.</p>

    <p>— Заебись, — постановил Соций. —
      А в Бедрограде какими судьбами?</p>

    <p>— Тропою мёртвых, ёба. Скажу, что
      просто захотелось, — не поверишь же. А и правда захотелось: я же мёртвый, меня
      же нет, мне всё можно. Мой пожизненный срок на Колошме благополучно завершился
      моей же смертью, искать-то никто не будет, — Гуанако потянулся за сигаретой. —
      К завкафу в гости приехал. У меня же с ним большая любовь. Была. Неужто в
      Бедроградской гэбне этих сплетен не слышали?</p>

    <p>То, что после армии Гуанако с
      кассахскими шлюхами путался, все слышали, кто к его досье прикасался.</p>

    <p>А когда его Столичная гэбня
      вербовала, чтоб Университет в Бедрограде прикарманить, а в итоге на Колошму
      отправила, Бедроградская гэбня с досье как раз подробно так ознакомилась. Чтоб
      знать, на ком столичные прокололись и кому спасибо надо говорить, что столичные
      без резидента в Университете остались.</p>

    <p>Охуели совсем, руки к чужому
      городу тянуть. Ну Бедроградская гэбня-то им руки пообкусала, сразу как
      вскрылось. Только у Университета через год всё равно своя гэбня завелась — и
      вышло даже хуже, чем если бы столичные преуспели.</p>

    <p>Никогда не знаешь, где
      наебнёшься.</p>

    <p>— И как вы с Молевичем эту
      кассахскую шлюху поделили? — спросил Соций.</p>

    <p>Версия, что завкафский дом таки
      на совести Молевича, снова набирала вес.</p>

    <p>— Как-как, — Гуанако отмахнулся.
      — Он хотел голову, я — жопу, а получилось скорее наоборот. Только это к делу не
      относится.</p>

    <p>— Как сказать, — усомнился Соций,
      но на обсуждение плюнул. — Про кассахских шлюх, кстати. Ну ты понял, кого мы
      сегодня на встречу ждали, — Соций кивнул на аппаратуру, где давно уже
      закончилась рябая плёнка с двумя десятками младших служащих и одной кассахской
      шлюхой. — Идея взялась, когда Андрей рассказал, что некто Дмитрий Ройш, которым
      очень интересуются фаланги, на документах Медкорпуса смахивает на Дмитрия
      Смирнова-Задунайского. А тут неизвестный университетский медик Дмитрий Борстен
      без прописки в Бедрограде. Я тебе это с самого начала говорил, а ты выёбывался
      и с темы съехал. Объясни уже, мы тут связь сами нарисовали или она есть?</p>

    <p>— Да скорее есть, чем нету, —
      покорно начал Гуанако. — Ну Дмитрия-то Ройша для Андрея лепили из того, что под
      руку подвернулось. Хотели, чтоб ему совесть о былых подвигах поныла, пока он у
      фаланг прохлаждаться будет. Какие у Андрея есть личные подвиги, не
      бедроградско-гэбенные? Загубленный Начальник Колошмы есть, но вся та история
      слишком громкая. Тут бы не то что фаланги, тут бы любой младший служащий
      просёк, если б отсылки были. А с Дмитрием Смирновым-Задунайским хорошо картинка
      складывается: мало кто знает, за что Андрею тут должно быть совестно, плюс
      ассоциация с последней вспышкой степной чумы. В контексте планирования
      контролируемой эпидемии — самое оно.</p>

    <p>— Это «Дмитрий» и фотоснимок, а
      «Ройш» почему? — уточнил Соций.</p>

    <p>— Гениальный бюрократ потому что,
      — отшутился Гуанако. — И потому что Андрей-то должен помнить, что Дмитрий
      Смирнов-Задунайский был феноменально необразован в вопросе Революции.</p>

    <p>— Да даже я помню, — поржал
      Соций. — Когда человек взаправду не может вспомнить состав Временного
      Расстрельного Комитета — это просто труба. Мы про этот самый состав тогда
      частенько и на других допросах спрашивали. Чтоб с толку сбивать неожиданной
      проверкой общих знаний. Хорошо работало, все задумывались, не свихнулись ли они
      часом. Только Смирнов-Задунайский не задумался, а просто не смог ответить. Мы и
      не догадывались, что такое вообще может быть на допросе совершеннолетнего
      гражданина.</p>

    <p>— Ну что поделаешь, не учил
      мальчик Революцию в отряде, — с ухмылкой покивал Гуанако. — Он другим был
      занят, у него отряд особенный. И Андрею это совершенно точно известно.
      Надеялись, Андрей решит, что только Смирнов-Задунайский как раз и мог бы себе
      такой псевдоним взять.</p>

    <p>— <i>Мог бы?</i> — сурово глянул на Гуанако Соций.</p>

    <p>— Мог бы. Он хоть и подучил
      что-то в Университете, но всё равно в уме не держал, что абсолютное большинство
      людей в этой стране всю Революцию хоть в обмороке, хоть в горячке взахлёб
      перескажут, — всё продолжал хмыкать Гуанако, а потом заткнулся на секунду и
      сообразил-таки: — А, ты не о том. И снова: <i>мог
        бы.</i> Он же умер ещё когда.</p>

    <p>Соций с некоторым сожалением снова
      покосился на аппаратуру, больше не воспроизводившую ёбаную рябую плёнку:</p>

    <p>— И ты умер ещё когда. Что с
      того?</p>

    <p>— Он мертвей меня, командир, —
      Гуанако опять забыл стряхнуть пепел, и пепел опять осыпался на шелка и кружева.
      — Знаю, видел.</p>

    <p>— Чумной изолятор? — недоверчиво
      предположил Соций.</p>

    <p>Где один живой мертвец — там и
      два, и шестнадцать.</p>

    <p>— Да нет, уже за пределами, — о
      сигарете Гуанако забыл, просто сидел, сжимал её в пальцах и смотрел мимо Соция.</p>

    <p>— Эй, ты чего темнишь, наглая рожа?</p>

    <p>— Не темню. Я, блядь, должен тебе
      сейчас побег из изолятора в лицах изобразить? — огрызнулся Гуанако.</p>

    <p>— Да ты чего вдруг? — не понял
      Соций. — Одной кассахской шлюхой больше, одной меньше. Велика потеря, блядь.
      Новую найдёшь. Наверняка нашёл уже, — Соций с неохотой задумался. — Ты его, что
      ли, всерьёз за человека принимал? Ну-ну. Валяй, ещё наплети мне любовных
      соплей, блядь. Про кассахскую шлюху.</p>

    <p>— Да при чём тут сопли, —
      сигарета прогорела до фильтра, обожгла напоследок гуанаковские пальцы. — А если
      и сопли, то не любовные, а такие, которые ты лучше всех поймёшь, командир.</p>

    <p>Гуанако ещё ничего не сказал, а
      Социю стало уже не по себе, что полез с расспросами.</p>

    <p>Не так долго они были знакомы —
      гуанаковский год в Северной Шотландии, и вот сейчас встретились на пару часов,
      но Соций был уверен: ненормально это, когда у Гуанако, у ёбаной наглой рожи,
      рожа-то совсем не наглая, а вот такая. Каменная.</p>

    <p>— Знаешь, командир, степная чума
      — это как Северная Шотландия, — совершенно обычным тоном заявил Гуанако,
      прищёлкнул языком. — Только удачи нужно больше, чем мозгов: заразиться — это не
      пулю схватить. Она ж воздушно-капельная, живучая. И лекарства нет, не бывает от
      неё лекарства, а хоть какой-то — даже самой дерьмовенькой — бодяги для
      иммунитета прислать не успели. Но в целом нестрашно, потому что когда от тебя
      почти ничего не зависит, можно расслабиться и верить, что пронесёт.</p>

    <p>— А потом поспеют огнемётчики и
      выжгут даже тех, кого пронесло, — добавил Соций. — Слышал я, что там творилось.</p>

    <p>— Да хуйня это, а не огнемётчики,
      — Гуанако возмутился почти. — Откуда их набирают, неужто из нашей армии?</p>

    <p>— Не, не только. Хотя бывает: я
      сам до Бедроградской гэбни успел в Силовом Комитете малость послужить. Уровень
      доступа аж четвёртый, а на нынешнем шестом мне как-то сподручнее. Ни у каких
      фаланг на поводке хоть не бегаем.</p>

    <p>— Они там на четвёртом уровне
      доступа, видать, пихтские лапти плетут, — фыркнул Гуанако. — У меня, блядь,
      только табельный гэбенный пистолет был да пять обойм. Хорошие, кстати, у гэбен
      пистолеты, завидую почти. Для короткого ствола так просто кайф.</p>

    <p>— Отдача незаметная совсем, —
      возразил Соций. — А по мне так лучше каждый выстрел чувствовать.</p>

    <p>— Короткие стволы вообще
      непонятно зачем нужны, — Гуанако опять попытался прикурить цветную сигарету с
      золочёным фильтром, но одумался. — Короче: табельный гэбенный ствол и кордон
      огнемётчиков. За ними — автоматчики на броневиках. А прорвать как нехуй.</p>

    <p>— Прям нехуй?</p>

    <p>— Ну не нехуй, но я тогда
      накануне ссал, что тяжелее будет. Но нет, нормально, — не без самодовольства
      пожал плечами Гуанако. — Они ж палят просто так, строй не очень держат — не
      рассчитывают на вооружённое сопротивление, привыкли к сплошной мирной жизни.
      Главное в первой волне было не бежать, когда стена прогорела, лучше попозже в
      давке и по трупам к ним подползти. А если подстрелить красавца с близкого
      расстояния и огнемёт подрезать, то и броневики не проблема. Тогдашние огнемёты
      уже по двадцать секунд перезаряжались, а не по сорок, как я помню с Северной
      Шотландии. В общем, перевязываешь где надо крепким савьюровым стеблем, которых
      там как грязи, подкладываешь к какому-нибудь телу — и вперёд. Пушка сама хуячит
      по броневикам, автоматчики хуячат в сторону пушки — вреда мало, охуения много,
      чего ещё надо?</p>

    <p>— Броневик подорвать хоть один, —
      тоже не без самодовольства прокомментировал Соций.</p>

    <p>Кто наглую рожу учил видеть
      ситуацию? Ирландская учебка? Ну да, конечно.</p>

    <p>— Командир, чего там подрывать,
      только время тратить, — отмахнулся Гуанако. — Это ж степь — она сухая, горит
      как костёр на выпускном из отряда. Если в тряпки поплотнее замотаться,
      случайного раненого подхватить, который ещё на ногах держится, и им прикрыться,
      можно и прямо между двух броневиков пробежать. Эти-то, четвёртый уровень
      доступа, жариться не хотят, валят с поста на безопасное расстояние.</p>

    <p>— Пиздец вообще, — хлопнул себя
      по лбу Соций.</p>

    <p>— У нас, блядь, госаппарат сплошь
      из мирного населения состоит? — Гуанако отшвырнул наконец пачку с цветными
      сигаретами, чтоб не лезть опять. — Так о чём бишь мы? Ну да, кассахская шлюха и
      сопли. Короче, ты ж врубился уже, командир, что при таком раскладе, если
      заранее внятный инструктаж провести, любая кассахская шлюха имеет шансы пройти
      через кордон, пока я фейерверки устраиваю.</p>

    <p>— Проебал шансы этот
      Смирнов-Задунайский, — не спросил, а с уверенностью заявил Соций.</p>

    <p>Этот Смирнов-Задунайский был
      кассахской шлюхой в самом прямом смысле, а не в переносном. Как завкаф, а не
      как Соций обычно имел в виду, говоря «кассахская шлюха». Настоящие кассахские
      шлюхи — это те, что в Северной Шотландии по горам шароёбятся и мнят себя частью
      британской армии. В Северную Шотландию прут прямо со своего плато, ёбаные
      горцы. Мигом адаптируются к горной местности так, как нормальный солдат и за
      год не сможет. У таких кассахских шлюх все шансы через кордон пройти, а этот
      Смирнов-Задунайский пороху не нюхал, куда ему.</p>

    <p>— Знаешь, не проебал, — мотнул
      головой Гуанако. — Вышел как-то в саму степь, отполз подальше, дождался меня.
      Он способный был, только кретин. Гуманист, ёба. Всю чуму до огнемётчиков в
      санитарах проходил, людей лечил. Лечить-то нечем, но надо ж, блядь, страдания
      умирающих облегчать. Кому надо? Зачем надо? — Гуанако сплюнул.</p>

    <p>— Чуму подхватил от своих
      умирающих, — снова с уверенностью заявил Соций.</p>

    <p>И снова ошибся.</p>

    <p>— Хуже, командир, гораздо хуже, —
      нехорошо улыбнулся Гуанако. — Увидел, что несколько заключённых поумнее — полдюжины,
      не больше — тоже кордон пересекли, и не засел себе молча в траве, а позвал их.
      Типа вместе дальше переть. Они ж выжили, он же их в своём блядском лазарете для
      здоровых-но-с-подозрением глюкозкой колол, он же не может их бросить! Тьфу,
      блядь. Тьфу. Я их когда увидел, чуть сам не убил гуманиста. Лучше б сам.</p>

    <p>Гуанако хмыкнул и заткнулся
      надолго, но Соций его не торопил.</p>

    <p>— Командир, ты представь, — таки
      заговорил Гуанако, когда Соций выкурил уже полторы сигареты. — Ему ж лет было
      как мне в армии. Я ему до Колошмы на истфаке лекции читал. То, что не только
      лекции и не только читал, — это неважно. Важно, что мы год в одной камере
      просидели, что он в эту камеру из-за меня во многом и попал. Я за него отвечал,
      командир. Как ты за новобранцев в твоём подразделении, которые туда
      неподготовленными к твоим обычным жопам попадают. Иногда заёбывают так, что
      думаешь, хоть бы сдохли поскорей. Но это ж не всерьёз.</p>

    <p>— Не всерьёз, — отозвался Соций.</p>

    <p>Он и так всё уже понял про
      Гуанако и ту кассахскую шлюху, но всё равно слушал как привязанный. Когда
      новобранцы, за которых ты отвечаешь, подыхают — это самое дерьмо из дерьма.</p>

    <p>Такое дерьмо, о котором вслух не
      говорят. А Гуанако — говорил. Прямо сейчас говорил, и не слушать было никак.</p>

    <p>— Эти его умирающие мне не
      порадовались, — и говорил, и говорил, и говорил Гуанако. — Они были при
      заточках, а я с нормальным ножом и со стволом при остатках патронов. А они мою
      рожу в колонии не видели — я ж на работы не ходил, я ж сидел в личной одиночке
      с печатной машинкой и кассахской шлюхой. В общем, разнервничались. Через полдня
      пути совсем разнервничались: они-то хотели в ближайший городок, а я шёл куда
      глаза глядят, но только не туда. Идиоту ж понятно, что в ближайшем городке либо
      уже степная чума, либо там беглецов и ждут с распростёртыми объятиями невесёлые
      силовики. В общем, я сказал им — валите. Они сказали — а ты нас пристрелишь в
      спину или властям сдашь. Я сказал им — какие нахуй власти посреди степи. Но
      ствол отдал. Без патронов, чтоб успокоились уже, но по мне палить не смогли.
      Четверо упёрлись со стволом, двое остались. Типа с «доктором», с кассахской
      шлюхой то бишь, в чуму лучше, чем без доктора. Доктор упросил меня их не гнать,
      а у меня как-то сил спорить уже не было, — Гуанако устало сплюнул. — Ещё через
      полдня, перед ночлегом, мы, конечно же, сцепились. Кому дежурить, решали. И они
      на меня, значит, попёрли. Два дохляка из колонии для политических с заточками,
      врубаешься? Пробесили порядком. Я думал, сверну им шеи сейчас и хоть высплюсь.
      А тут доктор заорал: ты, мол, чё творишь, я, мол, этих людей не для того
      глюкозкой в лазарете колол. И так далее. Встал между мной и ними. Лицом ко мне,
      конечно. Ну а дальше, командир, ты понимаешь.</p>

    <p>— Заточка со спины? — на этот раз
      не заявил, а спросил Соций.</p>

    <p>Хотел опять ошибиться, но не
      срослось.</p>

    <p>— Угу, — цыкнул зубом Гуанако. —
      Он даже сообразить не успел, каких падл защищать полез. Этих падл я там же на
      запчасти и разобрал, да толку-то. Ну, выспался разве что, — усмехнулся Гуанако
      и потянулся к Социю за пачкой сигарет со вкусом сигарет.</p>

    <p>— Ох бля, наглая рожа, —
      отзеркалил усмешку Соций и как-то смазанно хлопнул Гуанако по плечу.</p>

    <p>Посидели чуток тихо — одну на
      двоих скурили по армейскому обычаю.</p>

    <p>Только аппаратура, давно
      закончившая воспроизводить рябую плёнку с той самой кассахской шлюхой, гудела и
      присвистывала.</p>

    <p>— Командир, — Гуанако очнулся
      первым, — ты вопросы задавать хотел.</p>

    <p>Действительно.</p>

    <p>Они ж тут сидят на полу
      складского помещения, окурков — гора, к столу вон так и не подошли, а там
      ведущие микрофоны для записи.</p>

    <p>Отлично встретился Соций с университетским
      медиком Дмитрием Борстеном.</p>

    <p>— Лекарство как гоните? — быстро
      включился он. — И в каких количествах?</p>

    <p>— Из живых студентов, — так же
      быстро ответил Гуанако. — Экспериментальным методом. Деталей не знаю, я доктор
      не тех наук. Количество — скорее хорошо, чем плохо. Но можно лучше.</p>

    <p>— А чего так?</p>

    <p>— Допёрли поздно, что у вас
      масштабы посерьёзнее, чем дом Ройша. Ночью с воскресенья на понедельник.</p>

    <p>— Откуда дом Ройша взяли? —
      мгновенно среагировал Соций.</p>

    <p>Это ж самое важное.</p>

    <p>Важнее всех фальшивых Дмитриев,
      завкафских домов, литров лекарства.</p>

    <p>Как университетские бляди
      прознали планы Бедроградской гэбни.</p>

    <p>— Гошка девку с весны окучивал,
      так? — наморщил лоб Гуанако. — Его засекли, признали под маскировкой, начали
      копать. Девка раскололась, что неизвестный доброжелатель уровнем доступа Ройша
      обеспокоен. Поняли, что дело дрянь, раз Бедроградскую гэбню ебёт, есть у него
      второй или как. А у него правда же нету — поэтому дело вдвойне дрянь.</p>

    <p>— До этого всего и так додуматься
      можно, — оборвал Соций очевидную часть ответа. — Наглая рожа, ты предлагал
      начистоту, вот и отрабатывай. Откуда об идее контролируемого заражения вообще
      пронюхали?</p>

    <p>Гуанако ответил.</p>

    <p>Просто, не раздумывая, со своей
      вечной наглой рожей.</p>

    <p>Когда Гуанако ответил, Соций
      подумал, что лучше бы черёмуховый туман не уходил из головы. Сидел бы Соций,
      смотрел по кругу рябую плёнку с кассахской шлюхой и сам бы сдавал Бедроградскую
      гэбню, сам бы отвечал на гуанаковские вопросы, подкрепляемые уверенной рукой на
      ширинке форменных гэбенных брюк.</p>

    <p>Это было бы гораздо, гораздо
      лучше, чем услышать-таки, откуда университетские бляди пронюхали про
      контролируемое заражение.</p>

    <p>— Так от Гошки, — просто, не
      раздумывая, со своей вечной наглой рожей ответил Гуанако. — Я сам с ним и
      беседовал.</p>

    <p>— Что ты сказал, блядь?! —
      взревел Соций.</p>

    <p>— Командир, ты просил начистоту,
      — будто извиняясь, брякнул Гуанако.</p>

    <p>Кулак Соция встретился с его
      солнечным сплетением раньше, чем Соций успел подумать хоть что-нибудь кроме
      «вот бы ты пиздел сейчас».</p>

    <p>Гуанако даже не дёрнулся
      поставить блок, просто резко выдохнул и согнулся пополам.</p>

    <p>Сам думает, что бьют за дело.
      Значит, дело <i>было</i>.</p>

    <p>Соций вскочил с пола, одним
      прыжком оказался у ворот склада. Не очень понимая, зачем: не сваливать же
      сейчас в самом деле.</p>

    <p>Когда вначале Гуанако пытался,
      прикрываясь шелками и кружевами, гнать на Андрея — это было по больному месту,
      но ещё куда ни шло. Доверие трёх голов Бедроградской гэбни к Андрею — отдельная
      песня. С припевом про его ёбаную Колошму.</p>

    <p>Андрею доверяют, потому что
      знают: это Андрей, он много хуил, он может ещё, может струсить, может
      облажаться, может зарваться. Но он всё равно прибежит каяться, не выдержит, не
      захочет остаться один, предпочтёт решить дело миром. Ему хорошо в Бедроградской
      гэбне, он ни на что её не променяет.</p>

    <p>Соций хуякнул по воротам со всей
      дури.</p>

    <p>Гошке доверяют, потому что знают:
      это Гошка.</p>

    <p>Ворота приоткрылись, внутрь сунулся
      испуганный младший служащий со стволом в руке, но Соций мотнул головой, и тот
      тут же закрыл ворота обратно.</p>

    <p>Это Гошка сдал планы.</p>

    <p>И плевать Соций хотел на то, что
      Гуанако умеет развязывать языки, на то, что его этому малость обучали прямо в
      Северной Шотландии, на то, что полчаса назад он как нехуй развязал самому
      Социю.</p>

    <p>Это Гошка.</p>

    <p>Негласный командир
      спецподразделения «Бедроградская гэбня», кто бы там что ни думал про синхронизацию
      и равенство голов.</p>

    <p>— Командир, не бросайся на двери,
      — подал голос Гуанако. — Я с ним беседовал, я ему допрос похлеще чем тебе
      закатил.</p>

    <p>Соций обернулся.</p>

    <p>Наглая рожа всё берёт на себя.
      Соций бы тоже взял, но что с того?</p>

    <p>Что это меняет в фактах?</p>

    <p>— Ты, блядь, сам всё знаешь, —
      медленно произнёс Соций и не услышал себя. — Я плевать хотел, что ты с ним
      делал. Чё ты плёл, когда на Андрея гнал? «Никому нельзя доверять, кроме тех,
      кто прикрывает тебе спину»?</p>

    <p><i>А если и там подстава, значит, никто никогда ничего и не прикрывал.</i></p>

    <p>Гуанако тоже поднялся, совершенно
      бредовый в своих шелках и кружевах. Худой, потому что жилистый, а не потому что
      хрупкий. С кое-как, но всё-таки тренированным, крепким телом в бордельной обёртке
      под этого их завкафа (на котором, наоборот, следовало бы выжечь «осторожно,
      стекло», чтоб никто не сломал ненароком). С шелковой лентой и запахом ёбаной
      черёмухи в не по-армейски длинных волосах.</p>

    <p>Соций тупо пялился сейчас на
      Гуанако, на шелка и кружева, запоминал все детали. Как будто делал фотоснимок
      глазами. Как будто это имело какой-то смысл.</p>

    <p>Тупо пялился, потому что в голове
      ничего не осталось.</p>

    <p>Не надо, чтоб оставалось.</p>

    <p>Не надо.</p>

    <p>Гуанако сделал несколько шагов к
      Социю.</p>

    <p>Не надо, блядь!</p>

    <p>— Командир, ты это… В общем, чего
      я тут буду, ты лучше, наверное, сам с Гошкой поговори, чё да как вышло, —
      Гуанако глянул на Соция с этим своим ёбаным извиняющимся видом. — Короче, не
      виноват он, командир. Серьёзно. Он не мог не проболтаться, но нихуя он не
      виноват. Врубаешься?</p>

    <p>— Неважно, — отрезал Соций.</p>

    <p>Вслух сказать наглой роже
      «спасибо, что не лезешь в наши дела» язык не повернулся. Сам всё знает, раз не
      лезет.</p>

    <p>— К делу давай. И закругляемся,
      если неважно, — верно оценил состояние Соция Гуанако. — Чуму в Бедрограде
      гасим?</p>

    <p>— Гасим, — решил Соций, хоть без
      гэбни такие решения не принимают.</p>

    <p>С другой стороны — и так гасить
      начали. Когда Андрей завопил, что определённый процент больных всё же стабильно
      мрёт, коли их лекарством или не коли. Вяло начали, надо бы поживее, вот и всё.</p>

    <p>— Совместно гасим?</p>

    <p>— Не мешая друг другу, — выбрал
      оптимальный вариант Соций. — И вот что. Про Университетскую гэбню. Вся погань
      попёрла оттого, что нихуя она не гэбня, нечего ей на шестом уровне доступа
      делать, все там поголовно психи и так далее. Ты вроде как с Колошмы благодаря
      Хикеракли Университетскую гэбню усадил. Правда?</p>

    <p>— Правда.</p>

    <p>— Нахуя?</p>

    <p>— Так вышло, — опять просто, не
      раздумывая и со своей вечной наглой рожей ответил Гуанако. — Хотели же
      Университету жопу прикрыть, всё-таки к нему всегда больно много лезут.
      Путеводная звезда науки, пекарня кадров, вся хуйня. Без какой-то власти
      растащат по кирпичику. Как меня пытались перед Колошмой растащить в столичные
      полуслужащие, ну и вообще прецеденты были. Но не гэбню мы с Хикеракли
      придумывали. Это потом Бюро Патентов
      пошутило, когда вопрос с Максимом, в смысле с Молевичем…</p>

    <p>— Помню я всё про вопрос с
      Молевичем, — перебил Гуанако Соций.</p>

    <p>Только «вопрос с Молевичем»
      давнишний сейчас вспоминать не хватало, блядь.</p>

    <p>— А если помнишь, чего
      спрашиваешь?</p>

    <p>— Того, — прикрикнул Соций. —
      Короче, тот же Гошка верит, что Университетской гэбни не должно быть как
      института. Мне плевать, я не против института — я против этих конкретных людей.
      Потому что психи, ебанутые и власть реальную кому угодно делегируют. Тебе.
      Ройшу. Дмитрию Ройшу, которого никогда не было. Леший знает кому ещё.</p>

    <p>— Это не так, — попытался
      вставить Гуанако, но Соций ему напомнил, кто лекции в университетах читал, а
      кто семь лет приказы отдавал.</p>

    <p>— <i>Это так!</i> — гаркнув, Соций немного удовлетворенно помолчал, потёр
      свинцовый лоб. — Вот что, наглая рожа. Пока пусть все благополучно разбегутся,
      займутся погашением эпидемии и своими проблемами. А к завтрашней ночи приведи
      мне на этот склад <i>реальную
        университетскую власть</i>. Не гэбню, а тех, кто всю эту кашу заварил и
      помешивает. Из-под земли достань, блядь.</p>

    <p>— По какому протоколу? — с
      нескрываемым интересом воззрился на Соция Гуанако.</p>

    <p>— Без протокола. Без охраны, без
      досмотров.</p>

    <p>— При оружии? — совсем уж весёлым
      тоном уточнил Гуанако.</p>

    <p>— А то, — хмыкнул Соций. —
      Поговорим как нормальные люди. Обо всём сразу. И на этом все разборки закончим.</p>

    <p>— По рукам, командир, —
      присвистнул Гуанако.</p>

    <p>До завтрашней ночи должно хватить
      времени. Наладить потоковое лечение чумы в медицинских учреждениях
      Бедроградской гэбни. Подготовить последние рюшечки к ёбаному юбилею Первого
      Большого Переворота.</p>

    <p>Подстраховать город на тот
      случай, если Бедроградская гэбня вдруг с переговоров не вернётся или вёрнется в
      неполном составе.</p>

    <p>Понять, а был ли он когда-то,
      этот полный состав.</p>

    <p>Соций кивнул:</p>

    <p>— По рукам, наглая рожа.</p>
  </>
}

export default chapter27
