import React from 'react'
import { IBookPage } from './index'

const chapter11: IBookPage = {
  header: 'header_d5.jpg',
  title: 'Глава 11. Разделяй и херачь',
  prevLink: 'chapter10',
  nextLink: 'chapter12',
  content: <>
    <h2>Бедроградская гэбня. Гошка</h2>

    <img alt='' src="/images/11_goshka.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>— В Бедрограде эпидемия чумы.</p>

    <p>— Что?</p>

    <p>— Мы запустили эпидемию в
      субботу, когда тебя не было. Слишком удачный момент подвернулся, никто не мог
      знать, что по твою душу придут фаланги.</p>

    <p>Андрей ошарашенно захлопал глазами.</p>

    <p>Бедроградская гэбня полным наконец-то
      составом сидела в одном из своих кабинетов — рабочем, с большим лакированным
      столом на четверых, книжными шкафами вдоль всех стен и живописным серым
      кирпичом соседнего здания за окном. С учётом того, как все перенервничали,
      сейчас было бы здраво рассесться в более приятной обстановке, но на круглые
      диванчики почему-то не тянуло.</p>

    <p>Слишком красивый спектакль они
      только что сыграли на круглом диванчике, продемонстрировали чудеса если не
      синхронизации, то восстановления. Отлично сыграли недо-недоверие,
      отлично поговорили недо-намёками и словили,
      где легенда, а где на самом деле, поебались для услаждения фаланговских
      взоров. Фаланги любят демонстрации компетентности и профессионализма, фаланг
      очень радует, когда под их дудку исполняют первоклассный европейский менуэт.</p>

    <p>Настолько, что они отпустили
      Андрея. «Заражение не было произведено», а значит — Андрей виноват только в
      заказе вируса на стороне, не такой и грех. О бóльших грехах
      никто не знает.</p>

    <p>А качественными кадрами не
      разбрасываются.</p>

    <p>Проверочка
      пройдена, а?</p>

    <p>Вот и вышла полная херова драма с
      катарсисом на четыре действующих лица, осталось только написать имя
      постановщика на плёнках с записью картинки и звука — и прям
      сейчас тиражировать и распространять среди народонаселения вместе с недешёвой
      аппаратурой для просмотра.</p>

    <p>Будет фурор, бомба просто.</p>

    <p>Интересно, под диванчик тоже
      закрались глазки подглядывающих устройств, или фаланги решили не интересоваться
      тем, о чём гэбня общалась ногами?</p>

    <p>Бахта и Соций продолжали говорить
      с Андреем —&nbsp;снова, и снова, и снова пережёвывать его пребывание у фаланг,
      снова, и снова, и снова рассказывать, как мотались по складам в надежде
      перехватить вирус. Андрей светился херовым прожектором — вроде того, что
      установлен над Крайним Северным Регионом.</p>

    <p>С ним никогда не поймёшь,
      искренне он рад или решил побыть миленьким.</p>

    <p>Нет, не так. Не поймёшь, умеет ли
      в принципе искренне радоваться человек, который сделал личное очарование и
      приятность своей профессией. За всё надо платить; тот, кто умеет улыбаться для
      всех и для каждого, теряет способность улыбаться просто так, для себя.</p>

    <p>Херов Фрайд.</p>

    <p>Гошка доверял Андрею — просто
      потому, что он голова гэбни, один из них, их часть, других вариантов нет.
      Проблема не в этом.</p>

    <p>Все отвечают за каждого. ПН4 было совместным решением, и хорошим. Никто не мог
      знать,
      что по душу Андрея пришли фаланги. Второй вирус, исчезновение неизвестно куда,
      пустые склады, неслучившаяся когда-то в его жизни Медицинская гэбня —&nbsp;всё указывало на то, что Андрей их
      кинул.</p>

    <p>И всё же ПН4
      предложил Гошка. Никто с него не спросит, прошло и прошло, вот и хорошо, но не
      значит ли это, что он должен бы отвечать перед Андреем чуть больше, чем
      остальные?</p>

    <p>Думать воистину вредно.</p>

    <p>— Запустили без меня? Какого же
      лешего?</p>

    <p>— Дык когда запускали, ты должен
      был как раз из Медкорпуса выходить. Кто ж знал, что всё сложилось не так!</p>

    <p>— Говорим же, удачный момент,
      —&nbsp;поддержал Бахту Гошка, — девка Ройшева пришла на встречу злющая, хотела
      смертей и хтонических чудищ на его голову. Второго
      уровня у Ройша нет — пока что. Кто знает, когда он за ним кинется? А у меня в
      кармане была твоя пробирка — так, на всякий. Не дать её девке
      было бы глупо.</p>

    <p>— И что она?</p>

    <p>— Да всё правильно сделала,
      вылила эту срань в сортир — за ней проследили, ясен
      хер. Потом на факультет поехала, довольная.</p>

    <p>— И вы побежали брать пробы из сортира?</p>

    <p>Соций пожал плечами — так
      радостно, как будто не смог определиться, что дарить Андрею на день рождения,
      новый флакон парфюма или плед, ездить в такси зимой, и купил и то, и другое:</p>

    <p>— Ты ж сам сказал, что эта херь
      липкая, не меньше недели продержится. Чего рыпаться?</p>

    <p>— Хорошо, — Андрей выдохнул,
      откинулся на стуле, живо покивал, — а то теперь фаланги знают, что вирус наш.
      Свалить заражение на Университет не получится, вот просто никак. Надо всё
      зачищать и думать заново.</p>

    <p>Вирус наш. Так сказал Андрей, не
      запнулся, даже не вспомнил, что перед фалангами взял всю ответственность за гипотетическую
      чуму на себя.</p>

    <p>И Гошке вспоминать об этом ни в
      хер не упало.</p>

    <p>Он поплотнее
      уткнулся в бумаги, которыми решил занять руки (от этих троих дождёшься): отчётами
      из районных поликлиник, отчётами от младших служащих и других дружественных
      лиц, результатами проверок канализаций (незаконно, зато полезно). Эпидемия в
      Бедрограде — это не только столичных тавров за косы
      таскать, это ещё и тонны макулатуры.</p>

    <p>Четвёртый район — 89 обращений с
      тяжёлым ОРЗ, второй район — 355 обращений, пятый — 112, третий — 130, первый —
      872, из них из дома Ройша — 4.</p>

    <p>Отдельных обращений по городу —
      1448.</p>

    <p>И ещё в одном районе (получается,
      уже шестом?) просто-таки вспышка — 174 обращения.</p>

    <p>Это всё старая информация.</p>

    <p>Результаты проверок канализаций:
      со второго по пятый районы —&nbsp;всё в порядке.</p>

    <p>В первом следы вируса обнаружены
      в канализациях всех домов, кроме одного.</p>

    <p>В шестом следов вируса в
      канализациях не обнаружено — кроме одной.</p>

    <p>Проспект Объединённых
      Заводоводств, дом 33/2.</p>

    <p>Выражаясь человеческим языком —
      канализации дома Ройша почему-то чисты, зато заражены канализации совсем
      другого дома в совсем другом районе. И каком!</p>

    <p>Именно том, который Бедроградская
      гэбня собиралась радикально не трогать.</p>

    <p>Что за херь?</p>

    <p>Вчера, прочитав отчёты из
      поликлиник, они решили, что всякое бывает (без главного-то медика
      поди разберись). Ну загасла почему-то чума в доме
      Ройша и загасла — небось, его чарующий образ все беды и напасти отпугнул. Ну перекинулась на другой район и перекинулась, может, у них
      там непрекращающаяся оргия со вскрытием вен.</p>

    <p>Ан нет, смотрите-ка — кто-то
      любовно почистил сортиры Ройшу и попачкал дому 33/2 по проспекту Объединённых Заводоводств!</p>

    <p>Из стопки листов выпал тетрадный
      — самый ценный, между прочим.</p>

    <p>Все эти бесконечные младшие
      служащие, свои люди там и тут — херня. Будущее за молодёжью. Особенно за
      молодёжью, обучающейся в Университете. Особенно на юрфаке.</p>

    <p>Сантименты, слюни и прочая любовь
      к родному факультету тут ни при чём; когда Гошка формировал из студентов юрфака
      шпионскую сеть, он думал в первую очередь о том, что там сидят разумные люди,
      которых учили правильным вещам. Например, тому, что Университетская
      гэбня — мудаки.</p>

    <p>Никакой пропаганды, просто здравый
      смысл.</p>

    <p>Хочешь управлять сферой,
      территорией или учреждением —&nbsp;поимей сперва управлялку. Сидение в гэбне подразумевает не только
      табельный пистолет и модный наплечник, но и необходимость решать некоторое
      количество проблем. Кто требует отдельного финансирования, покрывает студентов,
      если те, нажравшись, колотят чьи-нибудь окна («они в
      нашей юрисдикции, ебитесь»), и гордо носится со всем городским дерьмом?
      Университет. Кто лечит их же ёбаных студентов, если тех сбило такси, вставляет
      в их квартирах стёкла, которые поколотили в ответ, и вычищает вытекшее из-за
      прорвавшейся трубы дерьмо («канализации в нашей
      юрисдикции, а залитая квартира, извините, в вашей»)? Угадайте.</p>

    <p>Они бы определились.</p>

    <p>С некоторыми приходилось
      проводить дополнительные беседы, но основная часть студентов
юрфака БГУ это и так понимала —&nbsp;просто потому, что изучала законы.
      И именно поэтому они были рады помочь — либо Бедроградской гэбне, либо лично
      Гошке.</p>

    <p>Что он обладает шестым уровнем
      доступа — информация, ясно, не для всяких там, но вот выпускника, который
      вместо защиты диплома нашёл в университетском уставе
      двадцать семь противоречий и заставил руководство этот самый устав переписать,
      на юрфаке помнят и любят до сих пор. Все ли противоречия присутствовали на
      самом деле и какими именно методами
      он заставлял что-то там переписывать, впрочем, помнят хуже.</p>

    <p>Вот и хорошо.</p>

    <p>Когда обнаружили странную вспышку
      чумы в доме 33/2, Гошка послал шпионов юрфака на истфак — посмотреть, как они
      там, всё ли в порядке, здоров ли цвет лица, бодр ли дух. Визит вежливости.</p>

    <p>В отчёте на тетрадном листке
      значилось, что цвет лица установить не удалось, поскольку нету на истфаке ни хера.
      Все студенты (и преподаватели — то есть и гэбня!)
      носятся с тайным проектом, о котором известно только то, что его предваряют
      некие пиздец неприятные медицинские процедуры. Страшная
      движуха, какие-то грузовики бесконечные, медфаковский
      лазарет переустроили, чувство собственной значимости с ликующим воплем скачет
      по верхушке Бедроградской Радиовышки.</p>

    <p>И ещё одна бумажка — короткая
      служебная записка из Медицинского Института.</p>

    <p>Старшекурсники медфака БГУ
      проходят практику в регистратурах всех доступных гражданским
      больниц и поликлиник Бедрограда.</p>

    <p>И медицинская
      движуха в Университете.</p>

    <p>И канализации дома Ройша
      вычищены.</p>

    <p>Пиз-дец.</p>

    <p>— Сказать, что нас выебли в жопу огромным раскалённым добела дрыном, — значит не
      сказать
      ничего!</p>

    <p>Гошка херакнул по столешнице так,
      что она прогнулась — пиздели поставщики, клявшиеся, что цельный дуб.</p>

    <p>В кабинете и так уже некоторое
      время царило молчание —&nbsp;видать, со слишком серьёзной рожей
      он закопался в бумажки. У Соция дёрнулась рука, невольно попыталась повторить
      движение. И стоило бы —&nbsp;если не дуб, то нахер этот стол вообще сдался?</p>

    <p>Гошка повторил движение сам,
      только уже ногой, вскочив.</p>

    <p>— «Ой
      как не вовремя фаланга зашёл»! «Ой как не вовремя
      Андрей пропал»! «Ой как странно, что его заловили,
      прям как знали»! «Ой, а почему в доме Ройша никто не болеет»! Ёбаные кретины!!</p>

    <p>Ёбаный стол!!</p>

    <p>Удача, хуедача,
      надо было с самого начала думать головой. Ой как
      славно девка Ройша на него разозлилась, прям когда вирус подоспел! Ой сколько потом
      неприятностей посыпалось на голову, надо
      же!</p>

    <p>— Отъебись уже от стола! —&nbsp;рявкнул Соций и тут же совершил роковую ошибку,
      попытавшись
      физически остановить насилие над фальшивым дубом. Поплатился, разумеется,
      —&nbsp;ну и Гошке прилетело, всегда прилетало, у него-то армейской подготовки
      нет.</p>

    <p>Он влепил
      Социю ещё разок под рёбра и немного успокоился.</p>

    <p>—&nbsp;Что на тебя нашло? —
      нахмурился Бахта. Заранее нахмурился, знает, что без веских причин Гошка столы
      не ебошит.</p>

    <p>— Университет на меня нашёл — да
      в порядке я! — рыкнул тот, отпихивая от себя Соция. — Университет на нас всех
      нашёл, встал на голову, выдавил глазные яблоки и выебал в получившиеся
      отверстия.</p>

    <p>Андрей прыснул, и это отрезвило
      даже сильнее, чем хук от Соция.</p>

    <p>Гошка тут ебёт себе мозги,
      виноват он перед Андреем или нет, а ведь это тоже их тактика, это так и
      задумано. Разделяй и херачь.</p>

    <p>— Университет?
      —&nbsp;разобравшись с хихиканьем, Андрей резко посерьёзнел. —&nbsp;Поясни.</p>

    <p>— Сам смотри, — Гошка обвёл рукой
      роскошно разлетевшиеся по всему кабинету бумажки. — Я не знаю, как, я не знаю,
      какого хера, но они нас подставили.</p>

    <p>Бахта нагнулся за каким-то
      отчётом, но Гошка только махнул на него рукой.</p>

    <p>В моменте озарения есть нечто
      просветляющее. Валящиеся на голову неудачи и внезапные фаланги — это неудобно и
      неприятно. Не поймёшь, кто виноват и как бороться.</p>

    <p>То ли дело, если за этим стоит
      Университет.</p>

    <p>— Подумайте сами. Шапка зовёт
      Андрея в Столицу, плетёт ему какую-то херь про деньги
      и гарантии безопасности, разводит на трёп про вирус, после чего Андрея
      неожиданно заметают явившиеся с проверкой не пойми чего фаланги. Ровно в этот
      же день Ройшева девка прибегает ко мне — передумала
      она, больше его не любит, что б такое гадкое сотворить! Я еду к Шапке — он мне
      гонит про то, что Андрей-де что-то скрывает, второй заказ, степная чума, тайный
      агент, тьфу! И вот теперь — смотрите-ка —&nbsp;выясняется, что дом Ройша то ли
      не был заражён, то ли уже вычищен, в районных поликлиниках сидят
      университетские студенты, а в самом БГУ некая
      загадочная медицинская движуха. Прям как будто они знали про
чуму, спровоцировали нас запустить её в неподходящий момент, а теперь пытаются
обернуть ситуацию себе на пользу. Удивительная череда событий, а!</p>

    <p>Андрей, привыкший при разговоре
      держать одной рукой запястье второй, крепче сжал пальцы — Гошка аж почувствовал,
      как у него сильнее забился пульс. Бахта
      деловито перебирал бумажки, вчитывался в то, что ему и так уже сказали. Соций
      сумрачно покачал головой, сгруппировался — это тоже армейское, вышибить
      невозможно:</p>

    <p>— Может быть совпадением.</p>

    <p>Может быть. Может, секретный
      университетский проект — это плетение гигантского венка в честь любви и признательности
      Бедроградской гэбне.</p>

    <p>— Да нет, всё сходится, —
      медленно, в сторону сказал Андрей. — Этот якобы мой агент… я
      правда не уверен, клянусь, мне показывали мелкое фото из досье Медкорпуса, а
      времени много прошло с тех лет… но он <i>может
        быть</i> мальчиком 66563. Тем, помните, которого мы за расшифровки бесед
      посадили.</p>

    <p>— Забудешь, как же, — фыркнул
      Бахта откуда-то из-за стола.</p>

    <p>— Он же подох!
      — Соций обернулся как на добычу, нагнулся вперёд.</p>

    <p>— Я в курсе. Но если это всё-таки
      он, если — это же доказательство того, что игра уже давно ведётся против нас,
      ведётся Университетом, правда? Если это он…</p>

    <p>— Дмитрий Ройш? — Гошка запрыгнул
      на стол —&nbsp;леший знает, куда сейчас беседа заведёт, а окончательно ломать
      государственное имущество из этой позиции сложнее. — Я ошибаюсь, или его и правда
      звали Дмитрий?</p>

    <p>— Смирнов-Задунайский. И я знаю,
      знаю, что он умер! Думаете, я не пытаюсь всё это время понять, он или не он? —
      Андрей вскинул глаза, ища поддержки. — В какой-то момент даже подумал, что это
      был ты или кто-нибудь от тебя.</p>

    <p>— А я —&nbsp;что подарочек от
      тебя в честь меня и моих революционных псевдонимов, —&nbsp;хмыкнул Гошка.
      —&nbsp;Это меня и бесит. Они хотели, чтобы мы переругались, чудом пронесло.</p>

    <p>— Не чудом, а синхронизацией и
      опытом работы, — Бахта наконец-то разогнул спину, бросил бумаги горой на стол и
      улыбнулся —&nbsp;широко и очень радостно.</p>

    <p>И это правильно: Бедроградская
      гэбня снова вместе, и Университет —&nbsp;если это веселье устроил всё-таки
      Университет — ожидают внезапные каникулы и часы приятного досуга. Иначе и быть
      не может.</p>

    <p>Нельзя не отдать должное —&nbsp;у
      них почти получилось. В Университетской гэбне сидят уроды, психи с
      гормональными проблемами, которым не то что власть в
      руки давать нельзя — их бы по-хорошему запереть в какой-нибудь институт и
      исследовать. Уроды и психи, не представляющие, для
      чего нужны гэбни, —&nbsp;но не кретины. И, хоть больше всего хочется накормить
      их на четверых слабительным, зашить жопы и вальяжно
      созерцать, как им разрывает кишки к лешему, вместо этого приходится признать:
      план они породили нехилый. Тонкий, наглый и, главное,
      почти сработавший.</p>

    <p>Почти.</p>

    <p>ПН4
      оказалось спасением. Не будь его, фаланги мурыжили бы
      Андрея по всяческим допросам до бесконечности, наблюдали, что остатки гэбни
      будут делать. А ПН4 — это прямой наезд на фаланг, не
      среагировать нельзя, вот и пришлось колоться, пришлось вернуть Андрея.</p>

    <p>Ровным счётом никаких
      недостатков.</p>

    <p>— Это же Колошма, степь, чума, —
      продолжил тем временем Андрей. — Как будто кто-то опознавал тела в изоляторе,
      сожгли же всё, до чего дотянулись. Но теоретически там <i>могли быть</i> выжившие.</p>

    <p>— И Университет выкопал труп? —
      поинтересовался Бахта. — Зачем?</p>

    <p>— Может, и не выкопал. Говорю же,
      я не уверен. Может, это психическая атака, а в досье была ложная фотография,
      может, это вообще другой человек…</p>

    <p>— Сырьё, — неожиданно прогудел
      Соций. — Оно должно было быть, это проверяемо, Шапка не стал бы выдумывать на
      пустом месте. Он сказал, что наш вирус имеет те же исходники, что степная чума.
      Если Дмитрий Ройш — тот самый выкопанный труп, то ясно, откуда они могли
      взяться.</p>

    <p>— Колошма, чума, степь, ходячие
      мертвецы, —&nbsp;кивнул Гошка. —&nbsp;Вообразимо. И не имеет никакого значения —&nbsp;по
      крайней мере, прямо сейчас. Важно другое: этот самый
      Дмитрий Ройш работал вместе с Шапкой над вирусом, а потом вдруг в ответственный
      момент исчез. Растворился, оставив после себя фаланг. Про Шапку нам всё
      известно — купленный он или нет, всё равно для него интерес в деньгах и
      платиновых гондонах, сам бы не полез. А вот мог ли
      Дмитрий Ройш быть университетским и сделать всё это весьма
специально? Да легко.</p>

    <p>Слежка за домом Шапки, впрочем,
      была весьма серьёзная, не столичного разлива. На фаланговскую
      сильно смахивала эта слежка. За случайными обывателями, пусть и из Медкорпуса, такую не ставят.</p>

    <p>Шапка, похоже, в этом деле тоже
      по кончик косы, и не на стороне Бедроградской гэбни.</p>

    <p>— Но зачем, зачем делать вирус из
      степной чумы? — Андрей не смог просто отбросить этот вопрос, нервно обернулся
      по сторонам. Конечно, сейчас тебе книжные полки расскажут, зачем.</p>

    <p>Как-то привлечь Медицинскую
      гэбню? Напугать? Не вышло сделать нужный вирус полностью в пробирке, пришлось
      пользоваться? Да леший разберёт.</p>

    <p>Либо это часть какого-то более
      сложного плана, либо традиционный стиль Университета —&nbsp;сделаем что-нибудь
      просто так, потому что это <i>весело</i>.</p>

    <p>Весельчаки.</p>

    <p>— Это неважно, —&nbsp;ответил за
      Гошку Бахта, словил ход мысли. —&nbsp;То есть важно,
      но не прямо сейчас. Они попытались натравить нас друг на друга —&nbsp;на тебя,
      Андрей. Откуда-то узнали об эпидемии заранее и воспользовались.</p>

    <p>— Но откуда, откуда узнали? Если
      Университету рассказал Дмитрий Ройш, кто рассказал Дмитрию Ройшу? Всем, кто так или
      иначе, с любой стороны, участвовал в проекте, мозги
      были запудрены правильными легендами, неужели кто-то догадался?</p>

    <p>— Один из нас?</p>

    <p>От вопроса Соция Андрей сжался
      —&nbsp;слегка и так быстро, что это не могло быть неискренним, просто не могло.
      И зря, вопрос не был личным, просто планомерная отработка всех вариантов.</p>

    <p>Зря, но Гошка всё равно ответил
      почти мгновенно:</p>

    <p>— Не вариант. Разделяй и херачь!
      Слушайте тактическое предложение: мы <i>не</i> подозреваем друг друга. Я не сдавал наши планы, и никто из вас не сдавал. Я это
      просто знаю.</p>

    <p>В груди что-то разжалось, нормальный
      эффект от синхронизации — это Андрей расправил плечи, улыбнулся. Бахта не особо
      и отреагировал, ему это всё и так было очевидно; Соций коротко кивнул,
      указание, мол, ясно.</p>

    <p>— Смотрите. Университет как-то
      —&nbsp;разберёмся, как, не ссыте — узнал про заражение. И контратаковал.
      Устроил Андрею веселье с фалангами, спровоцировал меня запустить эпидемию
      руками Ройшевой девки.</p>

    <p>— Но зачем? — удивился Бахта.</p>

    <p>— Если у них был человек в
      Медкорпусе, у них и лекарство на руках, — коротко и спокойно ответил Соций,
      усмехнулся. —&nbsp;Вот бляди.</p>

    <p>— И лекарство, и вирус, — Гошка
      п��вторил усмешку, выкопал одну из бумажек, протянул Бахте — тот же
      интересовался, глазами только посмотреть забыл. —&nbsp;И способ доказать
      фалангам, что это мы, а не они, злобные и печальные лохи, припасён. Никогда, <i>никогда</i> нельзя недооценивать противника.
      Мы привыкли, что университетские при виде любой опасности откладывают здоровую
      кучу в штаны, а они действительно бляди, и недешёвые. Боевые,
      перешедшие в нападение.</p>

    <p>— Травят своих?</p>

    <p>Андрей распахнул глаза, вытянул
      шею, как будто такой цинизм со стороны Университета почему-то поразил его в
      самое сердце. Даже осел как-то, отобрав у Бахты бумажку и спешно пробежав её
      глазами. Верил в то, что университетские души ещё можно спасти, миленький?</p>

    <p>Гошка и сам немного опешил, когда
      понял, что дом 33/2 по проспекту Объединённых
      Заводоводств заражён умышленно, заражён через канализацию и заражён <i>не
        Бедроградской гэбней</i>. Не потому даже, что сложно представить такую кошмарную жестокость и
бесчеловечность Университета — это как раз легко; потому скорее, что за четыре
с малым дня они успели пропустить столько событий. Навёрстывать придётся
      бегом.</p>

    <p>Ничего, бег полезен для здоровья.</p>

    <p>— Университет — это кучка людей.
      Их гэбня — не нормальная гэбня, это просто четыре
      мужика, которым привалил подарочек от Хикеракли. Они не носят форму, у них нет
      синхронизации, их могло бы быть и трое, и пятеро. Они могут руководствоваться
      личными мотивами, они позволяют полуслужащим или тому же Ройшу вести свои дела.
      Понимаете? Это ублюдки, у которых травить своих легко
      мог решить кто-то один, сам, — Гошка снова усмехнулся —&nbsp;с уважением почти,
      иначе не вышло. — И этот кто-то — самый умный из них всех. Университет любит
      искать безболезненные решения, Университет любит выходить чистеньким. Это знаем
      мы, это знают фаланги, это их публичный образ. Заразить кого-то из университетских — вот тебе и предлог, под которым можно
      обнаружить эпидемию, и смертный приговор Бедроградской гэбне. Никто и никогда
      не поверит, что они сделали это сами. Хорошие, дорогие бляди, знающие цену
      своей репутации.</p>

    <p>— Кто-то один? — с сомнением
      переспросил Андрей. —&nbsp;Кто?</p>

    <p>Он сидел аккуратно, обхватив
      рукой запястье —&nbsp;волосы слегка растрёпаны, в глазах старшеотрядское
      желание всё услышать, понять и ничего не упустить. Тридцать лет мужику, а всё
      равно маленький.</p>

    <p>Миленький.</p>

    <p>— Да кто угодно. Любой из голов
      гэбни, любой из полуслужащих, Ройш…</p>

    <p>—&nbsp;Ага, Дмитрий, —&nbsp;снова
      фыркнул Бахта. От него волнами исходил боевой азарт: на нас наехали, да, но
      они-то думают, что мы не догадались, они надеялись, что мы выгнали Андрея или
      что он застрял навечно, а Андрей —&nbsp;вот он, и мы их поймали, теперь
      держитесь.</p>

    <p>Впадать в бессмысленную ярость
      неумно, умно сесть поудобнее и в кои-то веки подумать
      головой (печальная, но порой неизбежная необходимость).</p>

    <p>Две гэбни одного и того же уровня
      доступа могут грызться сколько угодно — шансы того, что одна свалит другую,
      минимальны. Фаланги сделают всё, чтобы этого не случилось, фаланги тоже
      весельчаки. Бахта рассказывал, что у настоящих тавров с настоящей Южной Равнины
      была традиция:&nbsp;сидя на конях в ожидании битвы, они ели мелкие кусочки
      этого своего особого таврского хлеба из наседельных
      сумок. Вот примерно этим фаланги и занимаются: сидят, пялятся и жрут.</p>

    <p>Университетской гэбни не должно
      быть, просто не должно —&nbsp;не на шестом уровне доступа. Они отобрали
      городские канализации, но у них постоянно не хватает людей чинить поломки. Их
      студенты продолжают жить на территории Бедроградской гэбни —&nbsp;и всё, что
      происходит со студентами за пределами университетских стен, оказывается на
      совести Бедроградской гэбни, если только Университету не возжаждется
      повозмущаться на сей счёт. Ну, мало ли, настроение
      такое у них сегодня.</p>

    <p>Университетская гэбня как
      институт порочна в принципе, но ещё порочнее люди, из которых она состоит.
      Детишки, которых посадили туда просто так, сверху, широким жестом Хикеракли. Ни
      один из них не знает, что такое управлять, ни один из них не сделал ничего,
      чтобы своё место заслужить, зато сколько апломба. Охрович и
Краснокаменный —&nbsp;самые ёбнутые из всех, они и убивали, и применяли
насилие, но это-то ладно — неладно, что они совершенно непредсказуемы; по ним
не Медицинская гэбня — по ним расстрел плачет. Базальд, Ларий Валерьевич
      — наоборот, самый спокойный и даже относительно компетентный, на нём всё
      держится, но он всё равно из этих же, жертва экспериментов, где и когда
      взорвётся —&nbsp;знать нельзя.</p>

    <p>И ещё Молевич, Максим Аркадьевич.
      Решительный, но разумный, обладает лидерскими качествами — когда-то был главой
      той самой псевдо-недо-контрреволюционной
      террористической группировки, за которую посадили 66563 (отличный кадр для
      гэбни, не правда ли, наверняка замечательно пишет отчёты по идеологии за лето).
      Молевич —&nbsp;глава Университетской гэбни.</p>

    <p>А у гэбни не должно быть главы,
      только головы.</p>

    <p>А ещё у гэбни должна быть
      синхронизация и не должно быть слабых мест — на то и четыре головы, чтобы
      прикрывать друг другу жопы. Личных и частных слабых
      мест —&nbsp;уж точно. И если кто-то в какой-то гэбне
      считает иначе, однажды его может ожидать
      очень, очень интересное открытие.</p>

    <p>В случае скандала с эпидемией
      (эпидемия чумы, кошмарные дела в городе Бедрограде!) фаланги потеряли бы
      возможность судить, рядить и дознаваться и попросту вышвырнули бы с шестого
      уровня менее важную гэбню — гэбню уродов и психов. Фаланги
      тоже перед кем-то отвечают, тоже боятся, они не могут допустить повторных
      эксцессов такого масштаба. И фаланги должны были бы наказать мгновенно — не
      того, кто виноват, а того, кого легче наказать, кого можно наказать быстро, у
      кого сомнительная репутация за плечами и только тень Хикеракли в поддержку.</p>

    <p>Показательной эпидемии для фаланг
      не вышло, Университет перегнал?</p>

    <p>Что ж, если доказать высоким
      уровням доступа фактическую некомпетентность Университетской
      гэбни нельзя, можно вспомнить про то, что она состоит из людей. Случайных
      людей, которых скрепляет только всё та же тень Хикеракли.</p>

    <p>И если в Университете есть крыса
      —&nbsp;пусть Университет же крысу и ищет.</p>

    <p>Они сами подкинули эту идею, за
      что им большое человеческое спасибо.</p>

    <p>— Неважно, кто из них заразил
      33/2, —&nbsp;хмыкнул Гошка. — Нам этого отсюда не узнать.</p>

    <p>— Важно, —&nbsp;неожиданно твёрдо
      мотнул головой Соций. —&nbsp;Ты знаешь, как они работают. У всех со всеми такая
      нежная любовь, что разобраться, кто рулит, почти нереально. Зато если таки
      разобраться и рулевого снять — хуй они ещё хоть что-то смогут сделать.</p>

    <p>Бахта покивал.</p>

    <p>Правы они, правы, только из
      здания Бедроградской гэбни так близко не подберёшься. Тут нужно провоцировать,
      подбивать на первый шаг, смотреть, кто и как задёргается. Гошка открыл было рот,
      чтобы это поведать, но Андрей перебил его
      —&nbsp;подпрыгнул на стуле, размахивая ещё каким-то отчётом:</p>

    <p>— Смотрите, Порт! Я же видел,
      видел же грузовик с тельняшками за рулём всего минутах в семи от медфака БГУ,
      но не подумал, конечно, а тут — смотрите! — Андрей бросил отчёт, метнулся к
      одному из шкафов, яростно принялся там что-то искать. — Портовые наркоманы
      недовольны, из Порта пропала кровавая твирь — подчистую,
      вся —&nbsp;и твиревая настойка. А на медфак БГУ из Порта ездят грузовики, это у
      тебя же в отчёте написано — да где она?</p>

    <p>Опять у него белочка, какого хера! От белочки здравого и разумного Андрея
      становится
      невозможно воспринимать, он путается, не может закончить фразу, а фраза часто
      содержательная.</p>

    <p>Особенно забавно смотреть на это
      и думать, что изнутри он, небось, воспринимает всё это
      глубоко драматично и патетически. Андрей склонен малевать из себя немного
      героя.</p>

    <p>Героя с беличьими лапками.</p>

    <p>— Сел и успокоился, — прикрикнул
      Гошка, но так, для виду.</p>

    <p>Андрей очень хочет, чтобы всё
      было как раньше, слегка переигрывает и слегка перенервничал,
      но это нормально, это от переизбытка благих намерений в крови. И белочка его
      такая родная и по-свойски раздражающая, что Бахта снова засверкал во все
      тридцать два.</p>

    <p>Тоже от переизбытка.</p>

    <p>— Я не Шапка, понимаю в этом не
      всё, очень не всё, но… —&nbsp;Андрей выдернул из шкафа желанный листок, прыгнул
      обратно на стул. —&nbsp;Вот формула лекарства. Если вирус и правда сделан из
      степной чумы —&nbsp;или как-то с ним связан — то получается… чем лечить степную
      чуму, никто не знает, но степняки как раз твирью и того, — он очень
      сосредоточенно вперился глазами в формулу — прожжёт же
      сейчас. —&nbsp;Нет, не знаю. Правда не знаю, на глаз
      точно никак. Но если вся кровавая твирь из Порта перекочевала в Университет,
      похоже, они как раз какое-то своё лекарство и делают, иначе зачем.</p>

    <p>— И Порт прикарманили,
      —&nbsp;жизнерадостно и азартно присвистнул Бахта. — Впечатляющая подготовка.
      Когда только успели?</p>

    <p>— Сами своих
      травят и сами лечат? —&nbsp;Соций не стал смотреть в бумажку с формулой вируса,
      только ещё сильнее нахмурился.</p>

    <p>— Именно так! И это правильно.
      Можно надеяться на то, что Андрей сдаст всех нас вместе со складами фалангам,
      мы запустим вирус, они вылечат город и выйдут в белом. Мы их подставили, это
      доказуемо, но фаланг не впечатлит. То ли дело —&nbsp;если мы прямиком на них
      наехали, стали их самих вирусом травить. Это гораздо, гораздо эффектнее. А
      собственную жертву можно и вылечить потом.</p>

    <p>По краешку ходят — то ли совсем долбанутые оптимисты, то ли
      одурели от наглости. И ведь у них может сработать —&nbsp;могло бы, если бы
      Андрея не вернули, если бы Бедроградская гэбня так и продолжила гнить в
      неизвестности.</p>

    <p>Но всё-таки —&nbsp;как узнали?
      Никто из гэбни в здравом уме выдать не мог, угрожай не угрожай, обещай не
      обещай, слишком давно Университет из них тянет жилы. Кто-то из врачей или
      инженеров докопался и слил? Вообразимо, конечно, но тоже проверяемо. А больше
      утечке начаться неоткуда.</p>

    <p>И ведь знали заранее с гарантией,
      заманили чем-то Шапку, Дмитрия этого самого Ройша выкопали, перекупили Порт,
      подготовили девку. Не один месяц работы.</p>

    <p>Какая-то сука об этом запоздало
      пожалеет, когда увидит свои кишки по стеночкам.</p>

    <p>Прибитые
      аккуратными такими гвоздиками.</p>

    <p>— Надо всё-таки проверить, — чуть
      растерянно, проиграв, видимо, внутреннюю борьбу с паранойей, выдал Андрей.
      —&nbsp;Всё вроде бы и складно, но бросаться вот так тоже, знаете ли. Вот бы девку
      Ройшеву расколоть…</p>

    <p>— Не расколешь уже, — буркнул
      Бахта.</p>

    <p>— Есть ещё сортир
      его, — рассудительно заметил Соций. — Если Андреева гадость и правда липкая и
      если девка её туда таки кинула —&nbsp;будут следы.
      Если следов нет —&nbsp;значит, всё это <i>без
        вариантов</i> провокация и подстава.</p>

    <p>Перепроверить ещё раз — мысль не
      лишняя, чем больше известно, тем лучше. Новых шпионов юрфака за это время можно
      погонять по Университету, пусть посмотрят детальнее, кто там насколько
      весёленький, кто всем рулит и у кого с рыльца свисают клочья пушка.</p>

    <p>И потом, кто же устоит перед
      естественным человеческим желанием заглянуть в унитаз к Ройшу, можно (и нужно)
      же даже пальцем потрогать!</p>

    <p>А ещё у Гошки была интуиция.
      Ничего волшебного, просто побитая годами жопа не может
      не выработать чутьё. И интуиция подсказывала, что сами по себе обстоятельства
      так ловко не складываются, всегда есть кто-нибудь, кто сидит с пинцетиком и
      подгоняет детальки
      одну к другой.</p>

    <p>Ох
      прилетит этот пинцетик ему прямо промеж глаз.</p>

    <p>Андрей перечитывал бесчисленные
      бумажки ещё разок, и ещё, и ещё —&nbsp;немного подрагивая от возбуждения,
      пытаясь ничего не упустить, всё предусмотреть, не вляпаться
      ещё глубже.</p>

    <p>Какое глубже,
миленький, мы и так уже по колено в дерьме и по пояс в чуме. Остаётся
      только возрадоваться, что руки пока свободны, и найти им достойное применение.</p>
  </>
}

export default chapter11
