import React from 'react'
import { IBookPage } from './index'

const chapter21: IBookPage = {
  header: 'header_d7.jpg',
  title: 'Глава 21. Профиль',
  prevLink: 'chapter20',
  nextLink: 'chapter22',
  content: <>
    <h2>Бедроградская гэбня. Гошка</h2>

    <img alt='' src="/images/21_goshka.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>Причины некоторых явлений и
      событий невозможно отыскать, как ни старайся. Здравый смысл подсказывает, что,
      собственно, и незачем их искать. Что прошло, то пусть и идёт нахер.</p>

    <p>Иногда, однако же, случается
      академическое любопытство.</p>

    <p>Гошка прекрасно знал, что местами
      позволял себе нехилый непрофессионализм. У него слишком маленький набор
      псевдонимов. Он слишком часто прикидывается младшим служащим. У него слишком
      броская внешность, в конце концов, что само по себе неплохо (очень даже), но не
      в том случае, когда твоя излюбленная задача —&nbsp;быть третьим справа в
      четвёртом ряду.</p>

    <p>Академическое любопытство: как
      так вышло, что из всей Бедроградской гэбни именно Гошка, заметней которого
      разве что Бахта (поди не заметь бескосого
      тавра), всё время играет роль этого самого третьего справа?</p>

    <p>Было бы у Гошки лицо попроще — было бы у Гошки свободного времени побольше, а не
      целый шкаф накладных усов.</p>

    <p>Интересный факт: переодевание,
      перекрашивание волос, ящик линз и прочая смена облика работают только в том
      случае, когда небезызвестный на определённых уровнях доступа Гошка Петюньевич Туралеев, в отличие от всех своих альтер-херовых-эго,
      обладает вполне конкретной, броской и, главное, всегда одинаковой внешностью.</p>

    <p>Небезызвестный на определённых
      уровнях доступа Гошка Петюньевич Туралеев расстегнул
      узкий воротник форменного мундира, залез в ворот рубашки и вытащил за цепочку
      изящный серебряный медальон. Дедов ещё, из Польши-Италии, с чьим-то чеканным
      женским профилем. Бешеных денег, между прочим, стоит: невесомый и крошечный,
      меньше фаланги (пальца, пальца фаланги —&nbsp;есть у этого слова и такое
      значение), а на профиле каждая деталька видна, пряди
      волос, серьги этой неведомой бабы, херь какая-то в высокой причёске. Филигранненько, красота.</p>

    <p>Андрей, само собой, тут же
      неодобрительно покосился и заёрзал на стуле. Он и так-то этот медальон изо всех
      сил порицал — негоже таскать повсюду одну и ту же побрякушку, да ещё и такую,
      которую столь просто опознать. Только практической необходимостью и удалось
      отговориться. А тут вообще безумство —&nbsp;встреча гэбен вот-вот начнётся, а
      Гошка побрякушку на свет белый вытащил!</p>

    <p>А ну кто увидит!</p>

    <p>— И зачем так рано явились? —
      недовольно протянул Андрей, не сводя глаз со злорадно покачивающегося
      медальона. — Сидим теперь и ждём, когда они снизойдут.</p>

    <p>— Они не снисходят, — фыркнул
      Бахта, — они спешно собирают макет Молевича в натуральную величину.</p>

    <p>— Во-во, — с мрачноватым
      удовлетворением поддержал его Соций, — отмазку клепают. Молевич не мог ещё
      вернуться. Никак.</p>

    <p>Андрей поморщился (зато какая
      последовательность!): Университетская гэбня пока не пришла, любые аудиозаписи
      из помещения для встречи гэбен внепротокольны, но это
      не значит, что они не ведутся и что никто не может их
      потом с интересом послушать. Следовательно, незачем озвучивать свою
      осведомлённость.</p>

    <p>Хорошо хоть аппаратуры для записи
      изображения здесь не было — не успели (и не шибко стремились) установить, а то
      миленький бы у Гошки медальон прям так, зубами отгрыз. Но аппаратура для записи
      изображения — дорогая, громоздкая и неудобная.</p>

    <p>Хотя это полбеды, хохма — в другом. Служебные инструкции гэбен и прочих
      госслужащих со
      времён хэра Ройша толком не переписывались, и, кажется, Бюро Патентов до сих
      пор не определилось, имеют они право на правки или нет (и чего ждут, интересно?).
      Логичным образом ни в одной из инструкций нет упоминаний об аппаратуре для
      записи изображения как стандартном инструменте государственных процедур. Вчера,
      обнаружив в завкафской квартире отчёт об использовании этой аппаратуры в
      подвалах у Ройша, Гошка не поленился перепроверить: точно, никаких упоминаний.</p>

    <p>А значит, ставить её здесь вместе
      с устройствами для записи звука попросту незачем.</p>

    <p>Кроме того, присутствие
      соответствующей аппаратуры в конкретном месте в конкретное время и
      достоверность записи с неё может быть сорок восемь раз доказуемым — эта запись
      всё равно не будет иметь юридической силы.</p>

    <p>Поскольку она внепротокольна.</p>

    <p>То есть
      как и всегда в этом государстве: вы знаете, и мы знаем, что вы знаете, но
      давайте все вместе делать вид, что ничего не было, поскольку, когда писалась
      ваша служебная инструкция, уровень технического развития несколько отличался от
      нынешнего.</p>

    <p>Кое-кто конкретно лоханулся со
      своими обвинениями в адрес Бедроградской гэбни.</p>

    <p>Гошка пощёлкал замком медальона,
      накрутил цепочку на палец.</p>

    <p>Что у них там ещё может быть?
      Непроверяемые показания студентки —&nbsp;пшик. За сам факт вируса на складах их
      уже простили, а спереть со склада и использовать как
      не надо мог кто-то другой (и это тоже вина Бедроградской гэбни, но не того
      порядка вина, и вообще — сейчас не об этом). С точки зрения фаланг (тех,
      которые не пальцев) слишком безумно предположить, что это пойманная
      на горячем Бедроградская гэбня кого-то травит, — наоборот, здраво подумать на
      кого угодно другого.</p>

    <p>Если не считать чумного завкафа,
      который не менее успешно отводит стрелки от Университета.</p>

    <p>Башка
      уже от всего этого болит. Может, найти какого-нибудь недостаточно ретивого
      младшего служащего, повесить чуму на него и спать спокойно?</p>

    <p>Экие упаднические настроения в строю.</p>

    <p>Безликая баба с медальона
      продолжала существовать только в профиль. Крути не крути — другой стороны не
      увидишь.</p>

    <p>Нет её. Нет и не должно быть.</p>

    <p>— Убери уже, —&nbsp;прошипел
      Андрей.</p>

    <p>Ути
      какой.</p>

    <p>— Нахер иди, — лениво отозвался
      Гошка, продолжая изучать бабу. —&nbsp;Если они и явятся, мы услышим заранее, не
      глухие. — Он помолчал, пощёлкал замком ещё немного. — Если они найдут способ
      явиться, они круты.</p>

    <p>Андрей выпялился
      на Гошку с неподдельным изумлением.</p>

    <p>— Странный ты какой-то сегодня, —
      пробормотал он, еле заметно отодвигаясь — не отодвигаясь даже, а так.</p>

    <p>— Вроде нормальный,
      — пожал наплечником Гошка. — Спал плохо.</p>

    <p>— Убери-убери, —&nbsp;весомо и
      незлобно прогудел Соций, — носишься со своей цацкой —
      хоть не свети.</p>

    <p>Носился Гошка не с цацкой, а с её содержимым, и Соций это прекрасно знал.</p>

    <p>Небезызвестный голова гэбни так
      часто перекрашивал волосы, что, собираясь на встречу гэбен, мог бы столкнуться
      с определённой трудностью: поди ещё вспомни, какими
      они там были изначально. Цветная фотокарточка есть, но вытирается и ненадёжна,
      лучше просто хранить образец.</p>

    <p>Вот и пригодился дедушкин
      антикварный медальон — идеальная тара для локона волос.</p>

    <p>Своих, рыжих.</p>

    <p>Броских.</p>

    <p>Самое то.</p>

    <p>Можно было бы и не таскать всегда
      на себе, но не знаешь ведь, в каких условиях придётся в следующий раз
      перевоплощаться. Плюс баба красивая, хоть и серебряная.</p>

    <p>За дверью послышались шаги, и
      Гошка неохотно засунул медальон обратно в воротник, но застёгиваться не стал —
      ну нахер.</p>

    <p>Сперва
      на пороге показались Охрович и Краснокаменный — в наплечниках поверх свитеров,
      с довольно-таки плотоядными ухмылками на лицах.</p>

    <p>Значит, всё-таки гэбня.</p>

    <p>Как любопытно.</p>

    <p>На поясах у обоих неприкрыто
      болтались пустые кобуры — на встречу гэбен табельное (ну или любое другое, но
      любого другого вообще не должно существовать в природе) оружие проносить
      запрещено, забрали на входе. Это забавно, кстати: непредвзятых наблюдателей,
      способных обслуживать встречу гэбен (отбирать оружие, например), в природе тоже
      не существует, не фалангам же на пороге стоять. По-хорошему там должны быть
      младшие служащие обеих гэбен, но Университет никого
      загодя не прислал, так что нынче оружие отбирали бедроградские.</p>

    <p>А ведь можно просто скомандовать
      своим войти сюда и перестрелять университетских к херам конским из их же
      табельного — и пусть потом доказывают что хотят.</p>

    <p>И здание, выбранное для встречи
      гэбен, находилось в юрисдикции Бедроградской — не в Порту же ему располагаться.
      Само оно вроде как считалось ничьим, но есть проводка, прилежащий земельный
      участок, парковка для такси. И херовы канализации тоже есть.</p>

    <p>Вот тут-то они зачем, не срать же
      на встречу гэбен ходят!</p>

    <p>За Охровичем и Краснокаменным
      вошёл Ларий Базальд с кучей макулатуры в руках, улыбнулся. Экое
      солнышко. Интересно, солнышко уже капает на своих в
      Бюро Патентов, или пока любовь к родимым глобусам
      сильней?</p>

    <p>На Базальде
      был трогательный серый пиджак. Университетская гэбня в
      принципе ебёт любые инструкции о ношении формы в обе коленные чашечки, спасибо
      хоть наплечники нацепляют, но пиджак Базальда — почти и не отличается от
      форменного мундира. Цвет так вообще один в один, о
      певец конформизма.</p>

    <p>Но кого ебёт Базальд. Дальше-то,
      дальше кто?</p>

    <p>Барабанная дробь!</p>

    <p>Ройш.</p>

    <p>Ройш в наплечнике.</p>

    <p>Оп-паньки.</p>

    <p>Замешательство в рядах
      Бедроградской гэбни было столь осязаемо, а самодовольство на лицах Университетской столь неприкрыто, что Гошка ещё раз обдумал
      вариант, связанный с конскими херами и их же табельным.</p>

    <p>Расселись они традиционно. Стол
      для встречи гэбен шире стандартного стола для допросов, стульев вокруг него
      восемь — четыре на четыре, лицом к лицу. И ещё нет никакой перегородки под
      столешницей в середине, только ножки по углам: на ноги в такой ситуации
      смотреть некому. Зато если захочется пообщаться языком стоп с оппонентом
      —&nbsp;пожалуйста.</p>

    <p>Все условия для того, чтобы
      заподозрить своих в сговоре с противником.</p>

    <p>Проверочка,
      блядь. Бесконечная и постоянная.</p>

    <p>Симметрия при посадке соблюдалась
      неукоснительно, в этом почти было что-то уютное. Охрович и Краснокаменный
      напротив Бахты и Соция, по краям. Базальд напротив Андрея, а Молевич,
      разумеется, напротив Гошки.</p>

    <p>Сейчас напротив Гошки,
      соответственно, уселся Ройш, сложил свои пальцы и осмотрел присутствующих с
      величественным любопытством.</p>

    <p>А не начать ли встречу гэбен с
      ощутимого пинка, дабы стряхнуть с некоторых лиц
      победное выражение?</p>

    <p>— Вы ничего не перепутали? — не
      выдержал Бахта, обращаясь лично к Ройшу. — Мы не вполне уверены, что вы имеете
      право здесь присутствовать.</p>

    <p>Ройш не стал отвечать сразу,
      посмаковал вопрос. Он не выглядел усталым, он не выглядел раздражённым, он не
      выглядел вообще никаким.</p>

    <p>Все эти истории о том, как он
      принципиально не хочет уровня доступа выше простого истфаковского,
      — бутафория?</p>

    <p>Или, что более вероятно, они там
      все настолько ёбнулись, что посадить на место Молевича Ройша для них
      —&nbsp;малая кровь?</p>

    <p>— По всей видимости, мне
      необходимо представиться, — нарочито медленно отозвался тот. — Константин
      Константьевич Ройш, временно исполняющий обязанности головы гэбни Университета.</p>

    <p>— Вы, часом, не родственник хэра
      Ройша? — не удержался уже сам Гошка.</p>

    <p>Охрович и Краснокаменный
      посмотрели на него с одобрением.</p>

    <p>Шли бы они к лешему со своим
      одобрением под ручку.</p>

    <p>— Это не имеет отношения к делу,
      — сухо отозвался Ройш, не сводя с Гошки своих маленьких глазок.</p>

    <p>— Нас не уведомили об изменениях
      в составе гэбни БГУ имени Набедренных, — так же сде��жанно заметил Соций.</p>

    <p>Это весело: знать, что
      присутствующие радостно передушили бы друг друга, знать про объёмистые чаны
      дерьма, обоюдно вылитого за пределами этого здания, но разговаривать вежливо и
      называть Университетскую гэбню «гэбней БГУ имени
      Набедренных», как она означена в документах. Потому что ведётся запись, и ещё —
      потому что именно за умение в нужный момент переставлять слова до должной
      степени канцелярщины слога фаланги и терпят любые
      выходки.</p>

    <p>— Мы не получали запроса
      относительно текущего состава гэбни БГУ имени Набедренных,
      — равнодушно ответил Ройш.</p>

    <p>— Официально запрашиваем, —
      оборвал его Гошка.</p>

    <p>Базальд посмотрел на Гошку с
      жалостью, затем покосился на единственный печатный лист, лежащий перед Ройшем,
      а не в личной базальдовской стопочке.</p>

    <p>— <i>Как вам, безусловно, известно,</i> согласно специальной служебной
      инструкции головой гэбни БГУ имени Набедренных может
      быть только лицо, в Университете работающее, — извиняющимся тоном поведал он. —
      Некоторое время назад — вчера, если быть точным — Максим Аркадьевич Молевич
      подал заявление об увольнении по собственному желанию. Заявление было
      удовлетворено, что естественным образом исключило его как из преподавательского
      состава, так и из состава гэбни БГУ имени Набедренных.
      Ввиду особенностей вверенного нам учреждения мы имеем право выдвигать свои
      кандидатуры в качестве замены, что и было проделано,
      однако по причине объявленных вчера же чрезвычайных обстоятельств — вы,
      несомненно, помните, что это было озвучено в запросе, — гэбня БГУ имени
      Набедренных не могла бездействовать. Поэтому на время согласования кандидатуры
      нового головы гэбни БГУ имени Набедренных с
      вышестоящими уровнями доступа мы назначили Константина Константьевича
      исполняющим его обязанности.</p>

    <p>— Временным, — подхватили Охрович
      и Краснокаменный.</p>

    <p>— Он точно не задержится в гэбне.</p>

    <p>— Не подходит по габаритам.</p>

    <p>— Об
исполняющем обязанности ведь можно говорить в единственном числе?</p>

    <p>— Он же всё равно не настоящий
      голова гэбни.</p>

    <p>— И не настоящий Ройш.</p>

    <p>— У настоящего Ройша есть усики!</p>

    <p>— И личность выдвинутой вами
      кандидатуры на должность четвёртого головы гэбни БГУ имени Набедренных
      вы, разумеется, озвучить не можете, поскольку до согласования оной кандидатуры
      подобный разговор был бы бессодержательным, — в тон Базальду кивнул Андрей.</p>

    <p>Была у него такая специальная
      манера говорить — зверски-светская. Кто не знает — видит хладнокровие и
      владение собой в любой ситуации, кто знает — видит своеобразную форму истерики.</p>

    <p>— И
противоправным, — во имя формализма заметил Гошка, легонько касаясь Андрея ногой
под столом.</p>

    <p>У языка стоп, как и у любого
      живого языка, должна быть фатическая функция —
      функция простого и информационно ненаполненного поддержания контакта.
      Напомнить, что ты рядом.</p>

    <p>А потому что меньше надо было в
      своё время бухать на лингвистическом факультете.</p>

    <p>Что поделаешь, бабы там красивые,
      да и мужики тоже ничего. А ещё заснувших на задних рядах не выгоняют на ночь из
      аудиторий, слишком много прецедентов.</p>

    <p>Свои законы.</p>

    <p>У Университетской гэбни тоже свои
      законы — их служебные инструкции, видимо, сам Хикеракли сочинял, и уж он-то
      точно не был трезвым в этот момент. Больше ни одна гэбня страны не может
      самостоятельно выдвигать кандидатов на должность своей головы — потому хотя бы,
      что потребность в кандидате возникает тогда, когда гэбня неполная, а когда она
      неполная, она не должна, по-хорошему, функционировать, в том числе и что-то
      куда-то выдвигать. Всем и всегда голов назначали сверху — кроме Университета.
      Ещё у Медицинской гэбни истории какие-то тёмные, но они как раз могут себе
      позволить.</p>

    <p>Хвалить Университет — даже
      мысленно — не хотелось, так что оставалось только злиться. Нашли дырку в своих
      криво написанных служебных инструкциях и традиционно её выебли. Надо бы уже
      признать, что по-честному их победить невозможно, и искать другие методы —
      только другие методы тоже не работают.</p>

    <p>Гошка ещё раз осмотрел голов Университетской гэбни со всем возможным вниманием. Он
      видел
      этих людей в своей жизни куда больше раз, чем следовало бы, должен он заметить
      следы усталости или нет?</p>

    <p>Базальд особо не скрывает
      озабоченности, но успешно закапывает её в свою макулатуру и общий предлог
      встречи. Базальд — этакий <i>добрый брат-близнец</i> Андрея, даже внешне чем-то напоминает; хладнокровие и владение собой — это тоже
      форма истерики. И при этом — упорно промахивается плечом мимо Ройша.</p>

    <p>Сидя на одном уровне доступа и время от времени встречаясь, невольно приобретаешь
      что-то
      вроде синхронизации и со второй гэбней тоже. Эдакую
      тень. И сейчас Гошка был уверен: Базальду неуютно и неудобно.</p>

    <p>Когда Молевич вернётся с Пинеги,
      его ожидают интересные открытия.</p>

    <p>Всё ещё не закончилось, бляди.</p>

    <p>Охровича и Краснокаменного рассматривать
      без толку: зверюшки — они и есть зверюшки. Сейчас
      синхронно склонили головы к наплечникам и рассматривают Гошку в ответ,
      скалятся.</p>

    <p>Осталось понять, пробиваем ли
      Ройш.</p>

    <p>— Отлично, — постановил Гошка,
      краем глаза косясь в многочисленные бумаги, которые Базальд передал Андрею (и правда по собственному желанию, и правда рукой Молевича —
      или рукой, очень похожей на руку Молевича), — тогда мы не видим никаких
      препятствий к тому, чтобы официально начать встречу гэбен.</p>

    <p>Ройш величаво кивнул. Охрович и Краснокаменный
      с ухмылками сложили пальцы ройшевскими домиками. Базальд позволил себе
      небольшую паузу, но потом невозмутимо повторил жест.</p>

    <p>Атака отряда боевых ройшей, спасайся кто может.</p>

    <p>— Мы, гэбня БГУ имени
      Набедренных, заявляли на повестку дня один вопрос: объявление в Бедрограде
      чрезвычайных обстоятельств, —&nbsp;медленно начал Ройш.</p>

    <p>— В черте города нами была
      обнаружена вспышка некоего заболевания, по предварительному медицинскому
      заключению —&nbsp;смертельно опасного, — поддержал его Базальд.</p>

    <p>— Чума в Бедрограде!
      —&nbsp;воскликнули Охрович и Краснокаменный.</p>

    <p>Чума в Бедрограде — сказали-таки
      вслух, и ничего, не обвалился потолок. Андрей чуть дёрнулся, Соций напрягся;
      Бахты Гошка не касался, но знал — тот еле заметно улыбается.</p>

    <p>Чума в Бедрограде.</p>

    <p>А теперь мы все вместе будем с
      пылом первооткрывателей и искренним удивлением обсуждать то, что и так знаем.</p>

    <p>Гошка уловил желание Соция
      потянуться за сигаретой и мысленно поблагодарил его — самое время закурить.</p>

    <p>— Нам об этом известно, —
      спокойно ответил Бахта, чиркнул спичкой. — Ситуация под контролем.</p>

    <p>— По нашим представлениям она
      должна быть под обоюдным контролем гэбен города Бедрограда и БГУ имени Набедренных,
      — чинно заметил Базальд.</p>

    <p>— А какое, по-вашему, отношение
      данная ситуация имеет к гэбне БГУ имени Набедренных?
      —&nbsp;спросил Соций.</p>

    <p>— И как вы узнали о чуме?</p>

    <p>Гошка спросил слишком быстро —
      как раз настолько, чтобы университетские могли складно
      соврать. Они же все хором посмотрели на него с пресловутой снисходительной
      жалостью. Так смотрят из бункера, с безопасной верхушки, оттуда, где всё равно
      не достать.</p>

    <p>Как Университет узнал о планах
      Бедроградской гэбни?</p>

    <p>Как сумел подготовиться?</p>

    <p>Ответ где-то здесь, совсем под
      носом, рядом, и они его знают, и они считают Гошку идиотом
      за то, что он ещё не догадался. И они знают, что, когда догадается, не докажет,
      а если докажет — никто его не послушает.</p>

    <p>Сработали чистенько, ни один
      пихтский комар носа не подточит.</p>

    <p>Но что сработали-то, бляди?!</p>

    <p>Он должен знать, должен — в
      единственном числе должен, и похер на чуму, на уровни доступа и даже на гэбню с
      «мы» и синхронизацией — смотрят-то с жалостью на него, на него одного, на него <i>лично</i>! <i>Он</i> глава гэбни, <i>у него</i> должно хватить мозгов, чутья, наглости —&nbsp;чего угодно!</p>

    <p>Гошка медленно выдохнул — спасибо
      Социю ещё раз за возможность прикрыться сигаретой.</p>

    <p>Мирных переговоров тут не будет,
      вот уж точно.</p>

    <p>— Заражение затронуло
      в том числе и студентов БГУ имени Набедренных, — опустив очи долу, ответили
      неожиданно Охрович и Краснокаменный.</p>

    <p>— Некоторые из них оказались
      студентами медицинского факультета.</p>

    <p>— Отследили у себя признаки
      нетипичного заболевания.</p>

    <p>— Занялись развёрнутым
      самоанализом.</p>

    <p>— Мы обладаем достаточным
      количеством лекарства для излечения заражённых, —
      спокойно вклинился Ройш. — Вы, не сомневаемся, тоже. Нам представляется
      целесообразным объявить чрезвычайные обстоятельства и скоординировать наши
      усилия в борьбе с чумой.</p>

    <p>Первый удар всегда таков, что и
      не поймёшь, что это удар, пока не стало слишком поздно.</p>

    <p>— Объявлять чрезвычайные
      обстоятельства не хотелось бы, —&nbsp;мягко заметил Андрей, улыбнулся. — По нашим
      данным заражение достаточно обширно. И если медики, вынесшие заключения о
      степени опасности данного заболевания, не ошибаются, создавшийся информационный
      прецедент может оказаться… пагубным.</p>

    <p>— Во Всероссийском Соседстве не
      должно быть эпидемии чумы, — пояснил для тупых Бахта.</p>

    <p>— Информационное состояние города
      Бедрограда находится в ведении гэбни города Бедрограда, — удовлетворённо
      пробормотал Ройш, постучал кончиками пальцев друг о друга.</p>

    <p>— Мы всего лишь хотим обезопасить
      подотчётное нам население — и, разумеется, помочь вам обезопасить тех, кто
      подотчётен вам, — кивнул Базальд.</p>

    <p>Удобная позиция, почти идеальная
      — вы проебали, мы тут ни при чём, действуем сугубо из гуманизма, но готовы
      помочь, только попросите. Засунули бы себе поглубже
      свою помощь и провернули пару раз — город Бедроград обойдётся силами гэбни
      города Бедрограда, спасибо!</p>

    <p>— Если чрезвычайные
      обстоятельства будут объявлены, — злорадно напомнил Гошка, бросая сигарету в
      пепельницу, —&nbsp;неизбежно последует разбирательство. По нашим данным заражение
      распространяется через <i>подотчётные вам</i> канализации, что неизбежно вызовет ряд вопросов <i>к вам</i>.</p>

    <p>Ройш снизошёл до человеческого
      взгляда и осмотрел Гошку с любопытством энтомолога.</p>

    <p>— По <i>нашим</i> данным, — медленно проговорил он, — заболевание вызвано
      вирусом искусственного происхождения, созданного, по всей видимости, неким
      одарённым медиком на заказ. Как бы вышеозначенный вирус ни распространялся,
      вопросы неизбежно возникнут и к тому, кто его заказал, — если заказчика найдут,
      разумеется.</p>

    <p>Гошка сжал зубы.</p>

    <p>Отбили.</p>

    <p>— Вызывающая уважение
      осведомлённость, — безмятежно прокомментировал Соций. — Одарённый медик?
      Заказчик? В целях всеобщей безопасности мы были бы признательны за более
      подробную информацию касательно происхождения вируса.</p>

    <p>Снова время мысленной благодарности
      Социю. Хороший вопрос: можно ответить достаточно честно и наконец-то привести
      беседу к тому, что всех присутствующих действительно волнует, а можно не
      отвечать — и тем самым ещё яснее задекларировать, что здесь не мирные
      переговоры. Ройш и так уже малость проболтался —
      неоткуда им с их нынешней легендой знать о заказчике; осталось только додавить.</p>

    <p>— Приносим свои извинения за
      чрезмерную умозрительность предыдущего высказывания, — приподнял брови Базальд,
      чуть склонил голову в знак покаяния. — Содержательно важно следующее: вирус,
      судя по всему, — искусственный, создан в лаборатории, в пробирке. А значит —
      кто-то его создал, и к этому кому-то — или тому, кто создание вируса оплатил, а
      также допустил его распространение — неизбежно возникнут вопросы. Только и
      всего.</p>

    <p>— И это всё, что вам известно о
      вирусе? — прямо спросил Бахта.</p>

    <p>— Нам должно быть известно что-то
      ещё? — отозвались Охрович и Краснокаменный, склоняя и свои головы.</p>

    <p>— Возможно, это <i>вы</i> хотите поделиться с нами более
      подробной информацией?</p>

    <p>— Возможно, вы знаете, кто
      заказал вирус и как он оказался в наших канализациях?</p>

    <p>— Возможно, вы поделитесь вашим
      медицинским опытом оперативного создания действительно большого количества
      лекарства?</p>

    <p>— Когда вы обнаружили чуму?</p>

    <p>— Сколько дней вам потребовалось
      на разработку и создание дезинфекционного раствора, пущенного по <i>нашим</i> канализациям в нескольких районах?</p>

    <p>— Каковы имена тех медиков в
      Бедрограде, которые анализировали обнаруженный вирус?</p>

    <p>— Возможно, мы хотим поговорить
      начистоту? — рявкнул Гошка, перекрывая воркотню обоих.</p>

    <p>Эта беседа вся —&nbsp;как баба с
      медальона: чеканная, филигранная и только в профиль, никакого содержания. Какой
      в ней толк —&nbsp;узнать, что они заменили Молевича на Ройша и радуются?
      Охеренно полезная информация, блядь. Узнать, что они тоже владеют высоким
      слогом канцелярщины? Вот это действительно новость,
      стоила неврозов и кишок на потолке!</p>

    <p>Ройш замер, вперился
      в Гошку со всей старательностью.</p>

    <p>Давай, детка, я же знаю, что тебя
      волнует.</p>

    <p>— Возможно, хотим, —&nbsp;наконец
      выдал он. — Говорите.</p>

    <p>— Спрашивайте.</p>

    <p>Ройш улыбнулся одними губами:</p>

    <p>— Любые потенциально возможные
      вопросы неизбежно будут содержать пресуппозиции, находящиеся за рамками
      заявленной темы обсуждения. Мы полагаем не вполне корректным обременять ими
      информационные единицы, с зафиксированным вариантом которых могут быть
      ознакомлены представители более высоких уровней доступа.</p>

    <p>— Стандартной процедурой
      обработки данных, предоставляемых по запросу представителей более высоких
      уровней доступа, является устранение из записей внепротокольных элементов
      общения, — лучезарно ответил Андрей. — Мы все знаем, что государственные
      служащие Всероссийского Соседства — занятые люди, и не хотим обременять их
      информацией, не относящейся непосредственно к теме, заявленной в запросе.</p>

    <p>Мы все знаем строго обратное —
      что фаланги удушатся, лишь бы послушать запись с каждым вздохом, междометием и
      паузой, но плёнка у нас, поэтому слушать они будут то, что мы предоставим.</p>

    <p>Если им что-то не понравится, они
      могут послать свои жалобы по адресу тех, кто устроил из Всероссийского
      Соседства империю бюрократии.</p>

    <p>То есть на хер.</p>

    <p>— И обработкой данных,
      разумеется, вызовется заниматься гэбня города Бедрограда? — осведомился
      Базальд.</p>

    <p>— Самолично,
      — фыркнул Бахта. —&nbsp;Можете направить представителя в помощь.</p>

    <p>После некоторой паузы (они что, правда общаются с Ройшем ногами? Храбрые
люди!) Базальд кивнул.</p>

    <p>Итак, спрашивайте.</p>

    <p>Ройш слегка откинулся, возвёл
      глаза — иерархизируя вопросы, видать. Давай, детка, мы-то знаем, что тебя
      волнует.</p>

    <p>Давай-давай, шевели
      тазобедренными костями, или что там у тебя есть.</p>

    <p>— Сколько ещё продлится активная
      фаза заражения? — спросил он наконец. — Будут ли ещё
      вспышки? По вашим прогнозам, само собой.</p>

    <p>Гошка ухмыльнулся. За всеми этими
      политическими игрищами всё время забываешь о простом и
      важном: им страшно. Эти бляди знают себе цену и свято верят, что она высока, но
      эти бляди не хотят умирать и не могут бесконечно гнать лекарство.</p>

    <p>Не сидел бы Гошка в гэбне — мог
      бы просто зашкериться куда-нибудь и пугать оттуда заразой. Кто, блядь, и когда
      заставил его поверить, что в гэбне возможностей больше? В гэбне плясок вокруг
      протоколов больше, а чтобы хоть что-то сделать, надо сперва
      прорваться через макулатурный самум.</p>

    <p>Как ни крути, у государственного
      терроризма есть свои плюсы.</p>

    <p>— По нашим прогнозам данная
      активная фаза заражения завершена, — бухнул Соций, — но всегда возможен
      рецидив.</p>

    <p>— Медицина — тонкая штука,
      —&nbsp;язвительно прибавил Гошка. — Надеемся, наш ответ вас устраивает?</p>

    <p>— Вполне, — кивнул Ройш.</p>

    <p>— Тогда наша очередь. — Гошка
      обвёл университетских взглядом. — Откуда взялось
      заражение в доме номер 33/2 на пересечении Поплеевской и Объединённых
      Заводоводств?</p>

    <p>Недоумение в рядах противника
      принесло бледное, но всё-таки удовлетворение: не знают.</p>

    <p>Вот будет хохма, если сейчас
      выяснится, что они и исчезновение своего распрекрасного (в полном смысле этого
      слова!) завкафа осознать не успели. Ну ничего — совсем
      скоро завкаф найдётся, тогда и попрыгают.</p>

    <p>— Разве заражение проникло в 33/2
      не тем же путём, что и во все остальные заражённые дома? — осторожно спросил
      Базальд.</p>

    <p>— Мы, разумеется, имеем в виду
      естественное распространение болезни, — отчеканили Охрович и Краснокаменный.</p>

    <p>— Других путей ведь нет и быть не
      может, не правда ли?</p>

    <p>Гошка ожидал, что сейчас
      заговорит Андрей, но тот почему-то промолчал, и за него высказался Бахта:</p>

    <p>— Мы внимательно отслеживаем пути распространения чумы, и наши данные говорят
      о том, что заражение 33/2 — аномалия. Мы не ожидали болезни в этом районе, тем
      более — в этом доме.</p>

    <p>В отличие от Охровича и
      Краснокаменного, Гошка вовсе не был ходячим детектором лжи, но сейчас даже дохлый безглазый осёл заметил бы, что Ройш переваривает
      информацию — что только желудочный сок по роже не потёк.</p>

    <p>Если заражение завкафов
      —&nbsp;всё-таки не политическое решение, а чья-то самодеятельность в рядах
      Университета, то почти даже больно, почти обидно.</p>

    <p>Гошка ведь почти поверил в то,
      что у Университета (всего Университета!) пробилась
      наконец жилка цинизма.</p>

    <p>Зато можно смело рисовать
      звёздочку на борту: нынешняя Университетская недогэбня, кажется, не знала, что завкафа заразили не
      бедроградские, а кто-то из своих же. Не знала — а теперь узнала.</p>

    <p>Дальше пусть сами копаются в
      собственном грязном белье до закономерного финала.</p>

    <p>— Поскольку вы затруднились дать
      ответ на наш вопрос, мы, по всей видимости, вправе задать ещё один, — хмыкнул
      Гошка и тут же, не делая паузы, задал, — кто такой Дмитрий Борстен?</p>

    <p>Университетские смешались.
      Они-то, поди, считали, что самим фактом своего на
      встречу явления уже победили. Да что там, сам Гошка так почти счёл —&nbsp;пока
      не понял, что Университет от этой встречи ровным счётом ничего не выиграл.
      Разве что выяснил, что почему-то поскучневший Андрей по-прежнему занимает своё
      законное место.</p>

    <p>Ну что ж, пусть устраивают
      ликование и пляски.</p>

    <p>— Дмитрий Борстен — душевный
      человек, — заорали Охрович и Краснокаменный.</p>

    <p>— Поговаривают, мужчина.</p>

    <p>— Клёвый
      парень.</p>

    <p>— Малый хоть куда.</p>

    <p>— Умница и красавица.</p>

    <p>— Заноза в седалище.</p>

    <p>— Уточните свой запрос,
      пожалуйста, — нахмурился Базальд.</p>

    <p>— И обоснуйте, — прибавил Ройш.</p>

    <p>Опомнился-таки!</p>

    <p>Гошка степенно извлёк ещё одну
      сигарету, прикурил, выпустил в Ройша колечко дыма.</p>

    <p>— Уточнить непросто, — широко
      улыбнулся он и понёсся дальше на волне накатившего нахальства.
      — Нас интересует достаточно всесторонняя информация —
      биография, психологический портрет…</p>

    <p>— Физический портрет, кстати,
      тоже, — вставил Бахта.</p>

    <p>— Медицинская компетенция,
      уровень доступа…</p>

    <p>— И обоснуйте, — с нажимом
      повторил Ройш.</p>

    <p>Гошка по-андреевски
      похлопал на него глазками. О жестокий мир, о жестокий Ройш,
вырывающий из сладкого мира грёз.</p>

    <p>— До нас дошли сведенья о том,
      что так зовут человека, в числе прочих занимающегося вопросами чумы с вашей
      стороны. Во имя всеобщей безопасности было бы небесполезно узнать несколько
      больше о том, кому вы доверили столь ответственное дело, благо обо всех остальных
      университетских медиках нам хотя бы в общих чертах известно.</p>

    <p>— Вызывающая уважение
      осведомлённость, — прошипел Базальд.</p>

    <p>— Мы бегло проверили списки ваших
      младших служащих — Дмитрий Борстен среди них не значится, — перебил его Гошка.
      — По всей видимости, у него врачебный одиннадцатый уровень доступа. В
      медицинских учреждениях города Бедрограда он не числится. Дмитрий Борстен
      —&nbsp;сотрудник медицинского факультета БГУ имени Набедренных?
      Какую именно должность он там занимает? Это риторические вопросы, — жестом
      остановил он открывшего рот Базальда, — суть не в том.
      Можно ли доверить Дмитрию Борстену разработку средств по борьбе с чумой? Вот
      настоящий вопрос —&nbsp;и сложный, ответ на который не содержится в его дипломе
      или даже досье. Нужно учитывать многие факторы.</p>

    <p>Гошка плечом почувствовал, как за
      время его проникновенной речи Андрей весь потеплел, загорелся почти.</p>

    <p>Представал ли Дмитрий Борстен пред очи Бедроградской гэбни и бывал ли вследствие
      этого на
      Колошме? И — чем леший не шутит! — не носил ли он когда-нибудь других фамилий —
      Ройш, скажем?</p>

    <p>Вот настоящие вопросы, и способ
      ответить на них прост, как два пальца в рот.</p>

    <p>— Если у вас вызывает сомнение
      какая-либо причастность Дмитрия Борстена к вопросам чумы, мы можем просто
      аннулировать таковую… — заюлил было Базальд, но Гошка
      оборвал его, снисходительно покачав головой:</p>

    <p>— Факт причастности уже
      засвидетельствован, не так ли? Перевод в прошедшее время его не отменит. Мы
      хотим удостовериться в том, что ущерба не было и не могло быть причинено, и что
      безопасности вверенной вам части населения Бедрограда ничто не угрожает.
      Простейшим способом сделать это, по нашим представлениям, — Гошка не удержался
      и сделал-таки крошечную паузу — он тоже заслуживает минуты самодовольства, —
      является очная ставка с Дмитрием Борстеном.</p>

    <p>Повисла тишина. Охрович и
      Краснокаменный ощерились.</p>

    <p>Ничего, когда в дверь ввалился незваный Ройш, тоже было трудно подобрать первое
      слово.</p>

    <p>Незваный
      Ройш хуже трезвого пихта.</p>

    <p>— Мы не видим необходимости в
      удовлетворении вашего запроса, — очень медленно проговорил незваный
      Ройш. — Если вопрос заключается исключительно в профессиональной компетентности
      Дмитрия Борстена, официального подтверждения оной гэбней БГУ имени Набедренных должно быть вполне достаточно.</p>

    <p>— Вы не видите, но <i>мы</i> видим, — надавил Гошка. — В конце
      концов, в гэбне БГУ имени Набедренных <i>пока
        ещё</i> нет медиков, как она может самостоятельно оценить
      чью бы то ни было врачебную компетентность?</p>

    <p>— Мы предоставим
вам заключение квалифицированного специалиста, — с надеждой предложил
      Базальд.</p>

    <p>Гошка покачал головой с очень —
      ярко — выраженным — снисхождением.</p>

    <p>Жрите,
      бляди.</p>

    <p>— Мы же уже сказали: это не
      вопрос дипломов и не вопрос пересказанного третьими лицами психологического
      портрета. Нас интересует полная картина, которой можно достигнуть только в
      личной беседе.</p>

    <p>— Мы могли бы вызвать Дмитрия
      Борстена на эту беседу официально, — безмятежно прибавил Соций.</p>

    <p>Ройш шевельнулся.</p>

    <p>— Только в том случае, если бы
      могли заявить также и ведущий к беседе информационный повод — но, насколько мы
      поняли, вы расцениваете официальное объявление о чуме в Бедрограде как
      неблагоприятное <i>для себя</i>, а без него
      состав возможной беседы отсутствует, — заметил он.</p>

    <p>Заметил вполне резонно, но останавливаться
      Гошка не намеревался.</p>

    <p>— Неужели вы всерьёз полагаете,
      что вопросы, связанные с чумой, — единственные допустимые информационные поводы
      для небольшой беседы? Речь ведь не идёт о допросе. Например: насколько нам
      известно, Дмитрий Борстен не прописан нигде в Бедрограде. Поскольку жилищным
      благоустройством <i>всех</i> жителей
      Бедрограда по-прежнему занимаемся мы, этот вопрос оказывается в нашем ведении —
      а значит, достаточным информационным прецедентом.</p>

    <p>Ройш поморщился. Обидно, сука,
      да? Не подумал о том, чтобы нафантазировать и прописку тоже?</p>

    <p>Проёбывают
      всегда по мелочи.</p>

    <p>Жри
      теперь, жри и радуйся. Собственные ошибки всегда потом прилетают в рыло — и
      дальше люди делятся только на тех, кто готов признать и искупить, и тех, кто
      позорно поджимает хвост.</p>

    <p>Давай, детка, покажи, что ты
      сильный.</p>

    <p>— Дмитрий Борстен может проживать
      в Порту, — всё-таки попытался Базальд.</p>

    <p>— И однозначно работает на
      территории БГУ имени Набедренных — а значит, если он
      проживает в Порту, то перемещаться ему приходится и по территории города
      Бедрограда, — счастливым голосом высказался вдруг Андрей. — Это <i>достаточный</i> информационный повод для
      личной встречи, в ходе которой Дмитрий Борстен, безусловно, получит возможность
      подтвердить ваши показания относительно его места жительства.</p>

    <p>Университетские почти заглохли,
      но выскочили опять Охрович и Краснокаменный — вечный авангард, они же тяжёлая
      артиллерия, они же лучшая защита, то бишь нападение на
      всех присутствующих. Пока они говорят, Ройш может вдоволь поскрежетать
      шестерёнками.</p>

    <p>— Как скрупулёзно с вашей стороны
      столь тщательно заботиться о жилищном благоустройстве каждого рядового
      сотрудника БГУ имени Набедренных!</p>

    <p>— Возможно, вы также хотите
      поговорить с ним о свежих радиопостановках?</p>

    <p>— Сводить в ресторан?</p>

    <p>— Подобрать ему галстук в цвет
      глаз?</p>

    <p>— Вас ожидает великое
      разочарование.</p>

    <p>— У него нет глаз.</p>

    <p>— И всего остального тоже нет. И
      вообще его не существует в природе.</p>

    <p>— Мы поняли вашу мысль, спасибо,
      — некоторая трудность в том, чтобы их перекричать, всё-таки присутствовала, и
      Гошка даже подумал мимоходом, что вовсе не обязательно проявлять к ним
      снисхождение <i>на самом деле</i>, но зарываться всё-таки не стоило. — Официальный вызов кого бы
      то ни было — это действительно всегда дополнительные сложности, которых в
      нынешних обстоятельствах всем наверняка хотелось бы избежать. Мы готовы
      временно опустить формальности — нам всего лишь хочется посмотреть Дмитрию
      Борстену в глаза и поговорить с ним. Нас, к примеру, не меньше вашего
      интересуют возможные альтернативные способы борьбы с чумой —&nbsp;смеем
      надеяться, что Дмитрий Борстен согласится поделиться какими-то идеями. Во имя
      всеобщего блага и исключительно частным порядком.</p>

    <p>Ройш молчал. Ему было некуда
      деваться, но он всё равно молчал.</p>

    <p>Эк они все яростно-то этого
      Борстена покрывают, просто как будто на то есть
      дополнительные причины — помимо страстной любви к Бедроградской гэбне!</p>

    <p>Просто как будто в своё время они
      его не покрыли.</p>

    <p>Тычок-то совершенно
наудачу был — из социевских навязчивых идей о
      том, что снимать нужно рулевого, а рулевой Университета — не в гэбне. Надо,
      значится, сходить-проверить, нет ли у них там кого странненького,
      левого эдакого, кто как бы просто так затесался, а,
      дядя?</p>

    <p>Когда у человека совесть чиста,
      то и себя показать нестрашно, а коли страшно — значит, есть, чего порассказать.</p>

    <p>Значит, есть смысл устраивать
      встречу любыми силами, и поскорее.</p>

    <p>А если Дмитрий Борстен вдруг
      окажется скучным и невесёлым человеком, которому нечего рассказать, всегда
      можно перевести тему беседы на что-нибудь более занимательное.</p>

    <p>Как, например, они всё-таки гонят
      своё лекарство от чумы в отсутствие ресурсов.</p>

    <p>Как они умудрились натравить
      фаланг на Андрея.</p>

    <p>И как, как эти бляди узнали об
      изначальном замысле?</p>

    <p>Гошка лично перепроверил всех,
      кто был хоть как-то, хоть сорок восемь раз косвенно причастен к любым чумным
      делам, кто мог проходить у двери и мимоходом услышать, кто мог догадаться и от балды ляпнуть — чистота, как у прозектора!</p>

    <p>Кто-то из
своих, из голов гэбни — да нет же, тут даже херова гипотеза выдвигаться
отказывалась. Ну мог бы Андрей слить, но
      фалангам и недавно, а херов Университет заготовил херову девку когда ещё. Не
      свои.</p>

    <p>Есть ещё столичный Дмитрий Ройш.
      Борстен он или не Борстен, неважно, всё равно мог донести университетским.
      Только Дмитрий Ройш никого не ебёт, Дмитрий Ройш вполне может оказаться
      последним звеном утечки, а важно-то — первое! Если в Университет на крылах
      любви информацию принёс Дмитрий Ройш-Борстен, то кто был его крылами? Кто
      принёс информацию ему?</p>

    <p>И как, блядь, как?</p>

    <p>Без Фрайда же ясно, что херовы
      кошмары не перестанут сниться Гошке, пока он не поймёт!</p>

    <p>Леший.</p>

    <p>Так вот — Дмитрий Борстен не
      выйдет с частной встречи, пока всё это по порядку не расскажет. Или, если они в
      своём Университете его так любят и ценят, пока не расскажет кто-нибудь другой.</p>

    <p>— Мы понимаем и уважаем ваше стремление перестраховаться в ситуации чумы, —
      почти твёрдым голосом сказал Базальд, переплёл пальцы, одумался и вернул их в
      изначально-ройшевское состояние. — Не хотелось бы надолго отрывать специалиста
      от работы, но частную беседу, несомненно, можно устроить. — Он сделал паузу,
      которую явно полагалось заполнить Ройшу, но тот смотрел куда-то в регион
      гошковского воротника и явственно презирал любую вербальность. — Разумеется,
      если речь действительно идёт о частной встрече, на ней незачем присутствовать
      всем четверым головам гэбни города Бедрограда.</p>

    <p>— Речь ведь не идёт о допросе, —
      пришёл в себя Ройш.</p>

    <p>— И, поскольку дуэль назначили
      вы, выбор оружия по старой эвропэйской традиции — за
      нами, — радостно вскричали Охрович и Краснокаменный.</p>

    <p>— Мы выбираем розги.</p>

    <p>— Или отбойные колотушки.</p>

    <p>—&nbsp;Или Александра.</p>

    <p>— Но если в гэбне города
      Бедрограда нет Александра, мы готовы согласиться на Соция Всеволодьевича.</p>

    <p>— На <i>частной</i> беседе ведь и должна присутствовать <i>часть</i> гэбни, верно?</p>

    <p>Гошка взвесил варианты. Слова о
      том, что не пошли бы они нахер с такими раскладами, сами рвались с языка, но, с
      другой стороны, почему бы и нет? Соций справится, не маленький, а пережми
      сейчас — и лови завтра Борстена по всему Порту и дальше — по всему
      Всероссийскому Соседству.</p>

    <p>И так могли пережать, закончится
      эта встреча — Борстен же может сразу удариться в бега.</p>

    <p>Надо, значит, сделать так, чтобы
      не ударился.</p>

    <p>Интересно, почему Соций? Просто
      так, наугад? Или он по их представлениям самый тупой?</p>

    <p>Или методом исключения — боятся медицински компетентного Андрея, который вмиг
      вызнает всё
      про их методы создания лекарства, боятся Гошки (тут и аргументы не нужны),
      боятся Бахты… просто почему-то?</p>

    <p>Или университетские
      выбрали Соция просто потому, что настолько низко ценят физическую сохранность
      своих людей?</p>

    <p>Эмпирически доказано, что не
      ценят.</p>

    <p>Значит, и ответственность вся за
      то, что из этого может выйти, — на них.</p>

    <p>Ну а если Дмитрий Борстен — не
      только Борстен (много нынче Дмитриев развелось), его ожидают интересные
      открытия, связанные с предвзятым
      отношениям Соция Всеволодьевича к некоторым некогда малым народам
      Всероссийского Соседства.</p>

    <p>— Завтра в восемь утра, чего
      время тянуть, — согласился Соций. Радостно согласился — он же больше всех идею
      выйти поговорить по-пацански лоббировал.</p>

    <p>— Без оружия, само собой, —
      деловито кивнул Базальд. — Это же частная встреча.</p>

    <p>— Мы пришлём вам адрес места, —
      не менее деловито отозвался Бахта. — Во избежание излишней официозности вполне
      подойдёт какой-нибудь склад в черте города. Представители БГУ имени Набедренных,
      разумеется, получат возможность проверить
      помещение заранее.</p>

    <p>А представители гэбни города
      Бедрограда — отвлечь любых наблюдателей и быстренько переместить любых Дмитриев
      в какое-нибудь другое помещение, поинтересней, если
      понадобится.</p>

    <p>Складские районы в черте города
      довольно безлюдны.</p>

    <p>— Таким образом, по всей
      видимости, и с этим вопросом мы разобрались, — отрешённо постановил Ройш.</p>

    <p>Бедроградская гэбня синхронно
      кивнула.</p>

    <p>— По всей видимости, — хмыкнул
      Гошка. — Что-то ещё?</p>

    <p>— Да, — ответил Ройш, не отводя
      пустого взгляда от его воротника. — Какова судьба Брованны Шухер?</p>

    <p>Гошка неторопливо улыбнулся.</p>

    <p>Я знал, детка, я с самого начала
      знал, <i>что</i> тебя интересует.</p>

    <p>И ты это получишь — у нас всё
      честно, и если я буду добрым — но не раньше, чем я получу то, что интересует
      меня.</p>

    <p>— По нашим представлениям этот
      вопрос не имеет прямого отношения к ситуации с чумой, — наслаждаясь каждым
      словом и наконец-то полноценно получая удовольствие от работы, произнёс Гошка.
      — Он скорее был бы уместен в частной беседе. У нас есть некоторая информация, и
      мы — то есть, по всей видимости, Соций Всеволодьевич — не видим причин вам — то
      есть, по всей видимости, Дмитрию Борстену — в ней отказать. Уверены, Дмитрий
      Борстен донесёт до вас всё, что касается судьбы Брованны Шухер, в целости и
      сохранности.</p>

    <p>И явится на встречу.</p>

    <p>Ройш не пошевелился и ничего не
      спросил — и ответил всё так же, гошковскому воротнику, глуховатым голосом:</p>

    <p>— Ваше стремление столь чётко
      разводить информационные поводы наталкивает на мысли о том, что объявление
      чрезвычайных обстоятельств всё-таки необходимо.</p>

    <p>— Как жаль, что ваши, Константин
      Константьевич, мысли — мысли временно исполняющего обязанности головы гэбни БГУ
      имени Набедренных — остаются всего лишь мыслями, —
      пропел Андрей, трогательно улыбнулся — почти можно поверить, что утешает.</p>

    <p>Ройш по-прежнему не шелохнулся —
      зато вместе с тремя остальными головами Университетской гэбни дёрнулась их
      попытка синхронизации.</p>

    <p>— Когда мы рассматривали ваш
      запрос на встречу, нам не было известно о неполноте состава гэбни БГУ имени Набедренных, — спокойно объяснил Соций — все и так уже всё
      поняли, но он никогда не упускал возможности повторить для тупых, и злорадство
      Гошки его только одобряло. — Теперь, когда мы знаем об увольнении Максима
      Аркадьевича, получается, что запрос с самого начала был неправомочным — хотя бы
      потому, что под ним стоит подпись уволенного Максима Аркадьевича, не
      являющегося более головой гэбни. У вас нет оснований назначать временно
      исполняющего обязанности головы гэбни без чрезвычайных обстоятельств — и нет
      права объявлять чрезвычайные обстоятельства при неполном составе гэбни.
      Присутствие здесь Константина Константьевича было бы оправдано, если бы лица
      шестого уровня доступа или выше <i>подтвердили</i> объявление чрезвычайных обстоятельств, но мы, гэбня города Бедрограда, это
      делать отказываемся.</p>

    <p>— Обстоятельства не являются
      чрезвычайными, — победно улыбнулся Бахта. — Чума под контролем.</p>

    <p>— Мы запросим экстренной встречи
      по поводу чумы с гэбней Порта, — бледно пролепетал Базальд.</p>

    <p>— Которая
      не имеет к данному информационному поводу никакого отношения — а значит, и
      права принимать подобное решение не имеет, — жизнерадостно парировал Бахта.</p>

    <p>От Андрея аж
      волной разлетелось во все стороны вчерашнее — <i>я так и знал, что вся эта затея с завкафом — только для твоего личного
        удовольствия!</i></p>

    <p>Миленький прав — сейчас чума к
      Порту отношения не имеет, а через пару часов поимеет,
      и, раз уж они это так любезно здесь проговорили, университетским не понадобится
      даже слишком много думать, чтобы этим быстренько воспользоваться.</p>

    <p>Значит, надо быть быстрее.</p>

    <p>— Поскольку чрезвычайных
      обстоятельств не объявлено, наличие временно исполняющего обязанности головы
      гэбни не является необходимостью, и мы, гэбня города Бедрограда —&nbsp;сейчас,
      17 сентября 1883 года, в 17:49 по бедроградскому времени — требуем полного
      прекращения любой деятельности гэбни БГУ имени Набедренных до официального
      восполнения её состава.</p>

    <p>И мы, гэбня города Бедрограда,
      сейчас, 17 сентября 1883 года, проверим свою физическую подготовку, поскольку
      после того, как за вами, гэбней БГУ имени Набедренных, закроется дверь, нас
      ожидает очень, очень быстрый забег до родного здания, откуда мы должны очень,
      очень быстро отправить фалангам соответствующий запрос.</p>

    <p>Попробуйте обогнать, бляди.</p>

    <p>— Частные договорённости, само
      собой, остаются в силе, — походя пнул лежачего Андрей.</p>

    <p>Ройш опустил руки ладонями на
      стол.</p>

    <p>Что — и всё? Сдался без боя?
      Никаких даже высказываний о том, что, если протокол встречи будет
      подкорректирован, могут возникнуть сложности с доказательством того, что
      требование было выдвинуто именно в 17:49?</p>

    <p>Гошка фыркнул.</p>

    <p>Что бы там ни было дальше, одно
      точно останется фактом: состав Университетской гэбни ни разу не менялся с начала
      её существования.</p>

    <p>Сами-то фаланги вообще знают, как
      его менять, —&nbsp;с учётом особого университетского права выдвигать
      собственных кандидатов и всех прочих специальных служебных инструкций?</p>

    <p>Пусть всем блядям — и тем, и этим
      — будет неудобно. Это тоже проверка — прочности государственного аппарата.</p>

    <p>Во имя блага города Бедрограда.</p>

    <p>— До подтверждения запроса о
      прекращении любой деятельности БГУ имени Набедренных
      мы не имеем права конфисковать ваше табельное оружие, но не имеем права и
      возвращать его вам, — ласково склонился вперёд Бахта. — Поскольку в данный
      момент два пистолета из четырёх находятся в руках младших служащих гэбни города
      Бедрограда, наиболее целесообразным представляется задержание их выдачи до
      выяснения права пользования — с передачей представителям вышестоящих уровней
      доступа, разумеется.</p>

    <p>— И с нашей стороны было бы…
      некрасиво препятствовать представителям БГУ имени Набедренных
      проследить за судьбой оружия, — не менее дружелюбно поддержал его Андрей, —
      если, конечно, вы сочтёте это необходимым.</p>

    <p>— Вопрос оставшихся в вашем
      владении пистолетов, само собой, будет решаться уже без нашего участия, —
      совсем уж медовым тоном завершил Гошка.</p>

    <p>Вопрос оставшихся пистолетов
      важен —&nbsp;пиздец как важен.</p>

    <p>Во Всероссийском Соседстве
      контрабанда и доступ к разнообразным незаконным элементам действительности —
      искусство повыше политики. Тоже ничего нельзя, но всё вроде как можно. Порт
      этим вон и живёт —&nbsp;и все, блядь, это знают.</p>

    <p>Если с чем-то и есть настоящая определённость
      — так это с оружием. То есть и тут, конечно, романс с тройным припевом, но уже
      на международном уровне: Пакт о Неагрессии во имя блеска и своевременного
      увлажнения европейских задниц подписан, армии во
      Всероссийском Соседстве нет и быть не может, зато имеется невыносимо раздутый
      госаппарат, где каждому второму правомочно полагается табельное. Армии нет, нет армии, агрессии тоже нет, есть только гэбни, которым
можно пистолеты, и некоторые младшие служащие, которым можно пистолеты, и
некоторые полуслужащие, которым можно аж до ружей, и Расстрельный Комитет,
которому как раз ружья и можно, и Силовой Комитет, которому можно всё —&nbsp;и
огнемёты в придачу. Но эта песнь —&nbsp;для Европ, а
внутри государства присутствует неожиданная и почти пугающая ясность:
госслужащим оружие можно и очень нужно (чтобы армии не было, но при этом она
была), а всем прочим — нельзя категорически (чтобы никакой видимой агрессии, и
чтобы никто в Европах не задумался о количестве оружия в неагрессивном
Всероссийском Соседстве).</p>

    <p>Достать нелегальное оружие в
      Бедрограде, даже в Порту — это вам не рюкзак твири пронести. Сложно.</p>

    <p>И посадить, а то и расстрелять,
      за нелегальное оружие в Бедрограде — это вам не причастность к чуме доказать.
      Просто.</p>

    <p>Ну а сделать так, чтобы
      госслужащему официально и по-белому было запрещено
      своё вполне легальное оружие носить, — задачка для второго курса юрфака, но всё
      равно приятно не ржаветь.</p>

    <p>Андрей на днях обронил
      выразительное, хоть и упадническое, словцо — <i>безвыигрышная ситуация</i>. Миленький-то, как обычно, про
      себя говорил, но сейчас оно очень к месту.</p>

    <p>Если университетские внаглую
      продолжат таскать при себе стволы (табельные или контрабандные — похер) — их
      быстро задержат, уж Гошка-то проследит за тем, чтобы младшие служащие
      Бедроградской гэбни знали, что и у кого искать. Если покорятся и сложат лапки
      ладонями на стол —&nbsp;что ж, репутация всё той же Бедроградской гэбни не на
      пустом месте возникла, они никогда и не скрывали своей любви к силовым методам.</p>

    <p>Десять лет назад, после того, как
      будущего 66563 совершенно скотским образом увели
      из-под носа и посадили на Колошму столичные из тривиальной сучьей жадности,
      гэбня города Столицы ополовинилась.</p>

    <p>И это было достойное деяние.</p>

    <p>Выйдет действительно весело, если
      Университетскую гэбню в итоге упразднят за неимением
      Университетской гэбни.</p>

    <p>— Вы всерьёз полагаете, что
      отсутствие пистолетов нас остановит? — зло гавкнули Охрович и Краснокаменный, в
      упор глядя на Гошку.</p>

    <p>— Мы в любом случае предпочитаем
      более изящные методы.</p>

    <p>— Более длительные.</p>

    <p>— Розги — это только начало.</p>

    <p>— Потом вступают отбойные
      колотушки.</p>

    <p>—&nbsp;И Александр. В качестве ударного
      инструмента.</p>

    <p>— Так звучит симфония истинной
      боли.</p>

    <p>Шутку про Александра Гошка уловил
      с первого раза, спасибо. О том, что он стареет, страдает непрофессионализмом,
      имеет ограниченный репертуар псевдонимов и прочей хероте он подумает как-нибудь потом.</p>

    <p>На чьих-нибудь похоронах,
      например.</p>

    <p>— Да, — сказал он, откидываясь на
      стуле, —&nbsp;мы всерьёз полагаем, что отсутствие пистолетов вас остановит.
      Потому что если оно вас не остановит, нам всем предстоит предельно частная
      беседа, и есть подозрение, что суммарная боевая подготовка у Бедроградской
      гэбни несколько выше, чем таковая у гэбни Университетской.</p>

    <p>Андрей тихо вздохнул — как же
      так, испоганить блистательную бюрократическую победу прямыми угрозами! Гошка
      тоже вздохнул — глубоко и с наслаждением.</p>

    <p>Он желание сказать что-нибудь
      относительно честное прямо в рожи университетским все эти хер знает уже сколько лет
      давил, он свой шанс упускать не намерен.</p>

    <p>— Засим встречу гэбен предлагаем
      считать завершённой, если её вообще можно назвать состоявшейся,
      —&nbsp;прогнул-таки свою линию Андрей. — Поскольку полномочия гэбни БГУ имени Набедренных временно приостановлены, её головы не имеют
      права находиться в помещении для встречи гэбен.</p>

    <p>— Ввиду чего оным предлагается
      стены данного немедленно помещения покинуть, — закончил Бахта и в который раз
      жизнерадостно улыбнулся.</p>

    <p>Ройш и Базальд молча склонили
      головы, поднялись. Охрович и Краснокаменный тоже встали — громко и
      экспрессивно, в последний раз одарили Гошку взглядами, однозначно переводимыми
      как «мы тебя найдём, сука».</p>

    <p>Засим встреча гэбен завершилась,
      поскольку университетские вышли.</p>

    <p>Ищите-ищите.</p>

    <p>Чем быстрее найдёте — тем сами
      виноваты.</p>
  </>
}

export default chapter21
