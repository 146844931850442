import React from 'react'
import { IBookPage } from './index'

const chapter12: IBookPage = {
  header: 'header_d5.jpg',
  title: 'Глава 12. Женщина на корабле (здесь был Хикеракли)',
  prevLink: 'chapter11',
  nextLink: 'chapter13',
  content: <>
    <h2>Университет. Ройш</h2>

    <img alt='' src="/images/12_reusch.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>Предпосылки повального
      гомосексуализма жителей нынешнего Бедрограда достаточно очевидны любому, кто в
      той или иной степени знаком с историей возникновения этого города. Бедроград —
      тогда ещё Петерберг —&nbsp;изначально был небольшим поселением, выросшим на
      базе скопнической общины и портовых строений,
      предтечи нынешнего Порта. Четвёртый Патриархат, привыкший принимать
      проевропейские дипломатические решения, не мог по достоинству оценить наличие
      собственного выхода к морю, и настоящее строительство города началось только с
      приездом в тогда ещё Росскую Конфедерацию Йыхи Йихина, финно-голландского
      мецената и политика. Впрочем, уже к тому моменту население Петерберга было в
      основном мужским (матросы, строители и прочий технический персонал) и
      отличалось специфической культурой, пришедшей, по всей видимости, с моря. Не
      секрет, что многие люди, так или иначе связанные с мореплаванием, полагают, что
      женщина на корабле —&nbsp;к беде.</p>

    <p>И БЕДА ПРИШЛА БЕДА
      ОНА ЛЕЖИТ ОДНА НЕ ПОМНИТ СВОЕГО ИМЕНИ</p>

    <p>Циничное отношение к сфере эротического с появлением Йихина преломилось новым
      образом,
      но не ушло. Этому поспособствовало как создание Йихиным Петербержской
      Исторической Академии, образование в которой было доступно только для
      мальчиков, так и возникновение секты оскопистов под идеологическим руководством
      всё того же Йихина. Официально секта позиционировалась как религиозная, однако
      духовные идеи, объединившие оскопистов изначально, вскоре уступили место другим
      идеалам, как то: приданию большого значения
      культурному и интеллектуальному уровню человека и поддержанию весьма
      своеобразной эстетической парадигмы. Поскольку оказание платных сексуальных
      услуг с самого начала было частью послушания
      оскопистов, неудивительно, что после смерти Йихина религиозный аспект
      оскопистской культуры окончательно затерялся, и они населили собой сеть
      салонов, разбросанных по всему Петербергу. Несмотря на то, что было бы
      преувеличением называть оные салоны исключительно закрытыми борделями, многие историки
      сходятся на том, что Йихин использовал религиозное учение исключительно как
      средство привлечения новых кадров и сам предпочёл бы именно такое развитие
      событий.</p>

    <p>ЗОВЁТ МЕНЯ
НЕПРАВИЛЬНО ЭТОГО ПРОСТО НЕ МОЖЕТ БЫТЬ НЕ ДОЛЖНО</p>

    <p>Именно понимание данных предпосылок,
      повлекших за собой формирование в Петерберге крайне
      специфической культуры, необходимо для правильной оценки некоторых событий
      Революции. Члены Революционного Комитета взращивались в городе, где
      гомосексуализм приветствовался на всех уровнях, являясь в меру изысканным
      удовольствием для аристократической верхушки, нормальной формой досуга для
      студенческой среды, естественным образом жизни для социальных низов и
      традиционным способом заработка для всех тех, кто не мог отыскать у себя иных
      талантов. Именно этим объясняется тот факт, что, когда в руках Революционного
      Комитета оказалась положенная затем в основу проекта нового государства
      технология произведения людей в алхимических печах, всерьёз обсуждался вопрос
      воспроизводства исключительно мужского населения. В конечном
итоге главным аргументом против этого оказалась не возможность политического
конфликта с Европами, не разделяющими подобных идеалов, не отличие культуры
всей остальной страны (где также должна была внедряться новая технология) от петербержской, не возможные медицинские опасности, а личное
мнение хэра Ройша, предпочитавшего женщин и наложившего вето на подобные
интенции.</p>

    <p>ЗДРАВСТВУЙТЕ ЭТО КОНСТАНТИН РОЙШ
      ЗДРАВСТВУЙТЕ В ХАЩИНСКОЙ БОЛЬНИЦЕ ДЕВУШКА ОКОЛО ДВАДЦАТИ ЛЕТ ЗВАЛА
ВАС ПО ИМЕНИ ПРИЕЗЖАЙТЕ</p>

    <p>Вопрос дальнейшего развития
      гомосексуализма в теперь уже Всероссийском Соседстве был поднят Сигизмундом
      Фрайдом и развит его последователями. Несмотря на то, что алхимические печи
      новой страны выпекали людей обоего пола (хоть и с определённым численным
      перевесом), использовался для этого только генетический материал мужчин, что
      повлекло за собой переустройство социальной структуры. Несмотря на то, что дети
      воспитываются в отрядах, нельзя сказать, что институт семьи во Всероссийском
      Соседстве полностью отсутствует: дети общаются со своими отцами в меру
      обоюдного желания, и в меру него же поддерживают контакты. Тем не менее,
      сексуальное влечение к женщинам значительно реже проявляется у людей, лишённых
      образа матери. Если прибавить к этому отсутствие института брака, отсутствие
      способности к естественному (в обход алхимических печей) воспроизводству и
      полный перенос любых сексуальных контактов в сферу социальных связей и
      развлечения, станет вполне понятно, почему любые романтические или эротические
      устремления в адрес женщины встречаются во Всероссийском Соседстве достаточно
      редко.</p>

    <p>С САМОГО НАЧАЛА НЕ СТОИЛО ЗНАЛ ЧТО СЛИШКОМ МАЛЕНЬКАЯ ЧТО ВООБЩЕ НЕ СЛЕДУЕТ
      СВЯЗЫВАТЬСЯ НЕ ПОМНИТ ИМЕНИ</p>

    <p>Ночные электрички в Бедроградской
      области ходят редко, но достаточно бесперебойно, чтобы
      человек, не имеющий таксистских прав, мог добраться до любой искомой точки в
      диапазоне нескольких часов.</p>

    <p>Ройш прекрасно умел отличать те
      телефонные звонки, на которые следует реагировать мгновенно и самолично, от тех,
      которые допускают промедление до более
      подробного выяснения обстоятельств или как минимум до консультации с кем-нибудь
      достаточно компетентным в соответствующем вопросе. Звонок, прозвучавший в его
      квартире около часа назад, явно относился ко вторым. Даже при отсутствии возможности
      лично встретиться с Университетской гэбней, можно было позвонить в квартиру
      Гуанако в Порту или в его же прежнюю бедроградскую квартиру, поскольку
      неизвестно, где именно он и Дима заночевали на сей
      раз. Не затем даже позвонить, чтобы спросить совета, но затем хотя бы, чтобы
      проинформировать о своих перемещениях: в нынешней ситуации любой самовольный
      выход из дома потенциально опасен. Звонок в такое время отнял бы
      десять-пятнадцать минут, но, хотя Ройш успевал на ближайшую электричку до
      Хащины только впритык, он безболезненно мог бы поехать на следующей. Ночные
      электрички в Бедроградской области ходят редко, но достаточно
бесперебойно.</p>

    <p>И невыносимо медленно.</p>

    <p>ПРИЕЗЖАЙТЕ
СКОРЕЕ ВОЗМОЖНА КОМА ВАШЕ ПРИСУТСТВИЕ ПОМОЖЕТ ПРОДЕРЖАТЬСЯ АППАРАТУРА
      УЖЕ В ПУТИ</p>

    <p>Ройш терпеть не мог всякую
      психологию и другие попытки ковыряться в человеческом сознании, даже на Фрайда
      ссылался неохотно. То, что он сейчас делал, наверняка уже кем-нибудь описано и
      именуется «синдромом (или эффектом) этого кого-нибудь». Достаточно
очевидный синдром или эффект: всё разваливается; попытки официально обвинить
Бедроградскую гэбню в чём бы то ни было оказались безуспешными; как минимум в
двух районах города при помощи студентов медицинского факультета обнаружены
симптомы водяной чумы; ещё один район под подозрением (к утру будут результаты
анализов, но предположение, что там просто случайная вспышка тяжёлого ОРЗ,
представляется бессмысленным оптимизмом); лечить третий район в любом
      случае нечем, поскольку всё лекарство, коего Дима благоразумно привёз из
      Столицы с запасом, было израсходовано на первые два, а новые порции появятся
      только завтра к вечеру.</p>

    <p>Любой человек, так или иначе
      чувствующий себя причастным к подобным событиям, в какой-то момент немного
      сходит с ума, теряет ориентиры и представления о здравом смысле —&nbsp;даже несмотря на то, что именно здравый смысл нужно
      сохранять изо всех сил.</p>

    <p>Охровичу и Краснокаменному как
      раз необходимо к концу сентября написать хотя бы одну статью, они и так манкируют своей академической нагрузкой и могут в любой
      момент лишиться звания кандидатов наук. Поскольку они не гнушаются ничем, можно
      предложить им идею. Ройш даже мог навскидку привести несколько исторических
      примеров. Если же этот синдром или эффект уже описан каким-нибудь психоложцем, тем
      удобнее проследить его проявления в
      исторических событиях.</p>

    <p>ВОЛОСЫ РУСЫЕ ДЛИННЫЕ ГЛАЗА СЕРЫЕ
      ОДЕТА В СВИТЕР ДЖИНСЫ ДА ДА
      Я ЗНАЮ О КОМ РЕЧЬ ВЫЕЗЖАЮ</p>

    <p>Электричка только-только
      пересекла окраину города и проползла мимо краснокирпичного здания, некогда
      бывшего одной из казарм Охраны Петерберга, а теперь выполнявшего, по всей
      видимости, функцию склада или другого подобного подсобного помещения при
      стоящем неподалёку заводе. Здание казармы сохранили в исторически первозданном
      виде — не из сентиментальных побуждений, а, по всей видимости, из-за качества
      кладки — только обнесли голубым забором. Очевидно,
предполагалось, что «весёленькое» сочетание красно-рыжего с голубым должно
разнообразить пейзаж, однако в реальности оно только пробудило художественную
жилку в некоем вандале, крупно намалевавшем поперёк всего забора «ХУЙ ТЕБЕ В
РЫЛО, КАПИТАН ДАЛЬНЕГО ПЛАВАНЬЯ», и дальше, меленько, но явно той же рукой
(хоть уже и без запятых): «здесь был хикеракли я знаю
мне рассказали».</p>

    <p>Морская тематика пронизывает весь
      город Бедроград, даже его вандалов.</p>

    <p>Женщине на корабле не место.</p>

    <p>ЛЕЖИТ ОДНА ЧЕРЕПНО-МОЗГОВАЯ
      ТРАВМА ВЫ НЕ ЗНАЕТЕ ЧТО МОГЛА ДЕЛАТЬ В ХАЩИНЕ У НЕЁ
      ТАМ ПОДРУГА</p>

    <p>Нельзя не отдать должное либо
      интеллектуальному уровню, либо связям бедроградских вандалов. Информация о том,
      то Хикеракли нередко бывал в казармах Охраны Петерберга, разумеется, не
      является секретной (как не может являться секретной никакая информация о
      человеке, написавшем как минимум четыре автобиографических повести), но в
      отрядские учебники по истории Революции не входит. Из-за своих культурных
      особенностей Петерберг некоторое время являлся закрытым городом, любой
      несанкционированный выезд из которого пресекался так называемой Охраной
      Петерберга — одним из трёх существовавших тогда в стране военных подразделений.
      Это было явно излишне радикальной мерой, призванной, вероятнее всего, оправдать
      существование в Росской Конфедерации боевых единиц в принципе, что запрещалось
      дипломатическими отношениями с Европами, постулирующими Неагрессию. Тем не
      менее, Охрана Петерберга существовала, и любой человек, пытавшийся выбраться за
      границы города без соответствующих бумаг, попадал к ней в руки.</p>

    <p>Надо ли удивляться, что Хикеракли
      бывал в этих казармах немалое количество раз.</p>

    <p>Как и в доме у Ройшей. Хикеракли
      бывал там и пьяным, и подвыпившим, и слегка подшофе, и
      даже трезвым — но от него в любом случае нестерпимо несло. Когда отец Ройша
      как-то раз спросил у него, зачем он так много пьёт, Хикеракли ответил, что
      «невозможно жить в стране, которую сам построил». На резонное
замечание, что, если воспоминания о Революции неприятны, постоянно ходить в
гости к наследникам одного из членов Революционного Комитета как минимум
неразумно, он выдал гору соплей, сводящихся к тому, что он же их любит, что
детям нужен хоть какой-то дедушка (дети — по крайней мере, сам Ройш — были с
этим решительно не согласны), что хэра Ройша он тоже любит, а они на
      него так похожи, и вообще, что какая разница, «если ты делал Революцию, тебе
      всё в стране будет напоминать, <i>как</i> ты
      её делал». Выдав последнюю сентенцию, Хикеракли громко спросил у пространства,
      где все его биографы и почему за ним никто не записывает столь глубокое
      высказывание, и отключился.</p>

    <p>Наутро он, само собой, ничего не
      вспомнил и всё отрицал.</p>

    <p>Единственный урок, который Ройш
      для себя из этого извлёк, — это что некоторых невыносимых людей всё же можно
      выносить. Впоследствии это ему пригодилось.</p>

    <p>НЕ ДУМАТЬ ПРОСТО НЕ ДУМАТЬ
      ОСТАЛОСЬ ВСЕГО НИЧЕГО ВЕДЬ ОБЕЩАЛИ ЗАЩИТУ ЗАЧЕМ
      ПОЕХАЛА В ХАЩИНУ НАШЛА ВРЕМЯ</p>

    <p>Электричка начала мягко
      тормозить. Хащина — не конечная станция, двери откроются ненадолго, поэтому
      Ройш поднялся, взял портфель и зонт и вышел в тамбур. Там воняло табаком, но к
      этому Ройш тоже привык: пытаться заставить Охровича и Краснокаменного перестать
      делать то, что им нравится, — бессмысленное занятие.</p>

    <p>Помимо систематического курения в
      помещении им нравилось, например, то, что происходило на медицинском
      факультете, и Ройш полагал, что их энтузиазм сыграл в реализации сумасшедшего
      проекта далеко не последнюю роль. Со стороны Университета было непростительной
      халатностью заранее не предусмотреть возможность эпидемии, и тот факт, что
      изначально в планах Бедроградской гэбни достаточно ясно значилось заражение
      только одного дома, её не оправдывал. Университетской
      гэбне невероятно повезло, что им на голову свалились Сергей Корнеевич и Дима со
      своим эксцентричным, но действенным предложением; однако если бы Охрович и
      Краснокаменный не сказали своё твёрдое «да», Университетская гэбня легко могла
      бы отказаться от такого подарка.</p>

    <p>Двери электрички открылись.</p>

    <p>Станция Хащина.</p>

    <p>Реализация эксцентричного
      предложения всё равно выглядела тошнотворно. Ройш твёрдо не
собирался показываться на медицинском факультете до окончания «твири-в-жопу»,
как называл процесс Дима, но с утра ему позвонил Попельдопель и истерично
потребовал немедленно явиться, поскольку деньги, выделенные на закупку
капельниц и больничных уток, были взяты из гранта, куда явно было невозможно
приписать подобное целевое расходование финансовых средств, и требовалось
задним числом переписать заявку на грант и подтверждение за печатью
      Медицинской гэбни, «а в бумажках же только вы любите и, что самое страшное,
      умеете копаться, да мы без вас, да всё к лешему разлетится, да имейте же
      совесть, оторвите же зад ради Университета, не курьера же к вам посылать, у нас
      все люди на поимённом счету». Ройш приехал и убедился как минимум в том, что не
      соваться на медицинский факультет до окончания твири-в-жопу было разумным
      решением.</p>

    <p>Студенты лежали ровными рядами и
      в основном спали, но стоило кому-то проснуться, как он начинал стонать или хотя
      бы шипеть сквозь зубы; самые везучие просто звали врача. Охрович и
      Краснокаменный даже не пытались скрывать, что участвуют в этом только потому,
      что им нравится смотреть на чужие страдания (грузовик с анестетиками, который
      они вели, загадочным образом ополовинился в пути).
      Попельдопель соизволил отвлечься на Ройша только через двадцать минут после
      прихода последнего, а на резонное замечание о том, что незачем изображать
      срочным дело, которое таковым не является, разразился очередной истерической
      тирадой, сводящейся к тому, что он, Попельдопель, не ценит и не намеревается
      ценить чужое время, когда своего не хватает. Ройш хотел подождать Диму и
      перекинуться с ним парой слов —&nbsp;просто потому, что если Диму не отвлечь от
      работы, он свалится от переутомления, — но потом студентка, рядом с постелью которой Ройш стоял, начала плакать прямо во сне, и
      Ройш ушёл, решив, что в конечном итоге может только помешать.</p>

    <p>Ситуация, в которой подобные меры
      являются единственно верными и возможными, поистине безысходна.</p>

    <p>Ройш давно уже признался себе в
      том, что занялся историей именно потому, что все исторические деятели
      становятся в той или иной степени вымышленными, и поэтому не нужно думать о
      том, что и как им доводилось терпеть. Потому же он верил и в статистику:
      большие числа обесчеловечивают, и списки погибших и
      пропавших без вести, к примеру, совсем не страшно читать.</p>

    <p>За последние двое суток
      Университет заказал: полторы сотни больничных коек в дополнение к уже имеющимся (из
      Порта); около полутысячи капельниц с запасными
      элементами (из Бедроградского Медицинского Института, находящегося в ведомстве
      Медицинской гэбни, на деньги гранта медицинского факультета); пять грузовиков
      анестетиков (из Порта, в долг под личную ответственность Сергея Корнеевича); более десяти грузовиков вспомогательных препаратов (из Порта, в
долг под личную ответственность Сергея Корнеевича); более десяти грузовиков
вспомогательных средств, как то — вату, халаты, бинты, разнообразную тару,
перчатки, шприцы и т.п. (из Порта, в долг под личную ответственность Сергея
Корнеевича); неизвестное количество нелегальной и дорогостоящей кровавой твири,
настойки на твири (всё в долг, всё под личную ответственность); прочее.</p>

    <p>Если бы Ройш не поддался на
      истерику Попельдопеля и не пришёл сегодня на медицинский факультет, большие
      числа сработали бы.</p>

    <p>Сколько может продолжаться личная
      ответственность, пока Порт не вспомнит о своём постулируемом нейтралитете?</p>

    <p>Портовое население анархично в
      быту и отрицательно настроено по отношению к любой власти; Портовая
      гэбня управляет ими только за счёт личного авторитета. Для людей с подобной
      ментальностью «власть» — больше красная тряпка, чем объект каких бы то ни было
      симпатий или хотя бы обязательств. Личный авторитет Сергея Корнеевича временно
      оказался сильнее презрения портового населения к головам любых гэбен (за
      исключением, по всей видимости, гэбни Портовой), но действие личного авторитета
      ограничено.</p>

    <p>Хащинская
      районная больница.</p>

    <p>В регистратуре сидела средних лет
      женщина в очках с золочёной оправой. Ройш свернул зонт и поприветствовал её,
      после чего представился. Женщина кивнула и посмотрела на него с вежливым
      недоумением.</p>

    <p>— Насколько я понял, меня
      вызывали.</p>

    <p>Женщина с несколько растерянным
      видом поискала фамилию Ройша в списках пациентов — не нашла, разумеется, но
      согласилась вызвать дежурного врача. Тот прибежал сразу, что неудивительно с
      учётом того, что он должен был ждать Ройша, чрезмерно экспрессивно схватил его
      за локоть и потащил по коридору. Ройш ожидал, что его проводят непосредственно
      в палату, однако дежурный врач, человек за пятьдесят, невысокого роста и с
      обширным брюшком, пригласил Ройша в кабинет и плотно закрыл за собой дверь.
      Ройш приставил зонт к стене, сел в предложенное кресло и осведомился —</p>

    <p>КАКОГО ЛЕШЕГО МЫ ТРАТИМ ВРЕМЯ
      СКОРЕЕ</p>

    <p>— что от него требуется.</p>

    <p>Дежурный врач сел за стол, открыл
      журнал звонков, закрыл его и закурил папиросу. Несмотря на то, что Ройш привык
      к людям, курящим в помещении, он поморщился.</p>

    <p>— Константин
      Константьевич,&nbsp;я позволю себе не спрашивать документов, вся страна знает
      вас в лицо. Ещё я позволю себе, скажем так, интимный вопрос. Какой у вас
      уровень доступа?</p>

    <p>Этот вопрос в последние полгода
      представляется чрезвычайно популярным.</p>

    <p>— Четырнадцатый, как и у любого
      сотрудника исторического факультета БГУ имени Набедренных.</p>

    <p>Дежурный врач, кажется, не
      поверил, пожевал немного свою папиросу.</p>

    <p>— Вы же не рядовой… вы внук хэра
      Ройша, вы должны иметь право на второй. Или нет? Скажем так, если бы у вас был
      второй, мне было бы проще.</p>

    <p>— И тем
      не менее, у меня четырнадцатый.</p>

    <p>Врач посмотрел в окно, решаясь на
      что-то. На лбу у него проступили капли пота.</p>

    <p>— Даже если бы был второй, вы
      легко могли бы мне не говорить… да и, наверное, в
      таких случаях нужно исходить не из уровней доступа, — он стукнул по столу
      ладонью и посмотрел Ройшу в лицо. —&nbsp;Вашу девочку забрал Силовой Комитет.</p>

    <p>Если Бровь забрал Силовой
      Комитет, значит, фаланги всё-таки вмешались в происходящее —&nbsp;и, по всей
      видимости, не на стороне Университета.</p>

    <p>— Расскажите по порядку.</p>

    <p>— Они велели мне молчать и
      отрицать звонок вам… или подсунуть какую-нибудь другую пациентку. Я не знаю,
      чем мне грозит разглашение этой информации.</p>

    <p>Ройш помедлил.</p>

    <p>— Как врач районной больницы
      города Хащины, вы подотчётны непосредственно Хащинской
      Ыздной и Медицинской гэбням, причём уровень доступа
      последней, разумеется, выше. Несмотря на то, что уровень доступа Силового
      Комитета ещё выше, он, в отличие от фаланг —&nbsp;исходя из того, что вы сами
      оперировали термином «Силовой Комитет», я предположу, что вам известны названия
      всех уровней управления, — не имеет над вами прямой юрисдикции. Если среди
      пришедших за девочкой людей не было лиц третьего уровня доступа, указание молчать
      не имеет юридической силы, — Ройш слегка кивнул самому себе. — Разумеется, на
      самом деле указание было, скорее всего, отдано неким фалангой, а представитель
      Силового Комитета вам его только передал. Но вы могли этого не знать. Иными
      словами, я не буду вам врать, что, рассказав мне о событиях, произошедших
      здесь, вы не совершите преступления, но максимум, что вам могут вменить — это
      халатность и преступное неведение. Да и этого возможно избежать. Здесь есть
      пространство для маневра.</p>

    <p>Врач молчал, остолбенело
      созерцая Ройша. Возможно, ему требовались более убедительные аргументы.</p>

    <p>— Не говоря уж о том, что акт
      разглашения всё равно уже произведён. Силовой Комитет обычно работает
      достаточно грубо, вы не можете быть единственным свидетелем. По большому счёту,
      — Ройш не стал вставать, но всё же слегка качнулся, —&nbsp;я могу распрощаться
      с вами и выяснить обстоятельства их появления у других работников больницы.</p>

    <p>— Незачем мне угрожать, я и так
      согласен рассказать, — ответил врач, — просто не треплитесь
      об этом направо-налево. Несколько часов назад к нам поступила девочка — русые
      длинные волосы, серые глаза, одета в светло-синий свитер и джинсы, возраст —
      около двадцати…</p>

    <p>— Девятнадцать. Вы описывали
      отличительные признаки по телефону, и я в достаточной степени уверен, что это
      действительно одна из моих студенток.</p>

    <p>—&nbsp;Студенток? — врач хмыкнул,
      но не стал распространяться. — Как угодно. Её обнаружили на окраине Хащины, при
      ней не было документов или предметов, которые помогли бы её опознать. Тяжёлая
      черепно-мозговая травма, сломаны несколько рёбер, контузия головного мозга,
      потеря памяти. Девочку нашли на некотором расстоянии от проезжей части, но
      вполне вероятно, что она могла ходить, пока не потеряла сознание. Я бы
      предположил, что её сбило такси. Разумеется, мы немедленно положили её в
      стационар, взяли необходимые анализы и ввели обезболивающие. Когда девочка
      пришла в себя, мы пытались задавать ей вопросы —&nbsp;кто она такая, где учится,
      где работает, живёт ли в Хащине — однако она назвала только одно имя. Ваше.</p>

    <p>— Верно. А я ответил, что у меня
      есть веские основания полагать, что это Брованна Шухер. Вы связались с её
      отцом?</p>

    <p>Капли пота на лбу врача
      неожиданно выросли в размерах. Он истерически затушил папиросу и схватил
      следующую.</p>

    <p>— Девочка была в крайне тяжёлом
      состоянии. Она требовала присутствия.</p>

    <p>— Всей больницы?</p>

    <p>— Ваше предположение было всего
      лишь предположением, я не мог на пустом месте… — врач вдруг уронил голову на
      ладонь. — Леший, паршивые оправдания. Нет, я не известил её отца, потому что…
      Константин Константьевич, вы хоть понимаете, кто вы такой?</p>

    <p>Ройш вопросительно наклонил
      голову.</p>

    <p>— Вы внук хэра Ройша, и вы
      настолько на него похожи, что ваше лицо действительно знает вся страна.
      Известн��, что ваш дед — и ваш отец —&nbsp;занимались политикой. Вы называетесь
      простым преподавателем истории —&nbsp;на истфаке БГУ, это тоже, знаете ли,
      место более чем сомнительное. А потом в телефонном разговоре утверждаете, что
      Брованна — всего лишь одна из ваших студенток. Студентка в околокоматозном
      состоянии зовёт любимого учителя, ага. Это же просто смешно! —&nbsp;врач
      вскочил, отвернулся и тихо продолжил. — И учитель без колебаний едет за ней
      ночью из Бедрограда в Хащину. Простите, это выглядит слишком… подозрительно. Я
      не знаю, кем вы на самом деле друг другу приходитесь, не знаю, при каких
      обстоятельствах она получила травму, и не знаю, в каких отношениях вы с её
      отцом. Не знаю и не хочу знать. Не хочу —&nbsp;уж простите — быть причастным ни
      к какому делу, связанному с Ройшами. Простите ещё
      раз. Мне доводилось сталкиваться с <i>вашим</i> отцом на бюрократическом поприще.</p>

    <p>Как ни посмотри, у фамильного
      наследия и семейной репутации нет никаких плюсов, сплошь минусы.</p>

    <p>—&nbsp;Думаю, я всё-таки позвонил
      бы отцу Брованны. Наверняка позвонил бы… но через
      полчаса после нашего с вами разговора сюда приехали люди из Силового Комитета.
      Лично, без предупреждения. Показали жетоны, сообщили, что забирают её, — врач
      наконец-то отёр пот; говорил он тихо. — Я сказал, что это немыслимо, что в таком
      состоянии транспортировка может привести к летальному исходу. Они показали
      жетоны ещё раз.</p>

    <p>— Ясно, — Ройш чуть заметно
      постучал указательными пальцами, ещё раз покивал своим мыслям. —&nbsp;И как они
      выглядели?</p>

    <p>— Кто?</p>

    <p>— Люди из Силового Комитета,
      которые забрали девушку.</p>

    <p>Врач пожал плечами.</p>

    <p>— Как-как… с пистолетами, в
      кожаных плащах. Приехали на грузовике, уехали на нём же. Тот, который говорил
      со мной, высокий, телосложение крупное, волосы тёмно-русые, короткая стрижка…
      как вообще может выглядеть Силовой Комитет?</p>

    <p>— А что насчёт волос девушки?</p>

    <p>— Что насчёт волос девушки? —
      подпрыгнул врач и посмотрел на Ройша с явным раздражением.</p>

    <p>—&nbsp;Прямые или вьющиеся?</p>

    <p>— Прямые.</p>

    <p>— А телосложение?</p>

    <p>— К чему вы клоните? Это допрос?</p>

    <p>— Вовсе нет, —&nbsp;Ройш
      старательно улыбнулся. —&nbsp;Хочу быть уверен в том, что
речь идёт всё-таки о Брованне Шухер. Если здесь был Силовой Комитет, то
      у близких вашей пациентки, кем бы она ни являлась,
      наверняка есть поводы волноваться. Хочу знать, есть ли они у меня.</p>

    <p>Врач выдохнул, снова осел за
      стол.</p>

    <p>— Простите. Нервы ни к лешему от
      всего этого. Телосложение… — он нахмурился, потом пожал плечами, — нормальное
      телосложение, ничего примечательного.</p>

    <p>Ройш тоже нахмурился и посмотрел
      на врача как можно внимательнее. Было очевидно, что тот нервничал — то ли от Силового
      Комитета, то ли самого Ройша; то ли боится за свою безопасность, то ли терзается
      этическими проблемами.</p>

    <p>Какова вероятность того, что в
      районную больницу города Хащины попала другая девушка, подходящая под данное
      описание и отличающаяся только менее субтильным, чем у
      Брови, телосложением, зовущая Ройша по имени и способная заинтересовать Силовой
      Комитет?</p>

    <p>Явно меньше, чем вероятность
      того, что врач просто путает от волнения.</p>

    <p>— Есть ещё кое-что, — вдруг
      выдохнул тот, —&nbsp;я почти забыл. Одежда Брованны была в крови, её пришлось
      выбросить, но вот… нашли в кармане.</p>

    <p>Врач покопался в ящике, извлёк
      оттуда небольшой прямоугольный кусок бумаги и протянул его Ройшу.</p>

    <p>Фотоснимок. На обороте — знакомым
      размашистым почерком: «Сам он подписывать отказался (думаю, дело в том, что
      писать он умеет только научные статьи и высокохудожественные романы), так что я
      за него», и ниже: «Прекраснодушная Галка, я давно хотел признаться, что от
      всего сердца Вас люблю! Г. О.»</p>

    <p>—&nbsp;Вы сказали, что при
      девушке не было предметов, которые помогли бы её опознать.</p>

    <p>Врач хмыкнул.</p>

    <p>— Это же не значит, что при ней
      не было <i>предметов</i>. Я —&nbsp;и все те
      люди, у которых я успел спросить, —&nbsp;понятия не имею ни кто такая Галка, ни
      кто такой&nbsp;Г. О., ни кто изображён на снимке. А вы знаете?</p>

    <p>— Знаю, — Ройш встал, взял зонт и
      слегка поклонился на прощание. — Спасибо за откровенность. Если вам понадобится
      моя помощь в юридических вопросах — обращайтесь. Девушка, которую забрал
      Силовой Комитет, действительно была Брованной Шухер.</p>

    <p>Она всё собиралась и всё никак не
      могла отвезти подруге из Хащины давно обещанный парадный портрет Габриэля
      Онегина.</p>

    <p>&nbsp;</p>

    <p>Силовой Комитет без приказа
      фаланг не работает — это аксиома. Значит, фаланги так
      или иначе заинтересовались противостоянием Университетской и Бедроградской
      гэбен. В отличие от Максима, Ройш знал, что вопрос о том, на чьей стороне они
      выступают, не имеет смысла: фаланги всегда выступают исключительно на своей
      стороне. Их интересы, разумеется, могут совпасть с чьими-то
      ещё, но с чьими —&nbsp;предугадать практически невозможно. Их основной интерес
      —&nbsp;увеселять хэра Ройша даже после его смерти.</p>

    <p>Знают ли фаланги об эпидемии?
      Вероятность минимальна. Во всех доступных медицинских учреждениях Бедрограда
      —&nbsp;поликлиниках, больницах, институтах — проходят практику старшекурсники с
      медицинского факультета БГУ, и Ройш лично проследил за тем, чтобы все они были
      осведомлены о том, как опознать фалангу. Фаланги не приходили. Есть некоторое
      количество учреждений, подотчётных лично Бедроградской гэбне и, соответственно,
      закрытых для Университета, но Бедроградская гэбня пока что, видимо, хранит
      эпидемию в тайне, а значит, узнать о ней оттуда тоже не удалось бы. Даже если
      предположить, что они получили наводку от Диминого контакта из Медицинского
      Корпуса, Тахи Шапки, есть другой очевидный аргумент, говорящий против того, что
      фаланги знают об эпидемии.</p>

    <p>Эпидемия чумы в Бедрограде — это
      кошмар во всех смыслах. Бедроград — портовый город, критически важная для
      торговли с Европами точка. Если Европы узнают об эпидемии чумы, случится
      огромный внешнеполитический скандал и удар по экономике страны.</p>

    <p>Эпидемия смертельной болезни
      —&nbsp;это пережиток прошлого, отсутствие эпидемий во Всероссийском Соседстве —
      важный элемент пропаганды Бюро Патентов. Если информация о чуме в Бедрограде
      будет предана огласке, случится огромный внутриполитический скандал и гарантированная
      паника по всей стране.</p>

    <p>Проще говоря, представить, что
      фаланги узнали бы об эпидемии и бездействовали, невозможно, равно
      как и представить, что они действовали, но ни Университет, ни Порт этого не
      заметили.</p>

    <p>Медицинская гэбня, в свою
      очередь, имела возможность действовать тайно. Правда, чтобы что-то найти, надо
      что-то искать, но, положим, у них тоже есть доступ к Тахе Шапке. Положим даже,
      что кто-то из заражённых водяной чумой попал в закрытое медицинское учреждение
      Бедроградской гэбни, что был-таки обнаружен
      неизвестный вирус и что эта информация каким-то образом попала к Медицинской
      гэбне —&nbsp;в конце концов, многих сотрудников многих учреждений можно
      перекупить. Тахе Шапке известна формула вируса, но только Дима знает, откуда
      брать для него сырьё, и Медицинская гэбня не могла бы упустить возможность
      задать ему пару вопросов. Поскольку Дима резко (и оправданно) против того,
      чтобы Медицинская гэбня в принципе знала о чуме — он
      пошёл на большие ухищрения, чтобы этого избежать, — он вряд ли содействовал бы
      им добровольно, и уж тем более втайне от Университета. Разумеется, любого
      человека можно шантажировать, запугать и даже убедить, но Диму, по всей
      видимости, они не искали и не задерживали, с ним всё в порядке, несмотря на
      непредусмотрительное отсутствие охраны. Значит, довольно сомнительно, что Медицинская гэбня что-то знает.</p>

    <p>То есть об эпидемии не знает
      никто. Хорошо это или плохо?</p>

    <p>И те, и другие могли бы пресечь
      эпидемию. Однако если бы это сделала Медицинская гэбня, она
получила бы в своё распоряжение содержащиеся в водяной чуме элементы, которые
могли бы привести их к разработке формулы степной чумы, что чревато печальными
последствиями как минимум государственных масштабов: у Медицинской гэбни
какая-то мания вывести степную чуму, и никто не знает, что они сделают, если
добьются своего. Даже если представить, что цели их глубоко
      гуманистические и лишены политического смысла (что явно не так), фаланги
      возненавидели бы того, кто дал бы в руки Медицинской гэбни такое оружие.</p>

    <p>Если бы эпидемию пресекли сами
      фаланги, последствия для Университета были бы не менее печальны. Положение Университетской гэбни и так недостаточно твёрдо. Полностью
      отрицать свою причастность к эпидемии Бедроградской гэбне вряд ли удалось бы;
      фаланги обычно умеют достаточно правдиво выяснять обстоятельства. Разобравшись
      в конфликте, они с наибольшей вероятностью применили бы санкции к обеим
      сторонам — просто потому, что держать в одном городе две гэбни, способные
      породить скандал такого масштаба, слишком опасно. И если Бедроградскую гэбню
      просто перенабрали бы, у Университетской вполне были бы шансы прекратить своё
      существование в принципе —&nbsp;в конце концов, она возникла только за счёт
      авторитета и уровня доступа ныне покойного Хикеракли.</p>

    <p>Так что чем дольше информация об
      эпидемии останется тайной, тем лучше. Хотя бы до того момента, как Университет
      сделает лекарство.</p>

    <p>Со спины Ройша нагнало такси с бедроградскими номерами; аккуратно, чтобы не
      окатить водой
      из лужи, проехало мимо. От долгой и непривычной ходьбы у него устали ноги, но
      ловить такси было бы бесконечной глупостью: все обычные такси города Бедрограда
      принадлежат Бедроградской гэбне. Свои такси есть и у Университета, и у Порта, и
      у фаланг, но университетских и портовых такси немного, в Хащине посреди ночи им
      делать нечего, а фалангу вряд ли стоит просить подбросить до станции. Значит,
      это самое обыкновенное такси для гражданских.</p>

    <p>Такси могут быть опасны.</p>

    <p>То, что произошло с Бровью,
      действительно похоже на несчастный случай. У неё были поводы оказаться в
      Хащине; она не участвовала в твири-в-жопу (Ройш не уточнял ни у кого лично, но
      ознакомился со списком задействованных студентов). Она достаточно импульсивна,
      чтобы поехать почти ночью (да и неизвестно, сколько времени прошло до тех пор,
      пока её нашли); кровь, предположительно залившая всю одежду, могла не попасть
      на фотографию просто случайно; с телосложением врач мог напутать. Все допущения
      — как раз в рамках реальной жизни, ничего сверхъестественного.</p>

    <p>Просто Ройшу было неуютно от
      такого количества допущений.</p>

    <p>Раз фотографию обнаружили в
      кармане — то, скорее всего, до своей подруги Бровь добраться не успела,
      проверять почти бессмысленно. Силовой Комитет работает грубо, но исполнительно,
      а Бровь — критически важный свидетель, они не допустят летального исхода при
      транспортировке. Значит, по крайней мере её жизнь на
      ближайшее время в безопасности.</p>

    <p>Это уже что-то.</p>

    <p>Ей окажут лучшую помощь, чем смог
      бы Ройш, если бы успел.</p>

    <p>Это всё — просто факты, элементы
      общей картины. Фаланги обратили внимание на конфликт гэбен. Это значит, что
      нужно действовать как можно быстрее и как можно быстрее обозначить официальную
      позицию Университетской гэбни. Завтра (то есть уже
      сегодня) же вечером, как только будет сделана первая порция лекарства,
      необходимо организовать встречу двух гэбен, официально запротоколированную,
      четыре на четыре, и объявить, что эпидемия обнаружена, лекарство найдено.
      Бесконечно скрывать чуму не удастся, студенческой крови на весь Бедроград не
      хватит. Лучшее, что можно сделать, — это официально запросить помощи
      Бедроградской гэбни в борьбе с вирусом. Откажутся — Университет в выигрыше, с
      записью переговоров и отказа можно идти прямиком к фалангам и просить
      содействия (исключительно технического) прямо у них; согласятся — ситуация
      ближе к паритетной, но Университет всё равно в
      выигрыше просто потому, что не проиграл, а риски для него были значительно
      выше.</p>

    <p>В конечном итоге победит тот, кто
      первым позволит себе донести до фаланг информацию об эпидемии вместе с планом
      решения этой проблемы.</p>

    <p>Есть даже некоторая вероятность
      того, что Максим каким-то мистическим образом всё-таки сумел впечатлить фаланг
      существующими доказательствами агрессивных намерений Бедроградской гэбни. Это
      несколько меняет картину ситуации, но размышлять об этом без фактов
      —&nbsp;бессмысленно.</p>

    <p>Ройш посмотрел на часы, висевшие
      над перроном.</p>

    <p>5:02.</p>

    <p>Значит, на самом деле идёт уже не
      пятый, а шестой день эпидемии.</p>

    <p>Как только Ройш вернётся в
      Бедроград, он первым же делом свяжется с Максимом.</p>

    <p>Ройш никогда не был головой гэбни
      и не намеревался им становиться, но иногда фактическая синхронизация бывает полезна
      и обычным преподавателям истории с
      четырнадцатым уровнем доступа.</p>
  </>
}

export default chapter12
