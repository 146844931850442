import React from 'react'
import { IBookPage } from './index'

const chapter31: IBookPage = {
  header: 'header_d9.jpg',
  title: 'Глава 31. Максим, купи скопца',
  prevLink: 'chapter30',
  nextLink: 'chapter32',
  content: <>
    <h2>Университет. Охрович и Краснокаменный</h2>

    <img alt='' src="/images/31_okhrovich_i_krasnokamennyi.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }}/>
    <p>Дома обнаружился Максим!</p>

    <p>&nbsp;</p>

    <p>Чему-то Дима у
Гуанако всё-таки научился: <b style={{ color: '#C00000' }}>дешёвым приёмчикам</b>. У
      него — браслет,
      позволяющий не спать. У Охровича и Краснокаменного — отсутствие браслетов.</p>

    <p>Но Охрович и Краснокаменный были
      ПРИСТАВЛЕНЫ, и они не отставились.</p>

    <p>Ночь выдалась пресыщенной. Сперва приехали первые грузовики. Охрович и
      Краснокаменный
      водили их до Порта, закинув Диму в кузов. Потом Его Димейшество
      изволил ещё немного поработать. Охрович и Краснокаменный саботировали. Дима был
      упорен. Охрович и Краснокаменный поставили рекорд по сбору пасьянсов.</p>

    <p>Вслепую!</p>

    <p>Потом наступила совсем ночь, и
      труды вечера обратились в трупы.</p>

    <p>Весь вечер Охрович и
      Краснокаменный (с Димой в кузове) искали, куда приткнуть Шухера. Результат был
      несовершенен: квартира в Старом городе, под ключ снаружи, охрана — не охрана, а
      пара наблюдателей из достойнейших представителей лингвистического факультета.</p>

    <p>Там самые нормальные.</p>

    <p>Охрана — не охрана, а чтоб не
      сбежал. И не помер (от удара в сплетение ему-де сделалось нехорошо на весь
      вечер).</p>

    <p>А часов в пять утра в Димину
      каморку прибежал (лично!) мятый со сна Ларий. Сказал,
      что на кафедру позвонили. Сказал, что у Шухера там какая-то потасовка.</p>

    <p>Заперев на ключ уже Диму, Охрович
      и Краснокаменный метнулись проверить. <b style={{ color: '#C00000' }}>А толку.</b> Охрана — не охрана, а нормальные
      парни с лингвистического факультета, которые разумно не подставляются под
      младших служащих Бедроградской гэбни.</p>

    <p><span style={{ fontSize: '18.0pt' }}>+:</span> не зря прятали, выходит.</p>

    <p><span style={{ fontSize: '18.0pt' }}>–:</span> помер Шухер.</p>

    <p>ТАКИ.</p>

    <p>Потому что (!!)
решил оказать сопротивление. Один раз, зато его уж не допросят. Охрович
      и Краснокаменный сперва не поверили парням с
      лингвистического факультета, но потом поверили.</p>

    <p>Схватил, значит, какую-то
      табуретку и попёр.</p>

    <p>Ну и от первого ответного удара и
      всё.</p>

    <p>Бедроградские младшие служащие
      так растерялись, что чуть ли не с извинениями ушли.</p>

    <p>Просветлился (свихнулся-таки?),
      значит, Шухер, да и помер.</p>

    <p>С табуреткой в руках и героизмом
      в душе.</p>

    <p>ДоСтОйНаЯ
      кОнЧиНа.</p>

    <p>Диме Охрович и Краснокаменный
      говорить не стали. Он сам всё понял и вопроса этого не поднимал. Мало говорил,
      много ковырялся в своих журналах. Искал ещё идеи для умасливания Порта. Утомил.</p>

    <p>Идей не нашёл, поэтому Охрович и
      Краснокаменный отвезли в Порт его самого. С ящиками свежеизобретённой
      алкогольной смеси. Пусть умасливает лично.</p>

    <p>Охрович и Краснокаменный забежали
      домой на пару часиков — поспать. У них браслетов нет, всё натуральное.</p>

    <p>Натурально неумасленный
      Максим обнаружился.</p>

    <p>И с ним же надо что-то делать!</p>

    <p>Охрович и Краснокаменный походя лягнули ножки его стула, чтоб проснулся. Чистая
      формальность. Ничего личного, но от одной ночи висения на верёвках в такой позе
      случается сколиоз.</p>

    <p>Не был бы Максим Максимом —
      уронил бы стул набок и поспал с удобствами!</p>

    <p>Он проснулся сразу, от тычка. Набыченно посмотрел
      на
      Охровича и Краснокаменного. Поёрзал в своих путах.</p>

    <p>не ну а чо максима слишком крепко привязать
      невозможно</p>

    <p>Охрович и Краснокаменный навострились было на кухню, да пленник голос подал:</p>

    <p>— Развяжите меня, — неловко,
      будто за полтора дня говорить разучился. — Пожалуйста.</p>

    <p>Экий <i>радикальный элемент</i>-то! Аки в
      студенческие годы. То контрреволюция, то развяжите его, пожалуйста.</p>

    <p>— А мы надеялись, что ты умер от
      голода, —&nbsp;скучно сообщили Охрович и Краснокаменный.</p>

    <p>— Или, что лучше, от печали.</p>

    <p>— Ты думаешь, что вправе
      выдвигать требования?</p>

    <p>— Просить просьбы?</p>

    <p>— Говорить разговоры?</p>

    <p>— Или тебе у нас неуютно?</p>

    <p>— Пожалуйста, —&nbsp;Максим
      прибавил нажима, дёрнулся, прорвал бумагу и оставил кляксу. — Я сижу уже больше
      суток. — Помолчал и отчеканил одними зубами: — Мне нужно в сортир.</p>

    <p>Обнаружена <b style={{ color: '#C00000' }}>честность</b>!</p>

    <p>И недюжинная сила духа (ведь
      правда больше суток сидит).</p>

    <p>ВЕДЬ
      ПРАВДА ЖЕ</p>

    <p>Охрович и Краснокаменный повели
      недоверчивыми и по-грифоньи чуткими носами. Воздух
      еле заметно пах ёлочкой.</p>

    <p>ПРЯМО КАК ИХ ТУАЛЕТНЫЙ ОСВЕЖИТЕЛЬ</p>

    <p>Мог ли Максим провернуть такую
      операцию: выбраться из верёвок, сбегать в сортир,
      примчаться на место и всех обмануть?</p>

    <p>Или это просто сосед-походник возвратился из очередного путешествия с
      ёлочкой и поставил её на соседний походный балкон?</p>

    <p>Вероятно, второе.</p>

    <p>Скорее второе.</p>

    <p>— Соскучился по Габриэлю
      Евгеньевичу? — бросили Охрович и Краснокаменный.</p>

    <p>— Какая жалость, что теперь он
      ждёт тебя только в сортире.</p>

    <p>— Какая странность, что ты не
      спросил о нём первым же делом.</p>

    <p>— Возвышенные отношения пали
      жертвой низменных плотских желаний?</p>

    <p>— Это ничего, у него наверняка
      тоже.</p>

    <p>— Желания, правда, были не его,
      но рядом не оказалось никого, кто мог бы защитить.</p>

    <p>— Так что мы вполне понимаем твоё
      стремление довольствоваться малым.</p>

    <p>— Сортир
      на пять минут твой, кто успел подрочить — тот молодчик.</p>

    <p>Максим был автопоездом, из
      которого спешно сгинули пассажиры. Пустым, железным и
      страшным при определённом освещении. Пока Охрович и Краснокаменный ослабляли
      верёвки, Максим не сказал ни слова и не пошевелился. Его почти не задевало.</p>

    <p>Охровича и Краснокаменного почти
      не задевало, что его не задевало.</p>

    <p>скука</p>

    <p style={{ fontSize: '14.0pt' }}>желание
спать</p>

    <p style={{ fontSize: '16.0pt' }}>пусть
бежит себе</p>

    <p style={{ fontSize: '18.0pt' }}>традиционно</p>

    <p>И он побежал, почти вприпрыжку.
      Низменные плотские желания всегда сильнее любой силы воли.</p>

    <p>Снизу-то точить проще, чем сверху
      давить.</p>

    <p>&nbsp;</p>

    <p><i>О где, о где они теперь?</i></p>

    <p><i>Не быть мне больше молодым.</i></p>

    <p><i>О кто, о кто мог угадать,</i></p>

    <p><i>Что будто дым истают дни?</i></p>

    <p>&nbsp;</p>

    <p>Муля Педаль и обилие его
      академической музыки в такси вынудили какой-то романсик
      прицепиться к Охровичу и Краснокаменному, так что теперь они его периодически
      голосили.</p>

    <p>А кроме
      того, явно актуальная поэзия.</p>

    <p>И к лешему ваших «фигурантов»,
      есть потери посерьёзнее. УСИКИ ЗОЛОТЦА. Французские,
      тонкие, фигурные, невыносимые, незаменимые! Охраняемый объект (*Дима), впрочем,
      под утро хмуро вытряхнул из недр себя аналогичные (*которые счёл аналогичными
      он). На самом деле, конечно, негодные: слишком чёрные и неканоничной
      формы. И материал так себе — ЯВНО НЕ ИЗ НАСТОЯЩИХ ВОЛОС (*оскорбление!). Всем
      хорошо Всероссийское Соседство, но вот парики до сих пор в Европах делают лучше.
      Увы. Прости, Отечество.</p>

    <p>Когда Охрович и Краснокаменный
      достаточно отчитали Диму за отсутствие вкуса, меры и степени, он их неожиданно <i>уел</i>. Поклялся алкогольной бодягой для
      Порта, что в этих неидеальных усиках пропадал по неблагонадёжным заведениям сам!
      Габриэль! Евгеньевич!</p>

    <p>Охрович и Краснокаменный восхищённо
      уелись.</p>

    <p>Усики,
заразившие Порт, были немедленно назначены Временными Исполняющими Обязанности Золотцевых.</p>

    <p>Даритель был временно (и милостиво!)
      избавлен от общества Охровича и Краснокаменного путём транспортировки в
      заражённый Порт. Ах, он ведь так хотел от них отдохнуть, он наконец-то заслужил
      и отработал исполнение своих желаний!</p>

    <p>А Охрович и Краснокаменный
      заслужили несколько часов сна.</p>

    <p>И — почему-то — откусывающего
      драгоценные секунды сна Максима в своём сортире.</p>

    <p>Максим, впрочем, управился быстро
      — ни чайник, ни Охрович и Краснокаменный не успели вскипеть. Вошёл на кухню,
      загородил спиной дверной проём, впеееерился.</p>

    <p>Охрович и Краснокаменный говорить
      с ним не хотели, они хотели чай и спать.</p>

    <p>— Вы знаете, что с Габриэлем, —
      полез Максим с утверждениями-которым-полагалось-быть-вопросами.</p>

    <p>— Мы — да, а ты нет, — неохотно
      отозвались Охрович и Краснокаменный.</p>

    <p>— И в этом разница между нами.</p>

    <p>— В этом, и ещё в том, что мы
      по-прежнему головы Университетской гэбни, пусть и временно отстранённые.</p>

    <p>— И в том, что мы бесчеловечно
      жестоки.</p>

    <p>— И нас двое.</p>

    <p>— В общем, Максим, у нас с тобой
      не так-то много общего, а?</p>

    <p>— Что с Габриэлем? — не покинул
      колею Максим.</p>

    <p>Охрович и Краснокаменный с ленцой
      закурили.</p>

    <p>Максим без вагоновожатого —
      зрелище эффектней любой черёмухи.</p>

    <p>Куда ты пойдёшь, Максим?</p>

    <p>Что ты будешь делать, Максим?</p>

    <p>Сколько атмосфер давления есть в
      твоих челюстях, чтобы настолько крепко сжать зубы, Максим?</p>

    <p>— <i>О где, о где завкаф теперь?</i> — провыли
      Охрович и Краснокаменный.</p>

    <p>— Габриэль Евгеньевич в
      Медицинском Корпусе.</p>

    <p>— Сейчас — почти уже наверняка.</p>

    <p>— Там ему помогут.</p>

    <p>— Там его будут исследовать.</p>

    <p>— Залезут во все тёмные уголки,
      выяснят все сокровенные тайны.</p>

    <p>— Естественнорожденный,
      сын женщины, пьёт таблетки, чтобы не свихнуться, столько лет курит, но не
      подсаживается. Обширное поле для экспериментов.</p>

    <p>— И невеликая плата за исцеление,
      правда?</p>

    <p>— Дима всё сделал правильно, Дима
      вколол ему лекарство.</p>

    <p>— Дальше всё сложилось само
      собой.</p>

    <p>— Габриэль Евгеньевич потерял
      только товарный вид, но он в него вернётся.</p>

    <p>— Когда-нибудь.</p>

    <p>— Где-нибудь в Столице.</p>

    <p>— Возможно, Габриэль Евгеньевич
      потерял не только товарный вид, но и рассудок. Только мы этого не узнаем.</p>

    <p>— Но всё будет хорошо, Максим, не
      переживай.</p>

    <p>— Не о чем беспокоиться, просто
      двигайся дальше.</p>

    <p>Максим пасмурно посмотрел на
      сигареты, но не осмелился взять и не попросил.</p>

    <p>Ничему, ни-че-му
      не научился!</p>

    <p>— Вы собираетесь меня снова
      связать? — спросил он голосом цвета рубашек фаланг <i>(— сереньким таким)</i>.</p>

    <p>— Ты руки после сортира не помыл, животное.</p>

    <p>— Мы устали и хотим хоть немного
      поспать.</p>

    <p>— У нас было много дел, а будет
      ещё больше.</p>

    <p>— У тебя есть двадцать метров
      розового плюша?</p>

    <p>— Если нет, не докучай нам.</p>

    <p>— Иди
      займись чем-нибудь полезным.</p>

    <p>Грузная, тяжёлая усталость у
      Максима — ему плохо, когда его не держат. Не связывают. Не говорят прямо, что
      можно, а что нельзя.</p>

    <p>— Я не знаю, куда мне идти, —
      прогудел Максим в сторону, с неприязнью к себе. — И не понимаю, почему вы
      настолько быстро от меня отказались. Я правда думал,
      что все эти годы что-то — ну хоть что-то — значат, и что мы знаем друг друга, и
      что, когда я оступлюсь, мне если не протянут руку, то хотя бы не станут
      топтать.</p>

    <p>— Мы тоже что-то такое думали,
      Максим.</p>

    <p>— Нам было обидно занимать в
      твоём сердце место сразу за Габриэлем Евгеньевичем, но мы смирились.</p>

    <p>— Головы гэбни прощают друг другу
      слабости, не так ли?</p>

    <p>— Мы не злимся на то, что ты
      бросил нас, гэбню, Университет и судьбы Бедрограда ради Габриэля Евгеньевича.</p>

    <p>— Мы злимся на то, что и его ты
      на самом деле не любишь.</p>

    <p>— Ты любишь образ себя,
      спасающего Габриэля Евгеньевича.</p>

    <p>— Если бы всё вышло чуть менее некрасиво, мы бы заподозрили, что ты сам его
      заразил,
      чтобы потом спасти.</p>

    <p>— А тут спасти посмел кто-то
      другой, и ты злишься.</p>

    <p>— Кто-то другой крутится,
      разбираясь с чумой, и ты злишься.</p>

    <p>— Кто-то другой сидит на
      брошенном тобой месте в гэбне, и ты злишься.</p>

    <p>— И в итоге, Максим, мы не можем
      понять: что же тебе дорого, кроме твоей гордости?</p>

    <p>— Гордость полезна только в полуэротических рассказах про пытки
      и расстрелы, а в остальных случаях её следует вытравливать.</p>

    <p>— Мы не отказались от тебя,
      Максим. Мы хотим тебе помочь. Умерить твою гордость.</p>

    <p>— Взрастить смирение.</p>

    <p>— Мы не держим на тебя личной
      обиды.</p>

    <p>— Ничуть.</p>

    <p>— Никакой.</p>

    <p>— Наши действия носят
      исключительно стратегический характер и направлены на твоё же благо.</p>

    <p>— Чтобы в будущем мы снова могли
      дружить.</p>

    <p>— Ты же хочешь снова дружить?</p>

    <p>Сейчас Максим хотел
      преимущественно наорать и ударить (в меру сил). Но промолчал. Поморщился,
      проглотив несказанные слова. Вопросительно протянул руку за сигаретой.</p>

    <p>Охрович и Краснокаменный могли
      сделать ему легче, кивнув.</p>

    <p>Но не стали.</p>

    <p>Исключительно стратегический
      характер и никакой личной обиды.</p>

    <p>— Я хочу хоть что-то исправить, —
      закурил Максим. — Я <i>могу</i> хоть что-то
      исправить. Скажите мне, как. Вы можете?</p>

    <p>— Ты можешь, например, помыться.</p>

    <p>— Когда ты в последний раз мылся?</p>

    <p>— Отоспаться и повторить вопрос
      на трезвую голову.</p>

    <p>— В полном смысле трезвую, без твиревой настойки и прочих вспомогательных
      средств борьбы с чумой.</p>

    <p>— Ты не мастер спринта, не беги
      за ушедшим автопоездом.</p>

    <p>— Извинись перед теми, перед кем виноват.</p>

    <p>— Покайся в том, в чём согрешил.</p>

    <p>— Очисти разум и умиротвори душу.</p>

    <p>— И больше ничего не делай
      сгоряча.</p>

    <p>— С тем, кто и перед кем виноват,
      я сам разберусь, —&nbsp;клацнул зубами Максим, — но не с тем, что делать. Разве
      не ясно? Я больше не хочу непредсказуемых последствий.</p>

    <p>— Сколько разумности и
      скромности! —&nbsp;восхитились Охрович и Краснокаменный.</p>

    <p>— Ты рассудителен и здрав.</p>

    <p>— Осторожен,
      но полон благих намерений.</p>

    <p>— Это
      правда, всё так и есть? Ты решил оставить гордыню и побыть немного мальчиком на
      побегушках, которого никто не упомянет в списках
      важных действующих лиц?</p>

    <p>— Сделать что-нибудь ради пользы,
      а не славы, громкой ответственности и признания Габриэля Евгеньевича?</p>

    <p>— Ты решил подумать о других?</p>

    <p>— Я решил перестать закрывать
      глаза на ответы, — Максим смотрел плите промеж конфорок и видел там свои
      ответы, они прям отражались в глазах. — У меня было
      достаточно времени, чтобы подумать о других. И о себе. Вообще — подумать.</p>

    <p>— И что ты надумал?</p>

    <p>— Расскажи, мы поставим тебе
      оценку в ведомость.</p>

    <p>— Что хватался за слишком многое
      и потому самое важное пустил на самотёк, — отчеканил сумрачно. — А должно быть
      наоборот. Я <i>хочу хоть что-нибудь
        исправить</i>. Для этого — начать с простого и очевидного, — усмехнулся
(всё так же сумрачно). — Мне и правда не помешает помыться.</p>

    <p>Охрович и Краснокаменный
      сомневались в целительной силе сидения в комнате. Так НЕ МЕНЯЮТСЯ. Всегда нужны
      дополнительные воздействия. Охрович и Краснокаменный любят применять
      дополнительные воздействия. Но если Максим сумел сделать первый шаг до того, как
      его отвязали от стула, то будущее прекрасно.</p>

    <p>Потерявши Габриэля Евгеньевича,
      он выплакал наружу половину внутреннего мяса. Оставшееся тело не изменилось в
      объёмах, но стало пустоватым и проседать. От этого Максим двигался не медленней,
      но осторожней и бессильней.</p>

    <p>Такой может толкнуть, а ударить
      наотмашь — нет.</p>

    <p>Такой не может быть Максимом.
      Максиму надо бить наотмашь, иначе он засохнет в вазочке под зеркалом. Поэтому
      внутри него в противовес начало формироваться что-то маленькое, тяжёлое и
      металлическое. Чтобы не терять живую массу.</p>

    <p>Максим сделал первый шаг. Внутри
      у него зазвенело.</p>

    <p>Охрович и Краснокаменный допили
      свой чай. Докурили свои сигареты.</p>

    <p>Зазвенело за дверью.</p>

    <p>Максим дёрнулся.</p>

    <p>— Ты прав, наш грязный друг, это
      за тобой.</p>

    <p>— Мы не единственные, кто не
      отчаялся на твой счёт.</p>

    <p>— Гуанако просил твоей помощи в
      каких-то сегодняшних делах.</p>

    <p>— Серьёзных. Наверняка подразумевающих насилие.</p>

    <p>— Так что мы, разумеется, не
      отдадим тебе твой пистолет, поскольку он объявлен испорченным.</p>

    <p>— Это бюрократически опасно.</p>

    <p>— Что может быть опаснее
      бюрократии?</p>

    <p>— Один Ройш стоит сотни александров.</p>

    <p>— Сейчас самое время возмутиться,
      что мы отпускаем тебя только по просьбе Гуанако.</p>

    <p>— Это неправда. Мы бы вышвырнули
      тебя в холодный безразличный мир в любом случае.</p>

    <p>— Ты спрашивал, что тебе следует
      делать?</p>

    <p>— Выйди навстречу таксисту, сядь
      в такси и поезжай к Гуанако.</p>

    <p>— Он скажет, что тебе делать.</p>

    <p>— Он знает, как ты можешь «хоть
      что-нибудь исправить».</p>

    <p>— Он хочет, чтобы ты «хоть
      что-нибудь исправил».</p>

    <p>— Он — разумеется! — готов дать
      тебе шанс.</p>

    <p>— Шанс, понимаешь?</p>

    <p>— Шанс поступить правильно.</p>

    <p>— Шанс найти себе место.</p>

    <p>— Возможно, не последний,
      но ближайший в обозримом будущем.</p>

    <p>— Всё в твоих руках.</p>

    <p>Максим кивнул и не пошёл к двери.
      Должен был пойти и не пошёл. Потом ещё сколько-то не пошёл, а потом пошёл.</p>

    <p>Совсем пошёл.</p>

    <p>— Максим! — окликнули его Охрович
      и Краснокаменный. Он держался за ручку двери, поэтому ему пришлось
      разворачиваться всем корпусом. Медленно.</p>

    <p>М-Е-Д-Л-Е-Н-Н-О.</p>

    <p>Неожиданно для себя Охрович и
      Краснокаменный высказались не сразу.</p>

    <p>— Максим, купи скопца, — искренне
      попросили они после короткого молчания.</p>

    <p>— В смысле? — очень серьёзно переспросил
      тот.</p>

    <p>Хммм, хммм, в самом деле, что же может означать
      «купи скопца»?</p>

    <p>Это какой-то тайный шифр?</p>

    <p>Аббревиация?</p>

    <p><b style={{ color: '#C00000' }}>Осёл.</b></p>

    <p>Поймёт или не поймёт, ведает
      только монетка.</p>

    <p>Грифон — поймёт (грифоны
      понятливы). Решка — не поймёт (решки непонятливы).</p>

    <p>— Пусть это останется одной из
      загадок вселенной, — ответил Охрович.</p>

    <p>— Встретимся, когда ты её
      разгадаешь, — ответил Краснокаменный.</p>

    <p>Максим щёлкнул дверным замком.</p>

    <p>Охрович и Краснокаменный хотели спать и чтобы Максим купил скопца.</p>

    <p>Очень хотели, чтобы Максим купил
      скопца.</p>

    <p>Скопцов.</p>

    <p>СКОПЦОВ</p>

    <p style={{ fontSize: '16.0pt' }}>СКОПЦОВ НА КАФЕДРЕ!</p>

    <p>Охрович и Краснокаменный так
      резво метнулись к тюкам с кафедральным имуществом, что проснулись в полёте.</p>

    <p>Кафедральное революционное чучело
      <i>не было переодето</i>.</p>

    <p>Невозможная, невыносимая
      оплошность. Забегались ночью с шухерами и димами. Купились на усики. Хотели
      спать. А теперь уже восемь утра нового дня, а на кафедре по-прежнему Скопцов.</p>

    <p>Если Скопцов провисит ещё день,
      это ж сколько будет скопцов!</p>

    <p>— Можем ли мы оставить всё как
      есть и тем повысить шансы Максима? — вопросил Охрович.</p>

    <p>— Шансами Максима сегодня
      заведует Гуанако. И он сам. Негоже пользоваться кафедральным чучелом по таким
      мелочам.</p>

    <p>— И потом, никакой Максим не
      стоит атаки орды скопцов.</p>

    <p>— Кидаем?</p>

    <p>Охровичу и Краснокаменному стало
      не по себе (не по двум себям). Выпадет хэр Ройш —
      придётся немедленно мчаться на кафедру.</p>

    <p>Не все представители
      Революционного Комитета терпят шуток.</p>

    <p>А так хотелось поспать.</p>

    <p>Чтобы монетка не простаивала (её
      тоже негоже расчехлять впустую), они прокинули оружейную принадлежность.</p>

    <p>Головам Университетской
      гэбни до завтрашнего утра запрещено носить оружие. Но есть оружие, а есть
      исторические артефакты.</p>

    <p>Обрез Твирина (сегодня —
      Охровичу).</p>

    <p>Револьвер Золотца (сегодня —
      Краснокаменному).</p>

    <p>Официально хранившиеся на кафедре
      истории науки и техники все эти годы.</p>

    <p>По-прежнему рабочие <i>(кому нужны нерабочие ловушки?)</i>.</p>

    <p>— Чучело всё равно надо
      прокинуть. Выпадет хэр Ройш — поедем прямо сейчас, такова судьба.</p>

    <p>— Поберегись, кидаю. Решка, решка, решка, грифон.</p>

    <p>— Хикеракли! Это к хорошей
      погоде, веселью и примирению.</p>

    <p>— А также к неожиданным поступкам
      в адрес ближнего своего, чьему-нибудь безумию и
      бегству из политики. Ваше экспертное мнение относительно необходимости
      поспешности?</p>

    <p>— Сложный вопрос, коллега!
      Хикеракли — это не хэр Ройш, но и не опальный
      Гныщевич.</p>

    <p>— Он наш с вами политический
      батюшка, коллега. Насколько мы ценим наследственность?</p>

    <p>— Часа на два.</p>

    <p>— На полтора, пожалуй.</p>

    <p>— Включая дорогу до кафедры?
      Потому что если включая, то можно уже и не ложиться.</p>

    <p>— Не ложиться нельзя, коллега. Мы
      с вами, позволю себе заметить, выглядим как дохлые
      дикобразы.</p>

    <p>— И эффективность наша примерно
      такая же, соглашусь.</p>

    <p>Охрович и Краснокаменный выкопали
      из тюков всё необходимое для чучела Хикеракли. И — <span style={{ fontSize: '18.0pt' }}>НАКОНЕЦ-ТО</span> — пали.</p>

    <p>Полтора часа, каждая секунда
      которых будет использована с умом.</p>

    <p>Без ума.</p>

    <p>Во сне.</p>

    <p>Спальное место (с-ПАЛ-ьное) находилось в комнате с балконом, и запах ёлочки
      снова встревожил ускользающие умы. Сосед-походник
      безумен. Сосед-походник посреди чумы и политики
      притащил на балкон (свой, смели надеяться Охрович и
      Краснокаменный) ёлочку, которая на них теперь пахла.</p>

    <p>А с другой стороны, Максим тоже
      способен удивить.</p>

    <p>Максим вполне мог выбраться из
      верёвок только затем, чтобы тайно пробраться в сортир
      с еловым освежителем и потом незамеченным вернуться.</p>

    <p>И никто бы не догадался.</p>
  </>
}

export default chapter31
