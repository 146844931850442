import React from 'react'
import { IBookPage } from './index'

const epilogue: IBookPage = {
  header: false,
  title: 'Эпилог',
  prevLink: 'chapter38',
  nextLink: false,
  content: <>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img alt='' src="/images/epilogue.jpg" />
    </div>
    <br/><br/>
    <p><i>Дорогая Бровь!</i></p>

    <p><i>Я не писал от руки уже добрых полгода, всё сплошь на печатной машинке,
      и вот, полюбуйся, что ты со мной делаешь. Писем я не писал вообще никогда, так
      что, извини, особенностей жанра не знаю. Обязательно ли мне полагается
      осведомиться о здоровье — твоём и батюшки? Полагаю, нет. Ну, надеюсь, а то
      вышло бы как-то немного глупо — с учётом того, что вы оба умерли.</i></p>

    <p><i>Тут невольно хочется заметить, что письма покойникам, как и вообще
      любая литературная деятельность, куда лучше подходит Габриэлю Евгеньевичу, чем
      мне, но он, к сожалению, временно недоступен. А может, и не временно.
      Всероссийская медицина — это хтоническое чудовище, которое проглатывает всех
      причастных с ногами, я знаю, я там был. Виктор Дарьевич столь добр и щедр,
      почти и без оговорок, что довольно сложно всегда держать в голове тот простой
      факт, что из Медицинского корпуса не шибко-то выбираются невредимыми.</i></p>

    <p><i>Нда.</i></p>

    <p><i>Актуальная для тебя информация.</i></p>

    <p><i>В общем, я это всё к чему: помнится, когда-то давно велись какие-то
      речи про шпионский роман с главной героиней. Шпионские (как и любые другие,
      прости леший) романы я писать не умею, но базовым навыкам драматургии Охрович и
      Краснокаменный (о где, о где они теперь?) меня обучили. Дальше списка
      действующих лиц пока не продвинулся, но, короче, не выпендривайся и радуйся
      тому, что дают, всё равно никто другой этим заниматься не станет.</i></p>

    <p>&nbsp;</p>

    <p>На лестнице послышались шаги, и
      Дима тут же воровато спрятал листы под рубашку. Будучи человеком умным, а также
      весьма коварным, он засел не в каморке Святотатыча, куда постоянно кто-нибудь
      вваливался, а в каморке напротив (тем более что там даже имелся стол нормальной
      высоты). Он не то чтобы стеснялся своего занятия, просто нечего сбивать с
      полёта эпистолярной мысли.</p>

    <p>Шаги потыкались в дверь напротив
      и позвали Диму по имени.</p>

    <p>Он решил пока что сделать вид,
      что его тут нет.</p>

    <p>&nbsp;</p>

    <p><i><u>Действующие лица шпионского романа</u>, название которого я пока не
      придумал:</i></p>

    <p><i>&nbsp;</i></p>

    <p><i>Бровь — главная героиня. Юна, свежа, хороша собой, трагически и
      неожиданно гибнет где-то в первой половине действия, но до самого конца
      остаётся в сердцах окружающих и вдохновляет их на великие деяния.</i></p>

    <p><i>Ройш — избранник главной героини. Высок, загадочен, обладает тем
      неизъяснимым магнетизмом, который в народе называют «и чё она в нём нашла-то».
      После гибели Брови имел возможность переосмыслить многое в своей жизни и
      получить закономерное развитие персонажа, но успешно этого избежал, временно
      заняв завкафское кресло.</i></p>

    <p><i>Шухер — отец Брови. Сперва поступил не очень хорошо, потом не очень
      хорошо, потом ещё разок, а потом взял табуретку и помер от слабого
      сердца.</i></p>

    <p><i>Регалия Шепелогель — подруга Брови. В действии особо не участвует, но
      некоторое время о ней почему-то много говорят.</i></p>

    <p><i>Габриэль Евгеньевич Онегин — бывший заведующий кафедрой истории науки и
      техники, именитый всероссийский писатель. Умён, утончён, хорош собой, за что
      был бит, осмеян, заражён чумой и теперь, кажется, долечивается в Медицинском
      корпусе.</i></p>

    <p><i>Максим Аркадьевич Молевич — бывший заместитель заведующего кафедрой
      истории науки и техники, бывший голова Университетской гэбни. Солидный,
      серьёзный и деловой человек, на котором много чего держалось, а потом ка-а-ак
      обвалилось! После ряда необдуманных действий посыпал голову (бывшую) пеплом и
      отправился в добровольную ссылку во всё тот же Медкорпус на исследования.
      Наверняка это как-то смутно связано с предыдущим действующим лицом.</i></p>

    <p><i>Охрович и Краснокаменный — бывшие головы Университетской гэбни.
      Примечательны яркими свитерами, яркими личностями и тем, как быстро и просто
      переходят от приветствия к насилию. На первый взгляд являются апологетами
      бессмысленного хаоса, но пытливый читатель (или зритель? Я ещё не определился)
      заметит, что все их поступки крайне здравы и разумны. Вылетев из
      Университетской гэбни, с радостным гиканьем унеслись в неизвестном направлении.</i></p>

    <p><i>Ларий Валерьевич Базальд — голова Университетской гэбни (как ни
      странно, не бывший). Кажется, единственный человек, который за всю чуму не
      совершил ни одной ошибки. Поэтому его никто толком и не замечал, какой в нём
      интерес-то.</i></p>

    <p><i>Святотатыч — голова Портовой гэбни, мудрый наставник всех и вся,
      который как бы и ни при чём.</i></p>

    <p><i>Дима — тот ещё придурок.</i></p>

    <p><i>Гуанако — не лучше (сколько раз помер, а мозгов не прибавилось).</i></p>

    <p><i>Александр — младший служащий.</i></p>

    <p><i>Бедроградская гэбня — главные злодеи. Очень злые.</i></p>

    <p><i>Новый состав Университетской гэбни</i></p>

    <p><i>Новый состав Бедроградской гэбни</i></p>

    <p><i>Портовая гэбня (без нового состава)</i></p>

    <p><i>Студенты</i></p>

    <p><i>Аспиранты</i></p>

    <p><i>Сочувствующие</i></p>

    <p><i>Ещё чуваки, которых ты всё равно не знаешь</i></p>

    <p><i>Савьюр как метафора</i></p>

    <p>&nbsp;</p>

    <p>— Умерших от чумы забыл, —
      промурлыкал у Димы над ухом Святотатыч. Дима непринуждённо подскочил и подавил
      желание спрятать листы.</p>

    <p>Теперь это уже бессмысленно.</p>

    <p>— Они входят в «чуваков, которых
      она не знает», — буркнул он. — Или в «сочувствующих».</p>

    <p>— Или они тоже метафора, —
      похмыкал Святотатыч не без понимания в голосе, после чего очень деловым тоном
      прибавил: — Хочешь, чтобы сюжет продолжился, — раздевайся.</p>

    <p>И метнул Диме в голову тельняшку.</p>

    <p>Крыса Габриэль Евгеньевич глянул в
      его сторону со сдержанным любопытством.</p>

    <p>Многочисленные прихвостни
      Портовой гэбни теперь нередко посматривали на Диму с любопытством разной
      степени сдержанности, поскольку в недрах похищенной с бедроградского склада
      аппаратуры для воспроизведения изображений вполне остались изображения.</p>

    <p>Димы во всей красе.</p>

    <p>И весь Порт их с радостью
      воспроизводил в порядке живой очереди.</p>

    <p>Дима решительно сдёрнул галстук.</p>

    <p>Без тельняшек морем далеко не
      уберёшься. Им с Гуанако убираться не только морем, но для всего остального сгодится
      и обычное содержимое сумок, а ненаходимые в городе тельняшки отдельной статьёй
      расходов добывать надо.</p>

    <p>— Спасибо, — поблагодарил Дима
      Святотатыча, расстёгивая рубашку, — дальше я как-нибудь сам справлюсь.</p>

    <p>&nbsp;</p>

    <p><i>Все разбегаются из Бедрограда — хочется сказать, что как крысы, только
      крысы умнее и бегут в процессе, а мы почему-то дотянули до конца (кроме
      Габриэля Евгеньевича — ну так он, как известно, крыса и есть). И мы двое тоже
      разбегаемся, да — то ли потому что Гуанако командир приказал, то ли потому что
      тут больше нечего делать. Всё, что ломалось, уже сломано, а городские жители,
      кажется, успели привыкнуть к чайкам.</i></p>

    <p><i>Окончательной точки всё равно никогда не будет, а на каждое решение и
      каждую щедрость находятся оговорки. После того, как Виктор Дарьевич наобещал
      нам золотые горы, он доехал до собственной гэбни, и гэбня, видимо, справедливо
      решила, что кто-то тут совсем охренел. Оказалось, что есть дополнительные
      требования: либо оставить им Габриэля Евгеньевича насовсем (как много
      подтекстов в этом нейтральном слове), либо вернуть им часть денег, либо ещё
      чего. Вилонской грязи оказалось мало, а!</i></p>

    <p><i>Окончательной точки никогда не будет, но некоторые люди умеют ставить
      довольно убедительные запятые. Охрович и Краснокаменный все последние дни
      мотались где-то здесь, по Бедроградской области, подбивали какие-то дела, ещё и
      ещё разводили наивных старых друзей на бабки. Угнали у Хащинской гэбни такси и
      выкрасили его в убийственно-жёлтый цвет.</i></p>

    <p><i>Сперва они отчитывались о своих благих деяниях, а потом перестали, и дальше
      —&nbsp;только слухи. Говорят, какое-то
      повальное количество курсантов забрали документы из Института госслужбы, а в
      Порту (с которым мы по-прежнему вроде как не совсем рассчитались) неожиданно
      прибавилось благотворительной рабочей силы. Говорят, Ларий Валерьевич нашёл на
      своём столе очень детальное описание новых канализационных систем Бедрограда (с
      фотокарточками и подробным планом!), которого до сих пор никто не удосуживался
      сделать.</i></p>

    <p><i>А совсем на днях Медицинская гэбня умолкла и отозвала свои
      дополнительные требования.</i></p>

    <p><i>Говорят, накануне в Столице видели жёлтое такси.</i></p>

    <p><i>Все убегают из Бедрограда, потому что запятая — это всё-таки не точка,
      и единственный способ обмануть себя — просто взять и уйти. Тогда хотя бы твой
      личный сюжет закончится, верно? Мы же не виноваты в том, что не переубивали
      друг друга, хотя законы жанра требовали этого изо всех сил.</i></p>

    <p><i>Гуанако уверен, что Охрович и Краснокаменный вернутся. Я сомневаюсь. С
      кафедры пропала карта Афстралии с крестиком и «нам сюда», а о жёлтом такси
      многие хотят поговорить: одни утверждают, что оно так и стоит в Столице,
      пустое; другие — что видели его в Бедрограде; третьи — что что-то подобное было
      замечено на трассе в Северо-Восточный Регион. Не знаю. Как и все остальные,
      Охрович и Краснокаменный отыграли свою роль и больше никому ничего не должны.</i></p>

    <p><i>Но Гуанако в ответ на это напоминает, что кафедральным чучелом
      по-прежнему висит Хикеракли (Ларий порывался заменить, но его благоразумно
      отговорили). Охрович и Краснокаменный в день юбилея Первого Большого Переворота
      объявили его неделю, потому что всё, мол, закончилось счастливо. Неделя в их
      понимании —&nbsp;штука растяжимая (уж точно не семь дней), но никто другой ведь
      не осмелится заниматься чучелом.</i></p>

    <p><i>А неделя (месяц, год, эпоха) Хикеракли таки должна когда-нибудь завершиться,
      какой бы красивой ни была его рубашка.</i></p>

    <p>&nbsp;</p>

    <p>— Не юли мне тут, не юли, до
      всякой чумы замётано было! Хочешь, чтоб на тебе ещё один долг висел?</p>

    <p>В ответ на это своеобразное
      проявление дружелюбия со стороны Озьмы (он ведь никому ничего не откусил —
      значит, дружелюбие) Гуанако невнятно помычал.</p>

    <p>— Дело стоящее, — важно прибавил
      Муля Педаль. — Сегодня не горит, а завтра загорит. Тут грешно — как это
      говорят? — уклонят’ся! Так что совсем надолго-то не отчаливай.</p>

    <p>— Господа, вы так насели на
      человека, как будто в последний раз его видите, — пропел Зина. — Дайте же ему
      немного личной свободы!</p>

    <p>— А ты небыстр башкою, как я
      погляжу, — немедленно заворчал Озьма. — Если я про замётанное балакаю — дык как
      раз потому, что вижу не в последний раз, и видеть хочу не только рожу, но и
      некоторое полезное шевеление.</p>

    <p>— Будешь долго ждать —
      состаришься, без тебя уведут, — заметил какой-то незнакомый Диме голос.</p>

    <p>— Будет долго ехать — без него
      обнесём, — успокоил незнакомца Озьма и неожиданно сентиментально добавил: — Это
      так, шоб знал, что в Порту всегда дела найдутся.</p>

    <p>Посчитав этот момент удобным для
      побега, Гуанако скользнул в каморку к Диме. В дверном проёме мелькнули Озьма,
      Муля Педаль, Зина и пара чуваков, которых Дима всё равно не знал (в
      тельняшках). Все они были несколько нетрезвыми и очень весёлыми, да и сам
      Гуанако не отставал — чего стоила одна авоська с бутылками, лихо перекинутая
      через его плечо.</p>

    <p>На дорожку и в дорожку.</p>

    <p>Озьма, Муля Педаль, Зина и пара
      чуваков, которых Дима всё равно не знал, равномерно гудели за дверью.</p>

    <p>Гуанако улыбнулся — так широко,
      что уголки его губ полезли за пределы лица. Он слегка наклонил голову, и Дима
      невольно дёрнулся, испугавшись, что те сейчас заденут потолок —&nbsp;и либо потолок
      проломится, либо Гуанако ударится.</p>

    <p>Синие полосы отлепились от его
      тельняшки и начали кружиться в воздухе обручами. Дима хотел кинуть в них
      ручкой, потому что она тоже синяя, но сдержался. Ему пришлось отвернуться,
      потому что сейчас у Гуанако на лице должны были, как обычно, начать проступать
      дополнительные глаза — и вместо этого увидел глаза на своих ладонях: простые, круглые,
      чёрные, без век.</p>

    <p>Глаз без век не бывает.</p>

    <p>Не двигаясь с места, Гуанако
      тронул Диму за плечо обжигающе-горячей рукой, которая тут же потекла ему за
      шиворот.</p>

    <p>— Опять? Дышите, доктор, дышите
      глубже.</p>

    <p>&nbsp;</p>

    <p><i>Я, разумеется, думал, что это просто нервные переживания под конец
      чумы, но симптомы проходить не намереваются. Пытался понять, что же их всё-таки
      вызывает, но, кажется, мой организм плюнул на попытки поддержать иллюзию того,
      что это хоть как-то связано с внешним миром. Имитация Савьюра, пусть и
      идентичная натуральной (то есть я сам не знаю, было ли оно имитацией), никому
      даром не проходит.</i></p>

    <p><i>Ну, чую, у меня будет ещё куча возможностей разобраться в нюансах
      съезда своей крыши.</i></p>

    <p><i>Главное — бороться довольно просто. Нужно просто вспомнить, почему так
      на самом деле быть не может. Ну, скажем, синие полосы на тельняшке — это
      краска, пропитывающая ткань; если бы они отделились от белых, на их месте
      остались бы дырки. А если бы отделилась только краска, она была бы куда как
      менее заметна (просто попробуй прикинуть её удельный вес относительно ткани).
      Не говоря уж о том, что сам по себе, без хоть каких-то энергетических вливаний
      процесс разделения вряд ли мог бы произойти.</i></p>

    <p><i>Рука не может быть настолько горячей, как мне показалось, потому что
      при такой температуре белки сворачиваются. Уж тем более не может быть жидкой.
      Ну и прочие анатомические странности опровергаются так же легко.</i></p>

    <p><i>Понимаешь, да? Знания о мире, хранящиеся где-то в недрах башки,
      позволяют в нём убеждаться (или разубеждаться).</i></p>

    <p><i>Я теперь просто ходячая листовка о пользе образования.</i></p>

    <p><i>И вреде политики.</i></p>

    <p><i>Правда, когда конечности на своих местах и тельняшки не разваливаются
      на цвета, всё равно постоянно приходится копаться в познаниях и проверять
      реальность окружающего мира (типа почти ежеминутно), а то будет как в первый
      раз (мне тогда померещилось, что комната перевернулась вверх ногами и что мы с
      Гуанако сейчас упадём на потолок). В неподготовленном виде галлюцинации могут, выразимся
      изящно, впечатлять. Зато если всегда быть готовым, на отрезвление уходит не
      больше пары минут.</i></p>

    <p><i>Я после первого раза спросил Гуанако, не стрёмно ли ему и дальше
      путаться с психом.</i></p>

    <p><i>Он заржал и ответил, что у него богатый опыт.</i></p>

    <p><i>Но я и сам знаю, что стрёмно быть не должно.</i></p>

    <p><i>Это ведь просто такой постоянный эспандер для силы воли.</i></p>

    <p><i>Не говоря уж о том, что мне приходится гораздо больше читать, потому
      что некоторые законы окружающей действительности гораздо менее очевидны, чем
      тебе может показаться.</i></p>

    <p><i>Ну или я тупой.</i></p>

    <p><i>А теперь буду умный.</i></p>

    <p>&nbsp;</p>

    <p>— И почему мне раньше не приходило
      в голову напялить на тебя тельняшку? — вопросил Гуанако тоном человека, который
      никогда не станет умным, зато продолжит западать на любые нетривиальные
      костюмы.</p>

    <p>Особенно на те из них, которые
      можно встретить в портовом борделе.</p>

    <p>— Не беспокойся, она ещё успеет
      тебе надоесть, — дальновидно предположил Дима.</p>

    <p>Святотатыч взглянул на Гуанако и
      его крайне однозначное выражение лица с лёгким укором.</p>

    <p>— Вы там побыстрее, вам же ногами
      придётся топать — нынче до воды даже Муля на такси ехать не решится, а
      грузовика прям щас дать не могу.</p>

    <p>Никакого «там побыстрее»
      происходить всё-таки не намеревалось, ибо и правда надо было побыстрее. Корабль
      отходит по расписанию, даром что грузовой, а по всем портовым дорогам,
      пригодным для езды к воде и от воды, непрерывной колонной ползут грузовики с
      товарами. Такси там натурально обстреливают, чтобы движение не задерживали.</p>

    <p>Серьёзное дело — свежеснятая
      блокада.</p>

    <p>&nbsp;</p>

    <p><i>Это политический ход Святотатыча — разумный, наверное. Он какими-то
      своими святотатычевскими путями умудрился пронюхать о том, кого сажают в Бедроградскую
      гэбню на место Андрея — ещё до того, как его посадили. Какого-то чувака из
      гэбни Международных Отношений (которого мы с тобой всё равно не знаем). Святотатыч
      вышел с ним на связь, предложил сотрудничество. Какому-то чуваку это на руку:
      перейти на новую должность, успев напоследок снять блокаду Порта на должности
      предыдущей. Отличный послужной список.</i></p>

    <p><i>Святотатычу на руку благодарность обновлённой Бедроградской гэбни.</i></p>

    <p><i>Залог на безоблачное будущее ценой странного настоящего.</i></p>

    <p><i>Видишь ли, Бровь, чуму в Порту вылечить невозможно. Сколько бы ни
      сделали лекарства все окрестные гэбни совместными усилиями, больных не
      переловить — не так работает Порт. Блокаду можно было продержать и подольше, но
      вряд ли это бы что-нибудь изменило.</i></p>

    <p><i>В Порту ведь всякие болячки случаются, одной больше, одной меньше.</i></p>

    <p><i>Совместных усилий Портовой, Бедроградской гэбен и гэбни Международных
      Отношений должно хватить на то, чтобы не особо выпускать чуму из Порта. А
      внутри —</i></p>

    <p><i>— должно же что-то остаться внутри после того, как все разъедутся и
      Бедроград снова станет всего лишь ещё одним городом Всероссийского Соседства.</i></p>

    <p><i>Если никто не убьёт меня за медлительность, сейчас я встану с чемодана,
      возьму его в руку, и мы с Гуанако пойдём на свой грузовой корабль.</i></p>

    <p><i>Гуанако уверен, что мы не вернёмся. Я сомневаюсь. Слово, данное
      командиру, наверное, дорогого стоит, а наше участие в политике зарекомендовало
      себя с крайне сомнительной стороны — это правда. История оказалась слишком
      длинной, слишком сложной, слишком непохожей на правильный шпионский роман с
      настоящей интригой, настоящими героями и настоящими злодеями. Никто не выиграл,
      никто не проиграл —&nbsp;просто некоторые сменили род деятельности, а в Порту
      теперь водится одной болячкой больше.</i></p>

    <p><i>Если бы это оказалось концом, любые читатели бы разочаровались.</i></p>

    <p><i>Даже самая длинная, сложная и непохожая на правильный шпионский роман история
      не может заканчиваться запятой. Я не верю в точки, сомневаюсь в многоточиях и
      скептически отношусь к восклицательным знакам. И именно поэтому, когда мы с
      Гуанако сядем на свой грузовой корабль и перестанем существовать для Бедрограда, я всё равно не поверю — не смогу
      поверить! — в то, что мы никогда не вернёмся.</i></p>

    <p><i>Как же так, ответила бы ты, если бы могла, здесь наблюдается некоторый
      парадокс. Если история не может заканчиваться запятой, но ты не веришь в точки,
      многоточия и восклицательные знаки, то история не может закончиться в принципе!</i></p>

    <p><i>Именно, сказал бы тебе я. Ты познала суть вещей.</i></p>

    <p><i>Авторы шпионских романов бессовестно тебе врали.</i></p>

    <p><i>В конце не ставится запятая, не ставится точка, многоточие и
      восклицательный знак; в конце попросту ничего не ставится, потому что конца
      нет, нет и быть не может. И чума останется в Порту, и гэбни продолжат ругаться,
      воевать и примиряться, и студенты продолжат прогуливать пары, и кто-нибудь
      когда-нибудь с кем-нибудь встретится, что-нибудь случится — и разве возможно
      будет провести границу и сказать, что вчера был конец одной истории, а сегодня
      началась уже другая?</i></p>

    <p><i>Нет. Да и незачем это делать.</i></p>

    <p><i>Потому что всё это можно объяснить гораздо проще. Попробуй — и многое
      станет понятнее. Мне вот стало.</i></p>

    <p><i>Если никак не удаётся оборвать на запятой, если истории не находят ни
      точки, ни многоточия —</i></p>

    <p><i>— может быть, вся штука в том, что на самом деле они попросту никогда
      не заканчиваются?</i></p>

    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img alt='' src="/images/fin.jpg" />
    </div>
  </>
}

export default epilogue
