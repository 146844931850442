import React from "react";

export default [
  {
    quote: <>
      <p>Если честно, мне прежде не попадалось книги с таким количеством фансервиса. Точнее, я не дочитывал их.</p>
      <p className='quote_dots'>…</p>
      <p>
        На самом деле, скопцы нравятся <b>не</b> за скопчество. Я, конечно, согласен с Лемом, что человечьи половые органы устроены не самым мудрым способом, и соединить устройство самокопирования с канализационной трубой мог только очень недалекий инженер, но их отсутствие у индивида еще уродливей.
      </p>
    </>,
    author: 'Александр №1, который по загадочным причинам предпочёл избежать ссылки на свой блог'
  },
  {
    quote: <>
      …политика (и прочие социальные радости) — это люди. А людям свойственно переживать, причем поводами для переживаний может стать все что угодно, любая малозначительная фигня, но переживания-то неизбежно сказываются на мировосприятии тех самых людей, и, как следствие, — на их действиях, в том числе и во время делания политики (и прочих социальных радостей). На мой взгляд, вам удалось как раз достичь гармонии в художественном отображении этого бесспорного факта. И вот это-то я и называю хорошим романом — то повествование, которое <b>убедительно</b> показывает, как переживания отдельных людей влияют не только на жизнь этих самых людей, но нередко и, не побоюсь этого слова, на судьбы мира.
    </>,
    author: <a href='http://komuto-herovato.livejournal.com/' rel='noopener noreferrer' target='_blank' tabIndex={0}>Александр №2</a>
  },
  {
    quote: <>
      <p>И были они Тверды и Решительны, не знающие старости вечно юные боги с темным прошлым и лучистыми глазами. А другие — не так тверды и решительны (а просто тверды и решительны, без больших букв), но зато в здравом уме.</p>
      <p>И все как один — в белых штанах.</p>
    </>,
    author: <>
      <a href='http://preswiter-z.livejournal.com/' rel='noopener noreferrer' target='_blank' tabIndex={0}>Виталий</a>, аспирант
    </>
  },
  {
    quote: '…помогло бы ещё, ещё!!1 больше индивидуальных маньеризмов в этих самых внутренних монологах, в духе Максима в скобках Аркадьевича и таврских апострофов. Которые, кстати, машинально начал воспринимат’ как гортанную смычку в интересном месте, тавры неплохо зазвучали, а там оказалос’, что это всего-навсего немягкост’ (отчего -т’ся нелогично! нелогично!).',
    author: <a href='http://freedomcry.livejournal.com/' rel='noopener noreferrer' target='_blank' tabIndex={0}>Фридомкрай</a>
  },
  {
    quote: <>
      <p>Когда в первый раз смотришь иллюстрации, кажется, что оно всё мультяшное и уж точно не реализм. Сложно понять, что всё происходящее происходит <b>на самом деле</b> и что какой-нибудь Попельдопель — живой человек, а не нарисованный-с-вот-такими-глазами. Что все персонажи — при своих комикcовых цветах! — живут в настоящем (ну, с оговорками настоящем, но реалистичном же!) мире с обычными зданиями университетов и канализациями жилых домов. И тем паче сложно представить, что у них там человеческие отношения и переживания — они же все настолько мультяшные! Но по мере чтения это впечатление проходит.</p>
      <p>Вот, например, когда Бровь курит с Димой, хочется представлять всё-таки кино, а не комикс. Потому что ну какой же это комикс — с таким-то количеством <b>реальных проблем</b>?</p>
    </>,
    author: <>
      <a href='http://kapolskaya.livejournal.com/' rel='noopener noreferrer' target='_blank' tabIndex={0}>Маша</a>, сельская учительница
    </>
  },
  {
    quote: <>
      <p>Блин, но неужели в конце таки все умрут?! Это будет как-то обидно... (читаю главу 34).</p>
      <p className='quote_dots'>…</p>
      <p>
        Дочитал.
        И таки ДА, ДА, ДА, ЭТО КРУТО!! Это действительно хорошая книга — не
        без косяков, понятно, но хорошая по крупному счету, без скидок. Это
        то, что можно перечитывать. Буду.
      </p>
    </>,
    author: <a href='http://bukky-boogwin.livejournal.com/' target='_blank' rel='noopener noreferrer' tabIndex={0}>Бакки Бугвин</a>
  },
  {
    quote: <>
      <p>
        Внимание, вопрос: кто из них предыдущий? Хотя я тут подумал — Охрович похоже на
        отчество. Краснокаменный — на фамилию... Может, это такой концентрированный, <b>двойной человек</b>?
      </p>
      <p className='quote_dots'>…</p>
      <p>
        — Кто<br/>
        — Всё<br/>
        — Это<br/>
        — Говорит<br/>
        — Блять<br/>
        — Я<br/>
        — Запутался<br/>
        — Напрочь.
      </p>
    </>,
    author: <>
      <a href="http://vkontakte.ru/reikon" target='_blank' rel='noopener noreferrer' tabIndex={0}>Рэйкон</a>, избранные моменты из файла
        «Бредоград.doc»
    </>
  },
  {
    quote: <>
      Приходишь домой — а там опять все смеются. И чего смеются? «Нет, не спрашивай, нет, объяснить не получится, и вообще, вали-ка ты с кухни,<b>ты же до сих пор не читал Чуму!</b>» Достали уже.
    </>,
    author: <>
      Младший научный сотрудник <a href="http://red-vehicle.livejournal.com/" target='_blank' rel='noopener noreferrer' tabIndex={0}>Фёдор
        Фёдорович</a>
    </>
  },
  {
    quote: 'Скрывать сюжет за избыточными личными переживаниями героев — просто, проще некуда. Так можно оправдать любую нелогичность событий, любые нелепости повествовательной канвы. Это просто и потому — бесчестно по отношению к читателям. Язык? Его можно бы и назвать интересным — если забыть о вторичности; но язык служит авторам дурную службу, оказываясь не более чем цветастой обёрткой, под которой читатели обнаруживают лишь тривиальное развлекательное чтиво — и, увы, не более.',
    author: <>
      <a rel='noopener noreferrer' target='_blank' href="http://alphyna.livejournal.com/489720.html" tabIndex={0}>Габриэль Евгеньевич Онегин</a>,
        заведующий кафедрой истории науки и техники исторического факультета БГУ им. Набедренных
    </>
  }
]