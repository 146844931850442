import React from 'react'
import { IBookPage } from './index'

const chapter06: IBookPage = {
  header: 'header_d4.jpg',
  title: 'Глава 6. Идеология за лето',
  prevLink: 'chapter05',
  nextLink: 'chapter07',
  content: <>
    <h2>Бедроградская гэбня. Бахта Рука</h2>
    <img alt='' src="/images/06_bahta.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }}/>
    <p>А ещё говорят «тише едешь, дальше
      будешь». Росы и дураки говорят.</p>
    <p>Бáхта
      Рука громко газанул и съехал с магистрали, наплевав на разметку. Из-под колёс разлетелис’ во все стороны брызги, окатили указател’
      «Бедроград за спиной (20)».</p>
    <p>За спиной, за спиной. Заднего
      хода уже не дашь.</p>
    <p>Можно сейчас поехат’
      тихо, опоздат’ и быт’ потом далеко-далеко — этак на
      Колошме — всей Бедроградской гэбней. Или не всей —
      Гошка вот вчера доказывал, что шансы оказат’ся за
      решёткой на другом конце Всероссийского Соседства у Андрея ест’, но уже не
      вместе с ними.</p>
    <p>Говорят, «тише едешь, дальше
      будешь» — это метафора. Андрей умел быт’ тихим. Бахта
      Рука, Соций, Гошка — не умели, а Андрей умел. И как далеко он тепер’?</p>
    <p>По обе стороны дороги угрожающе
      синел лес. Такое называется «свет в конце туннеля»: тебя обступили, зажали,
      задавили массой, но если чут’ поднапрячься, с рыком
      рванут’ вперёд, будет воля и воздух. Простор.</p>
    <p>Бахта Рука не седлал коней на
      бескрайнем полотнище Южной Равнины, последнем осколке сказочного, никогда не
      бывшего Первого Пастбища, — он вырос в Бедрограде, в тесных
      закоулках Старого Города.</p>
    <p>Бахта Рука даже не был тавром — очен’ давно, ещё до выпуска из отряда, так давно,
      как будто
      это тоже сказка и небыл’, всё в тех же тесных
      закоулках ему отрезали косу. Без косы ты не тавр, не тавр и точка, ятаган
      твоего отца и табуны твоего деда значения не имеют, значение имеет только одно
      — твоя отрезанная коса.</p>
    <p>Бахта Рука давно забыл, как это —
      чувствоват’ тяжест’ косы, спат’ с ятаганом под правой рукой, сидет’
      по пятницам за широким столом ресторана таврской кухни в окружении гулких
      великанов и медного звона бубенчиков. Забыл и не делал драмы, но иногда сам
      удивлялся, если вдруг в голову без спросу лезло что-нибуд’
      таврское. Неконтролируемое, иррациональное, такое, которому неоткуда взят’ся, а оно р-р-раз — и
      берётся.</p>
    <p>Любов’
      к простору, например.</p>
    <p>Когда ходили по паутине
      канализаций (сами, сначала надо смотрет’ самим,
      только потом можно посылат’ людей работат’),
      выгуливали среди дерьма жетоны шестого уровня доступа,
      Бахте Руке местами становилос’ беспричинно жутко, так
      жутко, что хот’ беги без оглядки. Как будто приземистые своды уже накренилис’,
      трещат, вот-вот рухнут.</p>
    <p>«Отставить клаустрофобию в строю!
      — огрызался тогда Гошка. — Ну кто опять? Ты, дитя
      равнины? Кончай придуриваться, у меня из-за твоих
      национальных предпочтений ноги подкашиваются!»</p>
    <p>Бахта Рука останавливался,
      опускал веки, вдыхал глубоко-глубоко и представлял, с какими лицами смотрят на
      него сейчас остальные головы гэбни. Чут’ раздражённый
      Гошка, он вообще быстро заводится. Скупой на мимику Соций, он ждёт — умеет ждат’,
      с семнадцати лет насиделся в засадах. Ободряюще
      улыбается Андрей — низачем, рефлекторно, потому что
      всегда смягчает углы. Бахта Рука распахивал глаза и видел ровно то, что
      представлял.</p>
    <p>У них такая синхронизация, что
      мало не покажется.</p>
    <p>Бахта Рука дольше всех сидит в
      Бедроградской гэбне — он помнит разные составы, но настолько полной, настоящей,
      оглушительной синхронизации у него раньше не было. Понимат’
      других голов с полуслова, без слов даже; не созваниваяс’,
      приходит’ в одно и то же место к одному и тому же времени — это да, это у всех
      так, по-другому невозможно. Но чтоб от твоих припадков клаустрофобии немели
      мышцы у остальных!</p>
    <p>Мало не покажется, какая
      синхронизация.</p>
    <p>Мог Андрей на
    неё плюнут’?</p>
    <p>Ну вед’
      не мог!</p>
    <p>Синие шторы леса
      наконец раздвинулис’. Колёса едва не завязли в грязи,
      левое переднее хлюпнулос’ в выбоину, рул’ упрямо
      потяжелел.</p>
    <p>— Шшш,
      не дёргайся, — шепнул Бахта Рука куда-то в приборную панел’.</p>
    <p>«Такси не конь, нашёл, с кем
      поговорить», — смеялся обычно Гошка.</p>
    <p>Да не кон’, не кон’ — только
      говорит’ надо, и Андрей вон тоже всегда говорит, а его в коней носом не ткнёшь.
      Все говорят, кто водит.</p>
    <p>Интересно, в Европах тоже
      говорят? В Европах не только такси и грузовики, там частные средства
      передвижения ест’ — кто попало водит’ может. Должно быт’, это неудобно:
      толкотня на дорогах, мельтешение, асфальт чаще менят’
      надо — неразумный же расход ресурсов! Да и вообще, водител’ —
      это профессия, характер
      нужен правильный. У Гошки и Соция характер не тот, они и не суются. В
      Бедроградской гэбне водят Бахта Рука и Андрей, всегда кладут во внутренний
      карман чёрную таксистскую повязку — мало ли вдруг.</p>
    <p>Бедроградская гэбня заведует
      городским таксопарком — они могут остановит’ почти
      любое такси, показат’ жетон и сест’
      за рул’. И Бахта Рука, и Андрей делали это только по
      большой необходимости: любое не любое, а на своём
      служебном всегда сподручней. Кресло помнит контуры спины, зеркала подкручены
      точно под высоту посадки, каждый свист под капотом знаком и понятен, говорит
      напрямую: масло ли густеет, надтреснул ли где-то охладительный шланг, перегорит
      ли сейчас предохранител’. В чужом такси сразу и не
      разберёшь, что не так.</p>
    <p>Когда сегодня
    поутру пришло извещение, такси Бахты Руки стояло в мойке — сентябр’,
    позавчерашний дожд’ уже оставил за собой следы
    надвигающейся на город слякоти. Срыват’ся надо
      было мгновенно, каждая минута на счету, ждат’
      окончания мойки уж точно не стоило — и Бахта Рука как-то совсем естественно, не
      раздумывая, прыгнул в любимое служебное такси Андрея.</p>
    <p>Может быт’ синхронизация с
      неживыми предметами?</p>
    <p>Видимо, может.</p>
    <p>Кресло пришлос’
      отодвинут’ — разница в росте у них с Андреем значительная — а в остальном никакого
      дискомфорта. Бахта Рука не первый раз
      водил Андреево такси, случалис’ поводы, но когда так
      спешишь, когда так напряжены нервы, малейшее неудобство не-своего такси сразу
      лезет наружу. А тут — ничего никуда не лезет, даже рул’
      стёрт в тех же местах, что у Бахты Руки, хотя ладони
      Андрея гораздо меньше.</p>
    <p>Он и чувствовал всю дорогу как бы
      через рул’ эти ладони: чут’
      прохладней, чем у Гошки, чут’ теплее, чем у Соция.
      Чувствовал в салоне призрак хвойного парфюма, хотя
      откуда ему взят’ся — Андрей уехал ночью с пятницы на
      субботу, сегодня вторник, выветрилос’ всё уже.</p>
    <p>Выветрилос’,
      но всё равно помогало меньше психоват’, как всегда
      помогает присутствие кого-то из своих. Да и психоват’-то нечего, до склада
      пара минут, вон уже виднеется.</p>
    <p>Соций и Гошка махнули на те
      склады, что в черте города, Бахта Рука — на самый дальний, потому что он водит и в окрестностях
      Бедрограда может вести хот’ вслепую, доберётся мигом.</p>
    <p>Уже почти добрался, уже почти всё
      в норме.</p>
    <p>Первый тревожный звоночек был в
      воскресенье — когда Гошка в Столице искал Андрея, — но Бахта Рука и Соций плюнули,
      не стали застреват’. Зашёл фаланга, это бывает, хотят
      и заходят иногда, зудел в шапку про отчёт по идеологии
      за лето.</p>
    <p>Какая «идеология за лето» в
      колыбели Революции? Бедроград — да Петерберг тогда ещё, переименовали-то не
      сразу — всем этим насквоз’ пропитался раньше, чем в
      Столице мелкая аристократия шумет’ перестала, о чём
      вообще разговор.</p>
    <p>Фаланга в воскресенье мимоходом
      спросил, где две другие головы-то.</p>
    <p>Бахта Рука, не моргнув глазом,
      ответил: Андрей в области, там денег просят — монтаж временных укреплений для
      очередного Большого Переворота. С Первого-то десят’
      лет прошло, юбилей — вот пусть фаланга и верит, что
      они тут по уши идеологией заняты.</p>
    <p>Соций, тем более ничем не
      моргнув, подхватил: Гошка в Порту, встречается с информантом.</p>
    <p>Это тоже было правдоподобно:
      Гошка любит встречат’ся в Порту со всеми подряд,
      чужая территория ему нервы щекочет. И вернут’ся
      может не сразу, у него ж там женщина «для психической разгрузки», Врата — лет
      пят’ как одна и та же.</p>
    <p>Андрей самый младший, он вечно
      подтрунивает, что Гошка, мол, состарился и запаршивел, а Гошка вечно
      отшучивается — надоело, мол, новые болячки в родную
      гэбню таскат’.</p>
    <p>Фаланга легенды проглотил, как пришёл, так и
      ушёл, а сегодня — бац! — извещение об экстренной
      проверке служебных помещений Бедроградской гэбни.</p>
    <p>И такое тоже бывает, и обычно с
      бухты-барахты, фалангам-то в голову что только ни
      взбредёт. Пуст’ себе проверяют, сколько влезет, но что
      ж так не вовремя? Сейчас-то в паре-тройке служебных помещений Бедроградской
      гэбни лежат вирус и лекарство. Хорошо лежат, вирус вон запрятан между швабрами
      и вёдрами по каморкам уборщиков на больших складах, чтоб никто-никогда-ничего.</p>
    <p>Но всё равно, едва взглянув на
      извещение, Гошка, Соций и Бахта Рука рванули врассыпную из кабинета. Фаланги —
      породистые ищейки, натасканные на чужие промахи. Ничего не знают, не могут знат’,
      не подозревают даже, а всё равно вынюхают, что не
      так.</p>
    <p>У другого состава, но уже при
      Бахте Руке, нашли как-то оставшиеся от предшественников взрывные устройства в
      Институте государственной службы, о которых тогдашняя
      Бедроградская гэбня слыхом не слыхивала.</p>
    <p>И как нашли?</p>
    <p>Экстренная проверка служебных бла-бла-бла: фаланга споткнулся о половицу, ни с
      того ни с сего стал её отдират’, заметил посторонний
      объект, оказалос’ — взрывчатка. В середине тридцатых заложена, но даже бабахнут’ ещё можно, реагирует на какие-то
      там частоты.</p>
    <p>Вот как они это делают?</p>
    <p>Нюх, иначе не объяснишь.</p>
    <p>Так что — пулей на склады,
      пробирки по карманам и на частные квартиры. Лекарство в три пары рук не
      утащишь, да и не надо — мало ли, лекарство. За лекарство цеплят’ся
      особо не будут, лишь бы вирус подальше запихнут’.</p>
    <p>Бахта Рука слёту припарковался у
      самого входа, выскочил из такси и похолодел: в отдалении, за мохнатыми, не
      облетевшими пока кустами стоял грузовик. Обычный, нормальный грузовик, ничем не
      примечательный.</p>
    <p>Ничем, кроме
    того, что эту модел’ Бедроградская гэбня в том году
    ещё списала — она без блокируемого дифференциала, на таких пуст’ Столица
    катается по своим чистеньким пригородам, а у нас, в Бедрограде, на просёлочных
    дорогах топи, в которых тяжеловесы то и дело вязнут.</p>
    <p>Не должно быт’ этого грузовика у
      нашего склада!</p>
    <p>Бахта Рука на секунду завис, мучаяс’ выбором: бежат’ или не бежат’ смотрет’ номера. Если на
      нём приехал фаланга (фаланга на грузовике, мда), то
      номера никого не волнуют — наоборот, надо быстрей внутр’,
      изымат’ вирус из-под носа у проверки, пока возможност’ ест’. Если не фаланга, если кто-то другой
      сунулся — стоит знат’, кто. Хотя бы по фальшивым
      номерам попробоват’ разыскат’
      незваных гостей.</p>
    <p>Бахта Рука не считал всех кругом
      врагами, но твёрдо знал, что к Бедроградской гэбне много у кого ест’ вопросы,
      которые нельзя задат’ в мирной форме. Так сложилос’.
    </p>
    <p>Но лучше будет за номерами послат’ кого-то из охраны, всё-таки первоочередная
      задача —
      вирус.</p>
    <p>Бахта Рука торопливо дёрнул на
      себя двер’.</p>
    <p>За дверью, на положенном месте
      как раз стоял охранник. Сразу же признав Бахту Руку, он выпучил глаза, кивнул
      на узенький коридор справа и показал ладон’ с загнутым большим пальцем.</p>
    <p>Загнутый большой палец — четыре
      вытянутых.</p>
    <p>Четыре. Четвёртый.</p>
    <p>Охранника Бахта Рука едва не сбил
      с ног, устремившис’ в коридор.</p>
    <p>Там подсобки! То ест’ — швабры и
      вёдра, между которыми запрятан вирус. И идёт туда не фаланга, а Силовой Комитет
      — четвёртый уровен’ доступа с автоматами наперевес.</p>
    <p>Очен’, очен’ плохо.
    </p>
    <p>Фаланги — ищейки, Силовой Комитет
      — псы запрещённой до Революции из-за европейских постулатов Неагрессии
      бойцовской породы. Бойцовские псы на службе у ищеек — появляются только тогда,
      когда ищейка уже выследила жертву, и рвут её в клочья.</p>
    <p>Проверки проводят фаланги.
      Силовой Комитет — конфискации по приказу фаланг.</p>
    <p>Завидев впереди шест’ спин в
      кожаных плащах, Бахта Рука сбавил шаг. Неспешно нагнат’,
      сдержанно кивнут’ и непонимающими глазами смотрет’,
      что они будут делат’.</p>
    <p>Никто даже не спросил Бахту Руку,
      как он тут оказался. Жетон предъявлят’ не пришлос’
      — тавра без косы из Бедроградской гэбни опознают
      обычно с одного взгляда. Обнаглев, он сам поинтересовался, какого лешего к ним
      наведывается Силовой Комитет.</p>
    <p>— Не обсуждается, — отбрила его спина, идущая первой, и, сверившис’
      с планом склада в руках, повернула на развилке как раз туда, где притаилас’
      каморка с вирусом.</p>
    <p>Плохо, хуже не бывает.</p>
    <p>Пока кожаные плащи вскрывали двер’, простукивали стены и снимали кафел’,
      Бахта Рука стоял на расстоянии трёх шагов и думал, что они с Гошкой и Социем собиралис’ сегодня сделат’
      передышку от эпидемии и даже от поисков Андрея.</p>
    <p>Короткую, на
    пару-тройку часов и только к вечеру, но обязательно сделат’.
      Выкинут’ всё из голов — тех трёх, что осталис’ от
      Бедроградской гэбни, — потянут’ джин или ещё какое небыстрое пойло.
      В заведение, правда, не сунешься: эпидемия контролируемая, но может и
      самовольно доползти до любого бара, ресторана, кафешки — четвёртый ден’
      вед’ город травят. Зато, когда не в заведении, можно по бутылкам пострелят’,
      тоже был бы отдых.</p>
    <p>Отменяется отдых.</p>
    <p>Бетонная пыл’ так и норовила забит’ ноздри, клубилас’
      в воздухе — кожаные плащи с их разлетающимися полами всё не давали ей осест’.
      Осколки кафеля сыпалис’,
      как полчаса назад брызги из-под колёс.</p>
    <p>Сначала снимали кафел’, тепер’ б’ют, не
      осторожничают. Бахта Рука для профилактики прикрикнул: порча служебного
      имущества, полегче. Его проигнорировали.</p>
    <p>Он был в ступоре, таком странном
      после всех сегодняшних гонок на такси — вязком, липком, приставучем, как дерьмо канализаций, принадлежащих Университету. Сейчас
      кожаные плащи наткнутся на нишу с пробирками, как пит’
      дат’ наткнутся, они же её так усиленно ищут. У них распоряжение от фаланг.
      Никто не говорил этого Бахте Руке, но и так понятно — Силовой Комитет не крошит
      чужой кафел’ без их отмашки.</p>
    <p>Но как, откуда фаланги прознали про вирус?</p>
    <p>Вообще про вирус, леший уж с
      точным — до конкретной каморки — местонахождением запасов.</p>
    <p>Вся затея с эпидемией держалас’ в строжайшем секрете, всё, что могли, делали
      сами, без привлечения лишних людей. И ладно бы вой подняли какие-нибуд’
      врачи — заметили там неладное, взяли анализы, увидели
      заразу в микроскоп, обратилис’ в Медицинскую гэбню,
      минуя городские власти. Это представимо, этого они ждали — хотя и не
      на четвёртый ден’,
      рано. Но фаланги?</p>
    <p>Фаланги не
    шибко дружны с Медицинской гэбней, через врачей к фалангам сведения бы не
    утекли. А если и утекли бы — всё равно в микроскоп не разглядишь, откуда
      родом зараза и в какой подсобке её хранят.</p>
    <p>Белые осколки кафеля и чёрные
      кожаные плащи — как всё это может быт’ настоящим? Может быт’, Бахта Рука
      отключился за рулём и видит сон, глупый и нелогичный,
      порождённый взвинченными нервами? Может быт’, в каморку и в сон сейчас вообще
      шагнёт Андрей, извинится за опоздание и так, между прочим, расскажет, где его искат’, когда Бахта Рука проснётся?</p>
    <p>— Чисто, — сорвал пелену ступора
      голос одного из плащей.</p>
    <p>Бахта Рука вздрогнул, тряхнул
      головой, пригляделся: ниша — вот она, неглубокая, для пятидесяти пробирок
      глубокой не нужно.</p>
    <p>Неглубокая и пустая.</p>
    <p>Кожаные плащи хмуро уставилис’ на неё, потом прощупали каждый миллиметр,
      обнаружили механизм спуска нижней грани.</p>
    <p>Всё же проще, можно же не отдират’ кафел’:
      каморка —
      уборщика, прямо в полу сток для воды, ест’ сток — ест’ и подпол, в него
      попадают из люка в другой каморке, ползут на карачках,
      нажимают рычажок и получают прямо в руки содержимое ниши.</p>
    <p>Бахта Рука ехал сюда как раз ползат’ по стоку — и пополз бы, если б экстренная
      проверка
      не была на деле конфискацией, если б Силовой Комитет не пёр прямиком <i>именно
        в эту каморку</i>. Когда они уже там,
      рычаг, находящийся прямо у них под ногами, нажимат’
      бессмысленно — услышат.</p>
    <p>Кожаные плащи потребовали отвести
      туда, откуда попадают в подпол, и Бахта Рука повёл, сомнамбулически
      передвигая ноги. Мельком — пока кожаные плащи её не расхерачили — осмотрел двер’
      подсобки с люком, потом сам люк: никаких следов, если
      и вскрывали, то очен’ нежно, скорее уж открывали ключами.</p>
    <p>Такими же, как лежали у Бахты
      Руки в кармане.</p>
    <p>Он тайком сжал со всей дури
      осколок, подхваченный в разгромленной каморке, — больно, кров’, но проснут’ся не вышло.</p>
    <p>Фаланги знают про вирус, фаланги послали
      за ним силовиков, но кто-то увёл его ещё до фаланг, а Бахта Рука никак не
      проснётся, не стряхнёт с себя этот невозможный, невообразимый, душный бред.</p>
    <p>Само собой, в подполе пробирок с
      вирусом кожаные плащи не нашли. Потом шарахнулис’ за лекарством — в каком помещении его хранили, они тоже заранее
      знали.</p>
    <p>Пустые стойки оскалилис’
      на Бахту Руку холодным железом углов — лекарства не было.</p>
    <p>Охранник, зажатый кожаными плащами
      в проёме коридора,&nbsp;мотал головой: сам сменился полтора часа назад, сменщик
      ни о каких вторжениях на подотчётный объект не докладывал.</p>
    <p>Тепер’
      кожаные плащи подумают на Бедроградскую гэбню — кого ещё могут покрыват’
      охранники? Уже смерили тяжёлыми взглядами,
      выплюнули извинения за разгром и вышли прочь, в сентябр’скую
      хмар’.</p>
    <p>— Ну
      мне-то можешь сказать, — шепнул охраннику Бахта Рука безо всякого акцента. Он
      давно избавился от таврской манеры не смягчат’
      согласные. В речи, не в мыслях. — Был здесь кто-то? Чужой, свой, голова гэбни?</p>
    <p>Охранник ещё раз повторил, что не
      знает, что сменщик не упоминал, что это мрак и что он сам сменщика съест с
      потрохами, как только — так сразу. Бахта Рука улыбнулся, записал имя сменщика и
      вырвался поскорее на воздух.</p>
    <p>Кожаные плащи как раз запрыгивали
      в свой конспиративный грузовик. Хот’ бы поинтересовалис’,
      прежде чем вваливат’ся на склад, какие модели
      грузовиков в почёте у Бедроградской гэбни в этом сезоне.</p>
    <p>Бахта Рука отвернулся от них, от
      узкой дороги, убегающей в синий лес, и побрёл вдол’
      стены. В таком состоянии садит’ся
      за рул’ нельзя, надо взбодрит’ся,
      продышат’ся, выкинут’ из головы все дурные
      предчувствия — как будет, так и будет, а ехат’ можно
      только успокоившис’.</p>
    <p>Потеряет десят’
      минут сейчас — нагонит на магистрали, потом ещё по городу срежет, он знает, где
      лучше сократит’ пут’. К тому же грузовик свалит подальше, не будет
      маячит’ перед глазами.</p>
    <p>За складом простиралос’
      поле — уже пожухлое, но ещё дышащее тёплым летним паром. Горизонт всё равно был
      очерчен дымкой ещё одного пролеска, да и на самом поле то тут, то там
      вскакивали коробки сараев, но и так неплохо.</p>
    <p>Просторно.</p>
    <p>Бахта Рука закурил и попробовал
      просто смотрет’ на поле и ни о чем не думат’.</p>
    <p>Не думат’,
      что у дверей разграбленного склада его ждёт такси Андрея — уютное, послушное,
      совсём как своё, только хвойный парфюм мерещится.</p>
    <p>Не думат’,
      что разграбит’ склад по-тихому мог разве что сам Андрей.</p>
    <p>&nbsp;</p>
    <p>— Ни вируса, ни лекарства, ни хера, — рыкнул Гошка.</p>
    <p>— На обоих складах в городе, —
      уточнил Соций, зло прищёлкнув языком. — Явно Силовой
      Комитет понаехал с конфискацией. Но ночная охрана молчала в тряпочку, утренние не в
      курсе.</p>
    <p>Бахта Рука швырнул куртку в
      вешалку и сказал:</p>
    <p>— Не конфискация.</p>
    <p>Сказалос’
      легко, по дороге думал, что будет сложнее.</p>
    <p>Гошка и Соций выжидательно уставилис’ на Бахту Руку.</p>
    <p>— Я столкнулся с силовиками нос к
      носу, пёрли к хранилищу как по рельсам, ни секунды не
      сомневались. Правильная наводка от фаланг у них точно была, только про люк не
      знали, громили стены. Короче, не было там ничего ещё до силовиков.</p>
    <p>— Замки? — Соций нагнулся вперёд
      так резко, что кресло под ним скрипнуло.</p>
    <p>— Следов вроде никаких. Теперь не
      узнаем, силовики разнесли всё в хлам.</p>
    <p>— У нас тоже без следов, — кивнул
      Соций.</p>
    <p>Гошка мерил кабинет шагами с
      таким пасмурным видом, что тучи за окном становилис’
      уже не нужны. В пепельнице громоздилас’ свалка
      окурков: изгрызенных — Соций прикусывает сигареты со всей дури,
      совсем коротких — Гошка всегда докуривает до самого фильтра, а Андрей…</p>
    <p>— Андрей уезжал с ключами? —
      спросил Гошка у туч за окном. — С ключами, леший его?</p>
    <p>Можно было не отвечат’.
      Андрей малост’ параноик, он такие вещи хранит на
      сердце.</p>
    <p>Жалобно звякнул телефон, не успел разразит’ся трелью — Гошка
      остервенело схватился за трубку, едва ли не переломил её пополам, но осёкся и
      всё-таки приставил к уху.</p>
    <p>— Суки, ну что ещё? — и не подумав понизит’ голос, рявкнул Соций. Пнул
      валяющийся у ног коробок спичек с такой силой, что тот впечатался
      в противоположную стену.</p>
    <p>Бедроградская гэбня прикуривает
      от спичек. Началос’ с Соция — он же служил в армии,
      которой у Всероссийского Соседства нет, только одно на двоих с Ирландским
      Соседством резервно-тренировочное подразделение. И
      вот в этой армии, которой нет, ест’ правильные армейские зажигалки с крепким
      корпусом, с незаедающим колёсиком, с устойчивым
      пламенем — Бедроградская гэбня когда-то наслушалас’
      про них по самое не могу. Однажды Соций просто перестал бесит’ся на хиленькие
      зажигалки для мирного населения и радикально перешёл на спички. Остальные
      головы подтянулис’ как-то незаметно для самих себя —
      синхронизация.</p>
    <p>Гошка, так и не проронив в трубку
      ни слова, хрястнул ею об телефон.</p>
    <p>— Не кипятись ты, — Бахта Рука
      подошёл поближе. С хорошей синхронизацией бывает физически необходимо находит’ся друг от друга на
      расстоянии вытянутой руки, а то и меньше. — Не факт, что Андрей. Заловили,
      опустили на ключи…</p>
    <p>— И на план склада с жирным
      красным крестиком, и на схему проезда, и на подробную карту местности в
      нагрузку! — взвился Гошка. — Держи карман шире.</p>
    <p>— Да на нём всё сходится, — Соций
      упёрся левым глазом в кулак. — Новые заказы у тавра-вирусолога, не подпущенные
      к гэбне личные агенты, чистенько обнесённые склады. Мутит что-то своё наш чистенький! Только фаланги всё равно сели ему на хвост.
      Сраная конфискация, и тавра его в Столице тоже кто-то пасёт, — Бахта Рука всё
      это знал, и Соций знал, что Бахта Рука знал, но продолжал убеждат’.
      — Слышь, Бахта, нас же с тобой в воскресенье фаланга
      дежурно спрашивал, куда свинтили ещё две головы. Вынюхивал, сука, при делах мы
      или как.</p>
    <p>— Не нанюхался, за добавкой
      пришёл, — Гошка наконец-то повернулся от окна, но выражение, застывшее у него
      на роже, радости никому не добавило. — Звонили снизу:
      к нам фаланга с официальным визитом. Пока в буфет завернул, пирожки трескает. Тянет
      резину, чтоб мы лужу побольше
      нассали.</p>
    <p>— Ур-род, — Соций херакнул перед
      собой кулаком и вдруг добавил, — <i>фаланга</i> урод.</p>
    <p>В ответ на такое уточнение Гошка
      вздыбил свою фигурную, запоминающуюся бров’ и шагнул зачем-то к полкам, ломившимся от документов.
      Неважных, простеньких, тех, которые не надо прятат’ —
      семестровые отчёты Института госслужбы, прошения рядовых жителей города
      Бедрограда, образцы и формуляры для всякой официальщины.</p>
    <p>Да Андрей тоже тот ещё урод, если он их кинул, но кому захочется в это верит’?</p>
    <p>Бахта Рука непривычно отстранённо
      — без воспоминаний о всяких хвойных парфюмах — подумал, что примерно за то, что
      вытворил Андрей, таврам и отрезают косы другие тавры, а не какие-то бескосые злопыхатели. Свалил, спёр общие запасы, привёл хвост, не
      сказал ни слова. Ещё и
      личные агенты, Дмитрии леших их Ройши! Гошка после беседы с Шапкой всю Столицу
      оперативно перетряхнул — и ничего. Вчера разослали запросы по большинству
      учебных заведений, которые готовят медиков в Средней Полосе, откуда Дмитрий
      Ройш якобы родом. Кирзан’ и Старожлебинск
      уже ответили: не было у них никогда никакого Ройша, тем более Дмитрия.</p>
    <p>С самого начала было ясно: имя —
      подставное, документы — фальшивые, с настоящими кто ж
      в Медкорпус за заразой сунется? И точно так же было ясно, что приставит’ к
      столичному вирусологу своего наблюдателя вполне разумно.</p>
    <p>Всё ясно, всё разумно, всё так,
      как и должно быт’ — кроме одного. Почему остальные три головы Бедроградской
      гэбни раньше не слышали об этом наблюдателе?</p>
    <p>Андрею понадобятся очен’, очен’ веские
      оправдания,
      чтоб не получит’ по шапке, когда объявится.</p>
    <p>Гошка выудил из залежей
      макулатуры на полках какую-то бумажку — у него бывает, он юрфак заканчивал.</p>
    <p>— Эпидемия <i>уже</i> идёт полным ходом, хоть весь запас вируса и растворился в
      воздухе, — Гошка смотрел на остатки Бедроградской гэбни тяжело и решительно
      одновременно. — Мы <i>всё ещё</i> имеем шанс
      ткнуть херов Университет в некомпетентность, непрофессиональное обращение с их
      собственным канализационным дерьмом и далее по списку.
      Только без учёта заражения через студентку-истеричку, в неё как случайный
      фактор никто не поверит, раз Андрей попался.</p>
    <p>— Раз Андрей попался, ткнут нас,
      — мотнул головой Соций. — Нагнут хорошенько и ткнут во всё подряд.</p>
    <p>Бахта Рука поймал себя на том,
      что мотнул головой параллельно с Социем. Подхватыват’
      жесты друг друга ещё до того, как они начинаются, — стандартный навык, отлично
      работает на допросах: допрашиваемый сидит строго напротив гэбни и психует уже от
      того, как она слажено, одинаково двигается.
      Как единый организм, скреплённый синхронизацией.</p>
    <p>Так уже и не разберёшь: это ты
      сейчас свои личные сомнения выразил или рефлекторно скопировал реакцию.</p>
    <p>— Вы б дослушали, а? — возмутился
      Гошка, но усмехнулся при этом спокойно, понимающе. — Некогда спорить, фаланга
      пирожками не подавится и не исчезнет. Мы <i>можем</i> убедить его, что вирус на складах и эпидемия в городе — не наши художества. Я
      не я, кобыла не моя. Андреева кобыла — от гривы до хвоста.</p>
    <p>Бахта Рука и Соций ответили почти
      хором:</p>
    <p>— И что нам это даст?</p>
    <p>— Все отвечают за каждого, сам
      как будто не знаешь.</p>
    <p>Гошка метнул им свою бумажку,
      бумажка завертелас’, замедлилас’
      в воздухе, но всё-таки спланировала на стол.</p>
    <p>— Пока мы гэбня — отвечают, — он
      жёстко усмехнулся. — Предлагаю право нейтрализации четвёртого.</p>
    <p>От неожиданности Бахта Рука и
      Соций шумно выдохнули.</p>
    <p>Гошка явно очен’ торопился —
      фаланга нагрянет с минуты на минуту! — но таки дал время осмыслит’. Снова
      вернулся к окну, распахнул форточку. Бахте Руке почему-то захотелос’
      вытворит’ что-нибуд’ совсем дурацкое,
      детское даже — покричат’ в эту форточку, например. На языке вертелос’
      нелепое слово «коноедство» — храбрая попытка Бахты
      Руки и его отрядских товарищей ругат’ся как будто
      по-таврски.</p>
    <p>Как ест’ коноедство,
      но Бахта Рука промолчал.</p>
    <p>— На-а-армально,
      — обалдевшим голосом протянул Соций.</p>
    <p>Да только вед’ правда —
      нормально, как нормально взят’ и самому себе отрубит’
      руку. Жутко, в здравом уме не захочется, но у них такая работа, в ней всякое
      бывает. Уж какую выбрали, никто не заставлял.</p>
    <p>— Он не только нас кинул с
      планом, — Бахта Рука говорил медленно, скорее для
      себя, — он весь Бедроград подставил.</p>
    <p>— Да не знал он! — вдруг заорал Гошка. — Да, умотал в Столицу
      хер знает зачем, да, падла, но когда сбегал и запасы
      крал, не знал ведь на самом-то деле, что мы эпидемию <i>уже запустили!</i></p>
    <p>— Ты там определись, — Соций
      угрюмо чиркнул спичкой.</p>
    <p>Бахта Рука смотрел на Гошку, снова
      заметавшегося от стены к стене, и думал, что тому
      сейчас гораздо сложнее, чем им с Социем. Предложит’ применение ПН4 к Андрею — это
      правильно, это может спасти ситуацию, но
      это… в общем, Бахта Рука сам бы никогда не смог.</p>
    <p>— Чего определяться? — Гошка
      застыл посреди кабинета. — Он не знал, а значит, у нас именно она, <i>критическая
        рассинхронизация</i>.</p>
    <p>Повисла тишина, от которой может случит’ся припадок
      клаустрофобии — густая, плотная, не дающая сделат’ ни
      шагу не хуже обвала в канализационном туннеле.</p>
    <p>Критическая
      рассинхронизация, диагноз пострашнее любой чумы — водяной, степной, леший знает
      какой. Единственный повод применит’ право нейтрализации четвёртого, ничего
      неожиданного, это заставляют зубрит’ в Институте
      госслужбы, это должен знат’ каждый, кто служит в
      гэбне, да только обвалившейся на голову тишине наплеват’.</p>
    <p>— Из-за ПН4
      будет расследование. Заклюют, — смахнул тишину Соций. Он не боится обвалов, он
      служил в горах. — Выплывет же всё.</p>
    <p>Соций не пытался найти оправданий
      Андрею, он вообще не говорил ни слова про Андрея, но и
      ухнувшему в припадок ложной клаустрофобии Бахте Руке, и всклокоченному, дёрганому
      Гошке было совершенно очевидно, что все возражения Соция — это тоже оно,
      животное, физическое нежелание отгрызат’ самому себе
      руку.</p>
    <p>Где-то там в бумажке, которую
      швырял Гошка, форме для ПН4, мелькает обрывок из
      определения гэбни: «единица управления сферой, территорией или учреждением».</p>
    <p>Единица, не четыре единицы. И тем
      более — не три плюс одна.</p>
    <p>— Не выплывет, успеем замести, —
      Гошка скрестил руки на груди и стал обратно собранным, почти живым. — Пользуясь
      служебным положением, Зябликов Андрей Эдмундович под носом у
      родной гэбни устроил чуму в Бедрограде…</p>
    <p>— Родная
      гэбня вовремя зашевелилась, по горячим следам выловила папашу чумы Таху Шапку и
      начала принимать меры? — оживший Гошка вселил в Бахту Руку нездоровый азарт.</p>
    <p>— Улавливаешь, — одобрительно
      кивнул Гошка, взялся за форму для ПН4 и потряс ей в
      воздухе. — Все помнят, что это непредсказуемая штука? Типа либо его, либо нас,
      либо кого-то из нас, либо всех сразу, либо вообще никого?</p>
    <p>Бахта Рука не хотел вчитыват’ся в служебную инструкцию, он и так в курсе, как
      оно бывает: созывают Комиссию по Делам Гэбен из одного фаланги и нескольких
      кожаных плащей Силового Комитета, Комиссия разбирается, что и как, долго разбирается
      — и выносит решение. Любое. Кто в итоге окажется виноватым и к кому примут меры
      — заранее неизвестно.</p>
    <p>— Так вот мы напряжёмся и сделаем
      так, чтоб его, — уверенно закончил Гошка.</p>
    <p>— Эпидемия, — громыхнул Соций. —
      На время расследования гэбня снимает с себя все полномочия и передает их
      Комиссии. Ты хочешь, чтобы в ёбаную эпидемию здесь хозяйничали фаланги?</p>
    <p>— Да кто им даст хозяйничать.
      Пусть забирают здания, архивы, служащих. Но на кой хер нам служащие, мы самую
      важную работу всё равно всегда сами делаем, своими восьмью руками! — Гошка сбился было, но тут же бодро отмахнулся. — Шестью. И
      продолжим делать, но фаланги об этом не узнают.</p>
    <p>Гошка дело говорил —
      Бедроградская гэбня в нынешнем составе действительно решала главные проблемы
      без привлечения дополнительных человеческих ресурсов. Те, кто годами
      просиживает штаны в кабинете, а по канализациям бегат’
      отправляет младших служащих, — не власт’, а одно
      название.</p>
    <p>Временно лишит’ Бедроградскую
      гэбню официального статуса ещё не значит лишит’ её
      власти, нашли дураков.</p>
    <p>Бахта Рука красноречиво стянул
      зубами колпачок с ручки — чего медлит’-то?</p>
    <p>— Последнее,
      — Гошка жестом притормозил Бахту Руку, не дал пока поставит’ подпис’.</p>
    <p>Внезапно прорывающаяся
      дотошност’ Гошки в бумажных вопросах до сих пор
      иногда удивляла даже Бахту Руку. А уж со стороны заподозрит’, какой отпечаток
      оставила на нём суровая юрфаковская муштра, было тем
      более непросто.</p>
    <p>— Ну? — поднял голову Соций.</p>
    <p>— Пну, —
      честно отозвался Гошка и напомнил одну существенную детал’.
      — Подписать эту херню значит закрыть перед собой любые перспективы карьерного
      роста. Чем бы оно ни закончилось, никаких повышений уровня доступа и переходов
      в другие гэбни подписавшим не светит, — выхватив ручку у Бахты Руки, он первым
      оставил на листе размашистую, летящую закорючку. — Но лично я и так собирался
      служить городу Бедрограду, пока не подохну.</p>
    <p>Стало легко, как будто воздух из
      распахнутой форточки наконец заполнил пространство,
      вытеснил к лешему всё лишнее, сложное и ненужное.</p>
    <p>Всё, разобралис’
      — а дальше уж буд’ что будет.</p>
    <p>Три подписи под печатным текстом
      с пропусками.</p>
    <p>И нужно обязательно в обозримом
      будущем отдохнут’ и пострелят’ по бутылкам.</p>
    <p>&nbsp;</p>
    <p>— До свидания, — покивал фаланга
      самому себе, отлепился от подоконника, прислонившис’
      к которому он простоял последние сорок минут, почти повернулся к двери, но
      всё-таки спросил: — Только ещё одно: четвертый-то голова нынче где?</p>
    <p>Остатки Бедроградской гэбни
      синхронно посмотрели на фалангу с непереводимым выражением, которое снаружи
      должно было являт’ собой скорб’ и неуверенност’,
      а изнутри ощущалос’ не иначе как «выкуси, сука».</p>
    <p>…Стоило подписат’
      форму для ПН4 — из приёмной доложили, что фаланга
      ждёт.</p>
    <p>Бахта Рука бросился к вешалке за
      форменными мундирами, Гошка к бумажке — дозаполнит’
      пропуски, Соций к сейфу — согласно протоколу нельзя входит’ при оружии в
      помещение для допросов, где возжелал сегодня говорит’ фаланга, надо разоружит’ся.</p>
    <p>Разоружит’ся, упаковат’ся в
      мундиры, пристегнут’ на левую сторону ремнями
      наплечники, стерет’ с ботинок складскую пыл’, кое-как
      пригладит’ Гошке волосы — а то слишком заметно, что он всё утро как угорелый
      бегал по отсыревшему городу, даже дожд’ где-то
      зацепил.</p>
    <p>А потом долбаные сорок минут отвечат’ на вопросы про замену
      проводки, последние пополнения архивов и идеологию за лето!</p>
    <p>Фаланга как ни в чём не бывало поддерживал иллюзию будничности всей этой
      экстренной
      проверки служебных помещений. Остатки Бедроградской гэбни отводили душу, деляс’
      под столом фантазиями на тему его медленной и
      мучительной гибели — гэбенный служебный код, касания
      стоп. Чтоб никто не слышал и не видел, что гэбня обсуждает внутри себя. Удобно
      — помогает быстро принимат’ решения на допросах.</p>
    <p>Помогает не впадат’
      в бешенство, когда их сорок минут мурыжат ерундой.</p>
    <p>Но всё-таки спросил, спросил про
      Андрея!</p>
    <p>— Не знаем, — пожал плечами Бахта
      Рука, со злорадным удовольствием наблюдая, как меняется стойка породистой
      ищейки-фаланги.</p>
    <p>— Едва ли не у вас собирались
      спрашивать, — ещё больше подначил Соций.</p>
    <p>— Понимаете, — как будто бы извиняяс’, начал Гошка, — у нас критическая
      рассинхронизация.</p>
    <p>Про фаланг часто рассказывают — дуракам, которые развешивают уши, — будто у них на
      всё одно
      выражение лица. Бахта Рука знал три: одно стандартное,
      презрительно-равнодушное, «ничего нового вы всё равно не расскажете, но, так уж
      и быть, давайте послушаем» и ещё два, персонально для Бедроградской гэбни: «где
      отчёт по идеологии за лето?» и «прекратите обострять ситуацию со Столицей
      своими разбойными выходками!».</p>
    <p>Сейчас Бахта Рука своими глазами
      увидел, как мимика фаланги принимает доселе неизвестную, четвёртую конфигурацию
      — «а вот с этого места поподробнее, отечество нуждается в вашем грязном
      белье!».</p>
    <p>— Мы собирались посылать прошение
      через официальные инстанции, но раз уж вы здесь… — Бахта Рука замолчал,
      позволив себе поглумит’ся
      ещё самую малост’.</p>
    <p>Остатки Бедроградской гэбни
      держали паузу, в упор глядя на фалангу.</p>
    <p>Тот практически заёрзал — но не
      на месте, а так, как будто любопытная душонка заёрзала
      в ограничивающем её возможности теле.</p>
    <p>Гошка издевательски приподнял над
      столом заполненную и подписанную форму, пробежал её сосредоточенно глазами,
      изобразил сомнение и нерешительност’.</p>
    <p>Фаланга аж
      вытянулся:</p>
    <p>— Что это у вас, Гошка
      Петюньевич?</p>
    <p>— Прошение о применении права
      нейтрализации четвёртого, — громыхнул Соций.</p>
    <p>Гошка в молчании опустил голову.</p>
    <p>— В адрес Зябликова Андрея Эдмундовича?
      — очен’ серьёзным тоном уточнил фаланга, и Бахта Рука
      всё-таки признался себе, что тайно восхищается особым профессиональным чувством
      юмора фаланг.</p>
    <p>— В адрес покойного хэра Ройша! —
      неожиданно ляпнул он, улыбнулся растерянно и
      болезненно, покосился на Гошку и Соция, потом обратно на фалангу и добавил: —
      Простите, нервы.</p>
    <p>Бахта Рука думал, что насмехается
      умышленно, потому что так здорово насмехат’ся над
      фалангами, потому что остатки Бедроградской гэбни так быстро породили такой
      эффективный план спасения ситуации, потому что радост’
      бюрократической победы так и прёт наружу. Но,
      почувствовав плечом нехватку за столом ещё одного человека, понял — а вед’ и правда, нервы.</p>
    <p>Лихорадочный
      ден’, будто в городе не чума, а столбнячное пихтское
      бешенство. Бросает из крайности в крайност’,
      заставляет хотет’ пробежат’ся
      по коридором здания Бедроградской гэбни с воплем, разбит’
      случайное окно, надет’ фаланге на голову мусорное ведро, расстрелят’
      из табельного оружия кого-нибуд’ неудачно попавшегося
      под руку…</p>
    <p>Они применяют к Андрею ПН4, они уже сказали это фаланге.</p>
    <p>— …и таким образом выяснили, что
      Андрей Эдмундович от лица Бедроградской гэбни нелегально заказывал смертельно
      опасный вирус у специалиста из Медицинского Корпуса, — видимо, Соций только что
      изложил фактический повод для ПН4, последовательно
      отсекая все компрометирующие детали, как умеет отсекат’
      только он — армейская выучка отчитыват’ся перед чужим
      штабом, если воз’мут в плен. Связки фактов, стройные
      цепочки событий, и в них почти ни одного звена, способного разрушит’ легенду.</p>
    <p>Бахта Рука всё пропустил, глядя
      куда-то перед собой.</p>
    <p>— Соций Всеволодьевич, вы
      говорите страшные вещи, — прогнусавил фаланга, чут’ раскачиваяс’ всем корпусом, как дерево на слабом ветру. — И
      что же, по Бедрограду бродит смертельный вирус?</p>
    <p>— Пока такового не обнаружено,
      — уверенно заявил Гошка.</p>
    <p>Бахта Рука добавил:</p>
    <p>— Хотелось бы верить, что
      заражение не было произведено. Но мы продолжаем поиски.</p>
    <p>Можно было бы прямо сейчас выдат’ фаланге адреса всех зачумлённых микрорайонов —
      или
      хотя бы одну из пяти точек на карте. В субботу травили только дом Ройша и
      парочку рядом, но это и так добрая половина крохотного Тощего переулка. В
      воскресенье, пока Гошка катался за Андреем, ещё одно место, новостройки у
      бывших метелинских мануфактур — самая окраина Старого Города, подальше от
      Университета, но поближе к Ройшу. Ну, чтоб можно было поверит’, что болезн’ сама перекинулас’. Вчера — сразу
      три очага распространения заразы, эпидемия должна развиват’ся
      скачкообразно. Сегодня уже ничего не успели, вирус-то пропал.</p>
    <p>И лекарство пропало, а значит, сказат’ сейчас, что эпидемия ест’ — выставит’ себя
      такими
      же слепыми котятами, какими они собираются выставит’ Университет:
      у нас что-то происходит, но нам нечем это лечит’.</p>
    <p>Вот к завтрашнему утру, когда в
      спешном порядке будет синтезирована хот’ какая-то партия лекарства, — можно будет доложит’, что заражение нашли. Предоставит’ средство
      борьбы с эпидемией (формула которого якобы была добыта у тавра-вирусолога,
      когда выясняли, где же Андрей) и преспокойно сдат’ свои
      полномочия — вместе с лекарством! — Комиссии по Делам Гэбен.</p>
    <p>У остатков Бедроградской гэбни ПН4, но они всё равно способны контролироват’
      ситуацию на вверенной территории, а Университет — нет. Из-за выкрутасов Андрея
      первоначальный замысел оброс драматизмом, но сильно отклонят’ся от курса никто не собирается — и точка.</p>
    <p>В помещении для допросов всё по
      служебной инструкции: только стол гэбни, стул допрашиваемого
      — в двух с половиной метрах строго напротив — и окно. На этот стул фаланга в
      жизни не сядет, он облюбовал подоконник — смотрит на остатки Бедроградской
      гэбни сбоку, задумчиво поглаживает крашеное дерево рамы.</p>
    <p>— В сложном же положении вы
      собираетесь передать Бедроград во временное распоряжение Комиссии… — на лице
      фаланги никак не хотела проступат’ озабоченност’,
      хотя он явно очен’ старался.</p>
    <p>— Мы работаем в экстренном
      режиме, — отрезал Бахта Рука, — и собираемся сделать всё, что в наших силах,
      чтобы как можно скорее поправить «сложное положение», если таковое имеется.</p>
    <p>— Похвальное рвение, Бахтий
      Цоевич, — гаденько улыбнулся фаланга.</p>
    <p>Всё, что
    связано с фалангой, казалос’ гаденьким — его упорство
    в обращении к головам гэбни по отдельности, например. Даже собственное
      имя, давным-давно в документах изменённое Бахтой Рукой на росский манер (на
      публичной должности так проще — меньше народу путается), звучало как-то
      скользко, омерзительно, совсем не похоже на настоящее.</p>
    <p>— Мы прекрасно понимаем, — Гошка
      говорил безукоризненно вежливо, но не пытался скрыт’
      покровительственного, наглого сочувствия фаланге, — что <i>никто</i> не может разобраться с экстремальной ситуацией в городе
      быстрее и эффективнее, чем городские власти. — Он достал из кармана спички,
      чиркнул одной и тут же затушил, не прикурив. — Но, увы, у городских властей
      тоже бывают трудности.</p>
    <p>Последнее высказывание было даже
      большей ложью, чем всё это пока что не обнаруженное заражение. Не бывает таких
      трудностей у Бедроградской гэбни — даже у неприкаянных её остатков! — чтобы они
      надумали сложит’ руки с табельным оружием и ничего не делат’.</p>
    <p>Но раз Гошка решил
      добавит’ драмы, пуст’ уж фаланга считает, что они раздавлены — тем веселее завтра
      будет отдават’ ему Бедроград вместе с готовым
      лекарством.</p>
    <p>— Зря вы полагаете, что нам легко
      далось это решение, — будто бы разочарованный чёрствост’ю
      фаланги, которому неведомы чудеса синхронизации, тоскливо усмехнулся Бахта
      Рука. — Только другого выхода у нас нет.</p>
    <p>— Продолжать
    работать втроём при критической рассинхронизации с четвёртым — безответственно
    и противоречит всем служебным инструкциям, — Соций далёк от языка драмы, но
    неповоротливый, зато такой безапелляционный язык здравого смысла тоже подойдёт.</p>
    <p>Фаланга с одобрением кивнул лично
      Социю — разделяет, зараза, головы гэбни, игнорирует синхронизацию. И вед’ всё
      это умышленно: чтобы сбиват’, тормозит’, мешат’ думат’ тем, кто давно
      привык чувствоват’ себя одним целым.</p>
    <p>— Кри-ти-ческая
      рас-син-хро-ни-зация, — фаланга даже запрокинул
      голову, чтобы получше распробоват’
      слова. — Представляете себе, что такое расследование обстоятельств критической
      рассинхронизации?</p>
    <p>— Сталкиваться не приходилось, —
      ядовито улыбнулся Гошка.</p>
    <p>— А вот Андрей Эдмундович в предыдущей своей гэбне едва не столкнулся, —
      отзеркалил его
      улыбку фаланга.</p>
    <p>Ну
      начинается.</p>
    <p>— Мы в курсе, — Бахта Рука
      раздражённо сверкнул глазами.</p>
    <p>Несложно устраиват’
      спектакл’ перед фалангой, несложно злит’ся на Андрея — он заслужил,
      он творит леший знает что, косу бы ему отрезат’, да
      косы нет, только хвост. Сложно — отучит’ себя защищат’ Андрея от нападок за набившую уже оскомину историю
    с «его предыдущей гэбней», как защищали много лет подряд.</p>
    <p>— Кто ж не в курсе развала
      Колошмы, — покладисто ответил фаланга. — И что, не страшно было в самом начале?</p>
    <p>Кирпича эта кислая рожа просит, кирпича.</p>
    <p>Бахта Рука почувствовал, как
      слегка придвигается к нему Гошка, касается под столом коленом — не служебный гэбенный код, не осмысленная тактильная реплика, а так,
      просто — прос’ба
      успокоит’ся, не встават’ на дыбы.</p>
    <p>Фаланга имеет право задават’ свои дурацкие вопросы, они всего лишь част’ того самого расследования, на которое остатки
      Бедроградской гэбни подписалис’ сами. Понят’,
      почему развалилас’ гэбня,
      — важно, нужно, это не досужее любопытство. Чтобы фаланга с Комиссией сделали, что
      должны, приходится открыват’ перед ними не только
      рабочие архивы, но и душу — такие уж правила игры. Протокол такой, выражаяс’
      иначе.</p>
    <p>— Раз Бюро
    Патентов и фаланги сочли возможным после развала Колошмы назначить Андрея на
    службу в другую гэбню, — спокойно ответил Соций, — у нас не могло быть никаких
    оснований сомневаться в его пригодности.</p>
    <p>Это любимая армейская песня Соция
      — старшему по званию, то ест’ уровню доступа, виднее, а мы усердно исполняем
      приказы, вот и всё.</p>
    <p>— Особенно печально теперь
      сознавать, что назначившие могли и ошибиться, —
      неожиданно наехал на руководящую верхушку Гошка.</p>
    <p>— Вы знаете <i>правду</i> о роли Андрея Эдмундовича в тогдашних событиях? — удивлённо
      приподняв брови, перешёл в наступление фаланга.</p>
    <p>Гошка посмотрел на него как на
      умалишённого, несчастненького дурачка совсем без мозгов, который знат’ не знает
      ничего о синхронизации, а вслух тоже обозвал
      безмозглым, но совершенно по другому поводу:</p>
    <p>— Мы знаем о бытующем мнении, что правда про развал Колошмы — не наш уровень
      доступа.</p>
    <p>— Ну вы
      же должны понимать, — возразил фаланга якобы извиняющимся тоном, — что тогда,
      почти десять лет назад, вся эта информация была взрывоопасна. На тот момент
      66563…</p>
    <p>Ну вот
      опят’ разговоры ни о чём — как проводка и идеология за лето.</p>
    <p>Долбаный 66563 для госаппарата —
      универсальный способ пугат’ детей, как степная чума
      для медиков. Кое-кто даже пугается: как же, заключённый-без-имени, убивший
      самого Начал’ника Колошмы! Да кто бы ни убил Начал’ника, правильно сделал — в гэбне не должно быт’ начал’ников, так не живут и не работают, так умирают.</p>
    <p>История простая как варёный
      пихтский лапот’: гэбня Колошмы двадцат’
      лет подряд работала криво, ходила разными составами под одним человеком, его и
      головой-то гэбни не назовёшь, разве что злокачественным наростом. Потом
      посадили 66563 — тоже отличная история о непрофессионализме, но уже Столичной гэбни, чтоб им, — посадили, и Колошма развалилас’. Начал’ник Колошмы от
      бесед с 66563 слетел с катушек, ослабил хватку, остальная
      гэбня думала упечь его подальше, он думал упечь их, 66563 сидел себе на стуле
      допрашиваемого и провоцировал, а потом Начал’ника
      кто-то застрелил — при полной-то Колошме понаехавших фаланг и их псов из
      Силового Комитета!</p>
    <p>Поучительно, почти притча о том,
      что бывает с ненормальными гэбнями, и эта притча нравилас’
      бы Бахте Руке — если бы в ней не был замешан Андрей, их Андрей.</p>
    <p>Андрею, вернувшемуся из степи в
      Бедроград, было девятнадцат’ лет — это даже чут’
      меньше, чем срок неофициального правления того самого Начал’ника Колошмы.</p>
    <p>Девятнадцат’
      лет — аккуратная бородка, чтобы казат’ся старше, значительней,
      и желание всё на свете сделат’ правильно, нигде не ошибит’ся, всем угодит’. Бахта Рука, Соций и Гошка посмеивалис’ над ним поначалу: такой отрядский отличник,
      выучивший по учебникам всё, что нормальные люди познают методом проб и ошибок.</p>
    <p>Взвалил на себя непосильную ношу
      — закрыт’ вопрос 66563 — и неожиданно справился, за что и был повышен до
      Бедроградской гэбни. За такое пойди не повыс’, там
      столько вранья намешали, лезло из всех щелей, грозило прорват’ся наружу и подгадит’ всем.</p>
    <p>А всё она, идеология за лето.</p>
    <p>66563 жил в Бедрограде, работал в
      Университете и выпустил пару идеологических книжек, идеологичност’
      которых подтвердил аж Хикеракли — тогда ещё живой член
      Революционного Комитета, второй уровен’ доступа, выше
      только Бюро Патентов. Столичная гэбня хотела прибрат’
      66563 к рукам, сделат’ своим полуслужащим — прямо в
      Бедрограде, в Университете, где не было ещё никакой собственной гэбни, а следовательно, Университет тогда подчинялся гэбне
      Бедроградской.</p>
    <p>Вербоват’ людей на чужой территории
    — глупая ошибка столичных, и столичные поплатилис’
    потом, хорошо поплатилис’ (Бахта Рука до сих пор
    вспоминает ту оперативную вылазку), но сначала поставили капкан на этого самого
    66563. Нашли каких-то сумасшедших, замаскировали под других сумасшедших,
      слепили из них якобы террористическую якобы контрреволюционную группировку,
      дали книжонку 66563 и отправили взрыват’ Первый
      Большой Переворот. Чтобы потом пригласит’ 66563 на беседу и рассказат’,
      что он в заднице, его именем проект государственной
      значимости едва не сорвали — так что тепер’ либо к ним, столичным, в
      полуслужащие, либо на Колошму. Это всё не ради его одного делалос’,
      это война столичных и бедроградских — заело некоторых, что Первый
      Большой Переворот отдали реализовыват’ Бедрограду.</p>
    <p>Ну а 66563 воз’ми да и выбери Колошму. Чего его
      дёрнуло — никто
      не знает, но столичные осталис’
      с носом. Столько наворотили, а в результате своими руками посадили по кое-как состряпанному обвинению публичную фигуру, даже хуже — автора
      части современной государственной идеологии. Его потому по имени и не называют
      до сих пор, что гражданским — да что там, и госслужащим невысоких уровней
      доступа — лучше не знат’, какие люди иногда на
      Колошму попадают. Выплыви это в массы, началис’ бы такие
      кривотолки — никто бы костей не собрал.</p>
    <p>Но тут объявился Андрей,
      отрядский отличник с трогательной тетрадочкой для записей на допросах в руках,
      привёл хлипкие обвинения 66563 в порядок, фактически придумал сам, за что того
      стоило изначально сажат’, закрепил эффект сваленным
      на 66563 убийством Начал’ника Колошмы.</p>
    <p>Поступил на службу в
      Бедроградскую гэбню и узнал, что своими действиями прикрыл от гнева Бюро
      Патентов зарвавшихся столичных. Жутко злился, смешной.</p>
    <p>— Андрей Эдмундович ведь, сам
      того не зная, помог Столичной гэбне, — пока что разговор с фалангой далеко от
      Колошмы не ушёл, — <i>или зная</i>?</p>
    <p>Остатки Бедроградской гэбни хором
      загоготали.</p>
    <p>— Богатая у вас фантазия, —
      первым очухался Гошка. — Такого мы ему ещё не вменяли!</p>
    <p>— Не хватит оснований для ПН4 — обязательно используем эту идею, — истерически
      покивал
      Бахта, вспоминая вечное Андреево «как их земля носит, да лучше б тогда мои
      обвинения 66563 завернули».</p>
    <p>Фаланга всё поглядывал,
      поглядывал на них со сдержанным любопытством и вдруг поставил диагноз:</p>
    <p>— Вы очень хорошо относитесь к
      Андрею Эдмундовичу.</p>
    <p>Повисла неудобная пауза, за
      которую Бахта Рука успел осознат’, как глупо всё это
      выглядит со стороны: сидят три человека, честно пытаются навсегда отрезат’ от себя
      четвёртого, но, отвечая на каждый вопрос
      об этом четвёртом, скатываются в воспоминания — правильные, весёлые, прямо-таки
      тёплые. Как будто нет никаких обнесённых складов и побега в неизвестном
      направлении.</p>
    <p>— Мы работали вместе девять лет,
      — Соций закурил. — Какого ещё отношения можно ожидать?</p>
    <p>Фаланга неопределённо качнул
      головой:</p>
    <p>— Расследованию необходимы все
      подробности происходивших внутри гэбни процессов. Это займёт много времени, да
      и Комиссия ещё не собрана. А сейчас у нас с вами так, общение с целью составить
      приблизительную картину, — он посмотрел в потолок, пожевал губу, изобразил непринуждённост’
    и светским тоном добавил: — Ну и кто убил Начальника Колошмы?</p>
    <p>Вот прикопался.</p>
    <p>— Не Андрей, — дружелюбно и
      односложно ответил Гошка.</p>
    <p>— Вряд ли вас на самом деле
      волнуют наши фактические знания о ситуации, имевшей место вне сферы нашей
      ответственности, — осудил методы фаланги Соций. — Спрашивайте прямо.</p>
    <p>— Хорошо, — согласился тот. — Как вы могли доверять человеку, с некоторой вероятностью
    причастному к покушению на голову своей гэбни?</p>
    <p>Да, был там скользкий момент за
      три дня до непосредственно смерти Начал’ника — будто
      бы кто-то из гэбни собирался травит’ его ядом не ядом,
      но какой-то дрянью. А с подобной дрянью Андрей всегда
      умел обращат’ся, недаром он потом заведовал всеми
      медицинскими вопросами в Бедроградской гэбне.</p>
    <p>— Очень просто, — Гошка пожал
      плечами. — Весь развал Колошмы произвёл на Андрея сильное впечатление — первая
      гэбня, да и возраст такой, подходящий для впечатлительности. Отсутствие
      синхронизации, перекос власти в сторону кого-то из голов, несогласованная
      самодеятельность — это с тех пор его любимые кошмары.</p>
    <p>— За которые к нему сейчас
      применяют право нейтрализации четвёртого, — резонно заметил фаланга.</p>
    <p>Гошка пожал плечами ещё
      выразительней:</p>
    <p>— Фрайдизм. «Страхи рано или
      поздно овеществляются и поглощают личность» — или как там в оригинале?</p>
    <p>Иногда у Гошки прорезается
      эрудиция, хот’ он и твердит, что книжки пишут-читают те, у кого силёнок не
      хватает действительно важными делами занимат’ся. То,
      что сам он при этом вес’ма и вес’ма
      начитан, — производственная тайна, известная разве что родной гэбне.</p>
    <p>Бахта Рука некстати вспомнил, что
      Андрей-то Фрайда недолюбливает. Оскорбился бы, если б узнал, что его тут с
      фалангой с позиций фрайдистских неврозов разбирают.</p>
    <p>— Да и без фрайдизма всё ясно, — Бахта Рука
      понимал, что говорит это как будто бы для Андрея, чтобы тот не возмущался и не
      шипел, когда потом за каким-нибуд’ длинным пойлом
      вроде джина они будут вспоминат’ ПН4.</p>
    <p>Вспоминат’
      — будут, но не с Андреем.</p>
    <p>— Поделитесь мнением, Бахтий
      Цоевич, — не давал расслабит’ся
      фаланга.</p>
    <p>— Колошма ест людей, — просто
      сказал Бахта Рука, точно этих трёх слов достаточно, чтобы понят’, о чём он, но
      одумался и пояснил: — Специфическое учреждение со специфическими условиями,
      которые накладывают отпечаток. Не могут не накладывать, ничьей личной вины или
      слабости тут нет. Место такое — сами знаете, степняки его дурным обзывали до
      всякой колонии. Это всё предрассудки, конечно, но колония-то сильно лучше не
      сделала. Там ничего нет, только камеры и степь, и люди от этого сходят с ума
      потихоньку. И не одни заключённые, жизнь гэбни Колошмы несильно лучше —
      допросы, допросы, допросы, а больше ничего, <i>вообще
        ничего</i>. Лучше ничего и не хотеть, всё равно ведь не будет — разумнее сразу
      отказаться от любых потребностей. И поэтому даже те, кто вырвался, остаются со своей персональной Колошмой в голове, от такого уже не
      избавишься.</p>
    <p>Бахта Рука не соврал ни в чём,
      кроме вывода. В то, что персональную Колошму не
      вытрясти из головы, он не верил — он же видел, как это было у Андрея.</p>
    <p>И саму Колошму тоже видел,
      включая одиозного Начал’ника — давно, до Андрея и
      даже до Соция и Гошки, в совсем другом составе Бедроградской гэбни Бахта Рука
      катался туда по делу — вызволят’ служащего, попавшего под раздачу ни за что.</p>
    <p>Колошма была жуткая, жуткая и
      вязкая, не спасала даже широкая, просторная степ’.</p>
    <p>Наоборот, получалос’
      только хуже — больше пустоты, от которой не спрятат’ся
      и не убежат’, из которой не ускакат’
      на коне и не уехат’ на такси. У степняков никогда не
      было лошадей, а у гэбни Колошмы — служебного транспорта, им незачем, потому что
      некуда. Им не сест’ за рул’,
      не погонят’ по шоссе, пяляс’ на убегающие звёзды
      фонарей, снимая накопившееся напряжение. Не завалит’ся в заведение с музыкой, выпивкой, хорошим
      меню и незнакомыми, разодетыми, шумными людьми, не зайти в киоск за газетой, не
      перекинут’ся парой приветливых и бессмысленных реплик
      с девушкой на кассе.</p>
    <p>Газеты на Колошму привозят с
      курьером и двухдневным опозданием.</p>
    <p>Вернут’ся оттуда в нормальную жизн’,
      тем более в Бедроград, — праздник само по себе. У Андрея были жадные глаза и очен’, очен’ твёрдое понимание,
      как много на самом деле значит всё то, чего на Колошме у него не было: газеты,
      заведения, такси — и синхронизация.</p>
    <p>— Травма Колошмой, говорите? —
      привычка фаланги жеват’ губу раздражала.</p>
    <p>— Даже ничего
      не понимая в травмах и прочей психологии, — весомо пробасил Соций, — стоит
      иметь в виду, что опыт никуда не девается. Если опыт рассинхронизации уже есть,
      повторять его менее страшно, нежели в первый раз. Хотя бы с бюрократических
      позиций.</p>
    <p>Что они все трое сейчас несут?</p>
    <p>Говорят одно, думают другое, решили трет’е — и
      наматывают эти
      круги без конца. Фаланга понукает их кнутом своих наводящих вопросов,
      рассматривает как под микроскопом, никуда не торопится — хочет узнат’, как же так
      вышло, что в Бедроградской гэбне один
      голова заказал смертельный вирус, устроив критическую
      рассинхронизацию. А Бедроградская гэбня, её остатки, очен’
      хотят, чтобы фаланга не узнал, что вирус общий, синхронизированный, но ничего
      это не отменяет, раз Андрей сбежал.</p>
    <p>Вот и ходят кругами.</p>
    <p>— Случались ли ранее поводы
      подозревать Андрея Эдмундовича в намеренном нарушении фактической
      синхронизации?</p>
    <p>Глупые служебные инструкции делят
      синхронизацию на фактическую, физическую и психическую — как будто ест’
      разница, как будто одно не завязано на другое, как будто сокрытие «значимых
      производственных сведений» не мешает дышат’ в одном
      ритме.</p>
    <p>И да, поводы случалис’,
      только очен’ давно, в самом ещё начале — и кому, как
      не фаланге, об этих поводах известно больше всех.</p>
    <p>Но Бедроградская гэбня пережила,
      забыла и выкинула их к лешему — потому что нечего раздуват’
      из комара бегемота, тем более из мёртвого комара.</p>
    <p>А вопросы всё не кончаются, скатываются
      в совсем уж мелкие подробности жизни гэбни города Бедрограда. Мелкие и местами
      болезненные, вед’ вот так вот сидет’ перед фалангой и рассказыват’,
      сколько сигарет в среднем выкуривает Андрей за ден’,
      сколько часов ему нужно спат’, чтобы нормально функционироват’, как именно он огрызается, когда ему мешают
      за рулём, — это какое-то извращённое издевательство.</p>
    <p>Но надо терпет’
      и отвечат’, надо доказыват’,
      что Бахта Рука, Соций и Гошка в курсе, что такое нормальная работа гэбни, что
      они способны будут синхронизироват’ся со следующим
      четвёртым, которого Комиссия назначит вместо Андрея.</p>
    <p>Хорошая гэбня должна
      быт’ одним целым. Идеальная —&nbsp;должна перестат’
      им быт’, если возникла необходимост’.</p>
    <p>— Расскажите что-нибудь об
      эротических предпочтениях Андрея Эдмундовича, — с гаденьким интересом
      осведомился фаланга. Почти как будто для себя спрашивал, это вед’ так,
      предварительная беседа.</p>
    <p>Новым четвёртым
    не назначат кого-нибуд’ похожего на Андрея, голов гэбни
    подбирают не по принципу заменяемости.</p>
    <p>— Пёрышки, — с наглой
      мечтательност’ю ответил Гошка.</p>
    <p>— Пёрышки?</p>
    <p>— Подключите фантазию.</p>
    <p>Фаланга подобрался, выплюнул свою
      многострадальную губу. Ничего вроде бы не изменилос’,
      три головы Бедроградской гэбни всё так же сидели за столом рядом с пустым
      четвёртым стулом, но фаланги — профессионалы в своём нелёгком деле капанья на
      мозги, фаланги знают, когда терпение исчерпано и спрашиват’
      дальше — уже значит давит’.</p>
    <p>—&nbsp;К сожалению, я не могу
      принять ваш запрос на применение права нейтрализации четвёртого.</p>
    <p>Что?</p>
    <p>Бахте Руке не нужно было косит’ся, чтобы знат’, — Соций не шелохнулся, только молча уставился на
      фалангу в ожидании разъяснений; Гошка чут’
      прищурился, выпустил дым медленнее обычного; и только сам он, Бахта Рука,
      позволил себе лёгкий вздох удивления — значит, ему и спрашиват’,
      фаланга самостоятельно распространят’ся не станет.</p>
    <p>— На каком основании?</p>
    <p>— Существует <i>единственное</i> законное основание не принять подобный запрос, —
      укоризненно покачал головой фаланга, слегка даже причмокнул языком, изображая
      бездны разочарования.</p>
    <p>У Бахты Руки загудели мышцы — это
      напрягся Гошка, швырнул на фалангу злой взгляд:</p>
    <p>— Андрей задержан лицами более
      высокого уровня доступа.</p>
    <p>— Стоит ли предположить, что
      третьего? — мгновенно включился Соций.</p>
    <p>Бахта Рука и знал головой, и
      ощущал всем телом, что Соций просто подхватывает реплики, а сам тоже не
      понимает, о чём говорит. Зато понимает, что Бедроградская гэбня снова перешла в
      нападение.</p>
    <p>У гэбни нет никаких магических
      способностей, они не умеют обмениват’ся мыслями,
      Гошка не может передат’ им полувздохом
      годы обучения на юрфаке, — но для того, чтобы посмотрет’
      на фалангу сумрачно, <i>требуя</i> объяснений, это и не нужно.</p>
    <p>— В случае если один из голов
      подавшей прошение о применении права нейтрализации четвёртого гэбни ограничен в
      перемещениях и коммуникации по решению лиц более высокого уровня доступа <i>до
        подачи прошения</i>, — гнусавой
      скороговоркой выдал фаланга, — оное прошение отклоняется.</p>
    <p>Настал черёд Бахте Руке слегка коснут’ся колена
      Гошки — просто
      так, на всякий случай, чтобы не взорвался. Фаланга того и ждёт, затем и водил
      кругами по бесконечным вопросам. Тоже просто так, без особого смысла — потешит’
      своё гаденькое профессиональное чувство юмора. Сперва
      вытянут’ всю душу, посмотрет’, как смешно дёргаются
      подопытные, как тяжело и мучительно даётся им вес’ этот разговор, — а потом
      заявит’, что всё было впустую. Андрей, <i>их</i> Андрей, уже задержан кем-то, и с ним ведутся какие-то другие разбирательства, а
      вся драма с ПН4 — коню под хвост.</p>
    <p>Более высоких, чем у
      Бедроградской гэбни, уровней доступа всего пят’: Бюро Патентов
    (первый), наследники Революции (ныне — вроде бы — отсутствующие; второй),
    фаланги (третий), Силовой Комитет (четвёртый), Радио- и Медицинская гэбни
    (пятый).</p>
    <p>У кого-то из них Андрей, их
      Андрей.</p>
    <p>—&nbsp;С вами свяжутся, —&nbsp;не
      скрывая удовольствия бросил фаланга и вразвалочку
      направился к выходу.</p>
    <p>&nbsp;</p>
    <p>— Убиват’.
      И выкалыват’ глаза, — почти правдоподобно отчеканил
      Гошка и посмотрел на Бахту Руку в поисках хвалёной таврской агрессии, за
      которую всех без разбору тавров до сих пор иногда записывают в общественно
      опасные элементы.</p>
    <p>Вопрос «ну и что делать?» был,
      наверное, и правда неуместным — известно, что.
      Неизвестно пока, кого и кому — ну, если не считат’
      заметания следов вокруг канализаций, но это мелочи, рутинная работа, никакого
      тебе «выкалыват’ глаза».</p>
    <p>В подвале Института госслужбы,
      куда они заехали лично выдат’ аттестационное
      спецзадание паре курсантов, было темно и дымно. Бедроградская гэбня давно
      сделала из этого подвала свой служебный кабак с
      музыкой, выпивкой, подвозимой через институтскую кафешку, и складом разнокалиберного
      оружия — тренироват’ся.</p>
    <p>Лампы верхнего света Соций
      простреливал сразу же, как их опят’ меняли — он был убежден, что тренироват’ся имеет смысл либо в полут’ме,
      либо вообще вслепую, либо уж с каким-нибуд’
      прожектором, б’ющим прямо в
      глаза. А при нормальной видимости любой дурак в цел’ попадёт.
    </p>
    <p>— Допивай
      давай, — кровожадно зыркнул Гошка на джин безо
      всякого тоника в руке у Соция.</p>
    <p>Они хотели сегодня стрелят’ по бутылкам — они будут стрелят’
      по бутылкам, ящик с пустой стеклотарой сейчас спустят из кафешки,
      а фаланги, эпидемии, Университет и прочие мерзости пуст’ подождут. Всего пару
      часов, может, даже меньше — пока не отзвонятся
      отправленные на слежку курсанты, не доложат, в каком направлении выезжат’.</p>
    <p>Фаланги «с ними свяжутся», а до
      тех пор из важных осталос’ только одно дело — плёвое,
      но ни курсантов, ни младших служащих на него не пошлёшь.</p>
    <p>Соций сделал пару внушительных
      глотков и подошёл к большущей карте Бедрограда, закрывающей почти всю дальнюю
      стену. Из карты в нескольких местах торчали гвозди — вечно закрытые от
      городских властей въезды в Порт, истфак БГУ им. Набедренных (и
    отдельно места проживания всех голов Университетской гэбни), бедроградская
    резиденция фаланг на Революционном проспекте, одиннадцатый детский отряд, где окопалас’ Медицинская гэбня, вокзал в Старом городе, куда
    прибывают поезда из Столицы. Иногда приходилос’
      забиват’ новые гвозди — как весной в дом Ройша,
      например.</p>
    <p>Соций приспособил тару из-под
      джина горлышком на тот гвозд’, который пронзал
      прямоугольник угодий фаланг, но Бахта Рука молча — чтоб не подсказыват’
      повернувшемуся спиной Гошке — покачал головой.</p>
    <p>Фаланги — слишком просто, туда и
      так хочется выстрелит’.</p>
    <p>Гошка угадает на первом же
      патроне, выиграет возможност’ не прикасат’ся к долбаному отчёту по
      идеологии за лето.</p>
    <p>Соций кивнул, подумал немного и
      выбрал дом Ройша.</p>
    <p>Это тоже не очен’-то заковыристо — с заражения
      Тощего переулка в
      субботу началас’ рассинхронизация с Андреем, — но уже
      лучше.</p>
    <p>Бахта Рука закрыл ладонью глаза
      Гошке, развернул его к карте, и Соций сразу скомандовал:</p>
    <p>— Пли!</p>
    <p>Звон разбитого стекла всё равно
      прозвучал в унисон с первым же выстрелом.</p>
    <p>С такой, как у них,
      синхронизацией ставит’ на кон идеологию за лето неосмотрительно.</p>
  </>
}

export default chapter06
