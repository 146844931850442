import React from 'react'
import {RouteComponentProps} from "react-router";
import PageNotFound from "./PageNotFound";
import {DiscussionEmbed} from "disqus-react";
import Helmet from "react-helmet";
import {Link} from "react-router-dom";

interface Article {
  title: string,
  image: string,
  text: React.ReactChild
}

const articles: {
  [key: number]: Article
} = {
  87: {
    title: 'Крыса Габриэль Евгеньевич',
    image: 'gabriel.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Вот он какой, Самый Первый Фанарт на «Чуму». Умилительный!</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://soranium.livejournal.com/' target='_blank'>soranium</a>
      </p>
    </>
  },
  88: {
    title: 'Фаланга у борделя',
    image: 'herr-reushch.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Безупречен во всём. И обратите внимание на ноготь!</p>
      <p style={{textAlign: 'center'}}>бай <a href='https://preswiter-z.livejournal.com/' target='_blank'>аспирант Виталий</a></p>
    </>
  },
  89: {
    title: 'Бровь и грифоны',
    image: 'grif2.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Они не урчат, вам показалось. Точно-точно.</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://soranium.livejournal.com/' target='_blank'>soranium</a>
      </p>
    </>
  },
  90: {
    title: 'Г. Е. в юности',
    image: 'fange.png',
    text: <>
      <p style={{textAlign: 'center'}}>А так наш товарищ, обладающий связями с настоящими японками из настоящей Японии,
        поздравил нас с Новым годом. Гм.</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vk.com/id138439096' target='_blank'>Саки-сан</a>
      </p>
    </>
  },
  91: {
    title: 'Умозрительный аксолотль-1',
    image: 'axolotl.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>И ещё одна глубоко новогодняя, праздничная прям картинка.</p>
      <p style={{textAlign: 'center'}}>бай <a href='https://nepurgen.livejournal.com/' target='_blank'>Алёна</a></p>
    </>
  },
  92: {
    title: 'Умозрительный аксолотль-2',
    image: 'axolotl2.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Потому что их не бывает слишком много!</p>
      <p style={{textAlign: 'center'}}>бай <a href='https://nepurgen.livejournal.com/' target='_blank'>Алёна</a></p>
    </>
  },
  93: {
    title: 'Страшные грифоны',
    image: 'grif3.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Тёмной-тёмной ночью, в тёмном-тёмном закоулке — ну и вы поняли.</p>
      <p style={{textAlign: 'center'}}>бай <a href='https://drakoncik.livejournal.com/' target='_blank'>drakoncik</a></p>
    </>
  },
  110: {
    title: 'С чего всё началось',
    image: 'dont-touch.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>По крайней мере — откуда началось.</p>
      <p style={{textAlign: 'center'}}>бай <a href='https://vyacheslavinsomnis.deviantart.com/' target='_blank'>vyacheslavinsomnis</a></p>
    </>
  },
  111: {
    title: 'В курилке',
    image: 'smoking.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Вот он какой, этот самый «дух взаимопонимания».</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  112: {
    title: 'Плюшевые',
    image: 'plush.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «— У тебя есть двадцать метров розового плюша?<br/>
        — Если нет, не докучай нам».
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://kasu-doll.livejournal.com/' target='_blank'>kasu_doll</a>
      </p>
    </>
  },
  113: {
    title: 'Ш-шаман',
    image: 'shaman.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Страшный, аки твои детские травмы.</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  114: {
    title: 'Алхимия',
    image: 'alchemy.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>...как форма государственного устройства.</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vyacheslavinsomnis.deviantart.com/' target='_blank'>vyacheslavinsomnis</a>
      </p>
    </>
  },
  116: {
    title: 'Пейзаж',
    image: 'landscape.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Утро Старого города. Тихое и очень правильное.</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vyacheslavinsomnis.deviantart.com/' target='_blank'>vyacheslavinsomnis</a>
      </p>
    </>
  },
  117: {
    title: 'Портрет',
    image: 'portrait.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Персонаж для внимательных. Протрите свои очки, какие бы стёкла в них ни были.</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://soranium.livejournal.com/' target='_blank'>soranium</a>
      </p>
    </>
  },
  120: {
    title: 'Пора валить',
    image: 'time-to-go.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «Почти всю жизнь провёл в Бедрограде, а моря, кстати сказать, ни разу не видел.<br/>
        И никто, кажется, не видел. Оно вообще существует, море это?»</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  121: {
    title: 'Символизм',
    image: 'symbolism.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Это, в общем-то, ещё цветочки.</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vyacheslavinsomnis.deviantart.com/' target='_blank'>vyacheslavinsomnis</a>
      </p>
    </>
  },
  122: {
    title: 'Витраж',
    image: 'stained-glass.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Поклонникам истории этой вашей Академии.<br/>
        (Порода пса не та, но кого волнуют подобные мелочи, когда ошейники так хороши!)
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vk.com/id9450143' target='_blank'>Мария Воробьёва</a>
      </p>
    </>
  },
  125: {
    title: 'Жертвы жертвы',
    image: 'victims.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «...и ему вдруг стало страшно, очень страшно, потому что понял: сейчас загорится всё вокруг жёлтым фонарным светом,<br/>
        и не останется ни тел, ни следов, никто не вспомнит и не сумеет доказать».
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  128: {
    title: 'Грустный',
    image: 'sad.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «Что Дима вообще делает в Бедрограде? То же, что и всегда.<br/>
        Хуйню какую-то».
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  129: {
    title: 'Четыре буквы, пятнадцать голов',
    image: 'heads.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Был у нас когда-то Наш Первый и Главный Недовольный Читатель. Был, был, а потом вдруг прислал фанарт.<br/>
        Редакция в недоумении и восторге.
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://nik-aragua.livejournal.com/' target='_blank'>nik-aragua</a>
      </p>
    </>
  },
  130: {
    title: 'И снова Г. Е.',
    image: 'yet-another-ge.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Которого много, как известно, не бывает.</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://morteraphan.livejournal.com/' target='_blank'>morteraphan</a>
      </p>
    </>
  },
  131: {
    title: 'В гостях, так сказать',
    image: 'guest.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «— Максим, хочешь немного древней мудрости даром?<br/>
        — Не всё в этом мире крутится вокруг тебя одного».
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vk.com/id9450143' target='_blank'>Мария Воробьёва</a>
      </p>
    </>
  },
  133: {
    title: 'Про достоинство',
    image: 'dignity.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>«В любом случае, грех жаловаться. Даже без проникновенной сцены Бровь только что увидела босого завкафа в плаще растерянного замзавкафа. После такого и умирать не страшно».</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://morteraphan.livejournal.com/' target='_blank'>morteraphan</a>
      </p>
    </>
  },
  134: {
    title: 'Портовая',
    image: 'x_6821a8bb.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «А папа будет удивляться, почему же так выросла её успеваемость, но она только улыбнётся, закурит портовую самокрутку (потому что ей подарят целый ящик — тоже в благодарность) и не ответит».
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vyacheslavinsomnis.deviantart.com/' target='_blank'>vyacheslavinsomnis</a>
      </p>
    </>
  },
  135: {
    title: 'Хипстанако',
    image: '1343926.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Очень альтернативное прочтение. И очень юное.<br/>
        Но главное — сапоги. Сапоги!
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://gorowek.livejournal.com/' target='_blank'>gorowek</a>
      </p>
    </>
  },
  136: {
    title: 'Двуглавый грифон',
    image: 'z_7d4e966f.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Низкий старт и мягкий хвост. И прочая двоякость!
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://klekettle.deviantart.com/' target='_blank'>klekettle</a>
      </p>
    </>
  },
  137: {
    title: 'Техническая документация',
    image: 'AlVajA5CAAIuOcB.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Строго говоря, это не фанарт. Это ещё один повод дать ссылку на тот самый <a href='https://twitter.com/rev_effigy' target='_blank'>твиттер</a>!<br/>
        (Который по-прежнему ведём не мы — чучело совершенно самостоятельно, соблюдайте технику безопасности!)
      </p>
      <p style={{textAlign: 'center'}}>
        бай они сами, ага
      </p>
    </>
  },
  138: {
    title: 'А глаза добрые-добрые',
    image: 'z_1e9d85e1.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Комментарии излишни. Мы прибережём их для некролога того счастливца, на которого они сейчас смотрят.
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://twitter.com/Jarvotazik' target='_blank'>еврей-печка</a>
      </p>
    </>
  },
  139: {
    title: 'Лепреконы',
    image: 'y_6d709e00.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        На наш взгляд, так могли бы выглядеть иллюстрации к адаптации «Чумы» для детей младшего и среднего отрядского возраста.<br/>
        И это прекрасно!
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  140: {
    title: 'Угадайте кто',
    image: 'z_fb2359a5.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>В воистину хардкорных кружевах!</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vk.com/id9450143' target='_blank'>Мария Воробьёва</a>
      </p>
    </>
  },
  141: {
    title: 'Детство',
    image: 'x_11cdea2a.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Возрыдаем же. Нет, ну правда!</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  142: {
    title: 'УБИТ\' Б',
    image: 'z_bbbd9655.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Наша редакция так громко ржёт, что даже и не знает, чего тут ещё сказать-то.<br/>
        В точку. В яблочко. В десятку!
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vk.com/id9450143' target='_blank'>Мария Воробьёва</a>
      </p>
    </>
  },
  143: {
    title: 'Серьёзный бизнес',
    image: 'z_54387457.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «Здрасьте, фантомные ощущения из чужой жизни: ходить по этому ковру босиком и трахать завкафа».
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://klekettle.deviantart.com/' target='_blank'>klekettle</a>
      </p>
    </>
  },
  145: {
    title: 'Руки',
    image: 'y_e55c810f.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «Дух взаимопонимания» продолжает волновать сердца наших уважаемых радиослушателей.<br/>
        Сегодня в студии альтернативное и провокационное прочтение!
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  146: {
    title: 'И разлетелся чайками',
    image: 'z_bfc19b37.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>О где, о где б мы были без птичьего символизма?</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  147: {
    title: 'Девочки (их более одной!)',
    image: 'y_00e6b0a2.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «А вот где-то в Хащине сидит себе Галка, с которой можно выпить пива и потрепаться о том,<br/>
        как они провели лето и кто самый симпатичный в этом семестре».
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  148: {
    title: 'Хлыст как педагогический приём',
    image: 'x_33006197.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «— Для начала мы потренируемся.<br/>
        — У нас теперь нет пистолетов, так что удар должен прийтись точно в цель.<br/>
        — Вот так — убедительно?»
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://diary.ru/~lambda-cat' target='_blank'>Lambda Cat</a>
      </p>
    </>
  },
  149: {
    title: 'Натюрморт',
    image: 'x_f18fc37a.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        А ещё это могла бы быть горсть, так сказать, родной земли<br/>
        для отсутствующих в каноне космонавтов.
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://preswiter-z.livejournal.com/' target='_blank'>аспирант Виталий</a>
      </p>
    </>
  },
  150: {
    title: 'Кровавую пищу клюёт',
    image: 'z_93694f58.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Наша редакция напоминает, что у росских грифонов таки жопы волков, а не львов,<br/>
        но какие глазки! Какие ушки! Мимими! (И какая Бедроградская Радиовышка!)
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://pineapplepeak.blogspot.com/' target='_blank'>DVan</a>
      </p>
    </>
  },
  151: {
    title: 'За спиной',
    image: '7v5yuZaLREw.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «Сумасшедший Ройш, с гиканьем бросающийся в атаку?<br/>
        ДА ПОЖАЛУЙСТА ЕЩЁ КУСОЧЕК»
      </p>
      <p style={{textAlign: 'center'}}>
        бай okodnol (увы, без ссылки)
      </p>
    </>
  },
  152: {
    title: 'Жизнь и не так размажет',
    image: 'okodnol.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Виктор Дарьевич, Гуанако, Дима, Гошка и Бахта Рука под влиянием не того, о чём вы подумали,<br/>
        а сложных жизненных обстоятельств!
      </p>
      <p style={{textAlign: 'center'}}>
        бай okodnol (увы, без ссылки)
      </p>
    </>
  },
  153: {
    title: 'В некотором роде спойлер',
    image: 'qJvycdEtzX8.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        «…А он говорил: не ходи в леса, там дикие звери, грифоны и лисы.<br/>
        Зачем тебе в леса, милая, хорошая?»
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  154: {
    title: 'Вот не надо тут!',
    image: '_Q574_Su5F4.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Парадный портрет, написанный по следам обсуждения <Link to='/node/153'>предыдущего фанарта</Link>.<br/>
        Мол, что характерно, без косы грифон.
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://niaho.deviantart.com/' target='_blank'>Niaho</a>
      </p>
    </>
  },
  174: {
    title: 'Грифоны популярны',
    image: '6VloVlSs_e0.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>…и величественны!</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vk.com/id20305064' target='_blank'>Lenka Ngs</a>
      </p>
    </>
  },
  175: {
    title: 'Очень, очень популярны грифоны',
    image: '9S1rwHZH-Rg_0.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>К тому же, они идеально подходят для геральдических целей.</p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://vyacheslavinsomnis.deviantart.com/' target='_blank'>vyacheslavinsomnis</a>
      </p>
    </>
  },
  176: {
    title: 'Комикс про неудобненькую ситуацию',
    image: 'm70Vpzw5KmA_0.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Потому что жизнь жестока.</p>
      <p style={{textAlign: 'center'}}>
        бай okodnol (увы, без ссылки)
      </p>
    </>
  },
  177: {
    title: 'Только ГЕ может тягаться с грифонами',
    image: 'ewJ3p2uClgM.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Отлично, между прочим, схвачено выражение!</p>
      <p style={{textAlign: 'center'}}>
        бай this_protector (и вновь без ссылки)
      </p>
    </>
  },
  178: {
    title: 'Декоративно-прикладной фанарт',
    image: 'DjLvbNAotn0_0.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Когда мы это увидели, мы потеряли дар речи. Рассмотреть в деталях можно <Link to='/node/181'>тут</Link>.
      </p>
      <p style={{textAlign: 'center'}}>
        бай <a href='https://morteraphan.livejournal.com/' target='_blank'>morteraphan</a>
      </p>
    </>
  },
  179: {
    title: 'Внимание, первый косплей!',
    image: 'erTM756KSGE.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>
        Очень много белых бумажек. Ещё больше — <Link to='/node/182'>здесь</Link>.
      </p>
      <p style={{textAlign: 'center'}}>
        бай… ой, как их много-то: <a href='https://vk.com/opi_chan' target='_blank'>модель</a>, <a href='https://vk.com/ggkun' target='_blank'>фотограф</a> и <a href='https://vk.com/id103362374' target='_blank'>художник с фотошопом</a>
      </p>
    </>
  },
  180: {
    title: 'Сиськи Охровича и Краснокаменного',
    image: 'H_CT5exwBpI.jpg',
    text: <>
      <p style={{textAlign: 'center'}}>Немного шокирующе, но ведь самое то!</p>
      <p style={{textAlign: 'center'}}>
        бай творческое объединение «<a href='https://namnestidno.livejournal.com/' target='_blank'>НАМНЕСТЫДНО</a>»
      </p>
    </>
  },
}



type PropTypes = RouteComponentProps<{
  id?: string
}>

export default ({ match }: PropTypes) => {
  if (!match.params.id) {
    return <PageNotFound />
  }

  const article = articles[+match.params.id]

  return <>
    <Helmet>
      <title>{article.title}</title>
    </Helmet>
    <main>
      <h1>{article.title}</h1>
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <img src={`/images/${article.image}`} alt={article.title} />
      </div>
      <p>{article.text}</p>
      <Link
        to='/fanart'
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold'
        }}
        tabIndex={0}
      >
        <img src='/images/up.png' alt='' style={{ margin: '0 5px' }} />
        Обратно в галерею фанарта
      </Link>
      <DiscussionEmbed
        shortname='bedrograd'
        config={{
          url: `https://bedrograd.megus.org/node/${match.params.id}`,
          identifier: `node/${match.params.id}`,
          title: article.title
        }}
      />
    </main>
    <footer>
      <img src='/images/site_footer.jpg' alt='' style={{
        maxWidth: '100vw'
      }} />
    </footer>
  </>
}
