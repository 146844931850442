import React from 'react'
import Markdown from "./Components/Markdown";

interface IPropTypes {
  sidebarPosition: 'left' | 'right' | 'none',
  markdown: string,
  sidebar?: React.ReactChild,
  noIndent?: boolean,
  full?: boolean
}

const MarkdownPage: React.FunctionComponent<IPropTypes> = ({ sidebarPosition, markdown, sidebar, noIndent, full }) => {
  return <div className={`page--${sidebarPosition === 'none' ? 'noSidebar' : sidebarPosition === 'left' ? 'sidebarLeft' : 'sidebarRight'}`}>
    <main className={`page--withText ${noIndent ? 'page--noIndent' : ''}`}>
      <Markdown text={markdown} full={full} />
    </main>
    {sidebar && <aside>
      {sidebar}
    </aside>}
    <footer>
      <img src='/images/site_footer.jpg' alt='' style={{
        maxWidth: '100vw'
      }} />
    </footer>
  </div>
}

export default MarkdownPage