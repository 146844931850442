import React from 'react'
import Helmet from "react-helmet";

export default () => <>
  <main>
    <Helmet>
      <title>Опаньки</title>
    </Helmet>
    <h1>Опаньки</h1>
    <div style={{
      textAlign: 'center'
    }}>
      <img src='/images/notification.jpg' alt='Извещение №404. Ваш запрос не может быть удовлетворён, поскольку он не соответствует имеещемуся у вас уровню доступа к информации. С уважением, Бюро Патентов.' />
    </div>
  </main>
  <footer>
    <img src='/images/site_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</>