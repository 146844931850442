import React from 'react'
import { IBookPage } from './index'

const prolog: IBookPage = {
  header: 'header_pr.jpg',
  title: 'Пролог',
  prevLink: false,
  nextLink: 'chapter01',
  content: <>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img src="/images/prologue.jpg" />
    </div>
    <br/><br/>
    <p>«Шшш, ты не дёргайся, не дёргайся, всё закончилось уже. Теперь только говорить».</p>
    <p>Уж конечно. И давно ли?</p>
    <p>«Сам будто не знаешь. Знаешь-знаешь. И я знаю. Только рассказывать всё равно придётся, такие здесь порядки».</p>
    <p>Где — здесь?</p>
    <p>Шаман сипло расхохотался, и от него пахнуло гнилым мясом; сунулся прямо в лицо своей красной восьмиглазой рожей и осклабился.</p>
    <p>«Помер ты. Теперь — рассказывай, чего натворил, мертвяк».</p>
    <p>Мертвяк фыркнул бы, но не чуял тела — ни рук, ни ног, ни губ. Чуял только запах: тяжёлый, кровавый, и ещё чего-то тошнотворно-сладкого —&nbsp;и видел на потолке узоры из человеческого мяса, из костей и травы.</p>
    <p>Да ничего мы пока не натворили, расслабься. Собираем силы.</p>
    <p>Кишки на потолке затряслись от шаманьего ехидного смеха.</p>
    <p>«Забыл, что ли? Вспоминай давай и рассказывай, мне спешить некуда».</p>
    <p>Ничего я не забыл. Хотя глюк красивый, конечно, такого со мной раньше не было. Не соврала Врата, когда угощала.</p>
    <p>А ты продолжай, зверушка.</p>
    <p>Шаман радостно запрыгал, шурша травяной юбкой и надетым поверх линий-разводов на теле плетёным наплечником — как у настоящего головы гэбни, разве что длиной до локтя. От его немытой башки воняло, как от склада контрабанды.</p>
    <p>«Думаешь, это всё не по-настоящему? Думаешь, ещё живой?»</p>
    <p>Зачем мне думать, я и так знаю.</p>
    <p>Поудив пятернёй в одной из своих мисок, шаман вытащил нечто липкое и свесил прямо перед мертвяком, капая ему на лицо. Какой-то внутренний орган — печень, кажется, хотя вне тела все они — просто куски мяса.</p>
    <p>«Твоя, твоя, не сомневайся».</p>
    <p>Докажи.</p>
    <p>Шаман покачал головой, как доктор, которому не сладить с упрямым пациентом, и снова пропал из поля зрения — копаться в потрохах. Через полминуты вернулся, гордо демонстрируя на ладони два игрушечных белых шарика; продают такие, вертеть в руках и успокаиваться.</p>
    <p>Два игрушечных белых глазных яблока.</p>
    <p>«Думаешь, не твои? Смотри, сам попросил».</p>
    <p>И сжал кулак.</p>
    <p>Весь свет —&nbsp;и без того тусклый и мутный — ухнул куда-то. У мертвяка кольнуло несуществующее сердце. Он попробовал похлопать веками — вверх-вниз — но ничего не менялось.</p>
    <p>Мертвяк ослеп.</p>
    <p>Поаккуратнее тут!</p>
    <p>Шаман захихикал.</p>
    <p>«Все вы, мёртвенькие, не верите, все сперва артачитесь, только вам же от этого хуже. Ты когда всё вспомнишь, всё расскажешь, думаешь, оно и закончится? Шиш тебе. Обернёшься — и будем говорить ещё раз, и ещё раз, и ещё дюжину, дюжину на дюжине, дюжину дюжин раз. А наговоришься со мной — пойдёшь к следующему, нас в Загробной гэбне четверо, как полагается. И со следующим тоже дюжину дюжин раз, и с третьим, и только после четвёртого упокоишься. Так что говори быстрее, вспоминай, а то застрянешь на всю вечность —&nbsp;а мне охота разве с тобой маяться».</p>
    <p>А что говорить, что вспоминать? Мы не сделали ничего. Собирались — не успели. Надо было навести порядок, а воевать запрещено — вот мы и придумали, как всех перехитрить. </p>
    <p>Болезнь, отрава — это не так и страшно, если лекарство заранее припасти. Кому-то придётся, конечно, немного помучиться — но это ничего, мелочь, мы успеем всех вылечить. Нам только и надо —&nbsp;припугнуть малость. Самую толику — разве больше одного дома потребно? Показать, что бывает, когда не за своё дело берутся. Ты подумай, зверушка: а если болезнь сама собой случится, без нас и без лекарства? Что от города останется? Вот и я думаю, нечего ждать. Это просто наглядная демонстрация. Настоящая, конечно, в ненастоящую кто поверит. Всё путём, смертельная болезнь. Только от неё никто не умрёт.</p>
    <p>«Не умрёт уже, некому больше. Померли все и так».</p>
    <p>И шаман хихикал, хихикал и шуршал своей юбкой, чавкал, ковыряясь в потрохах.</p>
    <p>«Не веришь? Не хочешь вспоминать? Не нравится?»</p>
    <p>Не верю, не нравится. Мы, может, и делали дурное —&nbsp;нет, не так; мы знаем, что делали дурное, и именно это даёт нам право действовать. Незачем прикидываться беленькими, когда время замараться. Те, с которыми мы воюем, —&nbsp;не лучше ничуть, только боятся сказать себе, во что ввязались, боятся признать, чего это всё стоит.</p>
    <p>«Ты не юли, на других стрелки не переводи. Другим другой черёд придёт, а сейчас с тобой разговор ведём. Допрос, ежели по-гэбенному. Так что ты подумай сам, подумай — виноват-то кто?»</p>
    <p>Не я. Я всегда знал, на что иду. Если теперь мне за содеянное кишками расплачиваться — пусть так, я знал, с самого начала знал; но не содеяно ещё ничего, не могло быть, не могли умирать от болезни, пусть и смертельной. Лекарство — есть, было. Должно было быть.</p>
    <p>«Глупый ты, мертвяк. Но это ничего, поумнеешь. От смерти все умнеют, деваться некуда. Ты рубаху-то не рви, ты головой подумай: неужто у вас всё схвачено было?»</p>
    <p>Было, а то как же не было. Столько времени готовились, всё проверили, отыскали болезнь, отыскали людей, время тоже отыскали. Если всё равно пошло криво — так суди нас всех, разбирай на части, только дело наше было правое. Дурное, но правое.</p>
    <p>«Всех судить — не пересудишься. После смерти всех нету, есть только ты и твои разговоры — за себя наперёд и говори, про всех успеется. Самому тебе оно зачем надо было?»</p>
    <p>Мертвяк не слышал —&nbsp;смеётся ли, говорит ли, — но надеялся, что смеётся. Глупый ты, зверушка восьмиглазая, хоть и нацепил наплечник. Это наш город, наша земля; те, с которыми мы воюем, пришли и отобрали. Мы что же, терпеть должны? И мы, и они — все под одним мечом ходим, только мы его видим, а они залепили глаза и в ус не дуют, творят, что им нравится; они думают, Бедроград — игрушка их, подарочек праздничный. Сколько нам ждать ещё —&nbsp;год, два, сорок — пока не разверзнется земля, пока сама собой не случится беда?</p>
    <p>Беду надо седлать и ехать. Нам, не им. Мы лучше умеем.</p>
    <p>«Умеете, а в седле не удержались. Она вас сама оседлала».</p>
    <p>Она —&nbsp;болезнь, она —&nbsp;смерть, она — просто научный факт, социальный фактор, катастрофа на клеточном уровне, ерунда на человеческом.</p>
    <p>«Она — чума».</p>
    <p>Она чума, да не та чума, ненастоящая; чумой в народе называют всякую болезнь, от которой умирают. Та чума тут ни при чём, зверушка ты узколобая.</p>
    <p>«Любая болезнь — та чума».</p>
    <p>А если бы и та — ну и что с того? Никто не пострадает — никто не пострадал, мы всех защитим — защитили. Мы нужны, чтобы защищать. Степная чума — не древнее подземное зло, не исполненное ненависти чудище; это просто болезнь, просто страшная болезнь, просто медицинский эксцесс. И это была не она, это был вирус, стерильный вирус из пробирки; и не могло из него выйти ничего дурного, мы всё сделали правильно, мы справились, мы сберегли город.</p>
    <p>А если бы и не сберегли — ну и что с того? Всё равно иначе никак, и кто ты такой, чтобы спрашивать, допрашивать, указывать, прав я или нет? Это я допрашиваю, это я решаю, как правильно; никто больше не станет, никто не берёт на себя этот груз. Мертвяк ударил бы кулаком по столу-алтарю, на котором лежал, только не было кулака, и ничего не было, одна тьма, и в ней — шлёпанье босых шаманских ног.</p>
    <p>«С-с-совесть заела? И правильно ест, поделом тебе. Это ж ты всё придумал, а кому платить — считать не стал, какое тебе дело».</p>
    <p>Я придумал? Нет, не я; мы.</p>
    <p>Мы придумали, мы сделали, нашим кишкам и по ветру болтаться. И не жалею я, нисколько не жалею себя, не жалею тех, кто платил; жалею лишь, что дело не сделалось, что всё впустую вышло. Пусть бы даже они победили, так бывает; но если одни только смерть и страх, и ничего больше, то, выходит, — зря, зря, всё зря, все —&nbsp;зрячие, слепые —&nbsp;в одну кучу, а должно быть не так, должно быть ясно, кто, где и зачем.</p>
    <p>«Ты ш-ш-что ж думаеш-ш-шь, тут и впрямь —&nbsp;победить можно?»</p>
    <p>Победить можно, всегда можно — или рассыпаться костьми по алтарю, пытаясь. Но если не воевать, так и будет — одна куча, и никто в конце не разберёт правого и виноватого, не станет разбирать. Может, я и умер уже, неважно; значит, другим, живым — надо воевать, и надо побеждать, ибо —</p>
    <p>Если не побеждать, то зачем тогда жить?</p>
  </>
}

export default prolog
