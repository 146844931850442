import React from 'react'
import {RouteComponentProps} from "react-router";
import dayjs from "dayjs";
import news from '../../Data/news'
import NewsArticle from "../NewsArticle/NewsArticle";
import Helmet from "react-helmet";

const ArchivePage: React.FunctionComponent<RouteComponentProps<{
  date?: string
}>> = ({ match }) => {
  const date = dayjs(match.params.date?.slice(0, 4) + ' ' + match.params.date?.slice(4) + ' 01')
  const endDate = date.add(1, 'month')
  const dateName = date.locale('ru').format('MMMM YYYY')
  const articles = news.filter(article => {
    const articleDate = dayjs.unix(article.timestamp)
    return articleDate.isAfter(date) && articleDate.isBefore(endDate)
  })
    .map(article => <NewsArticle key={article.id} article={article} />)

  return <>
    <Helmet>
      <title>{`Новости за ${dateName}`}</title>
    </Helmet>
    <main>
      <h1>{`Новости за ${dateName}`}</h1>
      {articles}
    </main>
    <footer>
      <img src='/images/site_footer.jpg' alt='' style={{
        maxWidth: '100vw'
      }} />
    </footer>
  </>
}

export default ArchivePage