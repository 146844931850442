import React from 'react'
import { IBookPage } from './index'

const chapter19: IBookPage = {
  header: 'header_d7.jpg',
  title: 'Глава 19. Если достаточно сильно зажмуриться',
  prevLink: 'chapter18',
  nextLink: 'chapter20',
  content: <>
    <h2>Университет. Шухер</h2>

    <img alt='' src="/images/19_shukher.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>…А он говорил: не ходи в леса,
      там дикие звери, грифоны и лисы. Зачем тебе в леса, милая, хорошая? Зачем тебе
      не сидится дома, рядом, здесь?</p>

    <p>И всё же ушла, ушла и не
      вернулась.</p>

    <p>&nbsp;</p>

    <p>Вчера вечером умер один из
      студентов, похожий на цаплю парень с третьего курса. Шухер прежде его в глаза
      не видел, ему не доводилось толком бывать на истфаке. Умер так нелепо, как
      только возможно: процедуры уже закончились, кровь у него забрали, и от нехватки
      крови закружилась голова, споткнулся на пороге борделя, ударился виском о
      косяк. Случайность, ничего больше.</p>

    <p>Шухер никогда не хотел слишком
      многого: читать свои лекции, не терять связь с Ванечкой, ходить по субботам в
      радиоклуб слушать постановки на хорошей технике и выписывать из Британии
      «Мировой научно-фантастический вестник» на желтоватой, с рождения будто старой
      бумаге. Просыпаться невольно с первыми лучами солнца, жмуриться на клён за
      форточкой и завтракать всегда яичницей из трёх яиц. Иметь немного уважения, немного
      уважать других и летом ездить на Козюльское озеро, сидеть у костерка с
      каким-нибудь пухлым журналом на коленях.</p>

    <p>Шухер никогда не хотел многого,
      но и это оказалось слишком, не правда ли.</p>

    <p>Ваня пропала. Накричала на него,
      выплеснулась из кабинета —&nbsp;и больше не показывалась. Ушла и не вернулась,
      и последнее, что он сказал ей, — какие-то глупые отговоры от медицинских
      процедур.</p>

    <p>Да пусть бы она тысячу раз лежала
      на койке медфаковского лазарета — только бы он её видел, только бы мог следить
      за пульсом и приносить ей чай с кафедры!</p>

    <p>На кровати сиротливо раскинулся
      широкий серо-зелёный свитер, почти в цвет глаз, у неё красивые глаза, красивее,
      чем у Шухера. Большой, с толстым воротом — она любит такие, всё время носит.</p>

    <p>…Чушь, конечно. Ваня любит что-то
      другое, что-то ещё, только он, Шухер, не знает, что, и не умеет спросить.</p>

    <p>— Могли ли мы подумать, что время
      пролетит так быстро? Кажется, ещё вчера каждый житель Всероссийского Соседства
      с нетерпением и замиранием духа припадал к радиоприёмнику, ожидая новостей с
      Первого Большого Переворота, и вот —&nbsp;уже совсем скоро, всего через
      несколько дней вся страна сможет отпраздновать десятилетний юбилей этого
      знаменательного события!</p>

    <p>Радио вещало само себе в
      маленькой серенькой кухоньке, где над плитой стоял Шухер.</p>

    <p>Он присутствовал на Первом
      Большом Перевороте, и Ванечка присутствовала —&nbsp;с отрядом, конечно. Это
      было прекрасное событие, действительно великое. Торжественность заставляла
      робеть, и всё же Шухер смотрел тогда на то, как грандиозные ветви опускаются в
      землю, как огромные неповоротливые машины засыпают их землёй, смотрел и думал,
      что Набедренных не мог, никак не мог ошибаться.</p>

    <p>В человеке неубиваема жажда к
      переворотам и переменам, так движется эволюция и прогресс. И всё же не все
      перемены к добру. Как справиться с пагубной жаждой менять то, что в изменениях
      не нуждается?</p>

    <p>Омыв руки от крови Революции,
      Набедренных сказал: мы будем переворачивать деревья.</p>

    <p>— Тридцать лет необходимо
      вековому дубу, чтобы его ветви прижились в земле и стали корнями. Тридцать лет,
      из которых десять уже прошло. И в день десятилетия, 20 сентября 1883 года, всё
      Всероссийское Соседство обернётся к Первому Большому Перевёрнутому, чтобы
      увидеть листья на его бывших корнях и сказать: дерево приживается, и вместе с
      ним всё ближе к абсолютному подтверждению тезис Набедренных о равенстве корней
      и ветвей, верхов и низов!</p>

    <p>Абсолютное подтверждение,
      абсолютная симметрия, абсолютное равенство верха и низа.</p>

    <p>Абсолютный покой.</p>

    <p>Шухер так надеялся, что Ванечка
      не откажется поехать с ним на юбилей Первого Большого — там обещали праздник и
      перевороты нескольких деревьев поменьше. Это будет, это должно быть прекрасно.
      Ванечке, конечно, захочется поехать со своими друзьями, Шухер это понимает, но
      он мог бы просто постоять рядом, он не стал бы к ней лезть…</p>

    <p>К лешему юбилей Первого Большого,
      к лешему совместный туда поход — лишь бы нашлась, лишь бы с ней ничего…</p>

    <p>— …Парад лучших детских отрядов
      Бедрограда, выступления ведущих экологов страны, музыкальный концерт — всё это
      дань нашего уважения, нашей гордости Первому Большому Перевёрнутому и лицу
      Революции —&nbsp;великому Набедренных!</p>

    <p>Шухер смотрел, как покорно
      запекаются его три яйца, и было в их спокойной ежедневности что-то
      издевательское. Что бы ни происходило вокруг, овальбумин всегда начнёт
      сворачиваться при температуре около пятидесяти градусов. Развяжется война,
      случится эпидемия чумы, радиотехнологии дотянутся до Луны — но и на ней клетки
      эукариотов будут содержать ядро и цитоплазму, и на ней реакция окисления будет
      вызывать выброс энергии, и на ней одним из основных свойств живых организмов
      будет способность развиваться и умирать. Где-то — возможно, здесь, под боком, в
      Бедрограде, в этой квартире —&nbsp;живут разумные кристаллы с голубыми гранями,
      живут и смеются над говорящими белками. И тем не менее, даже тогда, когда всё выгорит
      и шагнёт вперёд, белки продолжат говорить, учиться, жить, любить и желать.</p>

    <p>И сворачиваться при температуре
      около пятидесяти градусов.</p>

    <p>Эту дикую, непередаваемую
      хрупкость не скрыть ни широкими свитерами, ни уважением окружающих; она всегда
      где-то рядом, бродит окрест.</p>

    <p>Только так ведь иногда хочется,
      чтобы —&nbsp;не.</p>

    <p>От тоски Шухер схватился за
      выпуск «Мирового научно-фантастического вестника», который сейчас читал,
      потянул картонную закладку (Ванечка ещё в отряде на занятии сделала: всего лишь
      полоска тёмно-фиолетового картона с не очень аккуратной аппликацией в виде
      ёлочки, но так приятно). Распахнулась короткая повесть малоизвестного
      латиноамериканского автора про бравого капитана пассажирского флота, чей
      корабль сошёл с курса и оказался неожиданно посреди пустыни, по ватерлинию в
      песке. Почему так вышло, Шухер ещё не знал, потому что до сих пор капитан был в
      основном занят сражениями с некими вроде бы бестелесными сущностями,
      нападавшими на команду. Повесть была забавной и отчасти сатирической: сущности
      пользовались оружием, а вот капитан, европеец по происхождению, изобретал
      всяческие уловки, пытаясь побороть их неагрессивными методами. В Европах, как
      известно, агрессия запрещена.</p>

    <p>Даже удивительно, что «Мировому
      научно-фантастическому вестнику» позволили такое печатать.</p>

    <p>Шухер за то и любил фантастику,
      что она, прикидываясь развлекательным чтивом, оказывается обо всём сразу. Под
      видом забавной борьбы с бестелесными сущностями можно высказать своё мнение о
      Пакте о Неагрессии, а ещё — под видом боевика можно написать о важном.</p>

    <p>О вечности, о неведомом, о том,
      что действительно волнует.</p>

    <p>Герои боевиков всегда выживают,
      потому что белки вечны, а их враги всегда погибают, потому что все мы
      —&nbsp;все — непередаваемо хрупки.</p>

    <p>&nbsp;</p>

    <p><i>«Капитан зачехлил впульсник и ещё раз напряжённо всмотрелся в коридор.
      Движения не последовало. Буйот лежал на палубе ничком.</i></p>

    <p><i>Значит, дело, как все и ожидали, закончилось выстрелом, даже двумя. И
      если первый только заставил споткнуться, то второй, кажется, окончательно
      уничтожил хорошего человека».</i></p>

    <p>&nbsp;</p>

    <p>Шухер досадливо захлопнул журнал.</p>

    <p>Сколько времени успеет пройти
      перед тем, как и здесь, в нашем мире, дойдёт до выстрелов?</p>

    <p>Это ведь не может быть опасно, не
      может быть <i>настолько</i> опасно, ведь
      только в научной фантастике бывает так, чтобы…</p>

    <p>Ваня и раньше с ним ругалась,
      пропадала на пару дней — и всегда возвращалась. Это было каждый раз немного
      неловко — и приятно: дурное позади, можно улыбнуться и помириться.
      Мирись-мирись-мирись и больше не убегай, я обещаю не ругаться, я обещаю, что мы
      сумеем договориться.</p>

    <p>Но это было не сейчас. Сейчас —
      другое время.</p>

    <p>Только Ванечка не могла тайно
      выпросить себе разрешение на медицинские процедуры с твирью, никак не могла.
      Этот Борстен ей позволил бы, но она не стала бы просить. Она только
      прикидывается самостоятельной и — иногда — жестокой, а на самом деле Ваня —
      добрая девочка.</p>

    <p>Этот Борстен мог её и заставить.</p>

    <p>Он свалился снегом на голову со
      своим планом создания лекарства от чумы — хорошим планом; в подходящих
      экспериментальных условиях Шухер с любопытством посмотрел бы на то, что можно
      сделать из аллергии на твирь, это прекрасный проект для коллективной монографии
      (особенно если учесть, что полученный препарат работает). Только так, как
      вышло, дела не делаются. Хитростью выманивать у студентов подписки о
      неразглашении, пытать их, запирать — ослабевших, потерявших кровь, измотанных —
      в университетском борделе на неизвестный срок…</p>

    <p>Откуда Борстену знать хоть что-то
      о врачебной этике, он же совершенно случайный человек.</p>

    <p>Шухеру приказали забыть его
      настоящую фамилию — и он забыл. Если Дмитрий Борстен и правда Борстен, то,
      выходит, иммунная сыворотка Смирнова-Задунайского к нему не имеет никакого
      отношения —&nbsp;равно как и целый научно-исследовательский институт, который в
      своё время организовали для доработки этой сыворотки (Шухер точно знал,
      поскольку его приглашали туда работать — отказался, конечно; гоняться за
      эфемерным лекарством от степной чумы — увлечение для куда больших романтиков,
      чем он сам). А раз так, всё меньше причин доверять его, Борстена, врачебному
      (если бы Шухер писал об этом мемуары, он бы наверняка поставил кавычки:
      «врачебному») мнению.</p>

    <p>Когда Шухер только поступил в
      БГУ, первые два года все медики учились вместе, делясь по отделениям, но не по
      кафедрам. Лекции были в основном потоковые, практику проходили толпой. Он сам
      был тогда ещё совсем маленьким мальчиком с круглым лицом, который на
      физкультуре всегда оказывался в конце строя. И уже тогда было отчётливо ясно,
      что изучать медицину приходят три категории людей. Некоторым — и таких было
      большинство — хотелось исцелять, помогать страждущим, облегчать боль. Некоторым
      — к ним относился сам Шухер — исследовать неведомое, создавать новые лекарства,
      понимать, как устроен мир, ведь мир так велик, что разгадать его можно только
      через малое — клетки человеческого тела, чья жизнь так коротка, и молекулы, чья
      жизнь вечна.</p>

    <p>Но были и третьи. Совершенно случайные
      люди, которым хотелось врачебный одиннадцатый уровень доступа, или стипендию,
      или «дык симпатичных мальчиков (девочек — у кого какие предпочтения)
      осматривать же».</p>

    <p>Эти почему-то всегда оказывались
      в начале строя на физкультуре.</p>

    <p>Правда, на том их лидерство и
      заканчивалось: ни с одним из таких третьих Шухеру в серьёзном возрасте не
      довелось столкнуться на профессиональном поприще. Они разъехались, или застряли
      в районных клиниках вечными терапевтами, или нашли себе какие-нибудь более
      громкие занятия.</p>

    <p>Медицина — не место для случайных
      людей.</p>

    <p>Риски слишком высоки, тайны
      слишком вечны.</p>

    <p>Шухер вовсе не был фаталистом, но
      верил — не мог не верить —&nbsp;что у определённых людей, вещей и событий есть
      своё место. Не предначертанное, а просто —&nbsp;правильное. Подобающее. Ему
      было неуютно и как-то немного неловко, когда кто-то находился не на своём
      месте.</p>

    <p>А сейчас со своих мест послетало
      всё вокруг.</p>

    <p>Утром в понедельник он не знал
      даже, что в его родном городе есть ещё гэбни высокого уровня доступа, кроме
      Бедроградской; он почти не знал, что такое гэбня. Вечером в понедельник
      Университетская гэбня пришла в его рабочий кабинет, красноречиво загородила
      дверь и стала втолковывать, каким опасным и нелёгким делом им всем предстоит
      заняться.</p>

    <p>Гэбня Университета. Разве это
      гэбня Университета? Гэбня истфака в лучшем случае, а вообще — одной конкретной
      кафедры истфака.</p>

    <p>И всё равно — близость шестого
      уровня доступа приятно щекотала. Шухеру это не нравилось, он пытался напустить
      на себя неприступный вид, но — щекотала.</p>

    <p>Власть имущие пришли к нему с
      просьбой.</p>

    <p>«А ещё —&nbsp;никогда, никому и
      ни при каких обстоятельствах не рассказывать, что С. К. Гуанако жив и находится
      в Бедрограде. Его присутствие строго секретно. Он много лет прожил в степи,
      сейчас занимается морскими делами и не имеет ни к политике, ни к чуме никакого
      отношения. И не должен иметь. Ясно?»</p>

    <p>Да пусть бы он и остался в своей
      степи на своих кораблях!</p>

    <p>Любой медик знает, что грань
      между панацеей и ядом тонка, если вообще существует. Наркотические травы, коими
      славится пресловутая степь, являются также ключевым компонентом доброй половины
      всероссийских лекарств. Чтобы действительно убить себя ими, нужно немало
      постараться. Многие очень жаждут; а у любого студента медфака всегда есть
      возможности раздобыть немного фармакологического сырья — необработанной твири,
      например, не говоря уж о савьюре…</p>

    <p>В общем, к середине третьего
      курса Шухер плотно занимался наркотическим оборотом Университета. Его,
      аккуратного круглолицего мальчика, держали на очень хорошем счету в аптеке, где
      он на втором курсе проходил фармакологическую практику; в отличие от своих
      одногруппников, Шухер продолжил там подрабатывать и дальше — после чего
      оставался всего один шаг до заветных упаковок в коричневой бумаге.</p>

    <p>Шухер торговал помалу (накроют,
      да и незачем подсаживать людей на твирь), зато стабильно. И сам покуривал
      —&nbsp;помалу, зато стабильно. Не савьюр: от савьюра просто уплываешь, мышцы
      расслабляются и голова пустеет, ничего интересного. Савьюр относительно
      безопасен — это лишь успокоительное, на него даже почти не подсаживаются.</p>

    <p>И всё же Шухер предпочитал
      опасную и жгучую твирь: больно уж звёздным становилось от неё небо.</p>

    <p>А потом в эту беспечальную жизнь
      ввалился Гуанако, года пройти не успело. Ввалился не налегке —&nbsp;с рюкзаками
      травы; барыжил направо и налево, вонял на весь квартал. Шухер испугался,
      конечно, репутация-то была и у него: начнут вылавливать — достанется и ему, и
      аптеке, и всему медфаку. Не достанется как раз Гуанако и Порту, откуда тот
      траву таскал.</p>

    <p>К счастью, в мире всё-таки есть
      справедливость. На пятом гуанаковском курсе (когда Шухер, соответственно,
      первый год был в аспирантуре) того вроде как вызвали пред очи Бедроградской
      гэбни. Тихому злорадству случиться не удалось, покрывал Гуанако весь истфак:
      кто-то ходил с повинной, кто-то спешно перепрятывал запасы, и в итоге вышло,
      что виноваты все понемногу, так что наказывать толком не за что. Гуанако,
      кажется, приструнился, да и лично к Шухеру никто не полез, только он всё равно
      свернул свои продажи.</p>

    <p>Его бы всем медфаком покрывать не
      стали.</p>

    <p>Зависть — дурное чувство, но ведь
      и траву курить неполезно.</p>

    <p>А потом Гуанако истаял с
      горизонта, и вся эта наркотическая история как-то забылась, даже в
      университетских байках особо не застряла. Шухер, по крайней мере, не застрял. А
      про Гуанако ходили и повеселее байки.</p>

    <p>Только Шухер забывать не умел.
      Иногда ему казалось, что <i>течение времени</i> придумал кто-то просто так, из вредности, а на самом деле — его не бывает, не
      может быть; человек всегда живёт во всех временах сразу, всегда одновременно и
      молод, и стар, и это унизительное чувство —&nbsp;<i>подумают-то на меня</i> — никуда не уходит и не уйдёт.</p>

    <p>Шухер задумчиво осмотрел
      сковородку, выключил конфорку и застенчиво хихикнул.</p>

    <p>С другой стороны, три яйца жарятся
      три минуты, и ничем, кроме течения времени, это не объяснить.</p>

    <p>Он аккуратно перетащил яичницу на
      тарелку, налил себе стакан яблочного сока, порадовался приумолкшему на
      мгновенье радиоприёмнику. Будто приёмник тоже живой, берёт дыхание перед
      следующим звуком.</p>

    <p>В размеренной утренней тишине
      двора Шухеру почудился чаячий крик. Близкий совсем, отчётливый — как будто мимо
      пролетела. Откуда во дворе взяться чайке? Порт неблизко, море — и того дальше.
      Это до Революции весь город был полон чаячьих криков, а теперь не каждый
      бедроградец сразу их узнает, потому что чайки — в Порту, не хотят улетать от
      кормушки.</p>

    <p>Но Шухер слышал чайку; выходит —
      течение времени повернуло-таки в другое русло, выходит — сейчас, как и
      семьдесят лет назад, за окном дореволюционный Петерберг.</p>

    <p>Поразмыслив, Шухер приоткрыл окно.
      Крик не повторился, зато запах сырых кленовых листьев, тут же заклубившийся по
      кухне, вселил надежду на то, что всё будет хорошо.</p>

    <p>Ванечка найдётся, чуму излечат, а
      Гуанако уплывёт себе в степь на корабле.</p>

    <p>В дверь позвонили.</p>

    <p>Шухер досадливо вздохнул. Соседка
      опять?</p>

    <p>Когда эта женщина запомнит, что в
      восемь утра он всегда завтракает, и не любит вмешательств в сей процесс?</p>

    <p>В глазке, однако, вместо соседки
      обнаружились два каких-то детины смурного вида. Один из них склонился к самой
      двери, загораживая обзор, но Шухер всё равно заметил ворот тельняшки.</p>

    <p>Портовые.</p>

    <p>К Порту у него было двойственное
      отношение. С одной стороны, если бы не Порт, не было бы сейчас никакого
      лекарства от чумы. С другой стороны —</p>

    <p>С другой стороны, если бы не
      Порт, не было бы сейчас никакого лекарства от чумы, никакой катавасии и никаких
      спотыкающихся от потери крови студентов.</p>

    <p>Шухер признавал, что в портовой
      жизни не может не быть некоего очарования:&nbsp;вольница, разгул и разбой — это
      страшно, но бывают люди именно из такого теста, которым место именно в Порту;
      и, быть может, сам Шухер когда-то ночью и думал о том, что был бы счастливее,
      если бы его личное тесто оказалось покрепче и сам он был бы слажен для более
      авантюрной жизни.</p>

    <p>Но —&nbsp;не был. И Шухеру было не
      место в Порту, где чайки и вольница.</p>

    <p>А портовым —&nbsp;не место на
      лестничной клетке перед дверью Шухера.</p>

    <p>— К-кто? — осторожно спросил он в
      глазок.</p>

    <p>Детины зашевелились,
      переглянулись.</p>

    <p>— Шухер тут?</p>

    <p>— Шухéр, — привычно
      поправил тот, — Анд-дроний Леонидович.</p>

    <p>Всегда любил своё имя, запинаться
      почти не на чем.</p>

    <p>— Шухéр, — согласно
      промычал тот из детин, что повыше, — мы про дочку твою.</p>

    <p>…Дикие звери, грифоны и лисы.</p>

    <p>Несколько секунд Шухер тупо
      смотрел на щеколду, а потом увидел, как его пальцы открывают её, рука делает
      приглашающий жест, а ноги отходят в сторонку, чтобы гости («гости»!) могли
      пройти.</p>

    <p>Гости были внушительны. Тот, что
      повыше, —&nbsp;в кривовато напяленной разноцветной шапочке, с короткой
      неопрятной бородкой, ножом на бедре и перстнями на заскорузлых пальцах. Второй
      —&nbsp;в надвинутой на глаза замызганной бандане, каком-то подобии куртки
      поверх тельняшки и с выражением некоторого любопытства на лице. А может, и не
      любопытства — просто глаза из-под банданы смотрели слишком яркие, совсем
      зелёные, гораздо зеленее, чем у Ванечки, и уж тем более чем у самого Шухера.</p>

    <p>Оба не разуваясь прошли прямиком
      на кухню; Бандана заинтригованно потыкал вилкой в яичницу. Борода деловито
      высунулся в окно, упёрся взглядом в клён, остался в целом доволен, но шторы всё
      равно задёрнул.</p>

    <p>—&nbsp;Короче, дядя, рассказывай,
      — пробулькал Бандана, щедро отпивая из шухеровского стакана.</p>

    <p>— Расск-казывать?</p>

    <p>— Про дочку.</p>

    <p>— Что?</p>

    <p>Детины переглянулись; Бандана
      полез в карман, извлёк какую-то засаленную бумажку и изучил её с кропотливостью
      малограмотного.</p>

    <p>— Слышь, я не понял, ты Шухер или
      нет? — пробасил Борода.</p>

    <p>— Я Шухер, я.</p>

    <p>— Дочка твоя пропала, дядя, ты не
      в курсе, что ли? — на лице Банданы мелькнула тень презрения.</p>

    <p>У Шухера замялось в горле.
      Бандана сейчас презирал его за то, что ему плевать на собственную дочь,
      —&nbsp;как будто это не Шухер еле уснул прошлой ночью, как будто он не просил втайне,
      сам не зная у кого, чтобы это она просто на него обиделась и отсиживалась
      где-нибудь у подруги, или даже чтобы принимала твирь — только бы не случилось
      чего.</p>

    <p>И почему-то вдруг очень-очень
      стало нужно объяснить это Бандане, сказать ему, что он в курсе, он волнуется,
      он еле уснул прошлой ночью…</p>

    <p>Слова скомкались, не пожелали
      произнестись.</p>

    <p>Бандана оттяпал себе кусок
      яичницы.</p>

    <p>— Короче, — буркнул Борода, — это
      мы с Порта, которых Университет нанял. Дочку твою искать. Рассказывай давай.</p>

    <p>Шухеру стало жарко, и он
      почувствовал, что его голова будто бы неплотно сидит на плечах, раскачивается
      из стороны в сторону. Держа спину прямо, чтобы не уронить, он присел на
      табурет.</p>

    <p>Значит, всё-таки случилось.</p>

    <p>— Я… — выдавил он, сглотнул
      всухую и попытался продолжить, — сп-п-просите, я отвечу. Меня не
      п-п-предуп-предили… я не знал, чт-то В-в-ванечк-ку ищут.</p>

    <p>Борода покосился на Шухера с
      омерзением.</p>

    <p>— Бля, косноязычный!</p>

    <p>— Ша, —&nbsp;оборвал его Бандана,
      — пасть захлопни. — Он повертел головой, взял с сушки стакан, налил туда воды
      прямо из-под крана и протянул Шухеру. — Ты, дядя, не сердись, мы не в обиду.
      Просто дельце с твоей дочкой какое-то гнилое, тухлятиной воняет, вот мы и дёргаемся.
      Но это не твоя беда, и не дочки твоей. Так что давай, водички хлебни
      —&nbsp;водка-то у тебя хоть есть, болезный? — и рассказывай всё, что сможешь.
      Где была, чем занималась, о чём в последний раз говорили, кому насолила.</p>

    <p>— Я д-думал, она к п-подружке
      жить п-п-поехала, —&nbsp;невпопад ответил Шухер.</p>

    <p>Вода была холодной, свежей, но в
      горле всё равно что-то торчало штырём —&nbsp;такое, которое не сглотнёшь.</p>

    <p>— Ага. В Хащину, — гоготнул
      Борода.</p>

    <p>— Ну, д-да, — попытался объяснить
      Шухер, чувствуя, как жалко и слабо звучит его голос. — У неё есть п-подруга
      т-т-там, она д-даже мне г-говорила, они с от-тряда…</p>

    <p>— Нет твоей девки в Хащине, —
      рявкнул Борода, —&nbsp;и не было никогда. Тебе её ваще надо или нет?</p>

    <p>— Надо! — закричал Шухер. — Надо!
      Но я нич-чего не з-знаю, я не знаю, к-к-как помочь, к-как найти…</p>

    <p>Бандана посмотрел на него с
      жалостью, цыкнул зубом и махнул Бороде на холодильник —&nbsp;мол, время водки.
      Сам аккуратно закурил, выдохнул даже за окно.</p>

    <p>— Слышь, дядя, давай по чесноку.
      Портовая гэбня нынче с вашим Университетом якшается, но Порт — не Портовая
      гэбня. Лично нам на вас покласть, хотели бы быть университетскими — пошли бы в
      дохтуры наук, — оба детины раскатисто заржали, довольные шуткой. — Но мы
      честные люди, нам отбашляли, велели найти Брованну Шухер — работаем.</p>

    <p>— Сп-пасибо, — опять невпопад
      брякнул Шухер. Борода протянул ему полстакана водки, от которого он опасливо
      пригубил. Горло опалило, но легче всё равно не стало.</p>

    <p>— Спасибо дохтурам наук оставь, —
      отрезал Бандана, — нам отбашляли. Только ты, дядя, не сердись, но мы работку-то
      и сбросить можем.</p>

    <p>— П-почему? — пробормотал
      опалёнными губами Шухер.</p>

    <p>— Мутит чё-то Университет, нас
      крайними выставить хочет! — рявкнул Борода.</p>

    <p>Шухер замигал.</p>

    <p>— Мы не лапти какие, в курсе, что
      этот ваш главный —&nbsp;Максим его или как — в Порту отирается. Знает, как дела
      делаются, как заказы даются, чего говорить надо. А тут — не работа, а слёзы
      одни: где искать — не сказали, сроков не дали, только имя и деньги на руки. Ну
      ладно, он тоже дохтур наук, чего с него взять; он клиент, за то и платит, чтоб
      мы искали, а не он. Мы человечки смирные, спрашиваем его по-людски, чего девка
      ваша в последние дни делала. И знаешь, дядя, что он нам в ответ? Это, говорит,
      к делу отношения не имеет!</p>

    <p>— «К делу отношения не имеет»! —
      шарахнул Борода по столу рукой. — И всё, в молчанку!</p>

    <p>— Лады, мы не звери, простили на
      первый раз, — продолжил Бандана, прищуриваясь. —&nbsp;Поспрошали вокруг — нам
      тоже про подругу из Хащины.</p>

    <p>— Рег-галию, —&nbsp;вклинился
      Шухер. На лице Банданы мелькнуло что-то вроде смешка —&nbsp;удивительно
      непортового.</p>

    <p>— Галку, — хмыкнул он.
      —&nbsp;Подорвались в Хащину, отыскали подругу, вчера как раз. Как же, как же,
      говорит, была у меня Бровь, вы с ней чуть разминулись, а теперь она к другой
      подруге уехала — и бумажку с именем в руки тычет. Ну нет, девонька, говорим мы,
      что-то ты слишком складно брешешь, а глаза зарёванные — колись по-хорошему,
      авось подсобим. Она в сопли. И что ты, дядя, думаешь? Были у неё вчера деньком
      университетские — не знаю, как зовут, высокие такие, вдвоём всегда ходят.</p>

    <p>— Охрович и К-к-краснокаменный? —
      без голоса спросил Шухер.</p>

    <p>— Да может и Охрович, и
      Краснокаменный, говорю же, не знаю. Знаю, что университетские и что Галка с них
      тряслась вся. Как оттряслась — раскололась: Брови вашей уже полгода как не
      видела и не слышала, но университетские ей, значится, велели, ежели кто
      спросит, говорить, что видела, слышала и привечала.</p>

    <p>— А теперь выходит, что они и
      тебе про нас не сказали, — подхватил Борода, весомо качая головой. — А ты ей
      папка или не при делах?</p>

    <p>Бандана откинулся на спинку стула
      и слегка склонил голову — рассматривая:</p>

    <p>— Тебя о поисках не известили,
      подружку сами выкопали, да ещё и мозги ей пропесочили, чтоб пургу гнала про
      Брованну твою. Про дела её молчат. Вот и скажи мне, дядя, как по-твоему,
      Университет чего хочет: чтоб мы девочку нашли или чтоб мы девочку поискали-поискали,
      посветили рылами где им надо, а потом сами же за виноватых получились?</p>

    <p>Слабое сердце Шухера научило его
      умом прислушиваться к тому, волнуется он или нет; когда говорили что-нибудь
      эдакое, делать паузу, прикрывать глаза хотя бы и проверять — как там пульс,
      давление, не скачут ли. Сперва это было очень странно («а после этой фразы я
      должен, по идее, разволноваться —&nbsp;ну-ка, ну-ка, волнуюсь или нет?»), но
      потом привык, сделал рутиной, образование помогло.</p>

    <p>Сейчас у него в груди разлилась
      какая-то красная липкая жижа, через которую не пробивались удары сердца, и
      Шухер никак не мог понять, расслышать — нужно уже принимать меры или ещё
      поживёт.</p>

    <p>—&nbsp;Мы люди честные, — бухнул
      Борода, — нам дело бросать не с руки. Но свои шеи за тебя подставлять — тоже не
      будем. А Университет темнит.</p>

    <p>— Эт-то в-всё какая-то ошибка, —
      выдохнул Шухер, снова хватаясь за стакан — с водой ли, с водкой, лишь бы что. —
      Университет не п-п-пыт-тается вас п-подставить, п-п-просто они не могут
      рассказать…</p>

    <p>—&nbsp;Чего? —&nbsp;навострился
      Борода.</p>

    <p>У Шухера в груди было липко. Он
      почти наяву видел скромную улыбку Лария Валерьевича, подкладывающего ему для
      подписи ещё одну подписку о неразглашении, и ещё одну, и ещё…</p>

    <p>— Как по мне, дядя,
      —&nbsp;медленно проговорил Бандана, выпуская дым сквозь зубы и слегка
      группируясь,&nbsp;будто для нападения, — Университет не хочет твою дочку найти,
      Университет хочет твою дочку закопать. А ты сам?</p>

    <p>Через липкую жижу что-то внутри
      задёргалось.</p>

    <p>— Я хочу найти!! — голос
      сорвался, но это было неважно. —&nbsp;П-пожалуйста, найдите её! П-п-пожалуйста!</p>

    <p>— Хочешь найти — отвечай на
      вопросы. Может, мы чего попутали, и всё чистенько. Может, ты найдёшь способ нас
      убедить её искать даже против Университета — риски тоже покупаются. Чего они
      нам не рассказывают?</p>

    <p>Шухер прикрыл глаза, прислушался
      к сердцу — нет, по-прежнему не сумел понять, стучит или нет.</p>

    <p>Охрович и Краснокаменный легко
      могут переломать ему руки, но это почему-то совсем не пугало. Не пугал Максим
      Аркадьевич, который может раскричаться, добиться увольнения, устроить показную
      порку.</p>

    <p>Пугал улыбчивый Ларий Валерьевич
      со своими бесконечными подписками о.</p>

    <p>— В Университ-тете д-делают
      лекарство от ч-чумы. Много. Из ст-тудентов. В-ваня… в эт-том участвует.</p>

    <p>— От чу-мы? — по слогам повторил
      за ним Бандана. — Хера себе. То-то все там такие и бегают, очумелые, — и
      радостно загоготал над собственной шуткой.</p>

    <p>— Что у вас там какая-то движуха
      медицинская, любая крыса знает, — оборвал его Борода. — Чем твоя дочка
      особенная такая, чтоб её лично закапывали?</p>

    <p>Чем?</p>

    <p>Ванечкой заинтересовалась
      Бедроградская гэбня, потому что у неё там что-то с этим Ройшем. Ей дали звание
      младшего служащего, попросили сотрудничать, «раскрыли карты», дальше какие-то
      провокации…</p>

    <p>С мокрым, вязким ужасом в горле
      Шухер понял, что не знает. Какие провокации, за что — какой там? двенадцатый? —
      уровень доступа, что было в раскрывшихся картах — не знает, не знает, не знает!
      И мог спросить, она бы рассказала, обязательно бы рассказала, мог выслушать, но
      —&nbsp;слишком занят был тем, что запрещал.</p>

    <p>— Ты не нервничай, дядя,
      —&nbsp;мягко пропел Бандана, нагибаясь к Шухеру ещё ближе, — ты подумай,
      подумай, нам любое поможет. Чем Бровь могла отличаться от других студентов?</p>

    <p>…Чем?</p>

    <p>— Она з-знала, — прошептал Шухер,
      — знала, ч-что из ст-тудентов делают лекарство. Ост-тальным соврали…</p>

    <p>Оба детины отшатнулись от Шухера
      как от заразного, загудели.</p>

    <p>— Звенеть, значит, начала, —
      цыкнул зубом Борода, — тухлое дело.</p>

    <p>— К-какое звенеть, к-к-кому! —
      испугался Шухер. Снова стало как-то глупо, но очень важно — объяснить им, что
      Ванечка не такая, что она не стала бы подставляться, что она не может, вот
      просто никак не может быть в опасности.</p>

    <p>Бандана посмотрел на него с
      жалостью.</p>

    <p>— Ты вообще чем за девкой следил,
      —&nbsp;продолжил Борода, сплюнул на пол, —&nbsp;головой подумал, во что ей дал
      ввязаться?</p>

    <p>— Ша, —&nbsp;снова прикрикнул
      Бандана, — сам-то головой подумал? У них там в Университете люди приличные, по
      крайней мере, с виду, чего б ему девку им не доверить? И вообще, не козявка
      какая, двадцать лет уже. Хотя ты, дядя, конечно, неправ, — обратился он уже к
      Шухеру, старательно смягчая тон, — если у вас там чё-то с чумой мутят, мог бы и
      поостеречься.</p>

    <p>Но он ведь остерегал — только
      стерёг, выходит, недостаточно.</p>

    <p>— Слышь сюда, — доверительно
      продолжил Бандана, — вот чё скажу. Я ваших университетских видел — они сами
      руки марать не любят. На мокруху не пойдут. Мне уже знаешь сколько лет? Я за
      эти лета погулять успел, по мордасам половить успел, у меня жопа тренированная,
      чует, куда ветер дует. И говорит мне она, что нанимать головорезов — нас, то
      есть — дельце грязненькое. Мы люди опытные, знаем, когда сливаться. Ежели б
      университетские головой подумали —&nbsp;вешали бы свои беды на лоха какого, да
      на тебя вот хоть — без обид, дядя, только ты кукуешь и ничего не ведаешь,
      верно? Дык и вот. А выглядит вся эта колобродь так, будто кто-то левый налажал,
      а они теперь в спешке за него покрывают. —&nbsp;Он задумчиво постучал пальцем
      по щеке, решительно подвинул к себе тарелку с яичницей и отправил в рот
      остатки. — Ты мне вон чё скажи: могла твоя дочка сама угулять куда?</p>

    <p>— К п-подруге… —&nbsp;пролепетал
      Шухер, слишком ясно понимая, как смешно это звучит.</p>

    <p>Борода гоготнул:</p>

    <p>— Ага, девка угуляла, а они
      теперь портовым мокруху шьют! —&nbsp;Он зыркнул на Бандану с определённым
      злорадством. — Мало твоя жопа получала.</p>

    <p>— А я откуда знаю, — огрызнулся
      Бандана, — какую она дружбу с университетскими водит.</p>

    <p>— Не мог-гла она, — осмелился
      перебить его Шухер, —&nbsp;ск-казала б-бы мне, если бы ушла над-долго.
      П-п-позвонила бы к-как-нибудь, нап-писала, нашла способ…</p>

    <p>— Да знаю я, дядя, — досадливо
      махнул рукой Бандана, — не хочу просто девку твою хоронить. Молодая ещё,
      молодым много применений можно найти получше могилки. Если сама не угуляла —
      значит, угулял её кто-то. Про движуху вашу, говоришь, знала многовато. Кто
      движухой-то занимается, тоже Максимка?</p>

    <p>— Максим Арк-кадьевич, Ларий
      В-в-валерьевич, Охрович и К-краснокаменный — они в-всё б-больше по
      админист-тративной части…</p>

    <p>— Не у руля, — прогудел Борода и
      как-то по-особому значительно посмотрел на Бандану — как будто это было важно.
      — А рулит кто?</p>

    <p>Кто «рулит» медицинскими
      процедурами? Хороший вопрос. Все возможные медфаковские занятия перенесли в
      третий корпус, подальше от лазарета, так что первый почти вымер; там только и
      остались — лежачие студенты, пара преподавателей с кафедры вирусологии,
      аспиранты, этот самый Борстен, Поппер и Шухер.</p>

    <p>Ловко Поппер всё-таки всех
      прижучил («прижучил»! Часа не успел Шухер проговорить с этими… гостями —&nbsp;а
      уже думает как они). Впрочем, Поппер в своём излюбленном зелёном костюме и
      правда напоминал майского жука. Совершенно безопасного, но Шухер их всё равно
      не любил: летят, гудят, тяжело врезаются, и — даже синяка не остаётся, можно
      просто стряхнуть, только потом снова — летят, гудят…</p>

    <p>Так и Поппер. Он, заведующий
      привилегированной кафедрой на медицинском факультете, даже и не скрывал особо,
      что, как только речь зашла о чуме, одним махом выслал всех неугодных по
      командировкам, оставил при себе самых пламенных и самых послушных — аспирантов
      в основном. Самому Шухеру прямо сказал, что и его выслал бы, если бы не Ваня,
      за которой он, Шухер, мол, так жаждет следить.</p>

    <p>Да лучше бы и выслал, может, оно
      бы как-то иначе обернулось.</p>

    <p>— Юр К-к-карлович П-поппер, —
      медленно (сложное имя) проговорил Шухер. — Т-т-только он не ст-тал бы ничего
      д-дурного д-делать.</p>

    <p>— А чё так? Шибко приличный? —
      презрительно фыркнул Борода.</p>

    <p>— П-приличный, — кивнул Шухер, —
      В-ванечку с от-т-трядского в-возраста знает. И ещё… к-к-как бы это… его не
      в-волнует, к-кто и что говорит. Он совсем п-по медицинской ч-части.</p>

    <p>— Лады, — согласился Бандана, —
      по медицинской — так по медицинской. Кому ещё могла доча твоя не угодить? Кто у
      вас там больше всех ошивается?</p>

    <p>— Ларий В-валерьевич, — быстро
      ответил Шухер. — И ещё…</p>

    <p>И ещё — Гуанако, например.</p>

    <p>Про Гуанако строго-настрого
      запретили говорить. Очень хотелось; Гуанако не злой человек, нет, но уж больно…
      небрежный. Сам не боится смерти —&nbsp;и остальных не побоится под каток
      положить, не заметит просто. Меряет других по себе, думает, что все такие же,
      что всем всё легко. И потом, сколько лет и в каких кругах он провёл?</p>

    <p>Он не стал бы делать специально
      во зло, но мог бы —&nbsp;случайно, не заметив.</p>

    <p>Хотелось — но не сказалось;
      как-то слишком понятно было, что выдать чуму — прегрешение, но простят, а за
      Гуанако — на клочки порвут.</p>

    <p>Потому что все и всегда, все и
      всегда любили его больше, чем следовало, больше, чем он того заслуживал.</p>

    <p>Больше, чем Шухера.</p>

    <p>И ещё — Ройш, например. Что он за
      человек? Что ему нужно? Зачем ему сдалась Ванечка? У Ройша нет сердца, и — ах,
      если бы только Шухер знал чуть больше…</p>

    <p>Он никогда не мог понять, как так
      вышло, что Ройш — внук хэра Ройша! — работает в Университете. Разве ему не
      место где-нибудь на управляющей должности, в государственном аппарате, в
      Университетской гэбне хотя бы?</p>

    <p>Шухер любил Всероссийское
      Соседство за то, что для неполитиков политики в нём не существовало; но теперь,
      когда она сама свалилась ему на голову, стало до головной боли понятно, что <i>всё непросто</i>. И если Ванечка может
      оказаться младшим служащим, кем на самом деле может быть Ройш и какие интриги
      он может плести?</p>

    <p>Ах, если бы только Шухер знал
      чуть больше.</p>

    <p>И ещё — Борстен, например. Про
      него строго-настрого запретили <i>думать</i>;
      а всё-таки — это он сказал Попперу взять Шухера заниматься чумой; это он привёз
      чуму, это его яркие рубашки всегда виднеются в лазарете, что днём, что ночью.
      Ясно, что он скрывается, как и Гуанако. Якобы помогает Университету по дружбе.</p>

    <p>Да только не бывает таких
      размеров дружбы, чтобы так помогать.</p>

    <p>— Б-борстен, — решился Шухер, —
      Д-дмитрий Сергеевич. Мед-дик. Это он в-в-в основном лек-карством з-занимается.</p>

    <p>Детины переглянулись с живым
      удивлением.</p>

    <p>— Борстен? —&nbsp;переспросил
      Борода. — Не слыхали. Кто такой, чё за тип?</p>

    <p>Вопрос вопросов, загадка загадок.
      Кто такой Дмитрий Борстен — выпускник медфака, работавший в степи, случайно
      наткнувшийся там на Имперскую Башню, сотрудничавший с Медкорпусом и вызванный в
      Университет кем-то из старых знакомых — не то Поппером, не то Ройшем?</p>

    <p>Или недоделанный выпускник
      истфака, попавший за что-то на Колошму, чудом сделавший там иммунную сыворотку
      от чумы, числящийся погибшим и незнамо зачем вернувшийся в Бедроград?</p>

    <p>О втором Шухер не знал ничего,
      кроме портрета в холле медфака, научно-исследовательского института, куда Шухер
      не пошёл работать, и нескольких в разной степени скандальных слухов. Помыслить
      о том, что такого человека могут допустить до работы со студентами — даже
      назойливый Поппер, даже небрежный Гуанако — было слишком страшно; так просто не
      может быть.</p>

    <p>Мир изгибается, принимает формы
      того, что мы видим. И, быть может, если Шухер достаточно сильно зажмурится,
      если он достаточно твёрдо будет верить —</p>

    <p>Быть может, всё обойдётся.</p>

    <p>— Не з-знаю, — покачал он
      головой. — Какой-то б-б-бывший медфаковец… г-говорят, д-друг Ройша —
      К-к-константина К-константьев-вича, это п-преп-подаватель с истфака.</p>

    <p>— Мы академиев не кончали, но кто
      такой Ройш, сами знаем, — обиделся Борода.</p>

    <p>Бандана же потёр глаза и
      неожиданно непортовым голосом сказал:</p>

    <p>— Дмитрий, друг Ройша. Да ты ж
      ебанись, — он ещё раз постучал пальцем по губам, разочарованно осмотрел тарелку
      из-под яичницы. — Чё у вас там, всегда незнамо кто заправляет?</p>

    <p>Не знал Шухер, кто и где
      «заправляет». Никогда не знал и не хотел знать.</p>

    <p>Он любил Всероссийское Соседство
      за то, что для неполитиков политики в нём не существовало.</p>

    <p>Детины смотрели разочарованно.
      Ведь сейчас уйдут же, плюнут и уйдут, незачем им всем этим заниматься. А
      портовая честь — ну что с неё взять, побыла и сгинула.</p>

    <p>— Не з-знаю, — честно ответил
      Шухер и панически попытался заглянуть в глаза какому-нибудь из детин. —
      П-п-послушайте, мне нечего в-вам п-предложить, но — п-п-пожалуйста,
      п-пожалуйста, умоляю в-вас…</p>

    <p>— Тухляк, —&nbsp;покачал головой
      Борода. — Сами к себе левых водите — сами и ковыряйтесь, я так глубоко в
      университетское закапываться не подписывался.</p>

    <p>Шухер перевёл глаза на Бандану:
      тот совершенно отрядским жестом покусывал ноготь, глядя в окно.</p>

    <p>Если содрать с него грязь и загар
      — он же ровесник самого Шухера, наверное, а то и младше.</p>

    <p>— Да ты, дядя, не переживай
      —&nbsp;может, и впрямь просто куда угуляла, — неловко сочувственным голосом
      пробормотал он.</p>

    <p>Шухер вдруг ясно понял, что ещё
      секунда —&nbsp;и он грохнется прямо посреди кухни на колени:</p>

    <p>— В-вы же сказали, чт-то риски
      п-п-перекуп-паются!</p>

    <p>— А ты — что тебе нечего нам
      предложить.</p>

    <p>— Я найду! Чт-то в-вам нужно? Я
      найду! — голос Шухера сорвался, кончики пальцев закололо —&nbsp;кровь отхлынула
      совсем.</p>

    <p>Детины снова заржали.</p>

    <p>— Уж не дипломы твои, — фыркнул Борода,
      — бабло.</p>

    <p>— У меня н-нет д-д-денег, но в
      Университ-тете есть, я п-п-попрошу…</p>

    <p>— А они прям дали! — с
      неожиданной злостью рявкнул Бандана. — У вас же движуха медицинская, сам
      знаешь, ежели они ещё бабло будут в обход Порта разбазаривать — их какой-нить
      ноченькой на квартирке и порежут, не посмотрят, что места непортовые. У вас
      долги такие, что всеми студенческими задницами не расплатитесь. И это, дядя,
      мозгой-то пошевели? Если они сами дочку твою закапывают — за Борстена, не за
      Борстена — в кой им впало на её поиски деньги давать?</p>

    <p>Шухер смешался.</p>

    <p>— Я с-совру…</p>

    <p>— Чё ты соврёшь? Чё, турпоездку в
      Афстралию, мол, захотел? — заржал Бандана. — Ты, дядя, обратно не обижайся, но
      я сказал уже — лошок ты лопоухий, никто тебя слушать не станет.</p>

    <p>Голова Шухера, неплотно сидящая
      на плечах, совсем расхлябалась. Он почувствовал, как она разваливается на
      части, и части эти не стыкуются, трясутся и с громким хлюпаньем сталкиваются.</p>

    <p>— А ну не реви, не хватало тут, —
      неловко прикрикнул Бандана, задумался и через некоторое время просиял. —
      Слухай, мы к тебе с пониманием. Дельце тухлое, но нас им, знаешь, тоже обидели,
      мы не прочь поквитаться. Бабло тебе никто не даст, нам сверху уже даденного
      тоже никто не даст, но мы-то не лошки, мы и сами взять можем. С тебя — наводка
      нужна.</p>

    <p>— Что? — переспросил Шухер сквозь
      красноватый туман.</p>

    <p>— Наводка. Где бабло, сколько,
      откуда, какие планы на него. Только это, дядя, ты понимай: дашь наводку —
      подставишь своих конкретно, их за долги на ошейники пустят. Смекаешь?</p>

    <p>— Какие они ему свои, девку
      закапывают, —&nbsp;встрял Борода.</p>

    <p>Шухер на всё это и отвечать не
      стал — только помахал нетвёрдой своей головой. Детины удовлетворённо кивнули.</p>

    <p>— Ну и ладненько тады, — заключил
      Бандана, —&nbsp;ты, значится, сегодня у себя там пошуруди, повыспрашивай,
      чё-куда, а мы вечерочком обратно заглянем, да и перетрём все вопросы. И это, не
      дёргайся тут особо — не порешили ж дочку твою в самом деле, скорее, спрятали
      куда. Отыщется, никуда не денется. Понял?</p>

    <p>Шухер понял.</p>

    <p>&nbsp;</p>

    <p>Но дверь за детинами закрыть не
      мог ещё долго, так и сидел в квартире нараспашку — сидел, потому что как-то не
      получалось встать.</p>

    <p>— Первый Большой Переворот —
      величайшее событие в истории Всероссийского Соседства, и, празднуя его
      десятилетний юбилей, мы лишь в малой степени можем выразить те восторг и
      благоговение, которыми наполняет нас одна только мысль о прижившихся в земле
      ветвях векового дуба, — напомнил радиоприёмник серенькой кухоньке.</p>

    <p>Он говорил: не ходи в леса, там
      дикие звери, грифоны и лисы. Зачем тебе в леса, милая, хорошая? Зачем тебе не
      сидится дома, рядом, здесь?</p>

    <p>Он говорил —&nbsp;и, когда
      говорил, не знал ещё, что незачем искать беды в лесах, что грифоны и лисы —</p>

    <p>— здесь, рядом, дома.</p>
  </>
}

export default chapter19
