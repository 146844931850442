import React from 'react'
import { IBookPage } from './index'

const chapter35: IBookPage = {
  header: 'header_d9.jpg',
  title: 'Глава 35. Бы',
  prevLink: 'chapter34',
  nextLink: 'chapter36',
  content: <>
    <h2>Университет. Дима</h2>

    <img alt='' src="/images/35_dima.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>Дима обернулся и обнаружил, что
      нет, с некоторой вероятностью его зрение никогда не станет прежним. Все
      возможные шутки про очки с простыми стёклами и дурную гуанаковскую
      наследственность врываются в это повествование и рядком садятся на край койки.</p>

    <p>Гуанако, трогательно-зелёный и
      принципиально отказывающийся фокусироваться, загибался от хохота, сползая по
      двери. А это, знаете ли, симптомчик. Симптомчик, знаете ли, это.</p>

    <p>Уж Дима-то ведал.</p>

    <p>Но, леший, как же хорошо было его
      видеть. Не в смысле качества изображения, а в смысле вообще.</p>

    <p>Ещё лучше было бы его трогать.</p>

    <p>По возможности пару лет без
      перерыва.</p>

    <p>— Гуанако, я тебя люблю, — не
      выдержал Дима, — и твоего брата-близнеца тоже. Это я так, на будущее и на тот
      случай, если у меня вдруг где-нибудь что-нибудь разойдётся и смерть решительно
      наступит мне на печень. Своим ржачем
      ты, кстати, повышаешь вероятность этого, поскольку заразительно, а мне не
      следует смеяться, я это как медик говорю.</p>

    <p>Гуанако как будто отмашки ждал —
      мгновенно заткнулся, скакнул к койке, аккуратно уселся на краешек (он сам и
      есть ходячая шутка про дурную наследственность, ага) и уставился на Диму
      круглыми-круглыми глазами.</p>

    <p>О это
      вечное «и тут-то до него, наконец, дошло, как же ему было страшно».</p>

    <p>Потом он протянул руку вроде как
      к Диминой щеке, почему-то смутился, изменил траекторию в полёте и потрепал его
      по волосам. Это потребовало решительных действий, и Дима схватил Гуанако в
      охапку —&nbsp;не очень сильную, зато крайне внятную в плане интенций.</p>

    <p>Потому что есть обстоятельства,
      которые сильнее интенций, но иногда, в день большого праздника, бывают
      интенции, которые сильнее обстоятельств.</p>

    <p>В том числе и зверской боли в,
      откровенно говоря, всём теле.</p>

    <p>— Можно я просто дебильно помычу?
      — пробормотал Гуанако куда-то Диме в макушку. — А то мои речевые навыки как-то
      не очень.</p>

    <p>У Гуанако под мышкой (почему Дима
      всё время оказывается под мышкой?) было крайне приятно и крайне
немыто, но всё равно приятно.</p>

    <p>Посмертная эйфория спешила
      приобрести чудовищные формы.</p>

    <p>— Довольно сложно описать
      словами, как я рад тебя видеть и особенно щупать, —&nbsp;сообщил Дима подмышке.
      — Порядком рад.</p>

    <p>Гуанако извлёк Диму из-под мышки,
      посмотрел на него с весьма убитым видом, улыбнулся и
      поцеловал.</p>

    <p>Судя по тому, как продолжительно
      и нежно это вышло, он тоже был рад.</p>

    <p>Крайне.</p>

    <p>— Я уничтожил твой плащ, —
      вспомнил Дима, как только отлепился. — То есть виноват
      Максим, но анализ улик укажет на меня. Извини. По крайней мере, он прострелил
      меня не насквозь, а то ещё и штопать пришлось бы, а я не умею штопать, я умею
      только стирать носки.</p>

    <p>Сколько лет стирать носки Ройшу
      было его трудовой повинностью и арендной платой!</p>

    <p>К слову, Дима был, конечно,
      весьма признателен за извлечение пули (над ним же поэтому тут столько бегали,
      верно?) и художественную штопку по крайней мере его самого,
      но вот принести хоть какую-нибудь чистую одежду почему-то никто не позаботился,
      поэтому Дима так и сидел — весь в красном. То красное, что засохло
на теле и не было смыто в процессе операции, уже начинало чесаться.</p>

    <p>Леший, где-то мы это видели!</p>

    <p>— Д�� какой нахуй плащ, —
      опомнился Гуанако. — Ты чего хочешь: курить, воды, книжку получше?
      — и со значением покосился на высокохудожественную литературу в Диминых руках.</p>

    <p>— Книжка сейчас была бы особенно
      полезна с учётом того, что у меня в глазах почти троится. Я тут практиковал
      слепое чтение. От мысли о сигаретах меня воротит, а вот пить хочу. И помыться.
      И чтоб не чесалось. И понять, как именно на меня подействовало сочетание
      браслета-батарейки, снотворного и потери крови — а то я почти в сознании во
      время операции был, хоть и не чувствовал ничего, незабываемые ощущения. И
      связано ли всё это с тем, что я не помер и даже не впал в неопределённой
      продолжительности кому. Переливание крови тоже не помешало бы, кстати. Ну и,
      конечно, хочу, чтобы перестало болеть. И иллюзию способности стоять на ногах.
      Многого, в общем, хочу, но больше всего — чтобы ты задал мне правильный вопрос,
      а то тут столько всего произошло, столько всего произошло!</p>

    <p>Пав под натиском словесной
      лавины, Гуанако покачал головой и убрёл к телефону.</p>

    <p>— Жидкостей, — жалобно попросил
      он у него, — под дверь.</p>

    <p>Когда Гуанако точно знал, что
      ситуации можно помочь чем-то конкретным, он всегда становился крайне деловым и
      целенаправленным, но вот сейчас у него как-то не очень получилось.</p>

    <p>— Это кошмар. Как просьба
      «расскажи анекдот» или ещё что-то такое. Правильный вопрос, ха-ха, тут даже
      образцовая гэбенная синхронизация не поможет. <i>Варианты,</i> блядь! Много их, — усмехнулся
      он, топчась возле койки и не решаясь сесть на неё обратно. — А так хочется хоть
      в частной беседе сделать всё правильно и нигде не налажать
      с выбором оного. Блядь.</p>

    <p>Это было, гм, умилительно.</p>

    <p>Как будто он всерьёз боялся, что,
      если сейчас задаст неправильный вопрос, что-то испортится.</p>

    <p>Как будто он всерьёз боялся, что
      что-то может испортиться.</p>

    <p>То, что было между Димой и
      Гуанако, было не <i>между</i>,
      а в них самих. Именно поэтому когда они не очень ожиданно встретились восемь дней назад, в прошлую субботу,
      ни сложностей с первой репликой, ни вопроса, что делать, не возникло. Именно
      поэтому если бы от мая до прошлой субботы прошло не три месяца, а тридцать лет,
      всё было бы точно так же. Плюс-минус разыгрывание перстней, а вот надрались бы
      и поржали с гарантией.</p>

    <p>Когда настолько хорошо знаешь друг друга, когда настолько легко и спокойно жить,
      настолько хорошо зная друг друга, ничего уже не может испортиться само собой —
      разве что обстоятельства приключатся.</p>

    <p>Головы гэбен, наверное, впадают в
      панику и рефлексию, если у кого-то из них возникают проблемы с синхронизацией и он
      начинает бояться что-то не так сказать,
      а Дима может просто умилиться.</p>

    <p>И отмахнуться.</p>

    <p>Ну какие
      могут быть неправильные вопросы, если и неправильных слов-то нет.</p>

    <p>А вопросы, к вашему сведенью, как
      раз из них и сделаны.</p>

    <p>— Подсказка: «Дима, я наблюдаю на твоём теле нетипичное покраснение! Не
      может ли это быть связано со, скажем, ранением? Возможно, ты хочешь рассказать
      мне, как так вышло и не беседовал ли ты в процессе со
      своими ногами?» Только не говори мне, что ты уже всё вынюхал, и мне придётся
      рассказывать самую, пожалуй, эпическую историю из своей жизни Ройшу, — фыркнул
      Дима.</p>

    <p>— Так тебе выговориться надо? —
      хлопнул себя ладонью по лбу Гуанако и плюхнулся-таки на койку.</p>

    <p>Лицо у него при этом было таким,
      как будто Дима на его глазах трансформировался в крошечного пушистенького зверька,
      что вызвало повышенное сопле- и слюноотделение.</p>

    <p>Облегчённое у него было лицо.</p>

    <p>Воспоследовал акт романтической зоофилии и всё то прочее, о чём определённо было
      бы неловко
      рассказывать Максиму, если бы он вдруг снова воспылал желанием узнать факты
      чьих-нибудь биографий.</p>

    <p>— И всё опошлил, — вернулся Дима
      к диалогу, когда получил такую возможность. — Не выговориться, а поделиться
      бесценным опытом. Зачем вообще хоть что-то делать — и особенно истекать кровью
      — если потом не можешь об этом рассказать?</p>

    <p>Гуанако замялся.</p>

    <p>— Ну гм.
      Нельзя сказать, что я ничего не знаю, — виновато покаялся он. — Показания
      Охровича и Краснокаменного, Мули Педали и Максима вместе составляют достаточно
      целостную картину. Фактическую, в смысле. Но под
      бесценным опытом ты наверняка подразумеваешь, эм, не совсем факты.</p>

    <p>И посмотрел на Диму с таким ути-пути-зверьком во взоре, что желание патетически
      пересказывать своё своеобразное, но всё-таки просветление сразу отпало.</p>

    <p>Ну, почти.</p>

    <p>Немного осталось.</p>

    <p>— Знаешь, в моей жизни
      происходило довольно много событий, с которыми моя же голова как-то, гм, не
      понимала, что делать, — дал Дима волю аналитическим талантам. — От самого
      первого переезда в Бедроград — да что там, до того, в Столицу — да что там, от
      старшего отряда — хотя, знаешь, в семь лет у меня тоже всё интересно было…
      Короче, кажется, от начала времён на мне большими буквами напечатано «кромешный
      пиздец». И вот с наступлением чумы ресурс мозга, способный просто складывать
      пиздец стопочками и распихивать по углам, не
      обрабатывая, исчерпался.</p>

    <p>Дима завистливо посмотрел на гуанаковскую самокрутку и
      постановил, что не так уж его от табака и воротит.</p>

    <p>Зачем вообще хоть что-то делать,
      если не можешь потом об этом рассказать, и зачем хоть что-то рассказывать, если
      не можешь при этом размахивать сигаретой?</p>

    <p>Хотя шибко сильно размахнуться не
      вышло.</p>

    <p>Как выяснилось, не вышло даже
      зажигалкой щёлкнуть по-человечески.</p>

    <p>Издержки бесценного опыта.</p>

    <p>— Я вообще-то всегда с
      подозрением относился к психическим заболеваниям, связанным с чем-нибудь
      неочевидным, — продолжил Дима, когда Гуанако зажёг ему сигарету. — Типа все эти
      вытеснения, замещения и прочая сыпь на коже от больших переживаний — фигня
      какая-то, мозг не так работает! Но вот веришь — у меня
      правда образовался внутренний голос. Такой, в порядке не раздвоения личности, а
      расслоения, что ли. Я сперва думал, что это просто моя
      многогранная и противоречивая натура так работает, а потом он воплотился. Почти даже визуально. Наверное, многогранная и
      противоречивая натура решила, что так дальше жить нельзя, и просто обнулилась.
      Наверное, это отрицание — термин такой психологический, когда до мозга ничего
      не докатывается, а ты просто идёшь дальше весь из себя весёленький,
      посвистываешь. Наверное, это не очень хорошо. Хотя ты вон так всю жизнь живёшь
      —&nbsp;и ничего, доволен.</p>

    <p>Гуанако покивал.</p>

    <p>Всё, абсолютно всё сказанное с
      удручающей очевидностью летело ему прямиком мимо ушей, потому что нельзя столь
      яростно пялиться и при этом слушать.</p>

    <p>Оставалось надеяться, что он
      считывает содержание по губам.</p>

    <p>— В общем, странное какое-то
      состояние. Виктору Дарьевичу, что ли, отдаться на изучение? Я не то чтобы
      просто вычеркнул всего себя с семилетнего возраста и готов начать категорически
      новую жизнь, но и не то чтобы по-настоящему пережил свои многочисленные
      бесценные опыты. Меня просто как будто перекинуло в альтернативную реальность,
      где всё-всё — от Колошмы до смерти Шухера — было, но я с самого начала умел с
      этим жить, и поэтому горы неразобранного пиздеца в голове не накопилось.
      Правда, если бы так случилось на самом деле, половины пиздеца бы не произошло,
      что несколько портит мою стройную теорию. Зато не лишает её
      высокохудожественной метафоричности и, главное, понятности, а это уже большая
      радость, правильно?</p>

    <p>Откуда-то из
давних студенческих лет у Димы в голове сохранилось знание о том, что при
имперском дворе был титул, именование которого наиболее точно переводилось как
«Тупое Лицо» (в имперском, как и в росском, антонимы
понятий «острый» и «умный» обозначаются одним словом). Тупое Лицо,
      будучи мирянином, кажется, использовалось представителями духовенства для
      отработки проповедей и речей, отображая, так сказать, степень понимания оных
      народом. А может, и не представителями духовенства. А может, это был кто-то
      типа юродивого советника, который должен был глаголать простую жизненную
      мудрость. И вообще бытовало мнение, что Тупым Лицом изначально прозвали некоего
      конкретного дворянина за личные физиогномические особенности, а потом кличка
      пошла-поехала наследоваться. И, разумеется, никто не мог поручиться за
      реальность Тупого Лица — ни человека, ни титула. Ходили, впрочем, слухи, что
      первую арию изрядно уже надоевшей Кармины Бураны
      написало некое выдающееся Тупое Лицо.</p>

    <p>Дима не был уверен, что не
      придумал это всё вот только что.</p>

    <p>Но это не отменяло того, что
      Гуанако бы удалось работать <i>отличным</i> Тупым Лицом.</p>

    <p>Больно уж хорошо у него
      получалось слушать тирады с физиономией, выражающей «ага, я тебя услышал» и
      ничего больше.</p>

    <p>— Для Виктора Дарьевича твоя
      история болезни жидковата, — высказался всё-таки Гуанако, переварив тираду. —
      Обычные сумасшедшие давно не интересуют когнитивную науку, этой науке сплошных
      скопцов теперь подавай. И слушай, — добавил он голосом, выдающимся в комплекте
      с круглыми-круглыми глазами, — не съезжай с катушек, я тебя очень прошу. Горы
      трупов и безработных по результатам всей этой дряни —
      это ладно. А вот психи, не справившиеся с нервными
      перегрузками чумы, — уже откровенная пошлость.</p>

    <p>И Гуанако аккуратно и рассеянно
      пощупал Диму, как будто на ощупь можно определить, всё ли у него в порядке с
      головой.</p>

    <p>— Раз в жизни мне стало хорошо и
      прекрасно, а ты обзываешься психом! — возмутился тот.
      —&nbsp;И потом, уже явно поздно просить не съезжать, я остро чувствую финальность произошедших процессов. Переделок, хе-хе. Не
      знаю только, какие сверхспособности
      мне это даёт.</p>

    <p>— Проверяется эмпирически, —
      хмыкнул Гуанако и незамедлительно эмпирически (но аккуратно и даже, пожалуй,
      нежно) проверил, насколько крепко Димины руки крепятся к телу. — Великие психи силой мысли на заре времён обрушивали под землю
      Вилонский Хуй. Возможно, пора его поднять, — он задумался. — Ты меня до того
      запугал, что я даже сразу не сообразил, что пошутил дурацкую
      пошлую шутку. Так вот, дурацкая пошлая шутка! Где же
      овация, ёба?</p>

    <p>В дверь постучались —&nbsp;увы,
      не овации, а всего лишь жидкости. Гуанако снова подскочил и вернулся уже с
      дарами на здоровом жестяном подносе.</p>

    <p>Кто бы ни исполнял заказ, он
      понял его крайне буквально: на подносе имелось всё, от графина с водой до
      неопределимой ёмкости с неопределимой, но явно сильноалкогольной
      настойкой.</p>

    <p>Интересно, есть ли там что-нибудь
      из биологических жидкостей.</p>

    <p>— Так вот, <i>до омерзения серьёзно я про Хуй говорил,</i> — продолжил в третий раз усевшийся
      на койку Гуанако, не размениваясь на
      разливание воды и протягивая Диме весь графин. — Мы с Виктором Дарьевичем на
      теоретическом уровне кое в чём безусловно сходимся.
      Это кое-что я ещё до диплома в его опубликованных работах читал: любая
      психическая нестабильность может быть переработана психикой же в религиозном —
      и околорелигиозном — ключе. Все эти экстазы,
      просветления и откровения, характерные для религиозного сознания, — это же и
      есть естественные физиологически-культурные механизмы продуктивно справляться
      со сбоями. И подмена исчерпавшей себя доктрины, да ещё и навязанной Европами,
      переворачиванием деревьев в масштабе всей страны — тоже туда, — залился он соловьём,
      позабыв о том, что только что носился над умеренно
      страждущим. — Ты ж не читал, небось, мою блядскую кандидатскую? А там было что-то почти даже совсем умное на
      сей счёт. Любые религиозные чудеса с точки
      зрения этой самой когнитивной науки — это своего рода контролируемое
      сумасшествие. Шаманство, пророчество и прочая поебень
      только на нём и держатся. Что уж говорить о воплотившихся в предсмертном бреду внутренних
      голосах! Стандартная ведь архетипическая схема.</p>

    <p>— Шаманство, пророчество и прочая поебень — это по твоей части. Для превращения
      тривиального сдвига по фазе во что-то такое нужна некая, эээ,
      парадигма мышления. Вера в богов там, в ритуалы или в пропаганду — даже не
      вера, а просто их постоянное присутствие в мозгах. А у меня этого нет. Не по принципиальным
      соображениям, ты сам знаешь, а просто потому что я
      живу в некоем другом мире. В мире частностей, где есть жажда, Максим с
      пистолетом и вон то шестипалое запястье на стенке, а ни богов, ни постулатов
      Набедренных нет, о них получается думать только
      специально. Если попросят, — Дима щедро отпил из графина, выдохнул и продолжил.
      — И вообще, это какое-то упрощение и попытка загнать себя в рамки, которых не
      существует от природы. Вот, допустим, случилось с человеком — совершенно
      абстрактным человеком, я сейчас никого не имею в виду —&nbsp;<i>что-то</i>.
      Можно назвать это что-то
      просветлением, можно назвать это что-то каким-нибудь психиатрическим термином,
      но это ведь всё — дополнительные осмысления. Конструкты, сооружённые поверх
      действительности. А в действительности есть только <i>что-то</i>, и никак оно не называется. Разве что переделкой, потому что
      эта шутка по-прежнему кажется мне остроумной. — Дима сделал паузу, потом ещё
      один глоток. —&nbsp;Впрочем, если ты надумал меня обожествить, ничего не имею против. Главное —&nbsp;чтоб не переворачивал.</p>

    <p>— «Поверх действительности»! — возбушевал Гуанако. — Дмитрий, вы так говорите,
      как будто она в самом деле существует. Человек — сложная зверюшка, его действительность всегда попорчена <i>называнием.</i> Дополнительными осмыслениями
      и интерпретациями. И они, блядь, действительней любой
      действительности. Хуй с ними, с внутренними голосами, — вспомни хоть своего
      пациента степного с выколотыми глазками. Это для тебя он сдох,
      а для местного населения — отдал соки земле. А для пизданутой бабы, которая ему
      глазки и выковыривала, может, и ещё чего похлеще натворил. И кто прав? Если «в
      действительности есть только <i>что-то</i>,
      и никак оно не называется», выходит, что никто. А на самом-то деле правы все, —
      он чуть не снёс стоящий с ним рядом поднос широким движением руки. — Хотя чаще
      прав оказывается тот, кто сильнее убежден, а, следовательно, резвее действует,
      исходя из своих убеждений, — и выразительно посмотрел на Димин простреленный
      живот.</p>

    <p>Если долго вглядываться в живот,
      живот посмотрит на тебя в ответ, поостерёгся бы!</p>

    <p>Дима прекрасно знал, что Гуанако
      вообще-то парадоксальным образом не любит такие вот абстрактные споры высокой
      степени кухонности. Разве что с Виктором Дарьевичем,
      потому что с ним кухонности поменьше плюс столь
      актуальная поминутная оплата. А так — и из Бедрограда на Колошму, небось, сдался от
      переизбытка академичности в жизни.</p>

    <p>Абстрактные споры похлеще твири
      будут. Любой способный на них человек понимает, в принципе, что всё это пустые
      слова, но остановиться-то невозможно.</p>

    <p>Если ты не Гуанако.</p>

    <p>Тем приятнее было, что с Димой он
      всё-таки говорил, скрепя тупое лицо.</p>

    <p>— А обожествили тебя блядские
      скопцы, —&nbsp;весело добавил Гуанако, снова придвигаясь поближе. — Тебе мало,
      что ли?</p>

    <p>Ну, да, обожествили. Хотя такое
      обожествление не всякому врагу пожелаешь, знаете ли.</p>

    <p>Аутентичные скопцы, значит, верят
      в двух богов — доброго и злого. Боги, разумеется, близнецы. Гуанако и Диму
      принять за близнецов —&nbsp;это ещё надо постараться, но допустим,
      художественная условность. Дима, разумеется, злой, поскольку волосы темнее и вообще потому что он мерзкий тип.</p>

    <p>Всё бы ничего, но есть нюанс: у
      двух скопцовских богов на двоих — одно, так сказать,
      мужское естество. Передаётся друг другу с определённой цикличностью, кому не
      досталось — тот и злой. А у Гуанако с Димой —&nbsp;всё-таки два. И как-то это
      разительно выяснилось на практике, когда скопцы, решив, что пред ними явление
      божественного, возжелали некоего подтверждения.</p>

    <p>Димино естество могло их
      несколько разочаровать.</p>

    <p>В итоге обошлись умолчаниями и
      правильной риторикой, но всё-таки обожествляться Дима отныне решил с умом.</p>

    <p>С другой стороны, впрочем, есть и
      плюсы. Если бы скопцы тогда не уверовали почему-то (по кому-то), что видят
      живых богов, они не повиновались бы божественному провидению, которое, опираясь
      на божественное же чувство юмора, указало им путь в Бедроград. Один из скопцов
      лично помнил Набедренных, как можно было не осчастливить кафедру таким
      приобретением?</p>

    <p>Кто ж мог ожидать, что скопцы окажутся столь небыстры и доберутся до кафедры
      только тогда,
      когда Дима и Гуанако успеют сами дотуда добраться, оттуда убраться и
      нарисоваться там снова. Кто ж мог ожидать, что это окажется так своевременно и
      так подозрительно. Кто ж мог ожидать, что скопцы такой ходовой товар.</p>

    <p>Наверное, у обожествления есть и
      другие плюсы —&nbsp;способность иногда оперировать рукой, ногой и естеством
      судьбы, например.</p>

    <p>Судьбы, на произвол которой Дима
      вовсе не собирался покидать абстрактный спор.</p>

    <p>— То, о чём вы говорите, Сергей
      Корнеевич, — это вообще давняя проблема когнитивной науки, —&nbsp;степенно
      изрёк он. — Определяет ли <i>называние</i> мышление? Называя некое своё ощущение словом, я причисляю его к определённой
      категории, где оно соседствует с другими ощущениями, которые другие люди
      называли тем же словом. Вопрос в том, насколько я себя при этом оскопляю. Я
      могу сказать, что люблю тебя, и Габриэль Евгеньевич то же самое говорил,
      известное дело. Испытываем ли мы с ним при этом одно и то же? Нет же. Одинаково в лучшем случае ядро ощущения, некая, скажем,
романтически-эротическая привязанность, а детали —&nbsp;в которых, напомню,
истина — разные. И обрезать их, выдавая термин, лично мне как-то обидно.
      Вот и то, что со мной случилось, я не хочу никак называть, потому что
      результаты моей интроспекции говорят, что это <i>что-то</i> сложнее и многограннее, чем любое слово, которое я могу
      подобрать. И чем любая парадигма мышления. Я уникален и исключителен.</p>

    <p>— Сын мой, к нашему общему
      сожалению, вы кончали университеты на особых условиях, — помахал Гуанако перед
      Димой перстнями, сделанными из клавиш печатной машинки, на которой Дима, помнится,
      дописывал свой диплом. — А потому не держите в уме существенную деталь, в
      которой тоже есть истина. <i>Мышление без
        называния</i> никому в хуй не упало. Называние — не только когнитивный акт, но
      и коммуникативный. Социальный, сечёшь? Пока никак не назвал, ничего и нету. Называние — его
      необходимость и конкретная форма — социально обусловлены, это с одной стороны.
      С другой, называние, как мы уже выяснили, само
      обусловливает мышление. Вывод: мышление тоже социально обусловлено. Рамки,
      которым ты так противишься, существуют, потому что без них не существовало бы
      ничего. Всем насрать, <i>что</i> происходит, но всех ебёт, <i>как</i> ты это называешь. А не называешь ты — назовут тебя, — ещё раз покосился он на
      Димин живот, намекая, вероятно, на то, что чем дольше человек ходит весь из
      себя такой уникальный и исключительный со сложными непередаваемыми чувствами,
      тем скорее кто-нибудь интерпретирует эти чувства в меру своего разумения и
      решит за них покарать.</p>

    <p>Например, припишет кровавую
      ревность и зачумление Габриэля Евгеньевича.</p>

    <p>Дима отстранённо попытался
      вспомнить, бесил ли его когда-нибудь Габриэль Евгеньевич <i>настолько</i> сильно. Сильно — бесил. И ревновал Дима сильно (ревновать
      к прошлому — это такое особое развлечение, не всем доступно). И по лицу
      Габриэля Евгеньевича не только в первый день чумы, но и после исчезновения
      Гуанако на Колошму бил (так само вышло). Но всерьёз и осознанно причинять ему
      вред Дима бы вряд ли стал.</p>

    <p>Потому что ревность, раздражение
      и желание врезать хорошенько, безусловно, были вызваны (обусловлены, хе-хе)
      Габриэлем Евгеньевичем, вот только это не его вина, а специфика Диминого
      восприятия.</p>

    <p>Диминому восприятию и следует
      бить морду. По-хорошему если.</p>

    <p>А профессионально
      деформированного идеологией Гуанако социальное
      волнует.</p>

    <p>Какое
      может быть социальное, когда столько граней внутренней многогранности не
      прояснились!</p>

    <p>— А вы, батюшка, так говорите,
      как будто определённая обусловленность мышления социальным
      —&nbsp;которой я, заметьте, не отрицаю — это знак равенства. А это не знак
      равенства. Любой коммуникативный акт — это презентация чего-то своего. Она в
      определённой степени условна, но презентующий должен
      степень этой условности понимать. Скажем, если ты видишь, что небо всё такое
      бледно-бледно-жемчужно-голубенькое, ты можешь сказать по телефону столичному
      другу, что оно голубое, поскольку все нюансы его действительно не ебут, и
      зазор, который получается между твоим восприятием и твоей презентацией, некритичен.
      Только чтобы не скатиться к лешему в отсутствие мышления в принципе, нужно
      всегда осознавать габариты этого зазора. Если видишь, что что-то смахивает на
      хуй, сперва убедись в том, что это действительно хуй
      во всём многообразии своих проявлений, и только потом обзывайся. Если это
      что-то смахивает на хуй только весьма отдалённо — тем более восемь раз отмерь.</p>

    <p>Обожествление оставляет свой след
      в душе и выборе выражений.</p>

    <p>— Всё это было бы справедливо,
      если б любой хуй можно было вот так запросто обмерить, — мигом вернулся к
      практическому аспекту беседы Гуанако и улёгся с Димой совсем
рядом, головой на одну подушку — ухо почуяло, что улыбаясь. — Но мы-то
      имеем дело с труднообмеряемым хуём!</p>

    <p>Истинно так.</p>

    <p>В завешенное одним цельным куском
      льняной ткани окно лезли жёлто-красные (ну такие вот, как бы описать, тёпло-золотисто-розовато-прозрачные, таким иногда бисер
      делают, и откуда только Дима знает, каким бывает бисер) лучи солнца. Всё-то
      время он думает о солнце — любит потому что.</p>

    <p>Мог бы, например, подумать о том,
      что всю чуму моросило, а сегодня не моросит — значит, этот день и правда
      последний. Мог бы — о том, что точно такое же солнце висело в небе сегодня
      утром, когда они ехали в такси с Максимом, и что оно при этом висело совершенно
      иначе. Мог бы — о том, что в степи почти не бывает дождей, но солнца всё равно
      не видно, потому что оно всегда в какой-то пелене, расплывается и отказывается
      быть точкой.</p>

    <p>Мог бы, но не думал — не
      получалось толком сосредоточиться ни на чём, кроме тёпло-золотисто-розовато-прозрачных
      лучей, рассеянных поглаживаний Гуанако и не холодящего руки графина с водой
      (ещё б их что-то холодило, и так ледяные). В мире существовало только то, что
      можно потрогать, и то, чего принципиально потрогать нельзя, вроде абстрактных
      споров.</p>

    <p>Голова захлопала ушами и улетела
      к солнцу, оставляя телу возможность воспринимать только самое актуальное
      —&nbsp;и то в виде большого одолжения. Прошлое, будущее и чем всё это закончится пытались взволновать, но не волновали.</p>

    <p>Можно же просто жить, а не жить в
      растянутом между вчера и завтра виде, как к батарее прикованным.</p>

    <p>Можно быть точкой, а не
      расплываться в пелене.</p>

    <p>— Если хуй не получается измерить
      в ходе наблюдения, необходимо ставить эксперименты, — сообщил Дима Гуанако. — К
      слову, у меня к тебе был важный вопрос. Насколько драматично —&nbsp;по
      десятибалльной шкале — я выглядел, когда меня принесли?</p>

    <p>Гуанако посмеялся —&nbsp;именно
      посмеялся, а не заржал. Аккуратно и немного неловко, как будто от слишком
      громких звуков вспомнилось бы слишком много весёлого.</p>

    <p>— Знаешь, а я не понял, — опять
      чуть виноватым голосом признался он. — Глянул мельком и так с одного взгляда
      охуел, что просто как-то не получалось заставить себя посмотреть ещё раз.</p>

    <p>Рассеянные поглаживания были на
      самом деле растерянными и слегка встревоженными, никаким абстрактным спорам
      этого не перебить.</p>

    <p>— Жаль, — вздохнул Дима, — может,
      это пролило бы свет. А то знаешь, мне сейчас каждый глоток воздуха кажется сладостным
      нектаром, а каждый звук твоего голоса — небесной серенадой. И причины этого, в
      общем-то, ясны — не сдох же. Но это ведь не на самом
      деле, правда? А что на самом деле, я не могу понять, потому что у меня плохо
      получается думать.</p>

    <p>Гуанако не жаждал мыслительных
      процессов, Гуанако жаждал исключительно тактильных ощущений, которых тут же
      получил сполна.</p>

    <p>В эпоху великой слабости во всём
      теле сложно просто тереться о первую попавшуюся под
      щёку часть тела, но тем приятнее.</p>

    <p>— Ты не привыкай к серенадам, всё
      равно отпустит скоро, — по-бытовому пожал тщательно
      потёртыми плечами Гуанако. — Отходняк
      у тебя обыкновенный. Наслаждайся, пока не рассосётся.</p>

    <p>— Я и наслаждаюсь, — улыбнулся
      Дима. — Ощущения, не при тебе будь сказано, как после особо одухотворяющего эротического
      акта. Все бурные переживания всё-таки — одно и то же.</p>

    <p>Ответно потиравший Димин затылок
      палец Гуанако ревниво замер.</p>

    <p>Сам Гуанако —&nbsp;надулся.</p>

    <p>— Я так и знал, что всё хуйня!
      Все эти подозрения, кто кого заразил, тьфу. Максим мне всего-навсего отомстил
      за то, что я у него Габриэля Евгеньевича на пару недель подрезал, — пробурчал
      он. — Все ваши высокодуховные разборки с морем кровищи отныне классифицируются
      как измена.</p>

    <p>Гуанако патологически не умеет
      обижаться, а когда пытается —&nbsp;у него выходит как-то дурашливо и невсерьёз. И
      это особая марка умилительности.</p>

    <p>По поводу чего Дима совсем-совсем
      растаял.</p>

    <p>— Измена без взаимного согласия
      классифицируется как насилие, а насилие надо мной тебя, помнится, более
      интересует, чем возмущает. Так что наслаждайтесь с нами, наслаждайтесь как мы,
      наслаждайтесь лучше нас.</p>

    <p>Гуанако призадумался над этой
      сентенцией, возмутился, сжал Диму так, как при его ранении явно не полагалось
      (серенады не серенады, а глухая боль во всём теле, головокружение и слабость
      проходить не спешили), снова задумался и пожевал один из эротических регионов
      Димы.</p>

    <p>— Я в растерянности, —
      пробормотал он сквозь жевание. — Как-то всё совсем, гм, как раньше. Сильно, <i>сильно
        раньше.</i> Ещё и весь этот трёп про мышление и действительность — это же прямо
      как до всякой
      Колошмы. Как дома под самогон и под твои заверения, что тебя не надо выгонять,
      потому что ты носки стирать умеешь. Ни разу ведь так и не прикоснулся, блядина.</p>

    <p>Вот Дима и говорит — стал совсем
      как если бы жил нормальной жизнью. Курс экспресс-омоложения имени Максима
      Молевича.</p>

    <p>— Тот факт, что я их не стирал,
      не означает, что я <i>не умею</i>. Что заявлялось — то и получили. И вообще, я собирался! —
      воскликнул он крайне искренне. — Все те два года, что мы протрепались под
      самогон, пока тебя на Колошму не того. Потом разве что в собственных слезах
      стирать пришлось бы, а это слишком трагично.</p>

    <p>Гуанако ещё сильнее задумался.</p>

    <p>— Жидкости, да, — переключился он
      с ностальгии на жизнь насущную, не отрываясь, впрочем, от жевания. — Я не то
      чтобы надеюсь услышать что-то новое, но, блядь, дом Габриэля Евгеньевича и
      жидкости из-под крана! Бедроградская гэбня на них совсем повёрнутая
      в последние дни. Сначала у меня командир всё выспрашивал и выспрашивал, потом
      вон к Максиму все вместе сходили за тем же самым, — любознательный Гуанако
      совсем выключился из действительности и замер. — Неужто и
      правда не знают, чьих рук дело? Очень странно. И я, конечно, готов хоть на себя
      взять это заражение, если кому-нибудь от этого станет легче и спокойней, но,
      леший еби всех их батюшек, мне уже самому интересно, кто на самом деле так
      постарался!</p>

    <p>У Гуанако тоже проблемы, таврским
      хлебом не корми — дай только взять на себя какое-нибудь преступное деяние.
      Контрреволюционное движение? Гуанако виноват! Савьюра застрелили? Гуанако,
      Гуанако виноват! Кто-то заразил дом Габриэля Евгеньевича? Мы пока не знаем, кто
      виноват, но с задней парты уже тянется решительная рука.</p>

    <p>— По-прежнему не я, — честно
      ответил Дима, — а большего сказать не могу. Всё-таки
      гораздо проще быть Максимом, чем тобой. Он сочинил себе ответ и доволен, ему
      больше ничего и не надо, а ты, прости за выражение, думаешь. Я по-прежнему не
      могу сообразить, кому из дружественных мне людей такое
      могло прийти в голову — ну не Таха же Шапка приехал в Бедроград завкафов
      травить! Мучительная предвзятость указывает на Бедроградскую гэбню, но здравый
      смысл —&nbsp;на то, что, как и все события, которые никому не были нужны и
      обернулись чем-то масштабно нехорошим, это какое-то недоразумение. Случайность,
      недосмотр, комическое взаимонепонимание. Ошибка. Другое дело, что ошибки тоже
      допускает <i>кто-то</i>, но об этом мне
      сейчас лень думать. И в обозримом будущем эта лень проходить не намеревается.</p>

    <p>— Ночью встреча, ночью будут
      ебать за дом Габриэля Евгеньевича, — по-прежнему задумчиво отозвался Гуанако. —
      И чё-то я вообще пока не представляю, что им
      ответить. Ну, кроме запасного варианта, что это я был.</p>

    <p>— А я чё-то
      не представляю, почему кто-то должен кому-то что-то отвечать. Мы имеем ровно
      столько же права спрашивать об этом их, разве нет? — Дима гневно зафырчал. — Ты
      бы лучше подумал о том, что собираешься делать с Максимом. Если собираешься.
      Собираешься?</p>

    <p>Здравствуй, Тупое Лицо, плавно
      перетекающее в лицо виноватое.</p>

    <p>Кто-то тут, в отличие от,
      патологически не умеет не только обижаться, но и
      раздавать наказания.</p>

    <p>Экзамен у Гуанако — радость всей
      сессии!</p>

    <p>— Ты только не подумай, что я
      жажду кровавой мести, — мне сейчас, в конце концов, лучше, чем во все последние
      месяцы, плюс-минус сомнения в собственной способности ходить, —&nbsp;поспешил
      утешить его Дима. — Я наоборот. Мне почему-то кажется, что сейчас ему как раз
      очень хотелось бы, чтобы решение за него принял кто-нибудь другой, а ты у нас
      мастер этого дела. Впрочем, если эта тема тебе не нравится —&nbsp;а она тебе не
      нравится, я знаю, — можно поговорить о чём-нибудь другом.</p>

    <p>— Или сразу накуриться,
      —&nbsp;рассеянно утешился Гуанако.</p>

    <p>Просто он не умеет
      (патологически!) принимать решения вслух. Это ж их сформулировать надо. Назвать.
      Понять, чего вообще творишь.</p>

    <p>Человеку, излюбленный метод
      взаимодействия с реальностью которого — сделать, а потом, если сильно побьют,
      так и быть, подумать, — не следовало тут гнать про социальную обусловленность
      мышления.</p>

    <p>— Или сразу накуриться, — мирно
      согласился Дима. — Хотя мне несколько обидно, что посул
      Охровича и Краснокаменного про то, что я тоже реальная университетская власть и
      должен в известном месте присутствовать, не воплотится. Они ж меня потом отыщут
      и добьют за нарушение их планов!</p>

    <p>— Не хочу сейчас об этом. Вот ни
      о чём вообще не хочу, — тёпленько пробормотал Гуанако, не прибавив «кроме
      тебя», за что ему спасибо.</p>

    <p>Нужно же всё-таки держать планку
      высокого стиля.</p>

    <p>— А с другой стороны, у меня есть
      подозрение, что мне лучше, чем я сам для себя малюю, — продолжил Дима, невольно
      игнорируя предложение заткнуться, —&nbsp;и поди
      разбери, с чем это связано: то ли с прочисткой мозгов, то ли с тем, что
      стимуляторов в моей крови уже столько, что самой крови много не нужно. Мне бы вообще-то сейчас лежать в глубокой отключке
и слышать белый шум, а я смотри какой бодренький. И потом, может, я
      вызову у Бедроградской гэбни жалость? Или хотя бы нежелание стрелять там, где
      уже настреляно?</p>

    <p>— Может, не может — опять, —
      по-собачьи вздохнул Гуанако. — Слушай, прости, я уже,
кажется, без вспомогательных средств «может» от «не может» не отличаю.
      Требуются профилактические процедуры, блядь.</p>

    <p>С этими словами он извлёк из
      сапога стебелёк савьюра и принялся крайне методично использовать его по
      назначению. Сам Дима предпочитал профилактические процедуры алкогольного типа
      (всё равно ж от травы толком не пёрло), поэтому первую
      савьюровую самокрутку проигнорировал, но потом выяснилось, что из одного
      стебелька получается на удивление много продукта потребления.</p>

    <p>Гуанако не
сильно изменился в лице (ещё б он от какого-то жалкого савьюра, ежели с
маленькой буквы, менялся), но всё-таки расслабился. Рассказал свои мысли
      относительно Бедроградской гэбни и Диминого в её судьбе участия, и свои мысли
      относительно разумных дальнейших действий в адрес Максима, и своё истинное
      отсутствие мыслей относительно заражения дома Габриэля Евгеньевича. Рассказал —
      и при этом не дёргался, как обычно, не думал по полтора часа перед каждым ответом и
      интеллектуальные шутки вворачивал совсем уместно.
      И был при этом и в самом деле таким, как сильно, <i>сильно раньше</i>.</p>

    <p>Харизматичным, остроумным и знающим
всё на свете —&nbsp;или уж точно знающим, как можно без этого знания обойтись.
      Весёлым, простым и крайне заразительным в своей
      весёлости и простоте. Как раз таким, каким полагается быть отцу.</p>

    <p>В общем, савьюр с маленькой буквы
      явно действовал на Гуанако лучше, чем Савьюр с
      большой.</p>

    <p>Дима проглотил ностальгические сопли.</p>

    <p>На третьей савьюровой
      самокрутке он решил, что всё равно уже слишком много
      пассивно выкурил, чтобы далее сопротивляться неотвратимой поступи нереальности.
      Гуанако воспротивился было, апеллируя к тому, что Дима
      же этого не употребляет и вообще, но Дима резонно заметил, что ему надо не для
      мыслительных процессов, а для развлечения и научного эксперимента относительно
      воздействия наркотиков разнообразной степени тяжести на его и без того
      измочаленный организм. Гуанако воспротивился было ещё
      раз, но Дима решил, что лимит честности на сегодня исчерпан, и прибег к
      неспортивным методам.</p>

    <p>Тактильного типа.</p>

    <p>У Святотатыча где-то должен был прятаться гербарий запрещённых к употреблению растений
посерьёзней савьюра, Дима это точно знал. А Гуанако знал, где (и что
      конкретно со всем этим полагается делать).</p>

    <p>Святотатычевские
      обширные запасы, оказывается, жили прямо тут, под матрасом койки.</p>

    <p>Святотатычевские
      обширные запасы, оказывается, были просто невыносимо гадкими на вкус.</p>

    <p>Святотатычевские
      обширные запасы, оказывается, испускали странный густой дым, который
      обволакивал висящее за окном солнце пеленой, заставляя его расплываться так,
      что оно переставало быть точкой.</p>

    <p>&nbsp;</p>

    <p>Степь была очень мягкой, как
      будто сделанной из толстой ворсистой ткани, и раскидывалась во все стороны до
      предела, где-то там, за пару сантиметров до него, превращаясь в такое же мягкое
      и ворсистое небо.</p>

    <p>Это кокон, понял Дима, и ещё —
      это колыбель.</p>

    <p>Алхимическая печь, состояние
      зародыша, который вправе выбрать, выходить ему наружу или нет.</p>

    <p>Состояние зародыша, который может
      себя осознать и по-настоящему взвесить за и против.</p>

    <p>Дима был совсем маленьким, таким,
      что его как бы и не было, и совсем прозрачным. Ещё не человек, пока что —
      только точка обзора, немая и неподвижная.</p>

    <p>Воздух в коконе струился как
      вода, тёплый и жидкий, спокойный и пробегающий по несуществующему телу рябью.</p>

    <p>Абсолютный покой.</p>

    <p>Каждая травинка, каждый стебель
      под несуществующими ногами был таким же зародышем, предпочетшим остаться в
      коконе навсегда. Они шептали что-то неразборчивое на неведомом травяном языке и
      гладили несуществующие лодыжки.</p>

    <p>Приглашая.</p>

    <p>Всё, что каза��ось финальным, было
      только первым этапом, понял Дима. Между жизнью и смертью растёт трава, и можно
      расти этой травой. Тогда всё —&nbsp;всё, всё — будет просто скользить поверху,
      не задевая.</p>

    <p>Абсолютный покой.</p>

    <p>Навсегда.</p>

    <p>Разве кто-нибудь может желать
      чего-то другого?</p>

    <p>В поле зрения точки обзора
      появилось что-то далёкое — только так Дима и понял, что обернулся: степной
      кокон был со всех сторон одинаковым, замкнуто-бежевым, мягким. Далёкое
      постепенно становилось ближе — только так Дима и понял, что двигается.</p>

    <p>Постепенно он разобрал, что
      видит: сколоченные из грубых досок нары, столик — откуда-то знакомая обстановка
      одиночной камеры, только прямо посреди травы, на ветру, без стен. В камере
      сидели двое и разговаривали: человек в голубой рубашке и ещё кто-то, кто
      располагался к Диме спиной. Дым их сигарет втягивался в дыру на краю горизонта.</p>

    <p>Солнце.</p>

    <p>Не прерывая разговора, человек в
      голубой рубашке посмотрел на Диму, и тот понял, что больше не может
      приближаться. Ему страшно захотелось моргнуть, хотя век не было, и глаз не
      было, и человек в голубой рубашке вовсе не пугал его. Но дальше приближаться
      было нельзя, потому что второй, сидевший спиной, мог заметить. Если бы он заметил
      Диму, у того больше не осталось бы свободы выбирать самому.</p>

    <p>Ноги запутались в савьюре, и Дима
      остановился. Дима спросил что-то у человека в голубой рубашке, но у Димы не
      было рта и не было голоса, поэтому он сам не узнал, что; человек в голубой рубашке
      что-то ответил, но у Димы не было ушей и не было слуха, поэтому он не узнал,
      что.</p>

    <p>Желание моргнуть становилось всё
      сильнее.</p>

    <p>Нити дыма от сигарет, серебрясь,
      привязывали двоих говоривших к солнцу, к выходу наружу, к миру движения и
      беспокойства. Дима знал, что знает этих людей, но никак не мог понять, что
      случится, если он вспомнит их имена: выпустят его или, наоборот, оставят здесь
      навсегда.</p>

    <p>Но пока у него была
своя воля и не было своей нити, он мог просто моргнуть.</p>

    <p>&nbsp;</p>

    <p>Вдалеке раздался грохот, как
      будто проехала колесница.</p>

    <p>Что-то пошло не так.</p>

    <p>&nbsp;</p>

    <p>Он был так близок к рождению и
      уже видел во снах руку, тянущуюся к заслонке, но в самом начале апреля 1854-го
      над Бедроградом прошли впечатляющие грозы, и в одном из печных цехов не
      выдержала проводка. Электричество выключилось, и его восстанавливали на
      несколько минут дольше, чем хватало резервных батарей печей. Дима погиб вместе
      с десятками других зародышей, которые не могли существовать вне печных условий.</p>

    <p>Ему было семь лет, и он был так
      близок к тому, чтобы перебраться на крышу соседней кабинки, но крыша намокла от
      дождя, а мозоль на безымянном пальце не позволила достаточно крепко сжать руку.
      Дима умер через полтора часа в районной больнице от переломов, которые получил,
      упав с колеса обозрения.</p>

    <p>Ему было пятнадцать, он сбежал из
      отряда с неделю назад и был так близок к тёплому зданию зала ожидания с уютными
      скамейками, но всё-таки и не решился пробраться внутрь, чтобы заночевать.
      Вместо этого он нашёл какой-то открытый чердак, где и устроился, но наутро не
      смог подняться. Дима погиб от холода и истощения, и его нашли только через пару
      недель.</p>

    <p>Ему было семнадцать, и он был так
      близок к решению свернуть на бегу в украшенное
      колоннами здание истфака БГУ имени Набедренных, но побоялся, что столичные
      младшие служащие повяжут его прямо там, обратившись к Учёному совету, и не стал
      прикидываться абитуриентом. Диму сбило неведомо откуда взявшееся такси, когда он не
      глядя выскочил за угол здания истфака на проезжую
      часть.</p>

    <p>Ему по-прежнему было семнадцать,
      и он всё-таки решил спрятаться от погони на истфаке, предварительно закинув
      чемодан на ближайшую колонну, но не догадался сбросить ботинки перед тем, как
      карабкаться по ней. Дима умер мгновенно, сломав шею при неудачном падении.</p>

    <p>Ему было девятнадцать, и он
      только начал подозревать по слухам, что Гуанако не вернётся с Колошмы. По
      другим слухам последним, что тот сделал перед отъездом, было возвращение всего
      былого с Габриэлем Евгеньевичем. Дима не то чтобы всерьёз намеревался кончать с
      собой, но слишком сильно напился, когда забрался на ближайшую эстакаду, поэтому
      случайно свалился на пантограф проезжающего автопоезда.</p>

    <p>Ему было всё ещё девятнадцать, и
      он почти всерьёз собирался покончить с собой, повесившись на красных резиновых
      подтяжках. Повезёт — растянутся до пола, и он останется стоять как идиот. Не
      повезёт — окажутся недостаточно эластичными,
      задушат, а значит, и жить-то было незачем. Не повезло.</p>

    <p>Ему было двадцать один, и
      расстрел на Колошме должен был быть фальшивым, но один из группы людей слегка
      промахнулся.</p>

    <p>Ему было двадцать два, и он слишком категорически отказался добивать четырёх
      безымянных.
      Ему было двадцать два, и его не обошла стороной степная чума. Ему было двадцать
      два, и один из голов гэбни Колошмы усмотрел в его действиях опасность для
      иллюзии порядка. Ему было двадцать два, и балки горящего изолятора обрушились
      не так, как ожидалось. Ему было двадцать два, и он поднял голову из травы,
      недостаточно далеко отобравшись от прорванного кордона. Ему было двадцать два,
      и заточка вошла в спину чуть глубже. Не выдержал недельной ходьбы по степи.
      Отравился какой-то неизвестной травой. Провалился ногой в яму во время одной из
      ночных прогулок и не смог выбраться. Не понравился кружку особо религиозных
      степняков. На обратном пути в Бедроград попался на глаза охранникам Колошмы.
      Сорвался с галереи Вилонского Хуя на самое дно. Не убедил скопцов в своей
      божественной сущности. Попался Бедроградской гэбне до всякой чумы. Попался
      Бедроградской гэбне во время чумы. Пошёл-таки на встречу с Социем, а тот
      сорвался с катушек.</p>

    <p>И — Максим оказался недостаточно
      хорошим человеком.</p>

    <p>Выстрела оказалось не два.</p>

    <p>&nbsp;</p>

    <p>Бесконечное, неисчислимое,
      невыносимое множество вариантов, и с каждым новым шагом их всё больше. Зачем
      делать эти шаги, когда савьюр так уютно оплетает лодыжки?</p>

    <p>Чтобы узнать что-то новое.</p>

    <p>Чтобы <i>было</i> что-то новое.</p>

    <p>Дима моргнул ещё раз.</p>

    <p>&nbsp;</p>

    <p>Тяжёлая чёрная вода могла бы
      залить Святотатычу горло, когда-то давно, когда он стоял, привязанный накрепко
      и навытяжку, в трюме тонущего корабля.</p>

    <p>Воротий Саныч, бывший завкаф
      истории науки и техники, мог бы тихо не проснуться в один из этих дней, он уже
      стар.</p>

    <p>Габриэль Евгеньевич, до недавних
      пор завкаф истории науки и техники, мог бы не выбраться из мокрых пинежских лесов,
      куда поехал на втором курсе в поисках
      истины, когда был ещё молод.</p>

    <p>Ларий мог бы стать единственной
      жертвой контрреволюционного движения, если бы во время одной потасовки всё-таки
      не сумел сдержаться.</p>

    <p>Национализм в адрес тавров вполне
      процветает во Всероссийском Соседстве за пределами Бедрограда, и голова
      Бедроградской гэбни Бахта Рука ещё в далёком детстве мог бы лишиться не только
      косы.</p>

    <p>Бюро Патентов могло бы совсем
      иначе оценить разработки Попельдопеля, если бы не определённая государственная
      тайна.</p>

    <p>Врат Поппер, молодой человек,
      которого Попельдопель выдаёт за своего брата, мог бы не доехать из Германии до
      Всероссийского Соседства из-за страха Европ перед этой государственной тайной.</p>

    <p>Один из трёх настоящих авторов
      Кармины Бураны мог бы действительно погибнуть в Британии от того ножевого
      ранения.</p>

    <p>Андрей мог бы не успеть убраться
      с Колошмы, когда началась степная чума.</p>

    <p>Сепгей Борисович мог бы не суметь
      доказать, что табельное оружие у него украли.</p>

    <p>В заминированном ущелье,
      бабахнувшем под ногами всего спецподразделения Соция, мог бы остаться только
      один выживший.</p>

    <p>Стас Никитич, бывший голова гэбни
      Колошмы и нынешний секретарь Учёного совета БГУ, мог бы подхватить воспаление
      лёгких, когда после развала Колошмы расшифровывал тамошние протоколы в Порту в
      голом виде.</p>

    <p>На четвёртом курсе экономный
      Виктор Дарьевич мог бы умереть от пищевого отравления.</p>

    <p>Муля Педаль мог бы попытаться
      всё-таки пойти учиться на контрабандиста.</p>

    <p>Столичная гэбня могла бы успеть
      выстрелить по Гошке.</p>

    <p>Максим мог бы выпить тот стакан
      зачумлённой воды, который поставил на подоконник в пылу ссоры с Габриэлем
      Евгеньевичем в четвёртый день чумы.</p>

    <p>Верёвка Гуанако могла бы не
      оборваться, если бы он внимательней слушал Святотатыча, который с поры трюма
      научился вязать действительно крепкие узлы.</p>

    <p>&nbsp;</p>

    <p>Нет, не то. Это новое —
      ненастоящее, это новое — из кокона, это — не новое. Это игры воображения, их
      нет на самом деле.</p>

    <p>Кто-то говорил Диме, что самого
      дела тоже нет, что действительности нет, но они есть.</p>

    <p>И самая суть —&nbsp;в самом деле.</p>

    <p>«Бы» — крошечная, невесомая
      частица, но Дима предпочтёт ей союз «но».</p>

    <p>С него начинается новое.</p>

    <p>Дима ведь за этим сюда пришёл.</p>

    <p>Он моргнул ещё раз.</p>

    <p>&nbsp;</p>

    <p>Казалось бы, в мае семьдесят
      пятого Хикеракли только-только исполнилось семьдесят восемь, самое время
      ложиться и помирать от старости в своей постели, но умер он от удара топором по
      голове. Решил проверить кое-какую догадку, причём не
      полагаясь на всяких там, самостоятельно —&nbsp;и подозревал, в принципе, что
      догадка обернётся государственной тайной. Кто ж знал, что её охраняют <i>настолько</i> <i>ревностно</i>. Зато тайна посмотрела на него своими глазами и наверняка
      узнала, так что он нисколько не пожалел. Вот ни капельки. Тем более что
      Хикеракли заранее завещал похоронить себя прямо под Первым
      Большим Перевёрнутым, чтобы в день Первого Обратного, когда выкопают
      теперь-уже-корни, помахать Всероссийскому Соседству ручкой. Он же естественнорожденный, его костям за двадцать лет в земле
      ничего не сделается.</p>

    <p>Димин отец ничего не успел
      понять, когда его зацепило карданным валом. Последнее, о чём он думал, — это
      как нужно переоборудовать второй вспомогательный цех по отладке осей, и под
      «переоборудовать» он имеет в виду «закрыть к лешему», потому что такая проверка
      качества накладна и не стоит выделки.</p>

    <p>Из четверых безымянных двое
      действительно уже ничего не соображали и инстинктивно хотели, чтобы их добили,
      один —&nbsp;самый первый — страшно злился и немного надеялся, что Дима
      откажется и всё обернётся каким-нибудь недоразумением. Четвёртому, последнему,
      оставалось сидеть всего три года, но накануне его мучила бессонница и какое-то смурное предчувствие, так что он
      даже не удивился, разве что испытал покорную досаду. Когда Дима не смог с
      первого раза пристрелить его насмерть, он вообще потерял к происходящему
      интерес и всерьёз задумался о том, закончилось ли всё уже с точки зрения его
      предчувствий, или всё-таки добьют. Он пришёл к выводу, что закончилось, и,
      когда Дима снова направил на него пистолет, хотел поспорить и что-то доказать,
      но не успел.</p>

    <p>Лицо Революции Набедренных
      долго и скучно умирал от заражения и потери крови после того, как неудачно
      попытался оскопиться. Большую часть времени он был без сознания, приходя в себя
      только тогда, когда его снова беспокоили врачи, которые искренне пытались
      помочь, но в итоге только мешали друг другу из-за переизбытка указаний,
      выданных разными членами Революционного Комитета, чьи голоса всё время смутно
      доносились из соседней комнаты. Думал Набедренных при
      этом о том, что проходит последнее духовное испытание, после которого он сможет
      истинно принять скопничество.</p>

    <p>Неизвестный Диме голова Столичной
      гэбни был страшно перепуган и пытался сбежать, но тут-то
      и обнаружил, что годы уже не те, а сидение при государственной кормушке
      подорвало его физическую форму. Он знал, что ещё одного голову Столичной гэбни
      уже застрелили и что сейчас придут за ним. Бедроградская гэбня покончила с ним
      довольно жёстко, успев перед смертью рассказать не только о вреде вербовки
      людей (ну и что, что неудачной) на чужой территории, но и о том, что он в
      принципе был плохим головой гэбни, так что невелика потеря.</p>

    <p>Удивительно, насколько
      по-взрослому умерла Бровь. За несколько секунд,
      прошедших с момента появления такси в конце переулка
      до их столкновения, она всё поняла, посетовала на краткость своей жизни и очень
      искренне похихикала над тем, что это всё-таки тавр-таксист. Не успела только
      разглядеть, что без косы. Ей было вовсе не обидно, и она возлагала большие
      надежды на неизвестного автора, который написал бы шпионский роман с
      неожиданной трагической гибелью главной героини.</p>

    <p>Удивительно, насколько по-детски
      умер Шухер —&nbsp;со стулом в руках. Он всегда мечтал о портовой романтике, он
      всегда хотел быть сильным и ловким, у него очень болело сердце, и ему было
      совсем нечего терять. А кроме того, он знал
      —&nbsp;правда знал —&nbsp;что где-то там, далеко, растут разумные кристаллы,
      которым плевать на шевеление белков и которые ждут не дождутся, пока Шухер
      покинет своё мясное тело и присоединится к ним.</p>

    <p>Слепому младенцу было очень,
      очень больно, когда в его глазницах оказалась твирь.</p>

    <p>Юр Саввович Бегичев, Савьюр,
      Начальник Колошмы, совершенно не вспомнил о том, что договаривался с охранником
      постучаться в собственный кабинет. Он вообще забыл об охраннике, у него другое
      было в голове. Как странно, что 66563 <i>совсем
        не похож на</i>. Как переустроить Колошму, чтобы она <i>действительно работала</i>. Какой Андрей всё-таки жалкий, и как теперь
      всё-всё будет по-другому. Выстрела было всего два: в печень и в сердце. Савьюр
      возмутился в адрес охранника, сообразил, что сам виноват, возмутился в адрес
      себя и подумал, что умереть вот так, когда только-только померещилось долго и
      счастливо, — это какое-то свинство. Блядство, как
      сказал бы 66563. Да ещё и два кровавых пятна на глубоко любимой голубой
      рубашке.</p>

    <p>После попадания в сердце мозг
      умирает не мгновенно, и сквозь окно без решёток Савьюр увидел степь. Степь была
      очень мягкой, как будто сделанной из толстой ворсистой ткани, и раскидывалась
      во все стороны до предела, где-то там, за пару сантиметров до него, превращаясь
      в такое же мягкое и ворсистое небо.</p>

    <p>Это кокон, понял Савьюр, и ещё —
      это могила.</p>

    <p>Могила, которой нет и не может
      быть, потому что умерших на Колошме сжигают.</p>

    <p>&nbsp;</p>

    <p>Шаман подошёл к степному доктору
      со спины и слегка приобнял — степнякам плевать, лишь бы работу работали, и
      вообще —&nbsp;шаманов боятся все, даже те, кто к ним ходит, так что они на
      особом счету. «Пойдём уже, —&nbsp;занудел шаман, — решили
      идти — так пойдём». «Пожалуйста, ещё чуть-чуть, — ответил доктор, — мне немного
      осталось». «Чего?» «Мне кажется, я почти всё понял». «Представляю, чего ты там
      у себя в голове наворотил. Пойдём, по дороге
      расскажешь!» «Вот так просто? Я не хочу ошибиться, тут сокрыты глубинные
      смыслы».</p>

    <p>«Вы доктор, вам виднее, глубинные
      — так глубинные, — захихикал шаман, потом рассудительно оставил надежду уйти
      хоть куда-нибудь прямо сейчас и задумчиво закурил савьюр. — Слушай, помнишь
      того подозрительного элемента, которого вы в Столице ногами пинали
и с которого всё началось? Ничего с ним тогда не сделалось, ты на пустом
      месте зассал. Ну повалялся месяц в больнице, потом
      только лучше стал. Уже, правда, умер. Совсем при других обстоятельствах». «Ты
      так говоришь, как будто это что-то меняет», — фыркнул доктор. «Я так говорю,
      потому что я шаман, а шаман должен время от времени порождать что-то
      значительное. Полное глубинных смыслов».</p>

    <p>Дима улыбнулся и протянул к
      Гуанако руку. «Я мог бы придумать тысячу причин, по которым мне было бы лучше
      остаться здесь…» «Это игры воображения, их нет на самом деле». «…Не перебивай
      ты! Я мог бы придумать тысячу причин, по которым мне было бы лучше остаться
      здесь, но ведь слишком же интересно, чем всё закончится».</p>

    <p>А «но» всё-таки всегда лучше, чем
      «бы».</p>

    <p>(И потом, если
бы Дима поддался порочному желанию просто успокоиться, Гуанако бы его порицал и
был бы прав.</p>

    <p>Но Дима не
поддался.)</p>
  </>
}

export default chapter35
