import React from 'react'
import {RouteComponentProps} from "react-router";
import news from '../../Data/news'
import NewsArticle from "../NewsArticle/NewsArticle";
import tagNames from '../../Data/tagNames'
import Helmet from "react-helmet";

const TaxonomyPage: React.FunctionComponent<RouteComponentProps<{
  term?: string
}>> = ({ match }) => {
  const term = match?.params?.term as string
  const articles = news.filter(article => article.tags.includes(+term))
    .map(article => <NewsArticle key={article.id} article={article}/>)

  const newTagNames = tagNames as {[key: string]: string}

  return <>
    <Helmet>
      <title>{newTagNames[term]}</title>
    </Helmet>
    <main>
      <h1>{newTagNames[term]}</h1>
      {articles}
    </main>
    <footer>
      <img src='/images/site_footer.jpg' alt='' style={{
        maxWidth: '100vw'
      }} />
    </footer>
  </>
}

export default TaxonomyPage