import React from 'react'
import marked from "marked";
import parse, {domToReact, HTMLReactParserOptions} from 'html-react-parser'
// @ts-ignore
import { LiteYouTubeEmbed } from 'react-lite-youtube-embed'
import {Link} from "react-router-dom";

interface MarkdownProps {
  text: string,
  full?: boolean
}

const Markdown: React.FunctionComponent<MarkdownProps> = ({ text, full }) => {
  let html = text

  if (!full) {
    html = text.replace(/(!\[[^\]]*]\(\/images\/[^.]+)(.(jpg|png)\))/g, '$1-th$2')
  }

  html = marked(html)
    .replace(/\^&gt;/g, '<center>')
    .replace(/&lt;\^/g, '</center>')
    .replace(/\[youtube\|([^\]]+)]/g, '<youtube id="$1" />')

  const parserOptions: HTMLReactParserOptions = {
    replace: ({ type, name, attribs, children }) => {
      if (type === 'tag' && name === 'youtube') {
        return <LiteYouTubeEmbed {...attribs}  />
      }
      if (type === 'tag' && name === 'a' && attribs && children) {
        const { href: to, ...otherAttribs } = attribs
        if (attribs.href.startsWith('/')) {
          return <Link to={to} {...otherAttribs}>{domToReact(children, parserOptions)}</Link>
        } else if (!attribs.href.startsWith('#')) {
          return <a href={to} target='_blank' rel='noreferrer noopener' {...otherAttribs}>{domToReact(children, parserOptions)}</a>
        }
      }
      if (type === 'tag' && name === 'h3') {
        const filteredChildren = children?.filter(child => child.type === 'text')
        // @ts-ignore
        attribs.id = (new marked.Slugger()).slug(filteredChildren[0].data.trim())
      }
    }
  }

  const components = parse(html, parserOptions)

  return <div style={{
    display: 'flex',
    flexDirection: 'column'
  }}>
    {components}
  </div>
}

export default Markdown
