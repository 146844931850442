import React from 'react'
import { IBookPage } from './index'

const chapter14: IBookPage = {
  header: 'header_d6.jpg',
  title: 'Глава 14. Двоеточие и скобочка',
  prevLink: 'chapter13',
  nextLink: 'chapter15',
  content: <>
    <h2>Университет. Дима</h2>

    <img alt='' src="/images/14_dima.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }}/>
    <p>На часах 17:07</p>

    <p>&nbsp;</p>

    <p>— Я надеюсь, ты догадываешься,
      что свести Максима с ума, вывести из себя, заставить бросить всё и уехать в
      дальние страны не было моим коварным планом.</p>

    <p>— Я не подозреваю тебя! Я
      подозреваю девочку с диктофоном. То есть как раз без диктофона.</p>

    <p>— И без девочки, судя по всему.</p>

    <p>— Как так вышло, что она
      оказалась ещё и девочкой-без-таксиста, без охраны хоть какой-нибудь?</p>

    <p>— Ну вот так так
      вышло. Провал коммуникации.</p>

    <p>— Ну Дмитрий, ну леший еби вашего
      батюшку!</p>

    <p>— Что я могу поделать, не ладятся
      у меня диалоги с Максимом — а позавчера было ещё не очевидно, что отсутствие
      диалога чревато.</p>

    <p>— Хм. С тобой-то, положим, ещё
      хуй, с Ройшем — сушёный хуй, но чтобы <i>гэбня</i> так облажалась…</p>

    <p>— И тем не менее, о чуме узнала
      не гэбня, Гошку провоцировала не гэбня и лекарство делала не гэбня. В этом,
      Гуанако, и заключается драма нашего времени.</p>

    <p>&nbsp;</p>

    <p>На часах было пять дня, и
      дипломатическая миссия имени Смирнова-Задунайского-и-Гуанако, кажется, только
      что с треском провалилась ввиду отсутствия объекта дипломатии.</p>

    <p>Сколько-то там времени назад
      (около часа, наверное) Гуанако прокрался в лазарет и попытался вывести Диму под
      ручку на променад. Потом пробрался ещё раз и попытался вывести за ручку. Потом
      ещё — и вытащил уже за шкирку.</p>

    <p>Столь решительные меры заклинание
      «щас-щас-щас, пять минут ещё» побороть не сумело.</p>

    <p>Диму самого поражало
      прорезавшееся у него трудолюбие (хотя не сильнее, чем прорезавшаяся параллельно
      трудоспособность): казалось бы, раньше за столь порочными занятиями, как
      общественно полезная работа, он замечен не был. Впрочем, не то чтобы ему
      нравилось скакать со шприцом наперевес между койками или торчать часами у
      титровального аппарата (посмотрел бы он на того, кому подобное может нравиться,
      — хотя нет, не стал бы смотреть, его даже Попельдопель в этом смысле пугал).</p>

    <p>Это всё от дурацкого, отрядского
      какого-то страха, что где-то что-то произойдёт без него, а он узнает только из
      третьих рук по пересказам.</p>

    <p>Например, индикатор из
      бледно-бледно-малинового станет просто бледно-малиновым!</p>

    <p>А Дима не засвидетельствует столь
      значимое событие лично!</p>

    <p>Если бы он был Охровичем,
      Краснокаменным или обоими сразу, у него было бы чутьё, подсказывающее, за каким
      углом может произойти ближайшее перекрашивание индикатора. Ввиду же отсутствия
      чутья приходилось просто не спать и старательно присутствовать во всех местах
      одновременно. И, поскольку лучшего благовидного предлога придумать не вышло, работать.</p>

    <p>По поводу чего Гуанако прозудел
      Диме всю шапку. Психическое, мол, перенапряжение. Мозги, мол, не отдыхают.</p>

    <p>Было бы чему отдыхать.</p>

    <p>Зато с благовидными предлогами у
      Гуанако всё складывалось куда лучше, поэтому как только со стороны Лария
      повеяло возможностью свалить на пару часов из Университета, он немедленно ею
      воспользовался. Соль заключалась в следующем: Максим средь бела дня покинул
      рабочее место, был найден телефонными путями в квартире Габриэля Евгеньевича,
      где до того двое суток не появлялся, куртуазную беседу отверг, бросив трубку и
      послав всех к лешему — и, ага, на этом этапе Дима резонно заподозрил, что
      является далеко не лучшей кандидатурой для дипломатической миссии. Если,
      конечно, их задача состоит в том, чтобы успокоить и (по возможности целым)
      вернуть Максима, а не превратить его окончательно в мрачного трагического
      героя, доведя до смертоубийства.</p>

    <p>(Что сейчас было уже неважно,
      поскольку никакого Максима в квартире Габриэля Евгеньевича не обнаружилось.)</p>

    <p>И что не отменяет нахальства
      Гуанако, который, глазом не моргнув, заявил, что насыщенная биография и былой
      статус политического заключённого не позволяют ему обратиться в гэбню
      Международных Отношений, так что помощника для дипломатической миссии
      приходится собирать из подручных материалов. И утащить за шкирку от
      титровального аппарата, в котором как раз намечалось что-то интересненькое.</p>

    <p>Гуанако оказался прав, как
      обычно: обнаружить за пределами медфака внешний мир было сомнительной
      приятности (погодка-то нынче, леший ей в котомку, а), но всё-таки
      неожиданностью. И сменой обстановки.</p>

    <p>После семи лет в степи любая
      смена чего угодно на что угодно кажется спасением утопающего.</p>

    <p>(Если бы у Димы было чуть более
      приличное воспитание — то есть, ну, <i>хоть
        какое-нибудь</i> —&nbsp;его бы ещё в младшеотрядском
      возрасте успели предостеречь относительно смешанных метафор, а теперь смотрите,
      что получилось.)</p>

    <p>Ещё после семи лет в степи можно
      отлично делать морду кирпичом и клеить из себя врача — просто потому, что
      умеешь отличать одну травку от другой. Это, вообще говоря, какой-то массовый
      внутриполитический провал Всероссийского Соседства: если бы оное Соседство чуть
      внимательнее относилось к собственным малым народам, человек, поживший со
      степняками и познавший их страшные и загадочные пути, не вызывал бы такой бури
      оваций. Даже Медицинская гэбня впечатлилась, хотя,
      казалось бы, — ну они-то чего могут про твирь с савьюром не знать.</p>

    <p>Все думают, что Дима
      переквалифицировался в медика, а выходит, что в дипломата.</p>

    <p>Когда приключится следующий
      кромешный пиздец, надо придумать себе ещё какую-нибудь фамилию и податься в
      пресловутую гэбню Международных Отношений. А что, в прошлый-то раз прокатило.</p>

    <p>(Не в том смысле, что Дима уже
      пытался податься в гэбню Международных Отношений, а в том, что проворачивал
      план «прийти к гэбне с высоким уровнем доступа и нулевым уровнем
      заинтересованности в его, Димы, благосостоянии и предложить себя покрывать —
      просто так, за красивые глазки и идеальную щетину; кстати, уважаемый Виктор
      Дарьевич, я вижу, что вы и сами цените суровую мужскую красоту, хотите
      средство, которое позволяет щетине не расти дальше, мне всего-то и нужно —
      трудоустройство у вас и поменьше внимания, ну, от всех, от кого может быть
      внимание». Прокатило!)</p>

    <p>Когда приключится следующий
      кромешный пиздец, надо (дать бы себе слово прямо сейчас) оказаться где-нибудь
      подальше от него, в тихих, спокойных местах — в Индокитае, например,
      медитировать на макушке шерстистого бегемота и не звать лешего на свою голову.</p>

    <p>На чужие тоже не звать.</p>

    <p>Дима молодец, самородок, почти
      гений, и кто ему только этого не сказал. Влёт придумать, как можно сделать
      много-много лекарства от чумы, как можно <i>тайно</i> сделать много-много лекарства от чумы (хотя операция «Хуй вам Вилонский, а не
      эпидемия смертельной болезни в Бедрограде» по праву принадлежит Гуанако), как
      можно <i>тайно</i> сделать много-много
      лекарства от чумы <i>за несколько дней </i>—
      не каждый сумеет. Молодец, самородок, давайте просто дружно не думать, кто эту
      самую чуму в Бедроград привёз.</p>

    <p>Бедроградская гэбня, во всём
      виновата Бедроградская гэбня.</p>

    <p>Дима молодец, почти гений и все
      дела, конечно, но вот сейчас он довольно громко врезался в косяк, и это наводит
      на подозрения о том, что думать двадцать мыслей одновременно — не лучшая
      тактика в нелёгких полевых условиях.</p>

    <p>— Поправь мои глаза, если они
      бесстыже лгут, но богатый и плодотворный опыт поисково-спасательных операций
      указывает мне на то, что эта квартира примечательна отсутствием Максима. И
      Габриэля Евгеньевича, кстати, тоже.</p>

    <p>Гуанако обернулся.</p>

    <p>— Если тебя интересует моё мнение
      как частного лица — я всё ещё твёрдо стою на том, что эта квартира
      примечательна <i>в первую очередь</i> наличием чудовищных размеров кровати под красным балдахином, —&nbsp;пробормотал
      он с неприкрытой мечтательностью.</p>

    <p>Гуанако, который — и в этом
      заключается <i>настоящая</i> драматическая
      ирония — толком почти и не бывал дома у Габриэля Евгеньевича.</p>

    <p>Ну, кроме того одного раза,
      который лично у Димы входит в обширный список событий, о которых он особо не
      вспоминает, потому что чего уж теперь. Тот самый список, благодаря которому его
      биографию можно пересказать примерно как «родился в 1854 году, потом, эмммм, была,
      в общем, одна пьянка на первом курсе, а больше-то
      и рассказать толком нечего, спасибо».</p>

    <p>— Кровати под красным балдахином,
      стоящей прямо напротив входной двери — так, что любой, кто заходит в квартиру,
      первым делом думает о самой непристойной вещи, на которую способен, — буркнул
      Дима. — Большое спасибо за столь детальное описание того, что я и так вижу.</p>

    <p>— Доктор, не будьте злыднем.</p>

    <p>— Гуанако, если Максима тут нет,
      моя дипломатическая карьера окончена — плакал теоретически возможный восьмой
      уровень доступа. А ты даже не потрудился поиметь какой-нибудь внятный род
      занятий, чтобы я тоже мог тебя иронически эдак обозвать.</p>

    <p>— Но-но. Я курьер. Мальчик на
      побегушках сорока с лишним лет. Точка.</p>

    <p>— Мальчик на побегушках сорока с
      лишним лет, вы не заметили, что, помимо отсутствия Максима и большой-большой
      кровати, эта квартира примечательна также картинами массовых разрушений?</p>

    <p>И она, леший дери, была ими
      примечательна. В отличие от Гуанако, Дима ещё не успел обежать все возможные
      комнаты — двадцать мыслей в голове не лучшим образом сказываются на моторных
      навыках,&nbsp;— но одного коридора и панорамы той самой кровати с прилагающейся
      к ней спальней хватало. В коридоре, положим, всё было ещё достаточно прилично —
      разве что несколько лысовато, вроде как чего-то не хватает.</p>

    <p>Кто знает все шмотки Габриэля
      Евгеньевича наперечёт? Дима знает все шмотки Габриэля Евгеньевича наперечёт!</p>

    <p>Почему Дима знает все шмотки
      Габриэля Евгеньевича наперечёт?</p>

    <p>Список-событий-о-которых-мы-особо-не-вспоминаем,
      пункт главный: в один светлый осенний день кучу лет назад Гуанако уехал в
      экспедицию и не вернулся. Не вспоминаем, потому что слишком стыдно за своё
      тогдашнее поведение.</p>

    <p>Отдельным подпунктом — зато уже
      вместе со всем истфаком — не вспоминаем, что в итоге это мистическим образом
      обернулось проживанием в квартире Габриэля Евгеньевича и детальным изучением не
      только гардеробных подробностей его жизни. Не вспоминаем, потому что сношаться
      втроём (Дима, Габриэль Евгеньевич и призрак некоего условно покойного
      профессора БГУ) — забава для слишком рафинированных эстетов, грубому
      неотёсанному народу с истфака не понять.</p>

    <p>Хочется, конечно, верить, что за
      всю эту страшную уйму лет набор шмоток Габриэля Евгеньевича претерпел хоть
      какие-то изменения, но красивый и потому чуть было не прихваченный в порыве
      злорадства плащ с застёжками под шинель Дима ещё в мае-месяце тут видел,
      точно-точно.</p>

    <p>— Многоопытно и компетентно
      подозреваю, что Габриэль Евгеньевич куда-то упёрся, — изрёк Дима, проходя
      вослед за Гуанако в спальню, —&nbsp;опираясь при этом не только на его
      отсутствие, но и на отсутствие его одежды.</p>

    <p>Тот изобразил некоторое сомнение,
      но Дима-то знал, что за сомнением скрывается пиетет к его, Димы, дедуктивным
      способностям и логическому мышлению.</p>

    <p>— Опираясь на показания
      очевидцев, — потряс Гуанако какой-то бумажкой, — можно заподозрить, что
      Габриэля Евгеньевича тут не было аж в 11:45.</p>

    <p>Ничего нового, такую бумажку Дима
      уже видел на двери квартиры. Записка от электриков: извините, у вас перегорел
      предохранитель в стиральном аппарате, мы заходили в 11:45 утра, аппарат унесли,
      всю квартиру загадили, поэтому унесли ещё и ковёр. Всё вернём. Когда-нибудь. Ах
      да, в квартире никого не было, поэтому пришлось оставить тут вот эту записку. В
      двойном экземпляре. Точное время, печать службы объединённых ремонтных услуг.</p>

    <p>— Как будто в 11:45 он не мог
      куда-то упереться, — один раз вкусив логического мышления, Дима уже не мог
      остановиться, — в одежде!</p>

    <p>— Без одежды мы нашли бы его
      быстрее, — рассеянно заметил Гуанако, — то есть нас, дипломатов, пока никто не
      просил никого искать, но это «пока» закончится, как только мы отзвонимся Ларию.
      Если, конечно, Максим и
      Габриэль-Евгеньевич-в-одежде не отсвечивают уже снова на факультете. Такое ведь
      тоже может быть, — без особой надежды прибавил он, осматривая большую кровать
      под красным балдахином.</p>

    <p>А вернее, то, что было разбросано
      по ней и вокруг неё.</p>

    <p>…Во-о-озвращаясь
      к списку того, о чём Дима особо не вспоминает: да, он в курсе, что Габриэль
      Евгеньевич когда-то писал отвратительно лиричные письма мёртвому Гуанако, что
      он до сих пор хранит ключи от его квартиры и что сам Дима способен опознать
      оные ключи даже весьма беглым взглядом. И что обо всём этом гораздо приятнее не
      думать, чем думать, а не думать, когда оные письма и ключи смотрят тебе прямо в
      глаза, трудновато.</p>

    <p>Но зачем же по всей кровати-то.</p>

    <p>Продуктивнее созерцать
      разметавшиеся по полу рубашки, брюки, шейные платки и тому подобные шедевры
      текстильного искусства.</p>

    <p>— Это ещё если я выкрою в своём
      невероятно плотном графике время на поиски всяких там заблудших голов гэбен и
      сочувствующих, — пнул Дима близлежащую брючину. — Не могу также не отметить
      феноменально удачный выбор момента для исчезновения — с учётом того, что
      Бедроградская гэбня ответила на запрос, встреча назначена на середину
      завтрашнего дня. Кстати, забыл тебе сказать, Бедроградская гэбня ответила на
      запрос, встреча назначена на середину завтрашнего дня. — Он задумчиво повертел
      в руках один из неоткрытых конвертов и подавляющим большинством голосов
      постановил, что лучше ему и оставаться неоткрытым. — Право слово, в интересах
      Максима вернуться через десять минут с пивом в руке, Габриэлем Евгеньевичем под
      мышкой и недоумением на лице.</p>

    <p>— И такое тоже может быть, —
      Гуанако тщетно клеил из себя следопыта, изучая какой-то след на полу. — И
      вообще всё что угодно может быть, блядь.</p>

    <p>Вековая мудрость глаголала его
      устами, а то как же.</p>

    <p>Осмысляя вековую мудрость, Дима
      убрёл в другой конец спальни, где и обнаружил рассыпавшиеся (по полу, по полу,
      все попадающиеся на глаза объекты находились на полу, особенно кровать)
      разноцветные сигареты — раритетный китч, сделаны лучшими подпольными мастерами
      для любителей оскопистских салонов.</p>

    <p>Тесно связаны с эротическими
      предпочтениями Габриэля Евгеньевича, но об этом Дима не просто не будет
      вспоминать, об этом Дима не будет вспоминать агрессивно и без дополнительной
      аргументации.</p>

    <p>Потому что ну честное слово.</p>

    <p>Возможно, ему и самому нравилось
      сидеть на диване в полусползшей рубашке, пить красное
      вино из бокалов на длинной ножке, курить эти самые цветные сигареты и потом
      иметь вот такие же вот изысканные сношения (то вдвоём, то втроём с неназванными
      призраками), но исключительно потому, что <i>это
        же Габриэль-батюшку-его-Евгеньевич</i>.</p>

    <p>Нельзя не отметить, что если
      каждый попадающийся (или даже не попадающийся, а лишь потенциально возможный —
      как плащ-шинель) на глаза объект будет вызывать такое количество воспоминаний
      (то есть, разумеется, их отсутствий), то никаких поисков не случится вовсе не
      по причине напряжённого рабочего графика.</p>

    <p>О деле надо думать, о деле.</p>

    <p>В поисках поддержки Дима
      обернулся к Гуанако, который как раз обнаружил письма к мёртвому себе и изучал
      одно из них с выражением всей мировой боли на лице. Сломался он довольно
      быстро, бросив мелко исписанный лист на хуй (то есть на ключи от своей же
      квартиры, за что ему большое человеческое спасибо).</p>

    <p>— Габриэль Евгеньевич с интересом
      обнаружил, что жизнь его не удалась, впал в ностальгическую печаль и уехал в
      неизвестном направлении, — воззвал к логике Дима. — Максим, отметив острую
      нехватку Габриэля Евгеньевича, уехал в другом неизвестном направлении на
      поиски. Где подвох?</p>

    <p>Не считая того, что этот вариант
      ничем не лучше, например, такого: стиральный аппарат поломался с громким
      хлопком, Габриэль Евгеньевич умер от ужаса, а Максим поехал его хоронить.</p>

    <p>Или такого: Габриэль Евгеньевич
      окончательно сошёл с ума и раскачивался на люстре (в одежде!), когда пришли
      электрики; психика электриков, не выдержав этого зрелища, вытеснила
      воспоминание, так что они решили, что дома никого не было; когда Максим,
      приехав, говорил с Ларием, Габриэль Евгеньевич подкрался к нему со спины, огрел
      печатной машинкой по голове и сейчас уже доедает его голень на корабле,
      плывущем в Объединённую Латинскую Америку.</p>

    <p>Или такого: никуда Максим с
      Габриэлем Евгеньевичем не делись, лежат вдвоём вот под этой вот большой
      кроватью и хихикают над тем, как ловко они всех перехитрили.</p>

    <p>И записку от электриков сами же
      написали, для пущей драмы.</p>

    <p>В двойном экземпляре.</p>

    <p>Дима старательно подавил желание
      заглянуть под кровать.</p>

    <p>— Габриэль Евгеньевич мог выйти
      за хлебом и вернуться уже после выноса стиральных аппаратов и ковров, — резонно
      и не очень-то весело ответил Гуанако. — Мог пересечься здесь с Максимом, мог
      поругаться с Максимом или, наоборот, помириться с Максимом, мог поставить
      Максиму ультиматум — либо благо города, либо я. Может, они просто где-то не
      здесь решают свои проблемы. И это было бы лучше для всех, потому что найти двух
      людей вместе проще, чем по отдельности.</p>

    <p>…Или такого: никаких Максима с
      Габриэлем Евгеньевичем никогда и не существовало, это всё — порождение
      массового сознания, которому не хватает мелодрамы в жизни. А сейчас, когда
      случился-таки кромешный пиздец, массовое сознание сосредоточилось на оном и
      перестало поддерживать иллюзию существования завкафа и замзавкафа
      истории науки и техники.</p>

    <p>Звучит убедительно.</p>

    <p>Да, вполне.</p>

    <p>— А ещё Габриэля Евгеньевича
      могли утащить в своё логово электрики, — заметил Дима, направляясь в сторону
      гостиной. — Это ж только сантехники подотчётны Университету — спасибо
      канализациям — а электрики вполне бедроградско-гэбенные.</p>

    <p>— Большой политике срать на
      Габриэля Евгеньевича. Вот если бы они утащили куда-то Максима, было бы по
      крайней мере понятно, чего это Бедроградская гэбня так быстро среагировала на
      запрос о встрече. Чтоб Университет смог не явиться и сесть в такую лужу, в
      которой впору топиться.</p>

    <p>Так-то оно так, но есть одно но.</p>

    <p>— Видимо, самое время обратить
      твоё внимание на то, что Максима тут по-прежнему нет. И, если только наши с
      тобой блистательные умы не догадаются, где его искать, нет никаких гарантий,
      что в урочный завтрашний час он появится, а лужа, соответственно, минует.</p>

    <p>Кто пессимист, Дима пессимист?</p>

    <p>Он бы назвал это оголтелым
      реализмом.</p>

    <p>Оголтелый реализм, например,
      никогда не разделял всеобщих восторгов в адрес Максима (оголтелый реализм
      вообще отличается чернущей неблагодарностью, по
      поводу чего сейчас грызться попросту нет сил, Дима внесёт страдания совести в завтрашний
      рабочий график). У Максима могут быть недостатки, но одного точно не отнять —
      он серьёзный, он надёжный, он не подведёт; ага-ага, отлично не подводит.</p>

    <p>И вообще, когда про кого-то много
      говорят и постоянно упоминают какую-нибудь одну его черту, на практике обычно
      получается, что именно этой-то черты у него и нет. Слабый здоровьем Габриэль
      Евгеньевич ещё всех их переживёт и уйдёт с сотрясением мозга на своих ногах.
      Надёжный Максим слился незнамо куда накануне встречи гэбен. Развратный Гуанако
      по собственному признанию перед очередной смертью только и говорил что о
      сложных отношениях и неоднозначных связях со своими не столь и многочисленными
      мужчинами. Приятный Ларий Валерьевич, думающий исключительно о входящих,
      исходящих и температуре кафедрального чайника, думает ой не только о
      температуре кафедрального чайника. Невыносимые Охрович и Краснокаменный, интересующиеся
      только тем, в кого нарядить кафедральное чучело, сегодня уехали на поиски
      следов Брови в Хащину (и вообще почему-то до сих пор не сдали Университет
      Бедроградской гэбне — а ведь так вышло бы гораздо забавнее).</p>

    <p>Никто не ставит капельницы
      аккуратнее суматошного Попельдопеля и никто не переживает происходящее глубже
      чёрствого Ройша.</p>

    <p>А (во имя сохранности психики
      оставленный без эпитета) Дима, как показывает практика, порождает крайне, крайне
      глубокие мысли.</p>

    <p><i>Крайне</i> глубокие.</p>

    <p>В гостиной было так же, как и
      везде: умеренно-бардачно. Грязные пятна (местами ещё лужицы) по всему полу,
      опасно накренившаяся стопка каких-то журналов (Падение Хуя само приходит на
      ум!) в центре — всё остальное, кажется, на местах. Судя по тому, что
      бесконечный хрусталь, фарфор и прочая керамика стояли себе на полочках,
      масштабного насилия здесь всё-таки не происходило.</p>

    <p>Или оно происходило тихо и
      локально.</p>

    <p>Ковёр, например, могли запятнать кр-р-ровью.</p>

    <p>Коварные электрики!</p>

    <p>— Вот это давай-ка перепрячем, —
      метнулся Гуанако к копиям запросов к фалангам, лежащим промеж двух (как это по-домашнему интимно) печатных
      машинок, — или даже заберём с собой.</p>

    <p>Дима критически осмотрел люстру и
      не обнаружил на ней следов свисания Габриэля Евгеньевича, что несколько
      уменьшало вероятность того, что, когда пришли электрики, он на ней качался.</p>

    <p>Впрочем, Габриэль Евгеньевич не
      очень тяжёлый.</p>

    <p>Если бы личный опыт не говорил
      обратного, Дима, как и многие, даже тоже верил бы, что и не очень телесный.</p>

    <p>— Габриэль Евгеньевич прятал в
      ворохе древнего снобского чтива документы,
      подтверждающие его британское гражданство? А теперь решил-таки ими
      воспользоваться, и больше мы его никогда не увидим? —&nbsp;свершил Гуанако
      Падение Хуя путём приложения ножной физической силы к неустойчивой стопке
      журналов —&nbsp;и тем самым обломав возможность свершить оное Диме.</p>

    <p>Хоть складывай обратно и рушь ещё
      раз собственноножно, стопка-то была что только не по
      пояс.</p>

    <p>— Насколько мне известно, нет, —
      отверг ещё один весьма вероятный вариант развития событий Дима, —&nbsp;потому
      что, во-первых, нет у него никакого британского гражданства, а во-вторых, все
      документы он хранит в верхнем ящике, ключ от которого он хранит в третьем
      ящике. И да, я тоже полагаю, что это не очень конспиративно. Мы с Габриэлем
      Евгеньевичем не только цветом волос похожи, знаешь ли.</p>

    <p>Но и тем, например, что они —
      оба-два —&nbsp;по всем окрестным любителям (главный из которых сейчас
      пристально рассматривал прочие лежащие на столе бумажки и содержимое ящиков, в
      верхнем из которых обнаружил-таки вполне нетронутые документы Габриэля
      Евгеньевича) славятся как местечковые кассахи, хотя ни один из них собственно кассахом и не является.</p>

    <p>С Габриэлем Евгеньевичем всё
      относительно понятно: Габриэль Евгеньевич — сын женщины, и мать его — британка,
      так что генетически он полубританец-полукассах,
      откуда и сочетание чёрных-чёрных волос с голубыми глазами. Юридически,
      разумеется, во Всероссийском Соседстве кассахов нет, и вообще он Гаврила Онега,
      простой росский парень с Пинеги, какие могут быть сомнения.</p>

    <p>Дима —&nbsp;человек более
      весёленький, поэтому и ситуация у него более весёленькая.</p>

    <p>Плато Кассэх, как известно,
      является частью Объединённой Британии-Кассахии, территориально при этом
      располагаясь в средней полосе Всероссийского Соседства. Анклав, короче. И не
      просто анклав, а особенный — отличающийся, например, удивительно стабильным
      приростом населения. Безо всяких алхимических печей — у них там, на плато,
      полный разврат и естественное деторождение.</p>

    <p>Вот и выходит, что население
      патологически некуда девать, ибо анклав ещё и очень-очень маленький.</p>

    <p>В принципе, новорожденных
      кассахов вполне можно было бы увозить на Британский остров —&nbsp;с частью так
      и делают, только британцев почему-то не очень увлекает идея тащить к себе
      безродных младенцев через всё Всероссийское Соседство, которое с довольным
      видом дерёт за эти забавы налоги в тройном размере.</p>

    <p>(Вообще говоря, им стоило бы
      попробовать контрабандные методы — ну там по ящикам детей распихивать, под
      вагонными койками провозить, дети же, в отличие от травы, даже не пахнут особо.
      По крайней мере, первые несколько дней.)</p>

    <p>С другой стороны, не всегда же Плато
      Кассэх было анклавом, до начала тридцатых — просто ещё одной необычной частью
      Всероссийского Соседства. И у необычной части после отделения сохранились с
      Соседством необычные дипломатические отношения, эдакое тайное джентльменское
      соглашение, про которое все делают вид, что его нет: плато спускает в Соседство
      своих младенцев, Соседство взамен их принимает и как-то устраивает им жизнь, ну
      и в целом ведёт себя по-человечески.</p>

    <p>Генетика —&nbsp;область сложная и
      малоизученная, с законами фенотипического наследования поди ещё разберись. Ну
      был дедушка блондин, был батюшка блондин, а сынок уродился брюнет —&nbsp;мало
      ли. Алхимические печи, производящие население Всероссийского Соседства,
      работают по принципам, оному населению (по крайней мере, лично Диме, что лишний
      раз намекает на то, что кто-то тут ни в одном глазу не врач) неизвестным, это
      артефакт иных цивилизаций, честно спизженный когда-то Революционным Комитетом
      всё из той же Британии.</p>

    <p>Ввиду чего так чрезвычайно просто
      в случае необходимости не выпекать очередному желающему сына, а взять у
      будущего счастливого отца необходимые образцы генетического материала,
      подождать полагающиеся полгодика и потом предложить ему малолетнего кассаха. Ну
      да, ему будет сколько-то там месяцев, но кто в этом государстве вообще может
      знать, как выглядит совсем новорождённый младенец? (Правильный ответ: Дима, и
      да будут прокляты все лешие вселенной).</p>

    <p>Разумеется, у любого нормального
      человека (особенно поклонника логического мышления) это не может не вызвать
      вопроса, связаны ли все остальные дети Всероссийского Соседства со своими
      отцами хоть как-нибудь, или наёбывают совсем на каждом углу.</p>

    <p>Поэтому кассахов во Всероссийском
      Соседстве нет — а следовательно, нет и вопросов.</p>

    <p>Зато у Медицинской гэбни самый
      высокий уровень доступа из всех гэбен страны.</p>

    <p>Бюро Патентов не в счёт.</p>

    <p>Вся эта лирическая предыстория
      была вот к чему: во Всероссийском Соседстве кассахов нет, но на самом деле они
      есть.</p>

    <p>И некоторые из них могут об этом
      совершенно случайно догадаться. Как и поступил Димин батюшка, когда ему было
      —&nbsp;ебись налево — пятнадцать лет.</p>

    <p>(Наследственные ум и
      сообразительность не пропьёшь, сам Дима вон в пятнадцать лет сбежал из отряда
      за два месяца до выпуска.)</p>

    <p>Догадался, кстати, из-за
      стереотипного такого сбоя системы: юридический батюшка Диминого батюшки, некто
      Смирнов, неудачно помер, когда того только-только выдали на руки; Димин батюшка
      случайно по одним бумагам получил фамилию Смирнова, а по другим — вымышленную,
      сирот же тоже как-то называют. В третьих бумагах вообще одно с другим
      объединили, так что вышел Кирилл Смирнов-Задунайский.</p>

    <p>По окончании отряда Кирилл
      Смирнов-Задунайский задумался о своём будущем, пошёл по инстанциям (то есть к Пинежской Ыздной гэбне —&nbsp;все
      дороги этого мира ведут на Пинегу, по крайней мере, кассахов точно ведут),
      обнаружил, что отец у него то есть, то нет, и возмутился (потому что вместе с
      отцом то отсутствовали, то присутствовали наследные коровы). Достаточно успешно
      возмутился, чтобы инстанции прищучились и покорно
      устроили Кириллу Смирнову-Задунайскому хорошую жизнь и страстно желанную
      управляющую должность на каком-то заводе в пятнадцатилетнем возрасте, приписав
      ему в документах лет пять.</p>

    <p>Только сквозь слёзы и плач
      (посылать пятнадцатилетнее дитя без намёков на опыт работы и столицы в голове
      руководить сборкой карданных валов!) попросили хоть как-нибудь изобразить
      солидность и двадцатилетний возраст.</p>

    <p>Завести себе ребёнка, например.</p>

    <p>По закону можно только с
      семнадцати, так что вот все и поверят, что Кирилл Смирнов-Задунайский уже
      совсем взрослый дядька, а борода у него просто так не растёт, болел в детстве.</p>

    <p>Так, собственно, и появился Дима.
      И ничего ему не «не повезло», он даже видел батюшку целых раза полтора — пока
      тот не сообразил, что для солидности необходимо наличие сына, а не наличие с
      сыном каких-то там взаимоотношений.</p>

    <p>И не погиб в итоге на
      производстве (очевидцы клянутся — и Дима склонен им верить — что действительно
      совершенно случайно).</p>

    <p>Всё это не имеет никакого
      отношения к истаиванию в неизвестном направлении
      Максима с Габриэлем Евгеньевичем (ага, они всё ещё отсутствовали в квартире),
      но поимело кучу интересных последствий для Димы лично.</p>

    <p>Например: юридически он не просто
      не кассах, он даже не сын кассаха, он сын уважаемого специалиста с Пинеги,
      молодого и блестящего, трагически погибшего, когда оному сыну было пять лет.</p>

    <p>Фактически же он как раз именно
      что полнокровный кассах (поскольку генетический материал в нём исключительно от
      батюшки, а у батюшки —&nbsp;с плато), и все те, кому может быть важно, чтобы во
      Всероссийском Соседстве кассахов не было и не появлялось, об этом, разумеется,
      знают.</p>

    <p>Например: в шестнадцать лет он
      жил себе в Столице (сбежал из отряда, но зато быстро приобрёл ценный навык
      паразитировать на чужих финансах), никого не трогал, только забросал как-то раз
      дерьмом балкон одного уважаемого человека (от большого уважения и юной
      пламенности, каждый имеет право на свои способы проявления светлых чувств).
      Уважаемому человеку почему-то не понравилось, и тут внезапно всплыло кассашество,
      весьма зримая угроза судимости, категорически
      осязаемая невозможность получить собственную квартиру по разнарядке (поскольку
      для этого нужно иметь документы, а документы выдают по окончании отряда, а
      окончание отряда кое-кто успешно закосил) и, соответственно, достаточно мутные
      дальнейшие перспективы.</p>

    <p>Так и свалил из Столицы — с
      чемоданом в одной руке и паникой в другой.</p>

    <p>(Ну то есть паника была в голове,
      но второй рукой он как раз за голову и держался.)</p>

    <p>Впрочем, как и с аллергией на
      твирь, оказавшейся почти иммунитетом к чуме, всё сложилось скорее хорошо, чем
      плохо. Если бы от вокзала за Димой эксплицитно не гнались какие-то люди (вплоть
      до прыжков с чемоданом через заборы, ага), он не очутился бы в здании истфака
      БГУ, куда завернул в поисках убежища (ну не стали же бы ему крутить руки в
      уважаемом, судя по колоннам, заведении при всём честном народе!).</p>

    <p>В уважаемом, судя по колоннам,
      заведении нужно было чем-то заняться — вот Дима и подошёл к Ройшу с вежливым
      вопросом, куда он, собственно, попал. Ройша Дима при этом избрал по принципу
      наибольшей прыщавости, задротского вида и того, что
      его все сторонились.</p>

    <p>Люди, которых все сторонятся
      из-за прыщавости и задротского вида, зачастую готовы
      помогать и по гроб жизни быть благодарными просто за то, что с ними заговорили.</p>

    <p>Уже гораздо позже выяснилось, что
      сторонились Ройша немного не из-за этого.</p>

    <p>Короче, не учил Дима Революцию
      —&nbsp;и вот и хорошо, иначе он бы тоже сторонился, и не вышло бы у него ни
      поступления на истфак, ни прописки в квартире Ройша.</p>

    <p>И ещё Гуанако не вышло бы.</p>

    <p>Это красивой кольцевой
      композицией возвращает к кассахской проблеме, кстати:
      если бы не падший жертвой карданного вала неумеренно юный батюшка, Гуанако бы
      тоже не было. Фрайд не Фрайд, а всё-таки при полном отсутствии родителя в жизни
      порочные связи выбирают именно что по принципу «кто старше», а не «кто умнее,
      красивее, шире душой и лучше трахается».</p>

    <p>При полном отсутствии родителя в
      жизни порочные связи также оказываются куда прочнее, чем всем всё-таки
      присутствующим хотелось бы.</p>

    <p>Сплошной вертикальный инцест.</p>

    <p>Вся Димина жизнь — один сплошной
      вертикальный инцест.</p>

    <p>Она его породила, она его и ебёт.</p>

    <p>И всё это бесконечное благолепие
      — тоже просто-таки кишит тем, о чём не шибко тянет вспоминать. Вот и выходит
      «родился, бухал как-то раз, а больше рассказывать нечего» — и не картина мира,
      а сплошное решето.</p>

    <p>— Хуйнёй мы маемся,
      —&nbsp;прервал стройный ход мыслей с красивой кольцевой композицией Гуанако, —
      если тут и были какие-то намёки на то, кто и куда подевался, то мы их уже не
      увидим. Это в рамках той версии, что Габриэль Евгеньевич исторгся раньше, а
      Максим пошёл его искать по свежему следу. Если записка от электриков правдива,
      то эта версия становится даже наиболее вероятной, только это всё равно нам
      ничего не даёт. — Он поднялся с четверенек, на которые зачем-то опускался
      (Зачем? И когда? Двадцать-мыслей-одновременно уходят из Диминой головы,
      строятся парами и уходят, невозможно жить с таким количеством мыслей!), и
      усмехнулся, — самое эффективное, что мы можем сделать, — это обратиться к
      Бедроградской гэбне, которая всё равно следит сейчас за главными
      университетскими фигурантами, и спросить у них, куда пошёл Максим.</p>

    <p>А что, отличный план, очень в
      духе последних дней.</p>

    <p>Конечно, по-хорошему можно было
      бы опросить соседей, только в такое время ещё далеко не все вернулись домой.
      Небезызвестный сосед напротив, чьё главное хобби — подбирать и возвращать
      выброшенные Габриэлем Евгеньевичем из окна предметы, например, мог бы знать (он
      внимательно и глубоко добрососедски печётся о здравствии окружающих, а также их личных вещей), но он-то
      точно сейчас на работе. Но даже если кто-то и просидел весь день на любимом
      диване, лучшее, что он может знать, — это кто и во сколько ушёл.</p>

    <p>Максим точно был в этой квартире
      чуть больше часа назад, и сужать временные рамки до минут особо незачем. Они в
      квартире уже столько времени протормозили, что если и
      разминулись с Максимом на пять минут и могли ещё отловить его на остановке, то
      теперь это уже нерелевантно.</p>

    <p>Кажется, поисково-спасательная
      миссия провалилась с не меньшим треском, чем дипломатическая, ввиду отсутствия
      малейших намёков на то, куда Максим с Габриэлем Евгеньевичем могли подеваться.</p>

    <p>Исполненный тоски и печали, Дима зарулил в ванную (хорошо умятого Габриэля
      Евгеньевича
      теоретически можно запихать в стояк с холодной водой, недаром же он так блюдёт
      стройность талии). В самом деле, стирального аппарата нет, есть только набившие
      уже оскомину картины масштабных разрушений, весь нежно-голубой кафель залит
      чем-то чёрным.</p>

    <p>Самое время похвалить себя за
      привычку закатывать рукава рубашки (нет, ещё до степи приобретённую и
      преследующую сугубо эстетическую цель демонстрации волосатости рук окружающим):
      если бы не она, оказалась бы вся рубашка в оном чёрном. Чёрное на белом
      выглядит слишком строго и пропедевтически, это не наш
      выбор.</p>

    <p>Сохранность собственной красоты и
      чувства стиля, разумеется, является главным приоритетом на повестке дня.</p>

    <p>Отвинтив кран, Дима попытался
      хоть как-то соскрести с себя чёрное — лешего с два, что это вообще такое
      приставучее? И, кстати, не хлынет ли сейчас из какой-нибудь незакупоренной
      трубы вода — стиральный аппарат-то отсоединили, но кто ж разберёт, где и как он
      крепился и можно ли пользоваться водопроводом в его отсутствие?</p>

    <p>Беспрецедентная возможность
      залить <i>всю</i> квартиру Габриэля
      Евгеньевича чернильным океаном, не упустите свой шанс. По засохшим пятнам,
      которые въедятся навечно, он потом сможет гадать о своей дальнейшей судьбе.</p>

    <p>Осознав тщетность попыток, Дима
      закрыл воду и поводил пальцем по стене.</p>

    <p>Отлично, теперь в тайне и под
      покровом ночи он сможет работать человеком-чернильной-ручкой, спасать
      неудачливых студентов от нехватки канцтоваров.</p>

    <p>Тоже карьерная перспектива,
      ничуть не хуже гэбни Международных Отношений.</p>

    <p>Чтобы хоть как-то применить
      полученную сверхъестественную силу, Дима нарисовал на зеркале двоеточие и
      скобочку, вот так — :). Это вроде как символизировало улыбку и веру в светлое
      будущее всех университетских фигурантов.</p>

    <p>Из-за улыбки в зеркале виднелся
      некто очень грязный, с во-о-от такими синяками под глазами и торчащими во все
      стороны волосами. Подозрительный тип, Дима определённо не хочет иметь с ним
      ничего общего.</p>

    <p>Этот тип делает иногда очень
      странные вещи и даже не угрызается потом совестью.</p>

    <p>Ну ничего, чёрная метка уже
      проставлена, дальнейшее —&nbsp;дело техники.</p>

    <p>— Мм, — замычал подкравшийся со
      спины Гуанако, скептически созерцая мизансцену. — Я знал, что дело плохо, но
      всё-таки не мог предположить, что, когда ты зайдёшь уже наконец хоть в
      какую-нибудь ванную, грязь польётся с тебя столь устрашающими чёрными ручьями.
      По стенам.</p>

    <p>— Это не я, моя грязь гораздо
      чернее и сжигает всё, к чему прикасается! — возмутился Дима, хватая валяющуюся
      на полу бутылку, к которой вели чёрные следы (и которую следовало, по-хорошему,
      заметить сразу). — Это, гм, «Вороново крыло»? «Закрасит любую седину и сделает
      вас чернее чёрного»?</p>

    <p>Это, гм, то, чего Дима радикально
      не хотел знать, а тем более трогать.</p>

    <p>В общем, можно было бы и
      догадаться, что одной прядью поперёк чёлки не седеют, так что, если хочешь
      продолжать тревожить умы подотчётных тебе студентов, необходимо всю остальную
      седину закрашивать, но вот чтобы это открытие свалилось прямо так на голову!</p>

    <p>На руку, если быть точным.</p>

    <p>Гуанако, разумеется, заржал.</p>

    <p>— Это оно так воняет?</p>

    <p>— Воняет?</p>

    <p>Ничего особо не воняло.</p>

    <p>Если у Димы и было что-то
      достойное, так это нос.</p>

    <p>Он верил своему обонянию.</p>

    <p>Чему-то же надо верить.</p>

    <p>— А вроде и не химией… и не
      воняет, а так, —&nbsp;задумчиво пробормотал Гуанако. — Странно, короче. Как
      будто тут лимон жрали.</p>

    <p>А.</p>

    <p>О.</p>

    <p>— Что, правда? — Дима одним
      щедрым движением выкрутил кран, окончательно очернив ручку, сунул нос почти в
      струю.</p>

    <p>Ничего не почувствовал,
      разумеется.</p>

    <p>И Гуанако скоро перестанет
      чувствовать.</p>

    <p>— Химией-химией, — пришлось
      неохотно признаться Диме, — и моим изобретательским гением.</p>

    <p>— Скажи, что это промышленная
      версия твоей блядской хуёвины для нерастущей щетины,
      — страшным голосом произнёс Гуанако. — Потому что всё прочее, что приходит мне
      в голову, совершенно чудовищно.</p>

    <p>— Блядская хуёвина, между прочим,
      распространяется не только на щетину, но и на волосы на ногах, руках и голове
      для тех, кто по каким-то непостижимым причинам возжелал всю жизнь ходить лысым.
      И нет, это не она.</p>

    <p>Садитесь, Смирнов-Задунайский,
      пятёрка по дисциплине «нести какую-то форменную околесицу в тот самый
      единственный момент, когда, возможно, стоило бы отвечать побыстрее».</p>

    <p>— Это всего лишь промышленная
      версия дезинфицирующего раствора от чумы.</p>

    <p>—&nbsp;С запахом лимона?
      —&nbsp;рассеянно изумился Гуанако.</p>

    <p>Лицо у него при этом было вовсе
      не как у человека, думающего о лимонах.</p>

    <p>Лицо у него, откровенно говоря,
      было как у человека, думающего простую, как коленка, мысль: незачем пускать по
      трубам некоего дома дезинфицирующий раствор от чумы, если в этом доме ранее не
      было чумы.</p>

    <p>— Это не художественное решение,
      это так вышло, — пояснил Дима. — Идея дезинфицирующего раствора пришла в головы
      Бедроградской гэбни довольно поздно —&nbsp;и нет, никто почему-то не задумался,
      что жирноваты для заражения одного дома такие разработки. Я решил, что, ну,
      любят же они красоту и порядок, хотят полностью зачистить дом Ройша после всех
      операций. Так вот: идея пришла поздно, поэтому раствор сочиняли быстро. Чтобы
      совсем убить в трубах чуму, хлоркой и прочими стандартными реагентами не
      обойдёшься, она специально обучена их обходить. Состав придумался сразу, а вот
      сделать его незаметным не выходило никак, ни у меня, ни у Тахи Шапки. Ну тогда
      и ворвался изобретательский гений: чтобы люди не заметили вкуса и запаха
      некоего средства, можно — то есть, как я уже сказал, нельзя, у нас не вышло
      —&nbsp;сделать вещество, вкуса и запаха не имеющее. А можно —&nbsp;сделать
      людей не чувствовать вкусов и запахов в момент контакта с веществом. Всего-то и
      нужно добавить в вещество каплю одного довольно сложного анестетика.
      Соприкоснёшься с раствором —&nbsp;в первый момент почувствуешь вкус или запах,
      но потом почти мгновенно перестанешь, и, скорее всего, забудешь, что лимоны
      вообще были. Если знать, что искать, можно даже отрефлексировать у себя
      характерное лёгкое онемение в носу или на языке — рецепторы слегка отрубаются.
      — Дима честно немного порефлексировал, хотя и так всё
      уже было понятно. — И да, я в курсе, насколько это идиотская идея. Времени не
      было, не было времени.</p>

    <p>Да и вообще вся эта изначальная
      затея с чумой не блещет величием тактической мысли и разумности.</p>

    <p>Диме стоило бы завести себе раба,
      который бил бы его по голове каждый раз, когда он говорит много и о ненужном —
      когда стоило бы заткнуться и.</p>

    <p>И что?</p>

    <p>Чума в кранах Габриэля
      Евгеньевича, чума в доме Габриэля Евгеньевича, чума у Габриэля Евгеньевича.</p>

    <p>И/или Максима.</p>

    <p>А может, чумы всё-таки не было,
      просто дезинфицирующий раствор зачем-то пустили по всем трубам города. А может,
      чума побыла здесь совсем недолго, и никто из обитателей квартиры не успел
      заразиться. А может, дезинфицирующий же раствор их и спас, в него входят компоненты,
      повышающие иммунитет и позволяющие самым крепким организмам преодолеть болезнь.</p>

    <p>Улыбка на зеркале созерцала сию
      попытку оптимизма с презрительной жалостью.</p>

    <p>…Габриэль Евгеньевич не смог
      слушать скопцов, потому что он-де нехорошо себя чувствовал. Опять. В который
      раз. Сотрясение мозга. Пять раз получил по лицу. Никто не придал значения.</p>

    <p>Заразить Габриэля Евгеньевича и
      уйти безнаказанным, потому что никто своевременно не отследит у него
      симптоматику, он один, дома, с сотрясением мозга, не станут проверять
      специально.</p>

    <p>Гуанако аутично и деловито залез
      в Димину сумку, извлёк пустую пробирку (набор юного медика всегда с собой, а
      как же), набрал пробу жидкости из-под крана, закупорил.</p>

    <p>Молча.</p>

    <p>По-хорошему надо так же молча
      спуститься в канализации, оттуда тоже взять пробы, осмотреть хотя бы пару
      соседей, отзвониться Ларию как можно скорее (и это
      уже не молча). Чума в доме, дополнительный ворох дел, скажем нет простоям.</p>

    <p>Только это не поможет найти
      пропавших.</p>

    <p>И самое забавное —&nbsp;с
      некоторой вероятностью в Университете могут <i>вообще
        никогда не узнать</i>, что произошло с Габриэлем Евгеньевичем.</p>

    <p>С Максимом.</p>

    <p>С Бровью, кстати.</p>

    <p>Даже нечего терзаться
      недостаточным пылом терзаний совести: пропавшие без вести живы, пока не увидишь
      труп своими глазами, верно?</p>

    <p>Подозревать — и не знать
      наверняка; знать только, что не узнаешь.</p>

    <p>Дима, между прочим, так впервые
      поседел — когда понял, что Гуанако не вернётся из экспедиции.</p>

    <p>Мог погибнуть в Объединённой
      Латинской Америке.</p>

    <p>Мог погибнуть в Объединённой
      Латинской Америке с чьей-то лёгкой руки.</p>

    <p>Мог попасть на Колошму.</p>

    <p>Мог попасть под расстрел.</p>

    <p>Мог по собственному желанию
      бросить Бедроград и уехать куда-нибудь без следов —&nbsp;вполне в его духе.</p>

    <p>Варианты, варианты, варианты,
      варианты — и ничего больше.</p>

    <p>— Если Габриэль Евгеньевич
      заразился чумой, а Максим наконец-то это заметил, он вполне мог, приехав сюда,
      подхватить своего возлюбленного и потащить к каким-нибудь врачам, — негромко
      (громко как-то не вышло) сказал Дима. — Это объяснило бы и бардак, и посылание
      Лария к лешим.</p>

    <p>— К каким нахуй врачам? — так же
      негромко отозвался Гуанако. — К прохлаждающемуся с фалангами Андрею? К
      медицинским учреждениям Бедроградской гэбни? К Тахе Шапке в Столицу? — посмотрел
      зачем-то на просвет пробирку с лимонной жидкостью из-под крана (зато хотя бы
      прозрачной, а не тёмно-зелёной какой-нибудь!), убрал её в карман. — У кого ещё
      есть лекарство от чумы, кроме нас?</p>

    <p>— У Порта, например. То есть нет,
      но по мнению Максима может быть, —&nbsp;невнятно пробормотал Дима. — Если ему
      по каким-то загадочным причинам не хочется возвращаться в Университет.</p>

    <p>Дима вот очень хорошо мог понять
      нежелание возвращаться в Университет. Вот просто чрезвы��айно хорошо. Особенно
      на медфак, особенно в лазарет.</p>

    <p>Оттуда даже чёрствый Ройш на днях
      позорно сбежал.</p>

    <p>У кровавой твири есть некоторая
      неудобная особенность: вытяжка, выжимка и прочие «вы-» из неё нестабильны,
      слишком быстро выводятся из организма. Вариант на спирту надёжнее, но не будешь
      же натурально поить студентов твиревой настойкой до позеленения,
      её просто неоткуда в таких количествах взять. Остаётся только использовать для
      медицинских процедур собственно стебли твири, предварительно нарезая их помельче.</p>

    <p>В общем, «твирью-в-жопу»
      происходящее на медфаке называется не только из сентиментальных соображений.</p>

    <p>В общем, лучше бы не было ни жоп,
      ни твири. Потому что на самом деле (внимание, неожиданное признание) Дима вовсе
      не медик, и плохо это не только потому, что ему где-то не хватает
      компетентности. С компетентностью как раз всё в порядке —&nbsp;Попельдопель и
      Шухер всегда рядом, они точно знают, чего не стоит делать.</p>

    <p>Только вот у не-медика Димы нет
      ещё и столь нужной любому сунувшемуся в нелёгкое медицинское дело привычки
      созерцать чужие страдания. И соответствующего профессионального равнодушия нет.</p>

    <p>Это довольно позорно, вообще-то
      говоря: назвался индокитайцем —&nbsp;полезай под
      воду. Верх идиотизма —&nbsp;убедить окружающих в том, что им надо немного
      помучиться, и оказаться не в состоянии <i>их</i> мучения терпеть.</p>

    <p>Так что сжимаем зубы и крепко
      держим в голове, что это всё —&nbsp;во имя великой цели. И страждущим не
      забываем напоминать. В их представлении великая цель немного не такая, но
      экспедиция в Вилонский Хуй — это тоже неплохо, это тоже стоит того.</p>

    <p>Верно?</p>

    <p>Ну мало ли, немного чешется жопа.</p>

    <p>Думайте о скопцах.</p>

    <p>Им вон и не такое довелось
      потерпеть, а смотрите, какие бодрые.</p>

    <p>И успокойтесь, конечно, у нас
      есть ещё обезболивающие.</p>

    <p>Кстати, хотите послушать историю
      без имён, в которой Дима вдрызг надрался на первом курсе, пересчитал все
      ступени грандиозной истфаковской лестницы, и что из
      этого вышло?</p>

    <p>Синяки? Ха! Да у него шрамы есть!</p>

    <p>А ещё он поспорил кое с кем, что,
      когда это всё закончится, пробежит голым по истфаку. Билеты стоит брать уже
      сейчас, Охрович и Краснокаменный скоро подойдут.</p>

    <p>Конечно, смеяться больно, ну так
      и не смейтесь. Не смейтесь, он кому говорит.</p>

    <p>(Спорил, кстати, с Бровью, сейчас
      даже не может вспомнить, о чём, — и, наверное, держать слово уже и
      необязательно.)</p>

    <p>Зато тестовая версия лекарства,
      слепленная днём, сработала. В тестовых же условиях, но. Шприцы, халаты и
      легенды уже выданы старшекурсникам медфака — буквально через несколько часов
      они побегут по некоторым районам Бедрограда колоть больных, типа прививать от
      новой формы ОРЗ — до всяких коммуникаций с фалангами, Бедроградской гэбней,
      Бюро Патентов и кем угодно ещё.</p>

    <p>Чем скорее — тем лучше.</p>

    <p>Буквально через несколько часов
      старшекурсники медфака будут в <i>этом</i> доме, потому что, как выяснилось, в этом доме тоже чума.</p>

    <p>Дезинфекция в трубах поможет
      некоторым не умереть, но лучше пере, чем недо.</p>

    <p>— Лекарство от нашей чумы у
      Порта? — почти раздражённо бросил Гуанако. — Максим не может быть таким
      дураком. Будь оно у Порта — всё портовое лекарство давно бы было у нас. Как и
      вообще <i>всё портовое.</i></p>

    <p>— Ага, если бы наши бордельные помещения не
      были заняты — занимаемы примерно сейчас, — все мальчики и девочки, видимо, тоже
      перекочевали бы. — Дима задумчиво поковырял кафель. — Гуанако, скажи мне
      правду: чем ты собираешься с Портом за все эти радости расплачиваться?</p>

    <p>— Выплывем — разберёмся.</p>

    <p>Так же, как и всегда.</p>

    <p>— Порт не бесится хотя бы?</p>

    <p>Гуанако махнул рукой с некоторой
      досадой.</p>

    <p>Значит, бесится.</p>

    <p>— В Медкорпусе никогда не
      задумываешься о том, что сколько стоит, всё выдают сразу, готовым и просто так.
      Реагенты, аппараты, подушечку, если спишь на рабочем месте. — Дима выбрал на
      поковырянной стене место без чёрных разводов и прислонился —&nbsp;так оно
      как-то удобнее. — Но на моё ж имя весь Медкорпус выписывал себе то, что дают
      готовым и просто так, но не сразу. И почему-то довольно многим хотелось монетизировать степень моего благородства, рассказав,
      сколько они на мне сэкономили — и времени, и денег. Тогда-то я и усёк, что
      медицина — дорогая штука.</p>

    <p>— Какое тонкое и изящное
      развязывание языка! — фыркнул Гуанако, но улыбнулся. — Хуй с тобой, есть
      проблемы. Но это не твоя головная боль.</p>

    <p>— Ты не посмеешь помешать мне
      стать истинным средоточием мировых страданий. Я так и так им буду, просто меня
      куда меньше прельщает путь, связанный с обнаружением где-нибудь в Порту твоего
      хладного тела, — Дима вздохнул. — Хотя, конечно, если я попрусь в Порт один
      кого-то там искать, недолго мне ходить средоточием мировых страданий.</p>

    <p>— Вот-вот, и это во-первых, —
      чрезмерно серьёзно кивнул Гуанако. — А во-вторых, не за что страдать пока.
      Просто сегодня с утра до всяких разных уважаемых и жадных людей доползли
      какие-то левые заказы на кровавую твирь. А твири нет — Портовая гэбня
      заморозила весь оборот ночью с воскресенья на понедельник. Потому что хуй же
      знает, каковы масштабы трагедии и, соответственно, сколько твири в итоге
      понадобится. Короче, по Порту можно наскрести только ту мелочь, которая была на
      руках до воскресенья, а остальное — неизвестно где и, главное, неизвестно
      почему. Заинтересованные лица и так волнуются, а тут эти заказы. Выгодные и на
      большие объёмы, которых хуй. И вроде хотели со Святотатычем никаких больших
      коммерческих предложений не пропускать в народ, а они всё равно откуда-то
      пролезли, — щёлкнул языком, машинально полез за сигаретами, но потом, видимо,
      решил-таки не дымить в ванной Габриэля Евгеньевича, дотерпеть хотя бы до
      коридора. — Срань, но пока живём.</p>

    <p>Твири надо много, много надо
      твири, не факт ещё, что обойдутся тем, что есть. Нерациональный, но
      единственный способ введения в организм. И Дима зарёкся заниматься твирью сам,
      потому что у него уже один вид стеблей начал вызывать безудержную жадность и
      «давайте попробуем порцию поменьше», а на медицине экономить нельзя.</p>

    <p>Только Гуанако он об этом
      говорить пока что не стал. Чтобы разобраться, надо выплыть, а выплывать с
      бременем тяжкого знания сложнее, чем без него.</p>

    <p>Об этом говорить не стал — так
      ляпнул о другом (потому что нечего давить речевые интенции), о чём совсем не
      стоило:</p>

    <p>— Если у Габриэля Евгеньевича — и
      Максима, чего уж там — правда чума…</p>

    <p>Если — то что?</p>

    <p>То много вариантов продолжения
      фразы, что на практике означает — ни одного.</p>

    <p>— Нехорошо как-то вышло.</p>

    <p>Гуанако в ответ снова усмехнулся,
      протянул руку и потрепал Диму по затылку.</p>

    <p>Молча.</p>

    <p>Типа и без слов всё понятно.</p>

    <p>Двадцать-мыслей-одновременно
      построились-таки парами и наконец-то покинули голову, даже относительно
      правильный выбрали момент. Дима отлепился от стены и несколько неловко (быстро
      же он отвык, полугода не прошло — и небыстро же он привыкает обратно) уткнулся
      Гуанако носом в плечо.</p>

    <p>Когда кто-нибудь кому-нибудь
      где-нибудь утыкается в плечо, он всегда естественным образом начинает думать о
      том, чем оно пахнет, только воду так никто и не закрыл, рецепторы не
      восстановились, и никаких запахов Дима не почувствовал.</p>

    <p>Ударим отсутствием обоняния по
      романтическим стереотипам.</p>

    <p>Гуанако его обнял, прижал к себе,
      разумеется, — эдак ничем не примечательно, повседневно, но всё-таки на пару
      секунд чумы вроде как и не стало.</p>

    <p>— Да не волнуйтесь вы, доктор,
      все выживут. Куда они, бляди, денутся.</p>

    <p>Даже если и выживут, ситуация, в
      которой выжить — это уже большая радость, — не самая лучшая ситуация. Максим
      вон с самого начала был резко против любых провокаций и подсовываний
      Бедроградской гэбне своего вируса —&nbsp;и, леший дери, всем стоило слушать
      Максима.</p>

    <p>Не самая лучшая ситуация <i>уже </i>вышла. С Максимом, с Габриэлем
      Евгеньевичем, с Бровью, с Ройшем, со студентами, с Тахой
      Шапкой.</p>

    <p>С Сепгеем, кстати, Борисовичем.</p>

    <p>Таха Шапка вон вообще ничем не
      виноват, а ему морду бить ездили. Всё потому, что он имел несчастье дружить с Сепгеем-кстати-Борисовичем, а Сепгей-кстати-Борисович
      вознамерился Диме помочь. Где у нас в Медкорпусе человек, который может
      сотрудничать с головой Бедроградской гэбни? Да вот же он, и не «может», а
      наверняка сотрудничает, ему не впервой. И имеет несчастье дружить с Сепгеем
      Борисовичем, ввиду чего внимательно выслушает его неожиданного степного гостя,
      поделится уже наработанным и сомнениями в адрес Бедроградской гэбни и тоже
      согласится помогать — не только делать вирус, но и плести потом Гошке
      сочинённую втроём легенду, и получать по морде, и даже звонить потом Диме в
      Порт.</p>

    <p>Зачем они все ему так доверяют, у
      них же нет никаких оснований.</p>

    <p>Дима же не давал никаких гарантий
      их безопасности.</p>

    <p>Он же ничего им не обещал.</p>

    <p>С чего они взяли, что он не
      исчезнет с горизонта, наплевав на всякую ответственность.</p>

    <p>За Максима, за Габриэля
      Евгеньевича, за Бровь, за Ройша, за студентов, за Таху Шапку.</p>

    <p>За Сепгея, кстати, Борисовича.</p>

    <p>— Сепгей, кстати, Борисович
      —&nbsp;помнишь такого? — мне тут из Столицы телеграмму написал, — пробурчал
      Дима в гуанаковское плечо. — Вернее, не мне — он моего нынешнего имени не знает
      — но если писать в Бедроград на фамилию Ройш, сильно не промахнёшься, так что
      телеграмма дошла по адресу.</p>

    <p>— А, человек с <i>действительно глупым</i> именем, — нехотя
      ответил Гуанако, которого явно устраивала ситуация бессловесности — особенно
      ситуация бессловесности без посторонних мужиков в чьих бы то ни было мыслях. —
      Что пишет?</p>

    <p>Что он ещё один человек, которому
      мы в жизни не принесли ничего, кроме радости и искромётного веселья,
      разумеется.</p>

    <p>— Что у него по результатам всех
      наших медицински-корпусных мероприятий нервы совсем
      всё. У Сепгея Борисовича очень особые отношения с фалангами — и фаланг на его
      голову в последние дни свалилось предостаточно. Просто целый палец, возможно,
      даже два. — Дима по возможности отодвинулся от Гуанако, заглянул ему в лицо
      (глаза, нос, рот — на месте). — Так что у него ко мне, а у меня, соответственно,
      к тебе — просьба. Сепгей Борисович хотел бы немного пожить в Порту, отдохнуть
      от официальщины в жизни. Это ведь не такое большое
      одолжение, верно?</p>

    <p>— А Сепгей Борисович со своими
      нервами там случайно не сдал фалангам чуму в Бедрограде? — подозрительно
      осведомились глаза, нос и рот.</p>

    <p>— Приедет —&nbsp;можешь его об
      этом и спросить, — хмыкнул Дима. — И вообще, не хочу впадать в мораль и этику,
      но после всего, что он для нас —&nbsp;меня —&nbsp;сделал, и всего, что я сделал
      ему, я несколько чувствую себя обязанным. Странно, не правда ли.</p>

    <p>Гуанако отпустил руки, развёл
      ими, ухмыльнулся (не скрывая, впрочем, скепсиса).</p>

    <p>Стоит ли тащить в Порт человека,
      которым так интересуются фаланги?</p>

    <p>Стоит ли тащить в Порт человека,
      который так интересуется Димой?</p>

    <p>То есть, конечно, интерес Сепгея
      Борисовича к Диме — это легенда, но кто же в это на самом деле верит. Впрочем,
      сейчас было бы уместно напомнить, что никакого Димы ни в каком Медкорпусе вовсе
      не оказалось бы, если бы не инициированные известно кем приснопамятные майские
      события, желание сгинуть из мест привычных (дабы не травить) и знакомство
      («знакомство», ха-ха) со всего одним человеком в Столице (зато его правда легко
      искать в телефонном справочнике прямо с вокзала!). Было бы уместно, но Дима не
      станет.</p>

    <p>Дабы не травить.</p>

    <p>Лучше просто особо не вспоминать
      и мимоходом разгрести последствия.</p>

    <p>— Попробуем, — вздохнул Гуанако.
      — Но только чтобы никакой самодеятельности без меня, ладно?</p>

    <p>Какая самодеятельность, сезон
      самодеятельности изо всех сил жаждет подойти к концу.</p>

    <p>— Ладно, —&nbsp;покладисто согласился
      Дима и улыбнулся.</p>

    <p>Улыбка на зеркале, впрочем,
      по-прежнему смотрелась убедительней.</p>
  </>
}

export default chapter14
