import React from 'react'

export default () => <>
  <main style={{
    fontFamily: 'Georgia, "Times New Roman", "DejaVu Serif", serif',
    fontSize: '16px'
  }}>
    <h1>Синестезия</h1>
    <p>...ещё: чего-то вдруг тюкнуло составить список материалов. Не знаю, что это значит; вероятно, ничего :)</p>
    <p>
      Бровь — ивовые прутья<br/>
      Шухер — птичий пух, каким набивают подушки<br/>
      Дима — пластилин неясного цвета<br/>
      Сепгей Борисович — ватман<br/>
      Гуанако — твёрдая чёрная резина<br/>
      Ройш — сушёные водоросли<br/>
      Охрович и Краснокаменный — камень, понятно, скорее всего, гранит<br/>
      Максим — тёмное очень плотное и тяжёлое дерево — типа груши, вишни или морёного дуба<br/>
      Ларий — не очень твердая, но прочная пластмасса, красная или зелёная<br/>
      Гошка — упругая сталь<br/>
      Андрей — высушенная осина или берёза<br/>
      Соций — латунь — слегка зеленоватая<br/>
      Бахта — жёсткий войлок (не знаю, бывает ли)<br/>
      Святотатыч — эбонит, кажется<br/>
      Виктор Дарьевич — сосна<br/>
      Габриэль Евгеньевич — тут я застопорился. Понимаю, каким должен быть материал, но не знаю такого материала %) Вот грибы бывают такие — самые нежные и хрупкие
    </p>
    <p style={{textAlign: 'right', fontStyle: 'italic'}}>Автор пожелал остаться неизвестным</p>
  </main>
  <footer>
    <img src='/images/site_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</>