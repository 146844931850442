import React from 'react'
import Helmet from 'react-helmet'
import cosplay from '../../Data/cosplay'
import MarkdownPage from '../../MarkdownPage'

const CosplayPage = () => <>
  <Helmet>
    <title>Внимание, первый косплей!</title>
  </Helmet>
  <MarkdownPage
    sidebarPosition='right'
    markdown={cosplay}
    sidebar=''
    full
  />
</>

export default CosplayPage