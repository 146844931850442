import React from 'react'
import {RouteComponentProps} from 'react-router'
import news from '../../../Data/news'
import NewsArticle from '../../NewsArticle/NewsArticle'
import {DiscussionEmbed} from 'disqus-react'
import Helmet from "react-helmet";
import PageNotFound from "../PageNotFound";

const NewsArticlePage: React.FunctionComponent<RouteComponentProps<{
  id: string
}>> = ({ match }) => {
  const article = news.find(item => item.id === +match.params.id)

  if (typeof article !== 'undefined') {
    return <>
      <Helmet>
        <title>{article.title}</title>
      </Helmet>
      <main>
        <NewsArticle
          article={article}
          full
        />
        <DiscussionEmbed
          shortname='bedrograd'
          config={{
            url: `https://bedrograd.megus.org/node/${article.id}`,
            identifier: `node/${article.id}`,
            title: article.title
          }}
        />
      </main>
      <footer>
        <img src='/images/site_footer.jpg' alt='' style={{
          maxWidth: '100vw'
        }} />
      </footer>
    </>
  }

  return <PageNotFound />
}

export default NewsArticlePage