import React from 'react'
import {Link} from 'react-router-dom'
import faq from '../../Data/faq'
import MarkdownPage from '../../MarkdownPage'
import Helmet from "react-helmet";

const FaqPage: React.FunctionComponent = () => {
  return <>
    <Helmet>
      <title>FAQ</title>
    </Helmet>
    <MarkdownPage
      sidebarPosition='right'
      markdown={faq}
      sidebar={<section className='index_sidebar_contacts' style={{
        background: 'transparent'
      }}>
        <h3>Оставьте это, Тоша</h3>
        <p>К чему всё это? Зачем вам штудировать бесконечно длинную страницу, на которой авторы тем только и занимаются, что открещиваются от всего на свете? Гораздо лучше поскорее бежать мимо и собственно</p>
        <Link to='/book/prolog' className='index_sidebar_btn index_sidebar_btn--read'>читат'</Link>
        <p>«Чуму в Бедрограде».</p>
      </section>}
    />
  </>
}

export default FaqPage
