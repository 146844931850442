import React from 'react'
import { IBookPage } from './index'

const chapter18: IBookPage = {
  header: 'header_d7.jpg',
  title: 'Глава 18. Маленькие такие гвоздики',
  prevLink: 'chapter17',
  nextLink: 'chapter19',
  content: <>
    <h2>Бедроградская гэбня. Гошка</h2>

    <img alt='' src="/images/18_goshka.jpg" style={{float: 'left', margin: '0 10px 10px 0'}}/>
    <p>— Шшш,
      ты не дёргайся, не дёргайся, всё закончилось уже. Теперь только говорить.</p>

    <p>— Уж конечно. И давно ли?</p>

    <p>— Сам будто не знаешь.
      Знаешь-знаешь. И я знаю. Только рассказывать всё равно придётся, такие здесь
      порядки.</p>

    <p>— Где — здесь?</p>

    <p>Шаман сипло расхохотался, и от
      него пахнуло гнилым мясом; сунулся прямо в лицо своей красной восьмиглазой рожей и осклабился.
      Зубы его были забиты травой, словно он жевал её только что; шесть глаз из
      восьми на лице — неживые, нарисованные.</p>

    <p>Одна пара из четырёх — настоящая,
      просто эффект четвёртого патриарха в среде глазных яблок. Эффект четвёртого
      патриарха, он же синдром Начальника Колошмы — ситуация критической
      рассинхронизации одного головы гэбни с тремя другими. Патриархи в своё время за
      это четвёртого закололи, но нынче цивилизация развилась, продвинулась и
      изобрела ПН4.</p>

    <p>Чистое средство для чистых времён.</p>

    <p>— Помер ты. Теперь — рассказывай,
      чего натворил, мертвяк.</p>

    <p>Гошка фыркнул бы, но не чуял тела
      — ни рук, ни ног, ни губ. Чуял только запах: тяжёлый, кровавый, и ещё чего-то
      тошнотворно-сладкого —&nbsp;и видел на потолке узоры из человеческого мяса, из
      костей и травы. Аккуратно прибитых маленькими такими
      гвоздиками.</p>

    <p>Чистые времена.</p>

    <p>В грязные,
      наверное, было проще.</p>

    <p>— Да ничего мы пока не натворили,
      расслабься. Собираем силы.</p>

    <p>Кишки на потолке затряслись от шаманьего ехидного смеха.</p>

    <p>— Забыл, что ли? Вспоминай давай и рассказывай, мне спешить некуда.</p>

    <p>— Ничего я не забыл. Хотя глюк красивый, конечно, такого со мной раньше не было.
      Не
      соврала Врата, когда угощала.</p>

    <p>А ты продолжай, зверушка.</p>

    <p>Шаман радостно запрыгал, шурша
      травяной юбкой и надетым поверх линий-разводов на теле плетёным наплечником —
      как у настоящего головы гэбни, разве что длиной до локтя. От его немытой башки воняло, как от склада контрабанды, и из длинных,
      травяного же цвета волос торчали во все стороны золотистые стебли бурой твири и
      тёмные —&nbsp;кровавой.</p>

    <p>— Думаешь, это всё не
      по-настоящему? Думаешь, ещё живой?</p>

    <p>Зачем мне думать, я и так знаю:</p>

    <p><i>Думать вредно.</i></p>

    <p>&nbsp;</p>

    <p>Когда Гошка распахнул глаза, он
      почти увидел на бледно-сером потолке кишечные разводы, твиревые венки и
      маленькие такие гвоздики. Померещилось, ясен пень: просто тени от занавесок
      (Андрей повесил-таки, не удержался).</p>

    <p>Итак, некий голова Бедроградской
      гэбни допрыгался.</p>

    <p>Своей квартиры он не видел уже
      добрый месяц: нерационально, да и опасно, тратить своё и человека за рулём
      время на шляния по уютным койкам, когда столько дел,
      а в здании Бедроградской гэбни столько помещений, в которые вполне влезают
      четыре дивана. Самое то, чтобы свалиться на допустимые пять-шесть часов, не
      выпадая из событий. Квартира — это просто инструмент, локация для сна и еды,
      никаких сантиментов и особой привязанности к личным квадратным метрам за Гошкой
      не водилось.</p>

    <p>Не мог же он ожидать, что
      непривычная форма теней на потолке покажется ему продолжением бредового сна.</p>

    <p>Впервые в жизни Гошка пожалел,
      что у голов гэбен не бывает отпусков.</p>

    <p>Не потому что ему стрёмно или он
      дал слабину, а потому что у него, в отличие от многих, есть мозги. И этим самым
      мозгам чрезвычайно хорошо известна определённая техника безопасности: если тебе
      бредятся кошмары — это нормально; если они
      повторяются — это ещё куда ни шло; но вот если ты
      просыпаешься от них с сердцем, колотящимся, как загнанный таврский конь, — это
      звоночек. Когда звоночки звонят, нужно внимать их трели, чтобы в ответственный
      момент не обнаружить у себя обрывки нервов вместо способности действовать.</p>

    <p>Когда звонят звоночки, нужно
      отдыхать.</p>

    <p>Оттягиваться в Порту с безотказной (вчера убедились, что <i>совсем</i> безотказной) Вратой, ага.</p>

    <p>&nbsp;</p>

    <p><i>— Думаешь, это всё не по-настоящему? Думаешь, ещё живой?</i></p>

    <p><i>— Зачем мне думать, я и так знаю.</i></p>

    <p><i>Поудив пятернёй в одной из своих мисок, шаман вытащил нечто липкое и
      свесил прямо перед мертвяком, капая ему на лицо. Какой-то внутренний орган —
      печень, кажется, хотя вне тела все они — просто куски мяса.</i></p>

    <p><i>— Твоя, твоя, не сомневайся.</i></p>

    <p><i>— Докажи.</i></p>

    <p><i>Шаман покачал головой, как доктор, которому не сладить с упрямым
      пациентом, и снова пропал из поля зрения — копаться в потрохах. Через полминуты
      вернулся, гордо демонстрируя на ладони два игрушечных белых шарика; продают
      такие, вертеть в руках и успокаиваться.</i></p>

    <p><i>Два игрушечных белых глазных яблока.</i></p>

    <p>&nbsp;</p>

    <p>Дооттягивался.</p>

    <p>Какого-то хера
      галлюцинация стояла перед глазами во всей своей красе, отчётливо, до мельчайших
      деталей. Глазные яблоки на ладони (с зелёными радужками, правильными, а ведь
      настоящий цвет глаз Гошки не всегда помнит даже сам Гошка). Небрежные лохмы шамана
      и медицински-аккуратные
      татуировки на его теле. Запах затхлости, ощущение склепа и одиночества — и
      смутный шум откуда-то издалека, словно совсем рядом,
      за порогом, город, только до него не дотянуться — никак не дотянешься, старайся
      не старайся.</p>

    <p>Ощущение отсутствия собственного
      тела.</p>

    <p>И так же отчётливо, во всей
      красе, как на ладони шамана, перед глазами Гошки стояло простое, почти
      визуальное понимание: он стареет.</p>

    <p>Не физически, конечно, сороковника ещё не стукнуло. Дело не в теле (тела-то нет,
      хе-хе). Просто слишком бешеный темп жизни — и он, Гошка, этот темп задаёт, и
      он, Гошка, первым же порвёт пасть любому, кто посмеет из этого темпа выпасть.</p>

    <p>И он, Гошка, первым же из него и
      выпадает.</p>

    <p>Не только Андрей ненавидит
      Фрайда.</p>

    <p>Во Всероссийском Соседстве нет
      обязательного пенсионного возраста. После шестидесяти, удалившись с места
      службы или работы, можно получать определённые выплаты ни за что, но никому это
      специально не предлагают — уж точно не государственным служащим. Зиновий
      Леонидович вон, легенда всея Колошмы, сидит себе в гэбне аж
      с 48-го — тридцать пять лет уже сидит! — и ещё столько же явно намеревается.
      Ему шестьдесят пять, он пережил две вспышки степной чумы и в ус не дует.</p>

    <p>&nbsp;</p>

    <p><i>— и мы, и они — все под одним мечом ходим, только мы его видим, а они
      залепили глаза и в ус не дуют, творят, что вздумается —</i></p>

    <p>&nbsp;</p>

    <p>Да заткнитесь уже, голоса в
      голове! Что за реакции на ключевые слова, честное слово.</p>

    <p>Может, это Гошка от завкафа
      воздушно-капельным (увы, увы, не половым) подцепил особую впечатлительность?</p>

    <p>Никто и никогда не обвинял Гошку
      в подстрекательстве к войне и чрезмерно боевым операциям. Во-первых, он ни разу
      и не подстрекал — это всегда были декларации вполне конкретных и отчётливо
      агрессивных намерений, никакой подковёрной игры и
      полунамёков; во-вторых, вся Бедроградская гэбня эти планы принимала, даже
      Андрей. Принимала и верила, что некоторые вопросы решаются именно так и только
      так, с обоймой в зубах и трупами на совести.</p>

    <p>Только решаются ли?</p>

    <p>В те золотые, кровавые, грязные
      времена, когда не было ни Пакта о Неагрессии, ни ПН4,
      ни Гошки — ясен хер, решались. Выдрессировал отряд боевых скопцов — и вперёд,
      завоёвывай континенты.</p>

    <p>Но золото обтёрлось, грязь повычистили — и остались Бюро Патентов с фалангами.
      Соблюдение протоколов, умение держать красивую мину при паршивой игре.
      Бедроградскую гэбню за то и терпели, что её головы держали мины только так, за
      самый загривок, и знали все окрестные тихие омуты, в мутные воды которых
      полагается опускать концы; а пока концы надёжно полощутся, терпение фаланг может
      быть безграничным.</p>

    <p>Лучше бы не могло быть.</p>

    <p>Неуютно не знать, когда
      взорвётся; невыносимо — не знать, взорвётся ли вообще.</p>

    <p>Люди придумали молитвы и прочую
      мистическую поебень как раз для того, чтобы хоть как-то защититься от хаоса и
      непредсказуемости этого мирка. Знать, что <i>ты
        не влияешь вообще ни на что</i>, что, каким бы ты пиздец распрекрасным
      благонравным гением ни был, тебе всегда может упасть на голову совершенно случайный
      кирпич, — невыносимо.</p>

    <p>В молитвы и прочую мистическую
      поебень Гошка не верил, зато верил — всей душой —&nbsp;в то, что думать вредно.</p>

    <p>Потому что институт фаланг — это
      целый институт случайных кирпичей. Они могут заловить Андрея, расколоть Андрея
      и вернуть ему шестой уровень доступа —&nbsp;просто так, почему бы и нет. Они
      могут конфисковать вирус и устроить показной осмотр уже пустующих складов —
      просто так, почему бы и нет. Они могут позволить сосуществовать в одном городе <i>трём</i> гэбням с одним уровнем доступа и
      явно схлёстывающимися интересами —&nbsp;просто так, почему бы и нет. Они могут
      знать об эпидемии, они могут не знать об эпидемии, они могут зайти — заглянуть
      на чаёчек! — сейчас, сегодня, завтра, они могут не
      зайти вовсе. Их не интересуют чужие переживания даже в связи с эффективностью,
      их, кажется, не шибко интересует чужое благосостояние.</p>

    <p>Один леший знает, что их
      интересует.</p>

    <p>Они непредсказуемы.</p>

    <p>Если позволить себе хоть немного
      думать об этом, загремишь в дурдом быстрее, чем
      отыщешь пистолет или динамит —&nbsp;по вкусу.</p>

    <p>Для особо изысканных вкусов
      где-нибудь во Всероссийском Соседстве наверняка до сих пор существуют
      скопнические общины. Плуг в зубы и паши болото, не думая ни о фалангах, ни о
      неизвестности.</p>

    <p>Лично Гошка в скопники не
      собирался. Если даже оставить в стороне связанные со скопничеством неприятные
      процедуры, то — как там было? «Мы знаем, что делали дурное, и именно это даёт
      нам право действовать. Незачем прикидываться беленькими, когда время
      замараться. Те, с которыми мы воюем, —&nbsp;не лучше ничуть, только боятся
      сказать себе, во что ввязались, боятся признать, чего это всё стоит».</p>

    <p>Это всё стоит невозможности
      дезертирства.</p>

    <p>В гэбне должны быть только
      головы, и, тем не менее, у гэбни всегда есть глава. Невольно есть, в любой
      группе людей сама собой проступает иерархия, и у любой иерархии есть вершина.
      Давешний Начальник в гэбне Колошмы, Молевич в Университетской,
      Святотатыч в Портовой.</p>

    <p>Гошка в Бедроградской.</p>

    <p>Хером
      был бы он мочёным, если бы делал вид, что ему это не нравится. Не меньшим хером —&nbsp;если бы отсвечивал авторитетом и не долбил
      Бахте, Социю и Андрею, что они все равны, все четверо равны, едины и равны,
      равны, бляди, поняли!</p>

    <p>Только ПН4
      всегда предлагает кто-то один, остальные соглашаются.</p>

    <p>Эксклюзивным хером был бы он,
      если бы забывал, что самых ретивых всегда ставят на
      место. Он сам ставит. И его поставят рано или поздно — не своя
      гэбня, нет; фаланги, или Университет, или обстоятельства.</p>

    <p>Остаётся только сделать так,
      чтобы от протянутой к его загривку руки осталась предельно короткая культя.</p>

    <p>Гошка потёр лицо ладонями. Линзы
      вчера не вынул, обвалился на койку прямо в одежде —&nbsp;неудивительно, что на
      потолке леший знает что мерещится, вон, вся морда в
      слезах. Стрелки часов мутятся, но, судя по свету, скоро уже пора двигать. Без
      линз — маскировка маскировкой, а сейчас проще сразу глаза выколоть, чем опять
      туда что-то пихать.</p>

    <p>В комнате никого нет —&nbsp;все
      при делах. Вчера при делах был Гошка, свой отдых он заслужил. Соций готовится к
      сегодняшним операциям, Бахта занимается городскими мелочами — не только чума
      есть в Бедрограде; Андрей бегает по медикам.</p>

    <p>Все при правильных, своих делах.</p>

    <p>Гошка с Социем попали в
      Бедроградскую гэбню вместе, в один день. Когда закончилась первая рабочая
      неделя, Гошка накатал конкретный такой отчёт — ни о чём особо, но старательно,
      со всеми правильными отступами и стилистическими оборотами. Накатал — и понял,
      что не знает, что с отчётом дальше делать. Везти на Революционный проспект, 3?
      И так —&nbsp;каждую неделю? Помаялся ночку, а наутро пришёл к Бахте спрашивать
      — как к старшему и опытному. Бахта только плечами
      пожал. «Ты что, не знаешь, что в нашей стране любая информация предоставляется
      исключительно по соответствующему запросу?»</p>

    <p>«Получается, я —&nbsp;мы — не
      должны ни перед кем отчитываться?» — спросил тогда Гошка.</p>

    <p>«Ну да, — ответил Бахта, — пока
      не прикажут».</p>

    <p>«А если мы херню творить начнём?
      Разве не в <i>их</i> интересах быть в курсе
      и корректировать наши действия?»</p>

    <p>Бахта заржал. «Поверь, когда мы
      начнём творить херню, они будут в курсе. Просто делай то, что считаешь нужным».</p>

    <p>Ни на юрфаке, ни в Институте
      госслужбы такому не учили. Дорвался до верхов — твори,
      что хочешь, и имей свои личные молитвы или другую мистическую поебень, чтобы не
      думать о том, что тебе за это будет.</p>

    <p>Это сакральное знание головы
      гэбен передают друг другу тайно, ногами под столом, и ни в одном билете ни одного
      экзамена его нет.</p>

    <p>Гошке с Социем повезло, что у них
      был Бахта.</p>

    <p>Андрею — что у него были Гошка с
      Социем.</p>

    <p>Зато Андрею не повезло с другим. С биографией — и ох как не повезло: на нём и
      случившаяся Колошма висит, и неслучившаяся
      Медицинская гэбня, и единственный вывод, который делают из этого окружающие,
      —&nbsp;что Андрей хочет забраться повыше. Он и правда
      та ещё шлюха, он и правда хотел когда-то —&nbsp;повыше в рамках реальности,
      быть фалангой, ходить в светло-сером и иметь жетон третьего уровня доступа.</p>

    <p>Ничего, перехотел.</p>

    <p>Не может никто в здравом уме
      долго мечтать о третьем уровне доступа, слишком многое нужно с себя снимать, напяливая форму поутру, — от выражения лица до личного
      мнения. Учиться меленько жить, меленько ходить,
      меленько радоваться. Херушки, Андрей лучше этого. Ему
      нужна не только власть.</p>

    <p>Ему нужна <i>не такая</i> власть — не через страх и омерзение. Андрею нужно, чтобы с
      ним считались, потому что любят. Да, он ляжет под кого угодно, лишь бы любили,
      но всё-таки — это лучше.</p>

    <p>Хотя бы
      потому что работает.</p>

    <p>А фаланги рано или поздно усохнут
      —&nbsp;не руками Гошки и не руками Бедроградской гэбни, ясен
      хер, но усохнут. И вообще — если Университетскую гэбню
      таки удастся убрать, в жизни фаланг будет чуть меньше бесконечной беззубой
      грызни в черте одного города, чуть меньше гаденькой радости. А это хорошо,
      верно?</p>

    <p>Да, это хорошо.</p>

    <p>А значит, Университетскую
      гэбню уберут —&nbsp;и вот это уже как раз руками Бедроградской гэбни.</p>

    <p>Руками Гошки.</p>

    <p>Наверное, он и правда стареет,
      если от одного стародавнего хорошего прихода и недельки без сна так разнылся.
      Вплоть до религиозной поебени, поди
      ж ты. Ну да, обвалились тогда канализации под метелинскими мануфактурами,
      стрёмно было, что всё пролетит со свистом, вот и подсел на измену от неведомых
      вратовских химикалий. Гошка не стальной и не железобетонный. Что надо сделать
      —&nbsp;так это не разводить тут интеллигентские нюни, а признать, что он тоже
      способен наложить в штаны, и дальше заниматься делами.</p>

    <p>Дел-то нынче —&nbsp;ого-го. Университетская
      гэбня умненькая, Университетская гэбня заранее пронюхала как-то про чуму,
      Университетская гэбня не боится контратаковать.</p>

    <p>Университетская
      гэбня вчера утром прислала запрос на встречу по причине неназванных
      чрезвычайных обстоятельств. Вчера днём Бедроградская гэбня запрос подтвердила —
      так и так надо было навестить завкафский дом, а тут удалось выжать вдвое больше
      пользы!</p>

    <p>Ни вчера вечером, ни сегодня
      ночью Университетская гэбня свой запрос не отозвала.</p>

    <p>Гошка что только лично на херову
      Пинегу не смотался — Молевич там, Молевич устроил целую поисково-спасательную,
      Молевич точно не в Бедрограде, наблюдатели рвут рубашки в клятвах. Как может
      произойти встреча двух гэбен, если одна из них обезглавлена?</p>

    <p>Впрочем, вопрос «как Университет
      может травануть чумой своего обожаемого завкафа?»
      тоже пару дней назад был риторическим.</p>

    <p>Встреча гэбен назначена на пять
      вечера, вот и заглянем в блестящие университетские глазёнки.</p>

    <p>Это не значит, что до пяти
      полагается проёбываться. Вчера нужные люди уже бывали во всём таком из себя
      нейтральном Порту, жаждали закупить партии кровавой твири по бешеным ценам
      —&nbsp;а твири в Порту нынче нет, и все знают, куда она ухнула. Местная
      наркосеть взбеленилась, ясен хер. Сегодня в Порт должно прийти немного
      гуманитарной помощи в виде коробков с твирью по бросовым ценам —&nbsp;и таких,
      чтобы краска, прикрывающая авторство подарка, достаточно легко стиралась.
      Бедроградская гэбня добрая и щедрая, Университет только и умеет, что тянуть
      жилы, — выбирайте.</p>

    <p>Раз уж Порт отказался от
      нейтралитета, сколько часов в такой ситуации их не-нейтралитет будет в пользу
      интеллектуально обогащённых?</p>

    <p>Особенно с учётом некоторых
      дополнительных факторов.</p>

    <p>Гошка уже устал талдычить: нечего Университету лезть в городскую политику,
      не имея на то ресурсов. Они если что и тянут, то только жилы из Порта. Другого им
      не потянуть. Никто не может долго управлять без
      ресурсов.</p>

    <p>Впрочем, отсутствие ресурсов тоже
      подобает проверить, и как раз сегодня.</p>

    <p>— Спишь? — дверь открылась, и за
      ней показался Соций —&nbsp;в полной боевой.
      —&nbsp;Пора идти уже.</p>

    <p>— К счастью, не сплю.</p>

    <p><i>Гошка не слышал —&nbsp;смеётся ли, говорит ли, — но надеялся, что
      смеётся.</i></p>

    <p>Всё это так просто и понятно, что
      становится стыдно за то, что позволил себе поддаться и всерьёз задуматься о
      всяком там. Нервы не канаты, а свалить уже Университет — задачка та ещё;
      сперва, когда стало ссыкотно, что не свалят, приглючились картины всеобщей гибели, теперь, когда
      вероятность не свалить опять зрима, они вернулись.
      Даже забавно, шаман из глюка смутно кого-то напоминает
      — батюшку, небось. Или декана юрфака, или кто ещё жизнь успел попортить (становитесь в очередь, сукины дети). Не самая
      нормальная, но простая и объяснимая реакция психики, Фрайд бы не стал марать
      руки о такую херню.</p>

    <p>Гошка резко сел, скинул ноги с
      койки и ещё раз потёр глаза.</p>

    <p>Даже самые эффектные кишки всегда
      прибиты к потолку аккуратными такими гвоздиками.</p>

    <p>И, если не забывать вынимать
      линзы перед сном, их всегда можно увидеть.</p>
  </>
}

export default chapter18
