import React from 'react'
import Helmet from "react-helmet";
import Markdown from "../Markdown";
import poetry from '../../Data/poetry'

export default () => <>
  <style>
    {`
    .poetry_author {
      margin-left: 130pt;
      font-style: italic;
    }

    @media (max-width: 620px) {
      .poetry_author {
        margin-left: 25%;
      }
    }
    `}
  </style>
  <Helmet>
    <title>Высокая поэзия</title>
  </Helmet>
  <main>
    <Markdown text={poetry} full={true} />
  </main>
  <footer>
    <img src='/images/site_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</>