import React from 'react'
import { Link } from 'react-router-dom'

export default () => <div className='page--sidebarRight'>
  <main>
    <h1>ШОК-ВИДЕО БЕЗ СМС или что мы там ещё обещали</h1>
    <p>
      Мы изо всех сил постарались сделать так, чтоб скачивать было легко и приятно. Само собой, мы могли чего-то не учесть, не вспомнить какой-то особо извращённый формат и не сделать так, как нравится лично вам. Можете сообщить нам об этом по телефону <img alt='телефон' src="/images/phone.gif" /><a href="mailto:bedrograd@gmail.com">bedrograd@gmail.com</a>, мы обещаем подумать, как дальше жить. Если вы вдруг хотите нам помочь и мира во всём мире, можете кидать туда же досье на попутно отловленных блох: на баги форматирования, криво отображающиеся картинки и прочие напасти, которые способны попортить удовольствие. Мы будем рады. А можете забить и кидать открытки к юбилею Первого Большого Переворота, мы тем более будем рады.</p>

    <p>Короче, скачивайте на здоровье, только имейте в виду: сами-то мы абсолютно уверены, что круче всего <Link to="/book/prolog">читать «Чуму» прямо с сайтика</Link>. Тут и картиночки в нормальном размере, и специфическое форматирование иногда проскакивает, которое не всякая читалка переживёт, и ещё много чего по мелочи тут лучше, чем не тут. Список персонажей, опять же, под рукой. Впрочем, если ваша религия вам такого не позволяет, мы поймём и простим.</p>
    <br/>
    <h2>Скачать всю «Чуму» одним файлом:</h2>
    <br/>
    <div style={{ paddingLeft: '1em' }}>
      <a href="/files/bedrograd.epub" title='Скачать «Чуму» в формате EPub' style={{ marginRight: '10px' }}>
        <img alt='epub' src="/images/epub.svg" height="32" />
      </a>
      <a href="/files/bedrograd.fb2" title='Скачать «Чуму» в формате FB2' style={{ marginRight: '10px' }}>
        <img alt='fb2' src="/images/fb2.png" height="32" />
      </a>
      <a href="/files/bedrograd.pdf" title='Скачать «Чуму» в формате PDF' style={{ marginRight: '10px' }}>
        <img alt='pdf' src="/images/pdf.svg" height="32" />
      </a>
      <a href="/files/bedrograd.mobi" title='Скачать «Чуму» в формате MOBI' style={{ marginRight: '10px' }}>
        <img alt='mobi' src="/images/mobi.png" height="32" />
      </a>
    </div>
  </main>
  <aside>
    <div className='index_sidebar_contacts' style={{ background: 'transparent' }}>
      <h3>Поймите, это для вашего же блага</h3>
      <p>
        Не тащите на винчестер и в читалку всякую гадость! Перед тем, как скачивать «Чуму», попробуйте на всякий случай о ней что-нибудь
        <Link to='/faq' className='index_sidebar_btn index_sidebar_btn--comprehend' tabIndex={0}>понят'</Link>
        и подумать, нужна ли она вам в принципе.
      </p>
      <p>А то мало ли вдруг.</p>
    </div>
  </aside>
  <footer>
    <img src='/images/site_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</div>
