import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import './BonusArtPage/BonusArtPage.sass'

const images: Array<{
  src: string,
  url: string,
  title: string
}> = [
  {
    src: 'gabriel-th.jpg',
    url: '/node/87',
    title: 'Крыса Габриэль Евгеньевич'
  },
  {
    src: 'herr-reusch-th.jpg',
    url: '/node/88',
    title: 'Фаланга у борделя'
  },
  {
    src: 'grif2-th.jpg',
    url: '/node/89',
    title: 'Брофь и грифоны'
  },
  {
    src: 'fange-th.png',
    url: '/node/90',
    title: 'Г. Е. в юности'
  },
  {
    src: 'axolotl-th.jpg',
    url: '/node/91',
    title: 'Умозрительный аксолотль-1'
  },
  {
    src: 'axolotl2-th.jpg',
    url: '/node/92',
    title: 'Умозрительный аксолотль-2'
  },
  {
    src: 'grif3-th.jpg',
    url: '/node/93',
    title: 'Страшные грифоны'
  },
  {
    src: 'dont-touch-th.jpg',
    url: '/node/110',
    title: 'С чего всё начиналось'
  },
  {
    src: 'smoking-th.jpg',
    url: '/node/111',
    title: 'В курилке'
  },
  {
    src: 'plush-th.jpg',
    url: '/node/112',
    title: 'Плюшевые'
  },
  {
    src: 'shaman-th.jpg',
    url: '/node/113',
    title: 'Ш-шаман'
  },
  {
    src: 'alchemy-th.jpg',
    url: '/node/114',
    title: 'Алхимия'
  },
  {
    src: 'landscape-th.jpg',
    url: '/node/116',
    title: 'Пейзаж'
  },
  {
    src: 'portrait-th.jpg',
    url: '/node/117',
    title: 'Портрет'
  },
  {
    src: 'time-to-go-th.jpg',
    url: '/node/120',
    title: 'Пора валить'
  },
  {
    src: 'symbolism-th.jpg',
    url: '/node/121',
    title: 'Символизм'
  },
  {
    src: 'stained-glass-th.jpg',
    url: '/node/122',
    title: 'Витраж'
  },
  {
    src: 'victims-th.jpg',
    url: '/node/125',
    title: 'Жертвы жертвы'
  },
  {
    src: 'sad-th.jpg',
    url: '/node/128',
    title: 'Грустный'
  },
  {
    src: 'heads-th.jpg',
    url: '/node/129',
    title: 'Четыре буквы, пятнадцать голов'
  },
  {
    src: 'yet-another-ge-th.jpg',
    url: '/node/130',
    title: 'И снова Г. Е.'
  },
  {
    src: 'guest-th.jpg',
    url: '/node/131',
    title: 'В гостях, так сказать'
  },
  {
    src: 'dignity-th.jpg',
    url: '/node/133',
    title: 'Про достоинство'
  },
  {
    src: 'port-th.jpg',
    url: '/node/134',
    title: 'Портовая'
  },
  {
    src: 'hipstanako-th.jpg',
    url: '/node/135',
    title: 'Хипстанако'
  },
  {
    src: 'grif4-th.jpg',
    url: '/node/136',
    title: 'Двуглавый грифон'
  },
  {
    src: 'taxi-th.jpg',
    url: '/node/137',
    title: 'Техническая документация'
  },
  {
    src: 'kind_eyes-th.jpg',
    url: '/node/138',
    title: 'А глаза добрые-добрые'
  },
  {
    src: 'leprechauns-th.jpg',
    url: '/node/139',
    title: 'Лепреконы'
  },
  {
    src: 'guess-who-th.jpg',
    url: '/node/140',
    title: 'Угадайте кто'
  },
  {
    src: 'childhood-th.jpg',
    url: '/node/141',
    title: 'Детство'
  },
  {
    src: 'kill-b-th.jpg',
    url: '/node/142',
    title: 'УБИТ\' Б'
  },
  {
    src: 'serious-business-th.jpg',
    url: '/node/143',
    title: 'Серьёзный бизнес'
  },
  {
    src: 'RUKI-th.jpg',
    url: '/node/145',
    title: 'Руки'
  },
  {
    src: 'seagulls-th.jpg',
    url: '/node/146',
    title: 'И разлетелся чайками'
  },
  {
    src: 'girls-th.jpg',
    url: '/node/147',
    title: 'Девочки (их более одной!)'
  },
  {
    src: 'whip-th.jpg',
    url: '/node/148',
    title: 'Хлыст как педагогический приём'
  },
  {
    src: 'naturemorte-th.jpg',
    url: '/node/149',
    title: 'Натюрморт'
  },
  {
    src: 'blood-food-th.jpg',
    url: '/node/150',
    title: 'Кровавую пищу клюёт'
  },
  {
    src: 'behind-th.jpg',
    url: '/node/151',
    title: 'За спиной'
  },
  {
    src: 'okodnol-th.jpg',
    url: '/node/152',
    title: 'Жизнь и не так размажет'
  },
  {
    src: 'spoiler-th.jpg',
    url: '/node/153',
    title: 'В некотором роде спойлер'
  },
  {
    src: 'stop-it-th.jpg',
    url: '/node/154',
    title: 'Вот не надо тут!'
  },
  {
    src: 'grif5-th.jpg',
    url: '/node/174',
    title: 'Грифоны популярны'
  },
  {
    src: 'grif6-th.jpg',
    url: '/node/175',
    title: 'Очень, очень популярны грифоны'
  },
  {
    src: 'awkward-th.jpg',
    url: '/node/176',
    title: 'Комикс про неудобненькую ситуацию'
  },
  {
    src: 'ge2-th.jpg',
    url: '/node/177',
    title: 'Только ГЕ может тягаться с грифонами'
  },
  {
    src: 'glasses-th.jpg',
    url: '/node/178',
    title: 'Декоративно-прикладной фанарт'
  },
  {
    src: 'cosplay-th.jpg',
    url: '/node/179',
    title: 'Внимание, первый косплей!'
  },
  {
    src: 'tits-th.jpg',
    url: '/node/180',
    title: 'Сиськи Охровича и Краснокаменного'
  }
]

export default () => <>
  <Helmet>
    <title>Нам нарисовали</title>
  </Helmet>
  <main>
    <h1>Нам нарисовали</h1>
    <p>Мы до сих пор очень удивлены, но нам не только пишут, но и рисуют. Это невероятно круто, так что не похвастаться мы не можем! Итак, хвастаемся. И обязательно похвастаемся ещё, если вдруг отыщутся новые герои, готовые приковать себя к стулу ради материального воплощения собственного видения «Чумы». Героям — огромнейшее спасибо и пожизненное авторское мимими!</p>
    <div className="ArtPage_Grid">
      {images.map(image => <Link to={image.url} tabIndex={0} key={image.url}>
        <img src={`/images/${image.src}`} alt={image.title} />
        <span>{image.title}</span>
      </Link>)}
    </div>
  </main>
  <footer>
    <img src='/images/site_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</>