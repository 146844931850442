import React from 'react'
import { IBookPage } from './index'

const chapter10: IBookPage = {
  header: 'header_d5.jpg',
  title: 'Глава 10. Трое на одного',
  prevLink: 'chapter09',
  nextLink: 'chapter11',
  content: <>
    <h2>Бедроградская гэбня. Андрей</h2>
    <img alt='' src="/images/10_andrei.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }}/>
    <p>Наутро солнца в Бедрограде не
      случилось.</p>

    <p>Давешний прогноз, который Андрей
      слушал ещё в Столице — с ним обращались хорошо, даже оставили радио, —
      бессовестно соврал.</p>

    <p>Все врут, но только прогнозу
      погоды за враньё ничего не будет.</p>

    <p>Вчера фаланга зашёл в комнату,
      где три дня держали Андрея, дал диктору закончить с температурой и влажностью и
      проблеял:</p>

    <p>— Видите, как хорошо, Андрей
      Эдмундович. Солнце, а то пока вас не было, в Бедрограде сплошные дожди.
      Привезёте завтра из Столицы нормальную погоду.</p>

    <p>Андрей улыбнулся, не хотел
      улыбаться, но мимический рефлекс сработал быстрее головы. Люди
любят, когда им улыбаются, им приятно, когда слушают — внимательно, отвечают —
вежливо, переспрашивают — по делу. Андрею несложно делать людям приятно,
      даже если не очень-то и хочется.</p>

    <p>На кокетливое «привезёте завтра»
      он почти не среагировал. Слишком много такого кокетства, обнадёживающего в
      первую секунду и бессмысленного на деле, выливалось на него с вечера субботы.
      Рецепторы, которыми душа — или мозг? — воспринимает ложную надежду, тоже
      притупляются.</p>

    <p>В Бедрограде Андрея снова будут
      держать под замком. Солнце станет лезть в окно, высвечивать в спёртом воздухе
      однообразные потоки пыли, напоминать про детский страх — остаться запертым в
      отряде на много-много лет после выпуска. Для кого-то — нелепость, скучная
      байка, о которой дети шепчутся перед сном, байки бывают и изобретательней; а в одиннадцатом отряде города Бедрограда такое и правда случалось.</p>

    <p>Когда его заканчивал Андрей,
      отрядом негласно заведовала Медицинская гэбня, внедряла экспериментальные
      программы, да и необязательно программы — наука ценнее воспитания детей. Лично
      у Андрея воспитание было более чем удачным: усиленная учебная нагрузка,
      постоянная помощь взрослым в их настоящих серьёзных делах — метафорически
      выражаясь, высокий уровень доступа к отрядской информации. Некоторым везло
      меньше, и это не ночные страшилки, а проверенные данные. Потом после одного
      вышедшего из-под контроля эксперимента медикам пришлось слегка, но поделиться-таки своей площадкой с
      Бедроградской гэбней — неправильно это, когда в городские учреждения нет хода
      городским властям.</p>

    <p>Задолбали такие учреждения, но
      пальцем показывать не будем.</p>

    <p>Везли Андрея под конвоем. Правда,
      в обычном поезде — десять часов, целая ночь и ещё чуть-чуть. Ему позволили быть
      в купе одному, но заперли вагон, выгнали взашей
      проводников, встали с автоматами у дверей. Автоматы надоели до смерти, как
      будто на одного безоружного человека нужно столько автоматов. Только чувство
      собственной значимости Андрею подкармливают — и вот зачем?</p>

    <p>Все пейзажи от Столицы до
      Бедрограда железной дорогой выучены наизусть, лучше бы ехали по шоссе — так
      редко выпадает возможность не пользоваться поездом. Да и на шоссе, когда
      ведёшь, не получается просто пялиться по сторонам. А в поезде наоборот — только
      и можно, что пялиться, и столько раз уже пялился на
      эти леса, лески, подлески и лесочки, что надоело невыносимо.</p>

    <p>Настолько невыносимо, что леший
      дёрнул Андрея выглянуть в коридор к автоматам.</p>

    <p>Дула чернели на него со всей
      возможной серьёзностью, но Андрей смотрел поверх, с деланой опаской улыбался
      лицам. Этим людям незачем знать, что для него они — деревянненькие
      остолопы на поводке у фаланг, несамостоятельные
      исполнители, хоть и с четвёртым уровнем доступа. В Силовом Комитете есть и не-остолопы, там непростая иерархия внутри одного уровня,
      только в поезд охраной их вряд ли запихнут.</p>

    <p>Но остолопы
      тоже заслуживают улыбки, вежливой и как будто бы неуверенной беседы, хлопанья
      ресницами и даже немножечко дрожи в плечах — им ведь приятно, когда их боятся.</p>

    <p>Умение делать людям приятно
      принесло Андрею важные, но такие обескураживающие сведения, что ресницы и плечи
      почти перестали слушаться, почти попортили дипломатию.</p>

    <p>— Андрей Эдмундович, — над
      автоматом мелькнул покровительственный взгляд и самолюбование, на которое так и
      провоцирует показательная беззащитность, — к вам же вчера пытались применить ПН4,
      вот и везут теперь в Бедроград разбираться.</p>

    <p>ПН4?</p>

    <p>К нему пытались применить…?</p>

    <p>Кое-как свернув разговор, Андрей
      спрятался в купе.</p>

    <p>Сел, всерьёз прикидывая, не
      залезть ли на багажную полку — притвориться вещью, чем-нибудь не думающим и не
      соображающим, лишь бы дожить до утра в здравом уме.</p>

    <p>ПН4,
      ПН4, нелепое-глупое-безвыходное ПН4!</p>

    <p>Не применили, раз он под стражей
      — нельзя; но пытались, пытались, пытались же.</p>

    <p>В Столице фаланги твердили ему:
      скажите всю правду, ваша гэбня не будет вас выгораживать,
      не сможет, даже если захочет, а она не захочет, у вас дурное положение, не
      делайте себе ещё хуже.</p>

    <p>Андрей потер
глаза, добился светящихся кругов под веками, встал, выключил свет в купе,
включил его обратно, несколько раз подряд проделал путь в три шага от окна до
двери, снова потер глаза, сел, встал, пробормотал что-то вслух, пересчитал
зачем-то сигареты в пачке, хотя так и не закурил, уставился на манящую
бездумностью багажную полку. И всё это за полторы минуты максимум.</p>

    <p>Гошка называл такие истерические
      припадки Андреевой активности «белочкой» — то ли в честь беспокойного зверька,
      то ли в честь белой горячки. Бесился на мельтешение, едко любопытствовал, куда
      же девается умение владеть собой. Да как будто сам Гошка всегда собой владеет —
      он пинает стены, ломает стулья, и все прекрасно
      помнят, как он однажды оторвал дверной косяк. От «белочки» гораздо меньше
      вреда.</p>

    <p>Но что дверные косяки, что
      мельтешение позволено видеть только своим, только гэбне, остальным же достаётся
      исключительно умение владеть собой.</p>

    <p>Только гэбне. Только своим.</p>

    <p>…нет
      больше никаких своих, нет, нету, не будет, не применили, но пытались, сесть,
      лечь, уткнуться в подушку, уткнуться — не грызть, белочки тоже нет, некому так
      обзываться, не перед кем мельтешить, всё сломалось и рухнуло, вернуть не
      получится, нет.</p>

    <p>Не грызть, не грызть, не грызть
      подушку!</p>

    <p>&nbsp;</p>

    <p>Наутро солнца в Бедрограде не
      случилось, вокзал был сырой и слякотный, неожиданно холодный после столичного
      не ушедшего пока лета.</p>

    <p>Охрана,
приставленная к Андрею, подождала, когда толпа сошедших с поезда рассосётся, сменила
автоматы на пистолеты, которые, безусловно, проще укрыть одеждой, отперла вагон
и быстро протащила своего подопечного к служебному такси.</p>

    <p>То есть протащил его давешний
      болтливый остолоп лично, приобнял всё с тем же
      покровительственным видом за талию — под чужую просторную куртку, напяленную на
      Андрея, тоже легко входил пистолет. Остолопу хватило
      дурости коротко — и самодовольно! — извиниться за его наличие.</p>

    <p>Как же иногда неприятно уметь
      делать людям приятно.</p>

    <p>Такси тронулось неразогретым, недовольный обращением мотор взбрыкнул,
      Андрей согласно фыркнул — зачем так, две минуты погоды не сделают. Тем более
      если тащиться потом Старым городом вместо того, чтобы обогнуть вокзал, въехать
      на шоссе в сторону Столицы, на нормальной скорости добраться до съезда на
      Ссаные Тряпки, а оттуда уже рукой подать и до Революционного проспекта, в начале которого громоздится бедроградская резиденция
      фаланг.</p>

    <p>Какие уж тут Ссаные Тряпки —
      очень умно было сажать за руль столичных силовиков, которые наверняка не знают,
      что ещё петербержские контрабандисты вырыли туннель где-то в сердце района, знаменитого
      прачечными для бедноты. Прятали там свои грузы, передерживали перед
      окончательной транспортировкой в Порт или из Порта, сбивая таможенных
      следователей с толку ароматами. Туннель даже не обвалился, не сильно просел в
      болотистой почве — повезло, и в тридцатые годы его укрепили, продлили, стали
      использовать как альтернативу неудобному объезду промзоны,
      выросшей на месте скопища лачуг.</p>

    <p>Андрей решил, что не к добру
      поминать Порт даже мысленно — стоило подумать про туннель под Ссаными Тряпками,
      как в грузовичок, скромно припаркованный в проулке, прыгнули две тельняшки. Вот
      что они тут делают, да ещё и с грузовиком? Точно же ничего законного. Но
      поставь хвост — почуют, заманят в Порт, а Портовая
      гэбня любой хвост придавит складскими воротами ещё на входе, пожмёт потом
      плечами: в Порту надо осторожнее, тут народ дикий, сами виноваты.</p>

    <p>С неаккуратным водителем в Старом
      городе мука — от тряски по брусчатке подташнивает, скорей бы уж на асфальт,
      пусть даже этот асфальт стелется к фалангам.</p>

    <p>Андрей очень попытался задремать.</p>

    <p>И тут, и в Столице резиденции
      фаланг находятся по адресу Революционный проспект, дом 3. Просто запомнить, но
      сложно добраться — бедроградский Революционный проспект далёк от цивилизации,
      широк и промышленен, хотя фаланг это не волнует. Вспомнилось, как Бедроградская
      гэбня тихонько пакостила: подолгу не меняла асфальт на
      въезде непосредственно к зданию, вынуждая его хозяев трястись на ямах или
      заполнять-таки форму для аренды асфальтового катка. Мелочь, но так согревало.
      Они тогда —</p>

    <p>Мысли о Бедроградской гэбне
      обрывались на середине, не желали додумываться, сами отодвигались на периферию
      сознания — пока Андрея уже внутри резиденции не подтолкнули к какой-то двери,
      объявив в спину:</p>

    <p>— Очная ставка по поводу
      отклонённого прошения о применении права нейтрализации четвёртого.</p>

    <p>Объявлял фаланга с незнакомым
      лицом. Сами лица,&nbsp;впрочем, у них несильно различаются — не чертами, это
      было бы малопредставимо, а стандартным для должности
      набором мимических реакций. Ожидал, вероятно, сокрушительного эффекта, но не
      вышло — Андрею сболтнули про ПН4 ещё в поезде, потому
      что в <i>его</i> наборе мимических реакций
      есть хлопанье ресницами и испуганные улыбки разных оттенков. Об очной ставке
      речи не шло, но можно и так догадаться — как будто есть какой-то другой путь.</p>

    <p>Место проведения оной было
      подготовлено с издёвкой: просторная комната, слева от
      входа — длинный и поблескивающий лаком конференц-стол на дюжину человек,
      пустой, но с приёмником внутреннего радиовещания, справа — гэбенный
      диван. Специальная модель, незамкнутое тесное кольцо, чтобы сидеть близко,
      легко касаться друг друга, и чтобы каждый мог хорошо видеть троих остальных. Ни
      в каких служебных инструкциях об этом ничего нет, но на таком диване проще всего
      успокаивать друг об друга нервы и решать без спешки производственные вопросы.</p>

    <p>Андрею, зашедшему в комнату
      первым и без сопровождающих, прозрачно предлагалось выбрать, где он встретит
      свою — бывшую? — гэбню.</p>

    <p>Повертев головой, он таки
      разглядел по углам замаскированные зрачки «подглядывающих» устройств.</p>

    <p>Глупо, очень глупо маскировать их
      наличие от Андрея — только поступив на службу в гэбню города Бедрограда, он
      лично продвигал и курировал проект их создания в Бедроградском Инженерном Бюро.
      Идея, привезённая с Колошмы: записывать не только звук, но и изображение.
      Полезно при фиксировании допросов — пластика, мимика, положение корпусов в
      пространстве говорит зачастую больше, чем слова и даже интонации.</p>

    <p>И не только допросов. С семьдесят
      шестого года, когда проект был едва реализован — пробная версия, звук
      приходилось писать со стандартных устройств, — сам Андрей нашёл ему несколько
      непротокольных способов применения.</p>

    <p>А раз на него ещё и смотрят, однозначно
      надо садиться на диван — он не доставит фалангам удовольствия наблюдать
      замешательство.</p>

    <p>Если не грызть подушки и
      рассуждать здраво, попытка применения ПН4 именно к
      Андрею должна восприниматься заинтересованными наблюдателями как естественное
      развитие ситуации. Не нынешней, с нелегальным заказом вируса в Медкорпусе, а
      гораздо более давней и <i>гораздо более</i> неприятной.</p>

    <p>Тем более не следует быть
      предсказуемым и естественно развивающимся.</p>

    <p>Андрей предположил, что до очной
      ставки есть ещё время — как же, он же сейчас впадёт в истерику от сообщённого
      фалангой, начнёт-таки грызть предусмотрительно раскиданные по дивану подушки,
      накрутит себя ожиданием, и <i>вот тогда</i> подсматривать его встречу с гэбней будет действительно занимательно.</p>

    <p>Вместо этого он устроился на
      диване поудобнее и с показательным умиротворением прикрыл глаза.</p>

    <p>&nbsp;</p>

    <p>Всё началось с Колошмы.</p>

    <p>То есть до Колошмы тоже кое-что
      было — успешная служба ревизором в бедроградском отделении Медкорпуса,
      покровительство одного из голов Медицинской гэбни, какие-то туманные, но
      манящие перспективы. Потом, через несколько лет Андрей понял: его в Медицинскую
      гэбню и готовили, поэтому так настойчиво убеждали получить полноценное
      образование. Гениального медика из него бы не вышло, но как раз такие и без
      него имеются в Медицинской гэбне, нужен был толковый администратор.</p>

    <p>Нашли другого, потому что Андрея
      в восемнадцать лет спешно повысили до гэбни Колошмы — фаланги захотели убрать
      его подальше от медиков, переориентировать на иной род деятельности.
      Самонадеянно полагать, что дело было лично в нём — совсем молодом служащем,
      только-только из отряда, но подающем масштабные надежды. Да нет, просто гадость
      Медицинской гэбне, просто грызня, в которой хороши все средства, даже банальное
      переманивание кадров.</p>

    <p>А на Колошме был Юр Саввович, он
      же Начальник, он же Савьюр (невероятно остроумное прозвище для тюремщика, чьи
      заключённые заняты сбором наркотических трав). И, прослужив год в одной гэбне с
      ним, Андрей навсегда испоганил себе репутацию.</p>

    <p>Савьюр ходил по краю — фактически
      правил колонией по своему разумению, называясь головой гэбни только ради
      приличия. Ему многое позволяли, ведь Колошма далеко, на Колошме всегда какие-то
      неприятности, Колошма важна для страны, но никто не хочет ей заниматься — а
      Савьюр занимался и вполне недурно, хотя больше половины его действий были
      небезупречны с точки зрения служебных инструкций. Одни смерти «раскаявшихся и
      раздавленных грузом вины» чего стоят.</p>

    <p>Теперь, после многих лет службы в
      Бедроградской гэбне, больше всего в этих смертях Андрея смущало то, что Савьюр
      никогда не марал рук — просил охрану или вообще давал пистолет самим
      раскаявшимся. Гуманист, не поклонник насилия, тьфу.</p>

    <p>Тот, кто ходит по краю, обязательно
      сорвётся, не может не.</p>

    <p>С Савьюром это случилось прямо на
      глазах у Андрея — появился заключённый №66563, и их с Начальником Колошмы
      взаимодействие имело чудовищно скандальные последствия. Нарушено было всё, что
      можно нарушить, и узнал об этом — по слухам, но большего и не требуется, — едва
      ли не весь госаппарат.</p>

    <p>Андрей тогда оказался в шатком положении,
      когда вся дальнейшая жизнь зависит от того, сумеешь ли ты правильно
      сориентироваться в обстоятельствах, которые не подождут и не дадут второго
      шанса. Андрею казалось, что он сумел: нелепую, скорее всего, действительно
      случайную — но кто бы в неё поверил! — смерть Савьюра взял на себя 66563, что
      было удобно во всех отношениях. Никого больше не беспокоило ни кто надоумил
      преданного охранника застрелить Начальника Колошмы, ни за что же сидит 66563,
      от бестолковости изначального обвинения которого слёзы на глаза наворачивались.</p>

    <p>Над изначальным обвинением Андрей
      тоже поработал, превратил его в нечто более подходящее для пожизненного
      заключения, уже прописанного во всех документах. «Идеологическое руководство
      попыткой подрыва Первого Большого Переворота» звучало, конечно, громко,
      внушительно и крайне политично, только вот за всей этой мишурой отлично
      просматривалась совершеннейшая профессиональная беспомощность обвинителей.
      Идеологическое руководство — даже если бы оно действительно имело место в том
      случае, а не являлось фантазией Столичной гэбни, — доказать раз и навсегда
      невозможно, тут есть пространство для инсинуаций. А пожизненное заключение, раз
      уж к нему приговорили, должно иметь твёрдые основания.</p>

    <p>Такими основаниями стали доселе
      неизвестные госаппарату (и даже самой Медицинской гэбне!) сведения о гормональных
      экспериментах по контролю над уровнем агрессии, кои в начале 50-х нелегально
      проводились над группой лиц, в дальнейшем состоявших в некоей
      контрреволюционной группировке. Связи 66563 с этими лицами, в отличие от связей
      его с террористами-подрывниками, в подтверждении не нуждались, так как оная
      группировка зародилась в студенческой среде истфака БГУ им. Набедренных, где и
      работал 66563 до заключения на Колошме.</p>

    <p>Андрею оставалось только найти — или
      сфабриковать — подтверждение того, что 66563 был в курсе давних опытов по
      контролируемой агрессии и сознательно использовал гормонально и психически
      нестабильных людей для достижения своих антигосударственных целей. А его
      сомнительная причастность к попытке подрыва Первого Большого стала уже менее
      существенным вопросом, когда всплыла информация о студенческом
      контрреволюционном движении, медицинских предпосылках оного и роли во всём этом
      66563.</p>

    <p>Профессионализм и темпы Андрея
      оценили — его вызвало аж Бюро Патентов, поблагодарило за погашение одним махом
      нескольких, хоть и тесно переплетённых друг с другом скандалов, назначило на
      службу в Бедроградскую гэбню, но —</p>

    <p>Всегда находятся какие-нибудь
      «но», способные всё испортить.</p>

    <p>Бюро Патентов решило, что
      большинству из тех, кому по уровню доступа положено знать, что 66563 сидел на
      Колошме (публичная фигура, для народонаселения — и вовсе пропал в экспедиции на
      другом континенте), теперь стоит думать, что за Савьюра его расстреляли.
      Расстреливать на самом деле не собирались — 66563 согласился считаться виновным
      добровольно, а Бюро Патентов понимает <i>всё</i>.</p>

    <p>Но история Начальника Колошмы
      должна была стать страшной сказкой для госслужащих, поэтому официально — всё
      плохо, все умерли, всех расстреляли.</p>

    <p>Правдивая информация о 66563 была
      у Бюро Патентов, у Хикеракли с его вторым уровнем доступа за революционные
      заслуги, у фаланг, у перенабранной гэбни Колошмы и у
      Андрея, поскольку он продолжал заниматься изначальным обвинением 66563 после
      официального расстрела оного.</p>

    <p>В общем, вскоре Андрей оказался в
      Бедроградской гэбне, не имевшей права знать, что мёртвый 66563 продолжает
      отбывать пожизненное заключение. Вроде бы ерунда, вроде бы и не входит это в
      компетенцию Бедроградской гэбни, но Андрею всё равно было не по себе. Хорошая,
      правильная синхронизация, нормальная, настоящая гэбня, а не пародия времён
      работы с Савьюром, — и государственная тайна, которую никак нельзя рассказать.</p>

    <p>И ведь не расскажешь своей гэбне
      то, что знаешь, —&nbsp;получается нарушение фактической синхронизации.
      Расскажешь —&nbsp;выдашь информацию, не полагающуюся им по уровню доступа, им
      же самим за это потом прилететь может.</p>

    <p>Безвыигрышная
      ситуация.</p>

    <p>Тайна жгла и мешала, заставляла
      злиться на Бюро Патентов, сначала просто так, а потом и по делу: в семьдесят
      четвёртом году из-под земли выскочил Хикеракли и посадил в БГУ им. Набедренных
      собственную гэбню с таким же уровнем доступа, как у Бедроградской и Портовой. С
      апломбом заявил: последняя, мол, воля мёртвого 66563, вырастившего и
      выкормившего половину истфака, а он, Хикеракли, мёртвого 66563 одобрял и никак
      не может не поспособствовать исполнению последней воли.</p>

    <p>Бедроградская гэбня возмутилась —
      ещё одна власть в городе, куда это годится.</p>

    <p>А Андрею стало очень, очень
      нехорошо наедине с государственной тайной.</p>

    <p>Дальше — больше и хуже: сначала
      останавливают на улице случайного студента, а у него на руках — расшифровки
      бесед 66563 с Савьюром. Не расшифровки допросов даже, а другие, совсем
      провокационные — из камеры, где они вдвоём заперлись, когда всё зашло уже
      слишком далеко.</p>

    <p>Откуда? Какого лешего? На такое
      не замахивались и ночные кошмары Андрея — ладно бы ещё какая-нибудь из гэбен,
      ладно бы фактически безработный наркоман Стас Никитич — он тоже был при Савьюре
      в гэбне Колошмы, самолично расшифровывал те аудиозаписи, мог прикарманить
      копию, но вот так — не обнародованные пока для госслужащих материалы в сумке у
      произвольного человека?</p>

    <p>Произвольный человек на самом
      деле оказался не очень-то и произвольным. На все вопросы о бумагах он молчал с
      упорством, достойным лучшего применения, но догадаться было несложно: просто
      актуальный на тот момент мальчик 66563, у которого от его исчезновения
      приключилась истерика.</p>

    <p>Не знай Андрей наизусть поганых
      расшифровок из камеры, где и про этого мальчика была пара слов, он бы голову
      себе сломал, пытаясь понять, какого лешего гражданский с простым истфаковским уровнем доступа полез в политику.</p>

    <p>Студента карала Бедроградская
      гэбня, Андрей настоял. Сказал, что для острастки, за ношение бумаг
      неподобающего уровня доступа и в назидание Университету — пусть следят за
      своими людьми получше, раз уж ввязались.</p>

    <p>Вдобавок хорошо накололи
      университетских по мелочи: соврали, что за расшифровки студенту положен
      расстрел, а те и купились. Разобрались потом,
      конечно, но тогдашний уровень компетенции <i>уже</i> был продемонстрирован. Университетские устыдились — и после этого сели, наконец,
      штудировать кодексы и инструкции. Бедроградская гэбня добилась, чего хотела:
      самонадеянные детишки, целый год просто так щеголявшие на кармане жетонами
      шестого уровня доступа, взялись за ум. Стоило всего-навсего громко посадить
      одного их студента.</p>

    <p>По-хорошему так всё и было, вот
      только посадили студента на Колошму, и не просто на Колошму, а —&nbsp;по
      личному указанию Андрея — в одиночную камеру всё ещё живого 66563.</p>

    <p>Привычка придерживать запасные
      варианты и рычажки воздействия въедается на всю жизнь. Устоять перед искушением
      заиметь ещё рычажок у Андрея не вышло.</p>

    <p>Потому что 66563 многое мог. И
      леший даже с разнесчастным «развалом Колошмы» — он же прямо из далёкой степи руками
      Хикеракли посадил Университетскую гэбню! А тот рычажок — это в некотором роде
      подарок; всем людям приятно, когда им делают подарки, все потом готовы за них
      отблагодарить, если случай представится.</p>

    <p>Представился уже через год:
      Университет устроил в городе глупую шумиху с борделями — а бордели ведь после
      Революции были запрещены личным указом самого Набедренных как статическая
      структура неравенства.</p>

    <p>С тем, что Порту бордели дороже
      Набедренных, все кое-как смирились — закрытая территория, рядовые жители
      Всероссийского Соседства там не прогуливаются, а если и прогуливаются, то
      только такие, которых двойными идеологическими стандартами не удивишь. А вот
      когда ни с того ни с сего студенты БГУ им. Набедренных массово понадевали ошейники
      — отличительный признак и главный
      атрибут работников дореволюционных салонов с эротическими услугами, — рядовые
      жители города Бедрограда смутились.</p>

    <p>Бедроградская гэбня намекнула
      Университету: ну что вы делаете, ну раз уж у ваших студентов есть ошейники, так
      одёрните их за поводок, пусть не пугают мирных жителей. Университет упёрся —
      после случая с мальчиком 66563 они любой косой взгляд в сторону своих студентов
      воспринимали как прямые военные действия.</p>

    <p>Военные действия и начались.
      Подавили друг на друга, подавили, а потом приходит сводка: Университет — из
      банального гадства — открывает собственный бордель,
      да ещё и не в Порту где-нибудь, а прямо в городе! И Бюро Патентов не возражает,
      их ублажили идеологической писулькой из неопубликованных черновиков 66563, а
      идеологические писульки 66563 одобрял недавно таки скончавшийся уже наконец
      Хикеракли, Бюро Патентов к памяти Хикеракли предвзято...</p>

    <p>Все, все кругом предвзяты, у всех
      идеологические писульки и игры в риторику на основе постулатов
      Революционного Комитета, а
      авторитет перед собственными горожанами стремительно падает не у всех, а только
      у Бедроградской гэбни!</p>

    <p>Простые люди далеки от
      идеологических тонкостей и политики, они знают только, что бордели Набедренных
      осуждал, а в Бедрограде бордель вдруг появился вслед за эпатажной студенческой
      модой. И всё возмущение, недовольство и недоверие — городской власти,
      университетской-то власти для простых людей не существует.</p>

    <p>В общем, надо было как-то
      разбираться с Университетом, насмехающимся надо всем Бедроградом и его гэбней в
      первую очередь.</p>

    <p>Андрей что-то соврал и поехал на
      Колошму.</p>

    <p>Мёртвый 66563 с неприкрытым
      весельем в глазах слушал про эпическую битву за бордели и качал головой, не
      желал помогать. Говорил, что ничего он с Колошмы сделать не может, а даже если
      и мог бы —</p>

    <p>Как раз для «даже если и мог бы»
      у Андрея был припасён рычажок. А в придачу — пробная модель устройства, записывающего
      изображение.</p>

    <p>С неделю 66563 удалённо любовался
      на разнообразные методы физического и психологического воздействия, применяемые
      к его мальчику, и таки сдался, породил план потопления Университетской гэбни.</p>

    <p>У Бедроградской гэбни имелись,
      конечно, свои собственные представления о потоплениях, но все эти
      университетские — они же совершенно ненормальные, у них голова по-другому
      работает, и Андрей был уверен, что топить их надо по-другому, по-университетски.</p>

    <p>Стоило ему узнать мнение 66563 о
      том, как именно, — пронёсся слух о степной чуме. Всего один подозрительный
      больной, но Зиновий Леонидович, самый старший голова гэбни Колошмы, который
      остался там даже после истории с Савьюром, видел предыдущую вспышку и ручался,
      что чума вернулась. Андрею нужно было как можно скорее в Бедроград — пришлось
      даже оставить своих младших служащих в колонии, собиравшейся вот-вот закрыть
      ворота по случаю опасности эпидемии, и подсаживаться в ночной товарняк в
      одиночку.</p>

    <p>Кошмар, глупость,
      непрофессионализм, рассинхронизация с собственной гэбней.</p>

    <p>Андрею был тогда двадцать один
      год, у него руки чесались воспользоваться подаренным Бюро Патентов правом на
      знание правды о 66563, найти наилучшее решение проблем с Университетом —</p>

    <p>…рассинхронизация,
      непрофессионализм, глупость, кошмар.</p>

    <p>Из-за вспышки степной чумы Зиновий
      Леонидович, самый старший и непреклонный голова гэбни Колошмы, не мог не
      передать медикам и фалангам списки лиц, находившихся на территории колонии в
      момент обнаружения заболевших.</p>

    <p>Бедроградская гэбня узнала.</p>

    <p>Андрей ждал, что к нему применят
      ПН4.</p>

    <p>Бахта спросил, не подохнут ли они
      вчетвером от заразы через пару дней.</p>

    <p>Соций спросил, не сожрёт ли
      Андрея за эту вылазку Бюро Патентов.</p>

    <p>Гошка ничего не спросил,
      выругался, съездил по морде и рассмеялся: «Ну что за младший отряд? Расслабься,
      проехали».</p>

    <p>План потопления Университета от
      66563 на практике оказался почти что ловушкой, пришлось всё-таки придумывать
      свой. 66563 с его мальчиком, к счастью, сгорели в чумном изоляторе. Вопрос
      наличия у Андрея сведений, не положенных по уровню доступа Бедроградской гэбне,
      рассосался сам собой: Бюро Патентов плюнуло, гэбня простила.</p>

    <p>Сидя через семь лет на гэбенном
      диване в резиденции фаланг в ожидании очной ставки, Андрей никак не мог понять,
      почему, почему, почему —</p>

    <p>Почему Бедроградская гэбня
      простила ему когда-то поганую Колошму и не простила сейчас внезапного
      исчезновения на несколько дней?</p>

    <p>Почему ПН4?</p>

    <p>&nbsp;</p>

    <p>— Да потому что нечего было
      исчезать в Медкорпусе, все же знают про твой детский вялотекущий роман с
      Медицинской гэбней! Первая любовь, блядь, — Гошка высказался с такой натуральной
      обидой в голосе, что Андрея передёрнуло. Неужто и впрямь можно было подумать —</p>

    <p>«Головной убор — заказ второй
      твой — степь болезнь», — ногой, скрываясь от прослушиваний и подглядываний,
      сообщил Соций.</p>

    <p>Андрей, как и остальные три
      головы Бедроградской гэбни, без каких-либо трудностей использовал гэбенный служебный тактильный код, но сейчас он вдруг
      засомневался в своих талантах перевода с языка ног на росский.</p>

    <p>Второй заказ у Шапки — степная
      чума?!</p>

    <p>Что за чушь, зачем она нужна, её
      же не лечат, ей не заразишь Бедроград на пару дней, чтоб потом вернуть всё
      обратно как было, только уже без Университета!</p>

    <p>«Неправда», — коротко отозвалась
      нога Андрея.</p>

    <p>На лице он изобразил самые
      непонимающие глаза, на которые был способен.</p>

    <p>Просить степную чуму у Шапки?</p>

    <p>Чушь, чушь-то какая отборная.
      Шапка профессионал, возможно, гений, но не бог же! Никто ещё не приблизился к
      разгадке структуры степной чумы ни на шаг, хотя столько пытались, какой к
      лешему заказ?</p>

    <p>Сидели они на том самом круглом диване, на
      котором так легко видеть и касаться сразу всех. Это было хорошо и плохо
      одновременно: так похоже на то, что было раньше, ещё на прошлой неделе было, —
      и так <i>иначе</i>.</p>

    <p>Соций справа, служба в армии
      развивает неведомое мирному населению пространственное мышление — мгновенно
      оценил расположение подглядывающих устройств относительно дивана и выбрал
      наименее просматривающийся угол. Он всегда говорит коротко и по делу, ему и
      объяснять ногами, что происходит.</p>

    <p>Бахта слева, тактильный код не
      использует, но через пару минут таки придвинулся, прилип плечом. Кажется,
      действительно хочет верить Андрею, а не ломает спектакль для фаланг.</p>

    <p>Гошка напротив.</p>

    <p>Сидя напротив тоже запросто можно
      касаться, для того и проектировали в конце двадцатых гэбенные диваны, но — не
      касается. Непривычно подобрал ноги, избегает любого контакта и много, много
      говорит. То ли наказывает так за какие-то несуществующие промахи, то ли ему
      отчего-то сложнее всех, то ли сам не определился.</p>

    <p>Андрей вздохнул.</p>

    <p>— Я знаю, что мои связи с
      Медицинской гэбней всегда были сомнительны, — посмотреть как можно серьёзнее.
      Сейчас он изложит легенду, которую скармливал все эти дни фалангам, и остальная
      Бедроградская гэбня должна сразу сообразить и встроиться, иначе ложь выплывет.
      — Но кем бы я был, если бы меня не отвратили эти их безалаберные проекты
      контролируемого заражения? И ладно бы хотели проводить эксперименты в Столице,
      но нет, им надо обязательно сунуться в <i>наш</i> город! Вы же с самого начала знали, хоть и не одобряли: всё, что я делал, — я
      делал, чтобы избежать в будущем паники среди служащих, подготовить их. И да,
      можете продолжать считать, что я перестраховываюсь по сорок восемь раз,
      продолжать твердить, что пора лечиться… Гошка, можешь выжечь мне на всю спину
      клеймо «параноик», я не против, но не подозревай же меня в <i>таком абсурдном</i> предательстве! Кто ещё после этого параноик,
      спрашивается?</p>

    <p>Бедроградская гэбня слушала внимательно.
      Делала усталые, раздражённые лица для подглядывающих фаланг, но Андрей
      чувствовал — слушала, впитывала каждое слово.</p>

    <p>— Про клеймо я запомню, —
      невесело усмехнулся Гошка. — Правильно говоришь: перестраховываешься по сорок восемь
      раз, пора лечиться, пора-пора. И что, леший тебя дери, мы должны думать, когда
      ты не отзванивался из Столицы который день? Что коридоры Медкорпуса излечили
      вдруг твою паранойю своим святым стерильным сиянием?</p>

    <p>— Я с вечера субботы сидел под
      замком у фаланг! — немножко невпопад возмутился Андрей, чтобы Бедроградская
      гэбня точно знала, где он прохлаждался. — Вряд ли мне дали бы приблизиться к
      телефону, даже если бы я очень попросил.</p>

    <p>А вот это уже, к несчастью,
      неправда.</p>

    <p>— А похлопать глазками? — Гошку
      перекосило. — Для фаланг твои фирменные глазки недостаточно проникновенны?</p>

    <p>Для фаланг — не пробовал, а вот
      морально нестойкий Силовой Комитет пал жертвой Андреевых глазок не только в
      поезде Столица-Бедроград.</p>

    <p>Вечером воскресенья его
      подпустили к телефону на полминуты. Против всех указаний, потому только, что он
      нашёл к правильному человеку правильный подход. Но как бы того ни хотелось,
      Бедроградской гэбне звонить было глупо и неосмотрительно, фаланги взбесились
      бы, если б всё-таки засекли звонок. Но возможность надо, обязательно надо было
      как-то разумно использовать, и Андрей использовал.</p>

    <p>Казалось — разумно; а теперь,
      после того как за исчезновение на пару дней и за какой-то мифический заказ степной
      чумы на него свалилось ПН4, стало ясно: ему ведь не поверят! Собственная гэбня
      уже не поверит, что это была не попытка подставить, а совсем наоборот.</p>

    <p>Поэтому придётся молчать о
      звонке, пока-то точно. А молчать, умалчивать, недоговаривать так не хочется,
      хочется — как раньше, как всегда.</p>

    <p>Но ведь не поверят, точно не
      поверят, нет. Что хотелось, как лучше, что мелькнула возможность —</p>

    <p>Да какого лешего она мелькнула?
      Без неё сейчас на душе было бы настолько легче.</p>

    <p>Иногда Андрей ненавидел свои
      «фирменные глазки» и талант делать людям приятно.</p>

    <p>«Склады — пусто», — требовательно
      оборвала поток его самобичеваний нога Соция.</p>

    <p>Склады, склады.</p>

    <p>— И да, я должен сказать, —
      Андрей опустил голову. — Фаланги слышали какую-то часть моего разговора с
      Шапкой. Они засомневались в правомочности моих действий, стращали эпидемией,
      паникой, развалом экономики — как будто этого всего бы не было, если б
      Медицинская гэбня осуществила свои намерения! У нас всяко больше шансов
      удержать ситуацию под контролем…</p>

    <p>— Что фаланги понимают в управлении
      городом, — перебил его не самым содержательным замечанием Бахта.</p>

    <p>Это сигнал: не дави и нарочито не
      повторяйся, твою легенду мы уже усвоили.</p>

    <p>— Короче говоря, местонахождение
      запасов вируса и лекарств пришлось сдать.</p>

    <p>Легенда для фаланг получилась не
      самая безупречная, но с учётом обстоятельств поимки Андрея ничего лучше соврать
      было нельзя. Разговор с Шапкой слышали, записывали, а там звучал вирус, <i>уже</i> сделанный для «контролируемого
      заражения служебных зданий Бедроградской гэбни в учебных целях», коим он ещё в
      начале года объяснял Шапке столь специфический заказ.</p>

    <p>Фаланги давили: для чего — одно,
      зачем — другое. Зачем заражать служебные здания Бедроградской гэбни и
      подвергать опасности город? Опасность невелика, но ведь есть.</p>

    <p>Андрей понимал — даже если вирус
      уже не получится использовать против Университета, правду говорить не стоит ни
      в коем случае. Форменным самоубийством для всего нынешнего состава
      Бедроградской гэбни было бы рассказать фалангам, что заражать планировалось не
      служебные здания, а жилые, и цель всего этого — в окончательной победе над
      зарвавшимся Университетом.</p>

    <p>Чуть менее самоубийственно, но
      тоже из рук вон плохо, было бы взять всю вину на себя лично, покаяться, что
      Андрей зачем бы то ни было заказывал вирус в тайне от собственной гэбни. Он
      даже был бы готов пойти на такое — если б карательные меры за непозволительную
      рассинхронизацию по закону должны были бы применяться только к нему, а не ко
      всей Бедроградской гэбне.</p>

    <p>Оставалось одно: выставить проект
      контролируемого заражения служебных зданий своей личной инициативой, которую со
      скрипом, но таки поддержала гэбня.</p>

    <p>Смысл? Андрей <i>действительно</i> предпочитает по сорок восемь
      раз перестраховываться, это даже значится в его досье. Ещё там значится, что у
      него неплохая медицинская подготовка и связи в Медкорпусе. А Медицинская гэбня <i>действительно</i> склонна экспериментировать
      на максимально приближенных к реальным условиям площадках — и это наверняка
      значится уже в их досье.</p>

    <p>Складываем два и два, получаем
      какую-никакую, но легенду: связи Андрея в Медкорпусе будто бы донесли до него
      неофициальную, сомнительную, но одновременно правдоподобную сплетню, что
      Медицинская гэбня собирается пробивать у Бюро Патентов право устроить локальную
      эпидемию смертельного вирусного заболевания. Моделировать экстремальную
      ситуацию, считать свои показатели и делать другие невероятно важные для науки
      вещи. И будто бы поговаривают — так же неофициально, в курилках после
      одиннадцати вечера, — что эпидемия грозит не какому-нибудь захолустью, а
      Бедрограду. Не гражданским, конечно, — служащим на территории Бедроградской
      гэбни.</p>

    <p>Дальше — паранойя Андрея, опасная
      идея провести пробное заражение самим, на практике обучить служащих действиям в
      ситуации эпидемии. И тяжкие, укоризненные, но всё-таки согласные после
      бесконечных уговоров вздохи остальных голов гэбни.</p>

    <p>Всё это писано плугом по болоту,
      но ничего хитрее Андрей придумать не смог.</p>

    <p>Неужто за недостаточную
      изворотливость он заслужил ПН4 и всё ещё отодвинутые подальше ноги Гошки?</p>

    <p>— Вчера Бахта столкнулся на
      складе под Жлобинкой с силовиками. Они к лешему
      разгромили хранилище, но ничего не нашли. Там <i>уже</i> не было вируса! — Гошка почти крикнул, почти сорвался, но Соций
      опустил свою тяжёлую руку ему на плечо. Хотел дождаться объяснений.</p>

    <p>Объяснения у Андрея были, но
      поверят ли в них?</p>

    <p>Андрей мстительно фыркнул в
      сторону подглядывающих устройств и, не пытаясь быть для своих — или уже не
      своих? — ни убедительным, ни образцово-приятным, ни хлопающим глазами, устало и
      честно сказал:</p>

    <p>— Провокация. Меня три дня
      раскалывали, не верили в подготовительное учебное заражение — как будто вчера
      из печи вышли и Медицинской гэбни не видели! Хотели услышать какую-то другую
      правду, пострашнее. Пугали, что гэбня от меня откажется, раз я попался на
      смертельном вирусе. А не откажется сама — грозились помочь. В моих вещах были
      ключи от складов, я признался фалангам, что это они, а те помахали связкой у
      меня перед носом. Сказали, что сначала конфискуют всё ночью втихаря, а утром
      заявятся с официальной проверкой, показательно ничего не найдут и посмотрят,
      как после этого запоёт Бедроградская гэбня, станет ли меня выгораживать.</p>

    <p>Под конец тирады он не удержался
      и прикрыл-таки глаза.</p>

    <p>Пожалуйста, пусть для фаланг это
      будет выглядеть хотя бы измотанностью. Очень не
      хотелось бы демонстрировать посторонним, что Андрею сейчас попросту страшно
      взглянуть на Бедроградскую гэбню, увидеть очередные сомнения на лицах,
      недоверчивые усмешки и все прочие мыслимые признаки того, что отклонённое по
      бумагам ПН4 на деле ещё в силе.</p>

    <p>Бахта крепче навалился плечом, у
      Соция напряглась и мгновенно расслабилась обратно нога. Несчастную синхронизацию
      не проведёшь, зажмурившись: есть ещё пульс, дыхание, много чего другого, что
      можно почувствовать кожей. Они психуют, буквально взбешены, очень-очень злы, но
      —</p>

    <p>…не на Андрея?!</p>

    <p>— Падлы, — сдержанно, буднично
      заявил Гошка.</p>

    <p>— У всех свои методы работы, —
      будто бы возразил Соций, — но у некоторых они на удивление блядские.</p>

    <p>— Коноеды хуевы, — светски
      добавил Бахта, в упор глядя на зрачок записывающего изображение устройства над
      окном.</p>

    <p>Оказывается, открывать глаза не
      очень-то и страшно.</p>

    <p>— Как они тебя взяли-то? — Соций
      привычно положил руку на спинку дивана за Андреем. Можно откинуться, опереться
      и попробовать успокоиться.</p>

    <p>— Прямо в Медкорпусе, — отпускало
      медленно, деловитый тон пока не хотел уходить, но всё-таки отпускало. —
      Говорили с Шапкой в пустой лаборатории у шумного аппарата, только вышли за
      дверь, как наткнулись на «проверку пропусков». Я ведь даже не сообразил сразу,
      что говорю с фалангой, не ожидал. Потом прошли в соседний кабинет, а там идёт непрерывная
      запись звука, производимого тем шумным аппаратом.</p>

    <p>— Ловко, — присвистнул Бахта.</p>

    <p>— Была какая-то наводка, не могло
      не быть, — согласился Андрей, неуверенно пристраиваясь к плечу Бахты поудобнее.
      — Не факт, что сам Шапка…</p>

    <p>— А другой клиент был? — Соций
      протянул Андрею пачку, дождался, пока тот возьмёт сигарету, и сам поднёс
      подожжённую спичку. — Тот, про которого ты говорил перед отъездом? А то Шапка
      чирикал, что клиента нет, телеграмм с вызовом нет, а ты к нему попёрся сам и по
      другому поводу.</p>

    <p>За степной чумой-то?</p>

    <p>Андрей ещё раз поразился
      абсурдности такого вранья.</p>

    <p>— Телеграмму я брал с собой в
      Столицу, хотел удостовериться, Шапка ли её посылал. Должна быть среди
      конфискованных личных вещей, если их вдруг не утилизовали.
      Что касается клиента, про него Шапка не распространялся, только уклончиво
      твердил о бóльших деньгах и лучших гарантиях
      анонимности своего участия. Зато я сглупил, несколько раз прямо повторил на
      запись про контролируемое заражение, про то, что от Бедроградской-то гэбни у
      него тоже неплохие гарантии, — Андрей притормозил, задумавшись над одним и тем
      же вопросом уже в который раз за последние дни. — Не знаю, не прослушав хотя бы
      один раз запись нашего разговора, я не могу сказать, подводил меня Шапка
      специально к компромату или нет. Он всегда говорит медленно, берёт большие
      паузы, будто нарочно испытывает терпение, а вроде как на самом деле росский
      недоучил.</p>

    <p>— Мог он из патриотизма и прочего
      врачебного долга таки стукнуть своей гэбне, что мы хотим подпортить им чистоту
      эксперимента на нашей территории? — для вида вернулся к Андреевой легенде
      Соций.</p>

    <p>Андрей сокрушённо покачал
      головой:</p>

    <p>— Я уже ничего не знаю, ни-че-го. Но ловили-то меня фаланги, а не медики.</p>

    <p>— Коноеды хуевы, — повторил свой
      коронный номер Бахта, которого всё это время продолжало потряхивать от злости.</p>

    <p>Руки Гошки были сложены на груди
      в положении «мы не сдадимся, ебитесь», но смотрел он куда-то в пустоту,
      сосредоточенно и несколько неуверенно, если слово «неуверенно» вообще можно
      применить к Гошке. Андрея терзало смутное предположение, что Гошке стыдно за
      ПН4, но слово «стыдно» ему тоже не очень-то подходило.</p>

    <p>Хоть бы прикоснулся разок,
      последний бастион отчуждения.</p>

    <p>— Расскажи уж нам тогда, —
      перехватил он взгляд Андрея, — кто такой Дмитрий Ройш.</p>

    <p>Леший, леший, леший.</p>

    <p>Столько сложностей, столько
      поводов для недоверия, разгрести их всей жизни не хватит.</p>

    <p>— Я не знаю. Про Дмитрия Ройша
      меня расспрашивали всё это время, но не знаю, не знаю, не знаю я этого
      человека!</p>

    <p>«Подозрение — сложно — не здесь»,
      — быстро добавил Андрей обеими ногами одновременно — для Соция и Бахты. Третьей
      ноги у человеческого организма не предусмотрено, так что извини, бастион
      отчуждения.</p>

    <p>Заметив Андреев манёвр, Гошка в
      очередной раз невесело усмехнулся, стряхнул с себя статичную позу и всё так же
      невесело протянул руку — покровительственно потрепать Андрея по голове.</p>

    <p>Иногда он совсем такой же, как
      давешние самодовольные остолопы с автоматами, покупающиеся на тривиальное
      хлопанье глазками. Только это неважно, потому что —</p>

    <p>— Без гэбни погано, — выдохнул
      неожиданно для себя Андрей.</p>

    <p>А с гэбней так правильно и так
      спокойно, что все Дмитрии Ройши кажутся абсурдным сонным бредом, не имеющим ни
      малейшего отношения к реальности.</p>

    <p>— И ведь непонятно, когда и на
      каких условиях тебя выпустят, — щёлкнул языком Соций, слишком быстро вернув
      Андрея к проблемам сегодняшнего дня.</p>

    <p>— Выходим и через полчаса штурмуем
      резиденцию фаланг? — засмеялся Бахта.</p>

    <p>— Хорошо бы, — очень честно
      ответил Андрей. — Хочу пострелять, сбить запах <i>их</i> табака и в своё такси.</p>

    <p>— В ремонте оно, Бахта побил в приступе
      взбурлившего таврского гнева, — Гошка теперь держался вроде и как обычно, со
      стороны вообще не заметишь ничего странного, но гэбня — это не со стороны.</p>

    <p>«Неправда — палево — месяцы
      несколько», — срочно уточнил ногой Бахта, который, как и Андрей, не любил шуток
      про поломки такси.</p>

    <p>Так. Они без Андрея ездили на
      какое-то нечистое дело, да ещё и на его такси, которому теперь отстаиваться в
      гараже хотя бы пару месяцев?</p>

    <p>Жизнь полна мелких пакостей и
      вечных «но», способных подпортить любое настроение.</p>

    <p>Интересно, что за дело хоть было?
      Если бы Андрея таки выпустили в обозримом будущем, ему бы очень не хотелось
      страдать абы за что за неродным рулём. Но спросить нельзя — ногами подробностей
      не расскажешь, а кругом подглядывают и подслушивают.</p>

    <p>Неужели так и не узнает? Хотя
      какая разница, ведь не выпустят же всё равно —</p>

    <p>…не выпустят, не за что
      выпускать, незачем!</p>

    <p>В тоске уставившись на
      подглядывающий зрачок, Андрей опять вспомнил, где подцепил эту идею для
      Бедроградского Инженерного Бюро — всё на той же поганой Колошме, когда
      бесконечно трахавшиеся в камере 66563 и Савьюр весёлыми и по-дурацки
      счастливыми голосами обсуждали, что порнография на аудиозаписи антигуманна.
      66563 бредил про возможные прибыли с распространения внутри госаппарата
      зафиксированного изображения сношений заключённого с тюремщиком, а Андрей,
      прослушивая как-то в очередной раз записи этих бесед, вдруг осознал, что у него
      как головы Бедроградской гэбни есть теперь на побегушках целый штат
      высококвалифицированных инженеров, радиотехников, оптиков и всех остальных.
      Понаделали столько аппаратуры, что даже фалангам досталось.</p>

    <p>Леший с ними, с прибылями, но —</p>

    <p>— Ну значит, пока без своего
      такси, — заявил он в пространство, всё ещё размышляя над степенью абсурдности
      пришедшей на ум затеи. — Но следует понимать, что это серьёзнейшая утрата,
      которая требует не только моральной, но и физической компенсации.</p>

    <p>— Хочешь поотгрызать
      нам головы? — с сомнением осведомился Гошка.</p>

    <p>Андрей посмотрел на него,
      одновременно посмеиваясь и сожалея, что гэбня всё-таки не умеет читать мысли
      друг друга, а потому приходится иногда говорить омерзительные пошлости:</p>

    <p>— Головы — это слишком гуманно, —
      и быстрым движением переместился с дивана на пол, на колени.</p>

    <p>&nbsp;</p>

    <p>Когда в помещении вдруг зазвучало
      давным-давно скинутое с конференц-стола местное радио, Андрей уже почти не был
      в состоянии воспринимать человеческую речь, тем более из шипящего
      радиоприёмника.</p>

    <p>Всё-таки синхронизация — страшная
      штука, а право нейтрализации четвёртого — ещё страшнее, потому что трое на
      одного.</p>

    <p>Радио со второй попытки всё же
      пробилось через приятно бессмысленный гул в голове:</p>

    <p>— …а также табельное оружие гэбни
      города Бедрограда, личные вещи и жетон, удостоверяющий шестой уровень доступа к
      информации Зябликова Андрея Эдмундовича, ожидают владельцев в приёмной номер восемь
      на первом этаже.</p>

    <p>Андрей потянулся, не слишком
      разобрав даже, чьё из гэбни города Бедрограда плечо служило ему сейчас такой
      удобной подушкой. И, наслаждаясь сонной ленью, подумал, что мёртвый 66563 был
      всё-таки очень неглуп для гражданского.</p>
  </>
}

export default chapter10
