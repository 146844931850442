import React from 'react'
import { IBookPage } from './index'

const chapter15: IBookPage = {
  header: 'header_d6.jpg',
  title: 'Глава 15. Опорный конспект',
  prevLink: 'chapter14',
  nextLink: 'chapter16',
  content: <>
    <h2>Университет. Максим</h2>

    <img alt='' src="/images/15_maxim.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>На часах 14:22</p>

    <p>&nbsp;</p>

    <p>— Распишись, я отправляю.</p>

    <p>— Уже? Но Охрович и
      Краснокаменный в Хащине.</p>

    <p>— Охрович и Краснокаменный
      расписались с утра.</p>

    <p>— Вот, значит, как.</p>

    <p>— Что тебе не нравится? Мы не
      можем сейчас медлить.</p>

    <p>— Зато можем подписываться ни свет ни заря, не ставя меня в известность?
      Великолепно. Куда
      мы торопимся?</p>

    <p>— Выигрываем время у
      Бедроградской гэбни!</p>

    <p>— Ларий, запрос на встречу
      действителен сутки, и если…</p>

    <p>— Если откажутся, мы их сделали.
      Если согласятся, у них всё равно будет мало времени.</p>

    <p>— Это у нас будет мало времени,
      Ларий. Лекарство ведь ещё не готово?</p>

    <p>— Готово. То есть готово будет к
      вечеру, но тестовый образец-то сработал.</p>

    <p>— Нам нужны гарантии, а не один
      сработавший тестовый образец!</p>

    <p>— Дима сказал, что раз сработал
      даже сделанный раньше срока и кое-как…</p>

    <p>— Дима сказал?! Давайте, слушайте
      его. Тогда вообще всё будет раньше срока и кое-как.</p>

    <p>— Да остынь ты. Лекарство есть,
      есть шанс выиграть время. Подпишешь или нет?</p>

    <p>— Нет. Мы не можем пойти на
      очередной неоправданный риск.</p>

    <p>— Ох. Росчерк на бумаге — это всё
      же формальность. Пустая, но необходимая по протоколу.</p>

    <p>— Что ты имеешь в виду?</p>

    <p>— Утром ты слишком быстро ушёл на
      медфак после разговора с Ройшем…</p>

    <p>— И?</p>

    <p>— Максим… мы <i>уже</i> послали запрос телефонограммой.</p>

    <p>&nbsp;</p>

    <p>На часах была половина третьего,
      когда дверь истфака БГУ им. Набедренных оглушительно
      хлопнула за спиной Максима. Удар мокрым ветром в лицо не помог, не вернул ни
      спокойствия, ни ясности мысли. Подошвы чуть скользили по не просыхающей который
      день брусчатке, пытались замедлить бегство. Если даже треклятые подошвы не
      хотят соглашаться с тем, что делает их хозяин, чего можно ожидать от людей?
      Какой-то случайный прохожий, едва не сбитый Максимом с ног, возмутился:
      «ненормальный!».</p>

    <p>&nbsp;</p>

    <p><i><u>Ненормальный</u></i></p>

    <p>&nbsp;</p>

    <p>Прекрасная отговорка,
      универсальный ответ на любой вопрос. Ненормальный уровень внутренней агрессии:
      вспыхиваешь вдруг яростью, слепнешь и глохнешь, клокочешь изнутри и не внимаешь
      разумным советам. Если разумные советчики осведомлены о твоей маленькой
      гормональной проблеме, они обязательно вежливо смолчат, а про себя вздохнут:
      ненормальный, ничего не попишешь, надо относиться с пониманием.</p>

    <p>Это «понимание» злит ещё больше,
      потому что понимать нечего. Медицина не относится к точным наукам, она имеет
      дело не с фундаментальными законами, а с суммой частностей, поведение которых
      принято считать предсказуемым. Принято, но не более того.</p>

    <p>Тот факт, что в 51-м году одна
      небольшая лаборатория медикаментозными методами пыталась повысить уровень
      внутренней агрессии у некоторого количества младенцев, ещё не означает, что она
      добилась значимых результатов. А тот факт, что эксперимент был вскоре пущен на
      самотёк, поскольку всем частным лабораториям пришлось присоединиться к
      Медкорпусу и не все из них хотели обнародовать свои прежние проекты, ещё не
      означает, что последствия были плачевны.</p>

    <p>В начале 74-го эти факты
      вынырнули вдруг из бюрократического забвения, подарив всем желающим прекрасную
      ширму из ложных выводов, дабы отгораживаться ей от реальной действительности.
      Почему группа студентов 51-го года рождения неоднократно была замечена в
      экстремистских действиях? Потому что ненормальные, почему же ещё.</p>

    <p>Габриэль — в 74-м не Габриэль,
      научное руководство и разница в статусе подразумевают иной коммуникативный код
      — спросил тогда: «Вы действительно полагаете, что диагноз хуже приговора?»</p>

    <p>Максим выпалил зачем-то: «Диагноз
      снимает ответственность».</p>

    <p>«А у вас хватило бы духу нести её
      вплоть до приговора? — Габриэль смотрел испытующе, почти зло. — Все хотят
      думать, что у них хватило бы, но на практике хватает только считанным единицам.
      Прочим же остаётся жить и радоваться, что за них заплатил кто-то другой».</p>

    <p>В 74-м Максим проглотил, как
      проглатывал всегда, когда разговор заходил о «ком-то другом». Два дня назад
      глотать надоело, и два дня подряд приходится ночевать не на Поплеевской, а на
      другом конце города в своей квартире, слушать тишину и спать, не раздеваясь.</p>

    <p>&nbsp;</p>

    <p>…От истфака до своей квартиры —
      дом 47 по Бывшей Конной Дороге — легко добраться автопоездом с
      Большого Скопнического. Максиму не нужно служебное такси, он доедет сам, он
      оставил все такси и всех водителей нуждающимся: у них чрезвычайная ситуация, у
      них всё время не хватает транспорта. У Максима нет чрезвычайной ситуации, он
      просто едет к себе, просто отдохнуть от дел среди бела дня, просто лечь лицом
      вниз и отключиться, просто выспаться наконец, раз
      Университету не требуется его присутствие. Ничего не случилось, Максим просто
      вспылил на пустом месте, просто не сдержался, ему просто надо побыть одному.</p>

    <p>Побыть одному во всех смыслах,
      потому что нет больше никаких сил делать вид, что он
      не один. Не один с Габриэлем, который не слушает, прикрывает глаза, молчит о
      ком-то другом. Не один с собственной гэбней, которая
      тоже не слушает, поступает необдуманно, ходит на поводу у тех, кто не имеет
      никакого права принимать решения вместо Максима.</p>

    <p>Дойти сейчас до Большого Скопнического, сесть на автопоезд. Никого не убить
      по пути.</p>

    <p>В доме 47 по Бывшей Конной Дороге
      лысые стены, пустые полки и сквозняк по полу, там не выходит врать себе, что
      рядом есть кто-то ещё. Скромные пожитки студентки Шухер только лучше оттеняют
      одиночество, не оставляют пространства для сомнений. На диване, где когда-то
      давно ютился Габриэль — хватал во сне своими тонкими пальцами Максима за
      рубашку, но вслух так ни разу и не попросил защитить и спрятать — теперь смятое
      покрывало и пара свитеров с яркими брошками. На кухонном столе, где когда-то —
      ещё раньше — не просыхали стопки однокурсников Максима из контрреволюционной
      группировки, теперь том беспробудно слезливых пьес из росской классики и
      россыпь конспектов в изрисованных обложках. Вчера случайно упал взгляд —
      брошенная на середине попытка изобразить профиль Хикеракли и подпись: «учите
      Революцию, у них были ТАКИЕ РУБАШКИ!».</p>

    <p>Максим тогда почти отдёрнулся от
      стола, почти заподозрил насмешку, но сообразил: студентка Шухер — домашняя
      девочка, читает слезливую классику вместо профессиональной литературы, не
      помнит в лицо скандального идеолога Гуанако С. К., ей просто неоткуда знать про
      контрреволюционное движение на истфаке в конце 60-х—начале 70-х.</p>

    <p>&nbsp;</p>

    <p><i><u>Контрреволюция</u></i></p>

    <p>&nbsp;</p>

    <p>Громкое слово для громких попоек,
      соседи всё время жаловались — нынешней звукоизоляции у Максима в те времена,
      конечно, ещё не было. Да и зачем бы? Никто и не думал ничего скрывать,
      замалчивать, соблюдать какую-либо секретность — эта самая секретность,
      положенная в основу государственного устройства, и бесила их больше всего!</p>

    <p>Нет, не «их» — Максима. Как
      жёстко, но правдиво твердил Гуанако, добровольно разгребая последствия их
      подвигов, не было ведь никаких «их». Ни организации, ни установок, которые
      разделяли бы <i>все</i> участники, ни хоть
      какого-то подобия программы. Сплошь совсем ещё детские обиды на весь мир (у
      каждого — свои) и слишком много — адреналина? тестостерона? — впрочем, уже
      несущественно, что он тогда говорил.</p>

    <p>Но говорил:
объяснял, переубеждал, доказывал, очаровывал, высмеивал — и все поддались, не
сразу и не с полпинка, но всё же. Где-то в истфаковских стенах ляпнул про
      адреналин (тестостерон?) — кто-то чужой услышал.
      Всплыло через пару лет, вместе с той историей про уровень агрессии и
      лаборатории, объединяющие всех активных участников контрреволюционного
      движения. Допрашивали не только их самих, много кого — вот и вспомнил кто-то
      оказавшуюся такой фатальной случайную фигуру речи. А допрашивавшим того и надо
      было: найти любую зацепку, наплевать на здравый смысл, приписать Гуанако грехов
      побольше. Упоминал о гормонах? Значит, знал об
      экспериментах, значит, использовал не вполне адекватных людей в собственных
      целях. Значит — виновен.</p>

    <p>Примитивная алгоритмическая
      логика часто ошибается: выводы, сделанные из случайно выбранного (и потому ограниченного)
      числа посылок, а не на основе всей картины в целом, не могут быть надёжными по
      определению. Учитывая эту особенность, примитивной алгоритмической логикой
      легко манипулировать. <i>Слишком</i> легко.</p>

    <p>Это следующая после секретности
      красная тряпка, на которую бросалось контрреволюционное движение. Нет, не
      движение — Максим. Пора бы запомнить уже, перестать себе
врать.</p>

    <p>Государственный аппарат, в
      котором так много алгоритмической логики — это нонсенс, это механизм,
      запрограммированный на самоуничтожение. Бюрократия призвана выполнять функцию
      урегулирования внутренних процессов, но не царить над ними. Государство, в
      котором бумажки весомей реального положения дел, — обречено.</p>

    <p>Адепты бумажек и алгоритмической
      логики (вроде того же Ройша, но Ройш появился потом, во времена несостоявшейся
      контрреволюции он только-только получал студенческий пояс и мундир) не
      учитывают, что бюрократия — инструмент. Удобный, практичный, почти идеальный —
      но инструмент, только и всего. А должно быть <i>что-то большее</i>. И не на уровне личных целей, для которых сгодится
      любой инструмент, был бы он рабочим, а на уровне общественном, а значит, и
      государственном. Потому что и государство тоже — инструмент, не цель.</p>

    <p>Отрядские учебники по Революции,
      которые каждый гражданин Всероссийского Соседства знает почти наизусть (вне
      зависимости от собственной близости к политике или исторической науке), даже
      честнее, чем любые прогрессивные исследования специалистов высокой
      квалификации. В них чётко и ясно сказано: «Революционный Комитет поставил перед
      собой казавшуюся недостижимой задачу — переломить существующий порядок ради
      создания нового государства». Не ради всеобщих благ, не ради народных масс, но <i>ради государства</i>.</p>

    <p>На первом курсе у Максима и
      остальных членов контрреволюционного движения был толковый преподаватель
      современнейшей истории. Уволился довольно быстро по собственному желанию, когда
      осознал, <i>к чему</i> подтолкнул совсем ещё
      зелёных студентов. Этот преподаватель дал им в сентябре простое задание —
      сравнить отрядский учебник по Революции и методические пособия на ту же тему
      для старшекурсников кафедры новейшей и современнейшей истории. С высоты своего
      теперешнего преподавательского опыта Максим ясно видел, что тот хотел всего
      лишь наглядно продемонстрировать на материале собственной дисциплины, чем
      обыденная картина мира отличается от научной и что
      значит набиравший как раз тогда популярность тезис «историография — скорее
      искусство, нежели наука», но поняли его иначе.</p>

    <p>Кто-то понял, что в этом
      государстве населению патологически врут, недоговаривают, не объясняют толком,
      что происходит. Это был Максим и ещё несколько самых здравых, самых идейных
      членов нарождавшегося контрреволюционного движения. Им просто хотелось, чтобы
      все могли решать за себя самостоятельно, имели возможность делать выбор
      осознанно, а не следовать безоглядно тому пути, по которому власть имущие
      посылают народные массы.</p>

    <p>Кто-то понял, что в этом
      государстве много лишнего, ненужного, только затрудняющего жизнь граждан. Это был
      Максим и ещё несколько самых предприимчивых, самых свободно мыслящих. Ларий,
      например. Им хотелось, чтобы жизнь народных масс была удобнее, чтобы сама
      система была дружелюбнее к инновациям, чтобы было меньше ситуаций биения
      головой о стену.</p>

    <p>Кто-то понял, что у этого
      государства на удивление эфемерный фундамент, что оно хрупко, а всё хрупкое так
      и просит сжать себя посильнее и сломать к лешему! Это был Максим и ещё
      несколько — самых взрывоопасных, самых неуравновешенных (и нет, Охрович и
      Краснокаменный не в их числе — эти никогда и рядом-то с контрреволюционным
      движением замечены не были). Самым взрывоопасным хотелось взорваться поскорее, а
      благородный политический повод лучше любого другого, когда тебе двадцать и
      меньше.</p>

    <p>Ну а кто-то понял, что изначальный
      замысел Революционного Комитета извратился, рассыпался в прах на самых ранних
      этапах возникновения Всероссийского Соседства, ухнул в могилу. И это была
      прекрасная питательная среда для сочувствующих
      контрреволюционному движению.</p>

    <p>И ещё много чего было, было и
      прошло, но как только прошло, стало ясно: не так уж и много. Пьяные споры до
      утра, пахнущие типографской краской сборники статей по современнейшей истории,
      байки о произволе власти (и если бы из личного опыта — по большей-то части из
      третьих рук, а то и вовсе из собственной головы), снова пьяные споры, драки,
      травмы, больницы, сборники статей, споры, драки.</p>

    <p>Какие-то акции тоже случались:
      провокационные листовки, горячечные дискуссии с заезжими специалистами
      соответствующего профиля, нападения на случайных младших служащих, просто на
      людей в форме — однажды досталось какому-то актёру, который выбежал из театра
      на пять минут за вином, не потрудившись переоблачиться.</p>

    <p>С первого курса по середину
      четвёртого не утихали скандалы, не прекращались вызовы к Учёному Совету,
      маячили угрозы отчисления. И потом тоже, но в гораздо меньшем объёме и уже по
      инерции.</p>

    <p>&nbsp;</p>

    <p>…По инерции оглядеть остановку
      автопоезда в поисках истфаковских студентов. Вспомнить, что большая часть
      студентов сейчас в лазарете, что лазарет нужен, чтобы было лекарство, а
      лекарство — потому что в Бедрограде чума. Чума же — потому что в Университете
      теперь есть гэбня, а гэбня есть, потому что на четвёртом курсе Максима всякая
      контрреволюция закончилась, побушевала и схлопнулась.
      Не сама собой, а с лёгкой руки профессора Гуанако — идеолога Всероссийского
      Соседства и светила науки, именно что.</p>

    <p>&nbsp;</p>

    <p><i><u>Гуанако</u></i></p>

    <p>&nbsp;</p>

    <p>Он же тогда был даже моложе, чем
      Максим сейчас. Но его <i>уже</i> читали все
      — и не только на истфаке, не только в БГУ, не только в Бедрограде. Все, кого
      социальные изменения хоть как-то интересовали с научной точки зрения. Воротий Саныч, бывший завкафом до Габриэля, брал Гуанако в
аспирантуру с прицелом на модуль по истории общественного прогресса, но в итоге
получил специалиста широкого профиля: от методологии до истории религии, от
одобренной самим Хикеракли будто бы идеологической работы о закономерностях
развития научного мышления до забитых под завязку аудиторий на рядовой лекции.
      Хотя вообще-то <i>рядовых</i> лекций у
      профессора Гуанако не бывало.</p>

    <p>Он работал на истфаке меньше
      десяти лет, но те, кто застал его, запомнили надолго. Эпигонов, подражателей,
      последователей потом развелось тьма, но Максиму так и не попадались на глаза
      хоть сколько-нибудь приближающиеся к оригиналу по силе воздействия. Потому что
      Гуанако действительно много знал, потому что умел быстро отыскивать, разбирать
      и понимать то, чего не знает, потому что он писал просто, логично и (умудрялся
      же!) весело, потому что говорил ещё проще и веселее, потому что его научные
      интересы не имели ни границ, ни искусственно навязанных ориентиров.</p>

    <p>Да, Максим восхищался <i>профессором</i> Гуанако — и это не секрет.</p>

    <p>Секрет в том, что к <i>человеку</i> Гуанако Максим имел крайне
      непростое отношение. Всегда.</p>

    <p>Своими простыми и весёлыми
      лекциями (хорошо, что они начались только со второго курса, иначе бы вовсе
      ничего не было, наверное) Гуанако подтачивал саму идею контрреволюции. Сначала
      ненамеренно, одним только фактом своего существования: не было среди
      контрреволюционеров никого, кто не задумывался бы, как такой человек, как
      Гуанако, может быть (хуже: может на глазах становиться) идеологом
      Всероссийского Соседства. Не самым значимым — по популярности ему ни с Набедренных,
      ни с Хикеракли, ни с любым другим членом
      Революционного Комитета, разумеется, не тягаться — но тем не менее. Если Гуанако
      — идеолог, пусть и исключительно в сфере науки, научного прогресса и его
      значения для государства, возможно, государство не так и дурно?</p>

    <p>Потом Гуанако пригляделся к своим
      новым студентам, позвал их выпить раз, другой — и вник в контрреволюцию. <i>И
        посмеялся</i>.</p>

    <p>Максим никогда этого не забудет:
      как все стремления, надежды и планы начинают трещать по швам от приступа хохота
      правильного человека в правильных обстоятельствах. Как всеобщее благо, высокие
      цели проигрывают в убедительности скабрёзным анекдотам про Хикеракли и
      Революцию в принципе. Как люди, которые ещё вчера прислушивались к тебе и
      разделяли твои убеждения, смотрят влюблёнными глазами на преподавателя, у
      которого прямо на подоконнике, не скрываясь, цветёт запрещённый кактус,
      содержащий высокий процент наркотических веществ.</p>

    <p>Максим обозлился и организовал
      после этого несколько откровенно экстремистских акций, которые масштабами
      разрушений (мемориальной резиденции Набедренных, к примеру) превосходили всё,
      что контрреволюционное движение раньше творило. Но и тут он проиграл Гуанако:
      их собирались наказывать, выдавать городским властям, а то и кому уровнем
      доступа повыше — университетское-то начальство быстро догадалось, кто стоит за
      тематически родственными погромами в Бедрограде. Не наказали и не выдали —
      неожиданно вступился ещё недавно посмеявшийся над ними Гуанако. Не моргнув
      глазом, соврал Учёному Совету, что точно помнит: в те дни, когда были совершены
      набеги, студенты из так называемой «контрреволюционной террористической
      группировки» употребляли самогон на его, Гуанако, частной квартире и если и
      устраивали погромы, то только там. Учёный Совет тоже не
      ожидал такого заступничества и потому поверил.</p>

    <p>Контрреволюционное движение (и
      Максим лично) остались перед Гуанако в долгу.</p>

    <p>И чем дальше, тем больше этот
      долг рос: Гуанако покрывал, отмазывал, ручался, заступался перед другими
      преподавателями, объяснял врачам в больницах ножевые ранения, пару раз объяснял
      даже наличие взрывчатки следственному отделу при Бедроградской гэбне,
      расплачивался однажды с портовыми нелегальными продавцами этой самой
      взрывчатки, которые постфактум потребовали от совершенно неопытных в подобных
      делах членов контрреволюционного движения каких-то безумных денег.</p>

    <p>И каждый, буквально-таки каждый
      раз повторял: бросайте вы это дело. Без громких нравоучений и без тихого страха
      перед тем, <i>на что</i> они замахнулись.
      Просто, логично, весело повторял — так, как читал лекции, писал статьи и
      рассказывал скабрёзные анекдоты про Хикеракли.</p>

    <p>А потом случился четвёртый курс.
      Две трети участников движения тогда уже потихоньку отошли от контрреволюции
      молитвами Гуанако, оставшаяся же треть задумала совсем серьёзное мероприятие —
      поиск и последующее уничтожение самого Бюро Патентов.</p>

    <p>И тут к ним пришёл завкаф истории
      науки и техники, Воротий Саныч.</p>

    <p>Вздохнул, покачал головой и
      поведал отрезвляющую историю о каком-то юном и яром почитателе голоса Революции
      Твирина, который хотел сделать то же самое — уничтожить Бюро Патентов. Именно
      тогда Максим и ещё несколько человек и узнали, что Воротий Саныч — полуслужащий
      <i>этих</i>, работает напрямую с самым
      верхом, хотя история и без того звучала убедительно и весомо, более чем.
      Воротий Саныч в лоб заявил: он — не только как должностное лицо, но и просто
      как сознательный гражданин Всероссийского Соседства — вынужден будет доложить о
      планах контрреволюционного движения куда следует, если оное контрреволюционное
      движение не прекратит своё существование. Немедленно. А если не прекратит —
      пусть начнёт с его, Воротия Саныча, уничтожения. Перед тем, как идти на Бюро
      Патентов, мол, стоит попрактиковаться.</p>

    <p>Уничтожать пожилого, уважаемого и
      во всех отношениях приятного завкафа науки и техники никто и не думал, Максим —
      тем более. Зато Максим всерьёз рассматривал перспективу уничтожить <i>того, кто
        этого самого завкафа-полуслужащего
        на них навёл.</i></p>

    <p>Дикость, конечно, но прямо сейчас кажется — может, зря тогда не решился?</p>

    <p>&nbsp;</p>

    <p>…Максим ждал автопоезда под
      зарядившим снова дождём в одной рубашке — чёрный шёлк, тонкий, совсем не
      защищает ни от дождя, ни от ветра. Плащ, пиджак — всё осталось на кафедре, он
      ведь не хотел сбегать, не хотел громких жестов и ругани, просто так вышло.
      Просто <i>его </i>Университетская
      гэбня больше не желает слышать, что говорит Максим, — так же, как когда-то не
      пожелало <i>его</i> контрреволюционное
      движение. И у обеих этих личных катастроф наличествует как минимум один
      совпадающий компонент — Гуанако.</p>

    <p>Чёрный шелк промок и лип теперь к
      телу, впускал под кожу холод. Старик, укрывавший зонтом свою пышную пепельную
      бороду, уставился на Максима с неприятным сочувствием, кивнул на тонущие в лужах
      рельсы:</p>

    <p>— Полчаса уже стою, а ни одного
      автопоезда так и не было. Авария где-то, что ли?</p>

    <p>Максим пожал плечами, дёрнулся
      посмотреть на часы и не посмотрел — незачем. Время пусть себе идёт без него,
      сегодня спешить некуда, <i>кто-то другой</i> справляется и сам. Или не справляется, что тоже сейчас неважно.</p>

    <p>— …В 20-х вообще кое-как ходили,
      дело понятное, а потом выровнялось, — вещал о чём-то своём старик. Очевидно, об
      автопоездах, — зато в 37-м пожары случились, а движение никто восстанавливать
      не торопился, не до того, видать, было — Твирин умер, радио замолчало, какие
      тут автопоезда. А потом в городе транспорт не ходил по
      полдня только в 45-м и 53-м — это, значит, Приблев и
      Мальвин. Ну с Твириным ясно — голос Революции
      как-никак, после Набедренных первый человек, а эти двое-то почему? Другие члены
      Революционного Комитета, померев, за собой автопоезда не утаскивали. Я-то всё
      помню, у меня балкон прямо на остановку выходит — никогда в тишине не покуришь,
      всё рельсы гремят. А когда не гремят, так и страшно — вдруг случилось чего?</p>

    <p>Максим горько усмехнулся.</p>

    <p>— Полчаса, конечно, ещё не конец
      света, — всё продолжал и продолжал старик, потряхивая в такт зонтом, — но тоже
      подозрительно. Это куда ж сейчас городская власть смотрит, если за полчаса с
      аварией не разобраться?</p>

    <p>Куда смотрит сейчас городская
      власть.</p>

    <p>Максим отстранённо — как сквозь
      стекло — удивился, что этот вопрос его совершенно не занимает. Хоть прямо на
      него с чердака ближайшего дома, да пожалуйста. Он при
      табельном оружии, в конце концов. Головам гэбен стрелять друг по другу
      запрещено, но и смертельно опасную болезнь на собственной территории
      культивировать не то чтобы разрешается. Все рамки и так уже перейдены, чего
      теперь-то мелочиться?</p>

    <p>Можно даже не ждать автопоезда,
      остановить случайное городское такси, подотчётное Бедроградской гэбне, —
      плевать. Но в такси не хочется, и проблема не в том, кому оно там подотчётно.</p>

    <p>Ночью с субботы на воскресенье
      Максим сел в одно из служебных университетских такси и сказал водителю: «Ну что
      — Революционный, дом 3». В последующие дни называть адрес уже не приходилось —
      водители иногда менялись, но про его неудачи с фалангами знали, по всей
      видимости, вообще все, кто состоял на службе при
      Университетской гэбне.</p>

    <p>Революционный проспект расположен
      едва ли не на краю города. Место неудобное, кругом промзоны,
      заводские трубы, вечно перекрытые переезды — хоть считай в ожидании зелёного
      семафора вагоны грохочущих мимо товарняков. А когда
      наконец доберёшься до резиденции фаланг, можно считать телефонные звонки, то и
      дело отклоняемые секретарём в холле.</p>

    <p>«Нет, простите, никакой
      информации по вашему запросу пока не поступало», — и трубка возвращается на
      рычаг. Вот поэтому Максим и не хотел обойтись звонками, являлся в глупой
      надежде лично. Но сменявшиеся не реже водителей секретари в холле у фаланг
      смотрели на Максима глазами, какие бывают у Габриэля в самые холодные и пустые
      дни: «Неужели ты ещё не догадался, что сегодня тебя никто не ждал?»</p>

    <p>Иногда фаланги до него
      снисходили, но толку от этого было чуть. Спрашивали бегло: «Неужели в Университете
      теперь так мало дел, что вы решили ещё раз навестить нас?»</p>

    <p>Максим снова предъявлял копии в
      субботу ещё отправленных на рассмотрение документов, но фаланги только
      удивлялись: «Неужели так необходимо дублировать официальные запросы своим
      присутствием?»</p>

    <p>Да, хотел кричать Максим, именно <i>так</i> и необходимо!</p>

    <p>Но сдерживался, вёл бессмысленные
      разговоры, заполнял всё новые и новые бланки, жалея, что пометку о чрезвычайной
      ситуации поставить пока не может.</p>

    <p>Рано, никакой чрезвычайной
      ситуации нет.</p>

    <p>Нет лекарства — нет болезни,
      иначе Университетской гэбни очень скоро тоже не станет.</p>

    <p>Может, оно и к лучшему?</p>

    <p>&nbsp;</p>

    <p><i><u>Университетская гэбня</u></i></p>

    <p>&nbsp;</p>

    <p>Ответственность, с которой
      непонятно, что делать. <i>Сначала</i> было
      непонятно, потом как-то понимание пришло, улеглось и последние много лет лежало
      себе спокойно, но тут вдруг опять пошатнулось: может, они где-то ошиблись?
      Может, они перегибают палку?</p>

    <p>Максиму не
нравились бесконечные «может», Максим любил «наверняка», «несомненно» и
«очевидно, что».</p>

    <p>Очевидно, что Университет (как и
      наука, как и образование вообще) — сфера повышенной опасности. Доказательства
      не требуются: всем известно, что Революционный Комитет состоял сплошь из
      студентов и вольнослушателей Йихинской Академии. Кому надо известно, что
      контрреволюционное движение Максима состояло из его однокурсников с истфака.
      Вывод прост.</p>

    <p>Очевидно, что сферы повышенной
      опасности нуждаются в отдельном контроле.</p>

    <p>Очевидно, что с таким контролем
      не справиться одному, даже самому подготовленному, человеку — каким до Университетской гэбни был Воротий Саныч, полуслужащий Бюро
      Патентов. Кстати: жаль, что именно сейчас к нему не пойдёшь за советом — в
      Университете он уже не работает, годы не позволяют, но это не значит, что они
      же не позволяют служить и дальше Бюро Патентов. А Бюро Патентов правду о чуме в
      Бедрограде знать не следует.</p>

    <p>Очевидно, что ситуация с
      контролем над БГУ им. Набедренных, находившимся в начале 70-х под угрозой в
      силу преклонного возраста Воротия Саныча, должна была
      так или иначе разрешиться.</p>

    <p>Неочевидно, не стоило ли ей
      разрешиться <i>иначе</i>.</p>

    <p>Шестой уровень доступа — это
      очень высоко. Это Столица, Бедроград, Порт и — уже девять лет как —
      Университет. Девять лет назад они не знали даже, как решают самые простые
      бюрократические задачки, всё Святотатыч подсказывал.</p>

    <p>Святотатыч — наследство Максима времён
      контрреволюционного движения, Гуанако когда-то знакомил, кто же ещё. Не как с
      головой Портовой гэбни, разумеется, — как со знающим человеком в Порту, к
      которому всегда можно обратиться. Оберегал, как мог, своих глупых
      контрреволюционеров. Преподаватели так не делают (Максим
      не делал) — так, наверное, делают хорошие отцы: ничего не запрещают, просто
      рассказывают, куда податься, когда обожжёшься.</p>

    <p>Максим не нуждался в отцовской
      опеке Гуанако, но почему-то всегда её принимал. Принял и Святотатыча, контакты
      в Порту лишними не бывают. Когда Гуанако уже не было в
Бедрограде (трагедия в экспедиции, Колошма, расстрел — все думали разное), а
Максима назначили в Университетскую гэбню, Святотатыч всплыл сам.
      Рассказал про гэбню Порта, про расстановку сил, про потенциальные сложности.
      Максиму казалось, Гуанако бессмертен: его не видели уже больше года, он пропал,
      умер, растворился, но он продолжал быть <i>везде</i>.
      В научных статьях, в пламенной речи Хикеракли про ответственность, ложащуюся на
      плечи Университетской гэбни, чей состав был рекомендован как раз Гуанако, в
      неожиданной заботе Святотатыча, которую по-другому не объяснить. В полных
      безысходной тоски вздохах Габриэля, но это не относится к делу.</p>

    <p>Совершенно не относится.</p>

    <p>Дальше — всё равно Гуанако,
      всегда Гуанако.</p>

    <p>Первый конфликт с Бедроградской
      гэбней — сомнительной законности осуждение студента. За что? За обнаруженные
      при случайном досмотре расшифровки аудиозаписей, имевшие прямое отношение к
      скандальному развалу Колошмы. Расшифровки личных внепротокольных бесед Начальника
      Колошмы и <i>Гуанако</i>.</p>

    <p>Первый идеологически смелый шаг —
      открытие борделя назло опять заевшей их Бедроградской
      гэбне. Как обойти запрет на бордели, исходящий от самого Набедренных?
      Поспекулировать перед Бюро Патентов на неизданных
      ранее работах <i>Гуанако</i>, в которых есть
      спекуляция уже на словах Набедренных: мол, не бордели плохи, а статическая
      структура неравенства. И Бюро Патентов проглотило, согласилось.</p>

    <p>Ну
      глупость ведь, бессмысленная шалость: Бедроградской гэбне не нравится новая
      студенческая мода на бордельные ошейники, возмущающая благонравных жителей
      Бедрограда? <i>Бедроградской гэбне опять
        что-то не нравится?</i> Пусть
      Бедроградская гэбня получит одобренный наивысшими инстанциями бордель прямо в
      Бедрограде и молчит в тряпочку!</p>

    <p>Охрович и Краснокаменный радовались
      как дети, Ларий грезил о кислых минах Бедроградской гэбни, а Максим не знал,
      как справиться с навалившимися после согласия Бюро Патентов делами. Надо же
      было не только помещение и сотрудников готовить — надо было для закрепления
      эффекта явить миру полное собрание сочинений проклятого Гуанако, чтоб и дальше
      на что-то ссылаться в подтверждение своей идеологической правоты. То есть
      покрывать свою глупость и бессмысленную шалость.</p>

    <p>Осоловевший
      от усталости Максим пришёл как-то за очередной помощью к Святотатычу, а ушёл с
      простым и тривиальным, но от того не менее важным вопросом.</p>

    <p>«На кой вам четыре головы, если
      вы не можете одновременно открыть бордель и издать ПСС?»</p>

    <p>На кой вам четыре головы.</p>

    <p>Кажется, именно тогда Максим
      осознал-таки, <i>что такое гэбня</i>. Жаль,
      что с годами это осознание опять потерялось, но уже не у Максима.</p>

    <p>&nbsp;</p>

    <p>…Рельсы задребезжали, по
      свинцовым лужам побежала рябь.</p>

    <p>— Ну
      наконец-то! — махнул зонтом старик с окладистой пепельной бородой, который,
      видимо, мог бесконечно говорить о связи городского транспорта с внутренней
      политикой.</p>

    <p>Максим вздохнул с облегчением:
      мокнуть надоело, да и, как бы там ни было, простужаться сейчас всё равно некогда,
      скорее бы домой.</p>

    <p><i>Домой.</i></p>

    <p>Нет, на свою квартиру на Бывшей
      Конной Дороге. <i>Домой</i>, на Поплеевскую,
      его не звали.</p>

    <p>Вчера Максим не выдержал,
      позвонил. В конце концов, у Габриэля сотрясение мозга, сложно не волноваться,
      как он там. Габриэль подошёл не сразу и говорил нехотя, отвечал на реплики
      через раз. Габриэль был равнодушный, раздражённый и укоряющий, и Максим счёл за
      лучшее свернуть разговор побыстрее, чтобы не
      сорваться. Он и так слишком часто срывается в последнее время. Поэтому <i>домой</i> ему пока возвращаться не стоит. А
      может, и вовсе уже не стоит.</p>

    <p>Снаружи автопоезд блестел от
      дождя, но салон так и манил теплом. Пора заводить карманную флягу — греться и
      успокаивать нервы. Максим и так уже все последние дни чуть
      что хватался за твиревую настойку, от которой теперь ломился стол Лария.
      Алкоголь на службе всяко лучше сна на службе. Или
      скандалов на службе.</p>

    <p>Максим пристроился в хвосте
      состава у окна (подальше от общительного старика). На сиденье у
      противоположного окна почти упал совсем молоденький мальчик, запрыгнувший в
      автопоезд едва ли не на ходу. Отдышался от бега, проверил карманы, скользнул
      глазами по Максиму и приготовился задремать.</p>

    <p>Мальчик был светловолосый, но всё
      равно чем-то неуловимо напоминал Габриэля: то ли тонкими чертами, то ли волной
      длинной чёлки, то ли этой совершенно особенной,
      фарфоровой осанкой. Максим поймал себя на том, что не может перестать пялиться.
      Максим тоже живой человек.</p>

    <p>Ему случалось пялиться
      на попутчиков в автопоезде, на студентов даже, но это всё так, ерунда. Мысли о
      том, чтобы променять Габриэля на кого-то — пусть не всерьёз, пусть на один раз
      — он не допускал. Есть вещи, которые невозможны по определению.</p>

    <p>Когда-то невозможным по
      определению ему казался Габриэль: Габриэль — преподаватель, Габриэль — научный
      руководитель Максима, у Габриэля была какая-то сложная история с Гуанако, а это
      серьёзно. Когда сам Максим заканчивал первый курс, а незнакомый ему тогда
      Габриэль — последний, он и сам видел краем глаза кое-что из этой «сложной
      истории». Сплетни по всему истфаку, <i>невозможные</i>,
      идущие в разрез с Уставом Университета поцелуи преподавателя и студента прямо
      перед кафедрой, <i>невозможного</i> зелёного
      цвета розы, подаренные в холле, на глазах у всего факультета. Это было
      по-своему правильно: <i>невозможно</i> красивый Габриэль и Гуанако, <i>невозможный</i> во всех смыслах — от количества академических заслуг в столь юном возрасте до
      манеры держаться. Само собой, они <i>невозможным</i> образом расстались: едва ли не на защите диплома
      Габриэля, едва ли не со вскрытыми венами, но совершенно точно под бурные овации
      неравнодушной публики.</p>

    <p>Само собой, после этого к Габриэлю
      ещё долго <i>невозможно</i> было
      подступиться.</p>

    <p>Автопоезд позвякивал по рельсам,
      усыплял размеренным шумом и уютным теплом, но засыпать прямо тут Максиму не
      стоило. Он бодрился, как мог, перебирал в уме случайные воспоминания, но все они так или иначе приводили опять к Гуанако. Максим понимал,
      что это нездорово, что нельзя так зацикливаться, что голова должна быть холодной,
      особенно — голова гэбни.</p>

    <p>Мысли сворачивались,
      укорачивались, превращались совсем уж в опорный конспект, чтобы ненароком не
      смешаться в сонном дурмане.</p>

    <p>Голова гэбни — Максим, голова
      гэбни — Ларий, а Охрович и Краснокаменный — это один голова гэбни или всё-таки
      два? Если один, можно назначить четвёртым ещё кого-нибудь. Алгоритмического
      дотошного Ройша? Нелегального надоедливого Диму?</p>

    <p>Проклятого Гуанако?</p>

    <p>&nbsp;</p>

    <p><i><u>Голова гэбни</u></i></p>

    <p>&nbsp;</p>

    <p>Одна четверть от единицы
      управления сферой, территорией или учреждением.</p>

    <p>Каждый голова гэбни имеет с
      остальными равные права и обязанности. Так — правильно, брать на себя больше
      (меньше) — неправильно.</p>

    <p>Нет ничего проще для понимания,
      нет ничего сложнее для исполнения.</p>

    <p>&nbsp;</p>

    <p><i><u>Ларий</u></i></p>

    <p>&nbsp;</p>

    <p>Он ставит чайник, он ведёт учёт
      кафедральному алкоголю, он принимает звонки и пересчитывает бумажки. Он
      секретарь кафедры, у него нет кандидатской степени — он не защитил даже диплом,
      отчислился из-за контрреволюционных дел, пошёл работать в архив Бедроградского
      Радио, собирался уехать в Тьверь к Центральной Радиовышке. Он всё бросил, когда
      узнал, что покойный Гуанако завещал ему сидеть в Университетской гэбне: ставить
      чайник, вести учёт кафедральному алкоголю, принимать звонки…</p>

    <p>Его нельзя недооценивать, он
      профессионал. Это именно он когда-то додумался до того, что можно заявить свои
      права на городские канализации, и проблема закрытых для Университета территорий
      будет решена.</p>

    <p>&nbsp;</p>

    <p><i><u>Охрович и Краснокаменный</u></i></p>

    <p>&nbsp;</p>

    <p>До сих пор занимаются ерундой, но
      — достанут из-под земли любого, добудут любые сведения, вытрясут правду хоть из
      мертвеца, попадут в монетку со ста шагов с закрытыми
      глазами, стреляя левой ногой.</p>

    <p>Впрочем, монетки они обычно
      используют по-другому — прокидывают, кому из Революционного Комитета сегодня
      висеть кафедральным чучелом. Косятся нагло на Максима с Ларием: Университетская
      гэбня наполовину идеологически ненадёжна, в жизни Университетской гэбни должно
      быть больше искренней любви к Революции, Университетская гэбня должна стыдиться
      своего контрреволюционного прошлого!</p>

    <p>Охрович и Краснокаменный не
      состояли в контрреволюционном движении и парадоксальным образом влюблены в
      существующее государственное устройство, а также в события, которые привели к
      его установлению.</p>

    <p>&nbsp;</p>

    <p>…Три головы Университетской гэбни
      обладают таким несметным числом достоинств, что даже удивительно, как это им
      четвёртым достался Максим.</p>

    <p>На очередной остановке в
      автопоезд зашли шумные дети в отрядских форменных куртках, совсем взрослые, лет
      четырнадцать-пятнадцать — последний курс. Загорелая девочка тут же вытащила
      из-под полы учебник по Революции, раскрыла ближе к концу, ткнула в страницу
      пальцем, явно продолжая какой-то спор.</p>

    <p>Интересно, какие глаза были бы у
      этих детей, если бы в одном автопоезде с ними ехал не Максим, а Ройш, чьё лицо
      имеет так мало отличий от лица его деда, напечатанного в любом учебнике по
      Революции?</p>

    <p>&nbsp;</p>

    <p><i><u>Ройш</u></i></p>

    <p>&nbsp;</p>

    <p>Ройш не сел бы
в гэбню — ни в Университетскую, ни в любую другую. Ройш упрямый,
      самолюбивый, сложный. И его ведь не упрекнёшь: он сам — сознательно и взвешенно
      — выбрал свой способ не терять достоинства под давлением ожиданий. Услышав его
      фамилию, каждый подумает о политике, как будто фамилия — всё тот же диагноз или
      даже приговор. Ройш никогда не был согласен ни с тем, ни с другим — а значит,
      он с юных лет был вынужден защищать от всего мира собственный путь любыми
      доступными методами.</p>

    <p>Упрямством, самолюбием и
      сложностью, которые давно уже намертво въелись в кожу.
      Въелись и теперь сами командуют Ройшем, принуждая его профилактически
      отгораживаться от всего подряд.</p>

    <p>Ройша нельзя попросить, на Ройша
      можно только рявкнуть: «Сделайте!» Иногда даже это не
      помогает, все последние дни не помогает.</p>

    <p>Ройш думает, что исчезновение
      небезразличной ему студентки Шухер даёт право диктовать всем свои условия, срывать
      намеченный план работы.</p>

    <p>Ройш неправ, но признавать свою
      неправоту он патологически не умеет.</p>

    <p>В мае Университетская гэбня
      выяснила, что студентка Шухер заглядывает домой к Ройшу не просто так, а с
      какими-то политическими намерениями. Повинуясь не то неумеренной страсти к
      сплетням, не то феноменальному чутью, Охрович и Краснокаменный вздумали
      последить слегка за студенткой, повадившейся слишком часто навещать своего
      научного руководителя, и с удивлением обнаружили, что та водит дружбу с одним
      из голов Бедроградской гэбни. Пришлось устраивать драму с разоблачениями.</p>

    <p>Студентка Шухер поддалась быстро,
      рассказала всё как есть: недавно с ней познакомился младший служащий из
      Столицы, временно переведённый в Бедроград, давний приятель Ройша. Этот
      приятель по долгу службы имеет доступ к определённым сведениям, указывающим на
      то, что в БГУ им. Набедренных есть некий полуслужащий,
      которому скоро придётся несладко. Он подозревает, что речь может идти о Ройше,
      но Ройша прямо не спросишь (упрямый, самолюбивый, сложный), проще найти
      неравнодушную к Ройшу студентку и отправить её покопаться у Ройша в документах
      — авось обнаружится где-нибудь код уровня доступа. Мол, узнать, полуслужащий
      он или нет.</p>

    <p>То есть — если выкинуть все
      бредни про столичных приятелей и полуслужащих и оставить только Бедроградскую
      гэбню — узнать, есть у Ройша второй уровень или нет.</p>

    <p>Тогда они не понимали, зачем
      вдруг Бедроградской гэбне это понадобилось, но на добрые намерения надеяться не
      приходилось. Студентку Шухер отчитали, пристыдили за доверчивость, кое-что ей
      объяснили, перевербовали.</p>

    <p>Ройш же, услышав, что пустил в
      свой дом шпиона, и бровью не повёл.</p>

    <p>За приоритет личных интересов над
      общественными Ройша надо было пристрелить ещё в мае.</p>

    <p>&nbsp;</p>

    <p>…Кто-то из детей выхватил у
      загорелой девочки учебник и рванул с улюлюканьем в конец состава. Максим
      вздрогнул, поёжился, заставил себя очнуться. Не засыпать, не засыпать.</p>

    <p>Поппер же недавно расхваливал
      какое-то средство, говорил, помогает запросто продержаться несколько суток
      вовсе без сна, хоть и последствия от него для организма тяжёлые. Очередная наркота,
      но, кажется, что-то очень простое.</p>

    <p>Ах да. Димины колючие браслеты из
      степных трав. Ещё немного народной медицины от не-специалиста,
      который может выдвинуть идею, но никак не может гарантировать результат.</p>

    <p>&nbsp;</p>

    <p><i><u>Дима</u></i></p>

    <p>&nbsp;</p>

    <p>Так много на чуму не работал
      никто, это факт. Дима впахивал всё лето в Медкорпусе,
      Дима не разгибал спину сейчас.</p>

    <p><i>Так бестолково</i> на чуму тоже никто не работал.</p>

    <p>У него всегда всё недоделано,
      недодумано, висит на волоске от провала. Он умудряется всюду лезть с советами,
      помощь даже предлагать — вместо того, чтобы взяться за одно дело, но взяться
      всерьёз.</p>

    <p>Сегодня утром Максим смотрел на
      Диму, видел его иссиня-чёрные волосы со странной ранней сединой — совсем как у
      Габриэля, только жёсткие, вечно растрёпанные — и непроизвольно его жалел.</p>

    <p>Иссиня-чёрные волосы с ранней сединой бывают у
      кассахов, нечасто встречающегося на территории Всероссийского Соседства малого
      народа. Плато Кассэх в конце 20-х присоединилось к Британии, хоть и не делось
      никуда после этого из Среднеросской Полосы. Кассахи с плато — подданные Объединённой Британии-Кассахии, кассахи во Всероссийском
      Соседстве — редкость, почти экзотика, а на любую экзотику найдутся ценители.</p>

    <p>Гуанако служил когда-то в
      Ирландском Соседстве, от Ирландии до Британии рукой подать — то, как неровно
      Гуанако дышит к кассахам, видно невооружённым глазом. Как и то, что для Гуанако
      Дима — очередной красивый мальчик (да мало ли у него их было?), только ещё и
      чуть-чуть похожий на Габриэля.</p>

    <p>А Дима ведь попал из-за Гуанако
      на Колошму, Дима видел там настоящую чуму — степную, страшную, непобедимую.
      Дима значился погибшим при возгорании изолятора, Дима прожил с Гуанако на краю
      света неполных семь лет. И всё это отправилось в мусорное ведро, когда этой
      весной Гуанако снова встретился с Габриэлем.</p>

    <p>Сегодня утром Максим так
      взбесился на Диму, потому что смотрел на него и видел себя.</p>

    <p>&nbsp;</p>

    <p>…Дети зачем-то открыли окошко, и
      порыв сырого ветра пронесся по автопоезду почти от самой кабины машиниста до
      хвоста. Максим присмотрелся: куртки у детей мокрые насквозь — простынут же, перезаразят пол своего отряда.</p>

    <p>Вот как немногочисленные
      университетские медики, и без того валящиеся с ног от переутомления, должны на
      глаз отличать тех, кто просто словил сквозняк, от тех,
      у кого <i>на самом деле</i> она?</p>

    <p>&nbsp;</p>

    <p><i><u>Водяная чума</u></i></p>

    <p>&nbsp;</p>

    <p>Излечима. Смертельна. Но
      излечима. Но…</p>

    <p>Выведена
      искусственно, можно сказать — выведена специально для заражения Бедрограда.</p>

    <p>То есть, конечно же, <i>всего одного</i> здания в черте Бедрограда.</p>

    <p>Так Диме сказал когда-то летом столичный
      вирусолог Таха Шапка, которому Андрей Зябликов из Бедроградской гэбни дал заказ
      по разработке смертельного вирусного заболевания.</p>

    <p>Одним только показаниям простого
      исполнителя доверять безоглядно никто бы не стал, но и <i>другие показания, </i>те, с которых всё началось, косвенно указывали на
      то, что заражение грозит одному зданию — не служебному зданию Бедроградской
      гэбни, конечно, а жилому дому.</p>

    <p>Косвенно указывали.</p>

    <p><i>Слишком косвенно</i>, стоило думать
      головой. Четырьмя головами.</p>

    <p>Стоило не радоваться нечаянной,
      невозможной удаче, благодаря которой удалось-таки разобраться, зачем
      Бедроградской гэбне вдруг понадобился уровень доступа Ройша, а думать.</p>

    <p>Раньше Максиму казалось, что это
      он как раз умеет — думать, понимать, достраивать неочевидные связи, исходя из
      общей картины. Напрасно казалось.</p>

    <p>Он не додумался своевременно до
      простого, лежащего на поверхности факта — Бедроградская гэбня не станет
      ограничиваться заражением одного жилого дома, Бедроградская гэбня давно
      перестала играть с Университетом в игрушки.</p>

    <p>Но в проклятом мае, когда всё
      началось, было сложно представить себе даже это, даже заражение одного дома!
      Это же немыслимо, это же противоречит всем постулатам государственной службы.
      Проще было поверить в то, что Бедроградская гэбня перестреляла бы Университетскую из табельного оружия, чем в то, что они
      пойдут на <i>такие</i> риски, подвергнут
      смертельной опасности простых жителей Бедрограда, гражданских. Даже в масштабах
      одного злосчастного дома, что уж говорить о.</p>

    <p>А теперь — <i>теперь</i>, когда дело сделано, Бедроградская гэбня спровоцирована,
      бумаги оформлены и отправлены фалангам, — этот злосчастный звонок от Тахи
      Шапки, которого Дима неизвестно как перетянул на университетскую сторону.</p>

    <p>К нему явился Гошка, под личиной младшего служащего расспрашивал о пропаже Андрея
      —
      всё так и задумывалось, у самого Шапки на этот случай было заготовлено
      дезориентирующее враньё. Максим изначально полагал это излишним: слишком
      безумный, слишком самонадеянный план — как бы невзначай выманить фаланг к
      какой-то записывающей технические звуки аппаратуре, дабы они прослушали беседу
      Андрея с Шапкой о вирусе. Впрочем, последнее хотя бы имело под собой какой-то
      практический смысл (ещё одно косвенное доказательство вины Бедроградской гэбни),
      в отличие от попыток заронить зерно сомнения в честности Андрея у трёх других
      его коллег через россказни Шапки.</p>

    <p>С другой стороны, не будь в плане
      россказней, Шапка не позвонил бы в воскресенье, не озвучил бы неожиданную
      догадку: Бедроградская гэбня, возможно, ставит под угрозу <i>весь город</i> — им море по колено и у них есть ресурсы.</p>

    <p>Способа узнать, сколько точно
      жилых домов подверглось заражению, не существует. Способа узнать, сколько точно
      людей заразилось — не существует тем более. Вирус, гуляющий в тех кварталах,
      где канализация была объединена с трубопроводом (они и это пропустили, не
      заметили, не отследили!) — не единственный вирус в городе, инфекция легко
      передается естественным путём.</p>

    <p>Поппер в припадке бессмысленно
      оптимизма уверял: «Есть ещё фактор счастливой случайности, он же сильный
      иммунитет — <i>далеко не все</i> люди, которые
      войдут в контакт с вирусом, заразятся».</p>

    <p>Гуанако в припадке бессмысленного
      оптимизма требовал: «Дайте мне карту канализаций, я готов хоть в одиночку
      прочесать весь город и взять пробы — намеренно заражённые дома <i>можно</i> найти».</p>

    <p>Бессмысленный оптимизм Максима
      день за днём подтачивали не желающие реагировать на запрос фаланги.</p>

    <p>Да, в запросе говорится лишь о
      доме Ройша — его отправляли до звонка Шапки. Да, можно отправить новый, но
      только тогда, когда в Университете будет достаточно лекарства. Иначе — надежды Бедроградской
      гэбни с оговорками, но всё же оправдаются: Университет продемонстрирует
      фалангам свою некомпетентность в чрезвычайных обстоятельствах.</p>

    <p>При чрезвычайных обстоятельствах
      такого толка никто — даже фаланги! — не будет тратить время на педантичное
      выяснение всех подробностей. Кто перестраивал канализации, кто и откуда взял
      вирус, кого пытаются подставить — важные вопросы, но не первостепенные.
      Диспозиция ведь ясна: есть Бедроградская и Университетская гэбни, есть конфликт
      между ними, из-за которого сейчас может рухнуть экономика и вера в
      государственность.</p>

    <p>Первостепенный вопрос — кто
      первый предоставит фалангам свой метод борьбы с эпидемией, действенный и
      надёжный, включающий в себя не только запасы лекарства. Недостаточно глубоко
      запрятанные грехи этому первому второпях спишут, а вторую сторону конфликта — тоже…
      спишут. Быстро и без сожалений, дабы в дальнейшем обойтись безо всяких
      конфликтов. На то, видимо, и делала ставку Бедроградская гэбня.</p>

    <p>Только непонятно, может ли
      Университет позволить себе то же самое.</p>

    <p>В Бедрограде чума. <i>Чума в Бедрограде, слышите?</i> И Университетской гэбне, у которой не хватает ни проклятых ресурсов,
ни рабочих рук, ни дисциплины, ни авторитета, с самого начала не стоило лезть в
это дело.</p>

    <p>То, что у
      Университетской гэбни из собственных противочумных
      средств есть, в общем-то, только Имперская Башня, полная скопцов, казалось
      совсем уж насмешкой над здравым смыслом. Величайшее открытие, используемое для
      отвода глаз. Деньги и кровь, добываемые из песка и камней. Чудотворство какое-то,
      не иначе.</p>

    <p>Можно ли в такой ситуации делать
      ставку на чудо?</p>

    <p>Можно ли в такой ситуации делать
      ставку на что-то ещё?</p>

    <p><i>В такую ситуацию</i> просто нельзя попадать. Точка.</p>

    <p>&nbsp;</p>

    <p>…Автопоезд покатился под уклон, и
      Максим качнулся вперёд, едва не стукнувшись лбом о спинку сиденья перед собой.
      Нет, он не спал, но вялая дремота всё-таки одолела его, почти отключила от
      реальности.</p>

    <p>Холм, с которого сейчас сползал
      автопоезд, утопал в деревьях, а где-то впереди маячила арка железнодорожного
      моста. Шолоховский переезд?</p>

    <p>Максим непроизвольно вскочил на
      ноги, тряхнул головой, осмотрелся получше: так и есть,
      Шолоховский парк, Шолоховский переезд.</p>

    <p>Вот леший.</p>

    <p>Светловолосый мальчик у
      противоположного окна — тот самый, с фарфоровой осанкой Габриэля, вбежавший на Большом Скопническом в последний момент — сочувственно
      улыбнулся.</p>

    <p>— Не подскажете, какой это маршрут? — с дурацкой надеждой обратился к нему Максим. Ну мало ли,
      где-то действительно авария, автопоезд пошёл не
      своим путём.</p>

    <p>— Двенадцатый, — ответил мальчик,
      и чёлка упала ему на глаза, совсем как обычно у… — Сейчас остановка будет — за
      переездом, на углу Поплеевской. Сможете пересесть. Вам куда нужно было-то?</p>

    <p>— Уже неважно, — выдохнул Максим.</p>

    <p>Двенадцатый маршрут — почти прямо
      от истфака почти прямо до Габриэля. Сколько лет подряд он каждый день ездил
      этим маршрутом? Леший.</p>

    <p>Это всё холод, дождь, недосып,
      злость, рассеянность. Дождаться первого подошедшего автопоезда, прыгнуть в него не глядя. Или глядя.</p>

    <p>Не сообразил, не подумал, не
      вспомнил.</p>

    <p>Как может брать на себя
      ответственность за пресечение эпидемии в городе человек, неспособный даже сесть
      на нужный автопоезд?</p>

    <p>— …Да просто такси возьмите, у
      них недалеко от парка стоянка в этом районе, с Поплеевской всего ничего идти в
      обратную сторону, — щебетал мальчик. — Хотите, провожу? Мне всё равно выходить.</p>

    <p>Сколько ему, едва-едва за
      двадцать? Раза в полтора моложе Габриэля, и улыбка — лёгкая, не вымученная, и
      зонта при нём нет — не боится дождя, не брезгует автопоездами, не корчит
      вселенских страданий…</p>

    <p>— Нет, — наверное, излишне резко
      ответил Максим. — Спасибо, не нужно.</p>

    <p>И вышел, не оборачиваясь. Хотя
      очень хотелось.</p>

    <p>Взять такси до Бывшей Конной
      Дороги тоже хотелось, но после автопоезда Максим уже понял — не выдержит,
      заснёт, не сможет не заснуть. И если вдруг Бедроградская гэбня так страшна, как
      сама себя малюет, окажется он после этого без сознания в каком-нибудь тёмном
      подвале, и дальнейшие вымирание города от чумы продолжится уже без Максима.</p>

    <p>Это всё несерьёзно, конечно, но
      попытки добраться-таки до своей квартиры надо оставить. Леший с ним, пойдёт к
      Габриэлю, раз уж оказался рядом, не скажет ни слова, ляжет в гостиной и наконец
      отдохнёт.</p>

    <p>У Габриэля пятиэтажный дом с
      девятиэтажным возвышением на углу — почти замок, почти башня. Видно издалека.
      Если приглядеться, можно даже различить задёрнутые шторы в спальне —
      тёмно-красный, кровавый бархат, балдахин над кроватью из такого же.</p>

    <p>Сразу войти Максим не смог,
      остановился покурить, собрать в кулак остатки спокойствия. Из подъезда вышла
      старушка с первого этажа, чихнула, поздоровалась, снова чихнула:</p>

    <p>— Это же у вас стиральный аппарат
      в квартире есть?</p>

    <p>Стиральный аппарат?</p>

    <p>Максим удивлённо нахмурился,
      кивнул.</p>

    <p>— Увезли его. Электрики, —
      догадалась, наконец, закрыться носовым платком соседка. — Прямо с утра. Там
      что-то где-то перегорело, весь подъезд обесточило. Сказали, вроде как из-за
      стирального аппарата. И ковёр ещё выносили — белый-белый такой, пушистый. Тоже
      ваш?</p>

    <p>— Ковёр-то тут при чём? — не
      понял Максим.</p>

    <p>— Так натекло с аппарата,
      запачкали. Завтра, сказали, почистят и вернут. Хорошие люди.</p>

    <p>Это не люди хорошие, чуть не
      сказал Максим, это Габриэль посмотрел на них так, что они теперь не только
      ковёр почистят, они ему завтра букет роз с извинениями в стихах пришлют.</p>

    <p>Записка на входной двери,
      впрочем, была другого мнения. Там что-то говорилось о том, что ввиду отсутствия
      хозяина квартиры ремонтной бригаде пришлось…</p>

    <p>Отсутствия хозяина квартиры?</p>

    <p>У него же сотрясение мозга, а в
      городе чума, о которой он не знает, — ну и куда его понесло вдруг?</p>

    <p>Перед тем, как открыть дверь,
      Максим дал себе слово: даже если Габриэля до сих пор нет, он не побежит
      неведомо куда его разыскивать, пока не поспит хотя бы пару часов. Ни за что не
      побежит, достаточно набегался уже за всю жизнь.</p>

    <p>Сначала — спать, потом всё
      остальное.</p>

    <p>Первое, на что падает взгляд
      любого, кто входит в эту квартиру, — кровать. На неё невозможно не смотреть,
      она прямо по курсу, она колоссальна, и над ней кровавого цвета балдахин.</p>

    <p>И Габриэля на ней нет.</p>

    <p>Максим только краем глаза отметил
      чужие следы на полу, грязные мыльные лужицы, ещё раз продублированную записку
      про характер аварии и сроки возвращения пострадавшего имущества, которую
      добросовестные электрики прикрепили на вешалку для ключей.</p>

    <p>Это всё ерунда, не стоит
      внимания.</p>

    <p>Габриэля на кровати нет.</p>

    <p>В спальне — бардак: одежда,
      сигареты, какие-то мелочи разбросаны по полу. Собирался на нервах, не мог найти
      что-то нужное и всё перевернул?</p>

    <p>Максим побыстрей
      отвёл взгляд от макулатуры и металлолома, оставленного на простынях.&nbsp;Ему
      не нужно это разглядывать, он и так знает, что это такое — письма к Гуанако,
      ключи от квартиры Гуанако. Священные реликвии, пролежавшие в прикроватной
      тумбочке тысячу лет. Чего он ещё ожидал?</p>

    <p>— Габриэль? — всё-таки позвал он.
      Вдруг дома, вдруг уже вернулся.</p>

    <p>Квартира ответила тишиной.</p>

    <p>Максим завернул на кухню, хотел
      поставить чайник, но ограничился коньяком — на столе стояла ополовиненная
      бутылка, рядом с ней от перевёрнутой ступки тянулась ароматная пустыня молотого
      кардамона и имбиря. Кляксы сбежавшего кофе на плите напоминали трупные пятна.</p>

    <p>Совершенно не хотелось гадать,
      почему Габриэль так психовал, что квартира
      превратилась в руины.</p>

    <p>Максим зачем-то зашёл в ванную,
      убедился в отсутствии стирального аппарата, поскользнулся слегка на мокром
      кафеле, задев плечом полочку. С полочки что-то упало, раскрылось, разлилось,
      запачкало стены и раковину. Максим не всматривался даже — разрушением больше,
      разрушением меньше.</p>

    <p>Глаза слипались, голова гудела.
      Спать, спать уже наконец.</p>

    <p>Не под кровавого цвета балдахином, конечно же, а на
      диване в другой
      комнате.</p>

    <p>В другой комнате без ковра было
      странно, пусто и непривычно. Что-то ещё тут было не так, но совершенно
      измотанный Максим не сразу сообразил, что именно. Он уже лёг (как был, в
      пропитавшейся дождём одежде), когда заметил на полу ворох старых литературных
      журналов — все выпуски за почти двадцать лет, Габриэль гордился своим
      собранием, но никогда на памяти Максима не перечитывал. Что ему вдруг взбрело в
      голову? Не электрики же в журналах копались…</p>

    <p>Максима вдруг поразила неприятная
      мысль: электрики ведь были в этой комнате, электрики ведь могли увидеть копии документов,
      которые он оставил Габриэлю ещё во вторник, надеясь объяснить, чем
      Университетская гэбня сейчас так занята, и, наверное, надеясь извиниться.</p>

    <p>С тяжёлым сердцем
      поднявшись с дивана, Максим подошёл к длинному письменному столу, за которым
      свободно можно было работать вдвоём. Копии документов лежали на виду, но,
      кажется, никто к ним со вторника так и не прикасался. Даже Габриэль.</p>

    <p>Стоило перепроверить, не пропало
      ли что. Максим уже взял проклятую стопку в руки (точно такую же он день за днём
      возил на Революционный проспект), как разглядел
      наконец, что в его собственную (не Габриэля!) печатную машинку был заправлен
      лист бумаги, на котором чернели всего несколько строчек.</p>

    <p>&nbsp;</p>

    <p><i>«Если мои просьбы для тебя хоть что-нибудь значат — то: пожалуйста, не
      отрывайся от работы. Я правда верю, что ваши дела
      важнее моих, и уж точно принесут тебе больше удовлетворения. Кроме того,
      поверь, ты даже не на первом месте в списке тех, кого могут позвать на
      опознание. Я, в отличие от Университета, справлюсь и без тебя».</i></p>

    <p>&nbsp;</p>

    <p>И ниже:</p>

    <p>&nbsp;</p>

    <p><i>«Прости, пожалуйста, прости — и попытайся просто быть счастливым».</i></p>

    <p>&nbsp;</p>

    <p>…Максим буквально физически
      ощутил, как под черепной коробкой медленно, неловко, с металлическим скрипом
      обрывки понимания попытались сложиться в мысли.</p>

    <p>Это невозможно — этого не должно быть
      — <i>это всё.</i></p>

    <p><i>«Ваши дела важнее моих».</i></p>

    <p>С чего ты взял, едва не выкрикнул
      в пустоту Максим, я же совсем не это имел в виду.</p>

    <p>Вырвал быстрым движением лист с
      печатными строчками, поднёс поближе к глазам: <i>«опознание», «не первый в списке», «справлюсь и без тебя».</i></p>

    <p>Максим и не догадывался раньше,
      что текст на родном языке может быть так сложен для понимания. В голову лезла
      одна ерунда: кого ещё во всем Бедрограде могут вызвать первыми на опознание,
      если не коллег с кафедры? И кто будет вызывать — следственный отдел под началом
      Бедроградской гэбни? Смешно. Надо было выбивать для Университета не только
      право самим решать проблемы с законом у своих людей, но и право самим хоронить
      свои трупы.</p>

    <p>Что он несёт, о чем он думает,
      леший.</p>

    <p>Это было так странно: всю долгую
      дорогу от Университета до Поплеевской, <i>до
        дома</i>, Максиму казалось, что он смертельно устал. Смертельно — не потому что
      сильно, а потому что усталость притупила мысли и ощущения, сделала голову
      стеклянной. Всю долгую дорогу от Университета до дома он думал тупо и через
      стекло, и уже успел поверить, что всё сумело-таки стать ему безразлично.</p>

    <p>Габриэль может быть безразличным <i>к кому-то</i>, но не безразличным <i>кому-то</i>. Уж точно не Максиму.</p>

    <p>Он любил «наверняка»,
      «несомненно» и «очевидно, что»; что рано или поздно случится <i>такая</i> записка, бардак в спальне и тишина
      — всегда было не несомненно, но очень наверняка.</p>

    <p>Но почему сейчас, почти закричал
      Максим — и знал, что это неправильный вопрос, правильный — зачем ты так. Правильный и глупый, только в припадке бессмысленного оптимизма
      можно подумать, что понимаешь, зачем Габриэль делает то или это. И он не
      объяснит, никогда не объясняет.</p>

    <p>Соседка сказала, что стиральный
      аппарат электрики вынесли ещё утром — и <i>уже</i> утром дома никого не было. Даже если Габриэль зачем-нибудь возвращался — может,
      из-за этого по всей квартире следы спешки, — прошла уже целая бесконечность.
      Надо срочно бежать по соседям, спрашивать, не видел ли кто, во
      сколько и куда уходил Габриэль.</p>

    <p>Куда (и как — как, с сотрясением
      мозга?) он мог поехать, чтобы покончить с собой? На ум ничего не шло, как
      Максим ни старался: сколько он помнил Габриэля, у того всегда был один дом —
      два, если считать кафедру. И ведь он смог бы выпить какие-нибудь таблетки и
      поехать в Университет — чтобы там, у всех на глазах…</p>

    <p>Нелепая, ускользающая надежда, и
      всё же больше, чем ничего. Надо позвонить Ларию, предупредить. И даже не только
      Лария, надо предупредить Святотатыча — вдруг Габриэль пошёл умирать в Порт,
      Порт — это Гуанако, корабли в Ирландию, куда они вдвоём плавали в проклятом мае… Об
      этом больно думать, но надо — надо хотя бы сейчас
      суметь предусмотреть всё, не поплатиться из-за халатности. Университет,
      Бедроград, сам Максим могут гореть огнём, особенно если кто-то из них позволяет
      себе отвергать помощь, но не Габриэль. Габриэля можно спасти, Габриэля <i>обязан</i> кто-то спасти.</p>

    <p>Стоящий тут же, между двумя
      печатными машинками телефон издал мелодичную и знакомую трель.</p>

    <p>Максим отстранённо осознал, что
      самолюбие всё-таки играет с ним дурную шутку: мысль о том, что эпидемию чумы
      могут остановить и без него, ранила в самое больное место, и поэтому он изо
      всех сил пытался стать незаменимым, и поэтому разбрасывался, не успевал;
      отсутствие ответственности нестерпимее её переизбытка. Так было со всем: с
      контрреволюцией, с чумой, с Университетом — Максим многое умеет, Максим сделает
      всё, чтобы помочь, защитить и справиться, но если Максим не нужен, то пусть оно
      катится к лешему.</p>

    <p>Так было со всем, кроме Габриэля.</p>

    <p>Если бы сейчас из-под осиротевших
      без ковра половиц вылез мифический леший и предложил Максиму продать душу, имя,
      уровень доступа и право бывать в этом доме, в обмен
      посулив спасти Габриэля, Максим не задумался бы ни на секунду. Пусть бы тот
      потом смотрел на него только со льдом, пусть бы вообще не смотрел — лишь бы…</p>

    <p>Телефон упрямо продолжал звонить.</p>

    <p>Сняв трубку, Максим толком не
      нашёл слов приветствия: звонил Ларий.</p>

    <p>— Габриэль Евгеньевич? Максим?</p>

    <p>— Максим.</p>

    <p>Ларий сделал паузу — он-то умел
      искать слова, выбирал сейчас самые аккуратные, чтобы не вызвать ещё большего
      раздражения у <i>ненормального</i> Максима,
      который устал, перенервничал и выбежал с факультета в чём был под дождь.</p>

    <p>Между печатными машинками, совсем
      рядом с телефоном, текстом вниз лежал один из выпусков «Литературы Нового Бедрограда» — не нужно даже всматриваться в
      зелёно-голубую обложку, чтобы догадаться, какой. В этом претенциозном издании в
      своё время вроде как печатали молодых авторов, но на совсем
      молодых смотрели косо. Рассказ второкурсника в «Литературе Нового Бедрограда» —
      событие не то чтобы неслыханное, но примечательное.</p>

    <p>Не нужно всматриваться
в обложку, потому что <i>все знают</i>: на
втором курсе Габриэль написал рассказ о том, как некто (очень похожий на него)
отправился в родную деревню в поисках единственно возможного ответа на
невыносимые вопросы, ушёл в леса и обнаружил там своего повесившегося двойника
(и вместе с тем —&nbsp;себя, и вместе с тем —&nbsp;просто какого-то случайного
человека в соответствующем контексте). В общем, про попытку телесной
      смерти, смерти духовной и духовного же перерождения.</p>

    <p>— Слушай, я понимаю, что ты
      устал, ты злишься на эту дурацкую ситуацию с запросом.
      Но — как сможешь — возвращайся, пожалуйста, на кафедру.</p>

    <p>— Зачем бы? Охровича и
      Краснокаменного вон нет, гэбня всё равно неполная.</p>

    <p>— Да, но я не могу один, у меня
      не хватает рук. Сейчас-то точно нельзя что-нибудь проворонить…</p>

    <p>— Ты за чем-то конкретным
      звонишь, или это снова некие абстрактные бесконечные дела, о которых я знаю
      только то, что они никому не нужны?</p>

    <p>Ещё <i>если не все, то многие знают</i>, что Габриэль Онегин на самом деле —
      Гаврила Онега, это излюбленный истфаковский курьёз. Родился на Пинеге, но сразу
      после отряда уехал в Бедроград, Евгения Онегу не признаёт
      и «возвращаться в родную деревню» не намерен. Что Евгений Онега ему не отец —
      информация уже более сложная для понимания, ей в курьёзе места не находится.</p>

    <p>— Сантехникам необходимо
      официальное разрешение на поисковую операцию. Мы пока только зачищали
      канализации в тех домах, где точно есть заражение, но это нерационально — вот
      как раз свободные сантехники-то у нас есть, это ж, как ты знаешь, не только
      студенческая практика. Кажется, — аккуратно посмеялся Ларий, —&nbsp;сантехники
      — это единственный свободный персонал, так что пусть не простаивают. Я могу
      подписать бумажку, но им нужен инструктаж по технике безопасности, карта города
      с заражёнными районами, тара для взятия проб, кого-нибудь из медиков бы в придачу
      — в общем, заняться этим делом надо плотно, и поскорее бы, а я ну никак не могу
      успеть… алё? Максим?</p>

    <p><i>Все знают</i> (леший!) про Габриэля столько всего, что любые попытки
      личной жизни превращаются в проходной двор, можно только сжать зубы и делать
      вид, что тебе всё равно.</p>

    <p>Но не все знают, что иногда два и
      два полагается складывать.</p>

    <p>Как бы ни было страшно.</p>

    <p>— Максим, я в курсе, что, если бы
      это были не мы, предложение сейчас пойти поговорить с сантехниками звучало бы смешно,
      но ты-то понимаешь, что это правда важно и нужно —&nbsp;и чем скорее, тем
      лучше.</p>

    <p>Пойти поговорить с сантехниками,
      потому что им нужен инструктаж и разрешение на поиски.</p>

    <p>Когда-то Максим верил, что в
      государственном устройстве должно быть <i>что-то
        большее</i> — большее, чем бюрократия, чем условности и протоколы. Отстранённо
      подумалось: как же так вышло, что даже во вверенном ему клочке государства он
      не сумел устроить это большее?</p>

    <p>— Если ты не можешь выкроить в
      своём чрезвычайно плотном графике времени на столь дорогое тебе городское дерьмо,
      то катись ты к лешему, Ларий, — вместе с
      сантехниками, канализациями, чумой и всем Бедроградом!</p>

    <p>Так бывает со всем, ещё раз
      признался себе Максим. Со всем, кроме Габриэля.</p>

    <p>И грохнул трубкой о телефонный
      аппарат, наслаждаясь коротким мигом звона, разлетевшегося по квартире. Он
      пожалеет об этом, конечно: Ларий всё делает правильно, старается удержать все
      нити в руках, ничего не упустить.</p>

    <p>Только нет сил
упоминать Габриэля в разговоре о сантехниках и бюрократии, просто нет
      сил, не поворачивается язык.</p>

    <p>Максим взял выпуск «Литературы
      Нового Бедрограда» в руки — без особой цели, он и так знал, что дальше делать,
      и не ожидал увидеть ничего необычного. Разумеется, открыто «Белое дерево»,
      рассказ Габриэля с давнего его второго курса. Рассказ Габриэля, который тот ни
      разу на памяти Максима, даже в минуты самой острой ностальгии, не перечитывал.
      На удивление хорошо сохранившаяся, хоть и довольно дешёвая, бумага, ставший
      почти символом времени шрифт с засечками —&nbsp;почти ничего необычного.</p>

    <p>Почти.</p>

    <p>Подхватив «Литературу Нового Бедрограда» и выдернутый из печатной машинки лист с
      запиской, Максим быстрым шагом (на бег, как он ни старался, всё-таки не хватило
      сил) бросился в коридор, накинул один из своих плащей —&nbsp;серый и нелюбимый,
      всегда бессменно висевший на вешалке.</p>

    <p>Последняя страница рассказа — та,
      на которой главный герой нашёл труп своего двойника, — была вырвана.</p>
  </>
}

export default chapter15
