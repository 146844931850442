import React from 'react'
import { IBookPage } from './index'

const chapter22: IBookPage = {
  header: 'header_d7.jpg',
  title: 'Глава 22. Два заветных слова',
  prevLink: 'chapter21',
  nextLink: 'chapter23',
  content: <>
    <h2>Университет. Дима</h2>

    <img alt='' src="/images/22_dima.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>Габриэля Евгеньевича нашли в
      Порту меньше часа назад, и Святотатыч сразу позвонил на кафедру. Подробностями
      делиться не стал принципиально, просто озабоченным тоном сообщил, что
      Университет хочет послать кого-нибудь условно-медицинского в Порт.</p>

    <p>Габриэля Евгеньевича нашли, и
      Святотатыч ни на что конкретно не намекал, но условно-медицинский посланник
      хотел иметь при себе хоть сколько-нибудь лекарства от чумы.</p>

    <p>Просто так, знаете, на всякий.</p>

    <p>Дима не хотел ехать. Вот не хотел
      и всё. Он видел, какими тихими и молчаливыми вернулись со встречи гэбен Ройш с
      Ларием, какими громкими и злыми — Охрович и Краснокаменный. Он видел, что,
      услышав о чумном Габриэле Евгеньевиче в Порту, Ларий встрепенулся и метнулся к
      телефону, а Ройш безразличным тоном осадил его, сказав, что шансов успеть уже нет.
    </p>

    <p>Университетскую гэбню <i>уже</i> отстранили.</p>

    <p>И никто не сказал этого вслух, но
      все очень явно подумали о том, что, наверное, лучше было дать Гуанако взорвать
      свой желанный юбилей Первого Большого. И не
      встречаться.</p>

    <p>Дима не хотел ехать в Порт за
      Габриэлем Евгеньевичем, потому что всё никак не уходило ощущение, что его,
      Димино, присутствие на кафедре делает гэбне чуть лучше. Он даже не лез особо с
      разговорами, просто сидел на диванчике под чучелом Твирина и смотрел, как Ларий
      заворачивает в грубую бумагу извлечённую из сейфа
      кобуру.</p>

    <p>За одиноким табельным пистолетом
      Лария вот-вот должен был приехать курьер.</p>

    <p>На кафедру забежал Стас Никитич —
      задним числом черкануть заявление почерком Максима о порче его табельного
      оружия. На самом деле табельное оружие истаяло вместе с самим Максимом, но
      раскрыть это — значит расписаться в том, что Максим пропал.</p>

    <p>Навлечь на него проблемы, когда
      он найдётся.</p>

    <p>Это «когда» взамен «если» было не
      от чрезмерной жизнерадостности, а вовсе даже и наоборот (лучше бы Максим
      сгинул, честное слово). Но как с самого начала было ясно, что Габриэль
      Евгеньевич заразился, так и — что Максим жив и здоров, просто хуит.</p>

    <p>Должно же в этом мире быть хоть
      что-то предсказуемое и гармоничное.</p>

    <p>&nbsp;</p>

    <p>Охрович и Краснокаменный молча
      копались в кафедральном шкафу, метая в мешок какую-то одежду, вещи, свёртки
      бумаги.</p>

    <p>— Решили-таки последовать примеру
      передовых голов гэбен и свалить? — окликнул их Дима.</p>

    <p>Охрович и Краснокаменный
      обернулись на него с яростью паяльников в глазах (в смысле паяльников, торчащих
      из глаз, а не паяльников, туда воткнутых, хотя этот образ открыт для
      интерпретации).</p>

    <p>Самым стрёмным в них было отсутствие портретного
      сходства:
      посмотришь на фотокарточки этих двух людей —&nbsp;никогда не придёт в голову
      называть их через постоянное «и». Оба, конечно, высокие и скорее
      крупногабаритные, но у Охровича круглое лицо, тёмно-русые волосы и страшного
      такого цвета глаза —&nbsp;вроде бы карие, но Дима был довольно твёрдо уверен в
      том, что так выглядит какой-нибудь неведомый сплав в жидком виде. Краснокаменный, наоборот, был весь какой-то рыбный — светленькие
волосы, никакие (бледно-голубые, что ли) глаза, тяжёлые веки, желтоватая кожа,
да ещё и поросль на лице, выдающая себя за усы. И если Охровича хотелось
      представить себе на рынке у лотка с цветными сувенирными куклами по бешеным
      ценам, то Краснокаменного — исключительно с гитарой на задворках Старого города.
      Этим двоим вообще не полагалось в жизни встречаться.</p>

    <p>Но потом они оказывались вместе в
      поле зрения и начинали одинаково двигаться, одинаково говорить, одинаково зло
      усмехаться и одинаково нехорошо прищуриваться. И тут в мозгу
у любого нормального человека (читай: Димы) возникало
неуютное зудящее чувство, намекающее, что что-то где-то не так, потому что два
таких разных человека не могут быть такими одинаковыми, и, короче, лучше просто
об этом не думать. И по возможности не смотреть в их сторону.</p>

    <p>И уж точно не ставить под вопрос
      их лояльность.</p>

    <p>— Я вообще до сих пор не могу
      понять, почему вы никого не сдали Бедроградской гэбне. Это бы очевидным образом
      приумножило веселье.</p>

    <p>— Да, поэтому мы и сдали тебя, —
      огрызнулись Охрович и Краснокаменный, отрываясь от разграбления кафедры.</p>

    <p>— Завтра, напоминаем, у Дмитрия
      Борстена рандеву с одним из голов.</p>

    <p>— Дмитрий Борстен — это ведь ты?</p>

    <p>— Если нет, то всё равно зайди
      непременно, скажи, чтоб тебя не ждали.</p>

    <p>— Бедроградская гэбня оценит.</p>

    <p>— Пригласит провести с ними часы
      досуга.</p>

    <p>—&nbsp;Побеседовать о высоком.</p>

    <p>— Возможно, произведёт тебя в
      Александры.</p>

    <p>— Вам не кажется, что шутка про
      Александра начинает устаревать? — не удержался Дима.</p>

    <p>— Вместе с прообразом,
      —&nbsp;пожали плечами Охрович и Краснокаменный.</p>

    <p>— Александру давно пора выйти в
      тираж.</p>

    <p>— Пятьсот экземпляров, по числу
      инновационных неопробованных методов психологического воздействия, которыми мы
      располагаем.</p>

    <p>Соль веселья заключается в том,
      что невозможно понять, придуриваются они или сейчас,
      обобрав останки шкафа, выйдут из здания Университета и поедут кого-нибудь
      убивать.</p>

    <p>И ведь не спросишь.</p>

    <p>— Вы намереваетесь ехать убивать
      Бедроградскую гэбню?</p>

    <p>В качестве ответа на этот
      невинный вопрос Диме прилетело что-то ОЧЕНЬ БЛЯДЬ БОЛЕЗНЕННОЕ по обоим плечам.
      Он подскочил и оскорблённо завопил. Охрович и Краснокаменный удовлетворённо
      осмотрели хлысты в своих руках.</p>

    <p>—&nbsp;Для начала мы
      потренируемся.</p>

    <p>— У нас теперь нет пистолетов,
      так что удар должен прийтись точно в цель.</p>

    <p>— Вот так —&nbsp;убедительно?</p>

    <p>На сей раз хлысты съездили Диме
      по лицу и шее, и этого он терпеть уже не намеревался, ввиду чего отпрыгнул
      подальше, за подлокотник дивана.</p>

    <p>Ларий посмотрел на Охровича и
      Краснокаменного с откровенной опаской.</p>

    <p>— Вы же это не всерьёз, правда?</p>

    <p>Разумеется, даже звание головы
      гэбни (временно безработного) не спасло его от жестокой расплаты за то, что он
      осмелился заподозрить Охровича и Краснокаменного в серьёзности.</p>

    <p>— Представляется, что вооружиться
      хлыстами&nbsp;в качестве минимального средства самообороны — более разумное
      решение, чем нам всем хотелось бы думать, — впервые за всё это время подал
      голос Ройш, неподвижно стоявший у окна. — Мы попытались найти дипломатическое
      решение, и наша попытка обернулась неудачей. Бедроградская гэбня вполне
      прозрачно дала понять, что дальнейшее взаимодействие развернётся на силовом
      поприще.</p>

    <p>— Гадская
      Бедроградская гэбня! —&nbsp;заорали Охрович и Краснокаменный, кидая свои хлысты
      во всё тот же масштабный мешок и заламывая руки.</p>

    <p>— Гадская Бедро-гадская!</p>

    <p>Дима не удержался и фыркнул, хотя
      по-хорошему явно следовало разрыдаться.</p>

    <p>Взаимодействие на силовом поприще
      почему-то не казалось ему обнадёживающей перспективой.</p>

    <p>Почему-то вот очень не казалось.</p>

    <p>— Я надеюсь, ты шутишь,
      —&nbsp;Ларий, сокрывшийся от карающих хлыстов за своим столом, откинулся на
      стуле, аж проехал пару сантиметров по паркету. — Они
      не станут, не решатся просто.</p>

    <p>— Почему нет? — Если бы Ройш не
      был Ройшем, в его голосе наверняка промелькнула бы досада и даже некоторое
      раздражение. — Тебе, как и всем присутствующим, известны их методы.
      Бедроградская гэбня присутствовала на той же встрече, что и мы, они не могли не
      осознать, что бюрократия исчерпала себя. Проложив себе дорогу легальным образом,
      они, несомненно, сделают всё, что возможно, чтобы завершить дело образом нелегальным.
      С учётом того, сколько сил, денег и времени вложено в план по уничтожению
      Университета, с их стороны было бы попросту глупостью упустить подобный шанс.</p>

    <p>— Вы пугаете нас, Константин
      Константьевич, —&nbsp;завыли Охрович и Краснокаменный.</p>

    <p>— Вы звучите как человек, готовый
      бросить Университет и подкинуть Бедроградской гэбне свой план только затем,
      чтобы он не сгнил в вашем блестящем уме.</p>

    <p>—&nbsp;Как великий стратег.</p>

    <p>— Как человек, которому место на
      какой-нибудь управляющей должности.</p>

    <p>— В гэбне, например?</p>

    <p>Дима вот сидел, сидел и как раз
      намеревался намекнуть, что у всей этой большой и печальной проблемы есть
      простое и очевидное решение, а они, глядите-ка, сами догадались. Он вопросительно
      посмотрел на Лария.</p>

    <p>— Я говорил с фалангами, —
      решительно кивнул тот. — Честно скажу, по моим ощущениям — они сами в некотором
      замешательстве. Дали нам три дня, чтобы мы предложили свою кандидатуру на
      должность четвёртого головы Университетской гэбни. При этом на эти три дня мы
      действительно не гэбня, оружие нам не полагается, полномочия тоже, но вопрос о
      том, кто, собственно, управляет Университетом, фаланга старательно обходил. —
      Ларий оживлённо постучал пальцами по столешнице. —&nbsp;Состав нашей гэбни никогда
      не менялся. По-моему, им просто не
      хочется этим заниматься. Надеются, что разберёмся сами, а за три дня ничего не
      случится.</p>

    <p>Три дня, за которые ничего не
      случится?</p>

    <p>По нынешним меркам это довольно
      остроумная шутка.</p>

    <p>—&nbsp;Поправьте меня, если я не
      вижу какой-то бюрократической наёбки, — осторожно (то есть
      высунувшись из-за подлокотника дивана, но пока что не целиком) заметил Дима, —
      но не кажется ли вам, что все проблемы можно решить, позвонив — или написав,
      или чего там полагается — фалангам прямо сейчас и предложив-таки кандидатуру на
      должность почётного Максима?</p>

    <p>Не в том смысле Максима, что
      этому человеку полагается сбежать в ответственный момент, а в том, что он
      станет головой Университетской гэбни. Побег тут ни при чём. Дима вообще уже
      думать забыл о побеге. Вообще совсем. Сущая правда. И
      толком не понимал, чего так на Максима взъелся — в основном, видимо, потому,
      что Максим первым взъелся на него.</p>

    <p>Каков запрос —&nbsp;таков ответ,
      ну или как там полагается говорить в этой стране.</p>

    <p>(Пусть никто не
поймёт неправильно: Дима всё ещё злился, но злился спустя рукава, по
      инерции. И всё ещё использовал смешанные метафоры, а вот это
уже неутешительно.)</p>

    <p>— А давайте выдвинем кандидатуру
      Дмитрия Борстена? — предложили Охрович и Краснокаменный.</p>

    <p>— Он полноценный гражданин
      Всероссийского Соседства, даром что без прописки.</p>

    <p>— Он всегда был без прописки.</p>

    <p>— Пропишем его к Ройшу.</p>

    <p>— Никто ни о чём не догадается,
      Ройш вечно укрывает у себя сомнительных мужиков.</p>

    <p>— Ещё не старый, а уже такой развратник!</p>

    <p>— А
      по-моему, кандидатура очевидна, — крикнул Дима. И ткнул пальцем в Ройша, если
      кто не уловил его указующий взор.</p>

    <p>(Палец, как и
рука, кстати, всё ещё были по локоть в «Вороновом крыле» от Габриэля
Евгеньевича. Так тот и сохранится в веках, видимо: стопка
      высокохудожественных романов и несмываемые чёрные пятна на руке у одного
      хорошего человека.</p>

    <p>Как можно догадаться, все эти
      разговоры происходили до звонка Святотатыча, который поведал, что Габриэля
      Евгеньевича нашли в Порту. Потому что после звонка Дима очень не хотел ехать,
      хотел посидеть ещё немного на кафедре и поговорить, но, конечно, поехал. Почти даже
      сразу.)</p>

    <p>Все обернулись на Ройша.</p>

    <p>Всем была, в общем-то, очевидна
      кандидатура.</p>

    <p>Ройш лучше прочих присутствующих
      (вместе взятых, причём в квадрат) понимает в бюрократических наёбках, Ройш до
      мозга своих тощих костей университетский, судьба Ройша была предопределена
      задолго до его рождения.</p>

    <p>— Да, —&nbsp;сказали Охрович и
      Краснокаменный (впервые на Диминой памяти хором).</p>

    <p>— Нет, —&nbsp;сказал Ройш.</p>

    <p>— А мы сказали — да, — повторили
      Охрович и Краснокаменный, надвигаясь на него.</p>

    <p>— Или нет? Даже за одну встречу
      гэбен наши пальцы устали.</p>

    <p>— Физическая синхронизация с
      Усиковым Вором слишком утомительна.</p>

    <p>— Кроме того, мы презираем
      лживость.</p>

    <p>— И тех, кто ставит находку
      объекта для потери невинности выше политики.</p>

    <p>— Давай мы украдём твою
      невинность, а потом скажем всем, что её никогда и не было?</p>

    <p>— Уж тогда-то ты не сможешь нам
      больше отказывать!</p>

    <p>— И вернёшь усики Золотца!</p>

    <p>— Почему нет? —&nbsp;сказал наконец Ларий.</p>

    <p>Вероятно, это был ответ на
      изначальное твёрдое ройшевское «нет», а не на высказывания по вопросам
      невинности. Хотя варианты возможны.</p>

    <p>Ройш полуобернулся и позволил
      себе приподнять брови.</p>

    <p>Бушевание страстей!</p>

    <p>— Я не обязан аргументировать
      свою позицию, — холодно провозгласил он (по-прежнему не вопросы невинности!), —
      но, с другой стороны, не вижу причин этого не сделать. Все мои заверения в том,
      что я не заинтересован в управляющей должности и не желаю быть элементом
      государственного аппарата, по-прежнему остаются в силе. Одно дело —&nbsp;найти
      и использовать — напомню, к слову, что безуспешно — несостыковку
      в служебной инструкции и временно принять на себя обязанности головы гэбни в
      экстренной ситуации. Другое — принять на себя это бремя на длительный период
      жизни. — Ройш даже повернулся всем корпусом, качнулся вперёд — ну точно сейчас
      лопнет от переизбытка чувств. — У меня складывается впечатление, будто вы все
      забываете о том, что существует мир за пределами чумы в Бедрограде. Так или иначе нынешний конфликт завершится, и Университетская гэбня
      продолжит функционировать вне его. Или не продолжит, но в таком случае весь
      этот разговор принципиально бессмыслен. Так вот: я предельно лоялен
      Университету, и вряд ли кто-то имеет право обвинить меня в недостаточной помощи
      по любым вопросам, в которых я сведущ. Во многом моя помощь оказывается
      действенной именно потому, что я использую неофициальные — и, как вам всем,
      несомненно, известно, не всегда законные — каналы. Стань я головой гэбни, моя и
      без того чересчур заметная персона привлекла бы ещё больше внимания. Это закрыло
      бы многие пути взаимодействия с государственным аппаратом. Поверьте, господа:
      моё функционирование вне гэбни основывается не только на моих, но и на ваших
      интересах.</p>

    <p>Прочувствованный монолог вызвал
      отклик в сердцах людей: Охрович и Краснокаменный сперва
      освистали Ройша, но потом, поразмыслив, выдали ему заслуженный раунд жиденьких
      аплодисментов. Ларий только печально кивнул.</p>

    <p>— Но кого тогда? — с искренним
      недоумением в голосе спросил он.</p>

    <p>Вот хороший же человек Ларий,
      приятный и всё такое, но настолько иногда несообразительный. Сообщить ему, что
      ли, что на одном только истфаке добрая сотня сотрудников?</p>

    <p>А если позволить своей фантазии
      буйство и вспомнить, что в Университете есть и другие факультеты!</p>

    <p>Это не Димино, в общем-то, дело,
      но леший же в ухо.</p>

    <p>— Попельдопеля, например? —&nbsp;сдёрнулось с потолка ближайшее имя. — Будет ему
      повышение.</p>

    <p>— Человека <i>не с истфака</i>? —&nbsp;ужаснулся Ларий.</p>

    <p>— А у вас что, гэбня истфака? —
      возмутился Дима.</p>

    <p>— А ты у нас что, имеешь право
      голоса? — надвинулись на него Охрович и Краснокаменный.</p>

    <p>— Свой голос завтра применишь,
      когда на тебе будут практиковать силовые методы взаимодействия.</p>

    <p>— Или не силовые. Все знают, что
      в душе ты слаб.</p>

    <p>— И твои предпочтения тоже все
      знают.</p>

    <p>— От всей своей слабой души
      благодарю за тренировку моей психической устойчивости, — пробурчал Дима и
      повернулся к Ларию. —&nbsp;Я не лезу в ваши дела, я просто имею неприятную
      привычку думать. Пока вы там ругались на встрече гэбен, Гуанако поводил меня по
      университетским территориям и наглядно, то есть пальцем, показал, сколько
      народу —&nbsp;с юрфака в основном —&nbsp;завербовано всеми нами любимой
      Бедроградской гэбней. Куча народу. Большая куча. Может, в этом часть проблемы?
      Может, университетская гэбня хочет стать чуть более
      гэбней Университета и чуть менее гэбней кафедры истории науки и техники
      истфака?</p>

    <p>(Смотрите и
завидуйте: Дима не лезет в чужие дела. Отлично получается, не правда ли.</p>

    <p>В общем, на
месте Максима он бы тоже, наверное, на себя взъелся.)</p>

    <p>Надо бы со всем этим завязывать,
      но недопечёный эмбрион (медицинское образование — залог
      богатого словарного запаса) логики где-то в регионе мозга всё-таки
      подсказывает, что на самом деле надо было с самого начала не лезть, а теперь
      поздно уже делать вид, что он в этом всём не по уши.</p>

    <p>Остаётся прикинуться
      последовательным.</p>

    <p>— Гэбня Университета останется
      нашей.</p>

    <p>— И Лария мы не отдадим, он
      смешной.</p>

    <p>— Он нас любит и ценит.</p>

    <p>— Ценит ли нас Попельдопель?</p>

    <p>— Хочет ли Попельдопель политики
      —&nbsp;или, возможно, он хочет только ковыряться в своих вирусах?</p>

    <p>— Ты о его интересах подумал,
      бессердечный чурбан?</p>

    <p>Ларий тоже посмотрел на Диму с
      глубокими сомнениями на лице.</p>

    <p>— Надо бы посоветоваться с
      Сергеем Корнеевичем —&nbsp;и с самим Юром Карловичем, конечно, тоже… Охрович и
      Краснокаменный ведь правы, не захочет он в гэбню.</p>

    <p>Кажется, кто-то здесь кого-то
      недопонял.</p>

    <p>(Вот всегда кто-то кого-то
      недопонимает, а потом просыпаешься уже в камере на Колошме.)</p>

    <p>— Да не настаиваю я на
      Попельдопеле! —&nbsp;возопил Дима. — Я просто пытаюсь тонко намекнуть, что если
      все ваши жизни в такой страшной опасности из-за того, что у вас нет четвёртого
      головы, <i>возможно</i>, вам стоит начать
      думать о том, кого бы подыскать?</p>

    <p>Охрович и Краснокаменный бережно
      извлекли из шкафа бутылку какой-то жидкости, раскупорили её, быстро понюхали,
      снова завернули и метнули всё в тот же мешок.</p>

    <p>—&nbsp;Возможно, стоит, — хмыкнули
      они и синхронно приподняли левые брови.</p>

    <p>— Но зачем?</p>

    <p>— Разве тебе чем-то не нравится
      возможная альтернатива?</p>

    <p>— Фаланги дали понять, что
      раньше, чем через три дня, мы всё равно не сможем полноценно вернуться к
      обязанностям, — покачал головой Ларий. — Так что спешить некуда.</p>

    <p>Диме осталось только мысленно
      воспеть своё новое любимое заклинание.</p>

    <p>Всё могло быть куда хуже.</p>

    <p>(Побочный эффект заклинания
      включал в себя детальное описание того, как именно хуже, но с этим оставалось
      только мириться.)</p>

    <p>Пока что даже почти все живы.</p>

    <p>Почти все.</p>

    <p>Дима же не видел трупов.</p>

    <p>— С учётом перспектив ситуации я
      не думаю, что тебе следует посещать завтрашнюю встречу, — негромко обратился
      Ройш к Диме. — Неявка может спровоцировать Бедроградскую гэбню на агрессивные
      действия, но гораздо проще переждать в наиболее надёжных местах несколько дней,
      чем самовольно идти им в руки.</p>

    <p>Ларий покосился на Ройша с
      очередной гримасой сомнения, но прибавил:</p>

    <p>— К сегодняшнему вечеру
      подавляющее большинство заражённых в Бедрограде
      получат лекарство. Не все найдены, не все ещё выявлены… не
все находятся в пределах города. Обходами и уколами найденных больных
      успешно занимаются студенты медфака, и вряд ли Бедроградская гэбня станет
      ловить их, им же не это нужно! А нам — здесь присутствующим, и Юру Карловичу, и
      Андронию Леонидовичу, и Сергею Корнеевичу,
      за которого, впрочем, я бы волновался меньше всего — вовсе не обязательно
      покидать территорию Университета в ближайшие дни. Можно просто отсидеться.</p>

    <p>— Игра уже не идёт по правилу
      «кто милее фалангам», — Охрович и Краснокаменный заржали.</p>

    <p>— Игра идёт по правилу «кто кого
      переживёт».</p>

    <p>— Тот, кто отправится завтра тетешкаться
      с Социем, не переживёт.</p>

    <p>— С наибольшей вероятностью он
      получит по голове чем-нибудь тяжёлым и станет объектом для шантажа.</p>

    <p>— Примерно как Бровь, о судьбе
      которой ему обещали рассказать.</p>

    <p>— И расскажут, они же всегда держат
      слово!</p>

    <p>— Не припомнить и случая, когда
      Бедроградская гэбня была бы нечестна.</p>

    <p>— Дима пойдёт, чтобы узнать про
      Бровь, следующий пойдёт, чтобы узнать про Диму.</p>

    <p>— Может, распределим места в
      очереди уже сейчас?</p>

    <p>Ах вот
      оно что.</p>

    <p>Ну
      спасибо вам, о Охрович и Краснокаменный, честнейшие из честнейших.</p>

    <p>О незначительном нюансе,
      связанном с Бровью, Диме до них никто не говорил. И, судя по рожам
      Ройша и Лария, не намеревался.</p>

    <p>Дима разозлился бы, но как
      разозлишься, когда Ройш первым же отговаривает от встречи. Не нужно быть ни
      гением, ни самородком, чтобы догадаться, нравится ли Ройшу такая ситуация.</p>

    <p>А ещё, например, не только Ройша
      волнует Бровь.</p>

    <p>Ещё, например, не Ройш тупо забыл
      сказать Максиму, где она должна ночевать.</p>

    <p>(И именно с тех пор никто не
      видел — ни Брови, ни трупа.)</p>

    <p>В общем, Дима всё-таки
      разозлился.</p>

    <p>И прекрасно знал, на кого, но
      уютнее было думать, что на этих четырёх уродов.</p>

    <p>— Вряд ли вы стали бы меня
      отговаривать, если бы не знали, что я пойду —&nbsp;так и так пошёл бы.</p>

    <p>Знали, конечно, — Ларий вон с
      готовностью проглотил тоскливый вздох.</p>

    <p>— Назови хотя бы одну причину,
      которая привнесла бы в это решение подобие элемента разумности,
      —&nbsp;монотонно потребовал Ройш.</p>

    <p>Даже и не придумать, с чего
      начать.</p>

    <p>— Весь этот роман с Бедроградской
      гэбней — просто развёрнутая вариация среднеотрядской
      влюблённости, — голосом диктора, зачитывающего древнеросскую
      былину, поведал Дима. — Жить постоянными догадками, пытаться додумать, что у
      пассии в голове, тыкать наугад —&nbsp;короче, свихнуться можно. Не знаю, как
      вам, а мне надоело. У меня вообще сложные отношения с ситуациями, допускающими
      чрезмерное количество исходов. Отсидеться три дня —&nbsp;и что дальше? Угодить
      в дурку на четвёртый, пытаясь понять, что ожидает завтра?
      Просто расслабиться? Я, конечно, тот ещё придурок, но
      мои высокие навыки интроспекции громко сообщают, что лично у меня не получится.
      Меня зовут поговорить —&nbsp;я пойду поговорить.</p>

    <p>— Причины по-прежнему не
      прозвучало, — не без ехидства заметил Ройш.</p>

    <p>— Ты знаешь её не хуже меня,
      —&nbsp;буркнул Дима. —&nbsp;В конце концов, если я буду достаточно милым и
      очаровательным, мне даже могут из любезности показать её тело.</p>

    <p>Некоторым вон даже позволяли тела
      целовать. Хотя это, конечно, совсем другая история.</p>

    <p>У Димы подобных интенций в адрес
      Брови не имелось, но он был готов послужить посредником.</p>

    <p>Ройш вздохнул.</p>

    <p>— Я знаю, что ты никогда не
      отличался здравостью в принятии решений и что твоё желание подставиться
      граничит с клиническим состоянием. Меня в меньшей степени интересуют причины
      такого поведения, хоть они и видны невооружённым глазом, нежели его возможные
      последствия. В прошлый раз, когда ты добровольно ушёл с кем-то из Бедроградской
      гэбни, дело закончилось Колошмой.</p>

    <p>Колошмой, на которой обнаружился
      подозрительно живой Гуанако.</p>

    <p>Гуанако, которого Дима к тому
      моменту как раз умеренно благополучно похоронил.</p>

    <p>Гуанако, с похорон которого,
      видимо, и пошла мода на странноватые поступки в среде Димы.</p>

    <p>— Да, и я ничуть об этом не
      жалею, — брякнул он.</p>

    <p>— Верю, — ничего не выражающим
      голосом ответил Ройш. —&nbsp;Но, возможно, об этом жалеет кто-то другой.</p>

    <p>Это прозвучало достаточно
      проникновенно, чтобы Охрович и Краснокаменный решили не спасать Диму своими
      комментариями.</p>

    <p>Свежая новость: не только Диме не
      нравится хоронить близких людей.</p>

    <p>Свежая новость с альтернативного
      радиоканала: Дима гарантированно побывал на большем числе допросов, чем любой
      из присутствующих. По крайней мере, в качестве допрашиваемого.
      У Димы наилучшие шансы что-нибудь выведать и выбраться целым, как обычно.</p>

    <p>Хотя, конечно, кому это он сейчас
      рассказывает.</p>

    <p>Просто страшно хочется поглядеть
      Бедроградской гэбне в глаза — хотя бы в одну пару из четырёх.</p>

    <p>— Откуда вам знать — может, вся
      чума изначально была моим личным способом добиться аудиенции у Бедроградской
      гэбни, они же мне страсть сколько должны.</p>

    <p>И проценты набежать успели.</p>

    <p>Ройш продолжал созерцать Диму,
      явно желая что-то сказать и явно не желая признавать
      это своё желание.</p>

    <p>— Подумай об этом с практической
      точки зрения, — предпринял ещё одну попытку Ларий. — Личность Дмитрия Борстена
      — довольно прозрачная липа. У Дмитрия Борстена нет квартиры, нет соседей, нет
      знакомых вне Университета — он может совершенно безболезненно пропасть. Им даже
      не придётся быть осторожными. Если ты всё расскажешь… и если ты останешься у
      них в руках — наше положение станет ещё хуже. Отдавать такое преимущество
      просто из упрямства безответственно, в конце концов.</p>

    <p>Нашёл, кого и чем стращать. Дима давно уже перешагнул ту грань, за которой
      волнуются об ответственности.</p>

    <p>Он не должен отвечать за то, что
      кто-то будет плакать о его исчезновении.</p>

    <p>Он же не давал никаких гарантий
      безопасности.</p>

    <p>Он же ничего им не обещал.</p>

    <p>С чего они взяли, что он не
      исчезнет с горизонта.</p>

    <p>(У него уже почти хватает навыка самоубеждения, чтобы правда в это верить.)</p>

    <p>— Если несуществующий Дмитрий
      Борстен что-то кому-то и расскажет, россказни его не будут иметь юридической
      силы, как ты сам любезно заметил, — хмыкнул Дима. — Что касается фактической
      информации — думаешь, она их правда до сих пор волнует? А «ваше положение» —
      это большая политика. Я к большой политике отношения не имею, меня и в
      природе-то не существует.</p>

    <p>В ответ на это он снова получил
      по лицу хлыстами.</p>

    <p>Весьма коварно со стороны
      Охровича и Краснокаменного было делать вид, что они припрятали их в мешок!</p>

    <p>— Пока что следов не остаётся, —
      назидательно сообщили Охрович и Краснокаменный.</p>

    <p>— Но это последнее
      предупреждение.</p>

    <p>— Только абсолютное равнодушие к
      твоей судьбе останавливает нас от того, чтобы попросту тебя связать и оставить
      здесь.</p>

    <p>—&nbsp;В шкафу теперь много
      свободного места.</p>

    <p>—&nbsp;А ты ведь любишь, когда
      тебя связывают, верно?</p>

    <p>— Мы проконсультируемся с Гуанако
      относительно оптимальной позиции.</p>

    <p>— Даже абсолютное равнодушие к
      твоей судьбе не позволит нам отнестись к своему прямому призванию с
      недостаточным тщанием.</p>

    <p>Дима собирался озвучить своё мнение
      относительно невыпускания его на встречу
      насильственным образом, но как раз в этот момент и позвонил Святотатыч.</p>

    <p>Габриэля Евгеньевича нашли в
      Порту, так что пусть Дима приезжает.</p>

    <p>И поскорее.</p>

    <p>— По крайней мере, у вас больше
      нет морального права запирать меня в шкафу, — сообщил он, вешая телефонную
      трубку, которую ему передал Ларий, как только понял, что к чему. — Порт в лице
      нашедшегося Габриэля Евгеньевича ждёт меня и моих шприцов.</p>

    <p>— Ты уйдёшь отсюда, только если
      гарантируешь, что Габриэль Евгеньевич до сих пор сохраняет свою эстетическую
      ценность, — надменно постановили Охрович и Краснокаменный.</p>

    <p>— Его чума уже на той стадии, в
      которой отваливаются конечности?</p>

    <p>— Без конечностей он нам не
      нужен.</p>

    <p>— Подобные фетиши слишком редки,
      чтобы вкладывать в них силы.</p>

    <p>— Мы решили отныне следовать
      вкусам массового потребителя.</p>

    <p>— Так сказать, продаться толпе.</p>

    <p>— Нам нужны ресурсы.</p>

    <p>—&nbsp;<i>Ресурсы</i>, Дима. Нужны нам.</p>

    <p>— Габриэль Евгеньевич —&nbsp;ещё
      ресурс?</p>

    <p>— Не знаю, ничего не знаю,
      —&nbsp;махнул рукой Дима. — Святотатыч звучал… озабоченно. И ничего не
      рассказал — ни где нашли, ни как, ни в каком состоянии. Хотите знать — придётся
      меня отпустить.</p>

    <p>Набор юного медика по-прежнему
      был в сумке, которую Дима нынче даже с плеча не снимал, дабы нигде не забыть.
      Спасибо, он знал себя. В общем, можно было вставать и ехать, даже куртку
      накидывать не надо. Куртки (равно как и плаща, пиджака, пальто, шубы или
      мантии) у Димы не было.</p>

    <p>В смысле, вообще не было в
      Бедрограде.</p>

    <p>Он, так ска��ать, тут налегке.</p>

    <p>(Всё, что хранилось на бывшей гуанаковской
      квартире в черте города, давно уже присвоили Охрович и Краснокаменный, до Ройша
      всё никак не получалось доехать, а в ворохе шмоток, оставшихся в Порту, верхней
      одежды почему-то не обнаружилось.)</p>

    <p>И леший бы с ней, просто сейчас
      это наблюдение почему-то привнесло в и без того
      увлекательную жизнь столь необходимую нотку лёгкой
      растерянности.</p>

    <p>Что Дима вообще делает в
      Бедрограде?</p>

    <p>То же, что и всегда.</p>

    <p>Хуйню какую-то.</p>

    <p>—&nbsp;Не думаю, что тебе стоит
      ехать одному, — заметил традиционно здравый Ларий. — В
      восемь Сергей Корнеевич обещал заглянуть — он всё равно наверняка в Порт.
      Дождёшься?</p>

    <p>Дима дождался, благо оставалось
      всего ничего. Потерпев из вежливости несколько минут,
      Ройш заявил, что его не интересует повторное обсуждение прошедшей встречи гэбен
      и что он, соответственно, направляется домой. На предостережение Лария о том,
      что, возможно, ему тоже не следует ехать куда-то одному, Ройш снисходительно
      заметил, что желающие могут его сопроводить, и вышел. Охрович и Краснокаменный,
      повозмущавшись должное количество времени, подхватили
      свой мешок и вышли следом.</p>

    <p>Дима посидел ещё немного с
      Ларием, потрепался о насущных мелочах, потом посидел ещё немного с Ларием в
      неловкой тишине, а потом решил, что он, пожалуй, предпочтёт ждать Гуанако за
      дверьми кафедры.</p>

    <p>&nbsp;</p>

    <p>И дождался.</p>

    <p>— Здравствуй, мрачная рожа, — жизнерадостно поприветствовал его из конца коридора
      Гуанако, резво подбегая и оставляя за собой картинную цепочку грязных следов. —
      Всё опять плохо, и мы с Попельдопелем зря сочиняли сказки?</p>

    <p>«Да», — хотел ответить Дима, но не
      ответил.</p>

    <p>Разве можно ломать мечты столь
      мокрого человека.</p>

    <p>После того, как Гуанако потыкал
      Диме пальцем в юрфаковских наблюдателей и они вдвоём
      немного просто пошлялись по университетским землям, покойный профессор
      распрощался и ускакал в неизвестном канализационном направлении. Его почему-то
      крайне прельщала идея сфабриковать данные о естественном
      происхождении чумы, так что он, видимо, потратил всё последнее время на чтение
      технической документации от Лария, осмотры водохранилищ и обсуждение чтения и
      осмотров с Попельдопелем.</p>

    <p>Что было бы полезно, если бы было
      полезно.</p>

    <p>— Привет и тебе, Маэстро
      Дружелюбие, —&nbsp;пробурчал Дима. — Плохость всего
      зависит от того, насколько для тебя важно, чтобы Габриэль Евгеньевич был в
      сознании, когда ты надумаешь им в очередной раз повосхищаться.
      И от того, считаешь ли ты критичным временное
      отстранение Университетской гэбни.</p>

    <p>Гуанако опешил в полном смысле
      этого слова — по крайней мере, перестал гарцевать.</p>

    <p>— Ммм,
      — рассудительно заметил он и потянулся за сигаретами.</p>

    <p>Выучил уже Дима, выучил два
      заветных слова (и первое из них «Габриэль», и второе — «Евгеньевич»), которые
      ставят Гуанако на паузу в любой ситуации! Наверное, если сражаться с ним в
      бесчестном бою, достаточно в ответственный момент прокричать это заклинание, и
      получишь шанс нанести роковой удар.</p>

    <p>— Вот именно, — кивнул Дима. — Не
      уверен, что у нас есть время на перекур прямо здесь, Святотатыч ждёт.</p>

    <p>Кафедра истории науки и техники
      находится на втором этаже, идти до выхода (даже если не до парадного), казалось
      бы, всего ничего, но вся важная информация умудрилась уложиться в несколько
      лестничных пролётов. По вопросу гэбни Гуанако
      пожал плечами — не то чтобы подобный исход мог кого-то удивить, мол, хотя
      надежда жила.</p>

    <p>По вопросу Габриэля Евгеньевича в
      Порту он плечами пожимать не стал, а стал вместо этого по-нехорошему
      весёлым. Причём Дима заподозрил, что заветными словами в данном случае были
      вовсе даже и «в Порту».</p>

    <p>— Окажи мне последнюю милость, —
      попросил Гуанако на выходе, старательно не глядя в сторону Димы. — Давай мы не
      будем <i>сейчас</i> гадать, как так вышло и
      что это значит для мировой истории, а?</p>

    <p>Да кто предлагал гадать-то. Дима
      давно уже перестал гадать, Дима занимал свои мысли другими интересными вещами.
      Гадать без толку, слишком много вариантов.</p>

    <p>Впрочем, представляется сомнительным
      тот из них, в котором Габриэль Евгеньевич убрёл в Порт сам.</p>

    <p>И ещё: у Гуанако довольно
      отчётливо было написано на роже, что всё он прекрасно
      понимает относительно значения сего знаменательного события для мировой
      истории.</p>

    <p>&nbsp;</p>

    <p>В такси Дима снял очки.
      Во-первых, они ему смертельно надоели (сколько времени
      уж прошло, а продолжают натирать переносицу), а во-вторых, он просто
      представил, как осматривает бренное тело Габриэля Евгеньевича в очках с
      простыми стёклами, будучи сам же в очках с простыми стёклами, и как было бы
      нездорово комично.</p>

    <p>Плюс воспоминания о каморке
      Святотатыча, безжизненном Габриэле Евгеньевиче и очках.</p>

    <p>Где-то это всё уже было.</p>

    <p>Гуанако покосился с некоторой
      иронией, но говорить ничего не стал.</p>

    <p>Это всё было странно,
      неестественно и странно. Меньше недели назад они снова встретились (какая
      неожиданность) и снова вроде как спутались (по крайней мере, эротические
      контакты определённо имели место). Дима не жаловался, но как будто не хватало
      какой-то паузы, чтобы выдохнуть и прочувствовать момент.</p>

    <p>(Крикнуть, что ли, «Габриэль
      Евгеньевич» в окно, или без очков это слишком неконспиративно?)</p>

    <p>А главное — такое ощущение, что
      они оба этой паузы вроде как специально избегали. Дима, по крайней мере,
      избегал. У его жалкой душонки тоже есть габариты, в неё
      влезает ограниченное количество переживаний.</p>

    <p>Вот сейчас, в такси, был бы
      отменный момент для распускания соплей, но Гуанако рассеянно не то улыбался, не
      то хмурился, приобняв Диму и глядя сквозь спинку
      водительского сиденья, и лезть было как-то неуместно.</p>

    <p>Достойный этический вопрос,
      вечный прям: если ты не уверен, что тебя тут хотят и что с тобой будет хорошо,
      следует ли что-нибудь делать, чтобы изменить ситуацию, или лучше не лезть и
      позволить ситуации самой разрешить себя, дабы не сотворить ещё большей хуйни?</p>

    <p>Этой ночью Гуанако прижал Диму к
      стенке, гнусно отвлёк его эротическим манёвром,
      дождался правильной кондиции, зафиксировал руки и подло срезал
      браслетики-батарейки. С каждой руки. Методично и равнодушно. Ножом. Потому,
      мол, что нужно хоть когда-то поспать, и эта ночь ничем не хуже других. Дальше
      было, наверное, достаточно эффектно: после трёх, что ли, суток без сна на
      самодельных стимуляторах падаешь довольно-таки сразу.</p>

    <p>Плюс пара часов на карачках над сортиром с утра.</p>

    <p>Последствия гениального изобретения.</p>

    <p>И Гуанако был прав, как всегда:
      выспавшийся Дима чувствовал себя значительно лучше во всех смыслах. Что не
      отменяет того, что сам Гуанако тоже — та ещё большая лицемерная собака, потому
      что днём, когда он пришёл на кафедру традиционно мокрым (у каждого своя личная
      любовь с канализациями), на его собственном теле браслетик был также обнаружен.</p>

    <p>Надо было думать головой <i>перед</i> тем, как снимать сапоги.</p>

    <p>Здравствуйте, бедроградская
      конспирация.</p>

    <p>В общем, это было к тому, что
      Гуанако-то явно для себя на вопрос относительно деятельности и бездеятельности
      ответил и знал, как всем лучше жить.</p>

    <p>И Дима вот тоже ответил, только
      терзало его мутное чувство, что как-то не очень правильно.</p>

    <p>А паузы, которая и для этого бы
      тоже сгодилась, всё не случалось. И новой татуировкой — в дополнение к
      «Курёхину» на лопатке, — которой он якобы обзавёлся в крайне непристойном месте
      во время давешнего плаванья, Гуанако Диму только стращал.</p>

    <p>Стращал,
      но не показывал. Каждый раз, как доходило до непристойных мест, об этом как-то
      не вспоминалось.</p>

    <p>Гуанако не глядя
      потрепал Диму по голове, продолжая думать о чём-то своём (прогноз: Порт — 60%, Габриэль Евгеньевич — 30%, дела университетские — 5%,
прочее — 5%).</p>

    <p>Заставить себя верить в то, что
      всё хорошо, пока всё хорошо, и бить (себя же) ногами по почкам, если не
      получается.</p>

    <p>(Вот, например, пока они в такси,
      можно просто сделать вид, что это развлекательный променад по ночному
      Бедрограду.)</p>

    <p>Развлекательному променаду
      пристала и светская беседа о мелких благоглупостях.</p>

    <p>(И откуда только Дима <i>такое</i> слово знает, тут даже на
      медицинское образование не спихнёшь.)</p>

    <p>— К слову сказать, я, будучи
      турко-греческим царём в изгнании, остаюсь любим и
      почитаем народом, — поведал он спинке сиденья. — Доходит аж
      до того, что Бедроградская гэбня жаждет у меня аудиенции.</p>

    <p>— А ты тюрбан проебал, какая
      незадача, — незамедлительно отозвался Гуанако, после чего осознал-таки смысл
      фразы (лучше поздно!), встрепенулся и даже посмотрел на Диму. — Что, блядь? С
      тобой в качестве кого?</p>

    <p>Турко-греческого царя, чем он ещё
      примечателен-то.</p>

    <p>— Со мной в качестве меня,
      —&nbsp;пожал плечом (второе было надёжно сокрыто под мышкой у Гуанако) Дима. —
      Бедроградская гэбня неведомыми смертным путями прослышала про Дмитрия Борстена,
      таинственного университетского медика, и не может упустить свой шанс. Завтра в
      восемь утра, на каком-то складе.</p>

    <p>— Как романтично. И ты хочешь
      сказать, что <i>наша</i> гэбня дала на этот
      разврат согласие?</p>

    <p>— Я хочу сказать, что право принимать
      приглашение, обращённое ко мне, принадлежит мне, нет? — Дима помахал руками.
      —&nbsp;Наша гэбня, впрочем, дала им зримую надежду.</p>

    <p>— Во-первых, не тебе, а мне
      принадлежат все права на твои свидания, — буркнул Гуанако. — Во-вторых, в чём
      тут выгода нашей гэбне?</p>

    <p>— Ни в чём, — честно ответил
      Дима, — наша гэбня меня отговаривала. Что, кстати, не
      очень последовательно с их стороны. Ну то есть ненаша гэбня вроде как обещала рассказать Дмитрию Борстену
      о судьбе Брови, но тут даже у меня хватает мозгов догадаться, что поводов сдержать
      это обещание нет. А, ещё Ройш здраво заметил, что грязный обман и неявка могут
      Бедроградскую гэбню на что-нибудь спровоцировать, но это тоже того… плугом по
      болоту.</p>

    <p>— Спровоцировали на что-нибудь их
      ещё в субботу. Этой самой Бровью и прочими дмитриями ройшами. Теперешнее «что-нибудь» уже не смотрится
      устрашающе. Незачем тебе идти, — отрывисто сказал Гуанако.</p>

    <p>С человеком, который понимает
      тебя до того, как ты выскажешься, и побеседовать приятно!</p>

    <p>— Но как же можно манкировать столь любезно протянутой рукой дружбы? — фыркнул
      Дима.</p>

    <p>— Да всё так же, — искренне
      предложил слабый в риторических приёмах (по крайней мере
      сегодня) Гуанако. — Взорвать-таки блядский юбилей Первого
      Большого, конечно!</p>

    <p>— Тебе подозрительно сильно этого
      хочется.</p>

    <p>— Вот и Ройш мне сегодня бубнил
      что-то про Фрайда и менее болезненные способы разобраться со встречей гэбен, а
      я его какого-то лешего послушал. Ну и чем всё закончилось?</p>

    <p>— Да всем известно, что мы с
      Ройшем духовные близнецы, — Дима откинулся и задумчиво повертел в руках хвост
      галстука. — Так что это, ты не удивишься, когда я скажу, что пойду.</p>

    <p>— Нет. Просто нет.</p>

    <p>Дима снова фыркнул — столько было
      в Гуанако решительной строгости.</p>

    <p>— Ты плохой отец, ты даже не
      спрашиваешь меня о мотивациях.</p>

    <p>— Вот блядь-то, а, — почти даже
      ласково отозвался Гуанако, заломил руки и завыл на всё такси. — Ну зачем, <i>зачем</i> тебе это, сын мой?</p>

    <p>Ну
      затем, например, что довольно сложно помешать Бедроградской гэбне вызвать Диму
      уже официально, и знает он, чем это обычно заканчивается.</p>

    <p>(Кстати, не настало ли уже время
      сказать Гуанако, что рандеву у него только с одним головой, или пусть в воздухе
      ещё немного повитает досадное и комичное
      взаимонепонимание?)</p>

    <p>Или затем, что один леший знает,
      как они там способны перекроить протокол прошедшей встречи и какой степенью
      официальности обрастёт эта неофициальная просьба.</p>

    <p>Или затем, что бывают в мире мотивации
      и за пределами выгоды нашей гэбни.</p>

    <p>Но кем бы был Дима, если бы стал
      вслух озвучивать относительно здравые аргументы!</p>

    <p>— Ты и сам прекрасно знаешь,
      зачем, — ответил он несколько в сторону. — Считай, что это опасная смесь
      любопытства, кровавой мести и околосуицидальных
      наклонностей.</p>

    <p>— Как и следовало ожидать,
      сплошной старший отряд, — недовольно бросил Гуанако.</p>

    <p>— Ты с самого начала знал, с кем
      связался, — не менее недовольно бросил Дима.</p>

    <p>Гуанако
некоторое время раздражённо смотрел перед собой, после чего вздохнул с
образцовым «кого мы тут обманываем» на лице, прижал Диму к себе и тихо сказал:</p>

    <p>— Не ходи. Пожалуйста.</p>

    <p>«Возможно, об этом пожалеет
      кто-то другой», —&nbsp;мрачным пророком возвестил в Диминой голове его личный
      внутренний Ройш.</p>

    <p>Дима любил людей. Дима любил
      жить.</p>

    <p>Дима не любил, когда жизнь
      сводилась к выбору между тем, будешь ли ты сожалеть о каких-то людях, и тем,
      будут ли они сожалеть о тебе.</p>

    <p>Сраные
      тенета, а не человеческие взаимоотношения.</p>

    <p>Что там было про недостатки семи
      лет в степи? Так вот, у них есть и достоинства.</p>

    <p>— Возможно, их <i>просто</i> заинтересовал таинственный
      университетский медик, — увещевал Гуанако. — Возможно, они пока не разобрались,
      кто он такой на самом деле. Но совершенно ведь невозможно, что на личной
      встрече Бедроградская гэбня тебя не признает. Андрей — так точно признает.</p>

    <p>Если бы встреча была с Андреем,
      Дима бы Гуанако и рассказывать не стал (а ну как отговорит), Дима бы потратил
      эту ночь на поиск дрели.</p>

    <p>(Это частная мечта, на которую
      каждый имеет право, и очередная совсем другая история.)</p>

    <p>— Андрея там не будет, — Дима
      решил, что время драматического разоблачения настало. —&nbsp;Аудиенции у нашего
      турко-греческого величества жаждет, собственно, не вся Бедроградская гэбня.
      Вернее, жаждала вся, но <i>наша</i> героическая гэбня отбила у большинства это желание. Поставили условие
      —&nbsp;встречаться со мной допущен только один голова, причём наши же и выбирали,
      какой. Что не Андрей —&nbsp;ясно, что не
      Гошка — тоже ясно. Относительно двух других Охрович и Краснокаменный поставили
      мысленный эксперимент по подкидыванию монетки, и из бескосого
      тавра и некоего малоизвестного господина Акровского мне достался последний. Так
      что всё путём.</p>

    <p>Гуанако захлопал глазами.</p>

    <p>— Чего? — с абсолютно нездешним
      видом переспросил он.</p>

    <p>По крайней мере, сегодня никто не
      блюдёт иллюзию того, что слушает.</p>

    <p>— Путём всё, говорю, — чинно
      повторил Дима. — Не со всей гэбней мне встречаться, а только с одним её
      головой, и это не страшный и ужасный Андрей.</p>

    <p>— Да понял я, понял. Ещё раз: с
      кем ты встречаешься?</p>

    <p>— Ага, встречаюсь-таки! —&nbsp;не
      удержался Дима. — С многоуважаемым Социем Всеволодьевичем, головой гэбни города
      Бедрограда. Их там, знаешь, четверо: Гошка — это который,
знаешь, Александр, Андрей —&nbsp;это который, знаешь, в Медицинский Корпус
ездил, Бахта Рука —&nbsp;это, знаешь, тавр без косы, у тавров часто такие
странные имена, и этот вот самый Соций. Знаешь.</p>

    <p>— Фамилию, блядь, повтори, —
      сосредоточенно приказал Гуанако.</p>

    <p>— Чью?</p>

    <p>— Основателя Временного
      Расстрельного Комитета, блядь!</p>

    <p>— Скотина ты, — обиделся Дима.</p>

    <p>Докопались же все до Временного
      Расстрельного Комитета, а. Не о Твирине (видите —
      теперь знает!) он тогда думал, совсем не о Твирине.
      Потерял концентрацию.</p>

    <p>Все эти гэбни и прочие
      политзаключённые так привыкли к допросам, расстрелам, доносам и прочим радостям
      жизни госслужащих, что никому как будто и в голову не приходит, что бывает
      иначе.</p>

    <p>Что бывает, например, так: живёт
      себе человек, знает умеренно горя, ничего особо вопиющего не делает, а потом бац —
      и допрос. И не только. И это, например, может быть
      неожиданно.</p>

    <p>И, например, неприятно.</p>

    <p>И, например, не все и не всегда
      просто привыкают и забывают об этом немаловажном элементе всероссийской
      реальности.</p>

    <p>(С расстрелами Дима,
впрочем, немного перегнул-таки палку. Они в этих краях
преимущественно вымышленные.)</p>

    <p>Гуанако тем временем решил
      поделиться ещё некоторым количеством информации, которую и так все знали.</p>

    <p>Весьма сердитым тоном поделиться.</p>

    <p>— Напоминаю: я на допросе у
      Бедроградской гэбни бывал только в далёкой студенческой юности, сажала-то меня
      потом Столичная, — он назидательно покачал головой. — А в моей далёкой
      студенческой юности у Бедроградской гэбни был совсем другой состав, только тавр
      с тех времён и остался. Так что, не считая всяких знакомств по Колошме, я этих
      людей не знаю. Майский эпизод знакомством тоже считать не будем. И досье на
      этих людей я у Лария попросить не потрудился, когда влез в чуму. Имена
      запомнил, и ладно. У нас же все их так ласково, по именам, блядь, величают.
      Дима, леший твоего батюшку, повтори, пожалуйста, <i>фамилию</i> человека, с которым у тебя назначено свидание.</p>

    <p>— Ну, знаешь, я вот был на
      допросе у нынешнего состава Бедроградской гэбни, и нежными друзьями это нас всё
      равно не сделало. Это тогда, помнишь, когда после этого на Колошме очутился. Я
      потому поясняю, что твоя манера проговаривать полностью всё то, что мы оба
      знаем и хорошо помним, смахивает на лёгкую форму склероза. И нет, я не наезжаю,
      просто какая разница? — Немного посопев, Дима решил, что всё-таки стоит
      проявить милосердие. — Акровский, говорю, Соций Всеволодьевич.</p>

    <p>Гуанако цыкнул
      зубом, озабоченно потёр лоб.</p>

    <p>— Ну и на что Соций Всеволодьевич
      похож?</p>

    <p>— Ты ведёшь себя так, как будто
      знаешь что-то, чего я не знаю, —&nbsp;проявил наблюдательность Дима.</p>

    <p>— Состав Временного Расстрельного
      Комитета, например.</p>

    <p>Аргх!</p>

    <p>—
      Твирин-Мальвин-Гныщевич-Плэть-Золотцэ-был-но-вышел, — оттараторил
      Дима, —&nbsp;и покончим с этим.</p>

    <p>— И почему вышел? —
      экзаменаторским тоном осведомился Гуанако.</p>

    <p>— Усики спиздили,
      —&nbsp;проворчал Дима.</p>

    <p>— Садитесь, три. Лучше ложитесь,
      раз вы и так сидите, — постановил Гуанако. — Опиши мне Акровского, блядь.</p>

    <p>— Не буду ничего описывать
      человеку, который ставит мне тройки за горькую правду. И вообще я с медфака. —
      Дима задумался, не закурить ли, но складские стены уже маячили в зоне видимости
      —&nbsp;видимо, проще дождаться. — Честное слово, в тот раз, что мы виделись, я
      был занят немного более интересными вещами, чем рассматривание. Не говоря уже о
      том, что и рассматривать-то там особо нечего. Если память меня не подводит,
      восемь лет назад Акровский более всего напоминал крупногабаритный шкаф из необработанной палы.</p>

    <p>— Блядь. Кругом одни бляди, не
      продохнёшь, — Гуанако в который раз покачал головой с совершенно отсутствующим
      видом, после чего жестом прервал попытки Димы привнести некую детальность в
      описание. — Ладно, забей: уже приехали,
      я сейчас у Святотатыча досье спрошу. Шкаф из палы,
      блядь! Нашли, блядь, кого выбрать. Лучше б тавра, поговорили бы о нелёгкой
      судьбе малых народов, — он обернулся к Диме, и, хоть только леший мог знать,
      что в этот момент творилось у Гуанако в голове, беспокойство на его лице было
      весьма выразительным. — Сам подумай: вот и зачем тебе соглашаться на свидание,
      раз поклонник не в твоём вкусе? Не ходи, а.</p>

    <p>Дима хотел сказать, что, если
      хочешь кого-то в чём-то убедить, возможно, имеет смысл делать это несколько более вслух, но не успел, ибо тут-то такси и остановилось.</p>

    <p>&nbsp;</p>

    <p>Традиционное петляние
      по портовым закоулкам до каморки Святотатыча было исполнено в эконом-режиме:
      Габриэль Евгеньевич не ждал. Ну то есть ждал. Ну то
      есть и так
      уже кучу времени проимели впустую.</p>

    <p>В каморке Святотатыча помимо
      предсказуемого Святотатыча и тела на койке (нет, Дима не кинулся с порога)
      обнаружился также некто крайне мелкого роста и угрожающей внешности, со
      странного плесневелого цвета порослью на голове. Некто сидел за столом и
      пожирал курицу, косо посматривая на окружающую действительность. У него даже
      натурально имелся золотой зуб.</p>

    <p>По всей видимости, Озьма.</p>

    <p>Как ни странно, раньше Дима
      никогда не видел никого из Портовой гэбни (он же не политик какой-нибудь, право
      слово). Не считая, конечно, Святотатыча.</p>

    <p>Всё потому что некоторые крайне
      эксплицитно предостерегали Диму от походов в Порт.</p>

    <p>Как будто он прямо <i>самый</i> несамостоятельный человек на
      свете, честное слово.</p>

    <p>Однако спрос на кассахов в
      борделях велик, и ажиотаж имеет шансы возникнуть, даже если ты формально и не
      кассах.</p>

    <p>Ну вот, значит, Озьма. Денежный
      властелин Порта. Приятно познакомиться.</p>

    <p>Озьме приятно, кажется, не было,
      поскольку он хищно осмотрел вошедших и вернулся к
      своей курице.</p>

    <p>Крыса Габриэль Евгеньевич,
      почему-то сидевший на столе в клетке, с завистью повёл носом на столь близкое,
      но столь недосягаемое мясо. А ему грех было жаловаться, восседал-то он на целой
      горе овощных и травяных обрезков.</p>

    <p>Всё ему мало, бывают же такие!</p>

    <p>— Дай ты зверю поесть, — попросил
      Святотатыч Озьму, созерцая крысу Габриэля Евгеньевича с истинной печалью на
      лице. — Он к решёткам не привык, злится.</p>

    <p>Озьма цыкнул
      (золотым!) зубом.</p>

    <p>— А я к наебалову
      не привык. Тоже злюсь.</p>

    <p>Оба были весьма увлечены своей
      беседой.</p>

    <p>Дима почувствовал себя
      прозрачным.</p>

    <p>— Слышь,
      Озьма, — негромко окликнул Гуанако, — я завтра безо всякого наебалова
      кое-что из долга подгоню, лады?</p>

    <p>— Нелады, — отрезал Озьма, не
      возжелав удостоить его взглядом. — Кончилось твоё завтра.</p>

    <p>— Захлопнитесь
      оба, а? Потом деньги считать будем, — Святотатыч был дружелюбен, но
      озабоченность это ему скрыть не помогло (и даже борода не помогла). — Эй,
      медик, разберись со страждущим.</p>

    <p>— Потом тоже
      закончилось, — пробурчал Озьма, но распространяться не стал и даже
      просунул-таки сквозь прутья клетки шматок мяса.</p>

    <p>Дима
      откашлялся, но больше поводов не подходить к Габриэлю Евгеньевичу (не крысе) не
      наблюдалось.</p>

    <p>Жаль.</p>

    <p>— А я-то всё
      ещё надеялся, что вы позвали меня для красоты, —&nbsp;пробормотал Дима и шагнул
      вперёд. И ещё шагнул. И ещё. И тут неожиданно оказался прямо над койкой с
      Габриэлем Евгеньевичем.</p>

    <p>Не крысой.</p>

    <p>Есть некоторая
      разница между тем, чтобы знать, что что-то где-то как-то обстоит, и видеть
      собственными глазами деяние рук своих.</p>

    <p>— Это и есть, шоль, твой мальчик, которого ты всё прятал? — где-то сзади
      вопросил Озьма у Гуанако, подразумевая с некоторой вероятностью Диму. — Этот,
      больной который, красивше будет.</p>

    <p>Дима ещё раз
      посмотрел на Габриэля Евгеньевича.</p>

    <p>Ну спасибочки.</p>

    <p>Не хотелось ему
      на Габриэля Евгеньевича смотреть, изо всех сил не хотелось. Его
волосы были неровно, хоть и не очень коротко, обчекрыжены, волнующая умы
поколения седина закрашена; на абсолютно жёлтой, ненормального цвета коже
виднелись какие-то пятна и вмятины, через всю левую щёку шёл кривой разрыв,
откуда продолжало сочиться что-то мутное и вязкое. Ещё один разрыв
      виднелся через распахнутую мятую рубашку на груди. Но главное — не это.</p>

    <p>На койке лежал
      кто угодно, но не Габриэль Евгеньевич. Черты этого человека были искажены, вся
      его поверхность пошла буграми, пальцы набухли, а голова запрокинулась в
      неизвестной, не габриэль-евгеньевичевской позе. Это
      вообще был не человек, а какой-то сгусток, издающий хриплые звуки и вызывающий
      единственное желание —&nbsp;усыпить, чтоб не мучился.</p>

    <p>Желательно не
      собственноручно усыпить, ибо трогать его не хотелось.</p>

    <p>В порядке особо
      тонкого издевательства лицо сгустка венчалось тоненькими французскими усиками.</p>

    <p>Машинально
      отклеив оные и отправив их в карман штанов (вот будет радость-то Охровичу и
      Краснокаменному, даром что не совсем копия
      кафедральных золотцевских), Дима подумал, что зря
      снимал очки: нет у сгустка никаких очков и не было никогда. Но не нацеплять же
      теперь, это было бы совсем уж нелепо.</p>

    <p>Потревоженный
      отклеиванием усиков сгусток булькнул и издал нечто вроде стона.</p>

    <p>— Медик, в
      обморок не падай, — подкрался со спины Святотатыч. — Он же даже не мутирует на
      глазах: я вам сразу позвонил, как его нашли, и с тех пор новых конечностей
      вроде не отросло. Это ж хорошо? В общем, ничего пока не знаем, его с улицы притаранили случайные люди. Обосрались, конечно, от
      отвращения, но проявили сообразительность: в Порту болячек больше видели, чем в
      городе, догадываются, чем это пахнет. Нашедшие изолированы
      нахуй, ясное дело. Как по санитарным соображениям, так и по информационным. Чё
      с ним было и где он валялся — скоро выясним, если
      повезёт. И это, мы с ним ничего не делали сверх того, что сделано уже, чтоб не напортачить чего. Ни снотворного, ни твиревого
      пойла без тебя не давали. Одно могу
сказать — он обколот какой-то тяжёлой наркотой, это и в такой кондиции заметно.</p>

    <p>Дима
      почувствовал, что на лице его рисуется совсем уж идиотская
      улыбка — слишком безумно прозвучало слово «отвращение» в адрес Габриэля
      Евгеньевича.</p>

    <p>Габриэль
      Евгеньевич — и отвращение.</p>

    <p>Так не бывает.</p>

    <p>По каморке
      разнеслось убитое «бляяяя» Гуанако.</p>

    <p>— Правильно
      сделали, что позвали, — проговорил Дима, старательно артикулируя и не
      оборачиваясь. —&nbsp;Это она, водяная чума. Вероятно. Я никогда вживую не видел
      поздней стадии. Идите возьмите с полки заслуженный
      пирожок, только сперва помойте руки с мылом, а то Габриэль Евгеньевич нынче
      пиздец как заразен.</p>

    <p>— Да понятно
      всё с руками, твирью умываемся. Можешь сказать, был ли он заразен <i>всё
        время</i> с тех пор, как пропал?</p>

    <p>— Нет, не могу,
      — ещё более старательно проартикулировал Дима.
      —&nbsp;Если позволишь, в ближайшую пару минут я предпочёл бы ничего не
      говорить.</p>

    <p>Дабы не
      чувствовать более присутствия над душой Святотатыча, Дима опустился на колени
      рядом с койкой (под предлогом помощи страждущему), раскрыл сумку и обнаружил,
      что с настолько трясущимися руками, наверное, не стоит делать никому уколы.</p>

    <p>Так уж вышло,
      что поздняя стадия водяной чумы существовала только на бумаге. Когда делали
      вирус, Таха Шапка заражал себя, чтобы отследить реальное протекание болезни, но
      решился дойти только до средней стадии, остальное смоделировали. Само собой, в подопытные полез Сепгей Борисович, но его изгнали с позором
      и маханием руками.</p>

    <p>Хватит уже
      радостей на долю Сепгея Борисовича, хва-тит.</p>

    <p>Впрочем, как показывают телеграммы (сегодня была ещё одна: СПАСИБО ТЧК ВЫЕЗЖАЮ ТЧК ПОДТВЕРЖДЕНИЕ&nbsp;ВРЕМЕНИ-МЕСТА),
отсутствие чумы не спасло.</p>

    <p>Вся история с
      Сепгеем Борисовичем тоже какая-то дикая. Давным-давно сидел себе Дима на
      Колошме в одиночной камере Гуанако, ходил (в отличие от)
      на работы по сбору травы и почти уже даже полюбил всё
      это дело. Не выкобенивался перед гэбней, поскольку
      обнаружил, что быть политическим заключённым с особыми условиями довольно
      приятно. Особенно если в фоновом режиме эдак вяло
      думать о том, что в любой момент можно сбежать.</p>

    <p>Сидел, значит,
      ходил, собирал травки, от скуки экспериментировал с их возможными свойствами. А
      потом, весной 76-го, почти через год сидения и, соответственно, семь лет назад,
      особые условия неожиданно решили переквалифицироваться в методы
      психологического воздействия на Гуанако. Твирь в жопу,
      сеансы массового насилия над Димой и ещё некоторые радости, которые он хранил
      уж в совсем отдельном ларчике своего сознания. И всё это — не по воле гэбни
      Колошмы, а по воле почти уже стороннего для Колошмы Андрея. И гэбня Колошмы,
      соответственно, совершенно растерялась и не знала, что со всем этим делать.
      Дима подозревал, что они ему даже в некотором роде сочувствовали.</p>

    <p>По крайней
      мере, Сепгей Борисович, который тогда в гэбне Колошмы и сидел, сочувствовал. А дальше вышла череда действительно комичных взаимонепониманий:
методы психологического воздействия на Гуанако увенчались угрозой увезти Диму
обратно в Бедроград, и угроза определённо воплотилась бы, если бы не началась
чума. Степная чума. А во время степной чумы всем резко становится не до
      психологических воздействий, так что сторонний для Колошмы Андрей спешно
      подобрал манатки и умотал из степи подальше.</p>

    <p>А Дима остался
      сидеть. Не в смысле «мотать свой срок в колонии для политических заключённых»,
      а в смысле тупо сидеть в другой одиночной камере, куда его временно поместили
      на передержку. В приличной рубашке, при галстуке, как полный идиот.
      И о нём все забыли.</p>

    <p>Во время
      степной чумы уж точно не до заключённых, которых непонятно, куда определить.</p>

    <p>(Гуанако,
      наверное, не забыл, но Гуанако не то чтобы сильно спрашивали.)</p>

    <p>И Сепгей
      Борисович не забыл. Сепгей Борисович привёл Диму пред
      светлые очи гэбни, где Дима показал свои научно-исследовательские достижения в
      области возможных свойств степных трав и поведал, что, если ему дадут
      возможность, он попробует сделать что-нибудь от чумы.</p>

    <p>Степной чумы.</p>

    <p>Потому что Дима
      такой разумный и рассудительный.</p>

    <p>Чума только
      начиналась, особого права назначать заключённого в санитары и выпускать шляться без
      присмотра у гэбни не было, но они это всё-таки
      сделали.</p>

    <p>Гуанако тогда
      сказал, что если кто-нибудь тут намеревается из колонии бежать, сейчас самое
      время.</p>

    <p>Но Дима не
      побежал.</p>

    <p>Разумный и
      рассудительный.</p>

    <p>Что-нибудь от
      чумы даже сделалось. Дима передал вакцину (и он настаивает на том, что она
      «вакцина», а никакая не иммунная сыворотка, потому что он её так назвал, и что
      все эти люди понимают в тонком искусстве высокого слога) гэбне Колошмы. Гэбня
      Колошмы отослала информацию о вакцине Медицинской гэбне. Гэбня Колошмы также
      опробовала вакцину на больных. Выяснилось, что вакцина не лечит, но хотя бы
      задерживает развитие болезни, что само по себе вроде как прорыв в медицине.
      Диме сказали спасибо. На Диме тогда было больше браслетиков-батареек, чем
      сейчас. Даже чем вчера.</p>

    <p>Степная чума, в
      отличие от водяной, успешно передаётся воздушно-капельным.</p>

    <p>А потом
      возможность безболезненно сбежать закончилась, потому что приехали огнемётчики.</p>

    <p>Дима не был
      заражён (наверное, таки твирь-в-жопу уберегла).</p>

    <p>Гуанако тоже не
      был (это же Гуанако).</p>

    <p>Только
      огнемётчиков это не волновало, и если Гуанако в его одиночной камере ещё могли
      теоретически обойти стороной, то Дима-то работал санитаром, Дима контактировал с заражёнными.</p>

    <p>Обильно.</p>

    <p>Закончилась
      возможность безболезненно сбежать, закончилась.</p>

    <p>И тогда Дима
      столкнулся в коридоре с Сепгеем Борисовичем, и Сепгей Борисович сделал странное.
      Без каких-нибудь объяснений и уж точно без
      сантиментов он отдал Диме свой табельный пистолет с парой обойм.</p>

    <p>У Димы тогда
      даже не хватило сил удивиться. Степная чума на той стадии, когда фаланги
      присылают огнемётчиков, — это значит, что треть уже умерла, треть точно не
      выживет, а оставшаяся треть шарахается друг от друга изо всех сил, поскольку
      воздушно-капельный. Это значит, что головы гэбен могут стрелять в заключённых и
      охранников, потому что никто уже всё равно не узнает, а трупы сожгут.</p>

    <p>Это значит, что
      заключённые могут стрелять в голов гэбен.</p>

    <p>Дима даже не
      помнил, сказал ли Сепгею Борисовичу спасибо. Он вернулся в камеру, отдал
      пистолет с обоймами Гуанако (сам Дима стреляет, скажем
      так, не очень хорошо, в чём его предварительно крайне наглядно убедили), и
      возможность сбежать — пусть и болезненно — снова появилась.</p>

    <p>И сбежали,
      прямо через кордон огнемётчиков.</p>

    <p>С огнемётами.</p>

    <p>Не то чтобы
      Димина седина ещё могла кого-то впечатлять, но её тогда прибавилось.</p>

    <p>И на этом
      знакомство Димы с Сепгеем Борисовичем закончилось бы, если бы в мае неожиданным
      образом не вышло так, что Дима никак не мог оставаться в Бедрограде, ну не было
      ему там места. Поэтому Дима, не собирая толком вещей, уехал в Столицу, а в
      Столице он (и то предположительно) знал только одного человека.</p>

    <p>Уж точно одного
      человека с таким именем, которое никак не проебёшь в телефонном справочнике.</p>

    <p>Сепгей Борисович
      пустил его к себе жить не задавая особых вопросов.
      Чтобы не рассказывать о событиях на тот момент насущных, Дима рассказывал обо
      всём подряд. Как они с Гуанако сбежали-таки в степь; как
чуваки, которых Дима настоятельно вытащил вместе с собой, оказались не слишком
дружелюбными ребятами; как Дима с Гуанако и насмерть отнявшейся после не
слишком дружелюбных ребят рукой кучу дней топал по степи пешком на
браслетиках-батарейках; как они в итоге осели в каком-то степняческом
поселении, хорошо так осели, на семь лет; как они через семь лет оттуда
      ушли — пешком же — и пешком, совершенно случайно, притопали в Вилонский Хуй по
      следам дикого скопца, и как на самом дне Вилонского Хуя обнаружилась странная
      грязь, которая на проверку оказалась сырьём и исходным материалом, из которого
      время от времени предположительно вылезает на поверхность степная чума. Историй
      было много, от принятия родов у коровы до биографии степного травника, в
      учениках которого Дима ходил и который оказался не толстым скотоводом, а вполне
      разумным дядькой с книгами по истории Революции на полочке.</p>

    <p>Сепгей
      Борисович слушал, качал головой, смеялся в положенных местах, а потом неохотно
      и по частям рассказал свою биографию, и тогда Диме сделалось не очень хорошо.</p>

    <p>Табельный
      пистолет Сепгея Борисовича обнаружили через месяц после чумы в ближайшем к
      Колошме степном городке — тоже выгоревшем от болезни. Вместе с телами не слишком
      дружелюбных ребят. И началось бесконечное, заунывное дело по выяснению того,
      содействовал ли Сепгей Борисович опасным (страшно опасным, политическим!)
      заключённым в экстремальных обстоятельствах, или же пистолет у него тупо спёрли. Из
      гэбни Колошмы Сепгей Борисович вылетел со
      свистом, что само по себе ещё не такая беда (может, даже не беда вовсе); но год
      за годом ходить на допросы и не иметь возможности хотя бы просто поработать
      хоть где-нибудь — это уже сомнительное удовольствие.</p>

    <p>И этим
      удовольствием Сепгей Борисович был вроде как обязан Диме и своему глубоко
      порядочному поступку в адрес Димы.</p>

    <p>Он не сказал,
      что ни о чём не жалеет, но, наверное, всё-таки не жалел, потому что двух недель
      не прошло, как Дима предложил ему совершить ещё один глубоко порядочный
      поступок.</p>

    <p>Помочь
      подсунуть Бедроградской гэбне <i>правильный</i> вирус, а для этого — помочь сотворить этот вирус из вилонской
      грязи, помочь сотворить Дмитрия Ройша, помочь Дмитрию Ройшу обустроиться в
      Медкорпусе.</p>

    <p>Изобразить
      высокие отношения с Дмитрием Ройшем, чтобы ни у кого не возникало вопросов
      относительно мотиваций.</p>

    <p>Леший, они даже
      ни разу не потрахались,
      потому что Сепгей Борисович («Борисович», да он же младше Гуанако!) не
      попросил, а у Димы не хватило душевных сил предложить.</p>

    <p>А стоило,
      потому что тот согласился бы, и сейчас не было бы этого отвратительно ясного
      чувства того, насколько по-скотски Дима по отношению к нему поступил.</p>

    <p>Потому что
      Сепгей Борисович порядочный, умный и выносливый человек, но за годы допросов у
      любого образуется крайне особое отношение к фалангам.
      А помощь Дмитрию Ройшу с чумой сегодня — это ещё немного допросов от фаланг
      завтра.</p>

    <p>Так что в итоге
      Сепгей Борисович написал-таки Дмитрию Ройшу телеграмму о том, что нервы его
      всё, и не мог бы Дмитрий Ройш — Дима — хоть как-нибудь помочь.</p>

    <p>Пожалуйста.</p>

    <p>Если ему не
      очень сложно.</p>

    <p>Блядь.</p>

    <p>У Димы сложные
      и увлекательные отношения с собственной совестью, он умеет её избегать, но это
      же просто смешно.</p>

    <p>Он вспомнил
      Сепгея Борисовича, чтобы не думать о Габриэле Евгеньевиче — кажется, только
      затем, чтобы в итоге постановить, что проблемы Габриэля Евгеньевича — это
      отличный повод не думать о Сепгее Борисовиче, который должен приехать сегодня
      ночью.</p>

    <p>Дима сделает
      всё, чтобы нервы Сепгея Борисовича исцелились и его больше
никогда ничего не тревожило, только сложности со смотрением в глаза всё
      равно останутся. Они с Бровью почти об этом говорили, кажется: если из-за
      кого-то твоя жизнь стала (хоть бы и на время) невыносимой, можно сколько угодно
      раз всё исправить — этот кто-то всё равно останется живым напоминанием,
      которого лучше не видеть, чтобы не. Обратное тоже верно: если ты кому-то нагадил,
      довольно сложно измерить то количество благих
      деяний, которое требуется произвести в его адрес, чтобы счёт сравнялся. Проще
      не смотреть.</p>

    <p>Хороший человек
      — худшее наказание, которое может встретиться в жизни.</p>

    <p>С Бровью
      говорили, ага.</p>

    <p>Кажется, Димины
      руки уже в достаточно рабочем состоянии, чтобы
      разбираться с насущными проблемами.</p>

    <p>Наверное, можно
      было даже не запариваться с перчатками, но всё, что оттягивало момент
      прикосновения к сгустку, приносило неизъяснимую радость. Дима таки натянул их,
      проверил Габриэлю Евгеньевичу пульс (усвоил в основном то, что, когда запястье
      столь неестественно-мягко прогибается, пульс щупать не
      хочется), посмотрел глаза.</p>

    <p>Чёрные линзы,
      смотрите-ка.</p>

    <p>Самое время для
      патетичного «на вашем месте должен был быть я», только
      нет, не должен был. Должен был доучиться нормально на истфаке, защитить диплом
      (не в камере на Колошме перед Гуанако и печатной машинкой Савьюра), устроиться
      в Университет уборщиком и жить себе радостно.</p>

    <p>И не было бы
      никаких чёрных линз, не было бы никакой чумы, были бы мир и благодать.</p>

    <p>Точно-точно.</p>

    <p>Диме не хватало
      врачебного автоматизма, но пришедшие в себя руки сами знали, что делать.</p>

    <p>Обезболивающего, да покрепче — это раз. Неизвестно, как
      развилась водяная чума в данном конкретном случае, но сбои любых внутренних органов
      — это не очень приятно.</p>

    <p>Снотворного
      какого-нибудь — это два. Лёгкого. Чем бы Габриэля Евгеньевича ни накачали (в
      плане наркотиков), сильное давать не стоит, да и
      процессам восстановления на этом этапе искусственный сон скорее помешает, но
      всё-таки —&nbsp;пусть лучше спит.</p>

    <p>Ну и его,
      заветного, лекарства от чумы имени жоп всего истфака —
      это три.</p>

    <p>Дима посмотрел
      на ампулу, взломал её (ага, они там на медфаке даже
      запаивали ампулы, медицина не терпит раздолбайства),
      опустил третий по счёту шприц.</p>

    <p>Он когда-то
      придумал-таки вакцину от степной чумы, поди ж ты. Из
      собственной крови и твири, но придумал же.</p>

    <p>Великие
      открытия не делаются из гуманизма. Великие открытия не делаются из жажды денег,
      славы, спасения самого близкого и родного человека на свете, жадности, удовлетворения
      собственных амбиций, чего угодно. Есть только один способ совершить великое
      открытие: не думать о величии возможного открытия. Копаться в том, что тебе
      интересно, не спать ночами, тестировать на себе, продавать последнюю вешалку за
      нужную литературу. Испытывать любопытство. Тогда — возможно, если ты достаточно
      умный или достаточно везучий — ты что-нибудь откроешь или изобретёшь.</p>

    <p>Именно поэтому
      вакцина от степной чумы великим открытием (то бишь
      изобретением) не была. Потому что выросла она только одной ногой из любопытства
      (степные травы всё же забавная штука), а остальным телом —&nbsp;из упорства,
      вредности («как это нельзя такое сделать?») и экстренных обстоятельств.</p>

    <p>И гуманизма.</p>

    <p>Честное слово,
      все так поражаются, как будто что-то не в порядке с головой у человека,
      которому почему-то не нравится смотреть на то, как окружающие умирают в
      мучениях.</p>

    <p>Тем более если
      и так кое-какие мысли относительно возможной вакцины бродили.</p>

    <p>(Потому, кстати, бродили, что тогда Дима о медицине знал ещё
меньше, чем сейчас: полторы лекции Попельдопеля да этнографические очерки о степнячьих методах лечения — вот и вся подготовка.
      Знал бы больше — ничего бы не получилось, а так было нестрашно.)</p>

    <p>Но да, именно
      из-за гуманизма Димина вакцина —&nbsp;всего лишь иммунная сыворотка, которая
      временно приостанавливает развитие болезни.</p>

    <p>Зато он
      посмертно награждён за гражданское мужество и может служить хрестоматийным
      примером того, <i>как надо делать</i>.</p>

    <p>Именно то, чего
      и хотелось от жизни.</p>

    <p>Именно то.</p>

    <p>Получивший свою
      дозу лекарства от чумы (водяной, тут происходит водяная чума, все остальные
      чумы уходят куда полагается, в давние воспоминания, которые Дима не вспоминает)
      Габриэль Евгеньевич еле слышно вздохнул и наконец-то осел, сразу приобретая
      чуть более человеческие контуры. Это не лекарство мгновенно
      подействовало, само собой, это снотворное наконец-то докатилось до мозга.</p>

    <p>Всё у него
      будет хорошо, а волосы и обратно отрастить можно.</p>

    <p>Дима вытер
      мутную дрянь, стекавшую по щеке Габриэля Евгеньевича,
      —&nbsp;вот он, злобный оскал водяной чумы. Судя по всему, когда биологические
      жидкости скопились под кожей, та отслоилась и потом лопнула от какого-нибудь
      минимального механического воздействия. Обидно, что на лице.</p>

    <p>Хотя это же
      Габриэль Евгеньевич, напомнил себе Дима. У него что
      шрам на лице, что перо в заднице —&nbsp;всё будет выглядеть по-габриэль-евгеньевичевски
      и тревожить умы новых и новых поколений студентов.</p>

    <p>(Если, конечно,
      у него не успел <i>уже</i> отказать
      какой-нибудь жизненно важный внутренний орган.)</p>

    <p>И вообще, можно
      ни о чём особо не беспокоиться, Охрович и Краснокаменный в любом случае
      прикончат Диму за нарушение эстетической ценности Габриэля Евгеньевича.</p>

    <p>С особой
      жестокостью.</p>

    <p>Что поделаешь.</p>

    <p>— Я закончил,
      —&nbsp;провозгласил Дима, поднимаясь с колен и стаскивая с себя перчатки. —
      Пациент должен жить и здравствовать. Когда-нибудь. Я надеюсь.</p>

    <p>И обернулся.</p>

    <p>Оказывается,
      всё это время Святотатыч с Гуанако крайне напряжённо и внимательно следили за
      Димиными действиями и бездействиями, а Озьма почти расправился с курицей.</p>

    <p>Хороший человек
      — Озьма.</p>

    <p>(Не такой
      хороший человек, который наказание для окружающих, а
      правда хороший.)</p>

    <p>— Надо вести
      тело на медфак, — деловито высказался Гуанако. — Со всеми предосторожностями. В
      первую очередь, конспиративного характера, но медицинские
      тоже не помешают. У меня на входе Муля Педаль в такси остался, но тут лучше бы
      грузовик. Организуешь? — обернулся он к Святотатычу.</p>

    <p>— Обижаешь, —
      развёл руками тот, — уже готово, только свистни. Но я бы на твоём месте
      дождался подробностей появления тела в Порту. Скоро должны быть.</p>

    <p>Озьма звонко
      обсосал последнюю куриную кость.</p>

    <p>— Вылечил? Жить
      будет? Вот и ладненько, — причмокнул он. —&nbsp;А теперь, Гуанако, давай-ка
      поговорим о серьёзных делах.</p>

    <p>&nbsp;</p>

    <p>Разговоры о
      серьёзных делах Дима слушал вполуха. Сидеть на полу,
      подпирая спиной койку с Габриэлем Евгеньевичем, мерно курить
и рассматривать узоры на стенах было куда приятнее и даже относительно
      спокойно. Диме почти нравилось, потому что дыхание Габриэля Евгеньевича было
      почти ровным.</p>

    <p>(Какая
      фраза-то, если вырвать из контекста.)</p>

    <p>Впрочем, что-то
      из разговоров о серьёзных делах всё-таки уловилось. В
      первую очередь —&nbsp;что Озьма меньше злится на Гуанако, чем на Жудия (ещё
      одного голову гэбни), который когда-то оттяпал себе
      портовый оборот травы, так что теперь трясти деньги за твирь с должников и
      стращать их разморозкой её оборота полагается не
      Озьме. Лекарства, капельницы и прочие мелочи его тоже не особо занимали (хотя
      неправильным ведением официальных переговоров он Гуанако всё-таки укорил —
      деловые люди, мол, терпеть не могут, когда им не называют сроки расплаты).</p>

    <p>Но из-за
      отсутствия твири в Порту начались беспорядки. Поножовщина и прочие
      смертоубийства Озьму не волновали, а вот вспышка неподобающих грабежей всего
      подряд — весьма. Озьма лениво чесал под камзолом волосатую грудь, ковырял
      костью в зубах и всё спрашивал у Гуанако, понимает ли тот, что за украденные
      товары кто-то должен ему, Озьме, заплатить. Святотатыч вклинивался и осаждал
      Озьму, указывая на то, что оценить, какие грабежи вызваны отсутствием твири, а
      какие не вызваны, невозможно, и перевести ущерб, который понёс Порт от всей
      этой авантюры с чумой, в твёрдую деньгу — тоже. Озьма отвечал, что так-то оно
      так, но расплате всё равно быть, после чего диалог повторялся заново на слегка
      изменённый лад.</p>

    <p>В те моменты,
      когда Гуанако не говорил с Озьмой (сдержанно, дружелюбно, твёрдо и крайне
      убедительно), он сидел с довольно пасмурным видом. Трагедия с Портом могла быть
      куда трагичнее, тут никто никого даже не собирался убивать или рвать в клочья,
      но Дима-то понимал, как это приятно — когда вся трагедия, пусть и не предельно трагичная,
      висит лично на тебе. На Гуанако, то бишь.</p>

    <p>Сам Гуанако
      выкрутится, ничего ему не будет. Купить прощение Озьмы возможно, он не просто
      не скрывает ценника — он тыкает им в лицо всем
      желающим; но портовые прознали какие-то обрывки слухов и винят во всём
      Университет. И справедливо винят, и несправедливо. И дальше будут винить,
      невесело пояснял Святотатыч (ещё один любитель вслух пересказывать то, что и
      так понятно): все вчерашние и сегодняшние беды, от пропавшего грузовика
      самогона до чьей-нибудь диареи, спишут на Университет, и завтрашние тоже
      спишут, и послезавтрашние. И продолжат в том же духе, даже когда (привет,
      оптимистичное «когда») Университет расплатится, если позволить мнению про их
      жадность укорениться.</p>

    <p>А тогда
      получится нехорошо. Порт живёт слухами и байками, и если портовые
      уверуют в то, что Университет плохой, людям из последнего будет лучше сюда не
      соваться. И Портовая гэбня ничего не сможет сделать
      (по крайней мере, сразу, оптимистично уточнил Святотатыч): Порт живёт слухами,
      байками и ножами, а не гэбней.</p>

    <p>Всего этого
      можно избежать, талдычили Озьма со Святотатычем на два
      голоса. Найди денег, натурального продукта, а лучше — и того, и другого. Много,
      много денег. Экзотических наркотиков. Необычного бухла. Красивых цацек. Портовые
      готовы простить и купиться, только делать
      это надо прямо сейчас, пока слухи не расползлись ещё дальше. Главное — никак не
      усугубляй ситуацию, не бери больше в долг и действуй быстро.</p>

    <p>Гуанако слушал
      всё это с убитым видом, через силу объяснял, что что-то будет завтра и в
      ближайшие дни (хотя вряд ли его бывшие контрреволюционные студенты, про которых
      Диме проболтались Охрович и Краснокаменный, даже в самом экстатическом припадке
      благодарности смогут прислать что-то действительно интересное Порту). Только
      понятно было, что основную сумму взять неоткуда и что Гуанако рад бы продаться
      в пожизненное рабство ради расплаты, да не стоит даже самый расчудесный Гуанако
      таких денег.</p>

    <p>Гуанако слушал
      всё это и украдкой посматривал на Габриэля Евгеньевича очень тяжёлыми глазами.</p>

    <p>И Дима
      прекрасно мог догадаться, почему глаза Гуанако были такими тяжёлыми, но сейчас
      ему в кои-то веки удавалось не догадываться, так что он просто сидел и
      радовался тишине у себя в голове.</p>

    <p>…Перед отъездом
      в приснопамятную экспедицию, оказавшуюся Колошмой, Гуанако оставил Диме свой
      перстень — красивый, серебряный, с треугольным чёрным камнем. В горшке с
      кактусом оставил, крайне трогательно. Так перстень стал вроде бы Диминым.
      Нельзя же совсем истаивать в пространстве, должна же сохраняться какая-то реликвия
      — вспоминать и плакать в уголке.</p>

    <p>Реликвия почти
      девять лет как принадлежала вроде бы Диме.</p>

    <p>Через девять
      дет, в этом мае, Гуанако закончился, но про перстень как-то забылось (реликвии
      всё-таки играют вспомогательную роль в этой жизни). В сентябре, то есть неделю
      назад, Гуанако снова начался, и начался с того, что они с Димой случайно (не
      «случайно», а Святотатыч —&nbsp;старый сводник, конечно) столкнулись в этой
      самой каморке. Дима попытался вернуть реликвию владельцу, Гуанако пожелал
      разыграть в карты, а дальнейшее было сумбурной алкогольной тайной, но проснулся
      Дима таки с перстнем на мизинце.</p>

    <p>Спустя какое-то
      время (выразимся загадочно) Гуанако поведал, что перстень этот у него как раз
      отсюда. Мол, в совсем ещё юном возрасте, пока жил у Святотатыча, нашёл на
      лестнице, и сейчас вдруг поимел печальные подозрения о его
      неслучайном туда попадании, но думать об этом решительно отказывается.</p>

    <p>Дима уже забыл,
      к чему вспомнил про перстень, но мысль свелась всё равно к одному — к фрайдизму,
      любимому.</p>

    <p>Потому как да, в
      совсем юном возрасте Гуанако жил у Святотатыча.</p>

    <p>В полном смысле
      этого слова.</p>

    <p>С учётом
      ужасающей одинаковости разницы лет у Гуанако со Святотатычем и у Димы с Гуанако
      (там и там по двенадцать, уй) просматривалась
      просто-таки наследственность.</p>

    <p>Все тут такие
      близкие и родные.</p>

    <p>(И почему любой
      аспект Диминой жизни, даже невинная, пусть и реликварная
      цацка, настолько хорошо описывается словосочетанием
      «кромешный пиздец»?)</p>

    <p>Сей-то факт,
      положим, Диму как раз не травмировал, он привык к тому, что в мире смертных все
      со всеми трахаются, но эти двое до сих пор
      разговаривали друг с другом как-то немного странно. Не скованно, не чрезмерно
      интимно, не неправильно и не неловко, но странно. Ну
      бывает так, когда у одного человека от другого человека включается некий своеобразный
      режим, который и описать-то толком не получается, и никому он не мешает, но не
      заметить невозможно.</p>

    <p>Гуанако был
      дипломатом Университета в Порту не только из-за каких-то там перстней в
      анамнезе, разумеется. Но из-за перстней и, гм, хороших отношений в анамнезе он переживал нынешние проблемы Порта
      с особым градусом
      истеричности.</p>

    <p>Потому что люди
      всё-таки очень сильно зависят от идеи взаимности, и получать хорошее, не
      отдавая ничего взамен, непросто.</p>

    <p>Особенно когда
      взамен громче всех требует Озьма, у которого, по рассказам Гуанако же, всегда
      при себе большой страшный крюк.</p>

    <p>&nbsp;</p>

    <p>— Всё так и
      есть, Святотатыч. Всё так, как ты предполагал, — говоривший не успел даже
      переступить порог. — Происшествие и правда оказалось в
      моём ведении.</p>

    <p>Дима тряхнул уплывшей в не подобающие моменту дальние дали головой
(орнаменты на стенах шевелиться решительно отказывались, зато зазоры между их
витками обретали всё новые и новые измерения, складываясь в нравоучительное
«ЗАВЯЗЫВАЙ СО СТИМУЛЯТОРАМИ, МУДАК, ОСОБЕННО ДО ПОЛНОЙ ДЕТОКСИКАЦИИ») и
немедленно наткнулся на крайне профессиональный взгляд вошедшего. Равнодушный,
      почти не сфокусированный и до отвращения
      цепкий.</p>

    <p>Сразу чувствуешь, за сколько дней окупится какая часть твоего тела.</p>

    <p>Здравствуйте,
      Зина, начальник всея борделей Порта.</p>

    <p>Дима знал, что
      Зина вечно ходит в мундире Пассажирского Флота, и был морально готов, но от
      режущей глаз белизны одеяния это не спасло. В чём, видимо, и состояла задумка:
      лицо у Зины было довольно-таки никакое, щекастое и пегое, но после столкновения
      с нашитыми на место лычек нежно-розовыми цветочками это уже никого не
      волновало.</p>

    <p>Ну, кроме всех
      остальных людей, торчащих в каморке, которых волновало ещё и содержание его
      высказывания.</p>

    <p>— Узнал,
      сколько? — живо обратился к нему Святотатыч.</p>

    <p>Зина изобразил
      крайнюю заинтересованность в том, чтобы очистить поверхность стола, на который
      вознамерился усесться, и ответил через плечо:</p>

    <p>— Это непросто,
      местечко дерьмовое — из тех, где не считают. Сказали, от десяти до тридцати.
      Или больше.</p>

    <p>Примерно в этот
      момент Дима испытал очередной припадок острого нежелания догадываться, о чём
      именно идёт речь.</p>

    <p>— Ох ты бля. Кто-то наконец решился
      использовать Габриэля Евгеньевича по назначению? — звонко хлопнул себя по лбу
      Гуанако.</p>

    <p>И ему тоже спасибочки за чёткое разъяснение ситуации, которая
      прекрасно вписывалась в категорию загадок мироздания.</p>

    <p>Зина. Портовые
      бордели. Прямое назначение Габриэля Евгеньевича на пост инициатора всех
      эротических снов округи.</p>

    <p>Прямое
      назначение <i>чумного</i> Габриэля
      Евгеньевича в бордель, где не считают.</p>

    <p>Чума в Бедрограде
      по определению подразумевает посильное участие Порта, или теперь сей инцидент
      полагается именовать иначе?</p>

    <p>— Это самое
      эффективное, что можно было придумать, — помахал бородой Святотатыч. — Если
      отбросить вариант с распиливанием на части и подбрасыванием деликатеса крысам и
      чайкам.</p>

    <p>Интересно,
      крысы и чайки могут болеть водяной чумой? Переносить заболевание? Латентно
      переносить заболевание?</p>

    <p>Умудрённый опытом общения с коровами Дима даже вспомнил об этом
незначительном аспекте возможного кромешного пиздеца, даже вовремя вспомнил —
за пару дней до поезда из Столицы, но Таха Шапка только на него нагудел: «В
      Бедрограде домашних животных не держат,
      уличных ещё когда повывели, воздушно-капельного нет, как им заразит’ся? Пуст’ не дают коровам
пит’ из-под крана», — и забраковал блистательный план провести в
      Медкорпус если не корову, то хотя бы свинью какую-нибудь завалящую.</p>

    <p>О том, что
      животные включают в себя не только скот, умудрённый опытом общения с коровами
      Дима, в отличие от Тахи Шапки, как-то не подумал.</p>

    <p>О том, что чума
      в Бедрограде включает в себя не только Бедроград, но и Порт, ни один из них не
      подумал принципиально.</p>

    <p>Недовольный
      ролью узника, крыса Габриэль Евгеньевич сердито пыхтел и отчаянно отказывался
      понимать, что его заперли в клетке для его же блага. Дабы не повторил судьбы
      прототипа.</p>

    <p>Которого, стоит заметить, жизнь на замке не спасла.</p>

    <p>Святотатыч тем временем извлёк из неизведанных глубин толстый
раздёрганный гроссбух (на сей раз Диме было лень оценивать свой словарный
запас, так что он ограничился молчаливой жалобой на то, что следовало всё-таки
в семнадцать лет прятаться на лингвистическом факультете, а не на историческом,
хоть у него и здание с колоннами). Гроссбух, или корабельный журнал, или
      что-то такое —&nbsp;короче, толстую раздёрганную тетрадь. Святотатыч извлёк её
      и погрузился в ползанье носом по рукописным строчкам таинственного содержания.</p>

    <p>Озьма
      недовольно покосился на Зину, отодвинувшего следы его трапезы, дабы
      когда-нибудь воссесть рядом с их местоположением, и почти радостно протянул:</p>

    <p>— Вот, значит,
      какие новости. Значит, чума у нас. Гуанако, так мы <i>точно</i> не договаривались.</p>

    <p>Гуанако был в
      курсе.</p>

    <p>Гуанако с
      самого первого упоминания Габриэля Евгеньевича в Порту догадывался. Видимо,
      ввиду скудного воображения, не позволяющего представить оного
      ни в какой роли, кроме бордельной.</p>

    <p>— Да отсохни ты
      уже от него, сколько можно! — рявкнул на Озьму
      Святотатыч. — С одним виноватым далеко не уплывёшь, в своём глазу тоже нелишне
      мачту поискать.</p>

    <p>— Этот, —
      резонно (и по-прежнему как-то скорее удовлетворённо) ткнул Озьма пальцем в
      направлении Габриэля Евгеньевича (и сопутствующего Димы, кстати), — не наша
      мачта.</p>

    <p>— Этот пришёл
      сюда через <i>наши</i> ворота, — не жёстко,
      но где-то рядом с этим воспротивился Святотатыч. — Сечёшь? Лады, мои постовые —
      говно, но и твои вышибалы, значит, не лучше.</p>

    <p>Об этом,
      видимо, повествовал гроссбух, от которого он явно не намеревался отклеиваться.</p>

    <p>Озьма немного поскалился, но в итоге махнул рукой с некоторой
      беспечностью. Мысль о том, что тут кого-то чётко, по-деловому, за что-то
      конкретное (и явно переводимое в твёрдую деньгу!) прищучили, его бесстыже радовала.
    </p>

    <p>— Кончай
      разборки, гнильё это всё, — тряхнул он коротко стриженой головой. — И не бурли
      ты, не наезжаю я на твоего выкормыша, я на его
      собственных выкормышей наезжаю. Чё они, овцы, удумали в своих академиях? Порт
      выжечь к хуям?</p>

    <p>— Да хотели бы
      — не потянули б, — похоронно усмехнулся Гуанако. — Кишка узка.</p>

    <p>— Бедроградские
      дерзить бы не стали, им с нами ссориться не алё, мы ихний берег
      кормим, — не согласился Озьма. — Хочешь
      сказать, этот на своих двоих дошкандыбал?</p>

    <p>— Господа,
      позвольте вставить слово человеку, который лично разбирался, кто, как и куда дошкандыбал, — мелодичнейшим
      голосом пропел Зина. — У меня есть сведения, что о своих двоих речи не идёт.</p>

    <p>Уши всех
      окружающих (особенно крысы Габриэля Евгеньевича) зримо развернулись в его
      направлении.</p>

    <p>(Дима украдкой
      ощупал голову и не обнаружил никаких аномалий вроде повышенного внимания к
      дальнейшей беседе.)</p>

    <p>Зина
      подошёл-таки к телу Габриэля Евгеньевича, склонился над ним (его взор успел ещё
      раз проползти по Диме, особенно почему-то по рукам), передёрнулся и выхватил из
      кармана сияюще-белый платочек, коим отёр ладони.</p>

    <p>(Коими не касался ничего заразного, между прочим. Разве у него не должно быть профессиональной деформации?)
    </p>

    <p>Видимо, должна, потому что продолжил он абсолютно хладнокровно:</p>

    <p>— Господин
      Онегин попал вчера в середине дня в некое увеселительное заведение под чужим
      именем и с чужой легендой. Легенда наивная, но как раз в такую
      соответствующий контингент верит запросто: наркотики, долги, надо укрыться и
      хоть что-то заработать. Подобные пожелания можно без особых трудностей
      удовлетворить разве что в самых низкопробных местах, где не имеют значения ни
      профессионализм, ни текущее состояние, но где также не думают об учёте,
      фиксировании посетителей и санитарных предосторожностях.</p>

    <p>Не лезет в
      голову свой словарный запас — так влезет чужой.</p>

    <p>Дима, конечно,
      всё понимает. Святотатыч вон — удивительный человек: с портовыми по-портовому, со внешним миром — по-человечески. Гуанако туда же. Но Зина-то
      — Зина искренне полагает, что тут собрался соответствующий контингент для
      «соответствующего контингента», или тупо выёбывается? И, главное, перед кем?</p>

    <p>Ну не перед
      Димой же!</p>

    <p>(Может, они
      там, в Пассажирском Порту, все такие.)</p>

    <p>— Ты воду-то не
      лей, ты центровое выкладывай: кто припёр? — оборвал
      велеречивость соответствующий контингент в лице Озьмы.</p>

    <p>Зина томно
      вздохнул.</p>

    <p>— Уточняю
      запрос: кто припёр и кто пристроил? —&nbsp;прибавило
      бородатое лицо соответствующего контингента, не отрывая носа от гроссбуха.</p>

    <p>Зина ещё более
      томно вздохнул и что только не обмахнулся платочком.</p>

    <p>— С уточнением
      попроще — пристраивали наши. Мои. Одна-единственная
      дама, — Зина сделал драматическую паузу, и ему, кажется, за это даже не было
      особо стыдно, — мы ей с конца весны всё собственное заведение обещаем.</p>

    <p>На сей раз на Зину среагировали не уши окружающих, а их глаза.
      Они стали очень большими и очень вольными вне орбит, из которых вылезли.</p>

    <p>(Дима украдкой
      проверил свои глаза и не обнаружил ничего аномального.)</p>

    <p>— Пизданись, —
      присвистнул Гуанако. — Не дождалась расплаты, сама пошла паруса распускать в
      сутенёрском деле? — Он помолчал и прибавил с
      очередным тяжким подозрением в голосе. — Или переосмыслила свои грехи и таки
      кинула?</p>

    <p>То ли Дима
      чего-то не знает, то ли Диме это чего-то кто-нибудь когда-нибудь рассказывал в
      таких обстоятельствах, что он сразу забыл.</p>

    <p>Какая-то
      женщина бордельного толка, которой здесь все за
      что-то явно должны, приютила бренного Габриэля Евгеньевича (и икс клеток
      водяной чумы за компанию) в некоем дружественном заведении. Так? Так.</p>

    <p>Ага.</p>

    <p>И?</p>

    <p>Чего за женщина, за что должны и почему
      всё это звучит так веско?</p>

    <p>И таки кинула
      она тут всех (Дима просто не будет пока пытаться это понять) или ей просто
      понравился Габриэль Евгеньевич?</p>

    <p>— Ни то и ни
      другое, — возвестил Зина голосом, полным скорбного достоинства. — Ей можно
      посерьёзней язык поразвязывать, она в надёжном месте
      без связи с внешним миром. Я распорядился. Предварительные расспросы дали
      противоречивые результаты, — и снова сделал драматическую паузу.</p>

    <p>Никакого
      чувства меры.</p>

    <p>— Ты о своём
      языке кумекай, — хмуро вперился в Зину Озьма. — Будешь
      трещать как эти академические, язык в узел заебошится.
      Чем бабло слизывать будешь, коли вдруг чего?</p>

    <p>Вот и Дима
      думал, что кто-то тут просто выёбывается.</p>

    <p>Зина (в отличие
      от) и ухом не повёл:</p>

    <p>— Дама говорит,
      что пристроить человека куда-нибудь её попросил друг её друга. Сам друг дамы
      проживает за границей, прямых контактов с ним она не имеет. Но друг её друга
      был убедителен, в подтверждение своих связей с эмигрантом озвучил парочку довольно личных подробностей, о которых <i>мало кто знает</i>, — на этом ЗНАЧИТЕЛЬНОСТЬ его лица превысила все
      допустимые пределы.</p>

    <p>Никто, конечно,
      не обратил внимания на такую безвкусицу. Всё были очень заняты обменом
      неподъёмными взглядами.</p>

    <p>Речь ведь идёт
      о чём-то (ком-то) очень простом и краеугольном, да?</p>

    <p>— И самый
      тонкий момент, — нежно улыбнулся Зина, не сбиваясь с размеренного (то есть
      невыносимо медленного) темпа своего повествования. — Согласно словесному
      описанию друг её друга напоминает — разумеется, мне, а не даме, — Максима
      Молевича.</p>

    <p>О, вот кто это
      такой, Дима знает!</p>

    <p>Знать — знает,
      а вот верит ли — другой вопрос.</p>

    <p>Максим отдал бы Габриэля Евгеньевича другим мужикам (и женщинам?
      Гм!) только в том случае, если бы ему ультра-спешно нужно
было надоить с себя литр адреналина. Охрович и Краснокаменный давеча
      долго и радостно сливали Диме, как Максима корёжит от
      одних только студенческих взглядов.</p>

    <p>А с другой
      стороны, всё меняется.</p>

    <p>— Ну что я
      говорил! Твои, твои объебосы, — потёр руки Озьма.</p>

    <p>— Нет, — очень
      резко и очень мгновенно ответил Гуанако (вот Озьму он слушать удосуживается,
      смотрите-ка). — Обычный вброс. Надо бабе картинку
      сунуть. Глаз даю, не опознает.</p>

    <p>— Картинку
      сообразим, — с огромным сомнением вынул нос из гроссбуха Святотатыч, — но ты не
      торопись его в святые возводить.</p>

    <p>— Не он, —
      отрезал Гуанако почти со злостью.</p>

    <p>Очень такой
      искренней злостью — не как когда он принимает боевую стойку, а на самом деле.</p>

    <p>— Он, он! Он
      птичка-то неощипанная, — Озьма многозначительно постучал по тарелке лысой
      куриной костью, имея при этом по-детски счастливый вид. — Свою игру завёл, Порт
      решил потопить, чтоб долги скостить, тебя подъебал,
      с блядью своей рассчитался честь по чести. Ну точно он.</p>

    <p>— Не верю. Не
      мог. Мне, блядь, лучше знать, — рявкнул Гуанако чуть
      менее агрессивно, но твёрдо.</p>

    <p>Дима
      распрекрасно знал это выражение лица. Называется «Сергей Корнеевич Гуанако ещё
      не разобрался, каким образом Сергей Корнеевич Гуанако виноват в сложившейся
      ситуации, но уже очень злится. На Сергея Корнеевича Гуанако».</p>

    <p>Сергей
      Корнеевич Гуанако возвёл Максима в головы гэбни не за красивые глаза и даже,
      как ни странно, не за красивую задницу. Сергей
      Корнеевич Гуанако в Максима правда верит.</p>

    <p>И можно сколько
      угодно взъедаться на Максима за то, что он медлительный, чересчур серьёзный,
      легко впадает в предвзятость, каменно убеждён в своей правоте и может
      когда-нибудь взорваться в отвратительно ожидаемом месте, но —</p>

    <p>Но можно ещё
      взъедаться на Максима за то, что он жалок, что он готов терпеть любые унижения,
      что он неспособен увидеть в своей жизни ничего, кроме потирающих висок пальцев
      Габриэля Евгеньевича, что он из-за этого страшно скучен, что что
      угодно.</p>

    <p>Суть одна: если
      у Максима и есть смысл жизни, то он заключается в хранении алтаря, коему имя — два
      заветных слова.</p>

    <p>Вернее, такого
      Максима знал Дима.</p>

    <p>Это когдатошний Максим, которого знал когдатошний
      Дима.</p>

    <p>Май был давно.</p>

    <p>— Давайте до
      опознания держать свои частные мнения при себе, — примиряющим тоном попросил
      Святотатыч. — Проверить-то просто. Зина, будешь валить, я тебе из досье
      картинку выдам.</p>

    <p>Гуанако вынырнул
      из своих традиционных дум (не так просто навскидку решить, чем именно в этой
      ситуации провинился Сергей Корнеевич Гуанако, слишком много вариантов).</p>

    <p>— Святотатыч, —
      интимно обратился он ясно к кому. — Мне тоже выдай. Не Максима, а семейный
      портрет гэбни города Бедрограда. Припёрло вдруг на
      седьмой день чумы им в глаза посмотреть.</p>

    <p>Святотатыч
      нахмурился, хотел было спросить Гуанако (о мотивациях,
      вестимо), но в итоге кивнул и спросил о другом и у другого (у Зины):</p>

    <p>— Баба
      обеспечила свободный вход на территорию?</p>

    <p>— Вход — это
      громко сказано, — слегка закатил глаза тот. — Неопознанный доброжелатель внёс
      бесчувственное тело на руках мимо знакомого постового нашей дамы, который и смотреть-то особо не стал.</p>

    <p>— А мы всё утро
      всрали на пустую чесотку языка с постовыми, —
      сокрушённо покачал головой Гуанако.</p>

    <p>Он клёвый, очень неожиданно для себя постановил Дима, когда
      думает об одном, а говорит о другом. Потому что вроде как стоит себе человек,
      две руки, две ноги, а на деле там куча всего одновременно происходит.</p>

    <p>Он вообще клёвый, когда думает. Потому что тогда у него всегда
      растерянный и немного беззащитный вид, которого никакой агрессивностью и
      твёрдостью не перешибёшь.</p>

    <p>Он вообще клёвый.</p>

    <p>Потому что.</p>

    <p>— Надёжная она,
      баба эта, ей поверили, — изгнал Святотатыч несвоевременные мысли из Диминой
      головы. — Открыли ворота и забыли.</p>

    <p>— Эту надёжную
      на свечи и подстилки пустить надо, — проворчал Озьма.</p>

    <p>— Зина, ты ей
      обрисовал, чё она натворила?</p>

    <p>Зина кивнул:</p>

    <p>— Так о
      количестве посетителей у меня от неё и хворост. Испугалась, побежала сама
      узнавать.</p>

    <p>— От десяти до
      тридцати, — задумчиво почесал бороду Святотатыч. — От десяти до тридцати тех,
      кто не гнушается подобным сервисом. У нас чрезвычайная ситуация, ммм, господа.</p>

    <p>«Чрезвычайные обстоятельства,
      ты хотел сказать, —&nbsp;мысленно высказался Дима. — Видимо, примерно такие, за
      отсутствие которых пару часов назад отстранили
      Университетскую гэбню».</p>

    <p>Вслух не
      высказался, потому что как раз сейчас отчётливо видел вокруг бороды Святотатыча
      светло-фиолетовый ободок. Ничего порочного или страшного (завязывай со
      стимуляторами, мудак, всё, что ты мог сделать, ты уже сделал, спасибо), просто
      как-то неловко лезть к такому человеку.</p>

    <p>А ведь когда
      пытался курить что-нибудь эдакое, никаких эффектов!</p>

    <p>— Я заведение
      прикрыл аккуратненько своими способами, но что уж теперь, — негромко заметил
      Зина.</p>

    <p>— Это верно, да
      только мало, — помахал Святотатыч тетрадкой. — Всё прикрывать надо.</p>

    <p>Озьма во
      всеуслышание стукнул куриной костью по столу.</p>

    <p>Зина смял в
      руке свой платок.</p>

    <p>Гуанако
      продемонстрировал на лице весь спектр людских страданий.</p>

    <p>— Объявляем
      внутреннюю блокаду Порта, — сказал Святотатыч и протянул руку к телефону.</p>

    <p>Дима осознал
      масштаб катастрофы.</p>

    <p>— Погоди, друг,
      — вскочил со своего места Озьма (видимо, тоже осознал). — Нам только блокады не
      хватало, и так в час теряем по пол-Индокитая в бабках. Я против.</p>

    <p>— Теряем-то теряем, — равнодушно проговорил Зина, старательно
      изучавший пространство перед собой, — но выпустить болезнь в море нельзя. Вот
      тогда — пиздец всему, можно самим жечь Порт. Никто из Европ больше не сунется.</p>

    <p>Святотатыч
      поднял трубку.</p>

    <p>— Увы, друг, —
      посмотрел он на Озьму с извиняющейся улыбкой. — Плакали бабки. Новые отчеканим.</p>

    <p>Тот задумался было, но, увидев, что диск уже крутится, мгновенно
      возопил:</p>

    <p>— Да стой ты!
      Решение большинством принимают. Гэбня — это как бы четверо лохов. Зуда, Жудия-то спроси.</p>

    <p>Не того ли это
      Жудия, которого Озьма тут только что ласково крыл по всем гипотетическим
      батюшкам за излишнее влияние на оборот твири?</p>

    <p>О переменчивая натура.</p>

    <p>(И
      оптимистичная: насколько Диме было известно, Жудия не видел вживую почти никто,
      и уж точно никто не мог ему досаждать по мелочам вроде блокады Порта.)</p>

    <p>В улыбке
      Святотатыча прорезалось лёгкое самодовольство.</p>

    <p>— Эх. Жудий мне
      сразу, как нашли тело, сам позвонил и сказал: блокаду, срочно. Так что извиняй,
      решение большинством принимают.</p>

    <p>Озьма открыл было рот, но тут же его и закрыл.</p>

    <p>Наверное, он
      теперь особенно сильно ненавидит свою малую долю в твиревом
      деле.</p>

    <p>— Мальчик мой?
      — усталым и ласковым голосом обратился Святотатыч к трубке. — Передай всем
      постам: Порт ложится в дрейф. Да, что слышал. Да, да, именно. Раздеть суда,
      перекрыть бухту, задраить ворота. По чрезвычайной инструкции, да. Основания? —
      Святотатыч замолчал, потом еле заметно пожал плечами. — Основания позже. Отбой.</p>

    <p>— Забыл плохую
      шутку про такелаж и «целýю» перед отбоем, —
      пробормотал Гуанако себе под нос, осмыслил услышанное и вскинулся. — Ты
      ёбнулся?! У тебя не слеплены основания для блокады?</p>

    <p>Вести, что ли,
      статистику того, что и от кого он слышит, а что нет?</p>

    <p>— Нет, — просто
      и обезоруживающе развёл руками Святотатыч. — Нужно лепить. Но закрывать Порт
      прямо сейчас — нужнее.</p>

    <p>Где-то Дима уже
      видел эту блистательную стратегию: сперва сделать, а
      потом подумать, как бы это объяснить человеческим языком.</p>

    <p>Иногда
      срабатывает.</p>

    <p>Чаще — нет.</p>

    <p>Озьма был полон
      невыраженного внутреннего бунта и не знал, на кого бы его излить, поэтому
      переводил гневный взор с одного присутствующего на другого. Зина хмуро смотрел
      прямо перед собой и чуть шевелил губами —&nbsp;готовил, видимо, несуществующую
      речь для Пассажирского Порта. Святотатыч удивительным образом воплощал
      одновременно беспечность и озабоченность, листая свой гроссбух.</p>

    <p>Гуанако стал
      каким-то совсем уж серым, и Дима понял, что не знает: это очередной спецэффект
      от стимуляторов или на нём и правда настолько лица нет?</p>

    <p>(Ну и выраженьице, вообще-то говоря. Сразу представляешь
      обнажённые мимические мышцы, хрящи, всё такое. Хотя, с другой
стороны, кто сказал, что понятие «лицо» включает в себя только верхний слой?)</p>

    <p>Гуанако стал
      каким-то совсем уж серым и быстро попытался что-то сказать, но не сказал,
      потому что его перебили.</p>

    <p>— Обратная
      ветрянка!</p>

    <p>Дима почти
      завертел головой в поисках источника звука — только мгновенная реакция и
      позволила ему сообразить, что говорил он сам.</p>

    <p>Ой.</p>

    <p>Три головы
      Портовой гэбни неожиданно синхронно обернулись на него с выражением вежливого
      недоумения на лицах.</p>

    <p>Гуанако тоже
      обернулся, и лица у ��его было уже чуть больше.</p>

    <p>Осталось только
      понять, что, собственно, Дима собирался сказать-то.</p>

    <p>(Иногда
      срабатывает.)</p>

    <p>— Мы не можем
      во всеуслышание говорить о чуме, правильно? Но при этом карантин — он же
      блокада — нужен, причём именно карантин инфекционно-медицинский. Порт же такое
      место — говорю как специалист, я был тут целых три с половиной раза — где
      какие-то законы, может, и писаны, но каждый всё равно лучше знает. И нормальный
      карантин возможен, наверное, только тогда, когда портовые мужики сами поверят,
      что им лучше поостеречься. О чуме говорить не можем, но о какой-нибудь другой
      болезни ведь —&nbsp;вполне! — Дима так воодушевился собственной тирадой, что
      вскочил. — И болезнь должна быть правильная: громкая, заразная, неприятная. И
      смешная. Чтобы, с одной стороны, не хотелось вляпаться,
      а с другой — не было обидно, потому что вся блокада в любом случае обернётся
      достойной байкой. Поэтому — обратная ветрянка.</p>

    <p>Остальные его
      тирадой, кажется, не воодушевились.</p>

    <p>(Чаще — нет.)</p>

    <p>— Обратная
      ветрянка? — смущённо переспросил Зина. — При всём моём уважении, её же не
      существует. Это отрядская байка. Говорят, от обратной ветрянки волосы выпадают.</p>

    <p>— Ничего от неё
      не выпадает, просто чешешься как леший, — возмутился Озьма. — И глаза, глаза
      слезятся как пиздец.</p>

    <p>— Так ведь
      потому и выпадают, что чешешься! И цепляют её, — неожиданно прибавил Зина
      голосом человека, выдающего кошмарную тайну, — на пустыре у нас за отрядом.</p>

    <p>Гуанако
      хихикнул. Озьма раздражённо фыркнул и явно вознамерился восстановить
      справедливость, но Дима прервал его:</p>

    <p>— Господа! Две
      вещи. Первая: как медик со стажем могу вам сказать, что обратная ветрянка
      существует. То есть, конечно, говорить об этом можно только с определённой
      долей условности, потому что, как вы только что любезно продемонстрировали,
      описания симптоматики варьируются, но в «Общем справочнике инфекционных
      заболеваний» 72-го года обратная ветрянка вполне зафиксирована — как народное
      название чего-то там, не помню по-имперски. Авторитетная наука медицина
      считает, что обратная ветрянка характеризуется тем, что она как ветрянка,
      только обратная. Для здоровья не опасна, но очень заразна,
причём раздражение — и, возможно, сыпь — появляется на внутренних слоях кожи,
так что, в самом деле, чешется. Очень чешется. Но глазами ничего не
      видно. Есть, кстати, мнение, что может и не чесаться, и что вообще все люди ей
      хоть раз да болели, просто большинство этого вообще не замечает. Мнение идиотское,
      по-моему, но крайне полезное для поддержания
      некоторого градуса гражданской паранойи. В Порту мало моются и много чешутся,
      отличная среда для того, чтобы население уверовало в свою болезнь. —&nbsp;Дима
      покивал себе и продолжил. — Это было первое. Второе: обратная ветрянка существует,
      но никто в неё толком не верит, потому что это детская болезнь, отрядская
      болезнь, тема для страшилок и пересудов. Блокада Порта из-за эпидемии обратной
      ветрянки — нечто настолько абсурдное, что возмутиться не получится. То есть,
      конечно, кто хочет возмутиться — тот возмутится, но задумка к этому не
      располагает. Понимаете? Больше сил уйдёт на ностальгию по отрядским временам,
      чем на подсчёт убытков. То есть, конечно, и на подсчёт убытков тоже… в общем, это
      рабочая версия. Я и не говорил, что она
      идеальная.</p>

    <p>Категорически
      не воодушевлённый Святотатыч одарил Диму бетонным
      взглядом. Кажется, сегодня у него вообще много что вызывало сомнения.</p>

    <p>И одаривал с
      добрую минуту (Дима мужественно терпел), после чего не выдержал и расхохотался.</p>

    <p>Он <i>всегда</i> это делает.</p>

    <p>И не может не
      радоваться: это ж ему заниматься информационной обработкой Порта, а история про
      обратную ветрянку —&nbsp;как раз в святотатычевском
      вкусе.</p>

    <p>(Они с Димой
      вообще драматически сходятся во вкусах.)</p>

    <p>— В Европах
      решат, что мы скрываем что-то большое, — осторожно высказался Зина. —
      Политическое.</p>

    <p>— Пущай решают,
      — подскочил (при его росте это выглядело довольно-таки устрашающе) Озьма. — На
      болячку-то не подумают, а в ней соль! Болячка в Порту —&nbsp;каюк
      экономике, рыдали денежки. А политика — дело гнилое, пусть обрешаются
      и обшпионятся, что найдут-то.</p>

    <p>А с другой
      стороны, возможно, тирада их и воодушевила.</p>

    <p>По крайней
      мере, пока три головы Портовой гэбни занялись жарким спором о том, что важнее
      сделать первым: запустить слух или отзвониться
      четвёртому голове, — Гуанако, к которому Дима в процессе тирады загадочным
      образом придрейфовал, с довольно благодарным видом
      потрепал его по волосам.</p>

    <p>И это было
      приятно.</p>

    <p>&nbsp;</p>

    <p>Такси и
      грузовики по Порту вне грузовых дорог особо не ездят, но к Святотатычу,
      разумеется, и для них ведут свои лазейки. Дима проследил за погрузкой тела
      Габриэля Евгеньевича и воздержался от прощального поцелуя в лоб.</p>

    <p>Обуревало
      почему-то дурацкое чувство, что больше они не
      встретятся (сегодняшние события, впрочем, тоже можно было назвать встречей
      только с большими оговорками), и не менее дурацкая обида на то, что Габриэль
      Евгеньевич присутствовал на их последней встрече в таком виде.</p>

    <p>…История с
      развалом Колошмы закончилась в меру нелепо и трагично: гэбня распалась,
      Начальник (он же Савьюр) заперся в камере с Гуанако, а потом, когда уже совсем
      всё должно было стать хорошо, Начальника случайно застрелили в суматохе.</p>

    <p>(Правда
      случайно, через кучу лет Дима с Гуанако нашли среди
      скопцов того охранника, которому не посчастливилось нажать спусковой крючок.)</p>

    <p>И это было так потрясающе глупо, что, когда всё в той же суматохе
кто-то добрый позволил Гуанако сходить посмотреть на труп (под видом опознания,
что ли? Опознание Начальника Колошмы, ха-ха), он,
говорят, натурально опустился до целования мертвецов.</p>

    <p>(После чего
      разнёс нахуй нары в своей камере.)</p>

    <p>Вся эта история
      залезла в голову совершенно не по делу — нынче даже никто (почти) и не умирал.
      Просто Дима подумал, что завидует Савьюру (которого даже ни разу в жизни не
      видел!) и прочим так или иначе упокоенным. Лежат себе и не парятся, делать
      ничего не надо. Не надо втолковывать Гуанако, почему так не хочется прогуливать
      завтрашнее рандеву с головой Бедроградской гэбни. Не надо уже очень-очень скоро
      встречаться с Сепгеем Борисовичем и прилаживать его в закрытом на блокаду
      Порту. Думать о дальнейшей судьбе Габриэля Евгеньевича, гадать о прошедшей
      судьбе Брови, видеть все эти опостылевшие-таки рожи
      —&nbsp;тоже не надо. Ничего не надо.</p>

    <p>Смерть — это
      гимн лени, вот что это такое.</p>

    <p>Сладкозвучный
      такой гимн.</p>

    <p>Ещё и
      целоваться приходят.</p>

    <p>Дима выбросил
      сигарету, кое-как затоптал окурок и в последний раз посмотрел в сторону моря,
      куда (конспирация!) уехал грузовик с Габриэлем Евгеньевичем.</p>

    <p>Почти всю жизнь
      провёл в Бедрограде, а моря, кстати сказать, ни разу не видел.</p>

    <p>И никто,
      кажется, не видел.</p>

    <p>Оно вообще
      существует, море это?</p>

    <p>Дима подавил
      желание проверить и поплёлся обратно на третий этаж. В каморке Святотатыча
      разговоры о серьёзном уже закончились, остались только Гуанако и сам
      Святотатыч, причём при явлении Димы первый воровато захлопнул объёмистую папку
      с досье, а второй не менее воровато оборвал какое-то напутствие, которое Дима
      по невнимательности не догадался подслушать из-за двери.</p>

    <p>Нежданный
      гость, картина маслом.</p>

    <p>Даже и
      спрашивать не нужно, и так всё ясно: Гуанако что-то там знает про Соция Всеволодьевича,
      голову Бедроградской гэбни. Гуанако так и норовит этим знанием воспользоваться.
      И наверняка во зло.</p>

    <p>Святотатыч
      задал ещё несколько вопросов относительно обратной ветрянки, Дима любезно
      направил его к «Общему справочнику инфекционных заболеваний» (отдельно указав
      на то, что он, Дима, тут вам не энциклопедия на ножках), после чего они с
      Гуанако покинули-таки помещение.</p>

    <p>Пешком до
      выхода было со всеми петлями минут сорок, и минут сорок над Димой висела
      ненормальная, пришибленная тишина, которая и не тишина
      вовсе (на чаек блокада не распространяется, да и люди от неё не немеют), но —
      лысо в Порту без сигнальных сирен, оповещающих о прибытии и отправлении
      кораблей. Пару раз они всё-таки тявкнули — видать, кого-то только что
      отчалившего развернули на выходе из гавани — а потом осталось только шлёпанье
      их с Гуанако шагов и все другие мелкие бытовые шумы, которые сознание почему-то
      категорически отказывалось осмыслять как звуки.</p>

    <p>Это чувство смутного неуюта гораздо хуже любой
      враждебной обстановки.</p>

    <p>Неуютнее.</p>

    <p>— Давай кинем
      монетку, — атаковал-таки недотишину Гуанако привычным
      нездешним голосом.</p>

    <p>— Давай,
      —&nbsp;покладисто согласился Дима и полез по карманам.</p>

    <p>Просто как
      будто он надеется что-то там обнаружить!</p>

    <p>Вдоволь
      полюбовавшись на поиски монетки, Гуанако протянул на ладони свою. Дима бы даже
      мог не догадаться, откуда она, если бы не предательски хорошее зрение: по
      внешнему краю настойчиво повторялось «Ирландское Соседство» и год чеканки —
      1881.</p>

    <p>Свеженькая.</p>

    <p>Ирландское
      Соседство, как и Всероссийское, вращает деревья, поэтому монеты у них
      симметричные. С каждой стороны по номиналу, только на одной возле числа
      изображены ветви (метёлка хвостом вверх), а на другом корни (такая же метёлка, но хвостом вниз).</p>

    <p>— И что из
      этого считать грифоном, а что решкой?</p>

    <p>— Я бы поставил
      вопрос иначе: что из этого считать чуваком из
      Бедроградской гэбни, а что — твоим столичным гостем, — Гуанако невинно возвёл
      очи горе, после чего посмотрел на Диму с крайней суровостью. — Целых два
      свидания за такой короткий промежуток времени я тебе не дозволяю.</p>

    <p>— Как изящно ты
      делаешь вид, что уже почти и не помнишь, как зовут «чувака
      из Бедроградской гэбни». Гуанако, почему бы тебе просто мне не рассказать?</p>

    <p>— Попроси
      убедительней.</p>

    <p>— Прямо здесь?
      Подумай, что станет с моей портовой репутацией.</p>

    <p>— Здесь нельзя,
      ещё подхватим обратную ветрянку.</p>

    <p>— Но когда моё
      любопытство пребывает во фрустрации, я становлюсь склонным делать глупости.</p>

    <p>— Сначала
      глупости, потом любопытство.</p>

    <p>Если в каждой
      третьей реплике присутствует порнографическая шутка, диалог может продолжаться
      вечно.</p>

    <p>— Ты достойный
      гражданин Всероссийского Соседства, а, Гуанако? Даром что мёртвый, — Дима
      почувствовал, что начинает злиться. — Знаешь все тонкости обращения с
      информацией. Ну так я тоже достойный, и мёртвый, и
      пойду туда, куда захочу.</p>

    <p>С этими словами
      он подкинул монетку — достаточно высоко и достаточно криво, чтобы она улетела аж через крышу какого-то приземистого склада.</p>

    <p>(Или <i>на</i> крышу, если не льстить себе.)</p>

    <p>Гуанако
      проследил траекторию полёта внимательным взглядом.</p>

    <p>— Ты <i>настолько</i> не любишь выбирать?
      —&nbsp;ехидно приподнял брови он.</p>

    <p>— Я не вижу
      необходимости выбора.</p>

    <p>— Вот и я
      думаю, надо забить на обоих.</p>

    <p>— Ваше мнение
      очень ценно для нас, — буркнул Дима и ускорил шаг.</p>

    <p>(Через пару
      секунд он вспомнит, что без Гуанако ему будет трудновато пристроить Сепгея
      Борисовича в Порту, тем более при блокаде, но это случится только через пару
      секунд.)</p>

    <p>Пары секунд
      пройти не успело, поскольку сзади донеслось досадливое «да перестань ты», после
      чего Димину шею весьма прочно обхватила рука (исполняющая захват, или как это
      называется, если не кистью, а всем предплечьем —&nbsp;и где вообще весь
      словарный запас, когда он нужен?). Дима попытался вывернуться, но хрен бы там:
      кое-кто тут имел слишком богатый опыт захвата лично Димы. Удар локтём за спину
      тоже не принёс желаемого результата, только подача кислорода убавилась пуще прежнего.</p>

    <p>Знает Дима, к
      чему обычно ведут подобные инциденты.</p>

    <p>— Здесь нельзя,
      подхватим обратную ветрянку, — просипел он.</p>

    <p>Но Гуанако был
      храбрым человеком, и обратная ветрянка, судя по дальнейшему развитию событий,
      его не пугала. Спасибо хоть за угол успел оттащить.</p>

    <p>(Когда с тобой что-то делают <i>принудительно</i> в интересах большой политики или психологического воздействия — это гнусно, но
в других случаях — вовсе даже и нет. Принуждение, честно говоря, может быть довольно интересным и увлекательным, это Дима ещё в
      отряде выучил — вместо Революции.</p>

    <p>Принуждение
      руками и другими частями тела Гуанако увлекательно вдвойне.</p>

    <p>Когда почти запрещают двигаться, обнаруживаешь у себя доселе
неведомую способность ощущать очень многое и очень сразу.)</p>

    <p>Пока Дима ещё
      хоть что-то соображал, он успел подумать, что на возможность увидеть Сепгея
      Борисовича этого, наверное, хватит, а вот за Соция придётся трудиться
      дополнительно.</p>

    <p>И Дима будет
      трудиться.</p>

    <p>В конце концов,
      он же вроде как постановил, что из действия и бездействия следует выбирать
      действие; а верно ли постановил и правильным ли оказалось
      действие, пусть рассудит высочайшее искусство историографии.</p>

    <p>Когда-нибудь
      потом.</p>
  </>
}

export default chapter22
