import React from 'react'
import { IBookPage } from './index'

const chapter23: IBookPage = {
  header: 'header_d7.jpg',
  title: 'Глава 23. Слишком густые леса',
  prevLink: 'chapter22',
  nextLink: 'chapter24',
  content: <>
    <h2>Университет. Максим</h2>

    <img alt='' src="/images/23_maxim.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>Всему есть предел. Усталости,
      злости, желанию сделать всё правильно — тоже.</p>

    <p>Максим не был сейчас ни усталым,
      ни злым. И правильным тоже не был. Забыл, как это. Вспоминать не хотелось.</p>

    <p>Правильно было — поехать с
      вокзала прямо на кафедру?</p>

    <p>В Бедрограде три вокзала. Тот,
      что в Старом городе, даже не имеет названия — вокзал и вокзал. Иногда — старый
      вокзал, других обозначений не требуется. Самый загруженный, шумный, самый
      привычный вокзал — поезда в Столицу через каждые три-четыре часа, поезда во все
      мыслимые и немыслимые города Всероссийского Соседства. Оттуда до истфака и
      пешком-то совсем близко.</p>

    <p>На севере города — Международный
      вокзал (Ыбержский, Чухонский, Европейский). Впрочем, для заграничных
      путешествий в Бедрограде чаще пользуются Портом, поэтому на Международном
      тихо, спокойно и никогда нет проблем с билетами. Оттуда до истфака добираться
      гораздо дольше, но всегда есть автопоезд, следующий по маршруту номер
      двенадцать — в том числе и по улице Поплеевской мимо Шолоховского переезда,
      Шолоховского парка, мимо дома 33/2, так похожего на башню.</p>

    <p>И только с юго-востока, с
      Пригородного вокзала, на истфак без такси ехать неудобно. Автопоездом — с двумя
      пересадками, таксистских прав у Максима нет, а любой случайный таксист может
      иметь распоряжения от Бедроградской гэбни.</p>

    <p>Опасаться этого всерьёз — глупо,
      зато можно убедить самого себя, что опасаешься. Что опасаешься, что сил на две
      пересадки уже нет, что, если б не Пригородный вокзал, а какой-то другой, —
      непременно поехал бы на кафедру.</p>

    <p>Но поезда с Пинеги приходят
      только на Пригородный.</p>

    <p>Максим стоял у совершенно
      квадратного окна посреди совершенно квадратной комнаты. Квадратность не то
      чтобы раздражала, но не замечать её было тяжело. Навязчивая геометрическая
      правильность, непохожая на бытовую правильность жилых помещений. Такие
      пропорции были популярны недолго, всего несколько лет в конце 50-х, и домов с
      идеально квадратными комнатами в Бедрограде успели выстроить совсем немного. На
      юго-востоке, в двадцати минутах ходьбы от Пригородного вокзала было как раз
      несколько кварталов, спроектированных в тот период. Разумеется, Охрович и
      Краснокаменный, главные поклонники всего малопригодного для нормальной жизни,
      когда-то упорно осаждали Распределительную Службу во имя получения жилплощади
      именно с квадратными комнатами.</p>

    <p>Охровича и Краснокаменного дома
      не было. Неизвестно, появятся ли они здесь сегодня в принципе: Максим
      отсутствовал больше суток, Максим мог пропустить какие угодно поворотные
      события.</p>

    <p>Тот же Ларий, например, ещё с
      субботы ночевал на кафедре — кто-то же должен всё время быть на связи, чтобы
      можно было набрать один-единственный телефонный номер и задать любой вопрос,
      передать любые необходимые сведения. Сами Охрович и Краснокаменный со вторника
      на среду, в первую ночь студентов в лазарете, не спали вовсе, только под утро
      упали на пару часов на кафедральные диваны. Максим не мог быть уверен, что
      прямо сейчас не происходит чего-нибудь столь же существенного, а следовательно, не
      мог быть уверен и в том, что увидит Охровича
      и Краснокаменного в их собственной квартире, до которой от Пригородного вокзала
      всего двадцать минут ходьбы.</p>

    <p>Расстояния — простая отговорка.</p>

    <p>Не поехал сразу на кафедру,
      потому что далеко, а сил нет. Не поехал домой (на Поплеевскую ли, на Бывшую ли
      Конную Дорогу) — потому что ещё дальше. Охрович и Краснокаменный не поверят
      такой ерунде, но самому себе можно попробовать соврать.</p>

    <p>Поехать на кафедру — всё равно что сказать «я готов мириться со всем, что
      происходит,
      и всеми, кто в этом виноват, готов и дальше пытаться справиться с тем, с чем не
      справляюсь».</p>

    <p>Поехать домой — всё равно что сказать «мне плевать, я больше не могу, я выхожу
      из игры, разбирайтесь сами».</p>

    <p>А дойти с поезда до жилища
      Охровича и Краснокаменного, вспомнить, что ключи от квартир всех голов гэбни до
      сих пор на одной связке со своими собственными, —
      разумный компромисс. Отсрочка. Возможность ничего не решать, понадеяться на
      удачу, на то, что всё как-нибудь сложится само.</p>

    <p>Максим никогда не любил тех, кто
      полагается на удачу или стечение обстоятельств, чтобы увильнуть от осознанного
      выбора. А потому сейчас собственная безответственность почти пьянила его, пьянила
      и парализовала одновременно.</p>

    <p>Вот телефон — нужно просто
      протянуть руку, не задумываясь воспроизвести давным-давно заученную наизусть
      комбинацию цифр. Сказать: «Я в городе, я вернулся».</p>

    <p>&nbsp;</p>

    <p><i>«Я пришёл.</i></p>

    <p><i>Прощай».</i></p>

    <p>&nbsp;</p>

    <p>Последняя страница старого
      рассказа Габриэля, вырванная из «Литературы Нового Бедрограда», которая и
      навела вчера Максима на мысль, что искать Габриэля надо на Пинеге, теперь отскакивала
      от зубов не хуже телефонного номера кафедры.</p>

    <p>Эта страница, помятая и
      отсыревшая, обнаружилась под стеклом на стенде с расписанием.</p>

    <p>С расписанием поездов на
      платформе Пинега.</p>

    <p>В четырёх часах пути от
      Бедрограда.</p>

    <p>Разумеется, никто не видел, как
      Габриэль засовывал её под стекло. В билетной кассе пожали плечами, торговка с
      тележкой, полной сладкого молочного самогона — почти ликёра, который умеют
      настаивать до правильной мягкости только в этих местах, — отмахнулась. Сама
      торгует с обеда, когда пришла, лист под стеклом уже был.</p>

    <p>&nbsp;</p>

    <p><i>«Ходи не ходи — всё впустую».</i></p>

    <p>&nbsp;</p>

    <p>Максим ещё вчера мысленно
      поругивал студентку Шухер за томик слезливой росской классики на столе вместо
      профессиональной литературы, а теперь думал: может, зря?</p>

    <p>Наверняка зря.</p>

    <p>И ведь не сказать, чтобы сам он
      мало читал художественную. Немало; по крайней мере,
      достаточно, чтобы Габриэль не считал его бескультурным, бесчувственным,
      бездуховным (а у Габриэля высокая планка). Просто художественная литература
      заставляла Максима чувствовать себя беспомощным.</p>

    <p>С профессиональной всё ясно, и
      степень научности тут роли не играет. Всю профессиональную литературу — от
      монографий и статей до историографических источников, которые какими только ни
      бывают, — объединяет одно: её всегда можно разобрать по полочкам, превратить в
      схему, в опорный конспект.</p>

    <p>(Память услужливо подкидывает вчерашнее: <i>ненормальный,
      контрреволюция, Гуанако, Университетская гэбня, голова гэбни, Ларий, Охрович и
      Краснокаменный, Ройш, Дима, водяная чума</i> — пункты плана, параграфы в
      учебнике.)</p>

    <p>И можно тешить себя иллюзией, что
      вся жизнь, все происходящие события укладываются в конспект, разбираются по
      полочкам, поддаются анализу, классифицируются, занимают положенное место в
      общей картине.</p>

    <p>Только это не так.</p>

    <p>Беспомощность возникает как раз
      тогда, когда приходит вдруг ясное — яснее некуда — понимание, что жизнь гораздо
      больше похожа на художественную литературу, чем на
      профессиональную.</p>

    <p>А даже самый стройный и толковый
      опорный конспект не поможет разобраться ни в слезливом томике росской классики,
      ни в чём-нибудь поновее.</p>

    <p>&nbsp;</p>

    <p><i>«На ветви дерева, почти касаясь ногами травы, висел я — уродливый,
      кривой, с нерасплёсканным хохотом на лице. Я должен
      был заглянуть в свои глаза, должен, но —</i></p>

    <p><i>Слишком страшно?»</i></p>

    <p>&nbsp;</p>

    <p>До сих пор страшно.</p>

    <p>Максим прислонился лбом к стеклу
      и подумал, что невольно копирует сейчас такой привычный, затёртый и даже слегка
      раздражающий жест. Это Габриэль, Габриэль способен замереть
на целый час перед окном, ничего не говорить, ничего не делать, тонуть в свете
постепенно зажигающихся огней.</p>

    <p>Повторение жестов — инстинктивное
      желание продлить присутствие. Естественная и понятная попытка психической
      синхронизации через физическую. Максим знал это, как знает
      любой голова любой гэбни.</p>

    <p>Должно быть, синхронизация бывает
      не только у гэбен. Наверняка.</p>

    <p>Максим оторвался от окна, но
      краем глаза будто бы успел заметить на тёмном небе птичий силуэт (привидится
      же!). Какие птицы, это уже точно полный Габриэль —
      видеть в игре теней странные, невозможные образы. В Порту есть птицы, в
      Бедрограде — нет. На Пинеге есть: много и самых разных, и это так непривычно
      после звуков города, что даже немного сбивает с толку.</p>

    <p>Было бы с чего сбивать.</p>

    <p>Максим решительно развернулся к
      квадратной комнате и ещё раз её оглядел. Охрович и Краснокаменный тщательно
      блюдут у себя равновесие между хлевом и музеем. Запечатанные
ящики, обрезки бумаги, пепельницы со спрессованными под тяжестью лет окурками,
отвёртки и почерневшие от масла гаечные ключи, недособранные
металлоконструкции неизвестного назначения соседствуют с мрамором, мамонтовой
костью и резным деревом, с которых явно сдувают каждую пылинку.</p>

    <p>Где-то во всём этом хаосе должен
      быть алкоголь.</p>

    <p>Максим сунулся подряд в несколько
      незаклеенных коробок и — помимо внушительного запаса
      обезболивающих препаратов — обнаружил-таки с два десятка бутылок твиревой
      настойки. Выдернул пробку, принюхался и опрокинул в себя сколько смог.</p>

    <p>Твиревая настойка горькая, вяжет
      рот. Максим где-то читал, что раньше с этой вязкостью боролись серебряными
      фильтрами, но теперь так не делают, дорого — в лучшем случае кинут в тару
      серебряную ложку ненадолго. Но к любому вкусу можно привыкнуть. Максим пьёт
      твиревую настойку пять дней подряд, Максим уже привык. Дима всё повторял
      «профилактика, иммунитет», но в последнюю неделю Максиму не нужны были
      дополнительные аргументы, чтобы пить. А твиревую настойку или нет — это уж что
      под руку попадётся. Сейчас что на кафедре, что на всём истфаке и медфаке, что у
      каждого университетского таксиста в бардачке обязательно обнаруживалось пойло на
      твири.</p>

    <p>Когда вчера на Пинеге Максиму
      налили молочного самогона, он даже не сразу смог вспомнить,&nbsp;что алкоголь
      бывает таким — невязким, нетерпким, не обжигающим горло. От неожиданности выпил
      больше, чем стоило, — и в результате дошёл-таки до дома, где вырос Габриэль,
      хоть с самого начала и было понятно, что это ничего не даст. Габриэль вряд ли
      заглянул бы перед смертью к Евгению Онеге.</p>

    <p>Люди в этих местах не то чтобы
      странные, но понять их тяжело: за милую душу накормят и напоят случайного
      гостя, но стоит только начать расспросы — чураются: «балакать
      недосуг». Закрытые люди. А Евгений Онега считается отшельником даже среди них:
      живёт на отшибе, с немалым хозяйством справляется сам, за помощью к соседям
      обращаться не любит. Ну и соседи его в ответ недолюбливают, при упоминании
      кривятся, говорят: «Земли много, коров много, а дурной». Его сына Гаврилу,
      уехавшего сразу после отряда в Бедроград, уже мало кто помнит, а если и помнит,
      то тоже не жалует: «Дурной, хилый, городской». А ещё — «бесноватый».</p>

    <p>Убедить этих людей побросать свои занятия и отправиться на поиски пропавшего сына
Евгения Онеги было невозможно. Натолкнувшись несколько раз на скептичное «туда ему
      и дорога», Максим просто перестал
      упоминать имена. Кто-то в результате согласился, но толку было чуть: Пинега —
      это всего полдюжины деревень, скромный ыздный центр с горсткой многоквартирных
      домов и бесконечные пастбища, а где не пастбище, там глухой лес или болото.
      Местные знают свои леса неплохо: где что растёт, где какой зверь ходит, где
      течёт ручей, куда лучше не соваться и как выйти лесом к нужной деревне.</p>

    <p>Только этого недостаточно, чтобы
      найти в лесах одного-единственного человека, добравшегося до станции ещё перед
      обедом.</p>

    <p>&nbsp;</p>

    <p><i>«Здравствуй, я почти уже здесь, я уже не слышу себя; сейчас дыхание моё
      падёт росой, прозрачной, беззвучной, и они не найдут меня, и даже тело, даже
      то, что останется, не ухватят их жадные руки. Они вспомнят, и спросят, и ты
      ответишь — он улетел.</i></p>

    <p><i>На том конце поляны, по щиколотку в траве, стояло белое дерево,
      источавшее тихий свет, пропахший грозовым озоном и ещё чем-то — поднебесным,
      невыразимым».</i></p>

    <p>&nbsp;</p>

    <p>Белое дерево.</p>

    <p>Так назывался злосчастный рассказ
      Габриэля.</p>

    <p>Максим, хлюпая одолженными
      болотниками, всё переспрашивал своих добровольных провожатых: «Точно не знаете,
      где тут белое дерево?» Ни один не вспомнил, все только качали головой: «Ёлы есть, палы тоже есть, мшени есть, дохляки есть, скрипуны, кривуны,
срамны — все есть, дубы даже есть, дрожи-деревья есть, а белых нету».</p>

    <p>По лесам бродили с ночи до обеда,
      провожатые менялись, а Максим так и оставался на ногах. В какой-то момент старый
      смуглый печник («все места тут исходил — доброй глины по полям не найдёшь, ужо повыскребли, только в буреломе поглубже
      и есть чего») просто вывел Максима из чащи прямиком к деревне. Посмотрел строго
      («тебе б передышку») и напоил тем самым молочным самогоном, после которого
      усталость ненадолго схлынула, но пришло осознание: впустую всё, впустую.</p>

    <p>&nbsp;</p>

    <p><i>«Впустую; жизнь в деревне простая и жестокая, исчезают люди чаще, чем
      можно подумать. Этот напился и помёрз, тот ухнул в реку — всех не учтёшь. Так
      что нет ничего удивительного в том, что ни упоминаний о самоубийце, ни семьи
      его я не сумел отыскать — чего уж говорить о самом теле или белом дереве на
      краю поляны».</i></p>

    <p>&nbsp;</p>

    <p>Вырванная страница жгла руки,
      смеялась: мистический рассказ, написанный больше пятнадцати лет назад, к
      реальной жизни оказался ближе, чем любые недавние здравые прогнозы.</p>

    <p>Молочный самогон и слово «семья»
      в предпоследнем абзаце вместе дали в равной степени предсказуемый и нелепый
      результат — поход к Евгению Онеге.</p>

    <p>Тот даже не пожал Максиму руки —
      так вышло, что встретил его по локоть в какой-то грязи, только выдохнул
      недовольно: «Чего, городской?» Максим зачем-то был
      честен: «Пропал ваш сын, есть сведения, что отправился на Пинегу, поможете?»
      Евгений Онега нахмурился: «На «поможете» власть есть. И у нас своя есть — ты не
      смотри, что деревень тут пихт наплакал».</p>

    <p>А Максим чувствовал себя тем ещё дураком, ведь нельзя было объяснить, что нет,
      к Пинежской Ыздной гэбне не
      обратишься — она напрямую подчиняется гэбне Бедроградской.</p>

    <p>И что ещё хуже, нельзя было
      объяснить, что нет, ваш сын столько лет не желал иметь с вами ничего общего не
      потому, что вы делали что-то неправильно. Просто узнал когда-то, что он вам не
      и сын вовсе, и ему до сих пор не то обидно, не то стыдно, что всё так
      сложилось.</p>

    <p>Это непростая история.</p>

    <p>В отряде и первые несколько лет
      после Габриэль стеснялся Пинеги, мечтал о Бедрограде — а
      получив его, мечтал стать бедроградским настолько, чтоб никто и никогда даже
      подумать не мог ни о каких деревнях. Скрывал, врал что-то о родственниках за
      границей (смешно и страшно — угадал), но потом перерос, наверное. На его втором
      курсе «Литература Нового Бедрограда» опубликовала это
      проклятое «Белое дерево», и Габриэль успокоился. На все ехидные вопросы про
      деревню и про имя, данное отцом, только приподнимал брови с
      надменным «и?».</p>

    <p>И, собственно, ничего. Писал
      время от времени Евгению Онеге — коротко, деловито, справлялся о здоровье и о
      деньгах. Получаемый в ответ молочный самогон (удивительный напиток, почти
      уникальный, крепкий в градусах, мягкий на вкус) убирал в дальний угол, но хотя
      бы в канализацию не сливал. Однажды, когда Максим был ещё студентом, он пришёл
      к Габриэлю домой с дипломными правками весь вымокший и без плаща (прямо как
      вчера — снова смешно и страшно). Габриэль, устав слушать его охрипший голос,
      вздохнул и достал бутылку, присланную с Пинеги: «Смягчите уже горло, это должно
      помочь — хорошая вещь, хоть я сам и не люблю».</p>

    <p>А где-то через
полгода после диплома Максима и, соответственно, за полгода до появления
      Университетской гэбни молочный самогон хорошей вещью для Габриэля быть
      перестал. Он снова возненавидел Пинегу, снова не желал даже слышать о ней.
      Потому что где-то тогда Габриэль узнал правду о своём происхождении и всех этих
      политических дрязгах с кассахами.</p>

    <p>Как именно узнал, он до сих пор
      не признался Максиму. Максим подозревал дуэт Димы и Ройша: Ройш — он и в
      двадцать лет Ройш. Да и в семнадцать тоже, в семнадцать он помог Диме
      отвязаться от преследования законом из-за какой-то детской глупости, а походя раскопал Димину историю, отца-кассаха,
      который в юном возрасте шантажировал гэбню ызда
      Пинега своими излишними знаниями о государственной политике.</p>

    <p>Гэбню ызда
      Пинега.</p>

    <p>Пинега.</p>

    <p>Ызд
      Пинега — одна из первых точек на карте Всероссийского Соседства, куда стали
      негласно подселять лишних на Плато Кассэх младенцев. Не единственная, теперь и
      вовсе одна из многих, но.</p>

    <p>Пинежцы,
      считавшие себя отцами кассахских детей, ни о чём таком и слыхом
      не слыхивали, все дела проворачивала гэбня и местный крохотный печной цех,
      врачам из которого (всем поголовно) за несколько лет отстроили новые дома.
      Новый дом за простую услугу — болтать поменьше и некоторых младенцев доставать
      вовсе не из алхимической печи.</p>

    <p>Димин отец смог во всё это влезть
      из-за небрежности тогдашней гэбни, а сам Дима в сентябре своего первого курса с
      подачи Ройша наконец-то разобрался, почему внимание дисциплинарных органов к
      его скромной персоне всегда было особенно пристальным. Настолько пристальным, что
      Распределительная Служба не хотела даже
      выделять ему жилплощади в родном городе, думала послать куда-нибудь за Урал, не
      спрашивая согласия. Редчайшая для Всероссийского Соседства ситуация, только
      семнадцатилетний Ройш и помог: не моргнув глазом, прописал Диму к себе — он же
      Ройш, к нему же не посмеют сунуться.</p>

    <p>Максим помнил: на своём третьем
      курсе Дима неожиданно перебрался в квартиру Габриэля, хотя до того даже
      каких-нибудь его спецкурсов не посещал, что уж говорить об общении на более
      короткой дистанции.</p>

    <p>Почему, зачем, как так вышло —
      тогда было ясно очень смутно, а сейчас просто не хотелось вспоминать: как
      всегда Гуанако, сплошной Гуанако. Вроде как оплакивали вдвоём его исчезновение,
      дооплакивались до того, что всплыли документы
      неположенного обоим уровня доступа, и Диму, воспользовавшись поводом, упекла аж
      на Колошму Бедроградская гэбня — озверели, мерзавцы,
      из-за того, что ещё у кого-то в этом городе появился шестой уровень доступа.
      Выразили своё отношение к реформам путём отправления случайного истфаковского студента в главную политическую колонию
      страны.</p>

    <p>Габриэлю тоже досталось, но его
      таки успела прикрыть новообразованная Университетская
      гэбня, на лету разбираясь со своими полномочиями. Хотя «прикрыть» — это слишком
      громко сказано, скорее уж — «выторговать». Да и то по случайности, которой они
      обязаны всё тому же Диме: Бедроградская гэбня зачем-то предложила Университетской глубоко сомнительную сделку, на которую
      вместо Университета сгоряча согласился лично Дима, в буквальном смысле выскочив
      в момент переговоров из-за угла.</p>

    <p>Согласился понести наказание за
      хранение проклятых документов в одиночку, хотя никто не мог бы просить его о таком. Максим — не мог бы точно.</p>

    <p>И вот где-то между Диминым
      переездом к Габриэлю и Диминым отправлением на Колошму Габриэль и вычеркнул
      окончательно Пинегу из своей жизни.</p>

    <p>Наверняка Дима что-то сболтнул
      про собственного отца и Пинегу, не подумав (как будто он вообще когда-либо
      пользуется мозгами прежде, чем открыть рот). Наверняка Габриэль
      заинтересовался. Наверняка всемогущий двадцатилетний Ройш за
короткий срок выудил откуда-нибудь данные и про безответственную британскую
журналистку, и про не менее безответственного кассаха с плато, не пожелавшего
брать на себя лишние проблемы с ребёнком.</p>

    <p>Размышления над историей о
      происхождении Габриэля каждый раз заново убеждали Максима, что <i>некоторые</i> достоинства этого государства
      по праву должно было признавать и давно почившее контрреволюционное движение.
      Контролируемое деторождение — огромный шаг вперёд по сравнению
      что с Европами, что со всем остальным миром. И дело даже не в поставленных на
      поток уникальных технологиях алхимических печей, которым не существует
      аналогов. Дело в действительно значимом улучшении жизни общества.</p>

    <p>И потому тем более обидно, что
      специфика контролируемого деторождения оставляет лазейки для целого конвейера
      возмутительных и аморальных действий со стороны государства.</p>

    <p>Сегодня днём Максим впервые
      увидел Евгения Онегу, который не имел представления ни о какой международной
      политике, но до сих пор переживал, что с десять лет назад его совсем уже
      городской сын вовсе перестал посылать свои редкие весточки, а на леший знает какое по счёту
      письмо отца ответил коротко и больно: «Не пиши, меня более не интересуешь ни
      ты, ни твоя Пинега, пропади она пропадом».</p>

    <p><i>Собственного</i> сына, обучающегося в пинежском
      отряде, Габриэль в той телеграмме даже не упомянул.</p>

    <p>Сколько он тогда выдержал косых
      взглядов и перешёптываний за спиной, вспоминать тошно. Сначала завёл зачем-то
      ребёнка, едва поступив в аспирантуру, потом довольно быстро отправил его в
      отряд на Пинеге, фактически скинув на Евгения Онегу, а ещё через несколько лет
      начал во всеуслышание заявлять, что никакой Пинеги в его жизни нет.</p>

    <p>Любители грязного белья со всего
      Университета наслаждаются этой сплетней по сей день, а
      Габриэль терпит, игнорирует и иногда подогревает пересуды своим показательным
      циничным равнодушием.</p>

    <p>Потому что Габриэль — далеко не
      та безмозглая кукла, которой его малюют злые языки.
      Наслушавшись Диминых россказней о превратностях судьбы не только полноценных
      кассахов с плато, но и их потомков, уточнив у всесильного двадцатилетнего Ройша
      тонкости и нюансы отношения к ним госаппарата, Габриэль счёл за лучшее как
      можно меньше пересекаться с сыном и демонстративно порвать все связи.</p>

    <p>Как голова гэбни шестого уровня
      доступа Максим смог разобраться, какой во всём этом смысл.</p>

    <p>За кассахами,
проживающими во Всероссийском Соседстве, но родившимися на плато среди коз,
винограда и совершенно иного социального строя, аккуратно приглядывают всю
жизнь. Не то чтобы держат под колпаком, но изо всех сил бдят, чтобы те
      не прознали каким-нибудь случайным образом о своём
      происхождении, ведь любые сомнения в работе налаженной по всей стране системе
      контролируемого деторождения могут сослужить государству дурную службу. Поэтому
      госаппарат радуется каждому кассаху, уезжающему в сознательном возрасте
      подальше от своего официального отца и официального же места рождения. Лучше,
      если эдак из Крайнего Северного региона эдак в
      Столицу.</p>

    <p>Но с Пинеги в Бедроград тоже
      неплохо.</p>

    <p>Можно и как-нибудь тайно
      поддержать, поспособствовать, заманить через отрядских педагогов целевым
      обучением. А можно просто намекнуть Распределительной Службе, что на
      предварительный запрос о жилье для иногороднего стоит
      отвечать быстро, утвердительно и по возможности максимально гостеприимно.</p>

    <p>Например, дать пятнадцатилетнему
      мальчику из глухой деревни квартиру в живописнейшем месте — между оживлённым
      проспектом и тихим, почти одичавшим парком. Квартиру в шикарнейшем
      доме, похожем на башню.</p>

    <p>Затратный подход, но он оправдан
      нуждами государства в сфере информационной безопасности.</p>

    <p>Дети кассахов с плато, рождённые уже
      нормально, посредством алхимических печей, таких затрат обычно не стоят, но
      некоторое наблюдение за ними продолжает вестись. Перестраховаться проще, чем не
      перестраховаться и разгребать потом непредсказуемые последствия. Пресловутый
      отец Димы вот даже додумался оставить в надёжных руках письмо, которое
      надлежало отправить в Британию в случае его гибели или пропажи при сомнительных
      обстоятельствах. Письмо с подробнейшим его жизнеописанием, описанием его
      способа проникновения в государственные тайны и последствий оного. А также с просьбой о политическом убежище для своего отпрыска в
Британии-Кассахии (и почему, почему Дима так и не воспользовался им — было же
столько поводов? Жили бы все сейчас спокойнее, пусть и без лекарства от
      чумы. А вообще-то и без самой чумы — по крайней мере, в том
виде, в котором она случилась).</p>

    <p>Вот и выходит, что особенно
      тесное общение со своими отцами для детей кассахов с плато — непозволительная
      роскошь. Мало ли что.</p>

    <p>А Габриэль — не просто безродный кассах с плато, у которого, в отличие от людей из
      алхимических печей Всероссийского Соседства, есть не только какой-то там отец,
      но и какая-то там мать. <i>Его</i> мать —
      проклятая британская журналистка, достаточно известная и, кажется,
      здравствующая по сей день. Вдруг она на старости лет усовестится и захочет
      поискать своего брошенного ребёнка, а ещё хуже — ребёнка своего ребёнка? Вдруг
      найдёт?</p>

    <p>Максиму бы не пришла в голову
      такая муть прямиком со страниц слезливой росской классики. Но о кассахах во
      Всероссийском Соседстве первым Габриэлю рассказывал не Максим, а Дима, опираясь
      на собственное больное воображение и умозаключения двадцатилетнего Ройша.</p>

    <p>С повсеместным введением во
      Всероссийском Соседстве контролируемого деторождения половина сюжетов слезливой
      росской классики перестала быть актуальна и обратилась лишь материалом для
      науки истории. Только Габриэль, в отличие от Максима, не сегодня и не вчера
      поверил, что реальная жизнь гораздо ближе к художественной литературе, чем к серьёзным
      истфаковским учебникам.</p>

    <p>Поэтому-то Габриэль и решил, что
      с точки зрения бесконечно перестраховывающегося госаппарата его сыну следует как
      можно меньше контактировать с отцом. Во
      избежание любых проблем — как реальных, так и вымышленных.</p>

    <p>И перестал даже писать на Пинегу.</p>

    <p>Этого самого сына ещё сегодня
      днём Максим тоже видел у Евгения Онеги. Как и последнего
      — тоже впервые в жизни.</p>

    <p>Сын Габриэля появился откуда-то
      из недр сада, постоял в отдалении, подумал и явно вознамерился подслушать
      беседу Евгения Онеги с гостем. Гости, подумал Максим, тут появляются редко.
      Сегодня уже пятница, подумал Максим, а в понедельник юбилей Первого
      Большого. Следовательно, дни для посещения дома, которые в областных отрядах
      (ввиду расстояний и транспортной ситуации) обычно регламентированы, перенесены
      с субботы-воскресенья на пятницу-субботу.</p>

    <p>Леший, подумал Максим, и
      попробовал свернуть разговор.</p>

    <p>Не хватало ещё, чтобы сын
      Габриэля узнал, что с его отцом что-то случилось.</p>

    <p>Максим понятия не имел, как
      относится к Габриэлю его сын, но выяснять <i>таким
        путём</i> уж точно не собирался. Слезливая классика росской литературы пусть
      пока побудет отсюда подальше, без неё хватает проблем.</p>

    <p>Сыну Габриэля было, кажется,
      четырнадцать — в будущем году закончит отряд. Это очень, очень много — даже не
      верилось, хоть Максим и знал, что Габриэль обзавёлся ребенком на первом курсе
      аспирантуры. Истерический публичный жест — впрочем, как и вообще всё, что
      Габриэль тогда делал.</p>

    <p>Он ведь не хотел ни в какую
      аспирантуру — тем более на кафедру науки и техники — после скандала вокруг
      диплома. С самим дипломом (провокационным и потенциально скандальным, Гуанако
      же руководил) ничего фатального не произошло, но именно в день защиты Габриэль
      и Гуанако по не известным никому причинам расстались, и Габриэль вместо защиты
      резал вены. Демонстративно, едва ли не на факультете. Прошлый завкаф, Воротий
      Саныч, и ещё несколько человек даже замяли эту кошмарную ситуацию, поставили за
      диплом «хорошо», отправили Габриэля на лето приводить в порядок нервы. Но
      сплетен, разумеется, было столько, что не отмыться до сих пор.</p>

    <p>Связи преподавателей со
      студентами запрещены Уставом Университета. Да, они всё равно случаются. Да,
      Гуанако не первый и не последний — но уж точно оставивший за собой самый
      большой шлейф пересудов.</p>

    <p>Ему-то всегда было плевать, а вот
      Габриэлю, которого таки уговорили на аспирантуру,
      пришлось несладко. Умом Максим понимал, что Габриэль тогда вёл себя
      отвратительно и наломал дров (сына вот завёл, чтобы казаться взрослым,
      серьёзным и самостоятельным — <i>другим
        человеком</i>), но осуждать не мог, просто не мог и всё.</p>

    <p>Пресловутые очки с простыми
      стёклами, которые Габриэль всегда так медленно и так вызывающе снимает,
      появились тоже тогда (опять же: нелепое желание казаться взрослым, серьёзным,
      другим).</p>

    <p>У сына Габриэля очки были
      настоящие.</p>

    <p>У сына Габриэля были очень тёмные
      глаза (кассах с плато?) и довольно светлые волосы
      (британская журналистка?). У Габриэля ровно наоборот, да и вообще с первого
      взгляда сын вроде бы вовсе не похож на него, но, если знаешь наизусть каждую
      чёрточку, ошибиться невозможно: действительно, сын. Та же линия скул, тот же
      лоб, те же тонкие руки.</p>

    <p>Евгений Онега сам не хотел
      обсуждать пропажу Габриэля при мальчике, но тот то ли от природы был чрезмерно
      любопытен, то ли заподозрил что-то по обрывкам диалога. В общем, сын Габриэля
      умудрился сделать так, что мрачный и негостеприимный Евгений Онега всё же
      пустил Максима в дом, предложил пообедать. Отказаться не получилось, хотя
      стоило, но после молочного самогона Максим и так едва держался на ногах.</p>

    <p>Сын Габриэля всё время вился
      рядом в надежде что-нибудь разузнать. Быстро оценил сочетание городской одежды
      Максима и взятых взаймы болотников, начал задавать осторожные вопросы. Его
      детские попытки казаться незаинтересованным, но ненароком спровоцировать-таки
      собеседника забыться и проговориться, почему-то напоминали не слишком трезвому
      Максиму общение с фалангами. Наверное, он совсем рехнулся
      с этой чумой.</p>

    <p>Максим очень хотел выбраться
      оттуда побыстрее.</p>

    <p>Что из дома, где вырос Габриэль,
      что из чумы.</p>

    <p>— Шара, проводи гостя, — пробасил наконец Евгений Онега.</p>

    <p>Габриэль назвал сына Шарлем (в
      честь героя романа Толстоевского). Точнее, он назвал сына на европейский манер:
      Шарлем и ещё парой имён (в честь каких-то других значимых фигур). Остальных
      имён Максим не помнил, но догадывался, что — так или иначе — быть Шарлем в
      отряде на Пинеге нелегко. Если бы не показательная гордость Габриэля, был бы
      его сын Серёжей, подумал Максим и вдруг понял, что холить и лелеять свою обиду
      столько времени всё-таки стыдно.</p>

    <p>Но ещё стыдней то и дело
      возвращаться к мысли, что если бы на Пинегу поехал не Максим, а «Серёжа», то справился
      бы гораздо лучше. Заговорил бы зубы местным, выложив
      свой стандартный набор скабрёзных анекдотов про Революцию, и в два счёта
      разыскал бы и проклятое белое дерево, и Габриэля.</p>

    <p>Белое дерево.</p>

    <p>Максим стоял как вкопанный у
      входной двери и смотрел на белое дерево.</p>

    <p>Белое дерево на краю поляны,
      отсыревшая за ночь трава, завораживающий свет и лесная чёрнота на фоне.
      Неплохая — выразительная и техничная — акварель с неразборчивой подписью и
      датой (лет за десять до рождения Габриэля) была прибита к внутренней стороне
      входной двери. Гвоздь, на котором она держалась, давным-давно покрылся
      ржавчиной.</p>

    <p>— Красивый пейзаж, — без голоса
      произнёс Максим, обернулся к сыну Габриэля. — Шарль, это реальное место? Это
      где-то недалёко?</p>

    <p>— Белое дерево в наших лесах? —
      приподнял брови тот, посмотрел удивлённо и с насмешкой, совсем как Габриэль. —
      Ну что вы, конечно же, нет. Это, кхм, <i>символизм.</i></p>

    <p>Максим почти пошатнулся,
      инстинктивно схватился за ручку, чтобы выйти уже на воздух.</p>

    <p>— Кстати говоря, я вам не
      представлялся, — окликнул его сын Габриэля. — А пейзаж с белым деревом
      фигурирует в рассказе моего отца. Я этого раньше не знал, но у вас в кармане
      плаща, провисевшего на вешалке всё время обеда, лежит вырванный зачем-то финал.
      Когда вы, раздеваясь, прятали его, вы упустили тот факт, что в журналах имя
      автора дополнительно печатают сверху на каждой странице.</p>

    <p>Максим не хотел, с самого начала
      не хотел говорить, но было уже поздно:</p>

    <p>— Твой отец пропал. Ушёл в
      неизвестном направлении и не вернулся.</p>

    <p>— Вы когда-нибудь видели белые
      деревья? — светски осведомился сын Габриэля. — Вы хотя бы примерно
      представляете себе, где такие растут? Впрочем, после отряда, вероятно, все
      излишние сведения о деревьях, почвах и технологиях переворотов быстро вылетают
      из головы.</p>

    <p>Максим кивнул. Дети знают о деревьях
      всё, дети и переворачивают деревья по завету Набедренных, Большие Перевороты и
      другие проекты всероссийского масштаба не в счёт.</p>

    <p>— Нет тут такого дерева. Ни тут,
      ни в Бедроградской области в принципе, — сын Габриэля вздохнул с
      покровительственным сочувствием. — И разве в поисках пропавшего человека можно
      опираться на художественную литературу? Этот рассказ, хоть я и не видел его
      целиком, знаете ли, тоже символизм.</p>

    <p>От дома Евгения Онеги Максим шёл
      прямиком на станцию, только завернул в ближнюю деревню отдать болотники. Поезда
      до Бедрограда пришлось ждать несколько часов, которые Максим провёл, вяло отбиваясь
      от расспросов говорливой торговки с тележкой
      молочного самогона.</p>

    <p>А в голове прокручивались и
      прокручивались заново строчки с вырванной страницы.</p>

    <p>&nbsp;</p>

    <p><i>«… нет ничего удивительного в том, что ни упоминаний о самоубийце, ни
      семьи его я не сумел отыскать — чего уж говорить о самом теле или белом дереве
      на краю поляны. Ходи не ходи — всё впустую».</i></p>

    <p>&nbsp;</p>

    <p>Пусть белого дерева нет — Габриэль
      всё равно зачем-то приехал сюда, оставил страницу под стеклом стенда с
      расписанием, куда-то пошёл. Раз его не видели ни в одной деревне, значит, он
      сразу направился в лес. Но если бы — если бы! — приметное дерево росло-таки
      где-то здесь, был бы шанс найти Габриэля — живого или мёртвого. А так…</p>

    <p>&nbsp;</p>

    <p><i>«Слишком густые вокруг моей родной деревни леса».</i></p>

    <p><i>&nbsp;</i></p>

    <p>Где-то за стеной взвыло радио, и
      Максим очнулся.</p>

    <p>Соседи Охровича и Краснокаменного
      не слишком-то блюдут ночью тишину. Должно быть, у них тут царит образцовое добрососедское
      понимание.</p>

    <p>Впрочем, Максим был благодарен и
      соседям, и их чрезмерно громкому радио: засыпать у Охровича и Краснокаменного в
      квартире он не собирался, но после половины бутылки твиревой настойки было
      сложно сдержать данное себе обещание. Максиму уже начинал грезиться целый светящийся
      лес из одних сплошных белых деревьев, когда радио насильно вернуло его в этот
      мир.</p>

    <p>Голова была тяжёлая, звенящая.
      Дело не в алкоголе — просто обрывки сна в поезде не слишком помогли
      восстановлению сил, а алкоголь всего лишь довершил начатое лихорадочным
      режимом. Максим понимал, что по-хорошему ему давно пора нормально выспаться,
      простоять не меньше получаса под холодным душем, прекратить издевательства над
      организмом. Но пока что это было невозможно.</p>

    <p>Надежда, что Габриэль таки
      вернулся в Бедроград, побродил по лесам и сел на обратный поезд, была
      ничтожной, но всё ещё не позволяла расслабиться.</p>

    <p>И если кто и может достать
      Габриэля хоть из-под земли, то это Охрович и Краснокаменный. Они умеют брать
      след, они никогда на памяти Максима не терпели поражений в таких делах, всегда
      находили если не саму пропажу, то хоть какие-то полезные сведения об
      обстоятельствах. Вчера утром они отправились в Хащину, чтобы разобраться, как и
      зачем мог Силовой Комитет забрать студентку Шухер, попавшую с тяжёлыми травмами
      в больницу, и уж наверняка разобрались.</p>

    <p>Сейчас Максиму неудержимо (и так малодушно) хотелось внушить себе: если бы не
      Хащина и не
      студентка Шухер, он не сорвался бы в одиночестве на Пинегу. Он попросил бы
      помощи у Охровича и Краснокаменного, он бы вспомнил о них, он бы подумал о них,
      он бы мог верно оценить что свои собственные, что их
      шансы на успех в поисках Габриэля.</p>

    <p>Ведь очевидно, что, нет, не может
      один человек справляться с любым делом одинаково хорошо, кем бы он ни был. Не может быть одинаково эффективен в любой ситуации, не может
взваливать на себя всё сразу. Потому и существуют гэбни, потому и
      существуют уровни доступа, вертикали власти, горизонтали специализаций — да что
      там, потому и существует сам принцип разделения обязанностей. Это просто, это
      должен понимать любой младшекурсник, посещавший хоть
      какие-то занятия в рамках модуля по истории общественного прогресса.</p>

    <p>Того самого модуля, с прицелом на
      который прошлый завкаф, Воротий Саныч, когда-то брал в аспирантуру Гуанако.</p>

    <p>Того самого модуля, за который
      теперь отвечают Охрович и Краснокаменный.</p>

    <p>И всё это — люди, которые, будучи
      студентами, на общественном прогрессе занимались не укреплением и взращиванием
      своих контрреволюционных настроений, а чем-то совсем другим. Как показывает практика
      — чем-то более полезным.</p>

    <p>В отличие от Максима.</p>

    <p>Максим хотел снова приложиться к
      твиревой настойке, но от одного только запаха его замутило и едва не вывернуло
      наизнанку. Всему есть предел — он же сам сегодня многажды повторял про себя
      этот тезис. Когда уезжал ни с чем с Пинеги, когда пошёл домой к Охровичу и Краснокаменному
      вместо кафедры.</p>

    <p>Всему есть предел.</p>

    <p>Попыткам залить алкоголем свои
      ошибки под благовидным предлогом спасения организма от холода, переутомления и
      недосыпа — тоже.</p>

    <p>Максим встал и направился в сортир, по пути заглянув на кухню. Любой госслужащий
      знает
      этот простейший фокус, часто помогающий вести неофициальные переговоры с теми,
      чьё доверие приходится добывать в максимально непринуждённой обстановке. Пьёшь сколько
      нужно для поддержания соответствующей атмосферы, но в процессе периодически
      отлучаешься извергнуть выпитое, хлебнув предварительно
      растительного масла. Какое-то опьянение всё равно неизбежно, но сознание
      остаётся достаточно ясным.</p>

    <p>Фокус с растительным маслом и вне
      профессиональной деятельности помогает привести себя в порядок гораздо быстрее.</p>

    <p>О своих намерениях Максиму
      пришлось пожалеть: в сортире, прямо напротив входа, на
      уровне глаз красовался рисунок в кричащей рамочке (как же Максиму за
      сегодняшний день надоела вся эта живопись и графика на стенах). Рисунок, судя
      по небрежной россыпи дат с короткими пометками вверху листа, был студенческий,
      изъятый или подобранный Охровичем и Краснокаменным после лекции.</p>

    <p>На тетрадном листе проступал
      карандашный портрет Габриэля, сделанный поспешной, но точной рукой. Взгляд
      снизу вверх из-под ниспадающей волной чёлки, призрачные пальцы и сигарета у
      приоткрытого рта.</p>

    <p>В углу карандашная же надпись:
      «Габриэль Евгеньевич отсасывает?». Вопросительный знак перечёркнут решительным
      жестом Охровича и Краснокаменного.</p>

    <p>Символизм, подумал Максим.</p>

    <p>Невозможно, подумал Максим.
      Невозможно терпеть бесконечный ажиотаж вокруг Габриэля.</p>

    <p>Хорошо, подумал Максим, что этот
      рисунок попался Охровичу и Краснокаменному. Даже представлять не хотелось, что
      устроил бы сам Максим автору произведения.</p>

    <p>Быстро, слишком быстро
      провернулись в замочной скважине ключи — быстрые шаги хозяев квартиры застали
      Максима врасплох.</p>

    <p>— Это оскорбительно для
      искусства.</p>

    <p>— Ты пользуешься Габриэлем
      Евгеньевичем, чтобы проблеваться?</p>

    <p>— Габриэль Евгеньевич оскорблён.</p>

    <p>— Габриэль Евгеньевич — синоним
      искусства.</p>

    <p>— Ты неуч, ничего не смыслящий в
      высоких материях.</p>

    <p>— Правильно высокие материи с
      тобой поступили.</p>

    <p>— Так тебе и надо.</p>

    <p>— Ты это заслужил.</p>

    <p>Заслужил.</p>

    <p>— Я ждал вас, — начал объясняться
      Максим, когда ему было дозволено вставить пару слов. — Я сейчас собирался
      позвонить на кафедру, предупредить…</p>

    <p>— Ты врёшь.</p>

    <p>— У тебя в последнее время
      слишком напряжённые отношения с телефоном.</p>

    <p>— Телефон тебе так противен, что
      ты не смог сказать вчера Ларию, что бежишь в Афстралию.</p>

    <p>— Мы бы поняли и простили, но ты <i>не смог сказать</i>.</p>

    <p>Охрович и Краснокаменный
      возвышались посреди тёмного коридора часовыми, охраняющими сортир
      с Максимом. Глупо разговаривать вот так, вот здесь — точно так же глупо, как
      вчера казалось объяснять Ларию посреди беседы про сантехников, что Габриэль
      пропал, собрался умереть, оставил записку.</p>

    <p>Но если бы вчера Максим меньше
      думал о глупости и неуместности, сегодня Габриэль мог бы быть жив.</p>

    <p>— Я был неправ, сорвался из-за
      всей этой нервотрёпки, — неуверенно произнёс Максим, продолжая оттягивать
      момент, когда придётся уже спросить, знают ли на кафедре что-нибудь о Габриэле.</p>

    <p>Охрович и Краснокаменный
      переглянулись, приняли позу поспокойней, показательно
      опустили руки, которые до этого были скрещены на груди.</p>

    <p>— Хорошо.</p>

    <p>— Прекрасно.</p>

    <p>— Восхитительно.</p>

    <p>— Лучше любых ожиданий.</p>

    <p>— Ты сам знаешь, что ошибся.</p>

    <p>— А мы-то и не надеялись.</p>

    <p>Максиму не нравилось, что Охрович
      и Краснокаменный говорили сейчас так мало. Их поток слов ослабевает только в
      одном случае — когда что-то идёт совсем уж не так.</p>

    <p>За сутки у Университета
      образовалось ещё больше проблем, чем было?</p>

    <p>— Послушайте, я… — начал было Максим, но понял, что не вынесет разбирательств
      перед проклятым портретом Габриэля с дурацкими надписями.</p>

    <p>Двинувшись в сторону комнаты,
      Максим едва не споткнулся об огромный тюк, которого ещё пять минут назад в
      обозримом пространстве не было. Охрович и Краснокаменный зашипели на Максима,
      спешно подхватили тюк и поволокли с собой.</p>

    <p>В квадратной комнате было
      квадратное же окно, выходящее на мозаичную стену соседнего дома — абстрактный
      узор с проблесками растительного орнамента. На совершенно глухой стене. Охрович
      и Краснокаменный в своё время сделали всё возможное, чтобы усложнить
      потенциальное наблюдение за окнами их жилища.</p>

    <p>Максим упёрся взглядом в мозаику,
      едва не начав считать изумрудные плитки контуров. Тряхнул головой, сжал в
      кармане кулак.</p>

    <p>— Это был необдуманный поступок,
      о котором я уже сто раз пожалел, — навязчивая мысль о числе изумрудных плиток
      пока не собиралась оставлять Максима. — Не знаю, уместны ли в таком случае
      извинения, ведь подставил я в первую очередь самого себя…</p>

    <p>Охрович и Краснокаменный недобро
      усмехнулись, но ничего не сказали.</p>

    <p>— Я не знаю, что сейчас творится
      в Университете и в городе, не знаю, как повлияло на происходящее моё
      отсутствие. Ничего не знаю, хотя знать — мой долг и первостепенная обязанность,
      которой я пренебрёг. Не только в служебном смысле, — Максим развернулся к
      Охровичу и Краснокаменному. — Я пренебрёг гэбней, а гэбня — это не просто служба.
      Не только служба. Это вы и Ларий, это живые люди, которым, чтобы работать,
      жизненно важно доверять друг другу. Иметь уверенность, что тебя прикроют, тебе
      помогут. Что не бросят трубку, если ты позвонишь. Я бросил. Я не нарушитель
      служебных инструкций — это всё не так существенно, я просто мразь.</p>

    <p>Охрович вытащил пачку,
      Краснокаменный — зажигалку.</p>

    <p>Максим перестал цепляться глазами
      за изумрудные плитки мозаики только тогда, когда ему тоже протянули сигарету.
      Без кривляний и театральных жестов, как-то очень тихо,
      по-свойски… по-дружески?</p>

    <p>— Забей.</p>

    <p>— Расслабься.</p>

    <p>— Все всё понимают.</p>

    <p>— Мы всё понимаем.</p>

    <p>— Мы стреманулись.</p>

    <p>— С тобой могло приключиться что
      угодно.</p>

    <p>— И это стрёмно.</p>

    <p>— Не только из-за служебных
      инструкций.</p>

    <p>— Из-за британского детского
      стишка.</p>

    <p>— Где осёл, козёл и кто-то там
      ещё с музыкальными инструментами.</p>

    <p>— Максим, ты осёл.</p>

    <p>— Совершеннейший осёл.</p>

    <p>— Буквально-таки эталон осла.</p>

    <p>— Но без осла квартет неполон.</p>

    <p>Максим опустился на ближайший
      ящик. Наконец-то почувствовал, как были перенапряжены от недосыпа все мышцы.
      Повращал шеей, размял плечи, вытянул ноги. Попросил ещё
сигарету и начал рассказывать (нет, не рассказывать — изливать) про автопоезд
не того маршрута, про беспорядок в квартире, про записку в печатной машинке,
про выпуск «Литературы Нового Бедрограда» с «Белым деревом», про вырванную
страницу на стенде с расписанием, про сонные деревни и густые леса, про
молочный самогон и Евгения Онегу, про сына Габриэля и стародавний акварельный
пейзаж…</p>

    <p>Охрович и Краснокаменный слушали
      тихо, не стремились перебивать, хотя кое-где таки не смогли удержаться от
      бесполезных теперь советов и осуждения конкретных действий. Максим не имел
      ничего против, он ведь примерно за этим к ним и шёл: услышать, что он не умеет
      проводить поисковые операции, что где-то недодумал, чего-то не заметил, не
      проверил, что ещё есть шансы.</p>

    <p>Чем дальше Максим рассказывал,
      тем яснее он понимал, что не всё так плохо, как ему казалось. У него есть
      гэбня: люди, с которыми не может быть одиноко, и дело, которое не позволит
      надолго выпасть из жизни. У него есть ответственность перед Университетом,
      которая не даст забыть, зачем всё это нужно. У него есть нынешняя чрезвычайная
      ситуация, с которой необходимо как можно скорее разобраться.</p>

    <p>Даже если у него больше нет
      Габриэля — даже если Габриэля нет больше вовсе, а не только у Максима, — есть
      его сын. Да, они совсем чужие люди, но, с другой-то стороны, сын Габриэля в
      следующем году закончит отряд и вряд ли захочет остаться на Пинеге. Если он
      переберётся в Бедроград, Максим мог бы ему помочь. Шестой уровень доступа не
      только усложняет жизнь, но и имеет определённые достоинства. Это совсем не то
      же самое, это не загладит вины Максима перед Габриэлем, но это уже что-то.</p>

    <p>— …Просить об этом сейчас —
      сомнительный шаг что с моральной, что с практической
      точки зрения, но, если бы вы нашли время съездить туда и перепроверить всё ещё
      раз, мне было бы легче, — закончил Максим.</p>

    <p>Охрович придвинул к Максиму стул,
      Краснокаменный зарылся в содержимое тюка, с которым они вернулись домой.</p>

    <p>— Мы бы с радостью.</p>

    <p>— Мы любим прогулки по жопам мира.</p>

    <p>— Природа, знаешь ли, дикая.</p>

    <p>— Дикие коровы, дикие селяне.</p>

    <p>— Но есть одно но. Даже два.</p>

    <p>— Не психуй,
      они имеют шанс тебе понравиться.</p>

    <p>— Ты же взял себя в руки и решил
      видеть во всём только положительные стороны.</p>

    <p>— Здесь они тоже есть. Они вообще
      везде есть.</p>

    <p>— Как и отрицательные.</p>

    <p>— В мире царит отвратительное
      равновесие.</p>

    <p>— Первое но: Габриэль Евгеньевич
      уже обнаружен.</p>

    <p>— Плюсы: живым.</p>

    <p>Максим ощутил, как кровь
      прихлынула к голове, начала распирать черепную коробку изнутри, давить на виски.</p>

    <p>Охрович и Краснокаменный и не
      думали давать ему передышку:</p>

    <p>— Минусы: у него чума.</p>

    <p>— Поздняя стадия чумы.</p>

    <p>— Подсказка: поздняя стадия чумы
      начинается после средней.</p>

    <p>— А средняя — после ранней.</p>

    <p>— А ранняя
      — после заражения.</p>

    <p>— От которого
      до поздней стадии должно пройти некоторое время.</p>

    <p>— Знающие люди говорят, дней пять
      или шесть.</p>

    <p>— Сегодня пятница. Почти неделю
      назад, в субботу, Габриэль Евгеньевич был беспощадно избит.</p>

    <p>— Воскресенье потеряно для
      истории, но в понедельник у него обнаружили сотрясение мозга.</p>

    <p>— Головная боль и звон в ушах.</p>

    <p>— Неровный пульс, бледность,
      вялость.</p>

    <p>— Ничего особенного.</p>

    <p>— Ничего не напоминает?</p>

    <p>— Ещё зрачки и координация
      движений, конечно.</p>

    <p>— Но кто сказал, что один диагноз
      не может идти в комплекте с другим?</p>

    <p>— Это же Габриэль Евгеньевич! Он
      везучий.</p>

    <p>— Это же Габриэль Евгеньевич,
      всем похуй, что там у него опять заболело.</p>

    <p>— <i>Всем</i> похуй.</p>

    <p>Хорошо, когда Охрович и
      Краснокаменный говорят. Их много, они занимают собой всё пространство,
      вытесняют все мысли.</p>

    <p>Максим поймал себя на том, что
      боится мыслей.</p>

    <p>— Где, когда, где он… — попытался
      спросить Максим, но голосовые связки, кажется, подвели.</p>

    <p>Я должен увидеть его, попытался
      объяснить Максим, но слова — не кажется, совершенно точно — застряли в горле.</p>

    <p>— Ты, наверное, хочешь немедля
      прижать Габриэля Евгеньевича к груди.</p>

    <p>— Или не к груди, но такой
      вариант точно исключается.</p>

    <p>— Ему пока что хватит прижиманий,
      лимит исчерпан.</p>

    <p>— Даже мы это понимаем, мы ж не
      звери какие-то, в самом деле.</p>

    <p>— Стоп, что мы понимаем? Что
      Максим хочет или что Габриэлю Евгеньевичу хватит?</p>

    <p>— О понимании второго пункта не
      может быть и речи. Тут мы не звери, мы хуже.</p>

    <p>— Тут мы профессионалы. И наша
      профессиональная честь растоптана и поругана.</p>

    <p>— Прямо как Габриэль Евгеньевич.
      Возможно, он и есть наша профессиональная честь.</p>

    <p>Когда Охрович и Краснокаменный
      говорят друг с другом, это хорошо вдвойне. Они не становятся тише, они так
      легко прогоняют из головы всё, что в ней есть.</p>

    <p>Квадратная комната, квадратное
      окно, квадратные плиты мозаики на стене дома напротив. Чтобы жить было просто,
      нужна всего-навсего квадратная голова. Устроенная как-то иначе.</p>

    <p>— Ты выглядишь не слишком
      радостно.</p>

    <p>— Ты чем-то недоволен?</p>

    <p>— Смерть через повешение в лесах
      тебя устраивала больше?</p>

    <p>— Тот, кто её инсценировал,
      наверное, тоже подумал, что тебе придётся по вкусу.</p>

    <p>— Ловко инсценировал. Записка в
      твоей печатной машинке — это круто.</p>

    <p>— Сопливо
      и убедительно.</p>

    <p>— И никакой возни с почерком.</p>

    <p>— И вырванная страница, и
      подходящий рассказ.</p>

    <p>— Рассказ, посылающий своего
      догадливого читателя прямиком к лешему.</p>

    <p>— К пинежскому лешему, туда же
      любым транспортом неведомо сколько пилить.</p>

    <p>— Сработано мастерски.</p>

    <p>— Не делай страшное лицо.</p>

    <p>— Где твоё уважение чужого
      профессионализма?</p>

    <p>— Покорись, отрешись и признай,
      что он есть.</p>

    <p>Максим вскочил на ноги:</p>

    <p>— Мерзавцы.
      Какие же они мерзавцы. Что они этим выиграли?</p>

    <p>Охрович и Краснокаменный
      неодобрительно фыркнули.</p>

    <p>— Всё.</p>

    <p>— ВСЁ!</p>

    <p>— Встречу гэбен.</p>

    <p>— Твоё увольнение по собственному
      желанию из Университета.</p>

    <p>— И, как следствие, отставку с
      гэбенной службы.</p>

    <p>— Временное отстранение Университетской гэбни.</p>

    <p>— Временную конфискацию нашего
      табельного оружия.</p>

    <p>— Свидание с Дмитрием Борстеном
      завтра утром.</p>

    <p>— Право не признаваться, куда они
      дели Бровь.</p>

    <p>— Заражение Порта нашими руками.</p>

    <p>— То есть как раз не руками.</p>

    <p>— Другими частями тела.</p>

    <p>— Вполне конкретного тела.</p>

    <p>— Попробуй догадаться сам,
      вариантов не так много.</p>

    <p>Максим не мог догадываться.
      Сейчас — точно не мог. По всей видимости, он прослушал краткий пересказ
      пропущенной им части радиопостановки «Чума в Бедрограде», но ничего, совсем
      ничего не понял. Сию секунду всё это менее важно, чем…</p>

    <p>— Габриэль получил медицинскую
      помощь? — голосовые связки Максима всё-таки подчинились.</p>

    <p>— «Габриэль», если б он был в
      сознании, предпочёл бы помощь не получать.</p>

    <p>— Пошёл бы вешаться на белом
      дереве.</p>

    <p>— Не пережил бы осознания того,
      что с ним творилось.</p>

    <p>— И что с ним творили.</p>

    <p>— Ты ещё не сообразил, о чём это
      мы?</p>

    <p>— Или ты принципиально
      игнорируешь кощунственные подробности?</p>

    <p>— Дело твоё, игнорируй на
      здоровье.</p>

    <p>— Хоть кто-то будет иметь крепкое
      здоровье.</p>

    <p>— Ах да, здоровье.</p>

    <p>— Здоровье Габриэля Евгеньевича
      оставляет желать лучшего.</p>

    <p>— Скорейшей кончины от осложнений
      оно оставляет желать.</p>

    <p>— Потому что иначе кончина
      приключится в результате нервных потрясений.</p>

    <p>— Есть, чему потрясаться:
      например, у него теперь шрам на щеке.</p>

    <p>— И причёску ему испортили всякой
      там конспирацией.</p>

    <p>— В общем, кошмар и ужас, лучше
      умереть.</p>

    <p>— Но умереть на месте ему не
      дали.</p>

    <p>— Его осматривал Дима. И решал,
      добить или пусть мучается, — тоже Дима.</p>

    <p>— А Дима, как известно,
      недостаточно твёрд духом.</p>

    <p>Бессильная злость — на себя, на
      чуму, на весь мир — наконец затопила Максима. Затопила, полилась через край,
      выплеснувшись на первое, что оказалось поблизости:</p>

    <p>— Дима?! — Максим взревел, хотя
      ещё минуту назад он еле управлялся со своим почти пропавшим голосом. — Какого
      лешего Дима? Он же не врач, он же ни лешего ни в чём
      не смыслит, он же мог сделать ещё хуже!</p>

    <p>Охрович и Краснокаменный пожали
      плечами.</p>

    <p>— Тем не менее, ты зря негодуешь.
      Он не настолько бесполезен, как тебе хотелось бы.</p>

    <p>— Без него никто бы не узнал
      некоторых волнующих деталей происшествия.</p>

    <p>— Например, откуда у Габриэля
      Евгеньевича чума.</p>

    <p>— Например, из-под крана.</p>

    <p>Их торжествующий вид сбивал Максима столку.</p>

    <p>— Как из-под крана? То есть
      Габриэль заразился дома? Но я не заметил ничего особенного, — задумался он и в
      ужасе осознал, что некогда было замечать: приходил поздно, валился с ног, потом
      и вовсе сбежал оттуда.</p>

    <p>Леший.</p>

    <p>— Напоминаем: распространяемый по
      трубам вирус и должен быть незаметным.</p>

    <p>— Таков был план Бедроградской
      гэбни. Неоригинально, зато практично.</p>

    <p>— И его даже воплотили без
      существенных изменений.</p>

    <p>— Двое ребят из Медкорпуса: тавр
      и какой-то Дмитрий, ты, может, слышал.</p>

    <p>— Какой-то очередной Дмитрий
      зашёл вчера в гости к Габриэлю Евгеньевичу.</p>

    <p>— Правда, не застал того на
      месте, но зато застал косвенные признаки водопроводного заражения всего дома.</p>

    <p>— Дезинфицирующий состав для
      труб, сделанный всё теми же ребятами из Медкорпуса.</p>

    <p>— Повезло какому-то Дмитрию,
      первым донёс до общественности тревожные вести.</p>

    <p>Ещё секунда — и Максим тут
      что-нибудь уничтожит. Разобьёт ящик с твиревой настойкой, голыми руками согнёт
      какую-нибудь металлоконструкцию неизвестного назначения.</p>

    <p>— Бедроградская гэбня рехнулась, — констатировал Максим. — Да, я не прописан на
      Поплеевской, но доказать, что это моё основное и публично известное место жительства,
      не составит вообще никакого труда. На что они надеялись? Это же покушение на
      голову гэбни, это же сразу Колошма всем четверым.</p>

    <p>— Про Колошму потом поговорим.</p>

    <p>— Сейчас не до того, —
      придвинулись Охрович и Краснокаменный.</p>

    <p>— Ты правильно про покушение
      вспомнил.</p>

    <p>— Всё-таки следует
      удостовериться, не было ли оно успешным.</p>

    <p>— Присядь, пожалуйста. И рукав
      закатай.</p>

    <p>— Будем играть в докторов.</p>

    <p>Максим, которого всё ещё колотило
      от злости, хотел опуститься обратно на ящик, но Охрович и Краснокаменный
      выразительно уставились на придвинутый стул. Максим подчинился.</p>

    <p>Охрович и Краснокаменный не
      медики, но чтобы взять кровь на анализ, много специальных навыков не требуется.
      Максим снова бросил взгляд в сторону квадратного окна, позволил-таки себе
      начать пересчитывать мозаичные плитки, изо всех сил стараясь успокоиться.</p>

    <p>Двенадцать квадратов. Записку с
      проклятым «я правда верю, что ваши дела важнее моих»
      писал не Габриэль.</p>

    <p>Двадцать восемь квадратов и один
      потрескавшийся, обвалившийся, ставший почти что треугольником. Последнюю
      страницу рассказа под стеклом на платформе оставлял не Габриэль.</p>

    <p>С другой стороны — тридцать пять
      квадратов, узор ассиметричен. Габриэль не ездил умирать в густые леса, не думал
      о несуществующем белом дереве.</p>

    <p>Ещё восемь квадратов по центру. Габриэль
      был заражён водопроводным путём много дней назад.</p>

    <p>Итого — восемьдесят три квадрата
      с половиной. Вторая половина тысяча восемьсот восемьдесят третьего года,
      оставившая след в новейшей и современнейшей истории благодаря таинственной
      вспышке эпидемии смертельно опасного вирусного заболевания в городе Бедрограде.</p>

    <p>Максим зажмурился, чтобы не видеть
      больше назойливую мозаику.</p>

    <p>Сын Габриэля здраво сомневался,
      что в поисках пропавшего имеет смысл опираться на художественную литературу.
      Даже ничего не понимающему в нынешних сложностях четырнадцатилетнему ребёнку с
      Пинеги было очевидно то, чего не сообразил Максим.</p>

    <p>Слишком стройный получался
      символизм. В жизни такой не встречается.</p>

    <p>Охрович и Краснокаменный даже не
      пошутили про уколы и закрытые глаза. Кто-то из них довольно аккуратно, хоть и
      болезненно, вогнал шприц в вену, перетянув Максиму руку в отсутствии жгута
      верёвкой. Максим думал сказать им, что всё это, разумеется, нужно — для
      точности картины, а так-то вряд ли имеет смысл, поскольку симптомов, похожих на
      ОРЗ, у него по-прежнему нет, а с водой на Поплеевской он последний раз
      контактировал аж во вторник.</p>

    <p>Думал, но не сказал: как только
      шприц был набран, верёвка, заменявшая жгут, соскользнула на запястье и
      обратилась добротной петлёй. Второе запястье было опутано следом ещё до того,
      как до Максима дошло, что происходит.</p>

    <p>— Вы чего? — он распахнул глаза.</p>

    <p>Охрович и Краснокаменный с
      равнодушным видом разбирались каждый со своим концом верёвки, всё крепче
      фиксируя Максима на стуле.</p>

    <p>— Ты был невнимателен. Это
      печально.</p>

    <p>— Мы сказали, что не поедём для
      тебя на Пинегу, потому что тут есть два но.</p>

    <p>— Первое — уже найденный Габриэль
      Евгеньевич.</p>

    <p>— А о втором ты нас так и не
      спросил.</p>

    <p>— Ты расстроил нас.</p>

    <p>— Мы можем соврать, что
      привязываем тебя поэтому.</p>

    <p>— Или потому что Бедроградская
      гэбня вот-вот откроет сезон охоты за головами.</p>

    <p>— Головами какой-то другой гэбни,
      оцени изысканность фигуры речи.</p>

    <p>— Якобы здесь, в объятиях стула,
      тебе пока будет безопаснее.</p>

    <p>— А ещё всегда можно соврать, что
      тебе давно пора пройти бордельный инструктаж.</p>

    <p>— Что мы
      наконец решили взяться за тебя всерьёз. Открыть новые горизонты.</p>

    <p>— Разнообразить твою скучную
      жизнь с излишне хрупким Габриэлем Евгеньевичем.</p>

    <p>— НО МЫ НЕ БУДЕМ ВРАТЬ! — заорали
      они хором, и Максим непроизвольно отшатнулся, насколько позволяла ему верёвка.</p>

    <p>Он слишком давно знал Охровича и
      Краснокаменного, чтобы надеяться сейчас, что это их обычные неуместные шуточки.</p>

    <p>— Наше второе
      но примитивно и предсказуемо.</p>

    <p>— Максим, ты облажался. И мы не
      можем спустить тебе это просто так.</p>

    <p>Охрович и Краснокаменный
      синхронно припали к своему тюку, потом быстро разогнулись, и в воздухе что-то
      свистнуло.</p>

    <p>— Ты будешь страдать.</p>

    <p>— Ты заслужил много страданий.</p>

    <p>В воздухе свистнуло ещё раз.
      Теперь Максим даже почти не дёрнулся. Лицо горело, но только с одной стороны —
      Охрович и Краснокаменный точны с любым оружием. Доподлинно известно, что им
      удавалось, целясь с разных сторон в один объект, сталкивать пули.
      Неудивительно, что и хлыстами они способны попасть практически в одно и то же
      место.</p>

    <p>Через
      несколько секунд до губ докатились первые ленивые
      капли крови.</p>

    <p>Пошло, подумал Максим, невероятно
      пошло, если у Габриэля действительно остался шрам на щеке.</p>

    <p>Внимательно изучив рабочую
      поверхность хлыстов, Охрович и Краснокаменный расселись по ящикам строго
      напротив Максима. Их лица не выражали ничего.</p>

    <p>— Ты знаешь, в чём самая главная
      проблема?</p>

    <p>— Нам даже не весело тебя бить.</p>

    <p>— Это какая-то тоска и рутина.</p>

    <p>— Исполнение
морального долга перед заблудшим головой своей гэбни.</p>

    <p>— И нам совершенно не хочется
      читать в довесок проповеди.</p>

    <p>— Если ты сам ещё не понял, в чём
      дело, проповеди тебя не спасут.</p>

    <p>Максим собирался что-то
      возразить, но рассечённая кожа неожиданно болезненно отреагировала на попытки
      открыть рот. Пока Максим удивлялся ощущениям, Охрович и Краснокаменный вяло и
      без энтузиазма продолжили:</p>

    <p>— Нет, Максим, дело не в том, что
      ты куда-то там слился, психанув по идиотскому поводу,
      и покинул пост во время чрезвычайной ситуации.</p>

    <p>— Нет, Максим, дело не в том, что
      ты не соизволил поведать Ларию хоть что-то и бросил
      трубку, точь-в-точь как делал Габриэль Евгеньевич в лучшие годы.</p>

    <p>— Нет, дело не в том, что, заподозрив
      неладное, ты не обратился ни к кому за помощью и рванул на Пинегу один, не заморачиваясь какой-то там официальной встречей гэбен.</p>

    <p>— Нет, дело не в том, что, снова
      оказавшись в Бедрограде, ты малодушно отсиживался у
      нас, не известив кафедру о своём триумфальном возвращении.</p>

    <p>— Это всё ерунда, Максим.</p>

    <p>— Максим, не парься, с каждым
      могло случиться.</p>

    <p>Охрович и Краснокаменный говорили
      всё медленнее и медленнее, а потом совсем затухли, замолчали и просидели ещё
      немного в тишине, шаря глазами по полу. Тишину иногда разрывали особо бурные
      выкрики соседского радио где-то за стеной. Кажется, пускают отрывки репортажей
      десятилетней давности. С Первого Большого Переворота,
      глубоко идеологического события всероссийского масштаба.</p>

    <p>В понедельник юбилей, сегодня
      пятница.</p>

    <p>Бедроградская гэбня, наверное, не
      слишком довольна, что такое затратное по силам и
      деньгам мероприятие совпало по времени с чумой.</p>

    <p>Максим не хотел просидеть до
      самого юбилея в квадратной комнате с квадратным окном, из которого со стула
      видно только квадраты мозаики.</p>

    <p>— За тобой, в общем-то, всего
      одна настоящая лажа.</p>

    <p>— Но после неё тебя ни к каким
      делам уже и близко не подпустишь, — нехотя вновь заговорили Охрович и
      Краснокаменный, но на Максима глаз больше не подняли.</p>

    <p>— Ты пролюбил
      чуму у Габриэля Евгеньевича, которая всем нам нынче очень насолила.</p>

    <p>— Вернее, не пролюбил,
      а прострадал. Оскорбился его невниманием к твоим
      проблемам.</p>

    <p>— Таким неожиданным невниманием
      со стороны человека <i>с сотрясением мозга.</i></p>

    <p>— И бросил человека <i>с сотрясением мозга</i> одного в квартире.</p>

    <p>— Знаешь, Максим, никто не лезет
      в вашу долбаную личную жизнь, но это уже перебор.</p>

    <p>— Ты из-за личной жизни наломал дров что в этой самой личной жизни, что на службе,
      что во
      всём городе.</p>

    <p>— В городе экономика не
      сегодня-завтра куку. Порт закрыл доки, обнаружив чумного Габриэля Евгеньевича в
      своих недрах.</p>

    <p>— Совсем закрыл, Максим. У нас
      блокада. Скоро настанет разруха, голод и каннибализм на улицах.</p>

    <p>— Каннибализм — дополнительный источник
      распространения инфекции, об этом ты подумал?</p>

    <p>— Любой, кто захочет жрать, — в опасности. А раньше речь шла только о тех, кто
      захочет пить.</p>

    <p>— И всё это только потому, что
      самолюбие тебе дороже даже Габриэля Евгеньевича.</p>

    <p>— Что бы ты там себе ни нафантазировал
      за последние столь драматичные сутки.</p>

    <p>Максим не хотел это слушать, хотел как угодно прервать Охровича и Краснокаменного,
      но они
      только ещё раз свистнули хлыстами. Вот теперь Максим в полной мере ощутил, как
      это может быть больно.</p>

    <p>— Заткнись, все давно всё поняли.</p>

    <p>— Нечего тут понимать.</p>

    <p>— Ах, в Университете что-то
      решают без тебя.</p>

    <p>— Ах, в Университете кого-то
      слушают кроме тебя.</p>

    <p>— Ах, Габриэль Евгеньевич ебался
      с кем-то кроме тебя.</p>

    <p>— Ах, Габриэль Евгеньевич
      вздыхает о ком-то кроме тебя.</p>

    <p>— НО ЭТО НЕ ПОВОД ЗАГОНЯТЬ ВСЕХ К
      СКОПНИКАМ В БОЛОТО! — хором выкрикнули Охрович и Краснокаменный, но вышло
      как-то блёкло.</p>

    <p>Максим сам закричал в ответ:</p>

    <p>— Мне и в
      голову не приходило, что его могут заразить! Это же значит заразить меня, а это
      значит…</p>

    <p>— Колошма, —
      перебили его Охрович и Краснокаменный.</p>

    <p>— Для всей
      Бедроградской гэбни.</p>

    <p>— Ты уже
      говорил, мы запомнили.</p>

    <p>— И гэбенные
      служебные инструкции мы тоже давно запомнили, спасибо.</p>

    <p>— А ты не
      запомнил, что контролируемое заражение способно распространяться и
      бесконтрольно.</p>

    <p>— Мы уже знаем, что это не так,
      но с точки зрения здравого смысла — Габриэль Евгеньевич мог бы подцепить чуму
      случайно.</p>

    <p>— Водяную
      чуму тоже отправят в наручниках на Колошму, если она опосредованно покусится на
      голову гэбни?</p>

    <p>— Скажи это
      ей в лицо, если придумаешь, как с оным лицом связаться.</p>

    <p>Охрович и
      Краснокаменный буднично встали, подхватили хлысты, пробирку с взятой на анализ
      кровью, объёмный тюк и явно вознамерились выйти уже из комнаты.</p>

    <p>— Максим,
      хочешь немного древней мудрости даром?</p>

    <p>— Не всё в
      этом мире крутится вокруг тебя одного.</p>

    <p>— Если
      Габриэль Евгеньевич в трудную минуту вдруг игнорирует тебя, это ещё не значит,
      что дело в том, какой ты и что сейчас с тобой происходит.</p>

    <p>— Иногда дело всего лишь в том,
      какой <i>он</i> и что сейчас происходит <i>с
        ним</i>. Чума, например.</p>

    <p>Максим не
      собирался просить Охровича и Краснокаменного остаться, отвязать его, отпустить,
      дать увидеть Габриэля — было и так ясно, что уговоры бесполезны. Но он всё же
      задержал их на минуту:</p>

    <p>— Последний
      вопрос! — вылетело у Максима. — Почему вы не сделали этого сразу? Не связали
      меня, не избили, а выслушали мой рассказ про Пинегу и
      даже создали иллюзию поддержки?</p>

    <p>Охрович и
      Краснокаменный не обернулись.</p>

    <p>— Он опять
      говорит о себе.</p>

    <p>— Он
      неисправим.</p>

    <p>— До него ещё
      не дошло, что он больше не голова гэбни.</p>

    <p>— Наверное,
      мы не очень внятно это сказали.</p>

    <p>— Говорим
      внятно: Молевич Максим Аркадьевич с сегодняшнего дня не является головой гэбни
      БГУ имени Набедренных.</p>

    <p>— По причине
      увольнения из преподавательского состава по собственному желанию.</p>

    <p>— Число,
      подпись Молевича Максима Аркадьевича.</p>

    <p>— Теперь
      Максим Аркадьевич может быть подвержен любым издевательствам со стороны лиц
      более высокого уровня доступа без объяснения причин.</p>

    <p>— В том числе
      издевательствам морально-этического характера.</p>

    <p>— Максим,
      можешь считать, что это был допрос. В такой вот нетривиальной форме.</p>

    <p>— Мы же должны были
      как можно подробнее выяснить, где и почему ты шлялся <i>на самом деле.</i></p>

    <p>— Вот мы и
      воспользовались твоим доверием. Грязно воспользовались.</p>

    <p>— А то,
      знаешь ли, были интересные версии.</p>

    <p>— Но допрос
      проведен успешно и интересные версии отброшены за
      ненадобностью.</p>

    <p>— Радуйся,
      Максим.</p>

    <p>— Серьёзно: <i>радуйся.</i></p>

    <p>Дверь за Охровичем и
      Краснокаменным захлопнулась, но квартиру они не покинули — по крайней мере,
      пока. Занялись своими делами. Можно попробовать докричаться до них, можно
      пошуметь, опрокинуть вместе с собой стул — но вряд ли они изменят своё решение.
      Пытаться же высвободиться самостоятельно не имеет смысла, Охрович и Краснокаменный
      вяжут узлы на совесть.</p>

    <p>Это тривиальные пытки, подумал
      Максим, тривиальные пытки после нетривиального допроса.</p>
  </>
}

export default chapter23
