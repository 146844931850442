import React from 'react'
import { IBookPage } from './index'

const chapter03: IBookPage = {
  header: 'header_d2.jpg',
  title: 'Глава 3. Опять думать',
  prevLink: 'chapter02',
  nextLink: 'chapter04',
  content: <>
    <h2>Бедроградская гэбня. Гошкá</h2>
    <img alt='' src="/images/03_goshka.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>Думать вредно.</p>

    <p>Делать не думая — глупо.
      Правильно — делать и думать одновременно или перемежать одно другим. Понимание
      того, в какой фазе ты в данный момент находишься, приходит с опытом.</p>

    <p>Когда пропадает один из голов
      твоей гэбни — человек, который каждый херов раз трезвонит из Столицы и сообщает
      всё нужное и ненужное о своих дальнейших перемещениях, надо прыгать в поезд и
      ехать. Десяти часов дороги от Бедрограда с лихвой хватит на то, чтобы
      разобраться, куда и чего; обойти в Столице своих людей, созвониться с двумя
      оставшимися головами гэбни —&nbsp;это всё рефлекторно, не занимает мысли.</p>

    <p>Думать вредно, но ничего другого
      Гошке сейчас не оставалось.</p>

    <p>Надо дождаться, пока в квартире
      Шапки погаснет свет.</p>

    <p>Андрей уехал в Столицу прошлой
      ночью и исчез. Ездить в Столицу любому голове Бедроградской гэбни опасно
      —&nbsp;как и ездить по Бедрограду, и садиться в непроверенное такси, и ложиться
      спать; издержки профессии. Это все понимают, но это не повод не среагировать на
      тревожный звоночек.</p>

    <p>То есть его отсутствие.</p>

    <p>Шапка —&nbsp;тавр, и это вдвойне
      опасно. Не потому, что все тавры — чокнутые фанатики (поскольку не все, а лишь
      подавляющее их большинство); не потому, что учёный-вирусолог Шапка проходил
      национальные боевые тренировки в селе своей юности, славном Зажопейске; не
      потому даже, что ему могла коса в голову ударить и сподвигнуть на героический
      подвиг во имя добра и справедливости. Это всё было ясно с самого начала — как и
      то, что Андрей занимается в Медицинском Корпусе глубоко незаконными делами.</p>

    <p>Шапка —&nbsp;тавр, и это значит,
      что на него удобно повесить любую политическую акцию. Мотивации тавра никому не
      надо объяснять, он же чокнутый фанатик с национальными боевыми тренировками за
      косой. А политическая акция в адрес Бедроградской гэбни имеет в Столице все
      шансы случиться —&nbsp;помнят ещё политические акции в адрес гэбни Столичной.</p>

    <p>Шапка — тавр, и, сука, не желает
      ложиться спать.</p>

    <p>Ещё десять минут —&nbsp;и упустит
      свою возможность решить вопрос полюбовно.</p>

    <p>Пусть кто-нибудь потом попробует
      обвинить Гошку в том, что он не пытается искать безболезненных путей.</p>

    <p>Лучи заходящего солнца (ути-пути,
      поэзия, красный столичный сентябрь) неистово отсвечивали в глаза, но лампа в
      таврском окне продолжала гореть. Значит, придётся пока ещё немного подумать.
      Так вот: все вот эти, которые так любят хвалиться своим интеллектуальным
      багажом и ставить его на вершину херова человеческого бытия, могли бы заняться
      чем-нибудь полезным и изобрести контактные линзы, которые нивелировали бы
      эффекты косых солнечных лучей. Не носить же Гошке поверх них ещё и тёмные очки,
      а.</p>

    <p>Рабочий инструмент должен быть
      эффективным и многофункциональным.</p>

    <p>И чтобы эти волшебные линзы
      учитывали, что зритель может располагаться на крыше дома напротив, где угол
      падения этих самых лучей ебёт угол отражения так, что глаза вываливаются у всех
      присутствующих.</p>

    <p>На конспиративную квартиру в
      каждом столичном доме ресурсов не наберёшься, а кафешку на первом этаже успела
      занять другая слежка, которой Гошка вовсе не собирался попадаться.
      Соответственно, следить за Шапкиным жилищем (которому осталось испытательных
      две минуты) приходилось с крыши.</p>

    <p>Соций залёг бы на крыше
      профессиональнее, но он не стал бы пытаться решить вопрос полюбовно, вломился
      бы и надавал по зубам. Поэтому и не поехал, хотя хотел. Дробление челюстных
      суставов в любом случае предвидится, но есть некоторая вероятность того, что с
      Шапкой можно ещё и поговорить. Бедроградской гэбне всего-то и надо
      —&nbsp;узнать, куда он дел Андрея.</p>

    <p>Бáхта ехать даже не
      порывался: тавр без косы для своего малого, но такого гордого народа —
      отщепенец, сиди он хоть в гэбне, хоть в Бюро Патентов, хоть на вершине
      Вилонского Хуя; а вот с Социем пришлось что только не тянуть жребий.</p>

    <p>Победили оптимизм и вера в
      полюбовность.</p>

    <p>Вот и хорошо: у Соция с таврами
      проблем нет, он на другом малом народе зациклен — семь лет в армии даром не
      проходят, челюсть только так скрипеть начинает, но не на тавров, не на тавров.
      Да только всё равно — семь лет в армии, вся херня — Гошка полюбовность Социю не
      доверит, когда альтернатива есть.</p>

    <p>Так, время вышло.</p>

    <p>Шапке крышка.</p>

    <p>И не одна. Не только кафешка
      напротив, но и лавочка по диагонали, и уж наверняка какая-нибудь квартира
      —&nbsp;все были засижены разнообразными людьми, изредка косящимися в сторону Шапкиных
      окон. Этих людей кто-то туда рассадил, а значит, кого-то в наши дни очень
      интересует некий конкретный таврский вопрос. Судя по количеству и профессионализму
      наблюдателей, Гошка для Шапки — это ещё лёгкая и безболезненная смерть.</p>

    <p>Ещё поблагодарить должен.</p>

    <p>Гошка легко проскочил в слуховое
      окно, в косом красном свете проверил обойму, одёрнул куртку и аккуратно открыл
      чердачный люк.</p>

    <p>Как ни смешно, переходы с
      чердаков и на чердаки —&nbsp;чуть ли не самое сложное во всей операции. Лесенка
      стукнет о стенку, какой-нибудь чрезмерно добропорядочный гражданин потом
      стукнет Столичной гэбне, что по крышам бродят подозрительные элементы, — и о
      тебе вспомнят. Ничего не докажут, конечно, и уж тем более не опознают, но
      вспомнят, а потом случится какая-нибудь ещё политическая акция.</p>

    <p>Которой легко избежать
      аккуратными движениями конечностей и мягкими ботинками.</p>

    <p>Удача нынче была не совсем на
      стороне Бедроградской гэбни. Перед тем, как войти в подъезд Шапкиного дома,
      надо выйти из подъезда дома, на крыше которого Гошка залегал, —&nbsp;а он тоже
      просматривается из кафешки. Впрочем, специально следить не станут, так что
      нужно всего лишь не быть заметным.</p>

    <p>Что не так уж и просто. Например,
      под курткой у Гошки форменная рубашка младшего служащего — для Шапки и вообще
      по привычке. Рубашка заметна, как и тёмно-голубая курточная подкладка —&nbsp;но
      ещё заметнее свалявшийся дядька под сорок, который в такую теплынь выскочил за
      пачкой сигарет и возможной поллитрой застёгнутым на все пуговицы. Так что
      застёгиваться нельзя, только ещё раз правильно одёрнуть, сделать себе походку
      вразвалочку и неспешно двигать из подъезда — квартала эдак полтора, пока не
      подвернётся хороший закоулок.</p>

    <p>Столичная застройка в этом смысле
      куда хуже бедроградской. Делалась раньше, планомернее и вся просто сверкает
      чудесами архитектурной мысли — что ни улица, то проспект. Всё широкое, светлое,
      просматривается и продувается почти летним ветерком со всех сторон.</p>

    <p>Короче, Гошке пришлось-таки
      заскочить в очередной произвольный подъезд подальше от дома Шапки и верить, что
      всем по барабану.</p>

    <p>Тёмно-голубая подкладка
      превращается в тёмно-голубую куртку нехитрым методом выворачивания наизнанку.
      Вопрос —&nbsp;кто носит голубые куртки? Вестимо, поэты и прочие интеллектуально
      отягощённые личности.</p>

    <p>Пригладить волосы, сделать умное
      лицо и вперёд.</p>

    <p>Самое лучшее — что об этом тоже
      думать не нужно. Как естественно прикрыть кобуру не самой длинной курткой,
      помолодеть лет на десять только за счёт осанки, быть заметным и притом полностью
      выпасть из памяти — это всё не мысли. Это движения.</p>

    <p>Шапка жил на последнем этаже. Без
      приключений войдя в соседний подъезд, вскрыв чердак, пробравшись по нему до
      искомой локации и спустившись на Шапкину лестничную клетку, Гошка обнаружил,
      что в вопросе удачливости, кажется, ещё не всё потеряно. Вместо дверного звонка
      возле соседней квартиры —&nbsp;той, что выходила окнами не к кафешке — поблёскивала
      круглая эмблема Распределительной Службы.</p>

    <p>Не заселена.</p>

    <p>Где ещё можно мирно и полюбовно
      побеседовать вечерком, как не в мирной, полюбовной, пустой и не просматриваемой
      квартире!</p>

    <p>Это добавляло телодвижений, но
      избавляло от хлопот. Гошка весьма оперативно вылез обратно на крышу, перескочил
      её конёк, спрыгнул на балкон приветливо безлюдного помещения, выдавил стекло и
      через пару минут вышел на всё ту же лестничную клетку — уже изнутри, как
      простой столичный парень. Новый сосед, сегодня въехал, здравствуйте, я за
      сахаром —</p>

    <p>И кому это на хер упало,
      пистолет-то —&nbsp;вот он!</p>

    <p>Имело смысл провести
      рекогносцировку. Аккуратная площадка, очень жилая, пепельница у окна и какая-то
      туя в кадке. За дверьми —&nbsp;никакого ненужного шевеления: благопристойные
      добропорядочные граждане попивают чаи или слушают по радио о грядущем юбилее
      Первого Большого (в квартире по диагонали, и громко), в сводке можно потом с
      чистой совестью писать, что ничто не предвещало.</p>

    <p>Гошка извлёк родной табельный
      пистолет из кобуры, выдохнул и позвонил.</p>

    <p>Шапка распахнул дверь жестом
      человека, который не ожидает проблем, — кажется, даже в глазок не глянул.
      Заходи, народ, забирай, что хошь. Если <i>такой</i> тип что-то сотворил с Андреем, имеет смысл пересмотреть вопрос лёгкой и
      безболезненной смерти.</p>

    <p>— Дружественно советую быть
      очень, очень тихим, — поприветствовал Гошка тавра дулом в его широкоформатные
      рёбра. С такой комплекцией никаких дополнительных средств защиты от
      огнестрельных ранений и не нужно, какая экономия.</p>

    <p>Зато жрёт наверняка много.</p>

    <p>Покорный Шапка смерил Гошку
      хмурым взглядом. Особенно его макушку.</p>

    <p>— Сейчас ты спокойно выходишь из
      квартиры, прикрываешь дверь, перемещаешься вон туда, — Гошка кивнул в
      направлении приветливо безлюдного помещения, — и мы беседуем.</p>

    <p>И чем дальше от десятка
      микрофонов, которыми наверняка напичкан один только национальный таврский
      коридор, тем лучше.</p>

    <p>Шапка посмотрел на пистолет с
      глубокой думой на косоглазом лице, кивнул и выдвинулся в своё нелёгкое
      путешествие, позвякивая бубенчиками на левой руке. Учёный-вирусолог, кандидат
      наук — ростом в полтора нормальных человека, объёмом в два с половиной и с
      бубенчатым браслетом. Диссертацию небось силовыми методами защищал.</p>

    <p>И беседовать предпочёл силовыми
      методами. Дверь приветливо безлюдного ещё не успела толком закрыться, а Гошка
      уже капитально влетел в неё спиной под чутким руководством громадной таврской
      руки. Спасибо хоть не на лестнице.</p>

    <p>Многие полагают, что уязвимое
      место тавра —&nbsp;его коса, реликвия, фетиш и объект масштабного национального
      онанизма. В общем, и правильно полагают. Ошибаются те, кто думают, что въебать
      тавру — плёвое дело, достаточно только подобраться к уязвимому месту.</p>

    <p>Хер ты к нему подберёшься.</p>

    <p>Примерно с того момента, как у
      этих ублюдков начинают расти волосы, то есть с двух-трёхнедельного возраста, их
      начинают обучать Защищать Косу. И, суки, если что-то они умеют, так это
      Защищать Косу. Коса болтается на груди (или, в случае социальной успешности,
      брюхе) справа. И там, где жалкие дилетанты потянули бы лапки к священному
      волосяному отростку, Гошка просто сделал левой ладонью неопределённое движение,
      которое можно было расценить как угрозу, и хорошенько врезал Шапке рукоятью в
      регион левой почки.</p>

    <p>Вот поэтому настоящие тавры носят
      левосторонние доспехи.</p>

    <p>Тавр-вирусолог — это, в общем-то,
      и не тавр, куда ему.</p>

    <p>Как славно, что в Бедроградской
      гэбне тоже есть тавр, пусть и без косы, и что Гошка не поленился прослушать у
      него курс полубиографических лекций про историю и культуру малых народов Южной
      Равнины.</p>

    <p>— Беседа будет проходить на том
      языке, который ты сам выберешь, — сообщил Гошка, приставляя Шапке дуло к
      подбородку.</p>

    <p>— Коноедова вша! —&nbsp;рыкнул
      тот, утихомириваясь. </p>

    <p>— Хуй лохматый, — высказала свою
      политическую позицию Бедроградская гэбня, схватила тавра за основание косы и
      развернула. Это было тактически бессмысленно, но как тут удержишься.</p>

    <p>— Р-р-руки!</p>

    <p>— Ноги! Тебе предлагали вести
      себя по-человечески, нет? Сделал выбор —&nbsp;получай последствия, —&nbsp;Гошка
      любезно пнул тавра в направлении любовно зачищенного от нежилого полиэтилена
      стула. — Двигай давай, это твой путь к исправлению.</p>

    <p>Сопротивление было подавлено, и
      вскоре Шапка слился с предметом мебели в вечном экстазе не без помощи не менее
      любовно заготовленной верёвки. Пока Гошка пыхтел над дополнительными узлами,
      тавр своевременно решил поинтересоваться, чего же от него, собственно, хотят.</p>

    <p>— Могу поиграть в лицо высокого
      уровня доступа и предложить самому догадаться, — выдохнул Гошка, распрямляя
      натруженную спину, — но вообще-то ответа на вопрос. Где Андрей?</p>

    <p>— Не самое редкое росское имя, —
      невозмутимо ответил пленник.</p>

    <p>Не самая неожиданная реакция
      —&nbsp;скинуть ему косу с плеча за спину, чтоб хорошенько перекосило.
      Активирует сообразительность и остроту ума.</p>

    <p>Шапка зафырчал, как здоровенный
      конь-осеменитель, оскорблённый в лучших чувствах.</p>

    <p>— Ты при оружии, я связан. Ест’
      ещё причины тебе отвечат’?</p>

    <p>Это было сказано с подозрением (в
      принципе, почти оправданным), но беззлобно. Повод припомнить, почему в Столицу
      поехал Гошка, а не Соций, придвинуть себе ещё один стул — на сей раз в
      полиэтилене — сесть, закинуть ногу на ногу, закурить и представиться:</p>

    <p>— Младший служащий Скворцов.
      Запрос Бедроградской гэбни.</p>

    <p>Шапка крутил головой с явным
      неудовольствием.</p>

    <p>— Стоит спрашиват’ яснее.</p>

    <p>— Куда уж яснее? Андрей
      Витальевич Ильянов поехал в Столицу, ты последний, с кем его видели. Где он?</p>

    <p>Кручение прекратилось, началась
      небыстрая обработка запроса. Шапка молча упялился на карман форменной рубашки —
      то есть на пуговицу с государственной эмблемой — и принялся активно
      недоумевать.</p>

    <p>Эдак можно всю ночь просидеть.</p>

    <p>— Как ещё тебе уточнить запрос,
      чтобы произошла коммуникация?</p>

    <p>— Я говорил с Андреем Ил’яновым
      вчера до обеда. С чего его так быстро искат’? — медленно и подозрительно
      спросил Шапка у кармана. Медленно, как говорят люди, которые знают ответ на
      вопрос.</p>

    <p>— Должен был вернуться в
      Бедроградский Мединститут сегодня утром. Срочная работа. Не вернулся.</p>

    <p>Это примерно то, что может знать
      младший служащий Скворцов о некоем Андрее Ильянове. О привычке Андрея
      отзваниваться и о том, что сейчас творится в Бедрограде, он знать не может, а
      лучшей легенды по дороге в Столицу не сочинилось.</p>

    <p>И так в последнее время пришлось
      слишком много думать — например, разбираться, нет ли у университетского Ройша
      второго уровня доступа, который мог бы попортить всю клиническую картину
      расстановки сил. Главное своевременно задаться вопросом! Сперва перестроить
      бедроградские канализации, озадачиться поиском вируса, разобраться, кто там
      где, и только потом сообразить, что ой, у Ройша-то может быть право вето на
      весь Бедроград.</p>

    <p>Впрочем, кто в здравом уме может
      принять в расчёт второй уровень доступа? Второй уровень — это Хикеракли,
      Набедренных и прочие благополучно покойные Скворцовы-Скопцовы. Члены
      Революционного Комитета, коих более нет в живых и коим, положим, нужна была некая
      привилегированная позиция для доступа к информации, которой они, вероятно,
      знали примерно до хера.</p>

    <p>Члены Революционного Комитета и
      почему-то их потомки. Этот аспект служебных инструкций касательно уровней
      доступа к информации вызывал у Гошки каление особого белого цвета ещё со
      второго курса. Ибо какого лешего? Что за пережитки древнеимперского наследного
      права, откуда в прогрессивном и даже здравом Всероссийском Соседстве это
      европейское гнильё?</p>

    <p>Зачем это надо — хер проссышь,
      что они могут — тем паче. Вероятно, почти всё, Хикеракли вон целую гэбню
      шестого уровня доступа сотворил по своему хотению. К счастью, ни одного
      успешного прецедента обретения свежими лицами второго уровня доступа вроде как
      не зафиксировано. Гошка смирился с «потомками» в инструкциях, потому что
      формулировка была исторической, то бишь не имеющей никакого отношения к
      реальной жизни и нужной только в качестве реликвии.</p>

    <p>А потом вспоминаешь, что где-то в
      Бедрограде бродит весь такой из себя Ройш, имеющий полное бюрократическое право
      вдохнуть в реликвию новую, доселе невиданную жизнь.</p>

    <p>Выяснилось, что правом этим Ройш
      не воспользовался. Непонятно, почему, но вторым уровнем доступа он не обладает.
      Вроде как слишком гордый для таких мелочей.</p>

    <p>Только все сегодня гордые, а
      завтра перенабрали Бедроградскую гэбню. Когда Ройш одумается, Гошка знать не
      мог, зато смог прикинуть, сколько недель занимает получение второго уровня при
      благоприятной погоде.</p>

    <p>И, соответственно, сколько недель
      есть у Бедроградской гэбни на решительные действия эпидемологически-чумного
      характера при наихудшем раскладе. Вдруг Ройш рванул за своими привилегиями как
      раз вчера, пока Гошка с его девкой алкоголь распивал? Ему вроде бы и незачем,
      но ведь мог. Обнаружил, например, что в его сумке кто-то копался.</p>

    <p>Потому Гошка и отдал девке пробирку.
      Нехер тянуть, когда есть вариант не тянуть.</p>

    <p>Если б не это (ох заразна ты,
      болезнь паранойя), может, они бы отыскали на роль сортира судьбы кандидатуру
      получше ройшевой девки. Какого-нибудь в меру склочного и талантливого
      студента-медика, например.</p>

    <p>Только план нельзя полировать
      вечно, рано или поздно он это замечает и начинает полировать тебя. Вот Андрей,
      к примеру, пропал, и теперь, чтобы ничего не развалилось, надо его поскорее
      отыскать.</p>

    <p>Сложности, сложности.</p>

    <p>Потому что за просто так в этом
      мире ничего не даётся.</p>

    <p>— Говоришь, младший служащий? — тавр
      отвечал всё медленнее и медленнее, кажется, решая какую-то сложную таврскую
      морально-этическую дилемму. — Тебе хот’ рассказали, кого ты на самом деле
      ищешь?</p>

    <p>Это интересный вопрос. Гошка
      вполне представлял, кого он ищет, и несколько хуже — кого предлагает Шапка.</p>

    <p>Андрей был тепличным цветочком
      всея Бедроградской гэбни. Когда человек идёт на госслужбу сразу после отряда, в
      шестнадцать лет, он получает головокружительную карьеру и взамен теряет
      некоторые бытовые мелочи. Например, представление о том, что такое люди. Или
      что некоторым людям нужно доверять — требование к вакансии такое. Это
      неудивительно с учётом того, что единственный опыт Андрея в гэбне до
      Бедроградской закончился развалом Колошмы, и никто не возмущался, просто на
      синхронизацию с ним ушёл добрый год.</p>

    <p>Удивительно, когда человек
      настолько обучаем, готов слушать, соглашаться и принимать твои условия игры
      —&nbsp;и при этом настолько закрыт и, леший дери, испуган. Поди достучись и не
      сорвись в выламывание дверей.</p>

    <p>Не сорвались. Гэбня может хорошо
      работать только тогда, когда полное взаимодоверие не означает полного
      взаимоконтроля. Каждый имеет право на свои личные дела, предпочтения и
      специализации, просто ответственность за них несут все вместе.</p>

    <p>Никаких допросов внутри гэбни,
      никакой всей подноготной. Это и есть херово доверие. Без него ничего не будет.
      Если один пошёл и что-то сделал — это победа всей гэбни безотносительно личных
      заслуг. Если сделанное развалилось нахер — это проблема всей гэбни, и отвечает
      за неё вся гэбня. Приходит и исправляет, без укоров и поиска виноватых.</p>

    <p>И да, когда Андрей это наконец-то
      понял и принял, лично у Гошки случился херов катарсис.</p>

    <p>А когда выяснилось, что у Андрея
      хватает связей в Медкорпусе —&nbsp;что его чуть ли не в Медицинскую гэбню
      растили, но не сложилось, — естественным образом все соответствующие вопросы
      легли на него. И ни Гошке, ни Социю, ни Бахте не приходило в голову выяснять
      детали — как и Андрею не пришло бы в голову врать или умалчивать. Знали, под
      каким именем ездит, знали, к кому, знали последние сводки новостей относительно
      вируса. Цвет Андреевых ботинок и методы обольщения регистратуры — знания уже
      лишние, они никого не ебут.</p>

    <p>На то и четыре головы у гэбни,
      чтобы они делили работу на четверых и не грузили остальных своей частью.</p>

    <p>Так что вопрос Шапки был
      действительно интересным — в чисто академическом смысле. Даже любопытно, что
      знают об Андрее его контакты.</p>

    <p>— Кого ищу? — Гошка как бы
      покопался в памяти. —&nbsp;Ильянова Андрея Витальевича, сотрудника
      Бедроградского Института Медицинских Исследований. Молод, рост чуть ниже
      среднего, волосы светло-русые, усы и борода. Фотокарточка имеется. Чем именно
      он в своём институте занимается, мне знать не положено. Я исполнитель.</p>

    <p>Шапка посмотрел исподлобья.</p>

    <p>— В заднице ты, исполнител’.</p>

    <p>А уж ты-то в какой,
      тавр-вирусолог!</p>

    <p>— Поясни.</p>

    <p>— Не Ил’янов он и не из
      Института.</p>

    <p>Значит, Андрей всё-таки не
      предельно параноидален. Шапка, конечно, не только физически внушителен
      —&nbsp;как-никак, именно он сделал вирус, —&nbsp;но для Андрея это могло бы и
      не быть поводом представляться настоящим именем.</p>

    <p>Мысль об отсутствии предельной
      параноидальности в Бедроградской гэбне грела.</p>

    <p>Гошка нахмурился и изобразил
      смятение чувств.</p>

    <p>— Да что ты говоришь? А кто же?</p>

    <p>— Голова Бедроградской гэбни. Не
      вру. Заказ, который я для него делал, рядовому институтскому и не снился, дорого.</p>

    <p>Для того и делал в столичном
      Медкорпусе, чтобы бедроградские медицинские учреждения спали крепко и спокойно.
      И не только потому, что дорого.</p>

    <p>Гошка помолчал, сокрушённо
      покачал головой и цыкнул зубом:</p>

    <p>— Слушай, Шапка, лично я против
      тебя вообще ничего не имею. Мне сказали, что тавр и имеет шансы оказать
      сопротивление —&nbsp;я с оружием. А так ты, может, и нормальный мужик, не знаю.
      Но что меня послали искать аж голову гэбни? Какого хера я должен в это
      поверить?</p>

    <p>— Какого хера я должен знат’, как
      власт’ работает? Видел один раз — не понравилос’. — Шапка тоже покачал головой.
      — Ты пришёл меня спрашиват’ про Андрея — я могу тебе рассказат’ про Андрея. Но
      кто он такой и чем занимается, тебе знат’ не положено, раз не сказали. Меня это
      должно волноват’?</p>

    <p>— Я исполнитель, но это не
      означает, что у меня нет мозгов. Выкладывай всё.</p>

    <p>— Сразу говорю: не знаю я, где
      Андрей. Можешь не верит’, доказыват’ нечем. Но не знаю. Вчера позвонил, сказал,
      что он в Столице и что надо говорит’. Приехал в Корпус, поговорил, дальше не знаю
      ничего.</p>

    <p>Кажется, кто-то из присутствующих
      не вполне откровенен.</p>

    <p>Какая жалость, что младший
      служащий Скворцов не может знать, что на деле это как раз Шапка послал Андрею
      телеграмму со срочным приглашением «поговорит’» —&nbsp;у него, мол, другой
      покупатель сыскался. Что напоминает о том, что стоило бы ему ещё разок врезать.</p>

    <p>Только вконец зажравшиеся у
      гэбенной кормушки столичные вирусологи способны помыслить о том, чтобы вдруг
      перепродаться куда-то ещё в ответственный момент.</p>

    <p>— И когда ты его в последний раз
      видел?</p>

    <p>— Перерыв в полпятого. Мы
      закончили говорит’ минут за сорок до него. Точнее не помню.</p>

    <p>И куда Андрей потом направился
      ты, конечно, знат’ не знаешь, таврское рыло.</p>

    <p>— О чём же говорили?</p>

    <p>— Ты не поймёшь.</p>

    <p>Как-то не складывается беседа. Но
      человека таких габаритов — да в придачу ещё и тавра, обитающего под уютным
      крылом Медицинской гэбни, запугивать бессмысленно, остаётся только быть
      понятливым и сдержанно дружелюбным. Шапка не очень похож на того, кто сам стал
      бы закапывать Андрея, у него для таких закидонов слишком хорошая жизнь. А вот
      загадочный другой покупатель легко мог возжелать избавиться от конкуренции.</p>

    <p>— Объясни на пальцах, — хмыкнул
      Гошка и тут же вспомнил о дополнительных узлах, держащих таврские руки, —
      фигурально выражаясь.</p>

    <p>— Много объяснят’, — упрямо
      буркнул Шапка. — Андрей сделал заказ, я заказ выполнил. Андрей приехал говорит’
      про другой заказ на основе этого.</p>

    <p>Вот-те новость.</p>

    <p>— Это как?</p>

    <p>— Было сырьё. Из сырья первый
      заказ. Андрей хотел узнат’, возьмус’ ли я за второй из того же сырья.</p>

    <p>У вируса было сырьё? Совсем новая
      новость. Гошка не углублялся в медицинские подробности и формулы — тем более
      что и не было формул, Шапка продал только образец, — но подобная информация не
      могла не прозвучать. Если вирус делался из чего-то, гэбне не помешало бы знать,
      из чего.</p>

    <p>Неясно, зачем, — медицинские
      вопросы-то всё равно Андрей решает — но не помешало бы.</p>

    <p>— Сырьё? Это типа кровь
      смертельно больных? — И почти не забыл, что содержание заказа, которого
      по-прежнему не знает младший служащий Скворцов, ещё не обсуждалось. — Ну или
      чего ты там для него мог делать, вирусолог.</p>

    <p>— Не кров’, — Шапка в который раз
      смерил Гошку тяжёлым взглядом, всем своим видом давая понять, что дела творятся
      крайне серьёзные, а младший служащий Скворцов конкретно попал. —&nbsp;Заказ
      секретный. Андрей отдельно проплатил молчание. Я думал, заказ сделала его
      Бедроградская гэбня. А раз тебя послали за ним, но ничего не сказали, может,
      это лично Андреевы дела, а они и сами не знают.</p>

    <p>Гошка задумчиво постучал пальцем
      по пистолету.</p>

    <p>— Они, может, и не знают, но
      ты-то знаешь. Значит, можешь рассказать.</p>

    <p>— Сложно. Андрей платил. Андрей
      гарантировал безопасност’. Я ему тепер’ не слишком верю, но мы с ним давно
      работаем. Сама Бедроградская гэбня мне ничего не платила и ничего не гарантировала.</p>

    <p>Бедроградская гэбня не платила?
      От расценок тавра, между прочим, городской бюджет покачнулся. Если верить
      Андрею, он, конечно, профессионал — за два месяца сляпал именно то, что было
      нужно. Но не охамел ли?</p>

    <p>— Что значит «не платила»? Сам же
      сказал, что Андрей — голова Бедроградской гэбни. Значит, от них и заказ был.</p>

    <p>— Не знаешь — не торопис’, —
      Шапка ещё покрутил головой, коса-то по-прежнему лежала не на месте. — Все дела
      со мной всегда вёл Андрей. Когда первый раз пришёл, никакой Бедроградской
      гэбней не представлялся. Представлялся потом, но я привык, что Андрей — Андрей,
      а для кого он заказы делал, обычно не уточнялос’. Если он голова гэбни, но свои
      дела делает без неё, я в эти проблемы влезат’ не хочу.</p>

    <p>Нет в этом никакой проблемы,
      просто разделение обязанностей. Тем, кто никогда не сидел в гэбне, не понять.</p>

    <p>— Так ему и незачем
      представляться. Или ты его в чём-то конкретном подозреваешь? — Гошке было всё
      любопытнее и любопытнее. — Когда он в первый раз к тебе обратился — что-то
      странное заказал?</p>

    <p>— В первый раз? Смешное. Не помню
      уже, чем объяснял, сем’ лет назад это было. Просил сыворотку для формирования
      аллергии. Срок выполнения заказа назначил совсем короткий, меньше недели. Я
      работаю быстро, за то и платят, но Андрей хотел аллергию на сав’юр. Сав’юр как
      аллерген неудобен, на него естественной аллергии почти не бывает, полезная
      трава. За месяц я бы справился, за несколько дней — никто бы не справился, —
      решил вдруг поотстаивать свою компетентность Шапка, — а Андрею нужно было за
      несколько дней. Я сказал, что не воз’мус’, Андрей сказал, что раз на сав’юр
      прямо сейчас никак, подойдёт аллергия и на другую траву — твир’. Мол, тоже
      наркотик, тоже в степи растёт. Сыворотку я ему сделал, но догадался, что он не
      медик и не эксперименты ставит, если ему сроки и степ’ важны, а не близост’ по
      составу, например. Спрашиват’ не стал. Зачем не-медику аллергия на степную
      траву? Ясно же, что не наркотическую зависимост’ лечит’. Наверняка пытат’
      кого-то.</p>

    <p>Вот они, таврские представления о
      смешном. И увлечениях всех, кто не входит в Медицинский корпус. Впрочем, тут
      возмущаться нечестно, история тогда вышла паршивенькая: сыворотка семилетней
      давности и правда была нужна для того, чтобы пытат’ несчастных жертв на
      обломках разваленной Колошмы. Более того, это и правда была инициатива лично
      Андрея, он поехал строго от своего имени, не ставя в известность, —&nbsp;ну и
      поплатился, чуть не попал под степную чуму. Испугался и больше так не делал.</p>

    <p>И с тех пор все хорошо живут.</p>

    <p>Пожалуй, Гошка слишком часто
      думал о том, как важно быть с другими головами гэбни единым целым. Пожалуй, он
      жалел всех тех, кому не довелось.</p>

    <p>Особенно тех, кому не довелось
      ебаться вчетвером с синхронизацией. По законам Всероссийского Соседства внутри
      гэбни — <i>нормальной</i> гэбни — ебаться
      можно только вчетвером.</p>

    <p>У этой страны отличные законы.</p>

    <p>— Ну ладно, вы давно работаете
      вместе. Захотел он представиться частным лицом сперва и захотел, кто их знает,
      может, это нормально. Даже не скрывал от тебя особо, что заказывает препараты
      для пыток и другой радости. — Гошка снова закурил; стекло балконной двери всё
      равно погибло, пытаться сохранить квартиру в первозданном виде смысла не было.
      — Что такого Андрей мог назаказывать сейчас, что ты вдруг уверовал в
      неосведомлённость Бедроградской гэбни?</p>

    <p>— Ест’ поводы. Гэбня знает,
      только если в ней сумасшедшие сидят.</p>

    <p>Так бы и оторвал ему косу к херам
      козлиным. Тут даже не клещами, тут производственными тисками весом в полторы
      тонны каждое слово вытягивать приходится.</p>

    <p>Многие ошибочно полагают, что,
      если человек имеет шестой уровень доступа, он имеет также и железное терпение.</p>

    <p>— Ты расскажешь, блядь, или нет,
      какой заказ был? Андрей платил, Андрей гарантировал, Андрей пропал! За тобой же
      следующим придут, слежку уже поставили. Излей душу, пойди Бедрограду на пользу
      хотя бы в последние часы жизни.</p>

    <p>— Слежку? Кто? — зашевелился
      Шапка.</p>

    <p>— А я знаю? Не Бедроград.</p>

    <p>Тавр засопел и задумчиво покусал
      удила.</p>

    <p>Шарль Дарвен ошибался. Гошка не
      был уверен относительно всего человечества, но тавры-то точно произошли от
      коней.</p>

    <p>— Я вед’ говорил Андрею, что
      утечёт информация про его заказ —&nbsp;порвут нас всех на британо-кассахский
      флаг, — Шапка ещё немного почмокал губами, пережёвывая, видимо, своё будущее
      повествование. —&nbsp;Значит так. Андрей хотел, чтобы я сделал вирус,
      принципиально новый, чтобы лекарство было только у него. Требования ставил
      такие: чтобы передавался через жидкости, и передавался легко, не разлагался под
      воздействием некоторых веществ — подробности тебе ни к чему, — чтобы первые
      симптомы развивающейся болезни были максимально незаметными, и чтобы в результате
      — летальный исход.</p>

    <p>И снова замолчал. Видать, столь
      длинные речи утомительны для представителей мира фауны. Но стоит нахмуриться:
      ах, как же так, смертельный вирус, младший служащий Скворцов впечатлён до
      корней своих сегодня каштановых волос.</p>

    <p>— Сделал?</p>

    <p>— Не сразу. Смертельных вирусов
      много, но устойчивых почти нет. Сложно сделат’ такой, который противостоял бы
      воздействию агрессивной среды. А список веществ, которым вирус должен
      противостоят’, Андрей дал большой и специфический.</p>

    <p>Ещё бы! Теперь в бедроградских
      канализационных фильтрах протекают какие-то очень сложные химические реакции, и
      вирус, ясное дело, должен их обходить, иначе как его через канализации
      распространять. Наверняка это была непростая научная задача.</p>

    <p>— К весне я вывел для Андрея один
      вирус, — продолжил Шапка, —&nbsp;который подходил по всем критериям. Но там
      была вероятност’ заражения воздушно-капельным. Небольшая, но была. Андрей
      заплатил.</p>

    <p>Ввиду чего один из бедроградских
      отрядов остался без капремонта ещё почти на месяц, да. Зато у Шапки теперь
      наверняка золотой сортир и платиновые гондоны, ай да тавр, ай да умница. Хорошо
      пристроился. Может, прибить его всё-таки под конец беседы?</p>

    <p>—&nbsp;Заплатил, но попросил
      работат’ дальше. Либо с этим вирусом, либо с чем-то ещё, но чтобы без заражения
      по воздуху. Я сказал, что и с этим вероятност’-то не самая серьёзная, Андрей
      сказал, что не должно быт’ <i>даже такой</i>.
      И стал объяснят’, зачем вирус нужен, — Шапка многозначительно помолчал — оваций
      ожидал, что ли. — Контролируемая эпидемия в пределах одного здания
      —&nbsp;говорил, что чут’ ли не самой Бедроградской гэбни. Учебная тревога для
      проверки готовности служащих. А если ест’ заражение по воздуху, может перекинут’ся
      на гражданских.</p>

    <p>И Шапка правда это сожрал? Хотя
      да, сожрал. У них, живущих под тёпленьким и сытненьким медицинским крылом, нет
      никаких представлений о том, что в этом мире сколько стоит и какие ресурсы на
      самом деле можно выделить на подобные учебные испытания.</p>

    <p>— Я в рассуждения пускат’ся не
      люблю, я работаю за деньги и интерес. Но тогда Андрею сразу сказал, что затея
      его —&nbsp;говно. Контролируемые заражения проводят. Список мер безопасности —
      длиной в три с лишним страницы. Там ест’ полная изоляция пространства и
      постоянная очистка воздушных потоков, даже если инфекция по воздуху вроде как
      не должна передават’ся. Можно такое в здании Бедроградской гэбни сделат’? Нет. Заражение
      через воздух — это вед’ тоже жидкости, недаром оно воздушно-<i>капельное</i>. Воздух принято исключат’,
      когда от контакта с ним происходит слишком быстрое разложение, но это не
      значит, что воздух вообще не участвует. Так что вероятност’ю больше, вероятност’ю
      меньше… — Шапка коротко пожал плечами. — А даже если представит’, что нет
      воздуха, лучше, что ли? «Только жидкости» проще контролироват’? Да конечно! Вот
      если б тебе, младший служащий, дали приказ полторы недели не трахат’ся, ты б
      что о своём начальстве подумал? То-то и оно. Я говорил Андрею: инфекция <i>всё равно</i> выйдет за пределы служебного
      здания. Андрей слушал, слушал, но сказал только, что за вирус с минимальной
      вероятност’ю воздушного заражения заплатит в два раза больше. И клал на
      безопасност’. Ёбнутый. Либо он сам, либо вся его гэбня.</p>

    <p>Андрей, которому класть на
      безопасность, — вот это хохма. Шапка же всё меньше и меньше нравился Гошке
      —&nbsp;как и все люди, у которых поперёк всей широкой таврской морды написано
      ощущение собственной абсолютной безнаказанности и владения ситуацией. Думает,
      что если на его мощные плечи можно взвалить мешок интеллектуального багажа, то
      можно и кочевряжиться при первом удачном случае. Бесконечно сосать деньги, отвечать
      на вопросы с эдакой ленцой, высказывать свои бесценные мнения там, где никто не
      спрашивал.</p>

    <p>Радел бы Шапка на самом деле за
      безопасность — продал бы обе формулы (вируса и лекарства, а не только вторую) и
      образцы в таких количествах, в каких надо, а не в каких попросили. А вот когда
      Бедроградскую гэбню ставят перед лицом необходимости самостоятельно преумножать
      количество, скажем, лекарства — безопасность и правда может пострадать.</p>

    <p>Так и кто в этом виноват-то, если
      не поклонник платиновых гондонов?</p>

    <p>— У Бедроградской гэбни есть
      ресурсы, —&nbsp;отрезал Гошка. — Наверняка они лучше тебя представляют, могут
      ли обеспечить безопасность своих проектов. И потом — лекарство ведь ты им тоже
      делал, правильно? А раз так — ну выберется инфекция за пределы одного здания,
      не умрёт же никто от этого. Вылечат.</p>

    <p>Шапка посмотрел на него с тем
      сочувствием, за которое особенно больно бьют.</p>

    <p>— Младший служащий, ты не понял.
      Инфекционное заболевание с летальным исходом, которое, к тому же, быстро
      передаётся и вышло из-под контроля, — это не только проблема «вылечат или не
      вылечат». Это проблема «узнает кто-нибуд’ или нет». Сколько я работаю — вся
      Инфекционная Част’ завалена подписками о неразглашении. Ты когда в последний
      раз слышал слово «эпидемия»?</p>

    <p>— Сегодня утром. Один из бедроградских
      отрядов закрыли на карантин из-за эпидемии ветрянки.</p>

    <p>— Вот именно, — кивнул Шапка. —
      Эпидемия — это отряды, детские болезни, ветрянка, кор’, крапивница. Несерьёзно,
      нечего боят’ся. А эпидемии смертельных заболеваний существуют только в
      учебниках по истории. Раньше экономика была хуже, государственная структура,
      медицина вообще ни к лешему — ни методов упреждения, ни анализа, ни лекарств. А
      во Всероссийском Соседстве всё иначе устроено, и серьёзных эпидемий не бывает и
      быт’ не может. Понимаешь? Их отсутствие — не просто достижение науки. Это
      символ того, как тепер’ в нашей стране хорошо жит’. — Шапка ещё немного
      почмокал губами: слова по-прежнему давались ему непросто. — Вот скажи, младший
      служащий, что такое чума?</p>

    <p>Чумой в народе называют всякую
      болезнь, от которой умирают.</p>

    <p>— Я всех ваших медицинских дрючек
      не знаю, — Гошка снова постучал пальцем по пистолету, мобилизуя запасы выдержки
      и терпения, —&nbsp;но обычно так говорят про любую смертельную болезнь.
      Кажется, есть настоящая чума, которая в степи, но это никого не ебёт.</p>

    <p>— Именно, любую смертельную
      болезн’. А теперь
      представ’ себе заголовок в газете: «В Бедрограде эпидемия чумы». Начнётся такая
      паника, что никакая Бедроградская гэбня не справится. И экономику накроет
      медным бубном.</p>

    <p>Платиновым гондоном, блядь.</p>

    <p>— Заголовки газет и паника — не
      твоего ума дело. И не моего.</p>

    <p>— Вот я тебе с самого начала и
      говорю, что не нам разбират’ся, куда пропал Андрей.</p>

    <p>— Справится Бедроградская гэбня.
      Паника, заголовки — это если кто-то умрёт. Но никто не умрёт, вылечат, — младший
      служащий Скворцов кашлянул и вспомнил о своём уровне доступа. —&nbsp;Я так
      думаю. Ты не ответил —&nbsp;лекарство ведь к вирусу прилагается?</p>

    <p>— Пятнадцат’ литров. Это столько,
      сколько Андрей просил — на одно здание с запасом. Если заражение выйдет из-под
      контроля, этого не хватит. Твоё начальство чем вообще думало? Угробят четверт’
      города с такой подготовкой. Коноеды.</p>

    <p>Завалил бы уже ебало, а? У тавров
      два объекта национального онанизма: косы и кони. Так что это Шапка, видимо, так
      ругается. Видимо, он считает Бедроградскую гэбню в чём-то неправой. Видимо, у
      него морально-этическое шило в жопе зашевелилось.</p>

    <p>Видимо, он совсем охуел.</p>

    <p>Заражение одного дома выглядит
      неестественно; для создания красивой клинической картины чума должна вспыхнуть
      последовательно в нескольких точках. Под чутким контролем Бедроградской гэбни,
      чтобы было безопасно. Но на такой проект даже самого безумно влюблённого в свою
      работу учёного не сподвигнешь, не говоря уж о чокнутых таврах. Так что заказано
      и вируса, и лекарства действительно мало — но если бы Шапка хоть немного
      шевелил мозгами за пределами своих формул, ему не составило бы труда
      догадаться, что и то, и другое можно воспроизвести.</p>

    <p>Хотя разве это весело! Весело —
      оскорблять, ни хера не зная.</p>

    <p>— Как будто лекарство без тебя
      нельзя размножить, — Гошка хмыкнул и язвительно прибавил, — если —&nbsp;<i>если</i> — ситуация выйдет из-под контроля. <i>Если</i> Бедроградской гэбне понадобится,
      она из твоих образцов и лекарства, и самого вируса наделает столько, сколько
      понадобится. А то ты прям думаешь, что за пределами Корпуса медицины нет.</p>

    <p>Шапка вздыбился.</p>

    <p>— Мы так не договаривалис’.
      Лекарства пуст’ сколько угодно делают, а вируса я Андрею ровно на одно здание
      продал. Без формулы. Контролируемое заражение служебного здания — это одно,
      можно, если что, и официально через Медицинскую гэбню оформит’, много
      смертельного вируса за пределами Медкорпуса —&nbsp;совсем другое. Я в этом
      участвоват’ не хочу. Вот и продал без формулы — вирус сложный, без неё
      синтезироват’ нельзя.</p>

    <p>Какой любезный Шапка, какой
      заботливый, оберегает глупую-глупую Бедроградскую гэбню, чтобы она не ввязалась
      ненароком во что-нибудь опасное! Видимо, полагается сделать реверанс и
      рассыпаться в благодарностях?</p>

    <p>Вместо этого можно вспомнить, что
      синтез — не единственный способ производства любого вируса, есть ещё
      батюшка-природа. Что нельзя собрать из деталей в пробирке, тому можно дать
      развиться естественным образом в организме какого-нибудь недостаточно рьяного
      младшего служащего. А потом ещё десяти. А потом загадочным образом на руках у
      Бедроградской гэбни обнаружится много-много крови с искомым вирусом — и всё без
      формул!</p>

    <p>Какая жалость, что любезный
      заботливый Шапка, привыкший к подпискам о неразглашении, запросам на разрешение
      и сложностям при выбивании права проводить эксперименты на людях, об этом не
      подумал. Впору разрыдаться.</p>

    <p>— Не договаривались так не
      договаривались. Не о вирусе речь, а о том, что <i>если бы</i> Бедроградской гэбне <i>зачем-нибудь</i> потребовалось больше лекарства — ну или вируса —&nbsp;чем ты дал, вряд ли это
      стало бы большой проблемой.</p>

    <p>— Тебе не понят’. Без формулы
      можно разве что… — начал было Шапка и осёкся, посмотрел на младшего служащего
      Скворцова озадаченно, как будто только что его увидел.</p>

    <p>Здравствуйте.</p>

    <p>— Не понять, не понять, — махнул
      рукой Гошка, —&nbsp;я сюда не за высокотеоретическими дискуссиями явился. Мы
      занимались тем, что ты рассказывал мне всё, что может поспособствовать поискам
      Андрея.</p>

    <p>Шапка молчал, визуально изучая
      рост, вес и прочие параметры собеседника. Да-да, Гошка в курсе, что он
      неотразим, но сейчас это, право, неуместно.</p>

    <p>— Ты чего-то опять не догоняешь,
      кажется, — он нагнулся вперёд и изобразил проникновенность. — Все ваши
      предыстории крайне трогательны, но не ебут ни меня, ни Бедроградскую гэбню. А
      ебёт нас один простой вопрос: куда делся Андрей. И пока что ты по-прежнему
      последний человек, который с ним разговаривал. А значит, главный подозреваемый,
      и тебе отвечать за его судьбу.</p>

    <p>— Ты неумный и впереди коня
      бежишь, — гулко отозвался Шапка. — Ещё раз объясняю: весной я сделал Андрею
      один вирус, он Андрею не подошёл. Потом я для Андрея не работал, пока в июне он
      не передал мне сырьё. Из этого сырья я сделал вирус, который подошёл, и
      пятнадцат’ литров лекарства к нему. Андрей расплатился. Вчера он приехал
      спрашиват’, могу ли я сделат’ из этого сырья ещё один вирус.</p>

    <p>— Вот заладил-то: сырьё, сырьё.
      Ну сырьё и сырьё, это важно, что ли?</p>

    <p>— Это <i>самое</i> важное, —&nbsp;Шапка кивнул каким-то своим мыслям. — Ты не
      медик, но ты хот’ знаешь, что степную чуму до сих пор не лечат?</p>

    <p>Степную —</p>

    <p>Кто-то тут чего-то опять не
      догоняет, кажется.</p>

    <p>— Да, — педагогическим тоном, по
      слогам ответил Гошка, — только речь ведь идёт не о ней.</p>

    <p>— Её потому не лечат, —
      невозмутимо продолжил Шапка, — что в Медкорпус ещё ни разу не попадал образец
      вируса степной чумы <i>не на поздних стадиях</i>.
      Он очень быстро развивается. Стадий около сотни. По поздним ничего не понятно
      даже с нашим уровнем медицины. Чтобы найти лекарство, нужен образец на ранней,
      а его нет. В 18-м году изолятор с больными сгорел случайно, пожарная
      безопасност’ подвела. Следующие две вспышки огнём гасили намеренно — слишком
      страшная болезн’, чтобы рисковат’. В последний раз хотели рискнут’, но вмешалис’
      фаланги. Так и нет нужных образцов.</p>

    <p>Если бы Гошка не был уверен в
      обратном, он предположил бы, что Шапку обучали поведению на допросе.
      Потрясающее количество бессмысленной информации, которой наверняка можно было
      бы запудрить мозги настоящему младшему служащему.</p>

    <p>Вот поэтому и приходится столько
      работы делать самому.</p>

    <p>— Невероятно ценная информация,
      —&nbsp;не удержался он, — очень полезная и имеет отношение к делу. Ты
      издеваешься?</p>

    <p>— Опят’ вперёд коня помчался.
      Сырьё, которое я получил от Андрея — это то, из чего в естественных условиях и
      берётся степная чума. Скорее всего. Так оно или нет, я разобрат’ся не успел, но и это не стол’ важно. Важно, что в Андреевом сырье — первые попавшие в руки медиков образцы вируса вне клеток. В неактивной фазе. Медицинская гэбня за такое хот’ вес’ Корпус
      продаст, если узнает. Из сырья я сделал родственный штамм для контролируемого
      заражения — проще, безопасней, но родственный. — Шапка поднял глаза. — Вчера он
      просил саму степную чуму.</p>

    <p>Гошка медленно затянулся: тавру
      не стоит видеть никакой чересчур бурной реакции.</p>

    <p>Каковы факты?</p>

    <p>Вирус, который они запустили в
      Бедрограде, родственен степной чуме.</p>

    <p>У Андрея откуда-то есть её
      образцы.</p>

    <p>Андрей возжелал её саму.</p>

    <p>И пропал.</p>

    <p>Это всё —&nbsp;если Шапка не
      врёт, разумеется.</p>

    <p>Степная чума — это и правда
      важно. Простой народ боится её, как лешего; медики за ней исступлённо гоняются
      вот уже сколько лет. Опасна она или нет — похер, а вот как на эти ключевые
      слова реагируют различные слои населения — имеет значение.</p>

    <p>Из-за конфликтов на высоких
      уровнях доступа Андрея когда-то не взяли в Медицинскую гэбню. Если бы он сейчас
      вернулся туда со степной чумой в кулаке, никто бы не вспомнил дурного.</p>

    <p>Но младший служащий Скворцов не
      может злиться по этому поводу. Младший служащий Скворцов недоумённо хмурится.</p>

    <p>— Саму степную чуму? И ты
      согласился?</p>

    <p>— Мы не договорилис’. Мне это,
      безусловно, было бы интересно. Но в одиночку занимат’ся степной чумой под носом
      у Медицинской гэбни — зват’ проблемы на свою голову. Узнают — сожрут. Это
      опаснее любого нелегального заказа, тут нужны серьёзные гарантии со стороны
      заказчика. А заказчику этому я больше не верю.</p>

    <p>— Почему? Потому что он когда-то
      давно представлялся частным лицом и заказывал пыточные инструменты? Это глупо.</p>

    <p>— Срат’ мне на пытки, — пробасил
      Шапка. — Тут другое дело: сырьё Андрей не сам привёз и уж не по почте отправил.
      С сырьём ко мне приехал его человек. Доставит’ и дальше помогат’ в работе. И
      вроде как следит’, чтобы я про сырьё не болтал. На контрол’ я не в обиде,
      опасения понятные, и ещё одни руки не лишние. Нормальный человек, нормально
      помогал и сам держал связ’ с Андреем. Когда вирус был готов, Андрей на связ’ не
      вышел, и его человек поехал докладыват’ в Бедроград к гэбне. А его там какие-то
      Андреевы младшие служащие развернули и припугнули. Он подробностями не делился,
      но зассал всерьёз: если к гэбне не пускают, значит, она не в курсе. А если
      Андрей свои дела от гэбни скрывает, убрат’ связующее звено после изготовления
      вируса — первое дело. Потом вроде всё спокойно было, но на той неделе человек
      Андрея исчез. Мог сам сбежат’, но что-то я тепер’ сомневаюс’.</p>

    <p>Шапке незачем врать. Если умный,
      понимает, что за враньё ему же и прилетит, и прилетит больно. На его месте
      лучшее — честно всё рассказать и устраниться из процесса, так его же самого
      меньше трогать будут.</p>

    <p>Что у Андрея свои люди есть
      —&nbsp;понятно. У него с Колошмы ещё идея-фикс, что часть младших служащих должна
      присягать на верность лично ему. Положим. Но иметь свой штат — одно, а скрывать
      его от родной гэбни и не пускать — это хамство.</p>

    <p>Опасное хамство.</p>

    <p>Если Андрей хочет бросить
      Бедроградскую гэбню и приподняться до Медицинской — это грустно и неприятно. Но
      если он делает это втайне, старыми добрыми обходными путями — Гошка первый же
      его и закопает.</p>

    <p>Нехорошо пускать по ветру годы
      синхронизации.</p>

    <p>Это если Андрей со своим вирусом
      не пошёл прямо к фалангам, например. Известно, какая у него была голубая мечта.</p>

    <p>Серая рубашка и пустой взгляд
      прилагаются к Андреевой голубой мечте.</p>

    <p>Впрочем, это только усугубляет
      необходимость его как можно скорее найти.</p>

    <p>— Что-то многовато у вас тут, в
      Столице, людей пропадает, — хмыкнул Гошка. — Не верю, что бесследно.
      Рассказывай, что знаешь про Андреева человека, буду искать.</p>

    <p>— Много не знаю, мне это незачем.
      Говорил, что практикующий врач, учился в медицинском где-то в Средней полосе —
      в Кирзани или в Старожлебинске, не помню. После института уехал в степ’ лечит’
      местных за спасибо, жрат’ траву и спат’ в юрте — гуманист. Не учёный, но голова
      соображает, кое-чем помог даже. Андрей его летом через свои каналы официально
      пропихнул в Корпус — то ли со мной работат’, то ли ещё зачем-то.</p>

    <p>И снова тонны бесполезной
      информации, создающей иллюзию ответа.</p>

    <p>— Зовут его как?</p>

    <p>— Дмитрий Ройш.</p>

    <p>Дмитрий Ройш.</p>

    <p>У Гошки есть несколько рабочих
      привычек. Например, представляться младшими служащими. А в качестве псевдонимов
      брать фамилии членов Революционного Комитета — те из них, которые неизвестны
      широким массам. Скворцов в учебниках записан как Скопцов, давешний Ивин,
      поивший коньяком девку с истфака, — как Твирин; в общем, хватало простора для
      потряхивания лично гошковским интеллектуальным багажом. Знали об этой привычке
      немногие. И если бы кто-то из этих немногих решил вдруг истаять в неизвестном
      направлении, лучшего способа поглумиться, кроме как оставить после себя след
      человека по фамилии Ройш, который в учебниках записан как Ройш, просто не
      придумать.</p>

    <p>Если это прощальный подарочек
      Андрея, то он хотя бы весёлый. Подобная наглость не в его духе, но ведь и
      правда же — этот человек отличается повышенной обучаемостью.</p>

    <p>Поводы не доверять ему всегда
      были — он ещё Колошме зарекомендовал себя как тот ещё скунс. Но Гошка на оные
      поводы плюнул: доверять — это требование к вакансии такое. И сейчас он предпочёл
      бы о возможном предательстве не думать. В конце концов, они друг друга
      прекрасно знают. Если предательство имело место и Андрей попадётся, он должен
      понимать, что ему придётся очень, очень несладко.</p>

    <p>Не стоит топтать чужое доверие.</p>

    <p>Особенно доверие Гошки.</p>

    <p>Так что на нынешний вечер сводка
      такая: где Андрей, непонятно, но это не повод. Эпидемия уже запущена,
      останавливаться сейчас —&nbsp;испортить всё в любом случае. Раз уж план пришёл
      в действие, нужно быть последовательными и продолжать. Хорошо бы поставить
      Университет на место (то есть уничтожить, поскольку места им в Бедрограде нет)
      и хорошо бы, чтобы Андрей был в Бедроградской гэбне, когда это случится. Но
      если нет —&nbsp;что ж, значит, не судьба.</p>

    <p>А сейчас надо развязать тавра,
      извиниться за оказанные неудобства, доехать до ближайшего безопасного телефона
      и провести с Социем и Бахтой фактическую синхронизацию. И обдумать, кто такой
      Дмитрий Ройш и существует ли он в природе.</p>

    <p>Леший еби, опять думать.</p>
  </>
}

export default chapter03
