import React from 'react'
import { IBookPage } from './index'

const chapter32: IBookPage = {
  header: 'header_d9.jpg',
  title: 'Глава 32. Присмотреться',
  prevLink: 'chapter31',
  nextLink: 'chapter33',
  content: <>
    <h2>Университет. Максим</h2>

    <img alt='' src="/images/32_maxim.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>Потому что иногда надо просто
      смириться.</p>

    <p>Посмотреть на вещи с другой
      стороны и смириться.</p>

    <p><i>Другая сторона </i>— это ведь, как выясняется, вовсе не обязательно
      предательство убеждений. Это, как выясняется, всего-навсего другой угол зрения,
      который иногда помогает видеть чётче те же самые вещи.</p>

    <p>Максим невольно усмехнулся:
      видеть чётче.</p>

    <p>Можно при хорошем зрении надевать
      очки с простыми стёклами, а можно — при плохом линзы. Другой угол зрения — это
      почти что линзы и есть. Что-то меняется в восприятии, но никто об этом не
      догадается, если не сказать вслух.</p>

    <p>Последние
      пару дней Максим был не в том положении, чтобы много говорить вслух. После
      встречи гэбен он даже университетским преподавателем больше не числился, не то что
      кем-то, кого послушают, если он заговорит вслух.</p>

    <p>Тем более — под другим углом.</p>

    <p>Потому что в Университете есть
      мода на очки с простыми стёклами, а на линзы — нет. Всем, кто гнёт извилины в
      Университете, положено иметь плохое зрение. Как в прямом смысле, так и — увы —
      в переносном.</p>

    <p>Сейчас Максим до тошноты <i>чётко видел</i>: очки с простыми стёклами —
      это не только аксессуар, модой на который Университет обязан Габриэлю. <i>Это
        символизм</i> (леший, после Пинеги и
      «Белого дерева» Максим никогда не перестанет дёргаться от слова «символизм»).</p>

    <p>Символизм. Нет, хуже: символ
      Университета — очки с простыми стёклами (пункт в конспекте, параграф в
      учебнике, мысленно выделить и подчеркнуть).</p>

    <p>&nbsp;</p>

    <p><i><u>Очки с простыми стёклами</u></i></p>

    <p>&nbsp;</p>

    <p>Делать вид, что видишь меньше,
      чем на самом деле. Потихоньку привыкать щуриться для полноты образа. Щуриться,
      придвигаться поближе, не фокусировать взгляд, а в результате и
      правда стать подслеповатым, убедить себя, что не можешь верно рассмотреть что
      бы то ни было без очков.</p>

    <p>Максим много лет наблюдал, как
      это происходило с Габриэлем в прямом смысле.</p>

    <p>Сейчас Максим понял, что в
      переносном смысле это происходило и со всем Университетом.</p>

    <p>С самим Максимом — тоже.</p>

    <p>Потому что есть вещи, которые
      удобнее (спокойнее, приятнее) не замечать. Очки с простыми стёклами в таких
      вопросах отлично помогают. Как символ.</p>

    <p>А ещё они помогают в вопросах
      маскировки. Это тоже заслуга Габриэля — простое, как его стёкла, открытие:
      человек в очках выглядит одновременно серьёзнее и беспомощней, чем он есть на
      самом деле.</p>

    <p>Более
университетским выглядит человек в очках.</p>

    <p>&nbsp;</p>

    <p>— Чё как башкой ударенный сидишь? Думаешь, что л’? — проявил что-то вроде
      дружелюбия
      портовый таксист, тавр Муля Педаль, и Максима перекосило.</p>

    <p>Муля Педаль — человек
      Святотатыча, курьер с таксистскими правами и хорошим знанием Бедрограда.
      Последние пару лет Святотатыч именно его отправлял в Университет, когда
      возникали совместные дела.</p>

    <p>Последние пару
лет Муля Педаль обращался к Максиму на вы.</p>

    <p>Это было правильно и совершенно
      естественно — сообразно возрасту, социальному статусу, уровню доступа. Настолько
      естественно, что Максим ни разу в жизни и не задумывался, как обращается к нему
      Муля Педаль.</p>

    <p>Только сегодня задумался.</p>

    <p>По контрасту.</p>

    <p>&nbsp;</p>

    <p><i><u>Обращение на вы и на ты</u></i></p>

    <p>&nbsp;</p>

    <p>Элемент коммуникативного кода,
      маркирующий некоторые существенные параметры.</p>

    <p>Например, со студентами — только
      на вы, всегда на вы, в обе стороны на вы. Демонстрация уважения (к взрослым,
      сознательным людям, имеющим право на собственное мнение) и черта, которую
      переступать немыслимо.</p>

    <p>С Габриэлем (когда-то —
      Евгеньевичем) было как раз немыслимо.</p>

    <p>Когда-то Максим писал у него
      диплом и восхищался украдкой, ни на что не надеясь, а
      потому ни о чем и не помышляя. Когда же Максим писал у него кандидатскую,
      Габриэль иногда вдруг обращался к нему на ты. Это пугало и щекотало самолюбие,
      но сам Максим ещё долго не мог позволить себе ответное обращение. Потому что <i>позволить</i> должен был Габриэль.
      Позволяет, выбирает — всегда Габриэль, тут не может быть иных вариантов.</p>

    <p>Первое «ты» было позволено
      Максиму в жуткое время. Университетская гэбня
      появилась совсем недавно, гэбня Бедроградская устроила свою показательную акцию
      с отправлением первого попавшегося под руку истфаковского студента на Колошму,
      а потом подумала, что этого мало — и Габриэль вдруг пропал.</p>

    <p>Пропал, хотя казалось, что его
      беды уже позади — за расшифровки ведь только что наказали Диму, который
      вызвался сам, поперёк воли Университетской гэбни. Хотя нет — поперёк воли
      Максима: до полного состава гэбни даже слух дойти не успел, как Дима уже решил
      всё в одиночку. Его, вернувшегося после нескольких дней допросов, прятали на
      частной квартире, пока не настанет бюрократическая ясность по поводу того,
      которая из гэбен на самом деле имеет право применять санкции к университетскому
      человеку. Прошение, посланное в Бюро Патентов, — дело небыстрое.</p>

    <p>А пока небыстрые дела делались,
      на частную квартиру с правомочным обыском явилась Бедроградская гэбня. Вышло
      так, что Максим буквально нос к носу столкнулся на лестнице с Андреем и парой
      младших служащих. Андрей был совсем молод, в Бедроградской гэбне служил на тот
      момент всего ничего, и потому Максим не почуял опасности, когда тот дружелюбно
      предложил не устраивать-таки обыск, а переговорить с глазу на глаз прямо там —
      обсудить как голова гэбни с головой гэбни варианты решения вопроса.</p>

    <p>Андрей вежливо и доходчиво
      объяснял не видящему подвоха Максиму, что за <i>те самые</i> расшифровки на руках у гражданских лиц Бюро Патентов по
      голове гладить не станет. Что Бедроградская гэбня хотела бы повременить с
      санкциями, пока Университет подтверждает свои права, но в этом конкретном деле
      не может — слишком опасно, слишком скандально. Что действовать надо сейчас.</p>

    <p>Действовать означало «отправить
      виновных в добыче и хранении информации неположенного уровня доступа на
      Колошму».</p>

    <p>Виновных означало «Диму и
      Габриэля».</p>

    <p>Расшифровки нашли у Димы в сумке,
      но причастность Габриэля у Бедроградской гэбни сомнений не вызывала — он
      зачем-то оставил полторы карандашных строчки на полях, графологическую
      экспертизу уже провели. «Без протокола, некогда было, — смущённо пояснил
      Андрей, — а карандаш и вовсе можно стереть».</p>

    <p>А потом смутился совсем: мол,
      если допросить Габриэля, будет шанс выяснить, откуда расшифровки вообще
      взялись, а если исключить его из цепочки — не будет, Дима ведь уже не
      признался. И, вероятно, не признается, потому что просто-напросто не знает. Знает,
      вероятно, Габриэль, но если он не будет упомянут в деле, вся ответственность
      ляжет на Диму. Придётся обозначить в обвинении, что он укрыл источник утечки.</p>

    <p>«Чем ему это грозит?» — уточнил
      окончательно растерявшийся Максим.</p>

    <p>Андрей помялся, закурил и отвёл
      глаза: «Расстрелом».</p>

    <p>Максим оторопел. Да, он хотел бы
      уберечь Габриэля от Колошмы, но не такой же ценой!</p>

    <p>Не такой ценой, но из-за двери,
      под которой Максим с Андреем всё не могли определиться, что же делать, вышел
      Дима.</p>

    <p>Он уже тогда умел с лёгкостью
      принимать решения, идущие поперёк чьей угодно воли.</p>

    <p>Удивление на грани оцепенения, попытки
      отговорить, протест с нотой благодарности — всё давно смешалось в памяти
      Максима; через столько лет он помнил лишь, как сам прятал взгляд, когда тем
      вечером пришёл лично сказать Габриэлю «вам больше ничего не угрожает».</p>

    <p><i>Вам.</i></p>

    <p>Он просто не мог смотреть, как
      Габриэль скручивает одеревеневшими пальцами савьюровую самокрутку,
      как судорожно проглатывает вместе с дымом бессмысленные теперь вопросы.</p>

    <p>«Уходя под конвоем, Дима говорил,
      что его жертва бессмысленна, потому что вы всё равно наложите на себя руки,
      узнав о ней, — не выдержал Максим. — Я никогда не был поклонником его
      специфического чувства юмора, но в данном случае…»</p>

    <p>Габриэль даже улыбнулся: «Наверное,
      он тоже был немного не в себе. Более в его духе было бы пошутить, что я,
      услышав, лишусь чувств, нечаянно ударюсь головой о печатную машинку и на
      неопределённый срок впаду в кому».</p>

    <p>Максим инстинктивно сделал
      несколько шагов вперёд, желая обнять, прижать, хоть как-то успокоить. Поймал
      себя в последнюю секунду, вспомнив, что не получал разрешения.</p>

    <p>«Вам не стоит держать это всё в
      себе, сейчас не время», — аккуратно предложил он.</p>

    <p>Время ведь
действительно было сложное — Габриэлю через пару дней предстояла защита
докторской, без которой он не смог бы занять завкафское кресло вместо
отправлявшегося в том году на покой Воротия Саныча. А без завкафского
      кресла было бы сложнее пробить для него должность полуслужащего при Университетской
      гэбне, дабы окончательно обезопасить от
      дальнейших неприятностей.</p>

    <p>«Действительно, сейчас не время,
      слишком много дел, — отвернулся Габриэль. — Максим, у вас тоже есть дела и вы
      тоже на нервах. Я благодарен вам, но, право слово, моё моральное состояние — не
      ваша головная боль».</p>

    <p>Моя, хотел возразить Максим, ты
      давным-давно стал моей головной болью, — но сдержался. «Ты» в таком контексте
      прозвучало бы нагло, «вы» — глупо.</p>

    <p>Когда на следующий день после
      защиты докторской Габриэль не появился на факультете и не обнаружился дома,
      Максим бессчётное количество раз пожалел, что
      сдержался. Быть может, не сбеги он тогда так быстро, Габриэль бы и правда
      выговорился, пережил, смирился — вместо того, чтобы после защиты, употребив за
      раз весь домашний запас наркотических веществ, идти в Порт.</p>

    <p>Про Порт и наркотики вызнал Святотатыч:
      быстро нашёл кого-то, кто опознал Габриэля по фотокарточке и рассказал
      кое-какие детали. Самого Габриэля — не нашёл.</p>

    <p>Максим был зол и совершенно
      потерян, но тут среди студентов (студентов, которые и не подозревали о
      существовании власти в Университете и её сложностях!) поползли слухи, что
      Габриэль какого-то лешего у Бедроградской гэбни. Теперь, когда вопрос с
      расшифровками закрыл своим самоубийственным решением Дима, а Габриэль
      официально получил статус полуслужащего, которым можно было бы при желании
      кое-как оправдать проклятые бумаги! И гэбня Университетская
      почти клюнула, Максим и Ларий почти отправили запрос к фалангам на официальное
      разбирательство относительно неправомочных задержаний лиц, находящихся под
      протекцией Университета.</p>

    <p>Ситуацию спасли Охрович и Краснокаменный:
      почуяли неладное, разговорили студентов, нашли
      источник слухов. Этим источником оказалась четверокурсница с кафедры истории
      науки и техники — общительная, привлекательная девушка, привыкшая к случайным
      знакомствам с самыми разнообразными людьми. Слух ей принесло как раз такое
      случайное знакомство: подсел в баре некто, рассказал про то, как она хороша,
      заказал коньяку, слово за слово, услышал, что девушка учится на истфаке, и
      сделал загадочное лицо. Та заинтересовалась, стала задавать вопросы. Собеседник
      поддался и поведал — по страшному секрету, только такой прекрасной деве! — что
      он в этом баре околачивается как раз из-за истфака. Младший служащий
      Бедроградской гэбни, занят важным делом, имеющим отношение к задержанию Онегина
      Габриэля Евгеньевича. Ах, вы не знали? Да, задержан, да, Бедроградской гэбней.
      Да, совершенно точно, сам видел.</p>

    <p>Разумеется, сейчас вся эта
      история до смешного напоминала Максиму начало чумы.</p>

    <p>Совсем до смешного:
      младший служащий Бедроградской гэбни представился девушке Александром.</p>

    <p>Когда Охрович и Краснокаменный
      убедительно попросили, она даже нашла оставленный Александром телефон и
      напросилась на свидание. Охрович и Краснокаменный с трудом, но опознали-таки в
      нём Гошку, голову Бедроградской гэбни. Смекнули: если
      голова Бедроградской гэбни знакомится с истфаковскими студентками, чтобы
      невзначай сболтнуть, что Габриэль у них, значит, Габриэля у них искать не надо.
      Провокация: Университет отправит официальный запрос к фалангам, а Бедроградская
      гэбня возмутится клевете и потребует не менее официальной сатисфакции.</p>

    <p>По нынешним меркам — ерунда. По тогдашним — кошмар.</p>

    <p>Кошмар, спровоцированный
      аналогичной ерундой со стороны Университета: уже тем самым вечером, когда
      Максим ходил говорить Габриэлю, что Дима добровольно согласился на расстрел,
      Ройш поднял тонны кодексов и разъяснил остальным головам Университетской гэбни,
      что их обманули по неизвестным причинам. Расстреливать за расшифровки нельзя —
      по крайней мере, без прямого указания от Бюро Патентов. Диме полагалось только заключение
      на Колошме.</p>

    <p>Максим не знал этого наверняка во
      время беседы с Андреем — Университетская гэбня существовала
      тогда меньше года и состояла из людей, которые не оканчивали в общем порядке
      Институт госслужбы. Детальное изучение всех тонкостей взаимодействия разных
      уровней доступа к информации было ещё впереди.</p>

    <p>Зачем Андрей соврал, они не сумели
      понять (Дима походя рассказал уже в этом мае), но
      удержаться от ответной гадости не смогли — отправили в Бюро Патентов обтекаемую
      жалобу на процессуальное дезинформирование со стороны Бедроградской гэбни.</p>

    <p>Бедроградской гэбне, очевидно, не
      понравилось отдуваться за мелочи, и они решили ответить Университету не менее
      мелким обвинением в клевете. Только для того, чтобы оная клевета имела место,
      они не поленились похитить Габриэля, пустить слух среди студентов о его пребывании
      у Бедроградской гэбни, когда перепрятали его где-то понадёжнее.</p>

    <p>Ради бумажной войны через
      посредничество Бюро Патентов Бедроградская гэбня похитила человека.</p>

    <p>Гэбне Университетской тогда казалось,
      что это <i>уже слишком</i>. Теперь кажется, что
      тогда был отрядский утренник.</p>

    <p>В итоге Габриэля искали
      неофициальными путями. Максим от глупого бессилия почти возненавидел Гошку
      лично — как первого, кто на ум приходил в связи с этим делом. А Охрович и Краснокаменный,
      наоборот, Гошкой тогда очень впечатлились: качеством изменения внешности,
      коммуникативными навыками, подготовкой. Студентка, например, была выбрана
      прицельно — неглупая, но болтливая, та, которой многие поверят. Ходячий
      истфаковский радиоканал последних новостей. И подход к ней был найден
      правильный. Даже «Александр» — редкое, претенциозное, с дореволюционным
      ароматом имя — и то неслучайно. Студентка как раз писала работу об обкатке
      новых методов организации производственного процесса будущим Революционным
      Комитетом на метелинском заводе, который стал стартовым капиталом Революции и
      значимой экспериментальной площадкой. И писала явно не из любви к организации
      производственного процесса, а из любви к Метелину лично, что было более чем
      заметно из работы.</p>

    <p>Метелина звали Александр. Даже
      эту мелочь Гошка учёл.</p>

    <p>Это сейчас Охрович и
      Краснокаменный смеются над его бесконечными младшими служащими, да ещё и
      Александрами, а тогда они у него в некотором смысле учились.</p>

    <p>Сам Максим тогда ничему не учился
      (зря, как же зря), Максим просто хотел найти Габриэля и чтобы
всё поскорей закончилось, потому что Габриэль ни при чём, Габриэль не
      должен был попадать под раздачу, не должен был ещё раз страдать за большую
      политику.</p>

    <p>Габриэль нашёлся: не сразу, через
      месяц, без сознания, без документов, в какой-то городской больнице. Пришёл в
      себя в медфаковском лазарете. Когда Максим таки дошёл до него, прошептал слабым
      голосом: «Забери меня отсюда».</p>

    <p>И это было оно — разрешение,
      позволение обращаться на ты,
      оберегать и нести ответственность, делать что угодно ещё. Максим сказал: «Не
      беспокойся ни о чём» — и даже не сразу понял, что — да, перешёл черту, да,
      теперь будет по-другому, будет так.</p>

    <p>Он до сих пор в деталях помнил тот
      короткий разговор, состоявшийся много лет назад.</p>

    <p>Черта, перейти которую немыслимо.
      Перейденная черта.</p>

    <p>Это нелепо, но теперь каждый раз,
      когда Максим слышал в свой адрес неожиданное «ты» вместо приличествующего «вы»,
      он невольно дёргался. Потому что в этой тривиальной смене обращения так много
      личного, так много важного только для Максима и Габриэля, что всем остальным
      будто бы просто нельзя этого делать.</p>

    <p>Как бы смешно это ни звучало.</p>

    <p>&nbsp;</p>

    <p>— Да перестан’ ты морду кирпичом
      квадратит’, что я, пытаю тебя, что л’? — снова тыкнул
      Максиму Муля Педаль.</p>

    <p>Максим постарался не взъедаться:
      Муля Педаль ведь не со зла, просто…</p>

    <p>Просто Максим больше не голова
      гэбни, даже не университетский преподаватель. Не заслуживает уважительного обращения
      от портовой швали.</p>

    <p>Муля Педаль смотрел весело и с
      любопытством.</p>

    <p>— Ты это, только не зашиби, но про тебя ж нынче всякое болтают, — осторожно
      начал он. — Будто б ты и припёр в Порт заразу,
      подлюка.</p>

    <p>Максим открыл
      было рот, но Муля Педаль не дал ему встрять:</p>

    <p>— В гэбне Портовой болтают. Точнее
      в ней-то не болтают, болтают вокруг неё. Ну, самые доверенные люди, близкие к
      гэбне. Типа меня, — Муля Педаль довольно заулыбался. — Короче, те, кто в
      курсах, что у нас тут не обратная ветрянка, а что похуже. Болтают, что ты змея:
      денежки из гэбни высосал и такую подставу обустроил, шоб одним махом и
      бедроградских, и наших с этого вашего уровня доступа
      смахнут’.</p>

    <p>— Предельно глупо звучит, —
      сдержанно прокомментировал Максим, глядя мимо Мули Педали на высоченный забор
      из цельных плит.</p>

    <p>За забором — Порт, в Порту —
      чума, блокада, слухи про Максима и чуму.</p>

    <p>Максиму всегда казалось, что Порт
      — какая-то другая реальность, строго перпендикулярная привычной. Пересекается с
      ней по одной прямой, и как на этой прямой искажаются все известные переменные —
      сам леший не разберёт.</p>

    <p>— Глупо-глупо, я вот не купился,
      — всё так же довольно согласился Муля Педаль. — Гуанако сказал, это не ты, и я
      сразу мозгами-то пораскинул, сразу допёр: конечно, не
      ты, тебе оно нахуй надо?</p>

    <p>Гуанако сказал.</p>

    <p>Несколько дней назад от двух этих
      слов Максима бы затрясло. Несколько дней назад Максим не видел, от чего <i>на
        самом деле</i> стоит трястись.</p>

    <p>— Гуанако сказал, что подлюка и
      змея из тебя так себе, что подобное блядство тебе бы и в голову не пришло, —
      разливался соловьём Муля Педаль — Сказал, что ты хороший парен’, сверх всякой
      меры хороший, оттого на тебя вся сран’ и посыпалас’.</p>

    <p>Максим продолжил созерцать забор.</p>

    <p>Переменные принимают абсурдное
      значение.</p>

    <p>&nbsp;</p>

    <p><i><u>Хороший парень</u></i></p>

    <p>&nbsp;</p>

    <p>Сверх всякой меры. Настолько сверх, что плюнул на собственную гэбню, на
      Университет, на
      зачумлённый город — и сорвался на Пинегу. И нет бы просто
сорвался, забыл или хотя бы <i>не
        смог</i> позвонить. Так ведь сам бросил трубку, не стал предупреждать, что,
      куда и зачем.</p>

    <p>Сверх всякой меры хороший парень.</p>

    <p>Только если понятие «хороший» с
      необходимостью включает в себя признак «не очень умный».</p>

    <p>В таком случае Гуанако сам
      поразительно хороший, хм, парень. Потому что видит всех — абсолютно всех, кто
      заслуживает и не заслуживает! — хорошими.</p>

    <p>Одно дело — полагать, что
      «плохих» самих по себе, без конкретной причины, нет. Максиму никогда не
      казалось, что кто-то может быть плох просто так, плох, потому что плох. Совсем
      другое дело — игнорировать конкретные причины. Надевать очки с простыми
      стёклами, притворно щуриться и в результате переставать замечать эти конкретные
      причины.</p>

    <p>До того, как исчезнуть из
      Бедрограда на десять лет, Гуанако носил очки. Настоящие
      и довольно сильные, насколько Максим мог судить. Вернулся (восстал из мёртвых)
      без очков. Может быть, в линзах, может быть, нет — Максим не интересовался.
      Сейчас подумалось, что в этом есть что-то почти что страшное, <i>символическое</i> (Максим дёрнулся): Гуанако
      ведь может <i>просто не видеть</i>, что
      происходит у него под носом.</p>

    <p>И потому считать всех подряд
      хорошими парнями.</p>

    <p>Если бы речь шла только о
      Максиме, дело обстояло бы не так плохо.</p>

    <p>&nbsp;</p>

    <p>— …только Максима берём, он
      сказал, — продолжал разговор с самими собой Муля Педаль. — Дело-то фартовое, чем
      меньшим числом рук сдюжим, тем фартовей
      выйдет.</p>

    <p>Видимо, это было объяснение,
      зачем Максиму нужно сидеть в такси с тавром напротив забора из цельных плит.</p>

    <p>Муля Педаль побарабанил пальцами
      по рулю:</p>

    <p>— Побыстрей
      бы пёр уже, времечко-то ждат’ не будет.</p>

    <p>— Если нам нужно побыстрей, имеет смысл подъехать к какому-нибудь входу, —
      прохладно указал на оплошность Максим.</p>

    <p>— Не, — мотнул всем корпусом Муля
      Педаль. — Гуанако сказал тут стоят’ — будем тут стоят’.</p>

    <p>Максим ещё раз огляделся для
      верности: ухоженный сквер, за давностью лет поросшие травой крыши приземистых
      автомастерских, блестящий указатель выезда на магистраль, чуть вдалеке —
      кремовый кирпич новостроек. Максим знал Порт гораздо хуже многих, но уж
      основные входы и выходы выучил давно.</p>

    <p>— Тут до ближайшего поста не
      меньше двадцати минут пешком, — удостоверившись в своей правоте, констатировал
      он. — Теряем время, если оно нам дорого.</p>

    <p>— Много ты понимаешь, городской,
      — отбрил Муля Педаль, но на забор, отделяющий Порт от
      Бедрограда уставился с некоторым недоумением.</p>

    <p>Высоко, глухо, цепляться не за
      что, внизу асфальт — с такой высоты не попрыгаешь.</p>

    <p>— Тут — значит тут, — Муля Педаль
      решительно убрал руки с руля. — Гуанако вед’ хероты не скажет.</p>

    <p>Максим равнодушно отметил: сотое
      подряд упоминание непогрешимости Гуанако его не задело. Совсем. Это было даже
      немного странно. Поболело и отпустило, больше не болит.</p>

    <p>Когда Муля Педаль познакомился с
      Гуанако?</p>

    <p>Сейчас, в чуму, после того, как
      тот сошёл с Курёхина на берег в прошлую субботу? Или всё-таки в мае, когда тот
      только объявился в Порту и Бедрограде после стольких лет отсутствия?</p>

    <p>Неважно, так и так времени прошло
      мало, а Муля Педаль уже подался к Гуанако в личные помощники. Верит каждому
      слову, жмёт педали своего такси по первому требованию. Наверняка мечтает
      махнуть рукой на свои прежние обязанности и окончательно сбежать от
      Святотатыча, если понадобится.</p>

    <p>И Максим больше не видел в этом
      ничего странного. Если люди готовы безвозмездно тратить на Гуанако своё время,
      силы и деньги (столько, сколько было получено материальной помощи от Порта в
      обмен на честное слово, — это не шутки), значит, Гуанако того стоит. Значит, он
      даёт им всем что-то, на что не жалко ни времени, ни сил, ни денег. Наверное,
      Максиму просто с самого начала не повезло оказаться с Гуанако не в тех
      отношениях.</p>

    <p>Даже обидно. Почти.</p>

    <p>&nbsp;</p>

    <p><i><u>Не те отношения</u></i></p>

    <p>&nbsp;</p>

    <p>Видимо, это называется
      пересечение интересов.</p>

    <p>Тут можно прямо по пунктам:
      контрреволюционное движение, Габриэль, власть.</p>

    <p>С контрреволюционным движением
      вышло нелепо. Когда-то Максим верил, что этому государству не стоит быть (можно
      продолжить: «таким, какое оно есть сейчас», но лучше просто — «не стоит быть»).
      Гуанако год за годом без труда отнимал у Максима его веру, его сторонников, его
      перспективы — пока на четвёртом курсе Максима контрреволюционное движение не
      издохло совсем. Потому что Гуанако верил, что движению стоит издохнуть.</p>

    <p>Он не виноват, что верил сильнее
      Максима.</p>

    <p>Он молодец, он сделал, что считал
      нужным.</p>

    <p>Максим смирился, пошёл в
      аспирантуру, забыл о политике — но политика сама пришла за ним в лице
      подвыпившего Хикеракли, который широким жестом усадил Максима в новообразованную
      Университетскую гэбню.</p>

    <p>На тот момент Максим от слова
      «гэбня» шарахаться уже перестал, поостыл в ненависти к государственному
      устройству Всероссийского Соседства, признал кое-как некоторые его
      преимущества. Но это ведь не то же самое, что дать согласие на своё
      непосредственное участие в управлении государством.</p>

    <p>Но его согласия никто не
      спрашивал — Хикеракли мог не только перегаром дыхнуть любому в лицо, но и ткнуть
      своим вторым уровнем доступа.</p>

    <p>Живому члену Революционного
      Комитета не выскажешь никакого «не хочу».</p>

    <p>Максим пошёл с другой стороны:
      «51-й год, лаборатории, эксперименты по уровню агрессии — вы помните окончательный
      вердикт по делу контрреволюционного движения на истфаке? Группа состоявших в
      нём лиц была признана психически нестабильной, но — в силу отсутствия
      конкретных экспериментальных данных — решено было не начинать принудительное
      лечение до первого однозначно трактуемого прецедента. Прецедентов пока не было,
      но вы уверены, что таких людей можно наделять властью?»</p>

    <p>Хикеракли только посмеялся: «Я —
      нет, я-то вас не знаю, а вот ваш преподаватель, покойный Гуанако С. К., уверен.
      Был уверен, он и порекомендовал вас».</p>

    <p>Покойный Гуанако С. К. даже из
      могилы продолжил толкать Максима на путь любви к Революции. И к государству,
      ставшему её результатом.</p>

    <p>Максим взбесился. Сначала у него
      отобрали его идеалы, а потом и вовсе начали распоряжаться им по своему
      разумению. Ларий, Охрович и Краснокаменный, с которыми
      он по всё тому же чужому разумению оказался в одной упряжке, успокаивали его:
      собственная гэбня — это весело. Это возможность претворить идеалы в жизнь,
      совместить их с реальным положением дел, сделать так, как кажется правильным, а
      не так, как делают все остальные. Это единственная форма деятельности, которой
      разумно заниматься бывшему главе контрреволюционного
      движения.</p>

    <p>Ларий, Охрович и Краснокаменный —
      тоже экспериментальный материал 51-го года. Что их слушать, ненормальных.</p>

    <p>Особенно с учётом того, что наличие
      собственной гэбни и без их аргументов слишком
      подогревало самолюбие и вдохновляло на свершения. Это стыдно, это предательство
      былых идеалов, но это правда.</p>

    <p>И правдой это сделал Гуанако,
      потому что так захотел. А Максиму оставалось только подчиниться воле мертвеца и
      получать своё поначалу постыдное удовольствие.</p>

    <p>Даже смешно.</p>

    <p>Контрреволюционное движение на
      истфаке, двигавшееся прямиком к созданию Университетской
      гэбни. Которая состоит теперь из его ярых сторонников
      и ярых противников, двое на двое, равенство мнений.</p>

    <p>Достойный конец мечтаний о
      достойном же конце существующего государственного устройства.</p>

    <p>Но это давно пережитые эмоции,
      куда больше эмоций ещё совсем недавно вызывали пересекающиеся интересы
      «Габриэль» и «власть».</p>

    <p><i>Вызывали</i>, да. Но, по-хорошему, тут и думать нечего: и то, и другое
      казалось много лет подряд принадлежащим Максиму, но стоило Гуанако вновь
      возникнуть ниоткуда на горизонте, как иллюзия развеялась.</p>

    <p>Не над чем тут думать. Есть вещи,
      которые могут принадлежать только самым лучшим. И самые лучшие не виноваты в
      том, что они самые лучшие.</p>

    <p>Молодцы они, если мыслить здраво.</p>

    <p>&nbsp;</p>

    <p>— Что я тебе говорил! — замахал
      руками Муля Педаль. — Тут ждат’ — значит, тут.</p>

    <p>Максим проследил за его суматошными
      жестами: метрах в пятидесяти от такси на заборе возникла фигура в тельняшке,
      чуть неуклюже качнулась, бросая на асфальт что-то большое. Видимо, тюк с
      тряпьём или опилками. Фигура огляделась, помялась, прицелилась и приземлилась с
      высоченного забора на свой заранее подстеленный тюк.</p>

    <p>Вскочила, отряхнулась, отошла
      подальше и, размахнувшись, перекинула тюк обратно за забор. Видимо, таки
      тряпьё, а не опилки.</p>

    <p>Пара секунд — и Гуанако уже в
      городе, преодолевает своим мучительно знакомым быстрым шагом расстояние до
      такси. Улыбается заранее, как будто видит Мулю Педаль с Максимом сквозь
      затемнённые стёкла.</p>

    <p>Лёгкость,
подумал Максим, делает лучших <i>самыми
лучшими</i>. И тяжело вздохнул.</p>

    <p>— Не хочется никого нервировать
      лишний раз с этой блядской блокадой, — распахнул дверь такси Гуанако. — Там на
      постах сейчас такое творится, что лучше не соваться со своим пропуском.
      Страждущие убьют нахуй постовых, которые пропустят в город какого-то наглого
      хуя с бумажкой. Так что приходится выбирать альтернативные пути.</p>

    <p>— С такси или грузовиком проще, —
      радостно закивал Муля Педаль. — Думают, везём чего хорошее,
      не бухтят. Только прицепит’ся пытаются.</p>

    <p>Блокада Порта, подумал Максим.
      Перекрытые входы и выходы, недовольство портовой швали.
      Там сейчас ещё неспокойней, чем всегда. Там сейчас кошмар.</p>

    <p>— Здравствуй, — протянул руку с
      заднего сиденья Гуанако. — Ты живой?</p>

    <p>Максим неопределённо повёл
      плечами, пожал протянутую руку и только сейчас заметил, что Гуанако мокрый:
      характерный запах воды, непросохшие волосы, едва заметные дорожки застывшей
      соли на коже.</p>

    <p>— Сходил
      ополоснулся прямо в Пассажирском Порту, — пояснил Гуанако, дав Максиму
      возможность не отвечать, живой он или не очень. — Там же сейчас пусто совсем,
      таможенники не шугают, приличия и безопасность не
      блюдут. А бухта классная. Когда солнце встаёт, так вообще.</p>

    <p>— Просто так сходил? — удивлённо
      переспросил Муля Педаль, газуя и оставляя за спиной высоченный забор, ухоженный
      сквер и коробки автомастерских.</p>

    <p>Гуанако засмеялся:</p>

    <p>— Ага. Ничего не пиздил и никого
      не мочил, ты представь. Плавал себе меж беленьких-чистеньких
      пароходиков и на рассвет любовался как последний кретин, — поискал по карманам
      самокрутку Гуанако. — Муля, было б дело — я б тебя кликнул, не сомневайся.</p>

    <p>Блокада Порта, подумал Максим,
      корабли, не выпущенные в море, беспорядки и паника. Пока портовая шваль осаждает
      доступных ей представителей власти —
      капитанов, таможенников, диспетчеров, а кто-то, наверное, и саму Портовую
      гэбню, — Гуанако сходил поплавать в бухте Пассажирского Порта на рассвете.</p>

    <p>Как же понятно, подумал Максим,
      почему Габриэль вздыхал по нему столько лет.</p>

    <p>Как же непонятно, почему его
      вообще занесло в Университет.</p>

    <p>Гуанако ведь
другой породы — ему бы контрабанду возить из далёких стран, а не экзамены
принимать, он же на короткой ноге с Портовой гэбней, он же с отрядских лет по
портовым закоулкам прячется и через заборы прыгает.</p>

    <p>Максим попробовал представить, на
      что был бы похож Университет, не случись там Гуанако, но забуксовал уже на
      контрреволюционном движении. Заглохло бы само? Или всех бы пересажали? Или
      что-нибудь получилось бы? Но что? Не уничтожение же существующего
      государственного строя, в самом деле.</p>

    <p>Максим буквально увидел перед
      собой стройный ряд томов ПСС Гуанако, которое они
      когда-то ваяли всей кафедрой, чтобы идеологически прикрыть действия
      Университетской гэбни. Увидел Габриэля, который щурился в своих очках с
      простыми стёклами над затёртыми машинописными листами, пытаясь сложить
      недоработанные отрывки в подобие целого, и тайком подливал себе в чашку больше
      коньяка, чем кофе.</p>

    <p>Это было давно, с тех пор
      Габриэль стал щуриться гораздо чаще — может, и правда зрение село? А недавно,
      во вторник, ещё до того, как разругаться с Габриэлем и хлопнуть дверью, Максим
      застал на кафедре студентку Шухéр с первым
      томом ПСС Гуанако в руках. Нелепо раскричался — вместо того, чтоб порадоваться,
      что домашняя девочка решила-таки почитать что-то ещё, помимо своей слезливой
      росской классики.</p>

    <p>Задело, что домашняя девочка
      захотела начать просвещаться именно с томов Гуанако. А с чего ещё, спрашивается, ей было начинать?</p>

    <p>— Я хотел спросить, — прокашлялся
      Максим. — Студентка Шухер и Хащина — там стало что-нибудь понятно? Был, не был
      в больнице Силовой Комитет? Я же, — Максим замялся
      было, но продолжил, — я же выключился из работы Университета как раз на этапе
      выяснения, что с ней произошло.</p>

    <p>Ройш, подумал Максим, немногим
      лучше Максима. Но и Максим не лучше Ройша.</p>

    <p>Гораздо, гораздо хуже: он же сам
      утром четверга ругал того за не скоординированную с гэбней ночную поездку в
      Хащину после звонка из больницы. Распылялся перед Ройшем, сутулым и скрюченным ещё
      больше обычного: вы рехнулись, чем вы думали,
      как бы вы за неё ни волновались, вы должны были сперва известить нас, и хорошо,
      конечно, что всё хорошо закончилось, а ведь могли пропасть и вы…</p>

    <p>Днём четверга Максим рехнулся, думал чем-то не тем, волновался и не известил.
      Пропал — и хоть в результате вернулся, всё равно нельзя сказать, что
      это закончилось хорошо.</p>

    <p>— Уй бля, она же Шухерова дочка,
      — Гуанако тем временем звонко хлопнул себя по лбу. — А я-то и не подумал сразу,
      а он вот с чего!</p>

    <p>Максим вопросительно обернулся.
      Не то что бы его в самом деле так интересовало, что
      именно произошло со студенткой Шухер. Уже очевидно, что ничего хорошего. Но
      когда-то давно, тысячу лет назад, в прошлую субботу Максим пообещал ей
      безопасность — а потом она куда-то испарилась, а Ройш стал ещё сутулей и
      скрюченней обычного.</p>

    <p>Если бы кто-то пообещал
      безопасность Габриэлю, а потом тот куда-то испарился, Максим бы стёр этого
      кого-то в порошок.</p>

    <p>Только безопасность Габриэлю
      обещал сам Максим. То, что его запихнула в Порт Бедроградская гэбня, — целиком
      на совести Максима. То, что он оказался при этом зачумлён, — тоже, но в
      несколько меньшей степени. В конце концов, <i>медицинская
        безопасность</i> не совсем в компетенции Максима, хоть это и не оправдание.</p>

    <p>Интересно, у студентки Шухер тоже
      чума?</p>

    <p>— Погано
      вышло, — прищёлкнул языком Гуанако. — Леший, из головы как-то вылетело, что
      девочка-без-диктофона — Шухерова дочка. Он же на курс меня старше учился, мы же
      с ним оборот твири не поделили, я и не держал в уме, что у него дочка. У
      меня-то детей нет. Идиот, — рассеянно побеседовал с
      собой Гуанако.</p>

    <p>— Начал’ник, ты чё? — покосился
      на него Муля Педаль, лихо вписавшись в сложный
      разворот.</p>

    <p>— Муля, давай без начальников, —
      поперхнулся Гуанако. — Какой я тебе начальник? Я тебе подельник, а ты не дури.</p>

    <p>Максим почти усмехнулся: призрак
      Начальника Колошмы пролетел мимо услужливого, но не слишком осведомлённого Мули
      Педали, а тот и не понял, что не так.</p>

    <p>— Я только хотел это — как это
      говорят? — заметит’, — нашёлся Муля Педаль, — что все вроде давно в курсах, что
      девочку-то папаша и сгубил. Не захотел её в лазарет на лекарство пихат’,
      написал отвод по медицинским, вот она, неприкаянная, и
      добегалас’.</p>

    <p>— Да знаю я, знаю, — Гуанако
      досадливо хмыкнул. — Но всего-то не упомнишь, когда кругом так весело. Вроде и
      с Димой говорили об этом когда-то там в районе Хащины,
      а всё равно у меня Шухер отдельно, а девочка-без-диктофона отдельно. Нда.</p>

    <p>Дима, подумал Максим, мог
      положить студентку Шухер в лазарет против воли её отца. Тот же Поппер к Диме
      прислушивается больше, чем к Шухеру.</p>

    <p>— Пиздец, конечно, но поделом
      Шухеру, — аутично брякнул Гуанако. — А за девочку обидно. Мой батюшка тоже
      любил в мои дела соваться — так я и свалил от него в Порт, как только смог. А
      она аж до третьего курса никуда не свалила, — Гуанако
      таки сфокусировался на Максиме, вспомнил, что всё началось с вопроса, где
      студентка Шухер. — Бедроградская гэбня её прибила. Прям
сразу, во вторник.</p>

    <p>Во вторник, когда Максим накричал
      на студентку Шухер из-за первого тома ПСС Гуанако и не спросил даже, какого
      лешего она не на процедурах.</p>

    <p>— А сегодня и Шухера прибили, —
      продолжил Гуанако. — Только не Бедроградская гэбня, а младшие служащие. И не
      прибили, а так — зашибли. Его Охрович и Краснокаменный
      под замок посадили на конспиративной квартире. Сказали, может раскричаться про
      чуму. Я и не врубился, чего ему кричать, а он, видать, о девочке от кого-то
      услышал. Кто, блядь, додумался слить? — Гуанако сплюнул. — Не подождать было
      как будто, пока всё с политикой утрясётся! Короче, на конспиративную квартиру припёрлись гонцы от Бедроградской гэбни, чего-то хотели от
      Шухера, только мы этого уже не узнаем. Потому что Шухер, блядь, чё-то там
      попытался выкинуть, а они его, прихуев, и зашибли.</p>

    <p>Максим представил Андрония
      Леонидовича Шухера, субтильного медфаковского профессора, оказывающим
      сопротивление людям Бедроградской гэбни, и поморщился. В этом было что-то
      невыносимое, жалкое, нелепое, стыдное.</p>

    <p>— Вот уж не думал, что это скажу,
      но Шухер-то крутой! — заулыбался Гуанако. — Не сдался врагам, помер в бою, не
      дал им, чего они там хотели. Круто. И на пользу Университету, а поначалу всё
      выёбывался. Даже если о девочке думал, а не об Университете, всё равно круто.</p>

    <p>Выглядел Гуанако так, как будто в
      университетских рядах прибыло, а не убыло.</p>

    <p>Максиму не с чего было сожалеть о
      самом Андронии Леонидовиче Шухере, но в голову немедленно прокралась тоскливая
      мысль: вещи студентки Шухер в квартире на Бывшей Конной Дороге.</p>

    <p>Раз Габриэль теперь в Медкорпусе,
      Максиму лучше жить там, а не на Поплеевской. На Поплеевской после всего жить
      нельзя, можно только медленно умирать среди их с Габриэлем книг, кофейных
      чашек, двух печатных машинок на одном длинном столе. Но и в
квартире Максима на Бывшей Конной Дороге не побудешь один — свитера с яркими
брошками по дивану, томик слезливой росской классики, неаккуратные конспекты с
недорисованным профилем Хикеракли и размашистой подписью: «учите Революцию, у
них были ТАКИЕ РУБАШКИ!».</p>

    <p>Всё это стоило бы передать отцу
      студентки Шухер, если она погибла.</p>

    <p>То, что погиб и отец, несколько
      осложняет ситуацию.</p>

    <p>Выкинуть вещи у Максима рука не
      поднимется. Студентка Шухер была домашняя девочка. Для студентки истфака
      недостаточно образованная, зато сумасбродная —
      достаточно сумасбродная как раз для студентки истфака. Она не побоялась помочь
      в большом деле. Она не заслуживает того, чтоб её скромные пожитки полетели в
      мусорное ведро.</p>

    <p>Пойти с ними к Ройшу? Нет,
      спасибо.</p>

    <p>На месте Ройша Максим убил бы
      любого, кто рискнул бы сунуться к нему по такому вопросу. Максим и так почти
      что на месте Ройша, он знает, о чём речь. Не факт только, что он знает Ройша и
      правильно оценивает его гипотетическую реакцию.</p>

    <p>Максим подумал ещё немного,
      проскальзывая взглядом по бесконечным окнам Бедрограда, в которых пока что не
      зажигался свет.</p>

    <p>Воскресенье, раннее утро, Бедроград
      ещё валяется в кровати.</p>

    <p>&nbsp;</p>

    <p>Неделю назад, в воскресенье, в
      кровати валялся Габриэль со своим тогда ещё не диагностированным «сотрясением
      мозга».</p>

    <p>Постанывал и просил не включать
      лишний раз свет.</p>

    <p>Максим тихо и осторожно, как
      только мог, открыл дверь, разделся, выпил первого попавшегося под руку алкоголя
      и остался в другой комнате на диване, чтобы не мешать.</p>

    <p>После бессонной ночи на Революционном, дом 3, в резиденции фаланг, он чувствовал
      себя слишком взбешённым — ложиться к Габриэлю значило бы непременно поругаться.</p>

    <p>Фаланги не хотели рассматривать
      запрос Университетской гэбни, Габриэль не хотел рассматривать причины, по
      которым Максим сначала ударил его, потом отправил в Порт, а потом забрал домой,
      но бросил там в одиночестве на целую ночь.</p>

    <p>К полудню Максим паршиво
      выспался, позвонил на кафедру Ларию, поинтересовался положением дел и снова поехал
      на Революционный проспект.</p>

    <p>Вечером Габриэль выглядел ещё
      хуже, но уже бродил по дому, бесконечно заваривая кофе.</p>

    <p>Вечером (почти ночью) позвонил с
      кафедры Ларий (почти закричал): «Дима говорит, что заражать могли не только дом
      Ройша!»</p>

    <p>«С чего Дима это взял?» — хмуро
      отозвался Максим, перед носом у которого Габриэль не донёс до стола свой
      проклятый кофе.</p>

    <p>«Я не знаю, чем он занимался весь
      день, — тараторил Ларий. — У Святотатыча я его по телефону в обед не нашёл, но
      только что он отзвонился сам, сказал, к Шапке в Столицу ездил Гошка, Шапка
      подозревает, что масштабы заражения будут серьёзнее, чем мы рассчитывали».</p>

    <p>Максим задумался, насколько
      вообще можно полагаться на чьи-то предположения.</p>

    <p>Но, когда он сам связался той
      ночью с Димой, уверенность последнего в выводах Шапки убедила-таки Максима, что
      стоит хвататься за голову и спешно искать способы борьбы с настоящей эпидемией.</p>

    <p>Как выяснилось уже скоро —
      действительно стоило.</p>

    <p>&nbsp;</p>

    <p>Максим оторвался от окна и достал
      сигареты.</p>

    <p>Всего неделю назад, в такое же
      сонное бедроградское воскресенье, чума только начинала набирать обороты.</p>

    <p>Габриэль ещё был в квартире на
      Поплеевской, студентка Шухер — в квартире Максима на Бывшей Конной Дороге.</p>

    <p>Максим понял: как освободится от
      гуанаковских дел — надо найти Диму.</p>

    <p>Он больше всех, не считая Ройша,
      общался со студенткой Шухер, он тоже причастен к тому, что она погибла, он
      лучше Максима разберётся, как следует поступить с её вещами.</p>

    <p>Надо найти Диму сегодня же.
      Определённо.</p>

    <p>— Тебе ствол оставили? —
      завозился на заднем сиденье Гуанако.</p>

    <p>Максим вытряхнул из головы всё
      лишнее.</p>

    <p>Ствол — пистолет — табельное
      оружие с выгравированным личным номером.</p>

    <p>— Нет, конечно, — ответил Максим.
      — Он у Охровича и Краснокаменного с самой пятницы. Гэбня временно отстранена, я
      к гэбне и вовсе отношения не имею. Ходить при табельном не положено.</p>

    <p>— Как и использовать его в
      портовых делах, — удовлетворённо кивнул Гуанако. — Хорошо, что твой ствол не
      при тебе.</p>

    <p>— Никто б ему сейчас ствол не
      доверил, — подал голос Муля Педаль.</p>

    <p>Гуанако аж
      замялся, но потом захохотал, поскольку прозвучало это как раз тогда, когда он
      протягивал Максиму пистолет. Не табельный, с каким-то немного другим корпусом —
      Максим не то что бы хорошо разбирался в видах и родах пистолетов, поскольку во Всероссийском
      Соседстве таковых нет и быть не может. Табельные не
      считаются.</p>

    <p>— Если человека в моей ситуации
      задержат с нелегальным оружием… — начал Максим, прекрасно понимая, что Гуанако
      не остановят соображения здравого смысла.</p>

    <p>Гуанако хочет дать ему пистолет и
      даст.</p>

    <p>Именно сегодня, именно сейчас.</p>

    <p>Это какой-то совсем уж
      мучительный, невыносимый, жуткий символизм.</p>

    <p>Гуанако хочет. Он молодец, у него
      всегда получается именно то, чего он хочет. С Максимом-то точно — сколько раз
      уже проверяли на практике.</p>

    <p>— Вы уверены? — Максим дрогнул
      голосом, сбился вдруг на студенческое «вы» в адрес преподавателя.</p>

    <p>Гуанако посмотрел на него с тем
      самым искренним выражением, за которое его иногда хотелось пристрелить на
      месте. Искренность, честность, дружелюбие, все вокруг —
хорошие парни, буквально все, никаких проблем!</p>

    <p>— Сергей Корнеевич… — третий раз
      попытался Максим.</p>

    <p>Попытался что? Отказаться,
      объясниться, спихнуть ответственность?</p>

    <p>— Не выкай, а, — растерянно
      ответил не на то, о чём его спрашивали, Гуанако. — Большой мальчик давно. Ты
      боишься, что ли?</p>

    <p>Да, подумал Максим.</p>

    <p>Да, хотел закричать Максим.</p>

    <p>Растерянная искренность,
      честность и дружелюбие Гуанако запихнули Максиму его «да» очень, очень глубоко.</p>

    <p>— Что делать-то нужно? — как
      можно хладнокровнее осведомился он и наконец взял
      несчастное незарегистрированное оружие.</p>

    <p>Взвесил в ладони — габариты
      привычные, пойдёт.</p>

    <p>— Нормально? — подлил масла в
      огонь Гуанако. — Со стволами всё сложно даже в Порту, но я
подумал, чем ближе к вашему табельному, тем удобней.</p>

    <p>Искренний, честный и дружелюбный
      Гуанако позаботился даже о привычных габаритах.</p>

    <p>Максим через силу благодарно
      улыбнулся.</p>

    <p>Гуанако потёр лоб, проглотил пару
      слов, как будто собирался сейчас извиняться.</p>

    <p>— Насчёт
      что делать, — вздохнув, таки заговорил он. — Я знаю, тебе может это не
      понравиться. В смысле, тебе может не понравиться не что, а зачем. Но ты не
      отказывайся сразу, ладно?</p>

    <p>Максим кивнул как можно более
      миролюбиво.</p>

    <p>Он уже достаточно сделал того,
      что нравится ему, принципиально не глядя по сторонам, чтобы теперь не поумнеть.</p>

    <p>— В Порту нынче не очень-то
      жалуют что Университет, что тебя лично, — всё тем же извиняющимся тоном
      продолжил Гуанако. — Это для всех дерьмово, с этим надо разобраться. И я
      подумал…</p>

    <p>Гуанако — хороший парень,
      вспомнил Максим, Гуанако подумал про всё и про всех, как всегда. Часто его «про
      всех» означает «<i>за</i> всех», но это не
      такая большая беда, как казалось ещё пару дней назад.</p>

    <p>Даже про привычные габариты
      подумал.</p>

    <p>&nbsp;</p>

    <p>А потом Максим полчаса просидел в
      кустах с пистолетом, и ничего не произошло.</p>

    <p>«На шухере», — безвкусно
      посмеялся Гуанако, указывая на живую изгородь, за которой желтел пустырь перед
      каким-то складом на окраине города.</p>

    <p>Удобное место: завод по ту
      сторону шоссе, какие-то гаражи и собственно склад.</p>

    <p>Гуанако с Мулей Педалью
      подобрались к складу сзади, поскольку спереди, у ворот, грелась на неожиданном
      после стольких дождливых дней солнце малочисленная охрана.</p>

    <p>Максиму из его кустов было видно
      и ворота, и незваных гостей, когда те довольно комично обходили разомлевшую
      охрану с тыла.</p>

    <p>Если бы он хотел, он мог бы
      полчаса назад застрелить Гуанако, который, не задумавшись ни на секунду,
      повернулся к Максиму спиной и двинулся к складу по отлично простреливаемому
      из кустов пустырю.</p>

    <p>Он позвал Максима, он уговорил
      Охровича и Краснокаменного выпустить Максима как раз для того, чтобы дать ему
      пистолет и повернуться спиной.</p>

    <p>Он так верит в Максима, что у
      Максима пересыхает в горле, когда надо что-то сказать.</p>

    <p>Пока сидишь в кустах, по крайней
      мере, говорить ничего не надо.</p>

    <p>Следить за охраной, если что —
      стрелять её к лешему, сигналить.</p>

    <p>Этот склад совсем небольшой;
      Гуанако объяснил, что там внутри всего одно помещение, будка для охраны снаружи
      — очень удобно.</p>

    <p>На этом складе Гуанако вчера встречался
      с одним из голов Бедроградской гэбни, на этом же складе сегодня ночью тоже
      состоится некая встреча, относительно которой ничего внятного Гуанако выдавить
      из себя не смог.</p>

    <p>На этом складе может оказаться
      новейшего образца оборудование для записи и воспроизведения изображения. Вчера
      было, а с учётом назначенной на сегодня встречи его вряд ли стали бы
      демонтировать и уносить.</p>

    <p>А если так, его демонтирует и
      унесёт Гуанако.</p>

    <p>Подобное оборудование — пока что
      редкость. Его собирают инженеры Бедроградской гэбни, оно не валяется на дороге
      просто так. Фаланг они осчастливили чудом техники, Медицинскую
      гэбню по старой дружбе — тоже.</p>

    <p>А с Медицинской гэбней водит
      давнюю дружбу и Юр Карлович Поппер, заведующий кафедрой вирусологии. Он с
      кем-то из её голов учился на одном курсе, поэтому пару лет назад медфаку редкое
      оборудование всё же перепало. Для записи экспериментов или чего-то в этом духе.
      Когда встал вопрос, как доказывать готовящееся незаконное проникновение в
      подвал дома Ройша, Университетская гэбня о медфаковской
      аппаратуре вспомнила и использовала её в не слишком научных целях. Повезло, что
      аппаратура была, хоть фаланги и проигнорировали потом все доказательства.</p>

    <p>У Порта же такой аппаратуры,
      кажется, всё ещё нет.</p>

    <p>Если её раздобыть и с помпой
      презентовать Порту, есть шансы если не загладить свою вину, то
      как минимум поправить репутацию Университета.</p>

    <p>Поправить репутацию Максима.</p>

    <p>И всё уже заготовлено: условия
      для помпы, план ограбления, такси с водителем-тавром прямо до места. Максиму
      нужно только чуть-чуть посидеть в кустах с пистолетом для того, чтобы можно
      было сделать вид, будто он имеет ко всему этому какое-то отношение.</p>

    <p>Удивительным образом это даже не
      бесило Максима. Только горло продолжало пересыхать: к нему вот так, а он…</p>

    <p>Через полчаса сидения над крышей склада
      нарисовалась голова Гуанако. Посмотрела в сторону кустов, не увидела условных
      обозначений тревоги, скользнула на секунду обратно, чтобы совсем скоро Гуанако
      оказался на крыше целиком, а не только вращающейся головой.</p>

    <p>Всё это напоминало какую-то комическую
      пьесу с небогатой, но выразительной сценографией: Максим со своего места
      созерцал одновременно ничего не подозревающую охрану и тихого, гротескно
      аккуратного Гуанако, который передвигался по крыше буквально на цыпочках. В
      руках у него была толстая верёвка, почти канат, и прямо сейчас он искал, к чему
      бы привязать конец этого каната.</p>

    <p>На вчерашней встрече с головой
      Бедроградской гэбни Гуанако разглядел, что дополнительных входов на склад нет,
      поэтому охрана и прохлаждается в одном и том же месте. Зато на потолке, среди
      металлических балок, обнаружился люк. С замком, но замки для портовых отмычек
      не проблема, да и охрана не проблема, раз можно гулять у неё над головой.</p>

    <p>Гуанако, зафиксировав свой канат,
      подал какой-то знак в отверстие люка и начал медленно вытягивать прикреплённый груз.
      Медленно, очень медленно, плавно переставляя по канату подрагивающие от
      напряжения руки.</p>

    <p>Максиму, косившемуся одним глазом
      на охрану, не к месту вспомнилось: Гуанако умеет вязать узлы, он же портовый,
      но в мае один узел вышел у него недостаточно крепким.</p>

    <p>&nbsp;</p>

    <p>Максиму рассказали Охрович и
      Краснокаменный.</p>

    <p>Охрович и Краснокаменный часто
      преувеличивают или извращают факты, но врать от начала и до конца просто так
      они не станут.</p>

    <p>В мае Гуанако пытался вешаться,
      но верёвка — как в комической пьесе — оборвалась.</p>

    <p>История проста, с ней давно пора
      смириться. Некоторую её часть Максим видел своими глазами.</p>

    <p>В начале мая Гуанако и Дима
      вернулись в Бедроград живыми. Развлекали всех желающих байками, ходили по
      гостям, опустошали запасы алкоголя у обрадованных хозяев.</p>

    <p>В дом-башню на Поплеевской они,
      разумеется, тоже зашли.</p>

    <p>Разумеется, это была уже не
      первая встреча Максима и Габриэля с мертвецами — до этого была кафедра, было
      что-то ещё. Разумеется, Гуанако с хохотом всучил Габриэлю охапку роз невозможного
      зелёного цвета, таких, как когда-то давно, когда Габриэль заканчивал последний
      курс, а Максим — всего только первый. Разумеется, и Максим, и Дима смотреть на
      эти зелёные розы не могли, но, разумеется, тоже натужно смеялись: старые добрые
      времена, старая добрая шутка. Разумеется, сначала всё было нормально, зато
      потом Габриэль оказался с Гуанако в кухне один на один, и они, разумеется,
      поговорили.</p>

    <p>Разумеется, Максим и Дима
      подслушивали.</p>

    <p>Не договариваясь, но и не
      специально. Просто вышли из комнаты в коридор, дошли до полуприкрытых дверей и
      остановились в неловком молчании.</p>

    <p>В какой-то момент, не
      договариваясь, вышли из квартиры вон.</p>

    <p>Максим поехал на Бывшую Конную
      Дорогу, Дима (через пару дней, выяснив-таки отношения с Гуанако) — в Столицу.</p>

    <p>А Габриэль и Гуанако поехали в
      дружественное Ирландское Соседство. На неделю или две, уже и не вспомнить.</p>

    <p>Габриэль бросил завкафское кресло
      в разгар конца учебного года, студенты шептались и даже осмеливались задавать
      Максиму вопросы. Одного Максим чуть не отчислил за эти вопросы, но Ларий
      благоразумно припрятал в секретарском столе документы.</p>

    <p>К сессии Габриэль вернулся.
      Ничего не объяснял, но выглядел так, что было без слов понятно, что между
      зачётами он ночует не с Гуанако.</p>

    <p>В какой-то из зачётных дней на
      кафедру прибежали весёлые Охрович и Краснокаменный, загалдели и вытряхнули на
      Максима детали. Габриэль сказал: «Наигрались», Гуанако сказал: «Лады, никаких
      проблем» — и с улыбкой пошёл вешаться в тиши своей портовой жилплощади.</p>

    <p>Только верёвка оборвалась, и
      пришлось Гуанако до самого сентября плавать по дальним странам на Курёхине,
      переправлять контрабанду и жить дальше.</p>

    <p>Два раза подряд не вешаются,
      оборвалась — так оборвалась.</p>

    <p>&nbsp;</p>

    <p>Максим следил из кустов за руками
      Гуанако и очень надеялся, что <i>эта</i> верёвка выдержит.</p>

    <p>Когда на крышу таки была поднята
      сеть, гружёная редкой аппаратурой, которую
      непредусмотрительно оставила на складе до сегодняшней ночи Бедроградская гэбня,
      Максим даже выдохнул с облегчением.</p>

    <p>Ему вообще-то было плевать,
      удастся ограбление или нет, — меньше всего Максима сегодня волновала
      собственная репутация в Порту. Но когда смотришь из кустов на аккуратные
      движения рук по верёвке, невольно начинаешь хотеть, чтобы всё получилось.</p>

    <p>Вслед за сеткой на крышу вылез
      Муля Педаль. Закрыл за собой, склонился над люком на пару минут (поковырялся в
      замке отмычкой?). Потом выпрямился и, судя по позе, шепнул Гуанако что-то
      крайне самодовольное. Обернулся в сторону Максимова укрытия, удостоверился в отсутствии сигналов
      тревоги и на цыпочках побежал к тому краю крыши, который находился подальше от
      охраны.</p>

    <p>Охрана (три человека из младших
      служащих) вяло переговаривалась, прислушиваясь не к шагам над головой, а к
      звуку радиоприёмника, транслирующего всероссийские новости из приоткрытой
      будки.</p>

    <p>Максим опять подумал о
      контрреволюционном движении: да кому оно было нужно, если большая часть
      добропорядочных граждан с таким интересом слушают государственный новостной
      радиоканал? Даже на работе, на посту — так, что можно планировать ограбление на
      время выпуска новостей, чтоб никто ничего не заметил.</p>

    <p>Муля Педаль тем временем с
      неожиданной для его таврской комплекции грацией спрыгнул с какого-то уступа на
      стене. Приземлился мягко, охрана с другой стороны склада и не шелохнулась.</p>

    <p>Гуанако с сетью награбленного в
      руках подплыл к всё тому же
      краю крыши, глянул вниз, снова привязал к чему-то канатный конец, присел на
      корточки и снова медленно, очень медленно и напряжённо стал перебирать руками
      по канату, только теперь, наоборот, спуская груз. Периметр крыши был больше
      периметра основания склада, и зазор между канатом и стеной оказался достаточным
      для того, чтобы груз не бился, если не раскачивать конструкцию.</p>

    <p>Муля Педаль таращился наверх, готовый принять сеть в свои объятия.</p>

    <p>Всё это продолжало выглядеть
      чрезвычайно комично. Максим смотрел против солнца — так фигуры и охраны, и
      грабителей выглядели даже не актёрами на сцене, а вырезанными из тёмной бумаги
      силуэтами, совершающими свои смешные манипуляции на подсвеченном бумажном же
      экране.</p>

    <p>Дети младшего отрядского возраста
      были бы в восторге.</p>

    <p>Максим, не убирая руки с пистолета,
      размышлял, смог бы или нет Гуанако сейчас так ловко
управляться с верёвкой, если бы в конце мая после неудавшегося повешения
      не пошёл матросом на Курёхина.</p>

    <p>&nbsp;</p>

    <p>— Эк мы их! — погладил Муля
      Педаль сеть с награбленным, покоящуюся на переднем
      сиденье.</p>

    <p>Максим сидел сзади, чтобы удобнее
      было высматривать возможную слежку. Такси неспешно петляло по мощёным улочкам
      Университетского района.</p>

    <p>Гуанако Муля Педаль докинул до
      медфака, поскольку тот сослался на необходимость переговорить о чём-то с
      Поппером. Максим понимал: Гуанако просто самоустраняется из процесса передачи
      награбленного Порту, чтобы всё внимание досталось ему, Максиму.</p>

    <p>Максим не стал спорить. Пусть
      Гуанако порадуется воплощению своих планов. Он пока не знает, что любые планы
      всё равно бессмысленны, а проблемы с репутацией Максима сегодня точно не
      закончатся.</p>

    <p>Потому что иногда надо просто
      смириться: не лезть управлять тем, с чем не можешь управиться, и сделать один
      маленький, но важный шаг.</p>

    <p>Ведь один шаг может быть не менее
      важным, чем попытки управления большим и неподъёмным, попытки всё учесть, всё
      просчитать и сделать всем хорошо.</p>

    <p>Довольный
      Муля Педаль включил музыку, и это снова оказалась Кармина Бурана. Как в прошлую
      субботу, когда Максим забирал из Порта Габриэля и студентку Шухер. Как во многие
      другие дни, которые лежали за пределами чумы в Бедрограде, а потому
      воспринимались теперь не совсем реальными.</p>

    <p>— Чего лицо такое? — агрессивно
      поинтересовался Муля Педаль. — Симфонической музыки, что л’,
      не любишь?</p>

    <p>Максим улыбнулся и даже почему-то
      не дёрнулся в этот раз от обращения на ты.</p>

    <p>— Наоборот, — примирительно
      ответил он. — Но давно хочу спросить: почему всегда Кармина Бурана?</p>

    <p>— Не всегда, а просто часто, —
      Муля Педаль несколько смутился. — Хорошая она, величественная…
Как это говорят? Духопод’ёмная, во!</p>

    <p>Поднятие духа сейчас очень
      кстати, подумал Максим и вслушался в величественные, духоподъёмные, но такие
      зловещие звуки.</p>

    <p>Как будто говоришь с судьбой, а
      она почему-то откровенничает, рассказывает тебе вот так запросто, кому жить, а
      кому умирать.</p>

    <p>— Ну и байка же крутая, — подумав,
      добавил Муля Педаль. — Ты-то небос’ не слышал, сплетни в университетах не
      преподают.</p>

    <p>Именно сплетни и преподают,
      подумал Максим, особенно на истфаке.</p>

    <p>— Наверное, не слышал, — смиренно
      согласился он вслух.</p>

    <p>— Ноты же у нас в Порту откопали
      незадолго до Революции, — начал с общеизвестного Муля
      Педаль. — С подпис’ю Карл Ёрф. Прощелыга небос’ какой.</p>

    <p>Максим не соврал, он
      действительно немало понимал в симфонической музыке.</p>

    <p>Карл Ёрф, предполагаемый
      компилятор Кармины Бураны, возможно, действительно подставное лицо, псевдоним.
      Какой-то европейский авантюрист, чёрный археолог с музыкальной подготовкой,
      который занимался разграблением того, что осталось от имперских городов. Вряд
      ли человек такой породы, даже совершивший открытие благодаря своим нелегальным
      занятиям, стал бы подписывать это открытие своим именем.</p>

    <p>Поэтому Муля Педаль прав — прощелыга.</p>

    <p>Удачливый прощелыга,
      нашедший среди осколков и черепков крепостей двенадцатого века сборник народных
      имперских песен и несколько серьёзных религиозных арий. Оригинальные
      манускрипты (или что это было?) так и не обнаружены: очевидно, Карл Ёрф оставил
      их себе и растворился в неизвестности. Наверняка нечаянно погиб, как и бывает в
      конце концов с каждым чёрным археологом.</p>

    <p>Мировой культуре достались только
      его собственные бумаги — задрипанные нотные листы с
      короткими едкими комментариями, из коих следовало, что музыкальная грамотность
      некого Карла Ёрфа была высока (а грамотность языковая, кстати, не слишком —
      комментарии содержат изрядное количество орфографических ошибок). Он расположил
      песни и арии в определённом порядке, сделав из разрозненного материала
      целостное произведение. Подлинность текстов сомнений не вызывает, но
      относительно музыки единого мнения до сих пор нет. Карл Ёрф подробно расписал
      партии для современных ему инструментов, заменив ими по своему разумению
      утерянные имперские. В европейской симфонической обработке искажений
      относительно оригинального звучания не может не быть. Насколько они велики и
      как много Карл Ёрф, увлёкшись, дописал от себя, спорят по сей
      день.</p>

    <p>Но так или иначе, нотные листы с
      заглавием «Кармина Бурана» и посвящением (по-британски, с ошибками): «Моему
      дражайшему и нежнейшему другу, который так мечтал оказаться на вершине колеса
      музыкальной фортуны» — не остались в забвении.</p>

    <p>Их нашли в
Петербержском Порту (где же ещё) за несколько лет до Революции, их пытался
играть Петербержский Симфонический Оркестр, а уже скоро их музыка зазвучала из
радиоприёмников Всероссийского Соседства (настоял Набедренных, концерты Петербержского
Симфонического когда-то регулярно посещавший).</p>

    <p>— Когда она до Британии дошла с
      гастролями, случилас’ байка, — заговорщицки подмигнул Муля Педаль. — В оркестре
      Королевской Консерватории ёбнулся пианист. Свихнулся, в смысле. Стал доказыват’
      этим — как это говорят? — критикам, что Кармина Бурана — его, а не
      карло-ёрфовая и не имперская. Типа он написал, типа не
      раскопки всяких там крепостей, а вот прям музыка своего сочинения!</p>

    <p>Если Максим об этом даже не
      слышал, значит, совсем неубедительно доказывал. Вопросы достоверности имеющихся
      объектов имперской культуры входят в круг научной компетенции Максима.</p>

    <p>— Пианист в психушку
      попал, — будто признаваясь в чём-то стыдном, Муля Педаль заговорил совсем
      другим голосом. — Насовсем, там и подох через много
      лет. А пока не подох, всё твердил и твердил, что
      Кармина Бурана — его. Я когда её слушаю, об этом думаю. Может, правда его?</p>

    <p>— Исключено, — со
      вздохом ответил Максим. — Я не эксперт, но…</p>

    <p>— Все так говорили, а он с ума
      сошёл! — Муля Педаль выпятил губу с таким видом, как будто
      сумасшествие может быть аргументом.</p>

    <p>Максим едет в Порт с пистолетом,
      который ему дал Гуанако, и по дороге ведёт с портовым тавром дискуссии о
      Кармине Буране.</p>

    <p>Это казалось сумасшествием не
      меньшим, чем приписывать себе сочинение имперских народных песен и религиозных
      арий.</p>

    <p>— В любом случае, это
      удивительная музыка, — покладисто резюмировал Максим. — Настолько удивительная, что
      сойти с ума немудрено.</p>

    <p>Муля Педаль, кажется,
      относительно удовлетворился такой реакцией. В драматическом молчании он
      вывернул с улочек Университетского района, принуждающих к медлительности, и
      нормальной асфальтированной дорогой погнал к Порту.</p>

    <p>— Она же про нас, — со значением
      брякнул он под конец второй арии. — Про фортуну и про суд’бу, про то, что
      никогда не знаешь заранее, где тебе подфартит…</p>

    <p>— …а где удача от тебя
      отвернётся, а судьба обманет, — сумрачно добавил Максим, и не думая смеяться
      над тягой к символизму портового тавра Мули Педали.</p>

    <p>Тяга к символизму, подумал Максим
      и почувствовал пьянящую тяжесть пистолета на бедре.</p>

    <p>— Ты неправ, — с едва ли не
      гуанаковской улыбкой отмахнулся Муля Педаль. — Она про то, что иногда мы
      оказываемся в дерьмище, в большом дерьмище, но это
      тоже правильно, это суд’ба такая, поворот колеса. Провернулос’ — и хруст’ тебе
      по косточкам! Но вес’ остальной-то мир продолжает пит’
      и трахат’ся, продолжает — как это говорят? — любит’ друг друга, покуда ещё
      кого-нибуд’ колесом не придавило. Так и надо, чё.</p>

    <p>Максим сглотнул.</p>

    <p>Пить и трахаться,
      любить друг друга, покуда гигантское, чудовищное колесо неведомого механизма не
      подкатится близко-близко, не заслонит тебе солнце. Не качнётся угрожающе в
      качестве предупреждения.</p>

    <p>Но с предупреждением или без —
      всё равно догонит, раздавит, расплющит.</p>

    <p>Расплющит и не посмотрит, сколько
      ты пил и трахался, сколько любил. Мало — сам виноват,
      спрашивай с себя. Колесу наплевать, оно может только катиться и расплющивать.</p>

    <p>Как расплющило Габриэля и
      Максима.</p>

    <p>Как расплющили (колёса такси
      Бедроградской гэбни, Гуанако по пути со склада до медфака добавил подробностей)
      студентку Шухер, рисовавшую в конспектах профили членов Революционного
      Комитета.</p>

    <p>Надо, надо найти Диму.</p>

    <p>— Стёкла чут’ опусти и пушку вын’,
      — подобрался вдруг Муля Педаль. — Скоро через пост попрём,
      там толпы бурлят. Если за ворота побегут, это не наша морока, а постовых. Но могут бросит’ся на такси. Бросятся — в затылке долго не
      чеши, по ногам стреляй. Только совсем не мочи, если сможешь.</p>

    <p>Если сможешь, подумал Максим.</p>

    <p>Университетская
      гэбня, в которой он больше не числится, для гэбни имеет всё же не самый
      тривиальный круг обязанностей. Вооружённые действия в эти обязанности
      непосредственно не входят. А когда входят, их берут на себя Охрович и
      Краснокаменный, так сложилось.</p>

    <p>Во времена контрреволюционного
      движения Максиму доводилось всякое, но «совсем мочить» — всё-таки нет. Не
      потому что он не хотел, имел предубеждения или что-то ещё, а просто
      потому что не доводилось.</p>

    <p>Когда-то всё доводится делать в
      первый раз.</p>

    <p>Так почему бы и не на въезде в
      Порт?</p>

    <p>— Про тёрки у Святотатыча ты всё
      услышал? — Муля Педаль деловито ещё раз оглядел сеть с награбленным.
      — Удивис’, да поубедительней!</p>

    <p>Максим всё понял, Гуанако хорошо
      объяснял. Он всегда хорошо объяснял, когда-то послушать его рядовую лекцию
      собирались огромные аудитории.</p>

    <p>Сегодняшняя лекция была о
      важности общественного мнения для большой политики.</p>

    <p>Святотатыч к моменту приезда
      Максима с редкой аппаратурой должен собрать у себя (под предлогом блокады)
      совет самых значимых портовых фигур. Контрабандистов и наркоторговцев,
      сутенёров и убийц. Всех — самого высокого полёта.</p>

    <p>Не прямо у себя в каморке,
      разумеется, но всё в том же скромном кирпичном домике — на первом этаже, обычно
      занятом татуировщиками, к труду которых Святотатыч почему-то особенно
      неравнодушен.</p>

    <p>Поэтому появление Максима и Мули
      Педали с награбленным посреди
      совета не вызовет подозрений: они придут за Святотатычем к Святотатычу, они-то
      сами не того полёта птицы, чтобы знать, что на это время назначено такое
      серьёзное мероприятие.</p>

    <p>На мероприятии Максиму положено
      скромно и потупив глаза передать из рук в руки сеть с награбленным.
      Донести до общественности, что это такое и откуда оно взялось, — задача
      Святотатыча, а Максим может отправляться на все четыре стороны.</p>

    <p>Гуанако дал ему пистолет, не
      побоялся подставить спину и не побоится отправить на все четыре стороны, когда
      дело будет сделано.</p>

    <p>Муля Педаль, завидев шлагбаум
      цветов портовых тельняшек, выключил Кармину Бурану.</p>

    <p>Колесо судьбы покачнулось и
      замерло, пока что катились только колёса такси.</p>

    <p>К Святотатычу — и на все четыре
      стороны, Муля Педаль подвезёт.</p>

    <p>Только дел на всех четырёх
      сторонах у Максима почти не осталось. Он больше не голова гэбни, он больше даже
      не университетский преподаватель. Чумой занимаются не потерявшие мест
      представители власти, Габриэлем — Медкорпус, а потому Максим может сделать не
      так уж много.</p>

    <p>Правы Охрович и Краснокаменный:
      он был глуп, когда пытался взвалить на себя всё сразу. Иногда нужно просто
      смириться и ограничиться малым, взгляд <i>с
        другой стороны</i> отрезвляет и отлично
      помогает это осознать.</p>

    <p>Ограничиться малым.</p>

    <p>Вспомнить о вещах студентки Шухер,
      найти Диму, спросить: «Могу я с тобой поговорить?»</p>
  </>
}

export default chapter32
