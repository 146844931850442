import React from 'react'
import { IBookPage } from './index'

const chapter36: IBookPage = {
  header: 'header_d9.jpg',
  title: 'Глава 36. Снились трещины',
  prevLink: 'chapter35',
  nextLink: 'chapter37',
  content: <>
    <h2>Бедроградская гэбня. Андрей</h2>

    <img alt='' src="/images/36_andrei.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>Ох уж эти вечные «но», способные
      всё испортить!</p>

    <p>Андрей побарабанил пальцами по
      стеклу в ожидании секретаря с целой папкой сплошных «но», заверенных печатями.</p>

    <p>— Ты б закруглялся, — вошёл в
      общий кабинет Бедроградской гэбни Бахта. — Даже мундира ещё не надел, а уже
      время.</p>

    <p>— Какое время? — чуть раздражённо
      обернулся к нему Андрей. — Встреча назначена ночью. После полуночи. Не самое
      чёткое указание, можно и не бежать сломя голову.</p>

    <p>— Стоит приехать пораньше, —
      настаивал Бахта.</p>

    <p>— У меня ещё остались дела.</p>

    <p>— Эти? — Бахта протянул Андрею
      папку, на корешке которой чернел код инфекционных лабораторий при Бедроградской
      гэбне.</p>

    <p>— Спасибо, — заулыбался Андрей.</p>

    <p>Искренне заулыбался — кто бы что о нём ни думал, он способен на настоящее,
      неподдельное
      дружелюбие. По крайней мере по отношению к своей
      гэбне. Ведь ничего нет приятнее знания, что твои дела — не только твои, что
      остальные помнят о них, готовы помочь. Пусть даже в такой мелочи, как
      транспортировка папки с анализами от лаборатории до кабинета. Не пришлось
      ворковать с секретарями — уже облегчение.</p>

    <p>Улыбка сползла с лица Андрея,
      стоило ему бегло ознакомиться с содержимым папки.</p>

    <p>— Опять дерьмо?
      — закурил Бахта, продолжая переминаться с ноги на ногу от нетерпения.</p>

    <p>Ему бы только сорваться уже к
      «реальной университетской власти», ни о чём не может думать, упускает столько
      важных деталей!</p>

    <p>Андрей фыркнул. От своей гэбни не
      хочется скрывать ничего — ни благодарности, ни недовольства, ни даже злости. Своя гэбня — это ж не секретари какие-нибудь.</p>

    <p>— Вы трое, — сверкнул он глазами
      на Бахту, нисколько не смущаясь отсутствием в кабинете Соция и Гошки, — ведёте
      себя так, будто на этой встрече свет клином сошёлся! Даже если мы их там и
      перестреляем к лешему, это не значит, что после последнего выстрела из
      радиоприёмника понесётся музыка и «роли озвучивали», а потом начнётся выпуск
      новостей. Нам надо понимать, с чем мы останемся хотя бы к завтрашнему утру, не
      говоря уже о дальнейших перспективах.</p>

    <p>— С юбилеем Первого
      Большого Переворота мы останемся, — вздохнул Бахта. — Сейчас как раз закончили
      с Гошкой убиваться над телефоном. Без нашего личного
      присутствия там как будто и коня седлать не могут! Какой-то промышленный
      альпинист, который лозунг в кроне Первого Большого
      Перевернутого размещал, наебнулся оттуда. Голова закружилась. Врачи говорят,
      вышел на работу с тяжёлым ОРЗ.</p>

    <p>— ОРЗ, прекрасно, — Андрей даже
      не стал искать свои сигареты, забрал уже прикуренную
      прямо из рук Бахты. — Тяжёлое ОРЗ среди техперсонала, обслуживающего завтрашний
      юбилей. Леший, леший!</p>

    <p>— Расслабься, — махнул рукой
      Бахта. — Гошка поорал на каждого тамошнего начальника по очереди, заставил
      полностью заменить команду прямо сейчас. Якобы новой формы ОРЗ боимся. Ну, как медфаковские
      детишки в поликлиниках врали, чтоб там чуму ловить. Перенимаем опыт
      Университета! — Бахта удовлетворённо хмыкнул. — Короче, всех этих с ОРЗ через
      часик-два в твои лаборатории и подвезут.</p>

    <p>Андрей снова отвернулся к
      переливавшемуся предпраздничными огнями окну.</p>

    <p>Сам юбилей под Бедроградом, но
      гости-то обосновались в городе, поэтому город тоже был украшен, в городе уже
      сегодня проводились какие-то мероприятия. Кто этим занимался, когда на самом-то
      деле всех волнует эпидемия? Видимо, Бахта. У него на долбаные
      праздники рука давно набита, дольше всех в Бедроградской гэбне служит.</p>

    <p>А Андрей ещё ругался тут на
      безделье. Стыдно.</p>

    <p>Надо как-то успокоиться перед
      встречей, а то настроение так и скачет лихорадочно: от благодарности к
      бешенству и обратно за полминуты.</p>

    <p>— Бахта, не справляются мои
      лаборатории, — тихо-тихо произнёс Андрей. — Я не понимаю, в чём дело.</p>

    <p>— Какой сейчас
процент летальных исходов среди получивших лекарство? — с порога
поинтересовался Соций, пока Бахта подбирал слова ободрения.</p>

    <p>Соций тоже уже был при мундире и
      наплечнике. Готов выезжать, хмурит брови.</p>

    <p>— Четверть! — Андрей будто со
      стороны услышал, как дрогнул его собственный голос. — Не четверть процента,
      само собой. Четверть получивших лекарство — трупы, — и
      посмотрел на Бахту и Соция самыми искренними за всю свою жизнь показательно
      испуганными глазами.</p>

    <p>Перед собственно трупами Андрей
      не испытывал ни страха, ни угрызений совести. Люди умирают. Люди иногда умирают
      по вине тех, кто должен — сообразно своим служебным инструкциям — за них
      отвечать. Ну и что. Служебные инструкции всегда сложнее, чем кажутся, — и это
      не только давным-давно заготовленная отговорка для фаланг. Андрей действительно
      полагал, что безопасность <i>гражданских вообще</i> всегда важнее безопасности <i>конкретных
        гражданских</i>.</p>

    <p>Кому-то надо умирать, чтоб
      остальные могли жить спокойно.</p>

    <p>Показательно испуганные глаза
      испуганы искренне не поэтому.</p>

    <p>Совсем не поэтому.</p>

    <p>Бедроградская гэбня запустила
      эпидемию. Контролируемую. Контролируемая эпидемия — эпидемия болезни, от
      которой есть лекарство. На самом деле есть, без оговорок и погрешностей. Любых
      погрешностей, о четверти летальных исходов и речи быть не может.</p>

    <p>Отсутствие гарантий.</p>

    <p>В такой работе, как у
      Бедроградской гэбни, отсутствие гарантий — это конец.</p>

    <p>И если взять себя в руки, опыт
      подсказывает: выпутаться можно из почти любой ситуации. Есть способы, есть
      отработанные приёмы, есть совсем уж экстренные лазейки, есть — но <i>нельзя
        доводить до того, чтобы они в
        принципе шли в ход.</i></p>

    <p>Андрею было страшно.</p>

    <p>— Дай глянуть, — потянулся за
      папкой с анализами Соций. — Четверть, говоришь?</p>

    <p>Соций не имеет специальной
      медицинской подготовки, никто в Бедроградской гэбне её не имеет — кроме самого
      Андрея. Но все в Бедроградской гэбне имеют мозги, а девять дней чумы и годы
      планирования этих девяти дней волей-неволей заставят любые мозги научиться хоть что-то понимать в
      бумажках из лабораторий.</p>

    <p>Андрей отдал папку, обхватил себя
      зябко за плечи, невольно чуть отвернулся, забыл отнятую у Бахты сигарету в
      пепельнице.</p>

    <p>Он так надеялся, так надеялся,
      что сегодня из лабораторий придут нормальные результаты
      и все прежние трупы можно будет счесть случайным фактором. Или не случайным —
      пусть неслучайным, пусть хотя бы объяснимым!</p>

    <p>Леший.</p>

    <p>— Как у тебя? — обратился Бахта к
      закопавшемуся в бумажки Социю, пока сам шарил по
      ящикам.</p>

    <p>Бахта ищет Андрею выпивку?</p>

    <p>Спасибо, трогательная забота, да
      только не поможет. Вчера вон тоже напились джина и
      пошли напролом — говорить с Молевичем. Наследили наверняка. Уже по дороге
      обратно Андрей сообразил, что на нём была насквозь продушенная рубашка — некогда было утром менять на свежую,
      просто полился парфюмом
      и забыл. А Охрович и Краснокаменный наверняка заметили следы посторонних
      запахов.</p>

    <p>Беда вроде невелика: с Молевичем
      ведь ничего дурного не делали, даже не склоняли особенно на свою сторону. Не
      били. В кои-то веки пошли поговорить — и поговорили, а не как обычно.</p>

    <p>Но (и снова — «но», «но»!) всё
      равно неприятно, если Охрович и Краснокаменный таки догадались, что у них в
      квартире побывала Бедроградская гэбня, — надумают же всякого, чего и близко не
      было.</p>

    <p>Ничего не было, а то, что было, —
      бесполезно, бесполезно, бесполезно! Откуда бы Молевичу что-то знать?</p>

    <p>Его не было на встрече гэбен, он,
      видимо, не в курсе результатов встречи с Дмитрием Борстеном, которая обернулась
      явлением 66563, — Молевич только от Бедроградской гэбни и услышал про то, что с
      заражением дома завкафа не всё так просто, как ему казалось.</p>

    <p>Само собой, дом завкафа заражал
      не Молевич, что он тут же самолично и подтвердил.</p>

    <p>Само собой, само собой.</p>

    <p>Андрей не хотел этого похода к
      Молевичу, пытался отговорить Соция, но того страшно заело. Да и Бахта поддержал
      идею: если можно предпринять хоть какие-то действия, почему нет-то? А уж когда
      в институте госслужбы обнаружился Гошка — живой и стращающий
      курсантов проверочкой знаний служебных инструкций и
      навыков стрельбы прямо тут, в коридоре, — противиться стало уже совсем бесполезно.</p>

    <p>Гошка посмотрел на них троих со
      злым весельем, которое накрывает его в самых непростых рабочих ситуациях, и
      сказал: «К херову Молевичу? А поехали!»</p>

    <p>У Гошки от Молевича всегда крыша
      немного едет, что и понятно — после истории-то с появлением Университетской
      гэбни.</p>

    <p>Но не было, не было вчера толку
      от Молевича. Не знает, не думал, благодарит (ах, какие нежности!) за
      возможность взглянуть на события с другой стороны. Просит позаботиться о завкафе (кто бы вообще знал, что университетские
      с обнаруженным завкафом сделали, но допустим). Будет сам гадать, кто мог
      заразить проклятый дом.</p>

    <p>Ну удачи
      Молевичу в угадывании.</p>

    <p>Андрея буквально-таки передёрнуло
      от раздражения на всю эту бессмыслицу, нелепицу, невыносимую чушь.</p>

    <p>Бахта сунул ему под нос кофейную
      чашку, от которой несло не только кофе, но и алкоголем, и Андрей таки вернулся
      к нынешней беседе, стал снова различать слова.</p>

    <p>Что не означает, что кофе, пусть
      и с алкоголем, — не омерзительнейшая проевропейская дрянь. Омерзительнейшая.</p>

    <p>— …заткнуть метелинский
      завод легко, они хотя бы не тратились на транспортировку товара, — не отрывая
      глаз от бумажек из лаборатории, объяснял Соций. — Да и вообще он наш, мы первые
      и теряем в деньгах из-за их невыполненных контрактов, так что руководство пусть
      засунет себе в жопу свои предъявы. Но сегодня Порт не
      пустил составы из Средней Полосы, у которых были жёсткие даты. И они там
      взвыли. Но блядь, кто ж упомнит, что Старожлебенский
      Машиностроительный финансировался в последний год не Средней Полосой, а из кармана
      Столичного региона! Блядь. И прям с этой партии они должны со Столицей расплатиться
      турко-греческими деньгами.</p>

    <p>— Ох ебать, — хлебнул
      приготовленного для Андрея кофе Бахта.</p>

    <p>— Не переебать, — согласился
      Соций. — Я пока послал Старожлебинск усердно юбилеить и с экспортными вопросами в преддверии такого
      великого дня не приставать, но они очухаются в лучшем случае послезавтра. В
      худшем — уже послали сведения о простое в Столицу.</p>

    <p>— Если ещё и Столичная гэбня
      начнёт вынюхивать, почему Порт под блокадой, мы погибли, — схватился за голову
      Андрей.</p>

    <p>— Так уж прям «погибли»! —
      посмеялся Бахта. Нервно посмеялся: сам знает, столичные всегда рады
      Бедроградской гэбне ещё пару сантиметров могилы выкопать.</p>

    <p>— Где Гошка, блядь? — зыркнул на часы Соций. — Пусть звонит в Старожлебинск
      и ебёт их бюрократией изо всех сил. Он же может сообразить что-нибудь, что
      можно самим первыми в Столицу отправить, чтоб они пасть пока захлопнули.</p>

    <p>«Где Гошка, блядь?» — и всё, и
      никаких метаний на тему того, сдавал или не сдавал он планы Бедроградской гэбни
      66563.</p>

    <p>Андрей расслабленно выдохнул.</p>

    <p>Соций и Бахта ещё сомневаются,
      ждут очной ставки Гошки с 66563. Андрей и сам ждёт.</p>

    <p>Но никакого, никакого значения не
      имеют такие мелочи, когда экономика трещит, машиностроительный экспорт буксует
      на входе в Порт, а столичные только и ловят момент, чтобы поживиться.</p>

    <p>И, леший, <i>как же это хорошо.</i></p>

    <p>— Гошка только что со мной пинал
      юбилейных начальников, сейчас отошёл на секунду, — ответил Бахта. — Табельное
      начищает наверняка, перед переговорами-то. И пусть мне хоть ещё раз косу
      отрежут, если я неправ.</p>

    <p>— Подставляй косу! — выкрикнул прямо
      из коридора Гошка. — Или чем там имитировать собирался? Давай-давай. Я
      придумывал, как не выпустить херовых гостей города без медкомиссии, чтоб никто
      не догадался, что мы тут скрываем. И придумал.</p>

    <p>Гошка влетел в ворохе бумаг.
      Дверь на петлях замотало так, как будто она вот-вот оторвётся. Андрей сначала
      подумал, что Гошка взбешён, но нет, тот всего лишь самодовольно усмехался —
      встрёпанный, расстёгнутый, громкий и быстрый.</p>

    <p>Очень привычный.</p>

    <p><i>Очень свой.</i></p>

    <p>— И? — уставился на него Бахта,
      который добровольно взвалил на себя большинство юбилейных дел.</p>

    <p>— Долго, — отмахнулся было Гошка,
      но передумал: — Если коротко — якобы пользуемся юбилеем в благих целях.
      Собираем банк крови для новейших исследований в области синтеза белка и прочей
      поебени. Программа бедроградская, но гостям города предоставляется уникальная возможность
      сдать свои почётные миллилитры в первых рядах. Процесс сдачи уже организован, о
      нём прямо на юбилее и объявят. Ни одна падла не сольётся без анализа! — Гошка
      повернулся к Андрею. — Миленький, черкнёшь в Медицинскую гэбню две строчки про
      наши программы?</p>

    <p>Андрей кивнул.</p>

    <p>Новейшие исследования в области
      синтеза чего бы то ни было — это только для народонаселения нормальная формулировка,
      а чтобы не вызвать подозрений у Медкорпуса, придётся хорошенько поломать
      голову, но это ничего. Андрей справится, недаром он с семнадцати до
      восемнадцати лет служил медицинским ревизором.</p>

    <p>Справится.</p>

    <p>А Гошка обратился к нему
      «миленький». Это что-то почти обидное, но на самом деле — приятное.</p>

    <p>Андрей знает, как ведёт себя и
      как это выглядит, Андрей частенько всё это ненавидит, но Андрей слишком хорошо
      усвоил, что <i>так</i> — удобно, эффективно,
      результативно.</p>

    <p>Когда другие головы Бедроградской
      гэбни подтрунивают над ним по этому поводу, он даже бывает способен всерьёз
      разозлиться. Но не сейчас, не сейчас.</p>

    <p>Потому что «миленький» от Гошки —
      это не подтрунивание даже, а что-то такое —</p>

    <p>Опять <i>что-то такое очень своё.</i></p>

    <p>— Кровь, — задумчиво буркнул
      Соций, продолжая копаться в лабораторных отчётах. — Четверть летальных исходов,
      да. Слышь, Андрей, у нас ведь есть какой-то аналог того, что у естественнорождённых называется группой крови?</p>

    <p>Крови естественнорождённых
      граждан Объединённой Британии-Кассахии Соций в своей жизни навидался
      достаточно, чтобы кое-что в ней понимать.</p>

    <p>— Есть, — согласился Андрей. — Но
      это гораздо, <i>гораздо</i> менее значимый
      параметр, чем для естественнорождённых. Подожди, ты
      думаешь, имеет смысл… хотя в лекарство входит сыворотка крови… но нет, она же не
      донорская! С донорской теоретически могли бы быть проблемы, ты прав, но потому
      мы и взяли искусственную сыворотку, универсальную… хотя, конечно, в Шапкиной
      формуле не обозначено, каким именно соединением он пользовался… но это ни к
      чему, это общее место в синтезе естественных образований… не может быть…</p>

    <p>— Кончай с белочкой, — взял
      Андрея за плечо Гошка. — Мы уже поняли, лажа в искусственной крови.</p>

    <p>Андрей попробовал собраться с
      мыслями.</p>

    <p>— Я ещё ни в чём не уверен. Современный
      синтез крови абсолютно надёжен, когда мы имеем дело с большинством известных
      заболеваний. Если при лечении какого-то конкретного заболевания препаратами, в
      которые входит искусственная сыворотка крови, могут возникнуть сложности, это
      оговаривается отдельно.</p>

    <p>— В случае, когда заболевание
      достаточно изучено, чтобы вообще что-то оговаривать, — выплюнул Соций, отбросил
      лабораторные отчёты.</p>

    <p>Леший. У Шапки было мало, слишком
      мало времени — один человек не способен провести всесторонний анализ возможных
      следствий за такой короткий срок. Но никто ведь и не собирался запускать
      контролируемое заражение так рано: да, тара с вирусом и лекарством уже ожидала
      на складе своего часа, но Андрей планировал ещё целую серию тестов, планировал
      пронаблюдать за состоянием заражённых в стационаре, планировал, планировал, да
      только разнесчастный Университет —</p>

    <p>— А что мы… то есть как раз не
      мы, а медицина знает об использовании препаратов на основе искусственной крови
      при лечении <i>степной</i> чумы? —&nbsp;со
      странным, почти что счастливым выражением
      лица воззрился на Андрея Бахта.</p>

    <p>— Ничего, — огрызнулся Андрей.</p>

    <p>…А потом как-то так вышло, что он
      уже не стоит у окна, а сидит на столе, и Бахта опять суёт ему чашку с
      омерзительной проевропейской дрянью, Соций — уже прикуренную сигарету, а Гошка
      его едва ли не обнимает, держит за плечи сзади и легонько, осторожно
      потряхивает.</p>

    <p>— Ну ты даёшь, миленький, —
      посмеялся Гошка.</p>

    <p>— Ты прям позеленел весь, —
      беспокойно мотнул головой Бахта.</p>

    <p>— До натурального обморока не
      доходи, а? — хмыкнул ошалевший Соций. — Обморочность
      и прочая бордельная чушь — это университетские
      штучки.</p>

    <p>— Всё нормально, — устыдившись,
      выпрямился Андрей. Взял кофейную чашку, затянулся. — Столько дней уже в бешеном
      ритме вкалываем, вот и темнеет в глазах.</p>

    <p><i>Всё нормально.</i></p>

    <p>В 76-м году во время вспышки
      степной чумы на Колошме некоторые заключённые на добровольной основе были
      допущены до санитарской работы. Один из этих заключённых экспериментировал со
      степными травами и собственной кровью и за считанные дни достиг неожиданно серьёзных
      результатов, кои были отправлены одним из голов гэбни Колошмы в Медкорпус вопреки
      всем санитарным нормам.</p>

    <p>Заключённый не изобрёл лекарства
      от степной чумы, но полученная им иммунная сыворотка была уже достаточно
      эффективной, чтобы можно было говорить о прорыве в изучении самой загадочной
      болезни в истории медицины.</p>

    <p>Твирь и кровь.</p>

    <p>Что-то ещё, кроме твири и крови,
      конечно. «Что-то ещё» — все бесконечные катализаторы и стабилизаторы реакций
      могли быть лучше, чем в оригинальной сыворотке, и более адекватные их аналоги
      нашлись достаточно быстро.</p>

    <p>Твирь и кровь лучше быть не
      могли.</p>

    <p>Заключённый числился среди
      погибших в сожжённом изоляторе, а значит, задать ему вопрос «почему» не
      представлялось возможным. Его даже посмертно наградили за гражданское мужество
      или что-то вроде того, а для дальнейшей работы над сывороткой создали не то
      отдельную лабораторию, не то целый институт — Андрей уже не помнил точно.</p>

    <p>Зато помнил, как его позвала в
      Столицу Медицинская гэбня. Рассказала, что один голова гэбни Колошмы (уже
      бывший) признался, что прямо перед вспышкой эпидемии тот самый заключённый
      находился не под контролем гэбни Колошмы, а под контролем Андрея Зябликова
      лично, который со своим шестым уровнем доступа мог творить на Колошме что
      угодно.</p>

    <p>«Что творил-то?» — спросил Виктор
      Дарьевич. Чудеснейший, проницательнейший Виктор
      Дарьевич, под началом которого Андрей и служил когда-то медицинским ревизором.</p>

    <p>Андрей крайне симпатизировал
      Виктору Дарьевичу, Андрей крайне хорошо понимал, что работа
      лаборатории-института застопорилась, потому что все высококлассные специалисты оказались
      не в силах понять, почему никакая другая кровь не даёт таких же показателей,
      как кровь погибшего заключённого, ��о —</p>

    <p>Как же Андрей ненавидит все эти
      «но»!</p>

    <p>Андрею гораздо дороже была —
      есть! — Бедроградская гэбня, чем все изыскания Медкорпуса. А Бедроградская
      гэбня просила Андрея не трепать направо и налево, чем он занимался на поганой
      Колошме перед вспышкой эпидемии.</p>

    <p>Потому что — <i>рассинхронизация, непрофессионализм, глупость, кошмар</i>.</p>

    <p>И Андрей не стал трепать. Про
      аллергическую вакцину, про то, как именно она была использована, и про то, что,
      выходит, без рассинхронизации, непрофессионализма, глупости и кошмара погибший
      заключённый ни на шаг бы не продвинулся в своих дилетантских экспериментах.</p>

    <p>Андрей просто похлопал ресницами
      на Виктора Дарьевича и пролепетал: «К сожалению, никаких процедур, которые хотя
      бы отдалённо можно было счесть медицинскими, я там не проводил». Его спросили
      ещё раз, его спросили, не бросились ли случайно ему в глаза какие-нибудь
      признаки нетипичного состояния заключённого, его привлекли к светской беседе на
      тему «так в чём же секрет?», ему предложили повнимательней ознакомиться с
      разработками лаборатории-института.</p>

    <p>А он просто хлопал и хлопал
      ресницами. Вежливо, мило. Убедительно.</p>

    <p>Ещё через некоторое время
      лабораторию-институт не то закрыли, не то переквалифицировали. Разгадать
      загадку единственного
      средства, хоть сколько-нибудь эффективного против таинственной заразы, так и не
      удалось. <i>И поэтому, Бахта, — ничего,
        ничегошеньки медицина не знает об использовании препаратов на основе
        искусственной крови при лечении степной чумы. И не искусственной тоже.</i></p>

    <p>— Просто пришло в голову, —
      рассуждал Бахта, — что все эти нескончаемые упоминания степной чумы могут быть
      не просто психической атакой. Гуанако со своей черёмухой сколько выл Социю о
      степной чуме? Шапка вон вообще сказал, что было некое сырьё, из которого он наш
      вирус лепил, а ты, Андрей, будто б ездил его просить слепить вдобавок из того
      же сырья собственно степную чуму. И наш вирус, получается, к степной чуме
      какое-то отношение имеет, если Шапка про сырьё не выдумывал.</p>

    <p>— Мы уже обсуждали это в самом
      начале, — нахмурился Соций.</p>

    <p>— И сочли несущественным, —
      сокрушённо вздохнул Бахта.</p>

    <p>— Я счёл, — напомнил Гошка. — И
      продолжаю считать.</p>

    <p>Андрей посмотрел на него
      осуждающе.</p>

    <p>— Ты неправ. Иммунная сыворотка,
      которую получил Смирнов-Задунайский в 76-м, содержала кровь. Кровь, изменённую
      сильнейшим воздействием твири. Наше лекарство тоже содержит кровь — что,
      кстати, далеко не единственное его структурное сходство с той самой сывороткой.</p>

    <p>— Но достаточное ли для того,
      чтобы говорить о каком-либо сходстве болезней через сходство методов борьбы с
      ними? — подключился Соций.</p>

    <p>— Наверное, — растерянно ответил
      Андрей. — Мне так кажется, мне казалось так с тех пор, как я вообще услышал,
      что у Шапки якобы было сырьё. Но я не углублялся: времени не было на
      дополнительные исследования, да и вы все дружно обозвали меня параноиком.</p>

    <p>И теперь всё катится к лешему.</p>

    <p>— Ну и что это означает на
      практике? — осведомился Гошка, демонстративно поглядывая на часы.</p>

    <p>— Всё, что я могу сейчас сказать
      точно, — попробовал совладать с мрачными предчувствиями Андрей, — так это то,
      что <i>степная</i> чума вытворяет с кровью
      странные вещи. Непонятые, кажется, до сих пор. У заражённых <i>степной</i> чумой с составом крови леший
      знает что творится, а та самая иммунная сыворотка 76-го года вроде бы могла
      кое-как эти процессы блокировать. Насколько мне известно, как раз благодаря
      крови — крови человека, которого неочевидным способом предварительно накачали
      твирью. Медицинская гэбня не смогла разобраться, что к чему, и именно из-за
      этого компонента препарат так и не сумели повторить в лабораторных условиях. <i>Степная</i> чума отступала только перед
      совершенно конкретной, <i>особенной</i> кровью. Которую было не заменить ни обычной донорской, ни синтезированной, — Андрей
      нервно сглотнул, в недоумении воззрился на окурок у себя в руке, помялся, но
      всё же закончил мысль: —&nbsp;Получается, если
      вирусы и в самом деле родственны, наш сумасшедше высокий процент летальных
      исходов среди тех, кто получил лекарство, действительно может быть связан с
      использованием искусственно синтезированной сыворотки крови. В ней… чего-то не
      хватает. Чего-то такого, что вырабатывается в живом организме при специфическом
      контакте с твирью. Неучтённый фактор, можно сказать… человеческий…</p>

    <p>Андрей сам прекрасно знал, как
      это глупо звучит. Любой «человеческий фактор» воспроизводим в пробирке, это
      известно, это доказано — одни алхимические печи для выпекания людей чего стоят!</p>

    <p>Только чтобы было, что воспроизводить,
      нужно с самого начала обладать исчерпывающими исходными данными.</p>

    <p>А их нет. И Андрей —
      единственный, кто в полной мере понимает, <i>почему</i> их нет.</p>

    <p>— Но не стопроцентно же помирают!
      — вклинился Бахта. — Значит, наша формула лекарства хотя бы не полное фуфло.</p>

    <p>— Ещё бы, могло быть хуже, —
      сумрачно усмехнулся Соций. — Андрей, дай лабораториям задачку поковыряться в
      том, что у нас вместо групп крови естественнорождённых.
      Сходится ли этот параметр у имеющихся трупов или типа того. Наудачу тыкаем, но
      вдруг.</p>

    <p>— Да, разумеется. Там и не только
      это может быть, я сейчас же позвоню и скажу, что надо ещё раз приглядеться к
      крови, к синтезированной сыворотке… У нас должен был остаться образец лекарства
      от Шапки, имеет смысл посмотреть, какой конкретно синтез использовал он. Его
      лекарство на тестах не выдавало никаких нежелательных результатов, — возможно,
      зацепка как раз в способе синтеза. Способ, если он из перечня, утверждённого
      Медкорпусом, — это сугубо производственная частность, на неё давно никто не
      обращает внимания. Но незначительные расхождения в конечном составе есть, обычно
      они не играют никакой роли, хотя если наш вирус с кровью в таких же непростых
      отношениях, как степная чума… Короче, я сейчас же выдам указания, куда копать.</p>

    <p>У Андрея в голове сам собой
      включился деловой режим, в котором любая проблема перестаёт быть проблемой и
      становится задачей. Он связывался с лабораториями, надиктовывал
      торопливые инструкции и задавал вопросы, больше не чувствуя удушливого страха.</p>

    <p>Удушливый страх решил пока
      подождать.</p>

    <p>Удушливый страх дал Андрею
      облачиться в мундир и пристегнуть наплечник. Сесть за руль и завестись.
      Тронуться и начать какую-то беседу о предстоящей встрече с остальными головами
      своей гэбни.</p>

    <p>Удушливый страх очнулся от спячки
      делового режима только тогда, когда кто-то вслух пожалел, что откинувшегося
      папашу Ройшевой девки так и не допросили на предмет
      того, как же выглядел этот упомянутый им в числе главных действующих лиц
      Дмитрий Борстен.</p>

    <p>Удушливый страх шепнул Андрею:
      «Смирнов-Задунайский, степная чума, Колошма».</p>

    <p>&nbsp;</p>

    <p>Ворота склада скрипнули,
      послышались смешки и какой-то ор.</p>

    <p>Андрей поморщился: вечный
      университетский балаган, не могут даже на последние переговоры прийти
      нормально, как взрослые люди.</p>

    <p>Бахта, Соций и Гошка тем не менее
      улыбались. Бахта — с весёлым любопытством (что они там выкинут на этот раз?), Соций
      — с мрачным удовлетворением (ну наконец-то пришло время разобраться!), Гошка —
      с нездоровым каким-то блеском в глазах, который Андрей не мог перевести.</p>

    <p>Андрей уже второй день не мог
      перевести ни один взгляд Гошки.</p>

    <p>Это добавляло беспокойства.
      Удушливый страх всё твердил и твердил под руку: «Колошма, совсем как Колошма».
      Андрей не слушал — не до того.</p>

    <p>Первым вошёл 66563.</p>

    <p>Просто вошёл, не озирался по
      сторонам, не пользовался геометрией помещения, не пытался быть осторожным. Не
      боялся, что его расстреляют вот прямо так, на входе. Нет, если точнее: не
      боялся за себя, но явно боялся за остальных, раз сунулся первым.</p>

    <p>— Командир, я своё дело сделал, —
      кивнул он Социю лично.</p>

    <p>Так поступают фаланги — говорят с
      головами гэбни по отдельности. Знают, как это бесит. А 66563 не то не знает, не
      то не держит в уме: он действительно пришёл в первую очередь к Социю и по
      просьбе Соция, у них свои отношения.</p>

    <p>Вот эти «свои отношения» бесят
      почище отработанных приёмов фаланг. Как будто когда 66563 кивает Социю лично,
      Бедроградская гэбня разламывается на части под грузом прошлого каждого из
      голов. Под грузом самого факта наличия этого прошлого.</p>

    <p>Андрей очень постарался обуздать
      своё собственное прошлое и смотреть на 66563 непредвзято, не думать, что один
      66563 перед гэбней — это почти как допрос, это почти как когда-то на Колошме —</p>

    <p>Не слушать, не слушать сейчас
      удушливый страх!</p>

    <p>Лучше разглядывать детали
      облачения 66563 и качать головой по поводу того, что университетские
      патологически не умеют поступать нормально, как взрослые люди.</p>

    <p>— Ну, наглая рожа, а я-то думал,
      ты в прошлый раз вырядился как идиот! — с Соция даже схлынула на секунду вся
      его сегодняшняя мрачность и собранность.</p>

    <p>— Да ладно тебе, я же нынче даже
      в форме, — дружелюбно отмахнулся 66563, а потом уставился на Гошку: — В форме <i>реальной власти</i>.</p>

    <p>Гошка ответил нехорошей усмешкой:</p>

    <p>— Наплечник длинноват. Не мешает
      реальной власти реально властвовать?</p>

    <p>— А короче никак, — инстинктивно
      приподнял левое плечо 66563. — И длина тут следствие ширины, а ширина нужна,
      чтоб татуировку на лопатке прятать. Особая примета, во всех досье есть, — 66563
      заулыбался нагло-нагло. — Шаман с самого начала должен был быть шаманом, а
      всякая там Загробная гэбня и прочие страсти — следствие необходимости на
      шаманском теле особые приметы не светить. Только и всего.</p>

    <p>Гошка еле заметно дёрнулся.</p>

    <p>Значит, правда. Ничего пока не понятно,
      но — правда. <i>Это Гошка</i> каким-то
      образом слил их планы Университету через посредничество 66563.</p>

    <p>Леший, всё-таки правда.</p>

    <p>Андрей подавил очередную волну
      удушливого страха и ещё раз скептически оглядел 66563. Загробная гэбня, значит.
      Ну-ну.</p>

    <p>Формы как таковой на 66563 не
      было, поскольку форма — это одежда, а одежду в данном случае заменяли хитро
      переплетённые травяные стебли. Минимально заменяли, даже обуви не было, только
      какое-то подобие юбки на бёдрах и, собственно, наплечник. Действительно
      длинный, свисающий до локтя, и действительно широкий. Про скрывающуюся под ним
      татуировку «Курёхин» (по имени первого корабля) Андрей прекрасно помнил. Андрей
      прекрасно помнил всё проклятое досье 66563, разбуди его среди ночи — пересказал
      бы без запинки.</p>

    <p>Неуютно, как же неуютно сознавать,
      что до сих пор не выветрилось ни досье, ни собственные воспоминания о 66563 и о
      его деле.</p>

    <p>О Колошме.</p>

    <p>— Реальная университетская власть
      ограничивается вами? — надавил Бахта. — Если нет, пора бы узреть прочие её
      составляющие.</p>

    <p>— Конечно, — согласился 66563,
      всё ещё не сводя с Гошки глаз. — Прошу прощения, я сначала один заглянул, чтоб эксцессов
      в единицу времени было поменьше.</p>

    <p><i>Не сводя с Гошки восьми глаз.</i></p>

    <p>На щеках, под настоящими глазами,
      были нарисованы ещё три пары.</p>

    <p>Восемь глаз.</p>

    <p>У Андрея в мозгу что-то неприятно
      засверлило, удушливый страх снова подобрался совсем-совсем близко.</p>

    <p>Он тряхнул головой: чушь, 66563
      весь разрисован, живого места нет. Багровые узоры покрывают всю видимую
      поверхность кожи, расходятся лучами от солнечного сплетения, забираются под
      юбку и наплечник, обвивают ноги и руки. Подумаешь, какие-то восемь глаз —
      надоело абстрактную вязь малевать, вероятно.</p>

    <p>— Эксцессов? — снисходительно
      хмыкнул Гошка. — Многовато на себя берёте, реальная власть.</p>

    <p>— Загробная гэбня делегировала
      мне полномочия уведомить вас, что она чрезвычайно рада сохранению здорового
      морального климата в гэбне города Бедрограда, — через плечо бросил 66563, снова
      приоткрывая складские ворота. — И извиниться за доставленные неудобства
      морально-климатического характера.</p>

    <p>Гошка явно намеревался соврать,
      что таковых замечено не было, но тут в ворота вломились Охрович и
      Краснокаменный, оглушительно завыв какую-то муть на староимперском
      (Андрей немного понимал его благодаря медицинской подготовке).</p>

    <p>Муть отдалённо напоминала ту
      самую арию, с которой вчера утром как раз сюда приезжало университетское такси,
      а Охрович и Краснокаменный напоминали идиотов гораздо больше, чем пресловутый
      66563 с его пресловутыми рисунками на теле.</p>

    <p>Охрович и Краснокаменный
      напоминали идиотов из, хм, детского спектакля. Андрей сначала не поверил своим
      глазам: головы гэбни, пусть и временно отстранённые, в полноценных театральных
      костюмах — собак? птиц? чего-то среднего? Да ещё и омерзительно яркого,
      буквально слепящего розового цвета. Бесконечные искажающие пропорции складки,
      капюшоны с клювами и ушами, функционально бессмысленные хвосты, имитация когтей
      на рукавах и штанинах. И крылья. То есть наплечники. То есть крылья, но одна
      пара на двоих. Обтянутый тканью каркас, закреплённый с лёгким наклоном вниз
      относительно линии плеча — левого плеча Охровича и правого плеча
      Краснокаменного. Даже удивительно, что им хватило мозгов не приковываться друг
      к другу дополнительными средствами и остановиться на чисто визуальной иллюзии
      общих крыльев. Но всё равно подобный «наплечник» расширяет фигуру вбок больше,
      чем на полметра, — в случае, если понадобится обороняться, им будет очень и
      очень неудобно. С другой стороны, под такой костюм можно надеть леший знает чего
      — и ни одна пуля не возьмёт.</p>

    <p>Кроме той, что в голову.</p>

    <p>Андрей с Института госслужбы выучил,
      что правильно исполненное баловство с внешним видом может быть эффективным. Главная
      цель — дезориентация противника. Даже обычные наплечники — не просто знаки
      отличия: у гэбни, например, они вполне работают как простейший приём
      психологического воздействия. Сидящие на допросе в ряд четыре непохожих
      человека с одинаково нефизиологично скошенными влево
      силуэтами — это, разумеется, ерунда и мелочь, но ерунда и мелочь,
      обеспечивающая у допрашиваемого первичный дискомфорт на уровне восприятия, что
      небесполезно.</p>

    <p>Охрович и Краснокаменный своими
      костюмами из детского спектакля доводят этот принцип до абсурда. Восприятие,
      впрочем, действительно затрудняется. Слепящий розовый цвет и крылья — это не
      совсем то, что ожидаешь увидеть на неофициальных, но всё-таки переговорах.
      Сбивает настрой, рассредоточивает внимание.</p>

    <p>Настолько рассредоточивает, что
      Андрей не сразу заметил ещё одного человека, который вошёл на склад одновременно
      с Охровичем и Краснокаменным.</p>

    <p>Крыльев (или хотя бы багровых линий
      на практически обнажённом теле) у него не было — в глаза не бросался. Как
      только бросился, Андрей едва вслух не выдал что-то вроде «ну вот, а вы мне не
      верили!», но одёрнул себя, сдержался.</p>

    <p>Четвёртым представителем реальной
      университетской власти на переговорах был-таки Дмитрий Смирнов-Задунайский.</p>

    <p>Он же — по всей видимости,
      которую теперь нелепо отрицать, — Дмитрий Борстен с медфака и Дмитрий Ройш из
      Медкорпуса.</p>

    <p>С прошлой субботы, с того самого
      момента, как фаланги задержали Андрея и предъявили досье с фотоснимком Дмитрий
      Ройша, он подозревал, что Смирнов-Задунайский жив. Жив и в Университете. Это
      была такая бредовая, такая навязчивая и столько раз опровергнутая за последние
      пару дней мысль, что сейчас, когда она таки подтвердилась, ничего, кроме «а вы
      мне не верили», у Андрея на этот счёт не осталось.</p>

    <p>Ну да, Смирнов-Задунайский. Ну
      да, человек, на котором Андрей семь лет назад последний раз крупно споткнулся в
      профессиональном плане: совершил не в то время и не в том месте массу
      неоправданных глупостей, и за эти глупости Андрею уже не единожды прилетало. Ну
      да, вот он — и что?</p>

    <p>Что?</p>

    <p>Удушливый страх пока не хотел
      отвечать.</p>

    <p>Андрей с гораздо бóльшим интересом наблюдал за Социем, крайне недобро
      вперившимся сейчас в 66563.</p>

    <p>— Без обид, командир, — заметил
      66563 его реакцию, уже усевшись за переговорный стол.</p>

    <p>— Наебал-таки, — громыхнул Соций.
      — Ещё и смертями новобранцев зубы заговаривал как последняя падла!</p>

    <p>— Не как падла, а честь по чести.
      Сам знаешь, командир: делай что хочешь, а новобранца вытащи. Ну вот я и.</p>

    <p>— Да только твоя кассахская шлюха
      в эту категорию давно не попадает, — чуть тише и чуть злее отозвался Соций.</p>

    <p>— Для кого как, — нагло
      ухмыльнулся 66563.</p>

    <p>— Нахуя пиздел? Всё равно вы с
      шансами уедете отсюда на гробовозке.</p>

    <p>— Лучше сегодня, чем вчера, — ещё
      шире заухмылялся 66563.</p>

    <p>Прекрасное, просто отличное
      начало переговоров.</p>

    <p>Андрей скривился. Ни он сам, ни
      Бахта, ни Гошка в разборки Соция с 66563 встревать не хотели. Вряд ли совсем осознанно,
      но, в конце концов, все же слышали запись с несчастной вчерашней встречи,
      переполненную до краёв размазыванием соплей по армейским дням.</p>

    <p>И видели, кстати, тоже.</p>

    <p>— 66563, — предельно спокойным
      тоном начал Андрей, когда понял, что Соций продолжать свои личные разборки
      более не намерен, — помимо выражения нашей искренней радости по поводу того,
      что расставаться с жизнью вы собрались сегодня, а не семь лет назад на Колошме,
      мы хотели бы выразить также и глубочайшее восхищение вашими познаниями в
      ботанике. При прошлом вашем столкновении с гэбней города Бедрограда вы в
      дипломатических целях использовали препарат на основе пыльцы <i>упории живодавящей</i>,
      произрастающей в тропических лесах Объединённой Латинской Америки. Запах
      цветущей черёмухи, довольно близкий к аромату пыльцы упомянутого эндемика,
      выдал вас с головой, — без зазрения совести преувеличил Андрей. — Тем не менее,
      вы справились со своими дипломатическими целями блестяще, изыскав способ ввести
      в организм собеседника галлюциногенные вещества непривычным для дипломатии
      способом — через дыхательные пути. Очевидным образом также подвергшись их
      действию сами. И мы были бы вам чрезвычайно признательны, если бы вы поделились
      с нами образцом или формулой данного препарата. Для того чтобы мы могли
      убедиться, что здоровью Соция Всеволодьевича не был нанесён существенный ущерб.</p>

    <p>А ещё для того, чтобы не пропали
      даром те часы, которые сотрудники Андреевых лабораторий провели в поиске по
      разнообразным справочникам растения с черёмуховым запахом. И те часы, которые
      Андрей самолично отсматривал полученную ко вчерашней
      ночи плёнку с записью встречи, дабы понять, как же 66563 умудрился накачать
      Соция наркотой.</p>

    <p>Только на третьем просмотре
      Андрей отследил-таки вполне естественное — хоть и до тошноты копирующее манеры
      этого их завкафа — движение: пальцы, потирающие висок.</p>

    <p>Пальцы, осторожно прикасающиеся к
      волосам у виска, где вполне поместилась бы капсула в специальной легко
      разрушаемой оболочке — такие делают в Медкорпусе уже довольно давно.</p>

    <p>Пальцы, отправляющиеся затем
      поглаживать лицо Соция, несколько обескураженного от неожиданной
      рафинированности армейского знакомца.</p>

    <p>Именно после этого еле заметного
      движения пальцев разговор скатился в обмен многозначительным (а главное —
      неконтролируемым) бредом, что в результате и привело Соция к излишней
      откровенности.</p>

    <p>Наверняка капсула, наверняка! Препарат
      с запахом лиан, так похожим на запах обычной росской черёмухи. Потому и был при
      66563 дурацкий букет, да и вообще смысл всего этого дореволюционно-бордельного
      антуража стал теперь абсолютно прозрачен.</p>

    <p>Соций, узнав, для чего была нужна
      черёмуха, сорок раз помянул батюшку 66563, Бахта всё не мог перестать ржать,
      Гошка сдержанно одобрил.</p>

    <p>А Андрея заклинило на препарате.</p>

    <p>Нельзя, никак нельзя упускать из
      виду инструментарий Университета!</p>

    <p>Препарат, который приходится
      вдыхать самому, — непрактичная чушь, вряд ли применимая в работе Бедроградской
      гэбни, но представление иметь стоит. Не говоря уже о том, что довольно
      существенно как можно скорее выяснить, где 66563 таковой достал.</p>

    <p>И не менее существенно —
      продемонстрировать ему, что Бедроградская гэбня раскусила его штучки.</p>

    <p>— Какая формула, мы ж тут все
      малограмотные! — комично всплеснул разрисованными руками 66563. — Ваши
      естественные науки наш истфак знаете где видал? А образца нет, всё снюхали от
      нервов ещё вчера.</p>

    <p>— В таком случае, не подскажете
      ли вы адрес торговой точки, в которой мы могли бы приобрести дозу для
      собственных нужд? — подхватил линию Андрея изрядно развеселившийся Бахта.</p>

    <p>— Бедроградская гэбня тоже
      нюхает!</p>

    <p>— Бедроградская гэбня тоже люди!
      — коротко (но громогласно) прокомментировали Охрович и Краснокаменный.</p>

    <p>— Никак нет, — мотнул головой
      66563. — Товар контрабандный, а Порт-то нынче — сами знаете.</p>

    <p>— Контрабандный? — не купился Андрей
      на упоминание блокады. — Может, вы получше подумаете, 66563? Мы вам даже
      подсказать готовы, чтоб вы врали как можно достовернее: ваш препарат наверняка
      находился в такой странной маленькой капсуле, достаточно прочной, но и
      достаточно легко разрушаемой. Если знать, как именно её сжимать, — Андрей с
      улыбкой коротко продемонстрировал потирание виска. — Подобные капсулы,
      чрезвычайно удобные для хранения летучих веществ, являются разработкой
      Медицинского Корпуса. Откуда, говорите, ваш контрабандный товар привезли?</p>

    <p>— Из Британии, конечно, — ответил
      66563 с каменным лицом. — Где ещё нетрадиционными наркотиками балуются?</p>

    <p>— 66563, — вздохнул Андрей, — мы
      ценим вашу сообразительность и умение выкручиваться, но сейчас вы пытаетесь
      сочинять сказки о вещах, в которых не понимаете ровным счётом ничего. У
      Британии не может быть капсул, разработанных Медицинским Корпусом.</p>

    <p>— Андрей — как вас, леший, всё
      забыл уже! — Эдмундович, — 66563 вздохнул не менее тяжко, — это вы чего-то не
      понимаете. Вы даже не гэбня Международных Отношений, откуда вам знать, кто у
      кого что и как ворует. Медкорпус — любимая добыча всех европейских разведок.
      Тем, кто притащит в Европы какую-нибудь хуйню из Великого и Ужасного
      Медкорпуса, прощаются многие и многие грехи, не говоря уже о противоправных
      действиях. Вы уверены, что способны поддержать умозрительную дискуссию о гипотетических
      путях контрабанды на должном уровне?</p>

    <p>Андрей скривился и хотел уже
      что-то сказать (сказки про контрабанду глотать он не собирался: он не служил в
      гэбне Международных Отношений, зато служил когда-то медицинским ревизором!), но
      его опередил Соций. Молодец, вовремя среагировал — университетским, а уж 66563
      в первую очередь, только дай повод увести переговоры в дебри бессмысленной
      болтовни о том, кто на что способен.</p>

    <p>Подальше от насущных дел увести.</p>

    <p>— Ты лучше вот что скажи, ёбаный
      специалист по контрабанде, — Соций звучал всё ещё зло и разочарованно, но
      переговоры в верное русло направил с лёгкостью, — ты между Портом и
      Университетом связи налаживал?</p>

    <p>— Я, — покорно кивнул 66563. — В
      — дай подумать — семидесятом, что ли, году. Малолетнего Максима — то есть
      Молевича или как вы его зовёте — кое к кому за ручку водил. В личных
      педагогических целях.</p>

    <p>Соций недоверчиво нахмурился, дав
      повод разинуть пасти Охровичу и Краснокаменному:</p>

    <p>— Нет, вы не хотите уточнять, что
      такое «личные педагогические цели»!</p>

    <p>— Точно-точно не хотите,
      клянёмся!</p>

    <p>— Вы серьёзные люди.</p>

    <p>— Профессионалы в своём деле.</p>

    <p>— Вам ни к чему развращать свои
      профессионально зачерствевшие души нашими историями большой и чистой любви всех
      со всеми.</p>

    <p>— Мы не ведаем формул препаратов
      и служебных инструкций нормальных гэбен, а вы не ведаете сути драмы
      человеческих взаимодействий.</p>

    <p>— И в этом разница.</p>

    <p>— Не нивелируйте её, пожалуйста.</p>

    <p>— Если это произойдёт, про наше
      противостояние не напишут радиопьесу.</p>

    <p>— Для соблюдения жанровых
      особенностей вы должны быть бесчувственными злодеями, у которых всё путём на
      службе.</p>

    <p>— А мы — криворукими недотёпами с
      золотыми сердцами.</p>

    <p>— Не выходите за рамки отведённой
      вам роли, а то весь сюжет развалится.</p>

    <p>Андрей поморщился: опять, опять
      они уводят от темы! Если они что-то и умеют, так это уводить от темы. Проклятые
      университетские преподаватели же — все как на подбор.</p>

    <p>То есть как раз не все —</p>

    <p>Андрей расслабил мышцы, опустил
      плечи, задумался о текущем выражении своего лица и всё-таки посмотрел на
      Смирнова-Задунайского впервые с момента его появления на складе.</p>

    <p>Стол для переговоров был такой
      же, как позавчера на встрече гэбен — чтобы с обеих сторон могли рассесться по
      четыре человека.</p>

    <p>Охрович и Краснокаменный,
      единственные присутствующие головы Университетской гэбни, устроились по краям
      напротив Соция и Бахты, как они и делали это всегда. Очевидно, для поддержания
      иллюзии привычной официальности (привычная официальность в розовых костюмах не
      то птиц, не то собак!).</p>

    <p>66563, само собой, многозначительно
      плюхнулся перед Гошкой. Им сегодня есть о чём поговорить взглядами, все это уже
      осознали.</p>

    <p>Для Смирнова-Задунайского просто
      не нашлось другого места, кроме как напротив Андрея.</p>

    <p>Всё это время Андрей делал вид,
      что ему чрезвычайно важно следить за мимикой и пластикой 66563, который
      практически единолично вёл переговоры.</p>

    <p>Пришло время перестать нервничать
      и узнать уже, что нынче представляет собой погибший в 76-м году
      Смирнов-Задунайский.</p>

    <p>— …это несерьёзно, — посмеялся
      над чем-то 66563.</p>

    <p>Андрей не услышал, над чем.</p>

    <p>Андрей вовсе выключился из
      разговора.</p>

    <p>Андрей смотрел на
      Смирнова-Задунайского и мысленно повторял про себя: «Это несерьёзно,
      несерьёзно, несерьёзно».</p>

    <p>Несерьёзно так реагировать на
      месте Андрея.</p>

    <p>Он же всё знает про
      психологическое воздействие вообще и про эксцентричные приёмчики Университета в
      частности, он же давно не ждёт от них ничего, кроме таких вот приёмчиков, но —</p>

    <p>Но на Смирнове-Задунайском поверх
      голубой рубашки гэбенный наплечник. Такой, на тон
      темнее, чем у Андрея. На тон темнее, чем у Андрея <i>сейчас</i>: наплечники шестого уровня доступа немного, но отличаются от
      наплечников седьмого уровня.</p>

    <p>Наплечники Бедроградской гэбни
      немного, но отличаются от наплечников гэбни Колошмы.</p>

    <p>Где-то далеко-далеко, на грани
      слышимости Охрович и Краснокаменный возмущались какой-то реплике Бахты:</p>

    <p>— Бросьте свою порочную затею.</p>

    <p>— Мы не для того сюда пришли.</p>

    <p>— Посмотрите на наши плюшевые
      грифоньи шкуры и подумайте, для чего мы пришли.</p>

    <p>— Мы весь день без сна и отдыха
      кроили и кромсали наши плюшевые грифоньи шкуры.</p>

    <p>— Вы же не собираетесь доказывать
      нам на практике, что это было зря?</p>

    <p>— А то следующим нашим швейным
      подвигом станет кройка и кромсание кожаных шкур гэбни
      города Бедрограда!</p>

    <p>Болтовня Охровича и
      Краснокаменного отрезвляла. Вот если бы они сейчас начали наперебой
      рассказывать, как весь день без сна и отдыха перекрашивали наплечник, например,
      Молевича в нужный тон!</p>

    <p>Так было бы проще — проговорить
      вслух, что это значит, зачем это надо. Пусть едко и с издёвкой, пусть все бы
      насмехались над расшатавшимися нервами Андрея, главное — не молчать.</p>

    <p>Не молчать о том, что на
      Смирнове-Задунайском наплечник поверх голубой рубашки — совсем как у Савьюра,
      Начальника Колошмы.</p>

    <p><i>«Кстати, здравствуй, Андрей»,</i> — сказал невыносимо знакомым голосом
      удушливый страх.</p>

    <p>Несерьёзно, ну несерьёзно же так
      дёргаться из-за какой-то там одежды. Как будто чья-то рубашка может быть важнее
      четверти летальных исходов среди получивших лекарство или санитарных мер на
      завтрашнем юбилее!</p>

    <p>Андрею вдруг стало противно от
      самого себя. Он взрослый человек, у него хоть и противоречивый, зато однозначно
      внушающий уважение послужной список, он с отличием закончил Институт госслужбы;
      в конце концов, он просто умеет справляться с трудностями — в его положении
      буквально-таки неприлично впадать в проклятую белочку из-за такой ерунды.</p>

    <p>Он устал, это правда. Сначала
      телеграмма от Шапки про «другого клиента», жаждущего перекупить формулу вируса,
      потом с субботы по среду фаланги, которые мурыжат любого, кто к ним попадёт, до
      зелёных леших перед глазами, до бессонницы на нервной почве, до совершения
      необдуманных звонков, запускающих процессы, коим лучше бы не запускаться вовсе.
      Потом, со среды, со своей гэбней, было уже полегче — хотя и неуютно после
      запроса на ПН4, хотя в городе эпидемия, хотя надо всё время вкалывать, вкалывать,
      вкалывать. Бегать по лабораториям, гробить зрение над нескончаемыми отчётами из
      городских поликлиник, ломать головы над планами Университета, выносить завкафов
      в коврах, изымать у фармакологии партию твири для внедрения в оголодавший Порт,
      в сотый раз объяснять той же гэбне Международных Отношений, что без
      дополнительно введённых полгода назад медицинских обследований выпускать за
      границу нельзя не только гражданских, но и госслужащих. А в промежутках левой
      ногой ворошить там что-то, чтобы состоялся несчастный юбилей, чтобы город жил и
      функционировал в условно нормальном режиме.</p>

    <p>Андрей мечтал отоспаться,
      отключить все телефоны, отужинать в хорошем ресторане и ни о чём, вообще ни о
      чём не беспокоиться хотя бы пару дней.</p>

    <p>Вместо этого вчера утром он своими
      глазами увидел 66563, и беспокойства стало в разы больше. Не нормального (пусть
      и изматывающего) рабочего беспокойства, а совсем другого — нездорового,
      невротичного, иррационального.</p>

    <p>Это снова несерьёзно, неприлично
      в положении Андрея и как-то стыдно — бояться живых мертвецов. Через столько лет
      дёргаться, потому что из-за 66563 пошла трещинами и развалилась гэбня Колошмы.
      Велика беда, подумаешь. Та ещё гэбня была.</p>

    <p>Только 66563 для Андрея был
      чем-то вроде предзнаменования неудач. Он появляется — и трещины бегут, бегут,
      переплетаются, точно его сегодняшние узоры на теле. Он появляется — и Гошка,
      который громче всех долбил, не стрелять, мол, по университетским, пока Соций не
      потолкует с этим их Дмитрием Борстеном, хватается за кобуру. Он появляется — и
      Андрей перехватывает руку Гошки (громче всех же долбил!) и только потом
      понимает: опять.</p>

    <p>Когда-то давно Андрей заранее
      разрядил табельный пистолет Савьюра, потому что испугался неочевидного: чего
      это тот вдруг решил походить при оружии, когда двадцать лет подряд к оружию не
      прикасался и пальцем. Савьюр, оказалось, всего лишь хотел дать его дозревшему
      до суицида 66563.</p>

    <p>66563 уже десять лет как был бы
      мёртв на самом деле, если бы не Андрей.</p>

    <p>66563 ещё вчера был бы мёртв,
      если бы не Андрей.</p>

    <p>Опять, опять.</p>

    <p>Он появляется — и три головы
      Бедроградской гэбни косо смотрят на Гошку, ждут каких-то объяснений: сдавал или
      не сдавал тот планы. Гошка хлопает дверью, ничего не желает разжёвывать,
      скрывает что-то, и становится ясно: плохо всё.</p>

    <p>Он появляется — и Андрей
      опасается взглянуть под ноги. Потому что знает: трещины уже там, добежали,
      переплелись, ширятся и ширятся. Лишний шаг — и ничего кроме трещин не
      останется.</p>

    <p>Эти проклятые трещины снились
      Андрею вчера после похода к Молевичу. Он надеялся выключиться на пару часов,
      освежить загудевшую с джина голову, но под веками ползли трещины, трещины,
      трещины —</p>

    <p>Проснулся Андрей ещё сильнее
      разбитым, чем засыпал.</p>

    <p>Воровато наглотался
      успокоительного, Бахта ещё заметил тогда. Пришлось соврать, что стимуляторы.</p>

    <p>Опять.</p>

    <p>Опять врать, недоговаривать,
      скрывать — пусть по мелочи, но в любом случае тошно.</p>

    <p>Очевидно, это убойная доза
      успокоительного рассеивает теперь внимание, очевидно, из-за успокоительного Андрей
      и не может сосредоточиться, пропускает мимо ушей важнейшие переговоры, думает
      леший знает о чём, не может отвести взгляда от гэбенного наплечника на голубой
      рубашке —</p>

    <p>— Господа,&nbsp;— произнёс
      Смирнов-Задунайский тихо, но так, что все непроизвольно прислушались, —
      господа. Многие из нас устали от бесконечной болтовни и переживаний последних дней.
      Давайте оставим эти танцы и перейдём-таки к тому, что нас всех <i>в самом
        деле</i> волнует.</p>

    <p>Сначала Андрей похолодел.</p>

    <p>Потом усмехнулся.</p>

    <p>Потом распрямил спину и оглядел
      Смирнова-Задунайского с профессиональным на этот раз любопытством: ну надо же,
      как мы можем, а.</p>

    <p>Само собой, говорил
      Смирнов-Задунайский совсем как Савьюр. Это несложно. В реплику из полутора фраз
      отлично влезают главные савьюровские штампы. «Оставим
      все эти танцы», леший. Голоса у них совсем непохожи, но спокойная и дружелюбная
      безэмоциональность интонаций делает своё дело.</p>

    <p>Недурно.</p>

    <p>С аудиальной составляющей — очень
      недурно, но с визуальной…</p>

    <p>Смирнов-Задунайский почти не
      двигался, только чуть шевелил одним плечом.</p>

    <p>Он ведь никогда не пересекался с
      Савьюром, он попал на Колошму через полтора года после смерти последнего.
      Неоткуда Смирнову-Задунайскому знать, что байки про этакого абсолютно аутичного
      Савьюра — это просто байки, расплодившиеся вокруг образа эффективного и
      могущественного Начальника Колошмы, который при всей своей эффективности и
      могущественности был будто бы вовсе не от мира сего. Чушь невообразимая,
      россказни для дураков. Савьюр был странноватый, но живой. И жестикуляции,
      например, у него имелось в наличии несколько побольше, чем демонстрировал
      сейчас Смирнов-Задунайский.</p>

    <p>Неплохая мысль — сделать из
      одного мертвеца другого, но как же небрежно реализованная!</p>

    <p>Только наплечник на голубой
      рубашке и спасает: рубашка вот почти идеальная. Точное попадание в цвет, верно
      восстановленные манжеты не самого тривиального кроя. Андрей пригляделся ещё
      внимательней, но так и не нашёл изъяна. Шикарная всё-таки память у (видимо)
      66563. Для гражданского — так просто феноменальная.</p>

    <p>Хотя вот же он, изъян: по голубой
      рубашке со стороны печени ползло якобы кровавое пятно.</p>

    <p>Андрей закатил глаза к потолку.</p>

    <p>Леший, ну должно же быть у них
      чувство меры!</p>

    <p>Савьюр умер от двух огнестрельных
      ранений — в печень и в сердце. Стороживший его собственный кабинет охранник
      оказался слишком уж преданным: сказано стрелять если что — он и стрелял. Через
      дверь, в которой без предупреждения зашуршали ключи. Выстрелил бы единожды —
      Савьюр имел бы шансы выжить. Всё-таки печень — не сердце и не мозг, пара часов
      на операцию была бы.</p>

    <p>Якобы кровавое пятно, ползущее по
      голубой рубашке Смирнова-Задунайского со стороны печени, — это уже переигрывание.
      Как и нездоровая бледность, как и лёгкий тремор в руках. В психологических
      воздействиях нельзя пережимать с деталями, решительно нельзя.</p>

    <p>Университет — дилетанты.
      Талантливые и старательные, и у рубашки правильные манжеты, и мысль неплохая, и
      безэмоциональные интонации верны, но, леший, кровавое
      пятно!</p>

    <p>Вот это — действительно
      несерьёзно.</p>

    <p>Андрей даже повеселел от глупости
      университетских.</p>

    <p>— <i>Нас</i> в самом деле волнует следующее, — Бахта с живейшим интересом
      рассматривал таки открывшего рот Смирнова-Задунайского. — Почему вы не
      выиграли? У вас были сведения о наших планах, у вас было время на подготовку.
      Девка Ройша, вирусолог Шапка, взятие Андрея в Медкорпусе. Судя по тому, что мы
      знаем на данный момент, у вас было всё, чтобы сдать нас с потрохами фалангам. И
      почему вы обломались-то?</p>

    <p>Хороший вопрос. Андрею он в
      голову как-то не приходил — потому, наверное, что Андрею долгое время и вовсе
      не приходило в голову, что Университет имеет хоть какие-то преимущества перед
      Бедроградской гэбней в этой борьбе.</p>

    <p>Смирнов-Задунайский молчал.</p>

    <p>Охрович и Краснокаменный взяли
      дыхание.</p>

    <p>— Девка, — просто ответил 66563.
      — Вам, профессионалам, такое и в страшном сне не приснится.</p>

    <p>Бедроградская гэбня вопросительно
      уставилась на 66563.</p>

    <p>— Вы всё равно не поверите, —
      продолжил тот, закурив. — В субботу на свидании с младшим служащим Александром
      у девки был в кармане диктофон. Она всего-навсего промахнулась мимо кнопки
      «запись».</p>

    <p>Вот теперь Андрей <i>действительно похолодел</i>, просто-таки
      почувствовал, как озноб пробирается под мундир — психологическим воздействиям
      всякими рубашками и интонациями далеко до такого эффекта.</p>

    <p>Распознавание голоса по записи —
      самая банальная и надёжная доказательная процедура во Всероссийском Соседстве.
      Если б запись была, если б она дошла до фаланг, Бедроградской гэбне и Гошке
      лично было бы не отвертеться от подвигов «младшего служащего Александра».</p>

    <p>Неужели повезло? Вот так,
      случайно и бессмысленно?</p>

    <p>— Она, что ли, совсем дура была?
      — с сомнением потряс головой Соций.</p>

    <p>— Вы шутите, — оторопел Бахта.</p>

    <p>— Лепота, — присвистнул Гошка.</p>

    <p>66563 развёл руками:</p>

    <p>— Серьёзно говорю. Сам охуел,
      когда узнал.</p>

    <p>Гошка засмеялся.</p>

    <p>Как-то слишком громко, как будто
      бы почти истерично — при условии, что у Гошки вообще может быть истерика.</p>

    <p>— Я уже смирился с тем, что меня
      малолетка развела, — сквозь смех выдавил он. — Но чтоб малолетка, которая при
      херовом рабочем диктофоне доказательств собрать не может! Пора расстрелять к
      херам конским младшего служащего Александра.</p>

    <p>66563, заразившись, расхохотался
      сам.</p>

    <p>Охрович и Краснокаменный
      захлопали своими крыльями-наплечниками в такт взрывам хохота.</p>

    <p>Смирнов-Задунайский продолжал
      молчать.</p>

    <p>Андрей плевал на
      Смирнова-Задунайского, Охровича и Краснокаменного, 66563 — Андрея волновало
      другое: Гошка сказал <i>«я»</i>.</p>

    <p>Гэбня не говорит «я», гэбня
      говорит «мы».</p>

    <p>Даже пресловутый Савьюр стал
      сбиваться на «я» только тогда, когда всё зашло уже слишком, слишком далеко.</p>

    <p>Охрович и Краснокаменный не
      сдержались, встряли-таки:</p>

    <p>— Позвольте нам расстрелять
      Александра!</p>

    <p>— Он слишком дорог нам, чтобы
      отпускать его, не попрощавшись.</p>

    <p>— Любопытно, дорог ли он сам по
      себе?</p>

    <p>— Расчёт себестоимости одного
      Александра — важный пункт сегодняшних переговоров.</p>

    <p>— Но нам нужна пара минут и
      бумажка, мы не можем считать в уме, мы с истфака.</p>

    <p>— Тем более что придётся
      учитывать инфляцию, скакнувшую в нашу уютную экономику из злобного Порта.</p>

    <p>— Порт, Александр, расстрелять —
      в это цепочке ассоциаций чего-то не хватает, не находите?</p>

    <p>— Не находят и не найдут: к
      младшим служащим же не применяют право нейтрализации четвёртого.</p>

    <p>— Потому что младшие служащие никакие
      не четвёртые, они сами по себе.</p>

    <p>— Сами по себе ошибаются, сами
      отвечают за свои ошибки.</p>

    <p>— Как жаль, что младший служащий
      Александр не существует на самом деле.</p>

    <p>— Ах если бы его одного можно
      было расстрелять за чуму в Бедрограде!</p>

    <p>— Ебало завалили, — мигом
      помрачнел Гошка. — Вас не спрашивали, кого и за что расстреливать.</p>

    <p>Охрович и Краснокаменный
      суматошно замахали руками.</p>

    <p>— Конечно-конечно, зачем нас
      спрашивать? Незачем, незачем!</p>

    <p>— Когда можно спросить вот этого
      неодетого человека с большим наплечником.</p>

    <p>66563, неодетый и с большим
      наплечником, почти виновато шикнул на них:</p>

    <p>— Закрыли вроде тему уже, нечего
      по любимой мозоли ездить.</p>

    <p>Андрей буквально кожей почувствовал,
      как вытянулся в струну Гошка.</p>

    <p>— Да ты охерел,
      — выплюнул он сквозь зубы. — Свои мозоли обхаживай, ясно?</p>

    <p>— По-моему, мы уже разобрались с
      мозолями, — пробормотал 66563.</p>

    <p>— Нет, — вдруг бухнул в тишине
      Соций.</p>

    <p>Гошка обернулся на него с
      вызовом.</p>

    <p>Соций смотрел исподлобья куда-то
      мимо.</p>

    <p>— Во-от как, — взъелся Гошка. —
      Вчера сфальшивили в паре мест, запеваем сначала?</p>

    <p>Андрей понимал: это надо
      остановить. Ругань в гэбне прямо на глазах у противника — последнее, что стоит
      себе позволять.</p>

    <p>Прежде чем Андрей успел
      сформулировать правильную реплику, высказался Бахта.</p>

    <p>— Хотелось бы прояснить детали,
      только и всего. Бедроградская гэбня пока что не всё понимает в инциденте с
      утечкой информации.</p>

    <p>Услышав «Бедроградская гэбня»,
      Гошка осёкся и всё-таки подыграл:</p>

    <p>— Бедроградская гэбня по понятным
      причинам имеет некоторые затруднения в мысленном воспроизведении алгоритма
      утечки.</p>

    <p>Охрович и Краснокаменный сделали
      глупые-глупые лица.</p>

    <p>— О, эта была она!</p>

    <p>— Синхронизация!</p>

    <p>— О которой постоянно долбят
      служебные инструкции.</p>

    <p>— Но никто не знает, существует
      ли она в природе.</p>

    <p>— Видимо, существует. Надо же.</p>

    <p>— Стоит запомнить сей показательный
      пример.</p>

    <p>— У них там лажа, но они
      стараются.</p>

    <p>— Сначала пинают друг друга, а
      потом протягивают руки помощи.</p>

    <p>— Главное — покорно протягиваются
      за руками помощи.</p>

    <p>— Наверное, самостоятельного и
      непокорного Александра уже расстреляли.</p>

    <p>Они заткнулись сами, по-птичьи
      склонили головы на бок, дополнительно подчеркнув повисшее молчание.</p>

    <p>— 66563, — улыбнулся через силу
      Андрей, — поведайте нам свою версию событий, так тревожащих умы ваших
      эксцентричных спутников.</p>

    <p>66563 посмотрел на Андрея с недовольством,
      нагловато вскинулся, заухмылялся одновременно
      приветливо и угрожающе.</p>

    <p>Он вообще последние пять минут
      выглядел как человек, которому неловко и неуютно разговаривать на заданную
      тему. Так неловко и неуютно, что пора принимать боевую стойку.</p>

    <p>— Что <i>конкретно</i> вас интересует?</p>

    <p>Андрей уважительно кивнул:
      политзаключённого с Колошмы не разведёшь на откровения обтекаемо
      сформулированными вопросами, только намечающими направление беседы. На такие вопросы
      начинают вываливать всё, что наболело, разве что совсем наивные гражданские, у
      которых волнения больше, чем здравого смысла.</p>

    <p>— Детали, 66563, де-та-ли.
      Неочевидные составляющие ваших специфических коммуникативных приёмов, вроде тех
      же неплохо замаскированных черёмуховых капсул.</p>

    <p>— Сдаёте позиции, — наклонился
      вперёд 66563. — То, как вы спрашиваете, больно явно демонстрирует, что вы, гм,
      плаваете в материале. Так плохие студенты внимание рассеивают на экзамене.
      Короче, нихуя вы не знаете, Андрей Эдмундович.</p>

    <p>Направленная агрессия — признак
      дискомфорта.</p>

    <p>Андрей устало выдохнул.</p>

    <p>— Перестаньте валять дурака,
      66563. Вы сами <i>довольно явно
        демонстрируете</i>, что эта тема вам неприятна. Мы просто хотели бы понять,
      почему.</p>

    <p>— Заебало гэбни разваливать,
      сколько можно, — отшутился 66563.</p>

    <p>С некоторой вероятностью —
      правдиво отшутился. Андрей ещё с Колошмы прекрасно помнил, что у 66563
      неуклюжие и своеобразные, но устойчивые представления о совести, чести и
      адекватном поведении.</p>

    <p>Когда Андрей прямым текстом
      предложил 66563 взять на себя убийство Савьюра, именно на эти представления он
      и опирался.</p>

    <p>Метод, зарекомендовавший себя в
      качестве эффективного, всегда можно опробовать ещё раз.</p>

    <p>— Вы непоследовательны, — с
      некоторым разочарованием указал на противоречия Андрей. — Если вас
      действительно так волнует целостность Бедроградской гэбни — во что сложно
      поверить, но примем это пока без возражений, — то зачем вы вообще пошли на
      действия, способные эту целостность разрушить?</p>

    <p>Гошка задышал громче, прерывистей
      — само собой, ему не нравится избранная Андреем стратегия.</p>

    <p>Но у Андрея нет другого выхода,
      как нет его сейчас у всей Бедроградской гэбни: Бедроградская гэбня уже поняла,
      что за утечку информации ответственен Гошка, а университетские уже поняли, что
      в Бедроградской гэбне запустились процессы саморазрушения.</p>

    <p>Запустились, пора это признать.</p>

    <p>И они будут протекать всё
      активнее и активнее, пока — хотя бы! — не будет получена точная информация об
      обстоятельствах проклятой утечки. Предположения только усугубляют дело.</p>

    <p>Единственный способ повернуть
      ситуацию в свою пользу — вынести наружу некоторое количество сора. Изба
      переживёт.</p>

    <p>— Ну вы ещё спросите, зачем я
      Колошму развалил! — почти рассмеялся 66563. — Сами знаете: так получилось.</p>

    <p>Один на один Андрей справился бы
      с 66563 легко, но они не один на один — рядом Охрович и Краснокаменный,
      пристально следящие за каждым движением, всегда готовые помочь в уходе от
      прямого ответа.</p>

    <p>— Вся чума в Бедрограде —
      сплошное «так получилось»!</p>

    <p>— Кто-то опять назвался
      Александром — так получилось.</p>

    <p>— Кто-то Александра заметил и
      признал — так получилось.</p>

    <p>— Кто-то нашёл способ пробраться
      прямо в сердце Медкорпуса — так получилось.</p>

    <p>— Кто-то оказался слишком
      сознательным и совестливым, чтобы собственноручно созданным вирусом косвенно участвовать
      в массовом убийстве людей, — так получилось.</p>

    <p>— Кто-то слишком много
      перестраховывается и помчался в Столицу по первой же телеграмме — так
      получилось.</p>

    <p>— Кто-то слишком привык
      действовать решительно и надействовался всласть,
      прежде чем подумать четырьмя головами и всё скоординировать, — так получилось.</p>

    <p>— Кто-то не знал, где у диктофона
      кнопка «запись», — так получилось.</p>

    <p>— Так получилось! Так получилось!
      ТАК ПОЛУЧИЛОСЬ!</p>

    <p>— Нам продолжать?</p>

    <p>— Намордники уже на своих
      зверюшек надень, — поверх воплей обратился Соций к 66563. — <i>Ты</i> можешь
      начистоту сказать, как именно
      получилось то, что получилось?</p>

    <p>— Нахуя? — в лоб спросил 66563.</p>

    <p>— Переговоры назначались, чтобы
      всю подобную хуйню на них и разгрести, — отрезал Соций. Хорошо не прибавил
      «слушай мою команду!».</p>

    <p>— Ну ладно, но ты сам попросил, —
      66563 вальяжно откинулся на спинку стула, Охрович и Краснокаменный тут же с
      двух сторон протянули ему прикуренные сигареты. 66563 взял обе, затянулся по
      очереди с невыносимо нахальным видом.</p>

    <p>— Вы не могли бы побыстрее
      собираться с мыслями? — раздражённо поторопил его Бахта. — У нас, знаете ли, с
      утра юбилей Первого Большого, мы не можем тут прохлаждаться бесконечно.</p>

    <p>66563 ещё раз глубоко затянулся
      от одной сигареты, потом от другой.</p>

    <p>— У вас, знаете ли, юбилей
      Первого Большого только потому, что вы в Порт чуму принесли. Не принесли бы — я
      б ваш юбилей взорвал к лешему. Был такой план, но сплыл — портовые помощники вдруг
      оказались другим заняты. Обидно. А круто бы порезвились, да?</p>

    <p>Бахта открыл рот со всем
      праведным возмущением, на которое был способен, но Соций не дал ему заглотить
      наживку:</p>

    <p>— Говори, ёба! Про то, как
      вытаскивал из Гошки планы. Про всё остальное потом потолкуем.</p>

    <p>— Ну что вы такие скучные? — впал
      в отвратительное кокетство 66563. — Вас совершенно не занимают идеологические
      мероприятия и их значение для истории нашей страны, вам бы только о себе поговорить.
      Фу.</p>

    <p>Охрович и Краснокаменный
      одобрительно заурчали государственный гимн. 66563 почтительно привстал на
      секунду.</p>

    <p>Клоуны, вот же клоуны.</p>

    <p>— Всё очень предсказуемо, —
      выпустил струю дыма в сторону Гошки 66563. — Интерес к актуальному уровню
      доступа Константина Константьевича Ройша со стороны подозрительного младшего
      служащего не остался незамеченным. Девочка-раздолбайка,
      тайно копающаяся в официальных документах, — это же локальная бюрократическая
      катастрофа. Не хватило у неё навыков бумажки в том же порядке разложить, спалилась.
      С ней пообщались. Задумались. Эротическая
      фантазия «спросить у Бедроградской гэбни об её намерениях напрямую» появилась,
      скажем так, спонтанно. И была воплощена силами интеллектуально неполноценных
      энтузиастов, которых гораздо сильнее, чем собственно допрос, интересовала часть
      «раздеть-привязать», — 66563 всё с тем же нахальным видом подмигнул Гошке. —
      Поэтому и результат допроса вышел малость бестолковый: непосредственные
      исполнители временно — и пространственно! — устранились, а интеллектуально
      полноценные господа из Университетской гэбни, будучи лишены возможности
      воспринимать сию эстетическую акцию в полной мере — у них-то аппаратура для
      записи изображений на деревьях не растёт, они звуком обходятся обычно, —
      недопоняли кое-чего. Ну, касательно запланированных масштабов разрушений.</p>

    <p>Вот оно что. Недопоняли.</p>

    <p>А Бедроградская гэбня всё
      пыталась постичь, почему в запросах к фалангам, копии которых обнаружил Гошка в
      четверг на столе у завкафа, значилось обвинение в попытке преднамеренного
      заражения жителей <i>всего одного</i> дома.</p>

    <p>66563 что-то подобное коротко
      говорил вчера Социю, но кто ж станет верить во вчерашние россказни, да ещё и с
      учётом применения 66563 наркотических веществ?</p>

    <p>Андрей поёжился.</p>

    <p>Опять.</p>

    <p>Опять 66563 озвучивал многие
      вещи, но опять не те, не о том.</p>

    <p>— Нас интересует сама процедура —
      как вы выразились — допроса, — надавил Андрей.</p>

    <p>Давить не хотелось. Не хотелось
      выспрашивать, как именно удалось заставить Гошку слить планы собственной гэбни.
      Но — нужно.</p>

    <p>— Процедура носила мистериально-исповедальный характер, но вы таких слов не
      знаете, — расслабленно потянулся всем разрисованным телом 66563. — Давайте
      знакомыми словами: потребовалось сложносочинённое материальное обеспечение, в
      основном включавшее в себя кишки и твирь, и некоторое количество медицинских
      препаратов. Никаких наркотиков, к слову. Убойное снотворное, сложная комбинация
      мышечных релаксантов для создания эффекта бестелесности и один шприц какой-то
      непонятной лично мне дряни, которая временно, но мгновенно отрубает зрительный
      нерв. Имеет, кстати, побочный эффект в виде возможной атрофии этого самого
      нерва. На глаза не жалуетесь? — с картинным беспокойством наклонился он к
      Гошке.</p>

    <p>— Буду жаловаться — сам выколю, —
      огрызнулся тот.</p>

    <p>— Как знаете. Так вот, процедура:
      сообразно выдуманной наспех мифологической картине, Загробная гэбня, — повёл
      66563 сплетённым из травы наплечником, скрывающим татуировку, — хоть и ведает
      пути живых, всё равно принуждает попавших к ней на допрос лиц самих
      проговаривать свои грехи. На четырёх уровнях. Работают по отдельности, и, хоть
      этого мы и не воплощали в полном объёме, теоретически перед каждым головой надо
      каяться о разном: от последних предсмертных ошибок у первого головы до
      полузабытых, вытесненных на периферию сознания проблем у четвёртого. А иначе,
      мол, душа не упокоится. Крайне банально, в смысле архетипично,
      и потому работает. В смысле, один раз сработало, больше модель пока не
      использовали. Дарим безвозмездно.</p>

    <p>— Хуйня всё, — громыхнул Соций. —
      Нормального человека загробными глюками не поломаешь.</p>

    <p>— Во-первых, это смотря как
      подавать, — выдал 66563 сорок восьмую уже нахальную ухмылку. — Во-вторых,
      почему же сразу «ломать»? Мы работаем тоньше, нежнее и на другом топливе! Что
      опять возвращает нас к пункту первому: <i>как
        подавать.</i></p>

    <p>66563 щёлкнул пальцами, и Охрович
      и Краснокаменный протянули ему следующие две сигареты.</p>

    <p>Это выглядело глупо. Это злило.
      Это было плохо — как минимум тем, что университетские таким вот примитивным
      способом демонстрировали, что они-то запросто могут выбить из Бедроградской
      гэбни те эмоции, которые захотят.</p>

    <p>— Мы уже достаточно
      заинтригованы, так что ваш балаган не является необходимым, — высказался
      Андрей.</p>

    <p>— Не давите, мы и так изо всех
      сил стараемся сдерживать свои порочные привычки, — 66563 и две сигареты в его
      руках уставились в потолок. — Один информационный пласт, безусловно, не годится
      для результативной коммуникации с головой агрессивно настроенной гэбни.
      Пришлось создавать два: с одной стороны — загробные видения, с другой — их
      предварительная рационализация через простые и понятные вещи. Всё те же
      наркотики — которые, повторим для лучшего усвоения, на деле задействованы не
      были. Чтобы подготовить объект к столкновению с потусторонним миром, сначала
      пришлось склонить его к приёму некоего якобы наркотического препарата и
      подробно расписать действие оного: зрительные галлюцинации высокой степени
      связности, которые постепенно теряют сначала зрительность,
      а потом связность. Кстати насчёт зрительности — вы б
      записали, Андрей Эдмундович. Как раньше, в тетрадочку. Чрезвычайно продуктивная
      ведь методика: если таки вовлечь объект в происходящее, а потом перекрыть ему
      мощный канал восприятия, диалог выходит на диво содержательный. Этакий разговор
      допрашиваемого с самим собой, только и остаётся малость направлять.</p>

    <p>Продуктивные методики и отсылка к
      тетрадочке, которая и правда была у него во времена Колошмы, сейчас волновали
      Андрея меньше всего.</p>

    <p>Склонить к приёму чего-то, что
      выдали за наркотический препарат?</p>

    <p>Склонить к приёму наркотического
      препарата?</p>

    <p>Леший, Гошка не может быть таким
      дураком, просто не может!</p>

    <p>— Ты б лучше рассказал, как Врату
      склонял, — выплюнул Гошка, — ш-шаман.</p>

    <p>Врату?</p>

    <p>Женщину «для психической
      разгрузки» из Порта?</p>

    <p>Андрей предупреждал, давно же
      предупреждал, что подобные связи ничем хорошим не —</p>

    <p>— Шшш,
      ты не дёргайся, не дёргайся, мертвяк, — совсем другим, каркающим и резким,
      голосом прохрипел 66563. — Ты мне тут не выговаривай, мертвяк, я получше твоего
      знаю, чего да каким порядком рассказывать.</p>

    <p>Андрей скосил глаза на Гошку: тот
      старался держаться расслабленно, но забившаяся на виске жила портила
      впечатление.</p>

    <p>— Баба-то у тебя портовая, —
      66563 застрял где-то между художественным хрипом и своими обычными интонациями.
      — А в Порту представителей государственной власти не любят, ой не любят. Власти
      — к несчастью и растратам, тебе не понять. Портовые, они ж суеве-е-ерные.
      И идеологические как жопа, а со стороны и не разглядишь. Анархисты они, никакой
      власти спуску не дадут — сам будто не знаешь? Знаешшшь-знаешшшь,
      рисковый ты мой, раз молчал и не отсвечивал. Правильно делал: как услышала
      баба, что ты из этих, так и всё, разлюбила. Твоя мордашка против наплечника не
      аргумент.</p>

    <p>Гошка немного помолчал с
      нечитаемым выражением лица.</p>

    <p>— Сделали, бляди, — наконец
      ответил он, почти что восхищённо прищёлкнув языком. — Ну чего — круты вы,
      круты, успокойтесь. И лично ты ничего, шаман.</p>

    <p>— Да я б с тобой тоже поближе
      пообщался, — двусмысленно ухмыльнулся 66563. — Меня-то наплечники, наоборот,
      радуют.</p>

    <p>У Андрея аж челюсть свело: они на
      переговоры пришли, а не на торжественный смотр коммуникативного арсенала 66563!
      Всё как по писаному: сначала сорок восемь извинений, доверительный тон, потом
      деловой тон, потом откровенное и подростковое какое-то хамство, потом качели от
      высокопарного слога к портовой брани и обратно, потом немного картинных перевоплощений,
      а потом — венец риторического мастерства 66563! — неуместное предложение секса.</p>

    <p>Как же всё это опостылело, сил
      нет.</p>

    <p>— Радоваться наплечникам в другом
      месте будешь, — постучал пальцами по столешнице Гошка. — Ты вроде как по
      тюремным гэбням специализируешься? Вот к ним и валил бы. Может, сдать тебя как
      беглого заключённого обратно, а?</p>

    <p>— Лады, усёк, — будто бы покорно
      кивнул 66563. — Тебе давать не нравится, тебе <i>сдавать</i> веселей, да? Беглых заключённых — Колошме, промахи
      Университета с канализациями — фалангам, собственную гэбню с потрохами —
      известно кому.</p>

    <p>— Тебе жить надоело? — непривычно
      тихо осведомился Гошка.</p>

    <p>— Это тебе надоело. В гэбне
      сидеть, — 66563 так и не снял с лица двусмысленную ухмылку. — Вытащишь на
      официальный уровень, кто я такой, и кто-нибудь вытащит туда же наши с тобой
      разговорчики. Пока я никто, их как бы и не было, а как стану обратно беглым
      заключённым — они поимеют какую-никакую юридическую силу. Мне похуям, я, может,
      скучаю тут по своей одиночной камере, а
      тебе прилетит. Вам всем четверым прилетит. Помнишь хоть, чё сдавал?</p>

    <p>— Помню, — накренился вперёд
      Гошка. — И как свести твою херову юридическую силу в ноль — тоже помню, уж не
      сомневайся.</p>

    <p>— Ещё пара реплик, и я тебе выйти
      поговорить предложу! — заржал вдруг 66563.</p>

    <p>О да.</p>

    <p>Как же Андрей мог забыть:
      риторический навык номер леший-знает-какой — традиционно посмеяться в самом
      остром месте, обратить всё нелепостью.</p>

    <p>Охрович и Краснокаменный,
      собранно наблюдавшие за диалогом, мигом поскучнели.</p>

    <p>— А мы-то надеялись, вы друг
      друга убьёте!</p>

    <p>— У нас и закуски, и пиво
      заготовлены, а ничего не происходит.</p>

    <p>— РАЗОЧАРОВАНИЕ!</p>

    <p>— Полный провал.</p>

    <p>— Опять новомодная радиопьеса со сплошной болтовней и без, собственно,
      действия.</p>

    <p>— Одумайтесь, грешники, ещё не
      поздно.</p>

    <p>66563 только сильнее откинулся на
      спинку стула, зажмурился, почти зевнул.</p>

    <p>— А ты сам-то готов одуматься,
      шаман? — мрачно усмехнулся Гошка. — Ты пока никто, а значит, и цена тебе
      —&nbsp;ничего.</p>

    <p>66563 лениво приоткрыл левый
      глаз.</p>

    <p>В нём сейчас было столько
      нахального удовлетворения жизнью, что Андрею немедленно захотелось пристрелить
      его прямо тут.</p>

    <p>Почему, почему этот человек до
      сих пор не умер? И почему Андрей к этому причастен, какого лешего он за десять
      лет помешал аж двум пистолетам выстрелить в 66563?</p>

    <p>— Давайте уже приступать к
      актуальным делам, а не топтаться на просроченных, — снова подогнал раздражённый
      Бахта.</p>

    <p>Он прав и неправ одновременно:
      самое существенное — что делать дальше, но без знания некоторых «просроченных»
      деталей выстроить стратегию куда сложнее. С утечкой планов через Гошку, кажется,
      теперь всё понятно, но ведь есть ещё так много неочевидного: как фаланги
      оказались в Медкорпусе во время разговора Андрея с Шапкой, на каких условиях у
      Университета была установлена изначальная договорённость с Портом, насколько
      они способны договориться с Портом сейчас — по поводу всё той же несчастной
      блокады, например.</p>

    <p>— Мы с шаманом ещё не оказали
      друг другу всех приличествующих случаю любезностей, — прервал Гошка поднявшего
      было какой-то практический вопрос Бахту.</p>

    <p>Так не делают, головы гэбни не спорят
      при свидетелях!</p>

    <p>Андрей укоризненно чуть обернулся
      к Гошке.</p>

    <p>— Хочу повторить для
      отсутствующего протокола, — начал Гошка, и Андрей вдруг заметил, что его правая
      рука больше не лежит на столе, а упёрта в бок, — ты меня сделал, конский хер.
      Всухую. Я только в эту пятницу допёр, чего не так. Хочешь честно? Мог бы и
      вовсе не допереть — отлично сработано, не подкопаешься. И знаешь, что это
      означает? — Гошка взял короткую совсем паузу перед следующей фразой, но её
      хватило, чтобы перенапрягшийся от ожидания Андрей не сдержался, чуть дёрнулся
      инстинктивно в сторону Гошки. — Что ты для меня слишком крут, — закончил Гошка
      и всё-таки сделал это.</p>

    <p>Молниеносным, почти незаметным
      движением позиция «рука на поясе» сменилась позицией «рука над столом с
      пистолетом».</p>

    <p>Он даже выстрелил, но слишком
      поздно. На какие-то глупые, нелепые доли секунды его опередили Охрович и
      Краснокаменный.</p>

    <p>Наверное, среагировали на
      дёрнувшегося Андрея.</p>

    <p>Выстрел Гошки получился
      смазанным, ушёл куда-то вправо, 66563 даже отгибаться не пришлось.</p>

    <p>Пистолет с почти что нежным
      металлическим стуком хлопнулся на стол.</p>

    <p>Гошка зашипел сквозь зубы,
      вывалил гору какой-то брани.</p>

    <p>Андрей не слышал — <i>они его не убили</i>, всё-таки не убили.
      Побоялись разбирательств с фалангами, чего-то ещё, просто не захотели — они же все
      дилетанты и клоуны в своём Университете, дилетанты и клоуны.</p>

    <p>Они его не убили.</p>

    <p>— Прекратите голосить, Гошка
      Петюньевич.</p>

    <p>— Младший служащий Александр на
      вашем месте бы сдержался.</p>

    <p>— Младший служащий Александр —
      идеальный человек.</p>

    <p>— Как жаль, что его уже расстреляли.</p>

    <p>— Мы были с вами гораздо
      милосерднее.</p>

    <p>— Мы даже не позволили пулям
      встретиться у вас в руке!</p>

    <p>— Представляете, как это было бы
      неприятно?</p>

    <p>— У вас ерундовое ранение, мы за
      один сегодняшний день видали похуже.</p>

    <p>Гошка шипеть не перестал. Бахта и
      Соций, сверкая глазами, держались за кобуры. Готовы, но ждут отмашки. Андрей
      всё не мог нащупать во внутренних карманах мундира ампулу обезболивающего —
      умудрился забыть, с какой она стороны.</p>

    <p>66563 ловко подхватил со стола
      пистолет Гошки, взвесил, прицелился — но прицелился демонстративно в сторону
      двери.</p>

    <p>— Всё-таки табельные стволы у
      гэбен клёвые. Надо хоть один из тех, что попадают ко мне в руки, прикарманить.</p>

    <p>— Охерел?
      — рявкнул Гошка, аккуратно задирая окровавленный рукав.</p>

    <p>— Я ещё не решил, — всё с той же
      ленцой ответил 66563. — У меня вторая плетёная кобура на форменной юбке не
      предусмотрена, держать его негде, — 66563 медленно и лениво достал собственный
      пистолет левой рукой.</p>

    <p>Вышло как с двумя сигаретами,
      только в разы претенциозней.</p>

    <p>Андрей нащупал ампулу. Прямо на
      столе, у всех на виду зарядил шприц.</p>

    <p>— Короче, я им попользуюсь, но
      временно, — постановил бесконечно самодовольный 66563. — Верну-верну, не
      переживай, только в Порту похвастаюсь. А тебе он всё равно пока ни к чему с
      твоим ранением — кто в ИВА не служил, тот с обеих рук не стреляет, так ведь?</p>

    <p>— А ты как будто стреляешь, —
      огрызнулся Гошка, вероятно, от злости. Уж Бедроградская-то гэбня видела как
      минимум одного солдата ИВА, который спокойно пользуется обеими руками для
      стрельбы.</p>

    <p>Андрей придвинулся поближе к
      Гошке, помог поднять рукав мундира повыше, а рубашки — оторвать с концами.
      Почти не глядя уколол в предплечье, на автомате наложил повязку из обрывков
      рубашечного рукава.</p>

    <p>Белочки почему-то не было.</p>

    <p>Был не-убитый Гошка и странно
      чувство не-беспокойства: всё уже произошло, дальше ничего страшного быть не
      может, просто не может и всё.</p>

    <p>Тот же табельный пистолет с
      личным номером Гошки на руках у постороннего — это кошмар, это тысячи хождений
      по фалангам, если всплывёт, это до отстранения от должности может дойти, до
      отстранения от должности всех четверых тоже, но сейчас Андрей точно знал: они
      справятся, вывернутся, разберутся. Есть способы и рычаги, есть методы и опыт.</p>

    <p>Надо просто закончить переговоры
      и выйти на своих ногах с этого проклятого склада.</p>

    <p>И всё будет нормально.</p>

    <p>— Ты, наглая рожа, всё-таки не
      зарывайся, — замогильно прогудел Соций. — Одного продырявили и счастливы. Но
      я-то, если что, получше твоего с обеих рук могу. И с продырявленных тоже, сам
      знаешь.</p>

    <p>Охрович и Краснокаменный
      переглянулись.</p>

    <p>— Он предлагает нам укладывать
      его сразу в голову?</p>

    <p>— Он не многовато ли на себя
      берёт?</p>

    <p>— Он хотел бы посоревноваться в
      стрельбе <i>с нами</i>?</p>

    <p>— Дядя, ты в курсе, что у нас в
      плюшевых грифоньих лапах антиквариат?</p>

    <p>— Который и оружием-то по
      документам не считается — так, музейной безделушкой.</p>

    <p>— И осечки даёт по полметра, но
      мы справляемся.</p>

    <p>— Дядя, ты уверен, что хочешь
      увидеть нас с табельным?</p>

    <p>— А если в рукопашную? —
      плотоядно воззрился на них Бахта, который даже сидя производил за счёт таврской
      комплекции довольно устрашающий эффект.</p>

    <p>— А если динамитом? — ляпнул
      вдруг ни с того ни с сего Гошка. — Откуда вы знаете, что склад не заминирован?</p>

    <p>Охрович и Краснокаменный
      возмущённо хлопнули наплечниками-крыльями.</p>

    <p>— Откуда вы знаете, что
      скрывается под нашим розовым плюшем?</p>

    <p>— Гранаты?</p>

    <p>— Винтовки?</p>

    <p>— Огнемёты?</p>

    <p>— Хлысты?</p>

    <p>— Отбойные колотушки?</p>

    <p>— АЛЕКСАНДР?!</p>

    <p>— Плохая шутка, повторённая
      тысячу раз?</p>

    <p>— Чтоб вы сдохли вместе с
      Александром! — очень искренне проорал Гошка.</p>

    <p>— Холодное оружие забыли, — с
      весёлой какой-то яростью заёрзал на стуле Бахта.</p>

    <p>— ДА ЗАТКНИТЕСЬ ВСЕ УЖЕ, — очень
      громким и очень ровным голосом перекрыл гул 66563. — Всё равно никто не
      подерётся, всё равно как обычно закончится тем, что я с кем-нибудь прямо тут
      потрахаюсь, и мы все разбежимся в ужасе и соплях.</p>

    <p>66563 задумчиво воззрился на
      пистолет Гошки в своей правой руке, потом на Гошку, а потом всё с тем же
      невыносимым видом выразительно провёл языком по выгравированному вдоль ствола
      личному номеру.</p>

    <p>— Полегче! — возмутился Гошка. —
      Он дорог мне как память о гэбенной службе.</p>

    <p>— Раньше надо было думать, —
      помотал головой 66563. — Слюна Загробной гэбни способна прожечь металл! Так что
      номер я слижу подчистую какой-нибудь особенно тоскливой ночью, извиняй.</p>

    <p>Гошка попытался отмахнуться, но
      простреленная рука напомнила о себе, выдернув его на какое-то время из
      переговоров. То есть из того, во что они всеобщими усилиями превратились.</p>

    <p>— Послушайте, — воспользовался
      паузой Андрей, — мы действительно сидим здесь уже изрядное количество времени и
      до сих пор не достигли и половины стоящих перед всеми нами целей. Давайте
      попробуем отвлечься от эротических и смертоубийственных идей и вернуться к
      делу. Чума в городе благодаря нашим общим усилиям гаснет, но она всё ещё есть.
      Помимо этого, необходимо закрыть уже наконец ряд спорных вопросов, относящихся
      по большей части к изначальным нашим предпосылкам и мотивациям. Вы недовольны
      нами, а мы, как можно было догадаться, вами.</p>

    <p>— И фактом существования
      Университетской гэбни, — пробормотал Гошка сквозь сжатые зубы.</p>

    <p>Состояние его руки некритичное,
      но дискомфорта причиняет немало.</p>

    <p>— Не фактом, а тем, как она
      работает, — весомо не то уточнил, не то возразил Соций.</p>

    <p>— Я — фактом, — отбрил Гошка, но
      Андрей даже не стал пинать его под столом по поводу «я» вместо «мы». Ему
      больно, ему можно.</p>

    <p>Охрович и Краснокаменный
      нахохлились в своих птичье-собачьих шкурах.</p>

    <p>— Факт мы вам не уступим, не
      надейтесь.</p>

    <p>— Это наше последнее слово.</p>

    <p>— Университетская гэбня есть и
      будет.</p>

    <p>— И готова держать оборону
      сколько понадобится.</p>

    <p>— И сдастся она только Бюро
      Патентов, так и знайте.</p>

    <p>— Уберите свои грязные руки от
      наших чистых надежд.</p>

    <p>Соций опять обратился лично к
      66563, игнорируя Охровича и Краснокаменного:</p>

    <p>— Наглая рожа, ну ты-то
      врубаешься, что так не работают? У вас там всё через жопу, да ещё и мертвецы на
      подхвате. А гэбня должна быть самостоятельной, иначе труба.</p>

    <p>— Траншея, — кивнул 66563. Даже
      стал умеренно серьёзным, хоть и не убрал несчастные два пистолета.</p>

    <p>— Ты не вечный, — гнул свою линию
      Соций. — Никто не вечный. Должен работать институт, а не отдельные люди по собственной
      прихоти. Иначе — хуйня.</p>

    <p>— Да не так уж и много сделали
      отдельные люди, — как-то грустно и невпопад заявил 66563.</p>

    <p>— А что ж ты тогда тут сидишь? Мы
      хотели говорить с <i>реальной</i> университетской властью.</p>

    <p>— Кто может, тот и сидит, — пожал
      плечами 66563.</p>

    <p>Охрович и Краснокаменный с
      мрачными лицами подхватили:</p>

    <p>— Сидит тот, кто может сидеть.</p>

    <p>— И ходить. Хотя бы при поддержке
      других ходящих.</p>

    <p>— Остальные только ползают.</p>

    <p>— Им до этого склада три недели,
      ползком-то.</p>

    <p>— Пожалейте увечных, дайте им
      умереть спокойно и скучно.</p>

    <p>— И осознайте уже, что в данном
      случае «реальная власть» не означает «те, кто во всём виноваты и всем
      ворочали».</p>

    <p>— Виноваты все.</p>

    <p>— А ворочал — случай и прочие
      «так получилось».</p>

    <p>— В данном случае «реальная
      власть» означает всего лишь «те, кто не сломались к девятому дню».</p>

    <p>— «Те, кто к девятому дню всё ещё
      способны отвечать за дерьмо, которое навалили».</p>

    <p>— А вы-то думали.</p>

    <p>— Лохи.</p>

    <p>Гошка посмотрел на Охровича и
      Краснокаменного с интересом.</p>

    <p>Высказался, тем не менее, Бахта:</p>

    <p>— Мы тронуты вашей отповедью, но
      она совершенно не отвечает на вопрос, что же делать дальше. Нас в первую
      очередь беспокоит возможность нормального сосуществования в пределах одного
      города. И — уж простите нам профессионально деформированную узость наших
      взглядов — мы всерьёз полагаем, что таковое сосуществование наиболее вероятно в
      том случае, если Университетская гэбня будет выступать в профессиональных
      вопросах от своего имени и без оркестра сочувствующих.</p>

    <p>— А ещё пару дней назад хотели под
      корень их вырезать вместе с херовыми первокурсниками херова истфака, — как-то
      едва ли не с ностальгией брякнул Гошка. — Ну что же вы, гэбня города
      Бедрограда!</p>

    <p>— Лажовая
      цель была, — упрямо продолжил Соций. — Не в гэбне ж в самом деле проблема, а в
      людях в ней и вокруг неё.</p>

    <p>Гошка не ответил, посмотрел
      куда-то в сторону. Андрею очень захотелось прикоснуться под столом, подбодрить
      — Андрей всё ещё не смирился с тем, что надо идти на какие-то там уступки университетским.
      Не надо, ну нет же!</p>

    <p>Соций и Бахта цепляются сейчас за
      компромисс, потому что устали, они ведь не всерьёз, их ведь можно переубедить,
      но для этого нужно время и Гошка. Гошка бы напомнил им, почему и за что шла эта
      война. Но в последние два дня Гошка был сам не свой, пустил на самотёк дела в
      своей гэбне — вот теперь всё и трещит по швам.</p>

    <p>Идёт трещинами, которые снились
      Андрею после похода к Молевичу.</p>

    <p>— И, ёба? — нахмурился 66563.</p>

    <p>— И ты, ёба, пиздуешь
      из этого города со своей кассахской шлюхой и прочими
      толпами мертвецов, которые у тебя в кармане, — командирским тоном начал
      излагать условия Соций. — Спаливать и сдавать вас
      обратно Колошме мы не будем, но уж дать ваши фотоснимки с чужими именами и
      правильными легендами своим служащим можем легко. Увидят в городе — уебут.
      Хочешь — отсиживайся в Порту, но чтоб тихо. Будешь лезть в наши дела — и в
      Порту как-нибудь найдём, есть способы.</p>

    <p>— Командир, ты сейчас всерьёз всю
      эту волну гонишь? Ты всерьёз думаешь, что я тут такая значимая фигура, вокруг
      которой надо столько шуму поднимать?</p>

    <p>— Мы не хотим об этом думать, —
      вступился Бахта. — <i>Никогда больше</i> не
      хотим об этом думать, не хотим гадать, кто какая фигура и вокруг кого вихри
      крутятся.</p>

    <p>— Заебала до смерти ваша
      размазанная власть, — согласился Соций. — Виноват, не виноват, значимый,
      незначимый — нет своего наплечника, еби подальше. Все ебите, сколько бы вас там
      ни было. Ясно?</p>

    <p>— Ясно, — 66563 кивнул. — Ещё
      чего вам отрезать и в рот пол��жить?</p>

    <p>— Смену состава гэбни, — отдал
      очередной приказ Соций. — Нынешние — психи, им веры уже нет.</p>

    <p>— Мы понимаем, что это сложно, —
      продолжил за него Бахта. — Но у вас и так перемены из-за самовольного
      увольнения Молевича, можно под эту же лавочку и остальное провернуть. Возможно,
      имеет смысл оставить кого-то одного из прежнего состава. Это не даст работе
      гэбни сильно забуксовать в процессе перестройки. Но перестройка нужна.</p>

    <p>Охрович и Краснокаменный
      проехались взглядами по Бедроградской гэбне туда и обратно. Вздохнули.</p>

    <p>— Вас просто достала шутка про
      Александра.</p>

    <p>— И костюмированные вечеринки.</p>

    <p>— Вы несчастные люди без чувства
      юмора.</p>

    <p>— И чувства вкуса.</p>

    <p>66563 аутично пробормотал:</p>

    <p>— Такие масштабные перемены не
      совсем в юрисдикции Университета.</p>

    <p>— Ну приехали, — насел на него
      Гошка. — Как Молевичу уходить — так у вас специальные инструкции, согласно
      которым только действительный сотрудник БГУ им. Набедренных может в
      Университетской гэбне сидеть, а как отвечать за свои слова — так в кусты?
      Может, упразднить-таки вашу гэбню как факт?</p>

    <p>— Головой думай, — скривился
      66563. — Не захотят наверху радикально реформировать состав, просто не дадут из
      Университета уволиться и всё.</p>

    <p>— Положение о Трудоустройстве
      почитай, — фыркнул Гошка.</p>

    <p>— Устав Университета почитай, —
      отзеркалил недовольство 66563. — Ту часть, которая не к студентам относится.
      Ответственность преподавателя перед вверенными ему юными долбоёбами и вся
      хуйня. Там есть на чём сыграть, если надо. Мне самому так дважды заявление об
      уходе с кафедры заворачивали, только Колошма и выручила.</p>

    <p>— Да сжечь его пора, этот Устав,
      — раздражённо затих Гошка, сморщившись от очередного неловкого движения
      простреленной рукой.</p>

    <p>— Разберётесь, — прервал
      перепалку Соций. — И это, блядь, добавьте штрих к парадному портрету: кто ж
      таки заразил завкафов дом? Принципиальный вопрос уже.</p>

    <p>Вот сдался Социю этот завкафов
      дом! Андрей устало потёр глаза.</p>

    <p>66563 на вчерашней встрече
      правильную ставку сделал — Соция клинит на
      предательстве в рядах своих, он даже за завкафский дом именно поэтому так
      уцепился. Хочет узнать, «кто в Университете падла».</p>

    <p>Ох.</p>

    <p>66563 издал какой-то
      неопределённый звук: мол, он ещё вчера свои соображения высказал. Нет, мол,
      соображений.</p>

    <p>Охрович и Краснокаменный,
      разумеется, приняли удар на себя.</p>

    <p>— Вас интересует, какой человек?</p>

    <p>— Мы бы сперва посоветовали
      разобраться с вопросом «мы или вы».</p>

    <p>— Ответ на него не представляется
      самоочевидным.</p>

    <p>— Настоящая синхронизация
      работает только у нас двоих. Ваша — жалкое подобие.</p>

    <p>— Вам ведь даже чтобы мысли
      понять нужно пользоваться ногами!</p>

    <p>— Мысли — и ногами. Чуете подвох?</p>

    <p>— Ноги далеко от головы.
      Неизбежны провалы коммуникации.</p>

    <p>— И потом, разве важно, кто
      заразил нашего любимого Габриэля Евгеньевича?</p>

    <p>— Мы все здесь прогрессивные и
      профессиональные люди, ориентированные на результат.</p>

    <p>— Результат: все живы, кроме
      чумы.</p>

    <p>— Может, лучше обнимемся?</p>

    <p>С одной стороны, их подколки про
      «мы или вы» нервировали.</p>

    <p>С другой стороны, Андрей никогда
      не думал, что будет настолько согласен с каким-либо практическим выводом
      Охровича и Краснокаменного.</p>

    <p>Все живы, давайте обнимемся.</p>

    <p>Давайте, клоуны в розовых
      костюмах.</p>

    <p>Соций оглядел клоунов с
      недоумением — не верит, что ответа нет, хочет знать наверняка. Бахта
      ухмыльнулся — находит своё какое-то странное удовольствие в абсурдности
      ситуации. Гошку сейчас гораздо больше занимает собственная рука.</p>

    <p>Андрей выдохнул облегчённо.
      Поверил на миг, что всё уже закончилось.</p>

    <p>После чего университетская
      сторона напомнила вдруг, что она сегодня представлена не только 66563 и
      Охровичем и Краснокаменным.</p>

    <p>Не только.</p>

    <p>— Случайность, — второй раз за
      все переговоры подал голос Смирнов-Задунайский, — просто случайность. Все так
      много и так искренне задаются этим вопросом, что невольно становится ясно:
      виноватого-то нет. Габриэль Евгеньевич заразился, и все попытались этим
      воспользоваться, но так неловко и бессмысленно, что получившаяся история в
      итоге как-то не напоминает продуманный план. Случайность, чья-то ошибка. Тот,
      кто ошибся, вряд ли хотел того, что вышло, он может даже не помнить об этом — не
      помнить о своих деяниях вообще нынче модно, —&nbsp;с бесцветной полуулыбкой он
      чуть развернулся к Гошке. — Потому что если кто-то целенаправленно и осознанно
      заразил Габриэля Евгеньевича, то о нём можно сказать только одно: какой же он
      всё-таки глупый, этот кто-то.</p>

    <p>Смирнов-Задунайский ни разу не
      взглянул на Андрея, пока говорил.</p>

    <p>Это было очень, очень заметно —
      они ведь сидели друг напротив друга, при таком расположении трудно и странно
      избегать зрительного контакта.</p>

    <p>У Смирнова-Задунайского почему-то
      совсем тихий, безэмоциональный голос и голубая
      рубашка Савьюра, под Савьюра, как у Савьюра.</p>

    <p>То, что он говорил, — здраво и
      рационально, а ещё до отвращения человеколюбиво, только эта любовь тоже
      какая-то безэмоциональная, бесцветная, плоская: люди
      несовершенны, <i>все люди</i> несовершенны.
      Люди — грязь, то есть скорее пыль, то есть всё это ерунда, кто кого подставил,
      кто кого заразил; мог — любой, потому что любой — пыль, так какая к лешему
      разница, оставим эти танцы, оставим, оставим, кому сдались нудные выяснения,
      всё равно это уже ни на что не влияет, всё равно ничего не вернёшь, так оставим
      танцы и немного милосердия для виновника, оставим его в покое, оставим,
      оставим, оставим.</p>

    <p>То, что он говорил, было как у
      Савьюра, под Савьюра.</p>

    <p>То, что он говорил, было как
      будто он и есть Савьюр.</p>

    <p>— Нихуя себе случайность! —
      упорствовал Соций. — Не бывает таких случайностей. Это ж целый ёбаный жилой
      дом, ещё и на четыреста квартир. Там в отстойнике фильтры пятилетней, кажись,
      давности. И, как выяснилось, они не проржавели в хлам, реактивы в них почти все
      непросроченные. Не вспоминая уже о том, что вентиль кто-то поворачивал и чуму в
      канализацию кто-то запускал. Значит, у кого-то были некие намерения, мотивы и
      цели.</p>

    <p>— Необязательно, кстати, — неожиданно
      выдал Гошка. — Мог быть один подцепивший заразу в другом месте, который зашёл
      потом в свой родной херов сортир, и один повёрнутый почему-то, хоть бы и
      случайно, вентиль безотходного оборота воды — и всё, привет всему дому. Мог
      ведь?</p>

    <p>Андрей даже не понял сразу, что
      за подтверждением своей гипотезы Гошка обращается к нему.</p>

    <p>Голубая рубашка обращалась
      гораздо громче и внятней.</p>

    <p>— Мог. Наверное, — закивал Андрей
      и хотел на том и остановиться, да только рот как-то сам по себе, не подчиняясь
      приказам, артикулировал всё новые и новые слова: — Но это… малопредставимо.
      Дом 33/2 по проспекту Объединённых Заводоводств и улицы Поплеевской
      предположительно был заражён в прошлое воскресенье. Если первые инфицированные
      появились в субботу, к воскресенью вирус ещё никак не мог передаваться
      естественным путём, поскольку инфекция… впрочем, детали излишни, но тут не надо
      быть медиком, чтобы понимать… вирус входит в соответствующую фазу развития не
      раньше третьего дня с момента попадания в организм, там сложный инкубационный период…
      так что даже если бы вентиль был повёрнут неумышленно…</p>

    <p>— Вы представьте себе этот
      вентиль! — от души посмеялся Бахта. — Он за пять лет без ухода разве что
      тисками провернётся, а неумышленно и просто так за тисками не пойдут.</p>

    <p>Странно, очень странно: нормальное,
      привычное рабочее обсуждение делового вопроса Бедроградской гэбней и — при
      посторонних. Ничего особенного вслух не прозвучало — ни неположенной по уровню
      доступа информации, ни просто каких-то совсем уж внутренних моментов, — но,
      но&nbsp;—</p>

    <p>Совсем как когда за очной ставкой
      по поводу нереализованного ПН4 подглядывали фаланги: задёрнуть бы шторы! Зачем
      они смотрят, это <i>наши</i> дела.</p>

    <p>— Вентиль ведь мог стоять
      открытым сколько угодно, жители дома не заметили бы разницы, — спокойно
      вклинился в рабочее обсуждение Смирнов-Задунайский. — Вы уверены, что его не
      провернули год назад? Два года? А что касается вируса, господа, все вы как
      будто забываете, сколько человек к нему <i>на
        самом деле имели доступ</i>. У вас же целые склады стояли, — он еле заметно
      пожал плечом. — Один раз кто-нибудь недостаточно хорошо закрыл склад,
      кто-нибудь другой без особой цели украл пробирку, перепродал третьему, а тот не
      знал, что с этим полагается делать… Да вирус ещё в Медицинском корпусе мог
      утечь. И тот, по чьей вине это произошло, теперь об этом и не вспомнит. Он же
      никого специально не заражал, просто допустил какую-то мелкую оплошность,
      которой сам не заметил. И не считает себя виноватым. — Смирнов-Задунайский
      снова не смотрел на Андрея, Смирнов-Задунайский смотрел на кого угодно, кроме
      Андрея. — Я могу ошибаться, конечно, потому что мне никак не придумать, зачем
      же это могло быть кому-нибудь нужно. Со всеми этими взаимными планами
      совершенно забываешь, что иногда люди совершают поступки просто так, без
      внятной цели.</p>

    <p>Просто так, без внятной цели.</p>

    <p>Потому что все люди — пыль. Какие
      у пыли могут быть внятные цели?</p>

    <p>Андрей и рад был бы прислушаться
      к аргументам Смирнова-Задунайского, но голубая рубашка и совершенно Савьюрова демагогия вызывали инстинктивное отторжение,
      желание отгородиться.</p>

    <p>А ещё — Смирнов-Задунайский всё <i>не смотрел и не смотрел</i> на Андрея.</p>

    <p>— Говоришь так, будто кого-то
      покрываешь, — прищурился Соций, — кассахская шлюха.</p>

    <p>«Кассахская шлюха» — это ниточка,
      которую нельзя, никак нельзя сейчас отпускать. Андрей расслабился и продышался:
      кассахская шлюха, кассахская шлюха, кассахская —</p>

    <p>Как же хорошо, что рядом Соций с
      его животным каким-то недоверием к кассахам! Не даёт окончательно слиться
      Смирнову-Задунайскому с несчастной голубой рубашкой, невольно напоминает: это
      игра, это ложь, психологическое воздействие, психическая атака.</p>

    <p>Это <i>другой</i> человек.</p>

    <p>— Только если в рамках поступка
      без внятной цели. Соций Всеволодьевич, здесь, кажется, уже все присутствующие
      покаялись в куда более страшных грехах. Заражение одного дома, к чему бы оно ни
      привело, — это же мелочь в масштабах чумы. Зачем мне или кому-то ещё её так
      отчаянно скрывать? Совсем чистеньким <i>уже</i> никто не вышел.</p>

    <p>Смирнов-Задунайский полуулыбнулся. Не Социю, не кому-то ещё — скорее себе
      самому или даже всей эпидемии разом.</p>

    <p>И от этой полуулыбки — скованной,
      прозрачной и безадресной — у него по лицу поползли трещины.</p>

    <p>Андрей моргнул.</p>

    <p>Трещины бежали во все стороны — к
      волосам (чёрным с неожиданной сединой, абсолютно кассахским!),
      под ворот рубашки. Голубой рубашки Савьюра.</p>

    <p>Лица над рубашкой из-за трещин
      уже не было видно.</p>

    <p>Где-то там справа, совсем рядом,
      Соций говорил что-то про обтекаемую позицию и кассахских шлюх. Андрей не
      слышал, Андрей просто хотел сейчас быть как Соций — за цветом волос и разрезом
      глаз не замечать больше ничего, ничего, ничего.</p>

    <p>Трещины успели скрыть
      потрескавшиеся, пожелтевшие от лабораторных трудов руки. С тыльной стороны
      правой ладони и вовсе темнела уродливая клякса неочевидного происхождения, и
      трещины тянулись и тянулись к ней, врастали в неё, тонули в ней своими острыми,
      колкими, шипованными концами.</p>

    <p>Потому что не клякса это, это размазанная
      бездна на тыльной стороне ладони, трупное пятно, клеймо мертвеца, метка,
      опознавательный знак — <i>я оттуда</i>; и
      если замечаешь его, противиться уже невозможно, невозможно твердить себе, что
      клякса и клякса, что леший с ней, что на этих руках и без неё немало привычных
      любому, кто хоть немного проработал в лаборатории, следов химических
      воздействий —</p>

    <p>Андрей прикрыл глаза: химические
      воздействия. Университет постоянно использует наркотики как средство
      дополнительного влияния и подстраховки, вчера у 66563 при себе был какой-то
      уникальный препарат — и это доподлинно известно, хоть анализы ничего и не
      показали, но 66563-то сам только что подтвердил.</p>

    <p>Надо немедленно что-то предпринять
      — если они как-то (как?) пустили в ход наркотики, переговоры не могут, не
      должны продолжаться. Это опасно, неразумно, это может привести к плачевным
      последствиям!</p>

    <p>Андрей, не открывая глаз,
      дотянулся ногой до Гошки, подал сигнал «уходим».</p>

    <p>Нет, только попробовал подать,
      но, кажется, всё-таки сбился — тактильный код сложен, он требует превосходного
      контроля над моторикой, неопытные головы гэбен обучаются ему очень не сразу,
      самому Андрею ещё повезло — на Колошме был Стас Никитич, Стас Никитич много
      курил савьюр и часто испытывал скуку, Стас Никитич всё время лез к Андрею с
      тактильным кодом не по делу, а так, поболтать — и кому придёт в голову столь
      сложным способом болтать! — и они болтали, да, болтали, и Андрей волей-неволей
      освоил тактильный код быстро и без проблем.</p>

    <p>Андрей же когда-то мог — мог! — при
      помощи этого несчастного кода болтать от скуки, неужто сейчас он не сумел верно
      воспроизвести простейший сигнал?</p>

    <p>Непростительная, невозможная
      оплошность.</p>

    <p>Гошка ответил коротким сигналом
      непонимания.</p>

    <p>Чересчур коротким — тем, при
      котором нет разницы между «не понял, продублируй» и «не понял, зачем и почему».</p>

    <p>Андрей даже не узнал по реакции, таки
      сбился он сам или нет.</p>

    <p>Не узнал.</p>

    <p>Не открывать пока что глаза, ни в
      коем, ни в коем случае не открывать.</p>

    <p>Андрея даже почти не беспокоило,
      как это выглядит со стороны, — у всех здесь присутствующих такой недосып, что,
      например, закрытые глаза в качестве устранения дискомфорта от специфики
      освещения все поймут, не заострят внимание. Главное — держаться усталым, а не
      испуганным.</p>

    <p>Закрытые веки тут же задрожали,
      перестали слушаться.</p>

    <p>Перестали слушаться учебников
      Института госслужбы («не увлекаться потенциально травмирующим объектом или
      ситуацией, как можно быстрее прервать взаимодействие, лучше — физически, если
      невозможно — найти способ не воспринимать или воспринимать неполноценно») и
      порывались послушаться 66563 (он же только что долбил про отрубание канала
      восприятия как о мощном методе насильственной эскалации проблемных
      переживаний).</p>

    <p>Леший.</p>

    <p>Андрей не понимал, как сейчас вести
      себя оптимально даже на уровне «смотреть или не смотреть на голубую рубашку».</p>

    <p>Успокоиться не получалось.
      Принудить себя сказать Гошке под столом что-то ещё — тоже. Хотя нужно же
      сваливать отсюда, сваливать немедленно, это наркотики, наверняка наркотики, нужно
      дать понять своей гэбне — орать, бить ногами, просить помощи.</p>

    <p>Но ведь и ронять лицо не следует!</p>

    <p>Пусть и лицо с закрытыми глазами.</p>

    <p>Ни к порту ни к городу всплыло в
      памяти тоже учебничное что-то, но ещё отрядское,
      из углублённого курса древнеросской
      истории — у Андрея же все курсы были углублённые, из него же растили в
      специальном отряде специального неведомого кого. Вспомнилось, как жили
      отшельники, люди, сами выкинувшие себя из нормальной социальной среды, потому
      что боялись влияния «шельм» — злых духов, бесов, леших (во времена древних
      росов их и так называли — «отшель<i>м</i>ники»).</p>

    <p>Чтобы шельмы не подбирались
      близко, не дурманили и не дурачили, отшельмники всегда
      чётко регламентировали все свои действия. В один день можно есть, в другой —
      мыться, но никак не вместе: одновременно двух злых духов, приходящих этими
      путями, не сдержишь. В один день можно углубиться в дремучий лес, в другой —
      подняться на гору. В один день ходишь с повязкой на глазах, в другой — с затычками
      в ушах. Но каждый день с повязкой ходить нельзя, как нельзя выкалывать глаза —
      шельма приспособится, выучит твои привычки и так верней обманет.</p>

    <p>Вот потому появившиеся гораздо
      позже, уже при Четвёртом Патриархате, скопники — неправильные, ненастоящие отшельмники, хоть и самые известные. Долговременное, а тем
      более постоянное ограничение не обеспечит защиты от шельм.</p>

    <p>Андрей сам настолько удивился
      полезшим из него вдруг отрядским знаниям, что открыл глаза.</p>

    <p>И в первые мгновения ничего не
      произошло: Смирнов-Задунайский сидел себе тихо. Бахта призывал сворачивать
      переговоры («а то на юбилей не явимся» — точно, ещё же юбилей, когда уже
      выдастся хоть минута поспать?). Соций и 66563 опять довольно жёстко, но не
      слишком напряжённо о чём-то спорили («кража аппаратуры» — какой аппаратуры? А
      ведь было что-то совсем недавно, было-было, но память молчит, не подсказывает,
      что именно). Гошка вполголоса ругался на простреленную руку и покрикивал на
      Охровича и Краснокаменного («младшие служащие, не умеющие стрелять» — тоже
      что-то такое, что должно быть понятным, простым и понятным, но почему-то почти
      не вызывает отклика).</p>

    <p>Зато Андрей вдруг сообразил,
      птичьи или собачьи у Охровича и Краснокаменного костюмы.</p>

    <p>Грифоньи, они сами озвучили,
      только сначала не вспомнить было, кто такие «грифоны». А грифоны, согласно всё
      тому отрядскому углублённому курсу, — это же просто
      волки с совиными крыльями и головами, жуткие мифические звери, лучшие охранники
      от вторжений. Даже деревянные или каменные грифоны, поставленные при входе в
      дом, отваживают воров. Настоящие же грифоны просто рвут в клочья своими когтями
      и клювами случайно приблизившихся к их обиталищу путников, а иногда даже могут
      по доброй воле служить каким-нибудь выдающимся людям с мистическим даром.</p>

    <p>То, что дореволюционное
      правительство исправно чеканило на аверсе некоторых монет грифонов, — любимая
      финансовая шутка Революционного Комитета: «деньги от людей охраняют специально
      выдрессированные чудища». Андрей же учил всё это, в специальном отряде
      специально не учили Революцию, но Андрей — учил, ему было можно и нужно, ему
      нужны были и шутки про грифонов, и прочие детали, и всё на свете, его готовили
      стать профессионалом высочайшего класса, он должен многое знать и уметь, его же
      готовили, готовили, готовили —</p>

    <p>И специальный отряд — ещё целая
      одна ниточка к реальному миру без шельм: Смирнов-Задунайский ведь тоже оттуда, такое
      вот глупое совпадение, такие глупые совпадения бывают только в реальном мире.
      Тоже оттуда, хоть они и не пересекались вовсе, Андрей ведь жил почти без
      контактов с другими детьми, был всегда при педагогах, Андрея же готовили, а
      Смирнова-Задунайского — не готовили, его наоборот, совсем наоборот —</p>

    <p>Взрослый Андрей из Бедроградской
      гэбни давно уже знал, к чему, зачем и по какому именно экспериментальному
      направлению пускали когда-то в расход Смирнова-Задунайского, рано оставшегося
      без хотя бы формальной защиты в виде отца, и других таких же. Взрослый Андрей
      понимал: именно то экспериментальное направление — ещё не худшее, что может
      случиться, там хотя бы системность, хотя бы достаточное количество ресурсов, хотя
      бы контроль сверху от Медицинской гэбни, да и исследования сплошь
      воспитательно-когнитивные, а не медикаментозные на зародышевом уровне — как у
      печально известной и шибко самостоятельной лаборатории, через которую прошли
      психи из гэбни Университетской. Вот та лаборатория — это кошмар, а специальный
      отряд — ну что специальный отряд? Взрослый Андрей читал документы Медкорпуса по
      специальному отряду в Бедрограде, что-то критиковал вслух Виктору Дарьевичу,
      что-то его впечатляло — но уж точно не конкретная история со
      Смирновым-Задунайским.</p>

    <p>…А маленький Андрей из
      специального отряда не знал ничего и никого.</p>

    <p>Неопределённость страшнее чёткой
      картины.</p>

    <p>Непонятный, известный
      исключительно по слухам ужас где-то там в спальнях, учебных аудиториях и
      физкультурных залах вроде и легче игнорировать, но когда вдруг не получается,
      когда в оставленной на столе педагога ведомости какие-то полугодовые цифры по аутоагрессии — от побегов из отряда до суицидов, —&nbsp;остаётся только как можно осторожнее класть
      ведомость на место, осторожнее, осторожнее, под тем же точно углом, точно так
      же обратно закатывать машинально распрямлённый краешек и <i>надеяться</i>.</p>

    <p>Просто всегда очень надеяться,
      что маленькому Андрею из специального отряда повезло, что он не из <i>этих</i>,
      что он для другого, что его ценят,
      ему симпатизируют — потому что повезло и потому что он всё делает как надо. До
      темноты сидит над углублёнными курсами, стабильно выполняет мелкие
      административные поручения, а когда не в силах сидеть или выполнять —
      улыбается, хлопает ресницами, смотрит уважительно и заинтересованно, так и ждёт
      мудрого совета, чтобы применить его к текущим задачам.</p>

    <p>И маленький Андрей верит: это
      работает. Маленький Андрей верит, что это работает, до неполных тридцати лет,
      хотя он уже убеждался и не один раз, что работает-то всегда, но иногда — против
      него самого.</p>

    <p>В мелочах, всегда в мелочах —
      просто есть люди, которые от уважения, заинтересованности и еле уловимых ноток
      страха в свой адрес получают такое удовольствие, которое слишком уж хочется
      продлить подольше, зарываются, красуются. Но это вполне можно терпеть, этим
      можно управлять, можно вывернуть себе же на пользу.</p>

    <p>Пока маленькому Андрею не говорят:
      «Волнуетесь? В ситуации ограничения перемещений и коммуникации все волнуются —
      вы же столько раз видели это в своей работе с другой стороны! Ну зачем же так
      всерьёз переживать, воспринимайте это как неизбежный побочный эффект. Ситуация
      должна когда-то разрешиться, она не может длиться вечно, ваши шансы выпутаться
      вполне пристойны. Не можете не волноваться? Да не тряситесь вы! Что же с вами
      делать, Андрей Эдмундович… хм… Хорошо, <i>один
        телефонный звонок, произведённый с неотслеживаемого
        аппарата, вас успокоит?</i>»</p>

    <p>Один звонок с неотслеживаемого
      аппарата.</p>

    <p>Просто один звонок.</p>

    <p>Просто так, без внятной цели.</p>

    <p>— В принципе, мы могли бы
      предоставить вам некоторые результаты наших лабораторий в обмен на ответный
      херов жест, — прогремел где-то Гошка.</p>

    <p>Ткнул Андрея под столом: ощутимо,
      больно — не в первый раз?</p>

    <p>Хочет одобрения, согласия? Хочет,
      чтобы Андрей сам поговорил о медицинских вопросах?</p>

    <p>Наверное. Может быть.</p>

    <p>Первое: улыбнуться. Второе:
      похлопать ресницами. Третье: посмотреть уважительно и заинтересованно.</p>

    <p><i>Но на кого?!</i></p>

    <p>За третьим должно идти четвёртое.
      Но четвёртое не приходит на ум. В голову. Голова. Четвёртое не идёт за третьим.
      Рассинхронизация. Обрывочные суждения. По ассоциативному принципу. Возможно,
      психогенный ступор. Пульс частит. Во рту пересохло. Остальное не определить
      самостоятельно.</p>

    <p><i>Но он же учил, его же готовили,
      он же должен мочь.</i></p>

    <p>Ещё раз. Внимательней. Отрядские
      воспоминания. Отрядские ощущения. Отрядские эмоции. Краешек ведомости тогда не
      закатался обратно как надо. Полтора месяца потом закатывал краешки во всех
      тетрадках. Хочется тетрадку. Красивую, но построже. Тетрадку. Регресс
      психической деятельности? Такой регресс сочетается с ситуационным параноидом?
      Регресс по классу — истерический психоз. Ситуационный параноид
      — реактивный. Переутомление способствует. Бессонница тоже. Но как же регресс?
      Когда он в последний раз читал полный перечень психопатий? Виктор Дарьевич
      переделывает его раз в полтора года. В этом году детский регресс может
      сочетаться у Виктора Дарьевича с бредом разоблачения. Почему нет? Будет так,
      как напишет Виктор Дарьевич. Виктор Дарьевич после очередного ревизорского
      отчёта не вернул тетрадку. Она была красивая.</p>

    <p><i>Тетрадка</i>.</p>

    <p>Колошма — Савьюр — голубая
      рубашка — допросы — скука — мимика и пластика в тетрадке — не писать глупости
      на полях — 66563 — указания из Столицы — Савьюр — 66563 — не яд, легальный
      медицинский препарат — табельный пистолет — разряжен, но в помещении для
      допросов — Савьюр <i>и</i> 66563 —
      рассинхронизация — ПН4 — указания из Столицы выполнить любой ценой — Комиссия
      по Делам Гэбен — простреленная печень — но это не Андрей — это не кто-то другой
      из голов — не кто-то из Комиссии — это случайность — нелепая, чудовищная
      случайность — никто не поверит — 66563, вам же уже всё равно, а мы хотим
      продолжать нормальную работу —</p>

    <p>ЭТО ВСЁ ЕРУНДА!</p>

    <p>Это тысячу раз пережёвано, пережёвано
      кем надо и кем не надо, это было десять лет назад, от этого всё вокруг не может
      идти трещинами, трещинами, трещинами, трещинами —</p>

    <p>Трещины извиваются, как узоры
      66563.</p>

    <p>Не разрывают, не разламывают, как
      положено трещинам.</p>

    <p>Оплетают.</p>

    <p>«<i>А ты
      уверен, что это трещины?»</i> — спрашивает с издёвкой удушливый страх.</p>

    <p>Чего спрашивать, Андрей и так
      знает, что это стебли, тонкие и прочные, длинные и спутанные стебли одной
      стелющейся степной травы.</p>

    <p>НО ПРИ ЧЁМ ЗДЕСЬ САВЬЮР?</p>

    <p>Удушливый страх ведь не от
      Савьюра, не от Смирнова-Задунайского, не от Гошки-предателя, не от вчерашнего
      66563, не от четверти летальных исходов.</p>

    <p>Удушливый страх пришёл в
      воскресенье — от одного-единственного телефонного звонка, возможность которого
      обеспечили улыбки и ресницы перед нечаянно подвернувшимся нужным человеком. И
      всё как всегда, методы и способы, рычаги и приёмы, но слишком рискованно, и
      решать — самому, одному, на свою голову.</p>

    <p>Но — Савьюр?</p>

    <p>— Разве кому-нибудь другому ты бы
      смог признаться? <i>Чтобы тебе признавались</i> —
      это тоже талант, не у каждого есть, — с еле заметной, но какой-то почти
      счастливой усмешкой спросил вдруг Смирнов-Задунайский.</p>

    <p>Или Савьюр.</p>

    <p>Трещины-стебли замерли невдалеке
      от сложенных на столе рук Андрея.</p>

    <p>Ну не так Савьюр говорил, не так,
      психологически воздействуете — воздействуйте с умом!</p>

    <p>Хотя —</p>

    <p>Аудиозаписи изъяло в результате
      Бюро Патентов, оставшиеся от них расшифровки не передают интонаций, но
      фиксируют некоторые другие речевые характеристики. И нельзя понять, совсем уже
      нельзя, дорисовывает сейчас Андрей или нет, но в тех скандальных расшифровках,
      за те необъяснимые последние двое суток в камере 66563 Савьюр и правда стал
      говорить иначе.</p>

    <p>Легче. Веселее. Счастливей.</p>

    <p>Андрей уже не видел, но ясно
      почувствовал, как стебли стелющейся степной травы, тонкие и прочные, длинные и
      спутанные, обвили его неподвижные запястья.</p>

    <p>Нет, никому другому — не смог бы
      признаться.</p>

    <p>— Это я заразил дом завкафа, —
      сказал Андрей.</p>

    <p>Вышло тихо, совсем тихо — в горле
      было сухо, будто бы выжжено степным солнцем — Стас Никитич из гэбни Колошмы
      постоянно жаловался, что больше трёх савьюровых косяков подряд превращают горло
      в степь — так зачем же столько курить — так это же савьюр, это же единственный
      побочный эффект от него, по сравнению со всем остальным, что в жизни бывает, просто
      сказка.</p>

    <p>66563 засмеялся и несильно
      закашлялся, прикуривая не-сигарету.</p>

    <p>— Может, ты и прав. Как обычно, —
      кивнул он Савьюру, и развернулся обратно к Социю: — Командир, врубись уже, если
      б не ты, <i>я лично</i> блядский шаманский
      допрос замолчал бы к хуям — и всё. А тебе слил. Как умные люди говорят —
      признался!</p>

    <p>— Это я заразил дом завкафа.</p>

    <p>— Блядь, ну хорошо, ну считай,
      убедил, — с дружелюбным осуждением покачал головой Соций. — <i>Я лично</i> тебе
      враньё про кассахскую шлюху за это признание готов списать со счёта.
      Но, наглая рожа, <i>ты</i> врубись, что вам
      — с вашей ёбаной системой реакций и вашим бесконечным враньём — в деловых
      вопросах уже ничего и никогда.</p>

    <p>— Это я заразил дом завкафа.</p>

    <p>— Ну чё ты мелешь? — отмахнулся
      66563, глядя на Соция, но тихонько касаясь под столом Савьюра. — Извиняй,
      командир, но ты ж совсем как штабной стал. Всё, разобрались: под трибунал не
      отдали, но звания лишили и нахуй отправили — так нет, надо ебать и ебать, ебать
      и ебать. Ты чего хочешь, чтоб я раскаялся и возрыдал?</p>

    <p>— Это я.</p>

    <p>Раскаялся и возрыдал?</p>

    <p>…Сидел у фаланг, в воскресенье
      получил возможность позвонить. Но не Бедроградской же гэбне — вдруг обманули и всё-таки
      прослушивают? Зато была <i>запасным рычажком
        в кармане</i> хорошая, чистая схема: с десяток человек после первого кодового
      звонка звонят по цепочке друг другу, и только последний в этой цепочке хотя бы
      примерно представляет, к чему она вообще. Этот последний идёт под видом
      приятеля отлучившегося соседа к кому-нибудь из жильцов — пересидеть полчасика, с
      такой легендой пустит почти любой: Всероссийское Соседство, леший, все всем
      соседи. Выливает незаметно в раковину или унитаз содержимое некой пробирки, к
      которой имеет доступ уже пару месяцев. Те пару месяцев, которые переключатель
      фильтров работает в положении «безотходный оборот». Жильцы не жалуются, фильтры
      ведь на самом деле отлично работают, только искусственно созданный для их
      обхода вирус и может вызвать неприятности. Непосредственного исполнителя убрать
      потом в кратчайшие сроки тоже просто, для этого тоже есть свои хорошие, <i>чистые
        схемы</i>.</p>

    <p>Где-то совсем близко задёргался
      почему-то Гошка, сначала тихо и скрытно, потом повысил голос, заткнул личные
      разборки Соция и 66563, рявкнул что-то Бахте, не позволил Охровичу и
      Краснокаменному загалдеть, как-то почти даже обругал Савьюра.</p>

    <p>Придвинулся зачем-то совсем
      близко к Андрею, потряс, простреленной рукой попробовал съездить по морде —
      вышла беспомощная оплеуха, зарычал, стал что-то спрашивать, что-то совсем
      простое и понятное, простое и понятное, совсем. Должное таковым быть.</p>

    <p>Кажется, считал пульс, хотя
      савьюровые стебли, перетянувшие запястья Андрея, ему мешали, взвивались
      недовольно, стряхивали его дурацкое мельтешение; но Гошка не сдавался, снова
      тряс, тормошил, снова спрашивал и спрашивал что-то —</p>

    <p>Можно бесконечно спрашивать:
      зачем был нужен запасной рычажок, о котором не знает собственная гэбня? Зачем
      несогласованно запускать чуму в завкафском доме, когда всё равно фаланги кое-что
      уже прослышали о неком искусственно созданном вирусе по заказу гэбни города
      Бедрограда? Зачем не признаваться собственной гэбне, не признаваться сразу, как
      выяснилось реальное положение дел в Бедрограде?</p>

    <p>Можно, можно спрашивать.</p>

    <p>Удушливый страх делает так всё
      время.</p>

    <p>Пусть бы уже задушил к лешему.</p>
  </>
}

export default chapter36
