import React from 'react'
import './CharactersPage.sass'
import Helmet from "react-helmet";

const CharactersPage = () => <>
  <Helmet>
    <title>Список персонажей</title>
  </Helmet>
  <main className='CharactersPage'>
    <h1>Список персонажей</h1>
    <section className='CharactersPage_Brov'>
      <img src='/images/cast/brov.jpg' alt='' />
      <section style={{
        maxWidth: '335px'
      }}>
        <h3>
          <b>Бровь</b>
          <br />
          (Брованна Андроньевна Шухéр)
        </h3>
        <p className='CharactersPage_Subheading'>студентка третьего курса кафедры истории науки и техники исторического факультета БГУ им. Набедренных</p>
        <p>
          Обыкновенные всероссийские студентки не попадают в центр необыкновенных событий — для такого надо быть хоть чем-то да примечательной. Бровь примечательна любопытством, красноречием, искренностью, а главное — своими неожиданными романтическими предпочтениями. А потом всё завертелось!
        </p>
      </section>
    </section>
    <h2>Стороны конфликта</h2>
    <img src='/images/cast/gebni.jpg' alt='' className='CharactersPage_GebniBig' />
    <section className='CharactersPage_TwoColumns CharactersPage_Gebni'>
      <section>
        <img src='/images/cast/gebni_univer.jpg' alt='' className='CharactersPage_GebniSmall' />
        <h3><b>Университетская гэбня</b></h3>
        <p className='CharactersPage_Subheading'>шестой уровень доступа к информации</p>
        <p className='CharactersPage_Small'>
          Учреждена девять лет назад при деятельном участии последнего живого на тот момент члена Революционного Комитета. Обладает достаточно непрозрачным для стороннего наблюдателя кругом обязанностей. Впрочем, сторонних наблюдателей её деятельности много не наберётся, поскольку публичным органом власти она никогда и не являлась. Абсолютное б��льшинство студентов даже не слышали, что в их родном учебном заведении есть представители какой-то там власти. С момента учреждения ни разу не меняла своего состава.
        </p>
        <h4>Охрович и Краснокаменный</h4>
        <p>
          Страшны во гневе, но ещё страшнее они в своём дружелюбии. Быстры на расправу, точны на суждения, опасны при столкновении как в тёмном переулке, так и на свету, обладают отменным чувством юмора и меткостью в стрельбе. Встречаются только вдвоём, и хорошо бы не с вами (что сложно, если вы студент — они-то, представьте себе, преподаватели). В качестве преподавателей волокут на себе нелёгкое бремя модуля по истории общественного прогресса. Да, вдвоём. Да, когда вы придёте к ним на экзамен, они тоже будут вдвоём — смиритесь, против них двоих у вас точно нет шансов. Лучше попробуйте сорвать экзамен покушением на кафедральное революционное чучело — у вас всё равно не получится, а они, возможно, оценят вашу смелость и позволят вам умереть быстро. Возможно, нет.
        </p>
        <h4>Ларий Валерьевич Базальд</h4>
        <p>
          Нальёт вам чаю, перескажет последние новости, легко решит все ваши проблемы с пересдачей, умудрившись в процессе незаметно совершить восемь телефонных звонков по собственным нуждам. Милый и приятный во всех отношениях секретарь кафедры истории науки и техники, о котором никто не может сказать ничего дурного. Это-то и подозрительно.
        </p>
        <h4>Максим Аркадьевич Молевич</h4>
        <p>
          Заместитель заведующего кафедрой истории науки и техники, волею судьбы — бессменный герой студенческих пересудов и одновременно единственный серьёзный человек во всей этой шарашкиной конторе. Тщетно пытается навести в ней хоть какое-то подобие порядка и просто нести свалившуюся на него ответственность. Иногда это бывает сложно, но он не боится трудностей.
        </p>
      </section>
      <section>
        <img src='/images/cast/gebni_bedrograd.jpg' alt='' className='CharactersPage_GebniSmall' />
        <h3><b>Бедроградская гэбня</b></h3>
        <p className='CharactersPage_Subheading'>шестой уровень доступа к информации</p>
        <p className='CharactersPage_Small'>
          Учреждена леший вспомнит когда и с того же самого времени для жителей города Бедрограда является синонимом городской власти в принципе. Занимается сплошь серьёзными и скучноватыми делами, которые в разнообразных отчётностях носят гордые имена «развитие», «благополучие» и «процветание». Состав её меняется нечасто и всегда ввиду действительно объективных необходимостей, ибо требования к компетенции кадров суровы, а для развития, благополучия и процветания города стабильность лишней быть не может.
        </p>
        <h4>Гошкá Петюньевич Туралеев</h4>
        <p>
          Перед Институтом госслужбы успел закончить юрфак БГУ. Чрезвычайно гордится тем, что получил своё место в гэбне исключительно благодаря личным талантам, и вообще всячески ратует за честный труд. Как в его представлении выглядит честный труд — другой вопрос. Вспыльчив и отходчив, но к тому моменту, как он отойдёт, в радиусе поражения уже накапливается некоторое количество трупов.
        </p>
        <h4>Андрей Эдмундович Зябликов</h4>
        <p>
          Человек с непростой служебной биографией, незлобивый, очаровательный и всегда имеющий фигу в кармане. Пошёл в Институт госслужбы сразу после окончания детского отряда и потому в глаза не видел того, что все остальные назвали бы «обычной жизнью».
        </p>
        <h4>Соций Всеволодьевич Акровский</h4>
        <p>
          Один из немногих граждан Всероссийского Соседства, кому довелось выяснить на практике, что такое действующая армия. Выяснить, вернуться живым и заодно вынести из своего бесценного опыта следующую мысль: добро должно быть с кулаками. Причём можно, в принципе, и без добра.
        </p>
        <h4>Бáхта Рука</h4>
        <p>
          Тавр (представитель именно того малого народа, на который во Всероссийском Соседстве по сей день поглядывают с опаской). Служит в Бедроградской гэбне дольше всех своих нынешних коллег, а потому давно привык к авралам, спокойно переносит любые служебные неурядицы и любит повеселиться — то с кулаками, то с такси, то с гаечным ключом.
        </p>
      </section>
    </section>
    <h2>Другая большая политика</h2>
    <section className='CharactersPage_TwoColumns CharactersPage_OtherPolitics'>
      <section>
        <div>
          <img src='/images/cast/pg.png' alt='' />
          <h3>Портовая гэбня</h3>
          <p className='CharactersPage_Subheading'>шестой уровень доступа к информации</p>
          <p className='CharactersPage_Small'>
            Третья гэбня всё того же шестого уровня доступа, функционирующая в пределах многострадального города Бедрограда. Впрочем, как раз её-то существование более чем оправдано как культурно-историческими, так и экономическими особенностями вверенной, гм, территории. Когда Бедроград ещё назывался Петербергом, площадь Порта и плотность портового населения значительно превосходили аналогичные параметры самого города. Разумеется, это Оставило Отпечаток. Разумеется, хоть как-то контролировать этот отпечаток может разве что крайне своеобразный орган власти, состоящий из крайне своеобразных людей.
          </p>
        </div>
        <div>
          <img src='/images/cast/mg.png' alt='' />
          <h3>Медицинская гэбня</h3>
          <p className='CharactersPage_Subheading'>пятый уровень доступа к информации</p>
          <p className='CharactersPage_Small'>
            Гэбня, стоящая несколько в стороне от основной вертикали власти — в силу специфики подотчётной ей сферы. Сфера же эта представляется столь значимой для государства в целом, что органу, ей управляющему, можно всё. Ну или почти всё. К чести органа следует заметить, что привилегиями своими он особенно не злоупотребляет, сидит себе тихо в городе Столице в собственном крупнейшем исследовательском центре, именуемом Медицинским Корпусом, и — не поверите — исследует. Что совершенно не мешает всем остальным относиться к Медицинской гэбне с некоторым подозрением. Наверное, это всё-таки неспроста.
          </p>
        </div>
        <div>
          <img src='/images/cast/falanx.png' alt='' />
          <h3>Фаланги</h3>
          <p className='CharactersPage_Subheading'>третий уровень доступа к информации</p>
          <p className='CharactersPage_Small'>
            Во-первых, фаланги — это <b>не</b> гэбня. Во-вторых, фаланги — это настолько не гэбня, насколько вообще можно себе вообразить. У гэбен (в отличие от) есть хотя бы чёткая привязка к сфере, территории или учреждению, благодаря которой имеется возможность (даже даже безо всяких специальных знаний об особенностях политики Всероссийского Соседства) воспользоваться здравым смыслом и предположить, каков круг обязанностей каждой конкретной гэбни. С фалангами этот номер не пройдёт. Достоверно об их работе известны всего две вещи: они отчитываются непосредственно перед <i>самым верхом</i> и они имеют карт-бланш на проведение служебных расследований внутри госаппарата. А большего о них ничего и не полагается знать — у вас недостаточный уровень доступа, не сомневайтесь.
          </p>
        </div>
        <div>
          <img src='/images/cast/sc.png' alt='' />
          <h3>Силовой Комитет</h3>
          <p className='CharactersPage_Subheading'>четвёртый уровень доступа к информации</p>
          <p className='CharactersPage_Small'>Зато с Силовым Комитетом всё просто и прозрачно (как слеза ребёнка). Он живёт, чтобы выполнять ту работу, которая фалангам одновременно необходима и, скажем так, не к лицу. Вот и получается, что — несмотря на свой высокий уровень доступа — Силовой Комитет не может похвастаться какой-либо самостоятельностью действий. Зато у них есть пушки и, в отличие от всех прочих государственных служащих, они их совсем не прячут. Если вы видите серьёзных людей при оружии — так и знайте, они здесь по прямому приказу фаланг. Впрочем, эта информация вряд ли может быть вам полезна: если вы видите серьёзных людей при оружии — так и знайте, они здесь за вами.</p>
        </div>
      </section>
      <section>
        <img src='/images/cast/figure.jpg' alt='' style={{
          maxWidth: '100%'
        }} />
        <h3>
          Кафедральное<br/>
          революционное чучело
        </h3>
        <p>
          (оно же манекен среднестатистического члена Революционного Комитета) взирает на всю эту возню с непереводимым выражением отсутствующего у него лица. На что имеет полнейшее право: вот в его время была <i>политика</i>, а это — ну, знаете ли...
        </p>
      </section>
    </section>
    <h2>Заинтересованные частные лица</h2>
    <section className='CharactersPage_TwoColumns CharactersPage_Other'>
      <section>
        <div>
          <img src='/images/cast/reusch.jpg' alt='' />
          <div>
            <h3>Константин Константьевич Ройш</h3>
            <p className='CharactersPage_Subheading'>научный руководитель Брови, преподаватель истории древнего мира и методологии</p>
            <p>
              Сдержанный и даже чёрствый, Ройш не имеет никаких политических амбиций, поскольку радость бюрократического процесса ему милее любых возможных результатов.
            </p>
          </div>
        </div>
        <div>
          <img src='/images/cast/shucher.jpg' alt='' />
          <div>
            <h3>Андроний Леонидович Шухéр</h3>
            <p className='CharactersPage_Subheading'>отец Брови, профессор кафедры вирусологии медицинского факультета БГУ им. Набедренных</p>
            <p>
              Обыкновенный университетский преподаватель, который мог бы попасть в центр какой-нибудь другой истории, но тут его опередила дочь. Заикается. Просто хочет спокойной жизни.
            </p>
          </div>
        </div>
        <div>
          <img src='/images/cast/dima.jpg' alt='' />
          <div>
            <h3>Дима</h3>
            <p className='CharactersPage_Subheading'>старый друг Ройша, приехавший в Бедроград из Медицинского Корпуса</p>
            <p>
              Несерьёзный и другим не советует. Шутит смешные шутки, носит подтяжки <i>действительно</i> чудовищных цветов, на досуге разбирается с вирусом чумы.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div>
          <img src='/images/cast/ge.jpg' alt='' />
          <div>
            <h3>Габриэль Евгеньевич Онегин</h3>
            <p className='CharactersPage_Subheading'>завкаф истории науки и техники исторического факультета БГУ им. Набедренных, писатель</p>
            <p>
              Воспламеняет сердца студентов — и не только своим изысканным внешним видом (за которым, кстати, скрывается трепетная и мятущаяся душа). Бессовестно красив и хронически несчастен.
            </p>
          </div>
        </div>
        <div>
          <img src='/images/cast/popeldopel.jpg' alt='' />
          <div>
            <h3>Попельдопель (Юр Карлович Поппер)</h3>
            <p className='CharactersPage_Subheading'>завкаф вирусологии медицинского факультета БГУ им. Набедренных</p>
            <p>
              Любит: медицину, работать, свой зелёный костюм, хорошие байки и поводы к ним. Не любит: тех, кто тратит слишком много сил на душевные переживания вместо работы.
            </p>
          </div>
        </div>
        <div>
          <img src='/images/cast/guanaco.jpg' alt='' />
          <div>
            <h3>Гуанако</h3>
            <p className='CharactersPage_Subheading'>грозный пират</p>
            <p>
              Какой-то сомнительный тип, не имеющий, казалось бы, прямого отношения ни к Бедрограду, ни к его политике, но всё время отирающийся неподалёку.
            </p>
          </div>
        </div>
      </section>
    </section>
    <p className='CharactersPage_Rest'>...и многие другие.</p>
  </main>
  <footer>
    <img src='/images/site_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</>

export default CharactersPage