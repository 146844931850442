import React, {useState} from 'react'
import {
  VKShareButton,
  FacebookShareButton,
  TwitterShareButton
} from 'react-share'
import './ShareWidget.sass'


// @ts-ignore
const canShare = typeof window.navigator.share !== 'undefined'

const ShareWidgets: React.FunctionComponent = () => {
  const [copied, setCopied] = useState(false)

  const copyUrlToClipboard = () => {
    const { href } = window.location
    const dummy = document.createElement('input')
    document.body.appendChild(dummy)
    dummy.value = href
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
  }

  const url = window.location.href
  const quote = '«Чума в Бедрограде» очень интересная книга и авторы у неё красивые люди'

  return <div>
    {
      canShare
        ? <button
          className='share_button share_button-mobile'
          // @ts-ignore
          onClick={() => window.navigator.share({
            url: window.location.href,
            title: document.title,
            text: document.querySelector('meta[property=description]')?.getAttribute('content')
          })}
        >
          Поделиться ссылкой
        </button>
        : <>
          <TwitterShareButton className='share_button share_button-twitter' url={url} title={quote + ' (@alphyna, @cornel_ps)'}>
            Твитнут'
          </TwitterShareButton>
          <VKShareButton className='share_button share_button-vk' url={url} title='Чума в Бедрограде'>
            Вконтактнут'
          </VKShareButton>
          <FacebookShareButton className='share_button share_button-facebook' url={url} quote='Чума в Бедрограде'>
            Фейсбучнут'
          </FacebookShareButton>
          <button
            className={`share_button ${copied ? 'share_button-copied' : ''}`}
            // @ts-ignore
            onClick={copyUrlToClipboard}
          >
            {copied ? 'Ссылка скопирована' : 'Скопироват\' ссылку'}
          </button>
        </>
    }
  </div>
}

export default ShareWidgets