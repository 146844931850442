import React from 'react'
import { IBookPage } from './index'

const chapter05: IBookPage = {
  header: 'header_d4.jpg',
  title: 'Глава 5. Припев (первый раз)',
  prevLink: 'chapter04',
  nextLink: 'chapter06',
  content: <>
    <h2>Университет. Габриэль Евгеньевич</h2>
    <img alt='' src="/images/05_gabriel_evgenievich.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p>Имена хранят тайны — неведомые,
      нелепые, проклятые; познай имя —&nbsp;и обретёшь власть над вещью, над душой,
      над вялым немощным тельцем. И откроются врата, и пропустят тебя внутрь по
      путаным землёй пахнущим ходам, и ты узнаешь тайну, сокрытую на дне,
      занавешенную тиной и мхом подоткнутую.</p>

    <p>Имперская Башня, Небесное
      Таинство, Ллинвам, Вилонский Хуй — древний храм,
      пронзивший небо, падший сквозь землю и положивший начало людскому
      летоисчислению. Его не было, как не было двух древних богов — злого и доброго.
      Но боги смеялись над тем, что их не было, и смеялись над почитавшими их; боги
      манили, обманывали, менялись местами. Когда время закончилось и люди изошли из
      Первой Земли, боги, смеясь, сели на корабли вместе с ними. Но люди оказались —
      не хитрее, нет; злее, чем боги ожидали: люди украли их главное таинство и
      заточили его в вечном алтаре, и, когда алтарь пал, не осталось ничего — ни
      людей, ни богов.</p>

    <p>И тем не менее, люди пришли.</p>

    <p>Габриэль Евгеньевич прислонился
      лбом к стеклу.</p>

    <p>Бедроград —&nbsp;город с морским
      климатом: летом пар, зимой мокрый снег хлещет наотмашь, весной воды, и только
      осенью — редкой, счастливой — бывает прохладно и золото. На днях лил дождь и
      пришлось брать зонт, но даже если осторожно, даже за двадцать метров до такси
      одежда жадно облепила тело, присосалась — и не спастись. Дожди идут снизу
      юркими брызгами, отскакивают от брусчатки, ползают по коже; им одним интересно,
      что там — под мясом, что там — внутри.</p>

    <p>Спасибо, милые.</p>

    <p>А за окном ещё не рассвело, и
      город смотрел на Габриэля Евгеньевича его же глазами.</p>

    <p>Нет его, не должно быть —
      Имперской Башни, Вилонского Хуя. Легенде о начале времён место в легенде о
      начале времён —&nbsp;фантазии, кружевном человеческом вымысле. Историк не может
      не знать, что пророк Ибанутий, пожравший степную чуму и обрушивший Хуй, был
      слаб телом, паршив и кособок, а из имперского плена выбрался, вероятнее всего,
      взятками, но всё это — просто ещё одна вязь на теле вещей. И если кто-то
      вымыслил, что Хуй —&nbsp;был, и что в нём до сих пор живёт племя скопцов,
      поклоняющихся древним богам, — пусть так; но какое право они имеют быть живыми?</p>

    <p>Зачем они так?</p>

    <p>Так не может быть, Габриэль
      Евгеньевич знает точно: ведь он писал. Не очередную научную работу, нет —
      заказной роман о Хуе, скопцах, пророке, тех, кто искал его следы и верил его
      богам. В заказном романе — вольно: чудесные спасения, трагические смерти, и
      кособокость пророка можно по своему разумению замять, подчеркнуть, выбросить; в
      жизни — нет. В жизни проще и скучнее, жизнь безжалостней к красоте.</p>

    <p>Габриэль Евгеньевич когда-то не
      мог мириться; думал, можно жить по-другому — и для того писал, и писал, и
      писал, много, не только на заказ. Чтобы на короткий миг, на одну хотя бы
      страницу найти в себе силы поверить: <i>было
        так.</i></p>

    <p>Но кому это нужно, когда скопцы
      приходят сами, Хуй до сих пор где-то там, под укрытой теперь пастбищами и
      заводами степью, и находятся те, кто видел глазами, трогал руками, жил острее?</p>

    <p>В голове тупо шуршал дождь — не
      давешний напористый ливень, а ватная морось. Габриэль Евгеньевич, не открывая
      глаз, потёр виски, и по векам изнутри прошло сухой фланелью — прояснилось.</p>

    <p>Они все смеются, говорят — рисуется,
      красуется; не верят, что ему правда помогает.</p>

    <p>Если открыть окно, в лицо ударит
      упруго тугим воздухом, твёрдым и прохладным, как стекло. Зато — себя не видеть,
      а только город. Город, тонущий в парках и другом зелёном, по осени проступает
      на поверхность, обнажается костьми, и на твёрдый воздух можно опереться,
      прильнуть и спать — спать — навстречу городу —</p>

    <p>— Габриэль?</p>

    <p>Максим: чёрный, застёгнутый на
      все пуговицы —&nbsp;ему уходить затемно. Снова какие-то важные дела, такие
      большие, что занимают всего Максима, всю кафедру, весь истфак — и Габриэлю Евгеньевичу
      там не остаётся места.</p>

    <p>Он устал быть лишним. Он устал
      слушать слова. Слова — ложь, чушь, пепел, и пеплом сыплются они с губ Максима,
      марают пол:</p>

    <p>— Габриэль, я хотел поговорить.
      Извиниться.</p>

    <p>Не надо, оставь; эти блёклые
      пятна —&nbsp;грязь. Ковёр дорогой, пушистый, топит шаги.</p>

    <p>Испортишь.</p>

    <p>— Ты знаешь, что мы рассказываем
      тебе не всё, что происходит в Университете. И в городе тоже. Уровни доступа, да
      тебе и незачем всё знать. Обычно. Ты сердишься?</p>

    <p>Габриэль Евгеньевич мутно
      обернулся. Что за нелепица?</p>

    <p>Люди вечно —&nbsp;покупают друг
      друга доверием и правдой, как будто есть у них какая-то цена.</p>

    <p>Кому это нужно!</p>

    <p>Дождь в голове снова зашелестел,
      но пока воздух твёрдый, можно просто стоять.</p>

    <p>Вот только Максим снова зашевелил
      его словами:</p>

    <p>— Я не хотел тебя бить. Но гэбня
      приняла решение о неразглашении информации, а ты вошёл… кажется, я запаниковал.
      Ты же знаешь, ты — добыча, потому что со мной. Бедроградская гэбня видит в тебе
      орудие для шантажа. Я не знаю, как полностью обезопасить, но подумал… если ты
      ничего не будешь знать, по крайней мере, не ввяжешься.</p>

    <p>Здесь захотелось расхохотаться,
      но губы Габриэля Евгеньевича лишь растянулись в улыбке.</p>

    <p>Он что, серьёзно?</p>

    <p>А как же «предупреждён
      —&nbsp;значит, вооружён»?</p>

    <p>Максим же, наивно ободрившись
      улыбкой, улыбнулся в ответ:</p>

    <p>— Может, вышло и к лучшему. В
      конце концов, теперь тебе предписан больничный. Отдыхай и ни о чём не
      беспокойся.</p>

    <p>Когда-то давно Дима смеялся над
      тем, что Габриэль Евгеньевич живёт в доме-башне — мол, как треклятая
      европейская принцесса. Живёт с детства, как отвоевал его у распределительной
      службы слезами и шантажом (даже на краю крыши стоял!). В башне прохладно, сухо
      и безопасно.</p>

    <p>А Максим —&nbsp;Максим хочет вместо
      башни выстроить целую крепость, чтобы уж точно никто и ничего. Такую, чтоб ни
      ветерок, ни случайная капля не задели. Его можно понять: это любовь, забота.</p>

    <p>Только разве она спасёт.</p>

    <p>— И всё равно… я хочу рассказать
      тебе о том, что происходит. Хотя бы в двух словах. Дело… да ты хоть сядь, у
      тебя постельный режим!</p>

    <p>За окном всё ещё темно, но в до
      сих пор почему-то не просохших лужах зарождается — ещё не солнце, но первое
      светлое небо. Максим, чуя его, чуя, как утекает серебром время, сорвался с
      места, но поймал себя — просто нервно шагнул.</p>

    <p>— Как знаешь. Дело не в подписи,
      должности или разрешении. Просто так честнее.</p>

    <p>Конечно, не в подписи, не в
      должности, не в разрешении.</p>

    <p>Для подписи есть Стас Никитич,
      сотрудник лингвистического факультета, секретарь Учёного Совета, добрая душа,
      романтик, наркоман, гениально подделывающий почерк. Полуслужащий Университета,
      а раньше — больше: сам служил в гэбне. И не в какой-нибудь заштатной, а в той
      самой, которая управляет главной политической тюрьмой страны, сочинял фальшивые
      письма. Написать бумажку за подписью Онегина Г. Е. для него — рутина, сказали
      бы, какую.</p>

    <p>Скажет Ройш — похожий на
      обугленную палку, всегда прямой и вышагивающий метрономом. Он козырная карта
      против любых должностей. У него четырнадцатый уровень доступа и магический
      бумажный посох в руках, он исполнит любое желание, если знаешь, как попросить.
      Зачем нужен завкаф одной отдельно взятой кафедры одного факультета, когда
      преподаватель методологии и истории древнего мира пропускает через свои руки
      весь документооборот Университета?</p>

    <p>Про разрешения и говорить смешно.
      Максим — голова гэбни, глава гэбни, он эти разрешения и выписывает.</p>

    <p>А Габриэль Евгеньевич — ставленый
      завкаф, харизматичная личность, нужная для вдохновения пламени в студентов и
      публичных выступлений. И всё бы ладно, всё бы хорошо — сиди и ваяй трактаты в
      своё удовольствие — пока не напомнят, да ещё и <i>так</i>.</p>

    <p>Всё это чушь.</p>

    <p>Дима (и что он всё крутится в
      голове, окаянный) тогда же, давно, спросил, не смешон ли он сам себе. Габриэль
      Евгеньевич помнил: позже, когда его не стало, стоял в туалете и смеялся в
      зеркало — упорно, с надрывом. Всё ждал, что поможет.</p>

    <p>Не помогло, разумеется.</p>

    <p>Ещё раньше, когда пропал Гуанако
      (Серёжа!), впрочем, не смеялся — и тоже не помогло.</p>

    <p>А теперь есть Максим. Сперва
      казался скучным, Габриэль Евгеньевич держал его при себе — так, самолюбования
      ради, а потом вдруг понял: Максим не пропадёт. Разверзнется земля, небо пожрут
      языки пламени —&nbsp;а он всё равно будет рядом, со своими, пусть
      неповоротливыми, представлениями о любви и честности.</p>

    <p>Когда только стали ночевать в одной
      постели, Максим полторы недели ходил с кругами под глазами — не мог спать, но и
      тронуть тоже не мог без разрешения.</p>

    <p>Как цепной.</p>

    <p>— Слушай меня! — Максим схватил
      Габриэля Евгеньевича за плечи и развернул к себе лицом — резко, почти больно. —
      Я знаю, знаю, что виноват. Можешь сердиться, не прощать — пожалуйста. Но сейчас
      не время для спектаклей. Бедроградская гэбня перешла к решительным действиям…</p>

    <p>Спектаклей! Как тут не
      усмехнуться, не попытаться увильнуть, выскользнуть.</p>

    <p>Никому, даже Максиму, честному и
      прямому, как кирпич, нет дела до того, что внутри. Только дождю — тому, что
      юлил вчера по мостовым, и тому, что в голове.</p>

    <p>У Габриэля Евгеньевича сложный
      организм. Полубританец — значит, устойчивость к большинству наркотических
      веществ и нетипичный гомеостаз; полукассах — никогда не знал кровного родства,
      о кассахах после Революции молчат, они несуществующий народ, не заслуживающий
      знать правду о своих корнях; сын женщины — склонен к острым психическим
      отклонениям при обитании в стране, где население выпекают в алхимических печах.
      Да что уж там, у него справка есть и три месяца медикаментозной терапии за
      плечами.</p>

    <p>Он знает, что все знают, что он
      странный.</p>

    <p>Он знает, что злоупотребляет
      собой.</p>

    <p>Когда пропал Гуанако, Габриэль
      Евгеньевич падал в картинный обморок перед тогдашним завкафом — полуслужащим
      при Бюро Патентов: вдруг поможет. Да что там; этим маем Максим же попросил его
      устроить обморок перед Ройшем — у него-де какие-то проблемы. Устроил, он
      хороший актёр, он даже — чего уж — любит это делать и по менее судьбоносным
      поводам.</p>

    <p>Но ведь это не значит, что у него
      не может в самом деле моросить в голове, болеть живот или ломаться руки!</p>

    <p>Почему они все отбирают у него
      право в самом деле чувствовать себя плохо?</p>

    <p>У него сотрясение мозга —&nbsp;и
      справка, наверное, есть.</p>

    <p>Пожалуйста, просто ещё немного
      постоять у окна, от открытой форточки так прохладно и свободно.</p>

    <p>— Габриэль, послушай! Мы никогда
      с таким не сталкивались, они совсем одурели, опасность грозит всему городу! Я
      ходил к фалангам, я предъявлял доказательства — без толку. В Бедрограде
      настоящая политическая война.</p>

    <p>— Если не ошибаюсь, Поппер
      сказал, что мне нужен покой.</p>

    <p>Максим отпрянул, только зубы
      щёлкнули. Потряс волнистыми волосами (такими красивыми, когда растреплются),
      беспокойно спросил:</p>

    <p>— Тебе плохо?</p>

    <p>А как ещё ему может быть? Максим,
      когда бил, думал о безопасности, остальные же — так просто, смеясь. Потому что
      бить весело. Потому что он, Габриэль Евгеньевич, любит страдать, и никак им не
      объяснишь, что — нет, не любит.</p>

    <p>Он ведь даже не против этого
      вертепа, он ведь даже готов сделать вид, что смеётся вместе со всеми; но
      неужели о том, чтобы не били и не издевались, нужно просить отдельной графой?</p>

    <p>Попросить Ройша издать фальшивый
      указ о ненасилии над завкафом.</p>

    <p>Шутники бы оценили.</p>

    <p>— Всё как всегда, — Максим
      говорил тихо, не давал себе воли, но кого этим обманешь, — не отвечаешь. Всё
      время молчишь и отмахиваешься, как будто я идиот. И потом обижаешься, когда
      решения принимают за тебя. Хотя бы попробуй быть логичным, я тебя умоляю.</p>

    <p>Когда-то Габриэль Евгеньевич
      влюбился в Гуанако, а тот пропал. Потом был Дима, и никто вроде как не
      влюблялся, но и он пропал. Потом обнаружился Максим, но влюбляться было
      страшно, дико и страшно, и только через много лет, когда Гуанако и Дима
      воскресли, всё прояснилось.</p>

    <p>Даже ублюдок судьбы заслуживает
      какого-никакого, но —&nbsp;счастья.</p>

    <p>Только тише, пожалуйста, тише.
      Когда Максим в ярости, он кричит; сейчас — не кричит, но воздух всё равно чуть
      вибрирует, и от этого комната ходит ходуном, и рвётся в глаза дорогой пушистый
      ковёр, топящий шаги, скачут книги и статуэтки, слепит глаза (не просил ведь
      включать свет!).</p>

    <p>Максим выдохнул, махнул рукой.</p>

    <p>— Я больше не могу ждать, пока ты
      смилостивишься. От меня сейчас зависит не только наше благополучие, но и судьба
      всего города, леший возьми. И я всё ещё готов тебе об этом рассказать. Когда
      надумаешь снизойти, позвони.</p>

    <p>И вышел — не хлопая дверью, но
      всё равно всё вокруг зазвенело струнами. Габриэль Евгеньевич снова уткнулся в
      стекло и сжал виски.</p>

    <p>Не злись на меня, прости.</p>

    <p>Я бы с радостью ответил тебе,
      если бы ты мог говорить хоть чуточку тише.</p>
  </>
}

export default chapter05
