import React from 'react'
import { IBookPage } from './index'

const chapter24: IBookPage = {
  header: 'header_d7.jpg',
  title: 'Глава 24. Он, Гошка',
  prevLink: 'chapter23',
  nextLink: 'chapter25',
  content: <>
    <h2>Бедроградская гэбня. Гошка</h2>

    <img alt='' src="/images/24_goshka.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }}/>
    <p>Гошка не любил допросы.</p>

    <p>Не то чтобы они не занимали его
      как форма досуга и способ получения удовольствия от работы, вполне. Но вот вся
      та вера, которую возлагали окружающие на допросы как на метод извлечения информации,
      раздражала.</p>

    <p>Ну что тебе расскажет запуганный,
      озлобленный, забившийся в угол человечек, который только и видит, что форменные
      наплечники, только и думает — как бы пронесло? Ничего он тебе не расскажет, он
      будет слишком занят сокрытием мягких частей тела. А надо ж не так, надо ж со
      всей душой, по-хорошему, чтобы они сами пришли, сами захотели.</p>

    <p>Потом и по мягким частям тела
      можно, но сперва —&nbsp;дело.</p>

    <p>Наверное, хорошо, что Дмитрия
      Борстена будет допрашивать (то есть, конечно, будет с ним <i>беседовать</i>) Соций —&nbsp;кто сильнее верит в метод, у того этот
      метод и работает. Так что Гошка даже не возбухал особо, когда они с Андреем
      уединились разрабатывать планы завтрашней встречи, дал только несколько
      указаний («и, блядь, не вырывай ему язык <i>до</i> того, как он начнёт говорить, кассах не кассах») и
      пошёл к Бахте — вспоминать, что не только чума есть в Бедрограде.</p>

    <p>В Бедрограде, например, нависает
      юбилей Первого Большого — херова головная боль в
      придачу к уже имеющейся. То есть проводится он,
      конечно, не в Бедрограде, а в области, но угадайте-ка, кто за всё это отвечает!</p>

    <p>Неужели где-то ещё во
      Всероссийском Соседстве остались люди, которые правда
      во всю эту херотень про корни, ветви и прочее
      всеобщее равенство верят? Неужели остался кто-то, кому эта самая идеология за
      лето нужна содержательно, а не только для того, чтобы что-то доказать, кого-то
      опустить и не остаться в лохах?</p>

    <p>Ведь должны же быть. Простые
      люди. Те, ради кого это всё нужно.</p>

    <p>Оно ведь нужно ради них, а не
      ради собственных амбиций или по привычке, верно?</p>

    <p>Когда-то на все эти вопросы были
      очень простые и очевидные ответы, но они затерялись — за большой политикой,
      бытовыми мелочами и «ну тут пустим музычку какую-нибудь, народу посрать, народ благоговеть явится». За
      постоянным чувством обязанности и «кто, если не мы», за постоянной же нехваткой
      денег на ремонт херовых дорог.</p>

    <p>«Церемонию надо попышнее. Фаланги на нас и так нынче косятся, а теперь вот
      велели попышнее — придётся услужить», —&nbsp;сказал
      Бахта.</p>

    <p>Фалангам тоже не приходится
      заниматься художественной кройкой и шитьём бюджета. Университету не приходится,
      Порту не приходится. Медицинской гэбне не приходится.</p>

    <p>Бюро Патентов не приходится.</p>

    <p>Никто из этих блядей не знает,
      каково это — держать в руках один кусок хлеба, видеть перед собой двух голодных
      людей и понимать, что дать каждому по заслугам, каждому столько, сколько
      полагается, никак не выйдет. И постоянно недодавать, и постоянно придумывать
      оправдания — для них и для себя.</p>

    <p>Потому что не должны сидеть в
      гэбнях те, кто делает только то, что может.</p>

    <p>Должны — те, кто через «не могу» делает то, что
      надо.</p>

    <p>И это всё никогда не закончится.</p>

    <p>Нет, не так; <i>это всё закончится, когда мы победим.</i> Поэтому в план с чумой вбухано столько
      сил, средств и времени — чтобы уж наверняка,
      чтобы не пытаться впустую. Раз и навсегда, бляди.</p>

    <p>Раз и навсегда, даже если план
      споткнулся, даже если они как-то узнали заранее, даже если они увильнули. У
      Гошки по-прежнему есть пистолет, у Гошки по-прежнему есть право его
      использовать, и, если ничего в ближайшие дни не изменится, он просто пойдёт и
      последовательно перестреляет университетских на улице,
      не впервой искать беззащитные мишени.</p>

    <p>По беззащитным проще попасть.</p>

    <p>И куда проще стрелять по
      беззащитным, чем напяливать снова тельняшку и звониться в дверь к Шухеру,
      изображать портового и обиняками выспрашивать об
      университетских делах.</p>

    <p>Впрочем, всё равно лучше и
      веселее, чем допросы.</p>

    <p>Шухер ещё не в курсе (не его это
      ума дело), но завкафа нашли в Порту — вроде бы даже ещё живого, хотя деталей
      Гошка не знал, наблюдателям прищемили хвосты достаточно оперативно. Нашли
      —&nbsp;и Порт тут же объявил экономическую блокаду, даже пикнуть никто не
      успел.</p>

    <p>Все корабли стоят.</p>

    <p>Иначе водяная чума поплывёт по
      Европам.</p>

    <p>Туда бы ей и пиздовать!</p>

    <p>Гошка не ожидал настолько
      радикального решения. Испугаться — да, огрызнуться на Университет, огрызнуться
      на Бедроградскую гэбню даже, а разумнее —&nbsp;прийти к ней за помощью. Но
      сразу повесить полную блокаду — несколько чересчур. Если схлопнется экономика
      Порта — пиздец всем. Портовой торговлей живёт экономика Бедрограда.</p>

    <p>Всем, в том числе и самому Порту.
      Они же не полные кретины — невозможно заставить столько народу просто сидеть на
      жопе ровно, у народа свои интересы, дела за морями,
      сделки горят и всё такое. Если блокаду быстро-быстро не снимут, Порт
      взбунтуется против Портовой же гэбни.</p>

    <p>Что и хорошо, если вспомнить
      степень её не-нейтральности.</p>

    <p>А ещё — каждый день блокады
      Университету подобает, как честным людям, оплачивать из своего кармана.</p>

    <p>Что и хорошо.</p>

    <p>Изначально план с завкафом был
      ориентирован скорее на гэбню — вряд ли много кто ещё в Порту способен его
      опознать. Бесчувственное чумное тело вынесли в ковре, обстригли, перекрасили,
      накачали покрепче наркотиками разной степени тяжести —&nbsp;а потом Соций
      позвонил портовой Врате. Соций, не Гошка.</p>

    <p>Гошка-то помнил, что, пока он сам
      мучился кошмарами про шаманов и всеобщую гибель, ей привиделось что-то слюнявое
      про давнюю неслучившуюся любовь. Очень проникновенно,
      почти классика росской литературы: она работала в борделе, он втрескался по уши
      и предложил её увезти, она зассала (то ли заработок было стрёмно терять, то ли
      не поверила в вышину его чувств), он уехал один и сокрылся в веках. Она
      осталась, как дура, работать в борделе и время от
      времени вздыхать о печальной своей судьбе после приёма некоторых химических
      веществ.</p>

    <p>Так что позвонил Врате Соций, но
      говорила она якобы с неким другом её загадочного принца, который проникновенно
      просил её определить в какой-нибудь анонимный портовый бордель другого друга
      загадочного принца (в прошлой жизни —&nbsp;завкафа, но этого Врате знать
      незачем). Мол, должен неким серьёзным шишкам кучу денег, очень тяжёлый
      наркоман, становиться шлюхой не планировал, но других
      способов хоть как-то что-то отработать не видится. Первый друг загадочного
      принца (то есть Соций) за второго друга загадочного принца (то есть завкафа)
      жопу рвать не намерен, но помочь хочет. А в анонимном
      борделе много за раз не заработаешь, но зато отсиживаться можно долго. Никто не
      найдёт, и вот и хорошо.</p>

    <p>До тех пор, ясен
      хер, не найдёт, пока доверенные люди не подскажут портовым, где искать.</p>

    <p>Врата на это расчувствовалась,
      распустила сентиментальные сопли и сказала, что, мол,
      конечно-конечно, как же не помочь, а неужели загадочный принц её до сих пор
      помнит?</p>

    <p>Соврали, что помнит и даже
      намеревается в ближайшее время посетить Всероссийское Соседство и её лично.</p>

    <p>Вот, кстати, пусть она теперь по
      этому поводу устраивает манифестации и борется с блокадой Порта
      —&nbsp;пассажирские-то корабли в первую очередь встали.</p>

    <p>Гошка потёр лицо руками — кожа
      тугая и звонкая, блядь, как на барабане. Уже выделанная.</p>

    <p>Со всей этой чумой никакого
      товарного вида не сохранишь.</p>

    <p>Линии на теле у шамана — это,
      наверное, типа для разреза. Гошка что-то такое смутное читал о том, что все эти
      тёмные степные знахари записывают информацию о человеческой анатомии на
      собственной коже. А чё, разумно, уж точно не потеряешь, и никакой терминологии
      не нужно.</p>

    <p>Загробная
      гэбня, это ж надо было породить больному сознанию.</p>

    <p>Сфокусировав взгляд, Гошка
      обнаружил, что дверь давно открыта, Шухер стоит на пороге и опасливо косится на
      гостя, явно побаиваясь пригласить его внутрь.</p>

    <p>— А п-почему в-вы один?</p>

    <p>А потому что Соций нынче херова
      звезда. К Дмитрию Борстену — Соций, к Врате в Порт —&nbsp;Соций. К Врате —
      потому что не Бахту же посылать, и потому что (в парике, пиджаке и ботинках с
      тайными каблуками) Соций смутно похож на Молевича. То
      есть не очень, но словесное описание имеет шансы сойтись, морда
      одинаковым кирпичом. Лично Гошка особо не надеялся, что поверят (у Портовой
      гэбни водятся фотокарточки для опознания), но слушок пройти может, а больше
      разве потребно?</p>

    <p>С Вратой
      всё понятно, а вот университетским-то всё-таки зачем
      именно Соций?</p>

    <p>Задашь себе вопрос — и сразу
      возникает муторное желание хлопнуть сейчас Шухеру дверью в морду,
      свалить в Порт, наглотаться ещё чего-нибудь вратовского и спросить-таки шамана
      обо всём. Он наверняка в курсе университетских дел.</p>

    <p>Рожа для
      степняка больно интеллигентная.</p>

    <p>— Потому что один. Я, дядя, к
      тебе на вопросы отвечать пришёл, или, может, всё-таки <i>ты мне</i> чего-нибудь расскажешь?</p>

    <p>Шухер смутился, споткнулся,
      пригласил внутрь. Жалкий всё-таки человечек, но был бы даже почти умилительным
      — если бы не тоже туда же. Он сам-то хоть знает, сколько стоит рама зеркала,
      которое у него в коридоре висит? Она красивая, конечно: листики, веточки,
      грифончики по углам — но зачем ему такая?</p>

    <p>Да, Гошка залезал в чужие
      кошельки, потому что все кошельки города Бедрограда были его — и точка.</p>

    <p>На сей раз в
доме обнаружились некие следы гостеприимства: чай на столе в гостиной и
      какие-то сосиски с макаронами в кастрюле на кухне — за что Гошка был
      благодарен. Вот на что действительно никогда времени не остаётся, так это на
      еду.</p>

    <p>После спринтерского забега до
      здания Бедроградской гэбни, спринтерского же оформления запроса на временное
      прекращение деятельности гэбни Университетской и нудных часов копания в сметах
      по юбилею Первого Большого жрать хотелось только так.</p>

    <p>Гошка внятно объяснил Шухеру, что
      никакого разговора не будет, пока он не поест, и накинулся на сосиски (судя по
      реакции, они частью гостеприимства не подразумевались, но кто ж виноват).
      Сейчас полагалось сосредоточиться, выдать нечто отдалённо-портовое и получить в
      ответ информацию о финансовом состоянии Университета, но мысли всё время
      убегали. К сосискам, например. К юбилею Первого
      Большого. К дневной победе над Ройшем. К тому, стоит ли прямо сегодня дать
      соответствующим людям задание вооружиться и устроить весёлую игру «кто принесёт
      больше голов Университетской гэбни». К Врате, которая
      так ждала своего принца, что её было почти жалко. К портовому шаману.</p>

    <p>Гошка невольно сделал паузу в
      энергичном пережёвывании. Он только сейчас понял, что отчётливо знает: действие
      его старого доброго глюка-кошмара разворачивалось в
      Порту.</p>

    <p>А откуда, собственно, знает?</p>

    <p>Понятно, что таблетки жрал там, так что это типа ассоциация по смежности. Но не
      только, есть что-то ещё.</p>

    <p>Запах крови, прибитые гвоздями к
      потолку венки твири, сумрачный серый свет на дощатых стенах и чуть
      пробивающиеся через грань слышимости звуки —</p>

    <p>Шум Порта.</p>

    <p>Шум Порта ни с чем нельзя
      перепутать: каждый раз, когда отправляется или приходит корабль, звучат
      оповестительные сигналы. Часто. Каждые минут пять. Гошка не раз спрашивал у
      Враты, как тут вообще можно жить — спать, например. Она только пожимала
      плечами: привыкаешь.</p>

    <p>Свои роскошные глюки
      Гошка ловил у неё дома, прямо на полу, так что неудивительно, что портовые
      звуки туда ворвались, верно?</p>

    <p>Неверно. То есть верно, но
      непродуктивно. Вооружившись Фрайдом, можно вообще всё на свете объяснить —
      толку-то в этом?</p>

    <p>Лучше не пытаться понять, почему
      ему могло примерещиться то или это, а просто вспомнить в деталях. Где-то там
      может быть ответ.</p>

    <p>Где-то там <i>должен быть</i> ответ.</p>

    <p>— У меня нет в-возможности
      уз-з-знать в-всё об университет-тских ф-финансах, но
      я сп-п-просил Юра К-карловича…</p>

    <p>Гошка чуть не засветил Шухеру
      промеж глаз — хера ли он лезет со своими откровениями?
      Сдержался, помычал что-то в духе того, чтобы продолжал.</p>

    <p>…Он лежит на столе в некоем
      деревянном помещении вроде сарая или склада. Стол невысокий, ниже, чем по пояс.
      Есть на нём одежда или нет — непонятно, потому что тело не ощущается, его как
      будто нет, и из всех мышц работают только мимические. Сильно пахнет травой и
      всяческой мясной гнилью. Как Гошка туда попал, он не знает, в памяти чёрная
      яма, и до неё — хихиканье с Вратой над какими-то экзотическими таблетками,
      которые ей перепали почти случайно. Таблетками, которые они на двоих сожрали.</p>

    <p>— Я н-не знаю, к-как т-так вышло, чт-т-то Унив-верситет п-позволяет себе п-подобные т-т-траты… у нас
      нет д-денег. П-почти с-совсем нет.</p>

    <p>В поле зрения появляется шаман,
      пристаёт со своими набившими уже оскомину историями о том, что Гошка умер и
      разбирается теперь с Загробной гэбней, ха. Шаман —
      мужчина, среднего (насколько можно оценить со стола) роста, комплекции скорее
      худощавой, хотя не очень. Лохматые волосы набиты травой, вроде светлые, но
      Гошка-то знает цену цвету волос. Полуголый, всё тело
      разрисовано линиями — краска это, кровь или татуировки, в полутьме не поймёшь.
      На шамане наплечник из травы, травяная же юбка. И лицо — нарисованные
      (вытатуированные?) дополнительные глаза искажают черты, но оно всё же отдалённо
      знакомое, как у человека, которого Гошка видел очень давно или только на
      фотокарточке.</p>

    <p>Декан юрфака и гошковский батюшка
      остаются под подозрением, значит.</p>

    <p>— Г-госуд-дарственное
      финансирование Унив-верситета давно п-потрачено на
      чуму… з-закупали к-капельницы, к-койки — в об-бщем, в-вам неинтересно. Всё
      п-потрачено, до к-конца
      года — учеб-бного, т-то есть д-до июня. И
      д-дополнительных с-средств никаких — п-по крайней
      мере, я не уз-знал.</p>

    <p>Шаман рассказывает свои байки про
      смерть, трясёт перед мордой Гошки каким-то мясом — хер
      разберёт, что это. Гошка не верит, что умер, они препираются, потом шаман
      пропадает из поля зрения. Возвращается с глазными яблоками в руке. Сдавливает
      их в ладони. У Гошки колет в районе сердца, потом пропадает зрение. Через
      пару-тройку секунд, наверное.</p>

    <p>Шаман сдавливает глазные яблоки в
      ладони — укол в сердце — пропадает зрение — но за секунду до этого — самым
      краем глаза — он видит —</p>

    <p>— Ед-динственное,
      чт-то может в-вас заинт-тересовать…
      Юр К-карлович к-как-то очень б-б-быстро в-выбил несколько
      г-грантов — г-государственных с-субсидий на ис-с-след-дования. Д-думаю, он
      с-собирается нап-править эти деньги в П-порт. Это малая
      часть д-долга, и ф-физическ-ки их п-пока нет, т-так
      чт-то…</p>

    <p>У Гошки не фото-память,
      он мог придумать это прямо сейчас, воспоминания ненадёжны, но — это всё херовы
      отговорки.</p>

    <p>Он отчётливо знает, что за
      секунду до того, как шаман сжал глазные яблоки в руке и свет померк, где-то на
      периферии его зрения мелькнула смутная тень.</p>

    <p>Человеческая рука.</p>

    <p>Человеческая рука со шприцом.</p>

    <p>Ничем не разрисованная.</p>

    <p>Он не вспомнил этого сразу,
      потому что по помещению носилось слишком много неверных теней, потому что его
      слишком забавляли прыжки шамана и орнаменты из травы на потолке.</p>

    <p>Орнаменты, прибитые гвоздями.</p>

    <p>В небольшом дощатом помещении в
      Порту.</p>

    <p>Он же сам, добровольно сожрал эту ёбаную таблетку.</p>

    <p>— Я г-готов ск-казать
      в-вам, к-как т-только деньги от г-грантов появ-вятся в Университ-тете. Это
      б-будет на днях. П-пожалуйста, в-вы же с-согласитесь п-повременить с оп-платой п-пару дней?</p>

    <p>Гошка ожидал, что перевернёт этот
      херов стол вместе с кастрюлей из-под сосисок, что заорёт, накинется на Шухера —
      но не смог, только увидел отчуждённо, как затряслись его руки.</p>

    <p>Он уже подумывал грешным делом,
      не могла ли Врата его тогда чем-нибудь сложносочинённым накормить, чтобы он в
      бреду сболтнул лишнего, но быстро решил, что не могла. Гошка не держал Врату
      под колпаком (вот ещё), но послеживал за ней мимоходом — она с
      университетскими точно не снюхивалась. Да и сыворотки правды в природе, к
      счастью, не водится.</p>

    <p>Но что если Врату попросили
      накормить его снотворным — просто так, под любым предлогом?</p>

    <p>Что если она сама не знала?</p>

    <p>Накормить снотворным, унести на
      случайный склад, обколоть какой-нибудь херью,
      вызывающей паралич ниже шеи, обвешать стены травой и разыграть весь этот
      спектакль, без сучка и задоринки. Шаман, блядь. Загробная гэбня, блядь. Все
      умерли, блядь.</p>

    <p>Мешок травы (уж этого добра в
      Порту найдётся), пара свиных туш, нехилый набор медицинских препаратов и один
      хороший актёр.</p>

    <p>И он, Гошка, всё сдал.</p>

    <p><i>Он сам рассказал им про чуму.</i></p>

    <p>Гошка чувствовал, что трясётся,
      что его руки невольно сжимаются в кулаки, а Шухер перед глазами размывается в русоватое пятно.</p>

    <p>Он мог додумать
про руку, вколовшую ему в сердце некую поебень, от которой пропало зрение, но
это неважно, это отмазка —&nbsp;ответ всё равно уже стал понятен, он лежал на
поверхности, он всё объяснял, он был до отвращения простым.</p>

    <p>«Говори быстрее, вспоминай, а то
      застрянешь на всю вечность —&nbsp;а мне охота разве с тобой маяться».</p>

    <p>«А что говорить, что вспоминать?
      Мы не сделали ничего. Собирались — не успели. Надо было навести порядок, а
      воевать запрещено — вот мы и придумали, как всех перехитрить. Болезнь, отрава — это
      не так и страшно, если лекарство заранее
      припасти. Кому-то придётся, конечно, немного помучиться — но это ничего,
      мелочь, мы успеем всех вылечить. Нам только и надо —&nbsp;припугнуть малость. Самую
      толику — разве больше одного дома потребно?
      Показать, что бывает, когда не за своё дело берутся».</p>

    <p>Всё это время Гошке казалось, что
      шаман с самого начала знал про чуму, про ссору с Университетом, и только сейчас
      он вдруг понял — нет, не знал. Шаман спрашивал вокруг да около, как сам Гошка
      сегодня утром у Шухера, ничего конкретного, а в ответ получил —</p>

    <p>«Это просто наглядная
      демонстрация. Настоящая, конечно, в ненастоящую кто
      поверит. Всё путём, смертельная болезнь. Только от неё никто не умрёт».</p>

    <p>Гошка сам ему всё выложил. Всё,
      весь план. Повёлся на спектакль, осмелел в глюке.
      Шаману нужно было только внимательно слушать ответы, корректировать свои
      вопросы — и задавать их таким тоном, как будто он всё уже давно знает.</p>

    <p><i>Но он не знал.</i></p>

    <p>Никто не знал.</p>

    <p>Пока Гошка не повёлся, не выложил
      всё подчистую.</p>

    <p>Его наебали и поимели
      во все дыры. Он думал о Фрайде, он думал о сложных наркотиках (Андрей всякое
      рассказывал, Андрей интересуется научным прогрессом), он думал, что где-то
      среди младших служащих Бедроградской гэбни всё-таки сидит догадливая крыса, он
      думал, что заразился впечатлительностью от завкафа, а на самом деле всё было
      элементарно, односложно, на уровне отрядского утренника.</p>

    <p>Шаман не был фрайдистским сном,
      шаман не был наркотическим глюком.</p>

    <p>Шаман просто был талантливым
      человеком, мастерски проведшим допрос в нетривиальной форме.</p>

    <p>— В-вы в п-порядке? В-всего
      п-пару дней — я ничего не могу п-п-под-делать, в Университ-тете
      нет денег с-сейчас, нет с-совсем! Я могу п-продать в-вещи, я от-тыщу…</p>

    <p>И тогда Гошка всё-таки перевернул
      стол. Кажется, что-то проорав. Несколько раз пнул
      оттопырившуюся ножку, потом она хрустнула и отломилась; бешено посмотрел в
      сторону Шухера — тот забился в угол, что-то мыча.</p>

    <p>Гошка даже не сразу сумел снова
      понять, где он находится и о чём идёт речь. Кажется, он сам здесь занимался
      проведением допроса в нетривиальной форме — не очень-то мастерским, судя по
      тому, что лицо Шухера было угрожающе-красным, с белым треугольником вокруг губ,
      и по размерам катящихся с него капель пота.</p>

    <p>— Всё путём, дядя, — кое-как
      проговорил Гошка, — расквитаемся. Нет башлей
      — нет делей, но я тебе вот что скажу: накопал я
      следов, и они мне не нравятся. Разбирайся со своими бедами сам, дядя, не
      маленький. А чтоб ты не дёргался, вот тебе предсказание, в Порту их любят
      делать: завтра узнаешь, где дочка твоя. Дмитрий Борстен тебе расскажет. А не он
      — так гэбня. Жопой клянусь.</p>

    <p>Он не стал слушать шухеровского недоумённого лепета, скатился кое-как по
      лестнице, но даже сырой уличный воздух не прочистил мозги до конца. Мозги
      перетрудились, перегрелись и лопнули, они привыкли к многолетнему строительству
      в канализации и сложным бюрократическим планам, в них не влезала одна простая
      мысль.</p>

    <p>Это он, Гошка, подставил своих.</p>

    <p>Это он, Гошка, сдал чуму
      Университету.</p>

    <p>Именно поэтому ему не найти крысу
      среди других.</p>

    <p>Именно поэтому он столько ломал
      голову над тем, с какого же хера университетские
      решили, что травить собираются только дом Ройша. «Самую толику — разве больше
      одного дома потребно?» — то ли и правда чего-то в голове перемешалось (когда-то
      совсем давно был такой план), то ли почему-то Гошка
      рассказал кусок легенды вместо куска настоящего плана — решил подшутить над
      шаманом. Они повелись, теперь они импровизируют.</p>

    <p>Они знали заранее, потому что он
      им рассказал.</p>

    <p>Картинка складывалась.</p>

    <p>Пробежав добрый квартал в
      неведомом направлении, Гошка перешёл на быстрый шаг. Какая-то белая птица резво
      вспорхнула из-под ног, расхлопалась крыльями.</p>

    <p>С каких
      херов тут быть птице? Глаза сегодня, после сна в линзах-то, не лучше мозгов.</p>

    <p>Не лучше мозгов всё это время.</p>

    <p>Он же сам приказал своей гэбне — мы не подозреваем друг друга. «Я не сдавал наши
      планы, и никто из вас не сдавал».</p>

    <p>Но он же тогда не знал, он не
      помнил, не догадался, не подумал вовремя.</p>

    <p>Вот только это никого не ебёт.</p>

    <p>Все головы гэбни люди, все люди
      совершают ошибки, вся вина делится на четыре. Вся, да не вся; можно простить
      того, кто сдал склады с вирусом фалангам, укоротив план и ускорив события.</p>

    <p>Нельзя простить того, кто сдал
      сам план главным врагам.</p>

    <p>Это всё —&nbsp;вопрос меры и
      степени.</p>

    <p>Собственные ошибки всегда потом
      прилетают в рыло — и дальше люди делятся только на тех, кто готов признать и
      искупить, и тех, кто позорно поджимает хвост.</p>

    <p>Цитата из самого себя дневного
      покроя немного отрезвила; Гошка накопал в кармане самокрутку
      (для антуража, так-то он предпочитал человеческие сигареты), глубоко затянулся
      (с зажигалки, не со спички — тоже для антуража, чтобы не забывать, что на этот
      вечер он не голова Бедроградской гэбни) и даже почти почувствовал вкус дыма.</p>

    <p>Ещё немного побегает —&nbsp;и очухается как миленький.</p>

    <p>Нет, не все ошибки можно просто
      признать, принять и простить. Не все даже можно искупить. Но это не означает,
      что Гошка не знает, что делать.</p>

    <p>Он притормозил, стянул с головы бандану, ещё раз глубоко затянулся и выпустил
      струю дыма
      вертикально вверх.</p>

    <p>Ему не нужны никакие оправдания и
      никакие аргументы. Его наебали — и он будет мстить, даже если это означает
      разобрать здание Университета по кирпичу.</p>

    <p>Внутренний голос, такой
      миленький, трогательный и разумный, напомнил, что в прошлый раз, когда Гошка
      сам, один, без гэбни принимал решения, девка оказалась
завербованной и вышло не очень хорошо.</p>

    <p>Ну и где теперь эта девка?</p>

    <p>Вот и Гошка думает — шёл бы ты
      нахер, внутренний голос.</p>

    <p>Ему не нужны никакие оправдания и
      никакие аргументы.</p>

    <p>Низкое бедроградское небо было
      сплошь обложено тяжёлыми тучами, но тучи тоже могли идти нахер.</p>

    <p>Гошка невольно улыбнулся.</p>

    <p>По крайней мере, перед ним больше
      не стоял вопрос о том, стоит ли посылать на головы Университетской гэбни очень
      злых людей с оружием.</p>
  </>
}

export default chapter24
