import React from 'react'
import Helmet from 'react-helmet'
import levels from '../../../Data/levels'
import MarkdownPage from '../../../MarkdownPage'
import './BonusLevels.sass'

const BonusLevelsPage = () => <>
  <Helmet>
    <title>Уровни доступа к информации</title>
  </Helmet>
  <MarkdownPage
    sidebarPosition='none'
    markdown={levels}
    noIndent
  />
</>

export default BonusLevelsPage