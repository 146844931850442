import React from 'react'
import { IBookPage } from './index'

const chapter04: IBookPage = {
  header: 'header_d3.jpg',
  title: 'Глава 4. Варианты решения типовых задач',
  prevLink: 'chapter03',
  nextLink: 'chapter05',
  content: <>
    <h2>Университет. Попельдопель</h2>

    <img alt='' src="/images/04_popeldopel.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }} />
    <p><b><i><u>Дано</u>:</i></b></p>

    <p>&nbsp;</p>

    <p>— Дмитрий Ройш — это я,
      —&nbsp;изрёк Дима с лицом человека, выдающего страшную тайну. — Согласитесь,
      блистательная конспирация. С учётом того, что моей настоящей фамилией названа
      аж вакцина от чумы, я решил, что это как-то слишком броско и надо выбрать
      что-нибудь поскромнее, не привлекающее внимания.</p>

    <p>Попельдопель не успел
      определиться, стоит ли на это заржать или покачать головой, как с языка уже
      слетело:</p>

    <p>— Каждый второй спрашивал, не
      родственники ли вы, или таки каждый первый?</p>

    <p>— Каждый, кто не боялся узнать
      страшную правду о наследии Ройшей, — пока Дима говорил, Попельдопель ещё раз
      внимательно изучил его облик на предмет ройшеватости и пришёл к выводу, что за
      страшную дезинформацию о наследии того можно было бы ещё раз посадить. Особенно
      за кричащие страшным дезинформирующим голосом бирюзовые подтяжки.</p>

    <p>— Так я эмпирически выяснил, что,
      оказывается, в честь моего университетского друга назвали чувака, который
      сделал Революцию.</p>

    <p>— В смысле? — не сообразил
      Попельдопель.</p>

    <p>Дима, конечно, способен обозвать
      собственноручно сделанную иммунную сыворотку вакциной от чумы, но «не знал
      чуваков из Революционного Комитета и назвался для конспирации Ройшем» — это уже
      перебор, что для безграмотности, что для хорошей шутки.</p>

    <p>— У меня было тяжёлое детство. Я
      никогда не учил Революцию, — сурово всхлипнув, пояснил конспиратор.</p>

    <p>Сейчас (чтобы ничего не забыть и
      не перепутать) самое время вернуться бы к осмысленной беседе про эпидемии,
      расследования и дурацкую большую политику, но
      Попельдопеля <i>зацепило</i>.</p>

    <p>— Так же не бывает! Или бывает… Но из отрядов не выпускают без зачёта по
      Революции. И
      вообще, подожди, тебе же уже — сколько? — лет тридцать!</p>

    <p>— Двадцать девять, — Дима
      возмутился, — как Ройшу. И да, не выпускают, поэтому я сбежал.</p>

    <p>— Вот оно что, — дошло вдруг до
      Попельдопеля, — так у тебя богатый опыт побегов из учреждений по принуждению к
      деятельности.</p>

    <p>Дима серьёзно кивнул:</p>

    <p>— Всё что угодно, лишь бы не
      трудиться.</p>

    <p>— Попельдопель, ты ведёшься как
      первокурсник, — оторвался от важного дела собирания сруба из пробирок Гуанако, ещё
      один оживший покойник. — Он тебе прямо сейчас своими ройшами
      зубы заговаривает, лишь бы не трудиться. А у нас у всех чудовищно мало времени.</p>

    <p>Время. Попельдопель поморщился.</p>

    <p>Скорость распространения
      заражения в населённом пункте с таким-то количеством жителей в таком-то сезоне
      с учётом способа передачи инфекции — типовая задачка с госа для выпускников
      кафедры вирусологии, ничего сложного. То есть это на госе ничего сложного, в
      билете обычно какое-нибудь абстрактное село с сотней изб (абстрактные срубы из
      пробирок!) или промышленный городишко с одним консервным заводом, а вот на
      консультациях Попельдопель, завкаф этой самой вирусологии, иногда до вечера
      считал со своими выпускниками заражение по Бедрограду.</p>

    <p>Досчитались.</p>

    <p>— Ах да, трудиться, — Дима
      забрался в кресло с ногами. — Леший, с какой же стороны начать? В общем, ночью
      мне из Столицы позвонил Шапка и сказал, что, возможно,
      у нас в городе настоящая эпидемия.</p>

    <p>— Ммм, — ответил Попельдопель.
      Высказыванию не хватало экспрессии, но он опять открыл рот раньше, чем успел
      определиться, должно ли это быть «эммм», «уммм» или «мэээ». — Подожди. Если
      Максим с гэбней перекрыли всё к лешему в подвале Ройша, то откуда взяться
      эпидемии?</p>

    <p>Одна из пробирок в основании
      сруба угрожающе покачнулась.</p>

    <p>Очень символично.</p>

    <p>— Из других, никем не перекрытых
      подвалов. Вчера к Шапке с дружеским визитом заезжал очередной Александр и
      обмолвился, что если бы Бедроградская гэбня захотела четыре мешка вируса, она бы поимела четыре мешка вируса. С бантиком в придачу, — Дима о
      чем-то задумался, а Попельдопель задумался, что такое «очередной Александр».
      Сейчас не переспросит — забудет ведь. — И портовые ребята решили наудачу этот
      самый бантик поискать. Не поверите, нашли. Целый склад расчудесных бантиков.</p>

    <p>— Целый склад — это
      предположение. Непроверяемое, — вмешался Гуанако. Если
      крыть этот его сруб горизонтальной крышей, как раз получится склад. — У
      Бедроградской гэбни есть свои заповедные территории, о которых только в Порту и
      знают. В Порту вообще всё знают. В ночи, то есть уже после подвала Ройша, был
      рейд по этим самым территориям, — да, Попельдопель и сам заметил мешки под
      гуанаковскими глазами. — Запалили шибко бдительную охрану в паре мест и одного
      курьера. У курьера увели образец груза прямо из сумки — хорошим карманникам
      навыки взлома складов ни к чему. Ну, в общем, это вирус. Столько, сколько нужно
      на один дом при заражении через водопровод. А у курьера была сумка на плече и
      склад за спиной, хотя на складе, с точки зрения здравого смысла, не один
      сплошной вирус, а какие-нибудь там залежи лекарства. Целый склад вируса — даже
      для них как-то слишком.</p>

    <p>— Склад не склад, всё равно
      много, если целая сумка только у курьера. И вряд ли Бедроградская гэбня
      собирается поставить вирус на полочку и любоваться. Куда разумнее закинуть его
      в какое-нибудь неожиданное место — например, в подвал <i>не к Ройшу,</i> — Диме вся эта обеспокоенность определённо не к лицу, и
      уж тем паче не к подтяжкам. — А значит, в чрезмерно хорошо обозримом будущем
      нас ожидает эпидемия.</p>

    <p>Всё это как-то неправильно.
      Попельдопель почувствовал себя дураком-оптимистом: приходят к тебе с утра на
      кафедру два покойника, триумфальное возвращение которых в Бедроград ты
      пропустил, потому что как раз в мае ловил леший знает
      где комаров-переносчиков столбнячного пихтского бешенства. Оба мёртвые,
      нелегальные и похорошевшие со времён своих трагических исчезновений. И вместо
      весёлых историй о смертях, посмертиях и воскрешениях рассказывают тебе, что в
      городе эпидемия.</p>

    <p>Эпидемия. Все помрут, и работать
      будет некому.</p>

    <p>— Ммм, — ответил Попельдопель, и
      на этот раз это было именно «ммм». Суровое, недовольное, осуждающее «ммм». — Ну
      как можно, кто всем этим вообще занимался, у кого хватило мозгов заварить кашу
      и не обратиться сразу к университетским медикам, ко мне лично, ну что это
      такое!..</p>

    <p>В самом деле, кому и зачем пришло
      в голову устраивать все эти сложные многоходовые хитрости, которые оборачиваются
      эпидемией? Неужели нельзя было как-нибудь попроще?</p>

    <p>Впрочем, Попельдопель спрашивал
      из любви к искусству диалога, а не на самом деле. На самом деле и так ясно,
      кому и зачем. Как полуслужащему под началом Университетской гэбни, да ещё и
      стабильно проводящему на кафедре истории науки и
      техники один из шести рабочих дней, Попельдопелю всё это было как-то даже <i>слишком</i> ясно.</p>

    <p>Гэбня хочет деятельности, может,
      даже правильно хочет, гэбня начинает деятельность, командует Максим, потому что
      Максим хорошо командует и мозги у него есть. А ещё у него есть Онегин. Габриэль,
      батюшку его, Евгеньевич.</p>

    <p>И как только приходит (приползает
      под тяжестью груза собственного существования!) Онегин, вся деятельность идёт
      прахом. Времени командовать у командира не остаётся. Сколько раз уже было, сил
      никаких нет. Максиму докторскую защитить некогда со всей этой вознёй при страждущем, а уж в гэбне сидеть…</p>

    <p>Поэтому чего бы там гэбня ни
      собиралась, всё равно всё явно пошло не так. И явно из-за глупостей, можете
      даже не пытаться переубеждать Попельдопеля.</p>

    <p>— Вопрос не в том, кто виноват, а
      в том, что теперь со всем этим делать, — мрачный-мрачный Дима тоже может не
      пытаться. Хотя он и не пытается, кажись. — Я вижу стоящую перед нами проблему
      следующим образом: у Бедроградской гэбни есть до жопы лекарства, но они не
      собираются его использовать — то есть собираются, но только тогда, когда мы
      будем уже кругом виноватыми. А у нас есть яростное желание всех — включая свою
      репутацию — спасти, но нет лекарства. Идеальным решением было бы взяться за
      руки и подружиться, но мне почему-то кажется, что это может и не сработать.</p>

    <p>Лекарство. Попельдопель в
      очередной раз посмотрел на Димину пожёванную бумажку с формулой. Снял очки. Не
      помогло. Так и так для производства лекарства в количестве, высчитываемом по
      типовой задачке с госа про скорость распространения заражения, нужна
      аппаратура, которой на медфаке нет.</p>

    <p>На медфаке много чего есть,
      Попельдопель не жалуется, много чего можно для медфака попросить, благо
      Попельдопель учился на одном курсе с одним из голов Медицинской гэбни, а
      значит, может клянчить прямо у Столицы. Много чего — но не, леший вас всех
      забери, аппаратуру для синтеза крови <i>в
        промышленных объёмах</i>!</p>

    <p>Тот самый случай, когда значение
      имеет именно что размер. Медфаку такая аппаратура не нужна, подобный запрос в
      здравом уме не объяснишь, а даже если и объяснишь, на
      оформление-транспортировку-установку времени уйдёт уйма, а скорость
      распространения заражения из типовой задачки…</p>

    <p>— Гуанако, — Попельдопель
      подумал, что если уж покойники оживают, ими просто-таки грешно не
      воспользоваться по назначению. — Раз у тебя загар, тельняшка и фальшивые
      документы, значит, ты торчишь в Порту. Так пойди и сопри лекарство прямо у
      Бедроградской гэбни, если вы даже склады уже нашли. Ты же умеешь.</p>

    <p>Загар, тельняшка и серьга в ухе.</p>

    <p>Покойник Гуанако при жизни
      отличался… отличался и много раз, короче говоря. У
      него — помимо самодовольной ухмылки и объёмистого собрания сочинений — были ещё
      и криминальные связи. Едва ли не с отрядского возраста, дело давнее, а давних
      дел в подробностях Попельдопель обычно не помнит. Зато помнит промышленные
      объёмы говна, на которые изошла наркотическая инфраструктура медфака, когда
      основным университетским поставщиком травы ни с того ни с сего стал истфак.</p>

    <p>Это «ни с того ни с сего» Попельдопель
      знал в лицо ещё до всякого говна, потому что не только заведовал кафедрой на
      медфаке, но и читал модули по истории медицины на истфаке. «Ни с того ни с
      сего» в студенческом возрасте являло собой грозу всего живого и прогрессивного
      с переизбытком собственного мнения — спасибо хоть не в вопросе истории
      медицины, а то превратились бы экзамены в массовый скандал. Потом особое мнение
      увело Гуанако подальше от Университета, аж в армию
      (говорят), а потом привело обратно, в аспирантуру и преподавательский состав,
      где они с Попельдопелем вроде как познакомились, а на самом деле —&nbsp;страшно
      обрадовались встрече.</p>

    <p>Золотое было время, без дурацкой политики и эпидемий.</p>

    <p>— Спереть со склада нельзя. Мы
      уже думали об этом, — потряс лохматостью Дима (видимо, подразумевалось
      озабоченное покачивание головой), — но не покатит. Информация, информация! Мы
      начинаем и выигрываем только потому, что знаем об их планах, а они о наших нет.
      Ограбь склад —&nbsp;и идиот
догадается, что что-то нечисто. А Бедроградская гэбня, к сожалению, ещё
      и не идиоты. Скрытность и загадочность должны быть нашими вторыми именами. Ну,
      вторым и третьим, видимо.</p>

    <p>— Не говоря уже о том, — кивнул
      Гуанако, — что грамотно обнести склады способен мало кто. Даже если это сделаю
      лично я с группой квалифицированных сочувствующих,
      грешить-то Бедроградская гэбня всё равно на Порт станет. На <i>весь</i> Порт.
      Подстава это, Попельдопель.</p>

    <p>— Ну да, наверное, — пришлось
      согласиться Попельдопелю. Хотя, конечно, обидно. Такая была бы весёлая история
      про эпидемию, никаких сил и средств на такую не жалко.
      А так вместо веселья придётся впахивать, и ещё непонятно, чем это закончится.
      Нужного количества лекарства на медфаковской аппаратуре не синтезируешь!</p>

    <p>— Максим Аркадьевич пытается
      достучаться до фаланг, — покойник Гуанако с каменной рожей
      назвал Максима по имени-отчеству, хотя все они: и Максим, и остальная гэбня, и
      Ройш, и Дима (Дима Ройш!), и даже несчастный завкаф науки и техники Онегин —
      были ему при жизни студентами.</p>

    <p>А Дима и Онегин вроде и не только
      студентами, там какая-то жутко драматическая история, но на то, чтобы вникать
      во все эти истории, Попельдопелю вечно не хватало ни времени, ни желания. Ну
      их.</p>

    <p>— Нажаловаться наверх? —
      переспросил он. — А толку?</p>

    <p>— Если даже чума в Бедрограде не
      заставит фаланг обратить сюда свои взоры, то, думаю, можно смело причислять их
      к разряду вымышленных существ, — загробным голосом высказался покойник Дима.</p>

    <p>— Только если чума в Бедрограде
      случится и выплывет наружу, — перебил его покойник Гуанако, — Университет вроде
      как продует Бедроградской гэбне. У них же священная война.</p>

    <p>Священная война, она же мелкие дрязги.</p>

    <p>Застарелая вражда начинается с
      чепухи и ведёт к эпидемиям. Жили-были Удий и Жудий; Жудий назвал Удия нехорошим
      словом, а Удий обиделся и ударил Жудия в лицо, а Жудий обиделся и сломал Удию
      ногу, а Удий обиделся и вырезал всех друзей, родственников и знакомых Жудия.
      Жудий почти по ошибке посадил студента Удия, а Удий отобрал у Жудия городские
      канализации, а Жудий теперь готов полгорода перетравить, лишь бы доказать, что
      Удий сволочь.</p>

    <p>И ведь главное — даже если
      заняться поиском правых и виноватых, докопаться до того, кто первый начал,
      выяснится, что вначале было либо недоразумение, либо какая-нибудь ну такая
      ерунда, о которой и говорить стыдно. А потом одно, другое, третье —&nbsp;и
      теперь уже не остановиться ни первой стороне, ни второй.</p>

    <p>Вот и Дима туда же:</p>

    <p>— Ничего Университет не продует,
      если докажем, что это они на нас наезжают. И докажем. Впрочем, — он почесал
      макушку и поёрзал в слишком большом кожаном кресле, — это занятие всяких там максимов
      и ройшей, а мы тут исключительно по медицинскому вопросу спасения всех и вся от
      неминуемой гибели.</p>

    <p>Попельдопель вздохнул.</p>

    <p>И вздохнул ещё тяжелее, когда в
      ту же минуту раздался стук:</p>

    <p>— Юр К-к-карлович, можно? — вот
      кого здесь сейчас не хватало, так это Шухера.</p>

    <p>Покойник Гуанако ощутимо напрягся:
      посторонние штурмуют помещение! Покойник Дима повертел головой и сделал вид,
      что тоже волнуется.</p>

    <p>— Расслабьтесь, если мы таки
      будем кого-то лечить, то вот нам и специалист нужного профиля, — с очередным
      вздохом пояснил Попельдопель, — неплохой даже. В политику не посвящён вовсе,
      полуслужащим не является, про гэбню не знает… но я бы
      его, наверное, привлёк к работе по спасению всех и вся.</p>

    <p>Попельдопель говорил в два раза
      медленнее, чем обычно говорил Попельдопель. Растя-я-ягивая слова-а-а, делая неуместные па-а-аузы и очень надеясь, что Шухеру надоест
      стоять под дверью. А привлечь его к работе можно когда-нибудь потом.
      Когда-нибудь.</p>

    <p>— Войдите, — наконец сдался он.</p>

    <p>Шухер вошёл.</p>

    <p>Он не то чтобы был плохим
      человеком, но каким-то… каким-то… мелким, что ли. И не
      то чтобы плохо работал, просто медленно, в своём собственном ритме, и чего у
      него ни попроси, всё равно скажет «А? Что? Попозже» и преспокойно забудет
      задолго до наступления этого своего попозже. Попельдопель от такого отношения к
      делу впадал в бестолковую ярость, но приходилось мириться — специалист-то Шухер
      и правда неплохой, доктор наук всё-таки.</p>

    <p>Манная каша, вот он кто.</p>

    <p>Тихая, сопливая,
      заикающаяся манная каша!</p>

    <p>— Юр К-к-карлович, — повторно
      заикнулась манная каша и впала в ступор. Видать, разглядела покойников.
      Одного-то из них точно.</p>

    <p>— Шухер! — дурным голосом заорал
      покойник Гуанако и бросился обниматься.</p>

    <p>Попельдопель довольно усмехнулся:
      ну да, они же почти ровесники, они же на старших курсах как раз не сошлись на
      почве наркотических поставок в Университет.</p>

    <p>А на глаз и не скажешь. Гуанако
      нынче снова отрастил волосы и даже напялил нечто крайне похожее на студенческий
      мундир, даром что бордового цвета, а тельняшку он и
      раньше под него носил — вот и вышел почти четверокурсник, в свои-то сорок.
      Шухер же отрастил только серьёзность. Сейчас посмотришь и не скажешь, что в
      былые годы он ругался с кем бы то ни было за оборот
      травы.</p>

    <p>Время никого не щадит, просто
      некоторые успешно это скрывают.</p>

    <p>Шухер опасливо замер в яростных
      объятиях Гуанако и вопросительно посмотрел на Попельдопеля. В самом деле, ещё
      ведь не весь Бедроград в курсе, что покойные покойники не очень-то покойные — а
      кончина светила науки истории в своё время была довольно громкой. Даже для
      непосвящённых в политику, для них ведь имелась своя легенда: светило науки
      пропал в экспедиции на другом континенте, ах-ох.
      Попельдопель вот весьма впечатлился в своё время, но так вышло, что через год
      или вроде того возникла Университетская гэбня, а у
      самого Попельдопеля вслед за этим возник девятый уровень доступа и прочие
      привилегии полуслужащего — в том числе и информационные.</p>

    <p>В общем, смерть в горящем изоляторе
      на Колошме звучала эпичнее экспедиций, но Шухеру и экспедиций, кажется,
      хватило.</p>

    <p>— Шухер. Над нами нависает очень,
      очень много работы. Тяжёлой, неприятной и безотлагательной. Безо всяких
      «попозже», — мстительно заявил Попельдопель, смакуя каждое слово. Надо
      припахать Шухера так, чтобы он после всей этой чрезвычайной ситуации встать не
      смог.</p>

    <p>Будет знать, что случается с
      теми, кто отлынивает от трудов!</p>

    <p>— Шухер? — Дима картинно
      прищурился. — Мой острый ум подсказывает мне, что вы, наверное, родственник
      Брови.</p>

    <p>Брови?</p>

    <p>Ааа, девочка с истфака.</p>

    <p>— Мою
      д-д-дочь… — хотел было ответить Шухер, но Попельдопель с бессмысленным
      злорадством перебил его.</p>

    <p>Заик легко и приятно перебивать!</p>

    <p>— Он её п-п-па-п-п-паша.
      П-п-папаша, — пояснил Попельдопель и неожиданно для самого себя прибавил: —
      Папа Каша.</p>

    <p>Покойник Гуанако заржал и
      подавился дымом, но радость бессмысленных издевательств подпортил своим укоризненным
      взором покойник Дима. Попельдопель развёл руками — мол, ничего не поделаешь,
      Папа Каша и есть Папа Каша.</p>

    <p>Манная.</p>

    <p>— Мою
      д-д-дочь, — не замечая окружающей действительности, лично для Димы продолжил
      Шухер, — зовут Б-б-брованна. Ваня.</p>

    <p>— Наслышан. Равно как и о том,
      что ей это наименование не нравится.</p>

    <p>Действительно, дурацкое
      имя.</p>

    <p>Девочка-то милая, если
      Попельдопель, конечно, её ни с кем не спутал. Тощенькая, громкая, вечно в
      каких-то безразмерных свитерах и на тонких ножках — птичка. И с таким огро-о-омным,
      тяжёлым именем — Брованна!</p>

    <p>Только Шухер мог так ошибиться,
      лох.</p>

    <p>Кажется, «лох» вырвалось вслух.</p>

    <p>Ну и ладно, ну и вырвалось — у
      Попельдопеля вообще часто что-нибудь вырывается.</p>

    <p>— Андроний… эээ, как ваше
      отчество? — вкрадчивым тоном начал Дима, вытягивая ноги в (помилуй нас всех)
      канареечного цвета носках.</p>

    <p>— Леонидович, — всё так же
      подозрительно ответил Шухер.</p>

    <p>— Андроний Леонидович, полагаю,
      нам нужна ваша помощь.</p>

    <p>— Ты уверен? —&nbsp;в который раз
      вклинился покойник Гуанако, изображая глазами страшные фигуры. — <i>Начальство</i> пока разрешения не давало.</p>

    <p>— Уверен, — насупился Дима, — Попельдопель и я
      — это в лучшем случае один целый три десятых медика. Нужен кто-то ещё — и кто,
      как не Андроний Леонидович? И кто только что говорил, что у нас мало времени?</p>

    <p>Шухер явно не понимал возникшего
      вокруг него ажиотажа. Попельдопель, в общем-то, тоже, но зачем же так явно это
      демонстрировать!</p>

    <p>Папа Каша, Папа Каша, ня-ня-ня.</p>

    <p>— П-п-помощь? — подал наконец голос Папа Каша и что только не попятился по
      стеночке. —&nbsp;А вы, с-собственно, к-кто такой?</p>

    <p>На покойника Гуанако он просто
      предпочёл не смотреть. Патентованный шухеровский метод общения с
      действительностью: зажмуриться и чтобы её как бы не было. Отсюда и прозвище.</p>

    <p>Хотя, конечно, какого ещё
      прозвища может ожидать носитель звучной фамилии Шухéр.</p>

    <p>То ли дело он сам, Попельдопель.
      Вот у него —&nbsp;правильное, звучное прозвище. Всё потому, что Юр Карлович
      Поппер долгое время работал по переписке с немецким вирусологом Карлом-Йоханном
      Доплером, наработал вакцину Поппера-Доплера, вакцина получила мировое признание
      и диплом (ныне —&nbsp;фундамент пробирочного сруба), а Попельдопель получил
      новое имя.</p>

    <p>Мировое.</p>

    <p>Дима тем временем что-то бурно
      задвигал Шухеру — кажется, прикрываясь его, попельдопелевским, авторитетом. Ну
      и пусть: сами-то они покойники, им никто ничего не должен, а Шухера надо
      припахать; и если делается это мировым именем Попельдопеля, то он только рад.</p>

    <p>Покойника Диму, в отличие от
      покойника Гуанако, Попельдопель знал в основном по слухам про
      драматические истории. Лично они познакомились на Димином третьем курсе, на
      консультации за два дня до экзамена.</p>

    <p>Истфаковского, по истории
      медицины. Когда-то ноль целых три десятых медика изучали историю и имели склад
      ума, диаметрально противоположный естественнонаучному
      (что довольно остро выяснилось на самом экзамене).</p>

    <p>А теперь эвон оно как вышло.</p>

    <p>Ноль целых три десятых медика
      тогда ввалились через полчаса после начала консультации, плюхнулись на первую
      парту (прямо перед преподавательским столом, которую никто никогда не хочет
      занимать) и выпучили на Попельдопеля глаза, изображая предельное внимание.
      Выглядел при этом Дима так, как будто только что выбрался из эпицентра
      небольшого взрыва. Попельдопель тогда степенно изучил его специфический внешний
      вид и осведомился, кто, собственно, перед ним и чего ему надо, на что получил
      невозмутимое «студент такой-то, экзамен хочу сдавать». На резонный вопрос о
      том, где он шлялся весь семестр, студент такой-то
      внимательно осмотрел маленькую, на двадцать человек аудиторию и доверительно
      поведал, что он ходил, просто сидел за колонной.</p>

    <p>Мысль о том, что этот человек
      работал в Медкорпусе и даже, кажется, был там на
      хорошем счету, помогал высококвалифицированному специалисту, внушала страх и
      —&nbsp;чего греха таить —&nbsp;некоторую радость.</p>

    <p>Так им!</p>

    <p>Сладкую ностальгию прервала повисшая
      в кабинете тишина. Видимо, Дима только что закончил какой-то особо
      прочувствованный монолог. Про эпидемию, про что же ещё.</p>

    <p>— Надо обратиться в Ст-т-толицу,
      известить… — изрёк Шухер и, видать, сам испугался того, что собирался сказать
      дальше, — известить Медицинскую г-г-гэбню, — и
      посмотрел эдак, прямо с вызовом. Жуть жуткая, страшное слово «гэбня»!</p>

    <p>Попельдопель, кажется, опять
      пробурчал что-то недовольное вслух.</p>

    <p>— Нет, вот этого точно не надо, —
      мгновенно среагировал Дима, почти подпрыгнул в кресле и схватил следующую
      сигарету. — Я же объяснил: вирус, который сейчас в Бедрограде, основан на… эээ,
      родственен степной чуме. Давайте-ка подумаем вместе, какова вероятность того,
      что Медицинская гэбня вместо спасения всех и вся возжаждет изучить историю болезни
      целого города? Понаблюдать естественное протекание, рассмотреть связанные с
      эпидемией социальные изменения? Да дай им волю, они нам и ещё вирусов подкинут.</p>

    <p>Всё это, конечно, так
      (Попельдопель даже покивал в подтверждение), даже сто тысяч раз так, Медицинская гэбня слово «гуманизм» только в книжках читала,
      но остаётся вопрос (праздный, но любопытно же!): Димина позиция — это как раз
      несчастный гуманизм пополам со здравым смыслом, или он просто от Медицинской гэбни скрывается?</p>

    <p>Веселее, если скрывается.</p>

    <p>Надо будет потом (без всяких
      шухеров) спросить.</p>

    <p>— Д-да, но мы должны, — упрямо
      буркнул всякий Шухер, — этот в-вопрос не в нашей
      к-к-компетенции. Д-даже если в Университете есть г-г-гэбня… п-п-поймите, не в
      наших силах… Зачем вы мне это рассказали? — вдруг
      возмутился он. — Я не хочу разбираться с эпидемией в Б-бедрограде и не хочу
      знать, что она инициирована г-г-городскими властями. Это не мой уровень
      доступа!</p>

    <p>— Андроний Леонидович, — Дима
      всё-таки подпрыгнул, — властями, уровнями доступа и прочей политикой занимается
      Университетская гэбня. Кто знает, может, как раз в эту
      минуту к Бедроградской гэбне применяются подобающие санкции. Но всегда есть
      опасность, не знаю, бюрократических проволочек, каких-нибудь ошибок — а ждать
      нельзя, чума, простите за плеоназм, смертельна! Вы один из главных вирусологов
      Университета, и я, — уронил он покаянно голову, — гнусно
      взываю к вашей совести.</p>

    <p>Шухер молчал, тоскливо глядя на
      присутствующих и, кажется, догадываясь, что поддержки он ни от кого из них не
      дождётся.</p>

    <p>— Если вам будет спокойнее
      работать по прямому указанию Университетской гэбни, это, думаю, можно устроить,
      — искусительски добавил Дима.</p>

    <p>— Но я п-п-понятия не имею, что
      делать в такой ситуации! —&nbsp;вскричал Шухер — сдаваясь, впрочем. —
      К-к-конечно, я готов п-помочь с медицинской ч-частью, но к-к-какой в этом толк,
      если речь идёт о… о… я д-даже не знаю, к-к-как это
      назвать, — он вздохнул. —&nbsp;Я п-п-помогу, если вы скажете, что от меня т-требуется.</p>

    <p>Да кто бы знал, что требуется!
      Попельдопель, завкаф вирусологии и медик с мировым прозвищем еще раз обвёл
      взглядом присутствующих, не особо скрывая растерянность. Да-да, он тоже готов
      бежать и делать — сказал бы кто, куда бежать.</p>

    <p>Дима, три десятых медика, не
      отличающий вакцину от сыворотки, пока что изо всех сил переживал свой триумф в
      сфере убеждения.</p>

    <p>Гуанако, не медик ни в одном
      глазу, с предельной суровостью точил какой-то случайный карандаш внушительным
      ножом, грубая и потрёпанная рукоятка которого, видимо, символизировала серьёзность
      сложившейся ситуации.</p>

    <p>Шухер, полноценный медик и доктор
      наук, искал поддержки у узора на занавесках попельдопелевского кабинета.
      Хороший узор, геометрический — вроде бы стилизованный под таврский. Три круга —
      изгиб — три круга. Круги-то точно знают, как теперь со всем этим быть!</p>

    <p>— Но как же… г-г-городские
      власти, они же должны заниматься г-городом! — очень по делу высказался Шухер. —
      Я помню, я туда обращался, к-когда у меня багаж куда-то д-д-делся… и к-когда
      Ванечка в д-детстве потерялась, не вернулась из д-д-дома в отряд, к п-подружке
      п-п-поехала. Т-то есть не прямо к ним, к-конечно, в п-приёмную, но… а теперь они
      г-город заражают…</p>

    <p>А раньше занимались исключительно
      поиском детей и чемоданов! Ага, белая и пушистая
Бедроградская гэбня, которую нелёгкая жизнь довела до распространения в
собственном городе смертельной болезни.</p>

    <p>Попельдопель фыркнул.</p>

    <p>Так как больше никто не спешил
      заняться нервами Шухера, выступать опять пришлось Диме:</p>

    <p>— Они и занимаются городом.
      Регулярными уборками неблагонадёжных элементов. Знаете, я даже верю, что ваш
      багаж тогда нашли: когда обращаются к Бедроградской гэбне, она, может, и
      работает. А вот когда <i>не</i> обращаются,
      она приходит сама и делает всё, чтобы не обратиться было невозможно, — ничего
      себе, сколько в Диме, оказывается, затаилось проникновенности и гражданского
      пафоса. — В этом и проблема. Университет может прожить без Бедроградской гэбни,
      и это задевает их ранимые сердца. Они хотят, чтобы все ходили к ним за
      потерянным багажом, людьми и смыслом жизни. Весь город. Даже если города в
      итоге не останется.</p>

    <p>И не останется, раз все только и
      делают, что охают!</p>

    <p>Если Шухер сейчас спросит ещё
      что-нибудь бестолковое, а Дима в ответ продолжит разводить гражданский пафос,
      Попельдопель кого-нибудь ударит. Или на кого-нибудь наорёт, или что-нибудь
      сломает. Или как-то по-другому из него попрёт паника,
      но точно попрёт. Потому что на Попельдопеля таки <i>накатило</i>.</p>

    <p>Потому что они тут сидят,
      обсуждают моральный облик Бедроградской гэбни, а время-то нигде не сидит и
      никого не обсуждает. Время тикает, вирус распространяется, а как с этим быть — до
      сих пор непонятно.</p>

    <p><i>С некоторой вероятностью</i> Бедрограду грозит эпидемия водяной чумы.
      Заражение рукотворное, а значит, надеяться на удачу не
      приходится —&nbsp;само собой не пронесёт. Правда, формула лекарства известна,
      вот только пожёванной бумажкой с оной больных людей не накормишь, а больше
      ничего нет.</p>

    <p>С некоторой вероятностью.</p>

    <p>Будь вероятность маленькой, припёрлись бы покойники к Попельдопелю, ага, как же.
      Будь
      вероятность маленькой, он бы их сам взашей отсюда
      выгнал, других дел по горло. Встретились бы вечерком, посидели бы, потрепались
      о нелёгкой судьбе…</p>

    <p>Но сейчас-то
      сколько можно трепаться?</p>

    <p>— Хватит уже пережёвывать!</p>

    <p>Даже Гуанако, принципиально
      изображавший мебель, обернулся и внимательно посмотрел на Попельдопеля.
      Наверное, вышло слишком громко.</p>

    <p>И да, Попельдопель предпочёл не
      заметить, что время, судьба города и план дальнейших действий волновали его
      гораздо меньше, пока беседа была весёлой, а неприятных собеседников рядом не
      наблюдалось.</p>

    <p>Лучше он ещё немного посозерцает
      приятных.</p>

    <p>Медика, занимающегося исследованиями,
      а не просиживающего зад в районной поликлинике, легко узнать по рукам.
      Обязательно какие-нибудь разводы, ожоги, навсегда въевшиеся пятна — даже у
      подстригавшего каждые три-четыре дня ногти Попельдопеля они имелись.</p>

    <p>У Димы руки были жёлтые почти по
      локоть. Ну, не жёлтые-жёлтые, а так, неравномерно желтоватые, загрубевшие, с
      пересохшей на костяшках кожей — очень правильные руки, проще говоря.</p>

    <p>Молодец Дима: образованием не
      вышел, зато компенсирует площадью пострадавшей за науку поверхности тела!</p>

    <p>Жёлтая рука снова утонула в
      эпицентре небольшого взрыва, локализованного на Диминой голове. Голова
      вздохнула и заговорила неожиданно ровным голосом:</p>

    <p>— Хорошо, давайте по делу. Мы не
      можем ворваться в здание Бедроградской гэбни с пистолетами и таким образом остановить
      заражение, мы можем только лечить больных. Формула лекарства имеется, но
      синтезировать его без аппаратуры не получится. Да и вообще, синтез естественных
      образований — белков, например — штука тёмная. Значит, сделать лекарство мы не
      можем. Так?</p>

    <p>Попельдопель заворожено кивнул.
      Давненько ему не читали лекций по вирусологии.</p>

    <p>Особенно люди, не отличающие
      вакцину от сыворотки. Это было весело и как-то освежало — никакой академической
      мути, только суть дела.</p>

    <p>— А вот и не так, — риторически
      воскликнул Дима. — Что нельзя синтезировать, можно получить естественным путём.
      Водяную и степную чуму объединяет в первую очередь базовый компонент… это такая
      хрень, не помню, как называется, но работает она по принципу клея, скрепляет
      молекулу вируса, чтобы та не развалилась. Впрочем, в молекуле степной чумы эта
      хрень вроде как ещё что-то делает. Не знаю, не суть. А сути две: во-первых, эта
      хрень сама по себе абсолютно безвредна, а во-вторых, она наличествует в кровавой
      твири.</p>

    <p>Дима сделал драматическую паузу.</p>

    <p>Суть, хрень. Клей! Аж завидно — так бы лекции читать. Не заморачиваясь, в
      смысле.</p>

    <p>— П-п-погодите, — Шухер сделал
      круглые глаза и задохнулся, — это что же п-получается? В г-городе вирус,
      родственный ст-т-т… ст-т… — ну да, а теперь ему ещё и название степной чумы не
      выговорить. Учёный.</p>

    <p>— Да, да, говорил же уже. Но он
      куда менее опасен. Верьте мне, я сам его делал. Из грязи.</p>

    <p>Гуанако посмотрел на товарища по
      посмертию с укоризной. И справедливо: у Шухера с сердцем не очень, ему вредно <i>столько</i> волноваться.</p>

    <p>— Это б-безумие!</p>

    <p>— Вы бы предпочли, чтобы город
      сейчас оказался заражён вирусом, о котором мы ничего не знаем? —&nbsp;Дима
      сверкнул глазами. — Так сложилось, что Университет прослышал о затеях
      Бедроградской гэбни. Так сложилось, что мы по-прежнему не можем ворваться в их
      здание с пистолетами. Так сложилось, что доказать преступление без состава
      оного невозможно. Так сложилось, что я в подходящее время работал в Медицинском
      корпусе и имел возможность поспособствовать созданию подходящего вируса, а
      потом предоставить Университету всю информацию, — он выдохнул и продолжил
      извиняющимся тоном: — Поймите, Бедроградская гэбня носится с этим планом семь
      лет. Семь. И могла бы носиться ещё семь, и могла бы довести его до идеала.
      Университет решил, что лучше подготовиться и спровоцировать. Кто мог знать, что
      желание припугнуть одним заражённым домом у Бедроградской гэбни перерастёт в
      склады чумы?</p>

    <p>— Сумки. Пока что курьерские
      сумки, — педантично хмыкнул Гуанако куда-то в сточенный до зубочистки карандаш.</p>

    <p>Шухер с глубоко несчастным видом пялился на поблескивающий гуанаковский нож.</p>

    <p>У нашего Папы Каши слишком много
      морально-этических сложностей и слишком мало научно-исследовательских.
      Попельдопель хотел снова прикрикнуть, а вместо этого вдруг вспомнил один из
      забытых в ходе обсуждения вопросов и тут же брякнул:</p>

    <p>— Я вот сразу не спросил… ты в самом начале говорил, что сделал свою чуму из
      грязи, из которой теоретически можно получить степную. А грязь-то ты откуда
      вытащил?</p>

    <p>— Из холодильника, — широко
      улыбнулся Дима. — И всё-таки не совсем я, работал в основном Шапка. Вернёмся к
      делу.</p>

    <p>Ну нет,
      второй раз Попельдопель этот вопрос не забудет. Если три
десятых медика где-то надыбали образец степной чумы в дормантном состоянии,
кафедра вирусологии БГУ им. Набедренных просто обязана узнать, где именно.</p>

    <p>А вот Медицинской гэбне этого
      знать не стоит. Медицинская гэбня из зачатка степной чумы непременно возжелает
      сделать саму степную чуму — и один только леший ведает, какого масштаба им
      потребуется рабочая площадка.</p>

    <p>Город, например.</p>

    <p>— Та самая хрень, о которой я
      говорил, по-прежнему безопасна и содержится в кровавой
      твири. Безопасна — значит, принимается организмом. И,
      скажу я вам, весьма гостеприимно принимается, легко прицепляется к антителам,
      например. Собственно, так вирус в организме и удерживается. Если убрать хрень,
      он развалится и безболезненно выйдет естественным путём.</p>

    <p>Сточенный до зубочистки карандаш
      украсил собой крышу пробирочного склада, сделавшись не то флагштоком, не то
      антенной.</p>

    <p>Гуанако и Дима ввалились к
      Попельдопелю на кафедру очень вдвоём, но теперь ему
      как-то показалось, что тот из покойников, который изображал мебель, почти
      скучает, или не одобряет происходящее, или просто не при делах.</p>

    <p>Чаю ему сделать, что ли.</p>

    <p>— Синтезировать то, что убирает
      хрень, мы не можем, в нормальном состоянии организму на неё пофиг. Но! — Дима
      воздел палец. — К счастью, существует некий препарат —&nbsp;сыворотка,
      вызывающая аллергию на твирь, в которой эта хрень, напоминаю, тоже есть. Шапка
      же делал когда-то. Для меня же. Ну, в некотором роде.</p>

    <p>То, что убирает хрень, — это,
      видать, антиген. Синтезировать можно, но с учётом объёмов…
      в общем, опять всё дело упирается в объёмы и аппаратуру, которой у Столицы не
      попросишь, потому что не объяснишь, зачем.</p>

    <p>— Препарат этот совсем простой,
      его мы сделать на коленке точно сможем. Он, вообще говоря, изначально вызывает
      конфликт с другим компонентом твири, не хренью… и да,
      кажется, я ввёл новый термин! Так вот. К счастью — ну, для науки, не для
      испытуемого — конфликт разрастается, происходит эпическое сражение, издёргавшийся
      организм в ужасе отступает и начинает ненавидеть всё, что связано с кровавой
      твирью. Процесс неприятный, зато в результате
      хрень тоже становится врагом. Даже если она не в твири, а вовсе и в чуме.
      Понимаете, куда я клоню?</p>

    <p>— Сыворотка для аллергии — и есть
      лекарство? — поднял брови Шухер.</p>

    <p>— Да нет! Говорю же, она
      изначально реагирует не с хренью, аллергия на всё и вся — это сильно не первый
      этап процесса, — Дима почти незаметно дёрнулся. — И даже не самый неприятный.
      По крайней мере, к его моменту как-то уже и плевать, где чего болит. Но зато
      если сражение в крови достаточно масштабно — то бишь
      если сперва уколоть сывороткой, а потом долго и непрерывно подвергать
      воздействию аллергена — то в результате в крови образуются антитела, способные
      победить чуму. Извлечёшь кровь вместе с ними — получишь основу для лекарства.
      Про степную не знаю, а от водяной уж точно поможет.</p>

    <p>Попельдопель тряхнул головой и
      схватился за первую попавшуюся чистую бумажку. Надо нарисовать схему, а то
      Димин поток слов вроде бы и понятен, но настолько сумбурен, что страшно в
      спешке чего-то не уловить. Особенно с учётом того, что общепринятый
      терминологический аппарат у Димы за пять минут всё равно не отрастёт, этот их
      Шапка в Столице, а значит, надо говорить с имеющимся консультантом. И говорить
      на том языке, который этот консультант использует.</p>

    <p>Так. Рисуем звёздочку. Это хрень.</p>

    <p>Рисуем звёздочки между палочками
      — это степная чума. Палочки — её неизвестные и сейчас не очень важные
      компоненты. Хрень (звёздочки) — клей, как выразился Дима, компоненты связывает.
      Вязание сплошное, салфетка кружевная какая-то.</p>

    <p>Второе кружево — водяная чума.
      Всё то же самое, но звёздочки цепляют не прямые палочки, а какие-нибудь
      волнистые линии, которые компоненты уже водяной чумы. Они тоже пока неважны,
      хотя формула есть, можно было бы и подробно изобразить, но не нужно.</p>

    <p>А теперь кровавая твирь. Она не
      вирус, она трава, поэтому и рисовать надо не кружево, а просто облако
      компонентов. Много кружочков и одна звёздочка.</p>

    <p>Не академично, зато сразу видно,
      что объединяет две болезни и одну траву.</p>

    <p>Сыворотка, вызывающая аллергию на
      твирь, реагирует с каким-то её компонентом. То есть, если на схеме —
      перечёркивает какой-то конкретный кружочек. Дальше понятно: поддерживаем в
      организме нужное соотношение сыворотки и твири (с сывороткой понятно, а вот
      твирь каким путём лучше внедрять?), получаем аллергическую реакцию уже не на
      конкретный компонент, а на все сразу. С аллергиями такое сплошь и рядом.
      Перечёркивание всех кружочков и звёздочки за компанию, выработка в организме
      антигена, множащего антитела.</p>

    <p>Антитела, борющиеся со степной
      чумой! С неизлечимой, неизученной, неуловимой степной чумой!</p>

    <p>Ну и заодно с
      нашей бедроградской-водяной (отличная двойная фамилия получилась).</p>

    <p>Попельдопель протянул свои
      каракули Диме:</p>

    <p>— Как-то так?</p>

    <p>— Угу, —&nbsp;кивнул тот.
      —&nbsp;У вас рука художника.</p>

    <p>— П-постойте, — вмешался Шухер. —
      Я, к-кажется, слышал нечто п-п-подобное. Семьдесят шестой г-год, последняя
      вспышка степной чумы. Т-тогда было много шума в-вокруг иммунной сыворотки
      Смирнова-Задунайского… к-который п-п-посмертно награждён
      за гражданское мужество. П-п-потом её вроде п-признали т-тупиковой
      ветвью.</p>

    <p>Или не захотели делиться
      результатами доведения сыворотки до ума. Попельдопель в которой раз поразился
      гражданской наивности Шухера.</p>

    <p>Шухер тем временем очень внимательно
      разглядывал бирюзовые подтяжки покойника Димы, с которым он при жизни знаком не
      был, но вот газетные портреты времён гражданского мужества, да и над медфаке один висит — всё-таки
      героически погибший автор сыворотки когда-то был студентом БГУ… ой.</p>

    <p>— Шухер, милый, — ласково позвал
      покойник Гуанако, резко перестав прикидываться мебелью. — Память — неприятная
      штука, да ещё и малоизученная. Вечно подводит, подкидывает ложные ассоциации,
      путает карты, выдаёт желаемое за действительное, достраивает недостающие детали… Пойди разбери, где кончается память и начинается
      воображение. Ты как медик, хоть и не того профиля, должен бы это понимать. <i>Я бы тебе от всей души советовал это
        понимать,</i> — Гуанако задумчиво и лирично повертел в руках свой выразительный
      тесак, недавно превративший карандаш в подобие зубочистки. — Буйство
      воображения случается. От недосыпа, от перегрузок на работе, да мало ли от
      чего. А в оживших покойников знаешь кто верит? Сплошь
      малые народы с их сохранившимися кровавыми культами и ещё психи.
      Точно говорю, — и вдруг облизнул нож, подмигнув Шухеру.</p>

    <p>Попельдопель к моменту демонстративного
      облизывания как раз успел осознать, что, наверное, совершил ошибку или даже
      служебное преступление, чем леший не шутит — без согласования с гэбней показал
      Шухеру покойников. С другой стороны, покойники сами пришли, сами принесли весть
      о чуме, а какой у них официальный статус и что они вообще во всей этой политике
      делают, непонятно. Может, поговорят-поговорят, а на следующее утро истают,
      будто и не было никаких покойников.</p>

    <p>— Так вот, лекарство, — как ни в
      чем не бывало продолжил Дима. — Допустим, берём мы
      какого-нибудь человека, вкалываем ему сыворотку для аллергии, набиваем поплотнее твирью и прячем в шкаф. Через два-три дня мы из
      этого шкафа можем извлечь пять литров основы для лекарства. Хотя все пять
      литров, наверное, брать не стоит. Наверное, от этого может случиться что-нибудь
      не очень хорошее. Поскольку ситуация драматическая, давайте брать, например,
      семьсот миллилитров… так, это считать сложно, давайте брать литр.</p>

    <p>Считать в любом случае сложно,
      сейчас всё равно промажет мимо точного числа, вздохнул Попельдопель. Правильно
      считать такие цифры большинство студентов только к госу обучаются. Но допустим.</p>

    <p>— В Бедрограде сколько? — около
      полутора миллионов жителей. Допустим, заразились все — потому что, знаете, мы
      так удачливы. Одному больному нужно вкалывать не меньше пятнадцати кубиков
      лекарства. Всего, соответственно, нужно… 0,015 л умножить на 1 500&nbsp;000… —
      Дима завис, в муках сдвинул брови и все-таки сосчитал. — 22&nbsp;500 литров.
      Значит, чтобы вылечить весь город, нам нужно двадцать две с половиной тысячи
      добровольцев, — Попельдопель на это чуть не заржал, но, посмотрев на Димину
      обалдевшую рожу, сдержался. — Знаете, я тут подумал — а давайте плюнем на
      программу-максимум, будем верить в удачу и исходить из того, что есть. Не
      заразят же они <i>на самом деле</i> весь
      город.</p>

    <p>— А если брать по пять литров, —
      еле сдерживая хохот, влез не-медик Гуанако со своим ножом, — понадобится даже
      меньше пяти тысяч, гм, добровольцев!</p>

    <p>— Жертвенно. Возложим же себя,
      своих друзей, родственников и тех, кто недостаточно быстро бегает, на алтарь
      гуманизма, — обиженно фыркнул недовольный своими подсчётами Дима.</p>

    <p>— А работать
      кто будет? — возмутился Попельдопель. Искренне возмутился!</p>

    <p>Хотя вообще-то давно пора
      спросить, как оптимально внедрять в организм кровавую
      твирь. И где её взять в таких количествах.</p>

    <p>То есть ещё непонятно, в каких
      (зависит от способа внедрения), но зато понятно, что скромности запросов тут
      можно не ждать.</p>

    <p>— К-к-какую ерунду вы мелете, —
      Шухер выглядел несчастным и, кажется, малость
      испуганным. — Да д-д-даже не на весь город, где мы возьмём хоть сколько-то
      д-добровольцев? Ещё и т-тайно от г-г-городской власти?</p>

    <p>Повисла нехорошая тишина, которая
      вернула Попельдопеля обратно к панике. Ну да, можно без аппаратуры, есть
      аллергическая реакция, есть общие компоненты у твири, чумы и другой чумы. Ну
      да, есть формула лекарства, вот она, а в ней треть — сыворотка крови с
      антителами. Только без аппаратуры или без людей (какие двадцать две, какие пять
      тысяч — хотя бы нескольких сотен!) всё это совершенно бесполезно.</p>

    <p>— Ну что же вы все так нервничаете? —
      усмехнулся в этой тишине Гуанако. — У нас, то есть у вас, есть целый
      Университет студентов.</p>

    <p>— Студентов, — Попельдопель
      задумался. — Но с учётом уровней доступа, статуса секретности и всего
      остального… как их заставить-то превратиться в ходячее лекарство? Мы же не
      можем сказать правду!</p>

    <p>Покойники переглянулись.</p>

    <p>— Говорить студентам, что мы
      хотим использовать их в качестве сырья для бесчеловечных экспериментов,
      действительно не стоит, — медленно, как бы спрашивая разрешения, произнёс Дима,
      —&nbsp;но это ведь не значит, что <i>какую-нибудь правду</i> рассказать нельзя.</p>

    <p>&nbsp;</p>

    <p><b><i><u>Решение</u>:</i></b></p>

    <p>&nbsp;</p>

    <p>План добычи лекарства из ничего
      был настолько сумасшедшим, что голова шла кругом и отказывалась верить в
      происходящее в принципе. Ну и леший с ней, с головой.</p>

    <p>Шухера Попельдопель оставил на
      медфаке заниматься всякими предварительными мероприятиями — и так и не спросил,
      кстати, зачем тот к нему в кабинет-то изначально стучался. Всяко
      же не вписываться в предотвращение чумы в Бедрограде.</p>

    <p>И плащ Попельдопель тоже оставил,
      шёл просто так, благородно зеленея костюмом, — на улице было на удивление
      тепло, хоть и ветрено. Вчера-то весь день дождь шёл стеной, и ночью шёл, и с
      утра накрапывал, а тут вдруг небо обратно прояснилось. И всё бы хорошо, но
      такая погода была прямо-таки неприлично на руку Бедроградской гэбне — шансы у
      врачей районных поликлиник заподозрить какую-то странную болячку у тех, кто к
      ним в ближайшие дни обратится, и так не очень высоки, а тут ещё и погодка.</p>

    <p>В отношении симптоматики у
      водяной чумы условно три этапа (на самом деле больше, но для выявления на глаз
      это несущественно). Первые дни — вялость, подавленность, сонливость и прочие
      мелочи, на которые мало кто вообще обратит внимание. Вирус обустраивается в
      организме, активно размножаться ещё не может, готовит почву. На этом этапе есть
      даже небольшая вероятность, что какой-нибудь особо сильный организм справится
      сам, задавит иммунитетом. Через два-три дня, у всех по-разному, происходит
      мощная атака на иммунитет в виде тяжёлого ОРЗ. Вирус хиленький, не степная же чума в самом деле, ему для манёвра требуются особые условия.
      Для создания этих условий к вирусу искусственно добавили ОРЗ. Иммунная система
      активируется, выявляет заболевание, спешно начинает производить антитела с
      соответствующей иммунной компетенцией — борется с ОРЗ, в общем. В это время
      обёрнутые в нейтральную хрень-клей молекулы чумы и
      проникают из крови в ткани и клетки организма. Не было бы ложной тревоги,
      иммунная система отнеслась бы к этому с подозрением, а так она слишком занята
      производством ОРЗ-специфичных антител. ОРЗ в итоге
      заканчивается — или не заканчивается,
      это вскоре становится неважно; важно, что чума к тому моменту успевает плотно
      обосноваться и начать наконец собственную атаку на
      организм. Как путано объяснил Дима, ОРЗ тут, с одной
      стороны, для эффективности, которая так нужна Бедроградской гэбне, с другой —
      для простоты выявления, которая нужна уже Университету, если вдруг что.</p>

    <p>«Если вдруг что» настало, но
      несчастная погодка усложняет дело: сколько народу вчера промокло, а сегодня
      обрадовалось солнышку, плюнуло на ветер и разделось обратно? Сам Попельдопель
      первый и разделся, чего уж. А это значит, что с
завтрашнего-послезавтрашнего дня сопливых и давящихся кашлем в районных
поликлиниках будет немало. Вычислить среди них больных на второй стадии
      водяной чумы — та ещё задача.</p>

    <p>Попытки лечить ОРЗ таким больным,
      конечно, помогут — от ОРЗ, не от чумы. Организм и дополнительные препараты
      будут изо всех сил бороться с нестрашным и несмертельным заболеванием, пока в
      какой-то момент вирус чумы не выйдет из подготовительной спячки и не устроит
      праздник, он же третья стадия. Быстро перекроит солевой баланс организма,
      станет тормозить жидкости и стягивать их куда леший
      захочет. Она ж не только потому <i>водяная</i> чума, что придёт из водопровода.</p>

    <p>Вирус почти случайно выбирает
      конкретный тип клеток и накачивает их жидкостями сверх необходимого,
      осушая другие клетки.&nbsp;Например, пересохшая кожа, а если пораниться —
      ка-а-ак хлынет. Ну или не кожа, кожа ещё ерунда, а вот
      стенки какого-нибудь внутреннего органа рано или поздно от давления разорвёт.</p>

    <p>Попельдопель пригляделся к
      усталой женщине, пытавшейся не задремать на скамейке, и отстранённо
      полюбопытствовал, что у неё разорвёт, если (допустим) она больна. Печень,
      определённо печень. Никаких оснований так думать не было, но у Попельдопеля с
      самого утра в голове почему-то вертелась сплошная печень.</p>

    <p>Хотя усталая женщина может жить
      прямо тут, в Университетском районе, который Бедроградская гэбня умышленно
      заражать не станет — они-то думают, что Университет про чуму не знает, прохлопает заражение ушами и останется кругом виноватым со
      своими непутёвыми канализациями. А в Университетском районе всё перед глазами,
      в районной поликлинике сидят сплошь медфаковские старшекурсники, у них мозги побыстрее соображают, чем у простых врачей. Неразумно тут в
      канализации вирусы пускать — вдруг кто почует
      неладное.</p>

    <p>Университетский район — самое
      правильное место во всём Старом городе. Помпезных особняков с садами, беседками
      и фонтанами тут никогда не было, просто многоквартирные дома дореволюционной
      постройки и петли мощёных улочек между ними, небогатое, но изобретательное
      литьё перил и крылечек, крохотные окна непонятного назначения, вылезающие в каких-нибудь
      неожиданных местах — то под самой крышей, то прямо между ярусами обычных окон.
      Кривоватые ступеньки с улицы сразу на чердак, минующие жилое пространство,
      кое-где сохранившиеся деревянные ставни, стены грубой облицовки «чтоб подешевле»
      — щербатой, под необработанный, кое-как перебитый
      камень. Низкие арки, фонарные цепи, подвальные забегаловки на месте прежних
      трактиров.</p>

    <p>От медфака (бывший Институт
      Штейгеля) до истфака (бывшая Академия Йихина) чуть больше получаса узкими
      улочками, а если знаешь, в какую хитрую дырку нырять, и того меньше. Как раз
      хватит времени пережить степень безумия плана по производству лекарства.</p>

    <p>«Если у тебя нихуя нет, — говорил
      только что на кафедре вирусологии покойник Гуанако, — надо ещё раз хорошенько
      подумать и исходить из того, что есть».</p>

    <p>Попельдопель совсем, совсем не
      ожидал, что у Университета есть хоть что-то, чтобы мотивировать студентов. Оказалось
      — есть, у покойника Димы даже фотоснимки нашлись при себе, но всё равно
      поверить непросто. Пойди тут поверь!</p>

    <p>Покойники, с которыми
      Попельдопель пёрся на поклон к гэбне, беззаботно
      трещали о чём-то своём: какой-то мастер татуировок, отрезанные косы и карты
      кладов — то ли радиопостановку обсуждают, то ли байку на ходу сочиняют.
      Попельдопель даже хотел прислушаться, но сосредоточиться на словах всё никак не
      получалось. Итак, скорость распространения заражения в населенном пункте на
      полтора миллиона человек…</p>

    <p>Истфаковское крыльцо (шесть пар
      колонн, а торжественного вида всё равно не выходит) было засижено студентами.
      Понедельник, до занятий доползти сил хватает, собственно заниматься — не очень.
      У Попельдопеля до всяких пришествий чумы тоже не хватало, послал вместо себя на
      лекцию аспиранта и заперся в лаборатории, там работа пободрее.</p>

    <p>Внутри многажды перестроенного
      здания бывшей Йихинской Академии всегда гулко и прохладно, но не зябко, а
      скорее свежо. Крохотный круглый холл по периметру заставлен едва ли не
      антикварными скамьями с резными спинками, на которых гораздо удобнее дремать,
      чем списывать. Над одной из них нависает прижизненный портрет Йыхи Йихина,
      коротконогого рыжего проходимца, державшего когда-то за ошейник весь Петерберг.
      Ошейников на парадном портрете полно — основатель Академии вряд ли позволил бы
      изобразить себя без первостепенных своих интересов, мальчиков в кружевах и
      больших лохматых собак.</p>

    <p>Хотя, наверно, правильно было бы
      упоминать их в обратном порядке — собак и мальчиков. Попельдопель не слишком-то
      знал историю, не считая Революции, каких-то общих вещей и, собственно, истории
      медицины, но Йыха Йихин ему нравился, и потому он даже откуда-то помнил, что
      тот вырос на псарне, был сыном пёсника, внуком пёсника, правнуком пёсника и сам
      собирался стать пёсником, но не сложилось. Сложилось попасть под суд на родной
      финско-голландской стороне и сбежать к лешему в строящийся портовый город на
      территории Росской Конфедерации. Только зелёные первокурсники истфака могут
      полагать, что Йихин основал какую-то там скучную историческую академию — на
      самом деле он основал бордель. А чтобы выдержать суровую конкуренцию, которой в
      портовом городе было предостаточно, он не только дрессировал своих мальчиков
      как настоящий пёсник и надевал на них ошейники в качестве знака отличия по
      профессиональному признаку, но и придумал кое-что покруче
      — он их, эээм, оскоплял. Мода, между прочим, продержалась до самой Революции
      (как и мода на ошейники). Рыжий Пёсник умудрился убедить как
своих современников, так и их потомков, в том, что лучшие работники борделей —
оскоплённые работники борделей. Физиологическая противоестественность
      этой моды всегда особенно восхищала Попельдопеля: невелика заслуга заставить
      людей верить в простое и естественное, куда веселее —
      в сложное и не укладывающееся в голове!</p>

    <p>Йихин даже придумал что-то вроде
      религиозной доктрины, предписывавшей его дрессированным
      оскопистам сношаться за деньги во имя преуменьшения страданий всего
      человечества. Покойник Гуанако ещё при жизни как-то объяснял Попельдопелю, что
      это йихинское учение было хитрой и грамотной издёвкой
      над традициями скопнических общин, доползшими с росской земли аж до Европ. И
      что ни в коем случае не следует путать бордельных оскопистов и благочестивых
      скопников, а также и тех, и других со скопцами, элитным войском леший знает когда
      павшей Империи. Потому что, мол, всё это очень,
      очень разные вещи!</p>

    <p>Видимо, изысканные богословские
      споры об этой разнице так нравились интеллектуально отягощённым клиентам
      Йихина, что ему, бедному, аж пришлось открыть
      Петербержскую Историческую Академию, которая ещё крайне нескоро перестала быть
      по совместительству борделем.</p>

    <p>Попельдопель улыбнулся парадному
      портрету и вместе с покойниками двинулся к главной лестнице истфака —
      громадной, роскошной, но при этом будто бы парящей в воздухе над скромным
      холлом. И, по правде сказать, совершенно бордельной. Искомая кафедра науки и
      техники — второй этаж по этой самой лестнице, тёмный закуток направо.</p>

    <p>Тёмный закуток направо, нетипично
      перегороженный каким-то столпотворением!</p>

    <p>Попельдопель пригляделся:
      определённо не студенты. Похоже на делегацию заводских рабочих из глубинки —
      странный говор, обгоревшая на солнце кожа, вообще вид какой-то не самый
      здоровый (пьют?), одежда простая, практичная (кто такую в Бедрограде носить
      будет?), особая провинциальная растерянность, сплошное «что делать, куда
      бежать?» на лицах.</p>

    <p>Кто бы они ни были, если они на
      кафедру, они не вовремя.</p>

    <p>— Ну
      кого ещё нелёгкая принесла… — начал было Попельдопель, но вдруг заметил
      волнение среди покойников.</p>

    <p>— Охуеть, — постановил покойник
      Гуанако. Со значением отдавил ногу покойнику Диме, почти уже рванул к делегации
      и вдруг вспомнил про Попельдопеля: — Слушай, подожди тут пару минут. Сейчас
      пойдём на кафедру, просто… просто ты себе не
      представляешь, как всё охуенно складывается!</p>

    <p>К Попельдопелю вернулось утреннее
      «ммм», потому что что тут ещё скажешь, кроме «ммм».</p>

    <p>Покойники ввинтились в делегацию,
      скрылись с глаз Попельдопеля за провинциальными делегатскими спинами и что-то
      устроили. Что — непонятно, не видно и не слышно, но делегаты их совершенно
      точно слушали и слушали — ммм, сорок тысяч раз ммм! — с почтением, внимали даже.
      Кто-то покачнулся. Его тотчас подхватили и силой заставили побыть ещё немного
      вертикальным, но Попельдопель был готов поклясться, что наблюдал сейчас несостоявшееся
      падение на колени.</p>

    <p>Ммм.</p>

    <p>&nbsp;</p>

    <p>Дальнейшее — набор картинок.</p>

    <p>По-другому это самое дальнейшее
      мозг Попельдопеля воспринимать отказался.</p>

    <p>Кафедра. Ворох бумаг — на столах,
      на шкафах, на диванах, на полу, разве что на потолке нет бумаг, но на потолке
      революционное чучело. Сегодня оно Метелин — в шинели Резервной Армии поверх
      парадной белой рубахи (их ещё называют расстрельными, расстреливали-то в
      парадном), в парике из чёрных-пречёрных волос, совсем как у покойника Димы или
      живого завкафа Онегина.</p>

    <p>Охрович и Краснокаменный кивают
      на чучело и хором заявляют: «Потому что Метелина звали Александр!», но какой к
      лешему Александр, при чём тут это, у нас же эпидемия, некогда глупостями
      заниматься.</p>

    <p>Покойник Гуанако выгружает на
      секретарский стол бесконечные бутылки без этикеток, Ларий расставляет их по
      росту и вздыхает: мол, крепкая она, на работе много не выпьешь. Кто «она», что
      это вообще за бодяга нежно-ржавого цвета?</p>

    <p>Максим обеспокоено выспрашивает
      что-то у покойника Димы, слушает традиционно путаные ответы и качает головой.
      Где-то неподалёку бродит мрачный Ройш, отпихивает бесконечные Максимовы бумаги едва взглянув, и это настолько ненормально, что
      Попельдопелю становится не по себе.</p>

    <p>Дверь в завкафский кабинет
      красноречиво заперта.</p>

    <p>Очень не ко времени, надо же
      срочно обсудить с гэбней детали плана —</p>

    <p>Дверь в завкафский кабинет
      распахнута, сейчас вообще от удивления сорвётся с петель, потому что перед
      завкафом вещает древний старец из делегации, на которую Попельдопель и
      покойники наткнулись при входе. Вещает былинным голосом, исполненным сильного
      старопэтэрбэржского акцента — Попельдопель когда-то жил рядом с дореволюционным дедком, он знает,
      как это звучит. Впрочем, дедок и то меньше экал.</p>

    <p>Онегин прикрывает глаза и
      отворачивается. Онегину, как обычно, недосуг слушать посетителей. Охрович и
      Краснокаменный сидят по обе его руки прямо на полу, преклонив одно колено,
      они-де грифоны. Кто такие грифоны: это которые совы с собачьими ногами или которые гуси с бычьими
      шеями? На дореволюционных монетах и тех, и тех чеканили, а та сторона, которая не
      решка, до сих пор в обиходе «грифон», только пойди уже
      разбери, что он такое.</p>

    <p>Максим пытается
      и слушать, и что-то писать в блокноте непреклонному Ройшу, а Ройш похож не на
      поганку, как обычно, а вовсе даже на плод фигового дерева.</p>

    <p>Ларий суетится, позванивает
      куда-то и тихо бормочет в трубку, поглядывая на старца.</p>

    <p>Покойники притаились за шкафом.
      Целуются они там, что ли? Самое время.</p>

    <p>Глаза Попельдопеля перескакивают
      с одного действующего лица на другое, шарят по кафедре, после каждого круга
      непременно спотыкаются на свитерах Охровича и Краснокаменного. Салатовый с оранжевым и ультрамариновый с белым, вязка крупная,
почти сеть — так похоже на условное изображение чумы, которое он тут рисовал.
      Ну и какого лешего? Лишней минуты ведь нет, а тут эти делегаты.</p>

    <p>Охрович и Краснокаменный степенно
      кивают, по-звериному держат спины, у них балаган (у них всегда балаган), но на
      самом деле понятно, что всё их внимание сконцентрировано на старце, они даже не
      мешают ему вещать, вклиниваются поменьше. Если аж
      Охрович и Краснокаменный не отвлекают, дают высказаться, может, оно
      действительно чем-то важно?</p>

    <p>И до Попельдопеля медленно доходит,
      докатывается даже, как будто просачивается сквозь беруши, <i>о чём</i> вещает старец. Приехали (приэхали, со старопэтэбэржским-то
      акцентом), мол, в Бедроград (Бэдроград, дореволюционный сосед Попельдопеля тоже
      так говорил), прямиком на истфак, мол, через всю страну эхали, мол, раньше
      таились, нэ высовывались, а тэпэрь врэмя настало, мол, свэт истины донэсти.</p>

    <p>Свэт истины. Сумасшедшие со
      старцем приэхали, фанатики? А выглядят пристойно, ничего дурного не подумаешь.</p>

    <p>«Под зэмлёй жили, зэмлю эли,
      никому нэ показывались, а кому показывались, тот под зэмлю уходил. Мэсто под
      зэмлёй эсть святое, всэ жэртвы, всэ люди — святому мэсту, ничэго святого кромэ
      мэста и нэ осталось, всё разрушэно, до послэднэго камня разобрано, по травэ да
      по стэпи разбросано, ногами да годами утоптано».</p>

    <p>Мэсто. Под зэмлёй. <i>Вот оно что</i>. Попельдопель ведь уже
      поверил, что покойники не брешут: Дима показывал совершенно
      обалдевшим Попельдопелю с Шухером фотоснимки — тёмные,
      зернистые, вспышка всё равно не вытянула. Гуанако на обороте схем чумы чертил
      эту махину в разрезе, дорисовывал стрелочки — воздушные потоки, грунтовые воды.</p>

    <p>Но ни один из покойников и словом
      не обмолвился о том, что <i>там</i> живут
      люди! Не могут жить, это же безумие, это большее безумие, чем всё остальное
      безумие!</p>

    <p>«Зэмля схоронила, зэмля укрыла и
      приютила, но всэго нэ укроэшь, под зэмлёй нэ спрячэшь, а чэго нэ спрячэшь, тому
      одна судьба — быстрый нож да злыэ надзэмныэ вэтры. А как избавишься, так и
      войдёшь нэузнанный под зэмлю тайными дорогами скопцов».</p>

    <p>Дорогами скопцов.</p>

    <p>Скопцы.</p>

    <p>Подземные скопцы с их тайными
      дорогами.</p>

    <p>Крепко ругаться Попельдопель себя
      отучил сто лет назад — когда читаешь лекции и тем более торчишь в лаборатории
      со студентами, привычка крепко ругаться очень мешает. Но…</p>

    <p>НО СКОПЦЫ БЛЯДЬ!</p>

    <p>СКОПЦЫ СКОПЦЫ
      СКОПЦЫ</p>

    <p>Скопцы, леший их еби.</p>

    <p>&nbsp;</p>

    <p>— Гуанако, — вцепился
      Попельдопель в покойника, покуда тот не убежал.</p>

    <p>Второй покойник после краткой
      рекогносцировки как раз-таки убежал вместе с Охровичем и Краснокаменным: дела,
      чума, привлечение студентов (ха-ха, со скопцами-то привлекать эффектней!), всё
      понятно — но как же поговорить? Попельдопель, между прочим, видел, как
      покойники общались с делегацией скопцов. И как те чуть на колени не попадали,
      тоже видел.</p>

    <p>— Гуанако, скажи мне только одну
      вещь, — спросить Попельдопель хотел не одну вещь, а много и разных, но сейчас
      голова и так шла кругом. — Если вы там были, если вы разгуливали по их
      долбанному святилищу, как, леший еби, они вас не сожрали
      живьём, закусив земелькой? Или не оскопили… — Попельдопель вдруг серьёзно
      задумался. — А если оскопили, то как выпустили
      обратно?</p>

    <p>— Ну…
      Дима вот обаятельный, — пожал плечами Гуанако и метнул свой бандитский нож
      строго вверх, в революционное чучело Метелина. Нож сверкнул в воздухе, даже,
      кажется, свистнул и аккуратненько шлёпнулся обратно, рукояткой прямо в
      подставленную ладонь. С расстрельной рубахи Метелина осыпались три, а то и
      четыре пуговицы, обнажив макет революционной груди.</p>

    <p>— А поподробнее?</p>

    <p>— Попельдопель, — Гуанако говорил
      тихо, косясь на вновь запертую завкафскую дверь, — ты же знаешь вроде, что я
      диплом не на науке и технике защищал. Диплом у меня — специалиста по истории
      религии. И все последние десять лет своей загробной жизни я с интересом
      выясняю, что мне его даже не зря выдали. Наверное, и правда
      специалист.</p>

    <p>Попельдопель тоже покосился на
      завкафскую дверь, за которой скрылся Максим (опять занят Онегиным, а не
      делом?), вспомнил, что невозмутимо отправившийся расселять скопцов Ларий
      оставил на подпись тысячи бумаг для создания иллюзии совместного проекта
      медфака с истфаком (когда только успел составить?), и вздохнул.</p>

    <p>— Ладно. Но имей в виду: когда
      всё это закончится, я вытрясу из тебя производственные религиозные тайны.</p>

    <p>Тайна чумы в Бедрограде на тайны
      истории древнего мира, отличный обмен для ничего не подозревающих студентов!</p>

    <p>Пока
Попельдопель штамповал подписи над секретарским столом, Гуанако с каким-то
совсем уж странным лицом пялился на ровный ряд томов собственного ПСС,
занимавших самое видное место в самом видном кафедральном шкафу.
      Семьдесят шестой год, издано посмертно, составитель Онегин Г. Е.</p>

    <p>— Знаешь, я же их придумал, —
      неожиданно усмехнулся Гуанако. — Подземных скопцов-то. Курсовик писал, на третьем,
      что ли, курсе. Окончательный разгром Империи, двенадцатый век, вся эта жуткая
      суматоха по обе стороны Урала. У Империи же были элитные войска скопцов — таких
      совершеннейших отморозков для точечных ударов, там сложная история, откуда всё
      это пошло.</p>

    <p>Попельдопель даже что-то знал про
      имперских боевых скопцов, с час назад ведь как раз вспоминал давнее
      гуанаковское наставление не путать скопцов, скопников и оскопистов — но
      перебивать не стал, решил послушать.</p>

    <p>— Эти элитные войска, когда
      Империя уже того, ещё малость порезали тех, кто под
      руку попался, а потом пропали. Растворились. Основная версия из учебников —
      массовое ритуальное самоубийство, командования-то больше не было, делать
      нечего. А я выебнулся, выдвинул и обосновал гипотезу, что они, спасаясь от суровых
      росских витязей, бежали в степь, наткнулись на место падения Башни, имперской
      святыни незапамятных времён. Ну и обосновались там, под землёй. Башня — она же
      хуй, ёбаный стержень имперского сознания. Самое то для
      скопцов, чтобы осесть, раз Империя закончилась. Я на третьем курсе доказывал,
      что они там окончательно ёбнулись, сформировали собственный культ на основе
      того, что раньше было, попохищали неудачливых степняков для поддержания
      демографических показателей и загнулись лет за сто, — Гуанако помолчал. — А они
      не загнулись. Нихуя ж себе, вообще-то говоря.</p>

    <p>Не ругающийся крепко Попельдопель
      был абсолютно согласен: нихуя ж себе.</p>

    <p>Несколько сотен человек, Гуанако говорит
      — около тысячи, в долбаном Вилонском Хуе. Который
      Имперская Башня, который легенда о первой победе над Империей.</p>

    <p>Ни один нормальный человек в неё
      не верит, только дети и те, кто свихнулся на истфаке.</p>

    <p>Студенты будут сражены наповал!</p>

    <p>Потому что невозможная Башня из
      древних летописей таки существует, а в Башне — скопцы, жрут
      себе землю и приносят жертвы.</p>

    <p>Если они почти не выходят на
      поверхность, что у них творится с обменом веществ? Что у них вообще может
      твориться с обменом веществ, если они скопцы, гормональный фон-то и так
      нарушен, а тут отсутствие солнечного света, состав воздуха, специфический режим
      питания и леший знает что ещё. Вон какие худые,
      половины зубов нет, глаза слезятся и кожа обгорела только так, пока в Бедроград
      ехали. И что, простите, у них с головой происходит, если случайно пойманные (и
      оскоплённые!) люди массово оттуда не сбегают?</p>

    <p>Попельдопель поймал себя на
      рисовании какой-то очередной схемы неизвестно чего — видимо, обмена веществ.
      Конечно же, на бумагах Лария.</p>

    <p>— Слушай, — бумаги Лария надо бы
      убрать подальше, а то Попельдопель сейчас разойдётся, — я, конечно, совсем другого
      профиля специалист, но твои скопцы — это клад какой-то. Специалисты нужного
      профиля бы вообще рехнулись от восторга. Расскажи
      хоть, что они жрут на самом деле?</p>

    <p>— Что-что. Насекомых, грызунов,
      корешки всякие несъедобные. Мох выращивают и плесень. Изысканная кухня!</p>

    <p>— А… — любопытству Попельдопеля
      пришлось умерить аппетиты, потому что на кафедру таки вернулся Ларий, сказал,
      что Гуанако бы сейчас поговорить где-то там в
      конференц-зале с Ройшем, Ройш желает каких-то подробностей из жизни Порта.</p>

    <p>Короче, Попельдопелю пришлось в
      одиночку оправдываться по поводу изрисованного документа.</p>

    <p>Хороший мальчик Ларий улыбнулся,
      махнул рукой, вытащил предусмотрительно заготовленную папочку с копиями всей
      этой макулатуры и заварил чаю с мягким пряным запахом, начисто отбивающем все
      мысли о мхе и плесени в грязных плошках скопцов.</p>

    <p>Пока Ларий колдовал над чайником
      с трогательным орнаментом из жующих козлов (у них на кафедре вообще на них какое-то
      тихое помешательство, книжку даже издавали — «Всемирная история козлов»),
      Попельдопель в который раз подумал, что Ларий-то выглядит помладше Максима,
      хоть они и однокурсники. Весёлый, кстати, был курс: и тебе экстремизм, и
      контрреволюционное движение, и последствия экспериментов с гормональных фоном у
      новорождённых детей, и теперь вот — гэбня. А Охрович и Краснокаменный (все из
      того же выпуска, будь он неладен) — так вообще легко сольются со студентами,
      если захотят.</p>

    <p>Это всё от нервов, кто больше нервничает
      — тот быстрее стареет.</p>

    <p>Что в очередной раз доказывает,
      что вечные выкрутасы Онегина — сплошная театральная студия. Страдал бы столько,
      сколько изображает, — видел бы в зеркале свои неполные сорок, а то и что
      похуже.</p>

    <p>А покойники, например…</p>

    <p>— Юр Карлович? — тронул его за
      плечо Ларий.</p>

    <p>Что-то Попельдопеля унесло.
      Наблюдения на предмет соответствия людей их реальному возрасту его всегда
      уносят — производственная, леший её, травма.</p>

    <p>— Я подписал, я всё понял,
      конспиративную легенду выучил, с медфаком договорюсь, — оттараторил
      Попельдопель. Хороший мальчик и чайник у него с козлами, но не стоит забывать,
      что Ларий не просто секретарь кафедры, а так, между прочим, голова гэбни. С
      головами гэбни лучше не зевать.</p>

    <p>— Я не к тому, — Ларий
      гостеприимно достал печенье, придвинул чашки. — Вы ведь понимаете, что люди,
      которые будут с нами сотрудничать со стороны медфака, должны быть надёжными?</p>

    <p>Мысль о Шухере, глубоко
      запрятанная среди скопцов и легендарных башен, вернулась.</p>

    <p>— Конечно же, мы отдельно
      поговорим с каждым, кого вы сочтёте нужным подключить к процессу, но не
      хотелось бы дополнительных сложностей. И, конечно же, выбор между
      профессионализмом ваших сотрудников и потенциальными сложностями с ними стоит
      делать в пользу профессионализма, у нас такая ситуация, заразу лечить — не
      дрова колоть, — ободряюще улыбнувшись, Ларий раскрыл портсигар и протянул его
      Попельдопелю. — Но вы уж предупреждайте о сложностях отдельно и побыстрее, ладно?
    </p>

    <p>Портсигар облагодетельствовал
      Попельдопеля терпким вкусом, чуть пощипывающим язык. Хорошие папиросы — весомый
      аргумент в беседе. На останках изрисованного документа Попельдопель написал
      «Шухер Андроний Леонидович. Покойники?».</p>

    <p>Кивнув, Ларий налил ещё чаю.</p>

    <p>— Я слышал, будет расследование,
      ну, на высшем уровне, — очень захотелось вдруг Попельдопелю перевести тему. —
      Наша теперешняя деятельность нас не выдаст? Формула лекарства от искусственно
      созданной болезни, вся эта секретность с изготовлением?</p>

    <p>— Расследование <i>должно быть</i>, — Ларий вздохнул в
      направлении завкафской двери. Сегодня все на нее косятся! — Максим с
      позапрошлой ночи обивает соответствующие пороги, но Ройш говорит, что, раз
      сразу на запрос не среагировали, ждать нечего.</p>

    <p>А, так значит, вот чего Ройш
      ходит с мордой даже гаже, чем обычно. Наслаждается
      производственным пессимизмом! Потому что если запрос не прошел…</p>

    <p>— Как так нечего?! — всполошился
      Попельдопель. — Наш запрос не приняли,
      выходят, примут их запрос? Бедроградской гэбни, в смысле. В смысле, если они
      его вообще пошлют, потому что они же собирались слать, когда всё будет плохо, а
      плохо не будет, мы всё знаем и даже лекарство сделаем в нормальных объёмах…</p>

    <p>— Юр Карлович, вы успокойтесь. Мы
      делаем что можем, но есть немалая вероятность того, что фаланги вообще не
      среагируют.</p>

    <p>— Но как? Эпидемия! —
      Попельдопелю стало очень, очень не по себе.</p>

    <p>— В нашем запросе значится, что
      заражению собирались подвергнуть <i>один дом</i>,
      и мы это заражение <i>пресекли</i>. Нет
      никакой эпидемии, Юр Карлович, — Ларий неопределённо качнул головой и встал
      зачем-то, книжки по местам порасставлять ему вздумалось. — И чтобы
      Бедроградская гэбня не посылала никаких своих запросов
      и никто на них не реагировал, эпидемии и дальше пусть не будет. Будут
      совместные проекты медфака с истфаком, практика медфаковских старшекурсников и
      другие совершенно безопасные — по сравнению с эпидемией — вещи.</p>

    <p>Попельдопель сам сегодня с утра
      говорил, что нет никакого толку жаловаться наверх, но это он так, в запале, а
      если на самом деле наверху всем всё равно — чума в Бедрограде, не чума, сделают
      лекарство, не сделают — страшно же получается!</p>

    <p>Ларий изучил
насильственно распахнутую рубаху чучела Метелина и полез было разыскивать
      под столом пуговицы, но наткнулся на взгляд Попельдопеля, в котором сейчас
      плескалось столько недоумения и возмущения, что пуговицам пришлось подождать.</p>

    <p>— Воротий Саныч как-то… вы,
      кстати, загляните к нему по возможности, ему ведь скучно без Университета, —
      Попельдопель мысленно согласился с Ларием.</p>

    <p>Воротий Саныч — человечище,
      обязательно надо зайти! Не забыть бы.</p>

    <p>Ларий тем временем продолжал:</p>

    <p>— Так вот он, ещё
      когда сам был завкафом, объяснял нам, что фаланги наблюдают за грызнёй гэбен и
      вроде как почти её поощряют. Но не вмешиваются, сколько могут не вмешиваться —
      не вмешиваются. Воротий Саныч говорил, что со стороны это, конечно, странно, но
      на деле вроде как даже хорошо: гэбня должна быть самостоятельной единицей.
      Брать всю ответственность на себя и не бегать к старшим по уровню доступа с каждой
      проблемой. В принципе не думать, что от старших можно что-то получить в плане
      помощи. И это может нравиться или не нравиться, Воротию Санычу, например, не
      нравится, но гэбни были задуманы именно так.</p>

    <p>Попельдопель посмотрел на Лария
      совсем уж ошалело, и тот, наконец, сообразил:</p>

    <p>— А, вы не в курсе. Воротий Саныч
      много-много лет был в Университете полуслужащим, задолго до появления Университетской гэбни. То есть <i>формально</i> полуслужащим с <i>формальным</i> девятым уровнем, поскольку работал прямо на Бюро Патентов. Кое-какие процессы
      он понимает, наверное, лучше нас.</p>

    <p>Что за день такой? Куда ни плюнь
      — срываются покровы. Воротий Саныч, пока завкафствовал, пахал как лошадь. Потом,
      конечно, Гуанако появился, и они вдвоём всю учебную и научную часть на себе
      тащили, но чтоб у Воротия Саныча даже тогда было время (и силы!) ещё и Бюро
      Патентов служить? Ничего себе.</p>

    <p>— Ларий, подожди, — Попельдопель
      выслушал доклад про то, как были задуманы гэбни, и учуял там какой-то подвох, —
      ведь весь этот план Бедроградской гэбни как раз и есть форменное «побежать к
      старшим». Кто же их послушает, если оно всё так?</p>

    <p>— Нет, «побежать к старшим» как
      раз будет, если <i>мы</i> вдруг напишем ещё
      один запрос: что <i>у нас</i> в канализациях
      эпидемия авторства Бедроградской гэбни и <i>мы</i> не справляемся. Из-за нехватки
      лекарства, людей, денег, чего-то ещё. Отчасти на это их план и рассчитан: что
      мы поздно заметим и ничего уже не сможем сделать, кроме как броситься наверх.
      Неважно даже, к фалангам или к медикам: если официально попросим о помощи Медицинскую гэбню, всё равно получится, что всё за нас
      сделают они, а мы ничего не сделаем, будем стоять и смотреть, потому что
      возможностей у нас никаких, — Ларий озабоченно хмурился. — А сами они,
      Бедроградская гэбня-то, рассчитали верно: они у нас в канализациях найдут
      вирус, вылечат тех, кто заразился по нашей вине, решат все проблемы и только
      потом пойдут к фалангам и медикам докладывать. И то, официально — только потому что о чрезвычайных ситуациях положено докладывать, а
      неофициально — потому что для карательных мер, передела власти и
      расформирования Университетской гэбни нужны фаланги.</p>

    <p>Стоп-стоп-стоп, хватит, столько
      политики разом не вмещается в голову Попельдопеля, она не для того над
      благородно зеленеющим костюмом болтается! Она для вакцин и сывороток, ну и ещё
      для того, чтобы придумать, как завтра так отправить студентов по районным
      поликлиникам отлавливать пациентов с тяжёлым ОРЗ, чтобы никто не догадался, чем
      мы тут занимаемся и чем в этом сезоне примечательно тяжёлое ОРЗ.</p>

    <p>Хотя кое-что он во всей этой
      политике уловил. Или, наоборот, не уловил:</p>

    <p>— Но тогда получается, что на наш
      нынешний запрос не отреагировали как раз потому, что он тоже… ну, немного
      несамостоятельный? Они напакостили, мы поймали за руку, спасите-помогите… Ой,
      извини, это не моё дело, вы начальство, вам и решать,
      просто…</p>

    <p>Ларий нахмурился ещё больше, взял
      в руки чайник с козлами, поставил обратно, снова взял. Козлы продолжали жевать.</p>

    <p>— Да вы всё правильно говорите,
      Юр Карлович, — говорил Ларий не с Попельдопелем, а с этими самыми козлами. —
      Спасите-помогите, поймали за руку. К тому же поймали недостаточно эффектно,
      важную запись на диктофон не сделали — нечем впечатлять фаланг. Ройш говорит,
      без той записи им недостаточно весело показалось. А вообще-то не в записи дело,
      просто нет же для фаланг никакой чрезвычайной ситуации и эпидемии, чего им дёргаться
      и лезть в нашу песочницу?</p>

    <p>Попельдопель вспомнил, что в их
      песочнице только что нарисовалась Имперская Башня, и с некоторым ужасом от
      собственной аморальности по отношению к жителям зачумлённого Бедрограда
      осознал: неплохой ведь расклад. Фаланги, диктофоны — это всё как-то нечестно,
      это не про то, кто быстрей и сообразительней, не про то, у кого фантазия богаче
      и смелости хватит, а про скучную бюрократию. Может, оно и к лучшему, что не
      реагируют?</p>

    <p>Ларий помолчал-помолчал и
      добавил:</p>

    <p>— Знаете же, гэбня всегда говорит
      «мы» и всегда на публику отстаивает одну позицию, общую. Но это ведь не значит,
      что у голов гэбни нет частных мнений? Их не принято высказывать, в ряде
      ситуаций это вообще должностное преступление, но полуслужащий — удобная
      должность, мало аспектов взаимодействия с полуслужащими
регламентировано достаточно чётко. И раз уж, Юр Карлович, вы наш
      полуслужащий и вся эта беседа ведётся вне всяких протоколов, вот вам <i>моё</i>, а не <i>наше общее</i> мнение: к лешему фаланг, сами разберёмся. Не ответили на
      запрос — всё, проехали, сейчас есть и другие дела, кроме обивания порогов.</p>

    <p>Именно на этой полной решимости
      ноте завкафская дверь слабо заскрипела (прямо как Онегин, когда страдает) и
      явила миру грозный лик Максима. Его очи метнули в Лария молнии принудительного
      оптимизма.</p>

    <p>— С фалангами ещё ничего не
      закончено. Не возьмут так не возьмут, но пытаться
      будем до последнего.</p>

    <p>Ларий с готовностью кивнул
      —&nbsp;мол, конечно-конечно, с этим никто и не спорит. Максим ещё немного
      поклубился у двери, убедился в отсутствии саботажа и выдохнул.</p>

    <p>Попельдопелю вообще-то нравилась
      его обычная спокойная уверенность —&nbsp;не всепоглощающая, а как раз
      нормальная такая, человеческая. Человек, который не кидается в каждую
      подвернувшуюся авантюру, но зато, взявшись за дело, делает его последовательно
      и до конца.</p>

    <p>Вообще-то нравилась, и сейчас
      было понятно, что на Максиме лежит прорва дел (Онегин,
      например, ага), но внутри всё равно зудело. Даже если отдать оформление всех
      бумаг Ларию с Ройшем, нужны шприцы, халаты, инструктаж старшекурсников-медиков,
      койки, перестановка в лазарете, пижамы, выгнать всех от аппаратуры для синтеза,
      кровавая твирь, наконец…</p>

    <p>И всё —&nbsp;срочно, и всё — в
      кошмарном количестве.</p>

    <p>Хорошо хоть успел решиться
      вопрос, откуда: Попельдопель наконец сообразил, что у
      Университета есть покойник Гуанако, а у покойника Гуанако есть Порт. А у Порта,
      как известно, есть всё.</p>

    <p>Полчаса назад, во время
      обсуждения плана с гэбней, покойник Гуанако говорил об этом с совсем не такой
      уверенностью, какая обычно бывает у Максима, но тоже убедительно. Даже поубедительней, наверно. У него не спокойная, а, наоборот,
      вдохновляющая какая-то уверенность: у Порта есть всё, прорвёмся.</p>

    <p>Но даже если всё необходимое есть
      и будет, надо побыстрей нестись на медфак, надо…</p>

    <p>— Юр Карлович, ещё одно дело.</p>

    <p>— Ммм?</p>

    <p>Так, выключить бессмысленную
      беготню, Попельдопель не один в этом всём замешан, и ему вовсе не обязательно
      быть во всех местах одновременно.</p>

    <p>— Не могли бы вы осмотреть
      Габриэля Евгеньевича? Ему, кажется, нехорошо.</p>

    <p>Ну
      здрасьте-приехали.</p>

    <p>Максим очень хороший человек, но
      у него проблемы с приоритетами.</p>

    <p>— Тут такое дело… позавчера он…
      получил удар по голове. Возможно, несколько. А поскольку, как вы знаете, у
      Габриэля Евгеньевича и так слабое здоровье…</p>

    <p>…он сейчас, конечно, возлежит на
      своей кушетке, закатив глаза, и видеть никого не желает. Попельдопель ещё
      немного помычал.</p>

    <p>С другой стороны, снять с шеи
      Максима вечного страдальца в нынешних обстоятельствах было бы крайне уместно.</p>

    <p>— Это я его ударил, — не к месту
      покаялся тот. — На факультете сейчас сами знаете что
происходит. Не хотел, чтобы его зацепило, а он не вовремя вошёл. Глупо,
      конечно. Теперь-то я понимаю, что лучше бы Габриэлю Евгеньевичу всё рассказать
      — он имеет право знать, да и ещё один человек. Должен же кто-то лекции читать,
      пока мы все тут бегаем!</p>

    <p>Кому читать-то?</p>

    <p>Онегин
действительно полулежал на кушетке (развели кушетки в завкафском кабинете!
      Попельдопель вот тоже завкаф, а не выпендривается),
      спрятав лицо в руках и волосах. Вид у него был измятый и потёртый, ещё бледнее
      обычного. Чем леший не шутит &shy;— может, и впрямь в кои-то веки совсем заболел?</p>

    <p>Как он вообще на ногах ходит, с
      такой-то медкартой. Даже сам Попельдопель не разберёт, что в ней настоящее, что
      успешный спектакль с хватаниями за сердце, а что просто так, в подарок
      приписано.</p>

    <p>Кожа бледная, мучнистая, пульс
      неровный. Зрачки реагируют на свет с опозданием. Конечности вялые. На просьбу
      встать и пройти по прямой линии отреагировал невнятным снисходительным жестом
      —&nbsp;мол, оставьте это. Просьбу коснуться пальцем кончика носа выполнил с
      третьей попытки. Повышенная температура отсутствует. Жалуется на головную боль
      и звон в ушах.</p>

    <p>Так и есть, всё как по учебнику —
      сотрясение мозга средней тяжести. По-хорошему надо бы его определить в
      стационар, но какой сейчас стационар, медфаковскому лазарету только Онегиных не
      хватало. И вообще, он же всё время как с лёгким сотрясением — может, у него в
      организме недостача спинномозговой жидкости?</p>

    <p>— Постельный режим и покой.
      Отвезите его домой, уложите в постель. Лучше поить снотворным и подержать так
      дней пять.</p>

    <p>Максим посмотрел на Онегина
      глазами человека, который не может понять, хорошо вышло или плохо. И чего тут
      думать, и так всё ясно —&nbsp;если всё сложится как надо, лекции читать всё
      равно будет некому (в смысле — у студентов найдётся занятие поважнее),
      а Онегину лучше ни у кого под ногами не путаться.</p>

    <p>А если не сложится, то кого
      вообще волнует Онегин!</p>

    <p>— Ничего страшного, просто нужно
      отлежаться.</p>

    <p>Максим неловко подёргал
      галстучный узел.</p>

    <p>— Хорошо. Я сам не хотел его
      волновать. Выдастся момент — расскажу…</p>

    <p>Жалко Максима. Носится с Онегиным
      как с писаной торбой, а тому только подавай внимания. Попельдопель
      неодобрительно пофырчал.</p>

    <p>Вроде бы всё и ладно, все
      довольны, только когда завкаф и замзавкаф так заняты своими неоднозначными
      взаимоотношениями, на работе кафедры это сказывается так себе. Если не
      вспоминать о том, что сейчас важна даже не работа кафедры, а кое-что посерьёзнее.
      Условия типовой задачки с госа про скорость
      распространения заражения ещё не полностью прояснились, да только уже пора бы
      заканчивать с решением и получать ответ. А тут, понимаете ли, Онегин Максима
      деморализует, тьфу.</p>

    <p>— Спасибо вам.</p>

    <p>Пожалуйста-пожалуйста.</p>

    <p>Всё, вот теперь можно включать
      бегательный режим: возвращаться на медфак, готовить до поздней ночи
      оборудование, материалы и (ох леший!) людей к тому,
      что нас всех ждёт. Правда, сперва стоит сунуться
      посмотреть, чего там Дима. У него ж в анамнезе, небось,
      самое публичное выступление — какой-нибудь случайно непрогулянный доклад перед
      своей группой, а тут весь истфак! Объявления уже давно сделали (это на кафедре
      науки и техники, разумеется, внутреннее радио злонамеренно сломано, а так
      отголоски слышно было), так что должно быть как раз в разгаре.</p>

    <p>Попельдопель тряхнул головой, четырежды
      повторил про себя исцеляющую молитву для трудоголиков («пока можно и отдохнуть,
      ничего за полчаса не случится») и вышел с кафедры истории науки и техники.</p>

    <p>&nbsp;</p>

    <p><b><i><u>Ответ</u>:</i></b></p>

    <p>&nbsp;</p>

    <p>Главный актовый зал истфака
      —&nbsp;огромное помещение высотой в два этажа, полное воздуха и удивительного
      порядка. Всё такое светло-деревянное, что даже в пасмурный день
      кажется, будто из окон пробивается солнце. Ряды стульев амфитеатром, невысокий
      подиум, огромные (колоссальные прямо!) доски, умеющие ездить вверх-вниз.
      Микрофон, несколько стоящих в ряд столов, небольшая кафедра —&nbsp;выбирай по
      вкусу.</p>

    <p>Дима выбрал столы, на которых и
      сидел, поджав ноги без ботинок и снова демонстрируя канареечные носки
      сомнительной свежести.</p>

    <p>Зато «вот обаятельный».</p>

    <p>И очень маленький �� отсюда-то.
      Актовый зал в части, противоположной доскам, венчался небольшим балконом,
      галёркой, куда вели двери из нескольких аудиторий —&nbsp;в том числе и с
      кафедры истории науки и техники. На балконе жирно колосился весь цвет истфака:
      вон развалился на кресле и потливо дышит завкаф новейшей и современнейшей
      истории, как бишь его; блаженный историк математики и
      философии оперся на перильца и созерцает распластанную по потолку йихинских
      времён ещё гигантскую люстру; Ройш, прямой, как незубочисточных габаритов
      флагшток, молча встал прямо посреди прохода и смотрит на всё с кислой миной…
      никакого, в общем, спасения. Ларий где-то в первом ряду, поддержать
      выступление, если что. Охрович и Краснокаменный в зале же со страшными лицами
      блюдут студенческую дисциплину (это у них что, правда
      хлысты в руках?).</p>

    <p>Попельдопель отчаянно забегал
      глазами и успокоился, только когда обнаружил в самом углу, у окна, покойника
      Гуанако. Тот сидел на паркетном полу балкона спиной к подиуму и невозмутимо
      курил в форточку.</p>

    <p>Он бы наверняка хотел выступать
      вместе с Димой (Башню-то вдвоём нашли!), но куда тут выступишь, когда твоя рожа на каждом томе собрания сочинений. Никакие отросшие
      волосы не спасут.</p>

    <p>Димина рожа,
      вообще говоря, тоже небезызвестна —&nbsp;портрет на медфаке при входе висит,
      разве что чёрно-белый, без очков и седины поменьше. Всё из-за его не очень
      рабочей, но так драматично придуманной аккурат перед
      смертью иммунной сыворотки от степной чумы. Но Диме, кажется, всё равно, он и в
      Столицу Дмитрием ездил, и здесь тоже. Нынче Борстен, спасибо кафедральному
      росско-немецкому словарю (потому что «ну у всех же медиков немецкие фамилии,
      иначе не бывает»). Бывший студент медфака, после пятого курса всё бросил и
      уехал в степь лечить коров и собирать травы, случайно наткнулся на подземные
      ходы и попал в Башню. Вот, вернулся рассказать и организовать туда нормальную
      экспедицию.</p>

    <p>Создать из небытия Дмитрия
      Сергеевича Борстена, выпускника кафедры вирусологии, кстати, тоже
      предполагалось Попельдопелю.</p>

    <p>Он ещё немного неодобрительно
      помычал.</p>

    <p>— Знаете, чем опасны сенсации?
      Они, как ни странно, привлекают внимание. Расскажи сегодня, что Вилонский Хуй,
      он же Имперская Башня, существует, да ещё и обитаем — завтра там уже не
      протолкнёшься. Редчайшие скопцовские породы мха вытопчут подчистую.
      У меня есть фотографии, есть даже настоящие хуежители и один честно
      прихваченный артефакт. Этого, в принципе, уже достаточно, чтобы войти в любимую
      вами науку историю. Только это, хоть и очень круто, не главное. Главное —
      поступить истинно по-имперски, то есть выжать из Башни всё что можно, пока она
      не набита желающими доверху. Скопировать тексты, взять пробы, изучить местную
      культуру и культуры — ну, сами понимаете.</p>

    <p>Попельдопель подошёл к покойнику
      Гуанако, на всякий случай встал так, чтобы получше
      закрыть его спиной от всех прочих обитателей балкона — больше половины этих
      людей с ним работали, кое-кто его учил, да и вообще — светило, идеолог, все
      дела. Они, конечно, не смотрят по сторонам, они всецело поглощены Хуём и
      грядущей экспедицией в Хуй (какая удача, что он всё-таки есть, что нашли его
      только и именно сейчас, да ещё и те, кто нужно!), но мало ли.</p>

    <p>— Бдишь?</p>

    <p>Гуанако кивнул, затянулся и
      негромко ответил:</p>

    <p>— Проверяю знание предмета и
      ораторские навыки. Он же никогда лекций не читал.</p>

    <p>Вот, и Попельдопель уже успел об
      этом подумать.</p>

    <p>— Я путаю, или он в аспирантуру
      даже собирался? — накатило вдруг на Попельдопеля что-то большое,
      ностальгическое и прямо-таки пронзительное, хоть сопли
      вытирай.</p>

    <p>— Собирался. И уехать с Охровичем и
      Краснокаменным в Афстралию собирался, искать там что-нибудь похожее на
      историческое развитие общества — чтобы дикие земли, дикие люди и плотоядные
      козлы.</p>

    <p>— А в
результате и диплом не успел защитить, — ностальгическое и пронзительное
атаковало Попельдопеля с такой силой, как будто Дима был ему не полузнакомым
студентом с чужого факультета, где у него всего один модуль, а как минимум
родным сыном.</p>

    <p>— Он потом передо мной лично
      защищал, — улыбнулся чему-то своему Гуанако, — на казённой печатной машинке
      дописывал.</p>

    <p>Гуанако повернул ладонь тыльной
      стороной и продемонстрировал Попельдопелю перстни, которые тот и сам уже
      приметил. Перстней было три штуки, все три — затёртые, исцарапанные и
      совершенно одинаковые. То есть не совсем одинаковые: тяжёлые круглые бляшки из
      какого-то желтоватого сплава вместо камней — это же выломанные клавиши печатной
      машинки! Складываются в слово «хуй», во что они ещё могут у Гуанако
      складываться?</p>

    <p>— Какие нынче казённые машинки.
      Это ж дореволюционная модель! — Попельдопель чуть не присвистнул.</p>

    <p>— А то. Большому начальнику
      когда-то принадлежала, — Гуанако очень внимательно вгляделся в буквы. — И
      ссудили мне её в непротокольное пользование благодаря ещё большей протекции.</p>

    <p>— Хорошо живёшь! Жил, в смысле… В смысле, извини. Нет, правда, извини, не
      подумал, вряд ли
      это называется «хорошо», если я правильно понял, о чём речь, — Гуанако только
      беззаботно отмахнулся, но Попельдопелю всё равно было как-то неловко за
      смороженную глупость, и он без задней мысли сразу же сморозил
      следующую. — Ты скучаешь по всему этому? Ну, Университет, кафедра, лекции…</p>

    <p>— …совращение студентов, ты хотел
      сказать! — затушив сигарету, Гуанако всерьёз задумался. — Вот знаешь, нихуя не
      скучаю. Наоборот, я тут позавчера изо всех сил скучал по тому, как оно было в
      последние десять лет.</p>

    <p>— А что изменилось-то?</p>

    <p>— Уже ничего, уже вернулось
      обратно, — вот невозможный человек, от всего сегодня отмахивается! — А потом
      вообще звонок Шапки, угроза эпидемии — я ведь не собирался ни в какую политику
      вписываться, но когда такая хуйня творится, как тут не впишешься?</p>

    <p>Тут Попельдопель его очень хорошо
      понимал: столько проблем, столько проблем, все справляются кое-как, мочи нет
      смотреть — надо просто пойти и самому всё сделать!</p>

    <p>Город от чумы вылечить, например.</p>

    <p>— И сделать я это предлагаю
      —&nbsp;конечно, вам! — крайне уместно, буквально прочитав мысли Попельдопеля,
      заявил в микрофон Дима. — Ну, не совсем я, даже совсем не я, а руководство
      истфака и медфака. То есть это, исторического и медицинского факультетов. Сам я хоть что-то про историю знаю на уровне обывателя, сдававшего
экзамен на третьем курсе с похмелья, но, кажется, со мной приключилось
крупнейшее открытие в области вашей дисциплины за последние… не знаю, за всё
время. Настоящий Вилонский Хуй, населённый
      настоящими скопцами, потомками… эээ, духовными потомками специальных имперских
      отрядов скопцов-смертников. Которые возродили некую
      древнюю религию, которая может быть изначальной человеческой. И медикам тоже
      повезло, на дне Хуя — бесконечное количество неизученных бактерий, культур и
      вирусов.</p>

    <p>— Гонореечка, — буднично
      прокомментировал Гуанако, и кто-то из студентов в зале тоже, видимо, пошутил на
      тему, прокатилась волна хохота, Дима поправил очки на носу и ответил в том же
      духе, старательно симулируя профессиональный врачебный юмор.</p>

    <p>Попельдопель похвалил его про
      себя, а Гуанако с тщательно скрываемой гордостью похвалил вслух:</p>

    <p>— Неплохо же держит внимание
      аудитории, зря Охрович и Краснокаменный хлысты расчехляли. И так все слушают.</p>

    <p>Куда они денутся — сенсация-то ого-го, наглядное подтверждение реальности
      легендарных
      событий! Империя действительно была когда-то гораздо мощнее, чем нам известно
      по её поздним дням, и первый раз она рухнула на колени, когда рухнул под землю
      Вилонский Хуй — удивительное сооружение, чудо света, басенка для детей и психов.
    </p>

    <p>Университет на репутацию в
      академических кругах не жалуется, даже на международном уровне, но после такого
      открытия его первенство вообще никто и никогда оспорить не сможет. И для
      студентов заманчиво — экспедиция на неисследованный объект, и какой объект. Сплошное
      веселье вместо нудной учёбы.</p>

    <p>Вот только экспедиция не раньше,
      чем через полгода, и это ещё если всё утрясётся у
      Университетской гэбни и Бедрограда заодно, а пока…</p>

    <p>— Что медику радость, то простому
      человеку проблема. Ехать в Хуй просто так, без подготовки — нельзя. Ну, насчёт
      теории — это не ко мне, хотя все участники, наверное, обрастут какими-нибудь дополнительными спецсеминарами. Но нужно ещё и
      пройти не самые приятные медицинские процедуры, и прямо сейчас. Потому что,
      сами понимаете, сомнительные бактерии, культуры, скопцы, в конце концов, — ещё
      покусают… все медицинские опасности, которых душа пожелать может. Так что вам
      светят осмотры, прививки и обширная сдача анализов. Прямо сейчас —&nbsp;потому
      что нужно создать у всех участников иммунитет ко всему и от всего, а он за два
      дня или даже неделю не отрастёт. Страшная гадость все эти медицинские штуки,
      скажу я вам, но зато какие перспективы открывает!</p>

    <p>Попельдопель был посвящён в
      детали плана по конспиративной добыче крови, которая пойдёт в лекарство, ещё когда они с покойниками заседали у него на кафедре
      вирусологии. Но сейчас, слушая Диму, он опять поразился, как всё аккуратненько
      складывается, вообще ведь не подкопаешься: Имперская Башня, внезапно
      обнаруженная во степи (то есть, конечно, <i>под</i> степью), держать её Университету
      нужно пока в тайне, чтоб никто со стороны не прибежал на всё готовенькое, брать
      со студентов подписку о временном неразглашении производственной информации
      тоже нужно.</p>

    <p>А то, что вместо обычных прививок
      добровольцев подвергнут издевательствам с аллергией на твирь и последующей
      выработкой у них в организме антител к чуме, нависшей над городом, — это ещё пойди разгляди за научными сенсациями, священной историей
      Империи и скопцами. Да и то, подумаешь, болезненные процедуры с твирью. Твирь —
      уникальное растение, есть только в степи, и Вилонский Хуй есть только в степи.
      Степняки одной сплошной твирью веками лечатся, да ещё савьюром, — медики в
      курсе, что все эти травы входят в жуткое число лекарств, разве что называются в
      рецептах по-имперски, чтоб никто не догадался. Так что ехать в степь к полному неведомо каких бактерий и вирусов подземному Хую,
      предварительно накачавшись твирью, — это очень и очень реалистично. Скорее
      всего, даже на самом деле почти осмысленно.</p>

    <p>Хорошо сошлось.</p>

    <p>Не знал бы Попельдопель, какой
      аврал и паника тут у гэбни с этим заражением Бедрограда, подумал бы, что
      специально всё так подстроено — делегация скопцов вот неведомо откуда взялась
      как раз тогда, когда от неё больше всего толку.</p>

    <p>Хотя кто ж такой кошмар
      подстраивать будет?</p>

    <p>— Как вы можете догадаться, всё это страшно
      секретно, потому что — помните про опасность сенсаций, да? — Дима спрыгнул со
      стола канареечными носками точно в пустующие ботинки и подошёл вплотную к
      первому ряду студентов. — Процедуры, повторяю, неприятные, несколько дней
      пролежите в стационаре — то есть тут, в лазарете на койке. Будете страдать,
      плакать, молить о пощаде и болью зарабатывать право поучаствовать в экспедиции.
      Нам нужны, гм, только самые преданные науке истории.
      Дополнительная учебная нагрузка тоже — то ещё удовольствие. Но зато половину
      следующего семестра сможете провести в — настоящем! существующем! — Вилонском
      Хуе. В общем, подумайте хорошенько. Приглашаются только студенты с
      исторического и медицинского факультетов. Ну, пока что. Желающие могут
      записаться прямо здесь, у Лария Валерьевича. Теоретически считается, что в
      итоге в экспедицию отберут только <i>самых</i> умных, но это фигня. Там столько, простите за
      выражение, пространства для исследований, что поедут все, зуб даю. Может, не в
      один этап. Для записавшихся в аудитории № 106
      выступают живые скопцы, а также фотки Хуя и один честно стыренный артефакт.
      Незаписавшиеся — извините — продолжат обучение по расписанию.
      Так, всё сказал? Всё. Эээ. Как там полагается — вопросы?</p>

    <p>Вопросы были. В зале поднялся
      гул, кто-то сразу рванул к Ларию, другие просто тянули руки или выкрикивали с
      места. Дима честно пытался ответить всем и поддержать иллюзию научного
      мероприятия, но потом плюнул, бросил микрофон на стол и погрузился в пучину
      чёрных студенческих мундиров —&nbsp;прямо так, не зашнуровывая ботинок. Рядом с
      ним мелькнула девочка Шухера (если Попельдопель, конечно, её ни с кем не
      путает), подёргала Диму за закатанный рукав и была услана всё к тому же Ларию.
      Некоторые студенты, особенно те, что постарше, кучками шушукались или громко
      спорили, но никто — никто! — не вышел из актового зала.</p>

    <p>Некоторым людям просто
      бессмысленно везёт, и все эти люди почему-то так или
      иначе оказываются в Университете.</p>

    <p>Ну в
      самом же деле! Работал себе покойник Дима в Медицинском корпусе и горя не знал.
      Потом бац! — опасные планы Бедроградской гэбни, а он
      тут как тут. Удача. Гуанако так вообще позавчера с корабля сошёл, и вот
      —&nbsp;у Университета есть помощь Порта. Удача. И Хуй, и неожиданные скопцы…</p>

    <p>Удача, удача, удача.</p>

    <p>Надеяться на неё не стоит, но она
      всё равно приходит.</p>

    <p>Если бы не она — поимела бы вконец обезумевшая Бедроградская гэбня разруху в
      городе. Или как минимум (но ничуть не лучше) — Университетскую
      гэбню, и ходили бы все бледные, с вытянутыми рожами и бесконечными бумажками. А
      так — веселье, тайные операции, поездки в степь и секретное производство лекарства
      от чумы из студентов.</p>

    <p>И ещё —&nbsp;скопцы, которых не
      стоит путать со скопниками и оскопистами.</p>

    <p>СКОПЦЫ!</p>
  </>
}

export default chapter04
