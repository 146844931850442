import React from 'react'
import { Helmet } from 'react-helmet'
import {Link} from 'react-router-dom'

import './BonusArtPage.sass'

const images: Array<{
  src: string,
  url: string,
  title: string
}> = [
  {
    src: 'header-big_0-th.jpg',
    url: '/node/62',
    title: 'Грифоны держат небо'
  },
  {
    src: 'go copy_1-th.jpg',
    url: '/node/63',
    title: 'Габриэль Евгеньевич печалится'
  },
  {
    src: 'griffin-th.jpg',
    url: '/node/64',
    title: 'Ещё грифон'
  },
  {
    src: 'night_and_day-th.jpg',
    url: '/node/65',
    title: 'Птицы'
  },
  {
    src: 'smth-th.jpg',
    url: '/node/66',
    title: 'Ночь или ночь'
  },
  {
    src: 'gayporn_1875_0-th.jpg',
    url: '/node/69',
    title: 'Полотно о духовном зрении'
  },
  {
    src: 'start-th.jpg',
    url: '/node/70',
    title: 'Ты не дёргайся'
  },
  {
    src: 'ge3-th.jpg',
    url: '/node/75',
    title: 'Г.Е. и порнография'
  },
  {
    src: 'mismatch-th2.jpg',
    url: '/node/82',
    title: 'Светотень'
  },
  {
    src: 'window_steppe_bw.jpg',
    url: '/node/97',
    title: 'Колошма'
  },
  {
    src: 'cell-th.jpg',
    url: '/node/98',
    title: 'Камера в степи'
  },
  {
    src: 'cranes-th.jpg',
    url: '/node/99',
    title: 'Журавлики'
  },
  {
    src: 'bewithme-th2.jpg',
    url: '/node/104',
    title: 'За спиной'
  },
  {
    src: 'hard-th2.jpg',
    url: '/node/107',
    title: 'Труд'
  },
  {
    src: 'che-th2.jpg',
    url: '/node/118',
    title: 'Черёмуха'
  },
  {
    src: 'valentine-th.jpg',
    url: '/node/132',
    title: 'Открытка к несуществующему празднику'
  },
  {
    src: 'ge_gentle-th2.jpg',
    url: '/node/155',
    title: 'Ночью темно и в тёмных дворах опасно'
  },
  {
    src: 'talk-th2.jpg',
    url: '/node/157',
    title: 'Куртуазная беседа как высшая ценность'
  },
  {
    src: 'badass-th2.jpg',
    url: '/node/159',
    title: 'Быть может, это обложка какой-то другой книги'
  },
  {
    src: 'teach-th2.jpg',
    url: '/node/161',
    title: 'О судьбах отечественного образования'
  },
  {
    src: 'table-th2.jpg',
    url: '/node/165',
    title: 'Не шутим про банан, не шутим!'
  },
  {
    src: 'life_story-th2.jpg',
    url: '/node/168',
    title: 'Ногу сверло'
  },
  {
    src: 'vragi-th2.jpg',
    url: '/node/172',
    title: 'Девочка и вирусы'
  }
]

export default () => <>
  <Helmet>
    <title>Красивые картинки</title>
  </Helmet>
  <main>
    <h1>Красивые картинки</h1>
    <p>А здесь можно полюбоваться на разнообразные изображения, которые не являются собственно иллюстрациями к «Чуме», но всё же имеют к ней некоторое отношение. Всё, чего вы не хотели знать о героях и мире действия. Да, картинок будет становиться больше.</p>
    <div className='ArtPage_Grid'>
      {images.map(image => <Link to={image.url} tabIndex={0} key={image.url}>
        <img src={`/images/${image.src}`} alt={image.title} />
        <span>{image.title}</span>
      </Link>)}
    </div>
  </main>
  <footer>
    <img src='/images/site_footer.jpg' alt='' style={{
      maxWidth: '100vw'
    }} />
  </footer>
</>