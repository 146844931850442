import React from 'react'
import { IBookPage } from './index'

const chapter29: IBookPage = {
  header: 'header_d8.jpg',
  title: 'Глава 29. Четверо безымянных',
  prevLink: 'chapter28',
  nextLink: 'chapter30',
  content: <>
    <h2>Университет. Дима</h2>

    <img alt='' src="/images/29_dima.jpg" style={{ float: 'left' }}/>
    <p>Давным-давно в одном
      далёком-далёком степняцком поселении Дима смирился с
      очень простой мыслью: видимо, нет такого безумного занятия, к которому ему не
      придётся приложить руку в своей жизни. Одна из степнячек принесла ему младенца
      (у них ведь там никаких печей, сплошное живое рождение; и да, человеческие роды
      Диме тоже довелось принимать, что наверняка сделало его стократ более политическим,
      чем ранее). У младенца были выколоты
      глаза. Это для того, объясняла женщина (хотя, леший, кто б её
спрашивал!), чтобы он с рождения учился чувствовать линии земли и линии тела,
чтобы он мог идти сквозь пожар и сквозь чуму, чтобы он лучше всех ведал степь,
ведь истинно ведать можно лишь тогда, когда не знаешь, стоит ли она или
выгорела годы назад.</p>

    <p>Обработайте ему, пожалуйста,
      ранки, попросила степнячка.</p>

    <p>Если бы она пришла с ещё зрячим
      чадом и попросила его ослепить, Дима попытался бы развести околорелигиозную
      демагогию (земное зрение, мол, не влияет и не может влиять на духовное, лишать
      первого, чтобы развить второе, — это, мол, сплошное оскопление, истинно ведать
      от этого не начинают и прочее в подобном духе). И вообще, младенец тоже
      человек, просто маленький и уродливый, надо бы дать ему возможность решать
      самому, когда сможет, а до тех пор охолонуть. Но говорить разговоры довольно
      очевидным образом было уже бессмысленно, поэтому Дима покорно потянулся за
      антисептиками и смирился с очень простой мыслью.</p>

    <p>События не обходят тебя стороной
      просто потому, что их сложно примерить на свою жизнь.</p>

    <p>(Младенец, разумеется, помер, не
      прошло и двух недель: степнячка за каким-то лешим напихала ему в нормально
      заживающие глазницы твири, а это довольно больно, и маленькая уродливая голова
      не выдержала.)</p>

    <p>События, которые непросто
      представить, день ото дня валятся на голову, удивляться тут нечему — и, тем не
      менее, Дима никогда не думал, что ему придётся изобретать новый вид алкоголя.</p>

    <p>Предельно оперативным образом.</p>

    <p>Потому что, знаете, чума в
      Бедрограде, чума в Порту, а главное — долги у Университета.</p>

    <p>Трагический
      неприезд Сепгея Борисовича, обернувшийся внезапным
      приездом Виктора Дарьевича, впрочем, несколько в этом вопросе помог. (Заметка на полях: голову блядь пиздец сломаешь
      от
      количества валящихся на неё неожиданных поворотов событий, способность хоть
      что-то ожидать отключилась уже к хуям козлиным,
      спасите-помогите.) Неповторимый стиль Университета неожиданно нашёл ярого
      поклонника в лице Виктора Дарьевича, который решил щедрой столичной рукой с
      долгами помочь. И с чумой помочь. И даже с Социем помочь. В общем, сплошной
      политик и меценат.</p>

    <p>«Политик и меценат» —&nbsp;это
      привязавшееся к Йыхе Йихину клише из каких-то научно-популярных
      статеек. Виктор Дарьевич, к сожалению, политиком как раз не был, о
      бедроградских сварах париться не собирался, но зато увёз
      пяток скопцов и Габриэля Евгеньевича. Лечить и исследовать.</p>

    <p>(Габриэля
Евгеньевича, не скопцов, им уже сложно помочь. Ему, впрочем, тоже: сын
      женщины, полубританец с устойчивостью к наркотическим
      веществам, сертифицированный псих, находящийся в процессе излечения водяной
      чумы — в общем, разгул что для вирусологии, что для
      когнитивной науки. А если что, любого можно разобрать на
органы.)</p>

    <p>Охрович и Краснокаменный должны быть
      довольны: в конечном счёте Габриэль Евгеньевич
      всё-таки оказался ресурсом.</p>

    <p>Ресурсом.</p>

    <p>Диме было как-то почти смешно от того, что ещё пару дней назад его дёргало это
      слово, а
      всё-таки — он привык к нежному и ласковому индивидуальному подходу всех ко
      всем. Нежные индивидуальные допросы каждого двадцатилетнего прыща у гэбен.
      Нежные индивидуальные беседы на Колошме. Нежная индивидуальная операция по
      вытягиванию информации у Гошки. Нежная индивидуальная
      твирь в жопу.</p>

    <p>Что твирь можно совать в жопу всем скопом («скоп» — это, видимо, организованная
      группировка скопцов, гы-гы), было странно. Потому что
      стебли твири — это стебли твири, и меряются они граммами, а люди — это люди, и
      меряются они людьми.</p>

    <p>Разве ж нет?</p>

    <p>Да же!</p>

    <p>Обратное
      требовало усиленной внутренней работы по привыканию.</p>

    <p>Виктор Дарьевич уехал буквально
      пару часов назад, рассыпая по тропе, на которую ступали его ноги, щедрые дары.
      Он дал капсулы с предположительно (сам Дима не пробовал) черёмуховым запахом,
      которые позволили задурить Соция. Он дал Гуанако прямоугольную жестяную
      коробочку с липкой субстанцией ядрёно-сиреневого цвета, которую настоятельно не
      советовал пробовать на вкус и которая должна была
      утихомирить любые личные претензии Озьмы, поскольку экспериментальные
      наркотические средства стоят натуральное состояние. Он обещал прислать к
      завтрашнему дню людей, чтобы искать и лечить больных в Бедрограде и за его
      пределами, он обещал прислать <i>реально
        много денег</i>, он обещал перспективу доить Медицинскую гэбню и дальше. Он до
      глубины души восхитился неповторимым стилем Университета и магическим ударом
      копыта решил все материальные проблемы.</p>

    <p>Взамен Виктор
Дарьевич попросил всего ничего: скопцов для когнитивных исследований, Габриэля
Евгеньевича для разнообразных, ответы на некоторые вопросы да образец штамма
водяной чумы с лекарством. В сравнении с
      щедростью даров —&nbsp;тьфу, плевок карликового коня в вечность.</p>

    <p>Не считая сущей мелочи,
      оставшейся несколько за кадром.</p>

    <p>Дима отдал ему остатки грязи, из
      которой гипотетически происходит степная чума.</p>

    <p>Того самого
сырья, ради сокрытия которого от Медицинской гэбни было выстроено столько
сложных комбинаций, поскольку неизвестно, что они станут с ним делать, но вряд
ли что-то хорошее.</p>

    <p>(Справедливости ради, сам Дима
      тоже не то чтобы что-то сильно хорошее сделал, так что всё путём, равновесие и
      гармония.)</p>

    <p>Виктор Дарьевич уже почти стоял
      на пороге (они с Димой собирались пройтись в направлении медфаковского
      лазарета, откуда следовало выкрасть Габриэля Евгеньевича — вряд ли Попельдопель
      жаждал лицезреть адресата своих слезливо клянчащих
      гранты писем; адресат вот точно не желал лицезреть Попельдопеля, и вообще, у
      него конспиративная шляпа для красоты, что ли?). Эту самую шляпу он и
      нахлобучивал, а потом обернулся и спросил, заглядывая в лицо своими
      омерзительно умными глазами — так всё-таки, Дмитрий, откуда же взялось сырьё
      для водяной чумы?</p>

    <p>Дмитрий всё это время надеялся,
      что за увлекательными обсуждениями правомочности экспериментов на детях и
      шаманских способах борьбы с психическими отклонениями (нетрудно догадаться, кем
      работал в степи Гуанако) вопрос сырья и степной чумы как-нибудь забудется.</p>

    <p>Не забылся.</p>

    <p>И хочется же сказать, что Дима
      Виктору Дарьевичу всё как на духу выложил чисто из боязни, что тот развернётся
      и уедет, увозя с собой обещанные деньги, людей и перспективу, только неправда
      это.</p>

    <p>Трудно, прям-таки невозможно отшить человека, который к тебе явно всей душой.</p>

    <p>(Душой, снабжённой омерзительно
      умными глазами, которые и смотавшихся из Порта чаек заметили, и о степной чуме
      явно всё поняли.)</p>

    <p>И очень, очень странно
      становится, когда все сложные комбинации, построенные ради сокрытия банки грязи
      от Медицинской гэбни, падают
      жертвой одного простого вопроса.</p>

    <p>Поэтому окрылённый Виктор
      Дарьевич умчался с Габриэлем Евгеньевичем под мышкой и банкой степной грязи в
      кармане, и его магические копыта стучали по брусчатке особенно звонко.</p>

    <p>Самое весёлое — что со всем этим явлением
      козла из машины судьба Сепгея Борисовича осталась неясной.</p>

    <p>Ну
      полечим, туманно посулил Виктор Дарьевич.</p>

    <p>Ну
      полечат.</p>

    <p>Все кругом только тем и заняты,
      что друг друга калечат, потом лечат, потом снова калечат и далее. Можно
      организовать замкнутую систему, в которой всегда найдётся работка для
      пробегающего мимо трижды покойника.</p>

    <p>И все будут довольны.</p>

    <p>Кроме нескольких случайных жертв
      и прочих хороших людей.</p>

    <p>Гуанако успел наплести стройных
      стратегических планов, которым подобало опутать Диму и не пустить его на
      встречу с Социем (хитрость заключалась в том, что на неё попёрся
      сам Гуанако), но явление козла из машины их несколько обесценило.</p>

    <p>Потому что когда вселенная
      говорит тебе — не рыпайся, козявка, у меня всё просчитано, твои потуги тут уже
      всем приелись, бери лучше деньгами — разумнее всего не рыпаться и брать
      деньгами.</p>

    <p>А завтра, вероятно, покойный
      Димин батюшка вылезет из-под своего карданного вала и решит взять его судьбу в
      свои руки.</p>

    <p>Это вовсе не было бы неожиданным.</p>

    <p>Зато неожиданным был тот факт, что
      липкая ядрёно-сиреневая хрень, шматок которой был
      честно похищен из коробочки, предназначенной Озьме, не распалась в спиртовой
      среде а, кажется, образовала с ней некую стабильную циклическую реакцию.</p>

    <p>Круто!</p>

    <p>Виктор Дарьевич ударом копыта
      решил все материальные трудности, и все ему, конечно, были от глубины
      определённых внутренних органов благодарны, только материальными трудностями
      гора нынешних бед не ограничивалась. Даже если оставить в стороне никуда не
      девшуюся трогательную дружбу с Бедроградской гэбней и прочих фигурантов
      Университета, всё равно оставались задачи.</p>

    <p>Порт не хотел денег. То есть
      Порт, конечно, хотел денег, и посмели бы ему их не дать, но помимо денег он
      хотел и духовной пищи. Того, что купить сложно.</p>

    <p>Изобретательности.</p>

    <p>«Удиви меня, тварь», — говорил
      Порт, и Дима покорно плёлся удивлять.</p>

    <p>(Тем более что счёт явно шёл на
      часы, а деньги от Виктора Дарьевича могли прийти не раньше, чем Виктор Дарьевич
      доехал бы до Столицы.)</p>

    <p>Порт любит бухать, лизать, курить
      и нюхать —&nbsp;вот пусть и получит Экспериментальную Наркотически-Алкогольную
      Смесь имени Дмитрия теперь уже Борстена. В двадцатилитровый бак пошло всё, от
      лёгких галлюциногенов до содержимого попельдопелевского
      кофейника.</p>

    <p>(Кофейник свалился в бак
      случайно, и вынимать его никто уже не стал.)</p>

    <p>Полученная смесь была крайне
      чёткой (Дима лизнул мокрый палец и получил пятнадцатиминутный перебой в
      трудоспособности, что явно неплохой результат) и уже почти готовой. Разбодяжить эти двадцать литров —&nbsp;и партия
      контрабандной сивухи из Латинской Америки готова. О признании личности
      контрабандиста позаботится Святотатыч, а легенду сожрут.</p>

    <p>Обратную ветрянку же сожрали.</p>

    <p>(Ну и образ, батюшки!)</p>

    <p>Сожрали
      с причмокиванием, и Диму все за это крайне полюбили и похвалили, только мысли о
      любви и похвалах невольно возвращали к их источнику.</p>

    <p>Чума в Порту.</p>

    <p>И вот что с ней делать
      —&nbsp;непонятно, невозможно понять, сама Портовая гэбня не знает точно, где и
      сколько там людей, кто и с кем там какую любовь имеет и когда кто-нибудь
      надумает послать блокаду в пень и заняться своими серьёзными делами. Никакие
      люди от Виктора Дарьевича не помогут вколоть лекарство бандюку,
      который числится в официальном розыске, и десятку его подручных, о
      существовании которых не знает даже Святотатыч.</p>

    <p>Мысль о том, что блокада Порта
      долго не продержится, кто-нибудь сорвётся с места на деловую встречу, и вместе
      с ним сорвётся и поползёт по всему миру чума, была совершенно не страшной и не
      грустной. Потому что проблема такого масштаба — слишком колоссальна и
      грандиозна, чтобы вместиться в маленькую Димину душонку.
      Он просто не мог представить, как это — всемирная эпидемия.</p>

    <p>(«Пандемия», — услужливо
      подсказал обширный словарный запас.)</p>

    <p>Примерно как не мог представить
      себе войну. В общем, как рядовому и благочинному жителю Всероссийского
      Соседства ему и не полагалось, но образы из полуподпольных латиноамериканских
      книжонок тревожили разум.</p>

    <p>Примерно как не мог представить
      себе степную чуму, пока не оказался в самой её серёдке.</p>

    <p>И это тоже было не страшно и не
      грустно, просто несколько мерзко — смотреть на бродячие куски гниющего мяса. Не
      страшно, потому что <i>слишком</i>.</p>

    <p>Если вдруг
      какая угодно чума разразится по всему миру, Дима даже не испугается.</p>

    <p>(Даже сейчас понятно, что будет
      ржать как идиот — кто бы, мол, мог подумать, что мне
      доведётся сгубить человечество.)</p>

    <p>Дима боится совсем, совсем другого.</p>

    <p>Это сложилось феерически нелепым
      образом. Когда Диме исполнилось девятнадцать (давно, на втором курсе, когда
      Гуанако ещё никуда не пропадал, а жизнь была радостна и прекрасна), он надумал
      возродить былые столичные радости, вернуть все вещи, которые стырил при побеге
      оттуда, и, возможно, даже покаяться перед дядькой с дерьмом
      на балконе.</p>

    <p>(Не сам, конечно, надумал, а
      былые радости позвонили и предложили мириться.)</p>

    <p>Примирение и
возрождение часа за полтора обернулось пьянкой каких-то совсем уж
исключительных масштабов, из которой Дима помнил первые двадцать минут,
затемнение, а потом — что на улице ночь, они с радостями прыгают по какому-то
столичному переулку и бьют ногами неизвестного Диме человека в крайне
задрипанной одежде (как есть беглый преступник или ещё какой неблагонадёжный
элемент — кажется, про это они и кричали, уверяя всех возможных
      очевидцев в том, что блюдут покой Всероссийского Соседства). Человек вроде бы
      закрывал голову руками, но на самом деле уже нет, и там, где Димина нога
      ожидала почувствовать рёбра, было почему-то очень мягко.</p>

    <p>Дима отдёрнулся и отдёрнул других
      — человек не пошевелился.</p>

    <p>Тогда он почувствовал, что его
      яростно тошнит, и убежал (не показывать же позор желудка былым радостям),
      услышав только, как за его спиной гадают, прибили или надо ещё чуток.</p>

    <p>(Так, впрочем, и не проблевался.)</p>

    <p>Разумеется, всё по всем законам
      жанра: он так до сих пор и не знал, прибили или нет, было ли это всё шуткой или
      даже, может, глюком.</p>

    <p>И не знал, почему приходил в
      такой ужас от мысли о том, что, <i>он, возможно,
        убил человека</i>. Ведь не то чтобы каждая жизнь была священна — есть
      бесполезные говнюки, отсутствие которых в этом мирке
      только сделает всем лучше. Хотя и это тоже пустой трёп, право на жизнь не
      связано с социальной ценностью, оно просто есть, а потом заканчивается. Иногда
      раньше, иногда позже, занавес, расходимся, не на что тут смотреть.</p>

    <p>Но Дима оказался не то духовнее, чем полагал, не то брезгливее, чем полагал, — в
      общем, ему бессмысленно и неотвратимо казалось, что, убив некоего безымянного,
      неблагонадёжного и все дела, он сломал что-то такое, в чём не понимает и не
      может понимать в силу своей человеческой сущности.</p>

    <p>(И рёбра.)</p>

    <p>И никакие умопостроения
      от этого ощущения не спасали.</p>

    <p>Гуанако сперва
      поржал (ох как они тогда поругались), потом недоумённо выкатил глаза, потом
      только сочувственно вздохнул, но так до конца и не понял, просто смирился.</p>

    <p>(Да Дима и сам не понял, просто
      вот так оно было — и всё.)</p>

    <p>И так бы оно, наверное, и
      осталось трагикомическим эпизодом из невинного детства, если бы не явление
      Андрея на Колошму весной 76-го, аккурат перед степной
      чумой.</p>

    <p>Андрею было сильно надо помощи
      Гуанако, Гуанако было не сильно надо помогать Андрею, а досталось, как обычно,
      Диме. Тогда и твирь-в-жопу приключилась, и крайне насильственные
      сношения с бесконечными младшими служащими, и ещё кое-что.</p>

    <p>Андрей притащил Диму в очередное
      безликое помещение, поставил перед закреплённым в специальной рамке пистолетом
      (выглядит предельно идиотски, но направление задано чётко — ни себя, ни Андрея,
      ни охрану не перестреляешь). Напротив рамки стоял неизвестный (безымянный,
      неблагонадёжный) заключённый.</p>

    <p>«Стреляй», — сказал Андрей.</p>

    <p>«А не пошёл бы ты в хуй?» —
      дружелюбно предложил Дима.</p>

    <p>(Знал бы он тогда, что они
      фактически в Хуе и находятся — то есть на Хуе, ибо Хуй под землёй, и не под абы
      какой, а прямо под Колошмой.)</p>

    <p>«Стреляй», — сказал Андрей.</p>

    <p>Дима пожал плечами и завалился на
      пол.</p>

    <p>Его подняли, он уставился в
      потолок. Его подтолкнули, он качнулся обратно. Его приволокли
      к рамке, он опёрся на неё спиной.</p>

    <p>Андрей вздохнул, вытащил
      собственный пистолет и выстрелил заключённому в живот.</p>

    <p>«Теперь ему очень больно, и,
      кроме того, он в любом случае скоро умрёт. Стреляй».</p>

    <p>Дима выстрелил, конечно. Чужие
      сдавленные стоны и ползанье в крови имеют крайне тонизирующее воздействие на
      моторные рефлексы.</p>

    <p>Андрей привёл второго — стреляй.</p>

    <p>Третьего.</p>

    <p>На четвёртом Дима не выдержал,
      спросил самого заключённого — ты, мол, хочешь умереть?</p>

    <p>Тот посмотрел на Андрея,
      посмотрел на своих успевших помучиться коллег по заключению и мрачно
      усмехнулся: «Конечно».</p>

    <p>«Своеобразные же у тебя мечты».</p>

    <p>«Так ведь без вариантов. Не ты —
      так эти вытащат на задний двор и привет. Только эти ещё помучают сперва. Давай, не
      выёбывайся».</p>

    <p><i>Эти</i> согласно и даже одобрительно покивали — мол, дело говорит.</p>

    <p>«А других вариантов что, совсем
      нет? Жизнь после смерти-то сомнительна».</p>

    <p>«Ты сюда вести учёные дискуссии припёрся? В Столице своей, или откуда ты там, надо
      было
      дискуссии вести. Будь мужиком, в конце-то концов».</p>

    <p>«Не провоцируй меня такими
      примитивными методами».</p>

    <p>«Блядь, пристрелите меня уже хоть
      кто-нибудь!»</p>

    <p>Дима и пристрелил, конечно.
      Патентованным (тоже политический термин!) методом Твирина, со второй попытки,
      потому что стрелять в того, с кем ещё и поговорить успел — это, в общем,
      головой надо было думать перед тем, как вопросы задавать.</p>

    <p>На том всё и закончилось. Гуанако
      выдал Андрею то, чего ему было надо. Андрей вскоре уехал с Колошмы и никогда не
      возвращался. Дима ушёл в свою камеру, и четыре безымянных заключённых ни разу
      не приснились ему в кошмаре.</p>

    <p>Только он знал, что гуанаковская бутафория, которой тот допрашивал Гошку под
      конец мая, — чушь, а настоящая Загробная гэбня — это и есть эти четверо безымянных (одинаковых, давно уже перемешавшихся в
      сознании), и что когда-нибудь уже они применят к Диме санкции. И это не совесть
      и не чувство вины, совесть — штука всё-таки довольно рациональная, она
      последовательно жаждет вырвать обладателю глаз за чужое око.</p>

    <p>Это не совесть и не чувство вины,
      это просто Дима тогда ещё чуть-чуть поседел и, по словам Гуанако, немного
      изменил манеру улыбаться.</p>

    <p>И вот что особенно любопытно:
      всё, что Андрей тогда делал с Димой, ему так или иначе
      пригодилось.</p>

    <p>Твирь-в-жопу подарила защиту от
      чумы.</p>

    <p>Общение с младшими служащими
      подарило Гуанако возможность разговорить Соция на
      утренней встрече. (Заметка на полях: спасибо Виктору Дарьевичу и отряду за то,
      что Дима в этой жизни хоть чем-то примечателен.)</p>

    <p>И только те четверо безымянных не подарили ничего, кроме бессильной и потому
      стыдной ненависти к Андрею.</p>

    <p>Если бы на утреннюю встречу шёл он,
      никакой Виктор Дарьевич с черёмуховыми пилюлями и никакой здравый смысл не
      позволили бы Диме пустить вместо себя Гуанако, сколь бы светлые и чувства и
      сколь бы разумные мысли ни побуждали того подставляться самому.</p>

    <p>Когда-то давно, до степной чумы и
      твири-в-жопу, в 75-м, когда всё тот же Андрей только упёк Диму на Колошму, он
      прострелил ему левую ногу при условной попытке к сопротивлению.</p>

    <p>С тех пор Дима мечтал симметрично
      всадить Андрею в ногу дрель.</p>

    <p>Просто так, чтоб прочувствовал и
      в назидание.</p>

    <p>(Ну и для
галочки нельзя не задаться вопросом: а убил бы? Ответ:
леший знает, но поставить эксперимент хочется.)</p>

    <p>Тем временем в двадцатилитровом
      баке с экспериментальной смесью для Порта царили мир и дружелюбие. Дима
      отщёлкнул электропровода, захлопнул крышку и потянулся за бумажкой — в этом
      учреждении нельзя оставлять двадцатилитровые баки на произвол судьбы, по этому
      учреждению бродят Охрович и Краснокаменный. Если оставить записку о
      непригодности содержимого для питья сторонними лицами, им хотя бы будет стыдно.</p>

    <p>Ну самую
      малость.</p>

    <p>Когда-нибудь.</p>

    <p>Правда, за что-нибудь другое.</p>

    <p>В баке довольно фыркнуло.</p>

    <p>Остынет — и можно разливать.</p>

    <p>Остывать будет долго, часа
      полтора, но у Димы на это время имелось ещё одно дело.</p>

    <p>Дело, вызывавшее у него примерно
      те же чувства, что и всемирная эпидемия («пандемия», склеротик) чумы: будто всё
      это происходит в радиопостановке, потому что в нормальном мире такого не
      бывает. Безглазые дети бывают, изобретение нового алкогольного коктейля бывает,
      Вилонский Хуй со скопцами бывает, а вот некоторых вещей — нет.</p>

    <p>Дима встал со стула и с интересом
      обнаружил, что спина уже привыкла к позиции «многоступенчатый крючок» и
      разгибаться не намеревается. Зря, зря Гуанако всё хвалит и хвалит его гибкость,
      годы уже явно не те, конечности заскорузли и требуют получасовой зарядки.</p>

    <p>(Это не было эротическим
      высказыванием.)</p>

    <p>Экспериментальная
смесь варилась не в Димином уже-почти-кабинете (переоборудованной кладовке за
актовым залом), а в лаборантской за одной из аудиторий на третьем этаже — не
попрёшь же вниз двадцатилитровый бак со всеми проводами. С одной стороны, удобно — совсем рядом с курилкой, а с другой — в
переоборудованной кладовке Дима не только чувствовал себя удивительно на месте,
но и хранил стратегический запас еды, который сейчас, вообще говоря, пришёлся
бы крайне к месту (со всеми этими викторами дарьевичами и прочими отвлекающими обстоятельствами
воспоминание о пасте по-портовому висело во рту томным миражом).</p>

    <p>Гуанако сказал, что на встречу с
      Социем Диме ходить незачем, и оказался прав, как обычно. Более того, пошёл сам.
      Опознал случайно брошенную фамилию, не смог упустить шанса
поиздеваться над бывшим командиром.</p>

    <p>Гуанако пошёл сам, и всё
      сложилось более чем прекрасно (все выжили). Выжили и даже рассказали друг другу
      почти всю правду — кроме душещипательной истории о
      том, как Дима погиб в степи.</p>

    <p>Это Гуанако, значит, так
      оберегает.</p>

    <p>(Он оберегает, а Загробная гэбня записывает в протокольные бланки!)</p>

    <p>Замечание для галочки: Гуанако
      всегда оказывается прав, а это означает, что Дима и правда погиб в степи, а всё
      происходящее — его предсмертная галлюцинация.</p>

    <p>Или посмертная.</p>

    <p>Загробная
      гэбня записывает в протокольные бланки.</p>

    <p>А ещё Соций с Гуанако сделали
      что? Правильно, договорились об очередной встрече! Это, наверное, нормально в
      большой политике, но всё-таки потрясающе нелепо. Нет бы прямо
сразу составить график, расписать посещения и всё такое.</p>

    <p>Встреча должна состояться между
      Бедроградской гэбней и «реальной властью Университета» в любом количестве. Без
      охраны, при оружии, чисто по-пацански, раз и
      навсегда, третий раз — юбилейный, а наутро выжившие дружно отправятся
      любоваться на годовщину Первого Большого.</p>

    <p>Чисто, честно, по-пацански, только Ларий, услышав об этом, сразу и прямо
      сказал: он, во-первых, не понимает значения термина «реальная власть», а
      во-вторых, никуда не пойдёт при оружии, пока не имеет права его носить.
      Оружия-то и нет, а если найти, то даже во всей этой полной хитросплетённых
      интриг истории Бедроградская гэбня не погнушается таким простым и очевидным
      способом арестовать его на месте. Так что просто нет, и пусть его считают
      трусом.</p>

    <p>Можно уговорить Охровича и
      Краснокаменного выпустить из-под замка нашедшегося Максима, но того на встрече
      с «реальной властью» попросту засмеют. Сами Охрович и Краснокаменный и пошли
      бы, наверное, только кто ж ходит вдвоём против четверых.</p>

    <p>Дима хотел
      было подумать мысль о том, до каких пучин заунывного абсурдизма
      докатилось всё происходящее, когда почти что в лоб ему ударила дверь кафедры
      вирусологии, куда принесли Диму решившие поразмяться ноги.</p>

    <p>В соседней с ней аудитории прямо
      сейчас работает Шухер.</p>

    <p>Гуанако пошёл на встречу с
      Социем, и всё сложилось прекрасно, Соций ответил на все вопросы. Дима пошёл к
      аудитории рядом с кафедрой вирусологии, и всё сложилось прекрасно, он
      благополучно преодолел пятьдесят метров и даже ничего не сломал себе в
      процессе.</p>

    <p>Вот только в обоих случаях победы
      на самом деле не хотелось.</p>

    <p>Потому что Соций ответил на <i>все</i> вопросы.</p>

    <p>Дима уже сказал Ройшу. Это было
      страшно и как-в-радиопостановке, потому что только в радиопостановках
      рассказывают о «гибели кого-то небезразличного» и «приношу соболезнования»
      (мешками). Но Ройш засел в своём доме, а у Димы вот никак не было времени дотуда доехать, поэтому он позвонил, хоть такие вещи и не
      говорят по телефону (если верить всё тем же радиопостановкам, за пределами
      которых такого попросту не бывает).</p>

    <p>&nbsp;</p>

    <p>«Гуанако поговорил с Социем», —
      сказал Дима молчанию в снятой трубке.</p>

    <p>«Я знаю», — ответил Ройш.</p>

    <p>«Применялись психические атаки и
      психотропные вещества, так что Соций поведал немало. В общем-то, всё, что нас
      интересовало», — сказал Дима.</p>

    <p>«Я знаю», — ответил Ройш.</p>

    <p>«Тебе уже звонили?» — спросил
      Дима.</p>

    <p>«Ты можешь меня чем-нибудь
      удивить?» — спросил в ответ Ройш.</p>

    <p>«Нет», — сказал Дима.</p>

    <p>Ройш помолчал, и Дима был готов
      поклясться, что услышал, как тот сглотнул.</p>

    <p>«Я знаю», — сказал он наконец всё тем же ровным голосом и повесил трубку.</p>

    <p>&nbsp;</p>

    <p>Но Ройш не врал, Ройш знал.
      Возможно, произошедшее заставит его выпить второй в жизни бокал вина, но он
      знал.</p>

    <p>А Шухер — нет.</p>

    <p>Это всё въевшееся в руку
      «Вороново крыло». Если бы не оно, Диме не пришлось бы стать вестником страданий
      на этой грешной.</p>

    <p>Если бы не оно и не тот факт,
      что, когда Гуанако пересказал ответы Соция, Дима кивнул и промолчал. И ему
      очень хотелось заплакать, или разозлиться, или расхохотаться, или что угодно,
      он даже закрыл глаза и попытался визуально представить — но не получалось,
      эмоций просто не было, выгоревшая степь внутри, и вместо сердца — безглазый,
      беззубый, беззлобный младенец, мягкий, как то, что
      скрывается за перемолотыми рёбрами —</p>

    <p>Дима моргнул.</p>

    <p>Может быть, если он расскажет
      Шухеру, ему станет чуточку тяжелее — достаточно, чтобы лопнуло, наконец.</p>

    <p>(Потому что Дима ради Гуанако
      готов, наверное, на всё, но превращаться мозгами в Габриэля Евгеньевича —
      всё-таки нет, а внутренние степи — это что-то из его репертуара.)</p>

    <p>От слишком долгого стояния под
      дверью аудитории в голове опять начиналась тотальная стагнация, и Дима дверь решительно
      открыл.</p>

    <p>Как и многие
рабочие аудитории медфака, эта была разделена на две части: маленькая
комнатка-коридорчик с полутора десятками стульев (сейчас — стопками вдоль
стены) и просторная лаборантская с разнообразными увлекательными приблудами, о назначении некоторых из которых Дима даже
что-то знал (вон той спиралевидной хренью проводят нетривиальную возгонку йода,
хотя как возгонка может быть нетривиальной, Дима уже, увы, не был в курсе).
      В стене между первым и вторым помещениями располагалось огромное окно.</p>

    <p>По задумке здесь будущие
      фармацевты должны строчить конспекты, созерцая опыты за стеклом. Проводимые высококвалифицированным специалистом, разумеется.</p>

    <p>Попельдопель работал в точно
      такой же аудитории.</p>

    <p>Вернее, такой
же, да не такой же: у него на придвинутом к окну столе лежали бумажки, бумажки,
десяток изрисованных заявок на гранты, пучок твири на всякий, список студентов
с твирью, список студентов из-под твири в нехорошем состоянии, лирическое эссе
о пользе шоколада в стрессовой ситуации (в какую-то столичную газетёнку надумал
тиснуть — обстоятельства, мол, вдохновили) и прочий творческий беспорядок.</p>

    <p>У Шухера на столе лежала
      аккуратно заложенная книжка («Мировой научно-фантастический вестник»,
      ознакомился Дима) и стояла немалых размеров чашка, в которой приятно коричневело что-то горячее, солёное, с лучком и явно полное
      питательных элементов.</p>

    <p>Всё, больше ничего на его столе
      не было.</p>

    <p>У каждого свои методы работы.</p>

    <p>Шухеровский,
      например, подразумевал постоянное присутствие при любом процессе. Поэтому,
      заметив Диму через окно, он махнул ему рукой — мол, не лезьте, сейчас сам
      подойду.</p>

    <p>Содержимое чашки манило.</p>

    <p>Наверное, не стоит выпивать суп
      человека, которому пришёл рассказать о гибели его дочери, подумал Дима.
      Подумал-подумал, да и опрокинул в себя половину чашки рывком.</p>

    <p>Шухеру же всё равно будет всё
      равно.</p>

    <p>На обложке «Мирового
      научно-фантастического вестника» было изображено нечто то ли плавательное, то
      ли даже летательное, но явно очень металлическое. Залпом допив шухеровский суп, Дима
      невольно потянулся к журналу.</p>

    <p>Просто вот так стоять — не лучший
      план.</p>

    <p>&nbsp;</p>

    <p><i>«Дело, как все и ожидали, закончилось выстрелом, даже двумя. И если
      первый только заставил споткнуться, то второй, кажется, окончательно уничтожил
      хорошего человека».</i></p>

    <p>&nbsp;</p>

    <p>И это, значит, наше предсказание на
      ближайшее будущее?</p>

    <p>Тогда всё отлично, Диме нечего
      беспокоиться.</p>

    <p>(И вообще, просроченное какое-то
      предсказание, наверняка ж про Сепгея Борисовича, если хороший-то человек.)</p>

    <p>В руку сама собой упала закладка
      — кривая полоска картона с феноменально кривой ёлочкой.</p>

    <p>В феноменально хорошем состоянии.</p>

    <p>Не нужно, в общем-то, обширного
      количества пядей во лбу, чтобы всё понять.</p>

    <p>Диме почему-то представилась не
      Бровь, лепящая зелёные треугольники маленькими неловкими руками (или, судя по
      результату, ногами), и даже не Шухер, с нелепой бережливостью хранящий сей
      артефакт год за годом, а всё-таки Бровь, но прознавшая
      об этом и такая —</p>

    <p>Очень возмущённая, но немного
      довольная.</p>

    <p>— В-вы ч-чего-то хот-тели?</p>

    <p>Хотел.</p>

    <p>Запоздало подумалось, что надо
      было, наверное, вытащить из ближайшей стопки стул, предложить — все кругом
      столько раз говорили о слабом сердце Шухера, что стул оказался бы даже не радиопостановочным штампом (ну не знает Дима, не знает, как
      это делается!), а вполне естественной мерой.</p>

    <p>— Мне нужно вам кое-что сказать.</p>

    <p>Шухер смерил чашку из-под супа
      крайне раздражённым взглядом, высказываться на её счёт не стал.</p>

    <p>— К в-вашим услугам.</p>

    <p>Так как же это делается? «Вы
      только присядьте»? «Возможно, вы и сами догадываетесь» (щас,
      догадывается он)? «Примите мои искренние»?</p>

    <p>— Бровь умерла.</p>

    <p>Шухер не шелохнулся и не
      изменился в лице, только халат на его довольно субтильных
      плечах повис чуть более дрябло.</p>

    <p>— П-п-простите?</p>

    <p>Вот не просил же никто Диму, не
      гнал, он сам решил, что должен, и что именно он, а ведь не умеет.</p>

    <p>— Бровь, ваша дочь, погибла. Её
      убила Бедроградская гэбня.</p>

    <p>Шухер снова не шелохнулся, только
      лицо его вдруг стало очень-очень глупым.</p>

    <p>— Я в-в-вас не п-понимаю.</p>

    <p>Дима мужественно не отвёл глаза.</p>

    <p>— Думаю, понимаете. Бровь… играла
      важную роль в нынешних событиях — она была единственным свидетелем обвинения
      против Бедроградской гэбни. И мы… мы не уберегли её.</p>

    <p>(«Я не уберёг».)</p>

    <p>Это была простая мысль —&nbsp;и
      жестокая, жёстче любой эпидемии любой чумы.</p>

    <p>(«Я обещал ей, что всё будет
      хорошо, и она верила, что вернётся — к вам! — победителем, спасёт Университет и
      заслужит уважение всех и вся, а потом — понимаете? — умерла».)</p>

    <p>— В ночь на среду, пятый день
      чумы, они сбили её такси по дороге домой. Насмерть. Думаю, тело уничтожено.</p>

    <p>— Я в-вам не в-верю.</p>

    <p>(«Мне нечем
доказывать, но я очень хорошо к ней относился, клянусь. Нечем
      доказывать, но… у меня, как и у всех, есть свои проблемы. Когда Бровь была
      рядом, они будто бы отступали. Наверное, мне казалось, что она меня понимает —
      просто так, без причин, из симпатии к человечеству. Наверное, я сам не успел
      заметить, как много это для меня значило. Бровь была сообразительной, смелой,
      искренней и честной. Мне нечем доказывать, но не думаю, что я
когда-нибудь смогу себе простить то, что случилось».)</p>

    <p>— Мне нечем доказывать, но
      сегодня один из голов Бедроградской гэбни признался в этом вслух. Не то чтобы
      мы не подозревали ранее…</p>

    <p>— Я в-вам не в-верю,
      слышите? — вдруг оборвал Диму Шухер с неожиданной злобой. — Не в-верю, что В-в-ванечка умерла!</p>

    <p>(«Я все эти дни говорил себе, что
      не поверю, пока не увижу труп, — и не знаю, что делать теперь, когда мне
      известно наверняка, что трупа я не увижу никогда».)</p>

    <p>— Доказательств нет, — Димины
      руки сами собой досадливо взмахнули, будто это только он говорил о Брови, а
      они, руки, пересказывали какую-то байку с первого курса, — но у головы
      Бедроградской гэбни не было особых причин врать, а Бровь действительно пропала
      именно в тот временной промежуток. Мы думали, что её забрал Силовой Комитет, но
      никакого Силового Комитета в Хащине…</p>

    <p>— Меня не инт-т-тересует
      в-всё это! — взвизгнул Шухер и шагнул прямо на Диму. — Не инт-тересует,
      п-поняли? Я не з-знаю, что вы сделали с В-ванечкой и в-во что вы её в-втянули,
      но чт-то бы это ни б-было, в-виноваты вы, а не Б-бедроградская г-гэбня, Силовой К-комитет или Хащина!</p>

    <p>От злости он даже почти перестал
      заикаться. По его лицу клубами дыма ползали красные пятна, и от этого он стал
      совсем некрасивым.</p>

    <p>— Я, если вы говорили обо мне
      лично, не отрицаю своей вины. Никто не отрицает своей вины. И я не знаю, что
      могу сказать в утешение. Не думаю, что Бровь хотела умирать. Но зато я точно
      знаю, что её последние дни принесли ей много веселья.</p>

    <p>— В-вы лжёте! — Шухер сжал
      кулаки, разжал, снова сжал. — В-вы пользуетесь В-ванечкой, как п-пользуетесь
      всеми, я не могу и не хочу в-вам верить!</p>

    <p>— Послушайте…</p>

    <p>— Нет, это вы п-послушайте!
      Университет — это место для об-бучения, леший подери. Преп-подаватели
      не собирались заниматься п-политикой, с-студенты не заслуживали т-того, что
      в-вы с ними сделали. Вы д-добиваетесь каких-то своих целей, идёте по т-трупам,
      и ни я, ни В-в-ванечка…</p>

    <p>— Да перестаньте же уже называть
      Бровь Ванечкой! — заорал Дима, и чашка из-под супа полетела на пол. — Ей не
      нравилось это имя. Ей хотелось приключений, ей хотелось жить, как в романе. Она
      была в восторге от того, во что «мы её втянули». Вы реагируете так, как будто у
      вас отобрали любимую игрушку, и «я» у вас впереди «Ванечки»! Леший вас еби,
      отцовская любовь заключается не в том, чтобы решать за ребёнка, как ему жить и
      как — если уж — умирать!</p>

    <p>— З-заткнитесь и б-больше ник-когда не говорите о том, в чём не п-понимаете, —
      выдавил
      Шухер.</p>

    <p>(«Он прав,
ходячая ты безотцовщина. Он прав, а ты виноват,
приумолкни».)</p>

    <p>— Если уж на то пошло, вы
      виноваты в произошедшем не меньше меня — может быть,
      больше. Зачем было писать липовую справку? Не накорябали
      бы — лежала бы Бровь в ту ночь на койке, и сейчас уже давно пришла бы в себя.
      Она бродить-то по городу пошла во многом от обиды, что
      ей не досталось твири-в-жопу.</p>

    <p>(«И оттого что
я её послал».)</p>

    <p>Шухер посмотрел на Диму
      неожиданно очень широкими и детскими глазами — будто впервые расслышал, что тот
      говорит, и очень надеялся, что расслышал неправильно.</p>

    <p>— Я не в-в-верю, — пробормотал
      он.</p>

    <p>— Мне тоже, я…</p>

    <p>— Я не в-в-верю, что у в-вас
      хватило наглости яв-виться ко мне.</p>

    <p>Кончик шухеровского
      носа мелко подрагивал, но в остальном он выглядел почти нормально — нормальнее,
      кажется, чем во все предыдущие разы, что Диме доводилось его видеть.</p>

    <p>— Я решил, что это честно.</p>

    <p>— Мне известно имя, к-которое вы
      носите с рождения, но что в-вы за ч-человек, я не з-знаю. Не з-знаю, как в-вам
      удалось в-всё это провернуть, не з-знаю, зачем. З-знаю, — Шухер презрительно
      фыркнул, — ч-что в-вы втёрлись в д-доверие ко в-в-всему Университету, в-вы все там заодно. Я — нет. Я не
      желаю в-в-вас больше в-видеть
      — никогда в ж-жизни — но не имею в-возможности
      в-выгнать. П-поэтому уйду сам.</p>

    <p>(Вот Попельдопель-то обрадуется.)</p>

    <p>— Не переносите свой праведный
      гнев с меня на весь Университет.</p>

    <p>Шухер ещё раз фыркнул, и от этого
      загадочнейшим образом стал будто выше ростом.</p>

    <p>Чтобы снисходить, надо
      приподняться.</p>

    <p>— В-вы — острое заб-б-болевание. Университет — х-хроническое.</p>

    <p>С такой странной для него
      решительностью Шухер направился к выходу.</p>

    <p>— Ваш уход не отменит того, что
      Бровь умерла, — перегородил ему дорогу Дима.</p>

    <p>— Это нев-важно.</p>

    <p>— Неважно? А что тогда важно?</p>

    <p>Шухер попытался выйти, но
      наткнулся на категорическую физическую преграду.</p>

    <p>— В-вы сами не видите? —
      раздражённо бросил он.</p>

    <p>— Даруйте мне зрение.</p>

    <p>— Чт-то,
      если верить в-в-вашим же с-словам, в-вы сгубили мою м-маленькую… — Шухер
      дёрнулся, его лицо заплясало, но он сдержался, — молодую н-наивную
      д-д-девочку, к-которая всей
      душой хотела в-вам помочь, <i>и д-для вас
        это в п-порядке в-вещей.</i> Д-для меня — нет.
      В-выпустите, я хочу найти Юра К-к-карловича. Мне
      нужно п-подать заявление об уходе.</p>

    <p><i>Не в порядке,</i> хотел ответить Дима, но ведь это было бы неправдой.</p>

    <p>Это ведь ресурсы, все так громко
      смеются, когда кому-то не нравится называть людей ресурсами.</p>

    <p>Дима не успел убрать руку из
      дверного проёма, но Шухер уже поднырнул под неё и пошёл слегка нетрезвой
      походкой по коридору. Нетрезвой — потому что только
      что осознал, что потерял самого дорогого человека на свете, хоть и не сказал
      этого вслух.</p>

    <p>И это настолько не вызывало у
      Димы никаких эмоций кроме естественного отражённого раздражения (оба ведь, как
      минимум оба виноваты!), что, наверное, Шухер был прав.</p>

    <p>Всё в порядке.</p>

    <p>Всё в порядке вещей.</p>

    <p>&nbsp;</p>

    <p>— Мы его успокоили, — радостно
      ухмыльнулись Охрович и Краснокаменный, подхватывая Диму под руки и запихивая
      обратно в аудиторию, которую он намеревался покинуть после нескольких печальных
      витков мысли.</p>

    <p>— Уж больно переживал.</p>

    <p>— Это вредно для слабого сердца.</p>

    <p>— Пусть полежит немного,
      отдохнёт, столько потрясений за день — это многовато.</p>

    <p>Шухер был перекинут через плечо
      Краснокаменного (и впоследствии сложен кучкой в углу аудитории).</p>

    <p>— А вы не хуже меня знаете, что
      делать с чужим горем, — мрачно заметил Дима.</p>

    <p>— Горем? Каким горем? — изумились
      Охрович и Краснокаменный.</p>

    <p>— Хочешь сказать, у него что-то
      произошло?</p>

    <p>— Мы-то думали, он сам выбрал
      столь неудачный момент покинуть университетские стены.</p>

    <p>— Хотели ласково пожурить, когда
      очнётся.</p>

    <p>— Не идиотничайте,
      — огрызнулся Дима. — Всё вы прекрасно видите и понимаете.</p>

    <p>Охрович и Краснокаменный
      переглянулись и посмотрели на него свысока.</p>

    <p>Весьма свысока.</p>

    <p>— Знаешь, чем мы от тебя
      отличаемся?</p>

    <p>(Ростом.)</p>

    <p>— Ты видишь, что Шухеру грустно.
      Мы видим, что Шухеру грустно.</p>

    <p>— Но мы видим также и то, что с
      медфака он может направиться в редакцию ближайшей газеты, не говоря уж о
      Бедроградской гэбне.</p>

    <p>— И если Шухер расскажет
      ближайшей газете хотя бы пятую долю того, что тут происходит, грустно станет
      всем.</p>

    <p>— Мы заботимся о благосостоянии
      общественности!</p>

    <p>— И потом, пока он без сознания,
      он не может грустить. Разве это не славно?</p>

    <p>— Ну и что дальше? — Дима
      посмотрел на бледного, без кровинки, и очень бессознательного Шухера, на лице
      которого, впрочем, читалась определённая безмятежность. — Под замок на двадцать
      лет, пока не сдохнет?</p>

    <p>— Нам рассказывали, что ты не
      только хорош собой, но и баснословно сообразителен, — покивали Охрович и
      Краснокаменный.</p>

    <p>— Всё-то схватываешь на лету.</p>

    <p>— Просто как чайка.</p>

    <p>— Вот и глаза такие же умные.</p>

    <p>— Польщён, — Дима вяло подумал,
      что Шухеру стоило бы пощупать пульс, но, с другой стороны, Охрович и
      Краснокаменный умеют рассчитывать силу удара. — Сколько бы вы его под замком ни
      продержали, он продолжит знать то, что знает. И, как только вы его выпустите,
      сможет рассказать. Смысл?</p>

    <p>Охрович и Краснокаменный
      презрительно скривились.</p>

    <p>— Сразу видно, что ты, родной,
      университетов не кончал.</p>

    <p>— Пребываешь вне академического
      дискурса.</p>

    <p>— Иначе понимал бы, что вопросы вроде
      «что он кому сможет и захочет рассказать» являются материалом для дальнейших
      исследований.</p>

    <p>— Мы проведём дополнительную
      работу.</p>

    <p>— Как только нам вернут уровень
      доступа и, соответственно, государственное финансирование.</p>

    <p>— Шухер вон жаловался и злился на
      то, что весь Университет против него, — покачал головой Дима, — и, честное
      слово, смотрю я на вас и не вижу, где же он ошибался.</p>

    <p>— Он и не ошибался.</p>

    <p>— Если ты плюёшь на
      общественность — не удивляйся удару в солнечное сплетение.</p>

    <p>— Ты сам-то
      чем недоволен?</p>

    <p>— Али это такой плевок на
      общественность?</p>

    <p>Дима пожал плечами.</p>

    <p>(Не могут живые люди спорить с
      Охровичем и Краснокаменным, это просто невыполнимо.)</p>

    <p>— Я отчётливо вижу всё мудачество ваших действий, но не могу противопоставить им
      ни грубую силу, ни лучший план. — Дима ещё раз пожал плечами, перевёл глаза с
      Охровича на Краснокаменного и обратно (ничего нового обнаружено не было). — Для
      лиц вашего уровня доступа манера быть затычками ко всем бочкам мира и решать за
      других, наверное, является
      достоинством, хотя я всё равно не понимаю, что вы вообще сегодня на медфаке
      забыли.</p>

    <p>Охрович и Краснокаменный
      расплылись в таких широких улыбках, будто за этим вопросом (ну, высказыванием,
      которое могло бы быть вопросом, хотя не было им, и никто не просил отвечать!)
      на него и пришли:</p>

    <p>— Тебя, родной наш,
      тебя.</p>

    <p>— Видишь ли, обстановка в городе
      нынче неблагонадёжная.</p>

    <p>— Только сегодня утром нам
      пришлось доходчиво объяснить нескольким ретивым молодым людям, что мы думаем об
      их навыке стрельбы.</p>

    <p>— Мы хотели обезглавить трупы и
      повесить их на пиках возле здания Бедроградской гэбни, но руки не дошли отлить
      пики.</p>

    <p>— И потом, пятеро — это как-то
      несолидно. Добьём до десяточка хотя бы.</p>

    <p>— Привезём на телеге прямо на завтрашнюю встречу.</p>

    <p>— Незачем спешить, — ухмыльнулся
      Дима. — На завтрашней встрече назначат послезавтрашнюю,
      дальше — ещё какую-нибудь. Успеете.</p>

    <p>— Дима, ты такой красивый.</p>

    <p>— Не пытайся думать, это тебе не
      к лицу.</p>

    <p>— Ты же не хочешь испортить
      отношения со своими телохранителями?</p>

    <p>— Делать так обычно не слишком-то
      умно.</p>

    <p>— Телохранители, знаешь, и
      передумать могут.</p>

    <p>— Телохранители? — Дима закрыл
      лицо неверящей ладонью. — Нашли кого телохранять.
    </p>

    <p>— Мы-то тоже думаем, что леший бы
      с тобой, сдох бы и сдох, но Гуанако повелел.</p>

    <p>— Если ты сдохнешь,
      он расстроится.</p>

    <p>— И Ройш расстроится, как бы эта
      фраза ни напоминала оксюморон.</p>

    <p>— Ройш — человек противоречий.</p>

    <p>— Шухер вон обвинял тебя в том,
      что ты «втёрся в д-доверие ко в-в-всему
      Университету», и он был прав.</p>

    <p>— Мы, разумеется, подслушивали.</p>

    <p>— Мы следим за тобой.</p>

    <p>— За каждым твоим шагом.</p>

    <p>— <i>Каждым</i>.</p>

    <p>— Нам всегда было интересно, что
      Гуанако в тебе находит.</p>

    <p>— Отлично, друзья, — ядовито
      высказался Дима, —&nbsp;я всегда немного страдал эксгибиционизмом. Надеюсь, эта
      трепетная связь у нас с вами теперь на всю жизнь?</p>

    <p>— До завтрашнего вечера, —
      легкомысленно отозвались Охрович и Краснокаменный.</p>

    <p>— Завтра вечером — последняя
      встреча с Бедроградской гэбней.</p>

    <p>— А после неё меня некому станет телохранять, как я понимаю.</p>

    <p>Дима посмотрел на них ещё раз — дурацким чересчур внимательным взглядом, которым
      смотрят на
      покойников или тяжелобольных, как будто на их лицах можно увидеть какое-то
      особое небесное откровение.</p>

    <p>(Небесное
откровение: Охрович и Краснокаменный крайне
самодовольны, уверены в себе и не в восторге от роли телохранителей.)</p>

    <p>(Заметка на полях: если Димин
      внутренний голос приобретёт ещё хоть чуть большую самостоятельность, можно
      будет смело говорить о множественном расстройстве личности.)</p>

    <p>— Я не сомневаюсь, что вам не
      составляет труда вдвоём уложить пятерых или там пятнадцатерых
      младших служащих, но четыре головы Бедроградской гэбни вас сделают на раз.</p>

    <p>— Это если к моменту встречи они
      не загрызут Гошку за некий инцидент из его прошлого, — беспечно отозвались
      Охрович и Краснокаменный.</p>

    <p>— Или он их, шансы тоже есть.</p>

    <p>— И потом, кто говорит о нас
      двоих?</p>

    <p>— Они же жаждут лицезреть реальную власть, пусть её и получат.</p>

    <p>— Гуанако ж теперь Начальник.</p>

    <p>— Начальник Университета — это
      как Начальник Колошмы, только пока что сравнительно живой и не ёбнутый на
      голову.</p>

    <p>— Ну, не в такой степени, по
      крайней мере.</p>

    <p>— Звание Начальника передаётся
      половым путём, как мы поглядим.</p>

    <p>— Вы вдвоём и Гуанако? — Диме
      хотелось возмутиться, но это, в сущности, были крайне здравые
      кандидатуры. — Взяли бы уж кого-нибудь четвёртого для ровного счёта.</p>

    <p>На него посмотрели как на идиота.</p>

    <p>— Мы и возьмём.</p>

    <p>— Конечно, дословно просьбы
      привести <i>всю</i> реальную власть
      Университета не звучало, но почему бы разок не сделать то, чего от нас хотят, а
      не то, чего формально попросили?</p>

    <p>— Может, именно так всё и
      закончится.</p>

    <p>— Они упадут в обморок и сойдут с
      ума от нашей искренности.</p>

    <p>— Тут-то мы ими и воспользуемся.</p>

    <p>— И кто же будет четвёртым? —
      полюбопытствовал Дима.</p>

    <p>— РЕАЛЬНАЯ ВЛАСТЬ УНИВЕРСИТЕТА, —
      заорали Охрович и Краснокаменный.</p>

    <p>— САМ ПОДУМАЙ
      КТО ЕЙ МОЖЕТ БЫТЬ!</p>

    <p>Дима честно подумал.</p>

    <p>— Есть варианты, — сделал он
      вывод.</p>

    <p>— Так выбери наиболее логичный.</p>

    <p>— Не помогает?</p>

    <p>— Ну
      тогда подумай о том, что более всего <i>реальную
        власть Университета</i> нынче напоминает, вероятно, человек, который в принципе
      придумал все эти игрища про «давайте подкинем Бедроградской гэбне нашу чуму».</p>

    <p>— И за которым
      все повелись.</p>

    <p>— Которого
      Бедроградская гэбня своими трудами заслужила увидеть и обласкать.</p>

    <p>Где-то за окном сбежавшие уже из
      борделя студенты довольно громко что-то обсуждали, но в остальном
      в коридорах медфака было пусто и тихо. Звуки голосов казались немного
      ненастоящими, будто где-то неподалёку играло радио.</p>

    <p>— А, — ответил Дима.</p>
  </>
}

export default chapter29
