import React from "react";
import './BlockQuote.sass'

interface BlockQuoteProps {
  quote: {
    quote: React.ReactChild,
    author: React.ReactChild
  }
}

const BlockQuote = ({ quote }: BlockQuoteProps) => <figure className='BlockQuote'>
  <blockquote>
    {quote.quote}
  </blockquote>
  <figcaption>
    — {quote.author}
  </figcaption>
</figure>

export default BlockQuote