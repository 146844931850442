import React from 'react'
import { IBookPage } from './index'

const chapter07: IBookPage = {
  header: 'header_d4.jpg',
  title: 'Глава 7. Припев (второй раз)',
  prevLink: 'chapter06',
  nextLink: 'chapter08',
  content: <>
    <h2>Университет. Габриэль Евгеньевич</h2>
    <img alt='' src="/images/07_gabriel_evgenievich.jpg" style={{ float: 'left', margin: '0 10px 10px 0' }}/>
    <p>Фаланги всегда врут. Им нет дела
      до простых смертных, даже если смертные мнят, что у них теперь — уровень
      доступа и политика. Какая политика — так просто, карнавал, грызня
      на потеху тем, кто может войти в любой дом и сказать: всё, закончено.</p>
    <p>Или не войти, или не сказать.</p>
    <p>Максим заботливо и неслучайно
      оставил возле своей печатной машинки каких-то бумажек — копии запросов к
      фалангам, несколько страниц неких показаний, бесконечные столбцы имён; Габриэль
      Евгеньевич, разумеется, читать не стал — не смог бы, даже если бы захотел,
      перед глазами кружилось, летело. И потом: если Максим так просит, он попробует
      быть логичным.</p>
    <p>Ничего не знать, не читать
      Максимовых бумажек и сидеть в доме-башне — пусть так; но всё же, всё же —
      неужели они не понимают, что фалангам до всего этого, что бы это ни было, — нет
      дела?</p>
    <p>Глупые и нелепые, как котята.</p>
    <p>И когда-нибудь перегрызут друг
      другу глотки.</p>
    <p>Голову Габриэля Евгеньевича, как
      небо, обложило тучами; скоро, скоро прорвётся и хлынет. Облака — тяжёлые,
      чёрные, будто привязанные к небесному своду тончайшими ниточками — готовы
      обрушиться в любую минуту, и от этого напряжения всё гудит. Гудит и томится
      ожиданием, и остаётся только — ждать, ждать и молить о дожде.</p>
    <p>Всё так же бесконечно стоять у
      окна — глупо, но идти невыносимо; а если и идти, то — куда? Упадёт же, раздавит
      стотонными тучами, поломает бессмысленные полые кости, и останется только рвань,
      рвань и осколки.</p>
    <p>Бесшумный, шершавый, любимый
      ковёр шевелился, шелестел савьюром. Габриэль Евгеньевич нагнулся бы
      —&nbsp;сорвать себе стебель, но голова —&nbsp;тяжёлая, облачная — тончайшей
      ниточкой к потолку привязана, не опустить. Только и остаётся, что далеко-далеко
      протянуть руку за ещё одной самокруткой. От савьюра в
      голове ватно и марлево, но зато хоть чуть глуше этот треклятый звон.</p>
    <p>Самокрутки
      закончились.</p>
    <p>Это страшно: запас —
      далеко-далеко, в спальне, а ног не оторвать, их уже оплетают жадные стебли,
      вяжут узлы. Хочется спать, но известно, что бывает с теми, кто преклоняет
      головы на отравленных полянах; надо просто — не опускаться на сонный ковёр, а
      сделать шаг, и за ним ещё один, и ещё, и так — до спальни. Чудное дело —
      любимая постель под красным балдахином не манит, не зовёт. Зачарован Габриэль
      Евгеньевич савьюровой ядовитой поляной, как есть
      зачарован, хочет вернуться в гостиную, только сперва — отыскать в тумбочке
      заветные из бумаги кручёные палочки, затянуться, выдохнуть бесцветный дым.</p>
    <p>Самокруток
      в тумбочке не было: кончились. Вместо них в дальнем тёмном
    углу отыскались: ключи от Серёжиной квартиры (и зачем хранит до сих пор, да ещё
    и не в коридоре, на подобающем месте, а у постели, в тумбочке, на сердце
    —&nbsp;как вор?); стопка каких-то бессмысленных писем; валокордин (вот ещё
    тоже: ни разу же не помогал, а всё равно стоит); расчёска и, наконец,
      старая пачка сигарет. Смешных, исторических, сделанных
      под оскопистский салон: разноцветная (у каждой —&nbsp;своя) бумага и фильтры аж
      золотые.</p>
    <p>Китч, но смешно.</p>
    <p>Курили их вдвоём с Димой, когда
      стало уж совсем невыносимо знать, что Гуанако умер.</p>
    <p>Он ведь, когда этой весною
      воскрес, вернулся; предложил бросить всё и всех, сбежать. Отвёз в Ирландию,
      мимоходом показывал даже кусочек Северной Шотландии, где служил, говорил, что
      можно остаться вместе — насовсем.</p>
    <p>Дима смолчал и уехал в Столицу,
      Максим просто смолчал.</p>
    <p>Только тогда и стало понятно, что
      не нужен Габриэлю Евгеньевичу живой Гуанако, нужны только его ключи в тумбочке
      и воспоминание о том, как потрескивали, прогорая, цветные бумажные сигареты.</p>
    <p>Внутри самый обычный табак, но
      кажется, конечно, что — с цветочным запахом.</p>
    <p>С пачкой в руках и незажжённой
      бирюзовой сигаретой во рту брёл Габриэль Евгеньевич обратно в гостиную. Почти
      уже на пороге увидел то, чего не должно было быть: крысу.</p>
    <p>Портовую чёрно-белую крысу по
      имени Габриэль Евгеньевич.</p>
    <p>Это ведь тоже, знаете, смешно.</p>
    <p>Габриэль Евгеньевич метался
      взад-вперёд по топкому ковру, будто обступала его вода, и спастись было некуда;
      за ним откуда-то выскочили ещё крысы, и ещё — не живым ковром, но много, очень
      много. И все в панике, и все искали выхода, как будто Порт горит, и надо
      спасаться. Габриэль Евгеньевич отшатнулся назад (с детства боялся крыс; в
      Бедрограде их нет, только Габриэль Евгеньевич и рос не в Бедрограде), хотел
      было закричать, но — уткнулся спиной в чьё-то плечо. Обернулся —&nbsp;Дима:</p>
    <p>— Спокойно, это мои. Попельдопель послал. Лекарство всё-таки
      экспериментальное, надо сперва на ком-то проверять.</p>
    <p>Дима шагнул вперёд, занёс скальпель,
      сделал несколько надрезов на выгибающемся пузырями ковре, и из надрезов
      —&nbsp;с готовностью, с базарной спешкой рванули вверх стебли кровавой твири,
      будто не трава это, а настоящая кровь хлынула из-под пола. Впрочем, настоящая
      кровь и была; плеснула, замарала Димину расстрельную рубашку, чуть сползающую с
      плеч (такую, как когда курили, курили цветные сигареты и старались даже не
      разговаривать, потому что нет у них ничего, только общая боль потери; хотя и
      это — больше, чем у многих). Тот улыбнулся, протянул руки, обнял. Совсем
      маленький ещё, без очков и почти без седины. Стащил с переносицы Габриэля
      Евгеньевича оправу, вышвырнул в траву:</p>
    <p>— Это всё — страшная тайна,
      помнишь? Я уже спрятался, теперь тебе тоже надо. Если найдут и узнают, быть
      беде. А так —&nbsp;мы похожи, как братья.</p>
    <p>Габриэль Евгеньевич достал из
      кармана халата ножницы и стал поспешно и неловко отстригать пряди волос, хотя
      точно знал, что это обещал сделать Максим. Надо припомнить ему, когда вернётся.</p>
    <p>— Так хорошо, — остановил его
      Дима, — теперь стрелять будут в меня. Когда они придут, не кричи, всё равно не
      поверят.</p>
    <p>Они обернулись. Напротив серой
      бетонной стены, с ружьями наизготовку, стояли двое: Гуанако и Соций (один из
      голов Бедроградской гэбни, Габриэль Евгеньевич помнил). Оба в форме Ирландско-Всероссийской
      Армии и в грязи.</p>
    <p>— Сдохните,
      шлюхи кассахские, —&nbsp;выплюнул Соций.</p>
    <p>— Ну чё,
      пли, что ли?</p>
    <p>— Пли, ёба.</p>
    <p>Треснули два выстрела — пустых;
      но, хоть пуль и не было, оба попали в Диму: в печень и в сердце. Он упал
      навзничь, и из глаз его начали прорастать зелёные розы (как те, которые Гуанако
      давным-давно дарил тогда ещё Габриэлю на глазах у всего факультета).</p>
    <p>К стрелявшим
      подошёл третий —&nbsp;Андрей, <i>тот самый</i> Андрей —&nbsp;в форменном гэбенном мундире, с серой тетрадью в руках. Покачал
      головой, пощёлкал языком:</p>
    <p>— Я же сорок раз говорил: в ногу, в
      правую ногу! Не в сердце! В сердце уже было, плохо кончилось.</p>
    <p>— Я не смогу в
    правую, — заныл Гуанако, — в правую вслепую нельзя.</p>
    <p>Глаза его были перемотаны грязной
      марлей.</p>
    <p>— А в левую я и сам могу, —
      ответил Андрей, захлопнул тетрадь и обернулся наконец
      к вжавшемуся в стену Габриэлю Евгеньевичу, — Габриэль, вы ведь понимаете, что
      это всё просто сон? На самом деле расстрел был фальшивый.</p>
    <p>Габриэль Евгеньевич хотел
      ответить, но вспомнил, что его ждут. Он кивнул в извинение и побежал к своей
      повозке — бесколёсной карете, запряжённой парой грифонов, дивных вымышленных
      животных, полуволков-полусов, что стерегут дома от воров и незваных гостей.
      Верхом на одном из них сидело кафедральное революционное чучело (тоже в
      расстрельной рубахе), а рядом, спешившись, стояла девушка с длинными волосами и
      старательно выводила на пустом лице чучела разлетающиеся брови, похожие на
      крылья. Грифоны косились на неё круглыми лунными зенками
      и сердито обрывали клювами цветы — аккуратно, по одному — с черёмухи, к которой
      были привязаны.</p>
    <p>В карете Габриэля Евгеньевича уже
      ждали двое: сам Габриэль Евгеньевич, мёртвый, и ещё один незнакомый человек в
      голубой рубашке. Они прервали игру в карты и помахали руками. Тогда Габриэль
      Евгеньевич понял: если сядет — умрёт. Он покачал головой и бросился бежать, но
      бежать нужно было долго, до самой Пинеги, через пустыню; солнце пекло голову,
      на висках проступил пот, ноги вязли в зыбком песке. Услышал стук, обернулся и
      понял — карета мчится за ним, вот-вот грифоны нагонят и разорвут. Прятаться
      было некуда, и Габриэль Евгеньевич в нелепой надежде бросился прямо на песок
      —&nbsp;вдруг пронесутся мимо. Песок обжёг руки и лицо, на мгновение всё вокруг
      заволокло чёрно-красной пеленой, но потом —&nbsp;схлынула она.</p>
    <p>Над Габриэлем Евгеньевичем стоял
      Максим в рубашке младшего служащего и улыбался. Карета всё ещё неслась следом,
      вот-вот нагонит, и Габриэль Евгеньевич понял, что у него осталось время только
      на один вопрос.</p>
    <p>— Этот сон, это значит… Гуанако нас всех убьёт?</p>
    <p>Максим рассмеялся.</p>
    <p>— Да нет же, глупый. Нас всех
      убьёт Ройш. Ты ведь знаешь, — Максим поднял его с обжигающего песка так резко,
      что зазвенело в голове, — это всё не по-настоящему, только в бумажках. На самом
      деле люди не умирают.</p>
    <p>Габриэль Евгеньевич улыбнулся и
      распрямил спину. В самом деле, как он мог забыть?</p>
    <p>Значит, можно было наконец-то
      посмотреть на солнце. Глаза взорвало белым, выжгло, но зато — только так
      Габриэль Евгеньевич и смог не увидеть, как один из грифонов сминает широкой
      грудью Максима, с разбегу валит его на землю, полосует клювом лицо.</p>
    <p>Только так он и смог не
      почувствовать, как хищные грифоньи когти мягко входят к нему под кожу и —
      разрывают мясо до кости.</p>
  </>
}

export default chapter07
