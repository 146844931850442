import React from 'react'
import { IBookPage } from './index'

const chapter20: IBookPage = {
  header: 'header_d7.jpg',
  title: 'Глава 20. Помоеньки, родненькие',
  prevLink: 'chapter19',
  nextLink: 'chapter21',
  content: <>
    <h2>Университет. Гуанако</h2>

    <img alt='' src="/images/20_guanaco.jpg" style={{ float: 'left', margin: '0 10px 10px 0'}} />
    <p><b><i><u>С остановки «Порт»</u></i></b></p>

    <p>&nbsp;</p>

    <p>Как же вы заебали, братцы.
      Братцы, оставайтесь на подольше, а?</p>

    <p>— Опят’, — сочувственно вздохнул
      Муля Педаль.</p>

    <p>Поворот — и истфаковское крыльцо,
      засиженное не-истфаковскими студентами, скрылось из виду. Служебное портовое
      такси с водителем таврской национальности и пассажиром, смирно залёгшим на
      заднем сиденье, потряслось себе дальше по брусчатке.</p>

    <p>— Опять, они упорные, — Гуанако
      улыбнулся.</p>

    <p>Пока Бедроградская гэбня не сняла
      своих наблюдателей, Университет ещё жив. Потому что <i>они верят,</i> что Университет ещё жив.</p>

    <p>Муля Педаль тоже улыбнулся
      (Гуанако подглядел в панорамное зеркало) — улыбнулся неуверенно, но радостно.
      Тоже просёк:</p>

    <p>— Б’ют — значит, любят? В смысле,
      подсматривают — значит, боятся?</p>

    <p>— Значит.</p>

    <p>— Значит, Максима Аркад’евича не
      они <i>того?</i></p>

    <p>— Не значит.</p>

    <p>Муля, не дави. Тут есть варианты.
      ВАРИАНТЫ, блядь. Они, не они — пойди разберись, что хуже.</p>

    <p>— Но чего им боят’ся, если они и <i>того</i> Максима Аркад’евича?</p>

    <p>А вот это нам и предстоит сейчас
      придумать.</p>

    <p>Вслух Гуанако только неуклюже
      отшутился:</p>

    <p>— Ты таким тоном повторяешь своё
      загадочное «того», как будто подозреваешь что-то неприличное! Неужто ты бы на
      их месте не боялся, если бы сделал <i>с
        Максимом Аркадьевичем</i> что-то неприличное?</p>

    <p>Муля Педаль довольно заржал, но
      такси не разлетелось на части, не затрещало даже от его ржача — ну и какой он
      после этого тавр?</p>

    <p>Тавр-таксист из Порта, коса есть
      — коня нет. Молодой совсем — лет двадцать пять, наверное. Муля Педаль говорил,
      что помнит Гуанако десятилетней давности, Гуанако же Мулю Педаль припоминал
      смутно: как будто мало детишек старшеотрядского возраста подкармливает у себя
      Святотатыч. Пусть даже таврской национальности, у Святотатыча широкий круг
      интересов.</p>

    <p>Видимо, Мулю Педаль в
      старшеотрядском возрасте звали как-то иначе — такую хорошую шутку Гуанако
      запомнил бы обязательно. Второй курс, первый семестр, общий для всех
      специальностей зачёт по истории малых народов, соседствовавших с росами,
      стандартный дополнительный вопрос: таврские имена.</p>

    <p>XII век, тавры-наёмники, отправившиеся
      побеждать
      Империю вместе с росами в надежде на то, что в благодарность росы отъебутся уже
      от Южной Равнины. Для росских военачальников таврские имена были страшной
      хуйнёй, поэтому особо отличившимся таврам давали клички, но, чтоб не путать их
      с благородными прозваниями прославленных росских витязей — с каким-нибудь там
      Удием Твёрдым Мечом или ещё кем похуже, — кто-то додумался обзывать тавров
      росскими словами исключительно женского рода.</p>

    <p>Империя пала, росы даже временно
      отъебались от Южной Равнины, а потому тавры-наёмники возгордились своими новыми
      именами, прибавили их к прежним и стали передавать по наследству — чтобы каждая
      сволочь знала, чей отец, дед, прадед (и далее вглубь веков) отрабатывал право
      на свою землю в чужой войне! Те, чьи предки не отрабатывали, в рез��льтате тоже
      поддались модным веяниям, сами придумали себе родовые клички, и с тех пор
      тавров так и зовут — таврским именем и росской фамилией женского рода,
      непременно вместе. Урхá Чаша, Дыхрá Ночь, Цой Бойня, Хтой Дыба, Хýмбра
      Пытка. Или эти, нынешние фигуранты — Таха Шапка и Бахта Рука.</p>

    <p>И «Муля Педал’».</p>

    <p>У кого-то в Портовой гэбне
      отличное чувство языка.</p>

    <p>— Где тебя выкинут’?</p>

    <p>— Да где хочешь. Давай только для
      верности ещё разок повернём.</p>

    <p>Такси и так уже порядочно
      отъехало от истфака, от университетского борделя, под завязку забитого
      обескровленными студентами, от дороги с медфака на истфак — то есть от всех
      ключевых для вражеского наблюдения точек. Конечно, по уму весь Университетский
      район должен бы быть под колпаком, но (попробуем мыслить здраво) даже у
      Бедроградской гэбни нет такого количества людей, чтобы Университету стоило
      опасаться вуайеристов на каждом шагу. Вот и хуй с ними.</p>

    <p>— Как же ты, падла, это делаешь?
      — озадачился чем-то Муля Педаль. — Крышами ходишь, что л’?</p>

    <p>А, он об этом.</p>

    <p>— И спускаюсь в кафедральное окно
      по верёвке из кос убитых тавров! — Гуанако протянул руку, непочтительно
      потрепал знак национальной принадлежности своего верного таксиста. Муля
      нормальный, не нервный, его косами не обидишь. — Извини, друг, военная тайна.</p>

    <p>Взгляд, брошенный Мулей Педаль
      через зеркало, напомнил Гуанако, что «военная тайна» — не лучший аргумент для
      граждан Всероссийского Соседства, пусть даже и маргинализованных портовой
      жизнью. Войн нет, не должно быть (Европы сожрут заживо), а следовательно,
      представления о войнах в массе своей тоже сомнительные.</p>

    <p>Как объяснить человеку,
      привыкшему к сплошным уровням доступа к информации, которые все и всегда нарушают,
      что такое «военная тайна»?</p>

    <p>Когда Гуанако занесло в армию,
      командир его спецподразделения справился с этой задачей с полпинка, сказал
      что-то вроде: «То, что мы тут не учения проводим, а стреляем по кассахским
      шлюхам боевыми патронами, — это уровни доступа. Если узнают те, кому не
      положено, будет срань, но с этой сранью не нам с тобой разбираться, а вообще
      всем, кто повязан. А то, что у нас на завтрашнем штурме ихней базы в окопе по
      три тряпочных пугала на одного живого снайпера, — это уже никакие не уровни
      доступа, а как раз военная тайна. Кто ненужный взболтнёт — размажут именно нас
      с тобой, прямо завтра и без вариантов. Врубаешься?»</p>

    <p>Командир был хороший человек и
      объяснял всегда по делу. Всем, кто нынче занят блядской эпидемией, неплохо было
      бы с данными терминологическими различиями ознакомиться.</p>

    <p>Потому что чутьё подсказывало:
      информация о блядской эпидемии в городе — это давным-давно военная тайна, а не
      уровни доступа, которые все и всегда нарушают. Для обеих сторон. Узнают — не
      разбираться будут, а размажут и наших, и ваших.</p>

    <p>Прямо завтра и без вариантов.</p>

    <p>Муля Педаль поворчал немного,
      осматривая окрестности на предмет тёмных подворотен.</p>

    <p>— Давай чуть вперёд и налево, там
      дворик есть подходящий, — подсказал Гуанако.</p>

    <p>Хотя в Университетском районе все
      улицы что те дворики. У иногородних от этих улиц глаза на лоб лезут: пойди
      отличи тут одно от другого. Улицы петляют, неожиданно сужаются, заползают под
      арки. Никакого вам архитектурного плана, одно стихийное бедствие.</p>

    <p>— Угу, — кивнул Муля Педаль,
      притормозил, пропуская разленившуюся толстую чайку, которая переходила дорогу
      пешком (чего это она тут делает?), и ни с того ни с сего брякнул: — Гуанако,
      чего б мы сегодня ни устроили, не ночуй в Порту.</p>

    <p>— Чё?</p>

    <p>— Не ночуй. Вчера ещё ничего, а с
      утра такие тёрки были под теплицами, что стрёмно тепер’. Особенно если
      пойдём-таки на дело. Порежут тебя.</p>

    <p>— Ты на нервы-то не капай, а то я
      сам кого нечаянно порежу. И замариную для пущей убедительности, — Гуанако и не
      думал демонстрировать озабоченность.</p>

    <p>На дело, на дело. Может, ещё
      обойдётся.</p>

    <p>Кто-то думает, что озабоченность
      нельзя демонстрировать чужим, — и это даже верная установка. Не абсолютно,
      конечно: чужим иногда полезно поглядеть, как ты трясёшься, дёргаешься, того и
      гляди сорвёшься, наделаешь дел. <i>Своим
        такое показывать нельзя никогда.</i></p>

    <p>Если подыхать — то только с верой
      в лучшее и идиотской лыбой от уха до уха.</p>

    <p>— Теплицы-то ещё стоят?</p>

    <p>— Стоят, грабит’ не решилис’.</p>

    <p>— Ну и чего ссать тогда? —
      Гуанако удовлетворённо пощёлкал языком. — Жудий-то выходил на тёрки?</p>

    <p>— Не.</p>

    <p>Да кто бы сомневался.</p>

    <p>У Портовой гэбни давным-давно
      было заведено чёткое разделение обязанностей.</p>

    <p>Озьма, низкорослый бандюган
      пихтских кровей с железным крюком на поясе — финансист и начальник службы
      безопасности в одном лице. Держит на себе <i>почти</i> всю контрабанду, диктует ценовую политику и вырывает своим крюком куски мяса из
      тех, кто с ней не согласен. Озьма всегда отирается на складах, в доках, у
      менял, на рынках, доступных всем и каждому, и в лавках с чем-нибудь этаким,
      закрытых от посторонних глаз. Ну и ещё там, где пролилось много кровищи.</p>

    <p>Зина, томный красавец в мундире
      гражданского офицера Пассажирского Флота, заведует сферой услуг и досуга.
      Столкнуться с ним можно, соответственно, в борделях, питейных и игорных
      заведениях или в Пассажирском Порту — он встречает и провожает корабли, едва ли
      не платочком машет.</p>

    <p>Святотатыч (леший, как можно описать
      Святотатыча?) — это, в общем-то, человек-информационный центр, человек-внешняя-
      и человек-внутренняя-политика. Он не делает ничего и делает всё сразу — просто
      знает все портовые сплетни. Собирает их и сам же распространяет. Святотатыча
      можно найти везде, если хорошо поискать.</p>

    <p>И только Жудия нельзя найти, не
      стоит искать и не имеет смысла даже пытаться описывать, поскольку видели его
      всё равно очень и очень немногие. Гуанако — видел, Муля Педаль — вроде как тоже
      пару раз, а, например, Максим, имевший за последние годы много общих дел с
      Портовой гэбней, — ни разу.</p>

    <p>Потому что Жудий <i>прячется</i>. Жудий сидит себе под замком
      неведомо где, допускает до себя только проверенных людей и не показывается по
      пустякам. Потому что Жудий — это наркотики. <i>Все
        блядские наркотики блядского Порта.</i></p>

    <p>Он выращивает то, что можно
      вырастить, синтезирует то, что можно синтезировать, и закупает то, что
      вырастить и синтезировать нельзя. А потом перепродает. Во многом через Озьму,
      но знающие люди в курсе, что в обороте наркоты сам Озьма веса не имеет, побежит
      и будет делать то, что скажет ему Жудий.</p>

    <p>Озьма и побежал. Артачился, не
      хотел замораживать оборот твири, но Жудий сказал своё веское «стоп, машина» — и
      всем пришлось заткнуться и выполнять. А уж как там Гуанако выпрашивал у Жудия дать-таки
      эту команду — история умалчивает, и пусть продолжает в том же духе. Выпросил
      ведь.</p>

    <p>Первые дни шуму не поднималось, а
      теперь поди ж ты: крупные заказы валятся вёдрами на голову, пролетают мимо
      Серьёзных Людей ввиду отсутствия у них товара — и Серьёзные Люди хотят
      разбушеваться. Гуанако мысленно хвалил тактику Бедроградской гэбни:
      пронюхали-таки, что Порт помогает Университету, решили поработать с
      общественным мнением в Порту. И грамотно даже работают, обстрел Порта выгодными
      заказами — отличная подрывная деятельность, да только хуй им в рыло.</p>

    <p>Все заказы у них через десятые
      руки, но размотать цепочку реально. Одну Святотатыч размотал уже вчера к
      вечеру, пустил где надо слушок: твирь-то городские шишки просят, хотят и руки
      нагреть, и кого из Серьёзных Людей посадить, и наши каналы накрыть. Кое-как
      помогло, притормозило волнения — портовые ничего не боятся, но куковать за решёткой в государственных (о ужас!)
      учреждениях лишения свободы считается у них крайне, <i>крайне</i> непрестижным.</p>

    <p>Эти самые заказы начали проверять
      получше, но вопрос «где твирь и когда будет?», которого пока что удавалось
      избегать, со вчерашнего дня встал ребром.</p>

    <p>Хуём.</p>

    <p>Грот-мачтой.</p>

    <p>К Жудию Гуанако соваться пока не
      стал, но передал с гонцом записку. Содержание её, конечно, было и так очевидным
      любой портовой крысе, но на всякий случай стоило ещё раз позудеть Жудию в
      шапку.</p>

    <p>«Тебя берут на понт» или ещё
      что-то такое же короткое, но драматичное, было наспех нацарапано на рваной
      бумажке. Жудий любит ощущать свою значимость, пусть порадуется.</p>

    <p>Если утром у теплиц (с кислотными
      кактусами, Жудию нравятся цветочки) были тёрки Серьёзных Людей, но Жудий их
      своим присутствием не почтил, значит, лезть на стенку ещё рано.</p>

    <p>Ну и леший с ним.</p>

    <p>— Чего вам стоит отсыпат’ твири
      под один крупный заказ, — пережидая, пока свалит куда-нибудь подальше
      обнаружившееся неподалёку от нужного дворика такси, с которым не разъедешься на
      такой дороге, прогудел Муля Педаль. — От вас не убудет, да и запросит’ можно
      втридорога по нынешней голодухе.</p>

    <p>Гуанако очень не хотел рассматривать
      вариант, при котором для производства лекарства понадобится <i>вообще
        вся</i> твирь, которую заморозил
      Жудий, но и без того были причины не сорить пока дефицитом.</p>

    <p>— Муля, ты рыбок-головожорок
      видел? — осведомился Гуанако. — Если видел, знаешь: одну рыбку покормить
      нельзя, только всех вместе. Иначе они подкараулят тебя, в неожиданный момент
      выпрыгнут из аквариума и это… Умрут, но покусают, короче. Ты понял меня, Муля?</p>

    <p>Муля понял, убрал свои
      предложения куда подальше, хотя метафора-то была с подъёбкой.</p>

    <p>Если из рыбок-головожорок не
      покормить ни одной, они всё равно будут выпрыгивать на тебя из аквариума,
      просто несколько позже, чем когда их дразнят.</p>

    <p>— С чёрных каналов уже поползло…
      — предпринял Муля Педаль свою последнюю попытку воззвать к чему-то там у
      Гуанако.</p>

    <p>Что-то там не отозвалось, а
      Гуанако только усмехнулся:</p>

    <p>— Нет никаких чёрных каналов. То,
      что так называют, тоже идёт от Жудия. Но ты этого не знаешь, ага?</p>

    <p>Ещё одна чайка (а может быть, та
      же самая, хотя вроде поменьше), приземлилась на козырёк соседнего крыльца. Чего
      это они повылезли из Порта? Не горим же.</p>

    <p>Хуйня какая-то.</p>

    <p>— Взяла же таможня ночью
      какого-то лошка при большом запасе… — Муля Педаль старательно наморщил лоб, но
      ему это не очень помогло. — Откуда запасу взят’ся, если чёрных каналов нет?</p>

    <p>— А ты ещё подумай.</p>

    <p>Таможня — люди гэбни
      Международных Отношений, у которой какой-то там занюханный восьмой уровень
      доступа. Командовать ей (то есть, конечно, спускать ей сверху директивы) может
      практически кто угодно. Всеми страстно любимая гэбня города Бедрограда,
      например. А ещё гэбня города Бедрограда, имея совсем другие мощности и не
      будучи вынуждена делать лекарство от чумы из того, что под ногами валяется,
      может позволить себе оттяпать у местной легальной фармакологии партию твири. И
      слить её по дешёвке случайному человеку, понаглей и понеопытей, а лучше ещё и
      наркоману позависимей, — чтобы он как можно быстрее спалился и подарил таможне
      драгоценный по нынешним временам товар.</p>

    <p>А в результате подобных нехитрых
      комбинаций вставший грот-мачтовым хуём вопрос «ГДЕ БЛЯ ТВИРЬ?» приобретает всё
      более и более пикантное звучание.</p>

    <p>Молодец, гэбня города Бедрограда.</p>

    <p>— Тепер’ чисто, вали давай, — убрал
      от лица выпуск «Вечернего Бедрограда» Муля Педаль.</p>

    <p>Настоящий шпион Муля Педаль,
      наблюдавший за окружающей действительностью через прорези в газете! И где,
      спрашивается, понабрался?</p>

    <p>Гуанако, впрочем, был немногим
      лучше — он же всё это время пролежал плашмя на заднем сиденье, чтоб
      никто-никак-ничего. Задрапировавшись сверху пледиком для надёжности, смех один.</p>

    <p>Перед тем как выскакивать из
      такси на волю, следовало проверить боевую готовность: нож — в ножнах, в
      нажопном кармане — фальшивые документы (настоящих не существует в природе уже
      лет семь как, Гуанако же вообще-совсем-покойник), стебелёк савьюра (покурил — и
      спокоен), моток верёвки, фонарик, буквально-таки подарочный набор отмычек — за
      голенищами сапог, рассованы в строго установленном порядке, чтоб не перепутать.
      Не скурить фонарь, не открыть замок верёвкой.</p>

    <p>А то, знаете ли, случаются иногда
      прецеденты нетривиального использования рабочего инвентаря — например,
      случается лечить чуму скопцами и обещанием Имперской Башни. Ох блядство.</p>

    <p>Гуанако сел, затушил окурок и
      быстро застегнулся на все пуговицы — тельняшкой сейчас лучше не светить вне
      Порта, но и совсем не надевать тоже нельзя, если всё время туда-обратно
      мотаешься. На человека без тельняшки или ещё каких характерных атрибутов в
      Порту всегда косо посматривают, приебаться могут ни за что — ну и кому это
      надо?</p>

    <p>Проблема оборотничества решалась
      просто: внизу тельняшка, сверху плащ, цивильный, смутно напоминающий
      студенческий форменный мундир БГУ им. Набедренных — только крой пораспиздяистее
      и цвет не чёрный. В Порту видят тельняшку, в городе — плащ с поднятым воротом,
      под который легко и быстро убирается завязанный на морской манер хвост. И
      никаких ненужных взглядов.</p>

    <p>— Ну, лешего тебе в жопу, Муля.
      Бывай, — вылез Гуанако из такси, тихо захлопнул дверь и почти уже скрылся под
      аркой, но всё-таки успел услышать, как Муля Педаль бормочет себе под нос:
      «удачи вам, что л’, академические уебаны».</p>

    <p>Не оборачиваясь, Гуанако
      продемонстрировал ему вытянутый в традиционном имперском приветственном жесте
      средний палец.</p>

    <p>Стёкла на дело надо поднимать,
      настоящий шпион.</p>

    <p>&nbsp;</p>

    <p><b><i><u>Через остановку «Город»</u></i></b></p>

    <p>&nbsp;</p>

    <p>Университетский район — хорошее
      место.</p>

    <p>Весь как будто один большой
      запутанный двор, целый район двором наружу.</p>

    <p>Большинство улиц — одно название,
      а на деле дома стоят как попало, лепятся друг к другу самыми разнообразными
      способами, едва ли не на чердаках других домов могут неожиданно вырастать. А
      улица (мощёная тропка) может и сама заводить вдруг на крепкую крышу
      какого-нибудь низенького здания, причудливо огибать каминную трубу и ползти
      вниз по пологому скату, снова расширяясь так, что два такси даже разминутся на
      ней, если повезёт.</p>

    <p>Петерберг был городом на особом
      положении.</p>

    <p>Порт, корабли и контрабанда,
      разбой и разврат, бандиты и иностранцы, а как следствие — Охрана Петерберга,
      кольцо казарм и пропуска в город и из города.</p>

    <p>Порт был большой (для порта), а
      Петерберг был маленький (для города), в общественном транспорте нуждался не
      слишком. Указ из Столицы — лошадей как можно меньше. Охране Петерберга можно и
      нужно, Порту для грузов тоже можно, аристократам побогаче (для увеселений) и
      предприятиям (для развозок) можно со скрипом, а частного извоза чтоб вовсе не
      было.</p>

    <p>Вот так и вышло, что даже теперь
      широких проспектов во всём Старом городе раз-два и обчёлся, улиц, где можно
      организовать хоть какое-то дорожное движение, тоже негусто, а всё нутро
      пешеходное. Строили-то исходя из того, что транспорт не разрешат, ставили дома
      как душа и земельный налог пожелает, а не скучными фасадами в ряд. Там, где
      особняки, мануфактуры и бывшие окраины, ещё попросторней, а вот Университетский
      район — это да.</p>

    <p>Хорошее место Университетский
      район!</p>

    <p>Если от кого-то прячешься —
      хорошее, если кого-то ищешь — плохое: подворотни, закоулки, сквозные проходы в
      неожиданных местах и прочие приятные мелочи. Не знаешь — заблудишься, знаешь —
      ёбнешься от количества вариантов, куда можно свернуть и где срезать. Никакие
      наблюдатели никакой Бедроградской гэбни в Университетском районе не страшны.</p>

    <p>Как любой нормальный
      когда-то-студент-БГУ (если принять за норму и эталон не тех, кто после занятий
      мчится на автопоезде домой готовиться к завтрашнему семинару), Гуанако имел
      свои личные тайные тропы в лабиринте Университетского района. Сперва подъезд,
      чёрный ход из которого мигом приводит в новую локацию, потом через чудом
      сохранившийся дореволюционный ещё садик (ограда неприступная, но со стороны
      магазина с дешёвой водкой есть дыра), потом крюк глухими совсем подворотнями,
      потом внешняя лесенка у красно-серого дома с флюгером (стратегично скрыта
      деревьями). С верхней площадки лесенки всего один шаг на высоте пяти этажей до
      полуразрушенной мансарды соседнего дома (можно зависнуть на перекур), по
      мансарде до следующей крыши — там уже посложнее, надо прыгать, но карниз
      надёжный. Ну а дальше — по пожарной лестнице вниз, и пусть теперь кто-нибудь
      догадается, как далеко ты ушёл от начальной точки пробежки.</p>

    <p>Каждый поворот — очень знакомый,
      ну и что, что годы незнамо где, фальшивые документы, а кривоватую пристройку к
      дому с флюгером перекрасили. Там пили с этим, тут курили с тем, а если пройти
      чуть вперёд — будет легендарная помойка, у которой на последнем курсе
      обнаружился рыдающий от накативших светлых чувств гуанаковский одногруппник.</p>

    <p>На каком-то массовом мероприятии
      одногруппник этот отделился, затерялся и пару часов не появлялся, все подумали
      — ну мало ли, свалил. А к утру он на нетвёрдых ногах вышел из подворотни,
      проигнорировал своих приятелей и помчался к помойке с душераздирающим воплем
      «помоенька, родненькая!». Оказалось, заблудился. Пять лет специалитета прожил в
      Бедрограде, а на последнем курсе выпил вроде всего ничего и вдруг заплутал, всю
      ночь не мог найти ни одного знакомого объекта, пока не подвернулась ему та
      помойка. Хитрая герметичная громадина, размалёванная кем-то из стремлений к
      прекрасному, но надёжная и технологичная, вся из себя по последнему слову
      санитарных норм. Пойди не заметь такую — особенно если все твои беспутные пять
      лет специалитета видела эта помоенька. Родненькая, в общем.</p>

    <p>Гуанако ещё в мае, как вернулся в
      Бедроград и очутился тут неподалёку, сходил проверить, на месте ли она. Много
      чего было на месте, в общем-то, почти всё — даже нездорово фиолетовая
      растительность в окне первого этажа рядом с мастерской по ремонту часов, даже
      надпись «ОТЪЭБИСЬ ОТ МЕНЯ ВЗРОСЛАЯ ЖИЗНЬ» на глухой стене. Только к последней
      прибавилось неожиданно сознательное «метелин умер молодым, хикеракли старым, а
      что сделал для общества ты, хуйло?» и какой-то приятный рисуночек с когтистыми
      лапами — передними птичьими и задними волчьими.</p>

    <p>Шныряя в который уж раз за
      последние дни между всеми этими не пострадавшими от времени деталями пейзажа,
      Гуанако опять подумал: стрёмно.</p>

    <p>Стрёмно, что дыры в оградах за
      десять лет не залатали, а люди, с которыми ты не виделся десять лет, как бы это
      —&nbsp;ну в общем — ёбаный стыд — нормально
      не объяснишь — речевые навыки сломались.</p>

    <p>Ну в общем, ёбаный стыд. И
      нормально это не объяснишь, речевые навыки ломаются, потому что <i>твои
        студенты</i> уже кандидаты наук, Ройш и
      вовсе доктор, а Габриэль батюшку его Евгеньевич, первый дипломник — завкаф
      истории науки и техники.</p>

    <p>Техники и науки. Кафедрой
      заведующий.</p>

    <p>Стыд ёбаный.</p>

    <p>Потому что пока ты неведомо где,
      и зовут тебя никак, и по документам на всех уровнях доступа ты покойник, как-то
      совершенно неважно, сколько тебе на каком-то там самом деле лет (сорок один?
      Чё, правда? А вроде пока ничего не отваливается). Но сунёшься обратно в
      Бедроград, увидишь все эти рожи, повзрослевшие, постаревшие и посерьёзневшие, —
      и хоть давись. Отъэбись от меня, взрослая жизнь. Отъэбись, пожалуйста — Метелин
      умер молодым, а Хикеракли был, конечно, весёлый мужик, но такой ведь на самом
      деле грустный.</p>

    <p>И можно не бояться стать старым
      (хотя кто же тогда захочет с тобой ебаться, а если не ебаться, то жить, в
      общем-то, не так уж и обязательно), но нельзя, просто вот никак невозможно не бояться
      стать старым-и-скучным.</p>

    <p>Хикеракли заезжал пару раз к
      Гуанако на Колошму, поэтому Гуанако знал наверняка, что Хикеракли так и не стал
      старым-и-скучным. То ли очень старался, то ли просто порода такая, запаршиветь
      не может.</p>

    <p>А блядский Метелин в своей
      блядской расстрельной рубахе блядской Резервной Армии висел до самой Колошмы у
      Гуанако над печатной машинкой. В виде канонической расстрельной фотографии из
      любого учебника, не в виде чучела, конечно (и нисколько не стыдно, трепетного
      отношения к блядским кассахам во всём их многообразии стыдиться грешно!). Но
      почему-то не покидало ощущение, что как раз с Метелина, проживи он подольше,
      можно было бы отливать образцовую старость-и-скучность. Повезло — подольше не
      прожил.</p>

    <p>Вся эта муть (в другой редакции,
      но смысл не менялся) затуманила мозг прямо в мае, стоило только вернуться.
      Затуманила до таких былинных подвигов, что это вам не Метелин над печатной
      машинкой, это просто-таки резиновый хэр Ройш в натуральную величину в ванной!</p>

    <p>Но на вопрос «а надо ли было
      возвращаться в Бедроград, если всё оно так сложилось?», есть только один ответ,
      в кои-то веки без вариантов: «Да надо, надо». Потому что ну бля.</p>

    <p>Помоенька, родненькая.</p>

    <p>&nbsp;</p>

    <p>«Знай и люби <i>свой</i> город, лох», — хотел написать Гуанако на стене, но сдержался.</p>

    <p>Третий день подряд сдерживался:
      как только появились толпы наблюдателей от Бедроградской гэбни на истфаковском
      крыльце, так и начал сдерживаться. Если они там себе думают, что могут пасти
      Университет, узнали бы для начала об Университете что-нибудь интересное.</p>

    <p>Гуанако огляделся, шмыгнул за
      угол, пробежался метров пятьдесят, упёрся носом в стену — тупик, огляделся ещё
      раз, сдвинул крышку люка, аккуратно опустил ноги вниз, нащупал выступ и резво
      скользнул под землю.</p>

    <p>Выступ небольшой, но когда
      знаешь, что он там есть, хотя бы не ёбнешься со всего маху в сточные воды и
      прочее дерьмище, логичным образом скрывающееся во тьме за крышкой люка. Крышку,
      кстати, надо за собой закрыть. Изнутри, со щербатого выступа это не самый
      простой акробатический номер, но чего не сделаешь ради конспирации.</p>

    <p>Знай и люби свой город, кому
      говорят.</p>

    <p>Про то, что Йыха Йихин,
      основатель Петербержской Исторической Академии (оскопистского борделя для людей
      с большими запросами), имел какие-то непростые отношения с канализациями,
      слышали многие. Немногие с перепоя на младших курсах брались это проверять.</p>

    <p>Соваться во все подряд люки в
      Университетском районе было когда-то любимой гуанаковской формой проведения
      досуга. Взрослые люди не тратят время на такую хуйню, взрослые люди не верят в
      подземные ходы под истфаком — да взрослые люди вообще взрослеют решительно зря.</p>

    <p>После ряда неудач,
      сопровождавшихся ушибами средней тяжести, Гуанако посетила-таки светлая мысль,
      что йихинский тайный ход не может начинаться в случайном месте — Йихин слишком
      ценил своё чувство юмора, чтобы пожертвовать им в угоду секретности.</p>

    <p>Не-случайных мест в голову
      приходило не так и много, и среди них был Пёсий тупик — каменная кишка на
      некотором расстоянии от здания бывшей Академии, куда йихинские лохматые псы по
      слухам загоняли не то воров, не то просто тех, чья рожа не приглянулась
      хозяину. Псы были охотничьи, хорошо натасканные и, говорят, могли держать
      построение, вынуждавшее жертву повернуть за правильный угол.</p>

    <p>А за правильным углом
      обнаруживался узкий проход, стены без окон и, собственно, тупик.</p>

    <p>Конечно же, в Пёсьем тупике было
      целых три люка — и, конечно же, в одном из трёх начинался подземный ход. Два
      других гуанаковская жопа иногда вспоминала до сих пор.</p>

    <p>Задвинув наконец ебучую крышку,
      Гуанако отдышался, извлёк из сапога фонарик и сделал пару осторожных шагов по выступу,
      не разгибаясь. Йихин был крайне невысок ростом и искренне полагал, что это
      проблема не его, а всего остального человечества. Мерзкий тип!</p>

    <p>Дыра в стене и открывающийся за
      ней наклонный туннель тоже были низкими, неудобными и успели поднадоесть Гуанако
      за последние дни, но совершенно детская радость «я знаю, как тайно пройти на
      истфак, а вы — нет» искупала все сложности.</p>

    <p>Вся эта подземная конспиративная
      беготня вообще была потрясающе детской, и оттого особо притягательной: трудно
      заморачиваться над большой политикой, финансовой трубой и (ах, ах!) всякими там
      потенциальными гибелями университетских фигурантов, когда ползаешь заповедными
      тропами с фонариком в зубах. Освежает.</p>

    <p>Конечно же, сразу после этой
      мысли Гуанако поскользнулся, не удержал равновесие на наклонной (и предательски
      мокрой!) плоскости и пропахал кувырком расстояние до ближайшей ровной площадки.
      Ощущения полной детскости происходящего только усилились: оставалось теперь
      случайно наткнуться тут на связанного, раненого и издыхающего (но обязательно
      пока не издохшего!) Габриэля Евгеньевича — и всё, чуму в Бедрограде можно смело
      поставить на полку с другими авантюрными романами, рассчитанными на читателей
      младшеотрядского возраста.</p>

    <p>Никакого Габриэля Евгеньевича в
      обозримом пространстве, увы, не было. Чего у Габриэля Евгеньевича не отнять —
      так это несвоевременности всех его действий и всех действий над ним: словить по
      морде леший знает сколько раз подряд, чтобы в ответственный момент чуму
      попутали с сотрясением мозга (если у него, конечно, чума), кромсать вены в
      сортире ближайшей забегаловки во время защиты диплома, и так далее, и так
      далее.</p>

    <p>Воспоминания о кромсаниях вен
      тянули за собой много чего невесёлого лично для Гуанако, хотя сам факт
      существования Габриэля Евгеньевича со всеми его заёбами однозначно входил в
      десятку самых весёлых вещей, которые Гуанако вообще знал. Наряду с правдой о
      том, что хотел сказать Набедренных своей идеологией переворачивания деревьев
      (узнано путём заговаривания зубов Хикеракли) или, например, с существованием легендарного ушедшего под землю
      Вилонского Хуя, существованием в нём живых и настоящих скопцов и существованием
      всех их вместе аккурат под блядской Колошмой (узнано путём попадания в Хуй). А
      вы-то думали.</p>

    <p>Оказавшись на развилке аж пяти
      туннелей, Гуанако для верности пошарил фонарным лучом по потолку, нашёл там
      собственноручно поставленный крестик (или букву «х», что более реалистично) и
      устремился в тот туннель, который располагался через один направо от
      помеченного крестиком (над ним могла бы быть написана буква «й»: конец слова
      «хуй», начало слова «Йихин»). Этот туннель вёл на истфак, остальные змеились
      между канализационных сооружений йихинских времён и проложены были, судя по
      всему, как раз благодаря оным сооружениям.</p>

    <p>Байку о том, что чернокнижники
      подарили Йихину волшебную хуйню, которая превращает отходы человеческой
      жизнедеятельности обратно в питьевую воду, вероятнее всего, придумал он сам.
      Надо же было как-то объяснить, как Академия (по совместительству бордель, по
      совместительству псарня, по совместительству место проживания Йихина) обходится
      без централизованной городской подачи воды, которая к тому же была тогда ещё
      очень и очень несовершенной. С городскими властями у Йихина была большая и
      светлая любовь, то и дело оборачивавшаяся обменом пакостями (может,
      Университетская гэбня, занимающая помещения бывшей Академии, просто попала под
      проклятие места?). Йихин твёрдо верил, что город принадлежит ему: он же заставил
      весь город переться от оскопистов, он же прославил город в Европах своей
      Академией, он же просто так давал деньги на всякое там городское строительство.
      Да он даже прибрал к рукам Охрану Петерберга — тренировал для них своих собак,
      делал им скидки на своих мальчиков. Йихин просто не мог позволить себе зависеть
      от городских властей в вопросе какой-то там воды!</p>

    <p>Решение оказалось простым,
      финансово затратным и очень йихинским. Чернокнижников (а попросту — толковых
      учёных с неограниченным кругом интересов) он нанял, чтобы разобраться с
      грунтовыми водами. Потом едва ли не сам начертил проект подземной плотины,
      поднимающий уровень этих грунтовых вод, и подземных же водохранилищ. Чтобы не
      привлекать внимания к своим манипуляциям, пожертвовал кучу денег на надземное
      строительство в окрестностях, инициировал там рабочий бардак и под шумок
      спокойно занялся своими водохранилищными делами. Поэтому теперь, прогуливаясь
      под Университетским районом тайными ходами, можно натолкнуться на громадные
      залы (с колоннами! Йихин питал к ним необъяснимую симпатию), в разной степени
      заполненные водой. Гуанако мог поклясться, что там даже рыбы плавают.
      Нормальные подземные рыбы, всё путем. Не чайки же!</p>

    <p>Путь на истфак лежал через один
      такой зал, и туннель временно становился длиннющим балконом под самым потолком
      водохранилища. Конечно же, перил у этого балкона предусмотреть не потрудились,
      что прямо сейчас было ой как некстати: бесконечные дожди повлияли-таки на
      уровень воды, и оная вода забралась совсем высоко, закрыла собой дорогу. Как
      оказалось — по колено всего закрыла, но балкон был узкий, а фонарик —
      недостаточно мощный, поэтому Гуанако двигался вперёд медленно, по стеночке.
      Утонуть он не опасался (ещё чего), но вот припереться на кафедру мокрым
      насквозь — очень даже. Не просить же, в конце концов, на смену что-нибудь из
      антикварного гардероба революционного чучела. Всё равно ведь не дадут.</p>

    <p>Революционное чучело, появившееся
      уже тогда, когда на кафедре стала заправлять гэбня, Гуанако одобрял и даже
      больше того — искренне верил Охровичу и Краснокаменному, что конкретный лик
      чучела предвещает конкретному дню конкретные события.</p>

    <p>В понедельник был Метелин, и
      полились реки студенческой крови. То есть полились они не в понедельник, но
      именно тогда стартовала операция по их добыче из окружающей среды.</p>

    <p>Во вторник — Набедренных, сулящий
      масштабы, идеологию и перевороты. И перевороты случились, но тогда никто не
      придал им значения: подумаешь, неизвестно, где ночевала
      девочка-с-диктофоном-без-диктофона. Подумаешь, известно, где ночевал Максим, и
      ночевал он не на квартире Габриэля Евгеньевича. Казалось бы, сущая ерунда. Зато
      теперь неизвестна как судьба девочки-критически-важного-свидетеля, так и что
      там у Максима с Габриэлем Евгеньевичем, у кого из них чума и как так вышло.</p>

    <p>В среду чучело нарядилось
      Мальвиным — спокойным, обстоятельным человеком, у которого всегда всё под
      контролем. Вот среда в Университете (не считая ночной вылазки Ройша), и прошла
      себе спокойно, обстоятельно и почти под контролем.</p>

    <p>Вчерашний же Золотце предвещал
      что-то непонятное. Не то сумасшедшие планы по улучшению финансового положения
      (это он раздобыл технологию алхимических печей для воспроизводства людей — просто
      заработать хотел), не то предпосылки к значимому и фатальному выстрелу (это он
      когда-то научил Метелина очень прилично стрелять, вот тот и выстрелил в своё
      время). Не то шпиона среди поваров: Золотце ведь был при жизни настоящим
      золотцем — очаровательным, несерьёзным, трогательным, как будто бы вечным
      ребёнком посреди большой политики. На такого и не подумаешь, ага. Вся большая
      политика начинается с детей.</p>

    <p>Только к чему всё это, пока
      неясно.</p>

    <p>Тем не менее, Гуанако очень хотел
      узнать, на что сегодня похоже кафедральное чучело. Хотя бы потому, что мокрые
      ноги мёрзли, а тащить в руках инвентарь, обычно хранящийся в сапогах
      (савьюровый стебель, верёвка, отмычки — ничего не проебал?), было неудобно.
      Поскорей бы к кафедральному чучелу.</p>

    <p>Размышления о знаках,
      пророчествах и прочей хуйне предсказуемо (о да) возвращали мысли обратно к
      Габриэлю Евгеньевичу.</p>

    <p>Вот уж с кого станется подохнуть
      от чумы, когда прямо под боком, в родном Университете, вовсю гонят лекарство.</p>

    <p>Мысли о Габриэле Евгеньевиче были
      все как на подбор неуютные. Гуанако всю жизнь посмеивался над ним, ржал как
      таврский конь над его пафосом и драматизмом, но стоило тому после майской
      Ирландии заявить: «Всё, наигрались, оставим это», — пошёл вешаться.</p>

    <p>Честное слово, правда-правда.</p>

    <p>И повесился бы, но (честное
      слово, правда-правда) верёвка оборвалась.</p>

    <p>И это не шуточки, это суровая,
      бля, реальность.</p>

    <p>Габриэль Евгеньевич сказал «всё»
      — и Гуанако пошёл вешаться, все слышали?</p>

    <p>То есть оно, конечно же, гораздо
      сложнее было, но с фактической точки зрения — именно так. И вы после этого всё
      ещё доверяете фактам? Ну-ну.</p>

    <p>Поминая недобрым словом
      переполненное водохранилище и хлюпая сапогами, Гуанако преодолел ещё парочку
      развилок.</p>

    <p>Сегодня встреча гэбен.</p>

    <p>Максим так и не объявился, но
      встреча гэбен сегодня.</p>

    <p>И если она каким-то чудесным
      образом таки состоится (как не дать ей состояться, Гуанако уже придумал, но
      лучше бы не прибегать к подобным методам), <i>если
        вдруг</i> Бедроградскую гэбню удастся склонить к сотрудничеству и совместному сокрытию
      от фаланг и Бюро Патентов эпидемии в городе, то оную эпидемию можно будет
      списать на водохранилища, например.</p>

    <p>Не было никакой
      девочки-с-диктофоном, вирусолога Тахи Шапки и обоюдного желания гэбен друг
      друга наебать. Были водохранилища, зараза в грунтовых водах и антисанитария
      выше по течению. Звучит подозрительно, но объединёнными с Бедроградской гэбней
      усилиями можно и не такое доказать, можно подчистить улики и разбросать где
      надо ложные. Фаланги сами не разберутся, искусственного или естественного
      происхождения вирус, но и к Медицинской гэбне не побегут.</p>

    <p>Подземные водохранилища по
      несколько переосмысленному йихинскому принципу (и уже без колонн) понастроили
      после Революции по всему городу (и как он, спрашивается, не провалился нахуй?).
      А эти бедроградско-гэбенные навороченные фильтры, системы возращения очищенной
      воды из верхнего отсека канализационного отстойника в водопровод,
      дополнительные резервуары и прочие новшества легко демонтируются, сантехники
      уже разобрались, что там к чему. Грунтовые воды связаны с областными реками, а
      в глухой деревне в реку может попасть что угодно. Чума, ну мало ли.
      Зафиксировали и быстро остановили её победное шествие. То, что очистительная
      аппаратура не справилась, — это, знаете ли, не промах, это повод задуматься о
      финансировании и тайнах природы, которая тоже не стоит на месте, развивается
      себе в ногу с общественным прогрессом.</p>

    <p>Эпидемии — природный регулятор
      плотности живых организмов на квадратный метр, это вам любой младшекурсник
      Попельдопеля скажет. Понимаете, чё творится-то?</p>

    <p>В общем, выходы есть всегда. Как
      до них добираться — другой вопрос.</p>

    <p>Гуанако до выхода из мрачных
      подземелий оставалось всего ничего. Начинался ход в Пёсьем тупике, куда
      йихинские гончие загоняли неудачников, а заканчивался, не поверите, на истфаке,
      то есть в бывшей Академии (борделе, псарне и далее по списку).</p>

    <p>Выводил прямо в бывшую пёсью
      кормушку — потому что никакие разумные доводы не могли принудить этого самого
      Йихина поступиться своим чувством юмора.</p>

    <p>&nbsp;</p>

    <p><b><i><u>До остановки «Университет»</u></i></b></p>

    <p>&nbsp;</p>

    <p>— По крайней мере, когда остатки
      Бедрограда вымрут от чумы или пропадут в неизвестном направлении, мы с тобой
      многоопытно выживем и поимеем восхитительный шанс оставить летопись потомкам, —
      сказал Дима.</p>

    <p>«ЖОПА 1883», — сказал Димин
      галстук. Гуанако мысленно согласился с галстуком.</p>

    <p>Галстук был
      трогательно-салатовый, буквы же и цифры, появившиеся на нём вчера вечером —
      грязно-красные, почти бурые (цвета засохших кровавых пятен, именно что). Такими
      вот почти бурыми нитками — прочными, но достаточно мягкими для того, чтобы не
      оставлять царапин на стеблях, — обычно и перевязывают пучки твири в Порту.
      Гуанако этот грязно-красный цвет ни с чем не перепутает — наперевязывался.</p>

    <p>Дима вчера сказал, что сим
      безапелляционным высказыванием галстук облагородил Попельдопель. Дежурил у
      какой-то там аппаратуры, ждал магического превращения студенческой крови в
      лекарство, не знал уже, чем занять руки и голову, — вот и накинулся на первый
      плохо лежащий объект. Когда закончил высказывание, сам удивился — мол, не думал
      даже, что знает, как иголку держать, которая не в шприце.</p>

    <p>Гуанако так и пялился с самого
      прихода на кафедру на эти чуть кривоватые стежки, всё представлял, как среди
      лабораторных склянок сидит себе Попельдопель и вышивает — и не мог прекратить
      ухмыляться.</p>

    <p>Чума в городе, она же жопа-1883.</p>

    <p>Или это не про чуму, а про Диму?</p>

    <p>Гуанако заухмылялся в два раза
      глупее: пока в голову продолжают лезть безвкусные пошлые шутки, всё нормально.
      Вот когда перестанут — можно и за вышивание хвататься.</p>

    <p>— Летопись потомкам? —
      автоматически переспросил только что вернувшийся Ларий, разрушив уединение.</p>

    <p>Попельдопеля с Охровичем и
      Краснокаменным Ларий просто вызвонил из борделя («скоро будут, там опять кто-то
      едва не помер»), а за Ройшем пришлось побегать по факультету — тот с утра ещё
      засел в хранилище библиотеки и не подавал признаков жизни. Гуанако не нравилась
      стратегия Ройша — пусть бы лучше вышивал, ну что же он так скучно психует.</p>

    <p>— Ага. Вышьем крестиком на коже
      павших студентов всю правду о нынешних событиях, — отозвался Дима, и у Гуанако
      свело челюсть. Потому что он знал, что сейчас скажет Ларий, точно скажет,
      скажет-скажет, не сдержится, ну-сколько-можно-надоело-ведь-давно!</p>

    <p>Ларий, конечно же, не сдержался и
      даже малость сымпровизировал:</p>

    <p>—&nbsp;Историография — декоративно-прикладное искусство?</p>

    <p>Гуанако тоже не сдержался —
      побился со всей доступной ему убедительностью головой об стол.</p>

    <p>Вот почему всегда так? Сделаешь
      что-нибудь дурацкое или скажешь — и всё, именно этим тебя и запомнят. Повторят,
      просклоняют на все лады столько раз, сколько нужно, чтобы изначальный смысл
      сделанного-сказанного стёрся подчистую, а осталась только нелепая и
      претенциозная формула.</p>

    <p>Тысячу лет назад, когда началась
      вся эта кутерьма с идеологией и пришедшей следом широкой известностью, Гуанако
      решил воспользоваться моментом и написать несколько <i>действительно</i> политических статей о <i>действительно</i> важных вещах (ну а кто, простите, без греха?). Важные
      вещи в основном касались малых народов, отношения к малым народам, проблемам
      малых народов из-за оного отношения и прочей благотворительности.</p>

    <p>Ясен хуй, была там и статья про
      таврский вопрос. Нормальная, кстати, статья — до сих пор не стыдно: с
      отрезанием кос, диким хлебом, лошадиным дерьмом и геометрической эсхатологией.
      И — этой — ёбаной — хуйнёй — про — историографию!</p>

    <p>Статью пережёвывали, где только
      могли (как же — ТАВРСКИЙ ВОПРОС!), а потому самым известным, самым цитируемым
      изречением покойного профессора Гуанако на веки вечные стала ёбаная хуйня про
      историографию. На обороте каждого тома блядского посмертного ПСС напечатали,
      суки. Да шрифтом пожирнее, чтоб никто не ушёл непросветлённым.</p>

    <p>Как там было-то, в оригинальном
      источнике? Гуанако и сейчас мог
      вспомнить, поднапрягшись: «оправдание при помощи данных историографии имеет все
      шансы с течением времени обратиться обвинением, ведь историография (в известной
      степени) является скорее искусством, нежели наукой».</p>

    <p>Историография — искусство, а не
      наука (сказал профессор Гуанако, все слышали?).</p>

    <p>И ещё разок, для профилактики:
      историография — искусство, так какого лешего мы ей так безоглядно доверяем?
      Искусство субъективно, все дела. Что захотел (запомнил, посчитал нужным,
      стратегически верным, политически выгодным) — то и написал в летописи.</p>

    <p>Не верьте летописям, их пишут те,
      кто остался жив.</p>

    <p>А у живых — свои интересы.</p>

    <p>Банальная, <i>банальнейшая</i> вообще-то мысль. При-ми-тив-на-я. Для граждан
      Всероссийского Соседства и вовсе должна бы лежать на поверхности.</p>

    <p>Потому, видать, и затаскали.</p>

    <p>Покойный-профессор-Гуанако был,
      наверное, до тошноты мудрым человеком, если на обложке каждого тома его
      блядского ПСС напечатали такое глубокое изречение.</p>

    <p>Живой-неизвестно-кто-Гуанако,
      утомившись биться головой об стол, одарил помянувшего изречение Лария очень
      тяжёлым взглядом.</p>

    <p>Ларий вился над чайником,
      открывал поочерёдно свои ароматные склянки, позвякивал бутылками (кто сейчас
      будет пить чай без градуса?). Ларий молодец, он делает что может, а когда
      ничего не может — делает чай с градусом. Это правильно.</p>

    <p>— В Порту следов никаких, да? —
      уточнил Ларий.</p>

    <p>Знает ведь: были бы следы (любые,
      хоть самые призрачные) — на кафедру тут же позвонили бы, но всё равно уточняет.
      Всем бы гэбенным головам принудительно проходить секретарскую практику, она
      приучает к точности. Оставаться всегда в курсе, кто где был и что делал, —
      единственный способ ничего не проебать.</p>

    <p>Увы, накладки всё равно неизбежны
      — поди останься в курсе, куда подевался человек, если он уязвлённо выбегает с
      кафедры и бросает трубку. Вот кого б посадить за секретарский стол на пару
      месяцев — чтоб неповадно было.</p>

    <p>Только б найти его сначала.</p>

    <p>— На всех входах-выходах из Порта
      с субботы торчат люди, которых предупредили, что надо быть внимательными, —
      начал отчитываться Гуанако. — Не-портовых и просто сомнительных пускают только
      после занудных расспросов, чтобы не прохлопать ушами каких-нибудь сюрпризов от
      Бедроградской гэбни. Мы всё утро со Святотатычем бегали по пьянчугам,
      стратегично дремлющим у самых тайных дырок в заборах. Даже там Максим не
      пролезал. Вроде бы.</p>

    <p>Ларий кивнул, плеснул в свою
      чашку ещё твиревой настойки и задумчиво пробормотал:</p>

    <p>— Максим с субботы вертелся тут и
      на Революционном, до квартиры Габриэля Евгеньевича доезжал глубокой ночью, со
      вторника на среду ночевал у себя, потом тоже. Судя по анализам, над которыми
      вчера ломал голову Юр Карлович, инфекция попала в водопровод дома Габриэля
      Евгеньевича в воскресенье или в понедельник, но точно не в субботу. С
      понедельника Максим то и дело прикладывался к твиревой настойке, пил больше
      всех — нервы, — Ларий уставился в чашку с какой-то совсем уж пронзительной
      надеждой. — Всего три дня возможного контакта с вирусом, два из них — на
      твиревой настойке. Дима, он ведь мог, ну мог же <i>не заразиться?</i></p>

    <p>Дима скривился.</p>

    <p>Он ещё ночью говорил Гуанако, что
      с гипотетическим заражением Максима полная жопа, потому что и Максим, и Ларий,
      и Охрович и Краснокаменный — они ж из этих-блядских-рождённых-в-51-м. Вроде как
      были экспериментальным материалом какой-то лаборатории, которая всех тут
      опосредованно, но крупно подставила (и не один раз).</p>

    <p>Гуанако вот нисколько не верил,
      что без лаборатории и опытов по уровню агрессии не случилось бы контрреволюционного
      движения (ну смешно же), но верил, что без лаборатории всем было бы проще.</p>

    <p>— Вас четверых уже наверняка
      заманало бороться с предрассудками в свой адрес, так что, чтобы все были
      довольны, вот ещё немного: я в гормональной системе Максима не копался и не
      особо жажду. И как вы четверо должны реагировать на чуму, даже и без твиревой
      настойки — понятия не имею, — развёл руками Дима. — Может, у вас и есть
      повышенная устойчивость, с настойкой — наверняка есть. И у Габриэля Евгеньевича
      может быть повышенная устойчивость, не берёт же его трава, он же весь из себя
      такой сложный. Так что вполне возможно, что они оба просто решили закономерно
      плюнуть на дела университетские, свалить в дальние края, выстроить себе там
      цитадель и упиваться иммунитетом, — последнее прозвучало уже почти зло. —
      Отвечая на изначальный вопрос: да, Максим мог не заразиться. Количество
      Максимов в зоне досягаемости от этого не увеличивается.</p>

    <p>Это называется «шерсть на
      загривке встала». Ещё чуть-чуть, и Дима загрызёт Лария только за то, что Ларий
      не злится (вслух?) на Максима.</p>

    <p>Гуанако инстинктивно положил руку
      на этот самый загривок, едва «шшш» не сказал.</p>

    <p>Дима зыркнул недовольно, зафырчал
      не хуже вечного кафедрального чайника. Оскорбился: его не надо успокаивать, он
      очень спокойный, прям пиздец какой спокойный и совершенно никого не загрызёт,
      даже не собирался.</p>

    <p>Руку с загривка не скинул — и
      хорошо. Гуанако в который уж раз пожалел, что великий человек Йыха Йихин
      оставил после себя и Историческую Академию, и подземные водохранилища, и
      традицию оскопизма, и много чего ещё (хорошего и не очень). Лучше бы оставил
      самое главное — хоть как-нибудь зафиксированную собственную методику
      дрессировки, в которой, говорят, он был большой специалист.</p>

    <p>Кафедральная дверь спасительно
      скрипнула, пропуская внутрь Попельдопеля.</p>

    <p>У Попельдопеля всегда много слов
      и ещё больше дел, вот и пусть развеивает тучи.</p>

    <p>— Эти ваши непуганые детки совсем
      одурели! — с порога заголосил тот. — Невозможно работать, не-воз-мож-но. Вчера,
      значит, какой-то дурак вписался своей безмозглой головой в косяк и окочурился,
      а курятник так до сих пор и стоит на ушах. <i>Вчера</i> это было, давно пора адаптироваться, переварить шок и забыть к лешему! Но нет,
      они там ревут, дёргаются, с каждым недомоганием донимают моих дежурных: «мало
      ли что». Мало ли что! Рук вот мало, а всяких «что» — много.</p>

    <p>Гуанако всей душой любил
      Попельдопеля.</p>

    <p>Со студенческих лет, с модуля по
      истории медицины: помнится, Попельдопель читал что-то там об анатомических
      опытах британских чернокнижников (всего-то XV век, а они почти вплотную подошли к принципу работы этих самых
      алхимических печей, из которых теперь всё население Всероссийского Соседства
      появляется на свет). Читал себе, читал и снова позабыл, что не с медфаком имеет
      дело, — пустился в детали. Не смаковал
      совершенно, но очень подробно излагал, что кому отрезали-пришивали, куда чего и
      при какой температуре заливали, ну и так далее. Как рецептом любимого пирога
      делился — с бесконечным «вы только представьте!», с восторженным «надо же было
      додуматься!». Один хрупкий мальчик с гуанаковского курса хлопнулся в обморок, а
      у Попельдопеля <i>такое</i> искреннее
      недоумение на лице нарисовалось, которое Гуанако уже никогда не забудет.</p>

    <p>Гуанако непроизвольно улыбнулся
      Попельдопелю:</p>

    <p>— Ну а чего ты полез в курятник?
      Ты же сам знаешь: они не медики, им стрёмно.</p>

    <p>— У меня дежурные взвыли от них!
      Да и динамику антител проще посмотреть на месте, чем на медфак кровь тащить,
      посыльных не оберёшься, — потряс головой Попельдопель, но голосил уже
      помиролюбивей.</p>

    <p>— Ну и кто мне вчера всю шапку
      прозудел, что наши ресурсы надо холить и лелеять? — дружелюбно возмутился
      Гуанако.</p>

    <p>Димин загривок на слове «ресурсы»
      отдёрнулся-таки.</p>

    <p>Гуанако медленно опустил
      покинутую ладонь на спинку стула.</p>

    <p>Блядство.</p>

    <p>Вытатуировать, что ли, на
      каком-нибудь достаточно убедительном участке тела телегу про то, что люди —
      тоже ресурс, что «ресурс» — всего лишь термин, а не манифестация цинизма, что
      нечего было прогуливать на третьем курсе общую теорию производства, и так
      далее, и так далее, и так далее.</p>

    <p>Попельдопель тем временем
      отхлебнул подсунутого Ларием чая пополам с твиревой настойкой, отдышался и
      подобрел.</p>

    <p>— Зато они хоть жрут, —
      удовлетворённо прикурил он папиросу с терпким запахом (опять запасы Лария). —
      Спасибо, Гуанако. Без тебя б не жрали.</p>

    <p>— Да какое нахуй «спасибо». Что
      велено, то и сделано.</p>

    <p>— Не прибедняйся, видел я, как им
      обед готовят. У самого слюни потекли.</p>

    <p>Гуанако ещё раз пожал плечами.</p>

    <p>Когда бордель готовили к вселению
      ресурсов, он пошёл спрашивать Попельдопеля, что, собственно, борделю нужно. Всё
      равно ведь всё везти опять из Порта, а значит, даже диета студентов — и та
      лично гуанаковская головная боль.</p>

    <p>Попельдопель заржал тогда: какая
      диета, мол, о полезной пище речи вообще не идёт, обескровленных студентов после
      всех перенесённых тягот надо будет ещё умудриться заставить жрать хоть что-то.
      Аппетита, мол, в их состоянии быть не может, а всех глюкозой через капельницу
      кормить до восстановления — это лучше сразу убиться, тут не то что у
      полноценных медиков, тут даже у медфаковских младшекурсников найдутся дела
      поважнее.</p>

    <p>Во имя сокращения объёма лишней
      работы для медиков Попельдопель постановил: студентов полагается не полезными
      продуктами добивать, а всячески развращать изысканнейшей кулинарией. Мол,
      обескровленные не обескровленные — а глазами и ушами жрать чтоб захотели. Мол,
      желание жрать — это не только суровая физиологическая необходимость, это и
      психологическое явление в том числе. А психологические явления отлично
      управляемы. Мол, устрой им, Гуанако, два грузовика шоколада (обязательно!) и
      сплошную шрапнель с анчоусами (кильку с перловкой?).</p>

    <p>Гуанако устроил.</p>

    <p>Максим вчера утром в ужасе взирал
      на подвозимые к борделю индокитайские актинии слабой соли, южнокарлатскую икру
      пустынных рыб, франко-саксонские национальные лягушачьи торты, турко-греческие
      национальные овечьи сыры с пряностями, латиноамериканские национальные нектары
      тропических лиан и прочую чепуху. Взирал и негодовал: «Мы и так должны Порту
      целое состояние, неужели нельзя было поскромнее?»</p>

    <p>Нельзя.</p>

    <p>Гуанако тоже совался несколько
      раз в лазарет, видел студентов и безо всякой медицинской подготовки врубался:
      прав Попельдопель, ничего они жрать не будут, кроме шрапнели с анчоусами. А что
      до долгов Порту, то с учётом ситуации, сложившейся на рынке твири, актинии
      слабой соли погоды уже не сделают. Без толку Максим возмущался.</p>

    <p>Впрочем, долго не провозмущался —
      погудел с утра, а днём, как известно, уже пропал.</p>

    <p>Наверное, терпеть не может
      актинии слабой соли.</p>

    <p>— …Нет, я всё понимаю, — отверг
      Попельдопель какую-то Димину оправдательную речь, которую Гуанако упустил. —
      Стресс, да. Два грузовика с шоколадом им на что? Скопцы им на что? Не знаю,
      переодень Ройша в женское платье и пусти погулять по курятнику! Если не
      поможет, я положу их всех под транквилизаторы. Извини, Дима, но так работать
      невозможно.</p>

    <p>Ах да, пикантная подробность: за
      студентов в борделе вообще и их бодрость духа в частности взялся отвечать,
      конечно же, Дима. Для бодрости духа есть ещё Охрович и Краснокаменный, но <i>отвечать</i> за студентов, конечно же,
      должен кто-то похожий на медика. И кто-то похожий на медика, конечно же,
      собственную бодрость духа от этого стремительно теряет.</p>

    <p>Гуанако покачал головой:</p>

    <p>— Ройш в платье может быть
      расценен как политическая акция.</p>

    <p>— Попробуем Ройша без платья? — в
      тон ответил Дима. — Студента часто сравнивают с собакой —&nbsp;мол, всё
      понимает, а сказать не может. Ну вот они и сейчас ведут себя как собаки.
      Большие, усатые и лицемерные, которые кладут тебе голову на колени и тяжко
      вздыхают, клянча печенье. Не должно им сегодня быть так плохо, как они малюют.
      Может, пойти жестоким путём и задать сочинять эссе про Вилонский Хуй? И мозги
      займут, и подготовка к экспедиции.</p>

    <p>Гуанако снова вернулся к мысли о
      ненаписанных йихинских трудах по методике дрессировки. Всем бы пригодились.</p>

    <p>Дима ведь изо всех сил понимает
      запертых в борделе студентов (лучше б не понимал, и так загонялся полночи из-за
      этой случайной смерти об дверной косяк), но и Попельдопеля тоже понимает. Тоже
      изо всех сил.</p>

    <p>— Сергей Корнеевич, — ухватился
      за подброшенную идею Ларий, — накидайте для письменных работ хороших
      дискуссионных вопросов, а?</p>

    <p>Гуанако («Сергей Корнеевич»,
      брр!) малость оторопел. Ну взрослые люди, ну сколько лет на истфаке извилины
      гнут! Ларий, конечно, вроде как просто секретарь, но есть ведь преподаватели
      (скольких, не знающих ни о какой чуме, запихнули в исследовательскую группу для
      экспедиции?). Есть Охрович и Краснокаменный — они отличные специалисты, как бы
      ни выёбывались. Есть Ройш — он (так, между прочим) древний мир и читает.</p>

    <p>Но «накидать хороших вопросов»
      просят Гуанако.</p>

    <p>Ему несложно, времени и сил у
      него это не отнимет, но как-то стрёмно вообще-то.</p>

    <p>Стрёмно признавать, но (видимо)
      бывают связи такого рода, в которые один раз вляпался — и всё, так и живи.</p>

    <p>Нет, это Гуанако сейчас даже не
      про всякие порочные связи со всякими габриэлями евгеньевичами, хотя и в них,
      конечно, не без того.</p>

    <p>Это Гуанако сейчас про
      архетипические вертикали учитель-ученик или, например, тюремщик-заключённый
      (совсем другая история, но). Они не всегда срабатывают, они срабатывают,
      наверное, один раз на миллион — но Гуанако, леший еби, умудрился собрать в
      своей биографии все разы со всех окрестных миллионов.</p>

    <p>И хуй с ними, с дискуссионными вопросами
      для эссе, не в них подстава. Подстава в том, что вся Университетская гэбня
      смотрит в рот Гуанако и свято верит, что Гуанако («Сергей Корнеевич») всё
      может, всё знает, а чего не знает, то сообразит на месте. Потому что блядь да,
      учитель-ученик.</p>

    <p>Ларий не скрывает, что смотрит в
      рот и свято верит. По Охровичу и Краснокаменному так и не скажешь, но вчера,
      когда пропал гипотетически зачумлённый Максим, стало ясно — да, смотрят, да,
      верят.</p>

    <p>И даже Максим сутки назад смотрел
      и верил, хоть и страшно не хотел терять весомость и самостоятельность в своих
      глазах. Оттого и сбежал? Возможно, да только ну нахуй такие фантазии.</p>

    <p>Кто ж знал сорок восемь лет назад, что
      некие студенты (совершенно потерянные, трогательно-злобные) из некоего выпуска
      (печально известного контрреволюционным движением) так легко приручаемы.</p>

    <p>Йыха Йихин, почто ж ты оставил
      потомков без своих гениальных методик дрессировки?</p>

    <p>— Обеспечьте меня бумагой с
      карандашиком, Ларий Валерьевич, — покорно вздохнул Гуанако. — Щас накидаю. И
      это, подбросьте им соответствующий роман Габриэля Евгеньевича. Пусть
      приобщаются к прекрасному — прекрасному уже всё равно, если у него чума.</p>

    <p>— Точно! — Ларий мгновенно
      кинулся к книжным полкам. — Как же мы сами не сообразили? Он же писал про Хуй,
      он же Габриэль Евгеньевич, студенты его художественную писанину с руками
      оторвут.</p>

    <p>— Ну хоть посмертно принесёт
      пользу общему делу, — мрачно, но удовлетворённо поддакнул Попельдопель.</p>

    <p>Гуанако хмыкнул.</p>

    <p>Габриэль Евгеньевич (был?)
      писатель. Габриэль Евгеньевич в далёкой юности даже, кажись, с семестр посещал
      занятия в ПТУ Поэтов и Литераторов, но быстро забил на это неправедное дело.
      Если б не писательство Габриэля Евгеньевича, не завязалось бы у них с Гуанако
      столь прочных порочных связей, пару раз трахнулись бы — и дело с концом.</p>

    <p>Гуанако познакомился с Габриэлем
      Евгеньевичем на экзамене. Не своём, чужом — Гуанако той зимой досрочно защитил
      кандидатскую и в весеннем семестре начал полноценно преподавать, но нагрузка
      была ещё плёвая, вот и соглашался подсобить всем, кто бы ни попросил. Заглянул
      в летнюю сессию за методичкой к одному приятелю, а тот взвыл: весь вечер уже
      сижу, все сдали, последний придурок остался, всё не идёт отвечать. В общем,
      приятель этот сбежал домой, расписавшись в ведомости, а послушать последнего
      придурка и как угодно его оценить попросил Гуанако.</p>

    <p>Гуанако к придурку присмотрелся,
      зафиксировал бледность, замученность и отсутствующий взгляд, а ещё тонну
      макулатуры на столе. Думал — ну, результаты старательной подготовки к ответу,
      подхватил с собой и свалил в курилку, чтоб придурок остался один, расслабился и
      перестал психовать.</p>

    <p>В курилке Гуанако натурально чуть
      не умер, подавившись дымом и хохотом.</p>

    <p>Макулатура оказалась не
      экзаменационной, а художественной. Рукописью романа она оказалась. А роман
      оказался, простите за выражение, о духовных исканиях Набедренных времён
      образования Революционного Комитета.</p>

    <p>Короче говоря, к концу своего
      четвёртого курса Габриэль Евгеньевич закончил эпическое полотно про
      Набедренных, который, мол, не Революции хотел и не идеологии переворачивания
      деревьев, а донести до широких народных масс <i>истину скопнического учения</i>.</p>

    <p>Первый идеолог Всероссийского
      Соседства и блядские скопники!</p>

    <p>Публика в экстазе.</p>

    <p>А самое крутое заключалось в том,
      что полотно хоть и было сплошным искусством, а не наукой, но Гуанако ещё в
      курилке с одного взгляда разглядел, что материалов к полотну перекопана хуева
      туча. И (как бы это сказать, чтоб не святотатствовать?) при помощи определённых
      логических манипуляций на этой хуевой туче материалов действительно
      представлялось возможным доказать, что так оно всё и было у Набедренных с
      учением скопников.</p>

    <p>Когда Гуанако вернулся в
      аудиторию к юному Габриэлю Евгеньевичу, ни о каком экзамене и речи уже быть не
      могло — только о роли скопников в деле Революции! В ведомость пошла какая-то
      там четвёрка, а Габриэль Евгеньевич в следующем году пошёл писать диплом к
      Гуанако. Про это самое, ага. Гуанако же его сдуру на том экзамене убедил, что
      романы — хуйня, а вот такое провокационное исследование, выполненное притом по
      всем правилам академически хорошего тона, — это да, это было бы охуительно.
      Габриэль Евгеньевич за лето созрел и припёрся с самыми серьёзными намерениями
      прямо к Гуанако, когда тот уже и думать забыл обо всяких там скопниках.</p>

    <p>Так Гуанако получил своего
      первого дипломника, репутацию совратителя студентов и очень, очень много
      головной боли на всю оставшуюся жизнь.</p>

    <p>Так Габриэль Евгеньевич бросил
      писать романы, по крайней мере — бросил показывать написанное кому бы то ни
      было. А ведь до Набедренных и скопников его даже издавали (даже нормальные
      издательства). Вот эту книжку, экземпляры которой сейчас спешно собирает по
      всем шкафам Ларий, Габриэлю Евгеньевичу заказали за приличные деньги, когда он
      был всего-то второкурсником. И на кафедре потом за курсовик засчитали, почему
      бы и нет — добротная же книжка вышла.</p>

    <p>Задача от издательства —
      беллетризировать глубоко научные сведения об определённом историческом периоде.
      Чтоб было исторически достоверно, но с интригой, мистикой, духовностью и
      подходящим к случаю языком. Мистики, духовности и языка (здесь самое место
      безвкусной пошлой шутке про язык) у Габриэля Евгеньевича всегда было хоть
      отбавляй. Деньги свои он отработал честно, забабахал исторический роман с
      действием, развивающимся параллельно в четырёх временах (и ни в одном времени
      особо не облажался ни со стилистикой, ни с деталями).</p>

    <p>А объединяла все четыре времени в
      тексте она, родимая, — загадка Падения Хуя.</p>

    <p>Вот и пусть студенты почитают,
      мозги займут. Там и по делу много чего полезного есть, и традиционных
      габриэль-евгеньевических соплей тоже достаточно. Гуанако подозревал, что
      Габриэля Евгеньевича за сопли и выбрали из кучи претендентов на приличные
      деньги, он же как раз тогда начал со всякими малыми формами печататься в этой,
      как её — «Литературе Нового Бедрограда». Хороший был журнал, Гуанако так до
      скандального закрытия из-за плагиата в него вяленую рыбу и заворачивал — всё
      время кто-нибудь особо культурный приносил почитать.</p>

    <p>Какая-то очень простая и очень
      очевидная недомысль зашевелилась у Гуанако в мозгу.</p>

    <p>«Литература Нового Бедрограда»,
      вчерашние вытряхнутые с полок номера на полу в квартире Габриэля Евгеньевича,
      он-то их не для вяленой рыбы держал, а для соплей. Сопли, сопли, традиционные
      габриэль-евгеньевические сопли, «Литература Нового Бедрограда», номера на полу,
      очередные всем прекрасно известные сопли, ну блядь, ну, ну —</p>

    <p>— Читал я этот самый
      габриэль-евгеньевичевский роман про Хуй, — в тишине брякнул Дима, — там же соль
      не в исторических деталях, там всё время кто-нибудь с кем-нибудь не трахается,
      плачет от обиды и умирает. Или трахается, плачет от стыда и умирает. Или просто
      плачет от бренности бытия и умирает. И кругом сплошные открытые финалы, потому
      что непонятно, где плачут и умирают на самом деле, а где в мыслях, мечтах и
      прочих кошмарных снах, — Дима вдруг улыбнулся самому себе и стал в два раза
      громче. — Знаете, что? Пусть студенты в качестве эссе пишут продолжение. Ну
      типа что там на самом деле вышло, или как это воспринималось с другой стороны,
      или что потом с героями стало. Или альтернативное повествование — как
      кто-нибудь с кем-нибудь потрахался и неожиданно не заплакал. Или про Хуй в ещё
      каком-нибудь времени — хоть в будущем. В общем, зануды могут написать
      исторический обзор, остальные — хорошую порнографию. И весело, и трудоёмко, и
      на все вкусы, и художественная мускулатура ещё никому не мешала, — Дима
      задумался. — Отдельно одарю того, кто напишет убедительное произведение о том,
      как я давеча этот самый Хуй под Колошмой нашёл.</p>

    <p>Ещё в начале сей прочувствованной
      речи гуанаковская недомысль (простая и очевидная, ну такая же очевидная!)
      сорвалась с крючка и ухнула в неведомые психические глубины. Гуанако потряс
      головой, но восстановлению недологической недоцепочки это не слишком помогло.
      Леший.</p>

    <p>— Насколько убедительное? — на
      автомате переспросил Гуанако. — Думаешь, чьё-то больное воображение сможет
      дотянуть до эпического размаха реальных событий?</p>

    <p>Недомысль, кажется, была потеряна
      навсегда.</p>

    <p>— Вот и узнаем, есть ли в рядах
      наших студентов истинные провидцы.</p>

    <p>— Если провидцы внезапно смогут
      прозреть морально-этическую составляющую нынешних событий, так и знай, мне
      будет неловко, — очень честно ляпнул зачем-то Гуанако. — Даже стыдно.</p>

    <p>Дима бросил на него выразительный
      взгляд из-под не менее выразительно нахмуренных бровей, но всё-таки придвинулся
      чуть поближе. Совсем уж припадать не стал (не время и не место), но Гуанако и
      так понимал, что припал бы обязательно, если б время и место позволяли.</p>

    <p>Стало спокойно и почти не стыдно.</p>

    <p>Что ещё не означает, что майские
      подвиги не будут ещё долго терзать по ночам гуанаковскую совесть.</p>

    <p>Дверь угрожающе распахнулась — и,
      кажется, всё-таки с ноги (с двух ног). Значит, не Ройш, хотя пора бы и ему
      явиться уже наконец.</p>

    <p>Поговорить с Ройшем, поговорить
      уже наконец с Ройшем. Срочно.</p>

    <p>— Его надо покарать!</p>

    <p>— Наказать, отстегать и поставить
      на место!</p>

    <p>— Поставить в угол на чечевицу.
      Его место в углу, а не в библиотеке.</p>

    <p>— Ладно бы он просто манкировал
      приглашением на экстренный слёт штаба.</p>

    <p>— Мы бы могли ему это простить
      после нескольких розог.</p>

    <p>— Но он укрыл за неприступными
      стенами библиотеки не только себя, но и усики!</p>

    <p>— Кафедральные революционные
      французские усики Золотца!</p>

    <p>— Это расстрельное дело, какие
      тут розги. Он подрывает государственные устои.</p>

    <p>— Что мы будем делать, если Ройш
      решит на старости лет податься в контрреволюцию?</p>

    <p>— Бежать в Афстралию в одной
      рубашке, как Максим, который узнал тайну Ройша первым. Максим нашёлся? —
      поздоровались (будем считать) Охрович и Краснокаменный.</p>

    <p>Ответил им Ларий, так и не подняв
      глаз от аскетичной обложки романа Габриэля Евгеньевича про Хуй:</p>

    <p>— В Порту — не нашёлся.</p>

    <p>Охрович и Краснокаменный
      одновременно заломили руки.</p>

    <p>— И в Бедрограде не нашёлся!</p>

    <p>— Не то чтобы мы обладали
      достаточной для поисков Максима властью в Бедрограде.</p>

    <p>— Но мы сделали всё, что было в
      наших силах.</p>

    <p>— Никакая власть не может
      сравниться с нашими силами.</p>

    <p>— Особенно когда при нас
      табельные розги.</p>

    <p>— На которых мы готовы
      поклясться, что Максим бежал из Бедрограда в Афстралию.</p>

    <p>— Но мы питали надежду, что он
      воспользовался услугами Порта.</p>

    <p>— Он не настолько суров, чтобы
      бежать в Афстралию при помощи ног.</p>

    <p>Охрович и Краснокаменный
      пристально уставились на Гуанако как на ответственного за поиски Максима в
      Порту. Гуанако только сокрушенно покачал головой, подтверждая слова Лария.
      Охрович и Краснокаменный на это продемонстрировали пластический этюд «Габриэль
      Евгеньевич в печали». Попельдопель хмыкнул и тоже уставился на Гуанако:</p>

    <p>— У них — то есть у нас, то есть
      у нашей гэбни — должен быть какой-то выход. Перенести эту несчастную встречу,
      прийти без Максима…</p>

    <p>Гуанако ещё немного помолчал как
      идиот. </p>

    <p>Идиот с идиотским планом действий.</p>

    <p>— Это исключено, — отрезал Ларий.
      — Экстренная встреча, созванная по причине чрезвычайных обстоятельств, должна
      состояться в течение суток с момента подтверждения запроса. И будет считаться
      состоявшейся только при наличии на ней обеих гэбен в полном составе.</p>

    <p>— Сколько там до истечения суток?
      — осведомился несгибаемо оптимистичный Попельдопель. — Ведь хватит же ещё
      времени, чтобы пристрелить кого-нибудь из Бедроградской гэбни. Я правильно
      понимаю, что тогда они тоже не смогут явиться?</p>

    <p>— Нам определённо импонирует ваш
      ход мысли!</p>

    <p>— Садитесь в гэбню вместо
      Максима! — оживились Охрович и Краснокаменный.</p>

    <p>Они бы вылили на Попельдопеля ещё
      много восторгов, но Ларий слишком громко и убедительно опустил чайник на стол.</p>

    <p>— Это моя вина, — гораздо тише
      чайника высказался он. — Я не должен был отправлять запрос так рано — до того,
      как Максим дал согласие. Он был в ярости. И он прав.</p>

    <p>Все как-то очень нехорошо
      заткнулись.</p>

    <p>Ларий, конечно, отправил запрос,
      но инициировал-то встречу Ройш. Ройш во всём виноват? А если бы ещё в субботу
      девочка-без-диктофона потрудилась нажимать кнопки поточнее, все бы вообще жили
      припеваючи! Девочка виновата?</p>

    <p>Гуанако поморщился.</p>

    <p>Всё это гнилые разборки: у кого в
      Университете, спрашивается, нет своей доли вины в сложившейся ситуации? Даже
      Попельдопель, не совавшийся в политику, — и тот отличился, проебал чуму
      (возможно) у Габриэля Евгеньевича. Ну и что, что всего через пару часов после
      того, как услышал о ней впервые. Ну и что, что стадия была (если была) ранняя,
      что сотрясение мозга тоже, скорее всего, было. Попельдопель-то всё равно сейчас
      постоянно косится в сторону завкафского кабинета, хоть и полон радужных надежд
      на то, что оно всё как-нибудь устаканится.</p>

    <p>И сидят теперь все, пересчитывают
      молча свои грехи перед заранее похороненным Максимом.</p>

    <p>Блядство, невыносимое блядство,
      которое невозможно терпеть.</p>

    <p>— Во всем виноват я, — очень
      бодро и очень радостно провозгласил Гуанако. — Я придумал Университетскую
      гэбню!</p>

    <p>Блядство, именно что.</p>

    <p>— Зачем? — вылетело у
      Попельдопеля. — В смысле, я не это имел в виду, в смысле, глупый вообще-то
      вопрос, извини, я…</p>

    <p>Гуанако едва не расхохотался.</p>

    <p>— Да нормальный вопрос, —
      отмахнулся он. — Только поздновато уже такими вещами интересоваться. Предлагаю
      считать, что я просто поехал крышей на блядской Колошме.</p>

    <p>Так что там говорил покойный
      профессор Гуанако про историографию?</p>

    <p>Что она (в известной степени)
      является скорее искусством, нежели наукой?</p>

    <p>Хуйню интеллигентскую он говорил,
      вот что. Потому что главное в историографии — кто из очевидцев дольше проживёт.
      Кто проживёт — тот и прав.</p>

    <p>Университетскую гэбню придумал не
      Гуанако, а Гуанако и Хикеракли — живой (тогда) член Революционного Комитета со
      вторым уровнем доступа к информации, решивший навестить некоего безымянного
      заключённого после того, как последний «развалил Колошму». У Хикеракли с
      Колошмой своя история, вот ностальгия в жопе и заиграла, видимо.</p>

    <p>Ностальгия Хикеракли принесла
      Гуанако приятного собеседника (о да, лакать самогон и задавать дурацкие вопросы
      о Революции!), печатную машинку Начальника Колошмы в камере (коснуться
      рефлекторно перстней из её выломанных кнопок) и постоянный приток алкоголя и
      сигарет через охранников впоследствии (почти пригодилось во время вспышки
      степной чумы).</p>

    <p>О том, что ностальгия Хикеракли
      принесла Университету гэбню, Гуанако обычно предпочитал не вспоминать.
      Изначальный план был не таков, разговор вообще не об этом шёл, кто ж знал, как
      оно в результате получится —</p>

    <p>И ещё много разных несостыковочек
      на любой вкус, цвет и размер.</p>

    <p>А в результате — ну да,
      Университетская гэбня.</p>

    <p>Возвращаясь к вопросу
      историографии, в которой Хикеракли понимал побольше многих (столько
      противоречащих друг другу мемуаров о Революции за всю жизнь накропал!),
      остаётся только напомнить, что прав тот, кто прожил дольше.</p>

    <p>Поэтому — извиняй, мёртвый
      Хикеракли. Что правда, а что нет в истории учреждения гэбни Университета,
      самолично решает теперь несколько менее мёртвый Гуанако.</p>

    <p>А он ничего никому рассказывать
      не хочет.</p>

    <p>— Я в ситуациях нехватки меня на
      важных мероприятиях всегда говорил, что заболел, — подал голос Дима. — Или
      проспал. И всегда все верили. Давайте вы скажете, что проспали?</p>

    <p>Гэбня Университета в составе
      Лария, Охровича и Краснокаменного, сиротливо сгруппировавшаяся у секретарского
      стола (синхронизация, неуютно без четвёртого?), смерила Диму крайне тоскливыми взглядами.</p>

    <p>Даже Охрович и Краснокаменный не
      желали извергать традиционного фонтана комментариев по поводу и без, а это уже
      точно никуда не годится.</p>

    <p>Придётся обойтись без
      предварительной юридической консультации Ройша.</p>

    <p>— Бля, — Гуанако для храбрости
      отхлебнул чаю с твиревой настойкой. — Кончайте страдать.</p>

    <p>Надежда, мгновенно нарисовавшаяся
      на лицах всех присутствующих, только злила: «Сергей Корнеевич всё может, всё
      знает», леший еби.</p>

    <p>— Если — <i>если</i> — мы не сочиним в самое ближайшее время лучших вариантов, худший
      вариант у нас имеется, — негромко продолжил он. — Утром я нашёл в Порту людей,
      согласных от своего имени поучаствовать в террористической акции, которая не
      позволит Бедроградской гэбне отвлечься сегодня на экстренную встречу по поводу
      чрезвычайных обстоятельств, поскольку у них самих приключатся <i>дополнительные
        и неожиданные</i> чрезвычайные обстоятельства, — Гуанако ещё раз задумался, как комично всё это
      звучит. — Я готов спешно организовать подрыв десятилетнего юбилея Первого
      Большого Переворота.</p>

    <p>Охрович и Краснокаменный оголтело
      зааплодировали.</p>

    <p>Гуанако выдохнул и заржал.</p>

    <p>— Честное слово.</p>

    <p>— Да здравствует кольцевая
      композиция, — прищёлкнул языком Дима.</p>

    <p>Гуанако всю ночь терпел и не
      делился с ним запасным планом (надо было сначала всё устроить, а потом уже трепаться),
      но очень надеялся (имеет же Гуанако право хоть изредка на что-то надеяться
      сам?), что Дима оценит.</p>

    <p>Как бы там ни было <i>на самом деле, </i>официальное обвинение, с
      которым он десять лет назад попал на Колошму, гласило: Гуанако С. К. (он же
      заключённый №66563) идеологически руководил успешно пресечённой попыткой
      подрыва Первого Большого Переворота.</p>

    <p>Тот факт, что десять лет назад
      никакого Гуанако С. К. вообще-то там и близко не стояло, делает сегодняшнюю
      радость осведомлённых лиц особенно бурной.</p>

    <p>— Ты ненормальный, — с искренним
      облегчением констатировал Попельдопель. — А успеешь? Времени всего ничего, а
      там, наверное, серьёзная охрана и прочие сложности…</p>

    <p>— Всё готово, — Гуанако махнул
      рукой, — а где не готово, там сымпровизируем. Цель-то — не взорвать юбилейные
      сооружения вместе с деревом, а устроить <i>угрозу
        взрыва</i>. Шумную, скандальную и требующую от городских властей
      незамедлительной реакции. Уж с этим я как-нибудь справлюсь. Люди уже сидят по
      такси и ждут звонка.</p>

    <p>И будем верить, что не все из них
      сидят по такси с прорезями в газете, как давеча Муля Педаль.</p>

    <p>— Сергей Корнеевич, — схватился
      за твиревую настойку Ларий, — но это же… нет, подождите! Вы сказали, что нашли
      в Порту тех, кто готов совершить теракт от своего имени. То есть некто из Порта
      полностью возьмёт на себя ответственность за акцию. По всей видимости, чтобы
      отвести подозрения в злонамеренном срыве экстренной встречи гэбен от
      Университета, так? Вы уверены в этих людях? Вы согласовали это с Портовой
      гэбней? Они же потом не оберутся проблем. А мы и так не знаем, как с ними
      расплачиваться за уже оказанную поддержку, — Ларий гипнотизировал взглядом
      телефон. — Хотя бы Святотатыч в курсе?</p>

    <p>Ещё как в курсе.</p>

    <p>Гуанако в который раз мысленно
      восхитился секретарской привычкой к точности (дотошности), а вслух ответил
      только:</p>

    <p>— Нормально всё. И будет
      нормально, я обещаю.</p>

    <p>Поднял глаза к потолку, ещё раз
      удостоверился в сегодняшней личине революционного чучела: с момента прибытия
      Гуанако на кафедру ничего не изменилось, под потолком по-прежнему болтался
      Твирин. Страшный человек Твирин, выросший за одну ночь в казармах Охраны
      Петерберга из домашнего мальчика Ивина. Страшный человек, ставивший самые
      жёсткие ультиматумы, проливавший реки крови, переругавшийся со всеми по очереди
      членами Революционного Комитета, глава Временного Расстрельного Комитета, сам
      тот ещё террорист вообще-то.</p>

    <p>Брр.</p>

    <p>У кафедрального Твирина опять
      расстрельная рубаха (та же, что была на Метелине, или их таки несколько?),
      шинель Охраны Петерберга (с оторванными знаками отличия), обрез (антикварный
      обрез, нацеленный на дверь!), брови вразлёт (не поленились же наклеить) и
      удивительного рыжего цвета волосы (и как нашли парик?).</p>

    <p>Хикеракли на Колошме рассказывал
      Гуанако, что в точности такого цвета была твиревая настойка в самых дорогущих
      трактирах дореволюционного Петерберга. Мол, очищали серебром, мол, такой
      настойки больше не делают.</p>

    <p>Любой отрядский ребёнок в курсе,
      что большинство псевдонимов Революционного Комитета — заслуга остроумия
      Хикеракли.</p>

    <p>Почему-то сопливое сочетание этих
      двух фактов затмевало сейчас для Гуанако все прочие варианты того, что может
      предвещать сегодняшний лик революционного чучела.</p>

    <p>— Прошлые ситуации, в которых ты
      обещал счастливый финал и светлое будущее, заканчивались <i>разнообразно</i>, — нервно огрызнулся Дима.</p>

    <p>— Разнообразие — залог как
      эволюции, так и прогресса, — Гуанако не удержался, всё-таки потрепал его
      лохматую башку. — Я вынужден покинуть вас, господа. Попробую нарушить
      затворничество Ройша в библиотеке, мне и моему идиотскому плану нужен его
      острый бюрократический глаз. И да, никто ещё ничего не взрывает. Часа полтора
      на подбор лучшего варианта у нас есть.</p>

    <p>Гуанако ретировался с кафедры
      побыстрей, но Ларий всё равно успел пробормотать своё неуместно сентиментальное
      «спасибо, Сергей Корнеевич, как бы мы без вас».</p>

    <p>Как-как — самостоятельней.</p>

    <p>И (возможно) с Максимом.</p>

    <p>&nbsp;</p>

    <p>В пустом коридоре Гуанако нагнали
      Охрович и Краснокаменный.</p>

    <p>— Сергей Корнеевич!</p>

    <p>— Вы же не любите, когда к вам
      так обращаются?</p>

    <p>— Тем более на вы!</p>

    <p>— Так вот: Сергей Корнеевич!</p>

    <p>— Вы бы не могли уделить нам
      минуту своего бесценного внимания?</p>

    <p>— Мы имеем к вам дело личного
      характера.</p>

    <p>— <i>Вашего</i> личного характера, поэтому мы не стали обсуждать его при
      всех.</p>

    <p>— Мы милостивы, потому что это
      вам, а не нам, бывает неловко от публичного обсуждения подобных вопросов.</p>

    <p>— Вы должны быть благодарны нам
      за нашу милость.</p>

    <p>— И ещё кое за что. Заранее
      благодарны.</p>

    <p>— И вообще благодарны.</p>

    <p>— Вам же больше нравится, когда
      благодарны <i>вы</i>, а не <i>вам</i>.</p>

    <p>— Так вот, можете совершенно
      бесплатно быть благодарны нам до конца своих дней.</p>

    <p>— Для сохранения душевного
      равновесия.</p>

    <p>— Вы готовы нас выслушать?</p>

    <p>— И быть нам благодарны? —
      сделали они паузу для ответа. Видимо, за неё тоже следует быть благодарным.</p>

    <p>— Я весь ваш, — смиренно отдался
      произволу судьбы Гуанако.</p>

    <p>Охрович и Краснокаменный, конечно
      же, остроумно пошутили в ответ:</p>

    <p>— Если бы не чрезвычайные
      обстоятельства, вы бы горько пожалели об этих словах.</p>

    <p>— Мы давно нуждаемся в покорном
      рабе для действительно важных дел.</p>

    <p>— Мы не хотим упустить
      возможность заполучить столь именитого раба.</p>

    <p>— Мы бы именовали вас исключительно
      «Сергей Корнеевич» и на вы.</p>

    <p>— Лучше «Серёжа», если, конечно,
      Габриэль Евгеньевич не купил авторское право на такое именование вас.</p>

    <p>— Но мы этого никогда не узнаем.</p>

    <p>— Потому что Габриэль Евгеньевич
      бежал в Афстралию, а у нас чрезвычайная ситуация.</p>

    <p>— И с вашим рабством у нас, к
      несчастью, придётся повременить.</p>

    <p>Гуанако закурил посреди
      истфаковского коридора. Не то чтобы он делал это впервые в жизни, но радость
      оставалась всё такой же пронзительной.</p>

    <p>— Сергей Корнеевич, скажите
      честно.</p>

    <p>— Вы уже продались в рабство
      Порту за университетские долги?</p>

    <p>Гуанако не нашёл сразу правильных
      слов, чтобы охарактеризовать ситуацию с Портом (банальное «блядство» звучало бы
      слишком бледно), но, по всей видимости, с характеристикой прекрасно справились
      его мимические мышцы.</p>

    <p>Охрович и Краснокаменный кивнули,
      декларируя тем самым свою осведомлённость в портовых делах, и сразу перешли к
      главному:</p>

    <p>— Мы знаем, как выплатить Порту
      долги.</p>

    <p>— Не все долги, все долги
      ужасающе масштабны.</p>

    <p>— Но хоть какую-то часть.</p>

    <p>— И совсем скоро.</p>

    <p>— В пределах нескольких дней.</p>

    <p>— Это ведь улучшит положение?</p>

    <p>— В некоторой степени, —
      подтвердил Гуанако. Как улучшить положение целиком и полностью, он просто
      старался пока не думать.</p>

    <p>Порт — слишком дом, слишком
      слабое место, слишком сильная гуанаковская привязанность, чтобы думать о нём
      было просто. Ну нахуй.</p>

    <p>Охрович и Краснокаменный
      неодобрительно покачали головами:</p>

    <p>— Где же ваш обычный дебильный
      оптимизм?</p>

    <p>— Вера в лучшее?</p>

    <p>— Уверенность в своих силах?</p>

    <p>— Лучше «дебильная
      самоуверенность»!</p>

    <p>— Вера в своих родных дебилов!</p>

    <p>— Вы нам сегодня не нравитесь,
      Сергей Корнеевич.</p>

    <p>— Вы удручающе серьёзны, вы нас
      разочаровываете.</p>

    <p>— Мы боимся заразиться
      серьёзностью.</p>

    <p>— Все нынче чем-то заражаются,
      это модно.</p>

    <p>— Но мы-то не можем следовать
      моде.</p>

    <p>— Мы дали жесточайший обет нонконформизма.</p>

    <p>— Не подводите нас.</p>

    <p>— Не ходите ничего взрывать с
      такой рожей.</p>

    <p>— Бомба испугается и не
      сработает.</p>

    <p>— Часовой механизм нежен, он не
      терпит серьёзных рож.</p>

    <p>— Как и мы.</p>

    <p>Гуанако понял, понял-уже-блядь
      рекомендацию относительно серьёзности!</p>

    <p>Охрович и Краснокаменный —
      ходячая психическая атака, за ними собственных мыслей не услышишь. Даже если
      таковые есть.</p>

    <p>— Вы собирались рассказать,
      откуда думаете возвращать долги, — напомнил Гуанако и попытался скорчить
      предельно дебильную рожу.</p>

    <p>— Вы что-то путаете.</p>

    <p>— Мы такого не обещали.</p>

    <p>— Мы собирались только известить
      вас о наличии денежных источников.</p>

    <p>— Мы же не можем сознаться вам,
      что поддались порочной слабости.</p>

    <p>— Последовали примеру
      Бедроградской гэбни, превращающей канализационное дерьмо в питьевую воду.</p>

    <p>— И начали превращать дерьмо в
      деньги.</p>

    <p>— Метафорически выражаясь.</p>

    <p>— На деле мы просто торгуем
      дерьмом.</p>

    <p>— Фасуем ночи напролёт и продаем.</p>

    <p>— И хорошо берут, надо сказать.</p>

    <p>— Отрывают с руками.</p>

    <p>— В городе беспорядки — драки в
      очередях за дерьмом.</p>

    <p>— Хуйня, — вклинился Гуанако, —
      если б кто-то взялся продавать дерьмо, я бы первый об этом узнал. И первый бы
      побежал доставать пару мешков для Димы. У него с дерьмом особые отношения, сами
      знаете. Поэтому либо выкладывайте, что вы задумали на самом деле, либо пиздуйте
      отсюда, у меня всё ещё разговор к Ройшу.</p>

    <p>Охрович и Краснокаменный на это
      поморщились форменным Ройшем, но выёбываться не стали:</p>

    <p>— Вы правы, мы опасаемся
      продавать зачумлённое дерьмо.</p>

    <p>— Чума и так у всех на слуху, а
      нам не требуется дешёвая популярность.</p>

    <p>— Дорогое дерьмо мы начнём
      продавать, когда чума закончится.</p>

    <p>— А пока мы пошли к финансовому
      благополучию более извилистым путём.</p>

    <p>— Мы превращаем в деньги
      благодарность.</p>

    <p>— Благодарность <i>вам</i>, Сергей Корнеевич.</p>

    <p>— Мы просто подумали, что версия
      с дерьмом вам приглянётся больше.</p>

    <p>— Но вы вынудили нас озвучить
      страшную правду.</p>

    <p>— И мы её уже почти озвучили.</p>

    <p>— Сейчас озвучим до конца.</p>

    <p>— Прямо сейчас.</p>

    <p>— Сию секунду.</p>

    <p>— Незамедлительно.</p>

    <p>— Не растрачивая попусту слова.</p>

    <p>— И минуты.</p>

    <p>— Не ломаясь для приличия.</p>

    <p>— Не отвлекаясь на неприличное.</p>

    <p>— Вообще ни на что не отвлекаясь.</p>

    <p>— Не рассеивая внимание заинтересованного слушателя.</p>

    <p>— Скажем правду в лоб.</p>

    <p>— Лоб не оценит, но мы всё равно скажем.</p>

    <p>Охрович и Краснокаменный
      подержали немного драматическую паузу, чтоб Гуанако мог за это время
      окончательно свихнуться от количества пришедших ему на ум вариантов
      разнообразной самоокупающейся благодарности, но долго измываться не стали —
      лишней-то пары часов в запасе ни у кого нет.</p>

    <p>— Сергей Корнеевич, мы продали
      ваше доброе имя.</p>

    <p>— Вышили его на знамени, знамя
      подняли и протащили по всей стране.</p>

    <p>— И сувенирных флажков ещё
      наделали.</p>

    <p>— Мы обратились к вашим бывшим
      студентам.</p>

    <p>— Особенно настойчиво — к
      студентам из нашего выпуска.</p>

    <p>— Вы, наверное, знаете — у них
      когда-то давно были проблемы.</p>

    <p>— С законом и с собой.</p>

    <p>— Бедняжки!</p>

    <p>— Потерянное поколение!</p>

    <p>— А вы его нашли.</p>

    <p>— Даже не думайте, отнекиваться
      бесполезно.</p>

    <p>— У нас есть свидетели.</p>

    <p>— И доказательства.</p>

    <p>— А главное — бумажки.</p>

    <p>— Вы не можете отнекиваться перед
      бумажками.</p>

    <p>— Никто не может, бумажки беспощадны.</p>

    <p>— В бумажках написано, что вас
      когда-то куда-то посадили за ваши благие деяния.</p>

    <p>— За помощь студентам, то есть.</p>

    <p>— За то, чтобы когда-то куда-то
      не сели они.</p>

    <p>— Всей шайкой.</p>

    <p>— Группировкой.</p>

    <p>— Движением.</p>

    <p>— И они, в отличие от вас, не
      считают это пустяком.</p>

    <p>— Они вам благодарны.</p>

    <p>— <i>Благодарны,</i> слышите?</p>

    <p>— БЛАГОДАРНЫ!</p>

    <p>—
      Благодарны-благодарны-благодарны-благодарны-благодарны.</p>

    <p>— Благодарны, и вам придётся с
      этим жить.</p>

    <p>— Потому что мы уже решили всё за
      вас.</p>

    <p>— Вы бы всё равно не согласились
      добровольно.</p>

    <p>— Но теперь поздно, дело сделано.</p>

    <p>— Мы нашли ваших бывших студентов
      и выклянчили у них денег.</p>

    <p>— Вашим именем, Сергей Корнеевич.</p>

    <p>— В память о вас.</p>

    <p>— На ваши посмертные нужды.</p>

    <p>— И, представьте себе, они
      купились.</p>

    <p>— То есть продались.</p>

    <p>— То есть безвозмездно расщедрились.</p>

    <p>— Некоторые ваши бывшие студенты
      — состоятельные люди.</p>

    <p>— Крупные начальники.</p>

    <p>— Частные предприниматели.</p>

    <p>— Воры и контрабандисты.</p>

    <p>— Безжалостные убийцы.</p>

    <p>— И у всех у них хорошая память.</p>

    <p>— И доброе сердце.</p>

    <p>— Если их разжалобить.</p>

    <p>— Мы умеем жалобить, клянчить и
      унижаться.</p>

    <p>— Мы профессионально унижаемся!</p>

    <p>— Мы проходили бордельный
      инструктаж.</p>

    <p>— Мы сами его сочинили.</p>

    <p>— Мы знаем об унижении всё.</p>

    <p>— Поэтому у нас теперь есть чем
      расплачиваться с Портом.</p>

    <p>Когда они наконец-то заткнулись,
      Гуанако понял, что так впустую и сжёг прикуренную сигарету. Жалко.</p>

    <p>— Я бы разрыдался, если бы умел,
      — заявил он и с нездоровой, истерической такой весёлостью отметил: наверное,
      это даже не совсем неправда.</p>

    <p>Бывшие студенты дают ему денег на
      нужды чумы, блядь. Просто так, потому что могут. Прикрывают от полной жопы. Что
      там Дима говорил про кольцевую композицию?</p>

    <p>— Мы можем научить вас рыдать.</p>

    <p>— Быстро, эффективно и даже не
      очень болезненно, — пригрозили Охрович и Краснокаменный.</p>

    <p>— Вы охуенные, — Гуанако взялся
      за следующую сигарету. — Вы всё правильно сделали. Спасибо, что ли.</p>

    <p>— Рано радуетесь, со всеми
      долгами мы этим не расплатимся.</p>

    <p>— Но хоть убедим на время Порт в
      своей платёжеспособности.</p>

    <p>— Первая партия бескорыстной
      помощи будет завтра.</p>

    <p>— И частично — натурой.</p>

    <p>— В смысле, товарами.</p>

    <p>— Порт ведь примет товары?</p>

    <p>— Куда он денется, — выпустил струю
      дыма Гуанако, — я предупрежу кого следует, что с завтрашнего дня мы потихоньку
      возвращаем награбленное. Грузовики, пропуска — всё будет.</p>

    <p>— Но это не значит, что мы
      оставили фантазии о вашем рабстве.</p>

    <p>— Мы в них только укрепились.</p>

    <p>— Нам нравится ваша покорность
      судьбе.</p>

    <p>— Вы даже не кричите, что не
      нуждаетесь ни в каких стародавних благодарностях.</p>

    <p>— Наверное, вы уже умерли.</p>

    <p>— В этот раз — от разрыва
      совести.</p>

    <p>Очень может быть. Гуанако
      недвусмысленно попятился в сторону лестницы.</p>

    <p>— Вы хотите сбежать.</p>

    <p>— Поорать и порыдать для
      успокоения совести в уголке.</p>

    <p>— В уголке библиотеки вместе с
      Ройшем.</p>

    <p>— Вам требуется сопровождение?</p>

    <p>— Звуковое?</p>

    <p>— Силовое?</p>

    <p>— Ройш вас просто так не
      подпустит, не надейтесь.</p>

    <p>— Ройш занят, у него траур по
      своей так и не поруганной невинности.</p>

    <p>— Можете попробовать сделать
      что-нибудь с его невинностью.</p>

    <p>— Вот тогда-то мы и зауважаем вас
      по-настоящему.</p>

    <p>— Взрывать какой-то там
      десятилетний юбилей Первого Большого — слишком мелко для такого великого
      человека.</p>

    <p>— Лучше трахните Ройша — это
      будет действительно чрезвычайная ситуация.</p>

    <p>— Идите, пожалуйста, в Хуй, —
      ужаснулся Гуанако. — Там ещё Колошма сверху, не промахнётесь.</p>

    <p>Охрович и Краснокаменный
      исполнили пластический этюд «Габриэль Евгеньевич оскорблён» и молча (какое
      счастье) удалились.</p>

    <p>Охуенные, какие же они охуенные,
      а.</p>

    <p>Умереть от разрыва совести — до
      отвращения реальная перспектива. Пользуясь всем, чем было (и не было), Гуанако
      назанимал у Порта ресурсов для университетской борьбы с чумой и Бедроградской
      гэбней. И даже представлял примерно, как будет расплачиваться, но потом, <i>потом</i>, не прямо же сейчас — сейчас
      некогда. Но ситуация-то вышла из-под контроля, Портовая гэбня с каждым часом
      теряет в деньгах и в доверии столько, сколько и представить страшно. А Гуанако
      продолжает и продолжает подставлять Порт, отмахиваясь от расспросов
      сочувствующих.</p>

    <p>Но Охровичу и Краснокаменному
      расспросы не нужны, они без всяких расспросов охуенные и знают, что делать.</p>

    <p>И от этого разрыв совести всё
      ближе.</p>

    <p>Гуанако двинулся к библиотеке и
      через два шага сорвался на бег. Во-первых, время-время-время: чтобы
      Бедроградская гэбня забила на встречу, теракт надо начинать побыстрей, если
      вообще начинать. Во-вторых, Охрович и Краснокаменный бодрят и вливают в
      организм свежие силы (через уши). А
      в-третьих, физические нагрузки помогают от совести, моральных терзаний и прочих
      «я этого не заслужил».</p>

    <p>Совершенно по-детски, зато
      работает.</p>

    <p>Конечно же, преодолевая последний
      перед библиотекой пролёт бегом, Гуанако едва не вписался в какого-то очень не
      вовремя возникшего на лестнице человека.</p>

    <p>— Простите, — на автомате бросил
      он и почти уже побежал дальше, но тут разглядел свою несостоявшуюся жертву. —
      Ёбаный стыд, здравствуйте, Писарь!</p>

    <p>Чуть постаревший, но всё такой же
      забавный, броский, дружелюбнейший Писарь.</p>

    <p>Нихуя себе кольцевая композиция!</p>

    <p>— Здравствуйте… 66563, — ошалело
      протянул руку Писарь.</p>

    <p>Гуанако, конечно, ещё по
      возвращении в Бедроград рассказали, что Писарь (Стас Никитич из блядской гэбни
      блядской Колошмы) числится теперь в Университете, но одно дело услышать, а
      другое — увидеть.</p>

    <p>Столкнуться на истфаковской
      парадной лестнице.</p>

    <p>Писарю повезло меньше. У него
      поперёк рожи было написано (огромными такими буквами), что правдой о судьбе
      Гуанако (подохшего от степной чумы в 76-м) с ним никто не делился.</p>

    <p>— Ну вот как-то так, — крайне
      внятно пояснил своё явление Гуанако и улыбнулся. — Леший, столько лет знакомы,
      а я только сейчас узнал, какого вы роста.</p>

    <p>Рост — чуть выше среднего,
      комплекция худощавая, волосы светло-каштановые, удлинённая стрижка. Тонкие,
      правильные черты лица. Держится неагрессивно, движения плавные, чуть
      замедленные (годы и годы курения савьюра). Впрочем, в досье, наверное, пишут
      поточнее.</p>

    <p>Писарь тоже улыбнулся.
      Растерянно, но дружелюбно. Прям как на допросе.</p>

    <p>Они виделись-то всего раз шесть,
      наверное — по числу допросов в том составе гэбни. Отсюда и рост — головы гэбни
      при допросе обвиняемого и/или заключённого не встают из-за гэбенного стола,
      протокол не велит. Гуанако, конечно, вместе с той самой гэбней Колошмы протокол
      куда только ни ебал, но вот из-за стола при нём поднимался, кажется, всего один
      из голов гэбни. И то на последнем в своей жизни протокольном мероприятии.</p>

    <p>Начальник Колошмы, Юр Саввович.</p>

    <p>Савьюр.</p>

    <p>(Почудилось, что перстни из
      клавиш печатной машинки легонько кольнули пальцы.)</p>

    <p>Писарь уставился на непроизвольно
      дёрнувшуюся руку Гуанако. Он недаром Писарь, у него годами тренированная
      зрительная память — опознал клавиши (поди их не опознай, модель-то была
      дореволюционная, с выебонами). Чуть усмехнулся, прочитав складывающийся из них
      «хуй».</p>

    <p>— Прихватил, сбегая от степной
      чумы, — пробормотал Гуанако, — машинку-то прихватить не мог. А её мне широким
      жестом ссудил Хикеракли, когда на Колошму заезжал. Я вообще-то потом его личный
      заказ вроде как выполнял, а руками-то я долго строчить не люблю, особенно после
      «писем из экспедиции». Ну вот он и велел новой гэбне отдать мне савьюровскую
      машинку. Для подрочить, наверное, — Гуанако рефлекторно принял стойку понаглее.
      О навеки отпечатавшийся опыт допросов! — Для подрочить лучше б наплечник
      савьюровский подарил.</p>

    <p>Писарь так же рефлекторно
      приподнял левое плечо (на котором носят наплечник) и машинально возразил:</p>

    <p>— Было бы надругательство над
      элементами символики государственного аппарата. Плюс ещё пара лет к вашему
      пожизненному заключению, — и тихо-тихо добавил: — Это ведь я ваши с Савьюром
      записи из камеры расшифровывал.</p>

    <p>Тут, наверное, уж точно
      полагалось возрыдать, но Гуанако не нашёл в себе сил ни на что, кроме
      очередного глупого комментария:</p>

    <p>— А. Ну, наверное, на это я бы мог
      у вас спросить, как же всё-таки полагается фиксировать в расшифровках процесс
      ебли, но увы. Очевидцы блядских расшифровок давным-давно поведали мне все
      существенные детали, — Гуанако задумался. — Зато теперь, вооружённый знанием
      протокольных формулировок, я могу спросить о другом. Как вы разобрались, кто
      кого: по окрестным репликам или таки прям по «неразборчивым звукосочетаниям»?</p>

    <p>Писарь махнул рукой, демонстрируя
      равнодушие к скрытым комплиментам своим профессиональным навыкам:</p>

    <p>— Это уж точно не сложнее, чем
      разбирать какую-нибудь руническую вязь. Наполовину истёртую, поросшую мхом,
      которой я к тому же не понимаю.</p>

    <p>Писарь, говорят, теперь научный
      сотрудник на лингвистическом факультете. Он же талант и профессионал высокого
      класса — ну и что, что рун не знает. На Колошме в промышленных масштабах ваял
      фальшивые письма чужим почерком, что ему какие-то замшелые письмена.</p>

    <p>Хорошо устроился, ещё и
      полуслужащим Университета заодно. Переманили когда-то там.</p>

    <p>— Слушайте, 66563, ну вы-то хоть
      знаете, что здесь творится? — перешёл на бессмысленный в полупустом здании
      шёпот Писарь. — Я только ночью вернулся из экспедиции к очередным рунам, а
      сейчас меня зовёт Ройш, даёт странные совсем распоряжения, голова кругом.
      Университетская гэбня сошла с ума?</p>

    <p>— Мы все сошли, Писарь. И вы
      присоединяйтесь, раз уж вы полуслужащий. Сейчас лишних рук быть не может, а у
      вас руки и вовсе на вес золота. Мой вам совет: если Ройшевы распоряжения не
      горят, найдите прямо сейчас Лария Базальда, он вас тут же и припашет, дела найдутся.
      Может, даже объяснит что-нибудь. Я бы и сам объяснил, но уровня доступа и права
      на распространение информации не имею никакого как почётный покойник. А ещё сам
      спешу к Ройшу, — Гуанако обернулся, уже почти добравшись до верхней площадки. —
      Свидимся, Писарь. Я сколько лет уже хочу с вами савьюра курнуть!</p>

    <p>— 66563, — окликнул его Писарь, —
      вы теперь вообще никогда не умрёте?</p>

    <p>— Вероятно, — пожал плечами
      Гуанако и юркнул в узкий коридор под самой крышей, где и ютилось хранилище
      факультетской библиотеки.</p>

    <p>Ройш даёт распоряжения Писарю?</p>

    <p>Ройш жив и деятелен?</p>

    <p>— Сергей Корнеевич, вы очень
      удачно зашли, — откуда-то из темноты, полной нескончаемых стеллажей,
      поприветствовал его Ройш.</p>

    <p>Правы Охрович и Краснокаменный:
      Ройш — рептилия.</p>

    <p>Отлёживается в пещере. Линяет,
      сбрасывая прежние убеждения?</p>

    <p>— Я по делу, — пошёл на звук
      Гуанако. — Хочу взрывать юбилей Первого Большого. В течение ближайшего часа.
      Нуждаюсь в юридической консультации.</p>

    <p>— Давайте повременим со взрывами,
      — отозвался Ройш.</p>

    <p>Вот он, зарылся в какие-то
      кодексы при тусклом свете крохотной лампы. Мрачный, усталый, но — вопреки всем
      ожиданиям — живой.</p>

    <p>Гуанако с сомнением оглядел
      логово Ройша, не обнаружил там Максима, который только и мог бы избавить тут
      всех от необходимости взрывов своим внезапным возвращением, и уточнил:</p>

    <p>— Ройш, ты сейчас будешь
      отговаривать меня от совершения теракта, за который меня, собственно, десять
      лет назад и посадили?</p>

    <p>— Я не поклонник Фрайда, и,
      следовательно, не вижу такой уж большой необходимости кому бы то ни было по
      своей воле проходить на новом этапе старые вехи развития, — Ройш невозмутимо
      пялился в какую-то бумажку. — Да. Буду отговаривать.</p>

    <p>Справа от Ройша, возвышаясь над
      макулатурой, стояла бутылка твиревой настойки с трогательно надетым на горлышко
      стаканом. Бутылка была нераспечатанная.</p>

    <p>Гуанако тряхнул головой.</p>

    <p>Ройш пошёл в библиотеку с бухлом?</p>

    <p>Ройш так отчаялся, что думал
      снизойти до алкоголя?</p>

    <p>Ройш не снизошёл до алкоголя,
      потому что он Ройш или потому что —</p>

    <p>— Ознакомьтесь, — Ройш
      добросовестно протянул Гуанако свою бумажку и добросовестно же начал
      отговаривать Гуанако взрывать блядский юбилей.</p>

    <p>И леший, у него были на то
      причины.</p>
  </>
}

export default chapter20
